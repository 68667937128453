import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';
import { HelperService } from '/services';
import './CustomersActionsFormatter.scss';
import cx from 'classnames';

export const CustomersActionsFormatter = ({
    creation,
    id,
    hash,
    onClickSend,
    onClickDelete,
    onClickReminder,
    isEnableReminder,
    isEnableInvitation,
    rightsSurvey: {
        isPermitInvite,
        isSupportUser,
    },
}) => {
    const openModalSend = () => {
        const date = HelperService.formatDate(creation, HelperService.defaultDateTimeMask);

        return isPermitInvite && onClickSend(date, hash);
    };

    const openModalDelete = () => isPermitInvite && onClickDelete(id, hash);

    return (
        <div
            id={ 'table-action__container' }
            className={ cx(
                'table-action__container table-action__customers',
                { 'disabled': !isPermitInvite },
            ) }
        >
            {
                isSupportUser
                && <TooltipWrapper value={ 'Send reminder' } force>
                    <span
                        className={ cx({ 'disabled': !isEnableReminder }) }
                        onClick={ () => isEnableReminder && onClickReminder(id) }
                    >
                        <FontAwesomeIcon icon="bell"/>
                    </span>
                </TooltipWrapper>
            }

            <TooltipWrapper value={ 'Send invitation' } force>
                <span
                    className={ cx({ 'disabled': !isEnableInvitation }) }
                    onClick={ () => isEnableInvitation && openModalSend() }
                >
                    <FontAwesomeIcon icon="envelope"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Delete questionnaire' } force>
                <span onClick={ openModalDelete }>
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>

        </div>
    );
};
