import { TFunction } from 'i18next';

const commonCharts = {
    marginTop: 25,
    marginBottom: 0,
    marginRight: 60,
    marginLeft: 80,
    minInternalWidth: 300,
    minInternalHeight: 190,
    decimateSize: 20,
    yLeftLabelOffset: 39, // height of y label container
    xLabelOffset: 33, // height of x label container
    rightOffset: 10,
    getYRightLabelOffset: (isNpsChart: boolean) =>
        isNpsChart ? commonCharts.yLeftLabelOffset : commonCharts.rightOffset,
    yTickOffset: 37, // width of container with yAxis ticks
    yRightTickPadding: 3,
    yRightTickSize: 4,
    yLeftTickPadding: 3,
    yLeftTickSize: 4,
};

export const customIdReplacerStr = /\W/g;

export const charts = {
    surveys_bar_chart: {
        ...commonCharts,
        marginBottom: 20,
        outerPaddingForBarGroups: 0.1, // range [0, 1]
        innerPaddingForBarGroups: 0.2, // range [0, 1]
        barTextYNegativeOffset: 15,
        barTextYPositiveOffset: 3,
        yLeftTicksCount: 5,
        tooltip: {
            showParentId: true,
            label: (t?: TFunction) => t?.('field.category') ?? 'Category',
        },
    },
    stacked_bar_chart: {
        ...commonCharts,
        outerPaddingForBarGroups: 0.1, // range [0, 1]
        innerPaddingForBarGroups: 0.2, // range [0, 1]
        barTextYNegativeOffset: 15,
        barTextYPositiveOffset: 3,
        yRightTicksCount: 10,
        yLeftTicksCount: 10,
        tooltip: {
            showParentId: true,
            label: (t?: TFunction) => t?.('field.name') ?? 'Name',
        },
        nps: {
            dotRadius: 5,
            overRadius: 8,
            overStrokeWidth: 3,
            lineStrokeWidth: 2,
        },
    },
    line_chart: {
        ...commonCharts,
        circleTextPositiveOffset: 5,
        yRightTicksCount: 10,
        yLeftTicksCount: 5,
        dotRadius: 3,
        overRadius: 7,
        lineStrokeWidth: 1,
        overStrokeWidth: 3,
        tooltip: {
            showParentId: false,
            label: (t?: TFunction) => t?.('field.name') ?? 'Name',
        },
        nps: {
            dotRadius: 5,
            overRadius: 8,
            overStrokeWidth: 3,
            lineStrokeWidth: 2,
        },
    },
    nps_bubble_chart: {
        ...commonCharts,
        marginTopWithTitles: 70,
        yLeftTicksCount: 10,
        xBottomTicks: 5,
        axisOffset: 0.25,
        maxRadius: 50,
        minRadius: 5,
        overRadius: 10,
    },
    generic_pie_chart: {
        tooltip: {
            label: (t?: TFunction) => t?.('field.category') ?? 'Category',
        },
    },
    lemmata_word_cloud: {
        sizeGadgetFooter: 121,
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        defaultColors: [
            '#FFB759',
            '#98CC6C',
            '#75909B',
            '#0065BD',
            '#006063',
            '#8AEAEA',
            '#7686C8',
            '#DB2F35',
            '#00A9F1',
            '#BF68C5',
            '#E876D5',
            '#FF3DA7',
            '#CD1262',
        ],
    },
};

export const allNpsSegments = [ 'Detractors', 'Promoters', 'Passives' ];

export const chartTypes = {
    SURVEYS_BAR: 'surveys_bar_chart',
    STACKED_BAR: 'stacked_bar_chart',
    TABLE: 'custom_columns_table',
    PIE: 'generic_pie_chart',
    RADAR: 'radar_chart',
    WORD: 'lemmata_word_cloud',
    BUBBLE: 'nps_bubble_chart',
    LINE: 'line_chart',
};

export const d3ColorSchema = [
    '#1f77b4',
    '#aec7e8',
    '#ff7f0e',
    '#ffbb78',
    '#2ca02c',
    '#98df8a',
    '#d62728',
    '#ff9896',
    '#9467bd',
    '#c5b0d5',
    '#8c564b',
    '#c49c94',
    '#e377c2',
    '#f7b6d2',
    '#7f7f7f',
    '#c7c7c7',
    '#bcbd22',
    '#dbdb8d',
    '#17becf',
    '#9edae5',
];
