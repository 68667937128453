import { CALL_API } from 'store/middleware/api';

const CLEANUP_VOC_SYSTEM_UPLOAD = 'CLEANUP_VOC_SYSTEM_UPLOAD';

const GET_SYSTEM_UPLOADS_REQUEST = 'GET_SYSTEM_UPLOADS_REQUEST';
const GET_SYSTEM_UPLOADS_SUCCESS = 'GET_SYSTEM_UPLOADS_SUCCESS';
const GET_SYSTEM_UPLOADS_ERROR = 'GET_SYSTEM_UPLOADS_ERROR';

export const getInitialState = () => ({
    tableData: [],
    total: 0,
    loading: false,
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_SYSTEM_UPLOADS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_SYSTEM_UPLOADS_SUCCESS: {
            return {
                ...state,
                tableData: [ ...action.response ],
                loading: false,
                total: action.response.length,
            };
        }

        case GET_SYSTEM_UPLOADS_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case CLEANUP_VOC_SYSTEM_UPLOAD: {
            return getInitialState();
        }

        default: {
            return state;
        }
    }
}

export function cleanupSystemUpload() {
    return {
        type: CLEANUP_VOC_SYSTEM_UPLOAD,
    };
}

export function getSystemUpload() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/system-upload/`,
            types: [
                GET_SYSTEM_UPLOADS_REQUEST,
                GET_SYSTEM_UPLOADS_SUCCESS,
                GET_SYSTEM_UPLOADS_ERROR,
            ],
            method: 'GET',
            logger: true,
        },
    };
}
