import { sockets } from '/services';
import { invalidateCache } from '/modules';


export function sockedUploadCreateListener() {
    return sockets.on('store_upload_created', (createdUpload, store) => {
        store.dispatch(invalidateCache('vocStore.uploads'));
    });
}

