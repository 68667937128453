import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import { RangeDatePicker } from '/components/RangeDatePicker/';
import { VocSelect, SwitchControl } from '/components';
import { ExportResultsSelected } from '../ExportResultsSelected/';
import { HelperService } from '/services';

import './ExportResultsTableToolbar.scss';

export class ExportResultsTableToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: props.search,
        };

        this.searchTypeDebouncer = HelperService.debounce((...args) => {
            props.onSearchType(...args);
        }, 300);
    }

    @autobind
    onSearchType(event) {
        const search = event.target.value;

        this.setState({ search });
        this.searchTypeDebouncer(search);
    }

    render() {
        const {
            dateRangeHandler,
            dates,
            selectedList,
            removeFromSelected,
            channel,
            changeChannel,
            channels,
            changeRange,
            rangeTypes,
            range,
        } = this.props;

        const channelValue = HelperService.getSelectedValue(channels, channel);
        const rangeValue = HelperService.getSelectedValue(rangeTypes, range);
        const defaultDate = new Date();

        return (
            <Row className='export-results__toolbar'>
                <Col xs={ 2 } sm={ 2 } md={ 3 }>
                    <Row>
                        <Col md={ 4 } className={ 'select-control-label' }>
                            <label htmlFor={ 'rangeValue' }
                                className={ 'export-results-toolbar-label' }>
                                Filtered by:
                            </label>
                        </Col>
                        <Col md={ 8 }>
                            <VocSelect
                                name="rangeValue"
                                isClearable={ false }
                                isHorizontal={ true }
                                options={ rangeTypes }
                                value={ rangeValue }
                                onChange={ changeRange }
                                className={ 'export-results-toolbar-select' }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={ 4 } sm={ 4 } md={ 4 }>
                    <RangeDatePicker
                        dateFormat={ HelperService.datePickerMask }
                        handleChange={ dateRangeHandler }
                        isHorizontal={ true }
                        values={ dates }
                        defaultDate={ defaultDate }
                    />
                </Col>
                <Col xs={ 2 } sm={ 2 } md={ 2 }>
                    <Row>
                        <Col md={ 5 } className={ 'select-control-label' }>
                            <label htmlFor={ 'channelSelect' }
                                className={ 'export-results-toolbar-label' }>
                                Channel:
                            </label>
                        </Col>
                        <Col md={ 7 }>
                            <VocSelect
                                name="channelSelect"
                                isClearable={ false }
                                isHorizontal={ true }
                                options={ channels }
                                value={ channelValue }
                                onChange={ changeChannel }
                                className={ 'export-results-toolbar-select' }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={ 6 } sm={ 6 } md={ 3 }>
                    <div className="form-group form-group-sm react-bs-table-search-form">
                        <Input
                            placeholder={ 'Search' }
                            className={ 'export-results__toolbar-search' }
                            type={ 'text' }
                            value={ this.state.search }
                            onChange={ this.onSearchType }/>
                    </div>
                </Col>
                <Col xs={ 12 }>
                    <ExportResultsSelected
                        selectedList={ selectedList }
                        removeFromSelected={ removeFromSelected }
                    />
                </Col>
            </Row>
        );
    }
}

ExportResultsTableToolbar.propTypes = {
    dateRangeHandler: PropTypes.func,
    removeFromSelected: PropTypes.func,
    dates: PropTypes.shape({
        from: PropTypes.instanceOf(Date),
        to: PropTypes.instanceOf(Date),
    }),
    channel: PropTypes.string,
    selectedList: PropTypes.array,
};
