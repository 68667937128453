import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { reduxForm, Field } from 'redux-form';
import { Button, Row, Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { exception } from '../../modules/caseAlerts.selectors';
import {
    VocFormSelectField,
    VocFormTextField,
    VocModal,
} from '/components';
import { CaseAlertConditionFormValidation } from './CaseAlertConditionFormValidation';
import { HelperService } from '/services';
import './CaseAlertConditionForm.scss';

@reduxForm({
    form: 'ConditionsCaseAlertForm',
    enableReinitialize: true,
    validate: CaseAlertConditionFormValidation,
})
export class CaseAlertConditionForm extends PureComponent {
    componentDidUpdate(prevProps) {
        const prevAlertCondition = prevProps.formValuesSelector.alert_condition;
        const nowAlertCondition = this.props.formValuesSelector.alert_condition;

        if (prevAlertCondition !== nowAlertCondition && exception.includes(nowAlertCondition)) {
            this.props.change('value', '');
        }
    }

    @autobind
    getAdditionalFieldProps(type, options, uniq = false, name, isTextField) {
        const { formValuesSelector: { classifier_id }, fieldsMap } = this.props;
        let additionalProps = {};
        const currentClassifier = uniq && fieldsMap.classifiers.find(item => item.classifierId === classifier_id);
        const currentOptions = currentClassifier ? currentClassifier.categories.map(category => ({
            value: category.categoryId,
            label: category.title,
        })) : options;

        if (type === 'select') {
            additionalProps = {
                placeholder: name === 'alert_condition' ? 'Choose alert condition' : 'Select...',
                options: currentOptions,
                normalize: HelperService.normalizeSelectValue,
                format: value => HelperService.getSelectedValue(currentOptions, value),
                portal: true,
            };
        } else if (isTextField) {
            additionalProps = {
                disabled: true,
                required: false,
                withError: false,
            };
        }

        return additionalProps;
    }

    @autobind
    caseAlertTypeChange({ value }) {
        const { initialValues, reset, change } = this.props;
        const formFields = [ 'value', 'link_condition', 'alert_condition', 'classifier_id', 'category_id' ];

        // update existed case
        if (initialValues.hidden && value === initialValues.case_alert_type) {
            return reset();
        }

        formFields.forEach(field => change(field, null));
    }

    @autobind
    getModalBody() {
        const { formValuesSelector: { case_alert_type = 'answer', alert_condition }, fieldsMap, handleSubmit } = this.props;
        const fieldsTypeMap = {
            text: VocFormTextField,
            select: VocFormSelectField,
        };

        return (
            <form onSubmit={ handleSubmit(this.onSubmit) } className={ 'case_alert-form_modal-body' }>
                <Row>
                    <Col md={ 12 }>
                        <Label htmlFor={ 'case_alert_type' }>
                            Case Alert Type
                            <span className='star'> * </span>
                        </Label>
                    </Col>
                    <Col md={ 12 }>
                        <Field
                            name={ 'case_alert_type' }
                            component={ VocFormSelectField }
                            className={ 'no-margin' }
                            options={ fieldsMap.typeOptions }
                            normalize={ HelperService.normalizeSelectValue }
                            format={ value => HelperService.getSelectedValue(fieldsMap.typeOptions, value) }
                            onChange={ this.caseAlertTypeChange }
                            portal
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={ 12 }>
                        <Label htmlFor={ 'external_identifier' }>
                            { case_alert_type === 'metadata'
                                ? 'Metadata name'
                                : 'Question External ID'
                            } <span className='star'> * </span>
                        </Label>
                    </Col>
                    <Col md={ 12 }>
                        <Field
                            name={ 'external_identifier' }
                            component={ VocFormTextField }
                            className={ 'no-margin' }
                        />
                    </Col>
                </Row>
                { fieldsMap[case_alert_type].fields.map((field, index) => {
                    const isTextField = field.name === 'value' && exception.includes(alert_condition);

                    return (
                        <Row key={ `${ index }-${ field.title }` }>
                            <Col md={ 12 }>
                                <Label htmlFor={ field.name }>
                                    { field.title }
                                    {
                                        !isTextField && <span className='star'> * </span>
                                    }
                                </Label>
                            </Col>
                            <Col md={ 12 }>
                                <Field
                                    name={ field.name }
                                    component={ fieldsTypeMap[field.type] }
                                    className={ cx('no-margin', { 'text-field__case-alert': isTextField }) }
                                    { ...this.getAdditionalFieldProps(
                                        field.type,
                                        field.options,
                                        field.uniq,
                                        field.name,
                                        isTextField,
                                    ) }
                                />
                            </Col>
                        </Row>
                    );
                }) }
            </form>
        );
    }

    getClassifier(id, categoryId, list, value) {
        const classifier = list.find(item => item.classifierId === id);
        const category = classifier && classifier.categories.find(category => category.categoryId === categoryId);

        return category
            ? {
                category_id: category.categoryId,
                category_title: category.title,
                classifier_id: classifier.classifierId,
                classifier_title: classifier.title,
                enterprise_id: classifier.enterprise_id,
            }
            : value;
    }

    @autobind
    onSubmit(values) {
        const {
            fieldsMap,
        } = this.props;

        const {
            case_alert_type,
            case_alert,
            alert_condition,
            external_identifier,
            link_condition,
            value,
            classifier_id = null,
            category_id = null,
            id = null,
        } = values;
        const isClassifierType = case_alert_type === 'classifier';

        const model = {
            case_alert_type,
            alert_condition: !isClassifierType ? alert_condition : '=',
            case_alert,
            external_identifier,
            link_condition,
            value: isClassifierType
                ? this.getClassifier(classifier_id, category_id, fieldsMap.classifiers, value)
                : value,
        };

        this.props.submitCaseAlertConditionForm(model, id);
    }

    @autobind
    getFooter() {
        const { valid, dirty, caseAlertConditionSubmit, handleSubmit, permission } = this.props;

        return (
            <div className={ 'case_alert_footer-buttons' }>
                <Button onClick={ this.props.close } color={ 'white' } outline>
                    Cancel
                </Button>
                <Button
                    disabled={ !dirty || !valid || caseAlertConditionSubmit || !permission.isPermitEdit }
                    onClick={ handleSubmit(values => this.onSubmit(values)) }
                    color="primary"
                >
                    <FontAwesomeIcon icon={ 'check' }/>
                    <span> Save</span>
                </Button>
            </div>
        );
    }

    render() {
        const { show, close, formValuesSelector: { case_alert_type } } = this.props;
        const formHeader = `Case alert condition based on ${ case_alert_type }`;

        return (
            <VocModal
                toggle={ close }
                modalClassName={ 'modal-scrollable' }
                header={ formHeader }
                isOpen={ show }
                size={ 'md' }
                footer={ this.getFooter() }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}
