import React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { sharesSelector, usersAndGroupsSelector } from '/modules/selectors';
import {
    getSharedRights,
    saveStoreRights,
    saveDictionaryRights,
    saveMineRights,
    saveVisualRights,
    saveTopicSetRights,
} from '/modules/rightsModules/rights.index';
import { ShareRightsModal } from '/components';

export const VocRightsHOC = HOCprops => WrappedComponent => {
    class VocRequestClean extends React.Component {
        state = {
            isOpenInviteModal: false,
            soursId: null,
            uuid: null,
        };

        @autobind
        manageAccess(body) {
            const { uuid, soursId } = this.state;
            const { saveStoreRights, saveDictionaryRights, saveMineRights, saveVisualRights, saveTopicSetRights } = this.props;
            const newBody = { ...body, queryId: uuid ? uuid : soursId };
            let request;

            if (this.props.type === 'query') { //todo
                request = saveMineRights(newBody);
            } else if (this.props.type === 'dictionary') {
                request = saveDictionaryRights(newBody);
            } else if (this.props.type === 'topic-set') {
                request = saveTopicSetRights(newBody);
            } else if (this.props.type === 'visual') {
                request = saveVisualRights(newBody);
            } else if (this.props.type === 'systemUpload') {
                request = saveStoreRights(newBody);
            } else {
                request = saveStoreRights(newBody);
            }

            return request.promise
                .then(() => {
                    this.closeModal();
                    return this.props.requestTrigger?.();
                });
        }

        @autobind
        openInviteModal(id, uuid = null) {
            this.props.getSharedRights(id, this.props.type);

            this.setState({
                isOpenInviteModal: true,
                soursId: id,
                uuid: uuid,
            });
        }

        @autobind
        closeModal() {
            this.setState({
                isOpenInviteModal: false,
                soursId: null,
                uuid: null,
            });
        }

        render() {
            const {
                members,
                shares,
            } = this.props;
            const { soursId, isOpenInviteModal } = this.state;

            return (
                <div>
                    <WrappedComponent
                        openInviteModal={ this.openInviteModal }
                        { ...this.props }
                    />

                    <ShareRightsModal
                        show={ isOpenInviteModal }
                        close={ this.closeModal }
                        onSubmit={ this.manageAccess }
                        queryId={ soursId }
                        members={ members }
                        shares={ shares }
                        isOwnerRights={ this.props.type === 'visual' } //TODO temporarily
                    />
                </div>
            );
        }
    }

    const mapStateToProps = state => ({
        shares: sharesSelector(state),
        members: usersAndGroupsSelector(state),
        ...HOCprops,
    });

    const mapDispatchToProps = {
        getSharedRights,
        saveStoreRights,
        saveDictionaryRights,
        saveMineRights,
        saveVisualRights,
        saveTopicSetRights,
    };

    return connect(mapStateToProps, mapDispatchToProps)(VocRequestClean);
};
