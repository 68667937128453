import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import './Tbody.scss';

const weekDays = [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ];

export const Tbody = ({
    dateGridData,
    changeHandler,
    isEditOn,
    isRegular,
    infiniteLoading,
    scrollCondition,
    setIsItemDeleted,
    isItemDeleted,
    updateFilters,
    filters,
    isUpdate,
    removeDate,
}) => {
    const tbodyRef = useRef();

    useEffect(() => {
        tbodyRef.current.scrollTop = 0;
    }, [ isRegular, isUpdate ]);

    const onScroll = () => {
        const { scrollHeight, scrollTop, clientHeight } = tbodyRef.current;

        const defaultCondition = scrollHeight <= Math.round(scrollTop) + clientHeight;

        if (defaultCondition && scrollCondition && !isItemDeleted) {
            updateFilters({ page: filters.page + 1 });
        } else if (!defaultCondition) {
            isItemDeleted && setIsItemDeleted(false);
        }
    };

    const renderWeekdayOrDate = obj => {
        return (
            isRegular
                ? weekDays[obj.day_of_week]
                : <p className='remove-data'>
                    <span className={ `${ isEditOn ? '' : 'disabled opacity-0' }` }
                        onClick={ () => removeDate(obj) }> <FontAwesomeIcon icon='times'/> </span>
                    <span> { obj.applying_date }  </span>
                </p>
        );
    };

    return (
        <tbody
            id='timeDateGrid__tbody'
            ref={ tbodyRef }
            onScroll={ onScroll }
        >
            {
                dateGridData.length
                    ? dateGridData.map(({ time_slots, ...el }, inx) => (
                        <tr key={ `${ inx }__${ renderWeekdayOrDate(el) }` }>
                            <td>{ renderWeekdayOrDate(el) }</td>
                            {
                                Object.keys(time_slots).map(key => (
                                    <td
                                        key={ key + renderWeekdayOrDate(el) }
                                        data-inx={ inx }
                                        data-key={ key }
                                        onClick={ () => !isRegular && changeHandler(inx, key) }
                                        className={ cx(
                                            { 'timeDateGrid__cell': true },
                                            { 'red--cell': time_slots[key] === 0 },
                                            { 'forbidden--cell': !isEditOn },
                                        ) }
                                    />
                                ))
                            }
                        </tr>
                    ))
                    : <tr>
                        <td>There is no data to display</td>
                    </tr>
            }
            {
                infiniteLoading
            && <tr>
                <td>Loading</td>
            </tr>
            }
        </tbody>
    );
};
