import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { getActiveSource, changeActiveSourceId } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { activeSourceSelector, _getDashboardData } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { IDataSettings, IGadgetData, ISource, setTempSettingsType } from '/visual/models';
import { IBoard } from '/visual/scenes/Dashboard/models';

import { DependencyMatrixService, GadgetSettingsService } from '../../services';
import { ConfigureGadgetModalService } from '/visual/scenes/Dashboard/components/ConfigureGadgetModal/services';

const SettingsRules = [
    {
        // for all charts
        condition: 'any',
        restriction: {
            chartType: true,
            sourceId: true,
            facts: true,
            function: true,
        },
    },
    {
        condition: { chartType: 'nps_bubble_chart' },
        restriction: {
            groupBy: true,
            secondGroupBy: true,
        },
    },
    {
        condition: {
            chartType: 'line_chart',
            factTypes: 'NPS_SEGMENT',
            function: 'count',
            isMulti: false,
        },
        restriction: { groupBy: true },
    },
    {
        condition: {
            chartType: 'line_chart',
            factTypes: 'CHOICE_NPS_SEGMENT',
            function: 'count',
            isMulti: false,
        },
        restriction: { groupBy: true },
    },
    {
        condition: { function: 'affection' },
        restriction: { groupBy: true },
    },
    {
        condition: { exist: 'secondGroupBy' },
        restriction: { groupBy: true },
    },
];

const INSPECTION_TYPES = {
    CONDITION: 'condition',
    VALIDITY: 'validity',
};

const inspectEquality = (restriction: any, dataForCompare: any) => {
    let isValid = true;
    const fields = Object.keys(restriction);

    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        isValid = restriction[field] === Boolean(dataForCompare[field]);

        if (!isValid) break;
    }

    return isValid;
};

const inspectEqualityCondition = (conditions: any, dataForCompare: any) => {
    let isValid = true;
    const fields = Object.keys(conditions);

    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        if (field === 'exist') {
            isValid = Boolean(dataForCompare[conditions[field]]);
        } else {
            isValid = Array.isArray(dataForCompare[field])
                ? dataForCompare[field].every((item: any) => item === conditions[field])
                : conditions[field] === dataForCompare[field];
        }

        if (!isValid) break;
    }

    return isValid;
};

const getSettings = (activeSource: ISource, gadgetData: IGadgetData, dashboard: IBoard, t: TFunction) => {
    const { contentSettings, dataSettings } = gadgetData;

    const uploadAttrs = activeSource?.attributes?.map((attribute: any) => {
        const { id, name, type, originType, badge } = attribute;

        return {
            value: id,
            label: name,
            id, type,
            originType, badge,
        };
    }) || [];

    const uploadDateAttrs = uploadAttrs.filter((attribute: any) => attribute.type === 'DATE');
    const settingsUploadAttrs = uploadAttrs.filter((attribute: any) => attribute.type !== 'DATE');

    /*
    * uploadId -> selected && selectedOption
    * division for matrix and for selects + paginated selector ( overwriting all its data )
    * */
    let settings = [
        {
            id: 'chartType',
            label: t('field.chartType'),
            type: 'chart',
            selected: [ contentSettings.chartType ] || [],
            options: [
                { value: 'surveys_bar_chart', label: t('barChart') },
                { value: 'stacked_bar_chart', label: t('stackedBarChart') },
                { value: 'custom_columns_table', label: t('tableChart') },
                { value: 'generic_pie_chart', label: t('pieChart') },
                { value: 'radar_chart', label: t('radarChart') },
                { value: 'lemmata_word_cloud', label: t('wordCloudChart') },
                { value: 'nps_bubble_chart', label: t('bubbleChart') },
                { value: 'line_chart', label: t('lineChart') },
            ],
        },
        {
            id: 'sourceId',
            label: t('field.selectData'),
            type: 'dataSelect',
            disable: Boolean(dashboard?.parentDashboardId) || false,
            drag: false,
            tagging: true,
            selected: [ activeSource?.id ],
            selectedOption: activeSource?.id
                ? {
                    ...activeSource,
                    label: activeSource.title,
                    value: activeSource.id,
                }
                : {},
            options: [],
        },
        {
            id: 'facts',
            label: t('field.selectColumn'),
            type: 'select',
            disable: false,
            drag: true,
            isMulti: true,
            tagging: true,
            selected: dataSettings.facts ? dataSettings.facts : [],
            options: activeSource?.id
                ? GadgetSettingsService.optionsWithoutDuplicateIds(dataSettings, uploadAttrs, 'facts')
                : [],
        },
        {
            id: 'function',
            label: t('field.selectMathFunction'),
            type: 'select',
            disable: false,
            drag: false,
            selected: [ contentSettings.function ] || [],
            options: [
                { value: 'count', label: t('field.count') },
                { value: 'just_show', label: t('field.justShow') },
                { value: 'average', label: t('field.average') },
                { value: 'variance', label: t('field.variance') },
                { value: 'median', label: t('field.median') },
                { value: 'affection', label: t('field.affectionLevel') },
                { value: 'npsScore', label: t('field.npsScore') },
                { value: 'impact', label: t('field.impact') },
                { value: 'accSum', label: t('field.cumulativeSum') },
                { value: 'sum', label: t('field.sum') },
            ],
        },
        {
            id: 'groupBy',
            label: t('field.groupBy'),
            type: 'select',
            disable: false,
            drag: false,
            tagging: true,
            selected: dataSettings.groupBy ? [ dataSettings.groupBy ] : [],
            options: activeSource?.id
                ? GadgetSettingsService.optionsWithoutDuplicateIds(
                    dataSettings, settingsUploadAttrs, 'groupBy', uploadDateAttrs,
                )
                : [ ...GadgetSettingsService.dateIntervals ],
        },
        {
            id: 'secondGroupBy',
            label: t('field.secondGroupBy'),
            type: 'select',
            disable: false,
            drag: false,
            tagging: true,
            selected: dataSettings.secondGroupBy ? [ dataSettings.secondGroupBy ] : [],
            options: activeSource?.id
                ? GadgetSettingsService.optionsWithoutDuplicateIds(
                    dataSettings, settingsUploadAttrs, 'secondGroupBy', uploadDateAttrs,
                )
                : [],
        },
    ];

    settings = DependencyMatrixService.processRules(settings, dataSettings);
    settings = DependencyMatrixService.checkIsSelectedExist(settings);

    return settings;
};

export const useSettingsFilter = (
    gadget: IGadgetData,
    setTempSettings: setTempSettingsType,
) => {
    const { t } = useTranslation();
    const activeSource = useSelector(activeSourceSelector);
    const dashboard = useSelector(_getDashboardData);
    const [ settings, useSettings ] = useState(getSettings(activeSource, gadget, dashboard, t));
    const dispatch = useDispatch();

    // Upload attribute data for the already configured gadget
    useEffect(() => {
        const { sourceId } = gadget?.dataSettings;

        sourceId
        && !activeSource?.id
        && !activeSource.loaded
        && dispatch(changeActiveSourceId(sourceId));
    }, []);

    // Upload attribute data if it's not loaded yet
    useEffect(() => {
        const { id, loaded } = activeSource;

        id && !loaded && dispatch(getActiveSource(id));
    }, [ activeSource ]);

    const changeHandler = (data: any, settingId: string) => {
        const timestamp = '' + Date.now();
        let { title } = gadget;

        if (settingId === 'sourceId') {
            dispatch(changeActiveSourceId(data?.value || null));

            const dataSettings: IDataSettings = {
                metadataQuery: null,
                dateFilter: { from: null, to: null },
                customDateColumn: null,
                facts: [],
                groupBy: null,
                secondGroupBy: null,
                customColorSet: null,
                forcedToShow: null,
                dataName: null,
                dataType: data?.type ? data.type : null,
                factName: null,
                factTypes: null,
                function: null,
                groupByName: null,
                groupByType: null,
                secondGroupByName: null,
                secondGroupByType: null,
                selectedGroup: null,
                selectedSuperGroup: null,
                state: null,
                timestamp,
            };

            if (data?.value) {
                dataSettings.sourceId = data.value;
                title = data.label;
            }

            setTempSettings({
                dataSettings,
                contentSettings: { timestamp },
                title,
            });
        }

        if (settingId === 'facts') {
            const selected = data
                ? Array.isArray(data)
                    ? data
                    : [ data ]
                : [];

            const selectedLabels = selected.map((selectData: any) => selectData.label);

            setTempSettings({
                dataSettings: {
                    facts: selected.map(({ id }: any) => id),
                    factTypes: selected.map(({ type }: any) => type),
                    factName: selectedLabels.length === 1 ? selectedLabels[0] : null,
                    timestamp,
                },
                title: `${activeSource.title} : ${selectedLabels.join(', ')}`,
            });
        }

        if (settingId === 'function') {
            const isPercentageSettingEnable = ConfigureGadgetModalService.checkIsPercentageSettingEnable({
                chartType: gadget.contentSettings.chartType,
                func: data?.value,
            });
            const relativeMode = isPercentageSettingEnable
                ? gadget.contentSettings.relativeMode
                : false;

            setTempSettings({
                contentSettings: {
                    function: data?.value || null,
                    relativeMode,
                    timestamp,
                },
            });
        }

        if (settingId === 'chartType') {
            setTempSettings({
                contentSettings: {
                    chartType: data,
                    relativeMode: data === 'generic_pie_chart',
                    annotateScore: data === 'generic_pie_chart',
                    timestamp,
                },
            });
        }

        if (settingId === 'groupBy') {
            setTempSettings({
                dataSettings: {
                    groupBy: data?.id || null,
                    groupByName: data?.label || null,
                    groupByType: data?.type || null,
                    timestamp,
                },
            });
        }

        if (settingId === 'secondGroupBy') {
            setTempSettings({
                dataSettings: {
                    secondGroupBy: data?.id || null,
                    secondGroupByName: data?.label || null,
                    secondGroupByType: data?.type || null,
                    timestamp,
                },
            });
        }
    };

    useEffect(() => {
        useSettings([
            ...getSettings(activeSource, gadget, dashboard, t),
        ]);
    }, [ gadget.dataSettings, activeSource, gadget.contentSettings, dashboard ]);

    const isValidHandler = () => {
        const { dataSettings } = gadget;
        let isValidState = true;
        const selectedForCompare: any = {};

        // existed data in gadget 'edit' modal
        settings.forEach((setting: any) => selectedForCompare[setting.id] = setting.selected[0]);
        selectedForCompare.factTypes = dataSettings.factTypes;
        selectedForCompare.isMulti = dataSettings.factTypes && dataSettings.factTypes.length > 1;

        // iterate by 'every' for loop breaking, whenever callback function return falsy 'isValidState'
        SettingsRules.every((rule: any) => {
            const isMeetConditionOfRule = rule.condition === 'any'
                || inspectEqualityCondition(rule[INSPECTION_TYPES.CONDITION], selectedForCompare);

            if (isMeetConditionOfRule) {
                isValidState = inspectEquality(rule.restriction, selectedForCompare);
            }

            return isValidState;
        });

        return isValidState;
    };

    return {
        settings,
        isValid: isValidHandler(),
        activeSourceLoading: activeSource.loading,
        changeHandler,
    };
};
