import React, { Fragment } from 'react';

export const RenderCondition = ({
    condition: { question, expression, matching },
    index,
    conditionsLength,
    logicalOperation,
}) => {
    const condition = <p className="combined-condition__padding">
        { question }
        <span className="combined-condition__item"> { expression } </span>
        { matching }
    </p>;

    const operation = index > 0
        ? <span className="condition__operation condition__red"> { logicalOperation } </span>
        : '';

    return (
        conditionsLength > 1
            ? <Fragment>
                { operation }
                <span className="condition__red">(</span> { condition } <span className="condition__red">)</span>
            </Fragment>
            : <Fragment> { condition } </Fragment>
    );
};
