import React from 'react';
import { VocSelect, ComponentOption, ComponentValue } from '/components';

export const RenderSmsSenders = ({
    fields,
    options,
    formatter,
    onChange,
}) => {
    return (
        <ul className={ 'sms-senders__list' }>
            {
                fields.map((member, index) => {
                    const fieldValue = fields.get(index);
                    const [ value, name ] = fieldValue.split('[::]');
                    const senderOptions = options.find(el => el.label === name);
                    const newOptions = senderOptions?.sms_senders.map(props =>
                        ({
                            ...props,
                            label: props.value,
                        }));

                    return (
                        senderOptions
                            ? <li className={ 'sms-senders__list-item' } key={ index }>
                                <div className={ 'settings-field__input-label' }>{ name }</div>

                                <VocSelect
                                    id={ member }
                                    name={ name }
                                    onChange={ onChange }
                                    options={ newOptions }
                                    value={ formatter(newOptions, value) }
                                    isMulti={ false }
                                    components={{
                                        Option: ComponentOption,
                                        ValueContainer: ComponentValue,
                                    }}
                                />

                            </li>
                            : null
                    );
                }) }
        </ul>
    );
};
