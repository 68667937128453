import { createSelector } from 'reselect';
import { delimiterVariantsSelector } from '/modules/selectors';
import { isLoadedPermissionSelector } from '/scenes/VocFeedback/scenes/Export/modules/export.selector';

const formatArray = (array, text = '') => array.map(el => ({
    value: el,
    label: `${el.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}${text}`,
}));

const exporterMapData = {
    delimiterMap: delimiterVariantsSelector(),
    columnHeadersMap: [ 'question_text', 'question_label', 'external_identifier' ],
    fileTypeMap: [ 'xls', 'csv' ],
    statusMap: [
        {
            value: 'created',
            label: 'Created',
        },
        {
            value: 'started',
            label: 'Started',
        },
        {
            value: 'terminated',
            label: 'Terminated',
        },
        {
            value: 'completed',
            label: 'Completed',
        },
        {
            value: 'expired',
            label: 'Expired',
        },
        {
            value: 'extracted',
            label: 'Extracted',
        },
        {
            value: 'invited',
            label: 'Invited',
        },
        {
            value: 'aborted',
            label: 'Partial',
        },
    ],
    filterQuestionnairesMap: [ 'response', 'creation', 'start' ],
    exportTypeMap: [ 'default', 'custom', 'blacklist' ],
};
const exporterDynamicFormMapData = {
    settings_title: [ 'Title', 'Type', 'Column' ],
    conditions_title: [ 'Question external id', 'Condition', 'Value' ],
    column_type: [
        {
            value: 'system',
            label: 'System value',
        },
        {
            value: 'data_value',
            label: 'Import data value',
        },
        {
            value: 'response_by_ext_id',
            label: 'Response by question external identifier',
        },
        {
            value: 'predefined',
            label: 'Predefined value',
        },
        {
            value: 'response_by_id',
            label: 'Response by question ID',
        },
    ],
    content_values: [
        {
            value: 'questionnaire_id',
            label: 'Questionnaire ID',
        },
        {
            value: 'customer_id',
            label: 'Customer ID',
        },
        {
            value: 'survey_id',
            label: 'Survey ID',
        },
        {
            value: 'survey_name',
            label: 'Survey name',
        },
        {
            value: 'creation',
            label: 'Creation time',
        },
        {
            value: 'invite_time',
            label: 'Invitation time',
        },
        {
            value: 'start_time',
            label: 'Start time',
        },
        {
            value: 'end_time',
            label: 'End time',
        },
        {
            value: 'state',
            label: 'State',
        },
        {
            value: 'address',
            label: 'Invitation address',
        },
        {
            value: 'language',
            label: 'Language',
        },
        {
            value: 'invite_channel',
            label: 'Invitation channel',
        },
        {
            value: 'response_channel',
            label: 'Response channel',
        },
        {
            value: 'questionnaire_link',
            label: 'Questionnaire link',
        },
        {
            value: 'questionnaire_hash',
            label: 'Questionnaire hash',
        },
    ],
    conditions_condition: [ '<', '<=', '>', '=', '>=', '!=', 'like%', '%like%' ],
};

const _getResultsList = state => state.VocFeedback.export.exporters;
const _getSettingsTitle = () => exporterDynamicFormMapData.settings_title;
const _getSettingsContentValues = () => exporterDynamicFormMapData.content_values;
const _getSettingsColumnType = () => exporterDynamicFormMapData.column_type;
const _getConditionsTitle = () => exporterDynamicFormMapData.conditions_title;
const _getConditionsColumnCondition = () => exporterDynamicFormMapData.conditions_condition;

export const dataExportSelector = state => _getResultsList(state).exporter;

export const vocFeedbackUnitsList = state => state.VocFeedback.common.unitsList.results.map(unit => ({
    value: unit.id.toString(),
    label: unit.name,
}));

export const ExporterModalDataSelector = createSelector([ vocFeedbackUnitsList ], unitsList => ({
    unitsList,
    ...exporterMapData,
    columnHeadersMap: formatArray(exporterMapData.columnHeadersMap),
    filterQuestionnairesMap: formatArray(exporterMapData.filterQuestionnairesMap, ' Date'),
    exportTypeMap: formatArray(exporterMapData.exportTypeMap),
    fileTypeMap: exporterMapData.fileTypeMap.map(el => ({ value: el, label: el.toUpperCase() })),
}));

export const exportersSelector = state => _getResultsList(state).results;

export const tableSettingsSelector = createSelector(
    [ _getResultsList, isLoadedPermissionSelector ],
    (results, isLoadedPermission) => {
        const { sort = '', page, limit } = results.filters;

        return {
            dataTotalSize: results.count,
            page: page,
            sizePerPage: limit,
            loading: results.count === 0 && isLoadedPermission
                ? isLoadedPermission
                : results.loading,
            sortName: sort.replace('-', ''),
            sortOrder: sort.includes('-') ? 'asc' : 'desc',
        };
    });

export const LangModalSelector = createSelector([ _getResultsList ], results => ({
    langResults: results.langResults.map(lang => ({
        value: lang.iso_6391_code,
        label: lang.name,
    })),
    loadingLanguage: results.loadingLanguage,
}));

export const filtersSelector = state => _getResultsList(state).filters;

export const ExporterSettingsSelector = createSelector([ _getSettingsTitle, _getSettingsContentValues, _getSettingsColumnType ],
    (settingsTitle, contentValues, columnType) => {
        return {
            titles: settingsTitle,
            emptyForm: 'Exporter settings values are required for custom type',
            btnName: 'Add settings',
            fields: [
                {
                    type: 'input',
                    name: 'title',
                    placeholder: 'Title',
                },
                {
                    type: 'select',
                    placeholder: 'Choose type',
                    affect: {
                        to: 2,
                        whenId: 'system',
                        how: 'select',
                        else: 'input',
                        placeholder: 'Choose column',
                        elseSetting: {
                            placeholder: 'Column',
                        },
                        settings: [
                            ...contentValues,
                        ],
                    },
                    settings: [
                        ...columnType,
                    ],
                    name: 'type',
                },
                {
                    type: 'input',
                    placeholder: 'Column',
                    name: 'content',
                },
                {
                    type: 'button',
                    name: 'remove',
                },
            ],
            typeFieldsForm: [ 'name', 'type', 'content' ],
        };
    });

export const ExporterConditionsSelector = createSelector([ _getConditionsTitle, _getConditionsColumnCondition ],
    (conditionsTitle, conditionsCondition) => {
        const conditions = conditionsCondition.map(el => ({ value: el, label: el }));

        return {
            titles: conditionsTitle,
            emptyForm: '',
            btnName: 'Add conditions',
            fields: [
                {
                    type: 'input',
                    name: 'title',
                    placeholder: 'Id',
                },
                {
                    type: 'select',
                    placeholder: 'Choose conditions',
                    settings: [
                        ...conditions,
                    ],
                    name: 'condition',
                },
                {
                    type: 'input',
                    name: 'content',
                    placeholder: 'Value',
                },
                {
                    type: 'button',
                    name: 'remove',
                },
            ],
            typeFieldsForm: [ 'condition', 'ext_id', 'value' ],
        };
    });
