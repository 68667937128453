import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResizeObserver } from '/services';
import { ChartAxis } from '/visual/scenes/Dashboard/components/Gadget/components';
import { IBubbleChart, bubbleData } from '/visual/scenes/Dashboard/components/Gadget/models';
import { charts } from '/visual/scenes/Dashboard/components/Gadget/contstants';
import { GadgetService } from '/visual/scenes/Dashboard/components';
import { BubbleChartService } from '../services';

import styles from '../style.module.scss';

const {
    marginTop,
    yLeftLabelOffset,
    marginTopWithTitles,
    rightOffset,
} = charts.nps_bubble_chart;

export const BubbleChartD3 = ({
    bubbles,
    showScore,
    longLabel,
    percentage,
    axisLabels,
    highlightedLabel,
    gadgetId,
    gadgetFunction,
    chartType,
    toolTipRef,
    onApplyLabelModal,
    setColorPickerData,
}: IBubbleChart) => {
    const { t } = useTranslation();
    const [ chartInstance, setChartInstance ] = useState<BubbleChartService | null>(null);
    const [ xAxisSize, setXAxisSize ] = useState<number | null>(null);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const wrapperRef = useRef<HTMLHeadingElement | null>(null);
    const dimensions = useResizeObserver(wrapperRef);
    const {
        showYLabels,
        showXLabels,
        internalWidth,
        internalHeight,
        boundedWidth,
        boundedHeight,
    } = GadgetService.getChartSizes({
        wrapperRef: wrapperRef.current,
        isNpsChart: false,
        chartType,
        xAxisSize,
        showScore,
    });

    const drawChart = (bubbles: bubbleData[]) => {
        if (chartInstance && chartInstance.isInitialized && boundedHeight > 0 && boundedWidth > 0) {
            const [ yDomainMin, yDomainMax ] = chartInstance.getDomain({ array: bubbles, key: 'score' });
            const [ xDomainMin, xDomainMax ] = chartInstance.getDomain({ array: bubbles, key: 'value' });
            const yTickFormat = chartInstance.getYAxisTickFormat(chartInstance.maxYValue);
            const xTickFormat = chartInstance.getXAxisTickFormat(chartInstance.maxXValue);

            chartInstance
                .preConfig({ xAxisSize: xAxisSize })
                .drawContainer({
                    svgWidth: internalWidth,
                    svgHeight: internalHeight,
                    chartWidth: boundedWidth,
                    chartHeight: boundedHeight,
                    transformValue: `translate(${showYLabels ? yLeftLabelOffset : yLeftLabelOffset + rightOffset / 2}px, ${showScore ? marginTopWithTitles : marginTop}px)`,
                })
                .drawYAxis({
                    domainMin: yDomainMin,
                    domainMax: yDomainMax,
                    // from top of the cord system (chart height) to origin (0)
                    rangeFrom: boundedHeight,
                    rangeTo: 0,
                    tickFormat: yTickFormat,
                })
                .drawBubbleXAxis({
                    domainMin: xDomainMin,
                    domainMax: xDomainMax,
                    rangeFrom: 0,
                    rangeTo: boundedWidth,
                    additionalSettings: [{ prop: 'style', value: [ 'transform', `translateY(${boundedHeight}px)` ] }],
                    tickFormat: xTickFormat,
                })
                .drawBubbles({ bubbles, longLabel });
        }
    };

    useEffect(() => {
        if (svgRef.current && toolTipRef.current) {
            const chartInstance = new BubbleChartService({
                chartType: chartType,
                svgRef: svgRef.current as SVGSVGElement,
                toolTipRef: toolTipRef.current,
                gadgetFunction: gadgetFunction,
                gadgetId: gadgetId,
                percentage: percentage,
                withVerticalGrid: true,
                withHorizontalGrid: true,
                showScore,
                t,
                setXAxisSize: setXAxisSize,
                setColorPickerData: setColorPickerData,
            });

            setChartInstance(chartInstance);
        }

        return () => {
            if (chartInstance) {
                chartInstance.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (bubbles && bubbles.length > 0 && dimensions !== null) {
            drawChart(bubbles);
        }
    }, [ bubbles, dimensions, xAxisSize ]);

    useEffect(() => {
        if (chartInstance && chartInstance.isInitialized) {
            chartInstance.setHighlightedBubble(highlightedLabel);
        }
    }, [ highlightedLabel ]);

    return (
        <div
            ref={ wrapperRef }
            className={ styles.bubbleChartWrapper }
        >
            <ChartAxis
                axisLabels={ axisLabels }
                axisSize={{ width: boundedWidth, height: boundedHeight }}
                gadgetId={ gadgetId }
                chartSettings={{
                    marginTop,
                    showYLabels: showYLabels,
                    showXLabels: showXLabels,
                    xOffsetForNps: rightOffset,
                }}
                onApplyLabelModalData={ onApplyLabelModal }
            >
                <svg ref={ svgRef } />
            </ChartAxis>
        </div>
    );
};
