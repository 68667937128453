import { useRef, useEffect } from 'react';

function createRootElement(id: string) {
    const rootContainer = document.createElement('div');

    rootContainer.setAttribute('id', id);
    return rootContainer;
}

function addRootElement(rootElem: Element) {
    document.body.insertBefore(
        rootElem,
        // @ts-ignore
        document.body.lastElementChild.nextElementSibling,
    );
}

export function usePortal(id: string) {
    const rootElemRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        // Look for existing target dom element to append to
        const existingParent = document.querySelector(`#${id}`);
        // Parent is either a new root or the existing dom element
        const parentElem = existingParent || createRootElement(id);

        // If there is no existing DOM element, add a new one.
        if (!existingParent) {
            addRootElement(parentElem);
        }

        // Add the detached element to the parent
        rootElemRef.current && parentElem.appendChild(rootElemRef.current);

        return function removeElement() {
            rootElemRef.current?.remove();
            if (!parentElem.childElementCount) {
                parentElem.remove();
            }
        };
    }, [ id ]);

    function getRootElem() {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div');
        }
        return rootElemRef.current;
    }

    return getRootElem();
}
