import { CALL_API } from 'store/middleware/api';

export const GET_QUERY_COLUMN_BY_ID_REQUEST = 'GET_QUERY_COLUMN_BY_ID_REQUEST';
export const GET_QUERY_COLUMN_BY_ID_SUCCESS = 'GET_QUERY_COLUMN_BY_ID_SUCCESS';
export const GET_QUERY_COLUMN_BY_ID_ERROR = 'GET_QUERY_COLUMN_BY_ID_ERROR';

export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_ERROR = 'GET_TAGS_ERROR';

export const CLEANUP_VOC_MINE = 'GET_QUERY_LIST_ERROR';

function getInitialState() {
    return {
        languages: {
            count: 0,
            next: null,
            loading: false,
            previous: null,
            results: [],
        },
        submittingRights: false,
        queryById: {
            loading: false,
            columnIds: null,
            data: {},
        },
        queryTags: {
            data: [],
            loading: false,
        },
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_QUERY_COLUMN_BY_ID_REQUEST: {
            return {
                ...state,
                queryById: {
                    ...getInitialState().queryById,
                    loading: true,
                },
            };
        }

        case GET_QUERY_COLUMN_BY_ID_SUCCESS: {
            return {
                ...state,
                queryById: {
                    loading: false,
                    data: {
                        ...action.response,
                    },
                },
            };
        }

        case GET_QUERY_COLUMN_BY_ID_ERROR: {
            return {
                ...state,
                queryById: {
                    ...getInitialState().queryById,
                },
            };
        }

        case CLEANUP_VOC_MINE: {
            return getInitialState();
        }

        case GET_TAGS_REQUEST: {
            return {
                ...state,
                queryTags: {
                    data: [],
                    loading: true,
                },
            };
        }

        case GET_TAGS_SUCCESS:
            return {
                ...state,
                queryTags: {
                    data: [ ...action.response ],
                    loading: false,
                },
            };

        case GET_TAGS_ERROR: {
            return {
                ...state,
                queryTags: {
                    data: [],
                    loading: false,
                },
            };
        }

        default:
            return state;
    }
}

export function cleanup() {
    return {
        type: CLEANUP_VOC_MINE,
    };
}

export function getQueryColumnById(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_QUERY_COLUMN_BY_ID_REQUEST, GET_QUERY_COLUMN_BY_ID_SUCCESS, GET_QUERY_COLUMN_BY_ID_ERROR ],
            unique: true,
        },
    };
}

export function getTags() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/tags`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_TAGS_REQUEST, GET_TAGS_SUCCESS, GET_TAGS_ERROR ],
            unique: true,
        },
    };
}
