import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Button, Label } from 'reactstrap';
import { VocModal, FormFieldItem, Preloader } from '/components';
import { LayoutEditor } from '../LayoutEditor';
import PropTypes from 'prop-types';
import './LayoutModal.scss';
import { HelperService } from '/services/';

export class LayoutModal extends Component {
    state = this.getInitialState();

    getInitialState() {
        return {
            loading: false,
            pending: false,
            name: '',
            id: null,
            template: `<!DOCTYPE html>
<html>
<head></head>
<body></body>
</html>`,
        };
    }

    componentDidUpdate(prevProps){
        if (this.props.layoutId && this.props.layoutId !== prevProps.layoutId) {
            this.setState({
                loading: true,
            });
            this.props.getLayoutById(this.props.layoutId).promise
                .then(layout => {
                    this.setState({
                        loading: false,
                        name: layout.name,
                        template: layout.value,
                        id: layout.id,
                    });
                });
        }
    }

    @autobind
    onClosed() {
        this.setState({ ...this.getInitialState() });
    }

    @autobind
    onTitleChanged(event) {
        this.setState({
            name: event.target.value,
        });
    }

    @autobind
    onTemplateChanged(val) {
        this.setState({
            template: val,
        });
    }

    @autobind
    getModalBody() {
        return (
            <div>
                <FormFieldItem
                    label={ 'Title' }
                    isRequired
                    type={ 'text' }
                    isHorizontal={ false }
                    value={ this.state.name }
                    handleChange={ this.onTitleChanged }
                />
                <Label>
                    Edit
                    <span className='star'> * </span>
                </Label>
                <LayoutEditor
                    value={ this.state.template }
                    onChange={ this.onTemplateChanged }
                    assets={ this.props.assets }
                    getAssets={ this.props.getAssets }
                    uploadAsset={ this.props.uploadAsset }
                />
            </div>
        );
    }

    @autobind
    onSubmit() {
        const model = {
            id: this.state.id,
            name: this.state.name,
            value: this.state.template,
        };

        this.setState({
            pending: true,
        });

        this.props.applyLayoutChanges(HelperService.removeNull(model))
            .then(this.props.close);
    }

    @autobind
    getFooter() {
        const { permission: { isPermitEdit }, close } = this.props;

        return (
            <div>
                <Button onClick={ close } color={ 'white' } outline>
                    Cancel
                </Button>
                <Button
                    disabled={
                        this.state.pending
                        || (!this.state.name || !this.state.template)
                        || !isPermitEdit
                    }
                    onClick={ () => isPermitEdit && this.onSubmit() }
                    color="primary"
                >
                    Save
                </Button>
            </div>
        );
    }

    render() {
        const { show, close } = this.props;

        return (
            <VocModal
                toggle={ close }
                onClosed={ this.onClosed }
                modalClassName={ 'modal-scrollable layout-modal' }
                header={ 'Save Layout' }
                isOpen={ show }
                size={ 'lg' }
                footer={ this.getFooter() }
                zIndex={ 1030 }
            >
                {
                    this.state.loading
                        ? <div className={ 'layouts-modal__preloader' }>
                            <Preloader/>
                        </div>
                        : this.getModalBody()
                }
            </VocModal>
        );
    }
}

LayoutModal.propTypes = {
    layoutId: PropTypes.number,
    close: PropTypes.func,
    show: PropTypes.bool,
    getLayoutById: PropTypes.func,
    assets: PropTypes.shape({
        loading: PropTypes.bool,
        files: PropTypes.array,
    }),
    getAssets: PropTypes.func,
    uploadAsset: PropTypes.func,
    applyLayoutChanges: PropTypes.func,
};
