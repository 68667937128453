import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';

export const GET_ALL_USER_REQUEST = 'GET_ALL_USER_REQUEST';
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS';
export const GET_ALL_USER_ERROR = 'GET_ALL_USER_ERROR';

export const GET_ALL_GROUPS_REQUEST = 'GET_ALL_GROUPS_REQUEST';
export const GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';
export const GET_ALL_GROUPS_ERROR = 'GET_ALL_GROUPS_ERROR';

export const GET_MY_USER_DATA_REQUEST = 'GET_MY_USER_DATA_REQUEST';
export const GET_MY_USER_DATA_SUCCESS = 'GET_MY_USER_DATA_SUCCESS';
export const GET_MY_USER_DATA_ERROR = 'GET_MY_USER_DATA_ERROR';

export const GET_SHARED_RIGHTS_REQUEST = 'GET_SHARED_RIGHTS_REQUEST';
export const GET_SHARED_RIGHTS_SUCCESS = 'GET_SHARED_RIGHTS_SUCCESS';
export const GET_SHARED_RIGHTS_ERROR = 'GET_SHARED_RIGHTS_ERROR';

export const SAVE_RIGHTS_REQUEST = 'SAVE_RIGHTS_REQUEST';
export const SAVE_RIGHTS_SUCCESS = 'SAVE_RIGHTS_SUCCESS';
export const SAVE_RIGHTS_ERROR = 'SAVE_RIGHTS_ERROR';

function getInitialState() {
    return {
        submittingRights: false,
        allUser: {
            loading: false,
            userList: [],
        },
        allGroups: {
            loading: false,
            groupList: [],
        },
        currentUser: {
            loading: false,
            currentUserData: {},
        },
        shares: {
            loaded: false,
            id: null,
            user: {},
            users: [],
        },
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_ALL_USER_REQUEST:
            return {
                ...state,
                allUser: {
                    ...state.allUser,
                    loading: true,
                },
            };

        case GET_ALL_USER_SUCCESS:
            return {
                ...state,
                allUser: {
                    userList: [ ...action.response ],
                    loading: false,
                },
            };

        case GET_ALL_USER_ERROR:
            return {
                ...state,
                allUser: {
                    ...state.allUser,
                    loading: false,
                },
            };

        case GET_ALL_GROUPS_REQUEST:
            return {
                ...state,
                allGroups: {
                    ...state.allGroups,
                    loading: true,
                },
            };

        case GET_ALL_GROUPS_SUCCESS:
            return {
                ...state,
                allGroups: {
                    groupList: [ ...action.response ],
                    loading: false,
                },
            };

        case GET_ALL_GROUPS_ERROR:
            return {
                ...state,
                allGroups: {
                    ...state.allGroups,
                    loading: false,
                },
            };

        case GET_MY_USER_DATA_REQUEST: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    loading: true,
                },
            };
        }

        case GET_MY_USER_DATA_SUCCESS: {
            return {
                ...state,
                currentUser: {
                    currentUserData: { ...action.response },
                    loading: false,
                },
            };
        }

        case GET_MY_USER_DATA_ERROR: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    loading: false,
                },
            };
        }

        case GET_SHARED_RIGHTS_REQUEST:
        case GET_SHARED_RIGHTS_ERROR: {
            return {
                ...state,
                shares: {
                    ...getInitialState().shares,
                },
            };
        }

        case GET_SHARED_RIGHTS_SUCCESS: {
            return {
                ...state,
                shares: {
                    loaded: true,
                    id: action.response.id,
                    user: action.response.user ? action.response.user : {},
                    users: [
                        ...action.response.shares,
                    ],
                },
            };
        }

        case SAVE_RIGHTS_REQUEST: {
            return {
                ...state,
                submittingRights: true,
            };
        }

        case SAVE_RIGHTS_SUCCESS: {
            return {
                ...state,
                submittingRights: false,
            };
        }

        case SAVE_RIGHTS_ERROR: {
            return {
                ...state,
                submittingRights: false,
            };
        }

        default:
            return state;
    }
}

export function getCurrentUser(body) {
    return {
        [CALL_API]: {
            endpoint: `/api/users/me`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_MY_USER_DATA_REQUEST, GET_MY_USER_DATA_SUCCESS, GET_MY_USER_DATA_ERROR ],
            body,
        },
    };
}

export function getAllUser(body) {
    return {
        [CALL_API]: {
            endpoint: `/api/users/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_ALL_USER_REQUEST, GET_ALL_USER_SUCCESS, GET_ALL_USER_ERROR ],
            body,
        },
    };
}

export function getAllGroups(body) {
    return {
        [CALL_API]: {
            endpoint: `/api/groups/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_ALL_GROUPS_REQUEST, GET_ALL_GROUPS_SUCCESS, GET_ALL_GROUPS_ERROR ],
            body,
        },
    };
}

export function getSharedRights(id, type) {
    let endpoint;

    if([ 'query', 'dictionary' ].includes(type)) {
        endpoint = `/public_api/mine/${type}/${ id }`;
    } else if( type === 'visual' ) {
        endpoint = `/public_api/visual/dashboards/v2/${ id }`;
    } else if(type === 'topic-set') {
        endpoint = `/public_api/voc-ai/${type}/${ id }`;
    } else if( type === 'systemUpload' ) {
        endpoint = `/public_api/store/system-upload/${ id }`;
    } else {
        endpoint = `/public_api/store/${type}/${ id }`;
    }

    return {
        [CALL_API]: {
            endpoint,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SHARED_RIGHTS_REQUEST, GET_SHARED_RIGHTS_SUCCESS, GET_SHARED_RIGHTS_ERROR ],
            unique: true,
        },
    };
}

export function saveStoreRights({ queryId, shares }) {
    return dispatch => {
        return dispatch(saveRights(shares, `/public_api/store/share/${ queryId }`));
    };
}

export function saveMineRights({ queryId, shares }) {
    return dispatch => {
        return dispatch(saveRights(shares, `/public_api/mine/share/${ queryId }`));
    };
}

export function saveVisualRights({ queryId, shares }) {
    const { t } = getI18n();

    return dispatch => {
        return dispatch(saveRights(
            shares,
            `/public_api/visual/dashboards/v2/${queryId}/share`,
            t('messages.securitySettingsUpdated'),
        ));
    };
}

export function saveDictionaryRights({ queryId, shares }) {
    return dispatch => {
        return dispatch(saveRights(shares, `/public_api/mine/share/dictionary/${ queryId }`));
    };
}

export function saveTopicSetRights({ queryId, shares }) {
    return dispatch => {
        return dispatch(saveRights(shares, `/public_api/voc-ai/topic-set/share/${ queryId }`));
    };
}

export function saveRights(shares, endpoint, success) {
    const { t } = getI18n();
    const defaultSuccessMessage = t('messages.newRightsSaved');

    return {
        [CALL_API]: {
            endpoint,
            method: 'POST',
            contentType: 'application/json',
            types: [ SAVE_RIGHTS_REQUEST, SAVE_RIGHTS_SUCCESS, SAVE_RIGHTS_ERROR ],
            body: { shares },
            logger: true,
            unique: true,
            loggerMessages: {
                success: success || defaultSuccessMessage,
                error: t('messages.somethingWentWrong'),
            },
        },
    };
}
