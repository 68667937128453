import React, { useEffect, useRef, useState } from 'react';
import { useResizeObserver } from '/services';

import { RadarChartService } from './RadarChartService';
import styles from './style.module.scss';

export const RadarChartD3 = ({
    data,
    highlightedLabel,
    gadgetId,
    gadgetFunction,
    chartType,
    toolTipRef,
    setColorPickerData,
}: any) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const wrapperRef = useRef<HTMLHeadingElement | null>(null);
    const dimensions = useResizeObserver(wrapperRef);
    const [ chartInstance, setChartInstance ] = useState<RadarChartService | null>(null);

    useEffect(() => {
        return () => {
            if (chartInstance) {
                chartInstance.remove();
            }
        };
    }, []);

    useEffect(() => {
        if(data && dimensions && svgRef.current && toolTipRef.current) {
            const chartInstance = new RadarChartService({
                chartType: chartType,
                svgRef: svgRef.current as SVGSVGElement,
                toolTipRef: toolTipRef.current,
                gadgetFunction: gadgetFunction,
                gadgetId: gadgetId,
                setColorPickerData: setColorPickerData,
                wrapperRef: wrapperRef.current as HTMLHeadingElement,
                data: data.data,
                maxValue: data.maxValue,
                allAxis: data.allAxis,
            });

            setChartInstance(chartInstance);
        }
    }, [ data, dimensions ]);

    useEffect(() => {
        if (chartInstance && chartInstance.isInitialized) {
            chartInstance.setHighlightedSlice(highlightedLabel);
        }
    }, [ highlightedLabel ]);

    return (
        <div className={ styles.radarChart } ref={ wrapperRef }>
            <svg ref={ svgRef } />
        </div>
    );
};
