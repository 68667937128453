import React from 'react';
export * from './SettingControlGroupValue.scss';
import DeleteCross from '/images/ic_delete.png';

export const SettingControlGroupValue = ({ value, deleteEmail, checked, invalid }) => {
    function onDelete() {
        deleteEmail(value);
    }

    return (
        <div className={ `setting-email-badge ${invalid ? 'invalid' : ''}` }>
            <span>{ value }</span>
            <button
                className={ 'setting-email-badge_button' }
                onClick={ onDelete }
                disabled={ !checked }
            >
                <img src={ DeleteCross } alt=""/>
            </button>
        </div>
    );
};
