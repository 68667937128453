import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Label } from 'reactstrap';

import { EmptyData, RangeSlider } from '/components';
import { AddTopicsModal, TopicsList, TopicsCategoryModal, LoaderWrapper } from '../';
import { getGraphs } from '/modules/topicSetModules/topicSet.modules';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

import styles from './styles.module.scss';

const initCategoryModalData = {
    open: false,
    initCategory: null,
};

export const TopicsBlock = ({
    isMine,
    topics,
    categories,
    fieldsData,
    isEnabledSave,
    onChange,
    onSelectTopics,
    onSelectTopicsForMove,
    onCreateCategory,
    onEditCategory,
    onDeleteCategory,
    onAddTopics,
    isEnabledValidateColumns,
    loading,
    onSaveTopics,
    topicsForGraphs,
    queryAdditionalDataForGraphs = {},
    disableLoadingRender = false,
    viewType,
    openedCategories,
    setOpenedCategories,
    topicsError,
}) => {
    const dispatch = useDispatch();

    const [ openAddTopicsModal, setOpenAddTopicsModal ] = useState(false);
    const [ categoryModalData, setCategoryModalData ] = useState(initCategoryModalData);

    const onClickValidateLabels = () => {
        const { source, column, language, threshold } = fieldsData;

        dispatch(getGraphs({
            threshold,
            sourceId: source.id,
            indexes: column.map(columnData => columnData.index),
            language: language.value,
            labels: topicsForGraphs,
            ...queryAdditionalDataForGraphs,
        }));
    };

    const onDeleteCategoryHandler = categoryId => {
        dispatch(showConfirm({
            header: 'Warning!',
            content: 'Are you sure to delete this Category?',
            successCallback: () => onDeleteCategory(categoryId),
        }));
    };

    return (
        <>
            {
                loading && !disableLoadingRender
                    ? <div className={ styles.preloader }>
                        <LoaderWrapper />
                    </div>
                    : !!topicsError && <EmptyData errorText={ topicsError }/>
            }
            {
                !!topics?.length && !topicsError && !loading
                && <div className={ styles.topicsBlock }>
                    <TopicsList
                        isMine={ isMine }
                        addTopics={ () => setOpenAddTopicsModal(true) }
                        openCategoryModal={ setCategoryModalData }
                        onDeleteCategory={ onDeleteCategoryHandler }
                        onSelect={ onSelectTopics }
                        onSelectForMove={ onSelectTopicsForMove }
                        topics={ topics }
                        viewType={ viewType }
                        openedCategories={ openedCategories }
                        setOpenedCategories={ setOpenedCategories }
                    />

                    <hr/>

                    <div className={ styles.treshholdBlock }>
                        <Label> Accuracy Threshold </Label>
                        <div className={ styles.thresholdSliderWrapper }>
                            <RangeSlider
                                step={ 0.1 }
                                min={ 0.1 }
                                max={ 0.9 }
                                values={ [ fieldsData.threshold ] }
                                colors={{
                                    rail: '#ccc',
                                    track: [ '#337ab7' ],
                                    hand: [ '#337ab7', '#337ab7' ],
                                }}
                                handleStyles={ [{ height: 20, width: 20 }] }
                                onChange={ data => onChange('threshold', data) }
                            />
                            <span>{ fieldsData.threshold }</span>
                        </div>
                    </div>
                    <div className={ styles.topicsButtons }>
                        <Button
                            outline
                            disabled={ !isEnabledValidateColumns }
                            onClick={ onClickValidateLabels }
                            color='white'
                        >
                            Validate labels
                        </Button>
                        {
                            queryAdditionalDataForGraphs?.scopeType !== 'mine'
                            && <Button
                                disabled={ !isEnabledSave }
                                onClick={ onSaveTopics }
                                color='primary'
                            >
                                    Save
                            </Button>
                        }
                    </div>
                </div>
            }

            <AddTopicsModal
                onClose={ () => setOpenAddTopicsModal(false) }
                modalIsOpen={ openAddTopicsModal }
                onSubmit={ onAddTopics }
            />
            <TopicsCategoryModal
                onClose={ () => setCategoryModalData(initCategoryModalData) }
                modalIsOpen={ categoryModalData.open }
                initialValue={ categoryModalData.initCategory }
                onSubmit={ categoryModalData.initCategory ? onEditCategory : onCreateCategory }
                categories={ categories }
            />
        </>
    );
};
