import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';
import { HelperService } from '/services';

import {
    GET_SEGMENTATORS_LIST_REQUEST,
    GET_SEGMENTATORS_LIST_SUCCESS,
    GET_SEGMENTATORS_LIST_ERROR,
} from '/scenes/VocStore/modules/VocStore.modules';

export const DELETE_SEGMENTATOR_REQUEST = 'DELETE_SEGMENTATOR_REQUEST';
export const DELETE_SEGMENTATOR_SUCCESS = 'DELETE_SEGMENTATOR_SUCCESS';
export const DELETE_SEGMENTATOR_ERROR = 'DELETE_SEGMENTATOR_ERROR';

export const UPDATE_SEGMENTATORS_FILTERS = 'UPDATE_SEGMENTATORS_FILTERS';
export const CLEANUP_SEGMENTATORS_PAGE = 'CLEANUP_SEGMENTATORS_PAGE';

const initialFiltersState = () => ({
    sort_field: 'name',
    sort_order: 'desc',
    search: '',
    limit: 10,
    page: 1,
});

export const getInitialState = () => ({
    loading: false,
    filters: HelperService.getFromStorage(localStorage, 'VocStore.Segmentators.filters') || initialFiltersState(),
    segmentators: {
        data: [],
        count: 0,
    },
    error: null,
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_SEGMENTATORS_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_SEGMENTATORS_LIST_SUCCESS: {
            return {
                ...state,
                segmentators: {
                    count: Number(action.response.count),
                    data: action.response.data,
                },
                loading: false,
            };
        }

        case GET_SEGMENTATORS_LIST_ERROR: {
            return {
                ...state,
                error: action.error,
            };
        }

        case UPDATE_SEGMENTATORS_FILTERS: {
            const filters = {
                ...state.filters,
                ...action.payload,
            };

            HelperService.setInStorage(localStorage, 'VocStore.Segmentators.filters', filters);
            return {
                ...state,
                filters,
            };
        }

        case CLEANUP_SEGMENTATORS_PAGE: {
            return getInitialState();
        }

        default: {
            return state;
        }
    }
}

export function deleteSegmentator(id) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `ENDPOINT_VOC_STORE_DELETE_SEGMENTATOR`,
            types: [ DELETE_SEGMENTATOR_REQUEST, DELETE_SEGMENTATOR_SUCCESS, DELETE_SEGMENTATOR_ERROR ],
            urlParams: { id },
            method: 'DELETE',
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyDeleted', { entity: t('recoder') }),
                error: t('messages.thereWasServerErrorIn', { action: t('deletingRecoder') }),
            },
            body: {},
        },
    };
}

export function updateFilters(payload) {
    return {
        type: UPDATE_SEGMENTATORS_FILTERS,
        payload,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_SEGMENTATORS_PAGE,
    };
}
