import { Dispatch, SetStateAction, RefObject } from 'react';

export type TChildrenProps = {
    setIsDisableHidePopover: Dispatch<SetStateAction<boolean>>,
};

export type TLabelPopoverCoords = {
    x: number,
    y: number,
}

export enum ELabelType {
    AXIS = 'axis',
    LEGEND = 'legend',
    TICK = 'tick',
    PIE_CENTER = 'pie_center',
}

export type TLabelPopoverData = {
    open: boolean,
    target: string | RefObject<HTMLElement>,
    id: string,
    value: string | null,
    type: ELabelType,
    coords?: TLabelPopoverCoords,
};

export type TApplyLabelModalData = Pick<TLabelPopoverData, 'id' | 'value' | 'type'>
