import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { TooltipWrapper } from '/components';

export class RowActions extends Component {
    @autobind
    sendInviteClickHandler() {
        const channels = this.props.surveyGroup.surveys.filter(
            survey => survey.type === 'active' || survey.type === 'paused',
        );

        if (channels.length) {
            this.props.openModal(this.props.surveyGroup, 'surveyGroup', 'openModalInvite');
        } else {
            this.props.showToast({
                text: 'Failed to send invitation - this survey has no channels',
                type: 'error',
            });
        }
    }

    render() {
        const { rights = {}, id, touchpoint, openModal } = this.props;

        return (
            <div className='survey_row-action__toolbar clearfix'>
                <div className={ cx('btn-group pull-right', { 'disabled': !rights.isPermitInvite }) }>
                    <TooltipWrapper value='Send invitation' force>
                        <Button className='survey_row-action__action-button'
                            color={ 'white' }
                            onClick={ () => rights.isPermitInvite && this.sendInviteClickHandler() }
                            outline
                        >
                            <FontAwesomeIcon icon={ 'envelope' }/>
                        </Button>
                    </TooltipWrapper>
                </div>

                <div className='btn-group pull-right'>
                    <TooltipWrapper value='Survey statistics' force>
                        <Link
                            to={ `/feedback/survey/${id}/questions_statistics` }
                            className='survey_row-action__action-button'
                        >
                            <FontAwesomeIcon icon={ 'chart-bar' }/>
                        </Link>
                    </TooltipWrapper>
                </div>

                {
                    !touchpoint
                    && <div className={ cx('btn-group pull-right',
                        { 'disabled': !rights.isPermitCopy },
                    ) }>
                        <TooltipWrapper value='Copy survey' force>
                            <Button
                                className={ 'survey_row-action__action-button btn-copySurvey' }
                                color={ 'white' }
                                onClick={ () => rights.isPermitCopy && openModal(id, 'copySurveyId', 'openModalCopy') }
                                outline
                            >
                                <FontAwesomeIcon icon={ 'copy' }/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                }

                <div className='btn-group pull-right'>
                    <TooltipWrapper value='Edit survey' force>
                        <Link
                            to={ `/feedback/survey/${id}/questions` }
                            className='survey_row-action__action-button'
                        >
                            <FontAwesomeIcon icon={ 'cog' }/>
                        </Link>
                    </TooltipWrapper>
                </div>
            </div>
        );
    }
}

RowActions.propTypes = {
    id: PropTypes.any,
};
RowActions.defaultProps = {};
