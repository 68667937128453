import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VocSelect, FormDynamicInput } from '/components';
import { HelperService } from '/services';

export class FormatSortableItem extends Component {
    renderField(field, idItem, idRow) {
        let fieldComponent;
        const {
            data,
            handleChangeSelect,
            handleChangeField,
            removeRowForm,
        } = this.props.propsData;

        const item = data[idRow][idItem];


        switch (field.type) {
            case 'input':
                fieldComponent = <FormDynamicInput
                    label=""
                    type="text"
                    placeholder={ field.placeholder || '' }
                    row={ idRow }
                    item={ item.type }
                    value={ item.value || '' }
                    handleChange={ handleChangeField }
                />;

                break;

            case 'select':
                const settings = field.settings.map(el => ({
                    ...el,
                    obj: {
                        row: idRow,
                        item: field.name,
                        type: field.type,
                    },
                }));
                const selectValue = HelperService.getSelectedValue(settings, item.value);

                fieldComponent = <VocSelect
                    label=""
                    className={ 'dynamic-form_select' }
                    placeholder={ field.placeholder || '' }
                    isClearable={ false }
                    portal={ true }
                    name={ field.name }
                    value={ selectValue || [] }
                    options={ settings }
                    onChange={ handleChangeSelect }
                />;
                break;

            case 'button':
                fieldComponent = <Button color="primary"
                    onClick={ () => {
                        removeRowForm(idRow);
                    } }>
                    <FontAwesomeIcon icon='trash'/>
                </Button>;
                break;
        }

        return fieldComponent;
    }

    render() {
        const { row, idRow } = this.props.propsData;

        return (

            row.map((field, idItem) => (
                <div key={ idItem } className={ `dynamic-form_col dynamic-col-${idItem}` }>
                    { this.renderField(field, idItem, idRow) }
                </div>
            ),
            )
        );
    }
}
