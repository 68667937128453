import { createSelector } from 'reselect';

import { statesSelector } from '/feedback/modules/VocFeedback.selectors';
import { isLoadedPermissionSelector } from '/scenes/VocFeedback/scenes/Export/modules/export.selector';
import { DownloadFileService } from '/services';

export const exportQueueSelector = state => state.VocFeedback.export.queue;

export const tableDataSelector = createSelector([ exportQueueSelector, statesSelector ], (queues, states) => {
    return queues.results.map(queue => {
        const stateDictionary = states['export'].find(state => state.id === queue.state);
        const stateName = stateDictionary && stateDictionary.name;

        const resultFile = queue.export_config?.id
            ? queue?.result_file_path
            : queue.result_file;
        let model = {};

        //todo remove after delete old exporters
        if(queue.export_config) {
            model = {
                date_from: queue.date_from,
                date_to: queue.date_to,
                export_config__name: queue.export_config?.name,
            };
        }

        const fileName = DownloadFileService
            .getCatalogLastPart({ catalogPath: resultFile });

        return {
            id: queue.id,
            creation: queue.creation,
            date_start: queue.date_start,
            date_end: queue.date_end,
            ...model,
            survey__survey_group__name: queue?.survey?.survey_group?.name,
            survey__channel__name: queue?.survey?.channel,
            exporter__name: queue?.exporter?.name,
            isNewExporter: Boolean(queue.export_config?.id),
            state: stateName,
            link: {
                isExportCleaned: !resultFile && stateName === 'completed',
                isDownloadPossible: fileName && stateName === 'completed' && resultFile,
                isExportFailed: stateName === 'error',
                id: queue.id,
                fileName,
                stateName,
                type: 'resultfile',
            },
        };
    });
});

export const filtersSelector = state => exportQueueSelector(state).filters;

export const tableSettingsSelector = createSelector(
    [ exportQueueSelector, isLoadedPermissionSelector ],
    (queues, isLoadedPermission) => {
        return {
            total: queues.count,
            loading: queues.count === 0 && isLoadedPermission
                ? isLoadedPermission
                : queues.loading,
        };
    });
