import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { combineReducers } from 'redux';
import { connect } from 'react-redux';

import { reducer as uploadsList } from './scenes/UploadsList/modules';
import { reducer as surveysList } from './scenes/SurveyList/modules';
import { reducer as SegmentatorsSlice } from './scenes/Segmentators/modules/segmentators.modules';
import { reducer as rootSlice, cleanup, getSegmentatorsList } from './modules/VocStore.modules';
import { reducer as virtualSources } from './scenes/VirtualSources/modules/VirtualSources.modules';
import { reducer as virtualSource } from './scenes/VirtualSource/modules/VirtualSource.modules';
import { reducer as vocSystemUpload } from './scenes/VocSystemUpload/modules/VocSystemUpload.modules';

import { VocRoutesSelector } from '/modules/selectors';
import { VocStoreRootSelector } from '/modules/segmentatorModules/segmentator.selectors.js';
//todo refactor
import { filtersSelector } from './scenes/Segmentators/modules/segmentators.selectors';
//todo refactor
import { getAllUser, getAllGroups, getCurrentUser } from '/modules';
import { PageRequests } from '/services';

import { UploadData, UploadDataReducer } from './scenes/UploadData/';
import { UploadsList } from './scenes/UploadsList/';
import { SurveysList } from './scenes/SurveyList/';
import { Segmentators } from './scenes/Segmentators/';
import { ExportDateFilterModal } from './scenes/ExportDateFilterModal';
import { TruncateUploadModal } from "./scenes/TruncateUploadModal";
import { VirtualSources } from './scenes/VirtualSources';
import { VirtualSource } from './scenes/VirtualSource';
import { VocSystemUpload } from './scenes/VocSystemUpload';
import { VocCleaupHOC, VocSocketHoc, ApplianceModal } from '/components';

const mapDispatchToProps = {
    cleanup,
    getAllUser,
    getAllGroups,
    getCurrentUser,
    getSegmentatorsList,
};

@connect(state => ({
    VocRoutes: VocRoutesSelector(state),
    VocStoreRoot: VocStoreRootSelector(state),
    filters: filtersSelector(state),
}), mapDispatchToProps)
@VocCleaupHOC
@VocSocketHoc('store')
export class VocStore extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        const { getAllUser, getAllGroups, getCurrentUser } = this.props;

        this.pageRequests.cleanup();
        const requests = [ getAllUser(), getAllGroups(), getCurrentUser() ];

        requests.forEach(request => this.pageRequests.addRequest(request));
    }

    render() {
        const {
            getSegmentatorsList,
            VocStoreRoot,
            filters,
            match: { url },
        } = this.props;

        return (
            <div>
                <Switch>
                    <Route
                        path={ `${ url }/upload-data` }
                        component={ UploadData }
                    />
                    <Route
                        exact
                        path={ `${ url }/uploads-list` }
                        component={ UploadsList }
                    />
                    <Route
                        exact
                        path={ `${ url }/system-upload` }
                        component={ VocSystemUpload }
                    />
                    <Route
                        exact
                        path={ `${ url }/surveys-list` }
                        component={ SurveysList }
                    />
                    <Route
                        exact
                        path={ `${ url }/recoders` }
                        component={ Segmentators }
                    />
                    <Route
                        exact
                        path={ `${ url }/virtual-sources` }
                        component={ VirtualSources }
                    />
                    <Route
                        exact
                        path={ `${ url }/virtual-source/create` }
                        component={ VirtualSource }
                    />
                    <Route
                        exact
                        path={ `${ url }/virtual-source/:sourceId` }
                        component={ VirtualSource }
                    />
                    <Redirect to={ `${ url }/upload-data` } />
                </Switch>
                <ApplianceModal
                    getSegmentatorsList={ getSegmentatorsList }
                    VocStoreRoot={ VocStoreRoot }
                    filters={ filters }
                />
                <ExportDateFilterModal />
                <TruncateUploadModal />
            </div>
        );
    }
}

export const reducer = combineReducers({
    rootSlice,
    uploadData: UploadDataReducer,
    uploadsList,
    surveysList,
    SegmentatorsSlice,
    virtualSources,
    virtualSource,
    vocSystemUpload,
});
