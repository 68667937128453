import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';
import PropTypes from 'prop-types';
import './CustomersTableSelected.scss';

export class CustomersTableSelected extends Component {
    renderSelectedItem(id) {
        return (
            <TooltipWrapper value={ 'Click for remove' } key={ id } force>
                <div className='export-results__selected-item' onClick={ () => this.props.removeFromSelected(id) }>
                    { id }
                    <FontAwesomeIcon
                        className='export-results__selected-icon'
                        icon='times'
                    />
                </div>
            </TooltipWrapper>
        );
    }

    @autobind
    renderSelectedList() {
        return this.props.selectedList.map(selected => this.renderSelectedItem(selected));
    }

    render() {
        return (
            <div className='export-results__selected-wrapper'>
                {
                    this.props.selectedList.length
                        ? this.renderSelectedList()
                        : <p className='export-results__selected-text'>Select items to remove</p>
                }
            </div>
        );
    }
}

CustomersTableSelected.propTypes = {
    selectedList: PropTypes.array,
};
