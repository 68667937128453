import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import { VocModal, SwitchControl } from '/components';
import { confirmDataSelector } from './modules/confirm.selectors';
import { closeConfirm, acceptConfirm } from './modules/confirm.index';

import './Confirm.scss';

const getInitialState = ({ confirmData }) => ({
    shouldOpen: confirmData.show,
    confirmed: false,
    isLabelSwitch: !confirmData.labelSwitch,
});

const mapStateToProps = state => ({
    confirmData: confirmDataSelector(state),
});

const mapDispatchToProps = {
    closeConfirm,
    acceptConfirm,
};

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
export class Confirm extends PureComponent {
    state = getInitialState(this.props);

    componentDidUpdate(prevProps) {
        const { checkDirty: prevCheckDirty, show: prevShow } = prevProps.confirmData;
        const { checkDirty, dirty, show } = this.props.confirmData;

        if (prevShow !== show) {
            // if checkDirty = true, should to check whether the form has been changed before
            if (prevCheckDirty !== checkDirty && checkDirty && !dirty) {
                // confirm routing without showing Confirm modal
                this.onConfirmClicked();
                return this.setState({ shouldOpen: false });
            }

            this.setState({ shouldOpen: show });
        }
    }

    @autobind
    clearState() {
        this.setState(getInitialState(this.props));
    }

    @autobind
    onConfirmClicked() {
        this.props.confirmData.successCallback();
        this.props.acceptConfirm();
    }

    @autobind
    onConfirmClosed() {
        const {
            errorCallback,
        } = this.props.confirmData;

        this.props.closeConfirm();

        if (errorCallback) {
            errorCallback();
        }
    }

    @autobind
    acceptChanges() {
        this.setState({
            confirmed: !this.state.confirmed,
        });
    }

    getFooter() {
        const { isLabelSwitch, confirmed } = this.state;
        const { t } = this.props;
        const isDisabled = !isLabelSwitch && !confirmed;

        return (
            <div>
                <Button
                    outline
                    color='white'
                    onClick={ this.onConfirmClosed }
                >
                    <FontAwesomeIcon icon='times' />
                    &nbsp;
                    { t('cancel') }
                </Button>
                <Button
                    color='primary'
                    disabled={ isDisabled }
                    onClick={ this.onConfirmClicked }
                >
                    <FontAwesomeIcon icon='check' />
                    &nbsp;
                    { t('confirm') }
                </Button>
            </div>
        );
    }

    getSwitchControl() {
        const { content, labelSwitch } = this.props.confirmData;

        return (
            <>
                { content }
                <label className={ 'confirm__switcher' }>
                    <SwitchControl
                        changeHandler={ this.acceptChanges }
                        type={ 'small' }
                        name={ 'confirmQuestionDelete' }
                        value={ this.state.confirmed }/>
                    <span className={ 'confirm__switcher--label' }>{ labelSwitch }</span>
                </label>
            </>
        );
    }

    render() {
        const { confirmData } = this.props;
        const { shouldOpen, isLabelSwitch } = this.state;

        return (
            <VocModal
                toggle={ this.onConfirmClosed }
                modalClassName='modal-scrollable'
                header={ confirmData.header }
                zIndex={ 1080 }
                isOpen={ shouldOpen }
                size={ confirmData.size }
                footer={ this.getFooter() }
                onOpened={ this.clearState }
                onClosed={ this.clearState }
            >
                {
                    isLabelSwitch
                        ? confirmData.content
                        : this.getSwitchControl()
                }
            </VocModal>
        );
    }
}

Confirm.propTypes = {
    confirmData: PropTypes.shape({
        successCallback: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        content: PropTypes.any.isRequired,
        header: PropTypes.any.isRequired,
        labelSwitch: PropTypes.string,
    }),
};

Confirm.defaultProps = {};
