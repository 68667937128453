import React, { memo } from 'react';
import { CollapseBodyHandler } from './CollapseBodyHandler';
import { TagItems } from '/components';
import { MetaQueryService, QueryExecuteService } from '/services';

const forbiddenAttributeTypes = [ 'TEXT', 'DATE', 'CLASSIFICATION' ];

const parseValues = ({ type, value, attr, handlerChange, handlerDelete, source }) => {
    const noValidStatus = () =>
        <div className={ 'collapse-filter-condition__info' }> filter condition is no more valid </div>;

    const classificationOrAttributes = ({ filters }) => {
        const attributes = attr[type];

        const data = filters.map((filter, index) => {
            let actualOption = [];
            const actualAttribute = attributes.find(attribute => attribute.value === filter.id) || {};
            const isForbiddenTypes = () =>
                type === 'classification' || !forbiddenAttributeTypes.includes(actualAttribute.originType);

            if (actualAttribute.skipOptions) {
                actualOption = filter.values;
            } else if (actualAttribute.attrOptions && isForbiddenTypes()) {
                actualOption = QueryExecuteService.getActualOption(filter.values, actualAttribute.attrOptions);
            }

            const conditionValid = actualOption.length || actualAttribute.originType === "scale";

            return (
                <div className={ 'collapse-filter-condition collapse-attributes__place' } key={ index }>
                    {
                        !conditionValid && noValidStatus()
                    }
                    <div className='collapse__attribute' key={ 'name' }>
                        attribute:
                        <div className='collapse-attributes__values'>
                            { conditionValid && <span> { actualAttribute.label } </span> }
                        </div>
                    </div>
                    <div className='collapse__attribute' key={ 'value' }>
                        values:
                        <div className='collapse-attributes__values'>
                            {
                                actualAttribute.originType === "scale"
                                    ? <span> from { filter.from } to { filter.to }  </span>
                                    : actualOption.map((el, inx) =>
                                        <span key={ inx }>
                                            { el }{ inx !== actualOption.length - 1 ? ', ' : "" }
                                        </span>)
                            }
                        </div>
                        <CollapseBodyHandler
                            handlerChange={ conditionValid ? handlerChange : null }
                            handlerDelete={ handlerDelete }
                            index={ index }
                            type={ type }
                            className={ 'collapse__toolbar' }
                        />
                    </div>
                </div>
            );
        });

        return (
            <div className={ 'collapse-filter__body' }>
                { data }
            </div>
        );
    };

    const textOrLemma = ({ values }) => {
        const onChange = (arr, index) => {
            handlerDelete(type, index);
        };

        return (
            <div className={ 'collapse-filter__body' }>
                <div className={ 'collapse-filter-condition collapse-tags__place' }>
                    <TagItems
                        values={ values }
                        onChange={ onChange }
                    />
                    <CollapseBodyHandler
                        handlerChange={ handlerChange }
                        type={ type }
                        className={ 'collapse__toolbar' }
                    />
                </div>
            </div>
        );
    };

    const date = value => {
        const attributes = attr[type];
        const column = attributes.find(attribute => attribute.value === value.column);
        const date = Object.keys(value).map((el, inx) => (
            <div className={ 'collapse__attribute' } key={ inx }>
                { el }: <div className={ 'collapse-attributes__values' }>
                    {
                        column && <span> { el === 'column' ? column.label : value[el] } </span>
                    }
                </div>
            </div>
        ));

        return (
            <div className={ 'collapse-filter__body' }>
                <div className={ 'collapse-filter-condition collapse-attributes__place' }>
                    {
                        !column && noValidStatus()
                    }
                    { date }
                    <CollapseBodyHandler
                        handlerChange={ column ? handlerChange : null }
                        type={ type }
                        className={ 'collapse__toolbar' }
                    />
                </div>
            </div>
        );
    };

    const advanced = ({ filters }) => {
        const queryString = filters.map((query, index) => {
            const queryForClient = MetaQueryService.serverToClientFormatter({ query: JSON.parse(JSON.stringify(query)) });
            const isQueryAbsent = QueryExecuteService.checkIsQueryItemAbsentInSource(queryForClient, source.attributes);

            return (
                <div className={ 'collapse-filter-condition collapse-advanced__place' } key={ index }>
                    {
                        isQueryAbsent && noValidStatus()
                    }
                    <div className="collapse__attribute">
                        query:
                        <div className={ 'collapse-attributes__values' }>
                            {
                                !isQueryAbsent && MetaQueryService.clientQueryToString({
                                    query: queryForClient,
                                    source,
                                })
                            }
                        </div>
                    </div>

                    <CollapseBodyHandler
                        handlerChange={ isQueryAbsent ? null : handlerChange }
                        handlerDelete={ handlerDelete }
                        index={ index }
                        type={ type }
                        className={ 'collapse__toolbar' }
                    />
                </div>
            );
        });

        return (
            <div className={ 'collapse-filter__body' }>
                { queryString }
            </div>
        );
    };

    const parsers = {
        classification: classificationOrAttributes,
        attributes: classificationOrAttributes,
        date: date,
        textToInclude: textOrLemma,
        textToExclude: textOrLemma,
        lemmaToInclude: textOrLemma,
        lemmaToExclude: textOrLemma,
        advanced: advanced,
        default: JSON.stringify(value),
    };

    return parsers[type]
        ? parsers[type](value)
        : parsers.default;
};

export const RenderCollapseBody = memo(({
    initialValues,
    queryAttributes,
    handlerChange,
    handlerDelete,
    source,
    name,
}) => {
    const classification = queryAttributes.filter(attribute => attribute.originType === 'CLASSIFICATION');
    const date = queryAttributes.filter(attribute => attribute.originType === 'DATE' || attribute.originType === "service_captured_at");

    return (
        parseValues({
            type: name,
            value: initialValues,
            attr: {
                classification: classification,
                attributes: queryAttributes,
                date,
            },
            handlerChange,
            handlerDelete,
            source,
        })
    );
});
