import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import {
    SelectAsyncPaginate,
    VocModal,
    VocSelect,
    Preloader,
    SwitchControl,
} from '/components';
import { MultiTopic } from './components';
import { ESourceField, IFormValues, IGetSources, ITopicSetApplianceModal } from './models';
import {
    cleanupTopicSetApplianceModal,
    getTopicSets,
    toggleTopicSetApplianceModal,
    updateTopicSetsFilters,
    updateSourcesFilters,
    getApplianceSources,
} from '/ai/modules/vocAi.modules';
import {
    _getTopicSets,
    _getCreateTopicSetApplianceModalLoading,
    _getCreateTopicSetApplianceModalState,
    _getSelectedSourceColumns,
    _getSourcesList,
} from '/ai/modules/vocAi.selectors';

import styles from './styles.module.scss';

const initialStateFormValues = {
    threshold: 0.0,
    maxCategories: 1,
    minCategories: 0,
    sentiment: false,
    multiTopic: false,
};

export const TopicSetApplianceModal = ({ onSubmit, initFormValues = {}, onClosed }: ITopicSetApplianceModal) => {
    const dispatch = useDispatch();

    const topicsSet = useSelector(_getTopicSets);
    const sourcesList = useSelector(_getSourcesList);
    const modalIsOpen = useSelector(_getCreateTopicSetApplianceModalState);
    const modalLoading = useSelector(_getCreateTopicSetApplianceModalLoading);

    const [ formValues, setFormValues ] = useState<IFormValues>({
        ...initialStateFormValues,
        ...initFormValues,
    });

    const columns = useSelector(state => _getSelectedSourceColumns(state, formValues.source));

    const onClose = () => dispatch(toggleTopicSetApplianceModal());
    const onSelectClose = () => dispatch(cleanupTopicSetApplianceModal());

    const onClosedHandler = () => {
        setFormValues(initialStateFormValues);
        onClosed && onClosed();
    };

    const onChange = (fieldName: string, value: any) => {
        setFormValues({
            ...formValues,
            [fieldName]: value,
        });
    };

    const getSelectDataHandler = (type: string, data: IGetSources) => {
        let filtersModel;

        // formulate filters for the type and reset options list if needed (before new list will be applied)
        const formulateFiltersBasedOnType = (prevSearch?: string) => {
            const isSearchChanged = data.body.search !== prevSearch;

            if (isSearchChanged) {
                // clear all options if 'search' changed
                onSelectClose();
            }

            return {
                ...data.body,
                page: isSearchChanged ? 1 : data.body.page,
            };
        };

        switch (type) {
            case ESourceField.topicsSet:
                filtersModel = formulateFiltersBasedOnType(topicsSet.filters.search);

                dispatch(updateTopicSetsFilters(filtersModel));
                dispatch(getTopicSets(filtersModel));
                break;
            case ESourceField.sourcesList:
                filtersModel = formulateFiltersBasedOnType(sourcesList.filters.search);

                dispatch(updateSourcesFilters(filtersModel));
                dispatch(getApplianceSources(filtersModel));
                break;
        }
    };

    useEffect(() => {
        setFormValues({
            ...initialStateFormValues,
            ...initFormValues,
        });
    }, [ initFormValues ]);

    useEffect(() => {
        !formValues.edit && onChange('column', null);
    }, [ formValues.source ]);

    const getModalBody = () => (
        <div>
            <div className={ styles.fieldWrapper }>
                <label htmlFor='voc_ai_choose_classifier'>
                    Topic Set
                </label>
                <SelectAsyncPaginate
                    isClearable
                    closeMenuOnSelect
                    isDisabled={ formValues.edit }
                    id='voc_ai_choose_classifier'
                    setPage={ (page: { page: number }) => dispatch(updateTopicSetsFilters({ page: page.page })) }
                    placeholder='Choose topic set'
                    total={ topicsSet.totalCount }
                    filters={ topicsSet.filters }
                    onChange={ (data: { label: string, value: string }) => onChange('topicSet', data) }
                    value={ formValues.topicSet }
                    loading={ topicsSet.loading }
                    getSources={ (data: IGetSources) => getSelectDataHandler(ESourceField.topicsSet, data) }
                    options={ topicsSet.data }
                    onClose={ onSelectClose }
                />
            </div>

            <div className={ styles.fieldWrapper }>
                <label htmlFor='voc_ai_choose_source'>
                    Source
                </label>
                <SelectAsyncPaginate
                    isClearable
                    closeMenuOnSelect
                    selectOptionClass={ styles.selectOption }
                    isDisabled={ formValues.edit }
                    id='voc_ai_choose_source'
                    setPage={ (page: { page: number }) => dispatch(updateSourcesFilters({ page: page.page })) }
                    placeholder='Choose source'
                    total={ sourcesList.totalCount }
                    filters={ sourcesList.filters }
                    onChange={ (data: { label: string, value: string }) => onChange('source', data) }
                    value={ formValues.source }
                    loading={ sourcesList.loading }
                    getSources={ (data: IGetSources) => getSelectDataHandler(ESourceField.sourcesList, data) }
                    options={ sourcesList.data }
                    onClose={ onSelectClose }
                />
            </div>

            <div className={ styles.fieldWrapper }>
                <label htmlFor='voc_ai_choose_classifier_column'>
                    Column
                </label>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*@ts-ignore*/}
                <VocSelect
                    isClearable
                    portal
                    id='voc_ai_choose_classifier_column'
                    disabled={ !formValues.source || formValues.edit }
                    onChange={ data => onChange('column', data) }
                    options={ columns }
                    value={ formValues.column }
                />
            </div>

            <div className={ styles.multiTopicWrapper }>
                <label htmlFor='voc_ai_sentiment' className={ styles.multiTopicLabel }>
                    Sentiment
                </label>
                <SwitchControl
                    name='voc_ai_sentiment'
                    changeHandler={ (state: boolean) => onChange('sentiment', state) }
                    value={ formValues.sentiment }
                />
            </div>
            <div className={ styles.multiTopicWrapper }>
                <label htmlFor='voc_ai_multiTopic' className={ styles.multiTopicLabel }>
                    Multi topic
                </label>
                <SwitchControl
                    disabled={ !formValues.topicSet }
                    name='voc_ai_multiTopic'
                    changeHandler={ (state: boolean) => onChange('multiTopic', state) }
                    value={ formValues.multiTopic && !!formValues.topicSet }
                />
            </div>

            {
                formValues.multiTopic
                && formValues.topicSet
                && <MultiTopic
                    formValues={ formValues as IFormValues & Required<Pick<IFormValues, 'topicSet'>> }
                    onChange={ onChange }
                />
            }

        </div>
    );

    const getFooter = () => (
        <div>
            <Button outline onClick={ onClose } color='white'>
                <FontAwesomeIcon icon='times'/>
                <span> Cancel</span>
            </Button>
            <Button
                disabled={ !formValues.column && !formValues.edit }
                onClick={ () => onSubmit(formValues) }
                color='primary'
            >
                <FontAwesomeIcon icon='check'/>
                <span> Save</span>
            </Button>
        </div>
    );

    return (
        <VocModal
            toggle={ onClose }
            onClosed={ onClosedHandler }
            header={ `${formValues.edit ? 'Edit' : 'Create'} Applied Topic Set` }
            modalClassName={ cx('modal-scrollable', styles.modalWrapper) }
            isOpen={ modalIsOpen }
            size='md'
            footer={ getFooter() }
        >
            {
                modalLoading
                    ? <Preloader/>
                    : getModalBody()
            }
        </VocModal>
    );
};
