import React from "react";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { MetaFilterWrapper } from "../../../";
import { dashboardFilterActiveSource } from "/visual/scenes/Dashboard/modules/Dashboard.selectors";
import { MetaQueryService, QueryExecuteService } from "/services";

import styles from './style.module.scss';

interface IMetadataFilterProps {
    metaQuery: any;
    onChange: (data: any) => void;
    setIsValidQuery: (state: boolean) => void;
}

export const MetadataFilter = ({ metaQuery, onChange, setIsValidQuery }: IMetadataFilterProps) => {
    const { t } = useTranslation();
    const activeSource = useSelector(dashboardFilterActiveSource);

    const onChangeMetaFilter = () => {
        const isQueryValid = QueryExecuteService.queryItemsValidation(metaQuery);
        const isEmptyQuery = QueryExecuteService.checkIsEmptyQuery(metaQuery);

        setIsValidQuery(isEmptyQuery || isQueryValid);

        isQueryValid
        && onChange({
            metadataQuery: MetaQueryService.clientToServerFormatter({ query: metaQuery, activeSource }, true),
        });

        isEmptyQuery
        && onChange({
            metadataQuery: null,
        });
    };

    return (
        <div className={ styles.metaDataFilterWrapper }>
            {
                activeSource?.attributes
                && metaQuery?.items
                    ? <MetaFilterWrapper
                        source={ activeSource }
                        metaQuery={ metaQuery }
                        onChange={ onChangeMetaFilter }
                    />
                    : <Label>
                        { t('metadataFilterInfo') }
                    </Label>
            }
        </div>
    );
};
