import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getRunnerUrl } from '/modules';
import { headerUserInfoSelector, runnerUrlSelector } from '/modules/selectors';
import { activeRouteSelector } from './scenes/VocMenu/modules/selectors';
import { HeaderSection } from './scenes/HeaderSection';
import { VocMenu } from './scenes/VocMenu';
import { NotificationsReducer as Notifications } from './scenes/NotificationWidget/modules/';
import { MenuReducer as Menu } from './scenes/VocMenu/modules/';
import { SettingsService } from '/feedback/services';

import Logo from './images/logo_white.svg';

import './Header.scss';


const mapStateToProps = state => ({
    userInfo: headerUserInfoSelector(state),
    activeRoute: activeRouteSelector(state),
    runnerUrl: runnerUrlSelector(state),
});

const mapDispatchToProps = {
    getRunnerUrl,
};

@connect(mapStateToProps, mapDispatchToProps)
export class Header extends PureComponent {
    componentDidMount() {
        this.props.getRunnerUrl()
            .promise.then(({ DIGI_RUNNER_URL }) => {
                SettingsService.injectDigiRunnerUrl(DIGI_RUNNER_URL);
            });
    }

    render() {
        const {
            userInfo: {
                firstName,
                environment,
                logoutUrl,
                ticketSystemUrl,
                hasActAccess,
                ticketSystemUseIframe,
                email,
            },
            runnerUrl,
            activeRoute,
        } = this.props;

        return (
            <>
                <header className={ 'header' }>
                    <Link className='header__logo' to="/">
                        <img alt="Hub logo" src={ Logo }/>
                    </Link>
                    <HeaderSection
                        ticketSystemUseIframe={ ticketSystemUseIframe }
                        ticketSystemUrl={ ticketSystemUrl }
                        hasActAccess={ hasActAccess }
                        environment={ environment }
                        firstName={ firstName }
                        logoutUrl={ logoutUrl }
                        activeRoute={ activeRoute }
                        runnerUrl={ runnerUrl }
                        email={ email }
                    />
                </header>
                <aside>
                    <VocMenu
                        activeRoute={ this.props.activeRoute }
                    />
                </aside>
            </>
        );
    }
}

Header.propTypes = {
    userInfo: PropTypes.shape({
        firstName: PropTypes.string,
        environment: PropTypes.string,
    }),
};

export const HeaderReducer = combineReducers({
    Notifications,
    Menu,
});
