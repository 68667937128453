import React from 'react';
import { VocFormTextField } from '../VocFormTextField';

export const VocFormNumberField = props => {
    const handleChange = value => {
        const val = typeof value === 'string'
            ? parseInt(value.replace(/[^0-9]/ig, ''), 10) || 0
            : value;

        props.input.onChange(val);
    };

    return (
        <VocFormTextField
            { ...props }
            onChange={ handleChange }
        />
    );
};
