import { createSelector } from 'reselect';
import { HelperService } from '/services';
import { isLoadedPermissionSelector } from '/scenes/VocFeedback/scenes/Export/modules/export.selector';

const _getResultsSchedules = state => state.VocFeedback.export.schedules;

export const exportersSchedulesSelector = state => _getResultsSchedules(state).results;

export const filtersSelector = state => _getResultsSchedules(state).filters;


const CONST_SCHEDULE = {
    type: {
        prev_hour: 'Previous hour',
        prev_24h: 'Last 24 hours',
        prev_day: 'Previous day',
        prev_7d: 'Last 7 days',
        prev_week: 'Previous week',
        prev_month: 'Previous month',
        prev_30d: 'Previous 30 days',
        prev_full: 'Full export',
    },
    dayofweek: {
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday',
    },
};

export const exportPeriodSelector = () => {
    {
        const { type } = CONST_SCHEDULE;

        return Object.keys(type).map(Period => {
            return {
                value: Period,
                label: type[Period],
            };
        });
    }
};

export const exportDayOfWeekSelector = () => {
    {
        const { dayofweek } = CONST_SCHEDULE;

        return Object.keys(dayofweek).map(day => {
            return {
                value: day,
                label: dayofweek[day],
            };
        });
    }
};

export const tableSettingsSelector = createSelector(
    [ _getResultsSchedules, isLoadedPermissionSelector ],
    (results, isLoadedPermission) => {
        return {
            total: results.count,
            loading: results.count === 0 && isLoadedPermission
                ? isLoadedPermission
                : results.loading,
        };
    });

export const exportersSchedulesTableDataSelector = createSelector([ exportersSchedulesSelector ],
    results => {
        return results.map(result => {
            const channel = HelperService.deepFind(result, 'survey'),
                type = CONST_SCHEDULE.type[result.type],
                { hour } = result,
                { minute } = result,
                dayofmonth = !result.dayofmonth ? '*' : result.dayofmonth,
                dayofweek = !result.dayofweek ? '*' : CONST_SCHEDULE.dayofweek[result.dayofweek],
                exporterSelect = result.export_config?.id //todo remove after delete old exporters
                    ? result.export_config?.id + ' newExporter'
                    : result.exporter?.id + '';

            return {
                id: result?.id,
                survey__survey_group__name: result?.survey?.survey_group?.name,
                exporter__name: result?.exporter?.name
                    || `${result?.export_config?.name}`,
                isNewExporter: Boolean(result.export_config?.id),
                survey__channel__name: channel.channel,
                type,
                dayofmonth,
                dayofweek,
                hour: `${hour}`.length < 2 ? `0${hour}` : hour,
                minute: `${minute}`.length < 2 ? `0${minute}` : minute,
                control: {
                    parameters: {
                        id: result.id,
                        self: result.self,
                        unit: result.unit.id,
                        creation: result.creation,
                        last_interaction: result.last_interaction,
                    },
                    selected: {
                        surveySelect: '' + result.survey.survey_group.id,
                        exporterSelect,
                        exportConfigSelect: !!result.export_config?.id,
                        periodSelect: result.type,
                        dayOfMonthSelect: result.dayofmonth,
                        channelSelect: '' + channel.id,
                        hourSelect: hour,
                        minuteSelect: minute,
                        dayOfWeekSelect: result.dayofweek,
                    },
                },
            };
        });
    });
