import { useState, useEffect } from 'react';

import { GadgetService } from '../../';
import { IChartData, lineItem, TLabelData, sliderData, IFormatDataLineChart, ISlicedLineChartData } from '../../models';

let onChangeBarColorTimeout: NodeJS.Timeout;

export const useLineChart = (initChartData: IChartData) => {
    const [ slicedChartData, setSlicedChartData ] = useState<ISlicedLineChartData>({
        labels: [],
        lines: [],
        npsData: GadgetService.getDefaultNpsData(),
    });

    const [ sliderConfig, setSliderConfig ] = useState<sliderData>({
        disableSlider: true,
        min: 0,
        max: 1,
        value: [ 0, 1 ],
    });

    const [ formatData, setFormatData ] = useState<IFormatDataLineChart>({
        labels: [],
        lines: [],
        level: 0,
        filterLabels: [],
        records: 0,
        axisLabels: {
            count: null,
            group: null,
            score: null,
        },
        isNpsChart: false,
        npsData: GadgetService.getDefaultNpsData(),
    });

    const getPropsForSlider = () => {
        const isOneTick = formatData.labels.length < 2;

        return {
            disableSlider: isOneTick,
            sliderMax: isOneTick
                ? 1
                : formatData.labels.length - 1,
        };
    };

    const changeSlider = (sliderValues: number[] | number | null) => {
        const { disableSlider, sliderMax } = getPropsForSlider();
        const sliderDataValue = sliderValues || [ 0, sliderMax ];

        const sliceData = (array: any[], sValue: number[] | number | null) => {
            if (sValue && Array.isArray(sValue) && sValue.length > 1) {
                const sortedValues = [ ...sValue ]
                    .sort((fValue: number, sValue: number) => fValue - sValue);

                return array.slice(sortedValues[0], sortedValues[1] + 1);
            }

            return array;
        };

        setSliderConfig({
            disableSlider,
            min: 0,
            max: sliderMax,
            value: sliderDataValue,
        });

        const slicedLabels = sliceData(formatData.labels, sliderValues);
        const slicedLines = formatData.lines.map((line: lineItem) => {
            const absoluteData = sliceData(line.data.absolute, sliderValues);
            const relativeData = sliceData(line.data.relative, sliderValues);

            return {
                ...line,
                data: {
                    absolute: absoluteData,
                    relative: relativeData,
                },
            };
        });

        // slice nps data array
        let npsTempData = formatData.npsData;

        if (formatData.isNpsChart) {
            const npsDataArray = sliceData(formatData.npsData.data, sliderValues);

            npsTempData = {
                ...npsTempData,
                data: npsDataArray,
            };
        }

        setSlicedChartData({ labels: slicedLabels, lines: slicedLines, npsData: npsTempData });
    };

    useEffect(() => {
        const formattedData = GadgetService.formatLineDataHandler({
            chartData: initChartData.chartData,
            gadgetData: initChartData.gadgetData,
        });

        setFormatData(formattedData);
    }, [ initChartData.gadgetData, initChartData.chartData ]);

    useEffect(() => {
        const { sliderMax } = getPropsForSlider();

        changeSlider(
            sliderConfig.max === sliderMax
                ? sliderConfig.value
                : null,
        );
    }, [ formatData ]);

    const changeLabel = (
        id: string,
        type: string,
        value: string | null,
        onChangeAxisLabelCallback: (labelRes: any, isChanged: any) => void,
    ) => {
        const formatLabelId = id.toString().toLowerCase();
        const axisObject = {
            key: formatLabelId,
            type: type,
            value: value,
        };
        const isChanged = initChartData.gadgetData.labels
            .findIndex((labelData: TLabelData) => labelData.key === formatLabelId) > -1;

        onChangeAxisLabelCallback(axisObject, isChanged);
    };

    const changeItemColor = (
        id: string,
        color: string,
        onChangeColorCallback: (data: any, isChanged: boolean) => void,
    ) => {
        const isChanged = GadgetService.getSavedColorIndex(id, initChartData.gadgetData.colors) > -1;
        const colorObject = {
            key: id,
            value: color,
        };

        clearTimeout(onChangeBarColorTimeout);
        onChangeBarColorTimeout = setTimeout(() => {
            onChangeColorCallback(colorObject, isChanged);
        }, 300);
    };

    return {
        gadgetData: initChartData.gadgetData,
        chartData: initChartData.chartData,
        labels: slicedChartData.labels,
        lines: slicedChartData.lines,
        level: formatData.level,
        filterLabels: formatData.filterLabels,
        allLabels: formatData.labels,
        records: formatData.records,
        sliderData: sliderConfig,
        axisLabels: formatData.axisLabels,
        isNpsChart: formatData.isNpsChart,
        npsData: slicedChartData.npsData,
        changeLabel,
        changeItemColor,
        changeSlider,
    };
};

