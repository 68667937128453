import React from 'react';
import { Button } from 'reactstrap';

export const StatisticFiltersButton = ({ apply, disabled }) => (
    <Button
        className={ 'filter-card__footer-button' }
        outline
        color="primary"
        disabled={ disabled }
        onClick={ apply }
    >
        <span>Apply</span>
    </Button>
);
