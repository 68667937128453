import { createSelector } from 'reselect';

import { TLanguage, IPreviewMode, TTheme } from './models';

export const _getPreviewData = (state: { VocFeedback: { preview: IPreviewMode; }; }) => state.VocFeedback.preview;

export const _getPreviewDevices = () => [
    {
        label: 'Iphone X - 375 px × 812 px',
        value: 'iphoneX',
        sizes: {
            width: 375,
            height: 812 - 90, // minus chrome search bar block,
        },
    },
    {
        label: 'iPad Pro (2017) - 834 px × 1112 px',
        value: 'ipadPro2017',
        sizes: {
            width: 834,
            height: 1112,
        },
    },
];

export const _getPreviewThemes = createSelector(
    [ _getPreviewData ],
    ({ themes }) => themes.map((theme: TTheme) => ({
        label: theme.name,
        value: theme.id,
    })));

export const _getPreviewLanguages = createSelector(
    [ _getPreviewData ],
    ({ languages }) => languages
        .map((language: TLanguage) => ({
            label: language.language,
            value: language.language,
        })));
