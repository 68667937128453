import React, { useEffect } from 'react';
import { TimeDateGrid } from '/components';
import DocumentTitle from 'react-document-title';
import { Card, CardHeader, CardBody } from 'reactstrap';

export const TimeDateGridContainer = ({
    timeSlotsData: {
        filters,
        results,
        globalResults,
        timeSlotIsRegular,
        count,
        loading,
        isUpdate,
        infiniteLoading,
    },
    unit,
    activeChannel,
    channelList,
    manageTimeFrameSpecial,
    updateFiltersActions,
    dirtyStatusConfirm,
    cleanup,
    showConfirm,
    pageTitle,
    pageRequests,
    requestTrigger,
    manageRegularDataHandler,
    deleteSurveyTimeFrameRegular,
    setSurveySettingOn,
    surveySettingOn,
    isSurveySetting,
    isActive = true,
    isSurveyTimeSlot = false,
    survey_id,
    rights,
    infoText = 'Please activate or select an activated channel first. Time slots work with WEB, SMS or IVR channels.',
}) => {
    useEffect(() => {
        return () => {
            pageRequests.cleanup();
            cleanup();
        };
    }, []);

    useEffect(() => {
        if (unit && isActive) {
            pageRequests.cleanup();
            updateFilters({
                ...filters,
                page: 1,
                show_past_events: '0',
            });
        }
    }, [ timeSlotIsRegular, unit, activeChannel ]);

    const showChecker = timeSlotIsRegular && isSurveyTimeSlot;

    const updateFilters = filter => {
        const model = {
            ...filters,
            ...filter,
        };

        updateFiltersActions(model);
        requestTrigger(model, activeChannel);
    };

    const manageSpecialDataHandler = timeSlots => {
        const createObj = [];
        const updateObj = [];
        const deleteObj = results
            .filter(el => !timeSlots.find(({ applying_date }) => applying_date === el.applying_date))
            .map(({ id }) => id);

        timeSlots.forEach(slot => {
            slot.id
                ? results.find(({ applying_date, time_slots }) => {
                    const compare = applying_date === slot.applying_date;

                    compare
                    && JSON.stringify(time_slots) !== JSON.stringify(slot.time_slots)
                    && updateObj.push(slot);

                    return compare;
                })
                : createObj.push(slot);
        });

        manageTimeFrameSpecial({
            updateObj,
            deleteObj,
            createObj,
            filterModel: {
                ...filters,
                page: 1,
            },
        });
    };

    const defineManageDataHandler = dateGridData =>
        timeSlotIsRegular
            ? manageRegularDataHandler(dateGridData)
            : manageSpecialDataHandler(dateGridData);


    const dataGrid = () => {
        if (showChecker && (!surveySettingOn || !results.length)) {
            return globalResults;
        }
        return results;
    };

    const clearSurveyTimeSlot = () => deleteSurveyTimeFrameRegular(survey_id);

    return (
        <DocumentTitle title={ pageTitle }>
            <Card className={ 'page__wrapper' }>
                <CardHeader className={ 'panel__header panel__header--buttons' }>
                    <span className={ 'panel__header-title' }>Time Slots Setting</span>
                </CardHeader>
                <CardBody>
                    {
                        isActive
                            ? <TimeDateGrid
                                dirtyStatusConfirm={ dirtyStatusConfirm }
                                isSurveySetting={ isSurveySetting }
                                showConfirm={ showConfirm }
                                dateGrid={ dataGrid() }
                                unit={ unit }
                                isRegular={ timeSlotIsRegular }
                                surveySettingOn={ surveySettingOn }
                                setSurveySettingOn={ setSurveySettingOn }
                                updateFilters={ updateFilters }
                                filters={ filters }
                                count={ count }
                                loading={ loading }
                                isUpdate={ isUpdate }
                                infiniteLoading={ infiniteLoading }
                                manageDataHandler={ defineManageDataHandler }
                                channelList={ channelList }
                                activeChannel={ activeChannel }
                                showChecker={ showChecker }
                                clearSurveyTimeSlot={ clearSurveyTimeSlot }
                                rights={ rights }
                            />
                            : <div className={ 'time-date-grid__info-empty' }>
                                {
                                    infoText
                                }
                            </div>
                    }

                </CardBody>
            </Card>
        </DocumentTitle>
    );
};
