import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SelectAsyncPaginateWithFilters } from '/components';
import { IDataSelectProps, IGetSourcesHandler, ISetSelectFiltersHandler, TGetNewFilters } from './models';
import { sourceSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { getSources, setFilters, setPage } from '/visual/scenes/Dashboard/modules/Dashboard.modules';

export const DataSelect = ({ onChange, value, disabled }: IDataSelectProps) => {
    const { t } = useTranslation();
    const { data, loading, count, filters } = useSelector(sourceSelector);
    const [ isAdditionalFilters, setIsAdditionalFilters ] = useState<boolean>(false);

    const radioFilters = [
        {
            name: 'all',
            id: 'filter-all',
            label: t('allData'),
        },
        {
            name: 'survey',
            id: 'filter-survey',
            label: t('surveys'),
        },
        {
            name: 'upload',
            id: 'filter-upload',
            label: t('uploads'),
        },
        {
            name: 'virtual',
            id: 'filter-virtual',
            label: t('virtual'),
        },
    ];

    const surveyFilters = [
        {
            name: 'Active',
            id: 'checkbox-active-surveys',
            label: t('active'),
            color: 'success',
        },
        {
            name: 'Paused',
            id: 'checkbox-paused-surveys',
            label: t('paused'),
            color: 'warning',
        },
        {
            name: 'Stopped',
            id: 'checkbox-stopped-surveys',
            label: t('stopped'),
            color: 'info',
        },
        {
            name: 'Deleted',
            id: 'checkbox-deleted-surveys',
            label: t('deleted'),
            color: 'danger',
        },
    ];

    const dispatch = useDispatch();

    const getNewFilters = ({ newFilters }: TGetNewFilters) => {
        return {
            ...newFilters,
            page: newFilters.search !== filters.search ? 1 : newFilters.page,
        };
    };

    const getSourcesHandler = (filterData: IGetSourcesHandler) => {
        const filtersModel = getNewFilters({ newFilters: filterData.body });

        dispatch(getSources(filtersModel));
    };

    const setSelectFiltersHandler = (data: ISetSelectFiltersHandler) => {
        const filtersModel = getNewFilters({ newFilters: data.filters });

        dispatch(setFilters(filtersModel));
    };

    return (
        <SelectAsyncPaginateWithFilters
            id='DataSelect'
            placeholder={ t('placeholders.select') }
            disabled={ disabled }
            openedAdditionalFilters={ isAdditionalFilters }
            exclude_sources={ [] }
            total={ count }
            filters={ filters }
            radioFilters={ radioFilters }
            surveyFilters={ surveyFilters }
            disableOutsideClose={ true }
            value={ value }
            loading={ loading }
            options={ data }
            onChange={ onChange }
            setPage={ ({ page }) => dispatch(setPage(page)) }
            getSources={ getSourcesHandler }
            setSelectFilters={ setSelectFiltersHandler }
            toogleAdditionalFilters={ () => setIsAdditionalFilters(!isAdditionalFilters) }
        />
    );
};
