import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Popover, PopoverHeader, PopoverBody, ListGroup } from 'reactstrap';
import { Preloader } from '/components';
import { CaseAlertConditionItem } from '../CaseAlertConditionItem';

import './CaseAlertsConditionsPopover.scss';

export class CaseAlertsConditionsPopover extends Component {
    @autobind
    renderConditionItems() {
        const {
            conditions,
            openCaseAlertConditionForm,
            deleteCaseAlertCondition,
            caseAlert,
            permission,
        } = this.props;

        return (
            <ListGroup>
                {
                    conditions.results.map((condition, index) =>
                        <CaseAlertConditionItem
                            condition={ condition }
                            caseAlertId={ caseAlert }
                            openCaseAlertConditionForm={ openCaseAlertConditionForm }
                            deleteCaseAlertCondition={ deleteCaseAlertCondition }
                            key={ index }
                            permission={ permission }
                        />,
                    )
                }
            </ListGroup>
        );
    }

    render() {
        const { show, toggle, conditions, target } = this.props;

        return (
            <Popover
                id='case_alerts-structure-popover'
                className='case_alerts-popover shadowed-popover'
                isOpen={ show }
                placement="bottom"
                target={ target }
                toggle={ toggle }
                trigger="legacy"
            >
                <PopoverHeader>
                    <strong>Case Alert Conditions</strong>
                </PopoverHeader>
                <PopoverBody className={ 'statistic-popover__body' }>
                    {
                        conditions.loading ? <Preloader/> : this.renderConditionItems()
                    }
                </PopoverBody>
            </Popover>
        );
    }
}
