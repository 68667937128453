import { createSelector } from 'reselect';
import { VocAIServices } from '/ai/services';

const topicSetApplianceSelector = state => state.VocAi.topicSetAppliance;

export const _getTopicSetAppliance = createSelector([ topicSetApplianceSelector ],
    topicSetAppliance => ({
        ...topicSetAppliance,
        data: topicSetAppliance.data?.map(({
            id, topicSet, source, attributeIndex, threshold, multiTopic, maxCategories, minCategories, sentiment,
        }) => ({
            id,
            threshold,
            topicSet: topicSet.title,
            source: source.title,
            column: source.attributes.find(attribute => attribute.index === attributeIndex).name,
            languages: Object.keys(topicSet.languages).join(', '),
            actions: {
                id,
                multiTopic,
                threshold,
                maxCategories,
                minCategories,
                sentiment,
                topicSet: {
                    ...topicSet,
                    label: topicSet.title,
                    value: topicSet.id,
                    categoriesCount: VocAIServices.getCategoriesCount(topicSet.languages),
                },
                source: {
                    id: source.id,
                    attributes: source.attributes,
                    label: source.title,
                    value: source.id,
                },
                columnIndex: attributeIndex,
            },
        })),
    }));

export const filtersSelector = createSelector([ topicSetApplianceSelector ], ({ filters }) => filters);

export const topicSetsFilters = createSelector([ topicSetApplianceSelector ], ({ topicSet }) => ({
    ...topicSet,
    data: topicSet.data.map(({ title, id }) => ({
        label: title,
        value: id,
    })),
}));

export const _getTopicSetsFilters = createSelector([ topicSetsFilters, filtersSelector ],
    ({ data, loading }, { extraFilters }) => ({
        options: data,
        selected: [ ...extraFilters.topicSet ],
        loading,
    }));

export const sourcesFilters = createSelector([ topicSetApplianceSelector ], ({ source }) => ({
    ...source,
    data: source.data.map(({ title, id }) => ({
        label: title,
        value: id,
    })),
}));

export const _getSourcesFilters = createSelector([ sourcesFilters, filtersSelector ],
    ({ data, loading }, { extraFilters }) => ({
        options: data,
        selected: [ ...extraFilters.source ],
        loading,
    }));

export const languagesFilters = createSelector([ topicSetApplianceSelector ], ({ languages }) => ({
    ...languages,
    data: languages.data.map(language => ({
        label: language,
        value: language,
    })),
}));

export const _getLanguagesFilters = createSelector([ languagesFilters, filtersSelector ],
    ({ data, loading }, { extraFilters }) => ({
        options: data,
        selected: [ ...extraFilters.languages ],
        loading,
    }));

export const columnsFilters = createSelector([ topicSetApplianceSelector ], ({ columns }) => {
    let columnsObj = {};

    columns.data.forEach(source => {
        source.attributes.forEach(attribute => {
            if(attribute.type === 'TEXT' || attribute.type === 'freetext') {
                columnsObj = {
                    ...columnsObj,
                    [attribute.index]: attribute.name,
                };
            }
        });
    });

    return {
        ...columns,
        data: Object.keys(columnsObj).reduce((columnsAcc, currentColumn) => ([
            ...columnsAcc,
            {
                label: columnsObj[currentColumn],
                value: columnsObj[currentColumn],
            },
        ]), []),
    };
});

export const _getColumnsFilters = createSelector([ columnsFilters, filtersSelector ],
    ({ data, loading }, { extraFilters }) => ({
        options: data,
        selected: [ ...extraFilters.columns ],
        loading,
    }));
