import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { ChannelLabel } from '/scenes/VocFeedback/components/ChannelLabel/';
import { SchedulesActionsFormatter } from '../SchedulesActionsFormatter';
import PropTypes from 'prop-types';
import { VocTableV2, TooltipWrapper } from '/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class ExportSchedulesTable extends Component {
    settings = [
        {
            field: 'id',
            title: 'ID',
            style: {
                width: '60px',
            },
            sort: true,
        },
        {
            field: 'survey__survey_group__name',
            title: 'Survey Title',
            formatter: 'conditionTooltip',
            sort: true,
            style: {
                width: '170px',
            },
        },
        {
            field: 'survey__channel__name',
            formatter: this.channelFormatter,
            title: 'Channel',
            sort: true,
            style: {
                width: '100px',
                textAlign: 'center',
            },
        },
        {
            field: 'exporter__name',
            title: 'Exporter',
            formatter: this.nameFormatter,
            sort: true,
            style: {
                width: '130px',
            },
        },
        {
            field: 'type',
            title: 'Export Period',
            sort: true,
            style: {
                width: '130px',
            },
        },
        {
            field: 'dayofmonth',
            title: 'Day of Month',
            style: {
                width: '130px',
                textAlign: 'center',
            },
            sort: true,
        },
        {
            field: 'dayofweek',
            title: 'Day of Week',
            style: {
                width: '130px',
                textAlign: 'center',
            },
            sort: true,
        },
        {
            field: 'hour',
            sort: true,
            title: 'Hour',
            style: {
                width: '68px',
            },
        },
        {
            field: 'minute',
            sort: true,
            title: 'Minute',
            style: {
                width: '85px',
            },
        },
        {
            field: 'control',
            title: '',
            formatter: this.controlsFormatter,
            style: {
                width: '70px',
                textAlign: 'center',
            },
        },
    ];

    getTableOptions() {
        return {
            requestTrigger: this.props.requestTrigger,
            haveSearch: true,
            updateFilters: this.props.updateFilters,
        };
    }

    @autobind
    channelFormatter(cell) {
        return (
            <ChannelLabel title={ cell }/>
        );
    }

    @autobind
    nameFormatter(cell, obj) {
        return <>
            {
                !obj.isNewExporter
                && <TooltipWrapper value='Legacy exporter type' force>
                    <span className={ 'export-list_old-exporter-icon' }>
                        <FontAwesomeIcon icon={ 'wrench' }/>
                    </span>
                </TooltipWrapper>
            }
            <span>{ cell }</span>
        </>;
    }

    @autobind
    controlsFormatter(cell) {
        return <SchedulesActionsFormatter
            { ...cell }
            openModalManage={ this.props.openModalManage }
            openModalDelete={ this.props.openModalDelete }
        />;
    }

    render() {
        const {
            total,
            loading,
        } = this.props.tableState;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                data={ this.props.data }
                options={ this.getTableOptions() }
                filters={ this.props.filters }
                total={ total }
                loading={ loading }
            />
        );
    }
}

ExportSchedulesTable.propTypes = {
    requestTrigger: PropTypes.func,
    updateFilters: PropTypes.func,
    data: PropTypes.array,
    tableState: PropTypes.shape({
        page: PropTypes.number,
        loading: PropTypes.bool,
    }),
    openModal: PropTypes.func,
};
