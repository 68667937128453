import React, { Fragment, useEffect, useState } from 'react';
import { VocSelect } from '/components';
import { Input, Col, Row, Label } from 'reactstrap';
import './ExportText.scss';

const delimiterOptions = [
    { label: ' , (comma delimiter)', value: ',' },
    { label: ' ; (semicolon delimiter)', value: ';' },
    { label: ' . (dot delimiter)', value: '.' },
    { label: ' | (vertical bar delimiter)', value: '|' },
    { label: ' ↹ (tab delimiter)', value: '\t' },
];

const textType = [
    { id: 'text', htmlId: 'query_text_columns_text_type', name: 'Query text columns', checked: true, disabled: true },
    { id: 'lemmata', htmlId: 'lemmas_text_type', name: 'Lemmas', checked: true },
    { id: 'classification', htmlId: 'classification_results_text_type', name: 'Classification results', checked: true },
    { id: 'other_text', htmlId: 'other_text_columns_text_type', name: 'Other text columns', checked: false },
    { id: 'attributes', htmlId: 'attributes_text_type', name: 'Attributes', checked: true },
    { id: 'date', htmlId: 'date_columns_text_type', name: 'Date columns', checked: true },
];

const exportTypeOptions = [
    { value: 'csv', label: 'CSV' },
    { value: 'xlsx', label: 'XLSX' },
];

export const ExportText = ({
    setFormData,
    formData,
    disabledExportXlsx,
}) => {
    const [ delimiter, setDelimiter ] = useState(delimiterOptions[0]);
    const [ exportTypeValue, setExportTypeValue ] = useState(exportTypeOptions[0]);
    const [ checkboxGroup, setCheckboxGroup ] = useState(textType);

    const getCheckedFieldId = checkbox => checkbox
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.id);

    useEffect(() => {
        const fields = getCheckedFieldId(checkboxGroup);

        setFormData({ delimiter: delimiter.value, fields, outputFileExtension: exportTypeValue.value });

        return () => {
            setCheckboxGroup(textType);
            setDelimiter(delimiterOptions[0]);
            setFormData(null);
            setExportTypeValue(exportTypeOptions[0]);
        };
    }, []);

    const changeTextExport = (id, { checked }) => {
        const newCheckboxGroup = checkboxGroup.map(checkbox => checkbox.id === id ? { ...checkbox, checked } : checkbox);
        const fields = getCheckedFieldId(newCheckboxGroup);

        setCheckboxGroup(newCheckboxGroup);
        setFormData({ ...formData, fields });
    };

    const onChangeDelimiter = option => {
        setDelimiter(option);
        setFormData({ ...formData, delimiter: option.value });
    };

    const changeExportType = option => {
        setExportTypeValue(option);
        setFormData({ ...formData, outputFileExtension: option.value });
    };

    return (
        <Fragment>
            <Row className={ 'export-results-checkbox__wrapper' }>
                {
                    checkboxGroup.map((text, inx) => (
                        <Col key={ inx } md={ 12 }>
                            <Label id={ text.htmlId }>
                                <Input
                                    type={ 'checkbox' }
                                    className='export-results__checkbox'
                                    disabled={ text.disabled }
                                    checked={ text.checked }
                                    onChange={ event => !text.disabled && changeTextExport(text.id, event.target) }
                                />
                                <span> { text.name } </span>
                            </Label>
                        </Col>
                    ))
                }
            </Row>

            <VocSelect
                name="outputFileExtension"
                label={ 'Export format' }
                value={ exportTypeValue }
                options={ exportTypeOptions }
                disabled={ disabledExportXlsx }
                placeholder={ 'Select format' }
                onChange={ option => !disabledExportXlsx && changeExportType(option) }
                portal
            />

            {
                exportTypeValue.value === 'csv' && <VocSelect
                    name=""
                    label={ 'CSV delimiter' }
                    value={ delimiter }
                    options={ delimiterOptions }
                    placeholder={ 'Select category' }
                    onChange={ onChangeDelimiter }
                    isCreatable
                    portal
                />
            }

        </Fragment>
    );
};
