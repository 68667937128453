import { useEffect, useState } from 'react';

import { HelperService } from '/services';
import { GadgetService } from '../../';

import { IContentSettings } from '/visual/models';
import {
    IChartData, TLabelData, filterLabel,
    IFormatDataBarChart, ISlicedBarChartData,
    barData, sliderData, filterRequiredWithScore,
} from '../../models';

let onChangeBarColorTimeout: NodeJS.Timeout;

export const useBarChart = (initChartData: IChartData) => {
    const [ slicedChartData, setSlicedChartData ] = useState<ISlicedBarChartData>({
        groups: [],
        npsData: GadgetService.getDefaultNpsData(),
    });

    const [ sliderConfig, setSliderConfig ] = useState<sliderData>({
        disableSlider: true,
        min: 0,
        max: 1,
        value: [ 0, 1 ],
    });

    const [ formatData, setFormatData ] = useState<IFormatDataBarChart>({
        groups: [],
        filterLabels: [],
        records: 0,
        axisLabels: {
            count: null,
            group: null,
            score: null,
        },
        allowSort: false,
        isNpsChart: false,
        npsData: GadgetService.getDefaultNpsData(),
    });

    const getPropsForSlider = () => {
        const isOneBar = formatData.groups.length < 2;

        return {
            disableSlider: isOneBar,
            sliderMax: isOneBar
                ? 1
                : formatData.groups.length - 1,
        };
    };

    const changeSlider = (sliderValues: number[] | number | null) => {
        const { disableSlider, sliderMax } = getPropsForSlider();

        const sliderDataValue = sliderValues ? sliderValues : [ 0, sliderMax ];

        const sliceData = (array: barData[], sValue: number[] | number | null) => {
            if (sValue && Array.isArray(sValue) && sValue.length > 1) {
                return array.slice(sValue[0], sValue[1] + 1);
            }

            return array;
        };

        setSliderConfig({
            disableSlider,
            min: 0,
            max: sliderMax,
            value: sliderDataValue,
        });

        const slicedGroups = sliceData(formatData.groups, sliderValues);
        // slice nps data array
        let npsTempData = formatData.npsData;

        if (formatData.isNpsChart) {
            const npsDataArray = sliceData(formatData.npsData.data, sliderValues);

            npsTempData = {
                ...npsTempData,
                data: npsDataArray,
            };
        }

        setSlicedChartData({ groups: slicedGroups, npsData: npsTempData });
    };

    useEffect(() => {
        const formattedData = GadgetService.formatBarDataHandler({
            chartData: initChartData.chartData,
            gadgetData: initChartData.gadgetData,
        });

        setFormatData(formattedData);
    }, [ initChartData.gadgetData, initChartData.chartData ]);

    useEffect(() => {
        const { sliderMax } = getPropsForSlider();

        changeSlider(
            sliderConfig.max === sliderMax
                ? sliderConfig.value
                : null,
        );
    }, [ formatData ]);

    const changeItemColor = (
        id: string,
        color: string,
        onChangeColorCallback: (data: any, isChanged: boolean) => void,
    ) => {
        const isChanged = GadgetService.getSavedColorIndex(id, initChartData.gadgetData.colors) > -1;
        const colorObject = {
            key: id,
            value: color,
        };

        clearTimeout(onChangeBarColorTimeout);
        onChangeBarColorTimeout = setTimeout(() => {
            onChangeColorCallback(colorObject, isChanged);
        }, 300);
    };

    const changeLabel = (
        id: string,
        type: string,
        value: string | null,
        onChangeAxisLabelCallback: (labelRes: any, isChanged: any) => void,
    ) => {
        const formatLabelId = id.toString().toLowerCase();
        const axisObject = {
            key: formatLabelId,
            type,
            value,
        };
        const isChanged = initChartData.gadgetData.labels
            .findIndex((labelData: TLabelData) => labelData.key === formatLabelId && labelData.type === type) > -1;

        onChangeAxisLabelCallback(axisObject, isChanged);
    };

    const changeBarsSort = (direction: string, onChangeBarsSortCallback: (data: IContentSettings) => void ) => {
        const initData = JSON.parse(JSON.stringify(initChartData.gadgetData));
        const isAscSort = direction === 'asc';
        const { filterLabels, isNpsChart } = formatData;
        const legends = isNpsChart ? filterLabels.filter((i: filterLabel) => i.id !== 'npsScore') : filterLabels;

        initData.contentSettings.order = legends
            .sort((a: filterRequiredWithScore, b: filterRequiredWithScore) => isAscSort
                ? b.score - a.score
                : a.score - b.score,
            )
            .map((item: filterLabel) => HelperService.checkNotEmpty(item.id) ? item.id : 'No value');

        onChangeBarsSortCallback(initData.contentSettings);
    };

    return {
        gadgetData: initChartData.gadgetData,
        groups: slicedChartData.groups,
        filterLabels: formatData.filterLabels,
        allGroups: formatData.groups,
        records: formatData.records,
        sliderData: sliderConfig,
        axisLabels: formatData.axisLabels,
        allowSort: formatData.allowSort,
        isNpsChart: formatData.isNpsChart,
        npsData: slicedChartData.npsData,
        changeLabel,
        changeBarsSort,
        changeItemColor,
        changeSlider,
    };
};
