import React from "react";
import { TooltipWrapper } from '/components';

import styles from "./style.module.scss";

export const TagsFormatter = ({ tags }) => {
    const tagsForShow = tags.slice(0, 3);
    const tagsForCount = tags.slice(3).join(', ');

    const widthOffset = tags.length > 3 ? 13 : 0;
    const widthStyle = `calc((100% / ${tagsForShow.length}) - ${widthOffset}px)`;

    return (
        <div className={ styles.tagsWrapper }>
            {
                tagsForShow.map((tag, i) => (
                    <div key={ i } className={ styles.tagWrapper } style={{ maxWidth: widthStyle }}>
                        <TooltipWrapper value={ tag } >
                            <span className='table-tag__label'>
                                { tag }
                            </span>
                        </TooltipWrapper>
                    </div>
                ))
            }

            {
                tags.length > 3 && (
                    <div className={ styles.tagWrapper }>
                        <TooltipWrapper value={ tagsForCount } force>
                            <span className='table-tag__label'>
                            +{ tags.length - 3 }
                            </span>
                        </TooltipWrapper>
                    </div>
                )
            }
        </div>
    );
};
