import { createSelector } from 'reselect';
import { HelperService } from '/services';

export const _getVocMineCommon = state => state.VocMine.common;
export const getQueryTagsSelector = state => _getVocMineCommon(state).queryTags;

const filterTextColumn = attributes => attributes.filter(attr => attr.type === 'TEXT' || attr.type === 'freetext');

export const getQueryTextColumnSelector = createSelector(
    [ _getVocMineCommon ],
    ({ queryById: { loading, data } }) => {
        return data.source
            ? {
                loading,
                columnIds: data.columnIds,
                options: HelperService.mapObjToSelect(
                    filterTextColumn(data.source.attributes),
                    'id',
                    'name',
                ),
            }
            : {
                loading,
                columnIds: data.columnIds,
                options: [],
            };
    });

export const getEditedQueryContextSelector = createSelector(
    [ _getVocMineCommon ],
    ({ queryById }) => queryById.data?.context,
);
