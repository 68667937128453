import React, { Component, Fragment } from 'react';

import {
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    ListGroup,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import {
    TouchPointList,
    TouchPointItemControl,
    ProjectListItemControl,
    ProjectsListItem,
} from './components';

import {
    VocModal,
    Preloader,
} from '/components';
import './ManageProjectsModal.scss';

const getInitialState = () => ({
    selectedProject: null,
    selectedTP: null,
    projectTouchPoints: [],
    newProject: '',
    loading: false,
});

export class ManageProjectsModal extends Component {
    state = getInitialState();

    @autobind
    initManageProjectsModal() {
        const setSelectedProject = () => {
            return this.props.projectList.find(pr => pr.id === this.props.activeProject);
        };

        if (this.props.projectList) {
            this.setState({
                selectedProject: setSelectedProject(),
                projectTouchPoints: this.getProjectTouchPoints(),
            });
        }
    }

    @autobind
    cleanupModal() {
        this.setState(getInitialState());
    }

    @autobind
    getProjectTouchPoints(project = this.props.activeProject) {
        return this.props.touchpoints
            .filter(touchpoint => touchpoint.project === project && touchpoint.id !== 1);
    }

    @autobind
    handleChangeTPInput(selectedOption) {
        this.setState({
            selectedTP: {
                ...this.state.selectedTP,
                project: parseInt(selectedOption ? selectedOption.value : ''),
            },
        });
    }

    @autobind
    addProject() {
        this.setState({ loading: true });

        this.props
            .addProject({ name: this.state.newProject })
            .then(this.onActionDone)
            .then(() => {
                this.setState({ newProject: '' });
            })
            .catch(this.onActionDone);
    }

    @autobind
    onSortStart() {
        document.body.style.cursor = 'move';
    }

    @autobind
    onSortEnd(e) {
        document.body.style.cursor = 'auto';
        const targetIndex = e.newIndex + (e.oldIndex > e.newIndex ? -1 : 0);
        const current = this.state.projectTouchPoints[e.oldIndex];
        const target = this.state.projectTouchPoints[targetIndex];
        const targetId = e.newIndex === 0 ? null : target.id;

        if (e.oldIndex !== e.newIndex) {
            const params = {
                current: current.id,
                target: targetId,
            };

            this.setState({
                loading: true,
            });
            this.props.reorderTouchPoint(params)
                .then(this.reorderDone);
        }
    }

    @autobind
    reorderDone() {
        this.state.selectedProject
        && this.setState({
            loading: false,
            projectTouchPoints: this.getProjectTouchPoints(this.state.selectedProject.id),
        });
    }

    @autobind
    onActionDone() {
        this.setState({
            loading: false,
            selectedTP: null,
            projectTouchPoints: this.state.selectedProject && this.getProjectTouchPoints(this.state.selectedProject.id),
        });
    }

    @autobind
    updateProject() {
        const { id, name } = this.state.selectedProject;

        this.setState({ loading: true });
        this.props
            .updateProject(id, { name })
            .then(this.onActionDone)
            .catch(this.onActionDone);
    }

    @autobind
    deleteProject(project) {
        this.setState({ loading: true, selectedProject: null });
        this.props
            .deleteProject(project.id)
            .then(this.onActionDone);
    }

    @autobind
    deleteTouchPoint(touchPoint) {
        this.setState({ loading: true, selectedTP: null });
        this.props
            .deleteTouchPoint(touchPoint.id)
            .then(this.onActionDone);
    }

    @autobind
    editTouchPoint() {
        const { id, project } = this.state.selectedTP;

        this.setState({ loading: true });
        this.props
            .editTouchPoint({ id, project })
            .then(this.onActionDone);
    }

    @autobind
    changeProjectFieldHandler(event) {
        const name = event.target.value;

        if (this.state.selectedProject) {
            this.setState({
                selectedProject: {
                    ...this.state.selectedProject,
                    name,
                },
            });
        } else {
            this.setState({
                newProject: name,
            });
        }
    }

    @autobind
    mapProjectsToSelect() {
        return this.props.projectList.map(pr => ({
            value: String(pr.id),
            label: pr.name,
        }));
    }

    @autobind
    setCreateProjectState() {
        this.setState({
            selectedProject: null,
            selectedTP: null,
            projectTouchPoints: [],
            newProject: '',
        });
    }

    getModalFooter() {
        if (this.state.loading) {
            return null;
        }

        return (
            <Row>
                <Col md={ 6 }>
                    <ProjectListItemControl
                        selectedProject={ this.state.selectedProject }
                        newProject={ this.state.newProject }
                        updateProject={ this.updateProject }
                        changeProjectFieldHandler={ this.changeProjectFieldHandler }
                        addProject={ this.addProject }
                    />
                </Col>
                <Col md={ 6 }>
                    { this.state.selectedProject && this.state.selectedTP
                    && <TouchPointItemControl
                        selectedTP={ this.state.selectedTP }
                        handleChangeTPInput={ this.handleChangeTPInput }
                        editTouchPoint={ this.editTouchPoint }
                        options={ this.mapProjectsToSelect() }
                    /> }
                </Col>
            </Row>
        );
    }

    @autobind
    mapProjectsList() {
        return this.props.projectList.map((project, i) => {
            return (
                <ProjectsListItem
                    key={ i }
                    project={ project }
                    onSelectProject={ this.onSelectProject }
                    selectedProject={ this.state.selectedProject }
                    deleteProject={ this.deleteProject }
                />
            );
        });
    }

    @autobind
    onSelectProject(selectedProject) {
        this.setState({
            selectedProject: { ...selectedProject },
            projectTouchPoints: this.getProjectTouchPoints(selectedProject.id),
            selectedTP: null,
        });
    }

    @autobind
    onSelectTouchPoint(selectedTP) {
        this.setState({ selectedTP: { ...selectedTP } });
    }

    renderCreateJourneyButton() {
        return this.state.selectedProject
            ? (
                <Button className={ 'panel__header-button' }
                    onClick={ this.setCreateProjectState }
                    color={ 'white' }
                    outline>
                    <FontAwesomeIcon icon='plus'/> Add Customer Journey
                </Button>
            )
            : null;
    }

    renderJorneysHeader() {
        return (
            <Fragment>
                <span className={ 'panel__header-title' }>
                    <strong>Customer Journeys</strong>
                </span>
                <div className={ 'panel__header-buttons' }>
                    { this.renderCreateJourneyButton() }
                </div>
            </Fragment>
        );
    }

    getModalBody() {
        return (
            this.state.loading
                ? <div className="manage-modal-preloader-wrapper">
                    <Preloader/>
                </div>
                : <div className='manage-projects-modal'>
                    <Row>
                        <Col md={ 6 }>
                            <Card color={ 'default' }>
                                <CardHeader className={ 'panel__header panel__header--buttons' }>
                                    { this.renderJorneysHeader() }
                                </CardHeader>
                                <CardBody className={ 'manage_projects__panel-body' }>
                                    <ListGroup tag="ul">
                                        { this.mapProjectsList() }
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={ 6 }>
                            {
                                this.state.selectedProject
                                    ? <TouchPointList
                                        selectedProjectName={ this.state.selectedProject.name }
                                        selectedTouchPoint={ this.state.selectedTP }
                                        touchpoints={ this.state.projectTouchPoints }
                                        onSelectTouchPoint={ this.onSelectTouchPoint }
                                        deleteTouchPoint={ this.deleteTouchPoint }
                                        onSortStart={ this.onSortStart }
                                        onSortEnd={ this.onSortEnd }
                                    /> : null
                            }
                        </Col>
                    </Row>
                </div>
        );
    }

    render() {
        return (
            <VocModal
                modalClassName='manage-projects-modal-wrapper'
                isOpen={ this.props.show }
                toggle={ this.props.close }
                header='Manage Customer Journeys'
                onOpened={ this.initManageProjectsModal }
                onClosed={ this.cleanupModal }
                footer={ this.getModalFooter() }>
                { this.getModalBody() }
            </VocModal>
        );
    }
}

ManageProjectsModal.propTypes = {
    projectList: PropTypes.array,
    addProject: PropTypes.func,
    updateProject: PropTypes.func,
    deleteProject: PropTypes.func,
    deleteTouchPoint: PropTypes.func,
    editTouchPoint: PropTypes.func,
    show: PropTypes.bool,
    close: PropTypes.func,
    activeProject: PropTypes.any,
    reorderTouchPoint: PropTypes.func,
};
