import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { VocModal } from '/components';
import { GadgetService, Tabs } from '/visual/scenes/Dashboard/components';
import { _getDashboardData, getGadgetData } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { applyGadgetDataChange } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { IGadgetData, ITab } from '/visual/models';
import { MetaQueryService, QueryExecuteService } from '/services';

import { DataSettings, PeriodSettings, AdvancedSettings, MetadataFilter, ChartSpecificSettings } from './components';
import { IConfigureGadgetModal } from './models';

import styles from './style.module.scss';

export const ConfigureGadgetModal = ({
    show,
    toggle,
    cleanupConfigureModal,
}: IConfigureGadgetModal) => {
    const { t } = useTranslation();
    const gadget = useSelector(getGadgetData);
    const dashboard = useSelector(_getDashboardData);
    const [ activeTab, setActiveTab ] = useState<ITab['id']>(0);
    const [ gadgetState, setGadgetState ] = useState<IGadgetData | null>(gadget);
    const [ isDataSettingsValid, setIsDataSettingsValid ] = useState(false);
    const [ isValidQuery, setIsValidQuery ] = useState(true);
    const [ metaQuery, setMetaQuery ] = useState(MetaQueryService.emptyQuery());

    const dispatch = useDispatch();

    useEffect(() => {
        if(show && gadget) {
            setGadgetState(gadget);

            const { dataSettings } = gadget;
            const metaQueryData = dataSettings?.metadataQuery;

            const initMetaQuery = metaQueryData
                ? MetaQueryService.serverToClientFormatter(
                    {
                        query: JSON.parse(JSON.stringify(metaQueryData)),
                    },
                )
                : MetaQueryService.emptyQuery();

            setMetaQuery(initMetaQuery);

            const isQueryValid = QueryExecuteService.queryItemsValidation(metaQuery);
            const isEmptyQuery = QueryExecuteService.checkIsEmptyQuery(metaQuery);

            setIsValidQuery(isEmptyQuery || isQueryValid);
        }

        return () => setMetaQuery(MetaQueryService.emptyQuery());
    }, [ show ]);

    const setTempSettings = ({
        dataSettings = {},
        contentSettings = {},
        title = gadgetState.title,
    }: {
        dataSettings?: any,
        contentSettings?: any,
        title?: string,
    }) => {
        const updatedState: IGadgetData = {
            ...gadgetState,
            title,
            dataSettings: {
                ...gadgetState.dataSettings,
                ...dataSettings,
            },
            contentSettings: {
                ...gadgetState.contentSettings,
                ...contentSettings,
            },
        };

        setGadgetState(updatedState);
    };

    const isDrillDownDashboardExist = GadgetService.isDrillDownDashboardExist(gadgetState);
    const tabs: ITab[] = [
        {
            title: t('data'),
            component: <DataSettings
                gadget={ gadgetState }
                isDrillDownDashboardExist={ isDrillDownDashboardExist }
                drillDownExistDisabledReason={ t('drillDownExistDisabledReason') }
                setTempSettings={ setTempSettings }
                setIsDataSettingsValid={ setIsDataSettingsValid }
            />,
            id: 0,
        },
        {
            title: t('period'),
            component: <PeriodSettings
                gadget={ gadgetState }
                setTempSettings={ setTempSettings }
            />,
            id: 1,
            disabled: !isDataSettingsValid,
        },
        {
            title: t('advanced'),
            component: <AdvancedSettings
                gadget={ gadgetState }
                setTempSettings={ setTempSettings }
            />,
            id: 2,
            disabled: !isDataSettingsValid,
        },
        {
            title: t('chartSpecific'),
            component: <ChartSpecificSettings
                gadget={ gadgetState }
                isDrillDownDashboardExist={ isDrillDownDashboardExist }
                drillDownExistDisabledReason={ t('drillDownExistDisabledReason') }
                setTempSettings={ setTempSettings }
            />,
            id: 3,
            disabled: !isDataSettingsValid,
        },
        {
            title: t('metadataFilter'),
            component: <MetadataFilter
                gadget={ gadgetState }
                metaQuery={ metaQuery }
                setTempSettings={ setTempSettings }
                setIsValidQuery={ setIsValidQuery }
            />,
            id: 4,
            disabled: !isDataSettingsValid,
        },
    ];

    const getModalBody = () => (
        <Tabs
            activeTab={ activeTab }
            tabs={ tabs }
            tabSelectorClassname={ styles.customTabsSelectorWrapper }
            onChange={ setActiveTab }
        />
    );

    const onApply = () => {
        dispatch(applyGadgetDataChange(gadgetState));
        toggle();
    };

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color={ 'white' }
                onClick={ toggle }
            >
                <FontAwesomeIcon icon={ 'times' } />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color={ 'primary' }
                onClick={ onApply }
                disabled={ !isDataSettingsValid || !dashboard.rights.isPermitEdit || !isValidQuery }
            >
                <FontAwesomeIcon icon={ 'check' } />
                &nbsp;
                { t('save') }
            </Button>
        </div>
    );

    const onCloseModal = () => {
        dispatch(cleanupConfigureModal());
        setActiveTab(0);
    };

    return (
        <VocModal
            backdrop={ 'static' }
            isOpen={ show }
            modalClassName={ 'modal-scrollable' }
            header={ t('gadgetSettings') }
            size={ 'md' }
            toggle={ toggle }
            footer={ getModalFooter() }
            onClosed={ onCloseModal }
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};
