import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { VocTableV2 } from '/components';
import { QuarantinesListActionsFormatter } from '../QuarantinesListActionsFormatter';
import { QuarantinesDaysFormatter, QuarantinesConditionFormatter } from '/feedback/components';
import { transformQuarantineConditionsToReduxForm } from '/feedback/modules/VocFeedback.selectors';

import './QuarantinesTable.scss';

export class QuarantinesTable extends Component {
    settings = [
        {
            field: 'days',
            title: 'Action type',
            formatter: this.quarantineDaysFormatter,
            sort: false,
            style: {
                width: '240px',
            },
        },
        {
            field: 'condition',
            title: 'Condition',
            formatter: this.quarantineConditionFormatter,
            sort: false,
        },
        {
            field: 'id',
            formatter: this.quarantinesListActionsFormatter,
            title: 'Actions',
            style: {
                width: '80px',
                textAlign: 'center',
            },
        },
    ];

    @autobind
    quarantinesListActionsFormatter(cell, obj) {
        return <QuarantinesListActionsFormatter
            { ...obj }
            openModalDelete={ this.props.openModalDelete }
            openModalEdit={ this.props.openModalEdit }
            rightsSurvey={ this.props.rightsSurvey }
        />;
    }

    @autobind
    quarantineDaysFormatter(cell, obj) {
        return (
            <QuarantinesDaysFormatter
                quarantine={ obj }
            />
        );
    }

    @autobind
    quarantineConditionFormatter(cell, obj) {
        return (
            <QuarantinesConditionFormatter
                flowOptions={ this.props.flowOptions }
                formValue={ this.props.formValue }
                setQuarantineDataById={ this.props.setQuarantineDataById }
                id={ obj.id }
                conditions={ transformQuarantineConditionsToReduxForm(obj.conditions) }
                logical_operation={ obj.logical_operation }
            />
        );
    }

    @autobind
    getTableOptions() {
        return {
            requestTrigger: this.props.requestTrigger,
            updateFilters: this.props.updateFilters,
        };
    }

    render() {
        const {
            tableState: {
                loading,
                count,
            },
            data,
            filters,
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                options={ this.getTableOptions() }
                data={ data }
                filters={ filters }
                total={ count }
                loading={ loading }
            />
        );
    }
}
