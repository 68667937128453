import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { combineReducers } from 'redux';
import { connect } from 'react-redux';

import { reducer as topicSets } from './scenes/TopicSets/modules/topicSets.modules';
import { reducer as topicSetAppliance } from './scenes/TopicSetAppliance/modules/topicSetAppliance.modules';
import { cleanup, reducer as common } from './modules/vocAi.modules';
import { getAllUser, getAllGroups, getCurrentUser } from '/modules';

import { TopicSets, TopicSet, TopicSetAppliance } from './scenes';
import { Navigation, VocCleaupHOC, VocSocketHoc } from '/components';
import { PageRequests } from '/services';

import styles from './style.module.scss';

const mapDispatchToProps = {
    cleanup,
    getAllUser,
    getAllGroups,
    getCurrentUser,
};

@connect(null, mapDispatchToProps)

@VocCleaupHOC
@VocSocketHoc('voc_ai')
export class VocAI extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { getAllUser, getAllGroups, getCurrentUser } = this.props;

        this.pageRequests.cleanup();

        const requests = [ getAllUser(), getAllGroups(), getCurrentUser() ];

        requests.forEach(request => this.pageRequests.addRequest(request));
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { url } = this.props.match;
        const isClassifier = location.pathname.match(/ai\/topic-set(\/.*)?$/g);

        const settingsChildRoute = [
            {
                title: 'Topic Sets',
                link: `${ url }/topic-sets`,
                isReactLink: true,
            },
            {
                title: 'Applied Topic Sets',
                link: `${ url }/topic-set-appliance`,
                isReactLink: true,
            },
        ];

        return (
            <div className={ styles.vocAiPageWrapper }>
                {
                    !isClassifier && <Navigation
                        navigationInfo={ settingsChildRoute }
                        className={ styles.vocAiPageNavigation }
                    />
                }

                <Switch>
                    <Route exact
                        path={ `${ url }/topic-sets` }
                        component={ TopicSets }
                    />
                    <Route exact
                        path={ `${ url }/topic-set-appliance` }
                        component={ TopicSetAppliance }
                    />
                    <Route exact
                        path={ [
                            `${ url }/topic-set/:topicSetId`,
                            `${ url }/topic-set`,
                        ] }
                        component={ TopicSet }
                    />
                    <Redirect to={ `${ url }/topic-sets` }/>
                </Switch>
            </div>
        );
    }
}

export const aiReducers = combineReducers({
    topicSets,
    topicSetAppliance,
    common,
});
