import React from 'react';
import { components } from 'react-select';
import { Badge } from 'reactstrap';
import cx from "classnames";

import { TooltipWrapper } from '/components';
import { getLabelClassByType } from '/modules/selectors';

import './SourcePaginateSelectOption.scss';

export const SourcePaginateSelectOption = props => {
    const { data: { channel }, className, selectProps } = props;
    let { type, labelStatusClass, state } = props.data;

    if(channel?.toLowerCase?.() === 'system') {
        type = null;
        labelStatusClass = 'info';
        state = '';
    }

    return (
        <div className={ cx(className, selectProps.selectOptionClass) } id={ props.innerProps?.id }>
            <components.Option { ...props }>
                <div className="Select-options--sources">
                    <div className="Select-options__title-wrapper">
                        <TooltipWrapper value={ props.children } placement={ 'top' }>
                            <div className='Select-options__title Select-menu__small vochub-select-control__option'>
                                { props.children }
                            </div>
                        </TooltipWrapper>
                    </div>
                    <div className="Select-options__labels-container">
                        {
                            channel
                            && <Badge
                                color={ `${ getLabelClassByType(labelStatusClass) }` }
                                className={ `Select-options__source-label ${ state }` }
                            >
                                { channel }
                            </Badge>
                        }

                        {
                            type
                            && <Badge
                                color={ getLabelClassByType(type?.toLowerCase()) }
                                className={ 'Select-options__source-label' }
                            >
                                { type }
                            </Badge>
                        }
                    </div>
                </div>
            </components.Option>
        </div>
    );
};
