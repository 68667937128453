// import DontRemove from './xlsx-worker.js';

export class FileParser {
    static columnTypes = {
        list: [
            'TEXT',
            'LANG',
            'DATE',
            'NPS',
            'NUMERIC',
            'META',
            'CHOICE',
        ],
        default: 'META',
    };

    static fileLoaderObserver;

    static executeParser({ file, fileLoaderObserver }) {
        const fileReader = new FileReader();

        FileParser.fileLoaderObserver = fileLoaderObserver;
        fileReader.readAsArrayBuffer(file);
        fileReader.addEventListener("load", () => {
            // const worker = new Worker('/react/dist/xlsx-worker.js');
            // worker.postMessage({file: e.target.result, isCsv, csvDelimiter});

            // eslint-disable-next-line no-undef
            worker.onmessage = function(message) {
                FileParser.fileLoaderObserver.trigger('sourse.headers.parsed', message.data);
            };
        });
    }

    static likeMainColumn(col) {
        return FileParser.columnTypes.list
            .filter(type => type !== FileParser.columnTypes.default)
            .some(type => col.id.includes(`[${type}]`));
    }

    static likeAdditionalColumn(col) {
        return FileParser.columnTypes.list
            .filter(type => type !== FileParser.columnTypes.default)
            .every(type => !col.id.includes(`[${type}]`));
    }

    static getColumnWithTypes(headers, startIndex) {
        const getFirstColumnType = (columnTypes, header) => {
            const indexOfString = [];

            for (const i in columnTypes) {
                indexOfString[i] = header.indexOf(columnTypes[i]);
            }

            const minIndexOfString = Math.min(...indexOfString);
            const indexFirstColumnType = indexOfString.indexOf(minIndexOfString);

            return columnTypes[indexFirstColumnType];
        };

        const getColumnType = header => {
            const columnTypes = [];

            for (const i in FileParser.columnTypes.list) {
                const columnType = FileParser.columnTypes.list[i],
                    pattern = new RegExp(`\\[${columnType}\\]`);

                if (pattern.test(header)) {
                    columnTypes.push(columnType);
                }
            }

            return columnTypes.length
                ? getFirstColumnType(columnTypes, header)
                : FileParser.columnTypes.default;
        };

        return headers
            .map((header, i) => ({
                id: header,
                index: startIndex + i,
                type: getColumnType(header),
            }))
            .filter(column => column.id.trim());
    }
}
