import React, { Component } from 'react';
import { VocModal, FormFieldItem, VocSelect } from '/components';
import { HelperService } from '/services/HelperService/HelperService';
import { Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './AddTouchPointModal.scss';
import { autobind } from 'core-decorators';

const getInitialState = () => ({
    isValid: false,
    project: null,
    name: '',
    url: '',
    pending: false,
});

export class AddTouchPointModal extends Component {
    state = getInitialState();

    @autobind
    initModal() {
        this.setState({ loading: true });
        this.props.getProjects()
            .then(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    @autobind
    cleanupModal() {
        this.setState(getInitialState());
    }

    @autobind
    checkValidation() {
        const checkValue = value => (value && value !== '') ? value : null;
        const isValid = !!(
            checkValue(this.state.name)
            && checkValue(this.state.project)
            && this.state.pending === false
        );

        return isValid;
    }

    @autobind
    handleChangeProjectInput(selectedOption) {
        this.setState({
            project: selectedOption ? selectedOption.value : '',
        });
    }

    @autobind
    handleChangeNameInput(event) {
        const name = event.target.value;

        this.setState({ name });
    }

    @autobind
    handleChangeUrlInput(event) {
        const url = event.target.value;

        this.setState({ url });
    }

    @autobind
    onAddTouchPointClicked() {
        if (this.state.pending) {
            return;
        }

        this.setState({
            pending: true,
        });

        const params = {
            ...this.state,
        };

        this.props.actionHandler(params)
            .then(this.successHandler, this.errorHandler);
    }

    @autobind
    successHandler() {
        this.errorHandler();
        this.props.close();
    }

    @autobind
    errorHandler() {
        this.setState({
            pending: false,
        });
    }

    @autobind
    onAddTouchPointClosed() {
        this.props.close();
    }

    getModalBody() {
        const selectOptions = HelperService.mapProjectsToSelectModel(this.props.projectList);
        const projectValue = HelperService.getSelectedValue(selectOptions, this.state.project);

        return [
            <FormFieldItem
                controlId="touchpoint_name"
                type="text"
                key={ 1 }
                isHorizontal={ true }
                value={ this.state.name }
                label="Name *"
                handleChange={ this.handleChangeNameInput }
            />,
            this.state.loading
                ? <div className={ 'dropdown-wrapper' } key={ 2 }>
                    <div className={ 'form-group' }>
                        <div className={ 'row' }>
                            <div className={ 'select-control-label control-label-nowrap col-md-3' }>
                                <label className={ 'control-label' }>Customer Journey *</label>
                            </div>
                            <div className={ 'col-md-9' }>
                                <p style={{ margin: '8px' }}>
                                    <FontAwesomeIcon icon='circle-notch' size='lg' spin/>
                                    <span> Loading...</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                // @todo remove this wrapper
                : <div className={ 'dropdown-wrapper control-label-nowrap' } key={ 2 }>
                    <VocSelect
                        label="Customer Journey *"
                        placeholder="Select a Customer Journey"
                        isClearable={ false }
                        isHorizontal={ true }
                        options={ selectOptions }
                        value={ projectValue }
                        onChange={ this.handleChangeProjectInput }
                    />
                </div>,
            <FormFieldItem
                controlId="touchpoint_url"
                type="text"
                key={ 3 }
                isHorizontal={ true }
                value={ this.state.url }
                label="Dashboard Link"
                placeholder={ 'http://example.com' }
                handleChange={ this.handleChangeUrlInput }
            />,
        ];
    }

    getModalFooter() {
        return (
            <div>
                <Button onClick={ this.onAddTouchPointClosed } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button onClick={ this.onAddTouchPointClicked }
                    disabled={ !this.checkValidation() }
                    color="primary">
                    <FontAwesomeIcon icon={ 'check' }/>
                    <span> Add</span>
                </Button>
            </div>
        );
    }

    render() {
        return (
            <VocModal
                modalClassName='add-touchpoint-modal-wrapper'
                isOpen={ this.props.show }
                onOpened={ this.initModal }
                onClosed={ this.cleanupModal }
                toggle={ this.props.close }
                header={ 'Add Touchpoint' }
                size={ 'md' }
                footer={ this.getModalFooter() }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}

AddTouchPointModal.propTypes = {
    projectList: PropTypes.array,
    show: PropTypes.bool,
    close: PropTypes.func,
    actionHandler: PropTypes.func,
    getProjects: PropTypes.func,
};
