import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageRequests } from '/services';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
} from 'reactstrap';
import { VocCleaupHOC } from '/components';
import { LayoutsListTable, LayoutModal, LayoutModalCopy } from './components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

import {
    cleanup,
    copyLayout,
    deleteLayout,
    getLayoutList,
    updateFilters,
    getLayoutById,
    processLayout,
} from './modules/Layouts.modules';
import { getPermissionSelector } from '../../modules/globalSettings.selectors';

import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';
import { layoutFilters, tableSettingsSelector, tableDataSelector } from './modules/Layouts.selectors';

const mapStateToProps = state => ({
    tableState: tableSettingsSelector(state),
    tableData: tableDataSelector(state),
    filters: layoutFilters(state),
    assets: assetsSelector(state),
    permission: getPermissionSelector(state),
});
const mapDispatchToProps = {
    getLayoutList,
    updateFilters,
    deleteLayout,
    getAssets,
    uploadAsset,
    showConfirm,
    copyLayout,
    getLayoutById,
    processLayout,
    cleanup,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class Layouts extends Component {
    state = {
        openModal: false,
        openModalCopy: false,
        id: null,
    };
    pageRequests = new PageRequests();

    componentDidMount() {
        this.requestTrigger();
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        const model = {
            limit: filters.limit,
            page: filters.page,
            name: filters.search,
        };

        this.pageRequests.cleanup();
        const request = this.props.getLayoutList(model);

        this.pageRequests.addRequest(request);
    }

    @autobind
    openModal({ id }) {
        this.setState({
            openManageModal: true,
            id,
        });
    }

    @autobind
    openModalCopy({ id }) {
        this.setState({
            openModalCopy: true,
            id,
        });
    }

    @autobind
    closeModal() {
        this.setState({
            openManageModal: false,
            openModalCopy: false,
            id: null,
        });
    }

    @autobind
    openConfirmDelete({ id }) {
        this.props.showConfirm({
            header: 'Warning!',
            // eslint-disable-next-line max-len
            content: 'This layout can be selected in the settings and after removing it you may need to check the settings and select another layout in Web template dropdown, continue?',
            successCallback: () => this.deleteLayout(id),
        });
    }

    @autobind
    deleteLayout(id) {
        const model = {
            ...this.props.filters,
            page: 1,
        };

        this.props.deleteLayout(id).promise
            .then(() => {
                this.requestTrigger(model);
                this.props.updateFilters(model);
            });
    }

    @autobind
    copyLayout(body) {
        this.props.copyLayout(body).promise
            .then(() => {
                this.requestTrigger(this.props.filters);
            });
    }

    @autobind
    applyLayoutChanges(body) {
        return this.props.processLayout(body).promise
            .then(() => this.requestTrigger());
    }

    render() {
        const {
            filters,
            tableData,
            tableState,
            updateFilters,
            getAssets,
            uploadAsset,
            assets,
            getLayoutById,
            permission,
        } = this.props;

        const {
            openManageModal,
            openModalCopy,
            id,
        } = this.state;

        return (
            <DocumentTitle title={ ' Layouts ' }>
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Layouts</span>
                        <div className={ 'panel__header-buttons' }>
                            <Button className={ 'panel__header-button' }
                                outline
                                color="white"
                                onClick={ e => permission.isPermitEdit && this.openModal(e) }
                                disabled={ !permission.isPermitEdit }
                            >
                                <FontAwesomeIcon icon='plus' className='color-green'/>
                                <span> Add Layouts</span>
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <LayoutsListTable
                            requestTrigger={ this.requestTrigger }
                            updateFilters={ updateFilters }
                            openConfirmDelete={ this.openConfirmDelete }
                            openModalManage={ this.openModal }
                            openModalCopy={ this.openModalCopy }
                            filters={ filters }
                            data={ tableData }
                            tableState={ tableState }
                            permission={ permission }
                        />
                        <LayoutModal
                            show={ openManageModal }
                            getAssets={ getAssets }
                            uploadAsset={ uploadAsset }
                            assets={ assets }
                            layoutId={ id }
                            getLayoutById={ getLayoutById }
                            close={ this.closeModal }
                            applyLayoutChanges={ this.applyLayoutChanges }
                            permission={ permission }
                        />
                        <LayoutModalCopy
                            show={ openModalCopy }
                            copyLayout={ this.copyLayout }
                            close={ this.closeModal }
                            layout={ tableData.find(layout => layout.id === id) }
                            permission={ permission }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}

