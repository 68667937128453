import { CALL_API, FEEDBACK } from 'store/middleware/api';

export const GET_GLOBAL_QUARANTINE_LIST_REQUEST = 'GET_GLOBAL_QUARANTINE_LIST_REQUEST';
export const GET_GLOBAL_QUARANTINE_LIST_SUCCESS = 'GET_GLOBAL_QUARANTINE_LIST_SUCCESS';
export const GET_GLOBAL_QUARANTINE_LIST_ERROR = 'GET_GLOBAL_QUARANTINE_LIST_ERROR';

export const DELETE_GLOBAL_QUARANTINE_REQUEST = 'DELETE_GLOBAL_QUARANTINE_REQUEST';
export const DELETE_GLOBAL_QUARANTINE_SUCCESS = 'DELETE_GLOBAL_QUARANTINE_SUCCESS';
export const DELETE_GLOBAL_QUARANTINE_ERROR = 'DELETE_GLOBAL_QUARANTINE_ERROR';

export const CREATE_GLOBAL_QUARANTINES_REQUEST = 'CREATE_GLOBAL_QUARANTINES_REQUEST';
export const CREATE_GLOBAL_QUARANTINES_SUCCESS = 'CREATE_GLOBAL_QUARANTINES_SUCCESS';
export const CREATE_GLOBAL_QUARANTINES_ERROR = 'CREATE_GLOBAL_QUARANTINES_ERROR';

export const UPDATE_GLOBAL_QUARANTINES_REQUEST = 'UPDATE_GLOBAL_QUARANTINES_REQUEST';
export const UPDATE_GLOBAL_QUARANTINES_SUCCESS = 'UPDATE_GLOBAL_QUARANTINES_SUCCESS';
export const UPDATE_GLOBAL_QUARANTINES_ERROR = 'UPDATE_GLOBAL_QUARANTINES_ERROR';

export const SET_GLOBAL_QUARANTINE_DATA_BY_ID = 'SET_GLOBAL_QUARANTINE_DATA_BY_ID';

export const GLOBAL_QUARANTINES_SETTINGS_FILTERS_CHANGED = 'GLOBAL_QUARANTINES_SETTINGS_FILTERS_CHANGED';
export const GLOBAL_QUARANTINES_SETTINGS_CLEANUP = 'GLOBAL_QUARANTINES_SETTINGS_CLEANUP';

function getInitialState() {
    return {
        loading: false,
        submitted: false,
        filters: {
            page: 1,
            limit: 10,
            survey_group_id: null,
            blocking_survey_group_id: null,
            hide_deleted_surveys: true,
        },
        quarantineId: null,
        results: [],
        count: 0,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_GLOBAL_QUARANTINE_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_GLOBAL_QUARANTINE_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                results: action.response.results,
                count: action.response.count,
            };
        }

        case GET_GLOBAL_QUARANTINE_LIST_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case GLOBAL_QUARANTINES_SETTINGS_FILTERS_CHANGED: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case CREATE_GLOBAL_QUARANTINES_SUCCESS:
        case UPDATE_GLOBAL_QUARANTINES_SUCCESS:
        case CREATE_GLOBAL_QUARANTINES_ERROR:
        case UPDATE_GLOBAL_QUARANTINES_ERROR: {
            return {
                ...state,
                submitted: false,
            };
        }

        case CREATE_GLOBAL_QUARANTINES_REQUEST:
        case UPDATE_GLOBAL_QUARANTINES_REQUEST: {
            return {
                ...state,
                submitted: true,
            };
        }

        case SET_GLOBAL_QUARANTINE_DATA_BY_ID: {
            return {
                ...state,
                quarantineId: action.payload,
            };
        }

        case GLOBAL_QUARANTINES_SETTINGS_CLEANUP:
            return getInitialState();

        default:
            return state;
    }
}

export function getQuarantineListRequest(body) {
    return {
        [CALL_API]: {
            endpoint: `quarantine-rule/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_GLOBAL_QUARANTINE_LIST_REQUEST,
                GET_GLOBAL_QUARANTINE_LIST_SUCCESS,
                GET_GLOBAL_QUARANTINE_LIST_ERROR,
            ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function deleteQuarantineItem(id) {
    return {
        [CALL_API]: {
            endpoint: `/quarantine-rule/${ id }/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [
                DELETE_GLOBAL_QUARANTINE_REQUEST,
                DELETE_GLOBAL_QUARANTINE_SUCCESS,
                DELETE_GLOBAL_QUARANTINE_ERROR,
            ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Quarantine was successfully deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function createQuarantineItem(body, isCopy) {
    return {
        [CALL_API]: {
            endpoint: `/quarantine-rule/`,
            method: 'POST',
            contentType: 'application/json',
            types: [
                CREATE_GLOBAL_QUARANTINES_REQUEST,
                CREATE_GLOBAL_QUARANTINES_SUCCESS,
                CREATE_GLOBAL_QUARANTINES_ERROR,
            ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: `Quarantine was successfully ${isCopy ? 'copied' : 'created'}`,
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function updateQuarantineItem(id, body) {
    return {
        [CALL_API]: {
            endpoint: `/quarantine-rule/${ id }/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [
                UPDATE_GLOBAL_QUARANTINES_REQUEST,
                UPDATE_GLOBAL_QUARANTINES_SUCCESS,
                UPDATE_GLOBAL_QUARANTINES_ERROR,
            ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Quarantine was successfully updated',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function updateFilters(payload) {
    return {
        type: GLOBAL_QUARANTINES_SETTINGS_FILTERS_CHANGED,
        payload,
    };
}

export function setQuarantineDataById(payload) {
    return {
        type: SET_GLOBAL_QUARANTINE_DATA_BY_ID,
        payload,
    };
}

export function cleanup() {
    return {
        type: GLOBAL_QUARANTINES_SETTINGS_CLEANUP,
    };
}


