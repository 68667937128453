import React, { useState } from "react";
import { Input, Label } from "reactstrap";
import cx from "classnames";

import { SwitchControl } from "/components";

import styles from './style.module.scss';

const options = [
    {
        title: 'All data',
        data: 'allData',
    },
    {
        title: 'Surveys',
        data: 'surveys',
    },
    {
        title: 'Uploads',
        data: 'uploads',
    },
    {
        title: 'Virtual',
        data: 'virtual',
    },
];

const surveyStatusArray = [
    {
        title: 'Active',
        data: 'active',
        color: '#5cb85c',
    },
    {
        title: 'Paused',
        data: 'paused',
        color: '#f0ad4e',
    },
    {
        title: 'Stopped',
        data: 'stopped',
        color: '#ffcc00',
    },
    {
        title: 'Deleted',
        data: 'deleted',
        color: '#d9534f',
    },
];


export const SourceFilter = ({ open }) => {
    const [ currentOption, setCurrentOption ] = useState('allData');
    const [ uploadsStatus, setUploadsStatus ] = useState(false);
    const [ currentSurveyStatuses, setCurrentSurveyStatuses ] = useState([
        'active',
        'paused',
        'stopped',
        'deleted',
    ]);

    const onChangeSurveyStatus = status => {
        if(currentSurveyStatuses.includes(status)) {
            setCurrentSurveyStatuses(
                currentSurveyStatuses.filter(currentStatus => currentStatus !== status),
            );
        } else {
            setCurrentSurveyStatuses([ ...currentSurveyStatuses, status ]);
        }
    };

    const surveyStatus = () => (
        <div className={ styles.surveyStatusWrapper }>
            <Label>
                Survey status
            </Label>
            <div className={ styles.surveyStatusList }>
                {
                    surveyStatusArray.map(status => (
                        <Label
                            className='radio_label'
                            for={ `checkboxEnd${status.data}` }
                            key={ status.data }
                        >
                            <Input
                                type="checkbox"
                                checked={ currentSurveyStatuses.includes(status.data) }
                                onChange={ () => onChangeSurveyStatus(status.data) }
                                id={ `checkboxEnd${status.data}` }
                            />
                            <span
                                className={ styles.surveyStatusListTitle }
                                style={{
                                    background: status.color,
                                }}
                            >
                                {status.title.toUpperCase()}
                            </span>
                        </Label>
                    ))
                }
            </div>
        </div>
    );

    const switchForHiddenUploads = () => (
        <div className={ styles.uploadsWrapper }>
            <Label
                className={ styles.uploadsSwitchWrapper }
            >
                <span className={ styles.uploadsLabel }>
                    Show hidden uploads
                </span>
                <SwitchControl
                    name='uploadsSwitch'
                    type={ 'small' }
                    value={ uploadsStatus }
                    changeHandler={ () => setUploadsStatus(!uploadsStatus) }
                />
            </Label>
        </div>
    );

    return (
        <div className={ cx(styles.sourceFilter, { [styles.openSourceFilter]: open }) }>
            <div className={ styles.optionsWrapper }>
                {options.map(option => (
                    <Label
                        className='radio_label'
                        for={ `radioEnd${option.data}` }
                        key={ option.data }
                    >
                        <Input
                            className='radio_input'
                            checked={ currentOption === option.data }
                            id={ `radioEnd${option.data}` }
                            type="radio"
                            onChange={ () => setCurrentOption(option.data) }
                        />
                        {option.title}
                    </Label>
                ))}
            </div>
            {
                currentOption === 'surveys' && surveyStatus()
            }
            {
                currentOption === 'uploads' && switchForHiddenUploads()
            }
        </div>
    );
};
