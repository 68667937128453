import React, { memo } from 'react';
import Slider, { SliderProps } from 'rc-slider';

import { IRangeSlider } from '/visual/scenes/Dashboard/models';

import { SliderHandle } from './SliderHandle';

import 'rc-slider/assets/index.css';
import styles from './style.module.scss';

const baseTrackStyles = ({
    index,
    colors,
    allowOverlap,
}: {
    index: number,
    colors?: string[],
    allowOverlap: boolean,
}) => ({
    height: 4,
    cursor: allowOverlap ? 'ew-resize' : 'default',
    backgroundColor: colors ? colors[index] : '#13b6ff',
    transform: 'translateY(50%)',
});

const baseHandStyles = ({ index, colors }: { index: number, colors?: string[] }) => ({
    opacity: 1,
    height: 15,
    width: 15,
    border: 'none',
    backgroundColor: colors ? colors[index] : '#428bca',
});

const defaultTipFormatter = (value: number): string => value.toString();

const RangeSliderComponent = ({
    step = 1,
    min,
    max,
    values = [],
    disabled = false,
    colors,
    draggableTrack = false,
    allowOverlap = false,
    railStyles,
    trackStyles,
    handleStyles,
    keyboard = true,
    marks,
    onChange,
    onBeforeChange,
    onAfterChange,
    tooltipValueFormatter = defaultTipFormatter,
}: IRangeSlider) => {
    const getValues = () => Array.isArray(values) ? values : [];

    const mergeStyles = (stylesList: any[] | undefined, baseStyles: any, options: any) =>
        stylesList && Array.isArray(stylesList)
            ? stylesList.map((s: any, index: number) => ({ ...baseStyles({ index, ...options }), ...s }))
            : getValues().map((s: any, index: number) => ({ ...baseStyles({ index, ...options }) }));

    const tStyles = mergeStyles(trackStyles, baseTrackStyles, { allowOverlap, colors: colors?.track });
    const hStyles = mergeStyles(handleStyles, baseHandStyles, { colors: colors?.hand });

    const getMarks = () => {
        if (marks && Array.isArray(marks) && marks.length !== 0) {
            const result: { [index: number]: any } = {};

            marks.forEach((m: any, index: number) => {
                if (getValues().includes(index)) {
                    result[index] = <span className={ styles.mark }>{ m }</span>;
                }
            });

            return result;
        }
    };

    const tooltipHandleRender: SliderProps['handleRender'] = (originNode, handleProps) =>
        <SliderHandle
            originNode={ originNode }
            visible={ handleProps.dragging }
            rangeValue={ handleProps.value }
            tipFormatter={ tooltipValueFormatter }
        />;

    return (
        <Slider
            className={ styles.slider }
            range={ getValues().length > 1 }
            step={ step }
            min={ min }
            max={ max }
            value={ values }
            disabled={ disabled }
            draggableTrack={ draggableTrack }
            allowCross={ allowOverlap }
            railStyle={{
                backgroundColor: colors?.rail || '#ccc',
                transform: 'translateY(50%)',
                ...railStyles,
            }}
            trackStyle={ tStyles }
            handleStyle={ hStyles }
            keyboard={ keyboard }
            marks={ getMarks() }
            onChange={ onChange }
            handleRender={ tooltipHandleRender }
            onBeforeChange={ onBeforeChange }
            onAfterChange={ onAfterChange }
        />
    );
};

RangeSliderComponent.displayName = 'RangeSlider';

export const RangeSlider = memo(RangeSliderComponent);
