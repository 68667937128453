import { useEffect } from 'react';
import { IUseHover } from '/visual/scenes/Dashboard/models';

export function useHover({ ref, page, setHoveredPageId, isEditable }: IUseHover) {
    useEffect(() => {
        const node = ref.current;

        const handleMouseEnter = () => {
            setHoveredPageId(page);
        };

        const handleMouseLeave = () => {
            setHoveredPageId(null);
        };

        if (isEditable) {
            node?.addEventListener('mouseenter', handleMouseEnter);
            node?.addEventListener('mouseleave', handleMouseLeave);
        } else {
            node?.removeEventListener('mouseenter', handleMouseEnter);
            node?.removeEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            node?.removeEventListener('mouseenter', handleMouseEnter);
            node?.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [ ref, isEditable ]);
}
