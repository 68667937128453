import React, { Fragment } from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CollapseSection } from '/components';
import './Info.scss';

export const Info = ({ collapse }) => {
    const { t } = useTranslation();

    return (
        <CollapseSection
            title={ t('field.info') }
            collapse={ collapse }
            className='info-section__header'
        >
            <Alert color='info'>
                <Fragment>
                    { t('uploadSocialNetworkDataInfoList.1') }
                    {
                        Array.from({ length: 10 }, (_, index) => index + 2)
                            .map(i18nKey => <li key={ i18nKey }>
                                { t(`uploadSocialNetworkDataInfoList.${ i18nKey }`) }
                            </li>)
                    }
                </Fragment>
            </Alert>
        </CollapseSection>
    );
};

