import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VocInputSearch } from "/components/VocInputSearch";
import { DictionaryForm } from "../DictionaryForm";
import { Icons } from '/components/Icons';
import { addDictionary, editDictionary, preprocessData } from "/dictionaries/modules/dictionary.modules";
import { dictionaryAllLanguagesSelector } from "/dictionaries/modules/dictionary.selectors";

import './DictionariesHeader.scss';

export const DictionariesHeader = ({
    closeModal,
    dictionaryId,
    requestTrigger,
    onSearchType,
    isModalOpened,
    setIsModalOpened,
    initialFormValues,
    setInitialFormValues,
    isAddButtonActiveHandler,
    setIsAddButtonActiveHandler,
    setIsChecked,
    isChecked,
}) => {
    const dispatch = useDispatch();
    const selectOptions = useSelector(dictionaryAllLanguagesSelector);

    const { location, push } = useHistory();
    const baseUrl = location.pathname.split('/', 2).join('/');

    const submitDictionary = ({ title, language, lemmata, children }, id) => {
        title = title.trim();
        lemmata = lemmata.split(', ');

        const formValues = {
            title,
            language,
            lemmata,
            children,
        };

        const request = id
            ? dispatch(editDictionary(id, formValues))
            : dispatch(addDictionary(formValues));

        request.promise
            .then(() => {
                closeModal();
                requestTrigger();
            });
    };

    const submitForPreprocessData = ({ language, lemmata, title }) => {
        const lang = language;
        const request = dispatch(preprocessData({
            lang,
            text: lemmata,
        }));

        request.promise
            .then(lemmata => {
                lemmata = lemmata.join(', ');
                setInitialFormValues({
                    title,
                    lemmata,
                    language,
                });
                setIsChecked(true);
            });
    };

    return (
        <Fragment>

            <div className={ 'dictionaries__header-wrapper' }>

                <div className={ 'dictionaries__backBtn' }
                    onClick={ () => push(`${ baseUrl }/query/list-queries`) }>

                    <Icons className='leftArrow-icon' icon='leftArrow'/>
                    <span>Back</span>

                </div>

                <div className='dictionaries__header'>

                    <h2 className='dictionaries__header-title'>
                        Dictionaries
                    </h2>

                    <div className='dictionaries__header-controls'>

                        <VocInputSearch className={ 'voc-table_header-search search-icon' }
                            onSearchType={ onSearchType }
                        />

                        <Button className='dictionaries__header-btn'
                            onClick={ () => setIsModalOpened(true) }
                        >
                            <FontAwesomeIcon icon='plus' className='color-white'/>
                            <span>Add dictionary</span>
                        </Button>

                    </div>
                </div>

                <DictionaryForm
                    dictionaryId={ dictionaryId }
                    setIsAddButtonActiveHandler={ setIsAddButtonActiveHandler }
                    isAddButtonActiveHandler={ isAddButtonActiveHandler }
                    submitDictionary={ submitDictionary }
                    closeModal={ closeModal }
                    selectOptions={ selectOptions }
                    isOpen={ isModalOpened }
                    isChecked={ isChecked }
                    setIsChecked={ setIsChecked }
                    submitForPreprocessData={ submitForPreprocessData }
                    initialValues={ initialFormValues }
                />

            </div>

        </Fragment>
    );
};
