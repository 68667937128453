import React, { useState } from "react";
import cx from 'classnames';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SettingFieldContainer } from "../components";
import { Editor } from '/components';

import './SettingJSField.scss';

const defaultHeight = '300px';

export const SettingJSField = ({
    input,
    label,
    meta: { dirty },
}) => {
    const [ height, setHeight ] = useState(defaultHeight);
    const [ expand, setExpand ] = useState(false);

    const disableExpandButton = () => parseInt(height) <= parseInt(defaultHeight);

    const toggleExpand = () => {
        setExpand(!expand);
    };

    const onChangeEditor = value => {
        const model = {
            ...input.value,
            value,
        };

        input.onChange(model);
    };

    const editorHeight = () => {
        return input.value.redefined && expand
            ? height
            : defaultHeight;
    };

    return (
        <>
            <label className='settings-field__input-label' htmlFor={ input.name }>{ label }</label>
            <SettingFieldContainer
                checked={ input.value.redefined }
                className={ dirty && 'changed' }
                field={ input }
            >

                <div
                    className={ 'settingsJSContainer' }
                    style={{ height: editorHeight() }}
                >

                    <div className={ cx( 'editor', { 'collapsed': !input.value.redefined }) }>

                        <div className={ cx({ 'disabled': !input.value.redefined }) }>
                            <Editor
                                onChange={ onChangeEditor }
                                onBlur={ () => input.onBlur(input.value) }
                                onFocus={ () => input.onFocus(input.value) }
                                value={ input.value.value }
                                mode='javascript'
                                disabled={ !input.value.redefined }
                                height={ editorHeight() }
                                resizeCallback={ setHeight }
                                baseHeight={ defaultHeight }
                            />
                        </div>

                        <Button
                            onClick={ toggleExpand }
                            color='white'
                            disabled={ disableExpandButton() }
                            className='buttonExpand btn settingButton'
                        >
                            <FontAwesomeIcon
                                className='expandIcon'
                                icon={ expand ? 'compress-alt' : 'expand-arrows-alt' }
                            />
                        </Button>

                    </div>

                </div>

            </SettingFieldContainer>
        </>
    );
};

