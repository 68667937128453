import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortableList } from '../SortableList';
import { TableHeader } from '../TableHeader';
import { SurveysListPagination } from '../SurveysListPagination';
import { SurveysListHeader } from '../SurveysListHeader';

export class SurveysListTableView extends Component {
    constructor(props) {
        super(props);
    }

    onSortStart = () => {
        document.body.style.cursor = 'move';
        document.body.style.userSelect = 'none';
    };

    componentDidMount() {
        this.props.getResponseRates();
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = 'auto';
        document.body.style.userSelect = '';

        this.props.reorderSurvey({
            current: oldIndex,
            target: newIndex,
        });
    };

    renderTablePanel() {
        const { surveysList, loading, unitsByPermit } = this.props;
        const isEmpty = surveysList.length === 0;


        return (
            <Card className={ 'page__wrapper' }>
                <CardHeader className={ 'panel__header panel__header--buttons' }>
                    <span className={ 'panel__header-title' }>Surveys</span>
                    <div className={ 'panel__header-buttons' }>
                        <Button outline
                            className={ 'panel__header-button' }
                            color={ 'white' }
                            onClick={ () => this.props.openModal(null, null, 'openModalNew') }
                            type='button'
                            disabled={ !unitsByPermit.length }
                        >
                            <FontAwesomeIcon icon='plus' className={ 'color-green' }/>
                            <span> Add Survey</span>
                        </Button>
                    </div>
                </CardHeader>
                <div>
                    { loading && <div className='preloader-progressbar'/> }
                    {
                        !this.props.showPreloader && isEmpty
                        && <div className='alert alert-danger alert-danger-light text-center no-results'>
                            <p>No surveys were found</p>
                        </div>
                    }

                    {
                        !this.props.showPreloader && !isEmpty
                        && <div className='surveys-list-modern-list'>
                            <div className='table-responsive'>
                                { this.renderTableBody() }
                            </div>
                        </div>
                    }
                </div>

            </Card>
        );
    }

    renderTableBody() {
        const {
            loading,
            applyFilter,
            filters,
            surveysList,
            openModal,
            showToast,
        } = this.props;
        const tableLoading = `${(loading) ? 'table-loading' : ''}`;
        const tableClass = `table table-hover ${tableLoading}`;

        return (
            <div>
                <table className={ tableClass }>
                    <TableHeader
                        applyFilter={ applyFilter }
                        sortBy={ filters.sortBy }
                    />
                    <SortableList
                        items={ surveysList }
                        onSortStart={ this.onSortStart }
                        onSortEnd={ this.onSortEnd }
                        useDragHandle={ true }
                        openModal={ openModal }
                        showToast={ showToast }
                        axis='y'
                        lockAxis='y'
                        helperClass='surveys-list-modern-sortable-helper'
                        self={ this }
                    />
                </table>
            </div>
        );
    }

    render() {
        return (
            <div className={ 'fadeIn' }>
                <SurveysListHeader
                    applyFilter={ this.props.applyFilter }
                    unitLabel={ this.props.activeUnit.name }
                    unitsList={ this.props.unitsList }
                    MAX_LIMIT={ this.props.pagination.MAX_LIMIT }
                    search={ this.props.filters.search }
                    itemsPerPage={ this.props.filters.limit }
                    hideDeletedSurveys={ this.props.filters.hideDeletedSurveys }
                    unit={ this.props.activeUnit.id }
                />
                { this.renderTablePanel() }
                {
                    !this.props.showPreloader
                    && <SurveysListPagination
                        MAX_LIMIT={ this.props.pagination.MAX_LIMIT }
                        itemsCount={ this.props.pagination.itemsCount }
                        itemsPerPage={ this.props.pagination.itemsPerPage }
                        applyFilter={ this.props.applyFilter }
                        page={ this.props.pagination.page }
                        loading={ this.props.pagination.loading }
                    />
                }
            </div>
        );
    }
}

SurveysListTableView.propTypes = {
    unitsList: PropTypes.array,
    surveysList: PropTypes.array,
    filters: PropTypes.object,
    openModal: PropTypes.func,
    getResponseRates: PropTypes.func,
    applyFilter: PropTypes.func,
    activeUnit: PropTypes.object,
    MAX_LIMIT: PropTypes.number,
};
