import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { reset, formValueSelector } from 'redux-form';
import { autobind } from 'core-decorators';

import { QuestionForm } from './components/QuestionForm';
import { activeLanguageSelector, rightsSurveySelector, responsesCountSelector } from '../../modules/survey.selectors';
import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { checkQuestionResponseById } from '../../modules/survey.modules';
import { HelperService, PageRequests } from '/services';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { EDigiQuestionType } from '/scenes/VocFeedback/constants';
import { QuestionsServices } from '/scenes/VocFeedback/services';

import {
    questionSelector,
    questionTypesOptionsSelector,
    questionConfigurableValuesForSectionSelector,
    questionTypeSelector,
    answersErrorsSelector,
    showAnswerSectionSelector,
    questionInitialFormSelector,
    questionViewFormSelector,
    ivrErrorsSelector,
    questionDataForInheritSelector,
} from './modules/question.selectors';
import {
    cleanup,
    createQuestion,
    patchQuestion,
    getQuestionRequest,
    getQuestionRequestByParentId,
    resetQuestionDataForInherit,
} from './modules/question.modules';

import './Question.scss';

const formFieldsSelector = formValueSelector('QuestionForm');

const mapStateToProps = state => ({
    activeLang: activeLanguageSelector(state),
    activeSurvey: activeSurveySelector(state),
    question: questionSelector(state),
    questionTypesOption: questionTypesOptionsSelector(state),
    configurableValues: questionConfigurableValuesForSectionSelector(state),
    configurableAnswers: formFieldsSelector(state, 'configurableAnswers'),
    questionType: questionTypeSelector(state),
    showAnswerSection: showAnswerSectionSelector(state),
    questionInitialForm: questionInitialFormSelector(state),
    questionViewForm: questionViewFormSelector(state),
    ivrErrors: ivrErrorsSelector(state),
    answersErrors: answersErrorsSelector(state),
    rightsSurvey: rightsSurveySelector(state),
    responsesCount: responsesCountSelector(state),
    questionDataForInherit: questionDataForInheritSelector(state),
});

const mapDispatchToProps = {
    cleanup,
    showConfirm,
    checkQuestionResponseById,
    createQuestion,
    patchQuestion,
    getQuestionRequest,
    getQuestionRequestByParentId,
    resetQuestionDataForInherit,
    reset,
};

@connect(mapStateToProps, mapDispatchToProps)
export class Question extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        this.checkAndRequestQuestion();
    }

    componentDidUpdate(prevProps) {
        const {
            questionId,
            inheritDataFromId,
            isNewQuestionCreated,
            setIsNewQuestionCreated,
        } = this.props;

        if (prevProps.questionId !== questionId || prevProps.inheritDataFromId !== inheritDataFromId) {
            isNewQuestionCreated && setIsNewQuestionCreated?.();
            this.checkAndRequestQuestion();
        }
    }

    componentWillUnmount() {
        this.cleanUp();
    }

    @autobind
    cleanUp() {
        this.props.cleanup();
        this.pageRequests.cleanup();
    }

    @autobind
    async checkAndRequestQuestion() {
        const {
            questionId,
            inheritDataFromId,
            questionDataForInherit,
            checkQuestionResponseById,
            getQuestionRequestByParentId,
            getQuestionRequest,
            resetQuestionDataForInherit,
        } = this.props;

        if (questionId) {
            await checkQuestionResponseById(questionId).promise;
        }

        if (inheritDataFromId && questionDataForInherit?.id !== inheritDataFromId) {
            await getQuestionRequestByParentId(inheritDataFromId, questionId).promise;
        } else {
            !inheritDataFromId && resetQuestionDataForInherit();

            questionId && await getQuestionRequest(questionId).promise;
        }
    }

    @autobind
    getConfirmContent(responsesCount) {
        return (
            <div className='remove-questions__modal-body'>
                <div>
                    <p className='remove-questions__modal-accented'>
                        { responsesCount } Customers already provided their responses for this question!
                    </p>
                    <p>
                        It will ruin the accuracy of analytic results.
                    </p>
                    <p>
                        Please confirm your action.
                    </p>
                </div>
            </div>
        );
    }

    @autobind
    onSubmitSuccess(isSave, id = null) {
        const {
            getQuestionsList,
            onCloseForm,
            reset,
            setIsNewQuestionCreated,
        } = this.props;

        if (isSave) {
            reset('QuestionForm');
            onCloseForm();
            setIsNewQuestionCreated(id, isSave);
        } else if(id) {
            setIsNewQuestionCreated(id, isSave);
        }

        getQuestionsList();
    }

    @autobind
    handleSubmit({ questionId, values }, isSave) {
        const {
            question,
            patchQuestion,
            createQuestion,
            activeLang,
            inheritDataFromId,
            questionDataForInherit,
        } = this.props;

        if (values.display_class === EDigiQuestionType.QuestionUnsubscribeDIGI) {
            values.answers = values.answers
                .filter(answer => answer.checked)
                // eslint-disable-next-line no-unused-vars
                .map(({ checked, ...resAnswer }) => ({
                    ...resAnswer,
                    names: {
                        ...resAnswer.names,
                        [activeLang]: {
                            language: activeLang,
                            name: resAnswer.names[activeLang]?.name || resAnswer.value,
                        },
                    },
                }));
        }

        // edit child questions case
        if (inheritDataFromId && questionId) {
            values = QuestionsServices.prepareChildQuestionForSaving({
                originalValues: question.data, // original child values
                formValues: values, // inherited and modified values
                parentDisplayClass: questionDataForInherit.display_class,
            });
        }

        return questionId
            ? patchQuestion({ questionId, values, isSave })
                .then(() => this.onSubmitSuccess(isSave))
            : createQuestion(values, inheritDataFromId, isSave)
                .then(({ id }) => this.onSubmitSuccess(isSave, id));
    }

    @autobind
    checkValueForSubmit(values) {
        //hotFix backend automatically sets is_multichoice = 0 if nothing is sent. Otherwise, it gives an error
        const isNotMultiChoice = values.configurable && values.configurable.is_multichoice === '0';

        if (isNotMultiChoice) {
            delete values.configurable.is_multichoice;

            !Object.keys(values.configurable).length && delete values.configurable;
        }

        return values;
    }

    @autobind
    onSubmit(values, isSave) {
        const { questionId } = this.props;

        // deep cloning for modifying the request's body
        const formValues = HelperService.deepClone(values);

        const model = {
            questionId,
            values: questionId
                ? formValues
                : this.checkValueForSubmit(formValues),
        };

        if (formValues?.display_class === 'QuestionUnsubscribeDIGI') {
            delete model.values?.configurable?.is_multichoice;
            delete model.values?.configurable?.min;
            delete model.values?.configurable?.max;
        }

        return this.handleSubmit(model, isSave);
    }

    @autobind
    showConfirm({ responsesCount }) {
        let resolver = () => {
        };
        let rejecter = () => {
        };

        const promise = new Promise((resolve, reject) => {
            resolver = resolve;
            rejecter = reject;
        });

        this.props.showConfirm({
            header: 'Warning!',
            content: this.getConfirmContent(responsesCount),
            labelSwitch: `I confirm I want to delete ${ responsesCount } already provided customers responses.`,
            successCallback: () => resolver(),
            errorCallback: () => rejecter(),
        });

        return promise;
    }

    render() {
        const {
            activeLang,
            configurableAnswers,
            configurableValues,
            activeSurvey,
            showAnswerSection,
            questionType,
            questionInitialForm,
            questionTypesOption,
            question,
            ivrErrors,
            answersErrors,
            questionId,
            onCloseForm,
            questionListLoading,
            showConfirm,
            rightsSurvey,
            responsesCount,
            questionViewForm,
        } = this.props;

        return (
            <DocumentTitle title='Feedback :: Survey Question'>
                <QuestionForm
                    questionTypesOption={ questionTypesOption }
                    configurableValues={ configurableValues }
                    onCloseForm={ onCloseForm }
                    language={ activeLang }
                    survey={ activeSurvey }
                    questionType={ questionType }
                    showConfirm={ showConfirm }
                    showAnswerSection={ showAnswerSection }
                    answers={ configurableAnswers }
                    onSubmit={ this.onSubmit }
                    initialValues={ questionInitialForm }
                    questionId={ questionId }
                    ivrErrors={ ivrErrors }
                    answersErrors={ answersErrors }
                    loading={ question.loading || question.ivrProcessing || questionListLoading }
                    rightsSurvey={ rightsSurvey }
                    responsesCount={ responsesCount }
                    questionViewForm={ questionViewForm }
                />
            </DocumentTitle>
        );
    }
}
