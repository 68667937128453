import { createSelector } from 'reselect';
import { metaDataToOptionSelector, questionTypeOptionsSelector, activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { activeLanguageSelector, languagesSelector, surveyGroupSelector } from '../../../modules/survey.selectors';
import { HelperService } from '/services';

export const questionsTextSelector = state => state.VocFeedback.survey.questions.questionsText;
export const questionsSelector = state => state.VocFeedback.survey.questions;
const languagesListSelector = state => state.VocFeedback.common.languages;

const SKIP = 1;
const GoToType = 2;

export const questionsListSelector = createSelector(
    [
        questionsSelector,
        questionTypeOptionsSelector,
        languagesSelector,
        surveyGroupSelector,
        activeLanguageSelector,
        questionsTextSelector,
        metaDataToOptionSelector,
        activeSurveySelector,
        languagesListSelector,
    ],
    (
        questions,
        questionTypes,
        languages,
        surveyGroup,
        activeLanguage,
        questionsText,
        metaData,
        activeSurveySelector,
        languagesList,
    ) => {
        const right = questions.list[0] && questions.list[0].nested_right;
        const additionalInfo = {
            language: activeLanguage,
            texts: questionsText.results.filter(text => text.language.iso_6391_code === activeLanguage),
            questionTypes,
            surveyGroup,
            metaData,
        };

        const list = fromFlatToTree(questions.list, additionalInfo, right, 0, 0, true, {}, languagesList);
        const activeChannel = surveyGroup.activeChannel === null ? activeSurveySelector.channel : surveyGroup.activeChannel;

        return {
            loading: questions.loading || questionsText.loading || surveyGroup.loading || languages.loading,
            activeChannel,
            list: [ 'SMS', 'IVR', 'DIGI' ].includes(activeChannel)
                ? getQuestionList(activeChannel, list)
                : [ ...list ],
            actionDisabled: questions.questionActionDisabled,
        };
    });

function fromFlatToTree(questions, questionsData, right, left = 0, level = 0, even = true, { parentId } = {}, languagesList) {
    return questions.filter(question => {
        const leftCondition = left < question.nested_left;
        const rightCondition = right > question.nested_right;
        const levelCondition = question.nested_level === level + 1;

        return leftCondition && rightCondition && levelCondition;
    },
    ).map((question, index) => {
        const {
            title,
            nested_right,
            nested_left,
            nested_level,
            expanded = true,
        } = question;
        const questionText = questionsData.texts.find(text => text.question.id === question.id);

        const contractedQuestions = HelperService.getFromStorage(localStorage, 'contractedQuestions') || {};
        const savedExpanded = typeof contractedQuestions[question.id] === "boolean"
            ? contractedQuestions[question.id]
            : expanded;

        const stripped = nested_level === 1 ? !Boolean(index % 2) : even;
        const getParentId = parentId ? parentId : 'root';

        const getFullLanguagesList = questionsData.surveyGroup.surveyGroupLanguages.data.map(languageData => {
            const findFullLangData = languagesList.results.find(language => language.iso_6391_code === languageData.language);

            return {
                value: findFullLangData.id,
                label: findFullLangData.name,
            };
        });

        return {
            ...question,
            title: title ? title : 'Empty title',
            isEmptyTitle: !Boolean(title),
            subtitle: questionText && questionText.text ? questionText.text : 'Empty text for current language',
            language: 'EN',
            questionLink: `/feedback/survey/${ questionsData.surveyGroup.id }/questions/channel/${ question.survey.id }/question/${ question.id }`,
            hasFlow: question.question_flows && Boolean(question.question_flows.length),
            hasCorruptedFlow: question.question_flows.some(flow => hasCorruptedFlow(flow, questions, getFullLanguagesList)),
            type: question.question_type.name,
            showConfigurablePopover: questionsData.questionTypes.some(type => type.question_type === question.question_type.id),
            expanded: savedExpanded,
            stripped,
            parentId: getParentId,
            children: fromFlatToTree(questions, questionsData, nested_right, nested_left, nested_level, stripped, { parentId: question.id }, languagesList),
        };
    });
}

/*
 * this for flow - questions list
 * */
const hasCorruptedFlow = (flow, questions, getFullLanguagesList) => {
    const checkPresenceOptions = flow.conditions.some(({ value }) => value.question_id && !Boolean(questions.find(question => question.id === value.question_id)));
    const checkNextQuestion = !Boolean(questions.find(question => question.id === flow.next_question));
    const checkMatchingValue = flow.conditions.some(({ value }) => {
        const flowTargetQuestion = questions && questions.find(el => el.id === value.question_id);
        const emptyNotEmptyCheck = ![ 'is_empty', 'is_not_empty' ].includes(value.expression);

        return emptyNotEmptyCheck
            && flowTargetQuestion
            && flowTargetQuestion.selects
            && !flowTargetQuestion.selects.includes(value.matching_value);
    });

    const checkIfLanguageNotExist = flow.next_language !== null && !getFullLanguagesList.find(language => language.value === flow.next_language);

    return checkPresenceOptions
        || (flow.action_type.id === GoToType && checkNextQuestion)
        || checkMatchingValue
        || checkIfLanguageNotExist;
};

const validateFlowRoot = (flows, children) => {
    return flows.some(({ next_question, question, conditions }) => {
        if (next_question === question) {
            const targetQuestionId = conditions.map(({ value }) => value.question_id);
            const childrenId = children.map(({ id }) => id);

            return !!targetQuestionId.filter(question_id => childrenId.find(id => question_id === id)).length;
        } else {
            return children.some(({ id }) => id === next_question);
        }
    });
};

const validateFlowChildren = (flows, children, parentId) => {
    return flows.some(({ conditions, action_type }) => {
        return action_type.id !== SKIP
            || conditions.some(({ value }) => {
                return value.question_id === parentId
                    || children.some(({ id }) => id === value.question_id);
            });
    });
};

const checkUnconditionalSkip = question_flows => {
    return question_flows.some(flow =>
        flow.action_type.name === "SKIP" && !flow.conditions.length,
    );
};

const getQuestionList = (activeChannel, list) => {
    return activeChannel === 'DIGI'
        ? list.map(question => {
            const { hasCorruptedFlow, children, question_flows, id } = question;
            const needToCheckFlow = !hasCorruptedFlow && question_flows.length && children.length;

            const isUnconditionalSkipForParent = checkUnconditionalSkip(question_flows);

            return {
                ...question,
                hasCorruptedFlow: needToCheckFlow
                    ? validateFlowRoot(question_flows, children)
                    : hasCorruptedFlow,
                isUnconditionalSkip: isUnconditionalSkipForParent,
                children: children.map(child => {
                    const { hasCorruptedFlow, question_flows } = child;
                    const needToCheckFlow = !hasCorruptedFlow && question_flows.length;

                    return {
                        ...child,
                        isUnconditionalSkip: isUnconditionalSkipForParent || checkUnconditionalSkip(question_flows),
                        hasCorruptedFlow: needToCheckFlow
                            ? validateFlowChildren(question_flows, children, id)
                            : hasCorruptedFlow,
                    };
                }),
            };
        })
        : list.map(question => {
            return {
                ...question,
                isUnconditionalSkip: checkUnconditionalSkip(question.question_flows),
            };
        });
};
