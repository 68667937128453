import React, { useState } from 'react';
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import { VocModal, RangeDatePicker, VocSelect } from '/components';
import { HelperService } from '/services';
import { truncateUploadModalSelect } from "../../modules/VocStore.selectors";
import { closeTruncateUploadModal, truncateUpload } from "../../modules/VocStore.modules";

export const TruncateUploadModal = () => {
    const { t } = useTranslation();
    const [ dateColumn, setDateColumn ] = useState(null);
    const [ range, setRange ] = useState({
        from: null,
        to: null,
    });

    const data = useSelector(truncateUploadModalSelect);
    const dispatch = useDispatch();
    const isDisabled = !range.from || !range.to;

    const onDatesChanged = range => {
        setRange(range);
    };

    const onDateColumnChanged = ({ value }) => {
        setDateColumn(value);
    };

    const onApply = () => {
        dispatch(truncateUpload({
            id: data.uploadId,
            data: {
                dateFilter: {
                    from: HelperService.formatDate(range.from, 'DD-MM-YYYY'),
                    to: HelperService.formatDate(range.to, 'DD-MM-YYYY'),
                },
                customDateColumn: dateColumn,
            },
        }));

        dispatch(closeTruncateUploadModal());
    };

    const getOptionDate = () => {
        const options = [
            {
                value: null,
                label: t('questionnaireEndTime'),
            },
            {
                value: 'updated',
                label: t('recordChangeTimeUpdated'),
            },
        ];

        if (data.type === "survey") {
            options.push({
                value: 'questionnaire_change_time',
                label: t('questionnaireChangeTime'),
            });
        }

        return [
            ...options,
            ...HelperService.mapObjToSelect(data.dateOptions, 'id', 'name'),
        ];
    };

    const cleanupModal = () => {
        setRange({
            from: null,
            to: null,
        });
    };

    const getModalBody = () => {
        const optionDate = getOptionDate();

        return (
            <>
                <VocSelect
                    placeholder={ t('placeholders.chooseDate') }
                    label={ t('field.dateColumn') }
                    isHorizontal
                    value={ HelperService.getSelectedValue(optionDate, dateColumn) }
                    options={ optionDate }
                    onChange={ onDateColumnChanged }
                    portal
                />
                <RangeDatePicker
                    dateFormat={ HelperService.datePickerMask }
                    handleChange={ onDatesChanged }
                    values={ range }
                    labels={{
                        start: t('field.from'),
                        end: t('field.to'),
                    }}
                    noMargin
                    enablePlaceholder
                />
            </>
        );
    };

    const getModalFooter = () => {
        return (
            <>
                <Button
                    outline
                    color='white'
                    onClick={ () => dispatch(closeTruncateUploadModal()) }
                >
                    <FontAwesomeIcon icon='times' />
                    &nbsp;
                    <span>{ t('cancel') }</span>
                </Button>
                <Button
                    color='primary'
                    disabled={ isDisabled }
                    onClick={ onApply }
                >
                    <FontAwesomeIcon icon='check' />
                    &nbsp;
                    <span>{ t('apply') }</span>
                </Button>
            </>
        );
    };

    return (
        <VocModal
            isOpen={ data.show }
            toggle={ () => dispatch(closeTruncateUploadModal()) }
            modalClassName={ 'modal-scrollable' }
            header={ t('truncateUpload') }
            footer={ getModalFooter() }
            onClosed={ cleanupModal }
        >
            { getModalBody() }
        </VocModal>
    );
};
