import React, { Component } from 'react';
import { RangeDatePicker, FilterCard, VocSelect } from '/components';
import { Row, Col } from 'reactstrap';
import { autobind } from 'core-decorators';
import { HelperService } from '/services';
import PropTypes from 'prop-types';
import { StatisticFiltersButton } from './StatisticFiltersButton/';

export class StatisticFilters extends Component {
    @autobind
    renderApplyButton() {
        const {
            loading,
            applyFilters,
        } = this.props;

        return <StatisticFiltersButton
            disabled={ loading }
            apply={ applyFilters }
        />;
    }

    render() {
        const {
            possibleRanges,
            statisticDateRanges,
            filters,
            onChangePossibleRanges,
            onChangeDateRanges,
            dateRangeHandler,
            dateRange,
            showDateRangeFilter,
        } = this.props;
        const rangeValue = HelperService.getSelectedValue(possibleRanges, filters.range);
        const dateRanges = HelperService.getSelectedValue(statisticDateRanges, dateRange);

        const dates = {
            from: filters.start_time ? new Date(filters.start_time) : null,
            to: filters.end_time ? new Date(filters.end_time) : null,
        };

        return (
            <FilterCard footer={ this.renderApplyButton() }>
                <div className={ 'statistic_result__filter' }>
                    {
                        showDateRangeFilter
                        && <VocSelect
                            name="rangeValue"
                            label={ 'Filtered by' }
                            isClearable={ false }
                            isHorizontal={ false }
                            options={ possibleRanges }
                            value={ rangeValue }
                            onChange={ onChangePossibleRanges }
                            className={ 'export-results-toolbar-select' }
                        />
                    }
                </div>
                <div className={ 'statistic_result__filter' }>
                    <VocSelect
                        name="dateRanges"
                        label={ 'Date range' }
                        isClearable={ false }
                        isHorizontal={ false }
                        options={ statisticDateRanges }
                        value={ dateRanges }
                        onChange={ onChangeDateRanges }
                        className={ 'export-results-toolbar-select' }
                    />
                </div>
                <div className={ 'statistic_result__filter' }>
                    <Row>
                        <Col md={ 6 }>
                            <label
                                className={ 'statistic_result__filter-label' }
                            >
                                Start date
                            </label>
                        </Col>
                        <Col md={ 6 }>
                            <label
                                className={ 'statistic_result__filter-label' }
                            >
                                End date
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={ 12 }>
                            <RangeDatePicker
                                withLabel={ false }
                                dateFormat={ HelperService.datePickerMask }
                                handleChange={ dateRangeHandler }
                                isHorizontal={ true }
                                values={ dates }
                            />
                        </Col>
                    </Row>
                </div>
            </FilterCard>
        );
    }
}

StatisticFilters.defaultProps = {
    showDateRangeFilter: true,
};

StatisticFilters.propTypes = {
    possibleRanges: PropTypes.arrayOf(PropTypes.object),
    statisticDateRanges: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.object,
    onChangePossibleRanges: PropTypes.func,
    onChangeDateRanges: PropTypes.func,
    dateRangeHandler: PropTypes.func,
    dateRange: PropTypes.any,
    loading: PropTypes.bool,
    applyFilters: PropTypes.func,
};
