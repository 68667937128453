import React from 'react';
import { useTranslation } from 'react-i18next';

import { VocTableV2, TableRightsColumn, VocRightsHOC } from '/components';
import { UploadProgressSection, UploadActionsFormatter } from '../';

const UploadsTable = ({
    shares,
    filters,
    loading,
    tableData,
    uploadsCount,
    updateFilters,
    requestTrigger,
    getSharedRights,
    showConfirm,
    getUploadRelations,
    uploadToDelete,
    uncoverUpload,
    userRights,
    cancelUpload,
    deleteUpload,
    downloadErrors,
    showApplianceModal,
    updateCurrentLocation,
    showExportFilterModal,
    toggleTruncateUploadModal,
    reLemmatization,
    pageRequests,
    toggleSocialSourceModal,
    openInviteModal,
    openSourceDetailsModal,
    onInfoButtonClick,
    onRenameButtonClick,
}) => {
    const { t } = useTranslation();

    const getTableOptions = () => ({
        updateFilters,
        requestTrigger: requestTrigger,
        haveSearch: true,
        rowStyle: {
            height: '40px',
        },
    });

    const progressSectionFormatter = cell => {
        return <UploadProgressSection { ...cell }/>;
    };

    const sharedFormatter = cell => {
        return <TableRightsColumn
            { ...cell }
            getShares={ id => getSharedRights(id, 'upload') }
            shares={ shares }
        />;
    };

    const uploadActionsFormatter = cell => {
        return <UploadActionsFormatter
            { ...cell }
            filters={ filters }
            updateFilters={ updateFilters }
            updateCurrentLocation={ updateCurrentLocation }
            showConfirm={ showConfirm }
            showApplianceModal={ showApplianceModal }
            downloadErrors={ downloadErrors }
            getUploadRelations={ getUploadRelations }
            uploadToDelete={ uploadToDelete }
            uncoverUpload={ uncoverUpload }
            deleteUpload={ deleteUpload }
            initiateSourceExport={ showExportFilterModal }
            toggleTruncateUploadModal={ toggleTruncateUploadModal }
            userRights={ userRights }
            cancelUpload={ cancelUpload }
            reLemmatization={ reLemmatization }
            requestTrigger={ requestTrigger }
            pageRequests={ pageRequests }
            toggleSocialSourceModal={ toggleSocialSourceModal }
            openInviteModal={ openInviteModal }
            openSourceDetailsModal={ openSourceDetailsModal }
            onInfoButtonClick={ onInfoButtonClick }
            onRenameButtonClick={ onRenameButtonClick }
        />;
    };

    const getDataSettings = () => [
        {
            field: 'title',
            title: t('field.title'),
            formatter: 'conditionTooltip',
            sort: true,
            style: {
                width: '200px',
            },
        },
        {
            field: 'originalName',
            title: t('field.originalName'),
            formatter: 'conditionTooltip',
            sort: true,
            style: {
                width: '200px',
            },
        },
        {
            field: 'created',
            title: t('field.uploaded'),
            formatter: 'date',
            sort: true,
            style: {
                width: '134px',
            },
        },
        {
            field: 'afterloadedAt',
            title: t('field.updated'),
            formatter: 'date',
            sort: true,
            style: {
                width: '134px',
            },
        },
        {
            field: 'processedItemsCount',
            title: t('field.progress'),
            formatter: progressSectionFormatter,
            sort: true,
            style: {
                width: '150px',
            },
        },
        {
            field: 'totalRowsCount',
            title: t('field.records'),
            sort: true,
            style: {
                textAlign: 'center',
                width: '95px',
            },
        },
        {
            field: 'shared',
            title: t('field.role'),
            formatter: sharedFormatter,
            style: {
                textAlign: 'center',
                width: '55px',
            },
        },
        {
            field: 'data',
            title: t('field.actions'),
            formatter: uploadActionsFormatter,
            style: {
                width: '100px',
                textAlign: 'center',
            },
        },
    ];

    return (
        <VocTableV2
            data={ tableData }
            total={ uploadsCount }
            loading={ loading }
            dataSettings={ getDataSettings() }
            options={ getTableOptions() }
            filters={ filters }
        />
    );
};

export default VocRightsHOC({
    type: 'upload',
})(UploadsTable);
