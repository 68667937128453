import { useEffect, useState } from 'react';

export const useSplitPane = ({
    initSizes,
    sizeChanged,
}: {
    initSizes: number[] | string[] | null,
    sizeChanged: (sizes: number[] | string[]) => void,
}) => {
    const [ sizes, setSizes ] = useState<number[] | string[]>(initSizes || [ '80%', '20%' ]);
    const [ onDrag, setOnDrag ] = useState(true);

    const onChange = (newSizes: number[] | string[]) => {
        !onDrag && setOnDrag(true);
        setSizes(newSizes);
    };

    const onDragEnd = () => setOnDrag(false);

    useEffect(() => {
        !onDrag && sizeChanged(sizes);
    }, [ onDrag ]);

    return {
        onChange,
        onDragEnd,
        sizes,
    };
};
