import { ReactElement, RefObject } from 'react';
import { Selection } from 'd3';
import { HandleProps as SliderHandleProps } from 'rc-slider/lib/Handles/Handle';
import { TFunction } from 'i18next';

import { TApplyLabelModalData, TLabelPopoverData } from './global.model';
import { IDrillDownSettingsItem, IGadgetData as IFullGadgetData } from '/visual/models';

export type chartData = {
    count: number,
    items: chartDataItem[],
    level: number,
};

export type chartDataItem = {
    accSum: number,
    affection: number,
    average: number,
    count: number,
    id: string,
    innerText?: string | null,
    items: chartDataItem[],
    label: string,
    level: number,
    median: number,
    name: string,
    npsScore: number,
    percent: number | null,
    sum: number,
    type: string,
    variance: number,
    words?: wordDataType[],
};

export type axisLabelType = {
    count: string | null,
    group: string | null,
    score?: string | null,
}

export type barData = {
    customId: string,
    bars: barSliceData[],
    id: string,
    label: string,
    value: string,
    count: number,
    percent: number | null,
    total: number,
    totalAnswers: number,
    type: string,
    variance?: number,
    average?: number,
    npsScore?: number,
    median?: number,
    sum?: number,
    affection?: number,
}

export type TParentData = {
    label: string,
    id: string,
};

export type barSliceData = {
    accSum?: number,
    affection?: number,
    average?: number,
    color?: string,
    count: number,
    customId: string,
    id: string,
    label: string,
    median?: number,
    parentData?: TParentData,
    percent: number | null,
    npsScore?: number,
    score?: number,
    sum?: number,
    value: string,
    variance?: number,
    groupIndex?: number,
    maxNumber?: number,
}

export type barSliceWithCoordinatesData = {
    color: string,
    count: number,
    customId: string,
    id: string,
    label: string,
    parentData: TParentData,
    percent: number,
    score: number,
    value: string,
    xPosition: string,
    yPosition: number,
    yStartPosition: number,
}

export type selectedColor = {
    color?: string,
    brighten?: number,
    colors?: string[],
};

export type color = {
    key: string,
    value: string,
};

export type getColorBase = {
    selectedColor: selectedColor,
    colors: color[],
    func: string,
};

export interface IGetRandomColorSet {
    items: string[],
    selectedColor: selectedColor,
}

export interface IGetColor extends getColorBase {
    itemId: string,
    ids: string[],
    randomColorSet: string[],
}

export type TLabelData = {
    key: string,
    value: string,
    type: string,
};

export type filterLabel = {
    color: string,
    customId: string,
    id: string,
    label: string,
    score?: number,
    disableDrag?: boolean,
    target?: string,
    coords?: {
        y: number,
        x: number,
    }
};

export type TRangeTooltipItem = Pick<filterLabel, 'id' | 'label'>;

export type TRangeTooltipValueFormatter = {
    index: number,
    list: TRangeTooltipItem[],
};

export type THighlightGadget = {
    updateType: EUpdateGadgetTypes | null,
    gadgetNode?: HTMLElement | null,
};

export type filterRequiredWithScore = Omit<filterLabel, 'score'> & { score: number };

export type openColorPickerType = Omit<filterLabel, 'score'>;

export type preLineLabel = {
    label: string,
    value: string,
};

export type lineLabel = {
    id: string,
    label: string,
}

export type preLineItem = {
    label: string,
    percents: (number | null)[],
    type: string,
    values: (number | null)[],
};

export type lineItem = {
    color: string,
    customId: string,
    id: string,
    label: string,
    percents: (number | null)[],
    type: string,
    values: (number | null)[],
    data: lineItemDataType,
};

export type lineItemDataType = {
    absolute: (number | null)[],
    relative: (number | null)[],
}

export type bubbleData = {
    color: string,
    count: number,
    customId: string,
    id: string,
    label: string,
    score: number,
    scoreLabel: string,
    segmentGroupTitle: string,
    value: number,
    valueLabel: string,
};

// radar
export type radarDotType = {
    axis: string | number,
    value: number,
    categoryValue: number,
    customId: string,
    parentId: string, // polygon id
    parentLabel: string, // polygon label
}

export type radarPolygonType = {
    polygonPoints: [number, number],
    color: string,
    customId: string,
    id: string,
}

export enum getDotCoordsAxisEnum {
    X = 'x',
    Y = 'y',
}

export type segmentGroupData = {
    label: string,
    value: number,
}

export type npsDataItemType = {
    count: number,
    id: string,
    label: string,
    score: number,
    customId: string,
};

export type npsDataType = {
    data: npsDataItemType[],
    id: string,
    label: string,
    color: string,
    customId: string,
};

export type TNpsItemPart = {
    id: string | null,
    count: number | null,
};

export type TNpsItemWithParts = {
    id: string | null,
    label: string | null,
    parts: TNpsItemPart[],
};

export type mockNpsType = {
    id: string,
    label: string,
    percent: number,
    count: number,
    value: string,
}

export type contentSettingsFunc = {
    affection: number,
    average: number,
    accSum: number,
    count: number,
    median: number,
    percent: number,
    npsScore: number,
    variance: number,
};

export type tickData = {
    id: string | null,
    label: string | null,
};

export type domainTickData = {
  index: number,
  id: string,
};

export type sliderData = {
    disableSlider: boolean,
    min: number,
    max: number,
    value: number[] | number,
};

export type dotData = {
    value: number | null,
    lineIndex: number,
    customId: string,
};

export type onItemEnterType = {
    parentData?: TParentData,
    label: string,
    percent: number | null,
    score: number | null,
};

export type TPrivateSetHighlightedLine = {
    highlightedLineId: string | number | null,
    dotCustomId?: string,
    lineGroupClass?: string,
    dotClass?: string,
    lineClass?: string,
};

export type chartEnum = {
    surveys_bar_chart: string,
    stacked_bar_chart: string,
    line_chart: string,
};

export enum EUpdateGadgetTypes {
    SOCKET_UPDATE = 'SOCKET_UPDATE',
    MANUAL_UPDATE = 'MANUAL_UPDATE',
}

export interface IChartData {
    showEmptyGadgetLoading: boolean;
    chartData: any,
    gadgetData: IFullGadgetData,
    loaded: boolean,
    loading: boolean,
    needToUpdate: boolean,
    updateType:EUpdateGadgetTypes,
    error?: string,
}

export interface IGadgetData {
    title: string,
    labels: TLabelData[],
    colors: color[],
    id: string,
    dataSettings: any,
    contentSettings: any,
    parent: any;
    type: EGadgetType,
    hasChildDashboard: boolean,
    childDashboardId: string | null,
}

export interface IChartLabelItem {
    isDisabled: boolean,
    labelData: filterLabel,
    longLabel: boolean,
    gadgetId: string,
    onColorPickerOpen: (data: filterLabel) => void,
    onMouseEnter: (id: string) => void,
    onMouseLeave: () => void,
    onCLickLabel: (customId: string, label: string, id: string, coords: { y: number, x: number }) => void,
    isActive?: boolean,
    isOverlay?: boolean,
}

export interface IBarChart {
    dataSet: barData[],
    allGroups: barData[],
    showScore: boolean,
    percentage: boolean,
    decimalDigits: string,
    axisLabels: axisLabelType,
    highlightedLabel: string | null,
    gadgetId: string,
    gadgetFunction: string,
    drillDownEnabled: boolean,
    chartType: string,
    toolTipRef: RefObject<HTMLDivElement>,
    setChangeTickModal?: (data: TLabelPopoverData) => void,
    resetTickData: any,
    onApplyLabelModal: (labelData: TApplyLabelModalData) => void,
    saveTickOrder: any,
    setColorPickerData: (data: IColorPickerData) => void,
    drillDownFromSelection: (d: IDrillDownSelection) => void,
}

export interface IBarStackedChart extends IBarChart {
    isNpsChart: boolean,
    npsData: npsDataType,
}

export interface IBaseChartService {
    chartType: string,
    svgRef: SVGSVGElement,
    toolTipRef: HTMLHeadingElement,
    gadgetFunction: string,
    drillDownEnabled?: boolean,
    gadgetId: string,
    percentage?: boolean,
    decimalDigits?: string,
    withVerticalGrid?: boolean,
    withHorizontalGrid?: boolean,
    showScore?: boolean,
    t: TFunction,
    setTickModal?: ((props: TLabelPopoverData) => void) | null,
    resetTickModal?: (() => void) | null,
    saveTickOrder?: ((order: string[]) => void) | null,
    setXAxisSize?: ((xAxisHeight: number) => void) | null,
    setColorPickerData: (data: IColorPickerData) => void,
    setInnerCircleData?: (data: TLabelPopoverData) => void,
    drillDownFromSelection?: (d: IDrillDownSelection) => void,
}

export interface ILineChart {
    labels: lineLabel[],
    lines: lineItem[],
    level: number,
    showScore: boolean,
    percentage: boolean,
    axisLabels: axisLabelType,
    highlightedLabel: string | null,
    gadgetId: string,
    gadgetFunction: string,
    drillDownEnabled: boolean,
    chartType: string,
    isNpsChart: boolean,
    npsData: npsDataType,
    toolTipRef: RefObject<HTMLDivElement>,
    setChangeTickModal: ((props: TLabelPopoverData) => void) | null,
    resetTickData: any,
    onApplyLabelModal: (labelData: TApplyLabelModalData) => void,
    setColorPickerData: (data: IColorPickerData) => void,
    drillDownFromSelection: (d: IDrillDownSelection) => void,
}

export interface IBubbleChart {
    bubbles: bubbleData[],
    showScore: boolean,
    longLabel: boolean,
    percentage: boolean,
    axisLabels: axisLabelType,
    highlightedLabel: string | null,
    gadgetId: string,
    gadgetFunction: string,
    chartType: string,
    toolTipRef: RefObject<HTMLDivElement>,
    onApplyLabelModal: (labelData: TApplyLabelModalData) => void,
    setColorPickerData: (data: IColorPickerData) => void,
}

export interface IRadarChart {
    chartType: string,
    svgRef: SVGSVGElement,
    toolTipRef: HTMLHeadingElement,
    wrapperRef: HTMLHeadingElement,
    data: object,
    maxValue: number,
    allAxis: string[],
    gadgetId: string,
    gadgetFunction: string,
    setColorPickerData: (data: IColorPickerData) => void;
}

export interface ICheckNPS {
    factTypes: string[],
    func: string,
    secondGroupBy: string | null,
}

export interface IAddMissingNpsSegments {
    segments: any[],
    customIdMaker: (id: string) => string | null,
}

// BarChartService Interfaces
export interface IDrawXBandAxis {
    domainValues: Iterable<string>,
    rangeFrom: number,
    rangeTo: number,
    maxCount: number,
}

export interface IDrawBars {
    groups: barData[],
    groupClass?: string,
}

// LineChartService Interfaces
export interface IDrawLines {
    lines: lineItem[],
    labels: lineLabel[],
    level: number,
}

// StackedBarChartService Interfaces
export interface IDrawStackedBars {
    dataSet: barData[],
    groupClass?: string,
}

// BubbleChartService Interfaces
export interface IDrawBubbleXAxis {
    domainMin: number,
    domainMax: number,
    rangeFrom: number,
    rangeTo: number,
    additionalSettings?: { prop: string, value: any }[],
    tickFormat?: (n: number | { valueOf(): number; }) => string,
}

export interface IDrawBubbles {
    bubbles: bubbleData[],
    longLabel: boolean,
}

// BaseChartService Interfaces
export interface IChartServiceInit {
    svgRef: SVGSVGElement,
    toolTipRef: HTMLHeadingElement,
    gadgetId: string,
}

export interface IPreConfig {
    redraw?: ((d: barData[], allList: barData[]) => void) | null,
    withDnD?: boolean,
    dragAndDropDataSet?: any[],
    allList?: any[],
    withPseudoAxis?: boolean,
    xAxisSize?: number | null,
    tickList?: any[],
}

export interface IDrawXAxis {
    domainValues: string[],
    rangeFrom: number,
    rangeTo: number,
    bandPadding?: number,
    bandInnerPadding?: number,
    bandOuterPadding?: number,
    additionalSettings?: { prop: string, value: any }[],
}

export interface IDrawYAxis {
    domainMin: number,
    domainMax: number,
    rangeFrom: number,
    rangeTo: number,
    tickFormat?: (dv: number | { valueOf(): number; }, i: number) => string,
}

export interface IDrawContainer {
    svgWidth: number,
    svgHeight: number,
    chartWidth: number,
    chartHeight: number,
    transformValue: string,
    isNpsChart?: boolean,
    npsData?: npsDataType | null,
}

export interface IDrawPieContainer {
    svgWidth: number,
    svgHeight: number,
    transformValue: string,
}

export interface IEmptyGadget {
    errorMessage?: string | null,
    message?: string | null,
    isLoading: boolean,
    onEdit?: () => void,
}

export interface IColorPickerData {
    open: boolean,
    coords?: {
        y: number,
        x: number,
    },
    target?: string | null,
    color?: string | null,
    elementId?: string | null,
}

export type changeColorHandlerType = {
    elementId: string,
    colorHex: string,
}

export type wordDataType = {
    _id: string | number,
    count: number,
}

export type excludeLemma = {
    value: string | number,
    word: string | number,
}

export interface IDrillDownSelection {
    group: { id: string | number | null },
    superGroup?: { id: string | number | null } | null,
    level?: number,
}

export interface IDataForFormatting {
    chartData: chartDataItem,
    gadgetData: IGadgetData,
    forSelectorOnly?: boolean,
}

export interface IFormattedDataByChartType {
    chartType: string,
    dataForFormatting: IDataForFormatting,
}

export type drillDownOptionType = {
    label: string | number,
    value: string | number,
    list?: drillDownOptionType[],
};

export type drillDownStateType = {
    superGroupOptions: drillDownOptionType[],
    groupOptions: drillDownOptionType[],
    withSuperGroup: boolean,
    withoutStandardGroups: boolean,
    initSelectedSuperGroup: drillDownOptionType | null,
    initSelectedGroup: drillDownOptionType | null,
    selectedSuperGroup: drillDownOptionType | null,
    selectedGroup: drillDownOptionType | null,
    isDirty: boolean,
}

export interface IDrillDashboardConfiguration {
    loading: boolean,
    gadget: IGadgetData,
    chart: IChartData,
    settings: IDrillDownSettingsItem[],
}

export interface IResizeObserverDimensions {
    x: number,
    y: number,
    width: number,
    height: number,
    top: number,
    right: number,
    bottom: number,
    left: number,
}

export interface IFormatDataBarChart {
    groups: barData[],
    filterLabels: filterRequiredWithScore[],
    records: number,
    axisLabels: {
        count: string | null,
        group: string | null,
        score: string | null,
    },
    allowSort: boolean,
    isNpsChart: boolean,
    npsData: {
        data: any[],
        id: string,
        label: string,
        color: string,
        customId: string,
    },
}

export interface IRotateTicks {
    chartWidth: number,
    dataSet: { label: string | null, id: string }[],
    xAxisView: Selection<SVGElement, any, HTMLElement, any>,
    withPseudoAxis: boolean,
}

export interface IWrapText {
    horizontalWidth: number,
    element: SVGElement,
    x: string,
    dy: string,
    label: string | null,
    isRotated: boolean,
    isSingleLineText: boolean,
}

export interface ISliderHandle {
    originNode: ReactElement<SliderHandleProps>,
    visible: boolean,
    rangeValue: number,
    tipFormatter?: (index: number) => string | null,
}

export interface ISlicedLineChartData {
    labels: lineLabel[],
    lines: lineItem[],
    npsData: npsDataType,
}

export interface ISlicedBarChartData {
    groups: barData[],
    npsData: npsDataType,
}

export interface IFormatDataLineChart {
    labels: lineLabel[],
    lines: lineItem[],
    level: number,
    filterLabels: filterLabel[],
    records: number,
    axisLabels: axisLabelType,
    isNpsChart: boolean,
    npsData: npsDataType,
}

export type TPieSegment = {
    id: string,
    label: string,
    percent: number | null,
    count: number,
    customId: string,
    color: string,
};

export type TPieChartData = Omit<chartDataItem, 'words' | 'items' | 'label'>
    & { totalLabelTransKey: string, totalValue: number | string | null, items: any[] };

export interface IFormatDataPieChart {
    data: TPieChartData | null,
    filterLabels: filterRequiredWithScore[],
}

export type TGetCustomChartElementId = {
    prefix?: string,
    groupId?: string,
    itemId: string
    divider?: string,
}

export type BodyItem = {
    [key: string]: any;
}
