import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autobind } from 'core-decorators';

import { VocTableV2, SwitchControl, TooltipWrapper } from '/components';
import { DownloadLink, ExplanationIcon } from '/feedback/components';
import { ChannelLabel } from '/scenes/VocFeedback/components/ChannelLabel';

import './ExportQueueTable.scss';

export class ExportQueueTable extends Component {
    @autobind
    fileFormatter(cell, obj) {
        return (
            <div>
                {
                    cell.isExportCleaned
                    && <ExplanationIcon
                        name={ 'ban' }
                        title={ `File was deleted` }
                    />
                }
                {
                    cell.isDownloadPossible
                    && <DownloadLink
                        id={ cell.id }
                        fileName={ cell.fileName }
                        type={ cell.type }
                        title={ 'Download export file' }
                        onClick={ () => this.props.onDownloadFile({
                            id: cell.id,
                            type: cell.type,
                            fileName: cell.fileName,
                            isNewExporter: obj.isNewExporter,
                        }) }
                    />
                }
                {
                    cell.isExportFailed
                    && <ExplanationIcon
                        title={ `Please get in contact with SandSIV support and refer to export ID ${cell.id}` }
                    />
                }
            </div>
        );
    }

    @autobind
    channelFormatter(cell) {
        return cell && <ChannelLabel title={ cell }/>;
    }

    @autobind
    nameFormatter(cell, obj) {
        return <>
            {
                !obj.isNewExporter
                && <TooltipWrapper value='Legacy exporter type' force>
                    <span className={ 'export-list_old-exporter-icon' }>
                        <FontAwesomeIcon icon={ 'wrench' }/>
                    </span>
                </TooltipWrapper>
            }
            <span>{ cell }</span>
        </>;
    }

    @autobind
    createCustomToolBar() {
        const { toggleShowNewQueue, showNewQueue } = this.props;

        return (
            <div className={ '' }>
                <TooltipWrapper value={ 'This switch will be deprecated.' } force>
                    <label>
                        <SwitchControl
                            name="switcher-hidden-uploads-list"
                            type={ 'small' }
                            value={ showNewQueue }
                            changeHandler={ toggleShowNewQueue }
                        />
                        &nbsp;
                        Show only new exports
                    </label>
                </TooltipWrapper>
            </div>
        );
    }

    getTableOptions() {
        return {
            customTableHeader: this.createCustomToolBar(),
            requestTrigger: this.props.requestTrigger,
            haveSearch: true,
            updateFilters: this.props.updateFilters,
        };
    }

    render() {
        const {
            total,
            loading,
        } = this.props.tableState;

        const settings = [
            {
                field: 'id',
                title: 'ID',
                style: {
                    width: '60px',
                },
                sort: true,
            },
            {
                field: 'creation',
                title: 'Date Added',
                formatter: 'date',
                sort: true,
            },
            {
                //todo remove after delete old exporters
                field:  this.props.showNewQueue ? 'date_from' : 'date_start',
                formatter: 'date',
                sort: true,
                title: 'Start',
            },
            {
                //todo remove after delete old exporters
                field: this.props.showNewQueue ? 'date_to' : 'date_end',
                formatter: 'date',
                sort: true,
                title: 'End',
            },
            {
                field: 'survey__survey_group__name',
                formatter: 'conditionTooltip',
                sort: true,
                title: 'Survey Title',
                style: {
                    width: '130px',
                },
            },
            {
                field: 'survey__channel__name',
                formatter: this.channelFormatter,
                sort: true,
                title: 'Channel',
                style: {
                    textAlign: 'center',
                },
            },
            {
                //todo remove after delete old exporters
                field: this.props.showNewQueue ? 'export_config__name' : 'exporter__name',
                formatter: this.nameFormatter,
                sort: true,
                title: 'Exporter',
                style: {
                    width: '160px',
                },
            },
            {
                field: 'state',
                sort: true,
                title: 'State',
                style: {
                    width: '100px',
                },
            },
            {
                field: 'link',
                formatter: this.fileFormatter,
                title: 'File',
                style: {
                    width: '50px',
                    textAlign: 'center',
                },
            },
        ];

        return (
            <VocTableV2
                dataSettings={ settings }
                data={ this.props.data }
                options={ this.getTableOptions() }
                filters={ this.props.filters }
                total={ total }
                loading={ loading }
            />
        );
    }
}

ExportQueueTable.propTypes = {
    requestTrigger: PropTypes.func,
    updateFilters: PropTypes.func,
    data: PropTypes.array,
    tableState: PropTypes.shape({
        page: PropTypes.number,
        loading: PropTypes.bool,
    }),
};
