import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ProductFruits } from 'react-product-fruits';
import cx from 'classnames';

import { PrivateRoute, IframeComponent } from '/components';
import { ZendeskSnippet, I18nService } from '/services';
import { Header } from './Header';
import { VocStore } from './VocStore';
import { VocFeedback } from './VocFeedback';
import { VocMine } from './VocMine';
import { VocAI } from './VocAI';
import { OtherRoutes } from './OtherRoutes';
import { VocLabs } from './VocLabs';
import { VocVisual } from "./VocVisual";
import { updateCurrentLocation } from './Header/scenes/VocMenu/modules';
import {
    zendeskDictionarySelector, iframeRoutesSelector,
    containerIsFluidSelector, headerUserInfoSelector,
    webUiInfoSelector,
} from '/modules/selectors';

const mapDispatchToProps = {
    updateCurrentLocation,
};

const mapStateToProps = state => ({
    iframeRoutes: iframeRoutesSelector(state),
    visualContainerIsFluid: containerIsFluidSelector(state),
    userInfo: headerUserInfoSelector(state),
    webUiInfo: webUiInfoSelector(state),
});

@connect(mapStateToProps, mapDispatchToProps)
class VocRouter extends Component {
    @autobind
    onHistoryChange(location) {
        const pathName = location.pathname;
        const searchWord = this.zendeskDictionaty[pathName];

        // change default namespace based on pathname
        I18nService.changeDefaultNS(location.pathname);

        this.props.updateCurrentLocation(location.pathname);

        if (searchWord) {
            ZendeskSnippet.setSuggestions(searchWord);
        }
    }

    componentDidMount() {
        const { history, location } = this.props;

        this.zendeskDictionaty = zendeskDictionarySelector();
        this.unlisten = history.listen(this.onHistoryChange);
        this.onHistoryChange(location);
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    containerIsFluid() {
        const {
            visualContainerIsFluid,
            location: {
                pathname,
            },
        } = this.props;

        const isMinePage = pathname.match(/(?=.*mine)(?=.*execute).*/);
        const isVisualPage = pathname.match(/(?=.*visualV2\/dashboard\/).*/) && window.screenermode !== 1;
        const isPreviewModePage = pathname.match(/(?=.*preview-mode).*/);
        const isAiPage = pathname.match(/ai\/topic-set(\/.*)?$/g);

        return isMinePage || isAiPage || isPreviewModePage || (isVisualPage && visualContainerIsFluid);
    }

    render() {
        const {
            containerClass,
            userRights: {
                hasFeedbackAccess,
                hasStoreAccess,
                hasLabsAccess,
                hasMineAccess,
                hasVocAIAccess,
                hasVisualAccess,
            },
            location: {
                pathname,
            },
            iframeRoutes,
            userInfo,
            webUiInfo,
        } = this.props;

        const webUiVersion = (webUiInfo?.branch || '')
            .replace(/^[a-zA-Z_-]+/, '')
            .replace(/[-_].*$/, '')
            .split('.')
            .slice(0, 2)
            .join('.');

        const productFruitProps = {
            username: userInfo.email,
            firstname: userInfo.firstName,
            props: {
                releaseVersion: webUiVersion,
            },
        };

        return (
            <>
                <Header/>
                <div
                    className={ cx(
                        'route__wrapper', 'route-voc-container',
                        this.containerIsFluid()
                            ? 'container-fluid container-fluid__new-design'
                            : containerClass,
                    ) }
                    id={ 'react-xircl' }
                >
                    {
                        userInfo.pf_enabled
                        && <ProductFruits
                            language='en'
                            user={ productFruitProps }
                            workspaceCode={ userInfo.pf_workspace }
                        />
                    }

                    <Switch>
                        <PrivateRoute
                            path='/store'
                            component={ VocStore }
                            hasAccess={ hasStoreAccess }
                        />
                        <PrivateRoute
                            path='/feedback'
                            component={ VocFeedback }
                            hasAccess={ hasFeedbackAccess }
                        />
                        <PrivateRoute
                            path='/labs'
                            hasAccess={ hasLabsAccess }
                            component={ VocLabs }
                        />
                        <PrivateRoute
                            path='/mine'
                            hasAccess={ hasMineAccess }
                            component={ VocMine }
                        />

                        <Route path="/ai"
                            render={ props => {
                                return hasVocAIAccess
                                    ? <VocAI { ...props } />
                                    : <Redirect to='/' />;
                            } }
                        />

                        {/* Support deprecated links */}
                        <Redirect
                            from='/mineV2'
                            to={ pathname.replace('mineV2', 'mine') }
                        />

                        <Route path="/visualV2"
                            render={ props => {
                                return hasVisualAccess
                                    ? <VocVisual { ...props } />
                                    : <Redirect to='/' />;
                            } }
                        />

                        {
                            iframeRoutes.map(({ path, hasAccess, url, noUrlMessage }) => (
                                <PrivateRoute
                                    key={ path }
                                    path={ path }
                                    component={ IframeComponent }
                                    hasAccess={ hasAccess }
                                    customProps={{
                                        url,
                                        noUrlMessage,
                                    }}
                                />
                            ))
                        }

                        <Route
                            path='/'
                            component={ OtherRoutes }
                        />
                    </Switch>
                </div>
            </>
        );
    }
}

export default withRouter(VocRouter);
