import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { VocSelect } from '/components';
import { InputLoader } from '/feedback/components/InputLoader';
import { autobind } from 'core-decorators';

import './QuarantineCustomToolBar.scss';

export class QuarantinesCustomToolBar extends Component {
    @autobind
    onChangeAffected(value) {
        this.updateFilters('survey_group_id', value);
    }

    @autobind
    onChangeBlocked(value) {
        this.updateFilters('blocking_survey_group_id', value);
    }

    updateFilters(name, value) {
        const { updateFilters, requestTrigger, filters } = this.props;
        const changedValue = value === null ? '' : value;

        const model = {
            ...filters,
            [name]: changedValue.value,
            page: 1,
        };

        updateFilters(model);
        requestTrigger(model);
    }

    render() {
        const { selectOptions, allSurveyLoading, flowOptions } = this.props;

        return (
            <Row className='quarantine-settings__toolbar'>
                <Col xs={ 6 } sm={ 6 } md={ 6 }>
                    <Row>
                        <Col md='auto' className={ 'select-control-label' }>
                            <label htmlFor={ 'affectedSelect' }
                                className={ 'quarantine-settings-toolbar-label' }>
                                Affected Survey:
                            </label>
                        </Col>
                        <Col md={ 8 }>
                            {
                                allSurveyLoading
                                    ? <InputLoader/>
                                    : <VocSelect
                                        name="affectedSelect"
                                        isClearable={ true }
                                        isHorizontal={ true }
                                        options={ selectOptions.affectedOptions }
                                        placeholder={ 'Choose affected survey' }
                                        onChange={ this.onChangeAffected }
                                        className={ 'quarantine-settings-toolbar-select' }
                                    />
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs={ 6 } sm={ 6 } md={ 6 }>
                    <Row>
                        <Col md="auto" className={ 'select-control-label' }>
                            <label htmlFor={ 'blockedSelect' }
                                className={ 'quarantine-settings-toolbar-label' }>
                                Blocked / delayed by:
                            </label>
                        </Col>
                        <Col md={ 8 }>
                            {
                                allSurveyLoading
                                    ? <InputLoader/>
                                    : <VocSelect
                                        name="blockedSelect"
                                        isClearable={ true }
                                        isHorizontal={ true }
                                        options={ flowOptions[0].blocked_by }
                                        placeholder={ 'Choose blocked by survey' }
                                        onChange={ this.onChangeBlocked }
                                        className={ 'quarantine-settings-toolbar-select' }
                                    />
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
