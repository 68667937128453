import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { HelperService, useResponsiveQuery, useResizeObserver } from '/services';
import { containerIsFluidSelector } from '/modules/selectors';
import {
    PaginatedLayout, pageAndDropZoneSizesType,
    IGadgetsGrid, TDataDrop, copyGadgetType,
} from '/visual/scenes/Dashboard/models';
import { gadgetClipboardSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { manageGadgetClipboard } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { DashboardGridService, PlaceholdersService } from '/visual/scenes/Dashboard/services';

import { DroppableList, GadgetsVirtualList } from './components';

import styles from './components/GadgetsVirtualList/GadgetsVirtualList.module.scss';
import cx from 'classnames';

export const GadgetsGrid = ({
    gadgets,
    wildGadgets,
    pages,
    isEditable,
    gadgetPending,
    onChangeGadgetsPosition,
    openGadgetModal,
    openGadgetDescriptionModal,
    openDeleteGadgetModal,
    onMoveToSave,
    onCopyGadget,
    handleCreateGadget,
}: IGadgetsGrid) => {
    const dispatch = useDispatch();
    const isFluidContainer = useSelector(containerIsFluidSelector);
    const gadgetClipboard = useSelector(gadgetClipboardSelector);
    const [ pageAndDropZoneSizes, setPageAndDropZoneSizes ] = useState<pageAndDropZoneSizesType>({
        pageWidth: 0,
        dropZoneWidth: 0,
        freeRightSpace: 0,
        diff: 0,
    });
    const [ isExpanded, setIsExpanded ] = useState(false);
    const [ hidePlaceholders, setHidePlaceholders ] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLHeadingElement | null>(null);
    const dimensions = useResizeObserver(wrapperRef);
    const { isTabletAndBelow } = useResponsiveQuery();

    const prepareData = (layout: any[]): TDataDrop => {
        const model: TDataDrop = {
            page: '',
            coordinates: {},
        };

        layout.forEach(({ page, x, y, w, h, i: gadgetId }) => {
            model.page = page;
            model.coordinates = {
                ...model.coordinates,
                [gadgetId]: { h,w,y,x, gadgetId },
            };
        });

        return model;
    };

    const prepareDataKeyIdPage = layout => {
        const model = {};

        layout.forEach(({ page, x, y, w, h, i: gadgetId }) => {
            const coordinates = model[page]
                ? model[page].coordinates
                : [];

            model[page] = {
                ...model[page],
                id: page,
                coordinates: [
                    ...coordinates,
                    { h,w,y,x, gadgetId },
                ],
            };
        });

        return model;
    };

    const onPositionChanged = (layout: any[], type: string) => {
        return onChangeGadgetsPosition(prepareData(layout), type);
    };

    const pasteGadget = async (coord: Partial<PaginatedLayout>, type?: string) => {
        const { x, y, cols } = DashboardGridService.getPageLayout();
        const coordinates = {
            ...HelperService.pick(coord, [ 'x', 'y', 'w', 'h', 'page' ]),
            i: gadgetClipboard?.id as string,
        } as PaginatedLayout;

        const getNewLayout = page => {
            return [
                ...page?.layout.filter(layout => layout.i !== gadgetClipboard?.id) ?? [],
                coordinates,
            ];
        };

        const getPageCoordinate = (layouts, affectedItems) => {
            const newLayout = layouts.map(layout => {
                const affectedItem = affectedItems.find(affected => affected.i === layout.i);

                return affectedItem
                    ? HelperService.pick(affectedItem, [ 'h', 'w', 'x', 'y', 'i', 'page' ])
                    : { ...layout, i: layout.gadgetId };
            });

            return prepareDataKeyIdPage(newLayout);
        };

        let model;

        // cut from one page to another
        if(coord.oldPage !== coord.page) {
            const oldPage = pages.find(item => item.page === coord.oldPage);
            const newPage = pages.find(item => item.page === coord.page);

            const newLayout = getNewLayout(newPage);

            let affectedOldItems = [];
            let oldPageCoordinate: any = [];

            if(oldPage.gadgets.length >= 2) {
                const oldLayout = [
                    ...oldPage?.layout.filter(layout => layout.i !== gadgetClipboard?.id) ?? [],
                ];

                const oldPlaceholders = PlaceholdersService.getPageEmptyPlaceholders({ layout: oldLayout }, { x, y, cols });

                affectedOldItems = DashboardGridService.getAffectedItems(
                    oldPlaceholders,
                    oldPage?.layout,
                    gadgetClipboard?.coordinates as PaginatedLayout,
                );

                oldPageCoordinate = getPageCoordinate(oldLayout, affectedOldItems);
            } else if(oldPage.gadgets.length === 1) {
                oldPageCoordinate = {
                    [coord.oldPage]: {
                        id: coord.oldPage,
                        coordinates: null,
                    },
                };
            }

            const newPageCoordinate = newLayout.map(gadget =>
                HelperService.pick(gadget, [ 'h', 'w', 'x', 'y', 'i', 'page' ]),
            );

            model = {
                ...oldPageCoordinate,
                ...prepareDataKeyIdPage(newPageCoordinate),
            };
        } else if(coord.gadgetId) {
            const page = pages.find(item => item.page === gadgetClipboard?.coordinates.page);
            const newLayout = getNewLayout(page);

            const actualPlaceholders = PlaceholdersService.getPageEmptyPlaceholders({ layout: newLayout }, { x, y, cols });

            const affectedItems = [
                ...DashboardGridService.getAffectedItems(
                    actualPlaceholders,
                    page?.layout,
                    gadgetClipboard?.coordinates as PaginatedLayout,
                ),
                { ...coordinates, gadgetId: coordinates.i },
            ];

            model = { ...getPageCoordinate(page.layout, affectedItems) };
        } else {
            model = {
                pageId: coordinates.page,
                coordinate: HelperService.pick(coordinates, [ 'h', 'w', 'x', 'y' ]),
                type,
            };
        }

        dispatch(manageGadgetClipboard());

        return coord.gadgetId
            ? onChangeGadgetsPosition(model, 'cut')
            : handleCreateGadget(model);
    };

    const handleCalculatePageWidthAndRightFreeSpace = () => {
        DashboardGridService.calculatePageWidthAndRightFreeSpace({
            dimensions,
            isEditable,
            isExpanded,
            isFluidContainer,
            handleSetSizes: setPageAndDropZoneSizes,
        });
    };

    const handleToggleExpanded = () => setIsExpanded(!isExpanded);

    useEffect(() => {
        handleCalculatePageWidthAndRightFreeSpace();
        window.addEventListener('resize', handleCalculatePageWidthAndRightFreeSpace);

        return () => {
            window.removeEventListener('resize', handleCalculatePageWidthAndRightFreeSpace);
        };
    }, [ dimensions, isExpanded, isEditable ]);

    useEffect(() => {
        if (!isEditable) {
            gadgetClipboard
            && dispatch(manageGadgetClipboard());
            setIsExpanded(false);
        }
    }, [ isEditable ]);

    const onCopy = (data: copyGadgetType) => {
        dispatch(manageGadgetClipboard());
        return onCopyGadget(data);
    };

    const handleMoveToSave = (data: { id: string, page: string }) => {
        onMoveToSave(data);
        setIsExpanded(true);
    };

    return (
        <div ref={ wrapperRef }>
            <div
                style={ !isMobile ? { width: pageAndDropZoneSizes.pageWidth || 'inherit' } : {} }
                className={ cx(styles.pageContainer, { [styles.disableSelect]: hidePlaceholders }) }
            >
                {
                    !isMobile
                    && <DroppableList
                        loading={ gadgetPending }
                        pageContainerRef={ wrapperRef }
                        isExpanded={ isExpanded }
                        show={ isEditable && !isTabletAndBelow }
                        width={ pageAndDropZoneSizes.dropZoneWidth }
                        rightOffset={{
                            freeOffset: pageAndDropZoneSizes.freeRightSpace,
                            diff: pageAndDropZoneSizes.diff,
                        }}
                        draggable={ !gadgetClipboard }
                        gadgets={ wildGadgets }
                        onEdit={ openGadgetModal }
                        onMoreInfo={ openGadgetDescriptionModal }
                        onDelete={ openDeleteGadgetModal }
                        onToggleExpanded={ handleToggleExpanded }
                        setHidePlaceholders={ setHidePlaceholders }
                    />
                }
                <GadgetsVirtualList
                    gadgets={ gadgets }
                    pages={ pages }
                    gadgetClipboard={ gadgetClipboard }
                    isEditable={ isEditable }
                    isTabletAndBelow={ isTabletAndBelow }
                    pasteGadget={ pasteGadget }
                    manageGadgetClipboard={ manageGadgetClipboard }
                    openGadgetDescriptionModal={ openGadgetDescriptionModal }
                    onCopyGadget={ onCopy }
                    openDeleteGadgetModal={ openDeleteGadgetModal }
                    onMoveToSave={ handleMoveToSave }
                    onPositionChanged={ onPositionChanged }
                    openGadgetModal={ openGadgetModal }
                    hidePlaceholders={ hidePlaceholders }
                    setHidePlaceholders={ setHidePlaceholders }
                />
            </div>
        </div>
    );
};
