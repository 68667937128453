import React from 'react';
import { TooltipWrapper } from '/components';
import { components } from 'react-select';
import { Badge } from 'reactstrap';

import './SelectAsyncOption.scss';

export const SelectAsyncOption = props => {
    const { typeColor, channel } = props.data;
    let { type, channelStatusColor } = props.data;

    if(channel?.toLowerCase?.() === 'system') {
        type = null;
        channelStatusColor = 'info';
    }

    return (
        <div
            className={ 'Select-menu__small form-fields-select-control__option' }
            { ...props.innerProps }
            onClick={ () => {
                // if onClick exist in custom Option of react-select, onChange in parent component will be fires twice
                // (https://github.com/JedWatson/react-select/issues/3859)
            } }
        >
            <components.Option { ...props }>
                <div className="Select-options--sources">
                    <div className="Select-options__title-wrapper">
                        <TooltipWrapper value={ props.children } placement={ 'top' }>
                            <div className='Select-options__title Select-menu__small vochub-select-control__option'>
                                { props.children }
                            </div>
                        </TooltipWrapper>
                    </div>
                    <div className="Select-options__labels-container">

                        {
                            channel
                            && <Badge color={ channelStatusColor }
                                className={ 'Select-options__source-label' }>
                                { channel }
                            </Badge>
                        }

                        <Badge color={ typeColor }
                            className={ 'Select-options__source-label' }>
                            { type }
                        </Badge>
                    </div>
                </div>
            </components.Option>
        </div>
    );
};
