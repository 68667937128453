import React, { useState, useEffect, ChangeEvent } from 'react';
import { Input, Row, Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { VocModal, SwitchControl, VocSelect, InputLoader } from '/components';
import { copyDashboardListSelector, dashboardsSelector } from '/visual/modules/VocVisual.selectors';
import {
    copyDashboard, createDashboard, getAllDashboards,
    getDashboards, getFavouritesDashboards,
} from '/visual/modules/VocVisual.modules';
import { useVisualNavigation } from '/visual/customHooks';

import { ICreateDashboardModal, TSelectValue } from './models';

import styles from './style.module.scss';

export const CreateDashboardModal = ({
    show,
    toggle,
    modalState,
    openNewDashboard,
}: ICreateDashboardModal) => {
    const { t } = useTranslation();
    const [ selectValue, setSelectValue ] = useState<TSelectValue | null>(null);
    const [ isDefault, setIsDefault ] = useState(false);
    const [ isFavorite, setIsFavorite ] = useState(false);
    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');

    const dashboards = useSelector(copyDashboardListSelector);
    const { dashboards: { filters }, allDashboards: { loading } } = useSelector(dashboardsSelector);
    const dispatch = useDispatch();

    const { goToDashboard } = useVisualNavigation();
    const isCreateDashboardModal = modalState.type === 'createDashboard';
    const isApplyDisabled = !title.trim();
    const descriptionTrim = description.trim();

    const onApply = () => {
        const bodyData = {
            isDefault,
            isFavorite,
            title: title.trim(),
            ...descriptionTrim
                ? { description: descriptionTrim }
                : {},
        };

        const dashboardId = selectValue?.value;
        const request = !dashboardId
            ? dispatch(createDashboard(bodyData, openNewDashboard))
            : dispatch(copyDashboard(bodyData, dashboardId, openNewDashboard));

        request.promise.then((res: { id: string; }) => {
            openNewDashboard
                ? goToDashboard(res.id)
                : dispatch(getDashboards(filters));

            dispatch(getFavouritesDashboards());
        });
    };

    useEffect(() => {
        if (modalState?.dashboardId && show) {
            const dashboardToCopy = dashboards
                .find(({ value }) => value === modalState.dashboardId);

            setSelectValue(dashboardToCopy || null);
        }
    }, [ modalState, dashboards ]);

    useEffect(() => {
        show && dispatch(getAllDashboards());
    }, [ show ]);

    const cleanupModal = () => {
        setSelectValue(null);
        setIsDefault(false);
        setIsFavorite(false);
        setDescription('');
        setTitle('');
    };

    const applyHandler = () => {
        if(title) {
            onApply();
            toggle();
        }
    };

    const onPressEnter = (e: Pick<KeyboardEvent, 'key'>) => {
        e.key === 'Enter' && applyHandler();
    };

    const onChangeDashboardTitle = (event: ChangeEvent<HTMLInputElement>) => {
        let name = event.target.value;

        if(name.indexOf(' ') === 0) {
            name = name.trim();
        }

        setTitle(name);
    };

    const getModalBody = () => (
        <div className={ styles.modalWrapper }>
            <Row className={ styles.fieldWrapper }>
                <Label htmlFor={ 'title_field' }>
                    <span>{ t('field.title') }<span className={ 'star' }> *</span></span>
                </Label>
                <Input
                    autoFocus
                    id={ 'title_field' }
                    value={ title }
                    onChange={ onChangeDashboardTitle }
                    onKeyUp={ onPressEnter }
                />
            </Row>

            <Row className={ styles.fieldWrapper }>
                <Label htmlFor={ 'description_field' }>
                    <span>{ t('field.description') }</span>
                </Label>
                <Input
                    id={ 'description_field' }
                    value={ description }
                    type={ 'textarea' }
                    onChange={ ({ target }) => setDescription(target.value) }
                />
            </Row>

            <Row className={ styles.fieldWrapper }>
                <Label htmlFor={ 'as_default' }>
                    { t('setAsDefault') }
                </Label>
                <SwitchControl
                    name={ 'as_default' }
                    value={ isDefault }
                    changeHandler={ () => setIsDefault(!isDefault) }
                />
            </Row>
            <Row className={ styles.fieldWrapper }>
                <Label htmlFor={ 'as_favorite' }>
                    { t('setAsFavorite') }
                </Label>
                <SwitchControl
                    name={ 'as_favorite' }
                    value={ isFavorite }
                    changeHandler={ () => setIsFavorite(!isFavorite) }
                />
            </Row>
            <Row className={ styles.fieldWrapper }>
                <Label htmlFor={ 'copy_dashboard' }>
                    { t('copyExistingDashboard') }
                </Label>
                {
                    loading
                        ? <InputLoader className={ styles.selectLoader } />
                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                        /* @ts-ignore */
                        : <VocSelect
                            name={ 'copy_dashboard' }
                            placeholder={ `${ t('select') } ...` }
                            className={ styles.select }
                            options={ dashboards }
                            value={ selectValue }
                            portal
                            isHorizontal
                            isClearable
                            onChange={ e => setSelectValue(e) }
                        />
                }
            </Row>
        </div>
    );

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color={ 'white' }
                onClick={ toggle }
            >
                <FontAwesomeIcon icon={ 'times' } className={ styles.buttonIcon } />
                <span>{ t('cancel') }</span>
            </Button>
            <Button
                color={ 'primary' }
                disabled={ isApplyDisabled }
                onClick={ () => !isApplyDisabled && applyHandler() }
            >
                <FontAwesomeIcon icon={ 'check' } className={ styles.buttonIcon } />
                <span>{ t(isCreateDashboardModal ? 'create' : 'save') }</span>
            </Button>
        </div>
    );

    return (
        <VocModal
            isOpen={ show }
            toggle={ toggle }
            modalClassName={ 'modal-scrollable' }
            header={ t(isCreateDashboardModal ? 'createDashboard' : 'copyDashboard') }
            footer={ getModalFooter() }
            size={ 'ms' }
            onClosed={ cleanupModal }
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};
