import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';

const GET_QUINTLY_HEADERS_REQUEST = 'GET_QUINTLY_HEADERS_REQUEST';
const GET_QUINTLY_HEADERS_SUCCESS = 'GET_QUINTLY_HEADERS_SUCCESS';
const GET_QUINTLY_HEADERS_ERROR = 'GET_QUINTLY_HEADERS_ERROR';

const CREATE_IMPORT_REQUEST = 'CREATE_IMPORT_REQUEST';
const CREATE_IMPORT_SUCCESS = 'CREATE_IMPORT_SUCCESS';
const CREATE_IMPORT_ERROR = 'CREATE_IMPORT_ERROR';

const SET_SOCIAL_UPLOAD_HEADER_ROW_TYPE = 'SET_SOCIAL_UPLOAD_HEADER_ROW_TYPE';
const CLEANUP_UPLOAD_SOCIAL_PAGE = 'CLEANUP_UPLOAD_SOCIAL_PAGE';

const getInitialState = () => ({
    loading: false,
    headerRow: [],
    headerRowType: [],
    error: null,
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_QUINTLY_HEADERS_REQUEST:
            return {
                ...state,
                loading: true,
                headerRow: [],
                headerRowType: [],
            };

        case GET_QUINTLY_HEADERS_SUCCESS:
            return {
                ...state,
                loading: false,
                headerRow: action.response,
            };

        case SET_SOCIAL_UPLOAD_HEADER_ROW_TYPE:
            return {
                ...state,
                headerRowType: action.response.headerRowType,
            };

        case GET_QUINTLY_HEADERS_ERROR:
        case CREATE_IMPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case CREATE_IMPORT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_IMPORT_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case CLEANUP_UPLOAD_SOCIAL_PAGE:
            return getInitialState();

        default:
            return state;
    }
}

export function getQuintlyHeaders(fromData) {
    return {
        [CALL_API]: {
            endpoint: '/public_api/store/import/get-quintly-headers',
            method: 'POST',
            types: [ GET_QUINTLY_HEADERS_REQUEST, GET_QUINTLY_HEADERS_SUCCESS, GET_QUINTLY_HEADERS_ERROR ],
            body: fromData,
            logger: true,
            loggerSuccessNotShow: true,
        },
    };
}

export function createImport(type,fromData) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/import/create/${type}`,
            method: 'POST',
            types: [ CREATE_IMPORT_REQUEST, CREATE_IMPORT_SUCCESS, CREATE_IMPORT_ERROR ],
            body: fromData,
            logger: true,
            loggerMessages: {
                success: t('uploadInitialized'),
                error: t('messages.thereWasServerErrorIn', { action: t('upload') }),
            },

        },
    };
}

export function setHeaderRowType(headerRowType) {
    return {
        type: SET_SOCIAL_UPLOAD_HEADER_ROW_TYPE,
        response: {
            headerRowType,
        },
    };
}

export function getQuintlyHeadersError() {
    return {
        type: GET_QUINTLY_HEADERS_ERROR,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_UPLOAD_SOCIAL_PAGE,
    };
}
