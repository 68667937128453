import { createSelector } from 'reselect';
import { unitsListSelector as feedbackUnitsListSelector } from '/feedback/modules/VocFeedback.selectors';
import { isSupportUserSelector } from "/modules/selectors";
import { UsersRoleFeedbackService } from '/services';

export const channels = [ "WEB", "SMS", "IVR", "DIGI" ];
export const settingsPageLinks = createSelector(
    [ isSupportUserSelector ],
    isSupportUser => {
        return [
            {
                title: 'Advanced',
                link: `/feedback/settings/advanced`,
                isReactLink: true,
            },
            {
                title: 'Language-specific',
                link: `/feedback/settings/language`,
                isReactLink: true,
            },
            {
                title: 'Time slots',
                link: `/feedback/settings/time-slots`,
                isReactLink: true,
            },
            {
                title: 'Common',
                link: `/feedback/settings/common`,
                isReactLink: true,
            },
            {
                title: 'Case Alerts',
                link: `/feedback/settings/case-alerts`,
                isReactLink: true,
            },
            {
                title: 'Quarantines',
                link: `/feedback/settings/quarantines`,
                isReactLink: true,
            },
            {
                title: 'WEB Layouts',
                link: `/feedback/settings/layouts`,
                iconType: 'fa',
                icon: 'mobile-alt',
                isReactLink: true,
            },
            {
                title: 'DIGI Themes',
                link: `/feedback/settings/theme-list`,
                iconType: 'fa',
                icon: 'palette',
                isReactLink: true,
            },
            ... (
                isSupportUser
                    ? [{
                        title: 'Share rights',
                        link: `/feedback/settings/share-rights`,
                        iconType: 'fa',
                        icon: 'share',
                        isReactLink: true,
                    }]
                    : []
            ),
        ];
    });

export const timeSlotTypeOptions = [//todo remove
    { label: 'Periodic slots', value: 'regular' },
    { label: 'Custom events', value: 'specific' },
];

export const unitsListSelector = createSelector(
    [ feedbackUnitsListSelector ],
    units => {
        const unitsWithoutOptionsNullAll = units.filter(unt => unt.id);

        return unitsWithoutOptionsNullAll.map(unit => ({
            label: unit.name,
            value: unit.id,
        }));
    });

export const channelSelector = state => state.VocFeedback.globalSettings.common.channel;
export const _gettypeTimeSlotSelector = state => state.VocFeedback.globalSettings.common.timeSlotType;
export const unitSelector = state => state.VocFeedback.globalSettings.common.unit || [];
export const getAdvancedState = state => state.VocFeedback.globalSettings.common;
export const getLanguagesList = state => state.VocFeedback.common.languages;
export const getActiveLanguageSelector = state => state.VocFeedback.globalSettings.common.activeLanguage;

export const activeTimeSlotTypeSelector = createSelector(
    [ _gettypeTimeSlotSelector ],
    typeTimeSlot =>
        timeSlotTypeOptions.find(({ value }) => value === typeTimeSlot));

export const languagesSelector = createSelector([ getLanguagesList, getActiveLanguageSelector, getAdvancedState ],
    (languages, activeLanguage, { enableLanguage, loading, langUpdating }) => {
        const active = activeLanguage.language ? activeLanguage : languages.results.find(el => el.id === enableLanguage[0]) || {};

        return {
            enableLangLoading: loading,
            langUpdating,
            loading: languages.loading,
            enableLanguage,
            languages: languages.results.map(language => ({
                language: language.iso_6391_code,
                value: language.iso_6391_code,
                label: language.iso_6391_code,
                id: language.id,
                hasLanguageText: enableLanguage.includes(language.id),
                active: active.iso_6391_code ? language.iso_6391_code === active.iso_6391_code : language.iso_6391_code === active.language,
            })),
        };
    });

export const activeLanguageSelector = createSelector(
    [ languagesSelector, getActiveLanguageSelector ],
    (languagesList, activeLanguage) => {
        const language = languagesList.languages.find(language => language.active);

        return language
            ? language
            : activeLanguage;
    });

export const getPermissionByActiveSelector = createSelector(
    [ unitSelector, feedbackUnitsListSelector ],
    ( activeUnit, unitsList ) => {
        const unitPermission = unitsList.find(unit => unit.id === activeUnit?.value);

        return unitPermission?.permissionsName
            ? UsersRoleFeedbackService.getRightsForGlobal(unitPermission.permissionsName)
            : {};
    });

export const getPermissionSelector = createSelector(
    [ unitSelector, feedbackUnitsListSelector ],
    ( activeUnit, unitsList ) => {
        const unitPermission = unitsList.some(unit => unit?.permissionsName?.includes('Manager'));

        return unitPermission
            ? UsersRoleFeedbackService.getRightsForGlobal('Manager')
            : {};
    });
