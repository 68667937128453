import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { autobind } from 'core-decorators';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import Arrow from '../../../../images/carret.svg';

import './VocMenuItem.scss';

export class VocMenuItem extends Component {
    @autobind
    toggle() {
        this.props.toggle(this.props.title);
    }

    @autobind
    checkIsActive({ routeLevel, activeWhenEqual, title, additionalCheckList }) {
        const { activeRoute, activeItem } = this.props;
        const currentActiveSubRoute = activeRoute.split('/')[routeLevel];
        let active = false;

        if (activeWhenEqual) {
            if (Array.isArray(activeWhenEqual)) {
                active = activeWhenEqual.some(item => item === currentActiveSubRoute);
            } else {
                active = activeWhenEqual === currentActiveSubRoute;
            }

            // check for additional conditions in configuration
            if (!active && additionalCheckList) {
                if (Array.isArray(additionalCheckList)) {
                    active = additionalCheckList.some(item =>
                        this.checkIsActive({
                            ...item,
                            title,
                        }),
                    );
                } else {
                    active = this.checkIsActive({ ...additionalCheckList, title });
                }
            }
        }

        // whether item is opened by click
        return active || activeItem === title;
    }

    renderHeaderItemMarkup(isActive) {
        const {
            icons,
            title,
            counter,
            countPrefix,
            items,
            hideInfo,
            activeItem,
            isBeta,
            isPro,
        } = this.props;

        return (
            <>
                <div className={ 'app-icon__container' }>
                    <img
                        className={ 'app-icon' }
                        src={ isActive ? icons.active : icons.def }
                        alt={ title }
                    />
                </div>
                <div className={ cx('navigation-item-text', {
                    'navigation-item-text--info-hidden': hideInfo,
                }) }>
                    <div className={ 'navigation__item-content' }>
                        <span className={
                            cx('app-name', {
                                'navigation-item-beta': isBeta,
                                'navigation-item-pro': isPro,
                            })
                        }>
                            { title }
                        </span>
                        { !hideInfo && <p className={ 'app-description' }>{ `${ counter } ${ countPrefix }` }</p> }
                    </div>
                    {
                        items.length !== 0
                        && <img
                            alt={ null }
                            className={ cx('navigation__arrow', { 'active': activeItem === title }) }
                            src={ Arrow }
                        />
                    }
                </div>
            </>
        );
    }

    renderMenuItem(title, url, isReactLink, routeLevel, activeWhenEqual) {
        const isActive = this.checkIsActive({ routeLevel, activeWhenEqual, title });

        return (
            <>
                {
                    isReactLink
                        ? <Link to={ url }>
                            <div
                                className={ cx('menu-header-item', { 'active': isActive } ) }
                                onClick={ this.toggle }
                            >
                                { this.renderHeaderItemMarkup(isActive) }
                            </div>
                        </Link>
                        : <a href={ url }>
                            <div
                                className={ cx('menu-header-item', { 'active': isActive } ) }
                                onClick={ this.toggle }
                            >
                                { this.renderHeaderItemMarkup(isActive) }
                            </div>
                        </a>
                }
            </>
        );
    }

    renderSubMenu(items) {
        return items.filter(item => item)
            .map(({
                type,
                title,
                url,
                isReactLink,
                routeLevel,
                activeWhenEqual,
                additionalCheckList,
            }, index) => {
                if (type === 'divider') {
                    return (
                        <div
                            className={ 'divider' }
                            key={ index }
                        />
                    );
                }

                //this stuff will be removed after all application will be common and rewritten on React
                if (isReactLink) {
                    const isActive = this.checkIsActive({
                        routeLevel,
                        activeWhenEqual,
                        title,
                        additionalCheckList,
                    });

                    return (
                        <Link
                            className={ cx('menu-header__item', { 'active': isActive }) }
                            key={ `${title}_${index}` }
                            to={ url }
                        >
                            { title }
                        </Link>
                    );
                }

                return (
                    <a
                        className={ 'menu-header__item' }
                        key={ `${title}_${index}` }
                        href={ url }
                    >
                        { title }
                    </a>
                );
            });
    }

    render() {
        const {
            items,
            url,
            title,
            isReactLink,
            routeLevel,
            activeWhenEqual,
        } = this.props;

        return (
            <>
                { this.renderMenuItem(title, url, isReactLink, routeLevel, activeWhenEqual) }
                {
                    items.length > 0
                    && <Collapse
                        className={ 'menu-header__list' }
                        isOpen={ this.props.activeItem === title }
                    >
                        { this.renderSubMenu(items) }
                    </Collapse>
                }
            </>
        );
    }
}

VocMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    counter: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({
        isReactLink: PropTypes.bool,
        title: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
    })),
    countPrefix: PropTypes.string,
    icons: PropTypes.any.isRequired,
    routeLevel: PropTypes.number,
    activeWhenEqual: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
};
