import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import { VoCVisualDBService } from '/services';
import { ConfirmDeleteVirtualContent } from '../ConfirmDeleteVirtualContent';
import { TooltipWrapper } from '/components';

import './VirtualSourcesTableActions.scss';

@withTranslation()
export class VirtualSourcesTableActions extends Component {
    @autobind
    onClickDeleteButton() {
        const { source: { id, rights }, getVirtualRelations } = this.props;

        rights.isPermitDelete && getVirtualRelations(id).promise
            .then(this.showConfirm);
    }

    @autobind
    showConfirm() {
        const {
            source,
            showConfirm,
            virtualToDelete,
            deleteVirtualSource,
            t,
        } = this.props;

        showConfirm({
            header: t('deleteVirtualSource'),
            content: <ConfirmDeleteVirtualContent virtualToDelete={ virtualToDelete }/>,
            successCallback: () => {
                deleteVirtualSource(source.id);
                // BE requirement
                VoCVisualDBService.updateVisualDB(String(source.id));
            },
        });
    }

    @autobind
    onCopyButtonClick() {
        const { source, onCopyButtonClick } = this.props;

        source.rights.isPermitCopy && onCopyButtonClick(source);
    }

    @autobind
    onCreateApplianceClick() {
        const { source: { id, rights }, showApplianceModal } = this.props;

        rights.isPermitRecord && showApplianceModal({ sourceId: id, mode: 'create' });
    }

    @autobind
    downloadSource() {
        const { source: { id, attrByDate, rights }, showExportFilterModal } = this.props;

        rights.isPermitDownloadSource && showExportFilterModal(id, attrByDate, 'virtual');
    }

    @autobind
    getLinkClassName() {
        return !this.props.source.rights.isPermitEdit
            ? 'table-action-link--disabled'
            : '';
    }

    render() {
        const {
            openInviteModal,
            source:
            {
                id,
                rights: {
                    isPermitShare,
                    isPermitDownloadSource,
                    isPermitRecord,
                    isPermitEdit,
                    isPermitCopy,
                    isPermitDelete,
                },
            },
            onInfoButtonClick,
            t,
        } = this.props;

        return (
            <div className='table-actions'>
                <TooltipWrapper value={ t('field.info') } force>
                    <span className={ 'table-action-btn-wrapper' }>
                        <Button
                            color='link'
                            onClick={ () => onInfoButtonClick(this.props.source) }
                        >
                            <FontAwesomeIcon icon='info-circle' />
                        </Button>
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('share') } force>
                    <span
                        className={ cx('table-action-btn-wrapper', { disabled: !isPermitShare }) }
                    >
                        <Button
                            color='link'
                            onClick={ () => isPermitShare && openInviteModal(id) }
                        >
                            <FontAwesomeIcon icon='share' />
                        </Button>
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('downloadSource') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitDownloadSource }) }>
                        <Button
                            color='link'
                            onClick={ this.downloadSource }
                        >
                            <FontAwesomeIcon icon='cloud-download-alt' />
                        </Button>
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('recodeVariables') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitRecord }) }>
                        <Button
                            color='link'
                            onClick={ this.onCreateApplianceClick }
                        >
                            <FontAwesomeIcon icon='sitemap' />
                        </Button>
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('edit') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitEdit }) }>
                        <Link
                            className={ this.getLinkClassName() }
                            to={ `/store/virtual-source/${this.props.source.id}` }
                        >
                            <Button color='link'>
                                <FontAwesomeIcon icon='pencil-alt' />
                            </Button>
                        </Link>
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('copySource') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitCopy }) }>
                        <Button
                            color='link'
                            onClick={ this.onCopyButtonClick }
                        >
                            <FontAwesomeIcon icon='copy' />
                        </Button>
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('delete') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitDelete }) }>
                        <Button
                            color='link'
                            onClick={ this.onClickDeleteButton }
                        >
                            <FontAwesomeIcon icon='trash-alt' />
                        </Button>
                    </span>
                </TooltipWrapper>
            </div>
        );
    }
}

VirtualSourcesTableActions.propTypes = {
    showConfirm: PropTypes.func,
    getVirtualRelations: PropTypes.func,
    deleteVirtualSource: PropTypes.func,
    virtualToDelete: PropTypes.shape({
        gadgets: PropTypes.array,
        virtualSources: PropTypes.array,
        segmentators: PropTypes.array,
    }),
    showApplianceModal: PropTypes.func,
};
