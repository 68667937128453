export const importFormValidation = (values = {}) => {
    const errors = {

    };

    if (!values.delimiter) {
        errors.delimiter = 'Delimiter field required';
    }

    if (!values.customerId) {
        errors.customerId = 'Customer ID field required';
    }

    if (!values.address) {
        errors.address = 'Address field required';
    }

    if (!values.languageColumn) {
        errors.languageColumn = 'Language column required';
    }

    if ((values.surveyId && values.surveyId.value) && !values.channelId) {
        errors.channelId = 'Channel field required';
    }

    if (!(values.surveyId && values.surveyId.value) && !values.surveyColumn) {
        errors.surveyColumn = 'Survey column field required';
    }

    if (!values.file) {
        errors.file = 'File field required';
    }

    return errors;
};
