import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import { QueryAttributesMatrix } from '../../services/';
import { MenuList } from '/components';
import { HelperService, SourceService } from '/services';

import './VocMetaFilterItem.scss';

import {
    MetaFilterItemOr,
    MetaFilterItemAnd,
    MetaFilterItemRemove,
    MetaFilterItemValueControl,
    MetaFilterOptionComponent,
    MetaFilterSelectValueComponent,
} from '../';

@withTranslation('common', { keyPrefix: 'metaQuery' })
export class VocMetaFilterItem extends Component {
    constructor(props) {
        super(props);

        const visualMetaOperator = {
            EQ_CI: props.t('EQ_CI'),
            NE_CI: props.t('NE_CI'),
        };

        const visualFrontOperators = Object.entries(visualMetaOperator).map(
            ([ id, name ]) => ({ id, name }),
        );

        const mineVisualFrontOperators = [
            { id: 'LNEN', name: props.t('LNEN') },
            { id: 'LEQN', name: props.t('LEQN') },
        ];

        const frontOperators = [
            { id: 'EQN', name: props.t('EQN') },
            { id: 'NEN', name: props.t('NEN') },
        ];

        const operators = [
            { id: 'EQ', name: props.t('EQ') },
            { id: 'NE', name: props.t('NE') },
            { id: 'INC', name: props.t('INC') },
            { id: 'EXC', name: props.t('EXC') },
            { id: 'EQA', name: props.t('EQA') },
            { id: 'NEA', name: props.t('NEA') },
            { id: 'GTE', name: '>=' },
            { id: 'GT', name: '>' },
            { id: 'LT', name: '<' },
            { id: 'LTE', name: '<=' },
        ];

        this.state = {
            manualValues: [],
            operators: [
                ... props.isVisual
                    ? visualFrontOperators
                    : [],
                ...operators,
                ... props.withLemmas
                    ? mineVisualFrontOperators
                    : [],
                ...frontOperators,
            ],
            visualMetaOperator: Object.keys(visualMetaOperator),
        };
    }

    mapSelectOptions({ options, valueKey = 'id' }) {
        return options.map(option => {
            return {
                value: option[valueKey],
                label: option.name,
            };
        });
    }

    componentDidMount() {
        const { metaQuery: { value } } = this.props;
        const attr = this.getAttribute();

        if (!Array.isArray(value)) {
            const isValueInOption = attr.options.find(attrOption => attrOption.id === value);

            if (!isValueInOption) {
                value !== null && value !== ''
                    ? this.setState({
                        manualValues: [
                            {
                                id: value,
                                name: value,
                            },
                        ],
                    })
                    : null;
            }
        } else {
            const manualValues = value
                .filter(valueId => {
                    return !attr.options.find(attrOption => attrOption.id === valueId);
                })
                .map(valueId => ({
                    id: valueId,
                    name: valueId,
                }));

            this.setState({ manualValues });
        }
    }

    componentWillUnmount() {
        this.setState({ manualValues: [] });
    }

    getAttribute() {
        const { metaQuery: { id }, attributes, attributeValueKey } = this.props;

        return attributes.find(attr => attr[attributeValueKey] === id) || { options: [] };
    }

    isChoiceAttribute() {
        const { type, options } = this.getAttribute();

        return options?.length > 0 || type === 'TEXT';
    }

    onChangeItem(data) {
        const { metaQuery, onChangedMetaQueryItem } = this.props;

        if (data === null) {
            metaQuery['value'] = data;
        } else {
            Object.keys(data)
                .forEach(key => {
                    metaQuery[key] = data[key];
                });
        }

        onChangedMetaQueryItem();
    }

    isMultiOperator() {
        const { metaQuery: { operator, id }, attributes } = this.props;
        const fullAttribute = attributes.find(attr => attr.id === id);
        const type = fullAttribute && fullAttribute.type;

        return type === 'CHOICE' || type === 'TEXT' || [ 'EQA', 'NEA' ].includes(operator);
    }

    @autobind
    onChangeOperator(operatorOption) {
        const value = !operatorOption ? '' : operatorOption.value;

        this.onChangeItem({ operator: value, value: null });
    }

    @autobind
    onChangeAtribute(attributeOption) {
        const value = !attributeOption ? '' : attributeOption.value;

        this.onChangeItem({ id: value, value: null });

        this.setState({ manualValues: [] });
    }

    @autobind
    onChangeValues(valueOptions) {
        if (valueOptions) {
            valueOptions = valueOptions.map(option => option.value);
        }

        this.onChangeItem(
            valueOptions === null
                ? null
                : { value: valueOptions || '' },
        );
    }

    @autobind
    onChangeSelectValue(valueOption) {
        const value = valueOption && valueOption.value;

        this.onChangeItem({ value });
    }

    @autobind
    onChangeTextValue(event) {
        const { value } = event.target;
        const data = { value: this.isMultiOperator() ? [ value ] : value };

        this.onChangeItem(data);
    }

    @autobind
    onChangeNumericValue(value) {
        const data = { value: this.isMultiOperator() ? [ value ] : value };

        this.onChangeItem(data);
    }

    getRelativeDivOffset(element) {
        let offset = element.offsetLeft;

        if (!element.classList.contains('voc-meta-filter')) {
            offset += this.getRelativeDivOffset(element.offsetParent);
        }

        return offset;
    }

    @autobind
    onAndCLicked() {
        const { onChangedMetaQueryItem, metaQuery, parent, getBaseItem, setScrollOffset } = this.props;
        const baseItem = getBaseItem();

        metaQuery.items = [ baseItem ];
        setScrollOffset();
        onChangedMetaQueryItem(parent);
    }

    @autobind
    onRemoveClick() {
        const { onChangedMetaQueryItem, parent, metaQuery, isRoot } = this.props;

        if(isRoot && parent.hash === 'root') {
            parent.items.splice(1, parent.items.length - 1);
            parent.items[0] = {
                ...parent.items[0],
                items: [],
                id: '',
                operator: '',
                value: null,
            };

            onChangedMetaQueryItem(parent);
        } else {
            const index = parent.items.findIndex(x => x === metaQuery);

            parent.items.splice(index, 1);
            onChangedMetaQueryItem(parent);
        }
    }

    @autobind
    onOrCLicked() {
        const { onChangedMetaQueryItem, parent, getBaseItem, setScrollOffset } = this.props;
        const baseItem = getBaseItem();

        parent.items.push(baseItem);
        setScrollOffset();
        onChangedMetaQueryItem(parent);
    }

    isNumericAttribute() {
        const attr = this.getAttribute();

        return SourceService.isNumericAttribute(attr);
    }

    @autobind
    onOptionAdd(option) {
        const { manualValues } = this.state;
        const { metaQuery: { value } } = this.props;
        const isNumericAttr = this.isNumericAttribute();
        const { values } = this.getSettings();
        const attr = this.getAttribute();

        const id = option.trim().split(/\s+/).join(' ');
        const name = option.trim().split(/\s+/).join(' ');

        option = {
            id: isNumericAttr ? Number(id) : id,
            name: isNumericAttr ? Number(name) : name,
        };

        const rejectConditions = [
            isNumericAttr && Number.isNaN(option.id),
            isNumericAttr && !Number.isFinite(option.id),
            option.id === '',
            [ 'LABEL', 'CLASSIFICATION', 'NPS_SEGMENT' ].includes(attr.originType),
            this.getAttributeValues(values).some(val => val.id === option.id),
        ];

        if (rejectConditions.some(condition => condition)) {
            return false;
        }

        manualValues.push(option);
        this.setState({ manualValues });
        this.onChangeItem({
            value: this.isMultiOperator()
                ? (value !== null ? value.concat(option.id) : [ option.id ])
                : option.id,
        });
    }

    isFullQueryItem(metaQuery) {
        const { id, operator, value } = metaQuery;
        const hasValue = Array.isArray(value)
            ? value.length > 0
            : ![ null, undefined ].includes(value);

        const isNullOperator = [ 'EQN', 'NEN', 'LNEN', 'LEQN' ].includes(operator);

        return isNullOperator
            ? id && operator
            : id && operator && hasValue;
    }

    hideValueOperator() {
        const { metaQuery: { operator } } = this.props;

        return [ 'EQN', 'NEN', 'LNEN', 'LEQN' ].includes(operator);
    }

    getAttributeValues(values) {
        const { manualValues } = this.state;

        return values.concat(manualValues);
    }

    filteredOperator(type) {
        let operators = Array.from(this.state.operators);

        if(type !== 'META') {
            operators = operators.filter(operator => !this.state.visualMetaOperator.includes(operator.id));
        }

        if(type === 'TEXT') {
            operators = this.changeOperatorsForText(operators);
        }

        return operators;
    }

    getSettings() {
        let { attributes } = this.props;
        const { metaQuery, attributeValueKey } = this.props;

        attributes = Array.from(attributes);
        const attr = attributes.find(attr => attr[attributeValueKey] === metaQuery.id);


        if (attr) {
            attr.options = attr.options.map(option => {
                if (option.name === '' || option.name === null) {
                    option = {
                        name: 'No value',
                        id: '',
                    };
                }

                return option;
            });
        }

        return QueryAttributesMatrix.processMartix({
            queryItem: metaQuery,
            attributeValueKey,
            settings: {
                attributes,
                values: attr ? attr.options : [],
                operators: this.filteredOperator(attr?.type),
            },
        });
    }

    changeOperatorsForText(operators) {
        const { t } = this.props;

        const operatorName = ({ id, name }) => {
            switch (id) {
                case 'EQA':
                    return t('EQA_Lem');
                case 'NEA':
                    return t('NEA_Lem');
                case 'NEN':
                    return t('NEN_text');
                case 'EQN':
                    return t('EQN_text');

                default:
                    return name;
            }
        };

        return operators.map(operator => ({
            ...operator,
            name: operatorName(operator),
        }));
    }

    render() {
        const { metaQuery, attributeValueKey, attributePlaceholder, parent, hasItems, vocMetaItem } = this.props;
        const { operator, id } = metaQuery;
        const { attributes, operators, values } = this.getSettings();
        const { metaQuery: { value } } = this.props;
        const attributeOptions = this.mapSelectOptions({
            options: attributes,
            valueKey: attributeValueKey,
        });
        const attributeValue = HelperService.getSelectedValue(attributeOptions, id);
        const operatorOptions = this.mapSelectOptions({
            options: operators,
        });
        const operatorValue = HelperService.getSelectedValue(operatorOptions, operator);
        const attr = this.getAttribute();

        return (
            <>
                <div ref={ vocMetaItem } className="voc-meta-filter__form-wrapper">
                    <div className="voc-meta-filter__item-form">
                        <Select
                            className="vochub-select-control voc-meta-filter__attr-select"
                            classNamePrefix="vochub-select-control"
                            value={ attributeValue }
                            components={{
                                MenuList,
                                Option: MetaFilterOptionComponent,
                                ValueContainer: MetaFilterSelectValueComponent,
                            }}
                            openMenuOnFocus={ true }
                            isClearable={ true }
                            onFocus={ this.onFocus }
                            options={ attributeOptions }
                            placeholder={ attributePlaceholder }
                            onChange={ this.onChangeAtribute }
                        />

                        <Select
                            className="vochub-select-control voc-meta-filter__operator-select"
                            classNamePrefix="vochub-select-control"
                            value={ operatorValue || '' }
                            components={{
                                MenuList,
                                Option: MetaFilterOptionComponent,
                                ValueContainer: MetaFilterSelectValueComponent,
                            }}
                            openMenuOnFocus={ true }
                            isClearable={ true }
                            options={ operatorOptions }
                            placeholder={ 'Operator' }
                            onChange={ this.onChangeOperator }
                        />

                        {
                            !this.hideValueOperator()
                            && <MetaFilterItemValueControl
                                isChoiceAttribute={ this.isChoiceAttribute() }
                                value={ value }
                                onChangeNumericValue={ this.onChangeNumericValue }
                                onChangeTextValue={ this.onChangeTextValue }
                                attr={ attr }
                                mapSelectOptions={ this.mapSelectOptions }
                                components={{
                                    Option: MetaFilterOptionComponent,
                                    ValueContainer: MetaFilterSelectValueComponent,
                                }}
                                onOptionAdd={ this.onOptionAdd }
                                onChangeValues={ this.onChangeValues }
                                onChangeSelectValue={ this.onChangeSelectValue }
                                isMultiOperator={ this.isMultiOperator() }
                                values={ this.getAttributeValues(values) }
                                isSeparateValue={ attr?.type === 'TEXT' }
                            />
                        }
                    </div>
                    <MetaFilterItemAnd
                        metaQuery={ metaQuery }
                        hasItems={ hasItems }
                        isFullQueryItem={ this.isFullQueryItem }
                        onAndCLicked={ this.onAndCLicked }
                    />
                    <MetaFilterItemRemove
                        parent={ parent }
                        onRemoveClick={ this.onRemoveClick }
                    />
                </div>
                <MetaFilterItemOr
                    metaQuery={ metaQuery }
                    parent={ parent }
                    isFullQueryItem={ this.isFullQueryItem }
                    onOrCLicked={ this.onOrCLicked }
                />
            </>
        );
    }
}

VocMetaFilterItem.propTypes = {
    attributes: PropTypes.array,
    onChangedMetaQueryItem: PropTypes.func,
    attributePlaceholder: PropTypes.string,
    setScrollOffset: PropTypes.func,
    metaQuery: PropTypes.object,
    parent: PropTypes.object,
    hasItems: PropTypes.bool,
    attributeValueKey: PropTypes.oneOf([ 'name', 'id', 'index' ]),
    withLemmas: PropTypes.bool,
};

VocMetaFilterItem.defaultProps = {
    showRemove: true,
    attributeValueKey: 'id',
    withLemmas: true,
};
