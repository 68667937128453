import { CALL_API } from 'store/middleware/api';

export const GET_SOURCES_REQUEST = '/public_api/store/source/request';
export const GET_SOURCES_SUCCESS = '/public_api/store/source/success';
export const GET_SOURCES_ERROR = '/public_api/store/source/error';
export const CLEAN_SELECT = 'clean-select';
export const SET_SELECT_FILTERS = 'set-select-filters';
export const SET_SELECT_PAGE = 'set-select-page';
export const INIT_SELECT = 'init-select';

export const TOOGLE_ADDITIONAL_FILTERS = 'toogle-additional-filters';

const getInitialState = () => ({});

export function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case GET_SOURCES_REQUEST: {
            return {
                ...state,
                [action.params.id]: {
                    ...state[action.params.id],
                    loading: true,
                },
            };
        }

        case GET_SOURCES_SUCCESS: {
            return {
                ...state,
                [action.params.id]: {
                    ...state[action.params.id],
                    options: setNewSelectSources({ state, action }),
                    total: action.response.count,
                    loading: false,
                },
            };
        }

        case CLEAN_SELECT: {
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    options: [],
                    total: 0,
                    loading: false,
                },
            };
        }

        case SET_SELECT_FILTERS: {
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    options: [],
                    filters: {
                        ...state[action.id].filters,
                        page: 1,
                        ...action.filters,
                    },
                },
            };
        }

        case SET_SELECT_PAGE: {
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    filters: {
                        ...state[action.id].filters,
                        page: action.page,
                    },
                },
            };
        }

        case TOOGLE_ADDITIONAL_FILTERS: {
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    openedAdditionalFilters: action.openedAdditionalFilters,
                },
            };
        }

        case INIT_SELECT: {
            return {
                ...state,
                ...action.select,
            };
        }

        default: {
            return state;
        }
    }
}

export function getSources({ body, params }) {
    return {
        [CALL_API]: {
            endpoint: '/public_api/store/source',
            method: 'GET',
            types: [
                GET_SOURCES_REQUEST,
                GET_SOURCES_SUCCESS,
                GET_SOURCES_ERROR,
            ],
            unique: true,
            body,
            params,
        },
    };
}

export function cleanSelectSources(id) {
    return {
        type: CLEAN_SELECT,
        id,
    };
}

export function setSelectFilters({ filters, id }) {
    return {
        type: SET_SELECT_FILTERS,
        filters,
        id,
    };
}

export function setPage({ page, id }) {
    return {
        type: SET_SELECT_PAGE,
        page,
        id,
    };
}

export function toogleAdditionalFilters({ openedAdditionalFilters, id }) {
    return {
        type: TOOGLE_ADDITIONAL_FILTERS,
        openedAdditionalFilters,
        id,
    };
}

export function initSelect(id) {
    return {
        type: INIT_SELECT,
        select: {
            [id]: {
                options: [],
                total: 0,
                openedAdditionalFilters: false,
                loading: false,
                filters: {
                    search: '',
                    sort_field: '',
                    sort_order: '',
                    page: 1,
                    limit: 15,
                    with_virtual_column: 1,
                    type: "all",
                    state: [],
                    show_hidden: false,
                },
            },
        },
    };
}

//Helpers
function setNewSelectSources({ state, action }) {
    // const isFilteredBySearch = state[action.params.id].filters.search.length > 0;
    // const currentItems = Array.from(state[action.params.id].options);
    // currentItems.push(...action.response.data);
    //
    // return isFilteredBySearch ? action.response.data : currentItems;
    const items = Array.from(state[action.params.id].options);

    items.push(...action.response.data);

    return items;
}
