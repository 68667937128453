import React, { useEffect, useRef, memo, useState } from 'react';
import { scaleOrdinal, select, event } from 'd3';
import cloud from 'd3-cloud';
import { isMobile } from 'react-device-detect';

import { charts } from '/visual/scenes/Dashboard/components/Gadget/contstants';

import './WordCloudChartD3.scss';

function getPercentages(arr, min, max) {
    const maxValue = arr.reduce((acc, value) => {
        return acc > value.count ? acc : value.count;
    }, 0);

    return arr.map(({ text, count }) => {
        const percent = (count * 100) / maxValue;
        const size = percent * ((max - min) / 100) + min;

        return {
            text,
            size,
            count,
        };
    });
}

const { defaultColors, fontFamily } = charts.lemmata_word_cloud;

export const WordCloudChartD3 = memo(({
    width,
    height,
    words,
    toolTipRef,
    onWordClick,
    onWordOver,
    changeClass,
    id,
    className,
    animation = true,
    colors,
    maxFontSize,
    minFontSize,
}) => {
    const divRef = useRef(null);
    const [ copyWords, setCopyWords ] = useState([]);

    const colorsArray = colors || defaultColors;

    const getFill = () => words
        .map(wordData => JSON.stringify(wordData))
        .sort()
        .map(scaleOrdinal(colorsArray));

    const draw = copyWords => {
        select(divRef.current)
            .selectAll('svg')
            .attr('viewBox', `0 0 ${ width } ${ height }`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .selectAll('g')
            .style('transform', 'translate(50%, 50%)')
            .selectAll('text')
            .data(copyWords)
            .join(
                enter => {
                    return enter
                        .append('text')
                        .attr('fill', (_, index) => getFill()[index])
                        .style('font-size', word => `${word.size}px`)
                        .style('font-family', fontFamily)
                        .attr('text-anchor', 'middle')
                        .attr('class', word => changeClass && changeClass(word))
                        .attr('id', word => `${ word.text }-${ word.count }`)
                        .attr('transform', 'translate(0, 0) rotate(0)')
                        .call(updateCallback)
                        .call(enter =>
                            enter
                                .transition()
                                .duration(animation ? 500 : 0)
                                .attr('transform', word => `translate(${ word.x }, ${ word.y })`)
                                .text(word => word.text),
                        );
                },
                update => {
                    update.exit().remove();
                    return update
                        .transition()
                        .duration(animation ? 500 : 0)
                        .attr('fill', (_, index) => getFill()[index])
                        .style('font-family', fontFamily)
                        .attr('text-anchor', 'middle')
                        .style('font-size', word => `${word.size}px`)
                        .attr('class', word => changeClass && changeClass(word))
                        .attr('transform', word => {
                            const translate = `translate(${ word.x }, ${ word.y })`;
                            const rotate
                                = typeof word.rotate === 'number'
                                    ? `rotate(${ word.rotate })`
                                    : '';

                            return translate + rotate;
                        })
                        .text(word => word.text);
                },
                exit => {
                    exit
                        .transition()
                        .duration(animation ? 500 : 0)
                        .attr('fill-opacity', 0)
                        .attr('stroke-opacity', 0)
                        .remove();
                },
            );
    };

    const updateCallback = selector => {
        return selector
            .on('click', wordClick)
            .on('mouseover', wordOver)
            .on('mousemove', wordOver)
            .on('mouseout', wordOut);
    };

    const layout = cloud()
        .size([ width, height ])
        .words(copyWords)
        .padding(5)
        .rotate(0)
        .font(fontFamily)
        .fontSize(d => d.size)
        .on('end', draw);

    const wordClick = word => onWordClick?.(word, event);

    const wordOut = () => {
        if (onWordOver) {
            select(toolTipRef.current)
                .style('visibility', 'hidden')
                .text('');
        }
    };

    const wordOver = word => {
        if (onWordOver) {
            !isMobile && select(toolTipRef.current)
                .style('visibility', 'visible')
                .style('top', `${event.clientY + 15}px`)
                .style('left', `${event.clientX + 15}px`)
                .text(word.count);
        }
    };

    useEffect(() => {
        layout.start();
    }, [ copyWords, width, height ]);

    useEffect(() => {
        const isSame = words.every(word => copyWords.find(el => el.text === word.text && word.count === el.count));

        if (words.length !== copyWords.length || !isSame) {
            setCopyWords(getPercentages(words, minFontSize, maxFontSize));
        }
    }, [ words ]);

    useEffect(() => {
        setCopyWords(getPercentages(words, minFontSize, maxFontSize));
    }, [ width, height ]);

    useEffect(() => {
        // if properties in parent component changed, we must update context of d3 event callback
        const selector = select(divRef.current)
            .selectAll('svg')
            .selectAll('g')
            .selectAll('text');

        updateCallback(selector);
    });

    return (
        <div
            id={ id ? id : '' }
            ref={ divRef }
            className={ `word-cloud ${ className }` }
        >
            <svg>
                <g/>
            </svg>
        </div>
    );
});

WordCloudChartD3.defaultProps = {
    className: 'word-cloud__wrapper',
    onWordOver: false,
    maxFontSize: 55,
    minFontSize: 18,
};
