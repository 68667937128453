import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResizeObserver } from '/services';
import { ChartAxis } from '/visual/scenes/Dashboard/components/Gadget/components';
import { IBarChart, barData } from '/visual/scenes/Dashboard/components/Gadget/models';
import { charts } from '/visual/scenes/Dashboard/components/Gadget/contstants';
import { GadgetService } from '/visual/scenes/Dashboard/components';
import { BarChartService } from '../../services';

import styles from '../../style.module.scss';

const {
    marginTop,
    yLeftLabelOffset,
    innerPaddingForBarGroups,
    outerPaddingForBarGroups,
    rightOffset,
} = charts.surveys_bar_chart;

export const BarChartD3 = ({
    dataSet,
    allGroups,
    showScore,
    percentage,
    decimalDigits,
    axisLabels,
    highlightedLabel,
    gadgetId,
    gadgetFunction,
    drillDownEnabled,
    chartType,
    toolTipRef,
    setChangeTickModal,
    resetTickData,
    onApplyLabelModal,
    saveTickOrder,
    setColorPickerData,
    drillDownFromSelection,
}: IBarChart) => {
    const { t } = useTranslation();
    const [ chartInstance, setChartInstance ] = useState<BarChartService | null>(null);
    const [ xAxisSize, setXAxisSize ] = useState<number | null>(null);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const wrapperRef = useRef<HTMLHeadingElement | null>(null);
    const dimensions = useResizeObserver(wrapperRef);
    const {
        showYLabels,
        showXLabels,
        internalWidth,
        internalHeight,
        boundedWidth,
        boundedHeight,
    } = GadgetService.getChartSizes({
        wrapperRef: wrapperRef.current,
        isNpsChart: false,
        chartType,
        xAxisSize,
    });

    const getGroupMaxCount = (array: barData[]) => {
        const countOfSlicePerGroup = array.map((item: barData) =>
            (typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, 'bars'))
                ? item.bars.length
                : 0,
        );

        return Math.max(...countOfSlicePerGroup);
    };

    const getColumnsArray = (groupMaxCount: number): string[] =>
        Array(groupMaxCount).fill(null).map((x, i) => i.toString());

    const drawChart = (list: barData[], allList: barData[]) => {
        if (chartInstance && chartInstance.isInitialized && boundedWidth > 0 && boundedHeight > 0) {
            const [ domainMinFromAllList ] = chartInstance
                .getDomain({ array: allList, withoutSaving: true });
            const [ domainMin, domainMax ] = chartInstance
                .getDomain({ array: list });
            const maxCount = getGroupMaxCount(list);
            const tickFormat = chartInstance.getYAxisTickFormat(chartInstance.maxYValue);
            const columns = getColumnsArray(maxCount);
            const withPseudoAxis = domainMinFromAllList < 0;

            chartInstance
                .preConfig({
                    redraw: drawChart,
                    withDnD: true,
                    dragAndDropDataSet: list,
                    allList: allList,
                    withPseudoAxis: withPseudoAxis,
                    xAxisSize: xAxisSize,
                    tickList: list,
                })
                .drawContainer({
                    svgWidth: internalWidth,
                    svgHeight: internalHeight,
                    chartWidth: boundedWidth,
                    chartHeight: boundedHeight,
                    transformValue: `translate(${showYLabels ? yLeftLabelOffset : yLeftLabelOffset + rightOffset / 2}px, ${marginTop}px)`,
                })
                .drawYAxis({
                    domainMin: domainMin,
                    domainMax: domainMax,
                    // from top of the cord system (chart height) to origin (0)
                    rangeFrom: boundedHeight,
                    rangeTo: 0,
                    tickFormat: tickFormat,
                })
                .drawXAxis({
                    domainValues: list.map(({ id }) => id),
                    // from origin of the cord system (0) to right
                    rangeFrom: 0,
                    rangeTo: boundedWidth,
                    bandInnerPadding: innerPaddingForBarGroups,
                    bandOuterPadding: outerPaddingForBarGroups,
                    additionalSettings: [{
                        prop: 'style',
                        value: [ 'transform', `translateY(${boundedHeight}px)` ],
                    }],
                })
                .drawXBandAxis({
                    domainValues: columns,
                    // from the starting point to the end point of the barGroup on XAxis (for bars inside barGroup)
                    rangeFrom: 0,
                    rangeTo: chartInstance.xScale.bandwidth(),
                    maxCount: maxCount,
                })
                .drawBars({ groups: list, groupClass: styles.barGroup });
        }
    };

    useEffect(() => {
        if (svgRef.current && toolTipRef.current) {
            const chartInstance = new BarChartService({
                chartType: chartType,
                svgRef: svgRef.current as SVGSVGElement,
                toolTipRef: toolTipRef.current,
                gadgetFunction: gadgetFunction,
                drillDownEnabled: drillDownEnabled,
                gadgetId: gadgetId,
                percentage: percentage,
                decimalDigits,
                withVerticalGrid: true,
                withHorizontalGrid: true,
                showScore,
                t,
                setTickModal: setChangeTickModal,
                resetTickModal: resetTickData,
                saveTickOrder: saveTickOrder,
                setXAxisSize: setXAxisSize,
                setColorPickerData: setColorPickerData,
                drillDownFromSelection: drillDownFromSelection,
            });

            setChartInstance(chartInstance);
        }

        return () => {
            if (chartInstance) {
                chartInstance.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (dataSet && dataSet.length > 0 && dimensions !== null) {
            drawChart(dataSet, allGroups);
        }
    },[ dimensions, dataSet, xAxisSize ]);

    useEffect(() => {
        if (chartInstance && chartInstance.isInitialized) {
            chartInstance.setHighlightedSlice(highlightedLabel);
        }
    }, [ highlightedLabel ]);

    return (
        <div
            ref={ wrapperRef }
            className={ styles.barChartWrapper }
        >
            <ChartAxis
                axisLabels={ axisLabels }
                axisSize={{ width: boundedWidth, height: boundedHeight }}
                gadgetId={ gadgetId }
                chartSettings={{
                    marginTop,
                    showYLabels: showYLabels,
                    showXLabels: showXLabels,
                    xOffsetForNps: rightOffset,
                }}
                onApplyLabelModalData={ onApplyLabelModal }
            >
                <svg ref={ svgRef } />
            </ChartAxis>
        </div>
    );
};
