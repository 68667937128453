import { createSelector } from 'reselect';
import { unitsListSelector } from '/feedback/modules/VocFeedback.selectors';
import { MAX_LIMIT } from './actions';
import { isSupportUserSelector } from '/modules/selectors';
import { UsersRoleFeedbackService } from '/services';

export const responseRatesSelector = state => state.VocFeedback.surveys.responseRates.results;
export const surveysListSelector = state => state.VocFeedback.surveys.surveysList;
export const filterSelector = state => state.VocFeedback.surveys.filters;

export const surveyListLoadingSelector = state => state.VocFeedback.surveys.surveysList.loading;
export const isRatesFullyLoadedSelector = state => state.VocFeedback.surveys.responseRates.isFullyLoaded;

export const surveysSelector = createSelector(
    [ surveysListSelector, responseRatesSelector, unitsListSelector, isSupportUserSelector ],
    (surveys, rates, unitsList, supportUser) => {
        return surveys.results.map(
            survey => {
                const rateData = rates.find(rate => rate.id === survey.id);
                const unitRights = supportUser ? {
                    rights: UsersRoleFeedbackService.getRightsForAll("Manager"),
                } : unitsList.find(el => {
                    // todo hack for BE => {} or num
                    const surveyUnitId = typeof survey.unit === 'object' ? survey.unit.id : survey.unit;

                    return el.id === surveyUnitId;
                });

                return {
                    ...survey,
                    rights: unitRights?.rights?.surveyList || {},
                    ...rateData,
                };
            },
        );
    });

export const filtersSelector = state => state.VocFeedback.surveys.filters;

export const activeUnitSelector = createSelector([ unitsListSelector, filtersSelector ], (units, filters) => {
    return units.find(unit => unit.id === filters.unit) || {};
});

export const showPreloaderSelector = createSelector([ surveyListLoadingSelector, filtersSelector, surveysListSelector ],
    (loading, filters, surveys) => {
        return (loading && surveys.results.length === 0 && !filters.search);
    });

export const surveysListPaginationSelector = createSelector([ surveyListLoadingSelector, filtersSelector, surveysListSelector ],
    (loading, filters, surveys) => {
        return {
            MAX_LIMIT: MAX_LIMIT,
            loading,
            itemsCount: surveys.count,
            page: filters.page,
            itemsPerPage: filters.limit,
        };
    });
