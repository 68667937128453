import React, { Fragment } from 'react';
import { components } from 'react-select';
import { Badge } from 'reactstrap';
import { TooltipWrapper } from '/components';
import { optionLabel } from '../../modules/export.selector';

import './SelectComponentValue.scss';

//todo remove after delete old exporters
export const SelectComponentValue = ({ children, ...props }) => {
    const valueItem = props.getValue();
    let label, isNewExporter;

    if (valueItem.length) {
        ({ label, isNewExporter } = valueItem[0]);
    }

    const { color, title } = optionLabel(isNewExporter);

    return (
        <div className="export_source-select-value">
            {
                props.hasValue
                    ? <Fragment>
                        <TooltipWrapper value={ label }>
                            <div className="exporter-select-value__title">
                                <components.ValueContainer { ...props }>
                                    { children }
                                </components.ValueContainer>
                            </div>
                        </TooltipWrapper>

                        <div className="exporter-select-options-value__labels-container">
                            <Badge color={ color }
                                className={ 'exporter-select-options__source-label' }>
                                { title }
                            </Badge>
                        </div>
                    </Fragment>
                    : <div className="exporter-select-value__title">
                        <components.ValueContainer { ...props }>
                            { children }
                        </components.ValueContainer>
                    </div>
            }
        </div>
    );
};


