import { createSelector } from 'reselect';
import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';

export const StatisticsResultsSelector = state => state.VocFeedback.survey.statisticsResults;

export const StatisticsResultsFilterSelector = createSelector([ StatisticsResultsSelector ],
    ({ filters }) => filters);

export const StatisticCountersSelector = createSelector([ StatisticsResultsSelector, activeSurveySelector ],
    (results, { channel }) => {
        return results[channel] ? results[channel].statistic : {};
    });

export const statisticSelector = createSelector([ StatisticCountersSelector, activeSurveySelector ],
    (statistic, { channel }) => {
        const statisticLabelsMap = {
            aborted: {
                label: 'Partial',
            },
            completed: {
                label: 'Completed',
            },
            expired: {
                label: 'Expired',
            },
            imported: {
                label: 'Imported',
            },
            invited: {
                label: 'Invited',
            },
            opened: {
                label: 'Opened',
                information: 'Value of "Opened" questionnaires is based on tracking pixel and is not 100% precise.',
                decimals: '%',
                about: '~',
            },
            response_rate: {
                label: 'Response rate',
                decimals: '%',
            },
            response_rate_with_partial: {
                label: 'Response rate with partial',
                decimals: '%',
            },
            started: {
                label: 'Started',
            },
            terminated: {
                label: 'Terminated',
            },
            triggered: {
                label: 'Triggered',
            },
            visited: {
                label: 'Visited',
            },
            blacklisted: {
                label: 'Blacklisted',
            },
            quarantined: {
                label: 'Quarantined',
            },
        };

        const statisticToChannelMap = {
            'WEB': [
                'imported',
                'invited',
                'opened',
                'started',
                'completed',
                'terminated',
                'aborted',
                'expired',
                'blacklisted',
                'quarantined',
                'response_rate',
                'response_rate_with_partial',
            ],
            'DIGI': [
                'visited',
                'triggered',
                'opened',
                'started',
                'completed',
                'terminated',
                'aborted',
                'expired',
                'blacklisted',
                'quarantined',
                'response_rate',
                'response_rate_with_partial',
            ],
            'SMS': [
                'imported',
                'invited',
                'opened',
                'started',
                'completed',
                'terminated',
                'aborted',
                'expired',
                'blacklisted',
                'quarantined',
                'response_rate',
                'response_rate_with_partial',
            ],
            'IVR': [
                'imported',
                'invited',
                'opened',
                'started',
                'completed',
                'terminated',
                'aborted',
                'expired',
                'blacklisted',
                'quarantined',
                'response_rate',
                'response_rate_with_partial',
            ],
        };

        return channel ? statisticToChannelMap[channel].map(item => ({ ...statisticLabelsMap[item], value: statistic[item] })) : [];
    });
