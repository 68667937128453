import React, { useRef, useEffect, useState } from 'react';
import cx from 'classnames';

import { HEADER_HEIGHT } from '/constants';
import { IStickyBox, StickyBoxPosition } from './models';

import './StickyBox.scss';

export const StickyBox = ({
    children,
    position = StickyBoxPosition.TOP,
    offset = { query: null, size: HEADER_HEIGHT },
    threshold = 1,
    root = null,
    stuckClass = '',
    unstuckClass = '',
    onChange,
}: IStickyBox) => {
    const stickyBoxRef = useRef<HTMLDivElement | null>(null);
    const [ isStuck, setStuck ] = useState(false);

    const getStickyBoxOffset = () => {
        let totalOffset = offset?.size || 0;

        if (offset && offset.query) {
            const headerElement = document.querySelector(offset.query);

            if (headerElement) {
                totalOffset += headerElement.getBoundingClientRect().height;
            }
        }

        return totalOffset;
    };

    useEffect(() => {
        const stickyBoxRefCached = stickyBoxRef.current;

        if (!stickyBoxRefCached) return;

        const options = {
            threshold,
            root,
            rootMargin: `-${getStickyBoxOffset() + 1}px 0px 0px 0px`,
        };

        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                const stickyEntry = entries[0];
                const isStuck = stickyEntry.intersectionRatio < threshold;

                setStuck(isStuck);
                onChange?.(isStuck);
            },
            options as IntersectionObserverInit,
        );

        observer.observe(stickyBoxRefCached);

        return () => {
            if (stickyBoxRefCached) {
                observer.unobserve(stickyBoxRefCached);
            }
        };
    }, [ stickyBoxRef, threshold, offset ]);

    return (
        <div
            className={ cx(
                'stickyBox',
                {
                    [stuckClass]: stuckClass && isStuck,
                    [unstuckClass]: unstuckClass && !isStuck,
                })
            }
            style={{ [position]: getStickyBoxOffset() }}
            ref={ stickyBoxRef }
        >
            { children }
        </div>
    );
};
