export * from './rightsModules/rights.selectors';
import { createSelector } from 'reselect';

export const userInfoSelector = state => state.UserInfo.userInfo;
export const webUiInfoSelector = state => state.UserInfo.webUiInfo;
export const isSupportUserSelector = state => state.UserInfo.userInfo.isSupportUser;
export const hasVisualWriteAccessSelector = state => state.UserInfo.userInfo.rights.hasVisualWriteAccess;
export const columnTypesSelector = state => state.UserInfo.columnTypesList;
export const runnerUrlSelector = state => state.UserInfo.runnerUrl;
export const aboutDataSelector = createSelector(
    [ userInfoSelector ], user => user.about_data,
);

const message = 'url link is not configured for your instance';

export const iframeRoutesSelector = createSelector([ userInfoSelector ], user => {
    return [
        {
            path: '/act',
            url: user.ticketSystemUrl,
            hasAccess: user.rights.hasActAccess,
            noUrlMessage: `VoC Act ${ message }`,
        },
        {
            path: '/voc_ci',
            url: user.vocCIUrl,
            hasAccess: user.rights.hasVocCIAccess,
            noUrlMessage: `VoC-CI ${ message }`,
        },
    ];
});

export const containerIsFluidSelector = createSelector(
    [ userInfoSelector ],
    ({ configuration }) => {
        return configuration.widenLayout;
    });

export const aboutSystemSelector = createSelector([ userInfoSelector ], ({ JWT, firstName }) => ({
    JWT,
    firstName,
}));

export const systemInfoSelector = createSelector([ userInfoSelector ], user => {
    if (user) {
        return {
            locale: user.locale,
            zendeskJWT: user.zendeskJWT,
            notificationJWT: user.notificationJWT,
            widenLayout: user.widenLayout,
            rights: user.rights,
            socketNotificationHost: user.socketNotificationHost,
            ticketSystemUrl: user.ticketSystemUrl,
        };
    }
});

export const userRightsSelector = createSelector([ userInfoSelector ], user => {
    return user.rights;
});

export const headerUserInfoSelector = createSelector([ userInfoSelector ], user => {
    return {
        logoutUrl: user.logout_url,
        environment: user.environment,
        firstName: user.firstName,
        email: user.email,
        ticketSystemUrl: user.ticketSystemUrl,
        hasActAccess: user.rights.hasActAccess,
        ticketSystemUseIframe: user.ticketSystemUseIframe,
        labs_access: user.labs_access,
        pf_workspace: user.pf_workspace,
        pf_enabled: user.pf_enabled,
    };
});

export const userSettingsSelector = createSelector([ userInfoSelector ], user => {
    if (user) {
        return user.configuration;
    }
});

export function zendeskDictionarySelector() {
    return {
        "/store/uploads-list": 'store uploads list',
        "/store/upload-data": 'store upload data',
        "/store/surveys-list": '',
        "/store/recoders": '',
        "/store/virtual-sources": '',
        "/feedback/survey-list": 'feedback surveys list',
        "/feedback/customer-journey": 'feedback customer journey',
        "/feedback/import": 'feedback import history',
        "/feedback/export/results": 'feedback export results',
        "/feedback/export/exporters-queue": 'feedback export exporters queue',
        "/feedback/export/exporters-list": 'feedback export exporters list',
        "/feedback/export/export-schedule": 'feedback export export schedule',
        "/about": 'about',
        "/settings": 'settings',
    };
}

export const delimiterVariantsSelector = () => ([
    { label: ' , (comma delimiter)', value: ',' },
    { label: ' ; (semicolon delimiter)', value: ';' },
    { label: ' . (dot delimiter)', value: '.' },
    { label: ' | (vertical bar delimiter)', value: '|' },
    { label: ' ↹ (tab delimiter)', value: '\t' },
]);

//Routes selectors
export const VocRoutesSelector = state => state.UserInfo.endpoints;
export const getLabelClassByType = state => {
    return ({
        active: 'success',
        deleted: 'danger',
        paused: 'warning',
        upload: 'success',
        system: 'info',
        virtual: 'info',
        survey: 'info',
        info: 'info',
        success: 'success',
        danger: 'danger',
        warning: 'warning',
        inactive: 'warning',
    })[state];
};

export const exportModalDateFormatSelect = () => [
    { label: 'd.m.Y', value: 'd.m.Y' },
    { label: 'd-m-Y', value: 'd-m-Y' },
    { label: 'Y-m-d', value: 'Y-m-d' },
    { label: 'm/d/Y', value: 'm/d/Y' },
    { label: 'Y.m.d', value: 'Y.m.d' },
];

export const exportModalTimeFormatSelect = () => [
    { label: 'hh:mm:ss', value: 'H:i:s' },
    { label: 'hh:mm:ss  am/pm', value: 'h:i:s a' },
    { label: 'hh:mm', value: 'H:i' },
    { label: 'hh:mm  am/pm', value: 'h:i a' },
    { label: 'TH:i:sP', value: 'TH:i:sP' },
];
