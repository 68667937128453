import React, { useRef, useState } from "react";
import { Range, Direction, getTrackBackground } from 'react-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import './RangeInput.scss';

const getMarkStyles = (isVertical, index, step, max, value, colors) => {
    const backgroundRule = isVertical ? (index * step > max - value) : (index * step < value);

    return ({
        [isVertical ? 'height' : 'width']: index % 5 ? '1px' : '2px',
        [isVertical ? 'width' : 'height']: index % 5 ? '11px' : '16px',
        backgroundColor: backgroundRule ? colors[0] : colors[1],
    });
};

export const RangeInput = ({ step, min, max, value, orientation, label, onChange, colors, showRefreshButton, refreshCallBack, trackPointsId = '' }) => {
    const [ limitDirty, setLimitDirty ] = useState(false);
    const isVertical = orientation === 'vertical';
    const direction = isVertical ? Direction.Up : Direction.Right;
    const rangeRef = useRef();

    const trackStyles = {
        background: getTrackBackground({
            colors,
            values: [ value ],
            direction,
            min,
            max,
        }),
    };

    const onChangeHandler = values => {
        setLimitDirty(true);
        values[0] !== value && values[0] !== 0 && onChange(values[0]);
    };

    const onRefreshCallback = () =>
        refreshCallBack({}).promise
            .then(() => setLimitDirty(false));

    return (
        <div className={ cx('range-wrapper', { 'range-wrapper--vertical': isVertical }) }>
            { label && <span className={ cx('range-label') }>{ label }</span> }
            <div className={ 'range-slider' }>
                <Range
                    ref={ rangeRef }
                    direction={ direction }
                    values={ [ value ] }
                    step={ step }
                    min={ min }
                    max={ max }
                    onChange={ onChangeHandler }
                    renderMark={ ({ props, index }) => (
                        <div
                            { ...props }
                            style={{ ...props.style, ...getMarkStyles(isVertical, index, step, max, value, colors) }}
                        />
                    )
                    }
                    renderTrack={ ({ props, children }) => (
                        <div
                            className={ 'slider-track' }
                            onMouseDown={ props.onMouseDown }
                            onTouchStart={ props.onTouchStart }
                        >
                            <div
                                className={ 'slider-background' }
                                ref={ props.ref }
                                style={ trackStyles }
                            >
                                {
                                    children.map(childrenData => ({
                                        ...childrenData,
                                        props: {
                                            ...childrenData.props,
                                            id: trackPointsId + childrenData.key + '_slider-track',
                                        },
                                    }))
                                }
                            </div>
                        </div>
                    ) }
                    renderThumb={ ({ props, isDragged }) => {
                        const onMouseDown = e => e.stopPropagation();

                        return (
                            <div className={ 'slider-thumb' } { ...props }>
                                <div className={ cx(
                                    'thumb-inner-block',
                                    { 'thumb-inner-block--dragged': isDragged },
                                ) }
                                />
                                {
                                    isDragged
                                        ? <div className='thumb-label'>
                                            { value }
                                        </div>
                                        : showRefreshButton && limitDirty && (
                                            <div
                                                onClick={ onRefreshCallback }
                                                onMouseDown={ onMouseDown }
                                                className='thumb-label-refresh'
                                            >
                                                <FontAwesomeIcon icon='sync-alt'/>
                                            </div>
                                        )
                                }
                            </div>
                        );
                    } }
                />
            </div>
        </div>
    );
};

RangeInput.defaultProps = {
    showRefreshButton: false,
    orientation: 'horizontal',
    colors: [ '#548BF4', '#ccc' ],
    min: 0,
    max: 100,
    step: 1,
    value: 1,
};
