import React, { useState, useEffect, createRef } from 'react';
import { select } from 'd3';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RangeInput, EmptyData, WordCloudChartD3, PopoverChartTooltip } from '/components';

import './QueryChartWordCloud.scss';

export const QueryChartWordCloud = ({
    width,
    height,
    newChangeFilter,
    wordList,
    updatePaginationFilters,
    filterForInclude,
    filterForExclude,
    idInclude,
    idExclude,
    name,
}) => {
    const [ statusButton, setStatusButton ] = useState(true);
    const [ selectedFilterType, setSelectedFilterType ] = useState(idInclude);
    const [ secondFilterType, setSecondFilterType ] = useState(idExclude);
    const tooltipRef = createRef();

    useEffect(() => {
        const cloudWrap = select('#wordCloudId').selectAll('text');

        cloudWrap.attr('class', word => changeClass(word));
    }, [ filterForInclude.values, filterForExclude.values ]);

    const changeClass = word => {
        const { isInclude, isExclude } = checkEntry(word.text);
        let classes = '';

        if (isInclude) {
            classes = 'svg-text__filtered svg-text__include';
        } else if (isExclude) {
            classes = 'svg-text__filtered svg-text__exclude';
        }

        return classes;
    };

    const checkEntry = text => {
        return {
            isInclude: filterForInclude.values.includes(text),
            isExclude: filterForExclude.values.includes(text),
        };
    };

    const changeStatusButton = status => {
        setStatusButton(status);
        setSelectedFilterType(status ? idInclude : idExclude);
        setSecondFilterType(!status ? idInclude : idExclude);
    };

    const getControls = () => (
        <>
            <div className={ 'btns-wrapper' }>
                <button className={ cx('btn', { active: statusButton }) }
                    onClick={ () => changeStatusButton(true) }
                >
                    <FontAwesomeIcon className={ 'icon' } icon={ 'eye' }/>
                    <span>Include lemmata</span>
                </button>
                <button className={ cx('btn', { active: !statusButton }) }
                    onClick={ () => changeStatusButton(false) }
                >
                    <FontAwesomeIcon className={ 'icon' } icon={ 'eye-slash' }/>
                    <span>Exclude lemmata</span>
                </button>
            </div>
        </>
    );

    const changeLemmataLimit = limit => {
        limit && updatePaginationFilters({ limit, name });
    };

    const getInput = () => (
        <RangeInput
            value={ wordList.filters.limit }
            onChange={ changeLemmataLimit }
            label={ 'Lemmas' }
            trackPointsId={ 'wordCloudLemmas_' }
        />
    );

    const onWordClick = ({ text }) => {
        newChangeFilter({
            text,
            selectedFilterType,
            secondFilterType,
            allFilters: {
                [idInclude]: filterForInclude,
                [idExclude]: filterForExclude,
            },
        });
    };

    const words = wordList.lemmata.filter((el, idx) => idx < wordList.filters.limit);
    const isNotEmpty = !!wordList.lemmataCount;
    const isLoading = wordList.lemmataWordCloudLoading;

    return (
        <div className={ 'word-cloud__section' }>
            {
                !isNotEmpty && !isLoading
                    ? <EmptyData errorText={ wordList.error }/>
                    : isNotEmpty
                        ? <>
                            <div className={ 'button-place' }>
                                { getControls() }
                            </div>

                            <div className={ 'cloud-place' }>
                                <WordCloudChartD3
                                    width={ width }
                                    height={ height }
                                    id={ 'wordCloudId' }
                                    words={ words }
                                    onWordClick={ onWordClick }
                                    changeClass={ changeClass }
                                    toolTipRef={ tooltipRef }
                                    onWordOver
                                />
                            </div>

                            <div className={ 'ranger-place' }>
                                { getInput() }
                            </div>

                            <PopoverChartTooltip ref={ tooltipRef } />
                        </>
                        : null
            }
        </div>
    );
};

QueryChartWordCloud.defaultProps = {
    width: 900,
    height: 450,
};
