import React, { useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { ControlPanel, DeviceFrame } from './components';
import { ThinSpinner } from '/components';
import { _getPreviewData, _getPreviewDevices } from './modules/PreviewMode.selectors';
import { cleanUpPreview, getPreviewData, getRunnerUrl } from './modules/PreviewMode.modules';
import { PreviewService } from './services';
import { IPreviewMode, TDevice, TIframeRef, TSizes } from './models';

import styles from './style.module.scss';

export const PreviewMode = ({ match: { params: { surveyId } } }: IPreviewMode) => {
    const {
        loading,
        editData,
        digiId,
        selectedThemeId,
        previewThemeId,
        unitId,
        runnerUrl,
    } = useSelector(_getPreviewData);
    const devicesOptions = useSelector(_getPreviewDevices);
    const selectedLanguage = editData.selectedLanguage
        || sessionStorage.getItem('feedbackActiveLanguage')
        || 'EN';

    const history = useHistory();
    const dispatch = useDispatch();
    const iframeRef = useRef<TIframeRef>(null);
    const iframeWrapperRef = useRef<HTMLIFrameElement>(null);

    const windowSizes: TSizes = {
        height: window.innerHeight - 120 < 320
            ? 320
            : window.innerHeight - 120,
        width: window.innerWidth - 60 < 320
            ? 320
            : window.innerWidth - 60,
    };
    const [ dimensions, setDimensions ] = useState(windowSizes);
    const [ viewType, setViewType ] = useState('full-screen');
    const [ deviceType, setDeviceType ] = useState(devicesOptions[0]);
    const [ loadingRunner, setLoadingRunner ] = useState(false);

    const changeDimensionsHandler = (sizes: TSizes) => {
        const newSizes = viewType !== 'mobile'
            ? {
                height: sizes.height > windowSizes.height ? windowSizes.height : sizes.height,
                width: sizes.width > windowSizes.width ? windowSizes.width : sizes.width,
            }
            : sizes;

        if(iframeRef.current) {
            const iframe = iframeRef.current;

            iframe.style.minHeight = newSizes.height + 'px';
            iframe.style.minWidth = newSizes.width + 'px';
            iframe.style.maxHeight = newSizes.height + 'px';
            iframe.style.maxWidth = newSizes.width + 'px';
        }

        setDimensions(newSizes);
    };

    const changeDeviceType = (device: TDevice) => {
        changeDimensionsHandler(device.sizes);

        setDeviceType(device);
    };

    const onChangeViewType = (type: string) => {
        PreviewService.updateRunnerFullScreenStyles({
            iframeRef,
            viewType: type,
        });

        const sizesData = type === 'mobile'
            ? deviceType.sizes
            : windowSizes;

        changeDimensionsHandler(sizesData);
    };

    const showDigiHandler = () => {
        setLoadingRunner(true);

        PreviewService.showDigiPopup({
            language: selectedLanguage,
            id: digiId,
            apiUrl: runnerUrl,
            selectedThemeId: editData.selectedThemeId === selectedThemeId || !editData.selectedThemeId
                ? previewThemeId
                : editData.selectedThemeId,
            iframeRef,
            setLoadingRunner,
        });
    };

    useEffect(() => {
        onChangeViewType(viewType);
    }, [ viewType ]);

    useEffect(() => {
        showDigiHandler();
    }, [ editData ]);

    useEffect(() => {
        if(runnerUrl) {
            //todo for development
            // const prefix = process.env.NODE_ENV === 'development' ? '/staticfiles' : '';

            PreviewService.injectDigiRunnerUrl({
                path: runnerUrl, // + prefix,
                onLoadHandler: () => showDigiHandler(),
                iframeRef,
            });
        }
    }, [ runnerUrl ]);

    useEffect(() => {
        unitId && dispatch(getRunnerUrl(unitId));
    }, [ unitId ]);

    useEffect(() => {
        dispatch(getPreviewData(surveyId));

        return () => {
            dispatch(cleanUpPreview());
        };
    }, []);

    return (
        <DocumentTitle title={ 'Preview Mode' }>
            <div className={ styles.previewPageWrapper }>
                <ControlPanel
                    onClickBack={ () => history.push(`/feedback/survey/${surveyId}/questions`) }
                    viewType={ viewType }
                    deviceType={ deviceType }
                    setViewType={ setViewType }
                    setDeviceType={ changeDeviceType }
                    dimensions={ dimensions }
                    setDimensions={ changeDimensionsHandler }
                />
                <div className={ styles.iframeWrapper } ref={ iframeWrapperRef }>
                    {
                        (loading || loadingRunner)
                        && <div className={ styles.preloaderWrapper }>
                            <ThinSpinner />
                        </div>
                    }

                    <DeviceFrame
                        disable={ viewType !== 'mobile' }
                        device={ deviceType.value }
                        className={ cx(styles.iframeDevice, { [styles.iframeDeviceDesktop]: viewType !== 'mobile' }) }
                    >
                        <iframe ref={ iframeRef } className={ styles.iframe }/>
                    </DeviceFrame>
                </div>
            </div>
        </DocumentTitle>
    );
};
