import React, { useEffect, useState } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { Button, Row, Col, Label, Container, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputLoader } from '/feedback/components';
import { VocFormSelectField, VocModal, VocFormNumberInput } from '/components';
import { HelperService } from '/services';
import { renderConditionFlow } from './renderConditionFlow';
import { quarantinesFlowValidation } from './QuarantinesFlowValidation';
import { logicalOperation, actionType } from '/feedback/modules/VocFeedback.selectors';
import { QuarantineFlowConditionString } from '../QuarantineFlowConditionString';

import './QuarantinesFlowModal.scss';

const QuarantinesFlow = ({
    allSurveyLoading,
    selectOptions,
    reset,
    close,
    show,
    formValue,
    array,
    initialValues,
    change,
    flowOptions,
    valid,
    submitQuarantineItem,
    isSurveyQuarantine = false,
    typeFlowModal,
}) => {
    const [ submit, setSubmit ] = useState(false);

    useEffect(() => {
        isConditionsEmpty() && addCondition();
    }, [ formValue ]);

    const addCondition = () => {
        const blocked_by = flowOptions[0].blocked_by[0].options
            .find(({ value }) => value === null) || null;
        const conditions = [{ ...initialValues.conditions, blocked_by }];

        array.unshift(`conditions`, ...conditions);
    };

    const isConditionsEmpty = () => !(formValue.conditions && formValue.conditions.length);

    const changeCondition = (index, el, field) => {
        if (field !== 'matching_value') {
            change(`conditions[${ index }]matching_value`, null);
        }
        if (field === 'blocked_by') {
            change(`conditions[${ index }]expression`, null);
        }
    };

    const onSubmit = () => {
        const {
            action_type,
            logical_operation,
            days,
            hours,
            conditions,
            survey_group,
            id,
        } = formValue;

        setSubmit(true);

        const blockingSurveyGroup = ({ value, label }) => {
            //null is All Surveys param
            return value === 'null'
                ? null
                : {
                    id: value,
                    name: label,
                };
        };

        const data = {
            survey_group: {
                id: survey_group.value,
                name: survey_group.label,
            },
            logical_operation,
            action: {
                type: action_type.value,
                days: action_type.value === 'DELAY' ? days : null,
                hours: action_type.value === 'DELAY' ? hours : null,
            },
            conditions: [
                ...conditions.map(({ expression, blocked_by, matching_value }) => {
                    let matchingValue = matching_value && (matching_value.value || matching_value.value === 0)
                        ? matching_value.value
                        : matching_value;

                    if (matchingValue !== null && typeof matchingValue !== 'number') {
                        matchingValue = matchingValue.trim();
                    }

                    let value = {
                        expression: expression.value,
                    };

                    if(blocked_by.condition_type === 'survey_group') {
                        value = {
                            ...value,
                            days: matchingValue,
                            blocking_survey_group: blockingSurveyGroup(blocked_by),
                        };
                    } else if(blocked_by.condition_type === 'random_100') {
                        value = {
                            ...value,
                            matching_value: +matchingValue,
                        };
                    } else {
                        value = {
                            ...value,
                            matching_value: matchingValue,
                            metadata_name: blocked_by.label,
                        };
                    }

                    return {
                        condition_type: blocked_by.condition_type || 'metadata',
                        value,
                    };
                }),
            ],
        };
        const isCopy = typeFlowModal === 'copy';

        submitQuarantineItem(id, data, isCopy);
    };

    const renderHeaderText = () => {
        const typeModal = typeFlowModal === 'copy' ? 'Copy' : 'Edit';

        return initialValues.id ? `${typeModal} quarantine` : 'Add quarantine';
    };

    const getFooter = () => {
        const isDisabled = !valid || isConditionsEmpty() || submit;

        return (
            <div className={ 'question-flow_footer-buttons' }>
                <Button onClick={ close } color={ 'white' } outline>
                    Cancel
                </Button>
                <Button
                    disabled={ isDisabled }
                    onClick={ e => !isDisabled && onSubmit(e) }
                    color="primary"
                >
                    <FontAwesomeIcon icon={ 'check' }/>
                    Save
                </Button>
            </div>
        );
    };

    const getBody = () =>
        <div className={ 'flows-quarantines__modal-body' }>
            <Row className={ 'flows-quarantines__row' }>
                <Col md={ 12 }>
                    <Label htmlFor={ 'survey_group' }>
                        Affected survey
                    </Label>
                </Col>
                <Col md={ 6 }>
                    {
                        allSurveyLoading
                            ? <InputLoader/>
                            : <Field
                                name={ `survey_group` }
                                component={ VocFormSelectField }
                                options={ selectOptions.affectedOptions }
                                placeholder={ 'Choose affected survey' }
                                disabled={ isSurveyQuarantine }
                                withError={ true }
                                required
                                portal
                            />
                    }
                </Col>
            </Row>
            <Row className={ 'flows-quarantines__row' }>
                <Col md={ 6 }>
                    <FormGroup>
                        <Label htmlFor={ 'action_type' }>
                            Action type
                        </Label>
                        <Field
                            name={ 'action_type' }
                            component={ VocFormSelectField }
                            options={ actionType }
                            className={ 'no-margin' }
                            withError={ false }
                            required
                        />
                    </FormGroup>
                </Col>
                {
                    formValue.action_type && formValue.action_type.value === 'DELAY'
                    && <>
                        <Col md={ 3 }>
                            <FormGroup>
                                <Label htmlFor={ 'days' }>
                                    For Days
                                </Label>
                                <Field
                                    disableSymbols
                                    type={ 'text' }
                                    name={ `days` }
                                    min={ 0 }
                                    max={ 9999 }
                                    component={ VocFormNumberInput }
                                    className={ 'flow-quarantine-number_field' }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={ 3 } >
                            <FormGroup>
                                <Label htmlFor={ 'hours' }>
                                    Hours
                                </Label>
                                <Field
                                    disableSymbols
                                    type={ 'text' }
                                    name={ `hours` }
                                    min={ 0 }
                                    max={ 23 }
                                    component={ VocFormNumberInput }
                                    className={ 'flow-quarantine-number_field' }
                                />
                            </FormGroup>
                        </Col>
                    </>
                }
            </Row>

            <Row className='justify-content-between conditions__contr'>
                <Col md={ 3 }>
                    <label htmlFor={ 'logical_operation' }>Join conditions with</label>
                    <Field
                        name={ 'logical_operation' }
                        component={ VocFormSelectField }
                        options={ logicalOperation }
                        normalize={ HelperService.normalizeSelectValue }
                        format={ value => HelperService.getSelectedValue(logicalOperation, value) }
                    />
                </Col>
                <Col md={ 4 } className={ 'text-right add-condition__wrap' }>
                    <Button outline color="primary" onClick={ addCondition }>
                        <FontAwesomeIcon icon={ 'plus' }/>
                        <span className='button-primary__text'>Add condition</span>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md={ 12 }>
                    <div className='conditions__header'>
                        <Row>
                            <Col md={ 4 }> Criteria </Col>
                            <Col md={ 4 }> Condition </Col>
                            <Col md={ 4 }> Value </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className='conditions__box'>
                <Container>
                    {
                        isConditionsEmpty()
                            ? <Col md={ 12 } className={ 'condition__empty' }> Create conditions </Col>
                            : <FieldArray
                                name="conditions"
                                component={ renderConditionFlow }
                                flowOptions={ flowOptions }
                                changeCondition={ changeCondition }
                            />
                    }
                </Container>
            </Row>
            {
                formValue.conditions && formValue.action_type
                && <Row className={ 'flows-quarantines__combined-condition text-left' }>
                    <QuarantineFlowConditionString
                        formValuesSelector={ formValue }
                    />
                </Row>
            }
        </div>;

    return (
        <VocModal
            toggle={ close }
            header={ renderHeaderText() }
            isOpen={ show }
            footer={ getFooter() }
            onClosed={ () => {
                setSubmit(false);
                reset();
            } }
            size={ 'lg' }
            modalClassName='modal-scrollable'
        >
            { getBody() }
        </VocModal>
    );
};

export const QuarantinesFlowModal = reduxForm({
    form: 'QuarantinesFlow',
    enableReinitialize: true,
    validate: quarantinesFlowValidation,
})(QuarantinesFlow);
