import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ZeroShot } from './scenes/ZeroShot';
import { VocSocketHoc } from '/components';

@VocSocketHoc('labs')
export class VocLabs extends Component {
    render() {
        const { url } = this.props.match;

        return (
            <div>
                <Switch>
                    <Route exact
                        path={ `${url}/zero-shot/` }
                        render={ ZeroShot }/>

                    <Redirect to={ `${url}/zero-shot/` }/>
                </Switch>
            </div>
        );
    }
}
