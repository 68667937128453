import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { SettingsForm } from "/feedback/components";
import { PageRequests } from '/services';
import { VocCleaupHOC, Preloader } from '/components';
import { activeSurveySelector, getEnterpriseId } from '/feedback/modules/VocFeedback.selectors';
import {
    getSurveySettings,
    putSurveyCommonSettings,
    cleanup,
    getSurveyClassifierList,
} from './modules/SurveyCommonSettings.modules';
import { surveyFormSettingsSelector } from './modules/SurveyCommonSettings.selectors';
import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';
import { assetsSelector } from "/modules/uploadFileModules/uploadFile.selectors";
import { SettingsService } from '/feedback/services/SettingsService';
import { rightsSurveySelector } from '../../modules/survey.selectors';

const mapStateToProps = state => ({
    activeSurvey: activeSurveySelector(state),
    surveySettings: surveyFormSettingsSelector(state),
    assets: assetsSelector(state),
    enterpriseId: getEnterpriseId(state),
    rightsSurvey: rightsSurveySelector(state),
});

const mapDispatchToProps = {
    getSurveySettings,
    getSurveyClassifierList,
    putSurveyCommonSettings,
    cleanup,
    getAssets,
    uploadAsset,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class SurveyCommonSettings extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        const { activeSurvey: { survey_group, unit }, getSurveyClassifierList } = this.props;

        getSurveyClassifierList();
        (survey_group && unit) && this.getSurveySettings();
    }

    componentDidUpdate(prevProps) {
        const { activeSurvey: { survey_group } } = this.props;

        if (survey_group !== prevProps.activeSurvey.survey_group) {
            this.getSurveySettings();
        }
    }

    getSurveySettings() {
        this.pageRequests.cleanup();

        const { activeSurvey: { survey_group, unit }, getSurveySettings } = this.props;

        const model = {
            survey_group,
            unit,
        };
        const requests = getSurveySettings(model);

        requests.forEach(request => this.pageRequests.addRequest(request));
    }

    @autobind
    putSurveyCommonSettings(body) {
        const { enterpriseId, activeSurvey } = this.props;
        const model = SettingsService.changeBodyCommonSettings({
            id: activeSurvey.survey_group,
            body,
        }, enterpriseId);

        return this.props.putSurveyCommonSettings(model);
    }

    render() {
        const {
            surveySettings: {
                loading,
                sending,
                fieldsMap,
                initialValues,
                classifierLoading,
                unsubscribeLoading,
            },
            assets,
            getAssets,
            uploadAsset,
            rightsSurvey,
        } = this.props;

        const isLoading = loading || classifierLoading || unsubscribeLoading;

        return (
            <DocumentTitle title="Feedback :: Survey Common Setting">
                <Fragment>
                    {
                        isLoading
                            ? <Preloader/>
                            : <SettingsForm
                                fieldsMap={ fieldsMap }
                                initialValues={ initialValues }
                                putSettings={ this.putSurveyCommonSettings }
                                sendingSettings={ sending }
                                form={ 'SurveyCommonSettingsForm' }
                                assets={ assets }
                                getAssets={ getAssets }
                                uploadAsset={ uploadAsset }
                                permission={ rightsSurvey }
                            />
                    }
                </Fragment>
            </DocumentTitle>
        );
    }
}
