import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';

import { fontAwesomeInit } from './FontAwesomeInit';
import { Toasts, RouteConfirm } from '/components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { Confirm } from '/scenes/Confirm';
import { systemInfoSelector, userRightsSelector } from '/modules/selectors';
import { getUserInfo, getWebUiInfo } from './modules/index';
import { storeCounters, storeMineQueries } from '/scenes/Header/scenes/VocMenu/modules';
import { ZendeskSnippet, LogoutService, TinyEditorService, I18nService } from '/services';
import VocRouter from './scenes/VocRouter';

import 'split-pane-react/esm/themes/default.css';
import './styles/index.scss';

fontAwesomeInit();

const mapStateToProps = state => ({
    systemInfo: systemInfoSelector(state),
    userRights: userRightsSelector(state),
});

const mapDispatchToProps = {
    getUserInfo,
    getWebUiInfo,
    storeCounters,
    storeMineQueries,
    showConfirm,
};

@hot(module)
@connect(mapStateToProps, mapDispatchToProps)
export default class App extends Component {
    state = {
        isSuccessUserInfo: false,
        completeDOMTree: false,
    };

    componentDidMount() {
        TinyEditorService.setFonts();

        const { getWebUiInfo } = this.props;
        const token = localStorage.getItem('token');

        if (token || window.location.port === '3000') {
            getWebUiInfo();
            this.getUserInfoHandler();

            document.addEventListener('readystatechange', this.listenerReadyStateChange);
        } else {
            window.location.replace('/login');
        }
    }

    @autobind
    async getUserInfoHandler() {
        const { getUserInfo } = this.props;

        await I18nService.init();

        return await getUserInfo().promise
            .then(this.onSuccessUserInfoGet, () => {
                localStorage.removeItem('token');
                LogoutService.timerCallback('/login');
            });
    }

    @autobind
    listenerReadyStateChange() {
        if (document.readyState === 'complete') {
            this.setState({
                completeDOMTree: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { isSuccessUserInfo, completeDOMTree } = this.state;

        if (isSuccessUserInfo && completeDOMTree && (!prevState.isSuccessUserInfo || !prevState.completeDOMTree)) {
            const pagePreloader = document.querySelector('body > .vochub-app-preloader__box');

            pagePreloader && pagePreloader.remove();

            document.removeEventListener('readystatechange', this.listenerReadyStateChange);
        }
    }

    @autobind
    async onSuccessUserInfoGet(response) {
        const { locale, zendeskJWT } = this.props.systemInfo;
        const { counters, mineQueries, logout_time, logout_url } = response;

        /**
         * TODO: Rewrite this part
         */
        this.props.storeCounters(counters);
        this.props.storeMineQueries(mineQueries);

        await I18nService.changeLanguage(locale);
        LogoutService.initLogoutSpy(logout_time, logout_url);

        this.setState({
            isSuccessUserInfo: true,
        });

        document.documentElement.setAttribute('lang', locale);
        ZendeskSnippet.getZendeskButton(zendeskJWT);
    }

    render() {
        const { systemInfo, showConfirm, userRights } = this.props;
        let containerClass = 'container';

        if (systemInfo.locale) {
            containerClass += (systemInfo.widenLayout ? '-fluid' : '');
        } else {
            return null;
        }

        return (
            <>
                <Router
                    getUserConfirmation={
                        (message, callback) =>
                            RouteConfirm(message, callback, showConfirm)
                    }
                >
                    <VocRouter
                        containerClass={ containerClass }
                        userRights={ userRights }
                    />
                </Router>
                <Toasts/>
                <Confirm/>
            </>
        );
    }
}
