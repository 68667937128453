import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { HelperService } from '/services';
import { VocFormSelectField, VocFormTextField } from '/components';
import iconCross from '/images/icon-cross.svg';

export const RenderCustomColumns = ({ fields, field, idRow, changeDataType, typeOptions }) => {
    const fieldValue = fields.get(idRow);
    const dateType = fieldValue.data_type?.value || fieldValue.data_type;

    const [ isRequiredDataValue, setIsRequiredDataValue ] = useState(undefined);

    useEffect(() => {
        typeOptions.forEach(item =>
            item.options.forEach(option =>
                option.value === dateType && setIsRequiredDataValue(option.valueRequired)));
    }, [ dateType, typeOptions ]);

    return (
        <>
            <Col md={ 10 }>
                <Row>
                    <Col md={ 4 }>
                        <Field
                            type={ `${ field }.column_title` }
                            placeholder={ 'Title' }
                            name={ `${ field }.column_title` }
                            component={ VocFormTextField }
                            required
                        />
                    </Col>
                    <Col md={ 4 }>
                        <Field
                            name={ `${ field }.data_type` }
                            component={ VocFormSelectField }
                            options={ typeOptions }
                            placeholder={ 'Choose type' }
                            onChange={ el => changeDataType(idRow, el) }
                            format={ value => HelperService.getSelectedValue(typeOptions, value, true) }
                            portal
                            required
                        />
                    </Col>
                    <Col md={ 4 }>
                        <Field
                            type={ `${ field }.data_value` }
                            placeholder={ 'Column' }
                            name={ `${ field }.data_value` }
                            component={ VocFormTextField }
                            disabled={ !isRequiredDataValue }
                            required
                        />
                    </Col>
                </Row>
            </Col>

            <Col md={ 1 }>
                <span onClick={ () => fields.remove(idRow) } className='exporter-condition__remove'>
                    <img src={ iconCross } alt=""/>
                </span>
            </Col>
        </>
    );
};
