import React from 'react';
import cx from 'classnames';
import { TooltipWrapper } from '/components';

export const VocSelectMineValueContainer = ({ deleteValue, value, additionalValueKey }) => {
    return value && value.map((item, index) =>
        <TooltipWrapper
            key={ index }
            value={ additionalValueKey ? `${ item.label } / ${ item[additionalValueKey] }` : item.label }
        >
            <div key={ index }
                className={ cx('voc-select-control-labels__item', { 'voc-select-control-labels__item--flex': !!additionalValueKey }) }>
                {
                    additionalValueKey
                        ? <>
                            <div className={ 'voc-select-control-labels__item-label' }>
                                { item.label }
                            </div>
                            <div className={ 'voc-select-control-labels__item-additional' }>
                                { `/ ${ item[additionalValueKey] }` }
                            </div>
                        </>
                        : <div className={ 'voc-select-control-labels__item-text' }>
                            { item.label }
                        </div>
                }
                <button className={ 'voc-select-control-labels__item-delete' }
                    onClick={ deleteValue.bind(null, item.value) }/>
            </div>
        </TooltipWrapper>,
    );
};
