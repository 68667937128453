import React from 'react';
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { IAbsolutePeriod } from "./models";
import { VocFormDatePicker } from "/components";
import { HelperService } from "/services";
import { DateService } from "../../services";
import { DateOptions } from "../";

import styles from './style.module.scss';

export const AbsolutePeriod = ({ data, onChange }: IAbsolutePeriod) => {
    const { t } = useTranslation();

    const onChangeDate = (type: string, date: Date) => {
        onChange({
            ...data,
            [type]: date && HelperService.formatDate(new Date(date), 'DD-MM-YYYY'),
        });
    };

    const onChangeDateOptions = ({ type }: any) => {
        const { from, to } = DateService.calculateAbsoluteDate(type);

        onChange({
            from: from && HelperService.formatDate(from, 'DD-MM-YYYY'),
            to: to && HelperService.formatDate(to, 'DD-MM-YYYY'),
        });
    };

    return(
        <div className={ styles.absolutePeriodWrapper }>
            <div className={ styles.selectsWrapper }>
                <div className={ styles.selectWrapper }>
                    <label>
                        { t('field.from') }
                    </label>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <VocFormDatePicker
                        input={
                            {
                                name: '',
                                value: data.from ? moment(data.from,'DD-MM-YYYY').toDate() : '',
                                onChange: (date: Date) => onChangeDate('from', date),
                            }
                        }
                        wrapClassName="voc-datepicker"
                        className="app-form-control form-control"
                        showMonthDropdown
                        showYearDropdown
                        customDateFormat={ 'dd-MM-yyyy' }
                        customDatePlaceholder={ 'DD-MM-YYYY' }
                    />
                </div>
                <div className={ styles.selectWrapper }>
                    <label>
                        { t('field.to') }
                    </label>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <VocFormDatePicker
                        input={
                            {
                                name: '',
                                value: data.to ? moment(data.to,'DD-MM-YYYY').toDate() : '',
                                onChange: (date: Date) => onChangeDate('to', date),
                            }
                        }
                        wrapClassName="voc-datepicker"
                        className="app-form-control form-control"
                        showMonthDropdown
                        showYearDropdown
                        customDateFormat={ 'dd-MM-yyyy' }
                        customDatePlaceholder={ 'DD-MM-YYYY' }
                    />
                </div>
            </div>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            <DateOptions onChange={ onChangeDateOptions } data={ data } />
        </div>
    );
};
