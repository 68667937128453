import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { AbsolutePeriod, RelativePeriod, Tabs } from "../../../";
import { SwitchControl, VocSelect, ComponentOption, ComponentValue } from "/components";
import { changeDashboardFilterActiveSource } from "/visual/scenes/Dashboard/modules/Dashboard.modules";
import {
    dashboardFilterActiveSource,
    dashboardFilterData,
    dashboardFilterSourceDate,
} from "/visual/scenes/Dashboard/modules/Dashboard.selectors";
import { DateService } from '/visual/scenes/Dashboard/services';
import { ITab } from '/visual/models';
import { HelperService } from '/services';

import styles from './style.module.scss';

interface IGeneralProps {
    filters: any;
    onChange: (data: any) => void;
}

export const General = ({ filters, onChange }: IGeneralProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dateOptions = useSelector(dashboardFilterSourceDate);
    const { dataSource } = useSelector(dashboardFilterData);
    const activeSource = useSelector(dashboardFilterActiveSource);
    const activeSourceFilters = filters.sourceDataFilters.find(source => source.sourceId === activeSource.id);
    const { recThreshold, recThresholdEnabled } = filters;

    const [ periodsData, setPeriodsData ] = useState<{ [key: string]: any }>({
        absolute: {
            from: null,
            to: null,
        },
        relative: {
            end: null,
            period: null,
            start: null,
        },
    });

    const [ activeTab, setActiveTab ] = useState<ITab['id']>('absolute');

    const activeDateValue = dateOptions.find(
        (dateOption: { value: string; }) =>
            dateOption.value === activeSourceFilters?.customDateColumn,
    );

    const onChangeSource = (source: { id: string; }) => {
        dispatch(changeDashboardFilterActiveSource(source.id));
    };

    const onChangeTab = (tabId: ITab['id']) => {
        const getRelativePeriod = () => periodsData.absolute.from && periodsData.absolute.to
            ? {
                ...DateService.calculateAbsoluteToRelative(periodsData.absolute.from, periodsData.absolute.to),
                period: 'DAY',
            }
            : periodsData.relative;

        const getAbsolutePeriod = () => {
            let relativeData;

            if(periodsData.relative.period !== null) {
                relativeData = DateService.calculateRelativeDate({
                    start: Math.abs(periodsData.relative.start),
                    end: Math.abs(periodsData.relative.end),
                    period: periodsData.relative.period,
                });
            }

            return periodsData.relative.period !== null && relativeData
                ? {
                    from: HelperService.formatDate(new Date(relativeData.from), 'DD-MM-YYYY'),
                    to: HelperService.formatDate(new Date(relativeData.to), 'DD-MM-YYYY'),
                }
                : periodsData.absolute;
        };

        const periodsDataMask = {
            ...periodsData,
            [tabId]: {
                ...tabId === 'relative'
                    ? getRelativePeriod()
                    : getAbsolutePeriod(),
            },
        };

        setActiveTab(tabId);
        setPeriodsData(periodsDataMask);

        onChange({
            dateFilter: periodsDataMask[tabId],
        });
    };

    const onChangeShowDate = (switchState: boolean) => {
        onChange({
            customDateColumn: switchState ? dateOptions[0].value : null,
        });
    };

    const onChangeThreshold = (type: string, value: number | boolean) => {
        onChange({
            thresholdFilters: {
                recThreshold,
                recThresholdEnabled,
                [type]: value,
            },
        });
    };

    const onChangeDateSelect = (selectValue: { value: string; }) => {
        onChange({
            customDateColumn: selectValue.value,
        });
    };

    const onChangePeriod = ({ periodType, ...data }) => {
        setPeriodsData({
            ...periodsData,
            [periodType]: data,
        });

        onChange({
            dateFilter: data,
        });
    };

    const onChangeRecThreshold = (value: number) => {
        onChangeThreshold('recThreshold', (value < 0 || !value) ? 0 : value);
    };

    useEffect(() => {
        const { dateFilter } = filters;

        setActiveTab(dateFilter?.end || dateFilter?.period || dateFilter?.start
            ? 'relative'
            : 'absolute',
        );

        if(dateFilter) {
            setPeriodsData({
                absolute: {
                    from: dateFilter.from,
                    to: dateFilter.to,
                },
                relative: {
                    end: Math.abs(dateFilter.end),
                    period: dateFilter.period,
                    start: Math.abs(dateFilter.start),
                },
            });
        }
    }, []);

    const tabs = [
        {
            title: t('absolutePeriod'),
            component: <AbsolutePeriod
                data={ periodsData.absolute }
                onChange={
                    data => onChangePeriod({
                        ...data,
                        periodType: 'absolute',
                    })
                }
            />,
            id: 'absolute',
        },
        {
            title: t('relativePeriod'),
            component: <RelativePeriod
                data={ periodsData.relative }
                onChange={
                    data => onChangePeriod({
                        ...data,
                        periodType: 'relative',
                    })
                }
            />,
            id: 'relative',
        },
    ];

    return (
        <>
            <Tabs tabs={ tabs } onChange={ onChangeTab } activeTab={ activeTab }/>
            <div>
                <Label htmlFor={ `selectData` }>
                    { t('field.source') }
                </Label>
                <VocSelect
                    portal
                    isHorizontal
                    name={ 'selectData' }
                    className={ 'select-with-label' }
                    placeholder={ t('field.selectSource') }
                    options={ dataSource }
                    value={ activeSource }
                    onChange={ onChangeSource }
                    components={{
                        Option: ComponentOption,
                        ValueContainer: ComponentValue,
                    }}
                />
            </div>

            <div className={ styles.selectWrapper }>
                <Label htmlFor={ `customDate` }>
                    { t('field.questionnaireCustomDate') }
                </Label>
                <SwitchControl
                    name={ `customDate` }
                    value={ Boolean(activeSourceFilters?.customDateColumn) }
                    changeHandler={ onChangeShowDate }
                    disabled={ !dateOptions?.length }
                />
            </div>

            {
                activeSourceFilters?.customDateColumn
                && <div className={ styles.radiosWrapper }>
                    {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <VocSelect
                            portal
                            isHorizontal
                            name={ 'selectData' }
                            className={ 'select-with-label' }
                            placeholder={ t('field.selectCustomDate') }
                            options={ dateOptions }
                            value={ activeDateValue }
                            onChange={ onChangeDateSelect }
                            components={{
                                Option: ComponentOption,
                                ValueContainer: ComponentValue,
                            }}
                        />
                    }
                </div>
            }

            <div className={ styles.selectWrapper }>
                <Label htmlFor={ `recThresholdEnabled` }>
                    { t('enableRecordThreshold') }
                </Label>
                <SwitchControl
                    name={ `recThresholdEnabled` }
                    value={ recThresholdEnabled }
                    changeHandler={ (state: boolean) => onChangeThreshold('recThresholdEnabled', state) }
                />
            </div>

            {
                recThresholdEnabled
                && <div className={ styles.inputWrapper }>
                    <Label htmlFor={ `recThreshold` }>
                        { t('recordThreshold') }
                    </Label>
                    <Input
                        name={ `recThreshold` }
                        onChange={ (event: any) => onChangeRecThreshold(event.target.value) }
                        value={ recThreshold || 0 }
                        type='number'
                    />
                </div>
            }
        </>
    );
};
