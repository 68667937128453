import { select, Selection } from 'd3';

import { HelperService } from '/services';
import { IRotateTicks, IWrapText } from '/visual/scenes/Dashboard/components/Gadget/models';

export class AxisService {
    static OPTIMISTIC_LINE_HEIGHT = 13;
    static VERTICAL_TICK_SPACE = 55;

    static wrapText({
        horizontalWidth = 150,
        element,
        x,
        dy,
        label,
        isRotated,
        isSingleLineText,
    }: IWrapText) {
        const text = select(element);
        const wrapper = text.text(null);
        const maxSpanAvailableSpace = isRotated ? AxisService.VERTICAL_TICK_SPACE : horizontalWidth;
        let tspan = wrapper
            .append('tspan')
            .attr('x', x)
            .attr('dy', dy);

        // available horizontal width for tick < than optimistic x 2
        // just render it in one line and trim if needed
        if (isSingleLineText) {
            const singleText = HelperService.trimByWidth(label, maxSpanAvailableSpace);

            tspan.text(singleText);
        } else if (label !== null) {
            // if available horizontal width for tick can be more than one line
            const labelStr = label?.toString();
            const getSvgNode = (container: Selection<SVGElement, any, null, any>) => container.node() as SVGGraphicsElement;
            const getLinesCount = () => getSvgNode(wrapper).childElementCount;
            const getBoxSize = () => getSvgNode(wrapper).getBBox();
            let startIndex = 0;
            let finishIndex = 0;

            // initial boxSize (~height of one line)
            let boxSize = { height: AxisService.OPTIMISTIC_LINE_HEIGHT };

            // build line letter by letter
            while (finishIndex < labelStr.length) {
                finishIndex++;
                const str = labelStr.substring(startIndex, finishIndex);
                const textWidthInCurrentTSpan = HelperService.getTextWidth(str);

                tspan.text(str);

                if (textWidthInCurrentTSpan > maxSpanAvailableSpace) {
                    const lineHeight = boxSize.height / getLinesCount();
                    const newBoxHeight = boxSize.height + (lineHeight * 2);
                    const tspanDyValue = isRotated ? '1.1em' : '1.39em';

                    // is there enough space for one more line inside wrapper
                    const remainSpace = isRotated
                        ? newBoxHeight <= horizontalWidth
                        : newBoxHeight <= AxisService.VERTICAL_TICK_SPACE;

                    const textStr = remainSpace
                        // take the last letter for the next line
                        ? labelStr.substring(finishIndex - 1, finishIndex)
                        : HelperService.trimByWidth(
                            labelStr.substring(finishIndex - 1, labelStr.length + 1),
                            isRotated
                                ? AxisService.VERTICAL_TICK_SPACE
                                : horizontalWidth,
                        );

                    // take away the last letter from current tspan
                    tspan.text(labelStr.substring(startIndex, finishIndex - 1));
                    // create new tspan and insert the last letter
                    tspan = wrapper
                        .append('tspan')
                        .attr('x', x)
                        .attr('dy', tspanDyValue)
                        .text(textStr);

                    // get an actual wrapper height
                    boxSize = getBoxSize();
                    if (!remainSpace) {
                        finishIndex = labelStr.length + 1;
                    } else {
                        // update startIndex = finishIndex - 1, finishIndex = finishIndex - 1
                        startIndex = --finishIndex;
                    }
                }
            }

            if (isRotated) {
                const boxLineHeightHalf = boxSize.height / getLinesCount() / 2;
                const wrapperOffset = boxSize.height / 2 - boxLineHeightHalf;

                wrapper.attr('y', -wrapperOffset);
            }
        }
    }

    static getMaxTickLength(ticksData: string[]) {
        let maxWidth = 0;

        ticksData.forEach(tickDataItem => {
            const width = HelperService.getTextWidth(tickDataItem);

            if (width > maxWidth) {
                maxWidth = width;
            }
        });

        return maxWidth;
    }

    static rotateTicks({
        chartWidth,
        dataSet = [],
        xAxisView,
        withPseudoAxis = false,
    }: IRotateTicks) {
        const TICK_PADDINGS = 5;
        const availableHorizontalWidth = Math.floor((chartWidth / dataSet.length) - (TICK_PADDINGS * 2));

        const ticksLabel = dataSet.map(bar => bar.label);
        const maxTickWidth = AxisService.getMaxTickLength(ticksLabel as string[]);
        const isRotated = AxisService.VERTICAL_TICK_SPACE >= availableHorizontalWidth
            && maxTickWidth >= availableHorizontalWidth;
        const transformValue = isRotated ? 'rotate(90)' : 'rotate(0) translate(-10, 15)';
        const textAnchor = isRotated ? 'start' : 'inherit';
        const isSingleLineText = (AxisService.OPTIMISTIC_LINE_HEIGHT * 2) >= availableHorizontalWidth;

        xAxisView
            .attr('transform', transformValue)
            .style('text-anchor', textAnchor)
            .attr('y', 0);

        xAxisView
            .each((tickText: string, tickIndex: number, nodeList: SVGElement[] | ArrayLike<SVGElement>) => this.wrapText({
                horizontalWidth: availableHorizontalWidth,
                element: nodeList[tickIndex],
                x: isRotated && withPseudoAxis ? '19' : '9',
                dy: !isRotated && withPseudoAxis ? '1.5em' : '0.3em',
                label: dataSet[tickIndex].label,
                isRotated,
                isSingleLineText,
            }));
    }
}
