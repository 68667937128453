import React from 'react';
import './FilterCard.scss';
import PropTypes from 'prop-types';

export const FilterCard = ({ children, footer, title }) => (
    <div className={ 'filter-card__wrapper' }>
        <div className={ 'filter-card__header' }>
            <span>{ title }</span>
        </div>
        <div className={ 'filter-card__body' }>
            { children }
        </div>
        <div className={ 'filter-card__footer' }>
            { footer }
        </div>
    </div>
);

FilterCard.defaultProps = {
    title: 'Filters',
};

FilterCard.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    // footer: PropTypes.element,
    title: PropTypes.string,
};
