import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';

export const GET_QUESTIONS_STATISTICS_REQUEST = 'GET_QUESTIONS_STATISTICS_REQUEST';
export const GET_QUESTIONS_STATISTICS_SUCCESS = 'GET_QUESTIONS_STATISTICS_SUCCESS';
export const GET_QUESTIONS_STATISTICS_ERROR = 'GET_QUESTIONS_STATISTICS_ERROR';

export const CHANGE_QUESTIONS_STATISTIC_FILTERS = 'CHANGE_QUESTIONS_STATISTIC_FILTERS';
export const QUESTIONS_STATISTICS_CLEANUP = 'QUESTIONS_STATISTICS_CLEANUP';


function getInitialState() {
    const today = new Date(),
        yesterday = new Date(today.setDate(today.getDate() - 1));

    return {
        loading: false,
        filters: {
            start_time:  HelperService.formatDate(yesterday, 'YYYY-MM-DD'),
            end_time:  HelperService.formatDate(yesterday, 'YYYY-MM-DD'),
        },
        values: [],
    };
}


export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_QUESTIONS_STATISTICS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_QUESTIONS_STATISTICS_SUCCESS:
            return {
                ...state,
                values: [ ...action.response.values[0].data ],
                loading: false,
            };

        case GET_QUESTIONS_STATISTICS_ERROR:
            return {
                ...state,
                values: [],
                loading: false,
            };

        case CHANGE_QUESTIONS_STATISTIC_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };

        case QUESTIONS_STATISTICS_CLEANUP: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getStatistic(id, body) {
    return {
        [CALL_API]: {
            endpoint: `statistics/survey-group/${id}/question/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_QUESTIONS_STATISTICS_REQUEST, GET_QUESTIONS_STATISTICS_SUCCESS, GET_QUESTIONS_STATISTICS_ERROR ],
            body,
            apiType: FEEDBACK,
            unique: true,
        },
    };
}

export function updateFilters (filters) {
    return {
        type: CHANGE_QUESTIONS_STATISTIC_FILTERS,
        filters,
    };
}

export function cleanup() {
    return {
        type: QUESTIONS_STATISTICS_CLEANUP,
    };
}
