import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Label } from 'reactstrap';
import { InputLoader, VocSelect, VocSelectMine } from '/components';
import { ModalFooter } from '../ModalFooter';
import { QueryExecuteService } from '/services';
import './ClassificationForm.scss';

export const ClassificationForm = ({
    show,
    close,
    name,
    onSubmit,
    filter,
    getClassification,
    classification: {
        classificationLoading,
        options,
    },
    initialValues,
}) => {
    const [ selectedClassifier, setSelectedClassifier ] = useState({});
    const [ selectedClass, setSelectedClass ] = useState([]);

    useEffect(() => {
        getClassification(filter.sourceId);

        return () => {
            setSelectedClassifier({});
            setSelectedClass([]);
            close();
        };
    }, []);

    useEffect(() => {
        const { isChange, inxCurrentFilter, filters } = initialValues;

        if (show && options.length && isChange) {
            const currentFilter = filters.find((el, inx) => inx === inxCurrentFilter);
            const classifierOption = options.find(el => el.value === currentFilter.id);

            setSelectedClassifier(classifierOption);
            setSelectedClass(
                classifierOption.matchingOptions.filter(el => currentFilter.values.indexOf(el.value) >= 0),
            );
        }
    }, [ options ]);

    const getChangedFilter = () => ({
        id: selectedClassifier.value,
        values: QueryExecuteService.convertObjToArray(selectedClass),
    });

    const submitForm = () => {
        const { isChange, condition, filters, inxCurrentFilter } = initialValues;

        const newFilters = isChange
            ? filters.map((filter, inx) => inx === inxCurrentFilter ? getChangedFilter() : filter)
            : [
                ...filters,
                getChangedFilter(),
            ];

        onSubmit({
            condition,
            filters: newFilters.map(filter => QueryExecuteService.convertValueToNull(filter)),
        }, name);
    };

    return (
        <Fragment>
            <div className={ 'modal-body' }>
                {
                    !classificationLoading && !options.length
                        ? <div className='classification-overlay'><span> No classifiers </span></div> : ''
                }
                <Row>
                    <Col md={ 12 }>
                        <Label>Select classifier name</Label>
                        {
                            classificationLoading
                                ? <InputLoader/>
                                : <VocSelect
                                    placeholder="Select classifier name"
                                    options={ options }
                                    value={ selectedClassifier }
                                    id="classifier_name_select"
                                    onChange={ value => {
                                        setSelectedClass([]);
                                        setSelectedClassifier(value);
                                    } }
                                />
                        }
                    </Col>

                    <Col md={ 12 }>
                        <Label>Matching class(es):</Label>
                        <VocSelectMine
                            placeholder="Select matching class(es)"
                            globalClass={ 'classifier-multi-select__row' }
                            value={ selectedClass }
                            options={ selectedClassifier.matchingOptions }
                            disabled={ !selectedClassifier.value }
                            onChange={ value => setSelectedClass(value) }
                            closeMenuOnSelect={ false }
                            id={ 'matching-class' }
                            isClearable
                            isMulti
                            isMultiOutside
                        />
                    </Col>
                </Row>
            </div>

            <ModalFooter
                close={ close }
                onClick={ submitForm }
                disabled={ !(selectedClassifier.value && selectedClass.length) }
                addBtn={ initialValues.nameButton }
            />

        </Fragment>
    );
};

ClassificationForm.defaultProps = {
    initialValues: {},
};
