import { createSelector } from 'reselect';
import { UsersRoleService } from '/services';
import uuid from 'uuid/v1';

export const virtualSourcesSelector = state => state.VocStore.virtualSource;
export const userInfoSelector = state => state.UserInfo.userInfo.isSupportUser;

export const virtualSourcesColumnsSelector = createSelector([ virtualSourcesSelector ], data => {
    return data.source.columns.map(column => ({
        ...column,
        key: column.id || uuid(),
    }));
});

// dirty hack for BE requirements
export const checkType = attribute => (
    (attribute.type === 'CHOICE' && attribute.originType === 'CLASSIFICATION')
    || ['TRANSLATED', 'TOPIC', 'TOPIC_CATEGORY', 'TOPIC_SENTIMENT'].includes(attribute.originType)
)
    ? attribute.originType
    : attribute.type;

export const virtualSourceSelector = createSelector([ virtualSourcesSelector ], data => {
    return {
        loading: data.loading,
        touched: data.touched,
        title: data.source.id && `Edit ${ data.source.name } virtual source`,
        source: {
            ...data.source,
            sources: data.source.sources.map(source => ({
                ...source,
                attributes: source.attributes.map(attribute => ({
                    ...attribute,
                    type:checkType(attribute),
                })),
            })),
        },
    };
});

export const virtualSourceNameSelector = createSelector([ virtualSourceSelector ], data => {
    return data.source.name;
});

export const virtualSourceColumnHeaderSelector = createSelector([ virtualSourceSelector ], data => {
    return data.source.columns.map((column, index) => ({
        name: column.name,
        invalid: !column.name || data.source.columns.filter(col => col.name.toLowerCase().trim() === column.name.toLowerCase().trim()).length > 1,
        type: column.type,
        id: index,
        columnId: column.id,
    }));
});

export const virtualSourceSidebarSelector = createSelector([ virtualSourceSelector ], data => {
    return data.source.sources.map((source, index, sources) => ({
        ...source,
        channel: (source.channel !== 'upload' && source.channel !== 'virtual') ? source.channel.toUpperCase() : null,
        customId: source.customId ? source.customId : uuid(),
        excludeIds: sources.map(source => source.id),
        index,
    }));
});

export const virtualSourceCellsSelector = createSelector(
    [ virtualSourceSelector, userInfoSelector ],
    (data, isSupportUser) => {
        return data.source.sources.map((source, index) => {
            return data.source.columns.map((column, columnIdx) => {
                const acceptedAttributes = {
                    label: 'Columns:',
                    options: source.attributes
                        .filter(attribute => {
                            return column.type.includes('TOPIC')
                                ? attribute.originType === column.type
                                : attribute.type === column.type;
                        })
                        .map(attribute => ({
                            label: attribute.name,
                            value: attribute.index,
                        })),
                };

                if (source.type === "survey" && column.type === 'DATE') {
                    acceptedAttributes.options.push({
                        value: 'questionnaire_change_time',
                        label: 'Questionnaire change time',
                    });
                }

                const defaultOptions = [
                    {
                        value: '',
                        label: 'No value',
                    },
                    {
                        value: 'fixed_value',
                        label: 'Fixed Value',
                    },
                ];

                const additionalAttributes = {
                    label: 'Fixed values:',
                    options: column.mapping
                        // eslint-disable-next-line no-prototype-builtins
                        .filter(cell => (cell?.hasOwnProperty('value') && cell.value !== '')).map(
                            option =>
                                ({
                                    label: option.value,
                                    //dirty fix for when column value is equal to fixed value
                                    value: ' ' + String(option.value).trim(),
                                    type: 'fixed_value',
                                }),
                        ).reduce((acc, cell) => {
                            if (!acc.find(item => item.value === cell.value)) {
                                acc.push(cell);
                            }
                            return acc;
                        }, []),
                };

                const attributeByIndex = column.mapping[index]
                    ? source.attributes.find(attribute => attribute.index == column.mapping[index].index)
                    : {};

                // eslint-disable-next-line no-prototype-builtins
                let selected = column.mapping[index] && column.mapping[index].hasOwnProperty('value')
                    ? column.mapping[index].value
                    : attributeByIndex && attributeByIndex.index;

                if (column.mapping[index] && column.mapping[index].index === 'questionnaire_change_time') {
                    selected = column.mapping[index].index;
                }

                const options = [ ...defaultOptions ];

                acceptedAttributes.options.length && options.push(acceptedAttributes);
                additionalAttributes.options.length && options.push(additionalAttributes);

                const { isPermitEdit } = UsersRoleService.getRightsForSourceInVirtual(
                    isSupportUser,
                    source.currentUserPermission,
                );

                return {
                    options: (column.type && source.id) ? options : [],
                    selected,
                    sourceId: source.id,
                    columnType: column.type,
                    columnIdx,
                    key: `${ source.customId }-${ columnIdx }`,
                    valid: typeof selected !== 'undefined',
                    isFixed: !column.mapping.find(item => item.data_id === source.id)?.index,
                    disabled: !isPermitEdit,
                };
            });
        });
    });

export const virtualSourceButtonSelector = createSelector(
    [ virtualSourceColumnHeaderSelector, virtualSourceSelector, virtualSourceNameSelector, virtualSourceSidebarSelector, virtualSourceCellsSelector ],
    (header, data, name, sources, rows) => {
        return (
            data.source.columns.some(column => (!column.name.trim() || !column.type))
            || data.source.columns.length === 0
            || !name.trim()
            || sources.length === 0
            || sources.some(source => !source.id)
            || rows.some(row => row.find(cell => !cell.valid))
            || header.some(head => head.invalid)
        );
    });

export const virtualRelationSelector = createSelector(
    [ virtualSourcesSelector ],
    source => {
        const {
            gadgets,
            segmentators,
            virtualSources,
            virtualSourceIds,
            queries,
        } = source.virtualRelations;

        const needToShowModal = Boolean(gadgets.length
        || virtualSources.length
        || segmentators.length
        || virtualSourceIds.length
        || queries.length);

        return {
            ... source.virtualRelations,
            needToShowModal,
        };
    });
