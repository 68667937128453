import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortableHandle } from 'react-sortable-hoc';
import cx from 'classnames';

import { TooltipWrapper } from '/components';

import './DragHandle.scss';

export const DragHandle = SortableHandle(({ className }) => (
    <TooltipWrapper value='Reorder' placement='left' force>
        <span>
            <FontAwesomeIcon
                className={ cx('drag__handle-icon', 'rowResize', className) }
                icon='bars'
            />
        </span>
    </TooltipWrapper>
));
