import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PieChartService } from '/visual/scenes/Dashboard/components';
import { useResizeObserver } from '/services';

import { TPieChartData } from '/visual/scenes/Dashboard/components/Gadget/models';
import { IPieChart } from './models';

import styles from './style.module.scss';

export const PieChartD3 = ({
    data,
    percentage,
    showScore,
    highlightedLabel,
    gadgetId,
    gadgetFunction,
    drillDownEnabled,
    chartType,
    toolTipRef,
    setColorPickerData,
    setInnerCircleData,
    drillDownFromSelection,
}: IPieChart) => {
    const { t } = useTranslation();
    const [ chartInstance, setChartInstance ] = useState<PieChartService | null>(null);

    const svgRef = useRef<SVGSVGElement | null>(null);
    const wrapperRef = useRef<HTMLHeadingElement | null>(null);

    const dimensions = useResizeObserver(wrapperRef);
    const width = wrapperRef?.current?.clientWidth || 100;
    const height = wrapperRef?.current?.clientHeight || 100;

    const drawChart = (enterData: TPieChartData | null) => {
        if (chartInstance && chartInstance.isInitialized && enterData) {
            chartInstance
                .drawPieContainer({
                    svgWidth: width,
                    svgHeight: height,
                    transformValue:  `translate(${width / 2}px, ${height / 2}px)`,
                })
                .drawPie(enterData)
                .drawLabels(enterData);
        }
    };

    useEffect(() => {
        if (svgRef.current && toolTipRef.current && dimensions?.height && dimensions?.width) {
            const chartInstance = new PieChartService({
                chartType: chartType,
                svgRef: svgRef.current as SVGSVGElement,
                toolTipRef: toolTipRef.current,
                gadgetFunction: gadgetFunction,
                drillDownEnabled: drillDownEnabled,
                gadgetId: gadgetId,
                percentage: percentage,
                showScore,
                t,
                setColorPickerData: setColorPickerData,
                setInnerCircleData: setInnerCircleData,
                drillDownFromSelection: drillDownFromSelection,
            });

            setChartInstance(chartInstance);
        }

        return () => {
            if (chartInstance) {
                chartInstance.remove();
            }
        };
    }, [ dimensions, data ]);

    useEffect(() => {
        chartInstance && drawChart(data);
    },[ chartInstance ]);

    useEffect(() => {
        if (chartInstance && chartInstance.isInitialized) {
            chartInstance.setHighlightedSlice(highlightedLabel);
        }
    }, [ highlightedLabel ]);

    return (
        <div ref={ wrapperRef } className={ styles.pieChart }>
            <svg ref={ svgRef } />
        </div>
    );
};
