import React from 'react';
import {
    Popover,
    PopoverBody,
} from 'reactstrap';

export const VocPopover = ({
    isOpen,
    toggle,
    target,
    children,
    className,
    popoverClassName,
}) => {
    return (
        <div id={ target }>
            <Popover
                className={ popoverClassName }
                innerClassName={ className }
                isOpen={ isOpen }
                toggle={ toggle }
                target={ target }
                hideArrow={ true }
                trigger="legacy"
                placement="bottom"
            >
                <PopoverBody>
                    { children }
                </PopoverBody>
            </Popover>
        </div>
    );
};
