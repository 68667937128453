import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { VocModal, RangeDatePicker, VocSelect } from '/components';
import { closeExportFilterModal, initiateExport, getMineQueryBySources } from '../../modules/VocStore.modules';
import {
    exportDateFilterModalSelect,
    exportModalDelimiterSelect,
    mineQueryBySourcesSelector,
} from '../../modules/VocStore.selectors';
import { exportModalDateFormatSelect, exportModalTimeFormatSelect } from '/modules/selectors';
import { HelperService } from '/services';

const mapStateToProps = state => ({
    data: exportDateFilterModalSelect(state),
    delimiterOptions: exportModalDelimiterSelect(),
    dateFormatOptions: exportModalDateFormatSelect(),
    timeFormatOptions: exportModalTimeFormatSelect(),
    mineQuery: mineQueryBySourcesSelector(state),
});

const mapDispatchToProps = {
    closeExportFilterModal,
    initiateExport,
    getMineQueryBySources,
};

const initialState = dateFormatOptions => ({
    range: {
        from: null,
        to: null,
    },
    dateFormat: dateFormatOptions[0].value,
    timeFormat: null,
    delimiter: ',',
    dateColumn: null,
    query: null,
});

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
export class ExportDateFilterModal extends Component {
    state = {
        ...initialState(this.props.dateFormatOptions),
    };

    @autobind
    cleanupModal() {
        this.setState({
            ...initialState(this.props.dateFormatOptions),
        });
    }

    @autobind
    onDatesChanged(range) {
        this.setState({
            range,
        });
    }

    @autobind
    onDateColumnChanged({ value }) {
        this.setState({
            dateColumn: value,
        });
    }

    @autobind
    onDelimiterChanged({ value }) {
        this.setState({
            delimiter: value,
        });
    }

    @autobind
    onDateFormatChanged({ value }) {
        this.setState({
            dateFormat: value,
        });
    }

    @autobind
    onTimeFormatChanged(option) {
        this.setState({
            timeFormat: option
                ? option.value
                : option,
        });
    }

    @autobind
    submitHandler() {
        const {
            delimiter,
            dateFormat,
            timeFormat,
            dateColumn,
            range: {
                from,
                to,
            },
            query,
        } = this.state;

        const {
            data,
            initiateExport,
            closeExportFilterModal,
            onSubmit,
        } = this.props;

        const dateTimeFormat = timeFormat
            ? `${dateFormat} ${timeFormat}`
            : dateFormat;

        const model = {
            id: data.sourceId,
            body: {
                dateFormat: dateTimeFormat,
                dateFilter: {
                    from: from ? HelperService.formatDate(from, 'DD-MM-YYYY') : null,
                    to: to ? HelperService.formatDate(to, 'DD-MM-YYYY') : null,
                },
                csvDelimiter: delimiter,
                customDateColumn: dateColumn,
                queryId: query ? query.value : query,
            },
        };

        initiateExport(model).promise
            .then(res => onSubmit && onSubmit(res));

        closeExportFilterModal();
    }

    @autobind
    getOptionDate() {
        const { data, t } = this.props;
        const { type, dateOptions } = data;

        const options = type !== 'system'
            ? [
                {
                    value: null,
                    label: t('questionnaireEndTime'),
                },
                {
                    value: 'updated',
                    label: t('recordChangeTimeUpdated'),
                },
            ]
            : [];

        if (type === 'survey') {
            options.push({
                value: 'questionnaire_change_time',
                label: t('questionnaireChangeTime'),
            });
        }

        return [
            ...options,
            ...HelperService.mapObjToSelect(dateOptions, 'id', 'name'),
        ];
    }

    getModalBody() {
        const optionDate = this.getOptionDate();
        const {
            dateColumn,
            range,
            query,
            dateFormat,
            timeFormat,
            delimiter,
        } = this.state;

        const {
            delimiterOptions,
            dateFormatOptions,
            timeFormatOptions,
            mineQuery,
            t,
        } = this.props;

        const formatedTimeOptions = timeFormatOptions.filter(option => option.value !== 'TH:i:sP');

        return (
            <>
                <VocSelect
                    placeholder={ t('placeholders.chooseDelimiter') }
                    label={ t('placeholders.csvDelimiter') }
                    isHorizontal
                    value={ HelperService.getSelectedValue(delimiterOptions, delimiter) }
                    options={ delimiterOptions }
                    onChange={ this.onDelimiterChanged }
                    portal
                />
                <VocSelect
                    placeholder={ t('placeholders.chooseDate') }
                    label={ t('field.dateColumn') }
                    isHorizontal
                    value={ HelperService.getSelectedValue(optionDate, dateColumn) }
                    options={ optionDate }
                    onChange={ this.onDateColumnChanged }
                    portal
                />
                <VocSelect
                    placeholder={ t('placeholders.chooseDateFormat') }
                    label={ t('field.dateFormat') }
                    isHorizontal
                    value={ HelperService.getSelectedValue(dateFormatOptions, dateFormat) }
                    options={ dateFormatOptions }
                    onChange={ this.onDateFormatChanged }
                    portal
                />
                <VocSelect
                    placeholder={ t('placeholders.chooseTimeFormat') }
                    label={ t('field.timeFormat') }
                    isHorizontal
                    value={ timeFormat?.value }
                    options={ formatedTimeOptions }
                    onChange={ this.onTimeFormatChanged }
                    isClearable
                    portal
                />
                <RangeDatePicker
                    dateFormat={ HelperService.datePickerMask }
                    handleChange={ this.onDatesChanged }
                    values={ range }
                    labels={{
                        start: t('field.startDate'),
                        end: t('field.endDate'),
                    }}
                    noMargin
                    enablePlaceholder
                    popperPlacement={ 'right' }
                />
                <div className="datepicker-control__spacer" />
                <VocSelect
                    placeholder={ t('placeholders.chooseQuery') }
                    label={ t('field.mineQuery') }
                    isHorizontal
                    value={ query }
                    options={ mineQuery.queriesOptions }
                    onChange={ query => this.setState({ query }) }
                    portal
                />
            </>
        );
    }

    getModalFooter() {
        const { closeExportFilterModal, t } = this.props;

        return (
            <>
                <Button
                    outline
                    color='white'
                    onClick={ closeExportFilterModal }
                >
                    <FontAwesomeIcon icon='times' />
                    &nbsp;
                    <span>{ t('cancel') }</span>
                </Button>
                <Button
                    color='primary'
                    onClick={ this.submitHandler }
                >
                    <FontAwesomeIcon icon='check' />
                    &nbsp;
                    <span>{ t('apply') }</span>
                </Button>
            </>
        );
    }

    render() {
        const {
            data,
            closeExportFilterModal,
            getMineQueryBySources,
            t,
        } = this.props;

        return (
            <VocModal
                isOpen={ data.show }
                toggle={ closeExportFilterModal }
                modalClassName={ 'export__date-filter-modal modal-scrollable' }
                header={ t('setupExportPeriod') }
                footer={ this.getModalFooter() }
                onClosed={ this.cleanupModal }
                onOpened={ () => getMineQueryBySources(data.sourceId) }
            >
                {
                    this.getModalBody()
                }
            </VocModal>
        );
    }
}

ExportDateFilterModal.propTypes = {
    data: PropTypes.shape({
        show: PropTypes.bool,
        sourceId: PropTypes.string,
    }),
    closeExportFilterModal: PropTypes.func,
    initiateExport: PropTypes.func,
    onSubmit: PropTypes.func,
};
