import { useState, useEffect } from 'react';

import { GadgetService } from '../../';
import {
    IChartData,
    chartDataItem,
    TLabelData,
    filterLabel,
    bubbleData,
    axisLabelType,
} from '../../models';

let onChangeBarColorTimeout: NodeJS.Timeout;

const initialFormatData = {
    bubbles: [],
    filterLabels: [],
    records: 0,
    axisLabels: {
        count: null,
        group: null,
    },
    errorMessage: null,
};

export const useNpsBubbleChart = (initChartData: IChartData) => {
    const [ formatData, setFormatData ] = useState<{
        bubbles: bubbleData[],
        filterLabels: filterLabel[],
        records: number,
        axisLabels: axisLabelType,
        errorMessage: string | null,
    }>(initialFormatData);

    const formatDataHandler = () => {
        const { items, count } = initChartData.chartData;
        const { labels, colors, dataSettings, contentSettings } = initChartData.gadgetData;
        const { groupByName } = dataSettings;
        const customColorSet = JSON.parse(dataSettings.customColorSet);
        const selectedColor = customColorSet && customColorSet.other[customColorSet.theOne];

        const getAllSegmentsName = (list: chartDataItem[]) => list.map((segment: chartDataItem) => segment.name);

        const getMinMaxGroups = (list: chartDataItem[]) => {
            const segments = list
                .reduce((segments: any[], group: chartDataItem) => [ ...segments, ...group.items ], [])
                .sort((a: chartDataItem, b: chartDataItem) => a.average - b.average);

            return {
                minSegmentName: segments[0].name,
                maxSegmentName: segments[segments.length - 1].name,
            };
        };

        const getAveragesValuesList = (group: chartDataItem) => group.items.map((item: chartDataItem) => item.average);

        const getSegmentGroupCount = (group: chartDataItem, groupName: string) => {
            const averageValues = getAveragesValuesList(group);
            const average = groupName === 'detractors' ? Math.min(...averageValues) : Math.max(...averageValues);
            const foundItem = group.items.find((item: chartDataItem) => item.average === average);

            return foundItem?.count || 0;
        };

        const getAllDetractors = (list: chartDataItem[], minMaxGroups: { minSegmentName: string }) => {
            return list
                .filter((group: chartDataItem) => {
                    const groupSegmentsNames = getAllSegmentsName(group.items);

                    return groupSegmentsNames.includes(minMaxGroups.minSegmentName);
                })
                .map((group: chartDataItem) => getSegmentGroupCount(group, 'detractors'))
                .reduce((x: number, y: number) => x + y, 0);
        };

        const calculatePriority = (group: chartDataItem, allDetractors: number) => {
            const averageValues = getAveragesValuesList(group);
            const maxAverage = Math.max(...averageValues) || 10;
            const minAverage = Math.min(...averageValues);
            const delta = Math.abs(maxAverage - minAverage);
            const detractors = getSegmentGroupCount(group, 'detractors');

            return Math.round(delta * detractors * 1000 / allDetractors) / 1000;
        };

        const getCategories = (list: chartDataItem[]) => {
            const categories: any[] = [];
            const minMaxGroups = getMinMaxGroups(list);
            const allDetractors = getAllDetractors(list, minMaxGroups);

            list.forEach((group: chartDataItem) => {
                const groupSegmentsNames = getAllSegmentsName(group.items);

                if (
                    !groupSegmentsNames.includes(minMaxGroups.minSegmentName)
                    || !groupSegmentsNames.includes(minMaxGroups.maxSegmentName)
                ) {
                    return false;
                }

                const categoriesPerGroup = group.items.map((segment: chartDataItem) => {
                    const id = segment.name;
                    const label = GadgetService.getSavedLabel(id, labels, 'legend')?.value || id;
                    const value = calculatePriority(group, allDetractors);
                    const customId = GadgetService.getCustomChartElementId({
                        prefix: 'bubble',
                        groupId: group.name,
                        itemId: id,
                    });

                    return {
                        count: segment.count,
                        id,
                        customId,
                        label,
                        segmentGroupTitle: group.name,
                        value,
                        valueLabel: groupByName,
                        score: segment.average,
                        scoreLabel: 'Average',
                    };
                });

                categories.push(...categoriesPerGroup);
            });

            return categories;
        };

        let categories = getCategories(items);

        if (!categories.length) {
            return {
                ...initialFormatData,
                errorMessage:
                    'This chart can\'t be built.\n One or both tails (extremes) of the population are missing.',
            };
        }

        const order = categories.map((category: bubbleData) => category.id).sort();
        const colorSet = GadgetService.getRandomColorSet({ items: order, selectedColor });

        categories = categories
            .map((category: bubbleData) => ({
                ...category,
                color: GadgetService.getColor({
                    itemId: category.id,
                    ids: order,
                    colors,
                    selectedColor,
                    randomColorSet: colorSet,
                    func: contentSettings.function,
                }),
            }))
            .sort((a: bubbleData, b: bubbleData) => a.count > b.count ? 1 : -1);

        const filterLabels = categories.reduce((acc: filterLabel[], item: bubbleData) =>
            acc.some(({ id: accLabel }) => item.id === accLabel)
                ? acc
                : acc.concat({
                    color: item.color,
                    customId: `label_${ item.customId }`,
                    id: item.id,
                    label: item.label,
                    score: item.score,
                    disableDrag: true,
                }),
        []);

        const countLabel = GadgetService.getSavedLabel('count', labels, 'axis')?.value || categories[0].scoreLabel || null;
        const groupLabel = GadgetService.getSavedLabel('group', labels, 'axis')?.value || categories[0].valueLabel || null;

        return {
            bubbles: categories,
            filterLabels: GadgetService.mapOrder(filterLabels, order, 'id'),
            records: count,
            axisLabels: {
                count: countLabel,
                group: groupLabel,
            },
            errorMessage: null,
        };
    };

    useEffect(() => {
        setFormatData(formatDataHandler());
    }, [ initChartData.gadgetData, initChartData.chartData ]);

    const changeLabel = (
        id: string,
        type: string,
        value: string | null,
        onChangeAxisLabelCallback: (labelRes: any, isChanged: any) => void,
    ) => {
        const formatLabelId = id.toString().toLowerCase();
        const axisObject = {
            key: formatLabelId,
            type: type,
            value: value,
        };
        const isChanged = initChartData.gadgetData.labels
            .findIndex((labelData: TLabelData) => labelData.key === formatLabelId) > -1;

        onChangeAxisLabelCallback(axisObject, isChanged);
    };

    const changeItemColor = (
        id: string,
        color: string,
        onChangeColorCallback: (data: any, isChanged: boolean) => void,
    ) => {
        const isChanged = GadgetService.getSavedColorIndex(id, initChartData.gadgetData.colors) > -1;
        const colorObject = {
            key: id,
            value: color,
        };

        clearTimeout(onChangeBarColorTimeout);
        onChangeBarColorTimeout = setTimeout(() => {
            onChangeColorCallback(colorObject, isChanged);
        }, 300);
    };

    return {
        gadgetData: initChartData.gadgetData,
        chartData: initChartData.chartData,
        bubbles: formatData.bubbles,
        filterLabels: formatData.filterLabels,
        records: formatData.records,
        axisLabels: formatData.axisLabels,
        errorMessage: formatData.errorMessage,
        changeLabel,
        changeItemColor,
    };
};
