import React, { useState, useEffect, useMemo } from 'react';
import { HelperService } from '/services';
import cx from 'classnames';
import moment from 'moment';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper, SwitchControl, ThinSpinner } from '/components';
import { TimeDateGridDayPicker, Thead, Tbody } from './components';
import { useDragSelect } from './customHooks';

import './TimeDateGrid.scss';

const noOverflow = 7;
const disable = 0;
const enable = 1;
const mockData = {
    "0_1": disable,
    "1_2": disable,
    "2_3": disable,
    "3_4": disable,
    "4_5": disable,
    "5_6": disable,
    "6_7": disable,
    "7_8": disable,
    "8_9": disable,
    "9_10": disable,
    "10_11": disable,
    "11_12": disable,
    "12_13": disable,
    "13_14": disable,
    "14_15": disable,
    "15_16": disable,
    "16_17": disable,
    "17_18": disable,
    "18_19": disable,
    "19_20": disable,
    "20_21": disable,
    "21_22": disable,
    "22_23": disable,
    "23_24": disable,
};

export const TimeDateGrid = ({
    dateGrid,
    isRegular,
    filters,
    loading,
    isUpdate,
    infiniteLoading,
    manageDataHandler,
    dirtyStatusConfirm,
    count,
    unit,
    updateFilters,
    showConfirm,
    channelList,
    activeChannel,
    setSurveySettingOn,
    surveySettingOn,
    isSurveySetting,
    showChecker,
    clearSurveyTimeSlot,
    rights,
}) => {
    const [ isEditOn, setIsEditOn ] = useState(false);
    const [ isDatePickerOpen, setIsDatePickerOpen ] = useState(false);
    const [ dateGridData, setDateGridData ] = useState([]);
    const [ isItemDeleted, setIsItemDeleted ] = useState(false);
    const { reInitDragSelect, selectedClass } = useDragSelect(isEditOn, isRegular, dateGridData, setDateGridData);
    const deepCopyDateGrid = () => JSON.parse(JSON.stringify(dateGrid));

    const { dirty, isValid } = useMemo(() => {
        return {
            dirty: !HelperService.deepEqual(dateGrid, dateGridData),
            isValid: dateGridData
                .some(grid =>
                    Object.keys(grid.time_slots)
                        .some(slot => grid.time_slots[slot] === enable),
                ),
        };
    }, [ dateGridData ]);

    useEffect(() => {
        dirtyStatusConfirm({ dirty });
    }, [ dirty ]);

    useEffect( () => {
        !surveySettingOn && setIsEditOn(false);
    }, [ surveySettingOn ]);

    useEffect(() => {
        isEditOn && setIsEditOn(false);
    }, [ activeChannel, isRegular ]);

    useEffect(() => {
        setDateGridData(deepCopyDateGrid());
    }, [ dateGrid ]);

    const channelsHandler = (param, action) => {
        showConfirm({
            checkDirty: true,
            header: 'Warning!',
            content: 'You might have some unsaved changes. Are you sure you want to proceed?',
            successCallback: () => action(param),
        });
    };

    const onSwitchControl = param => {
        channelsHandler(
            {
                show_past_events: param ? '1' : '0',
                page: 1,
            },
            updateFilters,
        );
    };

    const onUpdateFilters = param => {
        channelsHandler(param, updateFilters);
    };

    const datePickHandler = date => {
        const dateSlot = {
            applying_date: moment(date).format('YYYY-MM-DD'),
            channel: channelList.find(({ name }) => name === activeChannel).id,
            unit,
            time_slots: {
                ...mockData,
            },
        };

        setDateGridData([ dateSlot, ...dateGridData ]);
        setIsDatePickerOpen(false);
    };

    const removeDate = obj => {
        !isItemDeleted && setIsItemDeleted(true);
        setDateGridData(dateGridData.filter(el => el.applying_date !== obj.applying_date));
    };

    const resetHandler = () => {
        setDateGridData(deepCopyDateGrid());
        if(isSurveySetting === false) {
            setSurveySettingOn(isSurveySetting);
        }

        reInitDragSelect();
    };

    const manageHandler = () => {
        if (!surveySettingOn && showChecker) {
            return clearSurveyTimeSlot();
        }
        return manageDataHandler(dateGridData);
    };

    const changeHandler = (inx, key) => {
        if (!isEditOn) return;
        const dateGridModel = [ ...dateGridData ];
        const timeSlotsModel = dateGridModel[inx].time_slots;

        !!timeSlotsModel[key]
            ? timeSlotsModel[key] = disable
            : timeSlotsModel[key] = enable;
        dateGridModel[inx].time_slots = timeSlotsModel;
        setDateGridData(dateGridModel);
    };

    const scrollCondition = !isRegular && !infiniteLoading && count > dateGrid.length;

    const checkboxHandle = isChecked => {
        setSurveySettingOn(isChecked);

        !isChecked && resetHandler();
    };

    return (
        <>
            <Prompt when={ dirty }
                message='Seems you might have some unsaved changes. Are you sure you want to continue?'
            />
            <div
                className={ `time-date-grid__container ${ (loading || isUpdate) && 'time-date-grid__container-loading' }` }
            >
                { (loading || isUpdate) && <ThinSpinner/> }

                <div className={ 'timeDateHeaderPanel' }>
                    {
                        showChecker
                        && <div className={ 'time-date-grid__checkbox-container' }>
                            <input
                                type="checkbox"
                                id="time-date-grid__checkbox"
                                checked={ surveySettingOn }
                                onChange={ () => checkboxHandle(!surveySettingOn) }
                            />
                            <label htmlFor="time-date-grid__checkbox"> Survey setting </label>
                        </div>
                    }
                    <TooltipWrapper value={ isEditOn ? 'Disable' : 'Enable' } force={ true }>
                        <Button
                            className={ cx('onEdit-button', { 'onEdit-button--with_checker':  showChecker }) }
                            onClick={ () => setIsEditOn(!isEditOn) }
                            color={ 'white' }
                            outline
                            disabled={ (showChecker && !surveySettingOn) || !rights?.isPermitEdit }
                        >
                            <FontAwesomeIcon icon={ `${ isEditOn ? 'lock-open' : 'lock' }` } className={ '' }/>
                            <span> { isEditOn ? 'Unlocked' : 'Locked' } </span>
                        </Button>
                    </TooltipWrapper>
                    {
                        !isRegular
                        && <>
                            <TimeDateGridDayPicker
                                isDatePickerOpen={ isDatePickerOpen }
                                datesToDisable={ dateGridData.map(({ applying_date }) => applying_date) }
                                setIsDatePickerOpen={ setIsDatePickerOpen }
                                selectedDate={ new Date() }
                                minData={ filters.show_past_events === '0' }
                                datePickHandler={ datePickHandler }
                                disable={ !isEditOn }
                            />
                            <label className="time-date-grid__switch-label">
                                <SwitchControl
                                    name={ 'switch' }
                                    changeHandler={ onSwitchControl }
                                    value={ filters.show_past_events !== '0' }/>
                                <span className="settings__label-text">
                                    { 'Show past events' }
                                </span>
                            </label>
                        </>
                    }
                    <div className={ 'timeDateIndicators' }>
                        <span>Allow</span>
                        <div className={ 'timeDateIndicators timeDateIndicators--blue' }/>
                        <span>Deny</span>
                        <div className={ 'timeDateIndicators timeDateIndicators--red' }/>
                    </div>
                </div>
                <table className={
                    cx(
                        'timeDateGrid',
                        selectedClass,
                        { 'timeDateGrid--no-overflow': dateGridData.length <= noOverflow },
                    )
                }>
                    <Thead hasData={ !!dateGridData.length }/>
                    <Tbody
                        filters={ filters }
                        isItemDeleted={ isItemDeleted }
                        setIsItemDeleted={ setIsItemDeleted }
                        scrollCondition={ scrollCondition }
                        infiniteLoading={ infiniteLoading }
                        updateFilters={ onUpdateFilters }
                        isUpdate={ isUpdate }
                        dateGridData={ loading ? [] : dateGridData }
                        isRegular={ isRegular }
                        changeHandler={ changeHandler }
                        isEditOn={ isEditOn }
                        removeDate={ removeDate }
                    />
                </table>
                {
                    dirty
                    && <div className={ 'footerButtons' }>
                        {
                            !isValid && isRegular
                            && <span className={ 'footerButtons--error' }>
                                At least 1 hour per week should be allowed to send out surveys invites.
                            </span>
                        }
                        <Button
                            onClick={ resetHandler }
                            className={ 'question__controls-button' }
                            color={ 'white' }
                            outline
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={ manageHandler }
                            className={ 'question__controls-button' }
                            color={ 'success' }
                            disabled={ !isValid && isRegular }
                        >
                            <FontAwesomeIcon className={ 'question__controls-icon' } icon={ 'check' }/>
                            Save
                        </Button>
                    </div>
                }
            </div>
        </>
    );
};

TimeDateGrid.propTypes = {
    dateGrid: PropTypes.arrayOf(
        PropTypes.object,
    ),
    isRegular: PropTypes.bool,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    infiniteLoading: PropTypes.bool,
    updateFilters: PropTypes.func,
};
