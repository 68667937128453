import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Row, Col } from 'reactstrap';
import { VocFormTextBlock } from '/components';
import { protocolPhoneList } from './protocolPhoneList';

export const subscribePhoneList = ({ fields, valueCustomerBlacklist, onChange, initProtocol }) => {
    return (
        <div className='customers-subscribe__list'>
            {
                fields.map((field, index) => {
                    return (
                        <Row key={ index } className={ 'customers-subscribe__item' }>
                            <Col md={ 6 }>
                                <Field
                                    name={ `${ field }.address` }
                                    component={ VocFormTextBlock }
                                />
                            </Col>
                            <Col md={ 6 }
                                className={ `customers-subscribe__field ${ valueCustomerBlacklist === 16 ? 'disabled' : '' }` }>
                                <FieldArray
                                    name={ `${ field }.protocols` }
                                    onChange={ onChange }
                                    initProtocol={ initProtocol }
                                    component={ protocolPhoneList }
                                />
                            </Col>
                        </Row>
                    );
                },
                )
            }
        </div>
    );
};

subscribePhoneList.propTypes = {
    fields: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    initProtocol: PropTypes.func.isRequired,
    valueCustomerBlacklist: PropTypes.number,
};

subscribePhoneList.defaultProps = {
    valueCustomerBlacklist: 0,
};
