import { FileParser } from '../services/FileParser';
import { createSelector } from 'reselect';
import { HelperService } from '/services';

const _getUploadData = state => state.VocStore.uploadData.uploadNewFile;

export const formDataSelector = createSelector(
    [ _getUploadData ],
    ({ loading, error, upload, afterReload, file, headerRowType }) =>
        ({
            loading,
            error,
            upload,
            afterReload,
            file,
            headerRowType,
        }));

const uploadSelector = state => _getUploadData(state).upload;
const headersSelector = state => _getUploadData(state).headers;
const fileSelector = state => _getUploadData(state).file;
const headerRowTypeSelector = state => _getUploadData(state).headerRowType;

export const getInitialValues = t => createSelector(
    [ uploadSelector ],
    ({ title, choiceDelimiter, capturedAt, language, csvDelimiter }) => {
        if (!HelperService.checkNotEmpty(language.value)) {
            language.label = t('severalLanguagesInFile');
        }

        return {
            title,
            choiceDelimiter,
            language,
            csvDelimiter,
            capturedAt,
        };
    },
);

export const afterReloadSelector = state => _getUploadData(state).afterReload;


const oldHeaderRowTypeSelector = createSelector([ formDataSelector ],
    ({ afterReload }) => {
        return afterReload?.headerRowType || [];
    });

const oldHeadersSelector = createSelector([ oldHeaderRowTypeSelector ],
    headerRowType => {
        return Object.keys(headerRowType).map(key => headerRowType[key].id);
    });

function getFromHeadersAndRowType(headers, headerRowType, disabledRow) {
    const columns = [];

    headers.forEach((header, index) => {
        columns.push({
            id: header,
            index,
            type: headerRowType[index].type,
            disabledRow,
        });
    });

    return columns;
}

function getNewColumns(newHeaders, newHeaderRowType, startIndex = 0) {
    const columns = FileParser.getColumnWithTypes(newHeaders, startIndex);

    columns.forEach(col => {
        if (newHeaderRowType[col.index] !== undefined) {
            col.type = newHeaderRowType[col.index];
        }
    });

    return columns;
}

function sortNewColumnsFirst(columns) {
    return columns.filter(col => !col.disabledRow).concat(columns.filter(col => col.disabledRow));
}

function splitColumns(oldColumns, newColumns) {
    const columns = oldColumns.concat(newColumns);
    const langColumn = columns.find(column => column.type === 'LANG');

    langColumn && columns.map(column => {
        column.disabledLang = column.index !== langColumn.index;

        if (column.index !== langColumn.index && column.type === 'LANG') {
            column.type = 'META';
        }
        return column;
    });

    const main = columns.filter(col => {
        if (col.disabledRow) {
            return col.type !== FileParser.columnTypes.default;
        }

        return FileParser.likeMainColumn(col);
    });
    const additional = columns.filter(col => {
        if (col.disabledRow) {
            return col.type === FileParser.columnTypes.default;
        }

        return FileParser.likeAdditionalColumn(col);
    });

    return {
        main: sortNewColumnsFirst(main),
        additional: sortNewColumnsFirst(additional),
    };
}

function setStatusFlags(oldColumns, newColumns, headers) {
    if (oldColumns.length > 0 && headers.length > 0) {
        oldColumns.forEach(oldColumn => {
            const existInNewToo = headers.findIndex(header => header === oldColumn.id) !== -1;

            oldColumn.status = existInNewToo ? 'ok' : 'absent';
        });
    }

    newColumns.forEach(col => {
        if (oldColumns.length > 0) {
            col.status = 'new';
        }
    });
}

export const newHeadersSelector = createSelector([ headersSelector, oldHeadersSelector ],
    (headers, oldHeaders) => {
        return HelperService.arrDiff(headers, oldHeaders);
    });

export const columnsSelector = createSelector([
    headersSelector,
    headerRowTypeSelector,
    oldHeadersSelector,
    oldHeaderRowTypeSelector,
    newHeadersSelector ],
(headers, headerRowType, oldHeaders, oldHeaderRowType, newHeaders) => {
    const oldColumns = getFromHeadersAndRowType(oldHeaders, oldHeaderRowType, true),
        newColumns = getNewColumns(newHeaders, headerRowType, oldHeaders.length);

    setStatusFlags(oldColumns, newColumns, headers);
    return splitColumns(oldColumns, newColumns);
});

export const allNewHeadersInOldHeaders = createSelector([ headersSelector, oldHeadersSelector ],
    (headers, oldHeaders) => {
        return HelperService.arrDiff(oldHeaders, headers).length === 0;
    });

export const isFileRequired = createSelector([ fileSelector ],
    file => !file );

export const isChoiceDelimiterRequired = createSelector([ columnsSelector ],
    columns => {
        const concatedColumns = columns.main.concat(columns.additional);

        return concatedColumns.some(col => col.type === 'CHOICE' && col.status !== 'absent');
    });

export const clientToServerDataConverter = (value, file, headerRowType) => {
    const data = {
        title: value.title,
        headerRowType: JSON.stringify(headerRowType),
        choiceDelimiter: value.choiceDelimiter,
        csvDelimiter: value.csvDelimiter,
        file,
        capturedAt: HelperService
            .formatCapturedAtDate(value.capturedAt)
            .replace(',', ''),
    };

    if (Boolean(value.language.value)){
        data.language = value.language.value;
    }

    return data;
};
