import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './TableHeader.scss';

export class TableHeader extends Component {
    constructor(props) {
        super(props);
        this.filterSortById = this.filterSortById.bind(this);
        this.filterSortByTitle = this.filterSortByTitle.bind(this);

        this.topOffset = 119;
    }

	scrollHandler = () => {
	    if (this.topOffset < window.pageYOffset) {
	        this.tableHeader.classList.add('surveys-list-sticky-header');
	        this.tableHeader.parentElement.style.marginTop = `${this.tableHeader.offsetHeight}px`;
	    } else {
	        this.tableHeader.classList.remove('surveys-list-sticky-header');
	        this.tableHeader.parentElement.style.marginTop = '0';
	    }
	};

	componentDidMount = () => {
	    window.addEventListener('scroll', this.scrollHandler);
	};

	componentWillUnmount = () => {
	    window.removeEventListener('scroll', this.scrollHandler);
	};

	_filterSortByField(fieldName, fieldTitle) {
	    const defaultStatus = '-sequence';

	    const readCurrentStatus = () => {
	        const status = this.props.sortBy;
	        const isEmpty = (!status || status === '' || status === 'null' || status === defaultStatus);
	        const isDesc = !isEmpty && status.indexOf('-') !== -1;

	        if (!isEmpty) {
	            return isDesc ? 'desc' : 'asc';
	        } else {
	            return null;
	        }
	    };

	    const tripleLogic = trigger => {
	        switch (trigger) {
	            case null:
	                return 'asc';
	            case 'asc':
	                return 'desc';
	            case 'desc':
	                return null;
	        }
	    };

	    const sortByQuery = (field, sort) => {
	        switch (sort) {
	            case 'asc':
	                return field;
	            case 'desc':
	                return '-' + field;
	            case null:
	                return defaultStatus;
	        }
	    };

	    const setFilter = () => {
	        let sort = readCurrentStatus();

	        sort = tripleLogic(sort);
	        const sortBy = sortByQuery(fieldName, sort);
	        const applyState = {
	            loading: true,
	            sortBy: sortBy,
	        };

	        this.props.applyFilter(applyState);
	        window.sessionStorage.setItem('sortBy', sortBy);
	    };

	    const showStatus = () => {
	        const statusStr = this.props.sortBy || '';
	        const isEmpty = (statusStr === defaultStatus || statusStr === '');
	        const isDesc = !isEmpty && statusStr.indexOf('-') !== -1;
	        const checkField = fieldName === statusStr.replace('-', '');

	        return (!isEmpty && checkField)
	            ? (isDesc ? 'desc' : 'asc')
	            : null;
	    };

	    const showStatusIcon = () => {
	        const status = showStatus();

	        if (!status) {
	            return '';
	        }
	        const icon = (status === 'asc') ? 'down' : 'up';

	        return (
	            <FontAwesomeIcon className={ 'surveys-list__sort-icon' } icon={ `caret-${icon}` }/>
	        );
	    };

	    return (
	        <a className='noselect sortField' onClick={ () => setFilter() }>
	            <span>{ fieldTitle }</span>
	            { showStatusIcon() }
	        </a>
	    );
	}

	filterSortById() {
	    return this._filterSortByField('id', 'Group ID');
	}

	filterSortByTitle() {
	    return this._filterSortByField('name', 'Title');
	}

	render() {
	    return (
	        <thead ref={ header => { this.tableHeader = header;} }>
	            <tr>
	                <th>{ this.filterSortById() }</th>
	                <th>{ this.filterSortByTitle() }</th>
	                <th>Channels / Status</th>
	                <th>Response Rate</th>
	                <th/>
	            </tr>
	        </thead>
	    );
	}
}

TableHeader.propTypes = {
    applyFilter: PropTypes.func,
    sortBy: PropTypes.any,
};
TableHeader.defaultProps = {};


