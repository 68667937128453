export const lemmataFilterFormValidation = (values = {}) => {
    const errText = 'Field must not be empty';
    const errors = {
        values: '',
    };

    values.values && values.values.map(value => {
        if (/ /.test(value)) {
            errors.values = errText;
        }
    });

    if( values.values && values.values.length === 0 ) {
        errors.values = errText;
    }

    return errors;
};
