import React from 'react';
import { components } from 'react-select';
import dropDownIcon from './arrow_down.png';

export const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator { ...props }>
            <img className={ 'dropdown-indicator' } src={ dropDownIcon }/>
        </components.DropdownIndicator>
    );
};
