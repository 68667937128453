import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

import './ExportResultsTableCheckbox.scss';

export class ExportResultsTableCheckbox extends Component {
    @autobind
    changeHandler(e) {
        e.target.checked
            ? this.props.addToSelected(this.props.item)
            : this.props.removeFromSelected(this.props.item.id);
    }

    render() {
        return (
            <div className='export-results__checkbox-container'>
                {
                    this.props.disabled
                        ? <TooltipWrapper value='deleted' force>
                            <span>
                                <FontAwesomeIcon
                                    className='export-results__checkbox-icon'
                                    icon="ban"
                                />
                            </span>
                        </TooltipWrapper>
                        : <Input
                            type={ 'checkbox' }
                            className='export-results__checkbox'
                            checked={ this.props.selected }
                            onChange={ this.changeHandler }
                        />
                }
            </div>
        );
    }
}
