import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import cx from 'classnames';

import { TooltipWrapper } from '/components';

import './SwitchControl.scss';

export class SwitchControl extends Component {
    @autobind
    changeHandler() {
        const { changeHandler, value, name } = this.props;

        changeHandler(!value, name);
    }

    render() {
        const { name, value, type, disabled, disabledReason } = this.props;

        return (
            <TooltipWrapper
                value={ disabledReason }
                force={ disabled && disabledReason }
            >
                <div className='switch-wrap'>
                    <input
                        className='switch__checkbox'
                        disabled={ disabled }
                        type='checkbox'
                        checked={ value }
                        id={ name }
                        name={ name }
                        onChange={ this.changeHandler }
                    />
                    <label
                        className={ cx(`switch switch__${ type }`, { 'switch__disabled': disabled }) }
                        htmlFor={ name }
                    >
                        <span className={ `switch__toombler switch__toombler__${ type }` }/>
                    </label>
                </div>
            </TooltipWrapper>
        );
    }
}

SwitchControl.propTypes = {
    value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    disabledReason: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
};
