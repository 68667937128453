import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { cleanupTopicsAndGraphs } from '/modules/topicSetModules/topicSet.modules';
import { IFieldsData, IInitFieldsData } from './models';

const initFieldsDataState = {
    languages: {},
    threshold: 0.3,
    source: null,
    column: null,
    topicSetName: "",
    language: null,
};

export const useTopicSetFieldsData = ({ initFieldsData }: { initFieldsData: IInitFieldsData }) => {
    const dispatch = useDispatch();
    const [ fieldsData, setFieldsData ] = useState<IFieldsData>(initFieldsDataState);

    const onChange = (fieldName: string, value: string | null) => {
        setFieldsData({
            ...fieldsData,
            [fieldName]: value,
        });
    };

    const cleanUpFields = () => {
        setFieldsData(initFieldsDataState);
    };

    useEffect(() => {
        if(fieldsData.language?.value) {
            dispatch(cleanupTopicsAndGraphs());
            setFieldsData({
                ...fieldsData,
                source: null,
                column: null,
            });
        }
    }, [ fieldsData.language?.value ]);

    useEffect(() => {
        if(initFieldsData?.data) {
            setFieldsData({
                ...fieldsData,
                topicSetName: fieldsData.topicSetName ? fieldsData.topicSetName : initFieldsData?.data?.title,
                languages: initFieldsData?.data?.languages || {},
            });
        }
    }, [ initFieldsData ]);

    useEffect(() => {
        onChange('column', null);
    }, [ fieldsData.source ]);

    return {
        onChange,
        cleanUpFields,
        fieldsData,
    };
};
