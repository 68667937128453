import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageRequests } from '/services';
import { VocCleaupHOC } from '/components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { availableUnitsId } from '/scenes/VocFeedback/modules/VocFeedback.selectors';
import { ExportSchedulesTable } from './components/ExportSchedulesTable';
import { ManageSchedulesModal } from './components/ManageSchedulesModal';

import {
    getExportSchedules, cleanup, updateFilters,
    manageExportSchedules, deleteExportSchedules,
} from './modules/exportSchedules.index';

import {
    exportersSchedulesTableDataSelector, tableSettingsSelector,
    filtersSelector, exportPeriodSelector, exportDayOfWeekSelector,
} from './modules/exportSchedules.selectors';

import { isLoadedPermissionSelector, mapExportersToSelectModel, mapSurveysToModal } from '../../modules/export.selector';

@connect(state => ({
    tableData: exportersSchedulesTableDataSelector(state),
    tableState: tableSettingsSelector(state),
    exporters: mapExportersToSelectModel(state),
    surveys: mapSurveysToModal(state),
    filters: filtersSelector(state),
    exportPeriod: exportPeriodSelector(),
    exportDayOfWeek: exportDayOfWeekSelector(),
    availableUnitsId: availableUnitsId(state),
    isLoadedPermission: isLoadedPermissionSelector(state),
}), { getExportSchedules, cleanup, updateFilters, manageExportSchedules, deleteExportSchedules, showConfirm })
@VocCleaupHOC
export class ExportSchedules extends Component {
    defaultManageModal = {
        parameters: {},
        selected: {},
    };

    state = {
        openModalManage: false,
        manageSchedulesModal: {
            ...this.defaultManageModal,
        },
    };

    pageRequests = new PageRequests();

    @autobind
    closeModal() {
        this.setState({
            openModalManage: false,
            manageSchedulesModal: {
                ...this.defaultManageModal,
            },
        });
    }

    @autobind
    openModalManage(value) {
        this.setState({
            openModalManage: true,
            manageSchedulesModal: {
                ...value,
            },
        });
    }

    @autobind
    openModalDelete(surveyId) {
        const { showConfirm } = this.props;

        showConfirm({
            header: 'Warning!',
            content: `You are going to delete this schedule, continue?`,
            successCallback: () => this.deleteSchedule(surveyId),
        });
    }

    componentDidMount() {
        this.requestTrigger();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isLoadedPermission !== this.props.isLoadedPermission) {
            this.requestTrigger();
        }
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        const model = {
            sort: filters.sort_field ? (filters.sort_order === 'desc' ? '' : '-') + filters.sort_field : '',
            limit: filters.limit,
            page: filters.page,
            search: filters.search ? filters.search : '',
            units: `[${this.props.availableUnitsId?.toString()}]`,
        };

        this.pageRequests.cleanup();
        const request = this.props.getExportSchedules(model);

        this.pageRequests.addRequest(request);
    }

    @autobind
    manageSchedules(params) {
        return this.props.manageExportSchedules(params).promise
            .then(() => {
                this.requestTrigger();
                this.closeModal();
            });
    }

    @autobind
    deleteSchedule(surveyId) {
        const params = {
            ...this.props.filters,
            page: 1,
        };

        this.props.deleteExportSchedules(surveyId).promise
            .then(() => {
                this.requestTrigger(params);
                this.props.updateFilters(params);
            });
    }

    render() {
        const {
            tableData,
            tableState,
            exporters,
            surveys,
            exportPeriod,
            exportDayOfWeek,
            filters,
        } = this.props;

        const {
            openModalManage,
            manageSchedulesModal,
        } = this.state;

        return (
            <DocumentTitle title="Feedback :: Export Schedules">
                <Fragment>
                    <Card className={ 'page__wrapper' }>
                        <CardHeader className={ 'panel__header panel__header--buttons' }>
                            <span className={ 'panel__header-title' }>Schedules List</span>
                            <div className={ 'panel__header-buttons' }>
                                <Button className={ 'panel__header-button' }
                                    outline
                                    color="white"
                                    onClick={ () => this.openModalManage(this.defaultManageModal) }>
                                    <FontAwesomeIcon icon='plus' className='color-green'/>
                                    <span> Add Schedule</span>
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <ExportSchedulesTable
                                requestTrigger={ this.requestTrigger }
                                updateFilters={ this.props.updateFilters }
                                data={ tableData }
                                filters={ filters }
                                tableState={ tableState }
                                openModalManage={ this.openModalManage }
                                openModalDelete={ this.openModalDelete }
                            />
                        </CardBody>
                    </Card>
                    <ManageSchedulesModal
                        show={ openModalManage }
                        close={ this.closeModal }
                        exporters={ exporters }
                        surveys={ surveys }
                        manageSchedules={ manageSchedulesModal }
                        submitHandler={ this.manageSchedules }
                        exportPeriod={ exportPeriod }
                        exportDayOfWeek={ exportDayOfWeek }
                    />
                </Fragment>
            </DocumentTitle>
        );
    }
}
