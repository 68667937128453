import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { SubmissionError } from 'redux-form';
import { QuestionsServices } from '/scenes/VocFeedback/services';

export const CREATE_QUESTION_REQUEST = 'feedback/question-extend/create/request';
export const CREATE_QUESTION_SUCCESS = 'feedback/question-extend/create/success';
export const CREATE_QUESTION_ERROR = 'feedback/question-extend/create/error';

export const PATCH_QUESTION_REQUEST = 'feedback/question-extend/patch/request';
export const PATCH_QUESTION_SUCCESS = 'feedback/question-extend/patch/success';
export const PATCH_QUESTION_ERROR = 'feedback/question-extend/patch/error';

export const GET_QUESTION_REQUEST = 'feedback/question-extend/get/request';
export const GET_QUESTION_SUCCESS = 'feedback/question-extend/get/success';
export const GET_QUESTION_ERROR = 'feedback/question-extend/get/error';

const GET_QUESTION_DATA_FOR_INHERIT_REQUEST = 'GET_QUESTION_DATA_FOR_INHERIT_REQUEST';
const GET_QUESTION_DATA_FOR_INHERIT_SUCCESS = 'GET_QUESTION_DATA_FOR_INHERIT_SUCCESS';
const GET_QUESTION_DATA_FOR_INHERIT_ERROR = 'GET_QUESTION_DATA_FOR_INHERIT_ERROR';
const RESET_QUESTION_DATA_FOR_INHERIT = 'RESET_QUESTION_DATA_FOR_INHERIT';

const PATCH_QUESTION_TEXT_REQUEST = 'PATCH_QUESTION_TEXT_REQUEST';
const PATCH_QUESTION_TEXT_SUCCESS = 'PATCH_QUESTION_TEXT_SUCCESS';
const PATCH_QUESTION_TEXT_ERROR = 'PATCH_QUESTION_TEXT_ERROR';

const GET_IVR_TEXT_FILE_REQUEST = 'GET_IVR_TEXT_FILE_REQUEST';
const GET_IVR_TEXT_FILE_SUCCESS = 'GET_IVR_TEXT_FILE_SUCCESS';
const GET_IVR_TEXT_FILE_ERROR = 'GET_IVR_TEXT_FILE_ERROR';

const CLEANUP_IVR_TEXT_FILE = 'CLEANUP_IVR_TEXT_FILE';

const PATCH_QUESTION_ERRORTEXT_REQUEST = 'PATCH_QUESTION_ERRORTEXT_REQUEST';
const PATCH_QUESTION_ERRORTEXT_SUCCESS = 'PATCH_QUESTION_ERRORTEXT_SUCCESS';
const PATCH_QUESTION_ERRORTEXT_ERROR = 'PATCH_QUESTION_ERRORTEXT_ERROR';

const DELETE_QUESTION_TEXT_REQUEST = 'DELETE_QUESTION_TEXT_REQUEST';
const DELETE_QUESTION_TEXT_SUCCESS = 'DELETE_QUESTION_TEXT_SUCCESS';
const DELETE_QUESTION_TEXT_ERROR = 'DELETE_QUESTION_TEXT_ERROR';

const DELETE_QUESTION_ERRORTEXT_REQUEST = 'DELETE_QUESTION_ERRORTEXT_REQUEST';
const DELETE_QUESTION_ERRORTEXT_SUCCESS = 'DELETE_QUESTION_ERRORTEXT_SUCCESS';
const DELETE_QUESTION_ERRORTEXT_ERROR = 'DELETE_QUESTION_ERRORTEXT_ERROR';

export const CLEANUP = 'feedback/question/cleanup';

export const IVR_FILES_PROCESSING_INITIATED = 'IVR_FILES_PROCESSING_INITIATED';
export const IVR_FILES_PROCESSING_FINISHED = 'IVR_FILES_PROCESSING_FINISHED';

export const getInitialState = () => ({
    loading: null,
    error: null,
    ivrErrors: [],
    ivrProcessing: false,
    data: {},
    textAudio: {
        'download-text': null,
        'download-errortext': null,
    },
    questionDataForInherit: null,
});

export const reducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case CREATE_QUESTION_REQUEST:
        case GET_QUESTION_REQUEST:
        case GET_QUESTION_DATA_FOR_INHERIT_REQUEST:
        case PATCH_QUESTION_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case PATCH_QUESTION_TEXT_REQUEST:
        case PATCH_QUESTION_ERRORTEXT_REQUEST: {
            return {
                ...state,
                ivrErrors: state.ivrErrors.filter(error =>
                    error.id !== action.params.id && error.type !== action.params.type,
                ),
            };
        }

        case IVR_FILES_PROCESSING_INITIATED: {
            return {
                ...state,
                ivrProcessing: true,
            };
        }

        case GET_IVR_TEXT_FILE_SUCCESS: {
            return {
                ...state,
                textAudio: {
                    ...state.textAudio,
                    [action.params.type]: window.URL.createObjectURL(action.response),
                },
            };
        }

        case CLEANUP_IVR_TEXT_FILE: {
            return {
                ...state,
                textAudio: {
                    ...state.textAudio,
                    [action.payload]: null,
                },
            };
        }

        case IVR_FILES_PROCESSING_FINISHED: {
            return {
                ...state,
                ivrProcessing: false,
            };
        }

        case CREATE_QUESTION_SUCCESS:
        case PATCH_QUESTION_SUCCESS: {
            const { params, response } = action;

            return {
                ...state,
                loading: false,
                error: null,
                data: params.isSave
                    ? {}
                    : { ...response },
            };
        }

        case CREATE_QUESTION_ERROR:
        case GET_QUESTION_ERROR:
        case GET_QUESTION_DATA_FOR_INHERIT_ERROR:
        case PATCH_QUESTION_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error.response,
            };
        }

        //todo -> solve with id for mistakes
        case PATCH_QUESTION_TEXT_ERROR:
        case PATCH_QUESTION_ERRORTEXT_ERROR: {
            return {
                ...state,
                ivrErrors: [
                    ...state.ivrErrors,
                    {
                        ...action.params,
                        message: action.error.response.details,
                    }],
            };
        }

        case GET_QUESTION_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: {
                    ...action.response,
                },
            };
        }

        case GET_QUESTION_DATA_FOR_INHERIT_SUCCESS: {
            return {
                ...state,
                loading: !action.params.isCreatingSubQuestion,
                questionDataForInherit: {
                    ...action.response,
                },
                data: {},
            };
        }

        case RESET_QUESTION_DATA_FOR_INHERIT: {
            return {
                ...state,
                questionDataForInherit: null,
                data: {},
            };
        }

        case CLEANUP:
            return getInitialState();

        default:
            return state;
    }
};

export function cleanup() {
    return {
        type: CLEANUP,
    };
}

export function fileProcessingInitiated() {
    return {
        type: IVR_FILES_PROCESSING_INITIATED,
    };
}

export function fileProcessingFinished() {
    return {
        type: IVR_FILES_PROCESSING_FINISHED,
    };
}

export function resetQuestionDataForInherit() {
    return {
        type: RESET_QUESTION_DATA_FOR_INHERIT,
    };
}

export function createQuestionRequest(body, isSave) {
    return {
        [CALL_API]: {
            endpoint: `/question-extend/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_QUESTION_REQUEST, CREATE_QUESTION_SUCCESS, CREATE_QUESTION_ERROR ],
            apiType: FEEDBACK,
            body,
            logger: true,
            loggerMessages: {
                success: 'Question successfully created.',
                error: 'Something went wrong, please try again.',
            },
            params: {
                isSave,
            },
        },
    };
}

export function patchQuestionRequest(body, isSave) {
    return {
        [CALL_API]: {
            endpoint: `/question-extend/${ body.questionId }/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ PATCH_QUESTION_REQUEST, PATCH_QUESTION_SUCCESS, PATCH_QUESTION_ERROR ],
            apiType: FEEDBACK,
            body: body.values,
            logger: true,
            loggerMessages: {
                success: 'Question successfully updated.',
                error: 'Something went wrong, please try again.',
            },
            params: {
                isSave,
            },
        },
    };
}

// isCreatingSubQuestion - turn off loader after getting parent data, else await subquestion data
export function getQuestionRequest(id, isParent = false, isCreatingSubQuestion = false) {
    const types = isParent
        ? [ GET_QUESTION_DATA_FOR_INHERIT_REQUEST, GET_QUESTION_DATA_FOR_INHERIT_SUCCESS, GET_QUESTION_DATA_FOR_INHERIT_ERROR ]
        : [ GET_QUESTION_REQUEST, GET_QUESTION_SUCCESS, GET_QUESTION_ERROR ];

    return {
        [CALL_API]: {
            endpoint: `/question-extend/${ id }/`,
            method: 'GET',
            contentType: 'application/json',
            types,
            apiType: FEEDBACK,
            params: {
                isParent,
                isCreatingSubQuestion,
            },
        },
    };
}

export function getQuestionRequestByParentId(parentId, questionId = null) {
    return async dispatch => {
        await dispatch(getQuestionRequest(parentId, true, !questionId)).promise;

        if (questionId) {
            await dispatch(getQuestionRequest(questionId)).promise;
        }
    };
}

export function patchQuestionTextRequest({ file, id }) {
    return {
        [CALL_API]: {
            endpoint: `/question-language/${ id }/upload-text/`,
            method: 'PATCH',
            contentType: 'multipart/form-data',
            types: [ PATCH_QUESTION_TEXT_REQUEST, PATCH_QUESTION_TEXT_SUCCESS, PATCH_QUESTION_TEXT_ERROR ],
            apiType: FEEDBACK,
            params: {
                id,
                type: 'text',
            },
            body: {
                file,
            },
        },
    };
}

export function patchQuestionErrorTextRequest({ file, id }) {
    return {
        [CALL_API]: {
            endpoint: `/question-language/${ id }/upload-errortext/`,
            method: 'PATCH',
            contentType: 'multipart/form-data',
            types: [ PATCH_QUESTION_ERRORTEXT_REQUEST, PATCH_QUESTION_ERRORTEXT_SUCCESS, PATCH_QUESTION_ERRORTEXT_ERROR ],
            apiType: FEEDBACK,
            params: {
                id,
                type: 'error text',
            },
            body: {
                file,
            },
        },
    };
}

export function getIvrTextFile({ id, type }) {
    return {
        [CALL_API]: {
            endpoint: `/question-language/${ id }/${ type }/`,
            method: 'GET',
            responseType: 'blob',
            notDownload: true,
            types: [ GET_IVR_TEXT_FILE_REQUEST, GET_IVR_TEXT_FILE_SUCCESS, GET_IVR_TEXT_FILE_ERROR ],
            apiType: FEEDBACK,
            params: {
                type,
            },
        },
    };
}

export function cleanupIvrTextFile(type) {
    return {
        type: CLEANUP_IVR_TEXT_FILE,
        payload: type,
    };
}

export function deleteQuestionTextRequest({ id }) {
    return {
        [CALL_API]: {
            endpoint: `/question-language/${ id }/delete-audio-text/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_QUESTION_TEXT_REQUEST, DELETE_QUESTION_TEXT_SUCCESS, DELETE_QUESTION_TEXT_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function deleteQuestionErrorTextRequest({ id }) {
    return {
        [CALL_API]: {
            endpoint: `/question-language/${ id }/delete-audio-errortext/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_QUESTION_ERRORTEXT_REQUEST, DELETE_QUESTION_ERRORTEXT_SUCCESS, DELETE_QUESTION_ERRORTEXT_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function createQuestion(values, parentId = null, isSave) {
    return (dispatch, getState) => {
        const state = getState();
        const activeSurvey = activeSurveySelector(state);
        const model = {
            survey: activeSurvey.id,
            ...values,
        };

        if (values.language_values) {
            model['language_values'] = QuestionsServices.prepareLanguageDependentFieldsForServer(values.language_values);
        }

        if (values.answers) {
            model['answers'] = values.answers.map(answer => ({
                ...answer,
                names: QuestionsServices.prepareLanguageDependentFieldsForServer(answer.names),
            }));
        }

        if (parentId) model.parent_id = parentId;

        return dispatch(createQuestionRequest(model, isSave)).promise
            .catch(catchQuestionErrors);
    };
}

export function patchQuestion({ values, questionId, isSave }) {
    return async dispatch => {
        const isIvrChannel = values.display_class.includes('IVR');
        const model = {
            values: {
                ...values,
            },
            questionId,
        };

        if (model.values.language_values) {
            model.values.language_values = QuestionsServices.prepareLanguageDependentFieldsForServer(model.values.language_values);
        }

        if (model.values.answers) {
            model.values.answers = model.values.answers.map(answer => ({
                ...answer,
                names: QuestionsServices.prepareLanguageDependentFieldsForServer(answer.names),
            }));
        }

        let ivrResponseError = [];

        if (isIvrChannel) {
            ivrResponseError = await dispatch(processLangValuesIvr(model.values.language_values));
            delete model.values['language_values'];
        }

        return ivrResponseError.length
            ? new Promise((resolve, reject) => reject())
            : dispatch(patchQuestionRequest(model, isSave)).promise.catch(catchQuestionErrors);
    };
}

function processLangValuesIvr(langValues = []) {
    return dispatch => {
        dispatch(fileProcessingInitiated());
        const allPromise = [];

        langValues.forEach(value => {
            const { text, error_text, id } = value;

            if (text !== '' && text.removed) {
                allPromise.push(dispatch(deleteQuestionTextRequest({ id })).promise);
            }

            if (error_text !== '' && error_text.removed) {
                allPromise.push(dispatch(deleteQuestionErrorTextRequest({ id })).promise);
            }

            if (error_text !== '' && error_text instanceof Blob) {
                allPromise.push(dispatch(patchQuestionErrorTextRequest({ file: error_text, id })).promise);
            }

            if (text !== '' && text instanceof Blob) {
                allPromise.push(dispatch(patchQuestionTextRequest({ file: text, id })).promise);
            }
        });

        const errorMessage = [];

        return Promise.all(
            allPromise.map(p => p.catch(e => {
                errorMessage.push(e.response.details);
                return e;
            })),
        ).then(() => {
            dispatch(fileProcessingFinished());
            return errorMessage;
        });
    };
}

function catchQuestionErrors({ response }) {
    let errors = {
        'configurable': {},
    };

    if (response.configurable) {
        const dict = response.configurable.length ? response.configurable[0] : response.configurable;

        Object.keys(dict).map(value => errors.configurable[value] = dict[value]);
    }

    if (response.answers) {
        errors['answers'] = [{ 'error': response.answers[0] }];
    }

    if (response.language_values) {
        let newObj = {};

        Object.keys(response.language_values).map(el => {
            newObj = {
                ...newObj,
                ...response.language_values[el],
            };
        });

        errors = {
            ...errors,
            language_values: {
                ...newObj,
            },
        };
    }

    throw new SubmissionError(errors);
}
