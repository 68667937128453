import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { TopicSetApplianceTable } from './components';
import { TopicSetApplianceModal } from '/ai/components';
import {
    _getColumnsFilters,
    _getLanguagesFilters,
    _getSourcesFilters,
    _getTopicSetAppliance,
    _getTopicSetsFilters,
} from './modules/topicSetAppliance.selectors';
import {
    deleteTopicSetAppliance,
    getTopicSetAppliance,
    reApplySetAppliance,
    resetSelected,
    setSelected,
    topicSetApplianceFilter,
    updateTopicSetApplianceFilters,
} from './modules/topicSetAppliance.modules';
import {
    createTopicSetAppliance,
    editTopicSetAppliance,
    toggleTopicSetApplianceModal,
} from '/ai/modules/vocAi.modules';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

export const TopicSetAppliance = () => {
    const [ filterPopoverState, setFilterPopoverState ] = useState({
        targetFilter: null,
        filterField: '',
        isOpenPopoverFilter: false,
    });
    const [ initModalValues, setInitModalValues ] = useState({});

    const { data, loading, filters, totalCount } = useSelector(_getTopicSetAppliance);
    const topicSetsFilters = useSelector(_getTopicSetsFilters);
    const sourcesFilters = useSelector(_getSourcesFilters);
    const languagesFilters = useSelector(_getLanguagesFilters);
    const columnsFilters = useSelector(_getColumnsFilters);

    const dispatch = useDispatch();

    const updateTableFilters = appliedFilter => dispatch(updateTopicSetApplianceFilters(appliedFilter));
    const getTableData = appliedFilter => {
        updateTableFilters(appliedFilter);
        dispatch(getTopicSetAppliance(appliedFilter));
    };

    const applyPopoverFilter = appliedFilter => {
        const { payload } = dispatch(setSelected(appliedFilter));

        getTableData({
            ...filters,
            page: 1,
            extraFilters: {
                ...filters.extraFilters,
                [payload.name]: payload.value,
            },
        });
    };

    const resetFilters = filterName => {
        const { payload } = dispatch(resetSelected(filterName));

        const extraFiltersModel = payload
            ? {
                ...filters.extraFilters,
                [payload]: [],
            }
            : {
                topicSet: [],
                source: [],
                columns: [],
                languages: [],
            };

        getTableData({
            ...filters,
            page: 1,
            extraFilters: extraFiltersModel,
        });
    };

    const getPopoverFilterData = filterName => dispatch(topicSetApplianceFilter(filterName));
    const openModalCreateClassifierAppliance = () => dispatch(toggleTopicSetApplianceModal());

    const openModalDelete = id => {
        dispatch(showConfirm({
            header: 'Please confirm',
            content: `Are you sure you want to delete this appliance?`,
            successCallback: () => dispatch(deleteTopicSetAppliance(id))
                .promise.then(() => getTableData({
                    ...filters,
                    page: data.length - 1 > 0 ? filters.page : 1,
                })),
        }));
    };

    const openPopoverFilter = (currentTarget, sort_field) => {
        const { targetFilter } = filterPopoverState;

        if (!targetFilter || targetFilter !== currentTarget) {
            setFilterPopoverState({
                targetFilter: currentTarget,
                isOpenPopoverFilter: true,
                filterField: sort_field,
            });
        }
    };

    const closePopoverFilter = () => {
        setFilterPopoverState({
            targetFilter: null,
            isOpenPopoverFilter: false,
            filterField: '',
        });
    };

    const openModalEditClassifierAppliance = data => {
        const { source, topicSet, multiTopic, threshold, maxCategories, minCategories, id, sentiment, columnIndex } = data;
        const sourceColumn = source.attributes.find(attribute => attribute.index === columnIndex);

        const valuesModel = {
            topicSet,
            source,
            multiTopic,
            column: {
                id: sourceColumn.id,
                value: sourceColumn.id,
                label: sourceColumn.name,
                index: sourceColumn.index,
            },
            threshold,
            maxCategories,
            minCategories,
            applianceId: id,
            sentiment,
            edit: true,
        };

        setInitModalValues(valuesModel);
        dispatch(toggleTopicSetApplianceModal());
    };

    const afterSubmitModal = () => {
        dispatch(toggleTopicSetApplianceModal());
        getTableData(filters);
    };

    const onClosedModal = () => setInitModalValues({});

    const onSubmitModal = formValues => {
        const {
            multiTopic,
            topicSet,
            source,
            applianceId,
            threshold,
            maxCategories,
            minCategories,
            sentiment,
            edit,
            column,
        } = formValues;

        const params = {
            maxCategories: multiTopic ? maxCategories : 0,
            minCategories: multiTopic ? minCategories : 0,
            threshold: multiTopic ? threshold : 0.0,
            multiTopic,
            sentiment,
            ...!edit ? {
                attributeIndex: column.index,
            } : {},
        };

        const request = formValues.edit
            ? dispatch(editTopicSetAppliance(params, applianceId))
            : dispatch(createTopicSetAppliance(params, topicSet.id, source.id ));

        request.promise.then(afterSubmitModal);
    };

    useEffect(() => {
        getTableData(filters);
    }, []);

    return (
        <DocumentTitle title='VocAI :: Applied Topic Sets'>
            <Card>
                <CardBody>
                    <TopicSetApplianceTable
                        tableData={ data }
                        filters={ filters }
                        totalCount={ totalCount }
                        loading={ loading }
                        filterPopoverState={ filterPopoverState }
                        topicSetsFilters={ topicSetsFilters }
                        sourcesFilters={ sourcesFilters }
                        columnsFilters={ columnsFilters }
                        languagesFilters={ languagesFilters }
                        requestTrigger={ getTableData }
                        updateFilters={ updateTableFilters }
                        openModalDelete={ openModalDelete }
                        openPopoverFilter={ openPopoverFilter }
                        closePopoverFilter={ closePopoverFilter }
                        applyPopoverFilter={ applyPopoverFilter }
                        getPopoverFilterData={ getPopoverFilterData }
                        openModalCreateTopicSetAppliance={ openModalCreateClassifierAppliance }
                        openModalEditTopicSetAppliance={ openModalEditClassifierAppliance }
                        resetFilters={ resetFilters }
                        reApplySetAppliance={ (id: string) => dispatch(reApplySetAppliance(id)) }
                    />
                    <TopicSetApplianceModal
                        initFormValues={ initModalValues }
                        onSubmit={ onSubmitModal }
                        onClosed={ onClosedModal }
                    />
                </CardBody>
            </Card>
        </DocumentTitle>
    );
};
