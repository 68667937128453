import React, { PureComponent } from 'react';
import { Card, CardHeader, Col, Row, Label, CardBody, CardText, ListGroup, ListGroupItem } from 'reactstrap';
import { Field } from 'redux-form';
import cx from 'classnames';

import { VocFormDropzone } from '/components/VocFormFields';

import './QuestionAudioSection.scss';

export class QuestionAudioSection extends PureComponent {
    renderErrors() {
        return (
            <ListGroup className={ 'audio__section-error' }>
                {
                    this.props.errors.map((error, index) =>
                        <ListGroupItem key={ index }>{ error }</ListGroupItem>,
                    )
                }
            </ListGroup>
        );
    }

    render() {
        const {
            language,
            questionId,
            loading,
            errors,
            audioFiles,
            getAudioFile,
            cleanUpAudio,
        } = this.props;

        const isEdit = Boolean(questionId);

        return (
            <Card className={ cx( 'question__section', { 'question__section--disabled': !isEdit } ) }>
                <CardHeader>
                    <Row>
                        <Col lg={ 10 }>
                            <span>Question audio files (*.wav)</span>
                        </Col>
                        <Col lg={ 2 }>
                            <div className="language__header-value">
                                { language }
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className={ `question__section-body ${loading ? 'question__section--loading' : ''}` }>
                    {
                        errors.length > 0 && this.renderErrors()
                    }
                    <Row>
                        <Col md={ 12 } className="text-field-control-label text-right">
                            <Label className={ 'general-section__label' } htmlFor={ 'questionText' }>
                                Text
                            </Label>
                        </Col>
                        <Col md={ 12 } className="box-audio-files">
                            <Field
                                placeholder={ 'Enter question title' }
                                name={ `language_values.${language}.text` }
                                withError={ false }
                                component={ VocFormDropzone }
                                disabled={ !isEdit }
                                required
                                fieldType={ 'download-text' }
                                audioFile={ audioFiles }
                                getAudioFile={ getAudioFile }
                                cleanUpAudio={ cleanUpAudio }
                            />
                        </Col>

                        <Col md={ 12 } className="text-field-control-label text-right question_audio_label--error">
                            <Label className={ 'general-section__label' } htmlFor={ 'questionErrorText' }>
                                Error text
                            </Label>
                        </Col>
                        <Col md={ 12 } className="box-audio-files">
                            <Field
                                placeholder={ 'Enter question title' }
                                name={ `language_values.${language}.error_text` }
                                withError={ false }
                                component={ VocFormDropzone }
                                disabled={ !isEdit }
                                required
                                fieldType={ 'download-errortext' }
                                audioFile={ audioFiles }
                                getAudioFile={ getAudioFile }
                                cleanUpAudio={ cleanUpAudio }
                            />
                        </Col>

                        {
                            !isEdit
                            && <Col md={ 12 } className={ 'col-text-notice' }>
                                <CardText className={ 'text-right font-weight-bold text-center' }>
                                    To upload audio files please click "Save" or "Apply" button first.
                                </CardText>
                            </Col>
                        }

                    </Row>
                </CardBody>
            </Card>
        );
    }
}
