import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { surveyGroupSelector, isLoadingSurveySelector } from '../../modules/survey.selectors';
import { possibleRangesSelector, dateRangesSelector, activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { PageRequests, HelperService, DateRangesService } from '/services';
import { StatisticResultsBlocksSection } from './components/StatisticResultsBlocksSection';
import { StatisticResultsInfoSection } from './components/StatisticResultsInfoSection';
import { StatisticFilters } from '../../components/StatisticFilters';
import { VocCleaupHOC } from '/components';

import {
    getStatistic,
    changeStatisticFilters,
    cleanup,
} from './modules/statisticsResults.modules';

import {
    StatisticsResultsSelector,
    StatisticsResultsFilterSelector,
    statisticSelector,
} from './modules/statisticsResults.selectors';

import './StatisticsResults.scss';

const mapStateToProps = state => ({
    surveyGroup: surveyGroupSelector(state),
    statisticsResults: StatisticsResultsSelector(state),
    filters: StatisticsResultsFilterSelector(state),
    statistic: statisticSelector(state),
    activeSurvey: activeSurveySelector(state),
    isLoadingSurvey: isLoadingSurveySelector(state),
    possibleRanges: possibleRangesSelector(state),
    statisticDateRanges: dateRangesSelector(state),
});

const mapDispatchToProps = {
    getStatistic,
    changeStatisticFilters,
    cleanup,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class StatisticsResults extends Component {
    pageRequests = new PageRequests();

    state = {
        dateRange: 'yesterday',
    };

    componentDidMount() {
        const { channel } = this.props.activeSurvey;

        channel && this.getStatisticRequest();
    }

    componentDidUpdate(prevProps) {
        const { channel } = this.props.activeSurvey;

        if (channel !== prevProps.activeSurvey.channel) {
            this.getStatisticRequest();
        }
    }

    @autobind
    getStatisticRequest(filters = this.props.filters) {
        const { channel } = this.props.activeSurvey;
        const { surveyGroup } = this.props.match.params;

        return this.props.getStatistic(surveyGroup, { ...filters, channel }).promise;
    }

    @autobind
    onChangePossibleRanges(range) {
        this.props.changeStatisticFilters({ range: range.value });
    }

    @autobind
    onChangeDateRanges(dateRange) {
        const dateObject = DateRangesService(dateRange.value);

        this.setState({
            dateRange: dateRange.value,
        });

        const dates = {
            from: dateObject.start_time,
            to: dateObject.end_time,
        };

        this.dateRangeHandler(dates);
    }

    @autobind
    dateRangeHandler(date) {
        const dates = {
            start_time: date.from ? HelperService.formatDate(date.from, 'YYYY-MM-DD') : date.from,
            end_time: date.to ? HelperService.formatDate(date.to, 'YYYY-MM-DD') : date.to,
        };

        this.props.changeStatisticFilters(dates);
    }

    @autobind
    applyFilters() {
        this.getStatisticRequest();
    }

    @autobind
    getPreloader() {
        const { activeSurvey: { channel }, isLoadingSurvey } = this.props;
        const isCreatedChannel = channel || isLoadingSurvey;

        return (
            isCreatedChannel
                ? this.getStatisticResultsBody()
                : <Alert color={ 'info' } className={ 'text-center' }>
                    To see the statistics, please activate or select an activated channel first
                </Alert>
        );
    }

    @autobind
    getStatisticResultsBody() {
        const {
            possibleRanges,
            statisticDateRanges,
            statistic,
            filters,
            statisticsResults: { loading },
            activeSurvey: { channel },
        } = this.props;


        return (
            <Fragment>
                <Row>
                    <Col md={ 4 }>
                        <StatisticFilters
                            possibleRanges={ possibleRanges }
                            statisticDateRanges={ statisticDateRanges }
                            filters={ filters }
                            dateRange={ this.state.dateRange }
                            onChangePossibleRanges={ this.onChangePossibleRanges }
                            onChangeDateRanges={ this.onChangeDateRanges }
                            dateRangeHandler={ this.dateRangeHandler }
                            applyFilters={ this.applyFilters }
                            loading={ loading }
                        />
                    </Col>
                    <Col md={ 8 }>
                        <StatisticResultsBlocksSection
                            statistic={ statistic }
                            loading={ loading }
                            channel = { channel }
                        />
                    </Col>
                    <Col md={ 12 } className={ 'statistic_result__info-wrapper' }>
                        <StatisticResultsInfoSection channel={ channel }/>
                    </Col>
                </Row>
            </Fragment>
        );
    }

    render() {
        return (
            <DocumentTitle title="Feedback :: Survey Statistics">
                <div>
                    { this.getPreloader() }
                </div>
            </DocumentTitle>
        );
    }
}
