import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

import './ExplanationIcon.scss';

export const ExplanationIcon = ({ title, name = 'exclamation-triangle' }) => {
    return (
        <span>
            <TooltipWrapper value={ title }>
                <span>
                    <FontAwesomeIcon icon={ name } className="explanation__icon"/>
                </span>
            </TooltipWrapper>
        </span>
    );
};
