import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { VocTableV2, TooltipWrapper } from '/components';
import { Row, Col } from 'reactstrap';
import { CustomersTableActionsFormatter, CustomerTableCellExpandFormatter } from '../';

import './CustomersTable.scss';

export class CustomersTable extends Component {
    settings = [
        {
            field: 'external_id',
            title: 'Customer ID',
            sort: true,
            formatter: this.firstCellFormatter,
        },
        {
            field: 'emails',
            title: 'Email',
            formatter: this.emailsFormatter,
            forceRerender: true,
        },
        {
            field: 'phones',
            title: 'Phone',
            formatter: this.phonesFormatter,
            forceRerender: true,
        },
        {
            field: 'creation',
            title: 'Date Added',
            formatter: 'date',
            sort: true,
        },
        {
            field: 'survey_group_name',
            title: 'Survey',
            formatter: this.surveyFormatter,
        },
        {
            field: 'actions',
            formatter: this.customersTableActionsFormatter,
            title: 'Actions',
            style: {
                textAlign: 'center',
                width: '80px',
            },
        },
    ];

    @autobind
    customersTableActionsFormatter(cell, obj) {
        const {
            openModalDelete,
            openEditModal,
            isPermitEdit,
        } = this.props;

        return <CustomersTableActionsFormatter
            { ...obj }
            openModalDelete={ openModalDelete }
            openEditModal={ openEditModal }
            isPermitEdit={ isPermitEdit }
        />;
    }

    @autobind
    firstCellFormatter(cell, obj) {
        const { blacklisted } = obj;

        return <div className={ `${ blacklisted ? 'cell-blacklisted' : '' }` }>
            <TooltipWrapper value={ cell }>
                <div className="cell-data-wrapper"> { cell }</div>
            </TooltipWrapper>
        </div>;
    }

    @autobind
    emailsFormatter(cell, obj) {
        return cell
            && <CustomerTableCellExpandFormatter
                values={ cell }
                shouldCollapse={ obj.shouldEmailsCollapse }
                hiddenLength={ obj.hiddenEmailsLength }
                visibleItems={ obj.visibleEmails }
                hiddenItems={ obj.hiddenEmails }
            />;
    }

    @autobind
    phonesFormatter(cell, obj) {
        return cell
            && <CustomerTableCellExpandFormatter
                values={ cell }
                shouldCollapse={ obj.shouldPhonesCollapse }
                hiddenLength={ obj.hiddenPhonesLength }
                visibleItems={ obj.visiblePhones }
                hiddenItems={ obj.hiddenPhones }
                className={ 'address-list_phones' }
            />;
    }

    @autobind
    surveyFormatter(cell) {
        return cell
            && <div>
                <Row>
                    {
                        cell.map((survey, index) => <Col xs={ 12 } key={ index }>
                            <span>{ survey }</span>
                        </Col>)
                    }
                </Row>
            </div>;
    }

    render() {
        const {
            tableState: {
                count,
                loading,
                filters,
            },
            data,
            options,
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                data={ data }
                options={ options }
                filters={ filters }
                total={ count }
                loading={ loading }
            />
        );
    }
}
