import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { FileManager } from '/feedback/components';
import { TinyEditorService } from "/services";

// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// A theme is also required
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/icons/default';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/save';
import 'tinymce/plugins/table';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/fullpage';

import { Editor } from '@tinymce/tinymce-react';
import './TinyEditor.scss';
import PropTypes from 'prop-types';
// textpattern plugin crashed
const EditorPlugins = [
    'advlist autolink lists link image charmap hr anchor pagebreak',
    'searchreplace wordcount visualblocks visualchars',
    'insertdatetime media nonbreaking save table directionality',
    'paste quickbars imagetools',
];
const EditorSecondToolBar = `bold italic underline | alignleft aligncenter alignright alignjustify | forecolor | link image customInsertButton | formatselect | fontselect | fontsizeselect`;
const EDITOR_TOOLBAR_HEIGHT = 110;

const headingsStyles = `h1, h2, h3, h4, h5, h6 { font-weight: unset }`;

export class TinyEditor extends PureComponent {
    state = {
        isManagerOpen: false,
        isCanEdit: false,
        callback: () => {
        },
    };

    @autobind
    toggleManager() {
        this.setState({
            isManagerOpen: false,
        });
    }

    @autobind
    handleChange(value) {
        this.props.onChange(value);
    }

    componentDidUpdate(prevProps) {
        if (this.props.height && prevProps.height !== this.props.height) {
            const container = document.getElementsByClassName('tox-tinymce');

            container[0].style.height = this.props.height;
        }
    }

    @autobind
    checkEditorHeight(bodyHeight) {
        if (typeof this.props.resizeCallback === 'function') {
            const currentHeight = bodyHeight + EDITOR_TOOLBAR_HEIGHT;
            const newHeight = bodyHeight > parseInt(this.props.baseHeight)
                ? `${ currentHeight }px`
                : this.props.baseHeight;

            this.props.resizeCallback(newHeight);
        }
    }

    @autobind
    onEditorDetach() {
        const { value, onEditorDetach } = this.props;

        onEditorDetach?.(value);
    }

    render() {
        const {
            assets,
            getAssets,
            uploadAsset,
            value,
            height,
            additionalPlugin,
            onlyBlankLinks,
            auxiliaryModalClass,
        } = this.props;

        const plugins = [ ...EditorPlugins ];

        if(additionalPlugin) {
            plugins[3] = `${plugins[3]} fullpage`;
        }

        const targetList = [
            ...!onlyBlankLinks
                ? [{ text: 'Current window', value: '' }]
                : [],
            { text: 'New window', value: '_blank' },
        ];

        return (
            <>
                <Editor
                    value={ value?.toString() }
                    onEditorChange={ this.handleChange }
                    init={{
                        content_style: TinyEditorService.getFonts() + headingsStyles,
                        font_formats: `
                            Helvetica=helvetica;
                            Arial=arial,helvetica,sans-serif;
                            Montserrat=montserrat;
                            Open Sans=open sans;
                            Poppins=poppins;
                            Roboto=roboto;
                            Times New Roman=times new roman,times;
                            Carlito=carlito;`,
                        default_link_target: onlyBlankLinks ? '_blank' : null,
                        target_list: targetList,
                        resize: false,
                        mode: "specific_textareas",
                        editor_selector: "mceEditor",
                        plugins,
                        skin: false,
                        content_css: false,
                        menubar: false,
                        valid_children: '+body[style]',
                        extended_valid_elements: 'script[type|src],style',
                        entity_encoding: 'raw',
                        force_br_newlines: false,
                        force_p_newlines: false,
                        forced_root_block: '',
                        allow_conditional_comments: true,
                        verify_html: false,
                        toolbar1: false,
                        toolbar2: EditorSecondToolBar,
                        image_advtab: true,
                        file_picker_callback: callback => {
                            this.setState({
                                isManagerOpen: true,
                                callback,
                            });
                            getAssets();
                        },
                        quickbars_insert_toolbar: 'quickimage quicktable',
                        height: height,
                        setup: editor => {
                            editor.on('init', () => {
                                this.checkEditorHeight(editor.getBody().offsetHeight);

                                auxiliaryModalClass
                                && document.querySelectorAll('.tox.tox-silver-sink.tox-tinymce-aux').forEach(modal => {
                                    modal.classList.add(auxiliaryModalClass);
                                });

                                if (this.props.autofocus) {
                                    editor.execCommand('mceFocus');
                                }
                            });

                            editor.on('detach', () => {
                                this.onEditorDetach();
                            });

                            if(this.props.onOpenHtmlEditor) {
                                editor.ui.registry.addButton('customInsertButton', {
                                    icon: 'sourcecode',
                                    tooltip: 'Edit HTML',
                                    onAction: this.props.onOpenHtmlEditor,
                                });
                            }
                        },
                        init_instance_callback: editor => {
                            editor.on('keyup', () => {
                                this.checkEditorHeight(editor.getBody().offsetHeight);
                            });
                        },
                    }}
                />
                <FileManager
                    toggle={ this.toggleManager }
                    isOpen={ this.state.isManagerOpen }
                    fillEditorValue={ this.state.callback }
                    assets={ assets }
                    uploadAsset={ uploadAsset }
                    getAssets={ getAssets }
                />
            </>
        );
    }
}

TinyEditor.propTypes = {
    assets: PropTypes.shape({
        loading: PropTypes.bool,
        files: PropTypes.array,
    }),
    getAssets: PropTypes.func,
    uploadAsset: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onEditorDetach: PropTypes.func,
};

TinyEditor.defaultProps = {
    auxiliaryModalClass: '',
};
