import { arrayMove } from 'react-sortable-hoc';
import { getI18n } from 'react-i18next';

import { DashboardGridService } from '/visual/services';
import { GadgetService } from '/visual/scenes/Dashboard/components';
import { DashboardService } from '/visual/scenes/Dashboard/services';
import { toggleIsCreated } from '/visual/modules/VocVisual.modules';
import { CALL_API } from 'store/middleware/api';
import { EExportFormats, EGadgetType, IGadgetData, INewGadgetAction, TGadgetCoordinates } from '/visual/models';
import { EUpdateGadgetTypes, TLabelData } from '/visual/scenes/Dashboard/components/Gadget/models';
import {
    IMarkGadgetsForUpgrade,
    TMarkGadgetsForUpgradeDispatch,
    TSetScrollTo,
} from '/visual/scenes/Dashboard/modules/models';
import { TGetVisualStore } from './models';
import { IDashboard, IFilters, IMovePageToAction, IPages, saveChangesModelType } from '../models';
import { HelperService } from '/services';

export enum DashboardModalType {
    empty = '',
    copyDashboard = 'copyDashboard',
    createDashboard = 'createDashboard',
    dashboardFilter = 'dashboardFilter',
    renameDashboard = 'renameDashboard',
    deleteDashboard = 'deleteDashboard',
    sendToEmail = 'sendToEmail',
    scheduler = 'scheduler',
    configureGadget = 'configureGadget',
    editStaticAreaCode = 'editStaticAreaCode',
    descriptionGadget = 'descriptionGadget',
    deleteGadget = 'deleteGadget',
    importOldDashboard = 'importOldDashboard',
    drillDownGadget = 'drillDownGadget',
}

export const InitialTableFilters = {
    limit: 10,
    offset: '0',
};

const CLEANUP_CONFIGURE_MODAL = 'CLEANUP_CONFIGURE_MODAL';

const ADD_GADGET_SUCCESS = "ADD_GADGET_SUCCESS";
const ADD_GADGET_REQUEST = "ADD_GADGET_REQUEST";
const ADD_GADGET_ERROR = "ADD_GADGET_ERROR";

const COPY_GADGET_REQUEST = "COPY_GADGET_REQUEST";
const COPY_GADGET_SUCCESS = "COPY_GADGET_SUCCESS";
const COPY_GADGET_ERROR = "COPY_GADGET_ERROR";

const SAVE_GADGET_IN_STORE = "SAVE_GADGET_IN_STORE";

const CHANGE_POSITION_IN_STORE = "CHANGE_POSITION_IN_STORE";

const DELETE_GADGET_IN_STORE = "DELETE_GADGET_IN_STORE";
const DELETE_PAGE_IN_STORE = 'DELETE_PAGE_IN_STORE';

const EDIT_GADGET_TITLE_SUCCESS = "EDIT_GADGET_TITLE_SUCCESS";
const EDIT_GADGET_TITLE_REQUEST = "EDIT_GADGET_TITLE_REQUEST";
const EDIT_GADGET_TITLE_ERROR = "EDIT_GADGET_TITLE_ERROR";

const INITIATE_PRINT_SUCCESS = "INITIATE_PRINT_SUCCESS";
const INITIATE_PRINT_REQUEST = "INITIATE_PRINT_REQUEST";
const INITIATE_PRINT_ERROR = "INITIATE_PRINT_ERROR";

const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
const GET_DASHBOARD_ERROR = 'GET_DASHBOARD_ERROR';

const GET_DASHBOARD_FOR_PRINT_REQUEST = 'GET_DASHBOARD_FOR_PRINT_REQUEST';
const GET_DASHBOARD_FOR_PRINT_SUCCESS = 'GET_DASHBOARD_FOR_PRINT_SUCCESS';
const GET_DASHBOARD_FOR_PRINT_ERROR = 'GET_DASHBOARD_FOR_PRINT_ERROR';

const GET_GADGET_DATA_REQUEST = 'GET_GADGET_DATA_REQUEST';
const GET_GADGET_DATA_SUCCESS = 'GET_GADGET_DATA_SUCCESS';
const GET_GADGET_DATA_ERROR = 'GET_GADGET_DATA_ERROR';

const MARK_GADGETS_FOR_UPGRADE = 'MARK_GADGETS_FOR_UPGRADE';

const SET_STATIC_AREA_DATA_REQUEST = 'SET_STATIC_AREA_DATA_REQUEST';
const SET_STATIC_AREA_DATA_SUCCESS = 'SET_STATIC_AREA_DATA_SUCCESS';
const SET_STATIC_AREA_DATA_ERROR = 'SET_STATIC_AREA_DATA_ERROR';

const SOCKET_UPDATE_CHART_DATA_REQUEST = 'SOCKET_UPDATE_CHART_DATA_REQUEST';
const SOCKET_UPDATE_CHART_DATA_SUCCESS = 'SOCKET_UPDATE_CHART_DATA_SUCCESS';
const SOCKET_UPDATE_CHART_DATA_ERROR = 'SOCKET_UPDATE_CHART_DATA_ERROR';

const TOGGLE_FAVOURITE_IN_DASHBOARD_REQUEST = 'TOGGLE_FAVOURITE_IN_DASHBOARD_REQUEST';
const TOGGLE_FAVOURITE_IN_DASHBOARD_SUCCESS = 'TOGGLE_FAVOURITE_IN_DASHBOARD_SUCCESS';
const TOGGLE_FAVOURITE_IN_DASHBOARD_ERROR = 'TOGGLE_FAVOURITE_IN_DASHBOARD_ERROR';

const UPDATE_DASHBOARD_DATA = 'UPDATE_DASHBOARD_DATA';

const GET_VISUAL_SOURCE_ERROR = "GET_VISUAL_SOURCE_ERROR";
const GET_VISUAL_SOURCE_REQUEST = "GET_VISUAL_SOURCE_REQUEST";
const GET_VISUAL_SOURCE_SUCCESS = "GET_VISUAL_SOURCE_SUCCESS";

const DATA_SELECT_SET_FILTERS = "DATA_SELECT_SET_FILTERS";
const DATA_SELECT_SET_PAGE = "DATA_SELECT_SET_PAGE";

const SET_GADGET_DATA = "SET_GADGET_DATA";
const APPLY_GADGET_DATA_CHANGE = "APPLY_GADGET_DATA_CHANGE";

const SET_CONTENT_SETTING_REQUEST = 'SET_CONTENT_SETTING_REQUEST';
const SET_CONTENT_SETTING_SUCCESS = 'SET_CONTENT_SETTING_SUCCESS';
const SET_CONTENT_SETTING_ERROR = 'SET_CONTENT_SETTING_ERROR';

const SET_DATA_SETTING_REQUEST = 'SET_DATA_SETTING_REQUEST';
const SET_DATA_SETTING_SUCCESS = 'SET_DATA_SETTING_SUCCESS';
const SET_DATA_SETTING_ERROR = 'SET_DATA_SETTING_ERROR';

const GET_ACTIVE_SOURCE_REQUEST = 'GET_ACTIVE_SOURCE_REQUEST';
const GET_ACTIVE_SOURCE_SUCCESS = 'GET_ACTIVE_SOURCE_SUCCESS';
const GET_ACTIVE_SOURCE_ERROR = 'GET_ACTIVE_SOURCE_ERROR';
const CHANGE_ACTIVE_SOURCE_ID = 'CHANGE_ACTIVE_SOURCE_ID';

const GET_DASHBOARD_FILTER_SOURCE_REQUEST = 'GET_DASHBOARD_FILTER_SOURCE_REQUEST';
const GET_DASHBOARD_FILTER_SOURCE_SUCCESS = 'GET_DASHBOARD_FILTER_SOURCE_SUCCESS';
const GET_DASHBOARD_FILTER_SOURCE_ERROR = 'GET_DASHBOARD_FILTER_SOURCE_ERROR';

const GET_DASHBOARD_FILTER_REQUEST = 'GET_DASHBOARD_FILTER_REQUEST';
const GET_DASHBOARD_FILTER_SUCCESS = 'GET_DASHBOARD_FILTER_SUCCESS';
const GET_DASHBOARD_FILTER_ERROR = 'GET_DASHBOARD_FILTER_ERROR';

const TOGGLE_PROCESSING_DASHBOARD_FILTER = 'TOGGLE_PROCESSING_DASHBOARD_FILTER';
const SET_DASHBOARD_FILTER_ACTIVE_SOURCE = 'SET_DASHBOARD_FILTER_ACTIVE_SOURCE';

const SEND_DASHBOARD_FILTER_REQUEST = 'SEND_DASHBOARD_FILTER_REQUEST';
const SEND_DASHBOARD_FILTER_SUCCESS = 'SEND_DASHBOARD_FILTER_SUCCESS';
const SEND_DASHBOARD_FILTER_ERROR = 'SEND_DASHBOARD_FILTER_ERROR';

const TOGGLE_MODAL_VISUAL = 'TOGGLE_MODAL_VISUAL';

const SEND_CHART_COLOR_REQUEST = 'SEND_CHART_COLOR_REQUEST';
const SEND_CHART_COLOR_SUCCESS = 'SEND_CHART_COLOR_SUCCESS';
const SEND_CHART_COLOR_ERROR = 'SEND_CHART_COLOR_ERROR';

const SEND_BAR_LABEL_REQUEST = 'SEND_BAR_LABEL_REQUEST';
const SEND_BAR_LABEL_SUCCESS = 'SEND_BAR_LABEL_SUCCESS';
const SEND_BAR_LABEL_ERROR = 'SEND_BAR_LABEL_ERROR';

const SAVE_CHANGES_REQUEST = 'SAVE_CHANGES_REQUEST';
const SAVE_CHANGES_SUCCESS = 'SAVE_CHANGES_SUCCESS';
const SAVE_CHANGES_ERROR = 'SAVE_CHANGES_ERROR';

const ADD_PAGE_REQUEST = 'ADD_PAGE_REQUEST';
const ADD_PAGE_SUCCESS = 'ADD_PAGE_SUCCESS';
const ADD_PAGE_ERROR = 'ADD_PAGE_ERROR';

const MOVE_PAGE_TO = 'MOVE_PAGE_TO';
const MANAGE_GADGET_CLIPBOARD = 'MANAGE_GADGET_CLIPBOARD';

const CLEANUP_DASHBOARD_PAGE = 'CLEANUP_DASHBOARD_PAGE';

const TOGGLE_IS_EDITABLE = 'TOGGLE_IS_EDITABLE';

const ON_SYNC_CHART = 'ON_SYNC_CHART';

const UPDATE_FILTER_FOR_TABLE = 'UPDATE_FILTER_FOR_TABLE';

const UPDATE_COORDINATES = 'UPDATE_COORDINATES';

const PERSIST_SELECTED_GROUP_REQUEST = 'PERSIST_SELECTED_GROUP_REQUEST';
const PERSIST_SELECTED_GROUP_SUCCESS = 'PERSIST_SELECTED_GROUP_SUCCESS';
const PERSIST_SELECTED_GROUP_ERROR = 'PERSIST_SELECTED_GROUP_ERROR';

const SET_SCROLL_TO_GADGET = 'SET_SCROLL_TO_GADGET';
const SET_SCROLL_TO_PAGE = 'SET_SCROLL_TO_PAGE';

export const START_SMOOTH_SCROLLING = 'START_SMOOTH_SCROLLING';
export const RESET_SCROLL_INTO_VIEW = 'RESET_SCROLL_INTO_VIEW';

const redirect = '/visualV2/dashboard';
const PAYLOAD_TOO_LARGE = 413;

export const getInitialState = (): IDashboard => ({
    dashboard: {
        gadgets: [],
    },
    loading: true,
    isEditable: false,
    stateOfEdit: {
        extraGadgets: null,
        extraPages: null,
        gadgets: [],
        pages: [],
        pagesOrder: [],
        changed: {
            page: [],
            deleteGadget: [],
            isPageOrderChanged: false,
            deletedPages: [],
        },
        scrollTo: {
            scrollToGadget: null,
            scrollToPage: null,
            startSmoothScrolling: false,
            noResetOnLocationChange: false,
        },
    },
    gadgetData: null,
    gadgetPending: false,
    dataSource: {
        data: [],
        count: 0,
        loading: false,
        filters: {
            type: 'all',
            page: 1,
            limit: 10,
            state: [],
        },
    },
    activeSource: {
        loading: false,
        loaded: false,
        data: {},
    },
    dashboardFilter: {
        dataFilter: {},
        dataSource: [],
        activeSourceId: null,
        loading: true,
    },
    gadgetsChartsData: null,
    modalState: {
        dashboardId: null,
        gadgetId: null,
        gadgetType: null,
        type: '',
    },
    gadgetClipboard: null,
});

export function reducer(state = getInitialState(), action: any = {}) {
    switch (action.type) {
        case CLEANUP_DASHBOARD_PAGE: {
            // drillDown goToParentGadget logic (need to scroll parent gadget into view)
            if (state.stateOfEdit.scrollTo.noResetOnLocationChange) {
                const resetState = getInitialState();

                return {
                    ...resetState,
                    stateOfEdit: {
                        ...resetState.stateOfEdit,
                        scrollTo: state.stateOfEdit.scrollTo,
                    },
                };
            }

            return getInitialState();
        }

        case CLEANUP_CONFIGURE_MODAL: {
            return {
                ...state,
                activeSource: {
                    loading: false,
                    loaded: false,
                    data: {},
                },
                gadgetData: null,
            };
        }

        case UPDATE_DASHBOARD_DATA: {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    ...action.dashboard,
                },
            };
        }

        case GET_DASHBOARD_FOR_PRINT_REQUEST:
        case GET_DASHBOARD_REQUEST: {
            return {
                ...state,
                loading: true,
                dashboard: getInitialState().dashboard,
            };
        }

        case GET_DASHBOARD_SUCCESS: {
            const {
                dashboard: dashboardResponse,
                gadgets: extraGadgets,
                pages: extraPages,
            } = action.response;

            const { pagesOrder, pages } = action.response.dashboard;

            if (
                pagesOrder.length !== pages.length // If the board has just been created, pagesOrder.length = 0 - it needs to be updated on the frontend
                || HelperService.checkNotNullOrUndefined(pagesOrder) // Due to the initial lack of values in pagesOrder, incorrect pagesOrder were created like [null, id, null, id, id]
            ) {
                dashboardResponse.pagesOrder = HelperService.mapOrder(pages, pagesOrder, 'id').map(({ id }) => id);
            }

            const gadgets = dashboardResponse?.gadgets || [];
            const parentGadgets = DashboardService.getAllParentGadget(dashboardResponse);
            const allGadgets = [ ...parentGadgets, ...gadgets ];

            const gadgetsChartsData = allGadgets.reduce((chartsData, currentGadget) => {
                return {
                    ...chartsData,
                    [currentGadget.id]: {
                        loading: false,
                        loaded: false,
                        needToUpdate: false,
                        updateType: null,
                        gadgetData: currentGadget,
                        chartData: null,
                    },
                };
            }, {});

            return {
                ...state,
                loading: false,
                dashboard: {
                    ...dashboardResponse,
                    extraGadgets,
                    extraPages,
                    gadgets,
                },
                gadgetsChartsData,
                ...action.params.isCreated
                    ? {
                        stateOfEdit: JSON.parse(
                            JSON.stringify(
                                {
                                    ...getInitialState().stateOfEdit,
                                    extraPages,
                                    extraGadgets,
                                    gadgets,
                                    pages: dashboardResponse.pages,
                                    pagesOrder: dashboardResponse.pagesOrder,
                                },
                            ),
                        ),
                        isEditable: true,
                    }
                    : {},
            };
        }

        case GET_DASHBOARD_FOR_PRINT_SUCCESS: {
            const dashboardData = {
                ...action.response.dashboard,
                pages: action.response.dashboard.pages.filter((page: IPages) => page.id === action.data.page),
            };

            const { gadgets } = dashboardData;

            const gadgetsChartsData = gadgets.reduce((chartsData: any, currentGadget: IGadgetData) => {
                return {
                    ...chartsData,
                    [currentGadget.id]: {
                        loading: false,
                        needToUpdate: false,
                        updateType: null,
                        gadgetData: currentGadget,
                        chartData: null,
                    },
                };
            }, {});

            return {
                ...state,
                loading: false,
                dashboard: {
                    ...dashboardData,
                    extraGadgets: action.response.gadgets,
                    extraPages: action.response.pages,
                    gadgets,
                },
                gadgetsChartsData,
            };
        }

        case TOGGLE_FAVOURITE_IN_DASHBOARD_SUCCESS: {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isFavourite: action.response.isFavourite,
                },
            };
        }

        case GET_DASHBOARD_FOR_PRINT_ERROR:
        case GET_DASHBOARD_ERROR: {
            return {
                ...state,
                loading: false,
                dashboard: getInitialState().dashboard,
            };
        }

        case SET_GADGET_DATA: {
            return {
                ...state,
                gadgetData: state.gadgetsChartsData[action.payload].gadgetData,
            };
        }

        case ON_SYNC_CHART: {
            const { payload: { gadgetData } } = action;

            return {
                ...state,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [gadgetData.id]: {
                        loading: false,
                        loaded: false,
                        needToUpdate: false,
                        updateType: null,
                        gadgetData,
                        chartData: null,
                    },
                },
            };
        }

        case GET_GADGET_DATA_REQUEST: {
            const { params: { gadgetId } } = action;

            return {
                ...state,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [gadgetId]: {
                        ...state.gadgetsChartsData[gadgetId],
                        loading: true,
                        needToUpdate: false,
                        updateType: null,
                        chartData: null,
                    },
                },
            };
        }

        case MARK_GADGETS_FOR_UPGRADE: {
            const { gadgetsId = [], updateType } = action.payload;
            const { gadgetsChartsData } = state;

            const changedGadgetsChartsData: any = {};

            gadgetsId.forEach((id: string) => {
                changedGadgetsChartsData[id] = {
                    ...gadgetsChartsData[id],
                    needToUpdate: true,
                    updateType,
                };
            });

            return {
                ...state,
                gadgetsChartsData: {
                    ...gadgetsChartsData,
                    ...changedGadgetsChartsData,
                },
            };
        }

        case SOCKET_UPDATE_CHART_DATA_SUCCESS:
        case GET_GADGET_DATA_SUCCESS: {
            const { params: { gadgetId }, response, data } = action;
            const requestWithFilters = Object.keys(data).length;
            const filters = requestWithFilters
                ? { filters: { ...data } }
                : {};
            let chartData = null;

            if (response.data) {
                chartData = typeof response.data === 'string' && response?.gadget?.type === EGadgetType.STATIC_AREA
                    ? response.data
                    : {
                        ...response.data,
                        gadgetId,
                        ...filters,
                    };
            }

            return {
                ...state,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [gadgetId]: {
                        ...state.gadgetsChartsData[gadgetId],
                        loading: false,
                        loaded: true,
                        needToUpdate: false,
                        updateType: null,
                        chartData,
                        error: null,
                        gadgetData: response.gadget
                            ? response.gadget
                            : null,
                    },
                },
            };
        }

        case SOCKET_UPDATE_CHART_DATA_ERROR:
        case GET_GADGET_DATA_ERROR: {
            const { params: { gadgetId }, error } = action;

            const errorCode = error?.response[0]?.originalException?.[0] || error?.response?.originalException?.[0];
            const showEmptyGadgetLoading = [ PAYLOAD_TOO_LARGE ].includes(errorCode);

            return {
                ...state,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [gadgetId]: {
                        ...state.gadgetsChartsData[gadgetId],
                        loading: false,
                        loaded: true,
                        needToUpdate: false,
                        updateType: null,
                        error: error?.response[0]?.message || error?.response?.message,
                        chartData: null,
                        showEmptyGadgetLoading,
                    },
                },
            };
        }

        case UPDATE_FILTER_FOR_TABLE: {
            const { gadgetId, filters } = action.payload;
            const gadgetsChartsData = state.gadgetsChartsData[gadgetId];

            return {
                ...state,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [gadgetId]: {
                        ...gadgetsChartsData,
                        chartData: {
                            ...gadgetsChartsData.chartData,
                            filters: {
                                ...gadgetsChartsData.chartData.filters,
                                ...filters,
                            },
                        },
                    },
                },
            };
        }

        case COPY_GADGET_REQUEST:
        case ADD_GADGET_REQUEST: {
            return {
                ...state,
                gadgetPending: action.params.pending,
            };
        }

        case SET_STATIC_AREA_DATA_REQUEST: {
            const { params, body } = action;

            return {
                ...state,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [params.gadgetId]: {
                        ...state.gadgetsChartsData[params.gadgetId],
                        chartData: body.data,
                    },
                },
            };
        }

        case COPY_GADGET_SUCCESS:
        case ADD_GADGET_SUCCESS: {
            return {
                ...state,
                loading: false,
                gadgetPending: false,
                dashboard: {
                    ...state.dashboard,
                    gadgets: [
                        ...state.dashboard.gadgets,
                        { ...action.response },
                    ],
                },
                ...state.isEditable
                    ? {
                        stateOfEdit: {
                            ...state.stateOfEdit,
                            gadgets: [
                                ...state.stateOfEdit.gadgets,
                                { ...action.response },
                            ],
                        },
                    }
                    : {},
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [action.response.id]: {
                        loading: false,
                        loaded: false,
                        needToUpdate: false,
                        updateType: null,
                        chartData: null,
                        gadgetData: action.response,
                    },
                },
            };
        }

        case SAVE_GADGET_IN_STORE:
        case CHANGE_POSITION_IN_STORE:
        case DELETE_GADGET_IN_STORE: {
            const { gadgetsChartsData, ...rest } = action.payload.model;

            return {
                ...state,
                stateOfEdit: {
                    ...state.stateOfEdit,
                    ...rest,
                },
                gadgetsChartsData: action.type === DELETE_GADGET_IN_STORE
                    ? gadgetsChartsData
                    : state.gadgetsChartsData,
            };
        }

        case UPDATE_COORDINATES: {
            const { pageId, coordinates } = action.payload.model;

            return {
                ...state,
                stateOfEdit: {
                    ...state.stateOfEdit,
                    pages: state.stateOfEdit.pages.map(page => {
                        return page.id === pageId
                            ? {
                                ...page,
                                coordinates,
                            }
                            : page;
                    }),
                },
            };
        }

        case EDIT_GADGET_TITLE_REQUEST: {
            const { params: { id } } = action;

            const gadget = { ...state.gadgetsChartsData[id].gadgetData };
            const updateField = state.isEditable ? 'stateOfEdit' : 'dashboard';

            const updatedGadgets = state[updateField].gadgets
                .map((gadget: IGadgetData) => gadget.id === id
                    ? {
                        ...gadget,
                        title: action.params.title,
                        isUseCustomTitle: action.body.isUseCustomTitle,
                    }
                    : gadget,
                );

            gadget.title = action.params.title;
            gadget.isUseCustomTitle = action.body.isUseCustomTitle;

            return {
                ...state,
                loading: false,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [id]: {
                        ...state.gadgetsChartsData[id],
                        loading: false,
                        gadgetData: gadget,
                    },
                },
                gadgetData: {
                    ...state.gadgetData,
                    title: action.params.title,
                    isUseCustomTitle: action.body.isUseCustomTitle,
                },
                dashboard: {
                    ...state.dashboard,
                    gadgets: updatedGadgets,
                },
                stateOfEdit: {
                    ...state.stateOfEdit,
                    gadgets: updatedGadgets,
                },
            };
        }

        case ADD_GADGET_ERROR: {
            return {
                ...state,
                loading: false,
                gadgetPending: false,
            };
        }

        case TOGGLE_IS_EDITABLE: {
            const { extraGadgets, extraPages, gadgets, pages, pagesOrder } = state.dashboard;

            // In the feature backend needs to delete coordinate if gadget will be removed
            const filterPages = pages.map((page: IPages) => ({
                ...page,
                coordinates: page.coordinates
                    ?.filter?.(coordinate => gadgets
                        .some((gadget: IGadgetData) => gadget.id === coordinate.gadgetId),
                    ) || [],
            }));

            return {
                ...state,
                stateOfEdit: action.payload
                    ? JSON.parse(JSON.stringify(
                        {
                            ...getInitialState().stateOfEdit,
                            extraGadgets,
                            extraPages,
                            gadgets,
                            pages: filterPages,
                            pagesOrder,
                        },
                    ))
                    : { ...getInitialState().stateOfEdit },
                isEditable: action.payload,
            };
        }

        case SAVE_CHANGES_SUCCESS: {
            const { deleted } = action.data;

            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    ...action.params,
                    gadgets: state.dashboard.gadgets.filter((gadget: IGadgetData) => !deleted?.gadgets?.includes(gadget.id)),
                },
                stateOfEdit: { ...getInitialState().stateOfEdit },
                isEditable: false,
            };
        }

        case GET_VISUAL_SOURCE_REQUEST: {
            return {
                ...state,
                dataSource: {
                    ...state.dataSource,
                    data: state.dataSource.data,
                    loading: true,
                },
            };
        }

        case GET_VISUAL_SOURCE_SUCCESS: {
            return {
                ...state,
                dataSource: {
                    ...state.dataSource,
                    data: [
                        ...state.dataSource.data,
                        ...action.response.data,
                    ],
                    count: action.response.count,
                    loading: false,
                },
            };
        }

        case GET_VISUAL_SOURCE_ERROR: {
            return {
                ...state,
                dataSource: {
                    ...state.dataSource,
                    data: [],
                    loading: false,
                },
            };
        }

        case ADD_PAGE_SUCCESS: {
            const { moveToPageIndex } = action.params;

            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    ...DashboardService.addNewPage({
                        moveToPageIndex,
                        storePart: state.dashboard,
                        newPage: action.response,
                    }),
                },
                ...state.isEditable
                    ? {
                        stateOfEdit: {
                            ...state.stateOfEdit,
                            ...DashboardService.addNewPage({
                                moveToPageIndex,
                                storePart: state.stateOfEdit,
                                newPage: action.response,
                            }),
                            scrollTo: {
                                ...state.stateOfEdit.scrollTo,
                                scrollToPage: action.response.id,
                            },
                        },
                    }
                    : {},
            };
        }

        case DELETE_PAGE_IN_STORE: {
            const { id: deletedPageId } = action.payload;

            return {
                ...state,
                stateOfEdit: {
                    ...state.stateOfEdit,
                    ...DashboardService.deletePageInStore({
                        deletedPageId,
                        storePart: state.stateOfEdit,
                    }),
                    changed: {
                        ...state.stateOfEdit.changed,
                        page: state.stateOfEdit.changed.page.filter((p: string) => p !== deletedPageId),
                        deletedPages: [ ...state.stateOfEdit.changed.deletedPages, deletedPageId ],
                    },
                },
            };
        }

        case MOVE_PAGE_TO: {
            const { pageIndex, newPageIndex, pageId } = action.payload;

            return {
                ...state,
                stateOfEdit: {
                    ...state.stateOfEdit,
                    pagesOrder: arrayMove(state.stateOfEdit.pagesOrder, pageIndex, newPageIndex),
                    changed: {
                        ...state.stateOfEdit.changed,
                        isPageOrderChanged: true,
                    },
                    scrollTo: {
                        ...state.stateOfEdit.scrollTo,
                        scrollToPage: pageId,
                    },
                },
            };
        }

        case DATA_SELECT_SET_FILTERS: {
            return {
                ...state,
                dataSource: {
                    data: [],
                    count: 0,
                    loading: false,
                    filters: {
                        ...state.dataSource.filters,
                        ...action.payload,
                    },
                },
            };
        }

        case GET_ACTIVE_SOURCE_REQUEST: {
            return {
                ...state,
                activeSource: {
                    ...state.activeSource,
                    loading: true,
                    data: {},
                },
            };
        }

        case CHANGE_ACTIVE_SOURCE_ID: {
            const activeSourceData = action.payload ? { id: action.payload } : {};

            return {
                ...state,
                activeSource: {
                    loading: false,
                    loaded: false,
                    data: activeSourceData,
                },
            };
        }

        case GET_ACTIVE_SOURCE_SUCCESS: {
            const data = action.payload || action.response;

            return {
                ...state,
                activeSource: {
                    loading: false,
                    loaded: true,
                    data,
                },
            };
        }

        case GET_ACTIVE_SOURCE_ERROR: {
            return {
                ...state,
                activeSource: {
                    loading: false,
                    loaded: false,
                    data: {},
                },
            };
        }

        case DATA_SELECT_SET_PAGE: {
            return {
                ...state,
                dataSource: {
                    ...state.dataSource,
                    filters: {
                        ...state.dataSource.filters,
                        page: action.page,
                    },
                },
            };
        }

        case SET_CONTENT_SETTING_REQUEST:
        case SET_DATA_SETTING_REQUEST: {
            const typeSettings = action.type === SET_CONTENT_SETTING_REQUEST
                ? 'contentSettings'
                : 'dataSettings';

            const updateGadgetInField = (field: 'stateOfEdit' | 'dashboard') => {
                state[field].gadgets.map((gadget: IGadgetData) => {
                    if (gadget.id === action.params.gadgetId) {
                        gadget[typeSettings] = action.body;
                    }

                    return gadget;
                });
            };

            updateGadgetInField(
                state.isEditable
                    ? 'stateOfEdit' // update information for staging box
                    : 'dashboard', // update information for dashboard (for future staging box)
            );

            return {
                ...state,
                gadgetData: {
                    ...state.gadgetData,
                    [typeSettings]: action.body,
                },
                ...!action.params.onlyGadgetData
                    ? {
                        gadgetsChartsData: {
                            ...state.gadgetsChartsData,
                            [action.params.gadgetId]: {
                                ...state.gadgetsChartsData[action.params.gadgetId],
                                gadgetData: {
                                    ...state.gadgetsChartsData[action.params.gadgetId].gadgetData,
                                    [typeSettings]: action.body,
                                },
                            },
                        },
                    }
                    : {},
            };
        }

        case SEND_CHART_COLOR_REQUEST: {
            const { colors } = state.gadgetsChartsData[action.params.id].gadgetData;
            const findColorIndex = GadgetService.getSavedColorIndex(action.body.key, colors);

            if(findColorIndex !== -1) {
                colors[findColorIndex].value = action.body.value;
            } else {
                colors.push(action.body);
            }

            return {
                ...state,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [action.params.id]: {
                        ...state.gadgetsChartsData[action.params.id],
                        gadgetData: {
                            ...state.gadgetsChartsData[action.params.id].gadgetData,
                            colors,
                        },
                    },
                },
            };
        }

        case SEND_BAR_LABEL_REQUEST: {
            const { params: { id } } = action;

            const gadget = { ...state.gadgetsChartsData[id].gadgetData };

            const findLabelIndex = gadget?.labels
                .findIndex((labelData: TLabelData) => labelData.key === action.body.key && labelData.type === action.body.type);

            if(findLabelIndex > -1) {
                gadget.labels[findLabelIndex].value = action.body.value;
            } else {
                gadget.labels.push(action.body);
            }

            return {
                ...state,
                gadgetsChartsData: {
                    ...state.gadgetsChartsData,
                    [id]: {
                        ...state.gadgetsChartsData[id],
                        loading: false,
                        gadgetData: gadget,
                    },
                },
            };
        }

        case TOGGLE_PROCESSING_DASHBOARD_FILTER: {
            return {
                ...state,
                dashboardFilter: {
                    ...state.dashboardFilter,
                    loading: action.payload,
                },
            };
        }

        case GET_DASHBOARD_FILTER_SOURCE_SUCCESS: {
            return {
                ...state,
                dashboardFilter: {
                    ...state.dashboardFilter,
                    dataSource: action.response,
                    activeSourceId: action.response[0]?.id,
                },
            };
        }

        case GET_DASHBOARD_FILTER_SUCCESS: {
            return {
                ...state,
                dashboardFilter: {
                    ...state.dashboardFilter,
                    dataFilter: action.response,
                },
            };
        }

        case SEND_DASHBOARD_FILTER_REQUEST: {
            return {
                ...state,
                dashboardFilter: {
                    ...state.dashboardFilter,
                    loading: true,
                },
            };
        }

        case SEND_DASHBOARD_FILTER_SUCCESS: {
            return {
                ...state,
                dashboardFilter: {
                    ...state.dashboardFilter,
                    loading: false,
                },
            };
        }

        case SET_DASHBOARD_FILTER_ACTIVE_SOURCE: {
            return {
                ...state,
                dashboardFilter: {
                    ...state.dashboardFilter,
                    activeSourceId: action.payload,
                },
            };
        }

        case TOGGLE_MODAL_VISUAL: {
            return {
                ...state,
                modalState: action.payload,
            };
        }

        case APPLY_GADGET_DATA_CHANGE: {
            const { gadgetData } = action;
            const { gadgetsChartsData } = state;

            const gadgetsWithUpdatedOne = state.dashboard.gadgets.map((gadget: IGadgetData) =>
                gadget.id === state.gadgetData?.id
                    ? { ...gadgetData }
                    : gadget,
            );

            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    gadgets: gadgetsWithUpdatedOne,
                },
                gadgetsChartsData: {
                    ...gadgetsChartsData,
                    [gadgetData.id]: {
                        ...gadgetsChartsData[gadgetData.id],
                        gadgetData: { ...gadgetData },
                        loading: true,
                    },
                },
            };
        }

        case MANAGE_GADGET_CLIPBOARD: {
            return {
                ...state,
                gadgetClipboard: action.payload,
            };
        }

        case START_SMOOTH_SCROLLING: {
            const { scrollToGadget, scrollToPage } = state.stateOfEdit.scrollTo;

            return {
                ...state,
                stateOfEdit: {
                    ...state.stateOfEdit,
                    scrollTo: {
                        ...state.stateOfEdit.scrollTo,
                        // Reset scrollToPage if needed to scroll the gadget into view
                        scrollToPage: scrollToGadget ? null : scrollToPage,
                        startSmoothScrolling: true,
                    },
                },
            };
        }

        case SET_SCROLL_TO_GADGET: {
            return {
                ...state,
                stateOfEdit: {
                    ...state.stateOfEdit,
                    scrollTo: {
                        ...state.stateOfEdit.scrollTo,
                        scrollToGadget: action.payload,
                        startSmoothScrolling: false,
                        noResetOnLocationChange: true,
                    },
                },
            };
        }

        case SET_SCROLL_TO_PAGE: {
            return {
                ...state,
                stateOfEdit: {
                    ...state.stateOfEdit,
                    scrollTo: {
                        ...state.stateOfEdit.scrollTo,
                        scrollToPage: action.payload,
                        startSmoothScrolling: false,
                        noResetOnLocationChange: true,
                    },
                },
            };
        }

        case RESET_SCROLL_INTO_VIEW: {
            return {
                ...state,
                stateOfEdit: {
                    ...state.stateOfEdit,
                    scrollTo: {
                        scrollToGadget: null,
                        scrollToPage: null,
                        startSmoothScrolling: false,
                        noResetOnLocationChange: false,
                    },
                },
            };
        }

        default: {
            return state;
        }
    }
}

export function cleanup() {
    return {
        type: CLEANUP_DASHBOARD_PAGE,
    };
}

export function cleanupConfigureModal() {
    return {
        type: CLEANUP_CONFIGURE_MODAL,
    };
}

export function updateDashboardData(dashboard: any) {
    return {
        type: UPDATE_DASHBOARD_DATA,
        dashboard,
    };
}

export function getGadgetData(gadgetId: any, params: any) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${gadgetId}/data`,
            method: 'GET',
            types: [ GET_GADGET_DATA_REQUEST, GET_GADGET_DATA_SUCCESS, GET_GADGET_DATA_ERROR ],
            logger: false,
            body: params,
            params: {
                gadgetId,
            },
        },
    };
}

export function updateFilter(gadgetId: any, filters: any) {
    return {
        type: UPDATE_FILTER_FOR_TABLE,
        payload: {
            gadgetId,
            filters,
        },
    };
}

export function onSyncChart(gadgetData: any) {
    return {
        type: ON_SYNC_CHART,
        payload: { gadgetData },
    };
}

export function markGadgetsForUpgrade({
    gadgetsId,
    updateType = EUpdateGadgetTypes.SOCKET_UPDATE,
}: IMarkGadgetsForUpgrade) {
    return {
        type: MARK_GADGETS_FOR_UPGRADE,
        payload: { gadgetsId, updateType },
    };
}

export function updateGadgetsListForActiveDashboard() {
    return async (dispatch: TMarkGadgetsForUpgradeDispatch, getState: TGetVisualStore) => {
        const { dashboard, gadgetsChartsData } = getState().VocVisual.dashboard;

        if (dashboard?.id) {
            let gadgetsIdForUpdate: string[] = [];

            if (dashboard.extraGadgets) {
                gadgetsIdForUpdate = Object.keys(dashboard.extraGadgets)
                    .filter(gadgetId => {
                        const { loaded, gadgetData } = gadgetsChartsData[gadgetId] || {};

                        return loaded && gadgetData.type !== EGadgetType.STATIC_AREA;
                    });
            }

            if (dashboard.parentGadget) {
                gadgetsIdForUpdate.push(dashboard.parentGadget.id);
            }

            dispatch(markGadgetsForUpgrade({
                gadgetsId: gadgetsIdForUpdate,
                updateType: EUpdateGadgetTypes.MANUAL_UPDATE,
            }));
        }
    };
}

export function updateGadgetData(gadgetData: any, params: any = {}) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${gadgetData.id}/data`,
            method: 'GET',
            types: [ SOCKET_UPDATE_CHART_DATA_REQUEST, SOCKET_UPDATE_CHART_DATA_SUCCESS, SOCKET_UPDATE_CHART_DATA_ERROR ],
            logger: false,
            body: params,
            params: {
                gadgetId: gadgetData.id,
            },
        },
    };
}

export function toggleDashboardFavorite(dashboardId: string, isFavorite: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/favourite`,
            method: isFavorite ? 'DELETE' : 'POST',
            types: [
                TOGGLE_FAVOURITE_IN_DASHBOARD_REQUEST,
                TOGGLE_FAVOURITE_IN_DASHBOARD_SUCCESS,
                TOGGLE_FAVOURITE_IN_DASHBOARD_ERROR,
            ],
            logger: true,
            params: { dashboardId },
        },
    };
}

export function editGadgetTitle(id: string, title: string, isUseCustomTitle: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${id}`,
            method: 'PUT',
            types: [ EDIT_GADGET_TITLE_REQUEST, EDIT_GADGET_TITLE_SUCCESS, EDIT_GADGET_TITLE_ERROR ],
            logger: true,
            body: {
                isUseCustomTitle,
                title,
            },
            params: {
                title,
                id,
            },
        },
    };
}

export function reloadGadget(filters: null | object, gadgetData: any) {
    return async (dispatch: any) => {
        const { contentSettings: { chartType, saveTableLayout, rowsPerPage } } = gadgetData;
        let model = {};

        if(chartType === 'custom_columns_table') {
            model = filters
                ? filters
                : saveTableLayout && rowsPerPage
                    ? {
                        ...InitialTableFilters,
                        limit: rowsPerPage,
                    }
                    : InitialTableFilters;
        }

        return dispatch(getGadgetData(gadgetData.id, model));
    };
}

export function reloadAllGadgets() {
    return async (dispatch: any, getState: any) => {
        const { gadgetsChartsData } = getState().VocVisual.dashboard;

        Object.keys(gadgetsChartsData)
            .filter(gadgetId => gadgetsChartsData[gadgetId].loaded && gadgetsChartsData[gadgetId].gadgetData.type !== EGadgetType.STATIC_AREA)
            .forEach(gadgetId => dispatch(reloadGadget(null, gadgetsChartsData[gadgetId].gadgetData)));
    };
}

export function getDashboardHandler(id: string, isCreated?: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${id}/extended`,
            method: 'GET',
            types: [ GET_DASHBOARD_REQUEST, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_ERROR ],
            redirect,
            logger: true,
            params: { isCreated },
        },
    };
}

export function getDashboard(id: string) {
    return (dispatch: any, getState: any) => {
        const { isCreated } = getState().VocVisual.common;

        isCreated && dispatch(toggleIsCreated());

        return dispatch(getDashboardHandler(id, isCreated));
    };
}

export function getDashboardForPrint(id: string, page: string) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${id}/extended`,
            method: 'GET',
            types: [ GET_DASHBOARD_FOR_PRINT_REQUEST, GET_DASHBOARD_FOR_PRINT_SUCCESS, GET_DASHBOARD_FOR_PRINT_ERROR ],
            logger: true,
            redirect,
            body: {
                page,
            },
        },
    };
}

export function getSources(filters: any) {
    return {
        [CALL_API]: {
            endpoint: '/api/visual/dataSource/uploads-new',
            method: 'GET',
            contentType: 'application/json',
            unique: true,
            types: [
                GET_VISUAL_SOURCE_REQUEST,
                GET_VISUAL_SOURCE_SUCCESS,
                GET_VISUAL_SOURCE_ERROR,
            ],
            body: {
                ...filters,
                with_virtual_column: true,
            },
        },
    };
}

export function setFilters(filters: IFilters) {
    return {
        type: DATA_SELECT_SET_FILTERS,
        payload: filters,
    };
}

export function setPage(page: number) {
    return {
        type: DATA_SELECT_SET_PAGE,
        page,
    };
}

export function changeStaticAreaData(gadgetId: string, contentStr: string) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${gadgetId}/static-area`,
            method: 'PUT',
            types: [ SET_STATIC_AREA_DATA_REQUEST, SET_STATIC_AREA_DATA_SUCCESS, SET_STATIC_AREA_DATA_ERROR ],
            logger: true,
            body: { data: contentStr },
            params: { gadgetId },
        },
    };
}

export function setGadgetData(id: string) {
    return {
        type: SET_GADGET_DATA,
        payload: id,
    };
}

export function setContentSettings(gadgetId: string, data: any, onlyGadgetData?: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${gadgetId}/contentSettings`,
            method: 'PUT',
            types: [ SET_CONTENT_SETTING_REQUEST, SET_CONTENT_SETTING_SUCCESS, SET_CONTENT_SETTING_ERROR ],
            logger: true,
            body: data,
            params: {
                gadgetId,
                onlyGadgetData,
            },
        },
    };
}

export function setTickOrderContentSettings(gadgetId: string, tickOrder: string[]) {
    return (dispatch: any, getState: any) => {
        const { gadgets } = getState().VocVisual.dashboard.dashboard;
        const { contentSettings } = gadgets.find(({ id }: { id: string }) => gadgetId === id);

        // tick order from settings may be null
        // in this case, need to save the order on the server as well
        const isSame = contentSettings.tickOrder?.length === tickOrder.length
            && contentSettings.tickOrder.every((item: string, index: number) => item === tickOrder[index] );

        if (!isSame) {
            return dispatch(setContentSettings(gadgetId, {
                ...contentSettings,
                tickOrder,
            }));
        }
    };
}

export function setDataSettings(gadgetId: string, data: any, onlyGadgetData?: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${gadgetId}/dataSettings`,
            method: 'PUT',
            types: [ SET_DATA_SETTING_REQUEST, SET_DATA_SETTING_SUCCESS, SET_DATA_SETTING_ERROR ],
            logger: true,
            body: data,
            params: {
                gadgetId,
                onlyGadgetData,
            },
        },
    };
}

export function getActiveSource(sourceId: string) {
    return {
        [CALL_API]: {
            endpoint: `/api/visual/dataSource/uploads/${sourceId}`,
            method: 'GET',
            types: [ GET_ACTIVE_SOURCE_REQUEST, GET_ACTIVE_SOURCE_SUCCESS, GET_ACTIVE_SOURCE_ERROR ],
            logger: true,
        },
    };
}

export function changeActiveSourceId(sourceId: string | null) {
    return {
        type: CHANGE_ACTIVE_SOURCE_ID,
        payload: sourceId,
    };
}

export function applyGadgetDataChangeImmediately(gadgetData: any) {
    return {
        type: APPLY_GADGET_DATA_CHANGE,
        gadgetData,
    };
}

export function applyGadgetDataChange(gadgetData: any) {
    return async (dispatch: any) => {
        // set gadget title
        if (!gadgetData.isUseCustomTitle) {
            dispatch(editGadgetTitle(gadgetData.id, gadgetData.title, false));
        }
        // set gadget state for client side
        dispatch(applyGadgetDataChangeImmediately(gadgetData));
        // save dataSettings and contentSettings
        await dispatch(setDataSettings(gadgetData.id, gadgetData.dataSettings, true)).promise;
        await dispatch(setContentSettings(gadgetData.id, gadgetData.contentSettings, true)).promise;
        // get actual data of gadget
        await dispatch(reloadGadget(null, gadgetData));
    };
}

export function getDashboardFilterSource(dashboardId: string) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/sources`,
            method: 'GET',
            contentType: 'application/json',
            unique: true,
            logger: true,
            types: [
                GET_DASHBOARD_FILTER_SOURCE_REQUEST,
                GET_DASHBOARD_FILTER_SOURCE_SUCCESS,
                GET_DASHBOARD_FILTER_SOURCE_ERROR,
            ],
        },
    };
}

export function getDashboardFilter(dashboardId: string) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/dataFilter`,
            method: 'GET',
            contentType: 'application/json',
            unique: true,
            logger: true,
            types: [
                GET_DASHBOARD_FILTER_REQUEST,
                GET_DASHBOARD_FILTER_SUCCESS,
                GET_DASHBOARD_FILTER_ERROR,
            ],
        },
    };
}

export function toggleProcessing(loadingState: boolean) {
    return {
        type: TOGGLE_PROCESSING_DASHBOARD_FILTER,
        payload: loadingState,
    };
}

export function getDashboardFilterData(dashboardId: string) {
    return async (dispatch: any) => {
        dispatch(toggleProcessing(true));

        const requests = Promise.all([
            dispatch(getDashboardFilterSource(dashboardId)).promise,
            dispatch(getDashboardFilter(dashboardId)).promise,
        ]);

        const response = await requests;

        dispatch(toggleProcessing(false));

        return response;
    };
}

export function changeDashboardFilterActiveSource(id: string) {
    return {
        type: SET_DASHBOARD_FILTER_ACTIVE_SOURCE,
        payload: id,
    };
}

export function sendDashboardFilter(dashboardId: string, filter: any) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/dataFilter`,
            method: 'PUT',
            contentType: 'application/json',
            unique: true,
            types: [
                SEND_DASHBOARD_FILTER_REQUEST,
                SEND_DASHBOARD_FILTER_SUCCESS,
                SEND_DASHBOARD_FILTER_ERROR,
            ],
            body: filter,
        },
    };
}

export function sendChartColor(gadgetId: string, colors: any, change: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${gadgetId}/colors`,
            method: change ? 'PUT' : 'POST',
            contentType: 'application/json',
            unique: true,
            types: [
                SEND_CHART_COLOR_REQUEST,
                SEND_CHART_COLOR_SUCCESS,
                SEND_CHART_COLOR_ERROR,
            ],
            body: colors,
            params: {
                id: gadgetId,
            },
        },
    };
}

export function sendLabel(gadgetId: string, labelData: TLabelData, change: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${gadgetId}/labels${change ? '' : '/'}`,
            method: change ? 'PUT' : 'POST',
            contentType: 'application/json',
            unique: true,
            types: [
                SEND_BAR_LABEL_REQUEST,
                SEND_BAR_LABEL_SUCCESS,
                SEND_BAR_LABEL_ERROR,
            ],
            body: labelData,
            params: {
                id: gadgetId,
            },
        },
    };
}

export function toggleModal(params: any) {
    return {
        type: TOGGLE_MODAL_VISUAL,
        payload: params,
    };
}

export function toggleIsEditable(isEditable: boolean) {
    return {
        type: TOGGLE_IS_EDITABLE,
        payload: isEditable,
    };
}

/* action */
function addGadgetRequest(id: string, type: string, pending?: boolean) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${id}/gadgets`,
            method: 'POST',
            types: [ ADD_GADGET_REQUEST, ADD_GADGET_SUCCESS, ADD_GADGET_ERROR ],
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyCreated', { entity: t('gadget') }),
                error: t('messages.failedCreated', { entity: t('gadget') }),
            },
            body: {
                type,
            },
            params: { id, pending },
        },
    };
}

export function createPageRequest(id: string, isEditable: boolean, moveToPageIndex?: number) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${id}/page`,
            method: 'POST',
            types: [ ADD_PAGE_REQUEST, ADD_PAGE_SUCCESS, ADD_PAGE_ERROR ],
            logger: true,
            loggerMessages: {
                success: isEditable ? t('messages.successfullyCreated', { entity: t('page') }) : '',
                error: t('messages.failedCreated', { entity: t('page') }),
            },
            params: { moveToPageIndex },
        },
    };
}

export function deletePageInStore(id: string) {
    return {
        type: DELETE_PAGE_IN_STORE,
        payload: { id },
    };
}

export function createPage(id: string, moveToPageIndex?: number) {
    return async (dispatch: any, getState: any) => {
        const { isEditable } = getState().VocVisual.dashboard;

        return dispatch(createPageRequest(id, isEditable, moveToPageIndex));
    };
}

export function movePageTo({ pageIndex, newPageIndex, pageId }: IMovePageToAction) {
    return {
        type: MOVE_PAGE_TO,
        payload: { pageIndex, newPageIndex, pageId },
    };
}

function changePositionInStore(model: object) {
    return {
        type: CHANGE_POSITION_IN_STORE,
        payload: { model },
    };
}

function moveToSaveInStore(model: object) {
    return {
        type: SAVE_GADGET_IN_STORE,
        payload: { model },
    };
}

export function updateCoordinates(model: object) {
    return {
        type: UPDATE_COORDINATES,
        payload: { model },
    };
}

function deleteGadgetInStore(model: object) {
    return {
        type: DELETE_GADGET_IN_STORE,
        payload: { model },
    };
}

function copyGadgetRequest({ id, message, pending }) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${id}/copy`,
            method: 'POST',
            types: [ COPY_GADGET_REQUEST, COPY_GADGET_SUCCESS, COPY_GADGET_ERROR ],
            params: { pending },
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyCopiedAnd', { entity: t('gadget'), message }),
                error: t('messages.failedCopied', { entity: t('gadget') }),
            },
        },
    };
}

export function persistSelectedGroupFilter(gadgetId: string, model: any) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/gadgets/v2/${gadgetId}/filters/selectedGroup`,
            method: 'PUT',
            contentType: 'application/json',
            unique: true,
            types: [
                PERSIST_SELECTED_GROUP_REQUEST,
                PERSIST_SELECTED_GROUP_SUCCESS,
                PERSIST_SELECTED_GROUP_ERROR,
            ],
            logger: true,
            body: model,
        },
    };
}
/* end actions */

/* changes: action creators */
export function saveChangesRequest(dashboardId: string, model: any, forStore: any) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/save-edit-mode`,
            method: 'PUT',
            contentType: 'application/json',
            unique: true,
            types: [
                SAVE_CHANGES_REQUEST,
                SAVE_CHANGES_SUCCESS,
                SAVE_CHANGES_ERROR,
            ],
            body: model,
            params: forStore,
        },
    };
}

export function exportToFormat(dashboardId: string, format: EExportFormats) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/print?format=${format}`,
            method: 'GET',
            contentType: 'application/json',
            unique: true,
            types: [
                INITIATE_PRINT_REQUEST,
                INITIATE_PRINT_SUCCESS,
                INITIATE_PRINT_ERROR,
            ],
            logger: true,
            loggerMessageIsPrior: true,
            loggerMessages: {
                success: t('messages.exportSuccessfullyInitiated', { format }),
                error: t('messages.exportFailedInitiated', { format }),
            },
        },
    };
}

export function cutGadget(data: object): unknown {
    return async (dispatch: any, getState: any) => {
        const { pages, changed } = getState().VocVisual.dashboard.stateOfEdit;
        const pagesId = Object.keys(data);

        const modelForStore = {
            pages: DashboardGridService.changePageCoordinate(pages, data),
            changed: {
                ...changed,
                page: [ ...changed?.page, ...pagesId ],
            },
        };

        return dispatch(changePositionInStore(modelForStore));
    };
}

export function saveChanges() {
    return async (dispatch: any, getState: any) => {
        const { stateOfEdit: { changed, ...rest }, dashboard } = getState().VocVisual.dashboard;
        const { deleteGadget, page, deletedPages } = changed;
        const uniquePageId = [ ...new Set(page) ];

        const model: saveChangesModelType = {
            deleted: {
                gadgets: deleteGadget.length ? deleteGadget : [],
                pages: deletedPages.length ? deletedPages : [],
            },
            pagesOrder: rest.pagesOrder,
            pages: uniquePageId.length
                ? uniquePageId.reduce((acc, pageId) => ({
                    ...acc,
                    [pageId]: {
                        id: pageId,
                        coordinates: rest.pages
                            .find(({ id }: { id: string }) => pageId === id).coordinates,
                    },
                }), {})
                : null,
        };

        return dispatch(saveChangesRequest(dashboard.id, model, rest));
    };
}

export function deleteGadget(id: string, gadgetType: string | null) {
    return async (dispatch: any, getState: any) => {
        const {
            stateOfEdit: {
                extraGadgets,
                gadgets,
                pages,
                changed,
            },
            gadgetsChartsData,
        } = getState().VocVisual.dashboard;

        let modelForStore: any = {
            gadgets: gadgets.filter((gadget: IGadgetData) => gadget.id !== id),
            changed: { ...changed, deleteGadget: [ ...changed?.deleteGadget, id ] },
            gadgetsChartsData: Object
                .entries(gadgetsChartsData)
                .reduce((acc, [ key, value ]) => {
                    return key !== id
                        ? { ...acc, [key]: value }
                        : acc;
                }, {}),
        };

        if(!gadgetType) {
            delete extraGadgets[id];

            modelForStore = {
                ...modelForStore,
                pages: DashboardGridService.removePagesCoordinate(pages, id),
                extraGadgets,
            };
        }

        return dispatch(deleteGadgetInStore(modelForStore));
    };
}

export function moveToSave(id: string, pageId: string) {
    return async (dispatch: any, getState: any) => {
        const { extraGadgets, pages, changed } = getState().VocVisual.dashboard.stateOfEdit;

        delete extraGadgets[id];

        const modelForStore: any = {
            pages: DashboardGridService.removePagesCoordinateWithId(pages, pageId, id),
            extraGadgets,
            changed: {
                ...changed,
                page: [ ...changed?.page, pageId ],
            },
        };

        return dispatch(moveToSaveInStore(modelForStore));
    };
}

export function changeGadgetsPosition({ page, coordinates }: any): unknown {
    return async (dispatch: any, getState: any) => {
        const { pages, changed = {} } = getState().VocVisual.dashboard.stateOfEdit;

        const pageCoordinate = pages
            .find(({ id }) => id === page)
            ?.coordinates
            ?.map(({ gadgetId }) => coordinates[gadgetId]);

        return dispatch(changePositionInStore({
            pages: pages.map(pageEl => {
                return pageEl.id === page
                    ? { ...pageEl, coordinates: [ ...pageCoordinate ] }
                    : pageEl;
            }),
            changed: {
                ...changed,
                page: [ ...changed?.page, page ],
            },
        }));
    };
}

export function dropGadget({ page, coordinates }: { page: any, coordinates: Record<string, TGadgetCoordinates> }): unknown {
    return async (dispatch: any, getState: any) => {
        const { pages, extraGadgets, gadgets, changed } = getState().VocVisual.dashboard.stateOfEdit;

        const idGadgets: string[] = Object.keys(coordinates);
        const pageCoordinate = pages.find(({ id }: { id: string }) => id === page)?.coordinates;

        const newIdGadget: string = <string>idGadgets.find(
            id => !pageCoordinate?.some(
                ({ gadgetId }: { gadgetId: string }) => gadgetId === id,
            ),
        );

        const newCoordinates = idGadgets.map(key => coordinates[key]);

        return dispatch(changePositionInStore({
            extraGadgets: {
                ...extraGadgets,
                [newIdGadget]: gadgets.find(({ id }: { id: string }) => id === newIdGadget),
            },
            pages: pages.map((pageEl: IPages) => {
                return pageEl.id === page
                    ? { ...pageEl, coordinates: [ ...newCoordinates ] }
                    : pageEl;
            }),
            changed: {
                ...changed,
                page: [ ...changed?.page, page ],
            },
        }));
    };
}

function saveCoordinate(gadget, { pageId, coordinate }) {
    return (dispatch: any, getState: any) => {
        const { pages } = getState().VocVisual.dashboard.stateOfEdit;
        const { coordinates } = pages.find(page => page.id === pageId);

        let newCoordinates = coordinates.reduce((accum, { gadgetId, ...rest }) =>
            DashboardGridService.accumulateCoordinates(accum, gadgetId, rest), {});

        newCoordinates = DashboardGridService.accumulateCoordinates(newCoordinates, gadget.id, coordinate);

        dispatch(dropGadget({ page: pageId, coordinates: newCoordinates }));
    };
}

export function addGadget({ id, coordinate, pageId, type }: INewGadgetAction & { type: string }) {
    return async (dispatch: any): Promise<IGadgetData> => {
        const response = await dispatch(addGadgetRequest(id, type, !coordinate)).promise;

        coordinate
        && dispatch(saveCoordinate(response, { coordinate, pageId }));

        return response;
    };
}

export function copyGadget({ id, message, coordinate, pageId }: INewGadgetAction & { message: string }) {
    return async (dispatch: any) => {
        return dispatch(copyGadgetRequest({ id, message, pending: !coordinate }))
            .promise
            .then(response => {
                coordinate && dispatch(saveCoordinate(response, { coordinate, pageId }));
            });
    };
}
/* end */

export function manageGadgetClipboard(payload = null) {
    return {
        type: MANAGE_GADGET_CLIPBOARD,
        payload,
    };
}

export function setScrollTo({ gadgetId, pageId }: TSetScrollTo) {
    const actionType = gadgetId ? SET_SCROLL_TO_GADGET : SET_SCROLL_TO_PAGE;

    return {
        type: actionType,
        payload: gadgetId || pageId,
    };
}
