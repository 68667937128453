import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { QueriesTable, AddQueryModal } from './components';
import { ChangeNameModal } from '/mine/components';

import {
    updateSourceFilters, getQueryTitles, getQuerySources,
    getQueriesList, createNewQuery, updateFilters,
    setSelected, deleteQuery, getSources,
    cleanup, resetFilters, saveNewName,
} from './modules/queriesList.modules';
import {
    filtersSelector, tableDataSelector, getSourcesSelector,
    tableSettingsSelector, getFiltersBySourceSelector, textColumnSelector,
    languagesSelector, queryTagsSelector, queryTitlesSelector,
    querySourcesSelector, queryDateSelector,
} from './modules/queriesList.selectors';
import { getQueryColumnById, getTags } from '/mine/modules/vocMine.modules';
import { getQueryTextColumnSelector, getEditedQueryContextSelector } from '/mine/modules/vocMine.selectors';
import { saveMineRights } from '/modules';
import { isSupportUserSelector } from "/modules/selectors";
import { VocCleaupHOC } from '/components';
import { PageRequests } from '/services';
import { Card, CardBody } from 'reactstrap';

const mapStateToProps = state => ({
    filters: filtersSelector(state),
    tableData: tableDataSelector(state),
    addQueryModalData: getSourcesSelector(state),
    tableState: tableSettingsSelector(state),
    sourcesFilters: getFiltersBySourceSelector(state),
    textOptions: textColumnSelector(state),
    languageOptions: languagesSelector(state),
    queryTags: queryTagsSelector(state),
    queryTitles: queryTitlesSelector(state),
    querySources: querySourcesSelector(state),
    queryDates: queryDateSelector(state),
    textColumn: getQueryTextColumnSelector(state),
    isSupportUser: isSupportUserSelector(state),
    editedQueryContext: getEditedQueryContextSelector(state),
});

const mapDispatchToProps = {
    updateSourceFilters, createNewQuery, getQueriesList,
    getQueryTitles, getQuerySources, updateFilters,
    setSelected, showConfirm, deleteQuery, saveNewName,
    getSources, getTags, cleanup, resetFilters, saveMineRights,
    getQueryColumnById,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class QueriesList extends Component {
    pageRequests = new PageRequests();

    state = {
        isOpenPopoverFilter: false,
        openModalAddQuery: false,
        openModalChangeName: false,
        queryId: null,
        targetFilter: null,
        filterField: '',
    };

    componentDidMount() {
        this.requestTrigger();
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        const {
            extraFilters, sort_field, sort_order,
            limit, page, search,
        } = filters;
        const typeExtraFilters = [ 'created', 'updated' ];

        const model = {
            sort: sort_field ? { [sort_field]: sort_order } : null,
            limit: limit,
            page: page,
            search: search ? search : null,
            filters: Object.keys(extraFilters)
                .filter(filter => extraFilters[filter].length).reduce((accFilter, el) => {
                    return {
                        ...accFilter,
                        [el]: typeExtraFilters.includes(el)
                            ? {
                                from: extraFilters[el][0],
                                to: extraFilters[el][1],
                            }
                            : extraFilters[el].reduce((accValue, el) => {
                                return [
                                    ...accValue,
                                    el.value,
                                ];
                            }, []),
                    };
                }, {}),
        };

        this.pageRequests.cleanup();
        const request = this.props.getQueriesList(model).promise.then(() => this.props.getTags());

        this.pageRequests.addRequest(request);
    }

    @autobind
    createQuery(body) {
        return this.props.createNewQuery(body).promise
            .then(query => {
                this.closeModal();
                this.props.history.push(`/mine/query/${ query.id }/execute`);
            });
    }

    @autobind
    onOpenPopoverFilter(currentTarget, sort_field) {
        const { targetFilter } = this.state;

        if (!targetFilter || targetFilter !== currentTarget) {
            this.setState({
                targetFilter: currentTarget,
                isOpenPopoverFilter: true,
                filterField: sort_field,
            });
        }
    }

    @autobind
    togglePopover() {
        this.setState({
            targetFilter: null,
            isOpenPopoverFilter: false,
            filterField: '',
        });
    }

    @autobind
    openModalDelete(queryId) {
        const { showConfirm } = this.props;

        showConfirm({
            header: 'Please confirm',
            content: `Are you sure you want to delete this query?`,
            successCallback: () => this.deleteQuery(queryId),
        });
    }

    @autobind
    deleteQuery(surveyId) {
        const params = {
            ...this.props.filters,
            page: 1,
        };

        this.props.deleteQuery(surveyId).promise
            .then(() => {
                this.requestTrigger(params);
                this.props.updateFilters(params);
            });
    }

    @autobind
    closeModal() {
        this.setState({
            openModalAddQuery: false,
            openModalChangeName: false,
            queryId: null,
        });
    }

    @autobind
    openModal() {
        this.setState({
            openModalAddQuery: true,
        });
    }

    @autobind
    openChangeNameModal(id) {
        this.props.getQueryColumnById(id);
        this.setState({
            openModalChangeName: true,
            queryId: id,
        });
    }

    @autobind
    submitNewQueryName(body) {
        return this.props.saveNewName(body).promise.then(() => {
            this.requestTrigger();
        });
    }

    @autobind
    applyFilters({ name, value }) {
        this.props.setSelected({ name, value });

        const model = {
            ...this.props.filters,
            page: 1,
            extraFilters: {
                ...this.props.filters.extraFilters,
                [name]: value,
            },
        };

        this.requestTrigger(model);
        this.props.updateFilters(model);
    }

    @autobind
    resetFilters(name) {
        this.props.resetFilters(name);

        setTimeout(() => {
            const model = {
                ...this.props.filters,
                page: 1,
            };

            this.requestTrigger(model);
            this.props.updateFilters(model);
        }, 0);
    }

    @autobind
    getPropsForTable() {
        const {
            updateFilters, filters, tableData, tableState,
            getTags, queryTitles, querySources, queryTags,
            queryDates, getTableResetFilters,
            isSupportUser, getQueryTitles, getQuerySources,
            match: { url },
        } = this.props;

        const { targetFilter, filterField, isOpenPopoverFilter } = this.state;

        return {
            updateFilters, filters, tableData, targetFilter,
            tableState, filterField, getTags, queryTitles,
            querySources, queryTags, queryDates,
            url, getTableResetFilters, isSupportUser,
            isOpenPopoverFilter, getQueryTitles, getQuerySources,
        };
    }

    render() {
        const {
            tableData, getTags, sourcesFilters, getSources,
            updateSourceFilters, addQueryModalData, textOptions,
            languageOptions, queryTags, textColumn, editedQueryContext,
        } = this.props;

        const { openModalAddQuery, queryId, openModalChangeName } = this.state;

        return (
            <DocumentTitle title={ 'Mine :: Queries' }>
                <Card className={ 'page__wrapper page-wrapper__queries-list' }>
                    <CardBody>
                        <QueriesTable
                            requestTrigger={ this.requestTrigger }
                            togglePopover={ this.togglePopover }
                            openModalDelete={ this.openModalDelete }
                            openPopoverFilter={ this.onOpenPopoverFilter }
                            openModal={ this.openModal }
                            openChangeNameModal={ this.openChangeNameModal }
                            resetFilters={ this.resetFilters }
                            apply={ this.applyFilters }
                            { ...this.getPropsForTable() }
                        />
                    </CardBody>

                    <AddQueryModal
                        show={ openModalAddQuery }
                        sourcesFilters={ sourcesFilters }
                        getSources={ getSources }
                        updateFilters={ updateSourceFilters }
                        data={ addQueryModalData }
                        textOptions={ textOptions }
                        languageOptions={ languageOptions }
                        tags={ queryTags }
                        getTags={ getTags }
                        close={ this.closeModal }
                        createQuery={ this.createQuery }
                    />

                    <ChangeNameModal
                        show={ openModalChangeName }
                        queryId={ queryId }
                        queryList={ tableData }
                        initialContext={ editedQueryContext }
                        tags={ queryTags.options }
                        textColumn={ textColumn }
                        showTextColumn
                        showQueryContext
                        close={ this.closeModal }
                        onSubmit={ this.submitNewQueryName }
                    />
                </Card>

            </DocumentTitle>
        );
    }
}
