import { scaleOrdinal } from "d3";
import { HelperService } from "/services";

const d3ColorSchema = [
    '#1f77b4',
    '#aec7e8',
    '#ff7f0e',
    '#ffbb78',
    '#2ca02c',
    '#98df8a',
    '#d62728',
    '#ff9896',
    '#9467bd',
    '#c5b0d5',
    '#8c564b',
    '#c49c94',
    '#e377c2',
    '#f7b6d2',
    '#7f7f7f',
    '#c7c7c7',
    '#bcbd22',
    '#dbdb8d',
    '#17becf',
    '#9edae5',
];

export class RadarChartService {
    static getD3Color({ items, radarItemId, selectedColor }: { items: any[], radarItemId: string, selectedColor: string }) {
        const dataToSort = Array.from(items).map((x: any) => {
            return { id: x.id };
        });

        dataToSort.forEach((item, index) => {
            dataToSort[index] = JSON.stringify(item);
        });

        dataToSort.sort();

        const selectedColorGradation = selectedColor && HelperService.getColorGradation(selectedColor, items.length);
        const randomColorSet = dataToSort.map(scaleOrdinal(selectedColorGradation || d3ColorSchema));

        dataToSort.forEach((item, index) => {
            dataToSort[index] = JSON.parse(item);
        });

        const colorIndex = dataToSort.findIndex(x => x.id === radarItemId);

        return randomColorSet[colorIndex];
    }
}
