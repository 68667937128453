import React, { useState } from 'react';

import { EmptyData } from '/components';
import { VocTableFooter } from '/components/VocTableV2/components';
import { QueryChartTextItem } from './QueryChartTextItem';

import './QueryChartText.scss';

export const QueryChartText = ({
    changeFilter,
    filterForInclude,
    filterForExclude,
    idInclude,
    idExclude,
    textsData,
    updatePaginationFilters,
    requestByChart,
    name,
}) => {
    const [ isCollapse, handleCollapse ] = useState({ name: '' });

    const onToInclude = (selectedText, isAddOrDelete) =>
        changeFilter(selectedText, idInclude, filterForInclude, isAddOrDelete);

    const onToExclude = (selectedText, isAddOrDelete) =>
        changeFilter(selectedText, idExclude, filterForExclude, isAddOrDelete);

    const onCollapseChanged = name => {
        handleCollapse({ name: name });
    };

    const handlePaginationFilters = page => {
        updatePaginationFilters({ page, name });
        requestByChart({ pagination: { page } });
    };

    const handleLimitChange = limit => {
        updatePaginationFilters({ limit, name, page: 1 });
        requestByChart({ pagination: { limit, page: 1 } });
    };

    const renderContent = () => {
        return !textsData.texts.length && !isLoading
            ? <EmptyData errorText={ textsData.error }/>
            : textsData.texts.map(({ id, text, data_id, column_id }) =>
                <QueryChartTextItem
                    key={ id }
                    index={ id }
                    data_id={ data_id }
                    column_id={ column_id }
                    collapse={ isCollapse.name === id }
                    onCollapseChanged={ onCollapseChanged }
                    onToInclude={ onToInclude }
                    onToExclude={ onToExclude }
                    filterForInclude={ filterForInclude }
                    filterForExclude={ filterForExclude }
                    text={ text }
                />,
            );
    };

    const isLoading = textsData.textsLoading;
    const isNotEmpty = !!textsData.total;

    return (
        <>
            {
                !isNotEmpty && !isLoading
                    ? <div className='word-cloud__section'>
                        <EmptyData errorText={ textsData.error }/>
                    </div>
                    : isNotEmpty
                        ? <div className='query-details-text'>
                            {
                                renderContent()
                            }
                            {
                                textsData.total > 10
                                    ? <VocTableFooter
                                        total={ textsData.total }
                                        page={ textsData.filters.page }
                                        onPageChange={ handlePaginationFilters }
                                        onLimitChange={ handleLimitChange }
                                        limit={ textsData.filters.limit }
                                        goToPage={{
                                            show: true,
                                            closeOnChange: true,
                                        }}
                                    />
                                    : null
                            }
                        </div>
                        : null
            }
        </>
    );
};

QueryChartText.defaultProps = {
    name: 'textsData',
};
