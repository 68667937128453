import { HelperService } from '/services/HelperService/HelperService';
import {
    STORE_RESPONSE_RATES,
    ADD_RESPONSE_RATE,
    FILTERS_CHANGED,
    ADD_SURVEY_REQUEST,
    ADD_SURVEY_SUCCESS,
    ADD_SURVEY_ERROR,
    COPY_SURVEY_REQUEST,
    COPY_SURVEY_SUCCESS,
    COPY_SURVEY_ERROR,
    STORE_NEW_SURVEY,
    GET_SURVEYS_LIST_REQUEST,
    GET_SURVEYS_LIST_SUCCESS,
    GET_SURVEYS_LIST_ERROR, GET_RESPONSE_RATES_REQUEST,
} from './actions';

export function getFiltersFromStorage(key) {
    const filters = HelperService.getFromSessionStorage('filters');

    return filters ? filters[key] : null;
}

function getInitialState() {
    return {
        surveysList: {
            count: 0,
            next: null,
            previous: null,
            results: [],
            loading: false,
        },
        responseRates: {
            count: 0,
            next: null,
            previous: null,
            results: [],
            loading: false,
        },
        filters: {
            // TODO: Remade
            unit: getFiltersFromStorage('unit') || null,
            unitLabel: getFiltersFromStorage('unitLabel') || null,
            search: getFiltersFromStorage('search') || '',
            page: getFiltersFromStorage('page') || 1,
            limit: getFiltersFromStorage('limit') || 10,
            hideDeletedSurveys: getHideDeletedSurveys('hideDeletedSurveys'),
            sortBy: getFiltersFromStorage('sortBy') || '-sequence',
        },
    };
}

function getHideDeletedSurveys(key) {
    const filters = HelperService.getFromSessionStorage('filters');

    return filters && Object.prototype.hasOwnProperty.call(filters, key) ? filters[key] : true;
}

export function SurveysReducer(state = (getInitialState()), action = {}) {
    switch (action.type) {
        case GET_SURVEYS_LIST_REQUEST: {
            return {
                ...state,
                surveysList: {
                    ...state.surveysList,
                    loading: true,
                },
            };
        }
        case GET_SURVEYS_LIST_SUCCESS: {
            return {
                ...state,
                surveysList: {
                    ...action.response,
                    loading: false,
                },
            };
        }

        case GET_SURVEYS_LIST_ERROR: {
            return {
                ...state,
                surveysList: {
                    ...state.surveysList,
                    loading: false,
                },
            };
        }

        case GET_RESPONSE_RATES_REQUEST: {
            return {
                ...state,
                responseRates: {
                    ...state.responseRates,
                    loading: true,
                },
            };
        }

        case STORE_RESPONSE_RATES: {
            return {
                ...state,
                responseRates: {
                    ...action.data,
                    loading: false,
                    isFullyLoaded: action.isFullyLoaded,
                },
            };
        }

        case ADD_RESPONSE_RATE: {
            return {
                ...state,
                responseRates: {
                    count: state.responseRates.count + action.data.count,
                    results: state.responseRates.results.concat(action.data.results),
                    loading: false,
                },
            };
        }

        case FILTERS_CHANGED: {
            window.sessionStorage.setItem('filters', JSON.stringify(action.data));
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.data,
                },
            };
        }

        case COPY_SURVEY_REQUEST:
        case ADD_SURVEY_REQUEST: {
            return {
                ...state,
                surveysList: {
                    ...state.surveysList,
                    loading: true,
                },
            };
        }

        case COPY_SURVEY_ERROR: {
            return {
                ...state,
                surveysList: {
                    ...state.surveysList,
                    loading: false,
                },
            };
        }

        case COPY_SURVEY_SUCCESS:
        case ADD_SURVEY_SUCCESS: {
            return {
                ...state,
                surveysList: {
                    ...state.surveysList,
                    loading: false,
                },
            };
        }

        case ADD_SURVEY_ERROR: {
            return {
                ...state,
                surveysList: {
                    ...state.surveysList,
                    loading: false,
                },
            };
        }

        case STORE_NEW_SURVEY: {
            return {
                ...state,
                surveysList: {
                    ...state.surveysList,
                    results: [
                        ...action.data,
                    ],
                },
            };
        }

        default:
            return state;
    }
}
