import React from 'react';
import cx from 'classnames';

import { IDrillDownSettingsItem } from '/visual/models';

import styles from './style.module.scss';

interface IDrillDownDashboardSettings {
    settings: IDrillDownSettingsItem[],
    containerClass?: string,
    title?: string,
}

export const DrillDownDashboardSettings = ({
    settings,
    containerClass,
    title,
}: IDrillDownDashboardSettings) => (
    <table className={ cx(containerClass, styles.settingsContainer) }>
        {
            title
            && <caption className={ cx('titleRowS', styles.titleRow) }>
                <span>{ title }</span>
            </caption>
        }
        <tbody>
            {
                settings.map((s: IDrillDownSettingsItem) => (
                    <tr key={ s.title } className={ cx('settingsRowS', styles.settingsRow) }>
                        {
                            !!s.title
                            && <td><span>{ `${s.title}: ` }</span></td>
                        }
                        <td><span>{ s.value }</span></td>
                    </tr>
                ))
            }
        </tbody>
    </table>
);
