import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { VocSelect } from '/components';

import './QueryHeader.scss';

export const QueryHeader = ({
    history,
    queries,
    getQueryInfo,
    cleanup,
    queryId,
    disable,
    showConfirm,
    isDirtyForm,
}) => {
    const handleWithConfirm = action =>
        showConfirm({
            dirty: isDirtyForm,
            checkDirty: true,
            header: 'Warning!',
            content: 'Seems you might have some unsaved changes. Are you sure you want to continue?',
            successCallback: () => action(),
        });

    const changeQuery = event => {
        cleanup();
        history.push(`/mine/query/${ event.value }/execute`);
        getQueryInfo(event.value);
    };

    const goBack = () => history.push('/mine/query/list-queries');

    return (
        <div className={ cx('voc-mine-query__header', { 'mine-query-header__disable': disable }) }>
            <div className='header__item'>
                <Button color="link" onClick={ () => handleWithConfirm(goBack) } className='button_link-back_grey'>
                    <FontAwesomeIcon icon='arrow-left'/>
                    <span> Back</span>
                </Button>
            </div>
            <div className='header__item selectWrapper'>
                <VocSelect
                    options={ queries }
                    value={ queries.find(query => query.value === +queryId) }
                    className={ 'select-change-query' }
                    onChange={ e => handleWithConfirm(() => changeQuery(e)) }
                    disabled={ queries.length <= 1 }
                    id='query_name_select'
                />
            </div>
        </div>
    );
};
