import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TooltipWrapper } from '/components';
import { Badge } from 'reactstrap';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ProjectListItem.scss';

export class ProjectsListItem extends Component {
    componentDidMount() {
        if (this.focusProject) {
            this.focusProject.scrollIntoView();
        }
    }

    @autobind
    onClickProjectListItem() {
        const { onSelectProject, project } = this.props;

        onSelectProject(project);
    }

    @autobind
    onClickDeleteIcon(event) {
        const message = 'Are you sure to delete this Customer Journey?';
        const confirm = window.confirm(message);
        const { project, deleteProject } = this.props;

        if (confirm) {
            event.stopPropagation();
            deleteProject(project);
        }
    }

    renderDeleteProjectIcon() {
        const { project: { id } } = this.props;

        return id !== 1
            ? <FontAwesomeIcon className="delete-icon" icon='times'/>
            : null;
    }

    render() {
        const { selectedProject, project: { id, name } } = this.props;
        const isActive = selectedProject && id === selectedProject.id;
        const activeClass = isActive ? 'active' : '';
        const classList = `list-group-item ${activeClass} manage_projects__list-element`;

        return (
            <li
                className={ classList }
                key={ id }
                ref={ el => isActive ? this.focusProject = el : null }
                onClick={ this.onClickProjectListItem }>
                <TooltipWrapper value={ name } placement='top'>
                    <span className='wrap'>{ name }</span>
                </TooltipWrapper>

                <Badge className='manage_projects__list-icon pointer'
                    onClick={ this.onClickDeleteIcon }
                    color={ 'danger' }
                    pill>
                    { this.renderDeleteProjectIcon() }
                </Badge>
            </li>
        );
    }
}

ProjectsListItem.propTypes = {
    selectedProject: PropTypes.object,
    project: PropTypes.object,
};
ProjectsListItem.defaultProps = {};
