import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import {
    QueryDetailsHeader,
    QueryChartText,
    QueryChartBar,
    QueryChartWordCloud,
    QueryCoOccurrenceGraph,
    QueryCorrelationGraph,
    ZeroShotBlock,
} from './components/';
import { InsightBlock } from '/query/components';
import { ThinSpinner } from '/components';
import { QueryExecuteService } from '/services';
import { coOccurenceDataSelector, correlationDataSelector } from '/query/modules/query.selectors';

import './QueryDetails.scss';

export const QueryDetails = ({
    changeCollapseState,
    language,
    filters,
    changeDirtyFilters,
    lemmataData,
    textsData,
    updatePaginationFilters,
    updateCorrelationLimit,
    lemmataForCloud,
    chartType,
    isDataLabelling,
    onChangeChartType,
    requestByChart,
    members,
    queryId,
    queryList,
    saveMineRights,
    updateShares,
    getClassifierList,
    classifiers,
    exportQuery,
    textCases,
    shares,
    getSharedRights,
    isLoadingData,
    querySizes,
    pageRequests,
}) => {
    const [ showMessage, setShowMessage ] = useState(false);
    const [ showRefreshButton, setShowRefreshButton ] = useState(true);

    const coOccurrenceGraphData = useSelector(coOccurenceDataSelector);
    const correlationGraphData = useSelector(correlationDataSelector);

    const isScrollableChartType = [ 'insightNarrator', 'Text', 'Bar' ].includes(chartType);
    const waitingDataLoading = 5000;

    useEffect(() => {
        if (isDataLabelling) {
            setTimeout(() => {
                setShowMessage(true);
            }, waitingDataLoading);
        }

        return () => setShowMessage(false);
    }, [ chartType ]);

    const handleSetShowRefreshButton = value => setShowRefreshButton(value);

    const extractFilterValue = filter => {
        return filter.initialValues && filter.initialFilters
            ? filter.initialValues
            : filter.initialFilters;
    };

    const getFiltersValue = (idInclude, idExclude) => {
        return {
            filterForInclude: extractFilterValue(filters.find(({ id }) => id === idInclude)),
            filterForExclude: extractFilterValue(filters.find(({ id }) => id === idExclude)),
            idInclude,
            idExclude,
        };
    };

    //todo change changeFilter to newChangeFilter
    const changeFilter = (selectedText, typeFilter, filter, isAddOrDelete) => {
        const newFilters = QueryExecuteService.convertFiltersToServer(filters);

        newFilters[typeFilter] = {
            condition: filter.condition,
            values: isAddOrDelete ? [ ...filter.values, selectedText ] : filter.values.filter(value => value !== selectedText),
        };

        if (!newFilters[typeFilter].values.length) {
            delete newFilters[typeFilter];
        }

        changeDirtyFilters(newFilters);
    };

    const removeInclude = (values, text) => values.filter(filter => filter !== text);
    const newChangeFilter = ({
        text,
        selectedFilterType,
        secondFilterType,
        allFilters,
    }) => {
        const newFilters = QueryExecuteService.convertFiltersToServer(filters);
        const currentFilter = allFilters[selectedFilterType];
        const secondFilter = allFilters[secondFilterType];

        newFilters[selectedFilterType] = {
            condition: currentFilter.condition,
            values: currentFilter.values.includes(text) ? removeInclude(currentFilter.values, text) : [ ...currentFilter.values, text ],
        };

        newFilters[secondFilterType] = {
            condition: secondFilter.condition,
            values: secondFilter.values.includes(text) ? removeInclude(secondFilter.values, text) : [ ...secondFilter.values ],
        };

        if (!newFilters[selectedFilterType].values.length) {
            delete newFilters[selectedFilterType];
        }

        if (!newFilters[secondFilterType].values.length) {
            delete newFilters[secondFilterType];
        }

        changeDirtyFilters(newFilters);

        secondFilter.values.includes(text) && changeCollapseState(secondFilterType, true);
        changeCollapseState(selectedFilterType, true);
    };

    const renderDetailsContent = type => {
        switch (type) {
            case 'Text' :
                return <QueryChartText
                    changeFilter={ changeFilter }
                    changeDirtyFilters={ changeDirtyFilters }
                    requestByChart={ requestByChart }
                    textsData={ textsData }
                    updatePaginationFilters={ updatePaginationFilters }
                    { ...getFiltersValue('textToInclude', 'textToExclude') }
                />;

            case 'Bar':
                return <QueryChartBar
                    newChangeFilter={ newChangeFilter }
                    changeDirtyFilters={ changeDirtyFilters }
                    lemmataData={ lemmataData }
                    requestByChart={ requestByChart }
                    updatePaginationFilters={ updatePaginationFilters }
                    { ...getFiltersValue('lemmaToInclude', 'lemmaToExclude') }
                />;

            case 'Cloud':
                return <QueryChartWordCloud
                    newChangeFilter={ newChangeFilter }
                    changeDirtyFilters={ changeDirtyFilters }
                    wordList={ lemmataForCloud }
                    updatePaginationFilters={ updatePaginationFilters }
                    name={ 'lemmataWordCloud' }
                    { ...getFiltersValue('lemmaToInclude', 'lemmaToExclude') }
                />;

            case 'CoOccurrence':
                return <QueryCoOccurrenceGraph
                    showRefreshButton={ showRefreshButton }
                    setShowRefreshButton={ handleSetShowRefreshButton }
                    requestByChart={ requestByChart }
                    newChangeFilter={ newChangeFilter }
                    changeDirtyFilters={ changeDirtyFilters }
                    graphData={ coOccurrenceGraphData }
                    updatePaginationFilters={ updatePaginationFilters }
                    name={ 'lemmataCoOccurrence' }
                    extraNameForFilter={ 'lemmataCorrelation' }
                    { ...getFiltersValue('lemmaToInclude', 'lemmaToExclude') }
                />;

            case 'Correlation':
                return <QueryCorrelationGraph
                    showRefreshButton={ showRefreshButton }
                    setShowRefreshButton={ handleSetShowRefreshButton }
                    requestByChart={ requestByChart }
                    newChangeFilter={ newChangeFilter }
                    changeDirtyFilters={ changeDirtyFilters }
                    graphData={ correlationGraphData }
                    updatePaginationFilters={ updatePaginationFilters }
                    updateCorrelationLimit={ updateCorrelationLimit }
                    name={ 'lemmataCorrelation' }
                    extraNameForFilter={ 'lemmataCoOccurrence' }
                    { ...getFiltersValue('lemmaToInclude', 'lemmaToExclude') }
                />;

            case 'Data Labelling':
                return <ZeroShotBlock
                    querySizes={ querySizes }
                    queryData={ queryList[0] }
                />;

            case 'insightNarrator':
                return <InsightBlock
                    chartType={ chartType }
                    pageRequests={ pageRequests }
                />;

            default:
                return null;
        }
    };

    return (
        <div className={ cx('query-details', `query-details_${chartType}`) }>
            {
                isLoadingData && chartType !== 'insightNarrator'
                && <div>
                    <ThinSpinner className={ 'thin-spinner__query-details' }/>
                    {
                        showMessage && isDataLabelling
                            ? <span className={ 'query-details-spinner-long-load-message' }>
                                This process can sometimes take a while (up to 5 minutes).<br/> Please
                                wait while data is loaded.
                            </span>
                            : null
                    }
                </div>
            }
            <QueryDetailsHeader
                isLoadingData={ isLoadingData }
                language={ language }
                onChangeChartType={ onChangeChartType }
                chartType={ chartType }
                members={ members }
                queryId={ queryId }
                queryList={ queryList }
                saveMineRights={ saveMineRights }
                updateShares={ updateShares }
                getClassifierList={ getClassifierList }
                classifiers={ classifiers }
                submitExport={ exportQuery }
                filters={ QueryExecuteService.convertFiltersToServer(filters) }
                textCases={ textCases }
                shares={ shares }
                getSharedRights={ getSharedRights }
                isDataLabelling={ isDataLabelling }
            />

            <div className={ cx('query-details__content-wrapper', { 'scrollableList': isScrollableChartType }) }>
                {
                    renderDetailsContent(chartType)
                }
            </div>
        </div>
    );
};
