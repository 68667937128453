export class DynamicFormServices {
    static parseRowsForms(fields, data) {
        const amountRow = data.length;
        const objForRender = [];

        for (let i = 0; i < +amountRow; i++) {
            const deepCopyObj = DynamicFormServices.deepCopyObj(fields).map((el, inxItem, arr) => {
                {
                    if (el.affect !== undefined) {
                        const toggleChanges = el.affect.whenId === data[i][inxItem].value;

                        DynamicFormServices.changeObjRef(toggleChanges, arr, el.affect);
                    }
                    return el;
                }
            });

            objForRender.push(deepCopyObj);
        }

        return objForRender;
    }

    static changeObjRef(toggleChanges, arr, affect) {
        arr[affect.to] = {
            ...arr[affect.to],
            type: toggleChanges ? affect.how : affect.else,
            settings: toggleChanges ? affect.settings : [],
            placeholder: toggleChanges ? affect.placeholder : affect.elseSetting.placeholder,
        };
    }
    static deepCopyObj(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
}
