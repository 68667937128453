import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';

export const GET_SURVEYS_LIST_REQUEST = 'GET_SURVEYS_LIST_REQUEST';
export const GET_SURVEYS_LIST_REQUEST_SUCCESS = 'GET_SURVEYS_LIST_REQUEST_SUCCESS';
export const GET_SURVEYS_LIST_REQUEST_ERROR = 'GET_SURVEYS_LIST_REQUEST_ERROR';

const LEMMATIZATION_SURVEY_REQUEST = 'LEMMATIZATION_SURVEY_REQUEST';
const LEMMATIZATION_SURVEY_SUCCESS = 'LEMMATIZATION_SURVEY_SUCCESS';
const LEMMATIZATION_SURVEY_ERROR = 'LEMMATIZATION_SURVEY_ERROR';

export const CLEANUP_SURVEYS_PAGE = 'CLEANUP_SURVEYS_PAGE';
export const SURVEYS_LIST_FILTERS_CHANGED = 'SURVEYS_LIST_FILTERS_CHANGED';

const initialState = {
    loading: false,
    filters: {
        search: '',
        limit: 10,
        sort_field: '',
        sort_order: '',
        page: 1,
    },
    surveys: {
        data: [],
        count: 0,
    },
};

export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_SURVEYS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_SURVEYS_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                surveys: {
                    count: Number(action.response.count),
                    data: action.response.data,
                },
                loading: false,
            };
        case GET_SURVEYS_LIST_REQUEST_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case SURVEYS_LIST_FILTERS_CHANGED:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        case CLEANUP_SURVEYS_PAGE:
            return initialState;

        default:
            return state;
    }
}

export function getSurveys(body) {
    return {
        [CALL_API]: {
            endpoint: '/public_api/store/survey/list',
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_SURVEYS_LIST_REQUEST,
                GET_SURVEYS_LIST_REQUEST_SUCCESS,
                GET_SURVEYS_LIST_REQUEST_ERROR,
            ],
            body,
        },
    };
}

export function updateFilters(payload) {
    return {
        type: SURVEYS_LIST_FILTERS_CHANGED,
        payload,
    };
}

export function reLemmatization(sourceId) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/lemma/initiate`,
            types: [ LEMMATIZATION_SURVEY_REQUEST, LEMMATIZATION_SURVEY_SUCCESS, LEMMATIZATION_SURVEY_ERROR ],
            method: 'POST',
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyAddedToQueue', { entity: t('survey') }),
                error: t('messages.somethingWentWrong'),
            },
            body: {
                sourceId,
            },
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP_SURVEYS_PAGE,
    };
}
