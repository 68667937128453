import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ExporterForm } from './components';
import { Preloader } from '/components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import {
    exporterDataSelector,
    formValuesSelector,
    languagesSelector,
    getDirtyStatus,
    typeOptionsList,
} from './modules/exporter.selectors';
import { getExporter, cleanup, createExporter, updateExporter, getDataTypesList } from './modules/exporter.modules';

import './Exporter.scss';

export const Exporter = ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { loading, initialValues, dateFormatOptions, timeFormatOptions } = useSelector(exporterDataSelector);
    const languages = useSelector(languagesSelector);
    const typeOptions = useSelector(typeOptionsList);
    const isDirty = useSelector(getDirtyStatus);
    const formValues = useSelector(formValuesSelector);
    const { params: { exporterId } , url } = match;
    const isCopyMode = url.includes('/copy/');
    const title = `${ isCopyMode ? 'Copy ' : (exporterId ? 'Edit ' : 'Create ') } Exporter`;

    useEffect(() => {
        exporterId && dispatch(getExporter(exporterId));
        dispatch(getDataTypesList());

        return () => {
            dispatch(cleanup());
        };
    }, []);


    const goBack = () => history.push('/feedback/export/exporters-list');

    const goBackWithConfirm = () => {
        dispatch(showConfirm({
            dirty: isDirty,
            checkDirty: true,
            header: 'Warning!',
            content: 'Seems you might have some unsaved changes. Are you sure you want to continue?',
            successCallback: () => goBack(),
        }));
    };

    const submitExporter = ({ time_format, date_format, ...data }) => {
        const dateFormatString = time_format === 'TH:i:sP'
            ? `${date_format}\\${time_format}`
            : `${date_format} ${time_format}`;

        let model = {
            ...data,
            ...time_format && date_format ? { date_format: dateFormatString } : {},
            custom_columns: data.custom_columns.length ? [
                ...data.custom_columns.map(custom_column => ({
                    ...custom_column,
                    data_type: custom_column.data_type.value || custom_column.data_type,
                })),
            ] : [],
        };

        if (data.export_type === 'default') {
            model.include_error_message_column = !!data.include_error_message_column;
            model.with_topic_set = !!data.with_topic_set;
        } else {
            delete model.include_error_message_column;
            delete model.with_topic_set;
        }

        if (data.export_type === 'blacklist') {
            model = {
                output_type: model.output_type,
                delimiter: model.delimiter,
                export_type: model.export_type,
                name: model.name,
                custom_columns: [],
                export_conditions: [],
                filter_by_states: [],
            };
        }

        if (data.export_type === 'custom') {
            delete model.include_case_alert_column;
            delete model.include_invitation_channel_column;
        }

        const isCopy = url.includes('copy');
        const request = dispatch(
            exporterId && !isCopy
                ? updateExporter(exporterId, model)
                : createExporter(model),
        );

        return request.promise.then(() => goBack());
    };

    return (
        <DocumentTitle title={ `Feedback :: ${ title }` }>
            {
                loading
                    ? <div className='invite-modal-preloader-wrapper'>
                        <Preloader/>
                    </div>
                    : <>
                        <Row>
                            <Col md={ 12 }>
                                <div className='virtual-source__go-back'>
                                    <Button color='link' onClick={ goBackWithConfirm }>
                                        <FontAwesomeIcon icon='arrow-left' />
                                        <span> Back</span>
                                    </Button>
                                </div>
                            </Col>
                            <Col md={ 9 }>
                                <h2 className='virtual-source__header-title'>
                                    { title }
                                </h2>
                            </Col>
                        </Row>
                        <ExporterForm
                            goBack={ goBack }
                            initialValues={ initialValues }
                            languages={ languages }
                            formValues={ formValues }
                            submitExporter={ submitExporter }
                            isCopyMode={ isCopyMode }
                            typeOptions={ typeOptions }
                            dateFormatOptions={ dateFormatOptions }
                            timeFormatOptions={ timeFormatOptions }
                        />
                    </>
            }
        </DocumentTitle>
    );
};
