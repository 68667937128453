import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TooltipWrapper } from '/components';
import './RightsTableActionFormatter.scss';

export const RightsTableActionFormatter = ({
    openEditModal,
    openDeleteModal,
    openInviteModal,
    currentUserPermission,
    isSupportUser = false,
    id,
    tooltipTitle,
}) => {
    const [ isPermitShare, setIsPermitShare ] = useState(false);
    const [ isPermitEdit, setIsPermitEdit ] = useState(false);
    const [ isPermitDelete, setIsPermitDelete ] = useState(false);

    useEffect(() => setRights(), []);
    useEffect(() => setRights(), [ currentUserPermission ]);

    const setRights = () => {
        const permission = currentUserPermission;
        const isPermitView = permission === 'view' || currentUserPermission === 'unknown';
        const isPermitFull = permission === 'full';

        setIsPermitShare(isSupportUser || isPermitFull);
        setIsPermitEdit(isSupportUser || !isPermitView);
        setIsPermitDelete(isSupportUser || isPermitFull);
    };

    return (
        <div className='table-action__container table-action__query-list'>
            <TooltipWrapper value={ 'Share' } force>
                <span
                    className={ cx('table-action__query-list-icon', { disabled: !isPermitShare }) }
                    onClick={ () => isPermitShare && openInviteModal(id) }
                >
                    <FontAwesomeIcon icon="share"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ `Edit ${tooltipTitle}` } force>
                <span
                    className={ cx('table-action__query-list-icon', { disabled: !isPermitEdit }) }
                    onClick={ () => isPermitEdit && openEditModal(id) }
                >
                    <FontAwesomeIcon icon="pencil-alt"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ `Delete ${tooltipTitle}` } force>
                <span
                    className={ cx('table-action__query-list-icon', { disabled: !isPermitDelete }) }
                    onClick={ () => isPermitDelete && openDeleteModal(id) }
                >
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>
        </div>
    );
};

RightsTableActionFormatter.defaultProps = {
    tooltipTitle: 'query',
};
