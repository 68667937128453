import { CacheService } from '/services/CacheService';
import { INVALIDATE_CACHE } from '/modules/';


// eslint-disable-next-line no-unused-vars
export default store => next => action => {
    //todo - make Cache Sercive work
    return next(action);

    // eslint-disable-next-line no-unreachable
    if (action.type === INVALIDATE_CACHE) {
        next(action);
        return CacheService
            .getNamespaceByName(action.namespaceName)
            .then(CacheService.clearCacheByNamespace);
    }
    return next(action);
};
