import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import cx from 'classnames';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { VocMetaFilter, TooltipWrapper } from '/components';
import { MetaQueryService } from '/services';
import { ApplianceSegmentHeader } from '../ApplianceSegmentHeader';

import './ApplianceSegment.scss';

@withTranslation()
export class ApplianceSegment extends Component {
    state = {
        open: false,
        showConfirmDelete: false,
    };

    componentDidMount() {
        const { segment: { segmentName } } = this.props;

        if (!segmentName) {
            this.setState({ open: true });
        }
    }

    @autobind
    tooogleConfirmDelete() {
        this.setState({ showConfirmDelete: !this.state.showConfirmDelete });
    }

    @autobind
    onChangedMetaQueryItem() {
        const { onChangeSegment, segment } = this.props;

        this.forceUpdate();
        onChangeSegment(segment);
    }

    @autobind
    onChangeSegmentName(option) {
        const segmentName = option ? option.value : option;
        const { onChangeSegment, segment: { hash } } = this.props;

        onChangeSegment({ segmentName, hash });
    }

    @autobind
    onDeleteClick() {
        const { deleteSegment, segment } = this.props;

        this.setState({ showConfirmDelete: false });
        deleteSegment(segment);
    }

    @autobind
    toogleOpenSegment() {
        this.setState({ open: !this.state.open });
    }

    getSegmentHeader() {
        const { segment: { segmentName }, dataType, segmentOptions, initialOptions } = this.props;
        const { open, showConfirmDelete } = this.state;

        return (
            <ApplianceSegmentHeader
                initialOptions={ initialOptions }
                segmentOptions={ segmentOptions }
                showConfirmDelete={ showConfirmDelete }
                tooogleConfirmDelete={ this.tooogleConfirmDelete }
                onDeleteClick={ this.onDeleteClick }
                toogleOpenSegment={ this.toogleOpenSegment }
                onChangeSegmentName={ this.onChangeSegmentName }
                isInvalid={ this.checkDataTypeValidation().isInvalid }
                open={ open }
                dataType={ dataType }
                segmentName={ segmentName }/>
        );
    }

    getQueryString() {
        const { segment: { query }, source } = this.props;

        return (
            <div className="queryString">
                { MetaQueryService.clientQueryToString({ query, source }) }
            </div>
        );
    }

    checkDataTypeValidation() {
        const {
            segment: {
                segmentName,
            },
            dataType,
            initialOptions,
            t,
        } = this.props;

        const isNumber = !isNaN(+segmentName);
        const isOption = initialOptions.some(({ label }) => label === segmentName);

        if (isOption) return false;

        switch (dataType.value) {
            case 'NUMERIC': {
                const maxInputValueLength = 9;
                const isInvalidLength = segmentName && segmentName.length > maxInputValueLength;

                return {
                    isInvalid: !isNumber || isInvalidLength,
                    message: isNumber && isInvalidLength
                        ? t('validation.valueIsTooLong')
                        : t('validation.onlyNumericValuesAllowed'),
                };
            }
            case 'NPS': {
                const npsMinValue = 0;
                const npsMaxValue = 10;

                const isInvalid = !isNumber || segmentName > npsMaxValue || segmentName < npsMinValue;

                return {
                    isInvalid,
                    message: t('validation.onlyNumericValuesFromToAllowed'),
                };
            }

            default: {
                return {
                    isInvalid: false,
                };
            }
        }
    }

    renderValidationMessage() {
        const { hasEmptyItems, t } = this.props;
        const dataTypeValidation = this.checkDataTypeValidation();

        if (dataTypeValidation.isInvalid || hasEmptyItems) {
            const errorMessage = dataTypeValidation.isInvalid
                ? dataTypeValidation.message
                : t('validation.configureCondition');

            return (
                <div className={ cx('appliance-name__validation', { 'appliance-name__validation_bottom': !this.state.open }) }>
                    { errorMessage }
                </div>
            );
        }
    }

    render() {
        const { source, segment: { query }, attributeValueKey, t } = this.props;
        const { open } = this.state;
        const queryString = this.getQueryString();

        return (
            <Card className={ 'appliance-segment-panel' }>
                <CardHeader>
                    { this.getSegmentHeader() }
                </CardHeader>
                <CardBody>
                    <div className={ 'appliance-segment' }>
                        <Collapse isOpen={ open }>
                            <div>
                                <label className={ 'control-label' }>
                                    { t('field.condition') }
                                </label>
                                {
                                    this.renderValidationMessage()
                                }
                                <VocMetaFilter
                                    attributeValueKey={ attributeValueKey }
                                    attributePlaceholder={ t('field.inputVariable') }
                                    metaQuery={ query }
                                    onChangedMetaQueryItem={ this.onChangedMetaQueryItem }
                                    source={ source }
                                    withLemmas={ false }
                                />
                            </div>
                        </Collapse>
                        <TooltipWrapper
                            className={ 'query-string-tooltip' }
                            value={ queryString }
                        >
                            { queryString }
                        </TooltipWrapper>
                        {
                            !open && this.renderValidationMessage()
                        }
                    </div>
                </CardBody>
            </Card>
        );
    }
}

ApplianceSegment.propTypes = {
    segment: PropTypes.object,
    source: PropTypes.object,
    attributeValueKey: PropTypes.string,
    deleteSegment: PropTypes.func,
    onChangeSegment: PropTypes.func,
};

ApplianceSegment.defaultProps = {};
