import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody, ListGroup, ListGroupItem } from 'reactstrap';
import { Preloader } from '/components/';
import './ExportResultsSummaryPopover.scss';

export class ExportResultsSummaryPopover extends Component {
    renderList() {
        return (
            <ListGroup>
                {
                    this.props.data.statistics.map((statistic, index) =>
                        <ListGroupItem
                            className={ 'statistic-popover__item' }
                            key={ index }>
                            <span className={ 'statistic-popover__label' }>{ statistic.label }:</span>
                            <strong>{ statistic.value }</strong>
                        </ListGroupItem>,
                    )
                }
            </ListGroup>
        );
    }

    render() {
        const {
            isOpen,
            toggle,
            data,
        } = this.props;

        return (
            <Popover
                id='statistic-data'
                className={ 'statistic-popover shadowed-popover' }
                isOpen={ isOpen }
                toggle={ toggle }
                placement={ 'bottom' }
                target={ 'showStatisticSummary' }
                trigger="legacy"
            >
                <PopoverHeader>
                    <strong>Summary statistics</strong>
                </PopoverHeader>
                <PopoverBody className={ 'statistic-popover__body' }>
                    {
                        data.loading ? <Preloader/> : this.renderList()
                    }
                </PopoverBody>
            </Popover>
        );
    }
}
