import React, { ChangeEvent } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

import { IDimensions } from './models';

import styles from './style.module.scss';

export const Dimensions = ({ dimensions, setDimensions }: IDimensions) => {
    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>, inputType: string) => {
        const { value } = e.target;

        setDimensions({
            ...dimensions,
            [inputType]: value,
        });
    };

    return (
        <div className={ styles.dimensionsWrapper }>
            <span className={ styles.label }>Dimensions</span>
            <InputGroup className={ styles.dimensionInput }>
                <InputGroupText>
                    H
                </InputGroupText>
                <Input
                    type='number'
                    value={ dimensions.height }
                    onChange={ e => onChangeHandler(e, 'height') }
                />
            </InputGroup>

            <span className={ styles.multiply }>×</span>

            <InputGroup className={ styles.dimensionInput }>
                <InputGroupText>
                    W
                </InputGroupText>
                <Input
                    type='number'
                    value={ dimensions.width }
                    onChange={ e => onChangeHandler(e, 'width') }
                    className={ styles.dimensionInput }
                />
            </InputGroup>
        </div>
    );
};
