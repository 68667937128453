import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { VocModal, Editor } from '/components';
import { gadgetStaticAreaValueSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { changeStaticAreaData } from '/visual/scenes/Dashboard/modules/Dashboard.modules';

export const EditStaticAreaCodeModal = ({
    toggle,
    show,
}) => {
    const [ value, setValue ] = useState('');
    const [ disableSave, setDisableSave ] = useState(false);

    const data = useSelector(gadgetStaticAreaValueSelector);
    const dispatch = useDispatch();

    const onSave = () => {
        dispatch(changeStaticAreaData(data?.id, value));
        toggle();
    };

    const onChange = (e: React.SetStateAction<string>) => setValue(e);

    const disableSubmit = (state: boolean | ((prevState: boolean) => boolean)) => setDisableSave(state);

    useEffect(() => {
        setValue(data?.value || '');
    }, [ data ]);

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color={ 'white' }
                onClick={ toggle }
            >
                <FontAwesomeIcon icon='times'/>
                &nbsp; Cancel
            </Button>
            <Button
                disabled={ disableSave }
                color={ 'primary' }
                onClick={ onSave }
            >
                <FontAwesomeIcon icon='check'/>
                &nbsp; Confirm
            </Button>
        </div>
    );

    return (
        <VocModal
            isOpen={ show }
            modalClassName={ 'modal-scrollable' }
            header={ `Edit HTML` }
            size={ 'lg' }
            toggle={ toggle }
            footer={ getModalFooter() }
            onClosed={ () => setValue('') }
        >
            <div>
                <Editor
                    focus
                    onChange={ onChange }
                    value={ value }
                    disableSubmit={ disableSubmit }
                    validationCallback={ () => null }
                />
            </div>
        </VocModal>
    );
};
