import React, { Component } from 'react';
import {
    Pagination, PaginationItem, PaginationLink, Row,
    Col, Button, Input, InputGroup, InputGroupAddon,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { autobind } from 'core-decorators';

import PropTypes from 'prop-types';

export class SurveysListPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            invalidInput: false,
        };
    }

    @autobind
    getArrayFromNumber(number) {
        return Array.from({ length: number }, (v, i) => i + 1);
    }

    @autobind
    getArrayFromSetPage(page, count, pagesCount) {
        const maxArray = this.getArrayFromNumber(pagesCount);
        const setLastPageSet = maxArray.slice(Math.max(maxArray.length - count, 1));

        switch (true) {
            case (page <= (count - 2)):
                return this.getArrayFromNumber(count);
            case (page > (pagesCount - 2)):
                return setLastPageSet;

            default:
                return [ page - 2, page - 1, page, page + 1, page + 2 ];
        }
    }

    @autobind
    setLastPageSet() {
        const { itemsCount, itemsPerPage } = this.props;
        const pagesCount = Math.ceil(itemsCount / itemsPerPage);

        this.props.applyFilter({
            page: pagesCount,
            loading: true,
        });
    }

    @autobind
    setCurrentPage(page) {
        this.props.applyFilter({
            page,
            loading: true,
        });
    }

    @autobind
    setPrevPage() {
        const { page } = this.props;

        this.props.applyFilter({
            page: page - 1,
            loading: true,
        });
    }

    @autobind
    setNextPage() {
        const { page } = this.props;

        this.props.applyFilter({
            page: page + 1,
            loading: true,
        });
    }

    @autobind
    setFirstPageSet() {
        this.props.applyFilter({
            page: 1,
            loading: true,
        });
    }

    @autobind
    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.setPageFromInput();
            this.setState({
                inputValue: '',
                invalidInput: false,
            });
        }
    }

    @autobind
    changeInputHandler(e) {
        if (typeof +e.target.value === 'number' && (+e.target.value > 0)) {
            const inputValue = +e.target.value;

            this.setState({
                inputValue,
                invalidInput: false,
            });
        } else {
            this.setState({
                inputValue: '',
                invalidInput: true,
            });
        }
    }

    @autobind
    setPageFromInput() {
        const { itemsCount, itemsPerPage, page } = this.props;
        const pagesCount = Math.ceil(itemsCount / itemsPerPage);
        const value = this.state.inputValue ? this.state.inputValue : page;
        const currentPage = value <= pagesCount ? value : pagesCount;

        this.setState({
            inputValue: '',
            invalidInput: false,
        });

        this.setCurrentPage(currentPage);
    }

    render() {
        const { page, itemsPerPage, itemsCount, MAX_LIMIT, loading } = this.props;

        const pagesCount = Math.ceil(itemsCount / itemsPerPage);
        const pageSet = (5 <= pagesCount)
            ? this.getArrayFromSetPage(page, 5, pagesCount)
            : this.getArrayFromNumber(pagesCount);
        const prevDisabled = page === 1;
        const nextDisabled = page === pagesCount;

        const pageRangeStart = (page - 1) * itemsPerPage + 1;
        let pageRangeEnd = pageRangeStart + itemsPerPage - 1;

        pageRangeEnd = (pageRangeEnd > itemsCount)
            ? itemsCount
            : pageRangeEnd;

        const inputClassName = this.state.invalidInput ? 'is-invalid' : '';
        const paginationPrefix = `surveys-list-modern-pagination`;
        const paginationLoading = (loading) ? paginationPrefix + '-loading' : '';
        const paginationClass = `${paginationPrefix} ${paginationLoading} noselect`;
        const isPreventRender = itemsPerPage === MAX_LIMIT || pagesCount <= 1;
        const paginationInfo = (itemsCount > 1) ? (
            <span className='info text-left'>{ pageRangeStart } – { pageRangeEnd } of { itemsCount }</span>) : '';

        return !isPreventRender
            ? (
                <div className={ paginationClass }>
                    <Row className='row'>
                        <Col md={ '3' }>
                            { paginationInfo }
                        </Col>
                        <Col md={ '6' }>
                            <Pagination className={ 'surveys-list-pagination' }>
                                <PaginationItem disabled={ prevDisabled }>

                                    <PaginationLink
                                        onClick={ this.setFirstPageSet }
                                        previous
                                    >
                                        <FontAwesomeIcon icon={ 'angle-double-left' }/>
                                    </PaginationLink>

                                </PaginationItem>
                                <PaginationItem disabled={ prevDisabled }>

                                    <PaginationLink
                                        onClick={ this.setPrevPage }
                                    >
                                        <FontAwesomeIcon icon={ 'angle-left' }/>
                                    </PaginationLink>

                                </PaginationItem>

                                { pageSet.map((item, index) =>
                                    <PaginationItem active={ item === page } key={ index }>
                                        <PaginationLink onClick={ () => this.setCurrentPage(item) }>
                                            { item }
                                        </PaginationLink>
                                    </PaginationItem>,
                                ) }

                                <PaginationItem disabled={ nextDisabled }>

                                    <PaginationLink
                                        onClick={ this.setNextPage }
                                    >
                                        <FontAwesomeIcon icon={ 'angle-right' }/>
                                    </PaginationLink>

                                </PaginationItem>
                                <PaginationItem disabled={ nextDisabled }>

                                    <PaginationLink
                                        onClick={ this.setLastPageSet }
                                        next
                                    >
                                        <FontAwesomeIcon icon={ 'angle-double-right' }/>
                                    </PaginationLink>
                                </PaginationItem>

                            </Pagination>
                        </Col>
                        <Col md={ '3' }>
                            <div className='pagination-form noselect'>
                                <div className='form-group text-right'>
                                    <InputGroup className='input-group'>
                                        <span className='input-group-addon'>Go to the page: </span>

                                        <Input id='pagination_set'
                                            name='pagination_set'
                                            className={ inputClassName }
                                            onKeyPress={ this.handleKeyPress }
                                            value={ this.state.inputValue }
                                            onChange={ e => this.changeInputHandler(e) }
                                            maxLength='5'
                                        />
                                        <InputGroupAddon addonType="append">
                                            <Button className='form-control'
                                                color={ 'primary' }
                                                disabled={ this.state.invalidInput }
                                                onClick={ this.setPageFromInput }>
                                                <FontAwesomeIcon icon={ 'check' }/>
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
            : (
                <div className={ paginationClass }>
                    <Row className='row'>
                        <Col md={ '12' }>
                            { paginationInfo }
                        </Col>
                    </Row>
                </div>
            );
    }
}

SurveysListPagination.propTypes = {
    itemsCount: PropTypes.any,
    itemsPerPage: PropTypes.any,
    applyFilter: PropTypes.func,
    page: PropTypes.any,
    loading: PropTypes.any,
    MAX_LIMIT: PropTypes.any,
};

SurveysListPagination.defaultProps = {};
