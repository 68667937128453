import React, { useState, forwardRef, useImperativeHandle, useEffect, MouseEvent } from 'react';
import cx from 'classnames';
import { Popover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonComponent } from '/visual/components';
import { IPopoverList } from './models';
import { TPopoverListForwardRef, TAction } from '/visual/models';

import styles from './style.module.scss';

const DEFAULT_POPPER_MODIFIERS = {
    offset: {
        offset: '0 5px',
    },
};

export const PopoverList = forwardRef<TPopoverListForwardRef, IPopoverList>(({
    target,
    tag = ButtonComponent,
    disabled = false,
    tagClassName,
    tagDisabledClassName = '',
    buttonOutline = true,
    buttonColor = 'secondary',
    buttonIcon = 'ellipsis-h',
    buttonText = '',
    trigger = 'legacy',
    placement = 'auto',
    popperModifiers = DEFAULT_POPPER_MODIFIERS,
    popoverFade = true,
    listClassName,
    listItemClassName,
    popoverClassName,
    actionList = [],
    popoverHeader = null,
    onToggleModal,
    onBeforeHide,
    delay = null,
}, ref) => {
    const [ isPopoverOpen, setIsPopoverOpen ] = useState<boolean>(false);

    // static 'isPopoverOpen' and method 'togglePopover' for parent component throw forwardRef
    useImperativeHandle(ref,
        () => ({
            checkAndHidePopover: () => {
                isPopoverOpen && setIsPopoverOpen(false);
            },
            isPopoverOpen,
        }),
        [ isPopoverOpen, setIsPopoverOpen ]);

    useEffect(() => {
        if (disabled) {
            setIsPopoverOpen(false);
        }
    }, [ disabled ]);

    const getButtonByTagName = () => {
        let Tag: keyof JSX.IntrinsicElements | typeof ButtonComponent;

        if (typeof tag === 'function') {
            Tag = tag as typeof ButtonComponent;

            return (
                <Tag
                    buttonProps={{
                        id: target,
                        outline: buttonOutline,
                        color: buttonColor,
                        className: cx(tagClassName, {
                            [styles.tagDisabled]: disabled,
                            [tagDisabledClassName]: disabled,
                        }),
                    }}
                    text={ buttonText }
                    icon={ buttonIcon }
                />
            );
        } else {
            Tag = tag as keyof JSX.IntrinsicElements;

            return (
                <Tag
                    id={ target }
                    className={ cx(tagClassName) }
                >
                    <FontAwesomeIcon icon={ buttonIcon } />
                    { buttonText }
                </Tag>
            );
        }
    };

    const togglePopover = () => {
        if (isPopoverOpen) onBeforeHide?.();
        if (!disabled) setIsPopoverOpen(!isPopoverOpen);
    };

    const onHandleAction = ({ type, onClick }: TAction) => type && onToggleModal
        ? onToggleModal(type)
        : onClick?.();

    const renderActionList = () => (
        <ul
            className={ cx(styles.list, listClassName) }
            onClick={ togglePopover }
        >
            {
                popoverHeader
                && <div
                    className={ styles.listHeaderWrapper }
                    onClick={ (e: MouseEvent<HTMLElement>) => e.stopPropagation() }
                >
                    { popoverHeader }
                </div>
            }
            {
                actionList.map((action, index) =>
                    !action.hidden
                        ? <li
                            key={ `${ action.title }-${ index }` }
                            className={ cx(
                                listItemClassName,
                                action.className,
                                { [styles.actionDisabled]: action.disabled },
                            ) }
                            onClick={ () => !action.disabled ? onHandleAction(action) : null }
                        >
                            {
                                action.icon
                                && <FontAwesomeIcon icon={ action.icon } />
                            }

                            <span>{ action.title }</span>
                        </li>
                        : null,
                )
            }
        </ul>
    );

    const props = {
        ...delay ? { delay } : {},
    };

    return (
        <>
            {
                getButtonByTagName()
            }
            <Popover
                placement={ placement }
                target={ target }
                isOpen={ !disabled && isPopoverOpen }
                trigger={ trigger }
                modifiers={ popperModifiers }
                fade={ popoverFade }
                className={ cx(styles.popoverClass, popoverClassName) }
                innerClassName={ styles.popoverInnerClassName }
                toggle={ togglePopover }
                { ...props }
            >
                <PopoverBody>
                    {
                        renderActionList()
                    }
                </PopoverBody>
            </Popover>
        </>
    );
});
