import React, { useEffect, useState } from 'react';
import { VocModal, VocSelect, InputLoader } from '/components';
import { Button, Row, Col, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ChangeNameModal.scss';

export const ChangeNameModal = ({
    modalHeader,
    submitButtonName,
    show,
    close,
    onSubmit,
    tags,
    queryId,
    queryList,
    initialContext,
    showTextColumn,
    showQueryContext,
    useInVisual,
    //whenSubmittedClose fix warning render
    whenSubmittedClose,
    textColumn: {
        loading,
        columnIds,
        options,
    },
}) => {
    const [ nameValue, setNameValue ] = useState('');
    const [ context, setContext ] = useState(null);
    const [ tag, setTag ] = useState(null);
    const [ column, setColumn ] = useState([]);
    const [ query, setQuery ] = useState(null);
    const [ isSubmit, setIsSubmit ] = useState(false);

    const trimLeft = string => string.replace(/^\s+/g, '');

    useEffect(() => {
        if (columnIds && showTextColumn) {
            setColumn(options.filter(option => columnIds.includes(option.value)));
        }
    }, [ columnIds ]);

    useEffect(() => {
        if (queryId && show) {
            const filteredQuery = queryList.find(query => query.actions.id === queryId);

            // title 'string' in "Save as" case
            const title = typeof filteredQuery.title === 'string' ? '' : filteredQuery.title.title;

            setNameValue(title);

            if (filteredQuery.tag) {
                const value = tags.find(option => option.value === filteredQuery.tag);

                setTag(value);
            }

            setQuery(filteredQuery);
        }

        return () => {
            setNameValue('');
            setContext(null);
            setTag(null);
            setColumn([]);
            setQuery(null);
            setIsSubmit(false);
        };
    }, [ queryId, show ]);

    useEffect(() => {
        setContext(initialContext);
    }, [ initialContext ]);

    const changeTag = option => {
        let value, label;

        if (option) {
            value = trimLeft(option.value);
            label = trimLeft(option.label);
        }

        setTag(option && value
            ? {
                value,
                label,
            }
            : null,
        );
    };

    const changeName = ({ target }) =>
        setNameValue(trimLeft(target.value));

    const changeQueryContext = ({ target }) =>
        setContext(trimLeft(target.value));

    const toggle = () => close();

    const handleSubmit = () => {
        const { columnIds, language, userId: user, source: { id: sourceId } } = query.actions;

        setIsSubmit(true);

        const requestBody = {
            id: queryId,
            title: nameValue.trim(),
            tag: tag ? tag.value.trim() : '',
            sourceId,
            language,
            columnIds: showTextColumn ? column.map(element => element.value) : columnIds,
            user,
            useInVisual,
        };

        if (showQueryContext) {
            requestBody.context = context?.trim() || null;
        }

        onSubmit(requestBody)
            .then(() => whenSubmittedClose && toggle())
            .catch(() => setIsSubmit(false));
    };

    const getTextColumnSelect = () => {
        return (
            <div className='form-group'>
                {
                    loading
                        ? <Row>
                            <Col md={ 12 }>
                                <label className={ 'control-label' }> Query text column </label>
                            </Col>
                            <Col md={ 12 } className={ 'query-text-column__loading' }>
                                <InputLoader nesting={ false }/>
                            </Col>
                        </Row>
                        : <VocSelect
                            options={ options }
                            value={ column }
                            className={ 'select-change-query' }
                            label={ 'Query text column' }
                            onChange={ value => setColumn(value === null ? [] : value) }
                            portal
                            isMulti
                        />
                }
            </div>
        );
    };

    const getModalBody = () => {
        return (
            <>
                <Row>
                    <Col md={ 12 }>
                        <div className="form-group">
                            <Label for="query-name-id"> Query name </Label>
                            <Input
                                id="query-name-id"
                                type="text"
                                value={ nameValue }
                                onChange={ changeName }
                            />
                        </div>
                    </Col>
                </Row>

                <div className="form-group">
                    <VocSelect
                        options={ tags }
                        value={ tag }
                        className={ 'select-change-query' }
                        label={ 'Query tag' }
                        onChange={ changeTag }
                        isClearable
                        isCreatable
                        portal
                    />
                </div>

                {
                    showTextColumn && getTextColumnSelect()
                }

                {
                    showQueryContext
                    && <Row>
                        <Col md={ 12 }>
                            <Label for='query-context'>
                                Query context
                            </Label>
                            <Input
                                id='query-context'
                                type='textarea'
                                placeholder='Enter Query context'
                                rows={ 3 }
                                value={ context || '' }
                                onChange={ changeQueryContext }
                            />
                        </Col>
                    </Row>
                }
            </>
        );
    };

    const getModalFooter = () => (
        <>
            <Button onClick={ toggle } outline color={ 'white' }>
                <FontAwesomeIcon icon="times"/>
                <span> Cancel</span>
            </Button>
            <Button
                onClick={ handleSubmit }
                disabled={ !nameValue || isSubmit || loading }
                color={ 'primary' }
            >
                <FontAwesomeIcon icon="check"/>
                <span> { submitButtonName }</span>
            </Button>
        </>
    );

    return (
        <VocModal
            isOpen={ show }
            toggle={ toggle }
            modalClassName='modal-scrollable'
            header={ modalHeader }
            footer={ getModalFooter() }
            size="ms"
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};

ChangeNameModal.defaultProps = {
    tags: [],
    showTextColumn: false,
    showQueryContext: false,
    initialContext: null,
    textColumn: {
        loading: false,
        columnIds: [],
        options: [],
    },
    whenSubmittedClose: true,
    modalHeader: 'Change query name or tag',
    submitButtonName: 'Change',
    close: () => null,
};
