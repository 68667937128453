import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { VocModal, FormFieldItem, SwitchControl, VocSelect, Preloader } from '/components';
import { HelperService } from '/services';
import { arrayMove } from 'react-sortable-hoc';
import { InputLoader } from '/scenes/VocFeedback/components/InputLoader/';
import { DynamicForm } from '../../../../components/DynamicForm';
import {
    ExporterSettingsSelector,
    ExporterConditionsSelector,
    ExporterModalDataSelector,
    LangModalSelector,
} from '../../modules/exportersList.selectors';
import { getLanguage } from '../../modules/exportersList.index';
import './ManageExportersListModal.scss';

const customColumnHeadersValue = {
    value: 'custom',
    label: 'Custom',
};

const getInitialState = () => ({
    name: '',
    id: null,
    pendingRequest: true,
    unit: { id: null },
    config: {
        column_headers: '',
        delimiter: ';',
        output_type: 'csv',
        state: [],
        filter: 'creation',
        only_valid_answers: false,
        only_with_responses: false,
        type: 'default',
        columns: [],
        conditions: [],
    },
    languages: [],
    delimiterMap: [],
    columnHeadersMap: [],
    fileTypeMap: [],
    statusMap: [],
    filterQuestionnairesMap: [],
    exportTypeMap: [],
    self: '',
    type: '',
    loading: false,
});

@connect(state => ({
    dataExporterSettings: ExporterSettingsSelector(state),
    dataExporterConditions: ExporterConditionsSelector(state),
    modalData: ExporterModalDataSelector(state),
    languagesList: LangModalSelector(state),
}), { getLanguage })
export class ManageExportersListModal extends Component {
    state = getInitialState();

    checkEmptyParameters(obj) {
        const arr = obj.reduce((result, obj) => ([
            ...result,
            ...Object.keys(obj).map(el => obj[el] !== ''),
        ]), []);

        return arr.every(curVal => curVal);
    }

    isSubmitDisabled() {
        const {
            name,
            unit,
            config,
            pendingRequest,
        } = this.state;

        const {
            column_headers,
            columns,
            conditions,
            type,
            language,
        } = config;

        let isConditions = true;
        let isColumns = true;
        const isLang = !this.isShowLang() || !!language;
        const isColumnHeaders = type === 'custom' ? true : column_headers;

        if (!!conditions && !!conditions.length) {
            isConditions = this.checkEmptyParameters(conditions);
        }

        if (type === 'custom') {
            isColumns = this.checkEmptyParameters(columns) && !!columns.length;
        }

        return [ name, unit, isColumnHeaders, isLang, config.state.length, isConditions, isColumns, pendingRequest ]
            .every(field => field);
    }

    isShowLang() {
        const {
            config,
            columnHeadersMap,
            unit,
        } = this.state;

        return config.type !== 'custom' && columnHeadersMap[0] && unit && unit.id && config.column_headers === columnHeadersMap[0].value;
    }

    @autobind
    cleanupModal() {
        this.setState(getInitialState());
    }

    @autobind
    onOpened() {
        const { dataExportersModal, modalData } = this.props;

        this.setState({
            ...getInitialState(),
            ...dataExportersModal,
            ...modalData,
            loading: this.props.modalManageType !== 'new',
        });
    }

    static getDerivedStateFromProps(props, state) {
        if(!props.dataExportersModal.loading && props.modalManageType !== 'new' && state.loading && props.show) {
            const {
                dataExportersModal: {
                    result,
                    loading,
                },
                modalData,
                getLanguage,
            } = props;

            if(result.unit) {
                getLanguage(result.unit.id);
            }

            return {
                ...getInitialState(),
                ...result,
                ...props.modalManageType === 'copy'
                    ? { id: null, name : '' }
                    : {},
                ...modalData,
                loading,
            };
        }

        return null;
    }

    @autobind
    submitHandler() {
        this.setState({
            pendingRequest: false,
        });
        const {
            id, self,
            unit, name,
            is_editable, config,
        } = this.state;

        const model = {
            id: this.state.type ? null : id,
            self,
            unit: unit.id,
            name,
            is_editable,
            config: {
                ...config,
                column_headers: config.type === 'custom' ? 'question_label' : config.column_headers,
                columns: config.type !== "custom" ? [] : config.columns,
                only_valid_answers: config.only_valid_answers ? 1 : 0,
                only_with_responses: config.only_with_responses ? 1 : 0,
            },
        };

        this.props.manageExportersList(model).finally(() => {
            this.setState({
                pendingRequest: true,
            });
        });
    }

    @autobind
    handleSelectChange(values) {
        this.setState({
            config: {
                ...this.state.config,
                state: values,
            },
        });
    }

    @autobind
    valueChangedInput(event) {
        this.setState({
            name: event.target.value,
        });
    }

    @autobind
    handleUnitChangeSelect(group) {
        this.setState({
            unit: {
                id: group.value,
            },
        });

        this.props.getLanguage(group.value);
    }

    updateStateConfig(update) {
        this.setState({
            config: {
                ...this.state.config,
                ...update,
            },
        });
    }

    @autobind
    onChangeFilter(filter) {
        this.updateStateConfig({ filter: filter.value });
    }

    @autobind
    onChangeColumnHeaders(column_headers) {
        this.updateStateConfig({ column_headers: column_headers.value });
    }

    @autobind
    onChangeOutputType(output_type) {
        this.updateStateConfig({ output_type: output_type.value });
    }

    @autobind
    onChangeDelimiter(delimiter) {
        this.updateStateConfig({ delimiter: delimiter.value });
    }

    @autobind
    handleChangeExportType(group) {
        const { config } = this.state;

        this.setState({
            config: {
                ...config,
                type: group.value,
                output_type: group.value === "generic" ? 'csv' : config.output_type,
            },
        });
    }

    @autobind
    handleChangeLanguage(language) {
        this.updateStateConfig({ language: language.value });
    }

    @autobind
    handleChangeStatus(group) {
        this.setState({
            config: {
                ...this.state.config,
                state: group ? group.map(item => item.value) : [],
            },
        });
    }

    @autobind
    handleOnlyWithResponses(value) {
        this.setState({
            config: {
                ...this.state.config,
                'only_with_responses': value,
            },
        });
    }

    @autobind
    handleOnlyValidResponses(value) {
        this.setState({
            config: {
                ...this.state.config,
                'only_valid_answers': value,
            },
        });
    }

    @autobind
    changeDynamicForm(value, { row, item }, name) {
        const objState = this.state.config[name];

        objState[row][item] = value;

        this.setState({
            config: {
                ...this.state.config,
                [name]: [
                    ...objState,
                ],
            },
        });
    }

    @autobind
    addDynamicForm(name, emptyForm) {
        const objState = this.state.config[name];

        this.setState({
            config: {
                ...this.state.config,
                [name]: [
                    ...objState,
                    emptyForm,
                ],
            },
        });
    }

    @autobind
    removeDynamicForm(id, name) {
        const objState = this.state.config[name];

        this.setState({
            config: {
                ...this.state.config,
                [name]: [
                    ...objState.filter((el, inx) => inx !== id),
                ],
            },
        });
    }

    @autobind
    onSortEnd({ oldIndex, newIndex }, name) {
        const newRowsForms = arrayMove(this.state.config[name], oldIndex, newIndex);

        this.setState({
            config: {
                ...this.state.config,
                [name]: [
                    ...newRowsForms,
                ],
            },
        });
    }

    getDynamicFormForSettings() {
        const columns = this.state.config.columns.map(el => ([
            { type: 'name', value: el.name },
            { type: 'type', value: el.type },
            { type: 'content', value: el.content },
            { type: 'remove', value: "remove", hint: 'Delete Settings value' },
        ]));

        return <DynamicForm
            settings={ this.props.dataExporterSettings }
            name='columns'
            handlChange={ this.changeDynamicForm }
            addNewForm={ this.addDynamicForm }
            removeForm={ this.removeDynamicForm }
            onSortEnd={ this.onSortEnd }
            data={ columns }
        />;
    }

    getDynamicFormForConditions() {
        const conditions = this.state.config.conditions.map(el => ([
            { type: 'ext_id', value: el.ext_id },
            { type: 'condition', value: el.condition },
            { type: 'value', value: el.value },
            { type: 'remove', value: "remove", hint: 'Delete conditions' },
        ]));

        return <DynamicForm
            settings={ this.props.dataExporterConditions }
            name='conditions'
            handlChange={ this.changeDynamicForm }
            addNewForm={ this.addDynamicForm }
            removeForm={ this.removeDynamicForm }
            onSortEnd={ this.onSortEnd }
            data={ conditions }
        />;
    }

    getFormSelectLang() {
        const {
            langResults,
            loadingLanguage,
        } = this.props.languagesList;

        const {
            language = '',
        } = this.state.config;
        const languageValue = HelperService.getSelectedValue(langResults, language);

        return loadingLanguage
            ? <InputLoader label="Language" isRequired={ true }/>
            : <VocSelect
                label="Language"
                placeholder="Choose language"
                portal={ true }
                isRequired={ true }
                isClearable={ false }
                isHorizontal={ true }
                value={ languageValue }
                options={ langResults }
                onChange={ this.handleChangeLanguage }
            />;
    }

    getFormSelectFileType() {
        const { fileTypeMap } = this.state;
        const { output_type, type } = this.state.config;
        const options = type === "generic"
            ? fileTypeMap.filter(el => el.value === output_type)
            : fileTypeMap;
        const outputTypeValue = HelperService.getSelectedValue(options, output_type);

        return <VocSelect
            label="File type"
            isRequired={ true }
            portal={ true }
            isClearable={ false }
            isHorizontal={ true }
            value={ outputTypeValue }
            options={ options }
            onChange={ this.onChangeOutputType }
        />;
    }

    getModalTitle() {
        return this.state.type ? 'Copy Exporter' : 'Save Exporter';
    }

    getModalFooter() {
        return (
            <Fragment>
                <Button onClick={ this.props.close } outline color={ 'white' }>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button
                    onClick={ this.submitHandler }
                    disabled={ !this.isSubmitDisabled() }
                    color={ 'primary' }>
                    <FontAwesomeIcon icon="check"/>
                    <span> Save</span>
                </Button>
            </Fragment>
        );
    }

    getModalBody() {
        const {
            name,
            unit,
            config,
            delimiterMap,
            columnHeadersMap,
            statusMap,
            filterQuestionnairesMap,
            exportTypeMap,
            unitsList,
            loading,
        } = this.state;

        const {
            column_headers,
            delimiter,
            state,
            filter,
            only_valid_answers,
            only_with_responses,
            type,
        } = config;

        if (typeof unit.id === 'number') {
            unit.id = String(unit.id);
        }

        const unitValue = HelperService.getSelectedValue(unitsList, unit.id);
        const columnHeadersValue = type === 'custom' ? customColumnHeadersValue : HelperService.getSelectedValue(columnHeadersMap, column_headers);
        const delimiterValue = HelperService.getSelectedValue(delimiterMap, delimiter);
        const filterValue = HelperService.getSelectedValue(filterQuestionnairesMap, filter);
        const typeValue = HelperService.getSelectedValue(exportTypeMap, type);
        const stateValues = state.map(stateItem => HelperService.getSelectedValue(statusMap, stateItem));

        return <Fragment>
            {
                loading && <div className={ `preloader__wrapper preloader__wrapper--export` }>
                    <Preloader/>
                </div>
            }
            <FormFieldItem
                controlId="title"
                type="text"
                isHorizontal={ true }
                value={ name }
                label="Title"
                isRequired={ true }
                name="name"
                placeholder="Enter title"
                handleChange={ this.valueChangedInput }
            />

            <VocSelect
                label="Ref unit"
                isRequired={ true }
                placeholder="Choose unit"
                portal={ true }
                isClearable={ false }
                isHorizontal={ true }
                value={ unitValue }
                options={ unitsList }
                onChange={ this.handleUnitChangeSelect }
            />

            <VocSelect
                label="Column Headers"
                isRequired={ true }
                portal={ true }
                placeholder="Choose column headers"
                isClearable={ false }
                isHorizontal={ true }
                value={ columnHeadersValue }
                options={ columnHeadersMap }
                onChange={ this.onChangeColumnHeaders }
                disabled={ type === 'custom' }
            />

            { this.isShowLang() ? this.getFormSelectLang() : '' }

            <VocSelect
                label="Delimiter"
                isRequired={ true }
                portal={ true }
                isClearable={ false }
                isHorizontal={ true }
                value={ delimiterValue }
                options={ delimiterMap }
                onChange={ this.onChangeDelimiter }
            />

            { this.getFormSelectFileType() }

            <VocSelect
                label="Status"
                isRequired={ true }
                portal={ true }
                placeholder="Choose status"
                isMulti={ true }
                isHorizontal={ true }
                closeMenuOnSelect={ false }
                value={ stateValues }
                options={ statusMap }
                onChange={ this.handleChangeStatus }
            />

            <VocSelect
                label="Filter questionnaires by date considering their"
                isRequired={ true }
                placeholder=""
                portal={ true }
                isClearable={ false }
                isHorizontal={ true }
                name="filter"
                value={ filterValue }
                options={ filterQuestionnairesMap }
                onChange={ this.onChangeFilter }
            />
            <div className="form-group">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 manage__exporter-label-container">
                                <label htmlFor="only_with_responses" className="manage__exporter-label">Only with
                                    responses</label>
                            </div>
                            <div className="col-md-4">
                                <SwitchControl changeHandler={ this.handleOnlyWithResponses }
                                    name={ 'only_with_responses' }
                                    value={ only_with_responses }/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 manage__exporter-label-container">
                                <label htmlFor="only_valid_answers" className="manage__exporter-label">Show and count
                                    only valid responses</label>
                            </div>
                            <div className="col-md-4">
                                <SwitchControl changeHandler={ this.handleOnlyValidResponses }
                                    name={ 'only_valid_answers' }
                                    value={ only_valid_answers }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VocSelect
                label="Export type"
                isRequired={ true }
                portal={ true }
                isClearable={ false }
                isHorizontal={ true }
                name="type"
                value={ typeValue }
                options={ exportTypeMap }
                onChange={ this.handleChangeExportType }
            />

            { type === 'custom' ? this.getDynamicFormForSettings() : '' }
            { this.getDynamicFormForConditions() }
        </Fragment>;
    }

    @autobind
    onAddTouchPointClosed() {
        this.props.close();
    }

    render() {
        return (
            <VocModal
                isOpen={ this.props.show }
                toggle={ this.props.close }
                modalClassName='modal-scrollable exporters-list__modal'
                header={ this.getModalTitle() }
                footer={ this.getModalFooter() }
                onOpened={ this.onOpened }
                onClosed={ this.cleanupModal }
                backdrop={ 'static' }
                size="lg"
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}

ManageExportersListModal.propTypes = {
    show: PropTypes.bool,
    dataExportersModal: PropTypes.object,
    manageExportersList: PropTypes.func,
    close: PropTypes.func,
};
