import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { TooltipWrapper } from '/components';

import './CaseAlertsListActionsFormatter.scss';

export const CaseAlertsListActionsFormatter = ({
    openModalAddCondition,
    openModalDelete,
    openConditionPopover,
    conditions,
    permission: {
        isPermitEdit,
    },
    id,
}) => {
    const spanId = `table-action__case_alerts${id}`;
    const addCondition = () => isPermitEdit && openModalAddCondition({ id });
    const modalDelete = () => isPermitEdit && openModalDelete(id);
    const openPopover = () => openConditionPopover(spanId, id);

    return (
        <div id={ 'table-action__container' } className='table-action__container table-action__case_alerts-list'>

            <TooltipWrapper value={ "Show conditions" } force>
                <span
                    className={ cx(
                        'table-action__case_alerts-list-icon',
                        { 'disabled': !conditions.length },
                    ) }
                    onClick={ openPopover }
                >
                    <FontAwesomeIcon icon="cog" id={ spanId }/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Add condition' } force>
                <span
                    className={ cx(
                        'table-action__case_alerts-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ addCondition }
                >
                    <FontAwesomeIcon icon="plus"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ "Delete Case Alert" } force>
                <span
                    className={ cx(
                        'table-action__case_alerts-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ modalDelete }
                >
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>
        </div>
    );
};
