import React, { useEffect, useState } from 'react';
import { VocPopover, VocSelectMine, Preloader } from '/components';
import { Row, Col, Container } from 'reactstrap';
import './TableFilter.scss';

export const TableFilter = ({
    getFilterData,
    isOpenPopover,
    togglePopover,
    targetFilter,
    filterData: {
        options,
        selected,
        loading,
    },
    apply,
    classDefault,
    className,
    name,
}) => {
    const [ value, setValue ] = useState(null);

    const onFiltered = e => {
        e.stopPropagation();
        apply({ name, value });
        togglePopover();
    };

    const close = () => togglePopover(name);

    useEffect(
        () => {
            getFilterData && getFilterData(name);
            setValue(selected);
            return () => {
                setValue(null);
            };
        }, [],
    );

    return (
        <VocPopover
            target={ targetFilter }
            isOpen={ isOpenPopover }
            toggle={ close }
            className={ ` ${ className }  ${ classDefault } select-popover ` }
            popoverClassName={ 'select-popover-outer' }
        >
            <>
                {
                    loading
                        ? <div className={ 'queries-table-filter__preloader' }>
                            <Preloader/>
                        </div>
                        : <Container>
                            <Row>
                                <Col md={ 12 }>
                                    <VocSelectMine
                                        placeholder={ 'Select...' }
                                        value={ value }
                                        options={ options }
                                        onChange={ select => setValue(select) }
                                        closeMenuOnSelect={ false }
                                        apply={ onFiltered }
                                        id={ 'table-filter' }
                                        isClearable
                                        isMulti
                                        isMultiOutside
                                        filterName={ `Start search to add ${ name }` }
                                    />
                                </Col>
                            </Row>
                        </Container>
                }
            </>
        </VocPopover>
    );
};

TableFilter.defaultProps = {
    classDefault: 'queries-table-filter',
    loading: false,
    className: '',
};
