import { EExportFormats, EGadgetType } from '/visual/models';

export interface ISendToEmailBody {
  sendToOwnEmail: boolean;
  email: string | null;
  format: EExportFormats,
  subject: string | null;
  message: string | null;
}

export interface ISource {
  attributes: ISourceAttribute[];
  channel: boolean;
  currentUserPermission: any;
  dates: {
    min: any;
    max: any;
  };
  id: string;
  indexedAttributes: any[];
  languages: {
    id: string;
    name: string;
  }[];
  maxDate: any;
  maxUpdated: string;
  queries: any[];
  shares: null | string;
  state: null | string;
  title: string;
  totalRowsCount: number;
  type: string;
  updated: string;
}

export interface ISourceAttribute {
  badge: {
    color: string;
    title: string;
  }
  id: string;
  index: string;
  name: string
  options: {
    id: string;
    name: string;
  }[];
  optionsCount: number;
  originType: string;
  parameters: {
    mapping: {
      data_id: string;
      index: string;
      source_data_id: string;
    }[];
    name: string;
    type: string;
    id: string;
  }
  skipOptions: boolean;
  type: string;
}

export type TGadgetCoordinates = {
  gadgetId: string,
  h: number,
  w: number,
  x: number,
  y: number,
};

export interface IGadgetData {
  hasChildDashboard: boolean,
  childDashboardId: string | null,
  colors: any[];
  gadgetId?: string;
  contentSettings: IContentSettings;
  coordinates: {
    h: number;
    id: string;
    minH: number;
    minW: number;
    w: number;
    x: number;
    y: number;
  };
  dashboardId: string;
  dataSettings: IDataSettings;
  health: string;
  id: string;
  isUseCustomTitle: boolean;
  labels: {
    key: string;
    type: string;
    value: string;
  }[];
  lastHealthCheckTime: string;
  parent: any;
  title: string;
  type: EGadgetType;
  width: number;
}

export interface IContentSettings {
  annotateScore: boolean;
  applied: any;
  average: boolean;
  chartType: string;
  dateFormat: null | string;
  decimalDigits: string,
  drillDown: boolean;
  exclude: any;
  function: string;
  gadgetDescription: null | string;
  hideNoValue: boolean,
  innerText?: string | null,
  labelsSize: number[] | string[] | null,
  longLabel: boolean;
  maxCount: any;
  order: string;
  relativeMode: boolean;
  roundUp: boolean;
  rowsPerPage: null | number;
  saveTableLayout: boolean;
  showFullDate: boolean;
  tableColumnsWidth: any[];
  tableSortColumn: null | string;
  tableSortDirection: null | string;
  targetPoint: any;
  tickOrder: string[];
  timestamp: string;
  topValue: any;
  unlimitedTargetPoint: {
    value: any;
    visible: boolean;
  }
  wordwrapMode: boolean;
  recThresholdEnabled: boolean;
  recThreshold: number;
}

export interface IDataSettings {
  altChoices: boolean,
  customColorSet: null | string;
  customDateColumn: any;
  dataName: string | null;
  dataType: string;
  dateFilter: IDateFilter;
  factName: null | string;
  factTypes: string[] | null;
  facts: string[];
  forcedToShow: boolean | null;
  function: null | string;
  groupBy: null | string;
  groupByName: null | string;
  groupByType: null | string;
  metadataQuery: null | string;
  secondGroupBy: null | string;
  secondGroupByName: null | string;
  secondGroupByType: null | string;
  selectedGroup: null | string;
  selectedSuperGroup: null | string;
  state: null | string;
  timestamp: string
  sourceId?: string;
}

export interface IDateFilter {
  from?: null | number | string;
  to?: null | number | string;
  period?: null | string;
  start?: null | number | string;
  end?: null | number | string;
}

export interface IChartData {
  data: any;
  loading: boolean;
}

export type setTempSettingsType = ({
    dataSettings,
    contentSettings,
    title,
}: {
  dataSettings?: any,
  contentSettings?: any,
  title?: string,
}) => void;

export interface IRights {
  isPermitView: boolean
  isPermitViewInfoShare: boolean
  isPermitShare: boolean
  isPermitRename: boolean
  isPermitSend: boolean
  isPermitCopy: boolean
  isPermitScheduler: boolean
  isPermitPrint: boolean
  isPermitDelete: boolean
  isPermitEdit: boolean
  isPermitDefaultDashboard: boolean
  hasVisualWriteAccess: boolean
}

// TODO: move TUserRight, EUserRoles, TShareItemFromSelector to User's models
type TUserRight = {
  icon: string,
  label: string,
  ownerType: string,
  permission: string,
  value: number,
};

enum EUserRoles {
  Owner = 'Owner',
  Full = 'Full',
  Editors = 'Editors',
  Viewer = 'Viewer',
}

export type TShareItemFromSelector = {
  id: any,
  loaded: boolean,
  userRights: TUserRight[],
  usersByRole: Record<EUserRoles, TUserRight>[],
};

export interface IDrillDownBreadCrumb {
  id: string,
  title: string,
  isActive: boolean,
  isRoot: boolean,
  scrollToGadgetId: string | null,
  gadgetId: string,
}

export interface IDrillDownSettingsConf {
  title: string | null,
  factType?: string | null,
  factName?: string | null,
  selectedGroup?: string | null,
  selectedSuperGroup?: string | null,
  groupByName?: string | null,
  secondGroupByName?: string | null,
}

export interface IDrillDownSettingsItem {
  value?: string | null,
  title?: string | null,
  field: string,
}

export interface IDrillDashboardsNavigation {
  actualDashboardId: string,
  onSelectDashboard: (id: string, scrollToGadgetId?: string | null) => void,
}

export interface IDrillDashboardNavigationItem {
  drillDashboard: IDrillDownBreadCrumb,
  onClickDashboard: IDrillDashboardsNavigation['onSelectDashboard'],
}

export interface ITab {
  title: string,
  component: JSX.Element,
  id: number | string,
  disabled?: boolean,
}

export interface ITabsProps {
  tabs: ITab[];
  tabSelectorClassname?: string;
  onChange: (tabId: ITab['id']) => void;
  activeTab: number | string;
}

export interface ICopyModel {
  id: string;
  body?: {
    pages: {
      coordinate: object;
      pageId: any
    }
  },
  message: string
}

export interface IPageWithPlaceholder {
  placeholder: {
    minX: number,
    maxW: number,
    minY: number,
    maxY: number
  },
  page: string,
  dashboardId: string
}
