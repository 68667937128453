import React, { useEffect, useState } from 'react';
import { Button, Badge, Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { VocSelect, InputLoader, TooltipWrapper } from '/components';
import { selectedDictionariesSelector, dictionariesSelectOptionsSelector } from '/query/modules/query.selectors';
import { DictionariesModal } from '../../../QueryDetails/components';
import { QueryContextEditFieldModal } from './components';

import './QueryInfoSection.scss';

/* eslint-disable max-len */
const textInfoBox = `Please be aware, only tagged queries can be used in VoC Visual.
                        In case your query doesn't have any filter(s), you will not be able to label your query with a tag.
                        Warning! Complex queries with "text to include/exclude" filters can not be tagged (used in VoC Visual) as well.
                        An example. You have 2 queries with configured filters, 1st query called "promoters", 2nd one - "detractors". You can create a tag and call it "Sentiment".
                        You need to mark both your queries with the tag "Sentiment", as the result, you will be able to visualize combined results of queries "promoters" and "detractors" in the new artificial virtual column called "sentiment".`;

export const QueryInfoSection = ({
    queryInfo: {
        title,
        tag,
        context,
        sourceTitle,
        channelStatusColor,
        typeColor,
        type,
        source: {
            channel,
        },
        lang,
        langSelected,
        textColumns,
        useInVisual,
    },
    textCases: {
        textsLoading,
        allTextsCount,
        textsPercentage,
        count,
    },
    useQuery,
    changeLang,
    setUseQuery,
    showConfirm,
    hasFilters,
    isDataLabelling,
}) => {
    const selectedDictionaries = useSelector(selectedDictionariesSelector);
    const dictionaryOptions = useSelector(dictionariesSelectOptionsSelector);
    const isDisabledUseQuery = hasFilters || !tag;
    const [ dictionariesType, setDictionariesType ] = useState('synonyms');
    const [ isDictionariesModalOpen, setIsDictionariesModalOpen ] = useState(false);
    const [ isEditQueryContextModalOpen, setIsEditQueryContextModalOpen ] = useState(false);

    const textCasesString = `${ count } out of ${ allTextsCount } (${ textsPercentage }%)`;

    useEffect(() => {
        setUseQuery(isDisabledUseQuery ? false : useInVisual);
    }, []);

    useEffect(() => {
        (hasFilters || !tag) && setUseQuery(false);
    }, [ hasFilters, tag ]);

    const getDictionariesTitleList = dictionary =>
        dictionary.length
            ? dictionary.map(({ label }, idx, arr) =>
                <TooltipWrapper value={ label } key={ idx }>
                    <span className='dictionary-list-item'>
                        { label + (idx !== arr.length -1 ? ', ' : '') }
                    </span>
                </TooltipWrapper>,
            )
            : '-';

    const getTextColumn = () => (
        <span> { textColumns.map(({ name }) => name).join(', ') } </span>
    );

    const handleChangeLang = option => {
        showConfirm({
            header: 'Change language',
            size: 'md',
            content: `Are you sure you want to change language? All lemmata, text and dictionary filters will be reset.`,
            successCallback: () => changeLang(option.value),
        });
    };

    const toggleDictionariesModal = type => {
        type && setDictionariesType(type);
        setIsDictionariesModalOpen(!isDictionariesModalOpen);
    };

    const toggleEditQueryContextModal = () =>
        setIsEditQueryContextModalOpen(!isEditQueryContextModalOpen);

    return (
        <>
            <div className='mine-info__wrapper'>
                <div className='mine-info__row'>
                    <div className='mine-info__item titleWrapper'>
                        <span>Query:</span>
                        <div className='menu-info__value menu-info__value-title'>
                            <TooltipWrapper force value={ title }>
                                <div id='title_value' className={ 'menu-info__title' }>
                                    { title }
                                </div>
                            </TooltipWrapper>
                        </div>
                    </div>
                    <div
                        className={ cx('checkbox-input__wrapper', { 'wrapper__disabled': isDisabledUseQuery }) }>
                        <Input
                            type="checkbox"
                            disabled={ isDisabledUseQuery }
                            checked={ useQuery }
                            id="useInVisual"
                            onChange={ ({ target }) => setUseQuery(target.checked) }
                        />
                        <Label for="useInVisual">
                            <span>Tag query (for Visual)</span>
                        </Label>
                        <TooltipWrapper
                            force
                            value={ textInfoBox }
                            className='mine-info-box__for-visual'
                        >
                            <span>
                                <FontAwesomeIcon icon='info-circle' />
                            </span>
                        </TooltipWrapper>
                    </div>
                </div>

                <div className='mine-info__item'><span>Tag:</span>
                    <div id='tag_value' className='menu-info__value'>
                        {
                            tag
                                ? <span className='table-tag__label'> { tag } </span>
                                : ' - '
                        }
                    </div>
                </div>
                <div className='editField'>
                    <div className='mine-info__item'>
                        <span>Query context:</span>
                        <div className='menu-info__value'>
                            { context || '-' }
                        </div>
                    </div>
                    <TooltipWrapper value='Edit' force>
                        <div
                            className='mine-info__item editIcon'
                            onClick={ toggleEditQueryContextModal }
                        >
                            <FontAwesomeIcon icon='pencil-alt' />
                        </div>
                    </TooltipWrapper>
                </div>
                <div className='mine-info__item'><span>Source:</span>
                    <div id='source_value' className='menu-info__value'>{ sourceTitle }</div>
                    {
                        channel
                        && <Badge
                            color={ channelStatusColor }
                            className='Select-options__source-label'
                        >
                            { channel }
                        </Badge>
                    }

                    {
                        type !== channel && <Badge
                            color={ typeColor }
                            className='Select-options__source-label'
                        >
                            { type }
                        </Badge>
                    }
                </div>
                <div className='mine-info__item'>
                    <span>Text columns:</span>
                    <div id='text_column_value' className='menu-info__value'>
                        { getTextColumn() }
                    </div>
                </div>
                <div className={ cx( 'mine-info__item', { 'mine-info__item-opacity': isDataLabelling }) }>
                    <span>Text cases:</span>
                    <div id='text_cases_value' className='menu-info__value'>
                        {
                            textsLoading && !count
                                ? <InputLoader nesting={ false }/>
                                : textCasesString
                        }
                    </div>
                </div>

                <div className='dictionaries-info-items-wrapper'>
                    <div className='dictionaries-info-items'>
                        <div className='mine-info__item'>
                            <Button
                                className='mine-button__item'
                                color='secondary'
                                onClick={ () => toggleDictionariesModal('synonyms') }
                            >
                                Synonyms:
                            </Button>
                            <div className='menu-info__value'>
                                { getDictionariesTitleList(selectedDictionaries.synonyms) }
                            </div>
                        </div>
                        <div className='mine-info__item'>
                            <Button
                                className='mine-button__item'
                                color='secondary'
                                onClick={ () => toggleDictionariesModal('stopWords') }
                            >
                                Stop words:
                            </Button>
                            <div className='menu-info__value'>
                                { getDictionariesTitleList(selectedDictionaries.stopWords) }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mine-info__item mine-info__name-language'>
                    <span className='mine-info-name-language__label'>Language:</span>
                    <span className='mine-info-name-language__field'>
                        {
                            <VocSelect
                                options={ lang }
                                value={ langSelected }
                                id='mine_info_language_select'
                                disabled={ lang.length <= 1 }
                                onChange={ handleChangeLang }
                            />
                        }
                    </span>
                </div>
            </div>

            <DictionariesModal
                dictionaryOptions={ dictionariesType ? dictionaryOptions[dictionariesType] : [] }
                selectedDictionaries={ selectedDictionaries }
                type={ dictionariesType }
                show={ isDictionariesModalOpen }
                toggle={ () => toggleDictionariesModal() }
            />

            <QueryContextEditFieldModal
                show={ isEditQueryContextModalOpen }
                initialValue={ context }
                toggle={ toggleEditQueryContextModal }
            />
        </>
    );
};
