import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResizeObserver } from '/services';
import { ChartAxis } from '/visual/scenes/Dashboard/components/Gadget/components';
import { ILineChart, lineItem, lineLabel } from '/visual/scenes/Dashboard/components/Gadget/models';
import { charts } from '/visual/scenes/Dashboard/components/Gadget/contstants';
import { GadgetService } from '/visual/scenes/Dashboard/components';

import { LineChartService } from '../services';
import styles from '../style.module.scss';

const {
    marginTop,
    yLeftLabelOffset,
    rightOffset,
} = charts.line_chart;

export const LineChartD3 = ({
    labels,
    lines,
    level,
    showScore,
    percentage,
    axisLabels,
    highlightedLabel,
    gadgetId,
    gadgetFunction,
    drillDownEnabled,
    chartType,
    isNpsChart,
    npsData,
    toolTipRef,
    setChangeTickModal,
    resetTickData,
    onApplyLabelModal,
    setColorPickerData,
    drillDownFromSelection,
}: ILineChart) => {
    const { t } = useTranslation();
    const [ chartInstance, setChartInstance ] = useState<LineChartService | null>(null);
    const [ xAxisSize, setXAxisSize ] = useState<number | null>(null);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const wrapperRef = useRef<HTMLHeadingElement | null>(null);
    const dimensions = useResizeObserver(wrapperRef);
    const {
        showYLabels,
        showXLabels,
        internalWidth,
        internalHeight,
        boundedWidth,
        boundedHeight,
    } = GadgetService.getChartSizes({
        wrapperRef: wrapperRef.current,
        isNpsChart: isNpsChart,
        chartType,
        xAxisSize,
    });

    const drawChart = (labels: lineLabel[], lines: lineItem[]) => {
        if (chartInstance && chartInstance.isInitialized && boundedWidth > 0 && boundedHeight > 0) {
            const [ domainMin, domainMax ] = chartInstance.getDomain({ array: lines });
            const tickFormat = chartInstance.getYAxisTickFormat(chartInstance.maxYValue);

            chartInstance
                .preConfig({
                    xAxisSize: xAxisSize,
                    tickList: labels,
                })
                .drawContainer({
                    svgWidth: internalWidth,
                    svgHeight: internalHeight,
                    chartWidth: boundedWidth,
                    chartHeight: boundedHeight,
                    transformValue: `translate(${showYLabels ? yLeftLabelOffset : yLeftLabelOffset + rightOffset / 2}px, ${marginTop}px)`,
                    isNpsChart,
                    npsData,
                })
                .drawYAxis({
                    domainMin,
                    domainMax,
                    // from top of the cord system (chart height) to origin (0)
                    rangeFrom: boundedHeight,
                    rangeTo: 0,
                    tickFormat: tickFormat,
                })
                .drawXAxis({
                    domainValues: labels.map(l => l.id),
                    rangeFrom: 0,
                    rangeTo: boundedWidth,
                    additionalSettings: [{ prop: 'style', value: [ 'transform', `translateY(${boundedHeight}px)` ] }],
                })
                .drawRightAxis()
                .drawLines({ lines, labels, level })
                .drawNpsLine();
        }
    };

    useEffect(() => {
        if (svgRef.current && toolTipRef.current) {
            const chartInstance = new LineChartService({
                chartType: chartType,
                svgRef: svgRef.current as SVGSVGElement,
                toolTipRef: toolTipRef.current,
                gadgetFunction: gadgetFunction,
                drillDownEnabled: drillDownEnabled,
                gadgetId: gadgetId,
                percentage: percentage,
                withVerticalGrid: true,
                withHorizontalGrid: true,
                showScore,
                t,
                setTickModal: setChangeTickModal,
                resetTickModal: resetTickData,
                setXAxisSize: setXAxisSize,
                setColorPickerData: setColorPickerData,
                drillDownFromSelection: drillDownFromSelection,
            });

            setChartInstance(chartInstance);
        }

        return () => {
            if (chartInstance) {
                chartInstance.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (lines && lines.length > 0 && labels && labels.length > 0 && dimensions !== null) {
            drawChart(labels, lines);
        }
    }, [ dimensions, labels, lines, xAxisSize ]);

    useEffect(() => {
        if (chartInstance && chartInstance.isInitialized) {
            chartInstance.setHighlightedLine(highlightedLabel);
        }
    }, [ highlightedLabel ]);

    return (
        <div
            ref={ wrapperRef }
            className={ styles.lineChartWrapper }
        >
            <ChartAxis
                axisLabels={ axisLabels }
                axisSize={{ width: boundedWidth, height: boundedHeight }}
                gadgetId={ gadgetId }
                chartSettings={{
                    marginTop,
                    isNpsChart,
                    showYLabels: showYLabels,
                    showXLabels: showXLabels,
                    xOffsetForNps: isNpsChart ? rightOffset + yLeftLabelOffset : rightOffset,
                }}
                onApplyLabelModalData={ onApplyLabelModal }
            >
                <svg ref={ svgRef } />
            </ChartAxis>
        </div>
    );
};
