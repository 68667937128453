import { createSelector } from 'reselect';
import { getI18n } from 'react-i18next';

import { I18nService } from '/services';
import { userRightsSelector, headerUserInfoSelector, isSupportUserSelector } from '/modules/selectors';
import feedbackIcon from '../../../images/feedback.svg';
import storeIcon from '../../../images/store.svg';
import mineIcon from '../../../images/mine.svg';
import classifyIcon from '../../../images/classify.svg';
import visualIcon from '../../../images/visual.svg';
import labsIcon from '../../../images/labs.svg';
import aiIcon from '../../../images/ai.svg';
import feedbackActiveIcon from '../../../images/feedback_active.svg';
import storeActiveIcon from '../../../images/store_active.svg';
import mineActiveIcon from '../../../images/mine_active.svg';
import classifyActiveIcon from '../../../images/classify_active.svg';
import visualActiveIcon from '../../../images/visual_active.svg';
import labsActiveIcon from '../../../images/labs_active.svg';
import vocActIcon from '../../../images/voc_act.svg';
import vocActActiveIcon from '../../../images/voc_act_active.svg';
import vocCiIcon from '../../../images/voc_ci.svg';
import vocCiIActiveIcon from '../../../images/voc_ci_active.svg';
import aiActiveIcon from '../../../images/ai_active.svg';

export const countersSelector = state => state.Header.Menu.counters;
export const queriesSelector = state => state.Header.Menu.mineQueries;
export const activeRouteSelector = state => state.Header.Menu.activeRoute;

export const mineQueriesSelector = createSelector(
    [ queriesSelector ],
    mineQueries => {
        return mineQueries.queries
            ? mineQueries.queries.map(q => ({
                type: 'query',
                title: q.title,
                url: q.url,
                isReactLink: false,
            })).concat(mineQueries.moreCount ? [{
                type: 'muted',
                title: `${ mineQueries.moreCount } more ...`,
                url: '#',
                isReactLink: false,
            }] : [])
            : [];
    });

/**
 * Returns menu configuration list
 *
 * @description
 * routeLevel<number>: index of url snippet for comparison, whenever menu item is active
 * activeWhenEqual<string[]>: use this array for comparison urlPathArray[routeLevel] === activeWhenEqual
 * additionalCheckList<{ routeLevel, activeWhenEqual }[]>: additional comparison, if activeWhenEqual wasn't enough
 * **/
export const menuSelector = createSelector(
    [
        countersSelector,
        userRightsSelector,
        mineQueriesSelector,
        headerUserInfoSelector,
        isSupportUserSelector,
        I18nService.getActualLanguage,
    ],
    (
        counters,
        rights,
        mineQueries,
        { ticketSystemUseIframe, labs_access },
        isSupportUser,
    ) => {
        const { t } = getI18n();
        const setPlural = count => count > 1 ? 's' : '';

        return [
            {
                title: 'Feedback',
                counter: +counters.feedBackCount,
                hasAccess: rights.hasFeedbackAccess,
                countPrefix: `survey${ setPlural(counters.feedBackCount) }`,
                icons: {
                    def: feedbackIcon,
                    active: feedbackActiveIcon,
                },
                routeLevel: 1,
                activeWhenEqual: [ 'feedback' ],
                items: [
                    {
                        title: 'Surveys',
                        type: 'link',
                        isReactLink: true,
                        url: '/feedback/survey-list',
                        routeLevel: 2,
                        activeWhenEqual: [ 'survey-list', 'survey' ],
                    },
                    {
                        title: 'Customer Journey',
                        type: 'link',
                        isReactLink: true,
                        url: '/feedback/customer-journey',
                        routeLevel: 2,
                        activeWhenEqual: [ 'customer-journey' ],
                    },
                    {
                        title: 'Export',
                        type: 'link',
                        isReactLink: true,
                        url: '/feedback/export',
                        routeLevel: 2,
                        activeWhenEqual: [ 'export' ],
                    },
                    {
                        title: 'Import',
                        type: 'link',
                        isReactLink: true,
                        url: '/feedback/import',
                        routeLevel: 2,
                        activeWhenEqual: [ 'import' ],
                    },
                    {
                        title: 'Global settings',
                        type: 'link',
                        isReactLink: true,
                        url: '/feedback/settings/advanced',
                        routeLevel: 2,
                        activeWhenEqual: [ 'settings', 'theme' ],
                    },
                    {
                        title: 'Customers',
                        type: 'link',
                        isReactLink: true,
                        url: '/feedback/customers',
                        routeLevel: 2,
                        activeWhenEqual: [ 'customers' ],
                    },
                ],
            },
            {
                title: t('menu.store'),
                counter: +counters.storeCount,
                hasAccess: rights.hasStoreAccess,
                icons: {
                    def: storeIcon,
                    active: storeActiveIcon,
                },
                countPrefix: t('menu.upload', { count: counters.storeCount }),
                routeLevel: 1,
                activeWhenEqual: [ 'store' ],
                items: [
                    {
                        type: 'link',
                        isReactLink: true,
                        url: '/store/upload-data',
                        title: t('menu.uploadData'),
                        routeLevel: 2,
                        activeWhenEqual: [ 'upload-data' ],
                    },
                    {
                        type: 'link',
                        isReactLink: true,
                        url: '/store/uploads-list',
                        title: t('menu.uploads'),
                        routeLevel: 2,
                        activeWhenEqual: [ 'uploads-list' ],
                    },
                    (
                        isSupportUser
                            ? {
                                type: 'link',
                                isReactLink: true,
                                url: '/store/system-upload',
                                title: t('menu.systemSource'),
                                routeLevel: 2,
                                activeWhenEqual: [ 'system-upload' ],
                            } : null
                    ),
                    (
                        rights.hasStoreSurveyAccess
                            ? {
                                type: 'link',
                                isReactLink: true,
                                url: '/store/surveys-list',
                                title: t('menu.surveys'),
                                routeLevel: 2,
                                activeWhenEqual: [ 'surveys-list' ],
                            } : null
                    ),
                    {
                        type: 'link',
                        isReactLink: true,
                        url: '/store/recoders',
                        title: t('menu.recodedVariables'),
                        routeLevel: 2,
                        activeWhenEqual: [ 'recoders' ],
                    },
                    {
                        type: 'link',
                        isReactLink: true,
                        url: '/store/virtual-sources',
                        title: t('menu.virtualSources'),
                        routeLevel: 2,
                        activeWhenEqual: [ 'virtual-sources', 'virtual-source' ],
                    },
                ],
            },
            {
                title: 'Mine',
                counter: +counters.mineCount,
                hasAccess: rights.hasMineAccess,
                icons: {
                    def: mineIcon,
                    active: mineActiveIcon,
                },
                countPrefix: `quer${ +counters.mineCount !== 1 ? 'ies' : 'y' }`,
                routeLevel: 1,
                activeWhenEqual: [ 'mine' ],
                isPro: rights.hasMineInsightAccess,
                items: [
                    {
                        type: 'link',
                        title: 'Queries',
                        isReactLink: true,
                        url: '/mine/query/list-queries',
                        routeLevel: 2,
                        activeWhenEqual: [ 'query' ],
                    },
                    {
                        type: 'link',
                        title: 'Dictionaries',
                        isReactLink: true,
                        url: '/mine/dictionaries',
                        routeLevel: 2,
                        activeWhenEqual: [ 'dictionaries' ],
                    },
                ],
            },
            {
                title: 'Classify',
                counter: +counters.classifyCount,
                hasAccess: rights.hasClassifyAccess,
                icons: {
                    def: classifyIcon,
                    active: classifyActiveIcon,
                },
                countPrefix: `classifier${ setPlural(counters.classifyCount) }`,
                routeLevel: 1,
                activeWhenEqual: [ 'classify' ],
                items: [
                    {
                        type: 'link',
                        title: 'Upload data',
                        isReactLink: false,
                        url: '/classify/files',
                    },
                    {
                        type: 'link',
                        title: 'Classifiers',
                        isReactLink: false,
                        url: '/classify/classifier',
                    },
                    {
                        type: 'link',
                        title: 'Single text processing',
                        isReactLink: false,
                        url: '/classify/single-text',
                    },
                    {
                        type: 'link',
                        title: 'Statistics',
                        isReactLink: false,
                        url: '/classify/stats/overview',
                    },
                    {
                        type: 'link',
                        title: 'Export data',
                        isReactLink: false,
                        url: '/classify/output',
                    },
                ],
            },
            {
                title: t('menu.visual'),
                url: '/dashboard/',
                hasAccess: rights.hasVisualAccessV1,
                counter: +counters.visualCount,
                icons: {
                    def: visualIcon,
                    active: visualActiveIcon,
                },
                items: [],
                countPrefix: t('menu.dashboard', { count: counters.visualCount }),
                routeLevel: 1,
                activeWhenEqual: [ 'dashboard' ],
            },
            {
                title: t('menu.visualV2'),
                hasAccess: rights.hasVisualAccess,
                counter: +counters.visualV2Count,
                isReactLink: true,
                isBeta: true,
                url: '/visualV2',
                icons: {
                    def: visualIcon,
                    active: visualActiveIcon,
                },
                items: [],
                routeLevel: 1,
                countPrefix: t('menu.dashboard', { count: counters.visualV2Count }),
                activeWhenEqual: [ 'visualV2' ],
            },
            {
                title: 'Labs',
                hasAccess: rights.hasLabsAccess && labs_access,
                counter: 1,
                icons: {
                    def: labsIcon,
                    active: labsActiveIcon,
                },
                countPrefix: 'products',
                items: [
                    {
                        type: 'link',
                        title: 'Zero shot',
                        isReactLink: true,
                        url: '/labs/zero-shot',
                    },
                ],
                routeLevel: 1,
                activeWhenEqual: [ 'labs' ],
            },
            {
                title: 'Act',
                url: '/act',
                isReactLink: true,
                hasAccess: rights.hasActAccess && ticketSystemUseIframe,
                hideInfo: true,
                counter: 0,
                icons: {
                    def: vocActIcon,
                    active: vocActActiveIcon,
                },
                countPrefix: `act${ setPlural(counters.monitoringCount) }`,
                items: [],
                routeLevel: 1,
                activeWhenEqual: [ 'act' ],
            },
            {
                title: 'CI',
                url: '/voc_ci',
                isReactLink: true,
                hasAccess: rights.hasVocCIAccess,
                hideInfo: true,
                counter: 0,
                icons: {
                    def: vocCiIcon,
                    active: vocCiIActiveIcon,
                },
                countPrefix: `dashboard${ setPlural(counters.monitoringCount) }`,
                items: [],
                routeLevel: 1,
                activeWhenEqual: [ 'voc_ci' ],
            },
            {
                title: 'AI',
                isBeta: true,
                hasAccess: rights.hasVocAIAccess,
                hideInfo: true,
                counter: 0,
                icons: {
                    def: aiIcon,
                    active: aiActiveIcon,
                },
                items: [
                    {
                        type: 'link',
                        title: 'TopicAI',
                        isReactLink: true,
                        url: '/ai',
                    },
                ],
                routeLevel: 1,
                activeWhenEqual: [ 'ai' ],
            },
        ];
    });
