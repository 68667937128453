import React from 'react';
import cx from 'classnames';

import { GadgetPopover } from '../Gadget/components';
import { RangeSlider } from './RangeSlider';

import { IPopoverRangeSlider, TRangeSliderHandle } from './models';

import styles from './style.module.scss';

export const PopoverRangeSlider = ({
    hovered = false,
    sliderData,
    chartContainerRef,
    onChangeSlider,
    onStartSliding,
    onEndSliding,
    tooltipValueFormatter,
}: IPopoverRangeSlider) => {
    const showPopover = sliderData && !sliderData.disableSlider;

    const handleStart = ({ values, setIsDisableHidePopover }: TRangeSliderHandle) => {
        // disable popover hiding when customer keeps the mark pressed
        // disable when slider not visible (on mount)
        setIsDisableHidePopover(true);
        document.body.style.cursor = 'grabbing';

        onStartSliding && onStartSliding(values);
    };

    const handleEnd = ({ values, setIsDisableHidePopover }: TRangeSliderHandle) => {
        // enable popover hiding when customer end dragging
        setIsDisableHidePopover(false);
        document.body.style.cursor = '';

        onEndSliding && onEndSliding(values);
    };

    return (
        <GadgetPopover
            containerRef={ chartContainerRef }
            placement='bottom'
            popperInnerClassName={ styles.popperInnerClass }
            popoverInlineStyle={{ width: chartContainerRef?.current?.clientWidth || 0 }}
            hovered={ hovered }
            showPopover={ showPopover }
            delay={{ show: 300, hide: 600 }}
        >
            {
                ({ setIsDisableHidePopover }) => (
                    <div className={ cx(styles.rangeWrapper) }>
                        <RangeSlider
                            step={ 1 }
                            min={ sliderData.min }
                            max={ sliderData.max }
                            values={ sliderData.value as number[] }
                            disabled={ sliderData.disableSlider }
                            colors={{
                                rail: '#ccc',
                                track: [ '#337ab7' ],
                                hand: [ '#337ab7', '#337ab7' ],
                            }}
                            handleStyles={ [
                                { height: 20, width: 20 },
                                { height: 20, width: 20 },
                            ] }
                            draggableTrack
                            allowOverlap
                            onChange={ onChangeSlider }
                            onBeforeChange={ values => handleStart({ values, setIsDisableHidePopover }) }
                            onAfterChange={ values => handleEnd({ values, setIsDisableHidePopover }) }
                            tooltipValueFormatter={ tooltipValueFormatter }
                        />
                    </div>
                )
            }
        </GadgetPopover>
    );
};
