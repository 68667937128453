import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import { PopoverList } from '/visual/components';
import { VocRightsHOC } from '/components/VocRightsHOC';
import { DashboardService } from '/visual/scenes/Dashboard/services';
import { TAction, EExportFormats } from '/visual/models';
import { IEditMenu } from './models';

import styles from './style.module.scss';

const EditMenu = ({
    disabled,
    dashboard,
    rights,
    openInviteModal,
    onToggleModal,
    onMakeDefault,
    onMakeFavourite,
    onExport,
    onCopy,
}: IEditMenu) => {
    const { t } = useTranslation();
    const hasParentDashboard = DashboardService.isHasParentDashboard(dashboard);

    const actionsList: TAction[] = [
        {
            title: t('renameDashboard'),
            icon: 'pencil-alt' as IconProp,
            type: 'renameDashboard',
            disabled: !rights.isPermitRename,
            hidden: !rights.hasVisualWriteAccess,
        },
        {
            title: t(dashboard?.isFavourite ? 'removeFavorite' : 'makeFavorite'),
            icon: 'bookmark' as IconProp,
            hidden: hasParentDashboard,
            disabled: false,
            onClick: onMakeFavourite,
        },
        {
            title: t(dashboard?.isDefault ? 'removeDefault' : 'makeDefault'),
            icon: 'star' as IconProp,
            hidden: !rights.hasVisualWriteAccess || hasParentDashboard,
            disabled: !rights.isPermitDefaultDashboard,
            onClick: onMakeDefault,
        },
        {
            title: t('editSecurity'),
            icon: 'eye' as IconProp,
            hidden: !rights.hasVisualWriteAccess || hasParentDashboard,
            disabled: !rights.isPermitShare,
            onClick: () => openInviteModal(dashboard.id),
        },
        {
            title: t('sendToEmail'),
            icon: 'envelope' as IconProp,
            type: 'sendToEmail',
            disabled: !rights.isPermitSend,
        },
        {
            title: t('copyDashboard'),
            icon: 'copy' as IconProp,
            disabled: !rights.isPermitCopy,
            hidden: !rights.hasVisualWriteAccess || hasParentDashboard,
            onClick: onCopy,
        },
        {
            title: t('scheduler'),
            icon: 'clock' as IconProp,
            type: 'scheduler',
            hidden: !rights.hasVisualWriteAccess,
            disabled: !rights.isPermitScheduler,
        },
        {
            title: `${ t('exportTo') }PDF`,
            icon: 'file-pdf' as IconProp,
            disabled: !rights.isPermitPrint,
            onClick: () => onExport(EExportFormats.PDF),
        },
        {
            title: `${ t('exportTo') }PPTX`,
            icon: 'file-powerpoint' as IconProp,
            disabled: !rights.isPermitPrint,
            onClick: () => onExport(EExportFormats.PPTX),
        },
        {
            title: t('deleteDashboard'),
            icon: 'trash' as IconProp,
            type: 'deleteDashboard',
            hidden: !rights.hasVisualWriteAccess,
            disabled: !rights.isPermitDelete,
        },
    ];

    return (
        <div className={ styles.actionMenuWrapper }>
            <PopoverList
                target={ `menuButtonDashboard_${dashboard.id}` }
                disabled={ disabled || !dashboard.id }
                tagClassName={ styles.actionButton }
                buttonColor='primary'
                placement='left-start'
                actionList={ actionsList }
                onToggleModal={ (type: string) => onToggleModal({ type, dashboardId: dashboard.id }) }
            />
        </div>
    );
};

export default VocRightsHOC({
    type: 'visual',
})(EditMenu);
