import { createSelector } from "reselect";
import { HelperService } from "/services";
import { getFormValues } from "redux-form";

export const dictionaryFormValuesSelector = getFormValues('DictionaryForm');

export const _dictionariesSliceSelector = state => state.VocMine.dictionariesList;
export const dictionariesDataSelector = state => _dictionariesSliceSelector(state).results.data;
export const nestedDictionariesSelector = state => _dictionariesSliceSelector(state).nestedDictionaries;
export const activeDictionarySelector = state => _dictionariesSliceSelector(state).activeDictionary;
export const dictionariesTotalSelector = state => _dictionariesSliceSelector(state).results.count;
export const dictionariesLoadingSelector = state => _dictionariesSliceSelector(state).loading;
export const addEditLoadingSelector = state => _dictionariesSliceSelector(state).addEditLoading;
export const preprocessLoadingSelector = state => _dictionariesSliceSelector(state).preprocess.loading;
export const dictionariesFiltersSelector = state => _dictionariesSliceSelector(state).filters;
export const dictionariesExtraFiltersSelector = state => _dictionariesSliceSelector(state).filters.extraFilters;
export const dictionaryTitlesDataSelector = state => _dictionariesSliceSelector(state).titles;
export const dictionaryLanguagesDataSelector = state => _dictionariesSliceSelector(state).languages;
export const dictionaryLemmatesDataSelector = state => _dictionariesSliceSelector(state).lemmata;
export const _userInfoSelector = state => state.UserInfo.userInfo;

export const loadingSelector = createSelector([ dictionariesLoadingSelector, addEditLoadingSelector, preprocessLoadingSelector ],
    (dictionariesLoading, addEditLoading, preprocessLoading) =>
        dictionariesLoading || addEditLoading || preprocessLoading,
);

export const dictionariesWithExtraFiltersDropped = createSelector(
    [ _dictionariesSliceSelector, dictionariesFiltersSelector ],
    ({ filters }, { extraFilters }) => {
        const extraFiltersDropped = Object.keys(extraFilters)
            .reduce((acc, cur) => ({ ...acc, [cur]: extraFilters[cur] || [] }), {});

        return ({ ...filters, extraFilters: extraFiltersDropped });
    });

export const dictionariesListSelector = createSelector(
    [ dictionariesDataSelector ],
    dictionariesList => (
        dictionariesList.map(dictionary => {
            const { id, title, language, lemmata, currentUserPermission } = dictionary;

            return ({
                ...dictionary,
                lemmata: lemmata.join(', '),
                user: id,
                actions: {
                    values: {
                        language,
                        title,
                        lemmata: lemmata.join(', '),
                    },
                    id,
                    currentUserPermission,
                },
            });
        })
    ));

export const dictionaryTitlesSelector = createSelector(
    [ dictionaryTitlesDataSelector, dictionariesFiltersSelector ],
    ({ data, loading }, { extraFilters }) => ({
        options: HelperService.mapArrayToSelectModel(data),
        selected: [ ...extraFilters.title ],
        loading,
    }),
);

export const dictionaryLanguagesSelector = createSelector(
    [ dictionaryLanguagesDataSelector, dictionariesFiltersSelector ],
    ({ data, loading }, { extraFilters }) => ({
        options: HelperService.mapArrayToSelectModel(data),
        selected: [ ...extraFilters.language ],
        loading,
    }),
);

export const dictionaryLemmatesSelector = createSelector(
    [ dictionaryLemmatesDataSelector, dictionariesFiltersSelector ],
    ({ data, loading }, { extraFilters }) => ({
        options: HelperService.mapArrayToSelectModel(data),
        selected: [ ...extraFilters.lemmata ],
        loading,
    }),
);

export const dictionaryAllLanguagesSelector = createSelector(
    [ _userInfoSelector ],
    ({ languages }) => {
        return languages.map(({ value }) => ({
            value,
            label: value.toUpperCase(),
        }));
    },
);
