import {
    PaginatedLayout, TPageLayout,
    TPlaceholderObject, TPlaceholders,
    DashboardPageType,
} from '/visual/scenes/Dashboard/models';
import { EGadgetType } from '/visual/models';
import { HelperService } from '/services';

export class PlaceholdersService {
    static GRID_HEIGHT: number;
    static GRID_WIDTH: number;
    static SHAPES: [number, number][] = [[ 1, 2 ], [ 3, 8 ], [ 6, 12 ]];
    static minSpace = {
        gadget: { h: 8, w: 3 },
        mediaBlock: { h: 2, w: 1 },
    };

    private static createLayoutMatrix() {
        return Array(PlaceholdersService.GRID_HEIGHT)
            .fill(Array(PlaceholdersService.GRID_WIDTH).fill(null))
            .map((item: Array<any>) => item.map((item, index) => index));
    }

    private static getEmptySpacesMatrix(layoutMatrix: number[][], layout: Array<PaginatedLayout>) {
        return layoutMatrix.map((item, yIndex) => {
            return item.map(value => {
                const target = { x: value, y: yIndex };
                const yFilledValues = layout.filter(layout => layout.y + layout.h > target.y && layout.y <= target.y);

                const emptyXCoords = yFilledValues.reduce((acc, item) => {
                    acc.splice(item.x, item.w, ...Array(item.w).fill(null));

                    return acc;
                }, Array(PlaceholdersService.GRID_WIDTH).fill(null).map((item, index) => index));

                return emptyXCoords.includes(value)
                    ? value
                    : null;
            });
        });
    }

    static getPageEmptyPlaceholders(
        { layout }: { layout: Array<PaginatedLayout> },
        { x, y }: TPageLayout,
        gadgetClipboardSize: null | [number, number],
    ) {
        PlaceholdersService.GRID_HEIGHT = y;
        PlaceholdersService.GRID_WIDTH = x;

        const layoutMatrix = PlaceholdersService.createLayoutMatrix();
        const emptySpacesMatrix = PlaceholdersService.getEmptySpacesMatrix(layoutMatrix, layout);
        const placeholders = PlaceholdersService.findPlaceholders(emptySpacesMatrix, gadgetClipboardSize);
        const filteredPlaceholders: TPlaceholders = {};

        // filtering: only one placeholder per row
        Object.keys(placeholders)
            .map(el => {
                filteredPlaceholders[el] = [ PlaceholdersService.filterPlaceholders(placeholders[el]) ];
            });

        return PlaceholdersService.removeOverlapping(filteredPlaceholders);
    }

    private static findPlaceholders(
        emptySpacesMatrix: (number | null)[][],
        gadgetClipboardSize: null | [number, number],
    ) {
        let placeholders: TPlaceholders = {};
        const SHAPES = gadgetClipboardSize
            ? [ gadgetClipboardSize ]
            : PlaceholdersService.SHAPES;

        // Iterate over shapes and grid positions to find suitable locations
        SHAPES.forEach(shape => {
            for (let rowNumber = 0; rowNumber < PlaceholdersService.GRID_HEIGHT; rowNumber++) {
                for (let column = 0; column < PlaceholdersService.GRID_WIDTH; column++) {
                    if (PlaceholdersService.shapeFits(column, rowNumber, shape, emptySpacesMatrix)) {
                        placeholders = {
                            ...placeholders,
                            [rowNumber]: [
                                ...placeholders[rowNumber]
                                    ? placeholders[rowNumber]
                                    : [],
                                {
                                    minX: column,
                                    maxW: shape[0],
                                    minY: rowNumber,
                                    maxY: rowNumber + shape[1] - 1,
                                },
                            ],
                        };
                    }
                }
            }
        });

        return placeholders;
    }

    // Check if the shape fits in the current position
    private static shapeFits(x: number, y: number, shape: number[], emptySpacesMatrix: (number | null)[][]) {
        const [ shapeWidth, shapeHeight ] = shape;

        // Check if the placement of the shape exceeds the grid boundaries in width or height
        // If so, the shape cannot be placed, so return false
        if (x + shapeWidth > PlaceholdersService.GRID_WIDTH || y + shapeHeight > PlaceholdersService.GRID_HEIGHT) {
            return false;
        }

        // Check if there is free space above the current position
        // If there is, the shape can "move up", so placement is not possible
        if (PlaceholdersService.isSpaceAboveFree(x, y, shapeWidth, emptySpacesMatrix)) {
            return false;
        }

        // Iterating through each cell of the grid that the shape would occupy, starting at position (x, y)
        for (let i = y; i < y + shapeHeight; i++) {
            for (let j = x; j < x + shapeWidth; j++) {
                // Checking if the grid cell is already occupied (null means the cell is occupied)
                if (emptySpacesMatrix[i][j] === null) {
                    return false;
                }
            }
        }

        return true;
    }

    // Check if there is free space above the current position
    private static isSpaceAboveFree(x: number, y: number, shapeWidth: number, emptySpacesMatrix: (number | null)[][]) {
        // If the shape is at the very top of the grid, there cannot be free space above
        if (y === 0) return false;

        // Iterating through the width of the shape to check if there are occupied cells above
        for (let j = x; j < x + shapeWidth; j++) {
            if (emptySpacesMatrix[y - 1][j] === null) {
                return false;
            }
        }

        return true;
    }

    private static removeOverlapping(placeholders: TPlaceholders) {
        Object.keys(placeholders)
            .forEach((currentRow, i, keys) => {
                const currentPlaceholder = placeholders[currentRow]?.[0];

                if(currentPlaceholder) {
                    const nextRowsKeys = keys.slice(i + 1);

                    // Check for overlap with placeholders in lower rows
                    nextRowsKeys.forEach(nextRow => {
                        const nextPlaceholder = placeholders[nextRow]?.[0];

                        // Check for vertical (Y-axis) overlap
                        if (nextPlaceholder && currentPlaceholder.maxY >= nextPlaceholder.minY) {
                            // Check for horizontal (X-axis) overlap
                            const currentPlaceholderCoordinates = {
                                start: currentPlaceholder.minX,
                                end: currentPlaceholder.minX + currentPlaceholder.maxW,
                            };

                            const nextPlaceholderCoordinates = {
                                start: nextPlaceholder.minX,
                                end: nextPlaceholder.minX + nextPlaceholder.maxW,
                            };

                            if (
                                currentPlaceholderCoordinates.start <= nextPlaceholderCoordinates.end
                                && nextPlaceholderCoordinates.start <= currentPlaceholderCoordinates.end
                            ) {
                                // Determine which shape is larger
                                const currentArea = (currentPlaceholder.maxY - currentPlaceholder.minY) * currentPlaceholder.maxW;
                                const nextArea = (nextPlaceholder.maxY - nextPlaceholder.minY) * nextPlaceholder.maxW;

                                if (currentArea >= nextArea) {
                                    // Remove the smaller placeholder from the lower row
                                    delete placeholders[nextRow];
                                } else {
                                    // Remove the smaller placeholder from the current row
                                    delete placeholders[currentRow];
                                }
                            }
                        }
                    });
                }
            });

        return placeholders;
    }

    private static filterPlaceholders(arr: TPlaceholderObject[] ): TPlaceholderObject {
        // Find the largest maxW
        const maxW = Math.max(...arr.map(item => item.maxW));

        // Filter the array to only include items with the largest maxW
        const filtered = arr.filter(item => item.maxW === maxW);

        // If there's only one item, return it
        if (filtered.length === 1) {
            return filtered[0];
        }

        // Otherwise, find the item with the smallest minX
        return filtered.reduce((prev, curr) => (prev.minX < curr.minX ? prev : curr));
    }

    private static getNearestNotFullPage(type: string, pages: DashboardPageType[]) {
        return pages.reduce((acc: null | { page: string, placeholder: TPlaceholderObject }, page) => {
            const placeholder = acc === null
                && !HelperService.isEmptyObject(page.placeholders)
                && HelperService.getObjectValue(page.placeholders)
                    .map(data => {
                        const isEnoughSpace = type === EGadgetType.SUPER_CHART
                            ? data[0].maxW >= PlaceholdersService.minSpace.gadget.w
                            : data[0].maxW >= PlaceholdersService.minSpace.mediaBlock.w;

                        return isEnoughSpace && data[0];
                    })
                    .filter(placeholder => placeholder);

            return placeholder && placeholder.length
                ? { page: page.page, placeholder: { ...placeholder[0] } }
                : acc;
        }, null);
    }

    static getNearestPlaceholder(type: string, pages: DashboardPageType[]) {
        const notFullPage = PlaceholdersService.getNearestNotFullPage(type, pages);
        let coordinate = {};

        if(notFullPage) {
            coordinate = {
                coordinate: {
                    ...type === EGadgetType.SUPER_CHART
                        ? PlaceholdersService.minSpace.gadget
                        : PlaceholdersService.minSpace.mediaBlock,
                    x: notFullPage.placeholder.minX,
                    y: notFullPage.placeholder.minY,
                },
            };
        }

        return {
            pageId: notFullPage?.page,
            ...coordinate,
        };
    }
}
