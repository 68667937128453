import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { TooltipWrapper } from '/components';
import { IFavouriteButton } from './models';

import styles from './style.module.scss';

export const FavouriteButton = ({ isFavourite, onClick }: IFavouriteButton) => {
    const { t } = useTranslation();

    return (
        <TooltipWrapper value={ t(isFavourite ? 'removeFavorite' : 'makeFavorite') } force>
            <span
                className={ styles.isActiveIconWrapper }
                onClick={ onClick }
            >
                <FontAwesomeIcon
                    icon={ 'bookmark' }
                    className={ cx({ [styles.activeIcon]: isFavourite }) }
                />
            </span>
        </TooltipWrapper>
    );
};
