import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { reduxForm, Field, FieldArray } from 'redux-form';

import { Button, Row, Col, Label, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocFormSelectField, VocModal } from '/components';

import { HelperService } from '/services';
import { renderConditionFlow } from './renderConditionFlow';
import { questionFlowFormValidation } from './QuestionFlowFormValidation';

import { QuestionFlowConditionString } from '/feedback/components';
import './QuestionFlows.scss';

@reduxForm({
    form: 'QuestionFlowForm',
    enableReinitialize: true,
    validate: questionFlowFormValidation,
})
export class QuestionFlowForm extends PureComponent {
    @autobind
    addCondition() {
        const { array, initialValues, currentFlowQuestion, flowOptions } = this.props;

        const answerList = flowOptions[0].question.find(({ label }) => label === 'Answer');
        const question_id = answerList.options
            .find(({ value }) => value === currentFlowQuestion.value) || null;
        const conditions = [{ ...initialValues.conditions, question_id }];

        array.unshift(`conditions`, ...conditions);
    }

    @autobind
    changeCondition(index, el, field) {
        if (field !== 'matching_value') {
            this.props.change(`conditions[${ index }]matching_value`, null);
        }

        if (field === 'question_id') {
            this.props.change(`conditions[${ index }]expression`, null);
        }
    }

    changeActionType() {
        this.props.change(`next_question`, null);
        this.props.change(`digi_runner_command`, null);
        this.props.change(`change_language_command`, null);
        this.props.change(`next_language`, null);
    }

    @autobind
    getActionTypeOptions() {
        const {
            flowOptions,
            parentId,
            activeChannel,
        } = this.props;

        const { actionTypes } = flowOptions[0];

        let filteredActionTypes = [];

        if(parentId === 'root' || activeChannel !== 'DIGI') {
            filteredActionTypes = actionTypes;
        } else {
            filteredActionTypes = actionTypes.filter(action => action.text === 'Skip');
        }

        if(activeChannel !== 'DIGI') {
            filteredActionTypes = actionTypes.filter(action => action.text !== "digi_runner_command");

            if(activeChannel === 'WEB') {
                filteredActionTypes = filteredActionTypes.filter(action => action.text !== "change_language_command");
            }
        }

        return filteredActionTypes;
    }

    @autobind
    getFieldForGoToOrCommand(actionType, options) {
        const actionTypeGoTo = 'GoTo';
        const actionTypeCommand = 'digi_runner_command';
        const actionChangeLanguage = 'change_language_command';

        const isCommandActionType = actionType.includes(actionTypeCommand);
        const isChangeLAnguageActionType = actionType.includes(actionChangeLanguage);

        const showField = [ actionTypeGoTo, actionTypeCommand, actionChangeLanguage ].includes(actionType);

        let config = {
            className: 'next-question__margin',
            name: 'next_question',
            options: options[0].questionForNext,
            placeholder: 'Target question',
            format: value => HelperService.getSelectedValue(options[0].questionForNext, value),
            normalize: HelperService.normalizeSelectValue,
            component: VocFormSelectField,
            required: true,
            portal: true,
        };

        if(showField && isCommandActionType) {
            config = {
                ...config,
                placeholder: 'Select command',
                name: 'digi_runner_command',
                options: options[0].optionForCommand,
                format: value => {
                    return HelperService.getSelectedValue(options[0].optionForCommand, value?.toString());
                },
            };
        }

        if(showField && isChangeLAnguageActionType) {
            config = {
                ...config,
                placeholder: 'Select language',
                name: 'change_language_command',
                options: options[0].optionForChangeLanguage,
                format: value => {
                    return HelperService.getSelectedValue(options[0].optionForChangeLanguage, value);
                },
            };
        }

        return (
            showField
            && <Col md={ 12 }>
                <Field { ...config } />
            </Col>
        );
    }

    @autobind
    getModalBody() {
        const {
            formValuesSelector: {
                action_type,
                conditions = [],
            },
            flowOptions,
            activeChannel,
        } = this.props;

        const { optionForCommand, optionForChangeLanguage } = flowOptions[0];

        return (
            <div className={ 'flows-questions__modal-body' }>

                <Row className={ 'flows-questions__row' }>
                    <Col md={ 12 }>
                        <Label htmlFor={ 'action_type' }>
                            Action type
                        </Label>
                    </Col>
                    <Col md={ 12 }>
                        <Field
                            name={ 'action_type' }
                            component={ VocFormSelectField }
                            options={ this.getActionTypeOptions() }
                            onChange={ el => this.changeActionType(el) }
                            format={ value => HelperService.getSelectedValue(this.getActionTypeOptions(), value?.value) }
                            className={ 'no-margin' }
                            withError={ false }
                            portal
                        />
                    </Col>

                    {
                        action_type?.text && this.getFieldForGoToOrCommand(action_type.text, flowOptions)
                    }

                </Row>

                <Row className="justify-content-between conditions__contr">

                    <Col md={ 3 }>
                        <label htmlFor={ 'logical_operators' }>Join conditions with</label>
                        <Field
                            name={ 'logical_operation' }
                            component={ VocFormSelectField }
                            options={ flowOptions[0].logicalOperation }
                            normalize={ HelperService.normalizeSelectValue }
                            format={ value => HelperService.getSelectedValue(flowOptions[0].logicalOperation, value) }
                        />
                    </Col>

                    <Col md={ 4 } className={ 'text-right add-condition__wrap' }>
                        <Button outline color="primary" onClick={ this.addCondition }>
                            <FontAwesomeIcon icon={ 'plus' }/>
                            <span className="button-primary__text">Add condition</span>
                        </Button>
                    </Col>
                </Row>
                {
                    conditions.length
                        ? <>
                            <Row>
                                <Col md={ 12 }>
                                    <div className="conditions__header">
                                        <Row>
                                            <Col md={ 4 }> Variable </Col>
                                            <Col md={ 4 }> Condition </Col>
                                            <Col md={ 4 }> Value </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="conditions__box">
                                <Container>
                                    <FieldArray
                                        name="conditions"
                                        component={ renderConditionFlow }
                                        flowOptions={ flowOptions }
                                        changeCondition={ this.changeCondition }
                                    />
                                </Container>
                            </Row>
                        </>
                        : <Row className="conditions__box">
                            <Col md={ 12 } className={ 'condition__empty' }>
                                Create conditions
                                { activeChannel !== "WEB" ? ` or use like unconditional action` : '' }
                            </Col>
                        </Row>

                }

                <Row className={ 'flows-questions__combined-condition text-right' }>
                    <QuestionFlowConditionString
                        formValuesSelector={ this.props.formValuesSelector }
                        questionsList={ flowOptions[0].question }
                        getMatchingValue={ this.getMatchingValue }
                        optionForCommand={ optionForCommand }
                        optionForChangeLanguage={ optionForChangeLanguage }
                    />
                </Row>
            </div>
        );
    }

    getConditionsForSubmit(){
        const {
            formValuesSelector: {
                conditions,
                enterpriseId,
            },
        } = this.props;

        return conditions.length
            ? conditions.map(({ expression, question_id, matching_value }) => {
                //case for creation custom metadata
                if (question_id.condition_type === undefined) {
                    question_id.condition_type = 'metadata';
                    question_id.question_type = 'metadata';
                }

                const classifierConditionType = expression.condition_type;
                let matchingValue = matching_value && (matching_value.value || matching_value.value === 0)
                    ? matching_value.value
                    : matching_value;

                if (matchingValue !== null && typeof matchingValue !== 'number') {
                    matchingValue = matchingValue.trim();
                }

                const condition = {
                    condition_type: classifierConditionType ? classifierConditionType : question_id.condition_type,
                    value: classifierConditionType
                        ? {
                            classifier_id: expression.value,
                            category_id: matchingValue,
                            enterprise_id: enterpriseId,
                        }
                        : {
                            expression: expression.value,
                            matching_value: matchingValue,
                            enterprise_id: enterpriseId,
                        },
                };

                if (question_id.condition_type !== 'random_100') {
                    condition.value[`${
                        question_id.condition_type === 'answer'
                            ? 'question_id'
                            : 'metadata_name'
                    }`] = question_id.value;
                }


                return {
                    ...condition,
                };
            })
            : null;
    }

    @autobind
    onSubmit() {
        const {
            formValuesSelector,
            submitFlow,
            questionId,
            surveyId,
            surveyGroup,
            toggleIsUnsubscribeGroup,
            currentFlowQuestion: { question_type },
        } = this.props;
        const {
            action_type,
            logical_operation,
            question,
            next_question = null,
            survey,
            id,
            rule_priority,
            digi_runner_command,
            change_language_command,
        } = formValuesSelector;

        surveyGroup.isUnsubscribeSurvey
        && question_type === 'Unsubscribe'
        && action_type.text === "Skip"
        && toggleIsUnsubscribeGroup(false);

        const data = {
            action_type: action_type.value,
            logical_operation,
            next_question: action_type.text === "Skip" ? questionId : next_question,
            survey: survey ? survey : surveyId,
            question: question ? question : questionId,
            conditions: this.getConditionsForSubmit(),
            digi_runner_command: digi_runner_command && action_type.text === 'digi_runner_command'
                ? [ digi_runner_command ]
                : null,
            next_language: change_language_command && action_type.text === 'change_language_command'
                ? change_language_command
                : null,
        };

        rule_priority && (
            data['rule_priority'] = rule_priority
        );

        submitFlow(data, id).promise.then(this.close);
    }

    @autobind
    close() {
        const { reset, close } = this.props;

        reset();
        close();
    }

    @autobind
    getFooter() {
        const {
            valid, statusFlow,
            activeChannel,
            isUpdateFlow,
            parentId,
            currentFlowQuestion,
            formValuesSelector: { conditions = [], action_type },
            surveyGroup,
        } = this.props;

        let { dirty } = this.props;

        if(!isUpdateFlow && activeChannel === "DIGI" && parentId !== "root") {
            dirty = true;
        }

        const isUnsubscribeSkiped = action_type?.text === 'Skip'
            && surveyGroup.isUnsubscribeSurvey
            && currentFlowQuestion?.question_type === 'Unsubscribe';

        return (
            <div className={ 'question-flow_footer-buttons' }>
                <span className={ 'question-flow_footer-warning' }>
                    {
                        isUnsubscribeSkiped
                            ? `Survey will be detached from all survey groups and 
                        units for which it used as "Unsubscribe survey"`
                            : ''
                    }
                </span>
                <div>
                    <Button onClick={ this.close } color={ 'white' } outline>
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            !dirty
                            || !valid
                            || statusFlow.flowSubmitted
                            || (activeChannel === "WEB" && !conditions.length)
                        }
                        onClick={ this.onSubmit }
                        color="primary"
                    >
                        <FontAwesomeIcon icon={ 'check' }/>
                        Save
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const { show } = this.props;

        return (
            <VocModal
                toggle={ this.close }
                header={ 'Question Flow' }
                isOpen={ show }
                footer={ this.getFooter() }
                size={ 'lg' }
                modalClassName="modal-scrollable"
            >
                {
                    this.getModalBody()
                }
            </VocModal>
        );
    }
}
