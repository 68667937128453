import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { VocMenuItem } from './components/VocMenuItem/';
import { menuSelector } from './modules/selectors';
import { OutsideAlerter } from '/components';

import './VocMenu.scss';

const mapStateToProps = state => ({
    menu: menuSelector(state),
});

@connect(mapStateToProps, {})
export class VocMenu extends Component {
    state = {
        active: null,
        open: false,
        openMobMenu: false,
    };

    @autobind
    toggleMenuItem(item) {
        this.setState({
            active: item === this.state.active ? null : item,
        });
    }

    @autobind
    closeMenu() {
        this.setState({
            open: false,
            active: null,
        });
    }

    @autobind
    closeMenuOutside() {
        this.setState({
            open: false,
            openMobMenu: false,
            active: null,
        });
    }

    @autobind
    showMenu() {
        this.setState({
            open: true,
            openMobMenu: true,
        });
    }

    @autobind
    toggleMenu() {
        this.setState({
            openMobMenu: !this.state.openMobMenu,
            active: null,
        });
    }

    renderMenuItems() {
        const { menu, onSelectHandler, activeRoute } = this.props;

        return menu
            .filter(menuItem => menuItem.hasAccess)
            .map((item, i) => {
                return (
                    <li className="navigation__list-item" key={ i }>
                        <VocMenuItem
                            { ...item }
                            toggle={ this.toggleMenuItem }
                            activeItem={ this.state.active }
                            activeRoute={ activeRoute }
                            onSelectHandler={ onSelectHandler }/>
                    </li>
                );
            });
    }

    render() {
        const { open, openMobMenu } = this.state;

        return (
            <>
                <OutsideAlerter handleClickOutside={ this.closeMenuOutside } condition={ open || openMobMenu }>
                    <div className={ `header__menu ${open || openMobMenu ? 'active' : ''}` }
                        onClick={ this.toggleMenu }
                    >
                        <div className="hamburger-icon">
                            <span className="line line-1"/>
                            <span className="line line-2"/>
                            <span className="line line-3"/>
                        </div>
                    </div>
                    <nav
                        className={ `navigation ${ open ? ' active' : '' } ${ openMobMenu ? ' active-mob' : '' }` }
                        onMouseLeave={ this.closeMenu }
                        onMouseEnter={ this.showMenu }
                    >
                        <ul className="navigation__list">
                            { this.renderMenuItems() }
                        </ul>
                    </nav>
                </OutsideAlerter>
            </>
        );
    }
}

VocMenu.propTypes = {
    rights: PropTypes.shape({
        hasFeedbackAccess: PropTypes.bool,
        hasStoreAccess: PropTypes.bool,
        hasClassifyAccess: PropTypes.bool,
        hasVisualAccess: PropTypes.bool,
        hasVisualWriteAccess: PropTypes.bool,
        hasLabsAccess: PropTypes.bool,
        hasMineAccess: PropTypes.bool,
    }),
    counters: PropTypes.shape({
        storeCount: PropTypes.number,
        mineCount: PropTypes.number,
        classifyCount: PropTypes.number,
        visualCount: PropTypes.number,
        visualV2Count: PropTypes.number,
        monitoringCount: PropTypes.number,
        feedBackCount: PropTypes.number,
    }),
    mineQueries: PropTypes.object,
};
