import React from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

import { VocModal } from "/components";
import { deleteGadget } from "/visual/scenes/Dashboard/modules/Dashboard.modules";

import style from './style.module.scss';

interface IModalProps {
    cleanupModal?: () => void;
    show: boolean,
    toggle: () => void;
    gadgetId: undefined | string;
    gadgetType: string | null;
}

export const DeleteGadgetModal = ({
    show,
    toggle,
    cleanupModal,
    gadgetId,
    gadgetType,
}: IModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onDeleteGadget = () => {
        toggle();
        dispatch(deleteGadget(gadgetId, gadgetType));
    };

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color='white'
                onClick={ toggle }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color='primary'
                onClick={ onDeleteGadget }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                { t('confirm') }
            </Button>
        </div>
    );

    return (
        <VocModal
            size='ms'
            isOpen={ show }
            modalClassName='modal-scrollable'
            header={ t('removeGadget') }
            footer={ getModalFooter() }
            toggle={ toggle }
            onClosed={ cleanupModal }
        >
            <p className={ style.modalText }>
                { t('areYouSureYouWantRemoveGadget') }
            </p>
        </VocModal>
    );
};
