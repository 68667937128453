import React from 'react';
import { useTranslation } from 'react-i18next';

import { VocTableV2, TableRightsColumn, VocRightsHOC } from '/components';

import { SurveyChannel } from '../SurveyChannel';
import { SurveysActionFormatter } from '../SurveysActionFormatter';

const SurveysTable = ({
    tableData,
    requestTrigger,
    updateFilters,
    loading,
    surveysCount,
    filters,
    getSharedRights,
    shares,
    showApplianceModal,
    showExportFilterModal,
    reLemmatization,
    showConfirm,
    openInviteModal,
}) => {
    const { t } = useTranslation();

    const getTableOptions = () => ({
        requestTrigger,
        haveSearch: true,
        updateFilters,
        rowStyle: {
            height: '38px',
        },
    });

    const channelFormatter = cell => {
        return <SurveyChannel { ...cell }/>;
    };

    const sharedFormatter = cell => {
        return (
            <TableRightsColumn
                { ...cell }
                getShares={ id => getSharedRights(id, 'survey') }
                shares={ shares }
            />
        );
    };

    const surveysActionsFormatter = cell => {
        return <SurveysActionFormatter
            { ...cell }
            showConfirm={ showConfirm }
            reLemmatization={ reLemmatization }
            showApplianceModal={ showApplianceModal }
            initiateSurveyExport={ showExportFilterModal }
            openInviteModal={ openInviteModal }
        />;
    };

    const getDataSettings = () => [
        {
            field: 'title',
            title: t('field.title'),
            formatter: 'conditionTooltip',
            sort: true,
        },
        {
            field: 'maxDate',
            title: t('field.lastUpdated'),
            formatter: 'date',
            sort: true,
        },
        {
            field: 'channel',
            title: t('field.channel'),
            formatter: channelFormatter,
            sort: true,
        },
        {
            field: 'languages',
            title: t('field.languages'),
            formatter: 'conditionTooltip',
        },
        {
            field: 'totalRowsCount',
            title: t('field.surveyTriggered'),
            sort: true,
            style: {
                textAlign: 'center',
            },
        },
        {
            field: 'shared',
            title: t('field.role'),
            formatter: sharedFormatter,
            style: {
                textAlign: 'center',
                width: '70px',
            },
        },
        {
            field: 'actionsData',
            title: t('field.actions'),
            formatter: surveysActionsFormatter,
            style: {
                width: '150px',
            },
        },
    ];

    return (
        <div>
            <VocTableV2
                data={ tableData }
                loading={ loading }
                total={ surveysCount }
                options={ getTableOptions() }
                dataSettings={ getDataSettings() }
                filters={ filters }
            />
        </div>
    );
};

export default VocRightsHOC({
    type: 'survey',
})(SurveysTable);
