import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import { PopoverList } from '/visual/components';
import { TAction, EExportFormats } from '/visual/models';
import { IActionMenu } from './models';

import styles from './style.module.scss';

export const ActionMenu = ({
    rights,
    dashboard,
    onDeleteDashboard,
    onCopyDashboard,
    openInviteModal,
    onSendToEmail,
    onScheduler,
    onMakeDefault,
    onEditDashboardTitle,
    onExport,
}: IActionMenu) => {
    const { t } = useTranslation();

    const actionsList: TAction[] = [
        {
            title: t('renameDashboard'),
            icon: 'pencil-alt' as IconProp,
            disabled: !rights.isPermitRename,
            hidden: !rights.hasVisualWriteAccess,
            onClick: onEditDashboardTitle,
        },
        {
            title: t(dashboard.isDefault ? 'removeDefault' : 'makeDefault'),
            icon: 'star' as IconProp,
            disabled: !rights.isPermitDefaultDashboard,
            hidden: !rights.hasVisualWriteAccess,
            onClick: onMakeDefault,
        },
        {
            title: t('editSecurity'),
            icon: 'eye' as IconProp,
            disabled: !rights.isPermitShare,
            hidden: !rights.hasVisualWriteAccess,
            onClick: openInviteModal,
        },
        {
            title: t('sendToEmail'),
            icon: 'envelope' as IconProp,
            disabled: !rights.isPermitSend,
            onClick: onSendToEmail,
        },
        {
            title: t('copyDashboard'),
            icon: 'copy' as IconProp,
            disabled: !rights.isPermitCopy,
            hidden: !rights.hasVisualWriteAccess,
            onClick: onCopyDashboard,
        },
        {
            title: t('scheduler'),
            icon: 'clock' as IconProp,
            disabled: !rights.isPermitScheduler,
            hidden: !rights.hasVisualWriteAccess,
            onClick: onScheduler,
        },
        {
            title: `${ t('exportTo') }PDF`,
            icon: 'file-pdf' as IconProp,
            disabled: !rights.isPermitPrint,
            onClick: () => onExport(EExportFormats.PDF),
        },
        {
            title: `${ t('exportTo') }PPTX`,
            icon: 'file-powerpoint' as IconProp,
            disabled: !rights.isPermitPrint,
            onClick: () => onExport(EExportFormats.PPTX),
        },
        {
            title: t('deleteDashboard'),
            icon: 'trash' as IconProp,
            disabled: !rights.isPermitDelete,
            hidden: !rights.hasVisualWriteAccess,
            onClick: onDeleteDashboard,
        },
    ];

    return (
        <div className={ styles.actionMenuWrapper }>
            <PopoverList
                target={ `menuButtonDashboards_${dashboard.id}` }
                tag='div'
                tagClassName={ styles.actionButton }
                placement='right-start'
                popperModifiers={{
                    offset: {
                        offset: '-5px, 0px',
                    },
                }}
                actionList={ actionsList }
            />
        </div>
    );
};
