import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TooltipWrapper } from '/components';
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import UserIcon from './images/user.svg';
import './VocUserDropdown.scss';

export class VocUserDropdown extends Component {
    userDropDownMenuItems = [
        {
            url: '/settings',
            title: 'Settings',
            target: 'react',
        },
        {
            url: '/user-profile',
            title: 'Profile',
        },
        {
            url: '/about',
            title: 'About Hub',
            target: 'react',
        },
        {
            divider: true,
            target: 'react',
        },
        {
            url: this.props.logout,
            title: 'Log out',
            isLogOut: true,
        },
    ];

    shouldComponentUpdate(nextProps) {
        return nextProps.firstName !== this.props.firstName || this.props.activeRoute !== nextProps.activeRoute;
    }

    renderDropDownItems(activeRoute) {
        return this.userDropDownMenuItems.map(({ url, title, target, divider, isLogOut }, index) => {
            if (target === 'react') {
                const active = url === activeRoute;

                return divider ? (
                    <DropdownItem divider key={ index } />
                ) : (
                    <Link key={ index }
                        to={ url }>
                        <DropdownItem className={ active ? 'active' : '' } tag={ 'span' }>{ title }</DropdownItem>
                    </Link>
                );
            } else {
                return <DropdownItem
                    href={ url }
                    key={ index }
                    onClick={ () => isLogOut && localStorage.removeItem('token') }
                >
                    { title }
                </DropdownItem>;
            }
        });
    }

    render() {
        const { firstName, activeRoute, className = 'user__dropdown' } = this.props;

        return (
            <UncontrolledDropdown className={ className }>
                <DropdownToggle tag={ 'div' } className={ 'vochub-header__user-name-item' }>
                    <img src={ UserIcon } alt=""/>
                </DropdownToggle>
                <DropdownMenu right className={ 'user__dropdown-menu' } modifiers={{
                    offset: {
                        offset: '28px, 25px',
                    },
                }}
                >
                    <span className="user__dropdown-arrow"/>
                    <DropdownItem header>
                        <TooltipWrapper value={ firstName }>
                            <span>
                                { firstName }
                            </span>
                        </TooltipWrapper>
                    </DropdownItem>
                    <DropdownItem divider />
                    { this.renderDropDownItems(activeRoute) }
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

VocUserDropdown.propTypes = {
    logout: PropTypes.string,
    firstName: PropTypes.string,
};
