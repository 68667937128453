import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

@withTranslation()
export class ConfirmDeleteVirtualContent extends PureComponent {
    mapSourceData() {
        const { virtualToDelete, t } = this.props;

        const sourceDataCollections = {
            'gadgets': t('gadgets'),
            'segmentators': t('recoders'),
            'virtualSources': t('virtualSources'),
            'queries': t('queries'),
        };

        return Object.entries(sourceDataCollections)
            .map(([ sourceDataCollection, title ]) => {
                if (virtualToDelete[sourceDataCollection].length) {
                    return (
                        <details className='upload-to-delete-details' key={ title }>
                            <summary>{ title }</summary>
                            <ul>
                                { this.mapCollectionItems(sourceDataCollection) }
                            </ul>
                        </details>
                    );
                }
            });
    }

    mapCollectionItems(collection) {
        const { virtualToDelete } = this.props;

        return virtualToDelete[collection].map((item, i) => <li key={ i }> { item }</li>);
    }

    render() {
        const { virtualToDelete, t } = this.props;
        const {
            needToShowDetails,
            gadgets = [],
            gadgetsV2 = [],
            segmentators,
            virtualSources,
            queries,
        } = virtualToDelete;

        return (
            <div>
                <p>
                    {
                        t('deleteVirtualSourceInfo', {
                            virtualSourcesCount: virtualSources.length,
                            segmentatorsCount: segmentators.length,
                            queriesCount: queries.length,
                            gadgetsCount: gadgets.length + gadgetsV2.length,
                        })
                    }
                </p>
                {
                    needToShowDetails && <strong>
                        { `${ t('details') }:` }
                    </strong>
                }
                { needToShowDetails && <br/> }
                { this.mapSourceData() }
            </div>
        );
    }
}

ConfirmDeleteVirtualContent.propTypes = {
    virtualToDelete: PropTypes.object,
};
