import { TCheckIsNeedToShowPercentage } from './models';

export class ConfigureGadgetModalService {
    static checkIsPercentageSettingEnable({ chartType, func }: TCheckIsNeedToShowPercentage) {
        const forGenericPieChart = chartType === 'generic_pie_chart' && func === 'count';
        const forEnabledCharts = [ 'line_chart', 'surveys_bar_chart' ].includes(chartType)
            && func !== 'npsScore';

        return forGenericPieChart || forEnabledCharts;
    }
}
