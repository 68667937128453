import { createSelector } from 'reselect';

export const sourceSelectSelector = (state, props) => {
    return state.SourceSelects[props.id];
};

export const totalSelector = () => {
    return createSelector([ sourceSelectSelector ], select => select && select.total);
};

const getVirtualSourceId = state => state.VocStore.virtualSource.source.id;

export const excludedSourcesSelector = (state, props) => {
    return createSelector([ getVirtualSourceId ], id => {
        return [ ...props.exclude_sources, id ];
    });
};

export const loadingSelector = (state, props) => {
    const select = sourceSelectSelector(state, props);

    return select && select.loading;
};

export const sourceSelectFiltersSelector = () => {
    return createSelector([ sourceSelectSelector ], select => select && select.filters);
};

export const openedAdditionalFiltersSelector = () => {
    return createSelector([ sourceSelectSelector ], select => select && select.openedAdditionalFilters);
};

export const getVirtualSources = state => state.VocStore.virtualSource.source.sources;
export const sourceSelectOptionsSelector = (state, props) => {
    return createSelector([ sourceSelectSelector, getVirtualSources ], (select, sources) => {
        if (!select) {
            return [];
        }

        const exclude_sources = sources.map(source => source.id);
        const rawSources = select.options;
        const selectedValue = sourceOptionFormatter(props.value);

        return rawSources
            .map(sourceOptionFormatter)
            .filter(option => option.value !== props.value.value)
            .filter(option => !exclude_sources.find(id => id === option.value))
            .concat(selectedValue);
    });
};

function sourceOptionFormatter(source) {
    const {
        id,
        title,
        state,
        channel,
        type,
    } = source;

    if (!id) {
        return [];
    }

    return {
        value: String(id),
        label: title,
        source,
        labelStatusClass: getStatusColorClass(state),
        state: typeof state === 'string' ? state.toLowerCase() : 'undefined',
        labelChannelClass: getChannelColorClass(channel),
        type: type.toUpperCase(),
        channel: (channel !== 'upload' && channel !== 'virtual') ? channel.toUpperCase() : null,
    };
}

function getChannelColorClass(channel) {
    return channel === 'upload' ? 'success' : 'info';
}

function getStatusColorClass(state) {
    switch (state) {
        case 'Active':
            return 'info';
        case 'Paused':
            return 'warning';
        case 'Deleted':
            return 'danger';
        case 'Inactive':
            return 'inactive';
    }
}

export const radioFiltersSelector = t =>
    createSelector([], () => [
        {
            name: 'all',
            id: 'filter-all',
            label: t('allData'),
        },
        {
            name: 'survey',
            id: 'filter-survey',
            label: t('surveys'),
        },
        {
            name: 'upload',
            id: 'filter-upload',
            label: t('uploads'),
        },
        {
            name: 'virtual',
            id: 'filter-virtual',
            label: t('virtual'),
        },
    ]);


export const surveyFiltersSelector = t =>
    createSelector([], () => [
        {
            name: 'Active',
            id: 'checkbox-active-surveys',
            label: t('active'),
            color: 'success',
        },
        {
            name: 'Paused',
            id: 'checkbox-paused-surveys',
            label: t('paused'),
            color: 'warning',
        },
        {
            name: 'Stopped',
            id: 'checkbox-stopped-surveys',
            label: t('stopped'),
            color: 'info',
        },
        {
            name: 'Deleted',
            id: 'checkbox-deleted-surveys',
            label: t('deleted'),
            color: 'danger',
        },
    ]);

