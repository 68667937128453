import { sockets } from '/services';
import { updateCounterItem } from './index';

function socketCallback(data, store) {
    const counter = {
        [data.id]: data.value,
    };

    store.dispatch(updateCounterItem(counter));
}

export function initCountersSocket() {
    return sockets.on('header_counter', socketCallback);
}
