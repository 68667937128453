import React, { Component } from 'react';
import { Popover, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { autobind } from 'core-decorators';
import moment from 'moment/moment';

import { HelperService } from '/services';

import './VirtualSourceFormCellPopover.scss';

const CustomInput = React.forwardRef(({ onClick, value }, ref) => (
    <button className='date-picker-custom-input' ref={ ref } onClick={ onClick }>
        { value }
    </button>
));

@withTranslation()
export class VirtualSourceFormCellPopover extends Component {
    state = {
        value: '',
        invalid: false,
    };

    customInputRef = React.createRef();

    @autobind
    handleTextValue(event) {
        this.setState({
            value: event.target.value,
        });
    }

    @autobind
    handleDateValue(value) {
        this.setState({
            value: moment(value).format(HelperService.defaultDateMask),
        });
    }

    @autobind
    clickOutside() {
        this.customInputRef.cancelFocusInput();
        this.customInputRef.setOpen(false);
    }

    @autobind
    acceptChanges() {
        const columnType = this.props.columnType.toLowerCase();
        const value = this.state.value
            ? this.getConvertedValue(columnType, this.state.value) : '';

        const valid = this.isValueValid(columnType, this.state.value);

        if (valid) {
            this.props.acceptChanges(value);
        } else {
            this.setState({
                invalid: true,
            });
        }
    }

    @autobind
    getElementById() {
        return this.props.target || this.props.selectRef;
    }

    isValueValid(columnType, val) {
        const value = this.getConvertedValue(columnType, val);

        switch (columnType) {
            case 'numeric': {
                return typeof value === 'number';
            }
            case 'nps': {
                return !(!Number.isInteger(value) || value < 0);
            }

            default: {
                return true;
            }
        }
    }

    getConvertedValue(columnType, value) {
        return (columnType === 'numeric' || columnType === 'nps') ? +value : value.trim();
    }

    @autobind
    toggle() {
        this.props.discardChanges();
        this.setState({
            value: '',
            invalid: false,
        });
    }

    @autobind
    renderFormControl() {
        const {
            columnType = 'text',
            t,
        } = this.props;
        const { value, invalid } = this.state;

        switch (columnType.toLowerCase()) {
            case 'date': {
                return (
                    <div className='voc-datepicker form-datepicker__item'>
                        <DatePicker
                            ref={ ref => (this.customInputRef = ref) }
                            className='form-control'
                            selected={ value ? moment(value, HelperService.defaultDateMask).toDate() : null }
                            dateFormat={ HelperService.datePickerMask }
                            onClickOutside={ this.clickOutside }
                            onSelect={ this.handleDateValue }
                            onBlur={ this.clickOutside }
                            customInput={ <CustomInput /> }
                        />
                    </div>
                );
            }
            case 'numeric': {
                return (
                    <div>
                        <Input
                            type='number'
                            value={ value }
                            placeholder={ t('placeholders.fixedValue') }
                            autoFocus
                            className={ invalid ? 'popover__input--invalid' : '' }
                            onChange={ this.handleTextValue }
                            innerRef={ this.customInputRef }
                        />
                        <span className={ `popover__input-description ${ invalid ? 'invalid' : '' }` }>
                            { t('validation.onlyNumericValueIsAcceptable') }
                        </span>
                    </div>
                );
            }
            case 'nps': {
                return (
                    <div>
                        <Input
                            type={ 'number' }
                            value={ value }
                            placeholder={ t('placeholders.fixedValue') }
                            autoFocus
                            onChange={ this.handleTextValue }
                            className={ invalid ? 'popover__input--invalid' : '' }
                            innerRef={ this.customInputRef }
                        />
                        <span className={ `popover__input-description ${ invalid ? 'invalid' : '' }` }>
                            { t('validation.onlyIntegerValueIsAcceptable') }
                        </span>
                    </div>
                );
            }

            default: {
                return (
                    <div>
                        <Input
                            type={ 'text' }
                            value={ value }
                            placeholder={ t('placeholders.fixedValue') }
                            autoFocus
                            onChange={ this.handleTextValue }
                            innerRef={ this.customInputRef }
                        />
                        <span className={ `popover__input-description ${ invalid ? 'invalid' : '' }` }>
                            { t('validation.youCanTypeYourTextHere') }
                        </span>
                    </div>
                );
            }
        }
    }

    render() {
        return (
            <Popover
                id='popover-contained'
                isOpen={ this.props.show }
                target={ this.getElementById() }
                container={ document.body }
                toggle={ this.toggle }
                placement='bottom'
                className='shadowed-popover'
                trigger='legacy'
            >
                <div className="popover-content">
                    <div className="outside-alerter">
                        <div className="popover-wrapper">
                            <div className={ 'popover__input-container' }>
                                {
                                    this.renderFormControl()
                                }
                            </div>
                            <div className="popover__input-controls">
                                <Button
                                    className='popover__input-button'
                                    color='primary'
                                    onClick={ this.acceptChanges }
                                >
                                    <FontAwesomeIcon icon='check' />
                                </Button>
                                <Button
                                    className='popover__input-button'
                                    color='danger'
                                    onClick={ this.toggle }
                                >
                                    <FontAwesomeIcon icon='times' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popover>
        );
    }
}
