import React from 'react';
import propTypes from 'prop-types';
import { Field } from 'redux-form';
import { VocFormCheckbox } from '/components';

export const protocolPhoneList = ({ fields, onChange, initProtocol }) => {
    return (
        <div className='customers-subscribe__list'>
            {
                fields.map((field, index) => {
                    const objField = fields.get(index);
                    const { address, customer, name, protocol, state } = objField;

                    return (
                        objField.id
                            ? <Field
                                key={ index }
                                name={ `${ field }.state` }
                                label={ name }
                                theme={ 'checkboxChannelTheme' }
                                component={ VocFormCheckbox }
                                className={ 'checkbox-channel' }
                                type={ 'checkbox' }
                                onChange={ onChange }
                                normalize={ value => value ? 25 : 1 }
                                format={ value => value === 25 }
                            />
                            : <div className="checkboxChannelTheme checkbox-channel" key={ index }>
                                <span
                                    className="voc-checkbox__activate"
                                    onClick={ () => initProtocol({ address, customer, protocol, state }) }>
                                    { objField.name }
                                </span>
                            </div>
                    );
                },
                )
            }
        </div>
    );
};

protocolPhoneList.propTypes = {
    fields: propTypes.object,
    onChange: propTypes.func.isRequired,
    initProtocol: propTypes.func.isRequired,
};
