import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { VocModal } from '/components';
import { changeQueryContext } from '/query/modules/query.modules';
import { IQueryContextEditFieldModal } from './models';

export const QueryContextEditFieldModal = ({ show, initialValue, toggle }: IQueryContextEditFieldModal) => {
    const [ value, setValue ] = useState<string>('');
    const dispatch = useDispatch();

    useEffect(() => {
        show && setValue(initialValue || '');
    }, [ show ]);

    const handleOnSubmit = () => {
        dispatch(changeQueryContext(value.trim()));
        toggle();
    };

    const handleClosed = () => setValue('');

    const handleChangeQueryContext = (event: ChangeEvent<HTMLInputElement>) =>
        setValue(event?.target.value);

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color='white'
                onClick={ toggle }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                Cancel
            </Button>
            <Button
                color='primary'
                disabled={ value === initialValue }
                onClick={ handleOnSubmit }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                Edit
            </Button>
        </div>
    );

    return (
        <VocModal
            isOpen={ show }
            header='Edit Query context'
            footer={ getModalFooter() }
            toggle={ toggle }
            onClosed={ handleClosed }
        >
            <Label for='queryContext'>
                Query context:
            </Label>
            <Input
                id='queryContext'
                type='textarea'
                placeholder='Enter Query context'
                rows={ 3 }
                value={ value }
                onChange={ handleChangeQueryContext }
            />
        </VocModal>
    );
};
