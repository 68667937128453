import React from 'react';
import { Button, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SelectAsyncPaginate, VocSelect, TooltipWrapper } from '/components';
import { _getSelectedSourceColumns } from '/ai/modules/vocAi.selectors';

import styles from './style.module.scss';

export const SelectSourceBlock = ({
    setPage,
    sourcesList,
    fieldsData,
    getSources,
    onChange,
    isDisabledReset,
    onClickReset,
    onClickApply,
    isDisabledApply,
    onCloseSourceSelect,
    setViewType,
    viewType,
    openAllCategoriesHandler,
    openAllCategories,
    moveToCount,
    onMoveTo,
}) => {
    const columns = useSelector(state => _getSelectedSourceColumns(state, fieldsData.source));
    const { totalCount, filters, loading, data } = sourcesList;
    const { source, column } = fieldsData;

    return (
        <div>
            <div className={ styles.sourceFieldWrapper }>
                <Label>Source</Label>
                <SelectAsyncPaginate
                    selectOptionClass={ styles.selectOption }
                    isClearable
                    closeMenuOnSelect
                    setPage={ setPage }
                    placeholder='Select source'
                    total={ totalCount }
                    filters={ filters }
                    onChange={ data => onChange('source', data) }
                    value={ source }
                    loading={ loading }
                    getSources={ getSources }
                    options={ data }
                    onClose={ onCloseSourceSelect }
                />
            </div>
            <div>
                <Label>Columns</Label>
                <VocSelect
                    portal
                    isHorizontal
                    isMulti
                    disabled={ !source }
                    placeholder='Select a column'
                    isClearable={ false }
                    onChange={ data => onChange('column', data) }
                    options={ columns }
                    value={ column }
                />
            </div>
            <div className={ styles.settingsButtons }>
                {
                    moveToCount > 0
                    && <Button
                        outline
                        onClick={ onMoveTo }
                        color={ 'primary' }
                    >
                        Move to ({moveToCount})
                    </Button>
                }
                {
                    viewType === 'list'
                    && <TooltipWrapper value={ openAllCategories ? 'Close all categories' : 'Open all categories' } force>
                        <Button
                            outline
                            onClick={ openAllCategoriesHandler }
                            color={ 'primary' }
                        >
                            <FontAwesomeIcon icon={ openAllCategories ? 'caret-square-up' : 'caret-square-down' } />
                        </Button>
                    </TooltipWrapper>
                }
                <TooltipWrapper value={ viewType === 'grid' ? 'Show categories' : 'Show all topics' } force>
                    <Button
                        outline
                        onClick={ () => setViewType(viewType === 'grid' ? 'list' : 'grid') }
                        color={ 'primary' }
                    >
                        <FontAwesomeIcon icon={ viewType === 'grid' ? 'th-list' : 'th' } />
                    </Button>
                </TooltipWrapper>
                <Button
                    outline
                    disabled={ isDisabledReset }
                    onClick={ onClickReset }
                    color={ 'danger' }
                >
                    Reset
                </Button>
                <Button
                    disabled={ isDisabledApply }
                    onClick={ onClickApply }
                    color='primary'
                >
                    Show topics
                </Button>
            </div>
        </div>
    );
};
