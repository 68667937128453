import { exception } from '/feedback/modules/VocFeedback.selectors';

export const quarantinesFlowValidation = (values = {}) => {
    const errors = {
        conditions: [],
    };

    const {
        conditions = [],
        action_type,
        survey_group,
        logical_operation,
        days,
        hours,
    } = values;

    if((!Number(days) && !Number(hours)) || (days === '' || hours === '')) {
        errors.days = 'Field must not be empty';
        errors.hours = 'Field must not be empty';
    }

    if (!action_type) {
        errors.action_type = 'Field must not be empty';
    }

    if (!logical_operation) {
        errors.logical_operation = 'Field must not be empty';
    }

    if (!survey_group) {
        errors.survey_group = 'Field must not be empty';
    }

    conditions.map((condition, index) => {
        const { matching_value, blocked_by, expression } = condition;
        let matchingValueToNumber = +matching_value,
            isOnlyNumbers = false,
            isNotEmpty = false;

        if (expression) {
            isOnlyNumbers = [ '>=', '<=', '>', '<' ].includes(expression.value);
            isNotEmpty = !exception.includes(expression.value);
        }

        if (matching_value && typeof matching_value === 'object') {
            matchingValueToNumber = matching_value.value === 'dont_know' ? matching_value.value : +matching_value.value;
        }

        if (!blocked_by) {
            errors.conditions[index] = {
                blocked_by: 'Field must not be empty',
            };
        }

        if (!expression) {
            errors.conditions[index] = {
                expression: 'Field must not be empty',
            };
        }

        if (isNotEmpty && !matching_value ) {
            // dirtyHack: always change type of matching_value === 0 - number '0' - string null undefined
            matching_value !== 0
                ? errors.conditions[index] = {
                    matching_value: 'Field must not be empty',
                }
                : null;
        } else if (typeof matchingValueToNumber !== 'number' && isOnlyNumbers) {
            errors.conditions[index] = {
                matching_value: 'Field must not be string',
            };
        }

        if(blocked_by && blocked_by.condition_type === 'random_100' && expression?.value) {
            const isMinValue = expression?.value === '>'
                ? +matching_value >= 0
                : +matching_value > 0;

            if (!(isMinValue && +matching_value <= 100)) {
                errors.conditions[index] = {
                    matching_value: 'Field must be from 1 to 100',
                };
            }
        }
    });

    return errors;
};
