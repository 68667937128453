import React from 'react';
import { TooltipWrapper } from '/components';
import { Link } from 'react-router-dom';
import './QueriesTitleFormatter.scss';

export const QueriesTitleFormatter = ({
    id,
    title,
}) => {
    return (
        <TooltipWrapper value={ title }>
            <div className={ 'query-title__wrapper' }>
                <Link to={ `/mine/query/${id}/execute` }>{ title }</Link>
            </div>
        </TooltipWrapper>
    );
};
