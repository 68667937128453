import { createSelector } from 'reselect';
import {
    languagesSelector,
    activeLanguageSelector,
} from '../../../modules/survey.selectors';
import { questionTypeOptionsSelector, questionTypesSelector } from '/feedback/modules/VocFeedback.selectors';

export const rootQuestionSelector = state => state.VocFeedback.survey.questions.list[0];
export const activeQuestionIdSelector = state => state.VocFeedback.survey.questions.activeQuestionId;
export const inheritQuestionDataFromIdSelector = state => state.VocFeedback.survey.questions.inheritDataFromId;
export const showQuestionDetailsSelector = state => state.VocFeedback.survey.questions.showQuestionDetails;
export const getQuestionValue = state => state.VocFeedback.survey.questions.questionValue;
export const getActiveLanguage = state => state.VocFeedback.survey.surveyCommon.activeLanguage;
export const questionValueSelector = createSelector([ questionTypeOptionsSelector, questionTypesSelector, getQuestionValue, getActiveLanguage ],
    (typeOptions, questionTypes, questionValue, activeLanguage) => {
        const {
            loading,
            data: {
                display_class,
                configurable = {},
                answers = [],
            },
        } = questionValue;

        const optionsDictionary = {};
        const questionType = questionTypes.find(type => type.display_class === display_class) || {};

        typeOptions
            .filter(option => option.question_type === (questionType && questionType.id))
            .forEach(el => optionsDictionary[el.name] = { label: el.display_text, type: el.field_type });

        return {
            loading,
            configurable: Object.keys(configurable).map(el => {
                const dictValue = optionsDictionary[el];

                return {
                    label: dictValue.label,
                    value: dictValue.type === 'Checkbox' ? `${ Boolean(+configurable[el]) }` : configurable[el],
                };
            }),
            answers: answers.map(answer => {
                const answerName = answer.names.find(el => el.language === activeLanguage);

                return answerName ? { name: answerName.name, is_open: answer.is_open ? '[ IS OPEN ]' : '' } : answer;
            }),
        };
    });

export const surveysLanguageSelector = createSelector([ languagesSelector, activeLanguageSelector ],
    (languages, activeLanguage) => {
        return {
            loading: languages.loading,
            languages: languages.data.map(language => ({
                language: language.language,
                value: language.language,
                label: language.language,
                hasLanguageText: language.hasLanguageText,
                active: language.language === activeLanguage,
                text: language.hasLanguageText && 'contains question text in this language',
            })),
        };
    });
