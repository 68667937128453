import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';

const GET_NEW_EXPORTER_REQUEST = 'GET_NEW_EXPORTER_REQUEST';
const GET_NEW_EXPORTER_SUCCESS = 'GET_NEW_EXPORTER_SUCCESS';
const GET_NEW_EXPORTER_ERROR = 'GET_NEW_EXPORTER_ERROR';

const CREATE_NEW_EXPORTER_REQUEST = 'CREATE_NEW_EXPORTER_REQUEST';
const CREATE_NEW_EXPORTER_SUCCESS = 'CREATE_NEW_EXPORTER_SUCCESS';
const CREATE_NEW_EXPORTER_ERROR = 'CREATE_NEW_EXPORTER_ERROR';

const UPDATE_NEW_EXPORTER_REQUEST = 'UPDATE_NEW_EXPORTER_REQUEST';
const UPDATE_NEW_EXPORTER_SUCCESS = 'UPDATE_NEW_EXPORTER_SUCCESS';
const UPDATE_NEW_EXPORTER_ERROR = 'UPDATE_NEW_EXPORTER_ERROR';

const GET_DATA_TYPES_LIST_REQUEST = 'GET_DATA_TYPES_LIST_REQUEST';
const GET_DATA_TYPES_LIST_SUCCESS = 'GET_DATA_TYPES_LIST_SUCCESS';
const GET_DATA_TYPES_LIST_ERROR = 'GET_DATA_TYPES_LIST_ERROR';

const CLEANUP_EXPORTER_PAGE = 'CLEANUP_EXPORTER_PAGE';

const modelKeys = [
    'custom_columns',
    'delimiter',
    'export_conditions',
    'export_type',
    'filter_by_states',
    'filter_by_date',
    'language',
    'name',
    'only_with_answers',
    'remove_delimiter_from_value',
    'output_type',
    'question_column_header',
    'with_classifications',
    'with_lemmas',
    'with_recoders',
    'with_nps_segments',
    'with_tags',
    'with_topic_set',
    'date_format',
    'include_case_alert_column',
    'include_error_message_column',
    'include_invitation_channel_column',
    'with_sentiment',
];

function getInitialState() {
    return {
        loading: false,
        submitting: false,
        result: {
            output_type: "csv",
            delimiter: ";",
            export_type: "default",
            export_conditions: [],
            custom_columns: [],
            with_classifications: true,
            with_recoders: true,
            with_nps_segments: true,
            with_tags: true,
            with_lemmas: false,
            date_format: null,
            include_case_alert_column: true,
            include_invitation_channel_column: true,
            with_sentiment: false,
        },
        dataTypesList: [],
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_NEW_EXPORTER_REQUEST:
        case GET_DATA_TYPES_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_NEW_EXPORTER_REQUEST:
        case UPDATE_NEW_EXPORTER_REQUEST:
            return {
                ...state,
                submitting: true,
            };

        case GET_NEW_EXPORTER_SUCCESS:
            const result = action.response?.results;

            return {
                ...state,
                loading: false,
                result: result
                    ? HelperService.pick(...result, modelKeys)
                    : {},
            };

        case GET_DATA_TYPES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                dataTypesList: action.response,
            };

        case GET_NEW_EXPORTER_ERROR:
        case GET_DATA_TYPES_LIST_ERROR:
            return {
                ...state,
                loading: false,
            };

        case CREATE_NEW_EXPORTER_SUCCESS:
        case UPDATE_NEW_EXPORTER_SUCCESS:
        case CREATE_NEW_EXPORTER_ERROR:
        case UPDATE_NEW_EXPORTER_ERROR:
            return {
                ...state,
                submitting: false,
            };

        case CLEANUP_EXPORTER_PAGE:
            return getInitialState();

        default:
            return state;
    }
}

export function getExporter(id__in) {
    return {
        [CALL_API]: {
            endpoint: `/export-config/`,
            method: 'GET',
            types: [ GET_NEW_EXPORTER_REQUEST, GET_NEW_EXPORTER_SUCCESS, GET_NEW_EXPORTER_ERROR ],
            body: { id__in },
            apiType: FEEDBACK,
        },
    };
}

export function createExporter(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-config/`,
            method: 'POST',
            types: [ CREATE_NEW_EXPORTER_REQUEST, CREATE_NEW_EXPORTER_SUCCESS, CREATE_NEW_EXPORTER_ERROR ],
            contentType: 'application/json',
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Exporter was successfully created',
                error: 'Something went wrong',
            },
        },
    };
}

export function updateExporter(id__in, body) {
    return {
        [CALL_API]: {
            endpoint: `/export-config/${id__in}/`,
            method: 'PUT',
            types: [ UPDATE_NEW_EXPORTER_REQUEST, UPDATE_NEW_EXPORTER_SUCCESS, UPDATE_NEW_EXPORTER_ERROR ],
            contentType: 'application/json',
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Exporter was successfully updated',
                error: 'Something went wrong',
            },
        },
    };
}

export function getDataTypesList() {
    return {
        [CALL_API]: {
            endpoint: `/export-config/data-types-list/`,
            method: 'GET',
            types: [ GET_DATA_TYPES_LIST_REQUEST, GET_DATA_TYPES_LIST_SUCCESS, GET_DATA_TYPES_LIST_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP_EXPORTER_PAGE,
    };
}
