import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TooltipWrapper } from '/components/';
import { SortableList } from '../';
import { Card, CardHeader, CardBody, ListGroup } from 'reactstrap';

export class TouchPointList extends Component {
    renderPanelHeader() {
        const addQuotes = str => (str && str !== '') ? `"${str}"` : str;
        const selectedProjectName = addQuotes(this.props.selectedProjectName);
        const conditionTooltip = {
            width: 325,
        };

        return (
            <Fragment>
                <TooltipWrapper value={ selectedProjectName }
                    condition={ conditionTooltip }
                    placement='top'>
                    <span
                        className='panel__header-title'>Touchpoints { selectedProjectName ? 'in' : '' } { selectedProjectName }
                    </span>
                </TooltipWrapper>
            </Fragment>
        );
    }

    render() {
        return (
            <Card color={ 'default' }>
                <CardHeader className={ 'panel__header' }>
                    { this.renderPanelHeader() }
                </CardHeader>
                <CardBody className={ 'manage_projects__panel-body' }>
                    { this.props.touchpoints.length > 0
                        ? <SortableList
                            touchpoints={ this.props.touchpoints }
                            selectedTouchPoint={ this.props.selectedTouchPoint }
                            deleteTouchPoint={ this.props.deleteTouchPoint }
                            onSortStart={ this.props.onSortStart }
                            onSortEnd={ this.props.onSortEnd }
                            onSelectTouchPoint={ this.props.onSelectTouchPoint }
                            useDragHandle={ true }
                            axis='y'
                            lockAxis='y'
                            helperClass='touchpoint-list-modern-sortable-helper'
                        />
                        : <ListGroup>
                            <li className="list-group-item">No Touchpoints</li>
                        </ListGroup>
                    }
                </CardBody>
            </Card>
        );
    }
}

TouchPointList.propTypes = {
    selectedProjectName: PropTypes.string,
    selectedTouchPoint: PropTypes.object,
    onSelectTouchPoint: PropTypes.func,
    deleteTouchPoint: PropTypes.func,
    touchpoints: PropTypes.array,
    onSortEnd: PropTypes.func,
};
