import React from 'react';
import cx from 'classnames';
import { components } from 'react-select';
import { TooltipWrapper } from '/components';
import './ValueContainer.scss';

export const ValueContainer = ({ children, ...props }) => {
    let value = props.getValue();

    if (value.length) {
        value = value[0].label;
    }

    return (
        <div
            className={ cx('vochub-select__value-container',
                { 'vochub-select__value-container--clearable': props.selectProps && props.selectProps.isClearable }) }
        >
            <TooltipWrapper value={ String(value) }>
                <div>
                    <components.ValueContainer { ...props }>
                        { children }
                    </components.ValueContainer>
                </div>
            </TooltipWrapper>
        </div>
    );
};
