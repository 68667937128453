import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { SettingsService } from '/feedback/services/SettingsService';
import { HelperService } from '/services';

export const GET_GLOBAL_LANGUAGE_SETTINGS_REQUEST = 'GET_GLOBAL_LANGUAGE_SETTINGS_REQUEST';
export const GET_GLOBAL_LANGUAGE_SETTINGS_SUCCESS = 'GET_GLOBAL_LANGUAGE_SETTINGS_SUCCESS';
export const GET_GLOBAL_LANGUAGE_SETTINGS_ERROR = 'GET_GLOBAL_LANGUAGE_SETTINGS_ERROR';

export const PUT_GLOBAL_LANGUAGE_SETTINGS_REQUEST = 'PUT_GLOBAL_LANGUAGE_SETTINGS_REQUEST';
export const PUT_GLOBAL_LANGUAGE__SETTINGS_SUCCESS = 'PUT_GLOBAL_LANGUAGE__SETTINGS_SUCCESS';
export const PUT_GLOBAL_LANGUAGE_SETTINGS_ERROR = 'PUT_GLOBAL_LANGUAGE_SETTINGS_ERROR';

export const GLOBAL_LANGUAGE_SETTINGS_CLEANUP = 'GLOBAL_LANGUAGE_SETTINGS_CLEANUP';

function getInitialState() {
    return {
        loading: false,
        sending: false,
        settings: {},
    };
}

export const modelKeys = {
    ...SettingsService.getKeysForFilter('languages', true),
};

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_GLOBAL_LANGUAGE_SETTINGS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_GLOBAL_LANGUAGE_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                settings: {
                    ...HelperService.pick(action.response, modelKeys[action.params]),
                },
            };

        case PUT_GLOBAL_LANGUAGE_SETTINGS_REQUEST: {
            return {
                ...state,
                sending: true,
            };
        }

        case PUT_GLOBAL_LANGUAGE__SETTINGS_SUCCESS: {
            return {
                ...state,
                sending: false,
                settings: {
                    ...action.response,
                },
            };
        }

        case PUT_GLOBAL_LANGUAGE_SETTINGS_ERROR: {
            return {
                ...state,
                sending: false,
            };
        }

        case GLOBAL_LANGUAGE_SETTINGS_CLEANUP:
            return getInitialState();

        default:
            return state;
    }
}

export function getLanguageSettings({ unit, channel, language }) {
    return {
        [CALL_API]: {
            endpoint: `unit/${ unit }/get-channel-language-settings/${ channel }/${ language }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_GLOBAL_LANGUAGE_SETTINGS_REQUEST, GET_GLOBAL_LANGUAGE_SETTINGS_SUCCESS, GET_GLOBAL_LANGUAGE_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            params: channel,
        },
    };
}

export function putGlobalLanguageSettings(model) {
    return dispatch => {
        return dispatch(putGlobalLanguageSettingsRequest(model)).promise
            .catch(SettingsService.catchSettingsErrors);
    };
}

export function putGlobalLanguageSettingsRequest({ unit, lang, body }) {
    return {
        [CALL_API]: {
            endpoint: `/unit/${ unit }/set-settings/${ lang }/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ PUT_GLOBAL_LANGUAGE_SETTINGS_REQUEST, PUT_GLOBAL_LANGUAGE__SETTINGS_SUCCESS, PUT_GLOBAL_LANGUAGE_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Global Language settings were successfully updated',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function cleanup() {
    return {
        type: GLOBAL_LANGUAGE_SETTINGS_CLEANUP,
    };
}
