import { CALL_API } from 'store/middleware/api';

const CLEANUP_TOPIC_SET_APPLIANCE = 'CLEANUP_TOPIC_SET_APPLIANCE';

const GET_TOPIC_SET_APPLIANCE_REQUEST = 'GET_TOPIC_SET_APPLIANCE_REQUEST';
const GET_TOPIC_SET_APPLIANCE_SUCCESS = 'GET_TOPIC_SET_APPLIANCE_SUCCESS';
const GET_TOPIC_SET_APPLIANCE_ERROR = 'GET_TOPIC_SET_APPLIANCE_ERROR';

const GET_APPLIANCE_FILTER_REQUEST = 'GET_APPLIANCE_FILTER_REQUEST';
const GET_APPLIANCE_FILTER_SUCCESS = 'GET_APPLIANCE_FILTER_SUCCESS';
const GET_APPLIANCE_FILTER_ERROR = 'GET_APPLIANCE_FILTER_ERROR';

const DELETE_TOPIC_SET_APPLIANCE_REQUEST = 'DELETE_TOPIC_SET_APPLIANCE_REQUEST';
const DELETE_TOPIC_SET_APPLIANCE_SUCCESS = 'DELETE_TOPIC_SET_APPLIANCE_SUCCESS';
const DELETE_TOPIC_SET_APPLIANCE_ERROR = 'DELETE_TOPIC_SET_APPLIANCE_ERROR';

const REAPPLY_TOPIC_SET_APPLIANCE_REQUEST = 'REAPPLY_TOPIC_SET_APPLIANCE_REQUEST';
const REAPPLY_TOPIC_SET_APPLIANCE_SUCCESS = 'REAPPLY_TOPIC_SET_APPLIANCE_SUCCESS';
const REAPPLY_TOPIC_SET_APPLIANCE_ERROR = 'REAPPLY_TOPIC_SET_APPLIANCE_ERROR';

const SET_APPLIANCE_FILTERS = 'SET_APPLIANCE_FILTERS';
const RESET_APPLIANCE_FILTERS = 'RESET_APPLIANCE_FILTERS';

const UPDATE_TOPIC_SET_APPLIANCE_FILTERS = 'UPDATE_TOPIC_SET_APPLIANCE_FILTERS';
const extraFiltersLocalStore = 'voc_ai_topic_set_appliance_filters_2';

const extraFiltersData: string | null = localStorage.getItem(extraFiltersLocalStore);
const extraFilters = extraFiltersData && JSON.parse(extraFiltersData);

const initialFilters = {
    limit: 10,
    page: 1,
    search: '',
    sort: '',
};

export const getInitialState = (extraFilters?: object | undefined) => ({
    filters: {
        ...initialFilters,
        extraFilters: extraFilters
            ? {
                ...extraFilters,
            }
            : {
                topicSet: [],
                source: [],
                columns: [],
                languages: [],
            },
    },
    data: [],
    loading: false,
    totalCount: 0,
    topicSet: {
        loading: false,
        data: [],
    },
    source: {
        loading: false,
        data: [],
    },
    columns: {
        loading: false,
        data: [],
    },
    languages: {
        loading: false,
        data: [],
    },
});

export const reducer = (state = getInitialState(extraFilters), action: any = {}) => {
    switch (action.type) {
        case CLEANUP_TOPIC_SET_APPLIANCE: {
            return getInitialState();
        }

        case UPDATE_TOPIC_SET_APPLIANCE_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case GET_TOPIC_SET_APPLIANCE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_TOPIC_SET_APPLIANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.response.data,
                totalCount: action.response.count,
            };
        }

        case GET_TOPIC_SET_APPLIANCE_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case DELETE_TOPIC_SET_APPLIANCE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case DELETE_TOPIC_SET_APPLIANCE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case DELETE_TOPIC_SET_APPLIANCE_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case SET_APPLIANCE_FILTERS: {
            const { name, value } = action.payload;
            const extraFilters = {
                ...state.filters.extraFilters,
                [name]: value,
            };

            localStorage.setItem(extraFiltersLocalStore, JSON.stringify(extraFilters));

            return {
                ...state,
                filters: {
                    ...state.filters,
                    extraFilters: {
                        ...extraFilters,
                    },
                },
            };
        }

        case RESET_APPLIANCE_FILTERS: {
            localStorage.removeItem(extraFiltersLocalStore);

            return {
                ...state,
                filters: {
                    ...state.filters,
                    extraFilters: action.payload ? {
                        ...state.filters.extraFilters,
                        [action.payload]: [],
                    } : {
                        ...getInitialState(null).filters.extraFilters,
                    },
                },
            };
        }

        case GET_APPLIANCE_FILTER_REQUEST: {
            return {
                ...state,
                [action.params.filterName]: {
                    ...state[action.params.filterName],
                    loading: true,
                },
            };
        }

        case GET_APPLIANCE_FILTER_SUCCESS: {
            return {
                ...state,
                [action.params.filterName]: {
                    data: action.response.data || action.response,
                    loading: false,
                },
            };
        }

        case GET_APPLIANCE_FILTER_ERROR: {
            return {
                ...state,
                [action.params.filterName]: {
                    ...state[action.params.filterName],
                    loading: false,
                },
            };
        }

        default:
            return state;
    }
};

export function getTopicSetAppliance(filters) {
    const { sort_field, sort_order, extraFilters, ...restFilters } = filters;

    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set-appliance/`,
            method: 'POST',
            types: [ GET_TOPIC_SET_APPLIANCE_REQUEST, GET_TOPIC_SET_APPLIANCE_SUCCESS, GET_TOPIC_SET_APPLIANCE_ERROR ],
            logger: true,
            contentType: 'application/json',
            body: {
                ...restFilters,
                ...sort_field ? {
                    sort: { [sort_field]: sort_order },
                } : {},
                filters: {
                    topicSet: extraFilters.topicSet.map(filter => filter.value),
                    sourceId: extraFilters.source.map(filter => filter.value),
                    column: extraFilters.columns.map(filter => filter.value),
                    languages: extraFilters.languages.map(filter => filter.value),
                },
            },
        },
    };
}

export function updateTopicSetApplianceFilters(filters) {
    return {
        type: UPDATE_TOPIC_SET_APPLIANCE_FILTERS,
        payload: filters,
    };
}

export function deleteTopicSetAppliance(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set-appliance/${id}`,
            method: 'DELETE',
            types: [ DELETE_TOPIC_SET_APPLIANCE_REQUEST, DELETE_TOPIC_SET_APPLIANCE_SUCCESS, DELETE_TOPIC_SET_APPLIANCE_ERROR ],
            logger: true,
            loggerMessages: {
                success: `Applied Topic Sets successfuly removed`,
                error: 'Something went wrong',
            },
        },
    };
}

export function topicSetApplianceFilter(filterName) {
    const formattedFilterName = () => {
        switch (filterName) {
            case 'topicSet':
                return 'topic-sets';
            case 'source':
                return 'sources';

            default:
                return filterName;
        }
    };

    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/filter-value/${ formattedFilterName() }`,
            method: 'GET',
            types: [ GET_APPLIANCE_FILTER_REQUEST, GET_APPLIANCE_FILTER_SUCCESS, GET_APPLIANCE_FILTER_ERROR ],
            logger: false,
            body: {
                limit: 999999,
                page: 0,
            },
            params: {
                filterName,
            },
        },
    };
}

export function reApplySetAppliance(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set-appliance/reprocess/${id}`,
            method: 'GET',
            types: [
                REAPPLY_TOPIC_SET_APPLIANCE_REQUEST,
                REAPPLY_TOPIC_SET_APPLIANCE_SUCCESS,
                REAPPLY_TOPIC_SET_APPLIANCE_ERROR,
            ],
            logger: true,
            loggerMessages: {
                success: `Reapply started`,
                error: 'Something went wrong',
            },
        },
    };
}

export function setSelected(payload) {
    return {
        type: SET_APPLIANCE_FILTERS,
        payload,
    };
}

export function resetSelected(payload) {
    return {
        type: RESET_APPLIANCE_FILTERS,
        payload,
    };
}
