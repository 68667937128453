import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';

export const GET_UPLOADS_LIST_REQUEST = '/public_api/store/upload/list/request';
export const GET_UPLOADS_LIST_REQUEST_SUCCESS = '/public_api/store/upload/list/success';
export const GET_UPLOADS_LIST_REQUEST_ERROR = '/public_api/store/upload/list/error';

export const GET_UPLOADS_LIST_FROM_CACHE = 'GET_UPLOADS_LIST_FROM_CACHE';

export const CLEANUP_UPLOADS_PAGE = 'CLEANUP_UPLOADS_PAGE';

export const UPLOAD_LIST_FILTERS_CHANGED = 'UPLOAD_LIST_FILTERS_CHANGED';
export const UPDATE_UPLOAD_LIST = 'UPDATE_UPLOAD_LIST';

export const GET_UPLOAD_RELATIONS_REQUEST = 'GET_UPLOAD_RELATIONS_REQUEST';
export const GET_UPLOAD_RELATIONS_SUCCESS = 'GET_UPLOAD_RELATIONS_SUCCESS';
export const GET_UPLOAD_RELATIONS_ERROR = 'GET_UPLOAD_RELATIONS_ERROR';

export const DELETE_UPLOAD_REQUEST = 'DELETE_UPLOAD_REQUEST';
export const DELETE_UPLOAD_SUCCESS = 'DELETE_UPLOAD_SUCCESS';
export const DELETE_UPLOAD_ERROR = 'DELETE_UPLOAD_ERROR';

export const UNCOVER_UPLOAD_REQUEST = 'UNCOVER_UPLOAD_REQUEST';
export const UNCOVER_UPLOAD_SUCCESS = 'UNCOVER_UPLOAD_SUCCESS';
export const UNCOVER_UPLOAD_ERROR = 'UNCOVER_UPLOAD_ERROR';

export const LEMMATIZATION_UPLOAD_REQUEST = 'LEMMATIZATION_UPLOAD_REQUEST';
export const LEMMATIZATION_UPLOAD_SUCCESS = 'LEMMATIZATION_UPLOAD_SUCCESS';
export const LEMMATIZATION_UPLOAD_ERROR = 'LEMMATIZATION_UPLOAD_ERROR';

export const GET_IMPORTER_INFO_REQUEST = 'GET_IMPORTER_INFO_REQUEST';
export const GET_IMPORTER_INFO_SUCCESS = 'GET_IMPORTER_INFO_SUCCESS';
export const GET_IMPORTER_INFO_ERROR = 'GET_IMPORTER_INFO_ERROR';

export const SYNC_SOCIAL_SOURCE_REQUEST = 'SYNC_SOCIAL_SOURCE_REQUEST';
export const SYNC_SOCIAL_SOURCE_SUCCESS = 'SYNC_SOCIAL_SOURCE_SUCCESS';
export const SYNC_SOCIAL_SOURCE_ERROR = 'SYNC_SOCIAL_SOURCE_ERROR';

export const FOLLOW_SOCIAL_SOURCE_REQUEST = 'FOLLOW_SOCIAL_SOURCE_REQUEST';
export const FOLLOW_SOCIAL_SOURCE_SUCCESS = 'FOLLOW_SOCIAL_SOURCE_SUCCESS';
export const FOLLOW_SOCIAL_SOURCE_ERROR = 'FOLLOW_SOCIAL_SOURCE_ERROR';

const GET_UPLOAD_INFO_REQUEST = 'GET_UPLOAD_INFO_REQUEST';
const GET_UPLOAD_INFO_SUCCESS = 'GET_UPLOAD_INFO_SUCCESS';
const GET_UPLOAD_INFO_ERROR = 'GET_UPLOAD_INFO_ERROR';

const UPDATE_UPLOAD_DESCRIPTION_REQUEST = 'UPDATE_UPLOAD_DESCRIPTION_REQUEST';
const UPDATE_UPLOAD_DESCRIPTION_SUCCESS = 'UPDATE_UPLOAD_DESCRIPTION_SUCCESS';
const UPDATE_UPLOAD_DESCRIPTION_ERROR = 'UPDATE_UPLOAD_DESCRIPTION_ERROR';

const UPDATE_UPLOAD_TITLE_REQUEST = 'UPDATE_UPLOAD_TITLE_REQUEST';
const UPDATE_UPLOAD_TITLE_SUCCESS = 'UPDATE_UPLOAD_TITLE_SUCCESS';
const UPDATE_UPLOAD_TITLE_ERROR = 'UPDATE_UPLOAD_TITLE_ERROR';

const CLEANUP_UPLOAD_INFO = 'CLEANUP_UPLOAD_INFO';

export const DOWNLOAD_ERRORS_REQUEST = 'DOWNLOAD_ERRORS_REQUEST';

export const CANCEL_UPLOAD_REQUEST = 'CANCEL_UPLOAD_REQUEST';

export const CLEANUP_IMPORTER_INFO_STATE = 'CLEANUP_IMPORTER_INFO_STATE';

export const getInitialState = () => ({
    loading: false,
    syncLoading: false,
    followLoading: false,
    descriptionSending: false,
    titleSending: false,
    importerInfo: {
        loading: false,
        data: {},
    },
    filters: {
        sort_field: '',
        sort_order: '',
        search: '',
        limit: 10,
        show_hidden: 0,
        page: 1,
    },
    uploadToDelete: {
        classifiers: [],
        gadgets: [],
        queries: [],
        virtualSources: [],
        segmentators: [],
    },
    uploads: {
        data: [],
        count: 0,
    },
    uploadInfo: {
        loading: false,
        data: null,
        activeSource: null,
    },
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_UPLOADS_LIST_REQUEST:
        case GET_UPLOAD_RELATIONS_REQUEST:
        case DELETE_UPLOAD_REQUEST:
        case UNCOVER_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_UPLOADS_LIST_REQUEST_SUCCESS:
        case GET_UPLOADS_LIST_FROM_CACHE:
            return {
                ...state,
                uploads: {
                    count: Number(action.response.count),
                    data: [
                        ...action.response.data,
                    ],
                },
                loading: false,
            };

        case GET_UPLOAD_RELATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadToDelete: {
                    ...state.uploadToDelete,
                    ...action.response,
                },
            };

        case GET_UPLOADS_LIST_REQUEST_ERROR:
        case DELETE_UPLOAD_ERROR:
        case UNCOVER_UPLOAD_ERROR:
        case GET_UPLOAD_RELATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case UPLOAD_LIST_FILTERS_CHANGED:
            const filters = {
                ...state.filters,
                ...action.payload,
            };

            return {
                ...state,
                filters,
            };

        case UPDATE_UPLOAD_LIST:
            return {
                ...state,
                uploads: {
                    count: Number(state.uploads.count),
                    data: [
                        ...state.uploads.data.slice(0, action.updatedUploadIndex),
                        action.upload,
                        ...state.uploads.data.slice(action.updatedUploadIndex + 1),
                    ],
                },
            };

        case SYNC_SOCIAL_SOURCE_REQUEST:
            return {
                ...state,
                syncLoading: true,
            };

        case SYNC_SOCIAL_SOURCE_SUCCESS:
        case SYNC_SOCIAL_SOURCE_ERROR:
            return {
                ...state,
                syncLoading: false,
            };

        case FOLLOW_SOCIAL_SOURCE_REQUEST:
            return {
                ...state,
                followLoading: true,
            };

        case FOLLOW_SOCIAL_SOURCE_SUCCESS:
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    data: state.uploads.data.map(upload =>
                        upload.importer?.id === action.response.id
                            ? { ...upload, importer: action.response }
                            : upload,
                    ),
                },
                followLoading: false,
            };

        case FOLLOW_SOCIAL_SOURCE_ERROR:
            return {
                ...state,
                followLoading: false,
            };

        case GET_IMPORTER_INFO_REQUEST:
            return {
                ...state,
                importerInfo: {
                    ...state.importerInfo,
                    loading: true,
                },
            };

        case GET_IMPORTER_INFO_SUCCESS:
            return {
                ...state,
                importerInfo: {
                    loading: false,
                    data: action.response,
                },
            };

        case GET_IMPORTER_INFO_ERROR:
            return {
                ...state,
                importerInfo: {
                    ...state.importerInfo,
                    loading: false,
                },
            };

        case GET_UPLOAD_INFO_REQUEST: {
            return {
                ...state,
                uploadInfo: {
                    loading: true,
                    data: null,
                    activeSource: state.uploads.data.find(({ id }) => id === action.params),
                },
            };
        }

        case GET_UPLOAD_INFO_SUCCESS: {
            return {
                ...state,
                uploadInfo: {
                    ...state.uploadInfo,
                    loading: false,
                    data: { ...action.response },
                },
            };
        }

        case GET_UPLOAD_INFO_ERROR: {
            return {
                ...state,
                uploadInfo: {
                    ...state.uploadInfo,
                    loading: false,
                    data: null,
                },
            };
        }

        case UPDATE_UPLOAD_DESCRIPTION_REQUEST: {
            return {
                ...state,
                descriptionSending: true,
            };
        }

        case UPDATE_UPLOAD_DESCRIPTION_ERROR: {
            return {
                ...state,
                descriptionSending: false,
            };
        }

        case CLEANUP_UPLOAD_INFO: {
            return {
                ...state,
                uploadInfo: {
                    ...getInitialState().uploadInfo,
                },
            };
        }

        case UPDATE_UPLOAD_DESCRIPTION_SUCCESS: {
            const { id, description } = action.response;

            return {
                ...state,
                descriptionSending: false,
                uploads: {
                    ...state.uploads,
                    data: [
                        ...state.uploads.data.map(upload => {
                            return id === upload.id
                                ? {
                                    ...upload,
                                    description,
                                }
                                : upload;
                        }),
                    ],
                },
            };
        }
        
        case UPDATE_UPLOAD_TITLE_REQUEST: {
            return {
                ...state,
                titleSending: true,
            };
        }
        
        case UPDATE_UPLOAD_TITLE_SUCCESS: {
            const { id, title } = action.response;
            
            return {
                ...state,
                titleSending: false,
                uploads: {
                    ...state.uploads,
                    data: [
                        ...state.uploads.data.map(upload => {
                            return id === upload.id
                                ? {
                                    ...upload,
                                    title,
                                }
                                : upload;
                        }),
                    ],
                },
            };
        }
        
        case UPDATE_UPLOAD_TITLE_ERROR: {
            return {
                ...state,
                titleSending: false,
            };
        }

        case CLEANUP_IMPORTER_INFO_STATE:
            return {
                ...state,
                importerInfo: getInitialState().importerInfo,
            };

        case CLEANUP_UPLOADS_PAGE:
            return getInitialState();

        default:
            return state;
    }
}

export function getUploads(body) {
    return {
        [CALL_API]: {
            endpoint: 'ENDPOINT_VOC_STORE_GET_UPLOADS',
            method: 'GET',
            types: [
                GET_UPLOADS_LIST_REQUEST,
                GET_UPLOADS_LIST_REQUEST_SUCCESS,
                GET_UPLOADS_LIST_REQUEST_ERROR,
            ],
            body,
        },
    };
}

export function cancelUpload(id) {
    return {
        [CALL_API]: {
            endpoint: `/store/upload/${id}/cancel`,
            types: [ CANCEL_UPLOAD_REQUEST ],
            method: 'GET',
            body: {},
        },
    };
}

export function downloadErrors(id) {
    return {
        [CALL_API]: {
            endpoint: `/store/upload/errors/${id}/initiate`,
            types: [ DOWNLOAD_ERRORS_REQUEST ],
            method: 'GET',
            logger: true,
            body: {},
        },
    };
}

export function getUploadRelations(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/upload/relations/${id}`,
            types: [ GET_UPLOAD_RELATIONS_REQUEST, GET_UPLOAD_RELATIONS_SUCCESS, GET_UPLOAD_RELATIONS_ERROR ],
            method: 'GET',
            body: {},
        },
    };
}

export function deleteUpload(id) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/upload/delete/${id}`,
            types: [ DELETE_UPLOAD_REQUEST, DELETE_UPLOAD_SUCCESS, DELETE_UPLOAD_ERROR ],
            method: 'DELETE',
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyDeleted', { entity: t('upload') }),
                error: t('errorDuringUploadDeleteProcess'),
            },
            body: {},
        },
    };
}

export function reLemmatization(sourceId) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/lemma/initiate`,
            types: [ LEMMATIZATION_UPLOAD_REQUEST, LEMMATIZATION_UPLOAD_SUCCESS, LEMMATIZATION_UPLOAD_ERROR ],
            method: 'POST',
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyAddedToQueue', { entity: t('upload') }),
                error: t('messages.somethingWentWrong'),
            },
            body: {
                sourceId,
            },
        },
    };
}

export function uncoverUpload(id) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/upload/uncover/${id}`,
            types: [ UNCOVER_UPLOAD_REQUEST, UNCOVER_UPLOAD_SUCCESS, UNCOVER_UPLOAD_ERROR ],
            method: 'POST',
            logger: true,
            loggerMessages: {
                success: t('uploadWasSuccessfullyShown'),
                error: t('errorDuringUploadModification'),
            },
            body: {},
        },
    };
}

export function getImporterInfo(importId) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/import/${importId}`,
            types: [ GET_IMPORTER_INFO_REQUEST, GET_IMPORTER_INFO_SUCCESS, GET_IMPORTER_INFO_ERROR ],
            method: 'GET',
        },
    };
}

export function syncSocialSource(uploadId) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/import/${uploadId}/synchronize`,
            types: [ SYNC_SOCIAL_SOURCE_REQUEST, SYNC_SOCIAL_SOURCE_SUCCESS, SYNC_SOCIAL_SOURCE_ERROR ],
            method: 'POST',
            logger: true,
            loggerMessages: {
                success: t('successfullySynchronized'),
                error: t('errorDuringSynchronization'),
            },
        },
    };
}

export function followSocialSource(uploadId, status) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/import/${uploadId}/edit`,
            types: [ FOLLOW_SOCIAL_SOURCE_REQUEST, FOLLOW_SOCIAL_SOURCE_SUCCESS, FOLLOW_SOCIAL_SOURCE_ERROR ],
            method: 'PUT',
            logger: true,
            body: {
                status,
            },
        },
    };
}

export function getUploadInfo(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/upload/relations/${ id }`,
            types: [
                GET_UPLOAD_INFO_REQUEST,
                GET_UPLOAD_INFO_SUCCESS,
                GET_UPLOAD_INFO_ERROR,
            ],
            method: 'GET',
            params: id,
            logger: true,
        },
    };
}

export function updateUploadDescription(id, description) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/upload/${ id }/description`,
            types: [
                UPDATE_UPLOAD_DESCRIPTION_REQUEST,
                UPDATE_UPLOAD_DESCRIPTION_SUCCESS,
                UPDATE_UPLOAD_DESCRIPTION_ERROR,
            ],
            method: 'PUT',
            logger: true,
            body: { description },
        },
    };
}

export function updateUploadTitle(id, title) {
    debugger
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/upload/rename/${ id }`,
            types: [
                UPDATE_UPLOAD_TITLE_REQUEST,
                UPDATE_UPLOAD_TITLE_SUCCESS,
                UPDATE_UPLOAD_TITLE_ERROR,
            ],
            method: 'PUT',
            logger: true,
            body: { title },
        },
    };
}

export function cleanupUploadInfo() {
    return {
        type: CLEANUP_UPLOAD_INFO,
    };
}

export function updateUploadList(upload, updatedUploadIndex) {
    return {
        type: UPDATE_UPLOAD_LIST,
        upload,
        updatedUploadIndex,
    };
}

export function updateFilters(payload) {
    return {
        type: UPLOAD_LIST_FILTERS_CHANGED,
        payload,
    };
}

export function cleanupImporterInfoState() {
    return {
        type: CLEANUP_IMPORTER_INFO_STATE,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_UPLOADS_PAGE,
    };
}
