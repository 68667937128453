import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';

import { RowRates, DragHandle, RowActions, RowChannels } from '/feedback/components';
import { TooltipWrapper } from '/components';

export const SortableItem = SortableElement(item => {
    const isAllowDrag = item.self.props.filters.sortBy === '-sequence';

    return (
        <tr>
            <td className='noselect'>
                { isAllowDrag && <DragHandle/> }
                <div className={ cx('id-wrapper', { isAllowDrag }) }>
                    <TooltipWrapper value={ item.value.id }>
                        <span>{ item.value.id }</span>
                    </TooltipWrapper>
                </div>
            </td>
            <td>
                <Link
                    to={ `/feedback/survey/${item.value.id}/questions` }
                    className='wrap'
                >
                    <FontAwesomeIcon icon='comments' />
                    <div className='surveyNameWrapper'>
                        <TooltipWrapper value={ item.value.name }>
                            <span>{ item.value.name }</span>
                        </TooltipWrapper>
                    </div>
                </Link>
            </td>
            <td>
                <RowChannels surveys={ item.value.surveys }/>
            </td>
            <td>
                <RowRates
                    rates={ item.value.rates }
                    self={ item.self }
                />
            </td>
            <td>
                <RowActions
                    id={ item.value.id }
                    surveyGroup={ item.value }
                    openModal={ item.self.props.openModal }
                    showToast={ item.self.props.showToast }
                    rights={ item.value.rights }
                />
            </td>
        </tr>
    );
});

SortableItem.propTypes = {
    openCopySurveyModal: PropTypes.func,
};
SortableItem.defaultProps = {};
