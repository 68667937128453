import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { HelperService } from '/services';
import { showToast } from '/components';

let foundSpaceForCopyEarlier = false;
const placeholdersDebouncer = HelperService.debounce(({ value, callback }) => {
    callback?.(value?.id);
}, 500);

export const useCopyFindPlaceDebounce = gadgetClipboard => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const callback = () => {
        dispatch(showToast({ text: t('selectPlaceOnPage'), type: 'info' }));
    };

    const copyFindPlaceDebounce = ({ isFoundPlace, ...rest }) => {
        if(!foundSpaceForCopyEarlier) {
            placeholdersDebouncer({
                ...rest,
                ...isFoundPlace
                    ? { callback }
                    : {},
            });
        }

        foundSpaceForCopyEarlier = foundSpaceForCopyEarlier || isFoundPlace;
    };

    useEffect(() => {
        foundSpaceForCopyEarlier = false;
    }, [ gadgetClipboard ]);

    return {
        copyFindPlaceDebounce,
    };
};
