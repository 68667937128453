import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { HelperService, useEventListener } from '/services';
import propTypes from 'prop-types';
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuList, Option } from '/components';
import { SelectAsyncOption, SelectAsyncSelectValue, SourceFilter } from './components';
import { FormText } from 'reactstrap';

import styles from './style.module.scss';

const searchTypeDebounce = HelperService.debounce((...args) => {
    const { filters, requestHandler } = args[0];

    requestHandler({ ...filters });
}, 500);

export const VocFormSelectAsyncPaginate = ({
    input,
    customValue,
    className,
    placeholder,
    isClearable,
    options,
    required,
    withError,
    disabled,
    isMulti,
    portal,
    filters,
    requestHandler,
    setFilters,
    loading,
    total,
    hideSelectedOptions = true,
    closeMenuOnSelect,
    onClose,
    onMenuOpenHandler,
    isTooltip = true,
    sourceFilter,
    meta: {
        touched,
        error,
    },
}) => {
    const [ open, setOpenValue ] = useState(false);
    const [ openSourceFilter, setOpenSourceFilter ] = useState(false);

    const onMenuScrollToBottom = event => {
        const { scrollHeight, scrollTop, offsetHeight } = event.target;

        if (scrollHeight - scrollTop !== offsetHeight) return;

        const { page, limit } = filters;

        if (page * limit < total && !loading) {
            const data = {
                ...filters,
                page: page + 1,
            };

            setFilters(data);
            requestHandler(data);
        }
    };

    const scrollHandler = useCallback(onMenuScrollToBottom, [ total, filters.page, loading ]);
    const scrollElement = document.querySelector('.vochub-select-control__menu-list');

    useEventListener('scroll', scrollHandler, scrollElement);


    const onInputChange = (search, { action }) => {
        if ((action === 'input-change' || action === 'set-value') && filters && search !== filters.search) {
            setFilters({ ...filters, search, page: 1 });
            searchTypeDebounce({ filters: { ...filters, search }, requestHandler });
        }
    };

    const onMenuOpen = () => {
        if ((options.length === 0 || options.length % 15 !== 0) && !loading && !open) {
            searchTypeDebounce({ filters, requestHandler });
        }

        setOpenValue(true);
        onMenuOpenHandler && onMenuOpenHandler();
    };

    const onChange = changedOptions => {
        input.onChange(changedOptions);

        if((!changedOptions || changedOptions?.length < customValue?.length) && open) {
            requestHandler({ ...filters }, null, changedOptions || []);
        }
    };

    const config = {
        components: { MenuList, Option },
    };

    if (portal) {
        config['menuPortalTarget'] = document.body;
        config['styles'] = { menuPortal: base => ({ ...base, zIndex: 9999 }) };
        config['menuShouldScrollIntoView'] = false;
        config['menuPlacement'] = "auto";
    }

    if (isMulti) {
        config['isMulti'] = isMulti;
        config['closeMenuOnSelect'] = closeMenuOnSelect || false;
        config['onChange'] = onChange;
    }

    const blurHandler = () => {
        setOpenValue(false);
        input.onBlur();
    };

    const onMenuClose = () => {
        setOpenValue(false);
        setFilters({ search: '', page: 1 });
        onClose && onClose();
    };

    return (
        <div
            className={ cx(
                className,
                'app-form-control__container',
                { [styles.sourceFilter]: sourceFilter },
                { 'with-error': withError && !sourceFilter },
            ) } >
            <Select
                onMenuOpen={ onMenuOpen }
                onInputChange={ onInputChange }
                isLoading={ loading }
                isSearchable
                filterOption={ false }
                id={ input.name }
                options={ options }
                isDisabled={ disabled }
                className={ `vochub-select-control ${ className }` }
                classNamePrefix={ 'vochub-select-control' }
                isTooltip={ isTooltip }
                { ...input }
                { ...{
                    placeholder,
                    required,
                    ...config,
                } }
                error={ Boolean(touched && error) }
                value={ customValue || input.value }
                onMenuClose={ onMenuClose }
                isClearable={ isClearable }
                onBlur={ blurHandler }
                hideSelectedOptions={ hideSelectedOptions }
                components={{
                    Option: SelectAsyncOption,
                    ValueContainer: SelectAsyncSelectValue,
                }}
            />
            {
                Boolean(touched && error && withError)
                    ? <FormText className="app-form-control__error">{ error }</FormText>
                    : false
            }
            {
                sourceFilter
                && <button
                    className={ cx(styles.filterButton, { [styles.filterButtonActive]: openSourceFilter }) }
                    onClick={ () => setOpenSourceFilter(!openSourceFilter) }
                >
                    <FontAwesomeIcon icon='filter'/>
                </button>
            }
            {
                sourceFilter && <SourceFilter open={ openSourceFilter }/>
            }
        </div>
    );
};

VocFormSelectAsyncPaginate.propTypes = {
    options: propTypes.array,
    placeholder: propTypes.string,
    className: propTypes.string,
    loading: propTypes.bool,
    isClearable: propTypes.bool,
    required: propTypes.bool,
    disabled: propTypes.bool,
    portal: propTypes.bool,
    requestHandler: propTypes.func,
    total: propTypes.number,
    setFilters: propTypes.func,
    withError: propTypes.bool,
    filters: propTypes.object,
    sourceFilter: propTypes.bool,
};

VocFormSelectAsyncPaginate.defaultProps = {
    className: '',
    isMulti: false,
    withError: true,
    isClearable: true,
    sourceFilter: false,
};
