import React, { Component, Fragment } from 'react';
import { VocModal, FormFieldItem, VocSelect } from '/components';
import { autobind } from 'core-decorators';
import { HelperService } from '/services';
import { Button, Row, Col, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CopySurveyModal.scss';

export class CopySurveyModal extends Component {
    state = {
        unit: null,
        name: '',
        channels: [],
        isRequestPending: false,
    };

    @autobind
    getInitialState() {
        this.setState({
            unit: null,
            name: '',
            channels: HelperService.getPossibleChannels().map((channel, index) =>
                ({
                    name: channel,
                    checked: false,
                    id: index,
                }),
            ),
            isRequestPending: false,
        });
    }

    @autobind
    onChangeUnit(unit) {
        this.setState({ unit });
    }

    @autobind
    handleChangeNameInput(event) {
        let name = event.target.value;

        if(name.indexOf(' ') === 0) {
            name = name.trim();
        }

        this.setState({ name });
    }

    @autobind
    handleChannelsCheckbox(id) {
        const { channels } = this.state;
        const clickedChannel = channels.find(channel => channel.id === id);

        this.setState({
            channels: [
                ...channels.map(channel => {
                    channel.checked = channel.id === id ? !clickedChannel.checked : channel.checked;
                    return channel;
                }),
            ],
        });
    }

    @autobind
    onCopySurveyClicked() {
        const params = {
            ...this.state,
            channels: this.state.channels
                .filter(channel => channel.checked)
                .map(channel => channel.name),
        };

        params.unit = params.unit.value;
        this.setState({ isRequestPending: true });

        this.props.actionHandler(params)
            .then(() => {
                this.setState({ isRequestPending: false });
            })
            .catch(() => {
                this.setState({ isRequestPending: false });
            });

        this.props.close();
    }

    @autobind
    isSubmitButtonDisabled() {
        const {
            isRequestPending,
            name,
            unit,
        } = this.state;

        return !Boolean(!isRequestPending && name && unit);
    }

    getModalFooter() {
        return (
            <Fragment>
                <Button onClick={ this.props.close } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button onClick={ this.onCopySurveyClicked }
                    disabled={ this.isSubmitButtonDisabled() }
                    color="primary">
                    <FontAwesomeIcon icon="check"/>
                    <span> Copy Survey</span>
                </Button>
            </Fragment>
        );
    }

    render() {
        return (
            <VocModal
                isOpen={ this.props.show }
                onOpened={ this.getInitialState }
                toggle={ this.props.close }
                header={ 'Copy survey' }
                footer={ this.getModalFooter() }>
                <FormFieldItem
                    controlId="survey_name"
                    type="text"
                    isHorizontal={ true }
                    disabled={ this.props.loading }
                    value={ this.state.name }
                    label="Name *"
                    handleChange={ this.handleChangeNameInput }
                />
                <VocSelect
                    label="Unit *"
                    placeholder="Select a unit"
                    isClearable={ false }
                    isHorizontal={ true }
                    options={ HelperService.mapUnitsToSelectModel(this.props.units) }
                    value={ this.state.unit }
                    onChange={ this.onChangeUnit }
                />
                <FormGroup>
                    <Row>
                        <Col md={ 3 }
                            className="text-field-control-label">
                            <Label>Channel</Label>
                        </Col>
                        <Col md={ 9 }>
                            <Row>
                                {
                                    this.state.channels.map((channel, index) => (
                                        <Col md={ 3 }
                                            key={ index }>
                                            <input className="copy-survey__input" type="checkbox"
                                                name={ channel.name }
                                                onChange={ () => this.handleChannelsCheckbox(channel.id) }
                                                id={ channel.name }
                                                checked={ channel.checked }/>
                                            <Label className="copy-survey__input-label" htmlFor={ channel.name }>
                                                <span className="copy-survey__input-text">{ channel.name }</span>
                                            </Label>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                </FormGroup>
            </VocModal>
        );
    }
}

CopySurveyModal.propTypes = {
    units: PropTypes.array,
    show: PropTypes.bool,
    close: PropTypes.func,
    actionHandler: PropTypes.func,
};

