import React, { useEffect, useState } from 'react';
import { Input, Row, Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { VocModal } from '/components';
import { changeDashboardSettings } from '/visual/modules/VocVisual.modules';
import { updateDashboardData } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { TChangeDashboardSettingsBody } from '/visual/modules/models';
import { IProps } from './models';

import styles from './style.module.scss';

export const EditDashboardTitleModal = ({
    show,
    toggle,
    dashboard,
}: IProps) => {
    const { t } = useTranslation();
    const [ title, setTitle ] = useState<string>('');
    const [ description, setDescription ] = useState<string>('');
    const { dashboardId } = useParams<{ dashboardId: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (show) {
            setTitle(dashboard?.title);
            setDescription(dashboard?.description);
        }
    }, [ show ]);

    const onApply = () => {
        const titleTrim = title.trim();
        const descriptionTrim = description.trim();
        const dashboardSettings: TChangeDashboardSettingsBody = {
            title: titleTrim,
            description: descriptionTrim,
            isDefault: dashboard.isDefault,
        };

        // This notifies the server that all inherited drill down dashboards title
        // need to be changed according to parent title
        // https://sandsiv.atlassian.net/browse/VOC-11048
        // Currently set only once when title is edited for the first time
        if (!dashboard.isUseCustomTitle && dashboard.title !== titleTrim) {
            dashboardSettings.isUseCustomTitle = true;
        }

        titleTrim && dispatch(
            changeDashboardSettings(
                dashboard.id,
                dashboardSettings,
                t('messages.entityIsRenamed', { entity: t('dashboard') }),
            ))
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .promise.then(() => {
                if (dashboardId) {
                    dispatch(updateDashboardData({
                        title: titleTrim,
                        description: descriptionTrim,
                    }));
                }

                toggle();
            });
    };

    const onChange = (e: any) => {
        let newName = e.target.value;

        if (newName.indexOf(' ') === 0) {
            newName = newName.trim();
        }

        setTitle(newName);
    };

    const onPressEnter = (e: any) => {
        e.key === 'Enter' && onApply();
    };

    const getModalBody = () => (
        <div className={ styles.modalWrapper }>
            <Row className={ styles.fieldWrapper }>
                <Label className={ styles.fieldLabel }>
                    <Label htmlFor='title_field'>
                        <span>
                            { t('field.title') }
                            &nbsp;
                            <span className='star'>*</span>
                        </span>
                    </Label>
                    <Input
                        autoFocus
                        id='title_field'
                        value={ title }
                        onChange={ onChange }
                        onKeyUp={ onPressEnter }
                    />
                </Label>
            </Row>

            <Row className={ styles.fieldWrapper }>
                <Label htmlFor='description_field'>
                    <span>{ t('field.description') }</span>
                </Label>
                <Input
                    type='textarea'
                    id='description_field'
                    value={ description }
                    onChange={ ({ target }) => setDescription(target.value) }
                />
            </Row>
        </div>
    );

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color='white'
                onClick={ toggle }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color='primary'
                disabled={ !title }
                onClick={ onApply }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                { t('save') }
            </Button>
        </div>
    );

    return (
        <VocModal
            isOpen={ show }
            size='ms'
            header={ t('editDashboardTitle') }
            modalClassName='modal-scrollable'
            footer={ getModalFooter() }
            toggle={ toggle }
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};
