import React, { useState } from 'react';
import SplitPane, { Pane } from 'split-pane-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from 'reactstrap';
import cx from 'classnames';

import { InsightContent } from '/query/components/InsightBlock/components';
import { TooltipWrapper, SplitPaneSash } from '/components';
import { HelperService } from '/services';
import { IInsightSideBlock } from './models';

import styles from './style.module.scss';

export const InsightSideBlock = ({ insightData, chartType }: IInsightSideBlock) => {
    const [ isExpandInsightContent, setIsExpandInsightContent ] = useState<boolean>(false);
    // total min-width of container- 415px (Query.js pane), so the sum of these panes must be less or equal to 415.
    const [ sizes, setSizes ] = useState([ 'auto', 390 ]);
    const isContentExist = HelperService.checkNotNullOrUndefined(insightData[chartType].data);
    const isError = Boolean(insightData[chartType]?.error);

    const toggleInsightContent = () => {
        setIsExpandInsightContent(!isExpandInsightContent);
    };

    return (
        <div className={ cx(styles.insightSideBlock, { [styles.show]: isExpandInsightContent }) }>
            <div
                className={ styles.backdrop }
                onClick={ () => setIsExpandInsightContent(false) }
            />

            <SplitPane
                className={ styles.insightSideBlockSplit }
                split='vertical'
                sizes={ sizes }
                resizerSize={ 1 }
                allowResize={ isExpandInsightContent }
                sashRender={ SplitPaneSash }
                onChange={ setSizes }
            >
                <Pane minSize={ 48 }>
                    <ButtonGroup
                        vertical
                        className={ styles.controls }
                    >
                        <TooltipWrapper value={ `${ isExpandInsightContent ? 'Hide' : 'Show' } insight` } force>
                            <Button
                                className={ cx(styles.toggleContentBtn, {
                                    [styles.active]: isContentExist,
                                    [styles.withError]: isError,
                                }) }
                                onClick={ toggleInsightContent }
                            >
                                <FontAwesomeIcon icon='book-open' />
                            </Button>
                        </TooltipWrapper>
                    </ButtonGroup>
                </Pane>
                <Pane className={ styles.insightContentPane } minSize={ 300 }>
                    <div className={ styles.insightHeader }>
                        <h5>
                            <strong>{ chartType }:</strong>
                            &nbsp;
                            <span>Insight Narrator</span>
                        </h5>
                        <Button
                            outline
                            color='white'
                            className={ styles.close }
                            onClick={ toggleInsightContent }
                        >
                            <FontAwesomeIcon icon='times' />
                        </Button>
                    </div>

                    <InsightContent
                        chartType={ chartType }
                        data={ insightData }
                    />
                </Pane>
            </SplitPane>
        </div>
    );
};
