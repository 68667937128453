import { createSelector } from "reselect";

export const commonSelector = state => state.UploadFiles;

export const assetsSelector = createSelector(
    [ commonSelector ],
    ({ assets }) => {
        return {
            loading: assets.loading,
            files: [
                ...assets.dirs.map(dir => ({
                    name: dir,
                    itemType: 'folder',
                })),
                ...assets.files.map(file => ({
                    name: file.name,
                    itemType: 'file',
                    url: `${ assets.base_path }${ file.name }`,
                })),
            ],
        };
    });
