import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageRequests, DownloadFileService } from '/services';
import { showToast, VocCleaupHOC } from '/components';
import { ImportsTable, AddImportModal } from './components';
import {
    getImports,
    getSurveys,
    makeImport,
    updateFilters,
    cleanup,
    downloadImportFile,
} from './modules/imports.modules';
import {
    importsListTableDataSelector,
    filtersSelector,
    importsListTableCountSelector,
    importsTableSettingsSelector,
    mapSurveysGroupToModal,
    importFormValuesSelector,
    getPermissionSelector,
} from './modules/imports.selectors';

const mapStateToProps = state => ({
    tableData: importsListTableDataSelector(state),
    tableCount: importsListTableCountSelector(state),
    filters: filtersSelector(state),
    tableSettings: importsTableSettingsSelector(state),
    surveys: mapSurveysGroupToModal(state),
    importFormValues: importFormValuesSelector(state),
    permission: getPermissionSelector(state),
});

const mapDispatchToProps = {
    makeImport,
    getImports,
    getSurveys,
    updateFilters,
    showToast,
    cleanup,
    downloadImportFile,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class Imports extends Component {
    state = {
        openModal: false,
    };
    pageRequests = new PageRequests();

    componentDidMount() {
        this.pageRequests
            .addRequest(this.props.getSurveys())
            .addRequest(this.props.getImports(this.props.filters), 'getImports');
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        const model = {
            sort: filters.sort_field ? (filters.sort_order === 'desc' ? '' : '-') + filters.sort_field : '',
            limit: filters.limit,
            page: filters.page,
            search: filters.search ? filters.search : '',
            hide_empty_imports: filters.hide_empty_imports,
        };

        this.pageRequests.cleanupById('getImports');
        const request = this.props.getImports(model);

        this.pageRequests.addRequest(request, 'getImports');
    }

    @autobind
    refreshListMethod() {
        this.requestTrigger(this.props.filters);
    }

    @autobind
    closeModal() {
        this.setState({ openModal: false });
    }

    @autobind
    openModal() {
        this.props.permission && this.setState({ openModal: true });
    }

    @autobind
    makeImport({ file, params }) {
        return this.props.makeImport(file, params)
            .then(() => {
                this.requestTrigger();
                this.closeModal();
            });
    }

    @autobind
    handleDownloadFile(downloadData) {
        this.props.downloadImportFile(downloadData).promise
            .then(data => {
                DownloadFileService.prepareAndDownloadFile({
                    data,
                    type: 'text/csv;charset=utf-8;',
                    fileName: downloadData.fileName,
                });
            });
    }

    render() {
        const {
            importFormValues,
            updateFilters,
            tableSettings,
            permission,
            tableData,
            showToast,
            filters,
            surveys,
        } = this.props;

        return (
            <DocumentTitle title="Import History">
                <div>
                    <Card className={ 'page__wrapper' }>
                        <CardHeader className={ 'panel__header panel__header--buttons' }>
                            <span className={ 'panel__header-title' }>
                                <FontAwesomeIcon icon="list-ul"/> Import History
                            </span>
                            <div className={ 'panel__header-buttons' }>
                                <Button className={ 'panel__header-button' }
                                    outline
                                    color="white"
                                    onClick={ this.openModal }
                                    disabled={ !permission }
                                >
                                    <FontAwesomeIcon icon='plus' className={ 'color-green' }/>
                                    <span> Add Import</span>
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <ImportsTable
                                tableState={ tableSettings }
                                filters={ filters }
                                data={ tableData }
                                permission={ permission }
                                updateFilters={ updateFilters }
                                requestTrigger={ this.requestTrigger }
                                onDownloadFile={ this.handleDownloadFile }
                            />
                        </CardBody>
                    </Card>
                    <AddImportModal
                        surveys={ surveys }
                        show={ this.state.openModal }
                        refreshListMethod={ this.refreshListMethod }
                        actionHandler={ this.makeImport }
                        showToast={ showToast }
                        formValues={ importFormValues }
                        close={ this.closeModal }
                    />
                </div>
            </DocumentTitle>
        );
    }
}
