import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteUploadContent = ({ uploadToDelete }) => {
    const { t } = useTranslation();
    const {
        needToShowDetails,
        classifiers,
        gadgets,
        gadgetsV2,
        queries,
        segmentators,
        virtualSources,
        topics,
    } = uploadToDelete;

    const mapSourceData = () => {
        const sourceDataCollections = {
            'topics': t('aiAppliance'),
            'classifiers': t('classifiers'),
            'gadgets': t('gadgetsVisual'),
            'gadgetsV2': t('gadgetsVisualV2'),
            'segmentators': t('recoders'),
            'queries': t('queries'),
            'virtualSources': t('virtualSources'),
        };

        return Object.entries(sourceDataCollections)
            .map(([ sourceDataCollection, title ]) => {
                if (uploadToDelete[sourceDataCollection].length) {
                    return (
                        <details className='upload-to-delete-details' key={ title }>
                            <summary>{ title }</summary>
                            <ul>
                                {
                                    mapCollectionItems(sourceDataCollection)
                                }
                            </ul>
                        </details>
                    );
                }
            });
    };

    const mapCollectionItems = collection => uploadToDelete[collection]
        .map((item, i) => <li key={ i }> { item }</li>);

    return (
        <div>
            <p>
                {
                    t('deleteUploadInfo', {
                        virtualSourcesCount: virtualSources.length,
                        classifiersCount: classifiers.length,
                        segmentatorsCount: segmentators.length,
                        queriesCount: queries.length,
                        gadgetsCount: gadgets.length,
                        gadgetsV2Count: gadgetsV2.length,
                        topicsCount: topics?.length,
                    })
                }
            </p>
            {
                needToShowDetails && <strong>
                    { `${ t('details') }:` }
                </strong>
            }
            { needToShowDetails && <br/> }
            { mapSourceData() }
        </div>
    );
};

ConfirmDeleteUploadContent.propTypes = {
    uploadToDelete: PropTypes.object,
};
ConfirmDeleteUploadContent.defaultProps = {};

