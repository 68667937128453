import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import styles from './style.module.scss';

export const SplitPaneSash = ({ split = 'vertical' }) => (
    <div className={ cx(styles.splitPaneSash, styles[`splitPaneSash${split}`]) }>
        <FontAwesomeIcon icon="window-minimize" />
    </div>
);
