import { exception } from '/feedback/modules/VocFeedback.selectors';

export const questionFlowFormValidation = (values = {}) => {
    const errors = {
        conditions: [],
    };

    const {
        conditions = [],
        action_type,
        next_question,
        digi_runner_command,
        change_language_command,
    } = values;

    if (action_type && action_type.text === 'GoTo' && !next_question) {
        errors.next_question = 'Field must not be empty';
    }

    if (action_type && action_type.text === 'digi_runner_command' && !digi_runner_command) {
        errors.digi_runner_command = 'Field must not be empty';
    }

    if (action_type && action_type.text === 'change_language_command' && !change_language_command) {
        errors.change_language_command = 'Field must not be empty';
    }

    conditions.map((condition, index) => {
        const { matching_value, question_id, expression } = condition;
        let matchingValueToNumber = +matching_value,
            isOnlyNumbers = false,
            isNotEmpty = false;

        if (expression) {
            isOnlyNumbers = [ '>=', '<=', '>', '<' ].includes(expression.value);
            isNotEmpty = !exception.includes(expression.value);
        }

        if (matching_value && typeof matching_value === 'object') {
            matchingValueToNumber = matching_value.value === 'dont_know' ? matching_value.value : +matching_value.value;
        }

        if (!question_id) {
            errors.conditions[index] = {
                question_id: 'Field must not be empty',
            };
        }

        if (!expression) {
            errors.conditions[index] = {
                expression: 'Field must not be empty',
            };
        }

        if (isNotEmpty && !matching_value) {
            errors.conditions[index] = {
                matching_value: 'Field must not be empty',
            };
        } else if (typeof matchingValueToNumber !== 'number' && isOnlyNumbers) {
            errors.conditions[index] = {
                matching_value: 'Field must not be string',
            };
        }
    });

    return errors;
};
