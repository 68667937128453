import React from 'react';
import { VirtualSourceFormSidebarHead } from '../VirtualSourceFormSidebarHead';
import { VirtualSourceFormSidebarItem } from '../VirtualSourceFormSidebarItem';
import { VirtualSourceFormBodyHead } from '../VirtualSourceFormBodyHead';
import { VirtualSourceFormCell } from '../VirtualSourceFormCell';

export const VirtualSourceMappingCell = ({
    columnIndex,
    rowIndex,
    virtualSourceName,
    handleSourceName,
    addSource,
    addColumn,
    virtualSourceSidebar,
    updateSelectedSource,
    removeVirtualSourceRow,
    virtualSourceColumnHeader,
    columnTypes,
    updateColumnType,
    removeVirtualSourceColumn,
    updateColumnName,
    virtualSourceCells,
    updateCellData,
    autofillColumnsHandler,
    secondAutofillColumnsHandler,
    isAutoFill,
    setAutoFill,
    virtualRelation,
    showChangeNotificationModal,
    setShowChangeNotificationModal,
    showConfirm,
}) => {
    if (!columnIndex && !rowIndex) {
        return <VirtualSourceFormSidebarHead
            sourceName={ virtualSourceName }
            handleSourceName={ handleSourceName }
            addSource={ addSource }
            addColumn={ addColumn }
            isAutoFill={ isAutoFill }
        />;
    } else if (!columnIndex && rowIndex) {
        const {
            id, title,
            channel, state,
            type, customId,
            index, excludeIds, isNew,
        } = virtualSourceSidebar[rowIndex - 1];

        return <VirtualSourceFormSidebarItem
            key={ customId }
            id={ id }
            customId={ customId }
            index={ index }
            excludeIds={ excludeIds }
            handleSourceChange={ updateSelectedSource }
            removeVirtualSourceRow={ removeVirtualSourceRow }
            autofillColumnsHandler={ autofillColumnsHandler }
            secondAutofillColumnsHandler={ secondAutofillColumnsHandler }
            selectedOption={{
                value: id, label: title,
                title, channel,
                state, type,
            }}
            isFirstVirtualSourceSidebarLength={ virtualSourceSidebar.length === 1 }
            setAutoFill={ setAutoFill }
            isAutoFill={ isAutoFill }
            showChangeNotificationModal={ showChangeNotificationModal }
            setShowChangeNotificationModal={ setShowChangeNotificationModal }
            showConfirm={ showConfirm }
            virtualRelation={ virtualRelation }
            isNewRow={ isNew }
        />;
    } else if (columnIndex && !rowIndex) {
        const column = virtualSourceColumnHeader[columnIndex - 1];

        return <VirtualSourceFormBodyHead
            key={ column.id }
            mapColumnTypesToSelect={ () => columnTypes
                .map(type => ({
                    value: type,
                    label: type === 'CHOICE'
                        ? <>
                            { type }
                            <span className={ 'virtual-sources-form__header-hint' }> Use | as delimiter </span>
                        </>
                        : type,
                }))
            }
            onTypeChange={ updateColumnType }
            removeVirtualSourceColumn={ removeVirtualSourceColumn }
            onNameChange={ updateColumnName }
            virtualRelation={ virtualRelation }
            showChangeNotificationModal={ showChangeNotificationModal }
            setShowChangeNotificationModal={ setShowChangeNotificationModal }
            showConfirm={ showConfirm }
            { ...column }
        />;
    } else if (columnIndex && rowIndex) {
        const cell = virtualSourceCells[rowIndex - 1][columnIndex - 1];

        return <VirtualSourceFormCell
            updateCellData={ updateCellData }
            sourceId={ cell.sourceId }
            selected={ cell.selected }
            columnType={ cell.columnType }
            options={ cell.options }
            columnId={ cell.columnIdx }
            valid={ cell.valid }
            rowId={ rowIndex - 1 }
            cellId={ cell.key }
            isAutoFill={ isAutoFill }
            cellIsFixed={ cell.isFixed }
            disabled={ cell.disabled }
        />;
    }
};
