import { useEffect, useState } from 'react';
import DragSelect from 'dragselect';

let instanceDragSelect = null;
const disable = 0;
const enable = 1;

export const useDragSelect = (isEditOn, isRegular, dateGridData, setDateGridData) => {
    const [ selectedClass, useSelectedClass ] = useState('');

    const areaChangeHandler = elements => {
        const data = Array.from(elements);
        const dateGridModel = [ ...dateGridData ];

        data.forEach(({ dataset: { inx, key } }) => {
            const timeSlotsModel = dateGridModel[Number(inx)].time_slots;

            timeSlotsModel[key] = selectedClass === 'start-selected-red' ? enable : disable;

            dateGridModel[inx].time_slots = timeSlotsModel;
        });

        setDateGridData(dateGridModel);
        useSelectedClass('');
    };

    useEffect(() => {
        if (instanceDragSelect) {
            instanceDragSelect.callback = elements => areaChangeHandler(elements);
        }
    }, [ dateGridData, selectedClass ]);

    useEffect(() => {
        if (instanceDragSelect) {
            instanceDragSelect.callback = elements => areaChangeHandler(elements);
        }
    }, [ dateGridData ]);

    useEffect(() => {
        if (isEditOn) {
            setDragSelect(areaChangeHandler);
        } else if (!isEditOn && instanceDragSelect) {
            reset();
        }
    }, [ isEditOn, dateGridData.length ]);

    const setDragSelect = areaChangeHandler => {
        if (!instanceDragSelect && isEditOn && dateGridData.length && isRegular) {
            instanceDragSelect = new DragSelect({
                area: document.getElementById('timeDateGrid__tbody'),
                selectables: document.getElementsByClassName('timeDateGrid__cell'),
                multiSelectKeys: [],
                onDragStart: event => {
                    const hasRedClass = event.target.classList.contains('red--cell');

                    useSelectedClass(
                        hasRedClass
                            ? 'start-selected-red'
                            : 'start-selected-blue',
                    );
                    event.preventDefault();
                },
                callback: elements => areaChangeHandler(elements),
            });
        }
    };

    const reInitDragSelect = areaChangeHandler => {
        if ((isRegular || isEditOn) && instanceDragSelect) {
            reset();
            setDragSelect(areaChangeHandler);
        }
    };

    const resetDragSelect = () => {
        if ((!isRegular || !isEditOn) && instanceDragSelect) {
            reset();
        }
    };

    const reset = () => {
        instanceDragSelect.stop();
        instanceDragSelect = undefined;
    };

    return {
        setDragSelect,
        reInitDragSelect,
        resetDragSelect,
        instanceDragSelect,
        selectedClass,
    };
};
