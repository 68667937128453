import uuid from 'uuid/v1';
import { autobind } from 'core-decorators';

export class PageRequests {
    constructor(requests = []) {
        this.requests = requests;
    }

    @autobind
    addRequest(request, id) {
        if (!id) {
            this.requests.push({ id: uuid(), xhr: request.xhr });
        }
        this.requests.push({ id, xhr: request.xhr });

        return this;
    }

    cleanup() {
        this.requests.forEach(request => {
            request.xhr && request.xhr.abort();
        });
        this.requests = [];
    }

    cleanupAsync() {
        return new Promise(resolve => {
            this.cleanup();

            resolve();
        });
    }

    cleanupById(id) {
        const request = this.requests.find(request => request.id === id);

        if (request) {
            request.xhr.abort();
            this.requests = this.requests.filter(request => request.id !== id);
        }

        return this;
    }
}
