import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'd3';
import cx from 'classnames';
import { HelperService } from '/services';
import { RangeInput, EmptyData } from '/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CoOccurrenceGraph } from '../CoOccurrenceGraph';
import { SensitivityInfo } from "../SensitivityInfo";
import {
    correlationLoadingSelector,
    getQueryLemmataLimitForCorrelation,
    getQueryCorrelationLimit,
} from '/query/modules/query.selectors';

import './QueryCorrelationGraph.scss';

const getDataWithCorrelation = (graphData, correlationValue) => {
    let { nodes, links } = graphData;
    let linkedNames = [];

    links = links.filter(el => el.value >= correlationValue);
    links.forEach(({ source, target }) => {
        linkedNames = typeof source === 'string'
            ? [ ...linkedNames, source, target ]
            : [ ...linkedNames, source.name, target.name ];
    });

    const linkNames = [ ...new Set([ ...linkedNames ]) ];

    nodes = nodes.filter(el => linkNames.includes(el.name));

    return {
        nodes,
        links,
    };
};

const changeLimitDebouncer = HelperService.debounce(({ value, callback }) => {
    callback(value);
}, 0);

export const QueryCorrelationGraph = ({
    width,
    height,
    graphData,
    name,
    newChangeFilter,
    updatePaginationFilters,
    updateCorrelationLimit,
    filterForInclude,
    filterForExclude,
    idInclude,
    idExclude,
    extraNameForFilter,
    requestByChart,
    setShowRefreshButton,
    showRefreshButton,
}) => {
    const [ statusButton, setStatusButton ] = useState(true);
    const [ isCorrelationChange, setIsCorrelationChange ] = useState(true);
    const [ correlationData, setCorrelationData ] = useState({ nodes: [], links: [] });
    const [ selectedFilterType, setSelectedFilterType ] = useState(idInclude);
    const [ secondFilterType, setSecondFilterType ] = useState(idExclude);

    const limit = useSelector(getQueryLemmataLimitForCorrelation);
    const correlationLimit = useSelector(getQueryCorrelationLimit);
    const isLoading = useSelector(correlationLoadingSelector);
    const isNotEmpty = graphData.nodes.length;
    const isNotEmptyFiltered = correlationData.nodes.length;

    useEffect(() => {
        setCorrelationData(getDataWithCorrelation(graphData, correlationLimit));
    }, [ correlationLimit, graphData ]);

    useEffect(() => {
        const circles = select('#graph').selectAll("circle");

        circles.attr("class", d => changeClass(d.name));
    }, [ filterForInclude.values, filterForExclude.values ]);

    const changeClass = word => {
        const { isInclude, isExclude } = checkEntry(word);
        let classes = '';

        if (isInclude) {
            classes = 'included';
        } else if (isExclude) {
            classes = 'excluded';
        }

        return classes;
    };

    const checkEntry = text => {
        return {
            isInclude: filterForInclude.values.includes(text),
            isExclude: filterForExclude.values.includes(text),
        };
    };

    const changeStatusButton = status => {
        setStatusButton(status);
        setSelectedFilterType(status ? idInclude : idExclude);
        setSecondFilterType(!status ? idInclude : idExclude);
    };

    const getControls = () => (
        <>
            <div className='btns-wrapper'>
                <button className={ cx('btn', { active: statusButton }) }
                    onClick={ () => changeStatusButton(true) }
                >
                    <FontAwesomeIcon className="icon" icon={ 'eye' }/>
                    <span>Include lemmata</span>
                </button>
                <button className={ cx('btn', { active: !statusButton }) }
                    onClick={ () => changeStatusButton(false) }
                >
                    <FontAwesomeIcon className="icon" icon={ 'eye-slash' }/>
                    <span>Exclude lemmata</span>
                </button>
            </div>
        </>
    );

    const changeCorrelationLimit = limit => {
        !isCorrelationChange && setIsCorrelationChange(true);
        changeLimitDebouncer({ value: limit, callback: updateCorrelationLimit });
    };

    const changeLemmataLimit = limit => {
        setShowRefreshButton(true);
        updatePaginationFilters({ limit, name , extraNameForFilter });
    };

    const getInput = type => {
        const rangePropsModels = {
            'correlation': {
                label: 'Correlation',
                value: correlationLimit,
                onChange: changeCorrelationLimit,
                orientation: 'vertical',
                step: .01,
                max: 1,
                trackPointsId: 'correlationGraphRange_',
            },
            'sensitivity': {
                label: 'Sensitivity',
                value: limit,
                onChange: changeLemmataLimit,
                refreshCallBack: requestByChart,
                showRefreshButton,
                trackPointsId: 'sensitivityGraphRange_',
            },
        };

        return <RangeInput { ...rangePropsModels[type] } />;
    };

    const onWordClick = text => {
        newChangeFilter({
            text,
            selectedFilterType,
            secondFilterType,
            allFilters: {
                [idInclude]: filterForInclude,
                [idExclude]: filterForExclude,
            },
        });
    };

    return (
        <div className={ 'word-cloud__section word-cloud__section--correlation' }>
            {
                !isNotEmpty && !isLoading
                    ? <>
                        <EmptyData errorText={ graphData.error }/>
                        {
                            !graphData.error
                                ? <div className={ 'ranger-place' }>
                                    <SensitivityInfo limit={ limit }/>
                                    {
                                        getInput('sensitivity')
                                    }
                                </div>
                                : null
                        }
                    </>
                    : isNotEmpty
                        ? <>
                            <div className={ 'button-place' }>
                                { getControls() }
                            </div>

                            <div className={ 'cloud-place' }>
                                <CoOccurrenceGraph
                                    isEmpty={ !isNotEmptyFiltered }
                                    width={ width }
                                    height={ height }
                                    graphData={ correlationData }
                                    onWordClick={ onWordClick }
                                    changeClass={ changeClass }
                                    tooltip='Correlation'
                                />
                                <div className={ 'ranger-place ranger-place--vertical' }>
                                    {
                                        getInput('correlation')
                                    }
                                </div>
                            </div>

                            <div className={ 'ranger-place' }>
                                <SensitivityInfo limit={ limit }/>
                                {
                                    getInput('sensitivity')
                                }
                            </div>
                        </>
                        : null
            }
        </div>
    );
};

QueryCorrelationGraph.defaultProps = {
    width: 900,
    height: 450,
    graphData: {
        nodes: [],
        links: [],
    },
};
