import { CALL_API, FEEDBACK } from 'store/middleware/api';

export const GET_TIME_FRAME_REGULAR_REQUEST = 'GET_TIME_FRAME_REGULAR_REQUEST';
export const GET_TIME_FRAME_REGULAR_SUCCESS = 'GET_TIME_FRAME_REGULAR_SUCCESS';
export const GET_TIME_FRAME_REGULAR_ERROR = 'GET_TIME_FRAME_REGULAR_ERROR';

export const CREATE_TIME_FRAME_REGULAR_REQUEST = 'CREATE_TIME_FRAME_REGULAR_REQUEST';
export const CREATE_TIME_FRAME_REGULAR_SUCCESS = 'CREATE_TIME_FRAME_REGULAR_SUCCESS';
export const CREATE_TIME_FRAME_REGULAR_ERROR = 'CREATE_TIME_FRAME_REGULAR_ERROR';

export const UPDATE_TIME_FRAME_REGULAR_REQUEST = 'UPDATE_TIME_FRAME_REGULAR_REQUEST';
export const UPDATE_TIME_FRAME_REGULAR_SUCCESS = 'UPDATE_TIME_FRAME_REGULAR_SUCCESS';
export const UPDATE_TIME_FRAME_REGULAR_ERROR = 'UPDATE_TIME_FRAME_REGULAR_ERROR';

export const GET_TIME_FRAME_SPECIAL_REQUEST = 'GET_TIME_FRAME_SPECIAL_REQUEST';
export const GET_TIME_FRAME_SPECIAL_SUCCESS = 'GET_TIME_FRAME_SPECIAL_SUCCESS';
export const GET_TIME_FRAME_SPECIAL_ERROR = 'GET_TIME_FRAME_SPECIAL_ERROR';

export const RE_FETCH_TIME_FRAME_SPECIAL_REQUEST = 'RE_FETCH_TIME_FRAME_SPECIAL_REQUEST';
export const RE_FETCH_TIME_FRAME_SPECIAL_SUCCESS = 'RE_FETCH_TIME_FRAME_SPECIAL_SUCCESS';
export const RE_FETCH_TIME_FRAME_SPECIAL_ERROR = 'RE_FETCH_TIME_FRAME_SPECIAL_ERROR';

export const GET_TIME_FRAME_SPECIAL_INFINITE_REQUEST = 'GET_TIME_FRAME_SPECIAL_INFINITE_REQUEST';
export const GET_TIME_FRAME_SPECIAL_INFINITE_SUCCESS = 'GET_TIME_FRAME_SPECIAL_INFINITE_SUCCESS';
export const GET_TIME_FRAME_SPECIAL_INFINITE_ERROR = 'GET_TIME_FRAME_SPECIAL_INFINITE_ERROR';

export const CREATE_TIME_FRAME_SPECIAL_REQUEST = 'CREATE_TIME_FRAME_SPECIAL_REQUEST';
export const CREATE_TIME_FRAME_SPECIAL_SUCCESS = 'CREATE_TIME_FRAME_SPECIAL_SUCCESS';
export const CREATE_TIME_FRAME_SPECIAL_ERROR = 'CREATE_TIME_FRAME_SPECIAL_ERROR';

export const UPDATE_TIME_FRAME_SPECIAL_REQUEST = 'UPDATE_TIME_FRAME_SPECIAL_REQUEST';
export const UPDATE_TIME_FRAME_SPECIAL_SUCCESS = 'UPDATE_TIME_FRAME_SPECIAL_SUCCESS';
export const UPDATE_TIME_FRAME_SPECIAL_ERROR = 'UPDATE_TIME_FRAME_SPECIAL_ERROR';

export const DELETE_TIME_FRAME_SPECIAL_REQUEST = 'DELETE_TIME_FRAME_SPECIAL_REQUEST';
export const DELETE_TIME_FRAME_SPECIAL_SUCCESS = 'DELETE_TIME_FRAME_SPECIAL_SUCCESS';
export const DELETE_TIME_FRAME_SPECIAL_ERROR = 'DELETE_TIME_FRAME_SPECIAL_ERROR';

export const TIME_FRAME_SPECIAL_FILTERS_CHANGED = 'TIME_FRAME_SPECIAL_FILTERS_CHANGED';

export const START_PROCESSING_TIME_FRAME = 'START_PROCESSING_TIME_FRAME';
export const END_PROCESSING_TIME_FRAME = 'END_PROCESSING_TIME_FRAME';

export const TIME_FRAME_CLEANUP = 'TIME_FRAME_CLEANUP';

function getInitialState() {
    return {
        loading: false,
        infiniteLoading: false,
        isUpdate: false,
        filters: {
            page: 1,
            limit: 10,
            show_past_events: '0',
        },
        results: [],
        count: 20,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case CREATE_TIME_FRAME_REGULAR_REQUEST:
        case RE_FETCH_TIME_FRAME_SPECIAL_REQUEST:
        case UPDATE_TIME_FRAME_REGULAR_REQUEST: {
            return {
                ...state,
                isUpdate: true,
            };
        }

        case GET_TIME_FRAME_REGULAR_REQUEST:
        case GET_TIME_FRAME_SPECIAL_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_TIME_FRAME_SPECIAL_INFINITE_REQUEST: {
            return {
                ...state,
                infiniteLoading: true,
            };
        }

        case UPDATE_TIME_FRAME_REGULAR_SUCCESS:
        case CREATE_TIME_FRAME_REGULAR_SUCCESS: {
            return {
                ...state,
                isUpdate: false,
                results: [
                    ...action.response,
                ],
            };
        }
        case RE_FETCH_TIME_FRAME_SPECIAL_SUCCESS: {
            return {
                ...state,
                isUpdate: false,
                ...action.response,
            };
        }

        case GET_TIME_FRAME_REGULAR_SUCCESS:
        case GET_TIME_FRAME_SPECIAL_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.response,
            };
        }

        case GET_TIME_FRAME_SPECIAL_INFINITE_SUCCESS: {
            return {
                ...state,
                infiniteLoading: false,
                results: [
                    ...state.results,
                    ...action.response.results,
                ],
            };
        }

        case RE_FETCH_TIME_FRAME_SPECIAL_ERROR:
        case CREATE_TIME_FRAME_REGULAR_ERROR:
        case UPDATE_TIME_FRAME_REGULAR_ERROR:
        case GET_TIME_FRAME_SPECIAL_INFINITE_ERROR:
        case GET_TIME_FRAME_REGULAR_ERROR:
        case GET_TIME_FRAME_SPECIAL_ERROR: {
            return {
                ...state,
                loading: false,
                infiniteLoading: false,
                isUpdate: false,
            };
        }

        case TIME_FRAME_SPECIAL_FILTERS_CHANGED: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case START_PROCESSING_TIME_FRAME:
        case END_PROCESSING_TIME_FRAME: {
            return {
                ...state,
                isUpdate: action.type === START_PROCESSING_TIME_FRAME,
            };
        }

        case TIME_FRAME_CLEANUP:
            return getInitialState();

        default:
            return state;
    }
}

// REGULAR
export function getTimeFrameRegular(unit_id, channel__name) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-regular/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_TIME_FRAME_REGULAR_REQUEST,
                GET_TIME_FRAME_REGULAR_SUCCESS,
                GET_TIME_FRAME_REGULAR_ERROR,
            ],
            apiType: FEEDBACK,
            unique: true,
            body: {
                unit_id,
                channel__name,
            },
        },
    };
}

export function createTimeFrameRegular(body) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-regular/multiple-create/`,
            method: 'POST',
            contentType: 'application/json',
            types: [
                CREATE_TIME_FRAME_REGULAR_REQUEST,
                CREATE_TIME_FRAME_REGULAR_SUCCESS,
                CREATE_TIME_FRAME_REGULAR_ERROR,
            ],
            apiType: FEEDBACK,
            unique: true,
            body,
        },
    };
}

export function updateTimeFrameRegular(body) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-regular/multiple-put/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [
                UPDATE_TIME_FRAME_REGULAR_REQUEST,
                UPDATE_TIME_FRAME_REGULAR_SUCCESS,
                UPDATE_TIME_FRAME_REGULAR_ERROR,
            ],
            apiType: FEEDBACK,
            unique: true,
            body,
        },
    };
}

// SPECIAL Initial
export function getTimeFrameSpecialInfinite(body) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-special/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_TIME_FRAME_SPECIAL_INFINITE_REQUEST,
                GET_TIME_FRAME_SPECIAL_INFINITE_SUCCESS,
                GET_TIME_FRAME_SPECIAL_INFINITE_ERROR,
            ],
            apiType: FEEDBACK,
            unique: true,
            body,
        },
    };
}

export function reFetchTimeFrameSpecial(body) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-special/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                RE_FETCH_TIME_FRAME_SPECIAL_REQUEST,
                RE_FETCH_TIME_FRAME_SPECIAL_SUCCESS,
                RE_FETCH_TIME_FRAME_SPECIAL_ERROR,
            ],
            apiType: FEEDBACK,
            unique: true,
            body,
        },
    };
}

export function getTimeFrameSpecialActions(body) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-special/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_TIME_FRAME_SPECIAL_REQUEST,
                GET_TIME_FRAME_SPECIAL_SUCCESS,
                GET_TIME_FRAME_SPECIAL_ERROR,
            ],
            apiType: FEEDBACK,
            unique: true,
            body,
        },
    };
}

export function getTimeFrameSpecial(filters) {
    return dispatch => {
        return filters.page === 1
            ? dispatch(getTimeFrameSpecialActions(filters))
            : dispatch(getTimeFrameSpecialInfinite(filters));
    };
}

export function manageTimeFrameSpecial(updateObj, deleteObj, createObj) {
    return dispatch => {
        dispatch(startProcessing());
        const requests = [];
        const promises = [];

        if (updateObj.length) {
            requests.push(dispatch(updateTimeFrameSpecial(updateObj)));
        }

        if (deleteObj.length) {
            requests.push(dispatch(deleteTimeFrameSpecial(deleteObj)));
        }

        if (createObj.length) {
            requests.push(dispatch(createTimeFrameSpecial(createObj)));
        }

        requests.forEach(el => {
            promises.push(el.promise);
        });

        return Promise.all(promises)
            .then(() => dispatch(endProcessing()), ({ status }) => {
                if (status !== 0) {
                    return dispatch(endProcessing());
                }
            });
    };
}

function startProcessing() {
    return {
        type: START_PROCESSING_TIME_FRAME,
    };
}

function endProcessing() {
    return {
        type: END_PROCESSING_TIME_FRAME,
    };
}

export function createTimeFrameSpecial(body) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-special/multiple-create/`,
            method: 'POST',
            contentType: 'application/json',
            types: [
                CREATE_TIME_FRAME_SPECIAL_REQUEST,
                CREATE_TIME_FRAME_SPECIAL_SUCCESS,
                CREATE_TIME_FRAME_SPECIAL_ERROR,
            ],
            apiType: FEEDBACK,
            logger: true,
            unique: true,
            loggerMessages: {
                success: '',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function updateTimeFrameSpecial(body) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-special/multiple-put/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [
                UPDATE_TIME_FRAME_SPECIAL_REQUEST,
                UPDATE_TIME_FRAME_SPECIAL_SUCCESS,
                UPDATE_TIME_FRAME_SPECIAL_ERROR,
            ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function deleteTimeFrameSpecial(body) {
    return {
        [CALL_API]: {
            endpoint: `/time-frame-special/multiple-delete/${ body.toLocaleString() }`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [
                DELETE_TIME_FRAME_SPECIAL_REQUEST,
                DELETE_TIME_FRAME_SPECIAL_SUCCESS,
                DELETE_TIME_FRAME_SPECIAL_ERROR,
            ],
            apiType: FEEDBACK,
        },
    };
}

export function updateFiltersActions(payload) {
    return {
        type: TIME_FRAME_SPECIAL_FILTERS_CHANGED,
        payload,
    };
}

export function cleanup() {
    return {
        type: TIME_FRAME_CLEANUP,
    };
}
