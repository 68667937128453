import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Alert, Button } from 'reactstrap';

import { SettingsService } from '/feedback/services';
import { CopyToClipboard, TooltipWrapper } from '/components';

import './PreviewField.scss';

export class PreviewField extends Component {
    componentDidMount() {
        const { channelValue } = this.props.input.value;

        SettingsService.injectDigiRunnerUrl(channelValue);
    }

    componentWillUnmount() {
        SettingsService.removeDigiRunnerScript();
    }

    @autobind
    onCopy(status) {
        const { showToast } = this.props;
        const model = status
            ? { text: 'Link was successfully copied', type: 'success' }
            : { text: 'Something went wrong!', type: 'error' };

        showToast(model);
    }

    render() {
        const { value, errorMessage } = this.props.input.value;

        return (
            <>
                <label className={ 'settings-field__input-label' }>Embed snippet</label>
                <Alert fade={ false } color={ 'info' }>
                    Note: The embed code below is tested in default scenarios when the script is called asynchronously after the page is loaded.
                    If you are integrating the script to your website's codebase and make calls to functions defined in this script, please make sure to perform checks of digi_runner.js API availability in blocking or critical calls from your code.
                </Alert>
                <div className={ 'embed-snippet__container' }>
                    <div className="embed-snippet__value">
                        { value ? value : errorMessage }
                    </div>
                    <div className="embed-snippet__acitons">
                        <TooltipWrapper value={ 'Copy to clipboard' } force>
                            <CopyToClipboard disabled={ !!errorMessage } text={ value } onCopy={ this.onCopy }>
                                <Button color={ 'secondary' }>Copy script</Button>
                            </CopyToClipboard>
                        </TooltipWrapper>
                    </div>
                </div>
            </>
        );
    }
}
