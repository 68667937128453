import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { VocModal, VocSelect } from '/components';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectComponentOption, MultiValueLabel } from '../../../../components';

const getInitialState = () => ({
    values: [],
    pendingRequest: false,
});

export class ExportResultsModal extends Component {
    state = getInitialState();

    @autobind
    submitHandler() {
        this.setState({
            pendingRequest: true,
        });

        this.props.submitHandler(this.state.values).finally(() => {
            this.setState({
                pendingRequest: false,
            });
        });
    }

    @autobind
    onModalEntered() {
        const { defaultExporter } = this.props.exporters;

        if (defaultExporter) {
            const defaultItem = {
                value: String(defaultExporter.id),
                label: defaultExporter.name,
                isNewExporter: defaultExporter.isNewExporter,
            };

            this.setState({
                values: [ ...this.state.values, defaultItem ],
            });
        }
    }

    @autobind
    cleanupModal() {
        this.setState(getInitialState());
    }

    @autobind
    onChangeSelect(values) {
        this.setState({
            values: values ? values : [],
        });
    }

    getModalFooter() {
        return (
            <Fragment>
                <Button onClick={ this.props.closeModal } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button onClick={ this.submitHandler }
                    disabled={
                        this.state.pendingRequest
                            || !this.state.values.length
                    }
                    color="primary">
                    <FontAwesomeIcon icon="check"/>
                    <span> Add</span>
                </Button>
            </Fragment>
        );
    }

    getModalBody() {
        return (
            <div>
                <VocSelect
                    isMulti={ true }
                    isHorizontal={ true }
                    label={ 'Exporter' }
                    closeMenuOnSelect={ false }
                    placeholder={ 'Select exporters' }
                    value={ this.state.values }
                    options={ this.props.exporters.results }
                    onChange={ this.onChangeSelect }
                    portal={ true }
                    components={{
                        Option: SelectComponentOption,
                        MultiValueLabel,
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <VocModal
                onOpened={ this.onModalEntered }
                isOpen={ this.props.show }
                onClosed={ this.cleanupModal }
                modalClassName='modal-scrollable'
                toggle={ this.props.closeModal }
                header={ 'Add Exporters' }
                footer={ this.getModalFooter() }>
                { this.getModalBody() }
            </VocModal>
        );
    }
}
