import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { Row, Col, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { aboutDataSelector, aboutSystemSelector, webUiInfoSelector } from '/modules/selectors';
import { HelperService } from '/services';
import { CopyToClipboard, TooltipWrapper, showToast } from '/components';
import version from '../../../../version.json';

import './About.scss';

const mapStateToProps = state => ({
    data: aboutDataSelector(state),
    system: aboutSystemSelector(state),
    webUiInfo: webUiInfoSelector(state),
});

const mapDispatchToProps = {
    showToast,
};

@connect(mapStateToProps, mapDispatchToProps)
export class About extends Component {
    @autobind
    getBody({ branch, buildDate, commitDate, commitHashShort }, name) {
        return (
            <>
                <div className='info'>
                    <p className="title text-center">
                        { name }
                    </p>
                    <ul className='info__list'>
                        <li className='info__item'>{ commitHashShort }</li>
                        <li className='info__item'>
                            <span className="info__item-text">
                                { HelperService.formatDate(commitDate, HelperService.defaultDateMask) }
                                <br/>
                                { HelperService.formatDate(commitDate, 'HH:mm') }
                            </span>
                        </li>
                        <li className='info__item'>
                            <span className="info__item-text">
                                { HelperService.formatDate(buildDate, HelperService.defaultDateMask) }
                                <br/>
                                { HelperService.formatDate(buildDate, 'HH:mm') }
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="bottom-titles">
                    <p className="title">
                        Hash
                    </p>
                    <p className="title title--right">
                        Build
                    </p>
                </div>
                <div className="clearfix"/>
                <hr/>
                <p className="title title--subtop text-center">
                    Version
                </p>
                <div className="branch-background"/>
                <p className="text-center">{ branch ? branch : '-' }</p>
                <hr/>
            </>
        );
    }

    getVersion() {
        fetch('/static/version.json')
            .then(response => response.json())
            .then(responseJson => {
                this.setState({ versions: responseJson });
            })
            .catch(error => {
                console.error(error);
            });
    }

    @autobind
    onCopy() {
        this.props.showToast({
            text: 'Token was successfully copied',
            type: 'success',
        });
    }

    render() {
        const {
            data,
            system: {
                JWT,
                firstName,
            },
            webUiInfo,
        } = this.props;
        const webUiData = webUiInfo?.branch ? webUiInfo : version;

        return (
            <DocumentTitle title="About - Hub">
                <div className={ 'about-page' }>
                    <p className="title title--top text-center font-weight-bold">
                        versions
                    </p>
                    <Container>
                        <Row>
                            <Col md={ 12 } xl={ 6 } lg={ 6 } sm={ 12 }>
                                {
                                    this.getBody(data, 'api')
                                }
                            </Col>
                            <Col md={ 12 } xl={ 6 } lg={ 6 } sm={ 12 }>
                                {
                                    this.getBody(webUiData, 'web-ui')
                                }
                            </Col>
                        </Row>
                    </Container>
                    <p className="text-center">{ `${ firstName } token:` }</p>

                    <div className="jwt-place">
                        <div className="jwt-box">
                            <p className="jwt-info">
                                { JWT }
                            </p>
                        </div>
                        <div className="jwt-tool-bar">
                            <TooltipWrapper value={ 'Copy to clipboard' } force>
                                <div className="jwt-copy-button">
                                    <CopyToClipboard text={ JWT } onCopy={ this.onCopy }>
                                        <FontAwesomeIcon icon="copy"/>
                                    </CopyToClipboard>
                                </div>
                            </TooltipWrapper>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}
