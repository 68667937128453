import React from 'react';

import { HelperService } from '/services';

import { RenderCondition } from './RenderCondition';
import './QuestionFlowConditionString.scss';

export const QuestionFlowConditionString = ({
    formValuesSelector,
    questionsList,
    optionForCommand,
    optionForChangeLanguage,
}) => {
    const {
        action_type,
        next_question,
        conditions,
        logical_operation,
        digi_runner_command,
        change_language_command,
    } = formValuesSelector;

    const getMatchingValue = matching_value => {
        let value = matching_value;

        if (matching_value && HelperService.checkNotNullOrUndefined(matching_value.label)) {
            value = matching_value.label;
        } else if (!matching_value) {
            value = '';
        }

        return value;
    };

    const conditionsToString = conditions?.map(({ question_id, expression, matching_value }) => ({
        question: `${ !question_id ? '' : question_id.label }`,
        expression: `${ !expression ? '' : expression.label }`,
        matching: `${ getMatchingValue(matching_value) }`,
    }));

    const answerList = questionsList.find(({ label }) => label === 'Answer');
    const nextQuestionModel = answerList.options.find(({ value }) => value === next_question);

    return (
        <div className={ 'flow__list-accent' }>
            <span className={ 'combined-condition__item text-uppercase' }>
                { `${ action_type.text ? action_type.text : '' } ` }
            </span>
            {
                action_type.text !== 'digi_runner_command' && next_question && nextQuestionModel && nextQuestionModel.label
            }
            {
                action_type.text === 'digi_runner_command' && optionForCommand?.find(
                    option => option.value === digi_runner_command?.toString(),
                )?.label
            }
            {
                action_type.text === 'change_language_command' && optionForChangeLanguage?.find(
                    option => option.value === change_language_command,
                )?.label
            }
            {
                conditions.length
                    ? <span className={ 'combined-condition__item text-uppercase' }> if </span>
                    : null
            }
            {
                conditionsToString.map((condition, index) => <RenderCondition
                    key={ index }
                    condition={ condition }
                    index={ index }
                    logicalOperation={ logical_operation }
                    conditionsLength={ conditionsToString.length }
                />)
            }
        </div>
    );
};
