import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Progress } from 'reactstrap';
import { TooltipWrapper } from '/components';
import './StatisticAnswerItem.scss';

export const StatisticAnswerItem = ({ title, value, responseRatio }) => (
    <Row className={ 'statistic__answer-item' }>
        <Col md={ 8 } className={ 'statistic__answer-title' }>
            <TooltipWrapper value={ title }>
                <span>{ title }</span>
            </TooltipWrapper>
        </Col>
        <Col md={ 4 } className={ 'statistic__answer-progress' }>
            <Row>
                <Col md={ 12 }> <Progress value={ responseRatio } className={ 'statistic__progress-item' }/> </Col>
                <Col md={ 8 } className={ 'text-left statistic-answer__responses' }> { value } responses </Col>
                <Col md={ 4 } className={ 'statistic-answer__ratio' }>{ responseRatio }%</Col>
            </Row>
        </Col>
    </Row>
);

StatisticAnswerItem.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    value: PropTypes.number,
    responseRatio: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};
