export const UPDATE_COUNTER = 'UPDATE_COUNTER';
export const STORE_COUNTERS = 'STORE_COUNTERS';
export const STORE_MINE_QUERIES = 'STORE_MINE_QUERIES';
export const UPDATE_MINE_QUERIES = 'UPDATE_MINE_QUERIES';
export const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION';

const getInitialState = () => ({
    counters: {
        storeCount: 0,
        mineCount: 0,
        classifyCount: 0,
        visualCount: 0,
        visualV2Count: 0,
        monitoringCount: 0,
        feedBackCount: 0,
    },
    mineQueries: {
        queries: [],
        moreCount: 0,
    },
    activeRoute: '/',
});

export function MenuReducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case STORE_COUNTERS: {
            return {
                ...state,
                counters: {
                    ...state.counters,
                    ...action.data,
                },
            };
        }

        case UPDATE_COUNTER: {
            return {
                ...state,
                counters: {
                    ...state.counters,
                    ...action.data,
                },
            };
        }

        case STORE_MINE_QUERIES: {
            return {
                ...state,
                mineQueries: {
                    ...action.data,
                },
            };
        }

        case UPDATE_MINE_QUERIES: {
            return {
                ...state,
                mineQueries: {
                    ...action.data,
                },
            };
        }

        case UPDATE_CURRENT_LOCATION: {
            return {
                ...state,
                activeRoute: action.data,
            };
        }

        default:
            return state;
    }
}


export function updateCounterItem(data) {
    return {
        type: UPDATE_COUNTER,
        data,
    };
}

export function storeCounters(data) {
    return {
        type: STORE_COUNTERS,
        data,
    };
}

export function storeMineQueries(data) {
    return {
        type: STORE_MINE_QUERIES,
        data,
    };
}

export function updateMineQueries(data) {
    return {
        type: UPDATE_MINE_QUERIES,
        data,
    };
}

export function updateCurrentLocation(url) {
    return {
        type: UPDATE_CURRENT_LOCATION,
        data: url,
    };
}
