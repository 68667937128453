import { createSelector } from 'reselect';
import {
    layoutsSelector,
    phoneForSenderSelector,
    themeListSelector,
    SMSGatewaysOptionsSelector,
    getSMSGatewaysChainWithBrokenItems,
} from '/feedback/modules/VocFeedback.selectors';
import { SettingsService } from '/feedback/services/SettingsService';
import { GatewaysSettingsService } from '/feedback/services';
import { channelSelector, unitSelector } from '../../../modules/globalSettings.selectors';

export const getAdvancedState = state => state.VocFeedback.globalSettings.advancedSettings;

export const fieldsMapSelector = createSelector([ channelSelector ], channel =>
    SettingsService.getFieldsByChannelAdvancedSettings(channel),
);

export const initialFormValuesSelector = createSelector(
    [
        getAdvancedState,
        layoutsSelector,
        phoneForSenderSelector,
        fieldsMapSelector,
        themeListSelector,
        SMSGatewaysOptionsSelector,
        unitSelector,
    ],
    ({ settings }, layouts, phoneForSender, fields, themeList, SMSGatewaysOptions, unit) => {
        const advancedSettings = SettingsService.composeGlobalSettings(settings);

        const SMSGatewaysOptionsWithBrokenItems = getSMSGatewaysChainWithBrokenItems(settings, advancedSettings, SMSGatewaysOptions);

        const layoutOptions = layouts.results.map(layout => ({
            value: layout.id,
            label: layout.name,
        }));

        const themeListOptions = themeList
            .filter(el => el.unit_id === null || el.unit_id === unit.value)
            .map(template => ({
                value: template.id,
                label: template.name,
            }));

        const layoutFieldKey = 'SURVEY_LAYOUT_ID';
        const themeListKey = 'digi_theme';
        const SMSGatewaysOptionsKey = 'GATEWAYS_CHAIN';

        const getOptions = (key, options) => {
            if (key === layoutFieldKey) return layoutOptions;
            if (key === themeListKey) return themeListOptions;
            if (key === SMSGatewaysOptionsKey) return SMSGatewaysOptionsWithBrokenItems;

            return options;
        };

        return {
            fieldsMap: fields.map(field => ({
                ...field,
                children: field.children.map(child => {
                    return {
                        ...child,
                        options: getOptions(child.key, child.options),
                        redefined: true,
                        isGlobal: true,
                    };
                }),
            })),
            initialValues: SMSGatewaysOptionsWithBrokenItems.length && advancedSettings['GATEWAYS_CHAIN']
                ? GatewaysSettingsService.addOptionsToInitial(advancedSettings, SMSGatewaysOptionsWithBrokenItems)
                : advancedSettings,
        };
    });

export const advancedFormSettingsSelector = createSelector([ getAdvancedState, initialFormValuesSelector ],
    ({ loading, sending }, fields) => {
        return {
            loading,
            sending,
            ...fields,
        };
    });
