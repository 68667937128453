import React from 'react';

import { HelperService } from '/services';

export const RenderQuarantineCondition = ({
    condition: {
        blocked_by,
        expression,
        matching_value,
        condition_type,
    },
    index,
    conditionsLength,
    logicalOperation,
}) => {
    const conditionLabel = label => {
        return (
            <>
                <span className='combined-condition__item'> { label } </span>
                <span>
                    { label === 'All surveys' ? '' : 'survey' } <span className='condition__red'>&nbsp;)</span>
                </span>
            </>
        );
    };

    const condition = () => {
        if (condition_type === 'survey_group') {
            const condition = `${ matching_value } day(s) ${ expression.conditionLabel }`;

            return (
                <div className='combined-condition__padding'>
                    <span>
                        <span className='condition__red'>(&nbsp;</span> { condition }
                    </span>
                    { conditionLabel(blocked_by.label) }
                </div>
            );
        }

        return (
            <div className='combined-condition__padding'>
                <span className='condition__red'>(&nbsp;</span>
                <span className='combined-condition__item'> { blocked_by.label ? blocked_by.label : '' }</span>
                { ` ${ expression ? expression.label : '' } ${ HelperService.checkNotNullOrUndefined(matching_value) ? matching_value : '' }` }
                <span className='condition__red'>&nbsp;)</span>
            </div>
        );
    };

    const operation = index > 0
        ? <span className='condition__operation condition__red'> { logicalOperation } </span>
        : '';

    return (
        conditionsLength > 1
            ? <>
                { operation }
                { condition() }
            </>
            : condition()
    );
};
