import { CacheService } from '/services/CacheService';
import { SET_CACHE } from '/modules';

// eslint-disable-next-line no-unused-vars
export default store => next => action => {
    //todo - make Cache Sercive work
    return next(action);

    // eslint-disable-next-line no-unreachable
    if (action.type === SET_CACHE) {
        CacheService.setCache(action.data);
    }
    return next(action);
};
