import React, { useEffect } from 'react';
import { Label } from 'reactstrap';
import { ComponentOption, ComponentValue, VocSelect, VocSortableSelect, TooltipWrapper, ThinSpinner } from '/components';
import { HelperService } from '/services';
import { useSettingsFilter } from '/visual/customHooks';

import { DataSelect } from './DataSelect';
import { Charts } from '../Charts';

import styles from './style.module.scss';

export const DataSettings = ({
    gadget,
    isDrillDownDashboardExist,
    drillDownExistDisabledReason,
    setTempSettings,
    setIsDataSettingsValid,
}: any) => {
    const {
        settings,
        isValid,
        activeSourceLoading,
        changeHandler,
    } = useSettingsFilter(gadget, setTempSettings);

    useEffect(() => {
        setIsDataSettingsValid(isValid);
    }, [ isValid ]);

    const getField = (setting: any) => {
        switch (setting.type) {
            case 'chart':
                return (
                    <Charts
                        key={ setting.id }
                        chartsData={ setting.options }
                        selected={ setting.selected }
                        onChange={ data => changeHandler(data, setting.id) }
                    />
                );

            case 'dataSelect':
                return (
                    <div key={ setting.id } className={ styles.fieldWrapper }>
                        <Label htmlFor={ 'selectColumn' }>
                            { setting.label }
                        </Label>
                        <TooltipWrapper
                            value={ drillDownExistDisabledReason }
                            force={ drillDownExistDisabledReason && isDrillDownDashboardExist }
                        >
                            <div>
                                <DataSelect
                                    value={ setting.selectedOption }
                                    disabled={ setting.disable || isDrillDownDashboardExist }
                                    onChange={ data => changeHandler(data, setting.id) }
                                />
                            </div>
                        </TooltipWrapper>
                    </div>
                );

            case 'select': {
                const components = {
                    Option: ComponentOption,
                    ...!(setting.drag && setting.isMulti)
                        ? { ValueContainer: ComponentValue }
                        : {},
                };

                const props = {
                    name: setting.id,
                    placeholder: setting.label,
                    options: setting.options,
                    value: HelperService.getSelectedValue(setting.options, setting.selected),
                    onChange: (data: any) => changeHandler(data, setting.id),
                    disabled: setting.disable || setting.options.length === 0,
                    isHorizontal: true,
                    isClearable: true,
                    portal: true,
                    closeMenuOnSelect: !setting.isMulti,
                    ...setting.tagging
                        ? { components }
                        : {},
                };

                return (
                    <div key={ setting.id } className={ styles.fieldWrapper }>
                        <Label htmlFor={ 'selectColumn' }>
                            { setting.label }
                        </Label>
                        {
                            setting.drag && setting.isMulti
                                ? <VocSortableSelect { ...props }/>
                                : <VocSelect { ...props } className={ 'select-with-label' }/>
                        }
                    </div>
                );
            }
        }
    };

    return (
        <div className={ styles.dataSettingsContainer }>
            { settings.map(setting => getField(setting)) }
            {
                activeSourceLoading
                && <ThinSpinner className={ styles.loader } />
            }
        </div>
    );
};
