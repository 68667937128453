import { HelperService, MetaQueryService } from '/services';

export class ApplianceService {
    static convertRecodeDataToServer({ segments, name, attributeValueKey, source, dataType }) {
        const segmentPlaceholders = [];

        const convertedSegments = segments.map(segment => {
            const { query, segmentName } = segment;
            const placeholder = source.attributes.find(({ name }) => name === segmentName);

            placeholder && segmentPlaceholders.push({ name: placeholder.name, type: placeholder.type });
            const metaQueryServer = MetaQueryService.clientToServerFormatter(
                { query, attributeValueKey, source },
            );

            return {
                query: metaQueryServer,
                ...(
                    placeholder
                        ? { placeholder: `[placeholder]${ segmentName }[placeholder]` }
                        : { segmentName }
                ),
            };
        });

        let placeholders = [];

        convertedSegments.forEach(({ query }) => {
            placeholders.push(...ApplianceService.getSegmentPlaceholders({ query, source }));
        });

        placeholders = HelperService.uniqeArray(placeholders.concat(segmentPlaceholders), 'name');

        return {
            segments: convertedSegments,
            dataType,
            placeholders,
            name,
        };
    }

    static getSegmentPlaceholders({ query, source }) {
        const placeholders = [];
        let queryString = JSON.stringify(query);

        //hard fix: need exclude value null if column name === null
        queryString = queryString.replaceAll(',"value":null','');

        source.attributes.forEach(attr => {
            const { name, type } = attr;

            if (queryString.includes(JSON.stringify(name).replace(/^"(.+(?="$))"$/, '$1'))) {
                placeholders.push({ name, type });
            }
        });

        return placeholders;
    }

    static getSourcePlaceholdersMapping({ placeholders, attributes }) {
        const placeholderNames = placeholders.map(x => x.name);

        return placeholderNames.reduce((acc, name) => {
            acc[`[placeholder]${ name }[placeholder]`] = attributes.find(attr => attr.name === name).index;
            return acc;
        }, {});
    }

    static validateSegmentsBeforeSend({ showToast, segments, dataType, attributes, t }) {
        if (!segments.length) {
            showToast({ text: t('createAtLeastOneOutputLabel'), type: 'error' });
            return false;
        }

        return segments.every(segment => {
            const valid = MetaQueryService.validateViewDataBeforeSend(segment.query, attributes)
                || dataType.value === 'NPS_SEGMENT';

            if (!valid) {
                showToast({
                    text: `${ t('fillAllFieldsInOutputLabel') } ${ HelperService.decodeHtmlFromString(segment.segmentName) }`,
                    type: 'error',
                });
            }

            return valid;
        });
    }

    static hasSourceQueryItemValue({ value, sourceAttrbite, operator }) {
        let hasValue = true;
        const attributes = sourceAttrbite.options.map(option => ({
            ...option,
            id: option.name === '' ? '' : option.id,
        }));
        const findOption = value => attributes.find(option => option.id === value);
        const notInternalOperator = ![ 'NEN', 'EQN' ].includes(operator);

        if (Array.isArray(value)) {
            value.forEach(valueItem => {
                if (valueItem !== null && notInternalOperator) {
                    hasValue = hasValue && findOption(valueItem) !== undefined;
                }
            });
        } else {
            if (value !== null && notInternalOperator) {
                hasValue = findOption(value) !== undefined;
            }
        }

        return hasValue;
    }

    static isQueryItemInSource({ query, source, dataType, segments }) {
        let valid = true;
        const { id, value, operator } = query;
        const sourceAttrbite = source.attributes.find(attr => attr.index === id);
        const hasSourceAttribute = sourceAttrbite !== undefined;
        const onlyVirtualColumn = hasSourceAttribute && [ 'LABEL', 'CLASSIFICATION' ].includes(sourceAttrbite.originType);

        if (query.hash !== 'root') {
            valid = valid && hasSourceAttribute;

            if (onlyVirtualColumn) {
                valid = (valid && ApplianceService.hasSourceQueryItemValue({
                    id,
                    value,
                    sourceAttrbite,
                    operator,
                }));
            }
        }

        if (dataType === 'NPS_SEGMENT') {
            return ApplianceService.checkNpsSegment(segments);
        }

        if (query.items) {
            query.items.forEach(subItem => {
                valid = valid && ApplianceService.isQueryItemInSource({ query: subItem, source });
            });
        }

        return valid;
    }

    static isValidAppliance({ source, segments, dataType }) {
        let valid = true;

        const segmentOptions = source.attributes.filter(({ type }) => type === dataType).map(({ name }) => name);

        segments.forEach(({ query, segmentName }) => {
            valid = valid
                && ApplianceService.isQueryItemInSource({ query, source, dataType, segments })
                && ApplianceService.checkDataTypes(segmentName, dataType, segmentOptions) && ApplianceService.queryValidation(segments, dataType);
        });

        return valid;
    }

    static checkNpsSegment(segments) {
        return segments.some(segment => segment.query.items[0]
            && Boolean(
                segment.query.items[0].id
                && ApplianceService.checkOperatorValue(
                    segment.query.items[0].operator,
                    segment.query.items[0].value,
                ),
            ));
    }

    static queryValidation(segments, dataType) {
        const valid = [];

        segments.forEach(segment => {
            if (segment.query.items) {
                valid.push(ApplianceService.queryItemsValidation(segment.query.items, dataType));
            }
        });

        return valid.every(item => item);
    }

    static hasEmptyItems(segment, source, dataType, segments) {
        return ApplianceService.isQueryItemInSource({ query: segment, source, dataType, segments })
            && ApplianceService.queryItemsValidation(segment.items);
    }

    static queryItemsValidation(items) {
        const valid = [];

        items.forEach(item => {
            valid.push(Boolean(item.id && ApplianceService.checkOperatorValue(item.operator, item.value)));

            if (item.items && item.items.length) {
                valid.push(ApplianceService.queryItemsValidation(item.items));
            }
        });

        return valid.every(item => item);
    }

    static checkOperatorValue(operator, value) {
        const emptyOperators = [ 'NEN', 'EQN' ];
        const emptyCondition = emptyOperators.includes(operator);

        const notEmptyCondition = (operator && operator !== 'NEN' && operator !== 'EQN') && value !== null;

        return emptyCondition || notEmptyCondition;
    }

    static checkDataTypes(name, type, segmentOptions) {
        if (segmentOptions.includes(name)) return true;
        return (type === 'META' || type === 'NPS_SEGMENT')
            ? true
            : (!isNaN(name) && String(name).length <= 9);
    }

    static formatAttributesToSelectOptions(attributes, filterOption) {
        let selectOptions = attributes
            .filter(({ type }) => filterOption === 'META'
                ? [ 'META', 'CHOICE', 'CLASSIFICATION' ].includes(type)
                : type === filterOption)
            .map(({ name }) => ({ label: name, value: name }));

        if (filterOption === 'NPS_SEGMENT') {
            const npsSegmentOptions = [
                { label: 'Promoters', value: 'Promoters' },
                { label: 'Passives', value: 'Passives' },
                { label: 'Detractors', value: 'Detractors' },
            ];

            selectOptions = selectOptions.concat(npsSegmentOptions);
        }

        return selectOptions;
    }

    static getFilteredSegmentOptions(options, segments) {
        const segmentsNames = segments.map(({ segmentName }) => segmentName);

        return options.filter(({ label }) => !segmentsNames.includes(label));
    }

    static formatToSelectValue(value) {
        return value !== null
            ? ({ value, label: value })
            : null;
    }
}
