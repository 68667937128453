import { HelperService } from '/services';

const regexEmptiness = /^ *$/;

export const AddToBlackListModalFormValidation = (values = {}) => {
    const { customerId, email, phone } = values;
    const errors = {};

    if (!customerId || regexEmptiness.test(customerId)) {
        errors.customerId = 'Field must not be empty';
    }

    if (email && !HelperService.validateEmail(email)) {
        errors.email = 'Wrong email format';
    }

    if (phone && !HelperService.validatePhone(phone)) {
        errors.phone = 'Wrong phone format';
    }

    return errors;
};
