import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import { PageRequests } from '/services';
import { VocCleaupHOC } from '/components';
import { SourceModalInfo, SourceDetailsModal } from '../../components';
import { SocialSourceModal, UploadsTable, EditTitleModal } from './components';
import { userRightsSelector } from '/modules/selectors';
import { showExportFilterModal, showTruncateUploadModal } from '/scenes/VocStore/modules/VocStore.modules';
import { showApplianceModal } from '/modules/segmentatorModules/segmentator.modules.js';
import { updateCurrentLocation } from '/scenes/Header/scenes/VocMenu/modules';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import {
    getUploads,
    updateFilters,
    cancelUpload,
    cleanup,
    getUploadRelations,
    deleteUpload,
    reLemmatization,
    uncoverUpload,
    downloadErrors,
    getUploadInfo,
    cleanupUploadInfo,
    updateUploadDescription,
    updateUploadTitle,
} from './modules';
import {
    filtersSelector,
    loadingSelector,
    uploadsCountSelector,
    uploadToDeleteSelector,
    uploadListTableDataSelector,
    sourcesInfoSelector,
} from './modules/selectors';
import { afterReloadSelector } from "../UploadData/scenes/UploadNewFile/modules/selectors";

import './UploadsList.scss';

const mapStateToProps = state => ({
    tableData: uploadListTableDataSelector(state),
    uploadsCount: uploadsCountSelector(state),
    uploadToDelete: uploadToDeleteSelector(state),
    afterReloadState: afterReloadSelector(state),
    loading: loadingSelector(state),
    filters: filtersSelector(state),
    userRights: userRightsSelector(state),
    sourcesInfo: sourcesInfoSelector(state),
});

const mapDispatchToProps = {
    showConfirm,
    deleteUpload,
    showExportFilterModal,
    getUploadRelations,
    uncoverUpload,
    cancelUpload,
    updateFilters,
    getUploads,
    downloadErrors,
    showApplianceModal,
    reLemmatization,
    updateCurrentLocation,
    getUploadInfo,
    cleanupUploadInfo,
    showTruncateUploadModal,
    updateUploadDescription,
    updateUploadTitle,
    cleanup,
};

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class UploadsList extends Component {
    state = {
        showSocialSourceModal: false,
        showModalOpened: false,
        socialUploadId: null,
        showSourceDetailsModal: false,
        showModalRename: false,
        sourceDetailsData: null,
    };

    pageRequests = new PageRequests();

    componentDidMount() {
        this.requestTrigger();
    }

    @autobind
    toggleSourceDetailsModal(sourceDetailsData) {
        this.setState(prevState => ({
            showSourceDetailsModal: !prevState.showSourceDetailsModal,
            sourceDetailsData: sourceDetailsData
                ? sourceDetailsData
                : prevState.sourceDetailsData,
        }));
    }

    @autobind
    toggleSocialSourceModal(socialUploadId = null) {
        this.setState(prevState => ({
            showSocialSourceModal: !prevState.showSocialSourceModal,
            socialUploadId,
        }));
    }

    @autobind
    closeModal() {
        this.setState({
            showModalOpened: false,
            showModalRename: false,
        });
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        this.pageRequests.cleanup();
        const request = this.props.getUploads(filters);

        this.pageRequests.addRequest(request);
    }

    @autobind
    addNewUpload() {
        this.pageRequests.cleanup();
        this.props.history.push('/store/upload-data');
    }

    @autobind
    getPropsForTable() {
        const {
            uploadsCount, loading, updateFilters, tableData,
            showConfirm, getUploadRelations, uploadToDelete, uncoverUpload,
            cancelUpload, deleteUpload, downloadErrors, showApplianceModal,
            updateCurrentLocation, showExportFilterModal, reLemmatization,
            filters, userRights,
        } = this.props;

        return {
            uploadsCount, loading, updateFilters, tableData,
            showConfirm, getUploadRelations, uploadToDelete, uncoverUpload,
            cancelUpload, deleteUpload, downloadErrors, showApplianceModal,
            updateCurrentLocation, showExportFilterModal, reLemmatization,
            filters, userRights,
        };
    }

    @autobind
    onInfoButtonClick(upload) {
        this.props.getUploadInfo(upload.id);

        this.setState({
            showModalOpened: true,
            upload,
        });
    }
    
    @autobind
    onRenameButtonClick(uploadId) {
        const upload = this.props.tableData.find(({ id }) => id === uploadId);
        
        this.setState({
            showModalRename: true,
            upload,
        });
    }

    render() {
        const {
            showSocialSourceModal, socialUploadId,
            showModalOpened, showModalRename,
            upload, showSourceDetailsModal,
            sourceDetailsData
        } = this.state;
        const {
            sourcesInfo, updateUploadDescription,
            updateUploadTitle, cleanupUploadInfo,
            showTruncateUploadModal, t
        } = this.props;
        const title = t('uploadList');

        return (
            <>
                <DocumentTitle title={ title }>
                    <Card className={ 'page__wrapper' }>
                        <CardHeader className={ 'panel__header panel__header--buttons card-header' }>
                            <span className={ 'panel__header-title' }>
                                <FontAwesomeIcon icon='list-ul' />
                                &nbsp;
                                { title }
                            </span>
                            <div className={ 'panel__header-buttons' }>
                                <Button outline
                                    className={ 'panel__header-button' }
                                    color={ 'white' }
                                    onClick={ this.addNewUpload }
                                    type='button'
                                >
                                    <FontAwesomeIcon icon='plus' className='color-green' />
                                    &nbsp;
                                    <span>{ t('addUpload') }</span>
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <UploadsTable
                                requestTrigger={ this.requestTrigger }
                                toggleSocialSourceModal={ this.toggleSocialSourceModal }
                                toggleTruncateUploadModal={ showTruncateUploadModal }
                                pageRequests={ this.pageRequests }
                                onInfoButtonClick={ this.onInfoButtonClick }
                                openSourceDetailsModal={ this.toggleSourceDetailsModal }
                                onRenameButtonClick={ this.onRenameButtonClick }
                                { ...this.getPropsForTable() }
                            />
                        </CardBody>
                    </Card>
                </DocumentTitle>

                <SocialSourceModal
                    socialUploadId={ socialUploadId }
                    show={ showSocialSourceModal }
                    toggle={ this.toggleSocialSourceModal }
                />

                <SourceDetailsModal
                    sourceDetailsData={ sourceDetailsData }
                    show={ showSourceDetailsModal }
                    toggle={ this.toggleSourceDetailsModal }
                />

                <SourceModalInfo
                    show={ showModalOpened }
                    sourcesInfo={ sourcesInfo }
                    updateDescription={ updateUploadDescription }
                    cleanupInfo={ cleanupUploadInfo }
                    close={ this.closeModal }
                    header={ t('uploadsInfo') }
                    source={ upload }
                />
                
                <EditTitleModal
                    show={ showModalRename }
                    updateUploadTitle={ updateUploadTitle }
                    close={ this.closeModal }
                    header={ t('uploadsInfo') }
                    source={ upload }
                />
            </>
        );
    }
}

UploadsList.propTypes = {
    tableData: PropTypes.array,
    filters: PropTypes.object,
    loading: PropTypes.bool,
};
