import { CALL_API, FEEDBACK } from 'store/middleware/api';

const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';

const DELETE_QUESTIONNAIRE_REQUEST = 'DELETE_QUESTIONNAIRE_REQUEST';
const DELETE_QUESTIONNAIRE_SUCCESS = 'DELETE_QUESTIONNAIRE_SUCCESS';
const DELETE_QUESTIONNAIRE_ERROR = 'DELETE_QUESTIONNAIRE_ERROR';

const DELETE_QUESTIONNAIRE_LIST_REQUEST = 'DELETE_QUESTIONNAIRE_LIST_REQUEST';
const DELETE_QUESTIONNAIRE_LIST_SUCCESS = 'DELETE_QUESTIONNAIRE_LIST_SUCCESS';
const DELETE_QUESTIONNAIRE_LIST_ERROR = 'DELETE_QUESTIONNAIRE_LIST_ERROR';

const INVITATION_CUSTOMERS_REQUEST = 'INVITATION_CUSTOMERS_REQUEST';
const INVITATION_CUSTOMERS_SUCCESS = 'INVITATION_CUSTOMERS_SUCCESS';
const INVITATION_CUSTOMERS_ERROR = 'INVITATION_CUSTOMERS_ERROR';

const SEND_QUESTIONNAIRE_REMINDER_REQUEST = 'SEND_QUESTIONNAIRE_REMINDER_REQUEST';
const SEND_QUESTIONNAIRE_REMINDER_SUCCESS = 'SEND_QUESTIONNAIRE_REMINDER_SUCCESS';
const SEND_QUESTIONNAIRE_REMINDER_ERROR = 'SEND_QUESTIONNAIRE_REMINDER_ERROR';

const ADD_CUSTOMER_TO_SELECTED = 'ADD_CUSTOMER_TO_SELECTED';
const REMOVE_CUSTOMER_FROM_SELECTED = 'REMOVE_CUSTOMER_FROM_SELECTED';
const ADD_CUSTOMER_TO_SELECTED_MULTI = 'ADD_CUSTOMER_TO_SELECTED_MULTI';
const REMOVE_CUSTOMER_FROM_SELECTED_MULTI = 'REMOVE_CUSTOMER_FROM_SELECTED_MULTI';
const CLEAR_CUSTOMERS_SELECTION = 'CLEAR_CUSTOMERS_SELECTION';

const CUSTOMERS_FILTERS_CHANGED = 'CUSTOMERS_FILTERS_CHANGED';

const CLEANUP = 'feedback/survey/customers/cleanup';

export const getInitialState = () => ({
    loading: false,
    filters: {
        page: 1,
        limit: 10,
        survey__survey_group_id: null,
        survey__channel__name: null,
        sort_order: '',
        search: '',
        state: '',
        substate: '',
    },
    selected: [],
    results: [],
    count: 0,
});

export const reducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case GET_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                results: [ ...action.response.results ],
                count: action.response.count,
            };

        case GET_CUSTOMERS_ERROR:
            return {
                ...getInitialState(),
                loading: false,
            };

        case CUSTOMERS_FILTERS_CHANGED:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };

        case ADD_CUSTOMER_TO_SELECTED: {
            return {
                ...state,
                selected: [ ...state.selected, action.data ],
            };
        }

        case ADD_CUSTOMER_TO_SELECTED_MULTI: {
            return {
                ...state,
                selected: [ ...state.selected, ...action.data.filter(data => !state.selected.includes(data)) ],
            };
        }

        case REMOVE_CUSTOMER_FROM_SELECTED_MULTI: {
            return {
                ...state,
                selected: state.selected.filter(select => !action.data.includes(select)),
            };
        }

        case REMOVE_CUSTOMER_FROM_SELECTED: {
            return {
                ...state,
                selected: state.selected.filter(item => item !== action.data),
            };
        }

        case CLEAR_CUSTOMERS_SELECTION: {
            return {
                ...state,
                selected: [],
            };
        }

        case CLEANUP:
            return getInitialState();

        default:
            return state;
    }
};

export function addToSelected(item) {
    return {
        type: ADD_CUSTOMER_TO_SELECTED,
        data: item,
    };
}

export function removeFromSelected(id) {
    return {
        type: REMOVE_CUSTOMER_FROM_SELECTED,
        data: id,
    };
}

export function multiAddToSelected(items) {
    return {
        type: ADD_CUSTOMER_TO_SELECTED_MULTI,
        data: items,
    };
}

export function multiRemoveFromSelected(items) {
    return {
        type: REMOVE_CUSTOMER_FROM_SELECTED_MULTI,
        data: items,
    };
}

export function clearSelection() {
    return {
        type: CLEAR_CUSTOMERS_SELECTION,
    };
}

export function cleanup() {
    return {
        type: CLEANUP,
    };
}

export function manageSelectAll({ checked, data }) {
    return dispatch => {
        return checked
            ? dispatch(multiAddToSelected(data))
            : dispatch(multiRemoveFromSelected(data));
    };
}

export function getCustomers(body) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire/expand-full/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CUSTOMERS_REQUEST, GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_ERROR ],
            body: {
                ...body,
                // filter delete in progress state
                state_exclude: 3,
            },
            apiType: FEEDBACK,
        },
    };
}

export function deleteCustomers(hash) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire/${hash}/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_QUESTIONNAIRE_REQUEST, DELETE_QUESTIONNAIRE_SUCCESS, DELETE_QUESTIONNAIRE_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Questionnaire was deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function sendReminder(id, state) {
    return {
        [CALL_API]: {
            endpoint: `/send_manual_reminder/${id}/`,
            method: 'POST',
            contentType: 'application/json',
            types: [
                SEND_QUESTIONNAIRE_REMINDER_REQUEST,
                SEND_QUESTIONNAIRE_REMINDER_SUCCESS,
                SEND_QUESTIONNAIRE_REMINDER_ERROR,
            ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: `Reminder for the Questionnaire '${id}' has been successfully accepted for processing.`,
                error: `The Questionnaire '${id}' is still in '${state}' state. It should be 'Invited', 'Started' or 'Not answered' to send reminder.`,
            },
        },
    };
}

export function deleteQuestionnairesList(list) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire/delete-multiple/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ DELETE_QUESTIONNAIRE_LIST_REQUEST, DELETE_QUESTIONNAIRE_LIST_SUCCESS, DELETE_QUESTIONNAIRE_LIST_ERROR ],
            apiType: FEEDBACK,
            body: {
                questionnaire_ids: list,
            },
            logger: true,
            loggerMessages: {
                success: 'Selected questionnaires were deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function invitationCustomers(hash) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire/${hash}/invite/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ INVITATION_CUSTOMERS_REQUEST, INVITATION_CUSTOMERS_SUCCESS, INVITATION_CUSTOMERS_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Invitation was successfully sent',
                error: 'Something went wrong',
            },
        },
    };
}

export function updateFilters(payload) {
    return {
        type: CUSTOMERS_FILTERS_CHANGED,
        payload,
    };
}
