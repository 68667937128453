import React from 'react';
import { Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SwitchControl, VocSelect } from '/components';
import { ChartSpecificSwitchersSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { IContentSettings, IDataSettings, IGadgetData, setTempSettingsType } from '/visual/models';
import { HelperService } from '/services';

import styles from './style.module.scss';

export const ChartSpecificSettings = ({
    gadget,
    isDrillDownDashboardExist,
    drillDownExistDisabledReason,
    setTempSettings,
}: {
    gadget: IGadgetData,
    isDrillDownDashboardExist: boolean,
    drillDownExistDisabledReason: string,
    setTempSettings: setTempSettingsType,
}) => {
    const { t } = useTranslation();
    const { contentSettings, dataSettings } = gadget;
    const switchers = useSelector(ChartSpecificSwitchersSelector(contentSettings, t));

    const onChangeContentSettings = (data: any) => {
        setTempSettings({
            contentSettings: { ...data },
        });
    };

    const onChangeDataSettings = (data: any) => {
        setTempSettings({
            dataSettings: { ...data },
        });
    };

    const getSwitcher = (switcher: {title: string, type: string}) => {
        const isDisabled = switcher.type === 'drillDown' && isDrillDownDashboardExist;
        const switcherValue = Boolean(contentSettings[switcher.type as keyof IContentSettings]);

        return (
            <Label className={ styles.switchWrapper }>
                <span>{ switcher.title }</span>
                <SwitchControl
                    disabled={ isDisabled }
                    disabledReason={ drillDownExistDisabledReason }
                    name={ `select${switcher.type}` }
                    value={ switcherValue }
                    changeHandler={
                        (switcherState: boolean) => onChangeContentSettings({ [switcher.type]: switcherState })
                    }
                />
            </Label>
        );
    };

    const getSelect = (selectData: {title: string, type: string, options: [], render: string}) => {
        const selectValue = HelperService.getSelectedValue(
            selectData.options,
            selectData.type === 'altChoices'
                ? dataSettings[selectData.type as keyof IDataSettings]
                : contentSettings[selectData.type as keyof IContentSettings],
        );

        const selectChangeHandler = (e: any) => selectData.type === 'altChoices'
            ? onChangeDataSettings({ [selectData.type]: e.value })
            : onChangeContentSettings({ [selectData.type]: e.value });

        return (
            <div className={ styles.selectWrapper }>
                <Label htmlFor={ 'selectColumn' }>
                    { selectData.title }
                </Label>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*@ts-ignore*/}
                <VocSelect
                    portal
                    options={ selectData.options }
                    className={ 'select-with-label' }
                    value={ selectValue }
                    onChange={ selectChangeHandler }
                />
            </div>
        );
    };

    return (
        <>
            {
                switchers?.length
                    ? switchers.map((switcher, inx) => (
                        <div key={ `${switcher.type}_${inx}` }>
                            {
                                switcher.render === 'select'
                                    ? getSelect(switcher)
                                    : getSwitcher(switcher)
                            }
                        </div>
                    ))
                    : <p className={ styles.emptyLabel }>
                        { t('noCustomSettings') }
                    </p>
            }
        </>
    );
};
