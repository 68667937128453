import { sockets } from '/services';
import { markGadgetsForUpgrade } from './Dashboard.modules';
import { TMarkGadgetsForUpgradeDispatch, TGetVisualStore } from '/visual/scenes/Dashboard/modules/models';
import { EUpdateGadgetTypes } from '/visual/scenes/Dashboard/components/Gadget/models';

interface TData {
    dashboardId: string
    gadgetIds: string[]
}

function socketCallback(data: TData[], store: { getState: TGetVisualStore, dispatch: TMarkGadgetsForUpgradeDispatch }) {
    const stateVisual = store.getState().VocVisual;
    const dashboardId = stateVisual.dashboard.dashboard?.id;
    const activeDashboard = dashboardId
        ? data.find(dashboard => dashboard.dashboardId === dashboardId)
        : null;

    if(activeDashboard) {
        const { gadgetsChartsData } = stateVisual.dashboard;
        const gadgetsId = activeDashboard.gadgetIds.filter(id => gadgetsChartsData[id].loaded);

        if(gadgetsId.length) {
            return store.dispatch(markGadgetsForUpgrade({
                gadgetsId,
                updateType: EUpdateGadgetTypes.SOCKET_UPDATE,
            }));
        }
    }
}

export function socketGadgetUpdateListener() {
    return sockets.on('dashboardV2_gadget_updated', socketCallback);
}
