import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { HelperService } from '/services';
import { Navigation } from '/components';
import {
    ExportersList,
    ExportQueue,
    ExportResults,
    ExportSchedules,
} from './scenes';
import { connect } from 'react-redux';
import { combineReducers } from 'redux';
import { reducer as exportList } from './scenes/ExportersList/modules/exportersList.index';
import { reducer as exportResults } from './scenes/ExportResults/modules/exportResults.index';
import { reducer as exportSchedules } from './scenes/ExportSchedules/modules/exportSchedules.index';
import { reducer as exportQueue } from './scenes/ExportQueue/modules/exportQueue.index';
import { reducer as exportCommon, getExporters } from './modules/export.index';

import './Export.scss';

@connect(null, {
    getExporters,
})
export class Export extends Component {
    settingsChildRoute = [
        {
            title: 'Export Results',
            link: '/feedback/export/results',
            isReactLink: true,
        },
        {
            title: 'Export Queue',
            link: '/feedback/export/exporters-queue',
            isReactLink: true,
        },
        {
            title: 'Exporters List',
            link: '/feedback/export/exporters-list',
            isReactLink: true,
        },
        {
            title: 'Export Schedule',
            link: '/feedback/export/export-schedule',
            isReactLink: true,
        },
    ];

    componentDidMount() {
        this.props.getExporters();
    }

    render() {
        const { match } = this.props;

        return (
            <div className={ 'page__wrapper' }>
                <Navigation
                    navigationInfo={ this.settingsChildRoute }
                    className="export-navigation"
                />
                <Switch>
                    <Route path={ `${match.url}/results` } component={ ExportResults }/>
                    <Route path={ `${match.url}/exporters-queue` } component={ ExportQueue }/>
                    <Route path={ `${match.url}/exporters-list` } component={ ExportersList }/>
                    <Route path={ `${match.url}/export-schedule` } component={ ExportSchedules }/>
                    <Redirect to={ HelperService.joinRoute(match.url, '/results') }/>
                </Switch>
            </div>
        );
    }
}

export const ExportReducer = combineReducers({
    exporters: exportList,
    results: exportResults,
    queue: exportQueue,
    schedules: exportSchedules,
    common: exportCommon,
});
