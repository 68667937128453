import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getDashboard, setScrollTo } from '/visual/scenes/Dashboard/modules/Dashboard.modules';

export const useVisualNavigation = () => {
    const history = useHistory();
    const { dashboardId: currentDashboardId }: { dashboardId: string } = useParams();
    const dispatch = useDispatch();
    const rootPath = '/visualV2';

    const goToDashboard = (id: string, gadgetId?: string | null, skipRoutingConfirmation?: boolean) => {
        if (!id) return;

        if (currentDashboardId === id) {
            return dispatch(getDashboard(id));
        }

        gadgetId && dispatch(setScrollTo({ gadgetId: gadgetId as string }));

        history.push({
            pathname: `${rootPath}/dashboard/${id}`,
            state: { skipConfirm: skipRoutingConfirmation },
        });
    };

    const goToDashboardList = () => {
        history.push({
            pathname: `${rootPath}/dashboard-list/`,
        });
    };

    return { goToDashboard, goToDashboardList };
};
