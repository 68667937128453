import React from 'react';
import PropTypes from 'prop-types';
import { TooltipWrapper } from '/components';

import './VocTableTooltipFormatter.scss';

export const VocTableTooltipFormatter = ({ field = '' }) => {
    return (
        <TooltipWrapper value={ field }>
            <div className="cell-data-wrapper">{ field }</div>
        </TooltipWrapper>
    );
};

VocTableTooltipFormatter.propTypes = {
    field: PropTypes.any,
};
