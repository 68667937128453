import React from 'react';
import cx from 'classnames';

import { ITabsProps, ITab } from '/visual/models';

import styles from './style.module.scss';

export const Tabs = ({
    tabs,
    tabSelectorClassname,
    onChange,
    activeTab,
}: ITabsProps) => {
    const activeTabData = tabs.find(({ id }: ITab) => id === activeTab);

    return (
        <div className={ styles.tabsWrapper }>
            <div className={ cx(styles.tabsSelectorWrapper, tabSelectorClassname) }>
                {
                    tabs.map(({ title, id, disabled }: ITab) => (
                        <button
                            key={ id }
                            className={ cx(styles.tabButton, { [styles.tabButtonActive]: id === activeTab }) }
                            disabled={ disabled }
                            onClick={ () => onChange?.(id) }
                        >
                            { title }
                        </button>
                    ))
                }
            </div>
            { activeTabData?.component }
        </div>
    );
};
