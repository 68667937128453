import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import cx from 'classnames';

import { NotificationWidget } from '../NotificationWidget';
import { VocUserDropdown } from '../../components/VocUserDropdown';
import { ZendeskSnippet } from '/services';
import { SettingsService } from '/feedback/services';

import HeadSet from '../../images/voc_act_active.svg';
import InfoIcon from '../../images/info-icon.svg';

import './HeaderSection.scss';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class HeaderSection extends Component {
    @autobind
    showReportRunner(){
        SettingsService.showDigiPopup({
            language: 'en',
            id: 145,
            customerId: this.props.email,
            customApiPath: 'https://cx-survey.sandsiv.com',
        });
    }

    render() {
        const {
            ticketSystemUseIframe,
            ticketSystemUrl,
            hasActAccess,
            firstName,
            logoutUrl,
            activeRoute,
            runnerUrl,
        } = this.props;

        return (
            <div className='header__right-section'>
                {
                    hasActAccess
                    && !ticketSystemUseIframe
                    && ticketSystemUrl
                    && <a
                        href={ ticketSystemUrl }
                        className='headset__link'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img className='headset__icon' src={ HeadSet } />
                    </a>
                }

                <Button
                    className={ cx( 'report__icon', { 'disabled': !runnerUrl } ) }
                    onClick={ this.showReportRunner }
                    color={ 'white' }
                >
                    <FontAwesomeIcon icon={ 'flag' }/>
                </Button>

                <img
                    alt=''
                    src={ InfoIcon }
                    className='help__icon'
                    onClick={ ZendeskSnippet.showSnippet }
                />

                <NotificationWidget />

                <VocUserDropdown
                    firstName={ firstName }
                    logout={ logoutUrl }
                    activeRoute={ activeRoute }
                />
            </div>
        );
    }
}
