import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col } from 'reactstrap';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';
import { SurveyItem } from '../SurveyItem/';

export class TouchPoint extends Component {
    state = {
        expanded: this.props.expanded,
    };

    @autobind
    openAccordion() {
        const { id } = this.props;
        const { expanded } = this.state;

        if (!expanded) {
            this.props.openHandler(id);
            this.props.enterHandler(id);
        } else {
            this.props.closeHandler(id);
            this.props.exitHandler(id);
        }

        this.setState({
            expanded: !expanded,
        });
    }

    openLinkAction(isOpenLinkEnabled, openLinkUrl) {
        return (isOpenLinkEnabled && openLinkUrl && openLinkUrl !== '')
            ? window.location.href = openLinkUrl
            : null;
    }

    @autobind
    onRemoveTouchPointClicked() {
        const { showConfirm, isPermitRemoveTouchPoint } = this.props;

        isPermitRemoveTouchPoint && showConfirm({
            header: 'Warning!',
            content: 'Are you sure to delete this Touchpoint?',
            successCallback: () => this.props.removeTouchPointById(this.props.id),
        });
    }

    renderPanelHeader() {
        const {
            filteredUnitByPermit,
            isPermitRemoveTouchPoint,
            channels: {
                active,
                paused,
                deleted,
                stopped,
            },
        } = this.props;

        const conditionTooltip = {
            width: 400,
        };

        const openLinkUrl = this.props.url || null;
        const isOpenLinkEnabled = openLinkUrl && openLinkUrl !== '';
        const openLinkClassname = `btn btn-default touchpoint-actions-link ${!isOpenLinkEnabled ? 'disabled' : ''}`;

        return (
            <Container className="panel-heading">
                <Row className='clearfix'>
                    <Col md='5'>
                        <h4 className='pointer noselect' onClick={ this.openAccordion }>
                            <span className={ 'toggle-panel-icon' }>
                                {
                                    this.state.expanded
                                        ? <FontAwesomeIcon icon='caret-down'/>
                                        : <FontAwesomeIcon icon='caret-right'/>
                                }
                            </span>
                            <TooltipWrapper value={ this.props.title } condition={ conditionTooltip }>
                                <span className={ 'toggle-panel-title' }> { this.props.title }</span>
                            </TooltipWrapper>
                        </h4>
                    </Col>
                    <Col md='4'>
                        <div className={ 'status-counter-wrapper noselect' }>
                            <TooltipWrapper value='Active' placement='top' force>
                                <span className='status-counter status-counter-active'>{ active }</span>
                            </TooltipWrapper>
                            <TooltipWrapper value='Paused' placement='top' force>
                                <span className='status-counter status-counter-paused'>{ paused }</span>
                            </TooltipWrapper>
                            <TooltipWrapper value='Deleted' placement='top' force>
                                <span className='status-counter status-counter-deleted'>{ deleted }</span>
                            </TooltipWrapper>
                            <TooltipWrapper value='Stopped' placement='top' force>
                                <span className='status-counter status-counter-stopped'>{ stopped }</span>
                            </TooltipWrapper>
                        </div>
                    </Col>
                    <Col md='3'>
                        {
                            this.props.id > 1
                            && <div className={ 'touchpoint-actions clearfix' }>
                                <TooltipWrapper value='Delete' placement='top' force>
                                    <Button
                                        className={ 'touchpoint-actions-setting' }
                                        disabled={ !isPermitRemoveTouchPoint }
                                        outline color={ 'white' }
                                        onClick={ this.onRemoveTouchPointClicked }
                                    >
                                        <FontAwesomeIcon icon={ 'trash' }/>
                                    </Button>
                                </TooltipWrapper>
                                <TooltipWrapper value='Settings' placement='top' force>
                                    <Button
                                        className='touchpoint-actions-setting'
                                        outline color={ 'white' }
                                        onClick={
                                            () => this.props.openModal(this.props.id, 'touchPointId', 'openModalTouchPointSettings')
                                        }
                                    >
                                        <FontAwesomeIcon icon='cog'/>
                                    </Button>
                                </TooltipWrapper>
                                <TooltipWrapper value='Dashboard Link' placement='top' force>
                                    <a
                                        href={ openLinkUrl }
                                        onClick={ () => this.openLinkAction(isOpenLinkEnabled, openLinkUrl) }
                                        className={ openLinkClassname }
                                    >
                                        <FontAwesomeIcon icon='share-square'/>
                                    </a>
                                </TooltipWrapper>
                                <TooltipWrapper value='Assign Surveys' placement='top' force>
                                    <Button
                                        className={ 'touchpoint-actions-add' }
                                        outline color={ 'white' }
                                        onClick={
                                            () => this.props.openModal(this.props.id, 'touchPointId', 'openModalAttachSurvey')
                                        }
                                        disabled={ !filteredUnitByPermit.length }
                                    >
                                        <FontAwesomeIcon icon={ [ 'far', 'dot-circle' ] } color={ 'green' }/>
                                    </Button>
                                </TooltipWrapper>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        );
    }

    render() {
        const isEmpty = !this.props.loading && this.state.expanded && this.props.surveys.length === 0;
        const { unitsList } = this.props;

        return (
            <div className={ 'panel' }>
                {
                    this.renderPanelHeader()
                }

                <Container className="panel-collapse">
                    {
                        isEmpty
                        && <div className='touchpoint-empty fadeIn'>
                            <span>There are no Surveys in this Touchpoint.</span>
                        </div>
                    }

                    {
                        this.props.loading
                            ? <div className='touchpoint-loader fadeIn'>
                                <FontAwesomeIcon icon='circle-notch' size='lg' spin/>
                                <span>Loading...</span>
                            </div>
                            : <div className={ 'surveys-list-modern fadeIn' }>
                                <div className={ 'surveys-list-modern-list' }>
                                    <table className={ 'table table-hover table-fixed' }>
                                        <tbody>
                                            {
                                                this.props.surveys
                                                    .filter(survey => unitsList.some(unit => unit.id === survey.unit.id))
                                                    .map((survey, index) => (
                                                        <SurveyItem
                                                            key={ index }
                                                            survey={ survey }
                                                            openModal={ this.props.openModal }
                                                            showToast={ this.props.showToast }
                                                        />
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                </Container>
            </div>
        );
    }
}

TouchPoint.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    surveys: PropTypes.array,
    loading: PropTypes.bool,
    channels: PropTypes.object,
    enterHandler: PropTypes.func,
    exitHandler: PropTypes.func,
    openModal: PropTypes.func,
    openHandler: PropTypes.func,
    closeHandler: PropTypes.func,
};
