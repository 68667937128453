import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { VocSelect, VocSelectMine, InputMinMaxNumber } from '/components';
import { QueryExecuteService } from '/services';
import { ModalFooter } from '../ModalFooter';

export const AttributesForm = ({
    close,
    name,
    onSubmit,
    queryAttributes,
    initialValues,
}) => {
    const [ attributes, setAttributes ] = useState([]);
    const [ selectedAttrName, setSelectedAttrName ] = useState(null);
    const [ selectedAttrValue, setSelectedAttrValue ] = useState([]);

    const getAttributesList = () => {
        const { isChange, inxCurrentFilter, filters } = initialValues;
        const currentFilterIds = filters && filters.length ? filters.map(f => f.id) : [];

        if (isChange) {
            currentFilterIds.splice(inxCurrentFilter, 1);
        }
        return queryAttributes
            .filter(attribute =>
                attribute.originType !== 'CLASSIFICATION'
                && attribute.originType !== 'DATE'
                && attribute.type !== 'TEXT'
                && attribute.originType !== 'TEXT'
                && attribute.originType !== 'service_captured_at'
                && attribute.originType !== 'freetext'
                && !currentFilterIds.includes(attribute.value),
            );
    };

    useEffect(() => {
        const { isChange, inxCurrentFilter, filters } = initialValues;
        const attributesList = getAttributesList();

        setAttributes(attributesList);

        if (attributesList.length && isChange) {
            const currentFilter = filters.find((el, inx) => inx === inxCurrentFilter);
            const attrName = attributesList.find(el => el.value === currentFilter.id);

            setSelectedAttrName(attrName);

            let attrValue;

            if (attrName.originType === 'scale') {
                attrValue = [ currentFilter.from, currentFilter.to ];
            } else if (attrName.attrOptions.length) {
                attrValue = attrName.skipOptions
                    ? [ ...currentFilter.values.map(el => ({ value: el, label: el })) ]
                    : attrName.attrOptions.filter(el => currentFilter.values.indexOf(el.value) >= 0);
            } else {
                attrValue = currentFilter.values[0];
            }

            setSelectedAttrValue(attrValue);
        }
    }, [ initialValues ]);

    const getChangedFilter = () => {
        const model = {};

        if (selectedAttrName.originType === "scale") {
            model['from'] = selectedAttrValue[0];
            model['to'] = selectedAttrValue[1];
        } else if (typeof selectedAttrValue === 'string') {
            model['values'] = [ selectedAttrValue ];
        } else {
            model['values'] = QueryExecuteService.convertObjToArray(selectedAttrValue);
        }

        return {
            id: selectedAttrName.value,
            ...model,
        };
    };

    const submitForm = () => {
        const { isChange, condition, filters, inxCurrentFilter } = initialValues;

        const newFilters = isChange
            ? filters.map((filter, inx) => inx === inxCurrentFilter ? getChangedFilter() : filter)
            : [
                ...filters,
                getChangedFilter(),
            ];

        onSubmit({
            condition,
            filters: newFilters.map(value => {
                return value.values
                    ? QueryExecuteService.convertValueToNull(value)
                    : value;
            }),
        }, name);
    };

    const changeAttrName = element => {
        setSelectedAttrName(element);
        setSelectedAttrValue([]);
    };

    const changeAttrValue = value => {
        setSelectedAttrValue(value);
    };

    const changeInputAttrValue = ({ currentTarget }) => {
        setSelectedAttrValue(currentTarget.value.replace(/^\s+/, ""));
    };

    const onBlur = () => {
        if (typeof selectedAttrValue !== 'object' && selectedAttrValue) {
            setSelectedAttrValue(selectedAttrValue.trim());
        }
    };

    const getSelectByAttributeValue = () => {
        const { parameters, attrOptions } = selectedAttrName;

        if (parameters) {
            const { min, max } = selectedAttrName.parameters;
            const limit = [ min, max ];
            const values = selectedAttrValue.length === 2 ? selectedAttrValue : [ '', '' ];

            return (
                <InputMinMaxNumber
                    selectedAttrName={ selectedAttrName }
                    title={ 'Answers range' }
                    values={ values }
                    onChange={ changeAttrValue }
                    limits={ limit }
                    isRequired={ true }
                />
            );
        } else if (attrOptions && attrOptions.length) {
            return (
                <VocSelectMine
                    placeholder={ 'Select attribute value' }
                    globalClass={ 'classifier-multi-select__row' }
                    label="Attribute value"
                    value={ selectedAttrValue }
                    options={ selectedAttrName.attrOptions }
                    onChange={ changeAttrValue }
                    closeMenuOnSelect={ false }
                    hint={ selectedAttrName.skipOptions ? 'This metadata has too many unique values. Please, enter it manually.' : '' }
                    isCreatable={ selectedAttrName.skipOptions }
                    id={ 'attribute-value' }
                    isMulti
                    isMultiOutside
                    withoutActionButton
                />
            );
        } else if (attrOptions) {
            return (
                <Input
                    placeholder="Attribute value"
                    onChange={ changeInputAttrValue }
                    onBlur={ onBlur }
                    value={ selectedAttrValue }
                />
            );
        }
    };

    useEffect(() => {
        return () => {
            setSelectedAttrName(null);
            setSelectedAttrValue([]);
            setAttributes([]);
            close();
        };
    }, []);

    return (
        <Fragment>
            <div className={ 'modal-body' }>
                <Row>
                    <Col md={ 12 } className='classification-btn-add'>
                        <VocSelect
                            label="Attribute name"
                            placeholder="Select attribute name"
                            isHorizontal={ false }
                            options={ attributes }
                            value={ selectedAttrName }
                            onChange={ value => changeAttrName(value) }
                        />
                    </Col>

                    <Col>
                        { selectedAttrName && getSelectByAttributeValue() }
                    </Col>
                </Row>
            </div>

            <ModalFooter
                close={ close }
                onClick={ submitForm }
                disabled={ !(Boolean(selectedAttrName) && selectedAttrValue && selectedAttrValue.length) }
                addBtn={ initialValues.nameButton }
            />
        </Fragment>

    );
};

AttributesForm.defaultProps = {
    initialValues: {},
};
