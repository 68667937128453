import { CALL_API, FEEDBACK } from 'store/middleware/api';

const GET_ALL_RIGHTS_UNIT_REQUEST = 'GET_ALL_RIGHTS_UNIT_REQUEST';
const GET_ALL_RIGHTS_UNIT_SUCCESS = 'GET_ALL_RIGHTS_UNIT_SUCCESS';
const GET_ALL_RIGHTS_UNIT_ERROR = 'GET_ALL_RIGHTS_UNIT_ERROR';

const POST_ALL_RIGHTS_UNIT_REQUEST = 'POST_ALL_RIGHTS_UNIT_REQUEST';
const POST_ALL_RIGHTS_UNIT_SUCCESS = 'POST_ALL_RIGHTS_UNIT_SUCCESS';
const POST_ALL_RIGHTS_UNIT_ERROR = 'POST_ALL_RIGHTS_UNIT_ERROR';

const CLEANUP_FEEDBACK_SHARE_RIGHTS = 'CLEANUP_FEEDBACK_SHARE_RIGHTS';

function getInitialState() {
    return {
        loading: false,
        results: [],
    };
}

export function reducer(state = (getInitialState()), action = {}) {
    switch (action.type) {
        case GET_ALL_RIGHTS_UNIT_REQUEST: {
            return {
                ...state,
                results: [ ],
                loading: true,
            };
        }

        case GET_ALL_RIGHTS_UNIT_SUCCESS: {
            return {
                ...state,
                results: [ ...action.response?.shares ],
                loading: false,
            };
        }

        case GET_ALL_RIGHTS_UNIT_ERROR: {
            return {
                ...state,
                results: [ ],
                loading: false,
            };
        }

        case POST_ALL_RIGHTS_UNIT_REQUEST: {
            return {
                ...state,
                results: [ ...action.body?.shares ],
                loading: true,
            };
        }

        case POST_ALL_RIGHTS_UNIT_SUCCESS: {
            return {
                ...state,
                results: [ ...action.response?.shares ],
                loading: false,
            };
        }

        case POST_ALL_RIGHTS_UNIT_ERROR: {
            return {
                ...state,
                results: [ ],
                loading: false,
            };
        }

        case CLEANUP_FEEDBACK_SHARE_RIGHTS: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getAllRights(unit) {
    return {
        [CALL_API]: {
            endpoint: `access-permission-owner/?unit=${unit}`,
            method: 'GET',
            types: [ GET_ALL_RIGHTS_UNIT_REQUEST, GET_ALL_RIGHTS_UNIT_SUCCESS, GET_ALL_RIGHTS_UNIT_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function postAllRights(body) {
    return {
        [CALL_API]: {
            endpoint: `/access-permission-owner/`,
            method: 'POST',
            types: [ POST_ALL_RIGHTS_UNIT_REQUEST, POST_ALL_RIGHTS_UNIT_SUCCESS, POST_ALL_RIGHTS_UNIT_ERROR ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP_FEEDBACK_SHARE_RIGHTS,
    };
}
