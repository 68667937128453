import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CreateDashboardModal,
    SendToEmailModal,
    DeleteDashboardModal,
    EditDashboardTitleModal,
    SchedulerModal,
} from '/visual/components';
import {
    ConfigureGadgetModal,
    DashboardFilterModal,
    DeleteGadgetModal,
    GadgetDescriptionModal,
    DrillDownGadgetModal,
    EditStaticAreaCodeModal,
} from '/visual/scenes/Dashboard/components';
import { cleanupConfigureModal, toggleModal, DashboardModalType } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { modalStateSelector, _getDashboardData } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';

interface IProps {
    dashboardId: string
}

export const ModalWrapper = ({
    dashboardId,
} : IProps) => {
    const dispatch = useDispatch();

    const modalState = useSelector(modalStateSelector);
    const dashboard = useSelector(_getDashboardData);

    const closeModal = () => {
        dispatch(toggleModal({ type: DashboardModalType.empty, dashboardId: null, gadgetId: null }));
    };

    return (
        <>
            <CreateDashboardModal
                show={ [
                    DashboardModalType.copyDashboard,
                    DashboardModalType.createDashboard,
                ].includes(modalState.type) }
                toggle={ closeModal }
                modalState={ modalState }
                openNewDashboard={ true }
            />

            <DashboardFilterModal
                show={ modalState.type === DashboardModalType.dashboardFilter }
                toggle={ closeModal }
                dashboardId={ dashboardId }
            />

            <DeleteDashboardModal
                show={ modalState.type === DashboardModalType.deleteDashboard }
                toggle={ closeModal }
                deleteDashboardId={ dashboardId }
            />

            <SendToEmailModal
                show={ modalState.type === DashboardModalType.sendToEmail }
                toggle={ closeModal }
                dashboardId={ dashboardId }
            />

            <SchedulerModal
                show={ modalState.type === DashboardModalType.scheduler }
                toggle={ closeModal }
                dashboardId={ dashboardId }
            />

            <EditDashboardTitleModal
                show={ modalState.type === DashboardModalType.renameDashboard }
                toggle={ closeModal }
                dashboard={ dashboard }
            />

            <ConfigureGadgetModal
                show={ modalState.type === DashboardModalType.configureGadget }
                toggle={ closeModal }
                cleanupConfigureModal={ cleanupConfigureModal }
            />

            <GadgetDescriptionModal
                show={ modalState.type === DashboardModalType.descriptionGadget }
                toggle={ closeModal }
                modalState={ modalState }
            />

            <DeleteGadgetModal
                show={ modalState.type === DashboardModalType.deleteGadget }
                toggle={ closeModal }
                gadgetId={ modalState?.gadgetId }
                gadgetType={ modalState?.gadgetType }
            />

            <DrillDownGadgetModal
                show={ modalState.type === DashboardModalType.drillDownGadget }
                toggle={ closeModal }
                title={ modalState?.title }
                gadgetId={ modalState?.gadgetId }
            />

            <EditStaticAreaCodeModal
                show={ modalState.type === DashboardModalType.editStaticAreaCode }
                toggle={ closeModal }
                gadgetId={ modalState?.gadgetId }
            />
        </>
    );
};
