import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { TopicSetsTable } from './components';
import { TopicSetApplianceModal } from '/ai/components';
import { _getTopicSets } from './modules/topicSets.selectors';
import { deleteTopicSet, getTopicSets, updateTopicSetsFilters } from './modules/topicSets.modules';
import { createTopicSetAppliance, toggleTopicSetApplianceModal } from '/ai/modules/vocAi.modules';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { IApplyModal, IFilters, ITopicAppliance } from '/ai/scenes/TopicSets/models';

export const TopicSets = () => {
    const { data, loading, filters, totalCount } = useSelector(_getTopicSets);
    const [ initModalValues, setInitModalValues ] = useState({});
    const dispatch = useDispatch();

    const getTableData = (filters: IFilters) => dispatch(getTopicSets(filters));

    const updateTableFilters = (filters: IFilters) => dispatch(updateTopicSetsFilters(filters));

    const openModalDelete = (id: string) => {
        dispatch(showConfirm({
            header: 'Please confirm',
            content: 'Are you sure you want to delete this topic set?',
            successCallback: () => dispatch(deleteTopicSet(id))
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .promise.then(() => getTableData({
                    ...filters,
                    page: data.length - 1 > 0 ? filters.page : 1,
                })),
        }));
    };

    const openModalTopicSetAppliance = (values: ITopicAppliance | null) => {
        setInitModalValues(values || {});
        dispatch(toggleTopicSetApplianceModal());
    };

    const onClosedModal = () => setInitModalValues({});

    const onSubmitModal = (formValues: IApplyModal) => {
        const { threshold, maxCategories, minCategories, column, multiTopic, sentiment, topicSet, source } = formValues;

        dispatch(createTopicSetAppliance(
            {
                threshold,
                maxCategories,
                minCategories,
                multiTopic,
                sentiment,
                attributeIndex: column.index,
            },
            topicSet.id,
            source.id,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
        )).promise.then(() => {
            openModalTopicSetAppliance(null);
            getTableData(filters);
        });
    };

    useEffect(() => {
        getTableData(filters);
    }, []);

    return (
        <DocumentTitle title='VocAI :: Topic Set List'>
            <Card>
                <CardBody>
                    <TopicSetsTable
                        tableData={ data }
                        filters={ filters }
                        totalCount={ totalCount }
                        loading={ loading }
                        requestTrigger={ getTableData }
                        updateFilters={ updateTableFilters }
                        openModalDelete={ openModalDelete }
                        openModalTopicSetSetAppliance={ openModalTopicSetAppliance }
                    />
                    <TopicSetApplianceModal
                        onSubmit={ onSubmitModal }
                        onClosed={ onClosedModal }
                        initFormValues={ initModalValues }
                    />
                </CardBody>
            </Card>
        </DocumentTitle>
    );
};
