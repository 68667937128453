import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

export const CollapseBodyHandler = ({
    handlerChange,
    handlerDelete,
    className,
    index,
    img,
    type,
}) => {
    const onChange = () => handlerChange(type, index);
    const onDelete = () => handlerDelete(type, index);

    return (
        <div className={ className }>
            {
                handlerChange
                && <TooltipWrapper value={ 'Edit' } force>
                    <div onClick={ onChange } className={ 'collapse-section-toolbar__button' }>
                        <div className={ 'icon' }>
                            <FontAwesomeIcon icon={ 'pencil-alt' }/>
                        </div>
                        <div className={ 'label' }>
                            Edit filter
                        </div>
                    </div>
                </TooltipWrapper>
            }

            {
                handlerDelete
                && <TooltipWrapper value={ 'Delete' } force>
                    {
                        img
                            ? <img src={ img } alt="Delete" onClick={ onDelete }/>
                            : <div onClick={ onDelete } className={ 'collapse-section-toolbar__button' }>
                                <div className={ 'icon' }>
                                    <FontAwesomeIcon icon={ 'trash-alt' }/>
                                </div>
                                <div className={ 'label' }>
                                    Delete filter
                                </div>
                            </div>
                    }
                </TooltipWrapper>
            }
        </div>
    );
};

CollapseBodyHandler.defaultProps = {
    index: 0,
    className: 'collapse-section__toolbar',
};
