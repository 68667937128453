import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { EExportFormats, IDashboard } from '/visual/models';
import { SwitchControl, TableRightsColumn, VocRightsHOC, VocTableV2 } from '/components';
import { ITableDashboards, TActionCell, TSharedCell } from './models';
import { hasVisualWriteAccessSelector } from '/modules/selectors';
import { ActionMenu, DashboardsTableTitle, FavouriteButton } from '../';

import styles from './style.module.scss';

const TableDashboards: FC<ITableDashboards> = ({
    tableData,
    loading ,
    onDeleteDashboard,
    onCopyDashboard,
    onSendToEmail,
    onScheduler,
    onMakeDefault,
    onImportOldDashboard,
    onEditDashboardTitle,
    onMakeFavourite,
    onExport,
    filters,
    count,
    requestTrigger,
    updateFilters,
    getSharedRights,
    openInviteModal,
    shares,
}) => {
    const { t } = useTranslation();
    const hasVisualWriteAccess = useSelector(hasVisualWriteAccessSelector);

    const titleFormatter = (_: string, data: IDashboard) => <DashboardsTableTitle dashboard={ data } />;

    const actionsFormatter = (cell: TActionCell, data: IDashboard) =>
        <ActionMenu
            rights={ cell.rights }
            dashboard={ data }
            onDeleteDashboard={ () => onDeleteDashboard(data) }
            onCopyDashboard={ () => onCopyDashboard(data) }
            openInviteModal={ () => openInviteModal(data.id) }
            onSendToEmail={ () => onSendToEmail(data) }
            onScheduler={ () => onScheduler(data) }
            onMakeDefault={ () => onMakeDefault(data) }
            onEditDashboardTitle={ () => onEditDashboardTitle(data) }
            onExport={ (format: EExportFormats) => onExport(data.id, format) }
        />;

    const favouriteFormatter = (isFavourite: boolean, data: IDashboard) =>
        <FavouriteButton
            isFavourite={ isFavourite }
            onClick={ () => onMakeFavourite(data) }
        />;

    const sharedFormatter = (cell: TSharedCell, data: IDashboard) =>
        <TableRightsColumn
            id={ data.id }
            getShares={ (id: IDashboard['id']) => getSharedRights(id, 'visual') }
            isPermitViewInfoShare={ cell.isPermitViewInfoShare }
            shares={ shares }
        />;

    const settings = [
        {
            field: 'isFavourite',
            title: '',
            formatter: favouriteFormatter,
            style: {
                width: '50px',
            },
        },
        {
            field: 'title',
            title: t('field.title'),
            sort: true,
            formatter: titleFormatter,
            style: {
                width: '200px',
            },
        },
        {
            field: 'ownerId',
            title: t('field.user'),
            sort: true,
            formatter: 'conditionTooltip',
            style: {
                width: '140px',
            },
        },
        {
            field: 'created_at',
            title: t('field.created_at'),
            sort: true,
            formatter: 'date',
            style: {
                width: '140px',
            },
        },
        {
            field: 'source',
            title: t('field.source'),
            formatter: 'conditionTooltip',
        },
        {
            field: 'description',
            title: t('field.description'),
            formatter: 'conditionTooltip',
        },
        {
            field: 'shared',
            title: t('field.role'),
            formatter: sharedFormatter,
            style: {
                textAlign: 'center',
                width: '70px',
            },
        },
        {
            field: 'actions',
            title: t('field.actions'),
            formatter: actionsFormatter,
            style: {
                textAlign: 'center',
                width: '80px',
            },
        },
    ];

    const favoritesSwitchHandler = () => {
        const filtersModel = {
            ...filters,
            isFavourite: filters.isFavourite ? 0 : 1,
            page: 1,
        };

        requestTrigger(filtersModel);
        updateFilters(filtersModel);
    };

    const customTableHeader = () =>
        [
            <div className={ styles.customTableHeader } key={ 'favoritesSwitch' }>
                <label className={ styles.favoritesSwitch }>
                    <SwitchControl
                        name={ 'favoritesSwitch' }
                        type={ 'small' }
                        value={ Boolean(filters.isFavourite) }
                        changeHandler={ favoritesSwitchHandler }
                    />
                    &nbsp;
                    { t('showOnlyFavorites') }
                </label>
                {
                    hasVisualWriteAccess
                    && <Button
                        outline
                        color={ 'primary' }
                        key={ 'onImportOldDashboard' }
                        onClick={ onImportOldDashboard }
                    >
                        { t('importOldDashboard') }
                    </Button>
                }
            </div>,
        ];

    const options = {
        rowStyle: {
            height: '38px',
        },
        haveSearch: true,
        goToPage: {
            closeOnChange: true,
            show: true,
        },
        requestTrigger,
        updateFilters,
        customTableHeader: customTableHeader(),
    };

    return (
        <div className={ styles.table_dashboards_wrapper }>
            <VocTableV2
                loading={ loading }
                data={ tableData }
                total={ count }
                dataSettings={ settings }
                filters={ filters }
                options={ options }
            />
        </div>
    );
};

export default VocRightsHOC({
    type: 'visual',
})(TableDashboards);
