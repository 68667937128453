import React from "react";
import { useSelector } from "react-redux";
import { textCountStatusSelector } from "/query/modules/query.selectors";

import './SensitivityInfo.scss';

export const SensitivityInfo = ({ limit }) => {
    const textCasesInfo = useSelector(textCountStatusSelector);
    const amountLemmaPairsTextCases = Math.ceil(textCasesInfo.count * limit / 500);

    return (
        <p className={ 'sensitivity-info' }>
            (lemma pairs will appear in at least
            <strong>{amountLemmaPairsTextCases}</strong>
            amount of text cases together)
        </p>
    );
};

SensitivityInfo.defaultProps = {
    limit: 0,
};
