import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IChartData } from '/ai/scenes/TopicSet/models';
import { HelperService } from '/services';

import styles from './style.module.scss';

export const TableChart = ({ chartData }: { chartData: IChartData }) => {
    const [ tableData, setTableData ] = useState(chartData.labels_scores);
    const [ sortDirection, setSortDirection ] = useState('');

    const getSortControls = () => <FontAwesomeIcon
        className={ styles.sortControls }
        icon={
            sortDirection
                ? sortDirection === 'asc'
                    ? 'angle-down'
                    : 'angle-up'
                : 'sort'
        }
    />;

    const sortTableData = sort_direction => {
        setSortDirection(sort_direction);
        let newTableData = chartData.labels_scores;

        if(sort_direction) {
            newTableData = HelperService.sortArrayWithObjects(
                [ ...newTableData ],
                'labels_scores',
                sort_direction,
            );
        }

        setTableData(newTableData);
    };

    const onSortHandler = () => {
        switch (sortDirection) {
            case '': {
                sortTableData('asc');
                break;
            }
            case 'asc': {
                sortTableData('desc');
                break;
            }
            case 'desc': {
                sortTableData('');
                break;
            }
        }
    };

    return (
        <div className={ styles.tableChartWrapper }>
            <p className={ styles.tableChartLabel }>{ chartData.name }</p>
            <table>
                <thead>
                    <tr>
                        <th>Text</th>
                        <th className={ styles.sortTh } onClick={ onSortHandler }>
                            Probability score { getSortControls() }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tableData.map((el, inx) => (
                            <tr key={ inx }>
                                <td>{ el.text }</td>
                                <td style={{ textAlign: 'center' }}>{ el.labels_scores || '-' }</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};
