import { HelperService } from '/services';
import { IPages } from '/visual/scenes/Dashboard/models';

export class DashboardGridService {
    static test = '';

    static removeExtraPagesCoordinate(extraPages, pageId, gadgetId) {
        extraPages[pageId].coordinates = extraPages[pageId].coordinates.filter(coordinate => coordinate.gadgetId !== gadgetId);

        return extraPages;
    }

    //and find pageId
    static removePagesCoordinate(pages, gadgetId) {
        return pages.map(page => {
            return page.coordinates
                ? {
                    ...page,
                    coordinates: page.coordinates.filter(coordinate => {
                        return coordinate.gadgetId !== gadgetId;
                    }),
                }
                : page;
        });
    }

    //with pageId
    static removePagesCoordinateWithId(pages, pageId, gadgetId) {
        return pages.map((pagesEl => {
            return {
                ...pagesEl,
                coordinates: pagesEl.id === pageId
                    ? pagesEl.coordinates.filter(coordinate => coordinate.gadgetId !== gadgetId)
                    : pagesEl.coordinates,
            };
        }));
    }

    static changePageCoordinate(pages: IPages[], data) {
        return pages.map((pageEl: IPages) => {
            const getPage = data[pageEl.id];

            return {
                ...pageEl,
                coordinates: getPage
                    ? getPage.coordinates
                    : pageEl.coordinates,
            };
        });
    }

    static copyGadget(state, action, storeSlice) {
        const { pageId, coordinate } = action.data.pages;
        const { id: gadgetId } = action.response;
        const { extraPages, pages } = storeSlice;

        const newPages = pages.map(page => {
            return page.id === pageId
                ? {
                    ...page,
                    coordinates: [ ...page.coordinates, { gadgetId, ...coordinate, autoPosition: false }],
                }
                : {};
        });

        return {
            ...storeSlice,
            extraGadgets: {
                ...storeSlice.extraGadgets,
                [gadgetId]: {
                    ...action.response,
                },
            },
            extraPages: {
                ...extraPages,
                [pageId]: {
                    ...extraPages[pageId],
                    coordinates: [
                        ...extraPages[pageId].coordinates,
                        { gadgetId, ...coordinate, autoPosition: false },
                    ],
                },
            },
            pages: newPages,
        };
    }

    static getData(isEditable, dashboard, stateOfEdit) {
        return JSON.parse(JSON.stringify( isEditable ? stateOfEdit : dashboard ));
    }


    static accumulateCoordinates = (accum, gadgetId, coordinates) => {
        return {
            ...accum,
            [gadgetId]: {
                ...HelperService.pick(coordinates, [ 'x', 'y', 'w', 'h' ]),
                gadgetId,
            },
        };
    };
}
