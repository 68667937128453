import { HelperService } from '/services/HelperService/HelperService';
import {
    GET_PROJECTS_LIST,
    GET_PROJECTS_LIST_EXPANDED,
    ACTIVE_PROJECT_CHANGED,
    GET_TOUCHPOINTS_LIST,
    GET_SURVEYS_BY_TOUCHPOINT_STORE,
    GET_SURVEYS_BY_TOUCHPOINT_REQUEST,
    GET_PROJECTS_LIST_EXPANDED_REQUEST,
    CLEAN_SURVEYS_BY_TOUCHPOINT,
    OPEN_TOUCHPOINT_BY_ID,
    DELETE_PROJECT_REQUEST,
    CLOSE_TOUCHPOINT_BY_ID,
} from './actions';

function getInitialState() {
    return {
        projects: {
            count: null,
            next: null,
            previous: null,
            results: [],
            loading: false,
        },
        touchPoints: {
            count: null,
            next: null,
            previous: null,
            results: [],
            opened: [],
            loading: [],
        },
        activeProject: HelperService.getFromSessionStorage('activeProject') || 1,
    };
}

export function JourneyMap(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_PROJECTS_LIST: {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    ...action.data,
                },
            };
        }

        case GET_PROJECTS_LIST_EXPANDED_REQUEST: {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    loading: true,
                },
            };
        }

        case GET_PROJECTS_LIST_EXPANDED: {
            return {
                ...state,
                projects: {
                    ...state.projects,
                    loading: false,
                    ...action.data,
                },
            };
        }

        case GET_TOUCHPOINTS_LIST: {
            return {
                ...state,
                touchPoints: {
                    ...state.touchPoints,
                    count: action.data.length,
                    results: [ ...action.data ],
                },
            };
        }

        case ACTIVE_PROJECT_CHANGED: {
            window.sessionStorage.setItem('activeProject', action.data);
            return {
                ...state,
                activeProject: action.data,
            };
        }

        case GET_SURVEYS_BY_TOUCHPOINT_REQUEST: {
            return {
                ...state,
                touchPoints: {
                    ...state.touchPoints,
                    loading: [
                        ...state.touchPoints.loading,
                        action.data,
                    ],
                },
            };
        }

        case GET_SURVEYS_BY_TOUCHPOINT_STORE: {
            return {
                ...state,
                touchPoints: {
                    ...state.touchPoints,
                    results: [
                        ...state.touchPoints.results.map(touchPoint => {
                            let temp = {
                                ...touchPoint,
                            };

                            if (touchPoint.id === action.data.id) {
                                temp = {
                                    ...touchPoint,
                                    surveys: action.data.surveys,
                                };
                            }
                            return temp;
                        }),
                    ],
                    loading: state.touchPoints.loading.filter(id => id !== action.data.id),
                },
            };
        }

        case CLEAN_SURVEYS_BY_TOUCHPOINT: {
            return {
                ...state,
                touchPoints: {
                    ...state.touchPoints,
                    results: [
                        ...state.touchPoints.results.map(touchPoint => {
                            let temp = {
                                ...touchPoint,
                            };

                            if (touchPoint.id === action.data) {
                                temp = {
                                    ...touchPoint,
                                    surveys: [],
                                };
                            }
                            return temp;
                        }),
                    ],
                },
            };
        }

        case DELETE_PROJECT_REQUEST: {
            const activeProject = Number(window.sessionStorage.getItem('activeProject'));

            if (activeProject === action.id) {
                window.sessionStorage.setItem('activeProject', 1);
            }

            return {
                ...state,
                activeProject: HelperService.getFromSessionStorage('activeProject'),
            };
        }

        case OPEN_TOUCHPOINT_BY_ID: {
            return {
                ...state,
                touchPoints: {
                    ...state.touchPoints,
                    opened: [
                        ...state.touchPoints.opened,
                        action.data,
                    ],
                },
            };
        }

        case CLOSE_TOUCHPOINT_BY_ID: {
            return {
                ...state,
                touchPoints: {
                    ...state.touchPoints,
                    opened: state.touchPoints.opened.filter(id => id !== action.data),
                },
            };
        }

        default:
            return state;
    }
}

