import React from 'react';
import { sockets } from '/services';

export function VocSocketHoc(scope) {
    return function(Component) {
        return class VocRequestClean extends React.Component {
            componentDidMount() {
                sockets.changeClientScope(scope);
            }

            componentWillUnmount() {
                if (scope !== null) {
                    sockets.changeClientScope(null);
                }
            }

            render() {
                return <Component { ...this.props }/>;
            }
        };
    };
}
