import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

import './QuarantinesActionsFormatter.scss';

export const QuarantinesActionsFormatter = ({
    openModalDelete,
    openModalEdit,
    quarantine,
}) => {
    const { permission: { isPermitEdit } } = quarantine;

    return (
        <div
            id={ 'table-action__container' }
            className='table-action__container table-action__quarantine-list'>
            <TooltipWrapper value={ 'Copy Quarantine' } force>
                <span
                    className={ cx(
                        'table-action__quarantine-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ () => isPermitEdit && openModalEdit(quarantine, 'copy') }
                >
                    <FontAwesomeIcon icon="copy"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Edit Quarantine' } force>
                <span
                    className={ cx(
                        'table-action__quarantine-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ () => isPermitEdit && openModalEdit(quarantine) }
                >
                    <FontAwesomeIcon icon="cog"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Delete Quarantine' } force>
                <span
                    className={ cx(
                        'table-action__quarantine-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ () => isPermitEdit && openModalDelete(quarantine) }>
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>
        </div>
    );
};
