import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import { TooltipWrapper } from '/components';

@withTranslation()
export class SegmentatorActionFormatter extends Component {
    @autobind
    onEditButtonClick() {
        const {
            sourceId,
            showApplianceModal,
            virtualFieldName,
            segments,
            id,
            placeholdersMapping,
            segmentType,
            applianceId,
            rights,
        } = this.props;

        rights.isPermitEdit && showApplianceModal({
            mode: 'edit',
            sourceId,
            placeholdersMapping,
            virtualFieldName,
            segments,
            id,
            applianceId,
            segmentType,
            isPermitView: rights.isPermitView,
        });
    }

    @autobind
    onClickDeleteButton() {
        const { showConfirm, name, rights, t } = this.props;

        rights.isPermitDelete && showConfirm({
            header: t('deleteRecoder'),
            content: t('deleteRecoderWarning', { name }),
            successCallback: this.onConfirmDeleteSegmentator,
        });
    }

    @autobind
    async onConfirmDeleteSegmentator() {
        const { deleteSegmentator, requestTrigger, id } = this.props;

        await deleteSegmentator(id).promise;
        requestTrigger({ page: 1 });
    }

    render() {
        const {
            sourceId,
            rights: { isPermitEdit, isPermitDelete },
            t,
        } = this.props;

        return (
            <div className='table-actions'>
                <TooltipWrapper value={ t('edit') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitEdit }) }>
                        <Button
                            disabled={ !sourceId }
                            color='link'
                            onClick={ this.onEditButtonClick }
                        >
                            <FontAwesomeIcon icon='pencil-alt'/>
                        </Button>
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('deleteRecodedVariable') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitDelete }) }>
                        <Button
                            color='link'
                            onClick={ this.onClickDeleteButton }
                        >
                            <FontAwesomeIcon icon='trash-alt' />
                        </Button>
                    </span>
                </TooltipWrapper>
            </div>
        );
    }
}

SegmentatorActionFormatter.propTypes = {
    id: PropTypes.any,
    sourceId: PropTypes.string,
    name: PropTypes.string,
    requestTrigger: PropTypes.func,
    showConfirm: PropTypes.func,
    showApplianceModal: PropTypes.func,
    deleteSegmentator: PropTypes.func,
};
