import React, { Fragment, Component, cloneElement } from 'react';
import { autobind } from 'core-decorators';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';

export class CopyToClipboard extends Component {
    @autobind
    onClick(event) {
        const {
            text,
            onCopy,
            children,
        } = this.props;

        const result = copy(text);

        onCopy && onCopy(result);

        if (children.props && children.props.onClick) {
            children.props.onClick(event);
        }
    }

    render() {
        return (
            <Fragment>
                {
                    cloneElement(this.props.children, { disabled: this.props.disabled, onClick: this.onClick })
                }
            </Fragment>
        );
    }
}

CopyToClipboard.defaultProps = {
    text: '',
};

CopyToClipboard.propTypes = {
    children: PropTypes.element.isRequired,
    onCopy: PropTypes.func,
    text: PropTypes.string,
};
