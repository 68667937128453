import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';

import { TooltipWrapper } from '/components';
import { HelperService } from '/services';
import { VirtualSourceFormCellPopover } from '../VirtualSourceFormCellPopover/index';

import './VirutalSourceFormCell.scss';

const Option = props => {
    return (
        <div>
            <TooltipWrapper
                value={ String(props.children) }
                getLastElementChildSize
            >
                <div className={ 'virtual-source-tooltip__container' }>
                    <components.Option { ...props } className="Select-options__title"/>
                </div>
            </TooltipWrapper>
        </div>
    );
};

const ValueContainer = ({ children, ...props }) => {
    const { t } = useTranslation();
    const fixedValue = props.selectProps.value && props.selectProps.value.type === 'fixed_value';
    let value = props.getValue();

    if (value.length) {
        value = value[0].label;
    }

    return (
        <div className="fixed__select-value">
            <div className="fixed__select-value-title">
                <TooltipWrapper value={ String(value) }>
                    <div>
                        <components.ValueContainer { ...props }>
                            { children }
                        </components.ValueContainer>
                    </div>
                </TooltipWrapper>
            </div>
            { fixedValue
            && <div className="Select-value__label Select-value__label--inline">
                <span className={ `fixed__select-value-label` }>
                    { t('fixed') }
                </span>
            </div>
            }
        </div>
    );
};

@withTranslation()
export class VirtualSourceFormCell extends Component {
    state = {
        show: false,
    };

    selectRef = React.createRef();

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.selected !== nextProps.selected
            || this.props.columnType !== nextProps.columnType
            || this.props.cellIsFixed !== nextProps.cellIsFixed
            || JSON.stringify(this.props.options) !== JSON.stringify(nextProps.options)
            || this.state.show !== nextState.show
        );
    }

    @autobind
    onSelectChange(option) {
        const value = option ? option.value : '';

        this.setState({
            show: option && option.value === 'fixed_value',
        });

        if (!option || value !== 'fixed_value') {
            const model = {
                rowId: this.props.rowId,
                columnId: this.props.columnId,
                value: {
                    data_id: this.props.sourceId,
                },
            };
            const valueType = (option && (option.type === 'fixed_value' || option.value === ''))
                ? 'value'
                : 'index';

            model.value[valueType] = value.trim();
            this.props.updateCellData(model);
        }
    }

    @autobind
    discardChanges() {
        this.setState({
            show: false,
        });
    }

    @autobind
    acceptChanges(value) {
        this.setState({
            show: false,
        });

        const model = {
            rowId: this.props.rowId,
            columnId: this.props.columnId,
            value: {
                data_id: this.props.sourceId,
                value,
            },
        };

        this.props.updateCellData(model);
    }

    @autobind
    getGroupedSelectedValue(collection, value, cellIsFixed) {
        const flattenCollection = HelperService.flattenDeep(collection);
        const fixedValueItem = cellIsFixed && flattenCollection.find(item =>
            item.type === 'fixed_value' && String(item?.value).trim() === String(value).trim());

        return fixedValueItem ? fixedValueItem : flattenCollection.find(item => item.value === value);
    }

    render() {
        const { rowId, isAutoFill, valid, options, selected, cellIsFixed, disabled, columnType, t } = this.props;
        const { show } = this.state;

        const isAutoFillStyle = () => rowId === 0 && isAutoFill;
        const classList = new Set([ 'fixed-table__col', !valid && 'fixed-table__col--invalid', isAutoFillStyle() && 'cell__autofill' ]);
        const placeholder = t(`placeholders.${ valid ? 'select' : 'selectInvalid' }`);
        const selectValue = this.getGroupedSelectedValue(options, selected, cellIsFixed);
        const cellId = `id_${ this.props.cellId }`;

        return (
            <div className={ Array.from(classList).join(' ') } id={ cellId } ref={ this.selectRef }>
                <Select
                    value={ selectValue || '' }
                    classNamePrefix='vochub-select-control'
                    className='vochub-select-control no-margin'
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={ document.body }
                    menuShouldScrollIntoView={ false }
                    menuPlacement='auto'
                    components={{
                        ValueContainer,
                        Option,
                    }}
                    onChange={ this.onSelectChange }
                    options={ options }
                    isClearable={ true }
                    placeholder={ placeholder }
                    disabled={ !options.length }
                    isDisabled={ disabled }
                />
                {
                    show && <VirtualSourceFormCellPopover
                        show={ show }
                        columnType={ columnType }
                        acceptChanges={ this.acceptChanges }
                        discardChanges={ this.discardChanges }
                        target={ cellId }
                        selectRef={ this.selectRef }
                    />
                }
            </div>
        );
    }
}

VirtualSourceFormCell.propTypes = {
    options: PropTypes.array,
    sourceId: PropTypes.string,
    columnType: PropTypes.string,
};
