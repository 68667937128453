import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ChangeNameModal } from '/mine/components';
import { VocButtonDropdown } from '/components';
import { getQueryTagsOptionSelector } from '/query/modules/query.selectors';
import { IQueryFooter } from './models';

import styles from './style.module.scss';

const saveViewOptions = [
    { id: 'save as', name: 'Save as…' },
];

const saveFullOptions = [
    { id: 'save', name: 'Save' },
    ...saveViewOptions,
];

export const QueryFooter = ({
    loading,
    queryInfo,
    useInVisual,
    saveFilters,
    saveNewQuery,
    onRun,
}: IQueryFooter) => {
    const [ showSaveAsModal, setShowSaveAsModal ] = useState(false);
    const allTagsOptions = useSelector(getQueryTagsOptionSelector);

    const getSaveOption = () => queryInfo.userPermission === 'view'
        ? saveViewOptions
        : saveFullOptions;

    const handleSaveQuery = (value: string) => value === 'save'
        ? saveFilters()
        : setShowSaveAsModal(true);

    const handleRun = () => onRun({});

    return (
        <div className={ styles.queryFooter }>
            <Button
                color='primary'
                disabled={ loading }
                className={ styles.runButton }
                onClick={ handleRun }
            >
                <FontAwesomeIcon icon='rocket' />
                &nbsp;
                <span>Run query</span>
            </Button>

            <VocButtonDropdown
                options={ getSaveOption() }
                placeholder='Save query'
                color='secondary'
                icon='save'
                className='queryFooterDropdown'
                disabled={ loading }
                handleClick={ handleSaveQuery }
            />

            <ChangeNameModal
                modalHeader='Save query as'
                submitButtonName='Save'
                show={ showSaveAsModal }
                queryId={ queryInfo.queryId }
                queryList={ [ queryInfo ] }
                initialContext={ queryInfo.context }
                whenSubmittedClose={ false }
                tags={ allTagsOptions }
                useInVisual={ useInVisual }
                onSubmit={ saveNewQuery }
                close={ () => setShowSaveAsModal(false) }
            />
        </div>
    );
};
