/*eslint no-undef: "off"*/

export class ZendeskSnippet {
    static jwtUrl = '/zendesk_data';

    static zendeskScriptUrl = "https://static.zdassets.com/ekr/snippet.js?key=7f4b04a2-a8dd-41c5-bd6c-96775a663f23";
    static zendeskJwt;
    static buttonDisabled = true;
    static opened = false;

    static parseJwt(token) {
        const base64Url = token.split('.')[1],
            base64 = base64Url.replace('-', '+').replace('_', '/');

        return JSON.parse(window.atob(base64));
    }

    static getZendeskButton() {
        const xhr = new XMLHttpRequest(ZendeskSnippet.jwtUrl);

        xhr.open('GET', ZendeskSnippet.jwtUrl);
        xhr.onload = () => {
            const response = JSON.parse(xhr.response);

            this.zendeskJwt = response.jwt;

            if (response.zendesk_enabled) {
                ZendeskSnippet.initZendeskButton(response);
            }
        };
        xhr.send();
    }

    static initZendeskButton(model) {
        ZendeskSnippet.configureZendeskScript(model);
        ZendeskSnippet.injectZendeskScript();
    }

    static configureZendeskScript(response) {
        window.zESettings = {
            authenticate: {
                jwt: response.jwt,
            },
            webWidget: {
                chat: {
                    suppress: response.zendesk_chat_suppress,
                },
                contactForm: {
                    suppress: response.zendesk_contact_form_suppress,
                },
                color: {
                    theme: '#337ab7',
                },
                position: {
                    horizontal: 'right',
                },
            },
        };
    }

    static injectZendeskScript() {
        const script = document.createElement("script");

        script.id = "ze-snippet";
        script.src = this.zendeskScriptUrl;
        script.onload = () => this.zendeskScriptCallback();

        document.body.appendChild(script);
    }

    static zendeskScriptCallback() {
        const identifyData = ZendeskSnippet.parseJwt(this.zendeskJwt);

        ZendeskSnippet.buttonDisabled = false;
        zE('webWidget', 'hide');
        zE('webWidget:on', 'close', function() {
            zE('webWidget', 'hide');
            ZendeskSnippet.opened = false;
        });

        zE(() => {
            window.zE.identify({
                name: identifyData.name || '',
                email: identifyData.email || '',
            });
        });
    }

    static showSnippet() {
        if (ZendeskSnippet.buttonDisabled) {
            return;
        }

        if (ZendeskSnippet.opened) {
            zE('webWidget', 'hide');
            zE('webWidget', 'close');
        } else {
            zE('webWidget', 'show');
            zE('webWidget', 'toggle');
        }

        ZendeskSnippet.opened = !ZendeskSnippet.opened;
    }

    static setSuggestions(word) {
        if (window.zE && window.zE.setHelpCenterSuggestions) {
            window.zE.setHelpCenterSuggestions({ search: word });
        }
    }
}
