import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AutoSizer, List, ListRowProps,
    WindowScroller, ListProps, ScrollParams,
    OverscanIndexRange, IndexRange,
} from 'react-virtualized';
import cx from 'classnames';
import uuidV4 from 'uuid/v4';

import { DashboardGridService } from '/visual/scenes/Dashboard/services';
import { GridPage } from '/visual/scenes/Dashboard/components/GridPage';
import { Gadget } from '/visual/scenes/Dashboard/components';
import { Placeholder } from '../Placeholder';
import { useCopyFindPlaceDebounce } from '/visual/customHooks';
import {
    PaginatedLayout, copyGadgetType, IGadgetsVirtualList,
    DashboardPageType, ReducedGadgetType, IGridPageProps,
} from '/visual/scenes/Dashboard/models';
import { HelperService, useResponsiveQuery } from '/services';
import { scrollIntoViewSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { START_SMOOTH_SCROLLING, setScrollTo } from '/visual/scenes/Dashboard/modules/Dashboard.modules';

import styles from './GadgetsVirtualList.module.scss';

const {
    maxSize: { maxW, maxH },
    minSize: { minW, minH },
} = DashboardGridService.getGadgetSize();

export const GadgetsVirtualList = ({
    gadgets,
    pages,
    gadgetClipboard,
    isEditable,
    isTabletAndBelow,
    pasteGadget,
    manageGadgetClipboard,
    openGadgetDescriptionModal,
    onCopyGadget,
    openDeleteGadgetModal,
    onMoveToSave,
    onPositionChanged,
    openGadgetModal,
    hidePlaceholders,
    setHidePlaceholders,
}: IGadgetsVirtualList) => {
    const { isMobile } = useResponsiveQuery();
    const {
        scrollToPage,
        scrollToGadget,
        startSmoothScrolling,
    } = useSelector(scrollIntoViewSelector);
    const dispatch = useDispatch();

    const gadgetHeight = 350;
    const GridPageProps: IGridPageProps = {
        isResizable: isEditable && !gadgetClipboard,
        isDraggable: isEditable && !gadgetClipboard,
        isDroppable: isEditable && !gadgetClipboard,
        draggableCancel: '.disabled-drag',
        draggableHandle: '.enabled-drag',
        compactType: 'vertical',
        useCSSTransforms: true,
        onPositionChanged,
        ...DashboardGridService.getGridConfig(),
    };
    const gadgetsList = gadgets.reduce<Array<Array<ReducedGadgetType>>>((acc, gadget, index) => {
        // TODO: Check gadgets order
        // for mobile 1 gadget per row, for tablet 2
        if(isTabletAndBelow && !isMobile) {
            const chunkIndex = Math.floor(index / 2);

            if(!acc[chunkIndex]) {
                acc[chunkIndex] = [];
            }

            acc[chunkIndex].push(gadget);
        } else {
            acc.push([ gadget ]);
        }

        return acc;
    }, []);
    const rowHeight = isTabletAndBelow ? gadgetHeight : GridPageProps.pageHeight;
    const rowCount = isTabletAndBelow ? gadgetsList.length : pages.length;

    const [ isDragEvent, setIsDragEvent ] = useState(false);
    const [ creatingGadgetLoading, setCreatingGadgetLoading ] = useState(false);
    const [ newStaticAreaGadgetId, setNewStaticAreaGadgetId ] = useState<null | string>(null);
    const [ hoveredPageId, setHoveredPageId ] = useState<string | null>(null);
    const [ forceScrollData, setForceScrollData ] = useState<{
        forceToIndex: number | null, // for skipping large smooth scrolling to the far page
        preScrollOffset: number, // for preScrolling to the far page
    } | null>(null);
    const { copyFindPlaceDebounce } = useCopyFindPlaceDebounce(gadgetClipboard);
    const visiblePages = useRef<[ number, number ]>([ 0, 0 ]);
    const listRef = useRef<List>(null);

    useEffect(() => {
        if (HelperService.checkNotNullOrUndefined(scrollToPage)) {
            let scrollToPageIndex = pages.findIndex(page => page.page === scrollToPage);
            const [ overscanStartIndex, overscanStopIndex ] = visiblePages.current;
            const lessThanOverscanStart = scrollToPageIndex < overscanStartIndex;
            const moreThanOverscanStop = scrollToPageIndex > overscanStopIndex;

            if (lessThanOverscanStart || moreThanOverscanStop) {
                const differ = lessThanOverscanStart
                    ? overscanStartIndex - scrollToPageIndex
                    : scrollToPageIndex - overscanStopIndex;

                const offset = listRef?.current?.getOffsetForRow(({ alignment: 'auto', index: scrollToPageIndex })) || 0;

                // skip smooth scrolling per all pages, if it has large differ
                if (differ > 5) {
                    scrollToPageIndex = lessThanOverscanStart ? scrollToPageIndex + 5 : scrollToPageIndex - 5;
                    setForceScrollData({ forceToIndex: scrollToPageIndex, preScrollOffset: offset });
                } else {
                    window.scrollTo({ top: offset, behavior: 'smooth' });
                }
            }

            // need to sure that virtualize created new nodes after force scrolling (for animation)
            setTimeout(() => {
                dispatch({ type: START_SMOOTH_SCROLLING });
            }, 0);
        }
    }, [ scrollToPage ]);

    useEffect(() => {
        // first find the page for scrolling into view before scroll to gadget
        // skip this if page was found before and animation already started (startSmoothScrolling - true)
        if (!scrollToPage && !startSmoothScrolling && scrollToGadget) {
            const scrollToPageItem = pages.find(page =>
                page.gadgets.some(gadget => gadget.id === scrollToGadget),
            );

            scrollToPageItem && dispatch(setScrollTo({ pageId: scrollToPageItem.page }));
        }
    }, [ scrollToGadget, scrollToPage, startSmoothScrolling ]);

    const renderPagePlaceholders = (
        { placeholders }: DashboardPageType,
        pageIdx: number,
        isCreateNew = false,
    ) => {
        const availablePlaceholders = DashboardGridService.findAvailablePlaceholders(
            placeholders,
            { ...gadgetClipboard?.coordinates, y: 0 } as PaginatedLayout,
            isCreateNew,
        );

        const pageId = pages[pageIdx].page;

        let items = availablePlaceholders
            .flatMap((item, placeholderIdx) => {
                return item.map((value, valueIdx) => {
                    let model: { h: number, w: number } = { h: maxH, w: maxW };

                    if (isCreateNew) {
                        const availableHeight = value.maxY + 1 - value.minY;

                        if(value.maxW < maxW) {
                            model = value.maxW === 1
                                ? { w: 1, h: 2 }
                                : { w: minW, h: minH };
                        }

                        if (availableHeight < maxH && model.w === maxW) {
                            model = availableHeight >= minH
                                ? { w: minW, h: minH }
                                : { w: 0, h: 0 };
                        } else if (availableHeight < minH && availableHeight >= 2) {
                            model = { w: 1, h: 2 };
                        } else if (availableHeight < minH) {
                            model = { w: 0, h: 0 };
                        }
                    }

                    return {
                        x: value.minX,
                        y: value.minY,
                        maxW: value.maxW,
                        page: pageId,
                        key: `placeholder-${uuidV4()}${ pageId }-${ placeholderIdx }-${ valueIdx }-${ gadgetClipboard?.id || null }`,
                        ...isCreateNew
                            ? {
                                ...model,
                                oldPage: pageId,
                                gadgetId: null,
                            }
                            : {
                                ...HelperService.pick(gadgetClipboard?.coordinates, [ 'h', 'w' ]) as { h: number, w: number },
                                oldPage: gadgetClipboard?.page,
                                gadgetId: gadgetClipboard?.id,
                            },
                        i: 'placeholder',
                    };
                });
            });

        if (gadgetClipboard && gadgetClipboard?.type !== 'copy' && !isCreateNew) {
            items = items.filter(value => value.maxW >= value.w
                 && value.page !== gadgetClipboard.coordinates.page
                 || value.x !== gadgetClipboard.coordinates.x
                 && value.y - gadgetClipboard.coordinates.y !== 1,
            );
        } else if (!isCreateNew) {
            const isFoundPlace = Boolean(items.length);
            const model = {
                isFoundPlace,
                value: gadgetClipboard,
            };

            if (!isFoundPlace) model['callback'] = onCopyGadget;

            copyFindPlaceDebounce(model);
        } else {
            items = items.filter(value => value.w);
        }

        return items.map((value: copyGadgetType) => (
            <div
                key={ value.key }
                data-grid={ value }
                className={ cx(styles.pasteContainer, { [styles.smallContainer]: value.h <= 3 }) }
            >
                <Placeholder
                    key={ value.key }
                    loading={ creatingGadgetLoading }
                    withGadget={ value.h > 3 }
                    coords={ value }
                    copyHandler={ onCopyGadget }
                    setNewStaticAreaGadgetId={ setNewStaticAreaGadgetId }
                    createHandler={ pasteGadget }
                    setLoading={ setCreatingGadgetLoading }
                    isCopy={ gadgetClipboard?.type === 'copy' }
                    isCut={ Boolean(gadgetClipboard && gadgetClipboard.type !== 'copy' ) }
                />
            </div>
        ));
    };

    const renderGadgets = (gadgets: Array<ReducedGadgetType>, fixedWidth = true) => {
        const defaultPaddings = gadgets.length === 1 ? 0 : gadgets.length;
        const width = fixedWidth ? 'auto' : 100 / gadgets.length - defaultPaddings + '%';
        const cutGadgetId = gadgetClipboard?.type !== 'copy' && gadgetClipboard?.id;

        return gadgets.map(gadget => (
            <div
                key={ gadget.id }
                data-grid={{ ...gadget.coordinates, minW: gadget.minW, minH: gadget.minH }}
                className={ cx(styles.gridItemWrapper, { 'opacity-6': cutGadgetId === gadget.id }) }
                style={{ width }}
            >
                <Gadget
                    newStaticAreaGadgetId={ newStaticAreaGadgetId }
                    setNewStaticAreaGadgetId={ setNewStaticAreaGadgetId }
                    isEditable={ isEditable }
                    isDragEvent={ isDragEvent }
                    gadgetId={ gadget.id }
                    onCopy={ () => dispatch(manageGadgetClipboard({ ...gadget, type: 'copy' })) }
                    onEdit={ () => openGadgetModal(gadget) }
                    onMoreInfo={ () => openGadgetDescriptionModal(gadget) }
                    setHidePlaceholders={ setHidePlaceholders }
                    onDelete={ () => {
                        dispatch(manageGadgetClipboard());
                        openDeleteGadgetModal(gadget);
                    } }
                    onCut={ () => dispatch(manageGadgetClipboard({ ...gadget, type: 'cut' })) }
                    onMoveToSave={ () => {
                        dispatch(manageGadgetClipboard());
                        onMoveToSave(gadget);
                    } }
                />
            </div>
        ));
    };

    const renderLayoutList = (
        { index, key, style }: ListRowProps,
        width: number,
        isScrolling: boolean,
    ) => {
        const item = pages[index];

        return (
            <GridPage
                layout={ item.layout }
                key={ key }
                gadgetClipboard={ gadgetClipboard }
                width={ width }
                style={{
                    ...style,
                    ...isScrolling && { pointerEvents: 'all' },
                }}
                { ...GridPageProps }
                page={ item.page }
                pageIndex={ index }
                pagesCount={ pages.length }
                dashboardId={ item.dashboardId }
                isEditable={ isEditable }
                isTabletAndBelow={ isTabletAndBelow }
                hoveredPageId={ hoveredPageId }
                setIsDragEvent={ setIsDragEvent }
                setHoveredPageId={ setHoveredPageId }
                setHidePlaceholders={ setHidePlaceholders }
            >
                { renderGadgets(item.gadgets) }
                {
                    isEditable
                    && !hidePlaceholders
                    && ( gadgetClipboard?.type === 'copy' || hoveredPageId === item.page )
                        ? renderPagePlaceholders(item, index, isEditable && !gadgetClipboard)
                        : null
                }
            </GridPage>
        );
    };

    const renderGadgetsList = ({ index, key, style }: ListRowProps) => {
        const gadgets = gadgetsList[index];

        return (
            <div key={ key } className={ styles.gadgetWrapper } style={ style }>
                { renderGadgets(gadgets, false) }
            </div>
        );
    };

    const renderList = (item: ListRowProps, width: number, isScrolling: boolean) => {
        return !isTabletAndBelow
            ? renderLayoutList(item, width, isScrolling)
            : renderGadgetsList(item);
    };

    const handleSetVisiblePages = (props: OverscanIndexRange & IndexRange) =>
        visiblePages.current = [ props.overscanStartIndex, props.overscanStopIndex ];

    const getScrollProps = (windowScrollerScrollTop: ListProps['scrollTop']) =>
        HelperService.checkNotNullOrUndefined(forceScrollData?.forceToIndex)
            ? { scrollToIndex: forceScrollData?.forceToIndex }
            : { scrollTop: windowScrollerScrollTop };

    const handleListScroll = (scrollParams: ScrollParams, onChildScroll: (params: ScrollParams) => void) => {
        if (forceScrollData && HelperService.checkNotNullOrUndefined(forceScrollData.forceToIndex)) {
            // if forceScrollToPageIndex?.offset exists, do force scroll without smooth scrolling
            setForceScrollData({ forceToIndex: null, preScrollOffset: forceScrollData.preScrollOffset });
        } else if (forceScrollData?.preScrollOffset) {
            // after force scrolling, make smooth preScroll
            window.scrollTo({ top: forceScrollData?.preScrollOffset, behavior: 'smooth' });

            // reset scrollToIndex for right scroll behaviour (check getScrollProps method)
            setForceScrollData(null);
        }

        return onChildScroll(scrollParams);
    };

    return (
        <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <AutoSizer disableHeight>
                    {
                        ({ width }) => (
                            <List
                                ref={ listRef }
                                autoHeight
                                height={ height }
                                isScrolling={ isScrolling }
                                rowCount={ rowCount }
                                rowHeight={ rowHeight }
                                width={ width }
                                overscanRowCount={ 1 }
                                tabIndex={ -1 }
                                className={ styles.virtualizedList }
                                { ...getScrollProps(scrollTop) }
                                rowRenderer={ item => renderList(item, width, isScrolling) }
                                onScroll={ params => handleListScroll(params, onChildScroll) }
                                onRowsRendered={ handleSetVisiblePages }
                            />
                        )
                    }
                </AutoSizer>
            )}
        </WindowScroller>
    );
};
