import React, { Fragment } from 'react';
import { GatewaysSettingsService } from '/feedback/services';

export * from './SettingFieldContainer.scss';

export const SettingFieldContainer = ({ checked, children, className = '', field }) => {
    const redefineSetting = () => {
        const {
            onChange,
            value,
            name,
        } = field;

        const model = {
            ...value,
            ...(
                name === "GATEWAYS_CHAIN"
                    ? GatewaysSettingsService.getRedefineSetting(value)
                    : {
                        value: value.redefined
                            ? value.channelValue
                            : (value.surveyValue || value.channelValue),
                    }
            ),
            redefined: !value.redefined,
        };

        onChange(model);
    };

    return (
        <Fragment>
            {
                field.value.isGlobal
                    ? <div className={ `voc-setting-field ${ className }` }>
                        { children }
                    </div>
                    : <div className={ `voc-setting-field ${ checked ? '' : 'greyed no-events' } ${ className }` }>
                        <div className={ 'voc-settings-checkbox__container' }>
                            <label className={ 'voc-settings-checkbox' }>
                                <input
                                    type="checkbox"
                                    checked={ checked }
                                    onChange={ redefineSetting }
                                />
                                <span className={ 'voc-settings-checkbox__checkmark' }/>
                            </label>
                        </div>
                        { children }
                    </div>
            }
        </Fragment>
    );
};
