import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import './VocProgressBar.scss';

@withTranslation('common', { keyPrefix: 'progressBar' })
export class VocProgressBar extends Component {
    constructor(props) {
        super(props);

        const { t } = props;

        this.statusMap = [
            {
                name: 'processed',
                value: t('processed'),
                color: 'success',
                active: false,
            },
            {
                name: 'failed',
                value: t('failed'),
                color: 'danger',
                active: false,
            }, {
                name: 'cancelled',
                value: t('cancelled'),
                color: 'warning',
                active: false,
            },
            {
                name: 'pending',
                value: t('pending'),
                color: 'info',
                active: true,
            },
        ];
    }

    getPercentage(count, total) {
        return count > 0 ? Number((Math.floor(count * 1000 / total) / 10).toFixed(2)) : 0;
    }

    @autobind
    getErrorPercentageCount() {
        let totalPercent = 0;
        const { total } = this.props;
        let approximateCount = 0;
        const correctTotalPercent = 100;

        this.statusMap.map(status => {
            totalPercent += this.getPercentage(this.props[status.name], total);
        });

        if (totalPercent < correctTotalPercent) {
            approximateCount = Math.floor((correctTotalPercent - totalPercent) * total / 100);
        }

        return approximateCount;
    }

    @autobind
    addApproximationCount(approximateCount, propsObject) {
        for (let i = 0; i < this.statusMap.length; i++) {
            if (propsObject[this.statusMap[i].name] > 0) {
                propsObject[this.statusMap[i].name] += approximateCount;
                return this.statusMap[i].name;
            }
        }

        return null;
    }

    calculateCount({ propsObject, name, errorCount, approximatedBarName }) {
        return propsObject[name] - (approximatedBarName === name ? errorCount : 0);
    }


    renderProgressLegend() {
        const errorCount = this.getErrorPercentageCount();
        const propsObject = { ...this.props };
        let approximatedBarName;

        if(errorCount) {
            approximatedBarName = this.addApproximationCount(errorCount, propsObject);
        }

        return (
            <div className='progressbar-values'>
                <ul className='progressbar-values__list'>
                    {
                        this.statusMap.map((status, index) => (
                            propsObject[status.name] > 0
                            && <li className='progressbar-values__list-item' key={ index }>
                                <small className="progressbar-values__title">
                                    { status.value }: <span> {

                                        this.calculateCount({
                                            propsObject,
                                            name: status.name,
                                            errorCount,
                                            approximatedBarName,
                                        })

                                    } </span>
                                </small>
                                <small className='progressbar-values__percent'>
                                    { this.getPercentage(propsObject[status.name], this.props.total) }%
                                </small>
                            </li>
                        ))
                    }
                </ul>
            </div>
        );
    }

    render() {
        const { total } = this.props;

        return (
            <div>
                <Progress multi>
                    {
                        this.statusMap.map((status, index) =>
                            <Progress
                                value={ this.getPercentage(this.props[status.name], total) }
                                key={ index }
                                bar
                                animated={ status.name === 'pending' }
                                color={ status.color }
                            />,
                        )
                    }
                </Progress>
                { this.renderProgressLegend() }
            </div>

        );
    }
}

VocProgressBar.propTypes = {
    total: PropTypes.number,
};

