import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocTableV2, SwitchControl } from '/components';
import { DownloadLink, ChannelLabel } from '/feedback/components';

import './ImportsTable.scss';

export class ImportsTable extends Component {
    settings = [
        {
            field: 'control',
            title: '',
            formatter: this.controlFormatter,
            style: {
                textAlign: 'center',
                width: '13px',
            },
        },
        {
            field: 'id',
            title: 'Import ID',
            sort: true,
            style: {
                textAlign: 'center',
                width: '100px',
            },
        },
        {
            field: 'creation',
            formatter: 'date',
            title: 'Created',
            sort: true,
            style: {
                textAlign: 'center',
                width: '135px',
            },
        },
        {
            field: 'survey_id',
            title: 'Survey ID',
            formatter: 'conditionTooltip',
            sort: true,
            style: {
                textAlign: 'center',
                width: '100px',
            },
        },
        {
            field: 'survey__survey_group__name',
            title: 'Survey Name',
            formatter: 'conditionTooltip',
            sort: true,
            style: {
                textAlign: 'center',
                width: '200px',
            },
        },
        {
            field: 'survey__channel__name',
            title: 'Channel',
            formatter: this.channelFormatter,
            sort: true,
            style: {
                textAlign: 'center',
                width: '90px',
            },
        },
        {
            field: 'state',
            title: 'State',
            formatter: 'conditionTooltip',
            sort: true,
            style: {
                width: '132px',
                textAlign: 'center',
            },
        },
        {
            field: 'end_time',
            title: 'Completed',
            formatter: 'date',
            sort: true,
            style: {
                textAlign: 'center',
                width: '140px',
            },
        },
        {
            field: 'result_file',
            title: 'Download file',
            formatter: this.downloadFileFormatter,
            sort: false,
            style: {
                textAlign: 'center',
            },
        },
    ];

    state = {
        id: null,
    };

    @autobind
    downloadFileFormatter(cell, row) {
        const { original_file, result_file, error_file } = row;

        const handleOnClick = ({ id, type, fileName }) =>
            this.props.onDownloadFile({ id, type, fileName });

        return (
            <div className={ 'import-download-column' }>
                <DownloadLink
                    id={ original_file.id }
                    fileName={ original_file.fileName }
                    type={ original_file.type }
                    disabled={ original_file.isDeleted || !this.props.permission }
                    title={ 'Download file' }
                    subtitle={ `Total Records - ${original_file.totalItemsCount}` }
                    onClick={ () => handleOnClick(original_file) }
                />
                <DownloadLink
                    id={ result_file.id }
                    fileName={ result_file.fileName }
                    type={ result_file.type }
                    iconColor={ result_file.isDeleted ? 'disabled' : 'green ' }
                    disabled={ result_file.isDeleted || !this.props.permission }
                    title={ 'Download file' }
                    subtitle={ `Processed Records - ${result_file.processedItemsCount}` }
                    onClick={ () => handleOnClick(result_file) }
                />
                <DownloadLink
                    id={ error_file.id }
                    fileName={ error_file.fileName }
                    type={ error_file.type }
                    iconColor={ error_file.isDeleted ? 'disabled' : 'red ' }
                    disabled={ error_file.isDeleted || !this.props.permission }
                    title={ 'Download error file' }
                    subtitle={ `Error Records - ${error_file.failedItemsCount}` }
                    onClick={ () => handleOnClick(error_file) }
                />
            </div>
        );
    }

    @autobind
    channelFormatter(cell) {
        return cell && <ChannelLabel title={ cell }/>;
    }

    @autobind
    controlFormatter(cell, { id }) {
        const typeIcon = id === this.state.id ? 'chevron-up' : 'chevron-down';

        return cell
            ? <FontAwesomeIcon icon={ typeIcon } className={ 'chevron-down__blue' } />
            : null;
    }

    @autobind
    updateFilters(data) {
        this.props.updateFilters(data);
        this.setState({ id: null });
    }

    @autobind
    customTableHeader() {
        const { requestTrigger, filters } = this.props;

        const onChange = () => {
            const newFilters = {
                ...filters,
                hide_empty_imports: !filters.hide_empty_imports,
            };

            requestTrigger(newFilters);
            this.updateFilters(newFilters);
        };

        return [
            <label
                htmlFor={ 'hideEmptyImports' }
                key={ 'hideEmptyImports' }
                className={ 'hideEmptyImportsLabel' }
            >
                <span>
                    <SwitchControl
                        name={ 'hideEmptyImports' }
                        changeHandler={ onChange }
                        value={ filters.hide_empty_imports }
                        type={ 'small' }
                    />
                </span>
                Hide empty Imports
            </label>,
        ];
    }

    @autobind
    getTableOptions() {
        return {
            requestTrigger: this.props.requestTrigger,
            haveSearch: true,
            updateFilters: this.updateFilters,
            customTableHeader: this.customTableHeader(),
            goToPage: {
                closeOnChange: true,
                show: true,
            },
        };
    }

    @autobind
    mapAdditionalRow(item) {
        return item.map(({ channel, id, survey_group_name }) => {
            return (
                <tr key={ id } className={ 'import-additional-row' }>
                    <td colSpan={ 3 }/>
                    <td> { id } </td>
                    <td> { survey_group_name } </td>
                    <td> { this.channelFormatter(channel) } </td>
                    <td colSpan={ 3 }/>
                </tr>
            );
        });
    }

    @autobind
    onOpenRow({ target }) {
        let id = +target?.parentElement?.dataset?.id || null;

        if(!target?.dataset?.control) id = null;
        if(id === this.state.id) id = null;

        this.setState({ id });
    }

    render() {
        const {
            total,
            loading,
        } = this.props.tableState;

        return (
            <div className={ 'imports-table-wrapper' }>
                <VocTableV2
                    dataSettings={ this.settings }
                    data={ this.props.data }
                    options={ this.getTableOptions() }
                    filters={ this.props.filters }
                    onOpenRow={ this.onOpenRow }
                    openedRowId={ this.state.id }
                    mapAdditionalRow={ this.mapAdditionalRow }
                    total={ total }
                    loading={ loading }
                />
            </div>
        );
    }
}

ImportsTable.propTypes = {
    requestTrigger: PropTypes.func,
    updateFilters: PropTypes.func,
    data: PropTypes.array,
    filters: PropTypes.object,
    tableState: PropTypes.shape({
        page: PropTypes.number,
        loading: PropTypes.bool,
    }),
};
