import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    CardHeader,
    Badge,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import { VocTableV2, VocCleaupHOC } from '/components';
import { PageRequests } from '/services';

import { SegmentatorActionFormatter } from './components/SegmentatorActionFormatter/';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { getSegmentatorsList } from '/scenes/VocStore/modules/VocStore.modules';
import { showApplianceModal } from '/modules/segmentatorModules/segmentator.modules.js';

import {
    updateFilters,
    deleteSegmentator,
    cleanup,
} from './modules/segmentators.modules';

import {
    segmentatorsTableDataSelector,
    filtersSelector,
    loadingSelector,
    segmentatorsCountSelector,
} from './modules/segmentators.selectors';

const mapStateToProps = state => ({
    tableData: segmentatorsTableDataSelector(state),
    segmentatorsCount: segmentatorsCountSelector(state),
    filters: filtersSelector(state),
    loading: loadingSelector(state),
});

const mapDispatchToProps = {
    getSegmentatorsList,
    updateFilters,
    cleanup,
    deleteSegmentator,
    showApplianceModal,
    showConfirm,
};

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class Segmentators extends Component {
    pageRequests = new PageRequests();

    @autobind
    getSettings() {
        const { t } = this.props;

        return [
            {
                field: 'name',
                title: t('field.name'),
                formatter: 'conditionTooltip',
                sort: true,
                style: {
                    width: '220px',
                },
            },
            {
                field: 'created',
                title: t('field.createdAt'),
                formatter: 'date',
                sort: true,
            },
            {
                field: 'updated',
                title: t('field.lastUpdated'),
                sort: true,
                formatter: 'date',
            },
            {
                field: 'segmentatorSources',
                title: t('field.appliedSources'),
                formatter: 'conditionTooltip',
                style: {
                    width: '200px',
                },
            },
            {
                field: 'sourceType',
                title: t('field.sourceType'),
                formatter: this.segmentatorSourceTypeFormatter,
            },
            {
                field: 'actionsData',
                title: t('field.actions'),
                formatter: this.segmentatorsActionsFormatter,
                style: {
                    width: '150px',
                },
            },
        ];
    }

    componentDidMount() {
        this.requestTrigger();
    }

    getTableOptions() {
        return {
            requestTrigger: this.requestTrigger,
            haveSearch: true,
            updateFilters: this.props.updateFilters,
            rowStyle: {
                height: '38px',
            },
        };
    }

    @autobind
    segmentatorSourceTypeFormatter({ color, name }) {
        const { t } = this.props;

        return name
            ? <Badge color={ color } className='badge-label'>{ name }</Badge>
            : <label>{ t('noSource') }</label>;
    }

    @autobind
    segmentatorsActionsFormatter(cell) {
        const { deleteSegmentator, showConfirm, showApplianceModal } = this.props;

        return <SegmentatorActionFormatter
            { ...cell }
            showApplianceModal={ showApplianceModal }
            requestTrigger={ this.requestTrigger }
            deleteSegmentator={ deleteSegmentator }
            showConfirm={ showConfirm }
        />;
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        this.pageRequests.cleanup();
        const request = this.props.getSegmentatorsList({ ...this.props.filters, ...filters });

        this.pageRequests.addRequest(request);
    }

    render() {
        const {
            filters,
            segmentatorsCount: total,
            tableData,
            loading,
            t,
        } = this.props;

        return (
            <DocumentTitle title={ t('recodedVariables') }>
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header' }>
                        <span className={ 'panel__header-title' }>
                            <FontAwesomeIcon icon='sitemap' />
                            &nbsp;
                            { t('recodedVariables') }
                        </span>
                    </CardHeader>
                    <CardBody>
                        <VocTableV2
                            dataSettings={ this.getSettings() }
                            total={ total }
                            data={ tableData }
                            filters={ filters }
                            loading={ loading }
                            options={ this.getTableOptions() }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}

Segmentators.propTypes = {
    filters: PropTypes.object,
    loading: PropTypes.bool,
};
