import { FEEDBACK } from './api';
import { PageRequests, DownloadFileService } from '/services';

const pageRequests = new PageRequests();

export const API_PREFIX = 'api/rest';

export class Http {
    static callAPIMatix = {
        'multipart/form-data': Http.bodyToFormData,
        'application/json': Http.bodyJSONStringify,
    };

    static bodyJSONStringify(body) {
        return JSON.stringify(body);
    }

    static bodyToFormData(body) {
        const formData = new FormData();

        for (const name in body) {
            formData.append(name, body[name]);
        }

        return formData;
    }

    static createRequestProxy(request, params) {
        let xhr;

        if (request.unique) {
            pageRequests.cleanupById(request.pathname);
        }

        const promise = new Promise((resolve, reject) => {
            xhr = Http.createRequest(resolve, reject, request);
            xhr.send(params);
        });

        const req = {
            promise,
            xhr,
        };

        if (request.unique) {
            pageRequests.addRequest(req, request.pathname);
        }

        return req;
    }

    static createRequest(resolve, reject, request) {
        const xhr = new XMLHttpRequest();

        xhr.open(request.method, Http._getEndPointByApiType(request.apiType, request.endpoint));

        if (request.responseType === 'blob') {
            xhr.responseType = 'blob';
        }

        Http._prepareHeaderByRequestParams(xhr, request);

        xhr.onload = () => {
            if(xhr.status >= 200 && xhr.status < 300) {
                resolve(Http.transformSuccessResponse(xhr));

                request.responseType === 'blob' && !request.notDownload
                && DownloadFileService.prepareAndDownloadFile(
                    { data: xhr.response, type: xhr.response.type, fileName: request.fileName },
                );
            } else {
                reject(Http.transformErrorResponse(xhr));
            }
        };

        xhr.onerror = () => reject(Http.transformErrorResponse(xhr));
        xhr.onabort = () => reject(Http.transformErrorResponse(xhr));

        return xhr;
    }

    static get(request) {
        const url = new URL(request.endpoint, window.location.origin);

        if (request.body) {
            Object.keys(request.body).forEach(key => {
                request.body[key] && url.searchParams.append(key, request.body[key]);
            });
        }
        request.pathname = url.pathname;
        request.endpoint = url.pathname + url.search;

        return Http.createRequestProxy(request);
    }

    static post(request) {
        const url = new URL(request.endpoint, window.location.origin);
        const params = Http.callAPIMatix[request.contentType](request.body);

        request.pathname = url.pathname;
        return Http.createRequestProxy(request, params);
    }

    static put(request) {
        const params = Http.callAPIMatix[request.contentType](request.body);

        return Http.createRequestProxy(request, params);
    }

    static patch(request) {
        const params = Http.callAPIMatix[request.contentType](request.body);

        return Http.createRequestProxy(request, params);
    }

    static delete(request) {
        const params = Http.callAPIMatix[request.contentType](request.body);

        return Http.createRequestProxy(request, params);
    }

    static _getEndPointByApiType(apiType, target) {
        const apiUrl = window.localStorage.getItem('feedbackApi') || '';

        switch (apiType) {
            case FEEDBACK: {
                return target.search('/assets/') >= 0 ? apiUrl + 'assets/' : apiUrl + API_PREFIX + target;
            }

            default: {
                return window.location.origin + target;
            }
        }
    }

    static _prepareHeaderByRequestParams(xhr, request) {
        const token = window.localStorage.getItem('token') || '';
        const contentTypes = [ 'application/json', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ];

        if (contentTypes.includes(request.contentType)) {
            xhr.setRequestHeader('Content-Type', request.contentType);
        }

        if (request.apiType === FEEDBACK) {
            xhr.setRequestHeader('Authorization', `jwt ${token}`);
            xhr.setRequestHeader('Accept', `application/json, ${request.contentType === 'application/pdf' ? 'application/pdf, ' : ''} text/plain, */*`);
        }
    }

    static transformErrorResponse(xhr) {
        let { response } = xhr;

        if (Http.isJsonString(xhr.response)) {
            response = JSON.parse(xhr.response);
        }
        return {
            response,
            status: xhr.status,
        };
    }

    static transformSuccessResponse(xhr) {
        let { response } = xhr;

        if (Http.isJsonString(response)) {
            response = JSON.parse(response);
        }
        return response;
    }

    static isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
