import React, { Fragment, useState } from 'react';
import { VocModal } from '/components';
import { ExportText, ExportForTraining, ExportForVerification } from '../';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ExportModal = ({
    language,
    show,
    close,
    onClosed,
    onSubmit,
    exportType,
    getClassifierList,
    classifiers,
    queryId,
    filters,
    synonyms,
    stopWords,
    needRunForm,
    textCases,
}) => {
    const [ formData, setFormData ] = useState(null);

    const getModalBody = () => {
        switch (exportType.id) {
            case 'toText':
                const MAX_TEXT_CASES = 1000000;
                const disabledExportXlsx = needRunForm || textCases?.count > MAX_TEXT_CASES;

                return <ExportText
                    setFormData={ setFormData }
                    formData={ formData }
                    disabledExportXlsx={ disabledExportXlsx }
                />;
            case 'forTraining':
                return <ExportForTraining
                    setFormData={ setFormData }
                    getClassifierList={ getClassifierList }
                    classifiers={ classifiers }
                />;
            case 'toVerification':
                return <ExportForVerification
                    getClassifierList={ getClassifierList }
                    classifiers={ classifiers }
                    setFormData={ setFormData }
                />;

            default:
                return null;
        }
    };

    const handlerOnSubmit = () => {
        onSubmit({ filters, synonyms, stopWords, exportType: exportType.id, queryId, language, ...formData }).promise.then(toggle);
    };

    const getModalFooter = () => (
        <Fragment>
            <Button onClick={ toggle } outline color={ 'white' }>
                <FontAwesomeIcon icon="times"/>
                <span> Cancel</span>
            </Button>
            <Button
                onClick={ () => handlerOnSubmit() }
                disabled={ formData === null || classifiers.exportSending }
                color={ 'primary' }>
                <FontAwesomeIcon icon="check"/>
                <span> Export</span>
            </Button>
        </Fragment>
    );

    const cleanupModal = () => {
        onClosed();
        setFormData(null);
    };

    const toggle = () => close();

    return (
        <VocModal
            isOpen={ show }
            toggle={ toggle }
            modalClassName='modal-scrollable'
            header={ exportType.title }
            footer={ getModalFooter() }
            onClosed={ cleanupModal }
            size="ms"
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};
