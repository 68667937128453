// import Dexie from 'dexie';

export class DatabaseService {
    // eslint-disable-next-line no-unused-vars
    constructor(dbName, schema) {
        // this.db = new Dexie(dbName, { autoOpen: true });
        // this.db.version(1).stores(schema);
        //don't forget to add versio on change DB when code of db will be on prod
    }
}
