import { CacheService } from '/services/CacheService';
import { CALL_API } from './api';
import sha1 from 'sha1';

// eslint-disable-next-line no-unused-vars
export default store => next => action => {
    const request = action[CALL_API];

    //todo - make Cache Sercive work
    return next(action);

    // eslint-disable-next-line no-unreachable
    const hasGetFromCacheEvent = request && request.cache && request.cache.get;

    if (!hasGetFromCacheEvent) {
        return next(action);
    }

    const getCacheType = request.types[3];
    const { body, endpoint, cache } = request;
    const { namespaceName, collectionAccsessor } = cache;

    if (!getCacheType) {
        throw new Error(`Api call has 'cache' object but has no cache action type`, request);
    }

    const hash = sha1(JSON.stringify({ body, endpoint }));
    const responce = CacheService.getCache(namespaceName, hash, collectionAccsessor);

    return responce.then(data => {
        return data === null
            ? next(action)
            : next({
                type: getCacheType,
                response: data,
            });
    });
};
