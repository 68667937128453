export * from './rightsModules/rights.index';
import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { sockets } from '/services';
import { socketsListeners } from '/scenes/VocSocketListereners';

const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';

const GET_WEB_UI_INFO_REQUEST = 'GET_WEB_UI_INFO_REQUEST';
const GET_WEB_UI_INFO_SUCCESS = 'GET_WEB_UI_INFO_SUCCESS';
const GET_WEB_UI_INFO_ERROR = 'GET_WEB_UI_INFO_ERROR';

const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
const UPDATE_SETTINGS_ERROR = 'UPDATE_SETTINGS_ERROR';

export const GET_SOURCE_BY_ID_REQUEST = 'GET_SOURCE_BY_ID_REQUEST';
export const GET_SOURCE_BY_ID_SUCCESS = 'GET_SOURCE_BY_ID_SUCCESS';
export const GET_SOURCE_BY_ID_ERROR = 'GET_SOURCE_BY_ID_ERROR';

const GET_RUNNER_URL_REQUEST = 'GET_RUNNER_URL_REQUEST';
const GET_RUNNER_URL_SUCCESS = 'GET_RUNNER_URL_SUCCESS';
const GET_RUNNER_URL_ERROR = 'GET_RUNNER_URL_ERROR';

export const INVALIDATE_CACHE = 'INVALIDATE_CACHE';
export const SET_CACHE = 'SET_CACHE';

function getInitialState() {
    return {
        loading: false,
        userInfo: {},
        webUiInfo: {},
        endpoints: {
            ENDPOINT_VOC_STORE_GET_SEGMENTATORS: '/public_api/store/segmentator/',
            ENDPOINT_VOC_STORE_CREATE_SEGMENTATOR: '/public_api/store/segmentator/',
            ENDPOINT_VOC_STORE_UPDATE_SEGMENTATOR: '/public_api/store/segmentator/appliance/{id}',
            ENDPOINT_VOC_STORE_DELETE_SEGMENTATOR: '/public_api/store/segmentator/{id}',
            ENDPOINT_GET_SOURCE_BY_ID: '/public_api/store/source/{id}?is_recode_variables=1',
            ENDPOINT_VOC_STORE_GET_UPLOADS: '/public_api/store/upload/list',
            ENDPOINT_VOC_STORE_GET_VIRTUAL_SOURCES: '/public_api/store/virtual-source/',
            ENDPOINT_SETTINGS_PAGE: '/settings',
            ENDPOINT_ABOUT_PAGE: '/about',
            ENDPOINT_HOME_PAGE: '/',
        },
        columnTypesList: [
            'TEXT',
            'LANG',
            'DATE',
            'NPS',
            'NPS_SEGMENT',
            'NUMERIC',
            'META',
            'VOICE',
            'CLASSIFICATION',
            'CHOICE',
            'TOPIC',
            'TOPIC_SENTIMENT',
            'TOPIC_CATEGORY',
        ],
        runnerUrl: '',
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_USER_INFO_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_USER_INFO_SUCCESS: {
            localStorage.setItem('token', action.response.JWT);
            localStorage.setItem('feedbackApi', action.response.feedbackApiUrl);

            const {
                notificationJWT,
                socketNotificationHost,
            } = action.response;

            sockets
                .setToken(notificationJWT)
                .setSocketUrl(socketNotificationHost)
                .init(socketsListeners);

            return {
                ...state,
                loading: false,
                userInfo: action.response,
            };
        }

        case GET_USER_INFO_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }

        case GET_WEB_UI_INFO_SUCCESS: {
            return {
                ...state,
                webUiInfo: action.response,
            };
        }

        case GET_WEB_UI_INFO_ERROR: {
            console.error(action.error);

            return {
                ...state,
                error: action.error,
            };
        }

        case UPDATE_SETTINGS_SUCCESS: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    configuration: {
                        ...action.data,
                    },
                },
            };
        }

        case GET_RUNNER_URL_SUCCESS: {
            return {
                ...state,
                runnerUrl: action.response?.DIGI_RUNNER_URL || '',
            };
        }

        default:
            return state;
    }
}

export function getUserInfo() {
    return {
        [CALL_API]: {
            types: [ GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, GET_USER_INFO_ERROR ],
            endpoint: '/user_info',
            method: 'GET',
            contentType: 'application/json',
            params: [],
        },
    };
}

export function getWebUiInfo() {
    return {
        [CALL_API]: {
            types: [ GET_WEB_UI_INFO_REQUEST, GET_WEB_UI_INFO_SUCCESS, GET_WEB_UI_INFO_ERROR ],
            endpoint: '/static/version.json',
            method: 'GET',
            contentType: 'application/json',
            params: [],
        },
    };
}

export function updateUserSettings(formData) {
    return {
        [CALL_API]: {
            types: [ UPDATE_SETTINGS_REQUEST, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_ERROR ],
            endpoint: '/public_api/core/configuration/',
            method: 'POST',
            body: formData,
            logger: true,
            contentType: 'application/json',
        },
    };
}

export function getSourceById({ id, body }) {
    return {
        [CALL_API]: {
            types: [ GET_SOURCE_BY_ID_REQUEST, GET_SOURCE_BY_ID_SUCCESS, GET_SOURCE_BY_ID_ERROR ],
            urlParams: { id },
            endpoint: 'ENDPOINT_GET_SOURCE_BY_ID',
            method: 'GET',
            logger:{
                error: true,
            },
            contentType: 'application/json',
            body,
        },
    };
}

export function invalidateCache(namespaceName) {
    return {
        type: INVALIDATE_CACHE,
        namespaceName,
    };
}

export function setCache(data) {
    return {
        type: SET_CACHE,
        data,
    };
}

export function getRunnerUrl() {
    return {
        [CALL_API]: {
            endpoint: '/digi-runner-url/',
            types: [ GET_RUNNER_URL_REQUEST, GET_RUNNER_URL_SUCCESS, GET_RUNNER_URL_ERROR ],
            apiType: FEEDBACK,
            method: 'GET',
        },
    };
}
