import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { FileManager } from '/feedback/components';
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// A theme is also required
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/fullpage';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/save';
import 'tinymce/plugins/table';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/template';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/quickbars';

import { Editor } from '@tinymce/tinymce-react';
import './TinyEditor.scss';
import PropTypes from 'prop-types';
// textpattern plugin crashed
const EditorPlugins = [
    'advlist autolink lists link image charmap hr anchor pagebreak fullpage',
    'searchreplace wordcount visualblocks visualchars',
    'insertdatetime media nonbreaking save table directionality',
    'template paste quickbars imagetools',
];
const EditorMenuBar = [ 'edit', 'view', 'insert', 'format', 'table' ];
const EditorFirstToolBar = 'undo redo | styleselect | formatselect fontselect fontsizeselect ';
const EditorSecondToolBar = `bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | forecolor | backcolor | link image`;

export class TinyEditor extends Component {
    state = {
        isManagerOpen: false,
        callback: () => {
        },
    };

    @autobind
    toggleManager() {
        this.setState({
            isManagerOpen: false,
        });
    }

    @autobind
    onChange(event) {
        this.props.onChange(event);
    }

    render() {
        const {
            assets,
            getAssets,
            uploadAsset,
            value,
        } = this.props;

        return (
            <>
                <Editor
                    value={ value }
                    onEditorChange={ this.onChange }
                    init={{
                        selector: "textarea",
                        plugins: EditorPlugins,
                        skin: false,
                        content_css: false,
                        menubar: EditorMenuBar,
                        valid_children: '+body[style]',
                        extended_valid_elements: 'script[type|src],style',
                        entity_encoding: 'raw',
                        force_br_newlines: false,
                        force_p_newlines: false,
                        forced_root_block: '',
                        allow_conditional_comments: true,
                        verify_html: false,
                        toolbar1: EditorFirstToolBar,
                        toolbar2: EditorSecondToolBar,
                        image_advtab: true,
                        file_picker_callback: callback => {
                            this.setState({
                                isManagerOpen: true,
                                callback,
                            });
                            getAssets();
                        },
                        quickbars_insert_toolbar: 'quickimage quicktable',
                        height: 340,
                    }}
                />
                <FileManager
                    toggle={ this.toggleManager }
                    isOpen={ this.state.isManagerOpen }
                    fillEditorValue={ this.state.callback }
                    assets={ assets }
                    uploadAsset={ uploadAsset }
                    getAssets={ getAssets }
                />
            </>
        );
    }
}

TinyEditor.propTypes = {
    assets: PropTypes.shape({
        loading: PropTypes.bool,
        files: PropTypes.array,
    }),
    getAssets: PropTypes.func,
    uploadAsset: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func,
};
