import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import { Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'redux-form';

import { HelperService } from '/services';
import { DragHandle } from '/feedback/components';
import { VocFormTextField, VocFormCheckbox } from '/components';

import './AnswerItem.scss';

class AnswerItem extends PureComponent {
    render() {
        const {
            item,
            language,
            onDeleteAnswerCLick,
            id,
            channel,
            htmlEditorCallback,
            showEditor,
            isUnsubscribe,
            answer,
            isOneUnchecked,
            viewConfig: { checked, move_button, value, name, remove_button },
        } = this.props;
        const isDigi = channel === 'DIGI';
        const isIVR = channel === 'IVR';
        const answerName = `${ item }.names.${ language }.name`;
        const checkUnsubscribe = () => isUnsubscribe ? 6 : 7;

        return (
            <Row className='configurable-answers-item'>
                {
                    checked.show
                        ? <div className='configurable-answers-item_unsubscribe_checkbox'>
                            <Field
                                disabled={ isOneUnchecked && answer.checked }
                                passChecked
                                component={ VocFormCheckbox }
                                name={ `${ item }.checked` }
                            />
                        </div>
                        : <Col
                            className='configurable-answers-item__drag-icon configurable-answers-item__drag-icon-narrow'
                            lg={ 1 }
                        >
                            {
                                move_button.show
                                    ? <DragHandle/>
                                    : null
                            }
                        </Col>
                }
                <Col lg={ isUnsubscribe ? 5 : 3 }>
                    <Field
                        name={ `${ item }.value` }
                        type='text'
                        placeholder='Value'
                        className='question__answer-input'
                        component={ VocFormTextField }
                        normalize={ HelperService.normalizeTextValue }
                        disabled={ value.disabled }
                        required
                    />
                </Col>
                <Col
                    lg={
                        isDigi || isIVR
                            ? checkUnsubscribe()
                            : 5
                    }
                    className='answer-name-input'
                >
                    <Field
                        name={ answerName }
                        type='text'
                        placeholder='Name'
                        required
                        component={ VocFormTextField }
                        normalize={ HelperService.normalizeTextValue }
                        editorCallback={ value => htmlEditorCallback({
                            value,
                            name: answerName,
                        }) }
                        showEditor={ showEditor }
                        disabled={ name.disabled }
                    />
                </Col>
                {
                    !isDigi && !isIVR
                    && <Col lg={ 2 }>
                        <Field
                            name={ `${ item }.is_open` }
                            required
                            label='Open'
                            type='checkbox'
                            normalize={ value => value ? '1' : '0' }
                            format={ value => value === '1' }
                            className='question__answer-checkbox'
                            component={ VocFormCheckbox }
                        />
                    </Col>
                }
                {
                    remove_button.show
                    && <Col lg={ 1 }>
                        <Button
                            disabled={ remove_button.disabled }
                            color='white'
                            data-id={ id }
                            outline
                            onClick={ onDeleteAnswerCLick }
                        >
                            <FontAwesomeIcon className='question__answer-icon' icon='trash-alt' />
                        </Button>
                    </Col>
                }
            </Row>
        );
    }
}

AnswerItem.propTypes = {
    item: PropTypes.string,
    htmlEditorCallback: PropTypes.func,
    showEditor: PropTypes.bool,
};

export const SortableAnswerItem = SortableElement(AnswerItem);

