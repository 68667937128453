import { createSelector } from "reselect";
import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { HelperService } from '/services';

const _getQuestionnaire = state => state.VocFeedback.common.states.questionnaire;
const _getSurveyCustomers = state => state.VocFeedback.survey.surveyCustomers;
const _getSubState = state => state.VocFeedback.common.subState;

export const selectedListSelector = state => _getSurveyCustomers(state).selected;

export const getCustomersList = createSelector(
    [ _getSurveyCustomers, _getQuestionnaire, selectedListSelector, _getSubState ],
    (customers, questionnaire, selected, { results }) => {
        return customers.results.map(customer => {
            const address = customer.details.length ? customer.details.find(el => el.name === "MSISDN") : '';
            const state = questionnaire.find(el => el.id === customer.state);

            let substate = '';

            if(customer.substate && results.length) {
                substate = results.find( el => el.id === customer.substate )?.name || '';
            }

            return {
                checkbox: {
                    item: customer.id,
                    selected: Boolean(selected.find(id => id === customer.id)),
                },
                id: customer.id,
                import_id: customer.import_id,
                address: address ? address.value : '',
                externalId: HelperService.deepFind(customer, 'customer.external_id'),
                language__iso_6391_code: customer.language.iso_6391_code,
                state: state ? state.name : `undefined status ${customer.state}`,
                creation: customer.creation,
                substate,
                start_time: customer.start_time,
                end_time: customer.end_time,
                link: {
                    id: customer.id,
                    creation: customer.creation,
                    hash: customer.hash,
                },
            };
        },
        );
    });

export const questionnaireStatesSelector = createSelector([ _getQuestionnaire ], questionnaireStates => {
    return [
        {
            label: 'All',
            value: '',
        },
        ...questionnaireStates
            // Removed extracted state from list because it's unused and delete in progress
            .filter(state => ![ 3, 37 ].includes(state.id))
            .map(questionnaireState => ({
                value: questionnaireState.id,
                label: questionnaireState.name,
            })),
    ];
});

export const questionnaireSubStatesSelector = createSelector([ _getSubState ], questionnaireSubStates => {
    return [
        {
            label: 'All',
            value: '',
        },
        ...questionnaireSubStates?.results
            .map(questionnaireState => ({
                value: questionnaireState.id,
                label: questionnaireState.name,
            })),
    ];
});

export const getTableState = createSelector(
    [ _getSurveyCustomers ], ({ loading, filters, count }) => ({ loading, filters, count }),
);

export const getSurveyParam = createSelector(
    [ activeSurveySelector ], ({ survey_group, channel }) => channel ? { id: survey_group, activeChannel: channel } : {},
);
