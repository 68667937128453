import React from 'react';
import { Label, Col, Button, ButtonGroup, FormGroup } from 'reactstrap';

import './ColumnButtonGroup.scss';

export const ColumnButtonGroup = ({
    column,
    onColumnTypeSelect,
    columnTypes,
}) => {
    const onButtonClick = event => {
        const { type } = event.target.dataset;
        const unsetFlag = column.type === type;

        onColumnTypeSelect(column.id, type, unsetFlag);
    };

    const disableButtonHandler = (type, column) =>
        column.disabledHeaders.some(({ id, disabled }) =>
            id === type && disabled);

    const mapButtonTypes = () => {
        return columnTypes.map((type, i) => {
            const color = column.type === type ? 'primary' : 'white';

            return (
                <Button color={ color }
                    outline={ column.type !== type }
                    className="column-btn-type_button"
                    data-type={ type }
                    disabled={ disableButtonHandler(type, column) }
                    key={ i }
                    onClick={ onButtonClick }
                >
                    { type }
                </Button>
            );
        });
    };

    return (
        <FormGroup row className="col-column-btn-group_form-group">
            <Col md={ 12 } className="col-column-btn-group">
                <Label className="label__column-title"> { column.id } </Label>
                <ButtonGroup className="column-buttons-group">
                    { mapButtonTypes() }
                </ButtonGroup>
            </Col>
        </FormGroup>
    );
};

