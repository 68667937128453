import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Label, Row, Container } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { HelperService, usePreviousProps } from '/services';
import { exporterMapData } from '../../modules/exporter.selectors';
import { FieldsTableRender } from '../';
import { VocFormTextField, VocFormSelectField, VocFormSwitchControl } from '/components';
import { exporterValidation } from './ExporterFormValidation';

import './ExporterForm.scss';

export const Form = ({
    goBack,
    languages,
    formValues,
    array,
    initialValues,
    submitExporter,
    handleSubmit,
    invalid,
    pristine,
    submitting,
    change,
    isCopyMode,
    typeOptions,
    dateFormatOptions,
    timeFormatOptions,
}) => {
    const isExportTypeDefault = formValues.export_type === 'default';
    const isExportTypeCustom = formValues.export_type === 'custom';
    const isExportTypeBlacklist = formValues.export_type === 'blacklist';
    const isExportTypeLegacyDefault = formValues.export_type === 'emulate_legacy_default';

    const filterQuestionnairesMap = exporterMapData.filterQuestionnairesMap.filter(filterQuestionnaire =>
        isExportTypeLegacyDefault
            ? filterQuestionnaire.value !== 'Invitation date'
            : true,
    );

    const fileTypeMap = exporterMapData.fileTypeMap.filter(fileType =>
        isExportTypeLegacyDefault
            ? fileType.value === 'csv' || fileType.value === 'xls'
            : true,
    );

    const filterStatusMap = exporterMapData.statusMap.filter(statusData =>
        isExportTypeLegacyDefault
            ? statusData.value !== 'Blacklisted' && statusData.value !== 'Quarantined'
            : true,
    );

    const timeFormatOptionsMap = timeFormatOptions.filter(timeFormat =>
        formValues.date_format !== 'Y-m-d'
            ? timeFormat.value !== 'TH:i:sP'
            : true,
    );

    const addFields = nameFields => {
        array.unshift(nameFields, {});
    };

    const onSortEnd = ({ oldIndex, newIndex }, nameFields) => {
        array.move(nameFields, oldIndex, newIndex);
    };

    const prevValueExportType = usePreviousProps(formValues.export_type);
    const valueIsCSV = formValues.output_type === 'csv';
    const valueIsQuestionText = formValues.question_column_header === 'question_text';
    const customExportTypeValidationError = formValues.export_type === 'custom' && !formValues.custom_columns?.length;

    const updateFieldsByExportType = exportType => {
        if (exportType === 'custom') {
            change('question_column_header', 'question_title');
            change('language', null);
            change('with_classifications', true);
            change('with_recoders', true);
            change('with_tags', true);
            change('with_nps_segments', true);
            !formValues?.custom_columns?.length && addFields('custom_columns');
        } else if (prevValueExportType === 'custom' || exportType === 'blacklist') {
            if (exportType === 'blacklist') {
                change('language', null);
                change('filter_by_states', []);
                change('only_with_answers', false);
                change('include_case_alert_column', false);
                change('include_invitation_channel_column', false);
                change('with_classifications', false);
                change('with_lemmas', false);
                change('with_recoders', false);
                change('with_nps_segments', false);
                change('with_tags', false);
                change('date_format', false);
                change('time_format', false);
                change('export_conditions', []);
            }

            change('question_column_header', null);
            change('custom_columns', []);
        }

        if (exportType === 'default') {
            change('with_classifications', true);
            change('with_recoders', true);
            change('with_tags', true);
            change('with_nps_segments', true);
            change('include_case_alert_column', true);
            change('include_invitation_channel_column', true);
            change('include_error_message_column', false);
            change('with_topic_set', false);
            change('with_sentiment', false);
        }

        if (exportType === 'emulate_legacy_default') {
            change('with_classifications', false);
            change('with_lemmas', false);
            change('with_tags', false);
            change('with_recoders', false);
            change('with_nps_segments', false);
            change('remove_delimiter_from_value', false);
            change('only_with_answers', false);
            change('include_invitation_channel_column', false);
            change('include_case_alert_column', false);

            formValues.filter_by_states
            && change(
                'filter_by_states',
                formValues.filter_by_states.filter(state => state !== 'Blacklisted' && state !== 'Quarantined'),
            );

            if(formValues.output_type !== 'csv' && formValues.output_type !== 'xls') {
                change('output_type', null);
            }

            if(formValues.filter_by_date === 'Invitation date') {
                change('filter_by_date', null);
            }
        }
    };

    const onChangeExportTypeSelect = ({ value }) => {
        updateFieldsByExportType(value);
    };

    useEffect(() => {
        isCopyMode && change('name', null);
    }, [ initialValues.question_column_header ]);

    useEffect(() => {
        if(formValues.date_format !== 'Y-m-d' && formValues.time_format === 'TH:i:sP') {
            change('time_format', null);
        }
    }, [ formValues.date_format ]);

    const getCheckboxGroup = () => {
        return (
            <Col md={ 6 }>
                <Row>
                    <Col>
                        <h5 className={ 'export_form-checkbox_group' }>
                            Enrich exports with additional columns from VoC Store:
                        </h5>
                    </Col>
                </Row>

                <Row>
                    <Col md={ 6 }>
                        <Field
                            className={ 'exporter-switch-control' }
                            component={ VocFormSwitchControl }
                            name={ 'with_classifications' }
                            withError={ false }
                            disabled={ isExportTypeBlacklist || isExportTypeLegacyDefault }
                        />
                        <Label htmlFor={ 'with_classifications' }>
                            Classification results
                        </Label>
                    </Col>

                    <Col md={ 6 }>
                        <Field
                            className={ 'exporter-switch-control' }
                            component={ VocFormSwitchControl }
                            name={ 'with_nps_segments' }
                            withError={ false }
                            disabled={ isExportTypeBlacklist || isExportTypeLegacyDefault }
                        />
                        <Label htmlFor={ 'with_nps_segments' }>
                            NPS Columns
                        </Label>
                    </Col>

                </Row>

                <Row>
                    <Col md={ 6 }>
                        <Field
                            className={ 'exporter-switch-control' }
                            component={ VocFormSwitchControl }
                            name={ 'with_lemmas' }
                            withError={ false }
                            disabled={ isExportTypeBlacklist || isExportTypeLegacyDefault }
                        />
                        <Label htmlFor={ 'with_lemmas' }>
                            Lemmas
                        </Label>
                    </Col>

                    {
                        !isExportTypeCustom
                            ? <Col md={ 6 }>
                                <Field
                                    className={ 'exporter-switch-control' }
                                    component={ VocFormSwitchControl }
                                    name={ 'include_invitation_channel_column' }
                                    withError={ false }
                                    disabled={ isExportTypeBlacklist || isExportTypeLegacyDefault }
                                />
                                <Label htmlFor={ 'include_invitation_channel_column' }>
                                    Invitation Channel
                                </Label>
                            </Col>
                            : null
                    }
                </Row>

                <Row>
                    <Col md={ 6 }>
                        <Field
                            className={ 'exporter-switch-control' }
                            component={ VocFormSwitchControl }
                            name={ 'with_recoders' }
                            withError={ false }
                            disabled={ isExportTypeBlacklist || isExportTypeLegacyDefault }
                        />
                        <Label htmlFor={ 'with_recoders' }>
                            Recoded Variables
                        </Label>
                    </Col>

                    {
                        !isExportTypeCustom
                            ? <Col md={ 6 }>
                                <Field
                                    className={ 'exporter-switch-control' }
                                    component={ VocFormSwitchControl }
                                    name={ 'include_case_alert_column' }
                                    withError={ false }
                                    disabled={ isExportTypeBlacklist || isExportTypeLegacyDefault }
                                />
                                <Label htmlFor={ 'include_case_alert_column' }>
                                    Case Alert
                                </Label>
                            </Col>
                            : null
                    }
                </Row>

                <Row>
                    <Col md={ 6 }>
                        <Field
                            className='exporter-switch-control'
                            component={ VocFormSwitchControl }
                            name='with_tags'
                            withError={ false }
                            disabled={ isExportTypeBlacklist || isExportTypeLegacyDefault }
                        />
                        <Label htmlFor='with_tags'>
                            Tags/Query labels
                        </Label>
                    </Col>

                    {
                        isExportTypeDefault
                            ? <>
                                <Col md={ 6 }>
                                    <Field
                                        className='exporter-switch-control'
                                        component={ VocFormSwitchControl }
                                        name='with_topic_set'
                                        withError={ false }
                                    />
                                    <Label htmlFor='with_topic_set'>
                                        Topic AI Results
                                    </Label>
                                </Col>
                                <Col md={ 6 }>
                                    <Field
                                        className='exporter-switch-control'
                                        component={ VocFormSwitchControl }
                                        name='include_error_message_column'
                                        withError={ false }
                                    />
                                    <Label htmlFor='include_error_message_column'>
                                        Error message column
                                    </Label>
                                </Col>
                                <Col md={ 6 }>
                                    <Field
                                        className='exporter-switch-control'
                                        component={ VocFormSwitchControl }
                                        name='with_sentiment'
                                        withError={ false }
                                    />
                                    <Label htmlFor='with_sentiment'>
                                        Default sentiment analysis
                                    </Label>
                                </Col>
                            </>
                            : null
                    }
                </Row>
            </Col>
        );
    };

    return (
        <form onSubmit={ handleSubmit }>
            <div className={ 'exporter-form' }>
                <Container>
                    <Row>
                        <Col md={ 6 }>
                            <Label htmlFor={ 'name' }>
                                Title
                                <span className='star'> *</span>
                            </Label>
                            <Field
                                type={ 'text' }
                                placeholder={ 'Enter name' }
                                name={ 'name' }
                                component={ VocFormTextField }
                                required
                            />
                        </Col>
                        <Col md={ 6 }>
                            <Label htmlFor={ 'output_type' }>
                                Export file type
                                <span className='star'> *</span>
                            </Label>
                            <Field
                                placeholder={ 'Choose export file type' }
                                name={ 'output_type' }
                                options={ fileTypeMap }
                                component={ VocFormSelectField }
                                normalize={ HelperService.normalizeSelectValue }
                                format={ value => HelperService.getSelectedValue(fileTypeMap, value) }
                                onChange={ () => {
                                    change('delimiter', null);
                                    change('remove_delimiter_from_value', false);
                                } }
                                required
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Label htmlFor={ 'export_type' }>
                                Export type
                                <span className='star'> *</span>
                            </Label>
                            <Field
                                placeholder={ 'Choose export type' }
                                name={ 'export_type' }
                                options={ exporterMapData.exportTypeMap }
                                component={ VocFormSelectField }
                                normalize={ HelperService.normalizeSelectValue }
                                format={ value => HelperService.getSelectedValue(exporterMapData.exportTypeMap, value) }
                                onChange={ onChangeExportTypeSelect }
                                required
                            />
                        </Col>

                        <Col md={ 6 }>

                            <Row>
                                <Col md={ 5 }>
                                    <Label htmlFor={ 'delimiter' }>
                                        Delimiter
                                        {
                                            valueIsCSV && <span className='star'> *</span>
                                        }
                                    </Label>
                                    <Field
                                        placeholder={ 'Choose delimiter' }
                                        className={ 'export_form-select_error' }
                                        name={ 'delimiter' }
                                        options={ exporterMapData.delimiterMap }
                                        component={ VocFormSelectField }
                                        normalize={ HelperService.normalizeSelectValue }
                                        format={ value => HelperService.getSelectedValue(exporterMapData.delimiterMap, value) }
                                        disabled={ !valueIsCSV }
                                        required={ valueIsCSV }
                                    />
                                </Col>
                                <Col md={ 7 } className={ 'remove_delimiter_from_value_container' }>
                                    <Label htmlFor={ 'remove_delimiter_from_value' }>
                                        Remove line breaks and delimiters from values
                                    </Label>

                                    <Field
                                        className={ 'exporter-switch-control__top-Label' }
                                        component={ VocFormSwitchControl }
                                        name={ 'remove_delimiter_from_value' }
                                        withError={ false }
                                        disabled={ !valueIsCSV || isExportTypeLegacyDefault }
                                    />
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row>
                        <Col md={ 6 }>
                            <Label htmlFor={ 'question_column_header' }>
                                Question column headers
                                <span className='star'> *</span>
                            </Label>
                            <Field
                                className={ 'exporter_form-question_column_header' }
                                placeholder={ 'Choose question column headers' }
                                name={ 'question_column_header' }
                                options={ exporterMapData.columnHeadersMap }
                                component={ VocFormSelectField }
                                normalize={ HelperService.normalizeSelectValue }
                                format={ value => {
                                    const { columnHeadersCustom, columnHeadersMap } = exporterMapData;
                                    const list = isExportTypeCustom ? columnHeadersCustom : columnHeadersMap;

                                    return HelperService.getSelectedValue(list, value);
                                } }
                                onChange={ () => change('language', null) }
                                disabled={ isExportTypeCustom || isExportTypeBlacklist }
                                required
                            />
                        </Col>

                        <Col md={ 6 }>
                            <Label htmlFor={ 'language' }>
                                Language
                                {
                                    valueIsQuestionText && <span className='star'> *</span>
                                }
                            </Label>
                            <Field
                                placeholder={ 'Choose language' }
                                className={ 'export_form-select_error' }
                                name={ 'language' }
                                options={ languages }
                                component={ VocFormSelectField }
                                normalize={ HelperService.normalizeSelectValue }
                                format={ value => HelperService.getSelectedValue(languages, value) }
                                disabled={ !valueIsQuestionText }
                                required={ valueIsQuestionText }
                            />
                        </Col>
                    </Row>

                    <Row className={ 'exporter_form-middle_block' }>
                        <Col md={ 6 }>
                            <Row>
                                <Col>
                                    <Label htmlFor={ 'filter_by_states' }>
                                        Status ( Questionnaires states to be exported )
                                        <span className='star'> *</span>
                                    </Label>
                                    <Field
                                        className={ 'edit-export__select' }
                                        placeholder={ 'Choose status' }
                                        name={ 'filter_by_states' }
                                        options={ filterStatusMap }
                                        component={ VocFormSelectField }
                                        normalize={ HelperService.normalizeMultiSelectValue }
                                        format={ value => HelperService.getSelectedValue(exporterMapData.statusMap, value) }
                                        disabled={ isExportTypeBlacklist }
                                        isMulti
                                        required
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Label htmlFor={ 'filter_by_date' }>
                                        Filter questionnaires by date considering their
                                        {
                                            isExportTypeLegacyDefault && <span className='star'> *</span>
                                        }
                                    </Label>
                                    <Field
                                        className={ 'edit-export__select' }
                                        name={ 'filter_by_date' }
                                        placeholder={ 'Choose Date filter' }
                                        options={ filterQuestionnairesMap }
                                        component={ VocFormSelectField }
                                        format={ value => HelperService.getSelectedValue(filterQuestionnairesMap, value) }
                                        normalize={ HelperService.normalizeSelectValue }
                                        disabled={ isExportTypeBlacklist }
                                        required={ isExportTypeLegacyDefault }
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={ 6 }>
                                    <Label htmlFor={ 'date_format' }>
                                        Date format
                                    </Label>
                                    <Field
                                        className={ 'edit-export__select' }
                                        name={ 'date_format' }
                                        placeholder={ 'Date format' }
                                        options={ dateFormatOptions }
                                        component={ VocFormSelectField }
                                        format={ value => HelperService.getSelectedValue(dateFormatOptions, value) }
                                        normalize={ HelperService.normalizeSelectValue }
                                        disabled={ isExportTypeBlacklist }
                                        required
                                        isClearable
                                    />
                                </Col>
                                <Col md={ 6 }>
                                    <Label htmlFor={ 'time_format' }>
                                        Time format
                                    </Label>
                                    <Field
                                        className={ 'edit-export__select' }
                                        name={ 'time_format' }
                                        placeholder={ 'Time format' }
                                        options={ timeFormatOptionsMap }
                                        component={ VocFormSelectField }
                                        format={ value => HelperService.getSelectedValue(timeFormatOptionsMap, value) }
                                        normalize={ HelperService.normalizeSelectValue }
                                        disabled={ isExportTypeBlacklist }
                                        required
                                        isClearable
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Field
                                        className={ 'exporter-switch-control' }
                                        component={ VocFormSwitchControl }
                                        name={ 'only_with_answers' }
                                        disabled={ isExportTypeBlacklist || isExportTypeLegacyDefault }
                                        withError={ false }
                                    />
                                    <Label htmlFor={ 'only_with_answers' }>
                                            Only with responses
                                    </Label>
                                </Col>
                            </Row>
                        </Col>

                        {
                            getCheckboxGroup()
                        }
                    </Row>

                    {
                        formValues.export_type === 'custom'
                            ? <FieldsTableRender
                                fields={ [ 'Title', 'Type', 'Column' ] }
                                addFields={ addFields }
                                onSortEnd={ onSortEnd }
                                nameButton={ 'setting' }
                                nameFields={ "custom_columns" }
                                change={ change }
                                lengthRow={ formValues?.custom_columns?.length }
                                typeOptions={ typeOptions }
                                disabled={ isExportTypeBlacklist }
                            />
                            : null
                    }

                    {
                        <FieldsTableRender
                            fields={ [ 'Question external id', 'Condition', 'Value' ] }
                            addFields={ addFields }
                            onSortEnd={ onSortEnd }
                            nameButton={ 'condition' }
                            nameFields={ "export_conditions" }
                            change={ change }
                            lengthRow={ formValues?.export_conditions?.length }
                            disabled={ isExportTypeBlacklist }
                        />
                    }

                </Container>

            </div>
            <div className='exporter-form-action-buttons'>
                <Button color={ 'white' } onClick={ goBack } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button
                    onClick={ handleSubmit(values => submitExporter(values)) }
                    disabled={ pristine || submitting || invalid || customExportTypeValidationError }
                    color={ 'primary' }
                >
                    <FontAwesomeIcon icon="check"/>
                    <span> Save</span>
                </Button>
            </div>
        </form>
    );
};

export const ExporterForm = reduxForm({
    form: 'ExporterForm',
    enableReinitialize: true,
    validate: exporterValidation,
})(Form);
