import { SubmissionError } from 'redux-form';
import uuidV4 from 'uuid/v4';

import { HelperService } from '/services';
import { GatewaysSettingsService } from './';
import librarySettings from './librarySettings.json';

export class SettingsService {
    static digiPath = null;
    static matchedLibrarySettings = [];
    static defaultSelectSettings = {
        options: [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ],
        type: 'select',
    };

    static composeGlobalSettings(globalSettings) {
        return Object.keys(globalSettings).reduce((acc, setting) => {
            let channelValue;

            let gatewaysChainModel = {};

            if (setting === 'GATEWAYS_CHAIN') {
                channelValue = globalSettings[setting]
                    ? globalSettings[setting].map(gateway => gateway.name)
                    : [];

                gatewaysChainModel = GatewaysSettingsService.composeGatewaysGlobalSettings(
                    globalSettings,
                    setting,
                );
            } else {
                channelValue = HelperService.fromString(globalSettings[setting]);
            }

            acc[setting] = {
                channelValue: channelValue !== null ? channelValue : '',
                value: channelValue !== null ? channelValue : '',
                ...gatewaysChainModel,
                redefined: true,
                isGlobal: true,
            };

            return acc;
        }, {});
    }

    static composeSurveySettings(globalSettings, surveySettings) {
        return Object.keys(globalSettings).reduce((acc, setting) => {
            const checkValue = value => Array.isArray(value) ? !!value.length : value !== null;

            let channelValue;
            let surveyValue;

            let gatewaysChainModel = {};

            if (setting === 'GATEWAYS_CHAIN') {
                gatewaysChainModel = GatewaysSettingsService.composeGatewaysSurveySettings(
                    globalSettings,
                    surveySettings,
                    setting,
                    checkValue,
                );

                channelValue = globalSettings[setting]
                    ? globalSettings[setting].map(gateway => gateway.name)
                    : [];

                surveyValue = surveySettings[setting]
                    ? surveySettings[setting].map(gateway => gateway.name)
                    : [];
            } else {
                channelValue = HelperService.fromString(globalSettings[setting]);
                surveyValue = HelperService.fromString(surveySettings[setting]);
            }

            const value = checkValue(surveyValue)
                ? surveyValue
                : channelValue;

            acc[setting] = {
                channelValue: checkValue(channelValue) ? channelValue : '',
                surveyValue: checkValue(surveyValue) ? surveyValue : '',
                value: value !== null ? value : '',
                redefined: checkValue(surveySettings[setting]),
                ...gatewaysChainModel,
            };

            return acc;
        }, {});
    }

    static composeDigiUrlSettings(link, surveyId, language) {
        let digiUrlSettings = {
            errorMessage: 'Digi url is undefined',
            value: null,
        };

        if (link) {
            const result = /^http/.test(link);

            digiUrlSettings = {
                errorMessage: result ? '' : `Digi url is missing 'http/s'`,
                value: result
                    ? SettingsService.prepareDigiRunnerUrlValue({
                        link,
                        surveyId,
                        language,
                    })
                    : null,
            };
        }
        return digiUrlSettings;
    }

    static prepareDigiRunnerUrlValue({ link, surveyId, language }) {
        return `<script defer src="${link}/digi_runner.js?surveyId=${surveyId}&language=${String(language).toLowerCase()}"></script>`;
    }

    static injectDigiRunnerUrl(path) {
        const scriptName = 'digi_runner.js';
        const script = document.getElementById('digiRunnerUrl');

        SettingsService.digiPath = path;

        if (!script) {
            const runner = document.createElement('script');

            runner.setAttribute('id', 'digiRunnerUrl');
            runner.setAttribute('src', path + `/${scriptName}`);
            document.head.appendChild(runner);
        }
    }

    static removeDigiRunnerScript() {
        SettingsService.digiPath = null;

        const domElement = document.getElementById('digiRunnerUrl');
        const domElementApp = document.getElementById('VocSignalApp');

        domElement && domElement.remove();
        domElementApp && domElementApp.remove();
        if (window.VocSignal) {
            window.VocSignal.isInit = false;
        }
    }

    static showDigiPopup({ language, id, customApiPath, customerId }) {
        if ((customApiPath || SettingsService.digiPath) && window.VocSignal_ListenEvent) {
            window.VocSignal_ListenEvent('SetParametersCallback', function () {
                window.VocSignal_SendEvent('InitBySettings');
            });

            window.VocSignal_SendEvent('SetParameters', {
                apiUrl: customApiPath || SettingsService.digiPath,
                customerId: customerId || 'popup_preview_' + uuidV4(),
                language: language,
                surveyId: id,
            });
        }
    }

    static catchSettingsErrors({ response }) {
        const errors = SettingsService.prepareSurveySettingsErrors(response);

        throw new SubmissionError(errors);
    }

    static catchDIGIThemeErrors({ response }) {
        const errors = SettingsService.prepareSurveySettingsErrors({
            "digi_theme": typeof response === 'string'
                ? [ response ]
                : response.new_default_id,
        });

        throw new SubmissionError(errors);
    }

    static prepareSurveySettingsErrors(errors) {
        return Object.keys(errors).reduce((acc, errorKey) => {
            const error = errors[errorKey];

            acc[errorKey] = HelperService.isObjectValue(error)
                ? Object.keys(error).reduce((acc, id) => {
                    acc.message = error[id][0];
                    acc.id.push(id);
                    return acc;
                }, { message: '', id: [] })
                : error;
            return acc;
        }, {});
    }

    static matchingDictionaryByKey(fields, modelKeys) {
        return fields.map(field => ({
            ...field,
            children: field.children.filter(child => modelKeys.find(key => child.key === key)),
        }));
    }

    static getterLocalStorage(name, id) {
        let item = JSON.parse(localStorage.getItem(name));

        if (!!item) {
            item = Object.keys(item).length ? item : null;
        }

        return id && item ? item[id] : item;
    }

    static setterLocalStorage(name, obj) {
        const item = SettingsService.getterLocalStorage(name) || {};

        localStorage.setItem(name, JSON.stringify({
            ...item,
            ...obj,
        }));
    }

    static settingsLibrary() {
        if (!SettingsService.matchedLibrarySettings.length) {
            SettingsService.matchedLibrarySettings = librarySettings.map(setting => {
                return setting.options === true ? {
                    ...setting,
                    ...SettingsService.defaultSelectSettings,
                } : {
                    ...setting,
                };
            });
        }

        return SettingsService.matchedLibrarySettings;
    }

    static settingsModel = {
        languageSettingsDictionary: (isGlobal, isTheme) => ({
            WEB: [
                {
                    label: 'Invitation email',
                    children: [
                        ...!isGlobal ? [ 'SURVEY_STATIC_LINK' ] : [],
                        'MAIL_SENDER_NAME_LANGUAGE_SPECIFIC',
                        'MAIL_SENDER_ADDRESS_LANGUAGE_SPECIFIC',
                        'MAIL_EMAIL_SUBJECT',
                        'MAIL_EMAIL_TEXT_HTML',
                        'MAIL_EMAIL_TEXT_PLAIN',
                        'MAIL_URL_FORM_HTML',
                        'MAIL_URL_UNSUBSCRIBE_HTML',
                    ],
                },
                {
                    label: 'Reminder email',
                    children: [
                        'REMINDER_EMAIL_SUBJECT',
                        'REMINDER_EMAIL_TEXT_HTML',
                        'REMINDER_EMAIL_TEXT_PLAIN',
                    ],
                },
                {
                    label: 'Landing page',
                    children: [
                        'MAIL_FORM_EXPIRED',
                        'MAIL_FORM_THANKS',
                        'MAIL_FORM_THANKS_TERMINATED',
                        'MAIL_FORM_ALREADY_PASSED',
                        'MAIL_FORM_UNSUBSCRIBED',
                    ],
                },
                {
                    label: 'Survey template',
                    children: [
                        'MAIL_FORM_BACK_BUTTON',
                        'MAIL_FORM_SUBMIT',
                        'MAIL_FORM_TERMINATE_BUTTON',
                        ...isGlobal ? [ 'WEB_QUESTION_REQUIRED_MESSAGE' ] : [],
                    ],
                },
                {
                    label: 'Mobile template',
                    children: [
                        'MOBILE_SUBMIT_BUTTON_TEXT',
                        'MOBILE_TEMPLATE_TITLE',
                        'MOBILE_GENERIC_ERROR_TEXT',
                    ],
                },
            ],
            SMS: [
                {
                    label: 'Language Settings',
                    collapse: true,
                    disableCollapse: false,
                    children: [
                        'SMS_SURVEY_INVITE',
                        'SMS_INVALID_START_REPLY',
                        'SMS_SURVEY_FINISHED',
                        'SMS_STOP_REPLY',
                        'SMS_KEYWORD',
                        'SMS_SURVEY_NO_ACTIVE',
                        ...isGlobal ? [ 'SMS_QUESTION_REQUIRED_MESSAGE' ] : [],
                    ],
                },
            ],
            IVR: [
                {
                    label: 'Language Settings',
                    collapse: true,
                    disableCollapse: false,
                    children: [
                        'EXTENTION',
                    ],
                },
            ],
            DIGI: [
                {
                    label: 'Language Settings',
                    collapse: true,
                    disableCollapse: false,
                    children: [
                        ...isGlobal
                            ? [ 'DIGI_QUESTION_REQUIRED_MESSAGE' ]
                            : [
                                'DIGI_SURVEY_STATIC_LINK',
                                'digi_popup_title',
                                'digi_triggered_button_text',
                                'digi_custom_header_language',
                                'digi_custom_footer_language',
                                'digi_button_go_to_previous_text',
                                'DIGI_BUTTON_START_AGAIN_TEXT',
                                'DIGI_BUTTON_NEXT_QUESTION_TEXT',
                                'digi_button_finish_text',
                                'digi_thanks_message',
                                'digi_button_submit_text',
                                'digi_theme_css_custom_language',
                                'DIGI_RUNNER_URL',
                            ],
                    ],
                },
            ],
            ...{
                ...isTheme
                    ? {
                        themeDIGI: [
                            {
                                label: 'Language Settings',
                                collapse: true,
                                disableCollapse: false,
                                children: [
                                    'digi_triggered_button_text',
                                    'digi_button_submit_text',
                                    'digi_button_go_to_previous_text',
                                    'digi_custom_header_language',
                                    'digi_custom_footer_language',
                                    'digi_thanks_message',
                                    'digi_button_finish_text',
                                    'digi_popup_title',
                                    'digi_theme_css_custom_language',
                                ],
                            },
                        ],
                    }
                    : {},
            },
        }),
        commonSurveySettingsDictionary: isGlobal => [
            {
                label: 'Alert email',
                children: [
                    'ALERT_EMAIL_SUBJECT',
                    'ALERT_EMAIL_BODY',
                    'UNIT_EMAIL',
                    'CASE_ALERT_EMAIL_COLUMN',
                ],
            },
            {
                label: 'Whitelist',
                children: [
                    'MAIL_ADDRESS_WHITELISTED',
                    'BOUNCE_MAIL_ADDRESS',
                ],
            },
            {
                label: 'Common options',
                children: [
                    ...isGlobal
                        ? [
                            'QUARANTINE_PERIOD',
                            'QUARANTINE_CHECK_LOGIC',
                        ]
                        : '',
                    'SYSTEM_EXPIRY_TIME',
                    'REMIND_NUMBER',
                    'REMINDER_PERIOD',
                    'TIME_BETWEEN_REMINDERS',
                    'CASE_ALERT_PARTIAL_CHECK',
                    'DAILY_INVITATIONS_LIMIT',
                    'UNSUBSCRIBE_SURVEY_GROUP',
                ],
            },
            {
                label: 'VoC ACT',
                children: [
                    ...isGlobal ? [ 'VOCACT_CASEALERT_URL', 'VOCACT_CASEALERT_TOKEN' ] : '',
                    'VOCACT_CASEALERT_ENABLED',
                    'VOCACT_CASEALERT_TICKET_SUBJECT',
                    'VOCACT_CASEALERT_TICKET_BODY',
                    'VOCACT_CASEALERT_TICKET_FIELDS',
                ],
            },
            {
                label: 'CATI (computer assisted telephone interviewing)',
                children: [
                    ...isGlobal ? [ 'VOCACT_CATI_URL', 'VOCACT_CATI_TOKEN' ] : '',
                    'VOCACT_CATI_ENABLED',
                    'VOCACT_CATI_TICKET_SUBJECT',
                    'VOCACT_CATI_TICKET_BODY',
                    'VOCACT_CATI_TICKET_FIELDS',
                ],
            },
        ],
        surveySettingsDictionary: isGlobal => ({
            WEB: [
                {
                    label: 'Invitation email',
                    children: [
                        'MAIL_SENDER_NAME',
                        'MAIL_SENDER_ADDRESS',
                        'CC_ADDRESS_COLUMN_NAME',
                        'BCC_ADDRESS_COLUMN_NAME',
                        'MAIL_URL_FORM',
                        'MAIL_DIGI_URL_FORM',
                        'MAIL_URL_UNSUBSCRIBE',
                    ],
                },
                {
                    label: 'Templates options',
                    children: [
                        'SURVEY_LAYOUT_ID',
                        'ALLOW_MOBILE_LAYOUT',
                        'MOBILE_TEMPLATE_COLOR',
                        'MOBILE_BUTTONS_ON_TOP',
                        'SHOW_BACK_BUTTON',
                        'SHOW_TERMINATE_BUTTON',
                    ],
                },
            ],
            SMS: [
                {
                    label: 'Channel specific',
                    children: [
                        'SURVEY_WO_INVITATION',
                        ...!isGlobal ? [ 'SMS_SENDER_NAME_IMPORT_COLUMN' ] : '',
                        'GATEWAYS_CHAIN',
                    ],
                },
            ],
            IVR: [
                {
                    label: 'Channel specific',
                    children: [
                        'IVR_ROUTE',
                        'DTMF_TIMEOUT',
                    ],
                },
            ],
            DIGI: [
                {
                    label: 'DIGI pop-up triggers',
                    children: [
                        'DIGI_UNITE_TRIGGERS',
                        'DIGI_VISITS_COUNTER',
                        'DIGI_NUMBER_OF_PAGES_VISITED',
                        'DIGI_PROBABILITY_OF_APPEARANCE',
                        'DIGI_SESSION_TIMEOUT',
                        'DIGI_PAGE_TIMEOUT',
                        'DIGI_SHOW_LEAVE_POPUP',
                        'DIGI_ABANDONMENT_TRIGGER_DELAY',
                    ],
                },
                {
                    label: 'An excluding triggers',
                    children: [
                        'DIGI_DONT_SHOW_SURVEY_FOR_REFUSERS',
                        'DIGI_DONT_SHOW_SURVEY_FOR_REFUSERS_PERIOD',
                        'DIGI_QUIET_TIME',
                    ],
                },
                {
                    label: 'Channel specific',
                    children: [
                        'DIGI_TRIGGERED_BUTTON_ID',
                        'DIGI_SURVEY_IS_STEPBYSTEP',
                        ...!isGlobal
                            ? [
                                'DIGI_SHOW_NAVBAR',
                                'DIGI_SHOW_NAVBAR_TYPE',
                                'DIGI_NEXT_QUESTION_DELAY',
                                'DIGI_CHAT_MODE',
                            ]
                            : '',
                        'DIGI_ACTIVATE_BY_BUTTON',
                        'DIGI_SEND_INITIAL_URL',
                        'DIGI_TEST_MODE',
                        'DIGI_EMBEDDED_SCRIPTS',
                        'DIGI_ADDITIONAL_CODE',
                    ],
                },
                {
                    label: 'Theme settings',
                    children: [
                        'digi_theme',
                        ...!isGlobal
                            ? [
                                'digi_popup_window_width',
                                'digi_popup_window_height',
                                'digi_popup_font_family',
                                'digi_runner_font_size',
                                'digi_popup_indent',
                                'digi_popup_header_bg_color',
                                'digi_popup_header_font_color',
                                'digi_popup_button_bg_color',
                                'digi_popup_button_font_color',
                                'digi_popup_question_bg_color',
                                'digi_popup_question_font_color',
                                'digi_popup_bg_color',
                                'digi_custom_header',
                                'digi_custom_footer',
                                'digi_theme_css_custom',
                            ]
                            : '',
                    ],
                },
                {
                    label: 'Whitelist',
                    children: [
                        'DIGI_CUSTOMER_EXT_ID_WHITELISTED',
                    ],
                },
            ],
            ...{
                ...!isGlobal
                    ? {
                        themeDIGI: [
                            {
                                label: 'Theme settings',
                                children: [
                                    'digi_popup_window_width',
                                    'digi_popup_window_height',
                                    'digi_popup_font_family',
                                    'digi_runner_font_size',
                                    'digi_popup_indent',
                                    'digi_popup_header_bg_color',
                                    'digi_popup_header_font_color',
                                    'digi_popup_button_bg_color',
                                    'digi_popup_button_font_color',
                                    'digi_popup_question_bg_color',
                                    'digi_popup_question_font_color',
                                    'digi_popup_bg_color',
                                    'digi_custom_header',
                                    'digi_custom_footer',
                                    'digi_theme_css_custom',
                                ],
                            },
                        ],
                    }
                    : {},
            },
        }),
        createThemeLangSettingsForFilter: {
            themeLangSettings: [
                'digi_button_go_to_previous_text',
                'digi_button_finish_text',
                'digi_button_submit_text',
                'digi_button_go_to_previous_text',
                'digi_custom_header_language',
                'digi_custom_footer_language',
                'digi_popup_title',
                'digi_thanks_message',
                'digi_theme',
                'digi_theme_css_custom_language',
                'digi_triggered_button_text',
                'language',
            ],
            langSettings: [
                'DIGI_BUTTON_NEXT_QUESTION_TEXT',
                'DIGI_BUTTON_START_AGAIN_TEXT',
                'DIGI_SURVEY_STATIC_LINK',
            ],
        },
    };

    static matchingSettings(models) {
        const dictionary = SettingsService.settingsLibrary();

        return models.map(model => {
            return {
                ...model,
                children: model.children.map(child => {
                    return dictionary.find(({ key }) => key === child);
                }),
            };
        });
    }

    // Common Settings
    static getFieldsByCommonGlobal() {
        const models = SettingsService.settingsModel.commonSurveySettingsDictionary(true);

        return SettingsService.matchingSettings(models);
    }

    static getFieldsByCommonSurvey() {
        const models = SettingsService.settingsModel.commonSurveySettingsDictionary(false);

        return SettingsService.matchingSettings(models);
    }
    // end Common Settings

    // Advanced / Survey Settings
    static getFieldsByChannelSurveySettings(channel) {
        const models = SettingsService.settingsModel.surveySettingsDictionary(false)[channel] || [];

        return SettingsService.matchingSettings(models);
    }

    static getFieldsByChannelAdvancedSettings(channel) {
        const models = SettingsService.settingsModel.surveySettingsDictionary(true)[channel] || [];

        return SettingsService.matchingSettings(models);
    }
    // end Advanced / Survey Settings

    // Theme
    static getFieldsByThemeSettings() {
        const models = SettingsService.settingsModel.surveySettingsDictionary(false)['themeDIGI'] || [];

        return SettingsService.matchingSettings(models);
    }

    static getFieldsByThemeLanguageSettings() {
        const models = SettingsService.settingsModel.languageSettingsDictionary(false, true)['themeDIGI'] || [];

        return SettingsService.matchingSettings(models);
    }
    // end Theme

    // Language Settings / Language-specific
    static getFieldsBySurveyLanguageSettings(channel) {
        const models = SettingsService.settingsModel.languageSettingsDictionary(false)[channel] || [];

        return SettingsService.matchingSettings(models);
    }

    static getFieldsByGlobalLanguageSpecific(channel) {
        const models = SettingsService.settingsModel.languageSettingsDictionary(true)[channel] || [];

        return SettingsService.matchingSettings(models);
    }
    // end Language Settings / Language-specific

    static getKeysForFilter(type, isGlobal) {
        let modelKeys;

        const fillModelKeys = model => model.forEach(({ children }) => {
            modelKeys ? modelKeys.push(...children) : modelKeys = [ ...children ];
        });

        const fillModelKeysForChannels = (key, model) => model[key].forEach(({ children }) => {
            modelKeys = {
                ...modelKeys,
                [key]: [
                    ...modelKeys[key],
                    ...children,
                ],
            };
        });

        switch (type) {
            case 'common': {
                fillModelKeys(
                    SettingsService.settingsModel.commonSurveySettingsDictionary(isGlobal),
                );

                break;
            }

            case 'advanced': {
                const model = SettingsService.settingsModel.surveySettingsDictionary(isGlobal);

                modelKeys = {};

                Object.keys(model).forEach(key => {
                    modelKeys[key] = [];
                    fillModelKeysForChannels(key, model);
                });

                if (isGlobal) {
                    modelKeys = {
                        ...modelKeys,
                        DIGI: modelKeys['DIGI'].filter(el => el !== "digi_theme"),
                    };
                }

                break;
            }

            case 'languages' : {
                const model = SettingsService.settingsModel.languageSettingsDictionary(isGlobal);

                modelKeys = {};

                Object.keys(model).forEach(key => {
                    modelKeys[key] = [];
                    fillModelKeysForChannels(key, model);
                });

                break;
            }

            case 'ThemeLanguages' : {
                const model = SettingsService.settingsModel
                    .languageSettingsDictionary(false, true)['themeDIGI'];

                modelKeys = [ ...model[0].children ];

                break;
            }
        }

        return modelKeys ? modelKeys : [];
    }

    /*
    * COMMON_SETTINGS for VOCACT-CASEALERT
    * @use GlobalSettings
    * @use SurveySettings
    * */
    static changeBodyCommonSettings({ body, ...rest }, enterpriseId) {
        const VOCACT_CASEALERT_TICKET_FIELDS = SettingsService.addEnterpriseId(body.VOCACT_CASEALERT_TICKET_FIELDS, enterpriseId);

        return {
            ...rest,
            body: {
                ...body,
                VOCACT_CASEALERT_TICKET_FIELDS,
            },
        };
    }

    static addEnterpriseId(fieldGroup, enterpriseId) {
        const valueSourceTypeClassifier = 3;

        // eslint-disable-next-line
        return fieldGroup?.map(({enterprise_id, ...rest}) => {
            return rest.source_type === valueSourceTypeClassifier ? {
                ...rest,
                enterprise_id: enterpriseId,
            } : rest;
        }) || [];
    }

    static changeFieldsCommonSettings(field, classifierOptions, callback, settings) {
        return field.children.map(child => {
            if(child.key === 'VOCACT_CASEALERT_TICKET_FIELDS') {
                child.options.source['classifierOptions'] = [ ...classifierOptions ];
            }

            return callback(child, settings);
        });
    }
}
