import React, { Component } from 'react';
import { FieldArray } from 'redux-form';
import { SettingFieldContainer } from '../components';
import { VocSelect, MultiValueLabel, ComponentOption } from '/components';
import { autobind } from 'core-decorators';
import { FormText } from 'reactstrap';
import { RenderSmsSenders } from './RenderSmsSenders';

import './ConfigurableGroup.scss';

export class ConfigurableGroup extends Component {
    @autobind
    onChange(selected, { action, removedValue, option }) {
        const {
            normalizer,
            multiNormalizer,
            isMulti,
            input: {
                onChange,
                value,
            },
        } = this.props;

        const model = {
            ...value,
            value: isMulti ? multiNormalizer(selected) : normalizer(selected),
        };

        if (action === "clear") {
            model.smsSendersValue = [];
        } else if (action === "remove-value") {
            const regExp = new RegExp(`\\[::]${removedValue.label}$`);

            model.smsSendersValue = [
                ...model.smsSendersValue.filter(el => !regExp.test(el)),
            ];
        } else {
            model.smsSendersValue = [
                ...model.smsSendersValue,
                `[::]${ option.label }`,
            ];
        }

        onChange(model);
    }

    @autobind
    onChangeSmsSenders(selected, { name }) {
        const {
            onChange,
            value,
        } = this.props.input;

        const regExp = new RegExp(`\\[::]${name}$`);

        onChange({
            ...value,
            smsSendersValue: [
                ...value.smsSendersValue.map(el => regExp.test(el)
                    ? `${ selected.value }[::]${ name }`
                    : el,
                ),
            ],
        });
    }

    @autobind
    onFocus() {
        this.props.input.onFocus(this.props.input.value);
    }

    @autobind
    onBlur() {
        this.props.input.onBlur(this.props.input.value);
    }

    render() {
        const {
            input,
            formatter,
            clearable,
            withError,
            meta: {
                error,
                touched,
                dirty,
            },
            label,
            options,
            isMulti,
            input: {
                value: {
                    redefined,
                    value,
                },
            },
        } = this.props;

        return (
            <>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                <SettingFieldContainer
                    checked={ redefined }
                    field={ input }
                    className={ `${ dirty ? 'changed' : '' }` }
                >
                    <div className={ 'setting-control-group__container' }>
                        <VocSelect
                            id={ input.name }
                            className={ 'voc-setting-field__select' }
                            disabled={ !redefined }
                            onChange={ this.onChange }
                            onFocus={ this.onFocus }
                            onBlur={ this.onBlur }
                            isClearable={ clearable }
                            options={ options }
                            value={ formatter(options, value) }
                            isMulti={ isMulti }
                            components={{
                                Option: ComponentOption,
                                MultiValueLabel,
                            }}
                        />

                        <FieldArray
                            options={ options }
                            name={ `${ input.name }.smsSendersValue` }
                            component={ RenderSmsSenders }
                            rerenderOnEveryChange={ true }
                            formatter={ formatter }
                            onChange={ this.onChangeSmsSenders }
                        />
                    </div>
                </SettingFieldContainer>

                {
                    Boolean(touched && error && withError)
                        ? <FormText className="app-form-control__error">{ error }</FormText>
                        : false
                }

            </>
        );
    }
}

ConfigurableGroup.defaultProps = {
    withError: true,
    clearable: true,
};
