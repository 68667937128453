export class GatewaysSettingsService {
    static getRedefineSetting({
        redefined,
        channelValue,
        surveyValue,
        smsSendersSurveyValue,
        smsSendersChannelValue,
    }) {
        const checkEmptySurveyValue = (surveyVl, channelVl) =>
            surveyVl.length ? surveyVl : channelVl;

        return {
            value: redefined
                ? channelValue
                : checkEmptySurveyValue(surveyValue, channelValue),
            smsSendersValue: redefined
                ? smsSendersChannelValue
                : checkEmptySurveyValue(smsSendersSurveyValue, smsSendersChannelValue),
        };
    }

    static getSubmitSettings(body) {
        return body.smsSendersValue
            .filter(el => !!el.split('[::]')[0])
            .map(el => {
                const [ value, name ] = el.split('[::]');
                const options = body.options.find(el => el.label === name);
                const selectedOptions = options?.sms_senders.find(el => el.value === value);

                return selectedOptions
                    ? {
                        name: name,
                        sms_senders: [{
                            name: selectedOptions.label,
                            phone: selectedOptions.value,
                        }],
                    }
                    : {};
            })
            .filter(({ name }) => !!name);
    }

    static addOptionsToInitial(settings, options) {
        return {
            ...settings,
            GATEWAYS_CHAIN: {
                ...settings.GATEWAYS_CHAIN,
                options: options,
            },
        };
    }

    static composeGatewaysGlobalSettings(globalSettings, setting) {
        const smsSendersChannelValue = globalSettings[setting]
            ? GatewaysSettingsService.getValue(globalSettings[setting])
            : [];

        return {
            smsSendersChannelValue,
            smsSendersValue: smsSendersChannelValue,
        };
    }

    static composeGatewaysSurveySettings(globalSettings, surveySettings, setting, checkValue) {
        const smsSendersSurveyValue = surveySettings[setting]
            ? GatewaysSettingsService.getValue(surveySettings[setting])
            : [];

        const smsSendersChannelValue = globalSettings[setting]
            ? GatewaysSettingsService.getValue(globalSettings[setting])
            : [];

        return {
            channelValue: globalSettings[setting]
                ? globalSettings[setting].map(gateway => gateway.name)
                : [],
            surveyValue: surveySettings[setting]
                ? surveySettings[setting].map(gateway => gateway.name)
                : [],
            smsSendersChannelValue,
            smsSendersSurveyValue,
            smsSendersValue: checkValue(smsSendersSurveyValue)
                ? smsSendersSurveyValue
                : smsSendersChannelValue,
        };
    }

    static getValue(settings) {
        return settings.map(gateway => `${ gateway.sms_senders[0].phone }[::]${ gateway.name }`);
    }
}
