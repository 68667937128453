import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TooltipWrapper, StickyBox } from '/components';
import { ButtonComponent } from '/visual/components';
import { EditMenu, AddElementMenu } from '../';
import { StickyBoxPosition } from '/components/StickyBox/models';
import {
    _getDashboardLoading,
    _getDashboardData,
    enableDashboardFilterSelector,
} from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { addGadget, createPage, setScrollTo, toggleModal } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { DashboardService, PlaceholdersService } from '/visual/scenes/Dashboard/services';
import { useLocalState } from '/visual/customHooks';
import { IGadgetData } from '/visual/models';

import styles from './style.module.scss';

export const DashboardControls = ({
    isEditable,
    switchIsEditable,
    onMakeDefault,
    onExport,
    onMakeFavourite,
    onSaveChanges,
    goToParentDashboard,
    onCopy,
}: any) => {
    const { t } = useTranslation();
    const dashboard = useSelector(_getDashboardData);
    const dashboardLoading = useSelector(_getDashboardLoading);
    const enableDashboardFilter = useSelector(enableDashboardFilterSelector);

    const { pages } = useLocalState();

    const dispatch = useDispatch();
    const hasParentDashboard = DashboardService.isHasParentDashboard(dashboard);

    const goToParentalDashboard = () => {
        const parentGadget = DashboardService.getParentGadget(dashboard);

        goToParentDashboard(parentGadget.dashboardId, parentGadget.id);
    };

    const onAddGadget = (type: string) => {
        const placeholder = PlaceholdersService.getNearestPlaceholder(type, pages);

        const request = dispatch(addGadget({
            ...placeholder,
            type,
            id: dashboard.id,
        }));

        placeholder?.pageId
        && request.then(({ id }: IGadgetData) => dispatch(setScrollTo({ gadgetId: id })));
    };

    const handleToggleAddElementModal = (params: { type: string, dashboardId: string }) =>
        dispatch(toggleModal(params));

    return (
        <StickyBox
            position={ StickyBoxPosition.TOP }
            stuckClass={ styles.stuckControls }
            offset={{ query: '.header' }}
        >
            <div className={ styles.header }>
                <div className={ styles.title }>
                    <TooltipWrapper value={ dashboard?.title }>
                        <span>{ dashboard?.title }</span>
                    </TooltipWrapper>
                </div>
                <div className={ styles.headerActionBar }>
                    <ButtonComponent
                        isShow={ hasParentDashboard }
                        tooltipProps={{
                            force: isEditable && hasParentDashboard,
                            value: t('parentDashboard'),
                        }}
                        buttonProps={{
                            color: 'primary',
                            onClick: goToParentalDashboard,
                            id: 'parental-dashboard',
                        }}
                        icon={ 'level-up-alt' }
                        text={ !isEditable ? t('parentDashboard') : '' }
                    />

                    {
                        isEditable
                        && <AddElementMenu
                            dashboard={ dashboard }
                            onToggleModal={ handleToggleAddElementModal }
                            onAddGadget = { () => onAddGadget('super_chart') }
                            onAddPage = { () => dispatch(createPage(dashboard.id)) }
                            onMediaBlock = { () => onAddGadget('static_area') }
                        />
                    }

                    <ButtonComponent
                        isShow={ dashboard.rights.isPermitEdit }
                        buttonProps={{
                            id: 'save-edit',
                            disabled: dashboardLoading,
                            className: styles.actionButton,
                            color: isEditable ? 'success' : 'danger',
                            onClick: isEditable ? onSaveChanges : switchIsEditable,
                        }}
                        icon={ isEditable ? 'lock-open' : 'lock' }
                        text={ t(isEditable ? 'save' : 'editLayout') }
                    />

                    <ButtonComponent
                        isShow={ isEditable }
                        buttonProps={{
                            color: 'danger',
                            id: 'cancel-save-edit',
                            disabled: dashboardLoading,
                            className: styles.actionButton,
                            onClick: switchIsEditable,
                        }}
                        icon={ 'times' }
                        text={ t('cancel') }
                    />

                    <ButtonComponent
                        isShow={ dashboard.rights.isPermitEdit }
                        buttonProps={{
                            color: 'primary',
                            id: 'dashboard-filter',
                            className: styles.actionButton,
                            disabled: dashboardLoading || !enableDashboardFilter,
                            onClick: () => dispatch(toggleModal({ type: 'dashboardFilter', dashboardId: dashboard.id })),
                        }}
                        icon={ 'filter' }
                        text={ t('dashboardFilter') }
                    />

                    <EditMenu
                        disabled={ dashboardLoading }
                        dashboard={ dashboard }
                        rights={ dashboard.rights }
                        onToggleModal={ (params: { type: string, dashboardId: string }) => dispatch(toggleModal(params)) }
                        onMakeDefault={ onMakeDefault }
                        onMakeFavourite={ onMakeFavourite }
                        onExport={ onExport }
                        onCopy={ onCopy }
                    />
                </div>
            </div>
        </StickyBox>
    );
};
