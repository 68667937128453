import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocCleaupHOC, showToast, SwitchControl } from '/components';
import { PageRequests, HelperService } from '/services';
import { ExportResultsTable, ExportResultsModal, ExportResultsSummaryPopover } from './components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { availableUnitsId } from '/scenes/VocFeedback/modules/VocFeedback.selectors';
import { isLoadedPermissionSelector, mapExportersToSelectModel } from '../../modules/export.selector';
import {
    getExportResults,
    cleanup,
    updateFilters,
    addToSelected,
    removeFromSelected,
    clearSelection,
    createExports,
    getStatisticsSummary,
} from './modules/exportResults.index';
import {
    tableDataSelector,
    filtersSelector,
    tableSettingsSelector,
    selectedListSelector,
    exportSummarySelector,
    possibleRangesSelector,
} from './modules/exportResults.selectors';

import './ExportResults.scss';

const mapStateToProps = state => ({
    tableData: tableDataSelector(state),
    filters: filtersSelector(state),
    tableState: tableSettingsSelector(state),
    selectedList: selectedListSelector(state),
    exporters: mapExportersToSelectModel(state),
    popoverData: exportSummarySelector(state),
    possibleRanges: possibleRangesSelector(state),
    availableUnitsId: availableUnitsId(state),
    isLoadedPermission: isLoadedPermissionSelector(state),
});

const mapDispatchToProps = {
    getExportResults,
    cleanup,
    updateFilters,
    addToSelected,
    removeFromSelected,
    clearSelection,
    showToast,
    showConfirm,
    createExports,
    getStatisticsSummary,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class ExportResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openExportModal: false,
            isOpenPopover: false,
            showDisabledExports: false,
        };

        this.pageRequests = new PageRequests();

        const self = this;

        this.searchTrotler = HelperService.debounce(function() {
            self.requestTrigger();
        }, 300);
    }

    componentDidMount() {
        this.requestTrigger();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isLoadedPermission !== this.props.isLoadedPermission) {
            this.requestTrigger();
        }
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        const model = {
            sort: filters.sort_field ? (filters.sort_order === 'desc' ? '' : '-') + filters.sort_field : '',
            limit: filters.limit,
            page: filters.page,
            search: filters.search ? filters.search : '',
            channel: filters.channel,
            start_time: filters.start_time,
            end_time: filters.end_time,
            range_type: filters.range,
            show_only_deleted_survey_export: filters.show_only_deleted_survey_export,
            units: `[${this.props.availableUnitsId?.toString()}]`,
        };

        this.pageRequests.cleanup();
        const request = this.props.getExportResults(model);

        this.pageRequests.addRequest(request);
    }

    @autobind
    onSearchType(search) {
        this.props.updateFilters({ search, page: 1 });
        this.requestTrigger({ ...this.props.filters, search, page: 1 });
    }

    @autobind
    changeChannel(channel) {
        this.props.updateFilters({ channel, page: 1 });
        this.requestTrigger({ ...this.props.filters, channel, page: 1 });
    }

    @autobind
    changeRange(range) {
        this.props.updateFilters({ range, page: 1 });
        this.requestTrigger({ ...this.props.filters, range, page: 1 });
    }

    @autobind
    changeShowDisabledExports() {
        this.props.updateFilters({ show_only_deleted_survey_export: !this.state.showDisabledExports });
        this.requestTrigger({
            ...this.props.filters,
            show_only_deleted_survey_export: !this.state.showDisabledExports,
        });
        this.setState({
            showDisabledExports: !this.state.showDisabledExports,
        });
    }

    @autobind
    onDatesChanged(date) {
        const {
            start_time,
            end_time,
        } = this.props.filters;

        const params = {
            ...this.props.filters,
            start_time: HelperService.formatDate(date.from, 'YYYY-MM-DD'),
            end_time: HelperService.formatDate(date.to, 'YYYY-MM-DD'),
        };

        if (params.start_time !== start_time || params.end_time !== end_time) {
            this.props.updateFilters({
                start_time: params.start_time,
                end_time: params.end_time,
                page: 1,
            });
            this.requestTrigger(params);
        }
    }

    @autobind
    openModal() {
        this.setState({
            openExportModal: true,
        });
    }

    @autobind
    closeModal() {
        this.setState({
            openExportModal: false,
        });
    }

    @autobind
    submitModalHandler(selected) {
        return this.props.createExports(selected)
            .then(() => {
                this.props.clearSelection();
                this.closeModal();
            });
    }

    @autobind
    clearSelectionHandler() {
        this.props.showConfirm({
            header: 'Warning!',
            content: 'You are going to remove all selected surveys, continue?',
            successCallback: this.clearSelection,
        });
    }

    @autobind
    clearSelection() {
        this.props.clearSelection();
        this.props.showToast({ text: 'Chosen surveys list was removed', type: 'success' });
    }

    @autobind
    exportSurveysHandler() {
        this.props.selectedList.length
            ? this.openModal()
            : this.props.showToast({ text: 'No selected surveys. Please select at least one', type: 'error' });
    }

    @autobind
    showPopover() {
        const { end_time, range, start_time, channel } = this.props.filters;
        const params = {
            survey__channel__name: channel,
            start_time: start_time,
            range_type: range,
            end_time: end_time,
        };

        this.setState({
            isOpenPopover: true,
        });

        this.props.getStatisticsSummary(params);
    }

    @autobind
    togglePopover() {
        this.setState({
            isOpenPopover: !this.state.isOpenPopover,
        });
    }

    render() {
        const {
            addToSelected,
            removeFromSelected,
            selectedList,
            filters,
            tableData,
            tableState,
            exporters,
            possibleRanges,
        } = this.props;

        return (
            <DocumentTitle title="Feedback :: Export Results">
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Export Results</span>
                        <div className={ 'panel__header-buttons' }>
                            <Button className={ 'panel__header-button panel__header-button_showDisabled' }
                                id={ 'showDisabledExports' }
                                outline
                                color="white"
                                onClick={ this.changeShowDisabledExports }>
                                <SwitchControl
                                    name="disabled-exports-switch"
                                    type={ 'small' }
                                    value={ this.state.showDisabledExports }
                                    changeHandler={ this.changeShowDisabledExports }
                                />
                                <span>Show disabled</span>
                            </Button>
                            <Button className={ 'panel__header-button' }
                                id={ 'showStatisticSummary' }
                                outline
                                color="white"
                                onClick={ this.showPopover }>
                                <FontAwesomeIcon icon='file-signature' className='export-results__icon'/>
                                <span>Summary statistics</span>
                            </Button>
                            {
                                this.props.selectedList.length
                                    ? <Button className={ 'panel__header-button' }
                                        outline
                                        color="white"
                                        onClick={ this.clearSelectionHandler }>
                                        <FontAwesomeIcon icon='times' className='export-results__icon'/>
                                        <span>Clear selection</span>
                                    </Button> : null
                            }
                            <Button className={ 'panel__header-button' }
                                outline
                                color="white"
                                onClick={ this.exportSurveysHandler }>
                                <FontAwesomeIcon icon='cloud-download-alt' className='export-results__icon'/>
                                <span>Export</span>
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ExportResultsTable
                            requestTrigger={ this.requestTrigger }
                            onSearchType={ this.onSearchType }
                            updateFilters={ this.props.updateFilters }
                            filters={ filters }
                            data={ tableData }
                            tableState={ tableState }
                            onDatesChanged={ this.onDatesChanged }
                            changeChannel={ this.changeChannel }
                            onRangeChanged={ this.changeRange }
                            addToSelected={ addToSelected }
                            removeFromSelected={ removeFromSelected }
                            selectedList={ selectedList }
                            possibleRanges={ possibleRanges }
                        />
                        <ExportResultsModal
                            show={ this.state.openExportModal }
                            closeModal={ this.closeModal }
                            exporters={ exporters }
                            submitHandler={ this.submitModalHandler }
                        />
                        <ExportResultsSummaryPopover
                            isOpen={ this.state.isOpenPopover }
                            toggle={ this.togglePopover }
                            data={ this.props.popoverData }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}

ExportResults.propTypes = {
    tableData: PropTypes.array,
    popoverData: PropTypes.shape({
        loading: PropTypes.bool,
        statistics: PropTypes.array,
    }),
    tableState: PropTypes.shape({
        dataTotalSize: PropTypes.number,
        page: PropTypes.number,
        sizePerPage: PropTypes.number,
        loading: PropTypes.bool,
    }),
    selectedList: PropTypes.array,
    exporters: PropTypes.shape({
        loading: PropTypes.bool,
        results: PropTypes.array,
    }),
};
