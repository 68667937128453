import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';
import { SettingsService } from '/feedback/services/SettingsService';
import { getUnsubscribeSurveyGroupId } from "/feedback/modules/VocFeedback.modules";

export const GET_GLOBAL_COMMON_SETTINGS_REQUEST = 'GET_GLOBAL_COMMON_SETTINGS_REQUEST';
export const GET_GLOBAL_COMMON_SETTINGS_SUCCESS = 'GET_GLOBAL_COMMON_SETTINGS_SUCCESS';
export const GET_GLOBAL_COMMON_SETTINGS_ERROR = 'GET_GLOBAL_COMMON_SETTINGS_ERROR';

export const GET_SURVEY_COMMON_SETTINGS_REQUEST = 'GET_SURVEY_COMMON_SETTINGS_REQUEST';
export const GET_SURVEY_COMMON_SETTINGS_SUCCESS = 'GET_SURVEY_COMMON_SETTINGS_SUCCESS';
export const GET_SURVEY_COMMON_SETTINGS_ERROR = 'GET_SURVEY_COMMON_SETTINGS_ERROR';

export const PUT_SURVEY_COMMON_SETTINGS_REQUEST = 'PUT_SURVEY_COMMON_SETTINGS_REQUEST';
export const PUT_SURVEY_COMMON_SETTINGS_SUCCESS = 'PUT_SURVEY_COMMON_SETTINGS_SUCCESS';
export const PUT_SURVEY_COMMON_SETTINGS_ERROR = 'PUT_SURVEY_COMMON_SETTINGS_ERROR';

export const SURVEY_COMMON_SETTINGS_LOADING = 'SURVEY_COMMON_SETTINGS_LOADING';
export const SURVEY_COMMON_SETTINGS_LOADED = 'SURVEY_COMMON_SETTINGS_LOADED';

export const SURVEY_COMMON_SETTINGS_CLEANUP = 'SURVEY_COMMON_SETTINGS_CLEANUP';

const GET_SURVEY_CLASSIFIER_LIST_REQUEST = 'GET_SURVEY_CLASSIFIER_LIST_REQUEST';
const GET_SURVEY_CLASSIFIER_LIST_SUCCESS = 'GET_SURVEY_CLASSIFIER_LIST_SUCCESS';
const GET_SURVEY_CLASSIFIER_LIST_ERROR = 'GET_SURVEY_CLASSIFIER_LIST_ERROR';

const modelKeys = SettingsService.getKeysForFilter('common', false);

function getInitialState() {
    return {
        loading: true,
        sending: false,
        surveySettings: {},
        globalSettings: {},
        classifier: {
            loading: false,
            results: [],
        },
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case SURVEY_COMMON_SETTINGS_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case SURVEY_COMMON_SETTINGS_LOADED: {
            return {
                ...state,
                loading: false,
            };
        }

        case PUT_SURVEY_COMMON_SETTINGS_REQUEST: {
            return {
                ...state,
                sending: true,
            };
        }

        case PUT_SURVEY_COMMON_SETTINGS_ERROR: {
            return {
                ...state,
                sending: false,
            };
        }

        case PUT_SURVEY_COMMON_SETTINGS_SUCCESS: {
            return {
                ...state,
                surveySettings: {
                    ...HelperService.pick(action.response, modelKeys),
                },
                sending: false,
            };
        }

        case GET_GLOBAL_COMMON_SETTINGS_SUCCESS: {
            return {
                ...state,
                globalSettings: {
                    ...HelperService.pick(action.response, modelKeys),
                },
            };
        }

        case GET_SURVEY_COMMON_SETTINGS_SUCCESS: {
            return {
                ...state,
                surveySettings: {
                    ...HelperService.pick(action.response, modelKeys),
                },
            };
        }

        case GET_SURVEY_COMMON_SETTINGS_ERROR:
        case GET_GLOBAL_COMMON_SETTINGS_ERROR: {
            return {
                ...state,
            };
        }

        case GET_SURVEY_CLASSIFIER_LIST_REQUEST: {
            return {
                ...state,
                classifier: {
                    loading: true,
                    results: [],
                },
            };
        }

        case GET_SURVEY_CLASSIFIER_LIST_SUCCESS: {
            return {
                ...state,
                classifier: {
                    loading: false,
                    results: [
                        ...action.response,
                    ],
                },
            };
        }

        case GET_SURVEY_CLASSIFIER_LIST_ERROR: {
            return {
                ...state,
                classifier: {
                    loading: false,
                    results: [],
                },
            };
        }

        case SURVEY_COMMON_SETTINGS_CLEANUP: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getSurveySettings({ survey_group, unit }) {
    return dispatch => {
        dispatch(startProcessing());
        const globalSettingRequest = dispatch(getGlobalCommonSetting(unit));
        const surveySettingRequest = dispatch(getSurveyCommonSetting(survey_group));
        const unsubscribeSurveyGroupRequest = dispatch(getUnsubscribeSurveyGroupId(unit));
        const promises = [
            globalSettingRequest.promise,
            surveySettingRequest.promise,
            unsubscribeSurveyGroupRequest.promise,
        ];

        Promise.all(promises)
            .then(() => dispatch(endProcessing()), ({ status }) => {
                if (status !== 0) {
                    return dispatch(endProcessing());
                }
            });

        return [ globalSettingRequest, surveySettingRequest ];
    };
}

export function startProcessing() {
    return {
        type: SURVEY_COMMON_SETTINGS_LOADING,
    };
}

export function endProcessing() {
    return {
        type: SURVEY_COMMON_SETTINGS_LOADED,
    };
}

export function getGlobalCommonSetting(unit) {
    return {
        [CALL_API]: {
            endpoint: `unit/${unit}/get-channel-settings/WEB/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_GLOBAL_COMMON_SETTINGS_REQUEST, GET_GLOBAL_COMMON_SETTINGS_SUCCESS, GET_GLOBAL_COMMON_SETTINGS_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getSurveyCommonSetting(id) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${ id }/get-channel-settings/WEB/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_COMMON_SETTINGS_REQUEST, GET_SURVEY_COMMON_SETTINGS_SUCCESS, GET_SURVEY_COMMON_SETTINGS_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function putSurveyCommonSettings(model) {
    return dispatch => {
        return dispatch(putSurveyCommonSettingsRequest(model)).promise
            .catch(SettingsService.catchSettingsErrors);
    };
}

export function putSurveyCommonSettingsRequest({ id, body }) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${ id }/set-settings/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [
                PUT_SURVEY_COMMON_SETTINGS_REQUEST,
                PUT_SURVEY_COMMON_SETTINGS_SUCCESS,
                PUT_SURVEY_COMMON_SETTINGS_ERROR,
            ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Common settings were successfully updated',
                error: 'Something went wrong',
            },
        },
    };
}

export function getSurveyClassifierList() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/classify/classifier/list`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_CLASSIFIER_LIST_REQUEST, GET_SURVEY_CLASSIFIER_LIST_SUCCESS, GET_SURVEY_CLASSIFIER_LIST_ERROR ],
        },
    };
}

export function cleanup() {
    return {
        type: SURVEY_COMMON_SETTINGS_CLEANUP,
    };
}
