import React, { Component, Fragment } from 'react';
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import { autobind } from 'core-decorators';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TooltipWrapper } from '/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrowDown from './arrow_down@2x.png';

import './CollapseSection.scss';

export class CollapseSection extends Component {
    state = {
        collapse: this.props.collapse || false,
    };

    componentDidUpdate(nextProps) {
        if (nextProps.collapse !== this.props.collapse) {
            this.setState({
                collapse: this.props.collapse,
            });
        }
    }

    @autobind
    toggle() {
        const { collapseDisable, onCollapseChanged, title, onCollapseOpen, onCollapseClose } = this.props;
        const { collapse } = this.state;

        if (!collapseDisable) {
            onCollapseOpen && !collapse && onCollapseOpen();
            onCollapseClose && collapse && onCollapseClose();
            onCollapseChanged && onCollapseChanged(title, !this.state.collapse);
            this.setState(state => ({ collapse: !state.collapse }));
        } else {
            () => null;
        }
    }

    @autobind
    getCollapseStateImage() {
        const { collapseDisable, loader, isCollapseLoading } = this.props;

        return loader && !collapseDisable && isCollapseLoading && this.state.collapse
            ? <div className={ 'loader-wrapper' }>
                <FontAwesomeIcon icon='circle-notch' spin/>
            </div>
            : <img src={ arrowDown } alt="arrow_down"/>;
    }

    render() {
        const {
            title, titleAddon, children,
            collapseDisable, headerAddOn,
            className, handlerElement,
            isCollapseLoading, loader,
            noTitleTooltip,
        } = this.props;

        const { collapse } = this.state;

        const isOpen = loader
            ? !isCollapseLoading && collapse
            : collapse;

        const collapseClassname = loader
            ? cx({ 'no-collapse': collapseDisable }, { 'unClickable': isCollapseLoading })
            : cx({ 'no-collapse': collapseDisable });

        return (
            <Fragment>
                <Card className={ `collapse-section__info-section ${ className }` }>
                    <CardHeader onClick={ this.toggle } className={ collapseClassname }>
                        <h5 className={ cx('collapse__header', { 'open': isOpen }) }>
                            <div className="collapse__header-title">
                                {
                                    titleAddon
                                    && <span className={ 'collapse__header-title-addon' }>{ titleAddon }</span>
                                }
                                { !noTitleTooltip
                                    ? <TooltipWrapper value={ title }>
                                        <span
                                            className={ cx('collapse__header-title-text', { fullwidth: !titleAddon }) }>{ title }
                                        </span>
                                    </TooltipWrapper>
                                    : <span
                                        className={ cx('collapse__header-title-text', { fullwidth: !titleAddon }) }>{ title }
                                    </span>
                                }
                            </div>
                            {
                                !collapseDisable
                                    ? this.getCollapseStateImage()
                                    : <img src={ arrowDown } alt=""/>
                            }
                        </h5>
                        { headerAddOn && headerAddOn }
                        { handlerElement && handlerElement }
                    </CardHeader>
                    <Collapse isOpen={ isOpen }>
                        <CardBody>
                            { children }
                        </CardBody>
                    </Collapse>

                </Card>
            </Fragment>
        );
    }
}

CollapseSection.defaultProps = {
    collapseDisable: false,
    headerAddOn: null,
    title: 'Section Title',
    titleAddon: '',
    collapse: false,
    actionsFormatter: null,
    loader: false,
    noTitleTooltip: false,
};

CollapseSection.propTypes = {
    collapseDisable: PropTypes.bool,
    collapse: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.object,
    ]),
    handlerElement: PropTypes.element,
    headerAddOn: PropTypes.element,
    className: PropTypes.string,
    onCollapseChanged: PropTypes.func,
    loader: PropTypes.bool,
    onCollapseOpen: PropTypes.func,
    onCollapseClose: PropTypes.func,
    isCollapseLoading: PropTypes.bool,
};

