import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Label, Col, Row } from 'reactstrap';

import {
    MenuList, DropdownIndicator,
    Option, ValueContainer,
    TooltipWrapper,
} from '/components';

import './VocSelect.scss';

export const VocSelect = ({
    name,
    value,
    isClearable,
    options,
    placeholder,
    className,
    onChange,
    onFocus,
    onBlur,
    disabled,
    removeSelected,
    isMulti,
    closeMenuOnSelect,
    formatOptionLabel,
    isRequired,
    isCreatable,
    isSearchable,
    isLoading,
    label,
    isHorizontal,
    defaultValue,
    withTooltip,
    portal,
    zIndex,
    components,
    id,
}) => {
    const classList = new Set([ 'vochub-select-control', isMulti && 'multiselect' ]);

    const defaultComponents = {
        MenuList,
        DropdownIndicator,
        Option,
    };

    if (withTooltip) {
        defaultComponents['ValueContainer'] = ValueContainer;
    }

    const config = {
        className: Array.from(classList).concat(className).filter(Boolean).join(' '),
        classNamePrefix: 'vochub-select-control',
        name,
        value,
        isClearable,
        components: {
            ...defaultComponents,
            ...components,
        },
        options,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        isDisabled: disabled,
        removeSelected,
        isMulti,
        defaultValue,
        closeMenuOnSelect,
        isSearchable,
        isLoading,
        ...id ? { id } : {},
    };
    let selectColumn = isHorizontal ? 9 : 12;

    if (!isClearable) {
        config['backspaceRemovesValue'] = false;
    }

    if (isMulti) {
        config['hideSelectedOptions'] = true;
    }

    if (formatOptionLabel) {
        config['formatOptionLabel'] = formatOptionLabel;
    }

    if (portal) {
        config['menuPortalTarget'] = document.body;
        config['styles'] = { menuPortal: base => ({ ...base, zIndex }) };
        config['menuShouldScrollIntoView'] = false;
        config['menuPlacement'] = "auto";
    }

    if (!label) {
        selectColumn = 12;
    }

    return (
        <Row className={ 'select-control-wrapper' }>
            {
                label
                && <Col md={ isHorizontal ? 3 : 12 } className={ 'select-control-label' }>
                    <Label for={ name }>
                        <TooltipWrapper value={ label }>
                            <span className={ 'select-control-label-text' }>
                                { label }
                            </span>
                        </TooltipWrapper>
                        { isRequired ? <span className={ 'star' }> *</span> : '' }
                    </Label>
                </Col>
            }
            <Col md={ selectColumn }>
                { isCreatable ? <CreatableSelect { ...config }/> : <Select { ...config }/> }
            </Col>
        </Row>
    );
};

VocSelect.propTypes = {
    isRequired: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),

    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    })),

    isClearable: PropTypes.bool,
    isHorizontal: PropTypes.bool,
    removeSelected: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    portal: PropTypes.bool,
    withTooltip: PropTypes.bool,
    components: PropTypes.object,
};

VocSelect.defaultProps = {
    isMulti: false,
    placeholder: 'Select...',
    zIndex: 9999,
    isCreatable: false,
    isSearchable: true,
    withTooltip: false,
    components: {},
};
