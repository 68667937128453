import { CALL_API, FEEDBACK } from 'store/middleware/api';

export const GET_EXPORTERS_REQUEST = 'GET_EXPORTERS_REQUEST';
export const GET_EXPORTERS_SUCCESS = 'GET_EXPORTERS_SUCCESS';
export const GET_EXPORTERS_ERROR = 'GET_EXPORTERS_ERROR';

export const GET_SURVEYS_BASIC_REQUEST = 'GET_SURVEYS_BASIC_REQUEST';
export const GET_SURVEYS_BASIC_SUCCESS = 'GET_SURVEYS_BASIC_SUCCESS';
export const GET_SURVEYS_BASIC_ERROR = 'GET_SURVEYS_BASIC_ERROR';

export const GET_SURVEY_BASIC_REQUEST = 'GET_SURVEY_BASIC_REQUEST';
export const GET_SURVEY_BASIC_SUCCESS = 'GET_SURVEY_BASIC_SUCCESS';
export const GET_SURVEY_BASIC_ERROR = 'GET_SURVEY_BASIC_ERROR';

export const CLEANUP_SURVEYS_BASIC = 'CLEANUP_SURVEYS_BASIC';

function getInitialState() {
    return {
        exporters: {
            loading: false,
            results: [],
        },
        surveys: {
            loading: false,
            results: [],
        },
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_EXPORTERS_REQUEST: {
            return {
                ...state,
                exporters: {
                    loading: true,
                    ...state.exporters,
                },
            };
        }

        case GET_EXPORTERS_SUCCESS: {
            return {
                ...state,
                exporters: {
                    loading: false,
                    results: [//todo remove after delete old exporters
                        ...action.response.results.map(exporter =>
                            exporter.export_type
                                ? { ...exporter, isNewExporter: true, id: exporter.id + ' newExporter' }
                                : exporter,
                        ),
                    ],
                },
            };
        }

        case GET_EXPORTERS_ERROR: {
            return {
                ...state,
                exporters: {
                    loading: false,
                    ...state.exporters,
                },
            };
        }

        case GET_SURVEYS_BASIC_REQUEST: {
            return {
                ...state,
                surveys: {
                    ...state.surveys,
                    loading: true,
                },
            };
        }

        case GET_SURVEYS_BASIC_SUCCESS: {
            return {
                ...state,
                surveys: {
                    ...action.response,
                    results: [
                        ...state.surveys?.results,
                        ...action.response?.results,
                    ],
                    loading: false,
                },
            };
        }

        case CLEANUP_SURVEYS_BASIC: {
            return {
                ...state,
                surveys: {
                    results: [],
                    loading: false,
                },
            };
        }

        case GET_SURVEYS_BASIC_ERROR: {
            return {
                ...state,
                surveys: {
                    ...state.surveys,
                    loading: false,
                },
            };
        }

        default:
            return state;
    }
}

export function getExporters(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-config/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_EXPORTERS_REQUEST, GET_EXPORTERS_SUCCESS, GET_EXPORTERS_ERROR ],
            body: {
                page: 1,
                limit: 9999,
                include_legacy_exporters: 1,
                ...body,
            },
            apiType: FEEDBACK,
        },
    };
}

export function cleanupSurveysGroupBasic() {
    return {
        type: CLEANUP_SURVEYS_BASIC,
    };
}

export function getAllSurveysGroupBasic(filter) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/expand-basic/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEYS_BASIC_REQUEST, GET_SURVEYS_BASIC_SUCCESS, GET_SURVEYS_BASIC_ERROR ],
            body: {
                ...filter,
                limit: 15,
                sort: '-sequence',
                hide_deleted_surveys: true,
                hide_empty_survey_groups: true,
            },
            apiType: FEEDBACK,
        },
    };
}

export function getSurveyGroupBasic(surveyGroupId) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/expand-basic/?id__in=${surveyGroupId}`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_BASIC_REQUEST, GET_SURVEY_BASIC_SUCCESS, GET_SURVEY_BASIC_ERROR ],
            apiType: FEEDBACK,
        },
    };
}
