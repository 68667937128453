import { CALL_API } from 'store/middleware/api';
import { IInitialState } from './models';
import { IFilters } from '/ai/models';

const GET_SOURCES_TOPIC_SET_REQUEST = 'GET_SOURCES_TOPIC_SET_REQUEST';
const GET_SOURCES_TOPIC_SET_SUCCESS = 'GET_SOURCES_TOPIC_SET_SUCCESS';
const GET_SOURCES_TOPIC_SET_ERROR = 'GET_SOURCES_TOPIC_SET_ERROR';

const GET_TOPIC_SET_REQUEST = 'GET_TOPIC_SET_REQUEST';
const GET_TOPIC_SET_SUCCESS = 'GET_TOPIC_SET_SUCCESS';
const GET_TOPIC_SET_ERROR = 'GET_TOPIC_SET_ERROR';

const SAVE_TOPIC_SET_REQUEST = 'SAVE_TOPIC_SET_REQUEST';
const SAVE_TOPIC_SET_SUCCESS = 'SAVE_TOPIC_SET_SUCCESS';
const SAVE_TOPIC_SET_ERROR = 'SAVE_TOPIC_SET_ERROR';

export const GET_TOPICS_REQUEST = 'GET_TOPICS_REQUEST';
const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
const GET_TOPICS_ERROR = 'GET_TOPICS_ERROR';

export const GET_GRAPHS_REQUEST = 'GET_GRAPHS_REQUEST';
const GET_GRAPHS_SUCCESS = 'GET_GRAPHS_SUCCESS';
const GET_GRAPHS_ERROR = 'GET_GRAPHS_ERROR';

const UPDATE_SOURCES_TOPIC_SET_FILTERS = 'UPDATE_SOURCES_TOPIC_SET_FILTERS';
const AI_SOCKED_ERROR = 'AI_SOCKED_ERROR';
const CLEANUP_TOPIC_SET = 'CLEANUP_TOPIC_SET';
const CLEANUP_TOPICS_AND_GRAPHS = 'CLEANUP_TOPICS_AND_GRAPHS';
const CLEANUP_TOPIC_SET_SOCKET = 'CLEANUP_TOPIC_SET_SOCKET';
const CLEANUP_TOPIC_SET_SOURCES = 'CLEANUP_TOPIC_SET_SOURCES';

enum ActionTypes {
    GET_TOPICS_REQUEST = 'GET_TOPICS_REQUEST',
    GET_GRAPHS_REQUEST = 'GET_GRAPHS_REQUEST',
}

type RequestMethods = Record<ActionTypes, string>

const requestMethods: RequestMethods = {
    [GET_TOPICS_REQUEST]: 'topics',
    [GET_GRAPHS_REQUEST]: 'graphs',
};

const initialFilters: IFilters = {
    limit: 10,
    page: 1,
    search: '',
};

const getInitialState = (): IInitialState => ({
    topicSetInitData: {
        loading: false,
        data: {},
        error: undefined,
    },
    sources: {
        data: [],
        totalCount: 0,
        loading: false,
        filters: initialFilters,
    },
    topics: {
        loading: false,
        data: null,
        error: '',
    },
    graphs: {
        loading: false,
        data: null,
        error: '',
    },
    socket: {
        id: null,
        actionName: null,
        requestData: null,
    },
});


export const reducer = (state = getInitialState(), action: any = {}) => {
    switch (action.type) {
        case CLEANUP_TOPICS_AND_GRAPHS: {
            return {
                ...state,
                topics: getInitialState().topics,
                graphs: getInitialState().graphs,
            };
        }

        case CLEANUP_TOPIC_SET: {
            return {
                ...state,
                topicSetInitData: getInitialState().topicSetInitData,
                sources: getInitialState().sources,
                topics: getInitialState().topics,
                graphs: getInitialState().graphs,
            };
        }

        case CLEANUP_TOPIC_SET_SOURCES: {
            return {
                ...state,
                sources: getInitialState().sources,
            };
        }

        case GET_TOPIC_SET_REQUEST: {
            return {
                ...state,
                topicSetInitData: {
                    ...state.topicSetInitData,
                    loading: true,
                },
            };
        }

        case GET_TOPIC_SET_SUCCESS: {
            return {
                ...state,
                topicSetInitData: {
                    loading: false,
                    data: action.response,
                },
            };
        }

        case GET_TOPIC_SET_ERROR:
        case SAVE_TOPIC_SET_ERROR: {
            return {
                ...state,
                topicSetInitData: {
                    ...state.topicSetInitData,
                    loading: false,
                },
            };
        }

        case GET_TOPICS_REQUEST: {
            return {
                ...state,
                topics: {
                    ...state.topics,
                    loading: true,
                },
                socket: {
                    ...state.socket,
                    actionName: action.type,
                    requestData: action.body,
                },
            };
        }

        case GET_TOPICS_SUCCESS: {
            const data = action.response.id ? null : action.response;

            return {
                ...state,
                topics: {
                    ...state.topics,
                    error: '',
                    data,
                    loading: !data,
                },
                socket: {
                    actionName: !data ? state.socket.actionName : null,
                    requestData: !data ? state.socket.requestData : null,
                    id: !data ? action.response.id : null,
                },
            };
        }

        case GET_TOPICS_ERROR: {
            return {
                ...state,
                topics: {
                    ...state.topics,
                    loading: false,
                },
            };
        }

        case GET_GRAPHS_REQUEST: {
            return {
                ...state,
                graphs: {
                    ...state.graphs,
                    loading: true,
                },
                socket: {
                    ...state.socket,
                    actionName: action.type,
                    requestData: action.body,
                },
            };
        }

        case GET_GRAPHS_SUCCESS: {
            const data = action.response.id ? null : action.response.data;

            return {
                ...state,
                graphs: {
                    ...state.graphs,
                    error: '',
                    data,
                    loading: !data,
                },
                socket: {
                    actionName: !data ? state.socket.actionName : null,
                    requestData: !data ? state.socket.requestData : null,
                    id: !data ? action.response.id : null,
                },
            };
        }

        case GET_GRAPHS_ERROR: {
            return {
                ...state,
                graphs: {
                    ...state.graphs,
                    loading: false,
                },
            };
        }

        case AI_SOCKED_ERROR: {
            const { actionName, error } : { actionName: ActionTypes, error: string } = action.payload;
            const reduxSliceName = requestMethods[actionName] as keyof IInitialState;
            const reduxSlice = getInitialState();

            return {
                ...state,
                [reduxSliceName]: {
                    ...reduxSlice[reduxSliceName],
                    error,
                },
            };
        }

        case GET_SOURCES_TOPIC_SET_REQUEST: {
            return {
                ...state,
                sources: {
                    ...state.sources,
                    loading: true,
                },
            };
        }

        case GET_SOURCES_TOPIC_SET_SUCCESS: {
            return {
                ...state,
                sources: {
                    ...state.sources,
                    loading: false,
                    data: [
                        ...state.sources.data,
                        ...action.response.data,
                    ],
                    totalCount: action.response.count,
                },
            };
        }

        case GET_SOURCES_TOPIC_SET_ERROR: {
            return {
                ...state,
                sources: {
                    ...state.sources,
                    loading: false,
                },
            };
        }

        case UPDATE_SOURCES_TOPIC_SET_FILTERS: {
            return {
                ...state,
                sources: {
                    ...state.sources,
                    filters: {
                        ...state.sources.filters,
                        ...action.payload,
                    },
                },
            };
        }

        case CLEANUP_TOPIC_SET_SOCKET: {
            return {
                ...state,
                socket: getInitialState().socket,
            };
        }

        case SAVE_TOPIC_SET_SUCCESS: {
            return {
                ...state,
                topicSetInitData: {
                    loading: false,
                    data: {
                        ...state.topicSetInitData.data,
                        title: action.response.title,
                        languages: action.response.languages,
                        categories: action.response.categories,
                    },
                },
            };
        }

        default:
            return state;
    }
};

export function getSources(filters: any, lang: string | undefined) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/source/`,
            method: 'GET',
            types: [ GET_SOURCES_TOPIC_SET_REQUEST, GET_SOURCES_TOPIC_SET_SUCCESS, GET_SOURCES_TOPIC_SET_ERROR ],
            logger: true,
            contentType: 'application/json',
            body: {
                ...filters,
                sort: JSON.stringify({ title: 'asc' }),
                'filters[languages.id][]': lang,
            },
        },
    };
}

export function getTopics({ scopeType, queryId, ...body }: any) {
    return {
        [CALL_API]: {
            endpoint: scopeType === 'mine'
                ? `/public_api/mine/query/${queryId}/find-labels`
                : `/public_api/voc-ai/topic-set/find-labels`,
            method: 'POST',
            types: [ GET_TOPICS_REQUEST, GET_TOPICS_SUCCESS, GET_TOPICS_ERROR ],
            logger: true,
            loggerMessageIsPrior: true,
            loggerMessages: {
                success: '',
                error: 'Something went wrong',
            },
            contentType: 'application/json',
            body,
        },
    };
}

export function getGraphs({ scopeType, queryId, ...body }: any) {
    return {
        [CALL_API]: {
            endpoint: scopeType === 'mine'
                ? `/public_api/mine/query/${queryId}/assign-labels`
                : `/public_api/voc-ai/topic-set/assign-labels`,
            method: 'POST',
            types: [ GET_GRAPHS_REQUEST, GET_GRAPHS_SUCCESS, GET_GRAPHS_ERROR ],
            logger: true,
            loggerMessageIsPrior: true,
            loggerMessages: {
                success: '',
                error: 'Something went wrong',
            },
            contentType: 'application/json',
            body,
        },
    };
}

export function getTopicSet(id: string) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set/${id}`,
            method: 'GET',
            types: [ GET_TOPIC_SET_REQUEST, GET_TOPIC_SET_SUCCESS, GET_TOPIC_SET_ERROR ],
            logger: false,
            contentType: 'application/json',
        },
    };
}

export function aiSockedWithError(actionName: string, queryId: string, error: string) {
    return {
        type: AI_SOCKED_ERROR,
        payload: { actionName, queryId, error },
    };
}

export function saveTopicSet(body: any) {
    const { topicSetId, ...restParams } = body;

    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set/${topicSetId || ''}`,
            method: topicSetId ? 'PUT' : 'POST',
            types: [ SAVE_TOPIC_SET_REQUEST, SAVE_TOPIC_SET_SUCCESS, SAVE_TOPIC_SET_ERROR ],
            logger: true,
            loggerMessages: {
                success: 'Topic set saved',
                error: 'Something went wrong',
            },
            contentType: 'application/json',
            body: restParams,
        },
    };
}

export function updateSourcesFilters(filters: IFilters) {
    return {
        type: UPDATE_SOURCES_TOPIC_SET_FILTERS,
        payload: filters,
    };
}

export function cleanupTopicSet() {
    return {
        type: CLEANUP_TOPIC_SET,
    };
}

export function cleanupTopicsAndGraphs() {
    return {
        type: CLEANUP_TOPICS_AND_GRAPHS,
    };
}

export function cleanupTopicSetSocket() {
    return {
        type: CLEANUP_TOPIC_SET_SOCKET,
    };
}

export function cleanupTopicSetSources() {
    return {
        type: CLEANUP_TOPIC_SET_SOURCES,
    };
}
