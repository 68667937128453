import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { TableFilter } from "/components";

import './PermissionTableFilter.scss';

export const PermissionTableFilter = ({
    sortData,
    onSortFilter,
    filterData,
    filterActive,
    disabled,
}) => {
    const [ openFilter, setOpenFilter ] = useState(false);

    const onChange = value => {
        if(typeof value === 'string') {
            onSortFilter({
                type: 'sort',
                value: value,
            });
        } else {
            onSortFilter({
                type: 'filter',
                value: value.value,
            });
        }
    };

    const getSortIcon = sortType => !sortType
        ? <FontAwesomeIcon icon={ 'sort' }/>
        : <FontAwesomeIcon
            icon={ sortType === 'asc' ? 'angle-down' : 'angle-up' }
        />;

    return (
        <div className={ classNames(
            'share-rights-permission-table-filter__wrapper',
            {
                'share-rights-permission-table-filter__wrapper-disabled': disabled,
            },
        ) }>
            <div className='share-rights-permission-table-filter__item'>
                <button onClick={ () => onChange('label') }>
                    <b>User</b>
                    {
                        getSortIcon(sortData.key === 'label' && sortData.sortType)
                    }
                </button>
            </div>
            <div className='share-rights-permission-table-filter__item'>
                <button
                    id='share-rights-filter'
                    className={ filterActive ? 'share-rights-filter__active-filter' : '' }
                >
                    <FontAwesomeIcon icon={ 'filter' }/>
                </button>
                <button onClick={ () => onChange('permission') }>
                    <b>Right</b>
                    {
                        getSortIcon(sortData.key === 'permission' && sortData.sortType)
                    }
                </button>
                <TableFilter
                    isOpenPopover={ openFilter }
                    togglePopover={ () => setOpenFilter(!openFilter) }
                    targetFilter='share-rights-filter'
                    filterData={ filterData }
                    apply={ onChange }
                    name='rights'
                    className={ 'share-rights-permission-table-filter__tableFilter' }
                />
            </div>
        </div>
    );
};
