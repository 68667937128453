import React from 'react';
import PropTypes from 'prop-types';
import { FormText } from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { MenuList, DropdownIndicator, Option } from '/components';

export const VocFormSelectField = ({
    input,
    className,
    type,
    placeholder,
    isClearable,
    openOnFocus,
    options,
    required,
    isMulti,
    withError,
    disabled,
    portal,
    meta: {
        touched,
        error,
    },
    isCreatable,
}) => {
    const blurHandler = () => {
        input.onBlur();
    };

    const onChange = option => {
        input.onChange(option);
    };

    const configForMulti = {};

    if (isMulti) {
        configForMulti['isMulti'] = isMulti;
        configForMulti['closeMenuOnSelect'] = false;
        configForMulti['onChange'] = onChange;
    }

    const config = {
        ...input,
        ...configForMulti,
        type,
        placeholder,
        required,
        components: { MenuList, DropdownIndicator, Option },
        value: input.value,
        id: input.name,
        openOnFocus: openOnFocus,
        options,
        isDisabled: disabled,
        className: `vochub-select-control ${ className }`,
        classNamePrefix: 'vochub-select-control',
        onBlur: blurHandler,
        error: Boolean(touched && error),
        isClearable,
    };

    if (portal) {
        config['menuPortalTarget'] = document.body;
        config['styles'] = { menuPortal: base => ({ ...base, zIndex: 9999 }) };
        config['menuShouldScrollIntoView'] = false;
        config['menuPlacement'] = "auto";
    }

    return (
        <div className={ `${ className } app-form-control__container ${ withError ? 'with-error' : '' }` }>
            {
                isCreatable
                    ? <CreatableSelect { ...config }/>
                    : <Select{ ...config }/>
            }
            {
                Boolean(touched && error && withError)
                    ? <FormText className="app-form-control__error">{ error }</FormText>
                    : false
            }
        </div>
    );
};

VocFormSelectField.propTypes = {
    className: PropTypes.string,
    withError: PropTypes.bool,
    openOnFocus: PropTypes.bool,
    options: PropTypes.array,
    isCreatable: PropTypes.bool,
    isClearable: PropTypes.bool,
};

VocFormSelectField.defaultProps = {
    withError: true,
    isClearable: false,
    isCreatable: false,
    isMulti: false,
    openOnFocus: false,
    className: '',
};
