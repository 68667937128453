import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { VocFormSelectField, VocFormTextField } from '/components';
import iconCross from '/images/icon-cross.svg';
import { exception } from '/feedback/modules/VocFeedback.selectors';
import { HelperService } from '/services';

export const renderConditionFlow = ({ fields, flowOptions, changeCondition }) => {
    return (
        fields.map((field, index) => {
            const { expression, matchingValue, question } = flowOptions[index];
            const fieldValue = fields.get(index);
            const isNotEmpty = fieldValue.expression ? !exception.includes(fieldValue.expression.value) : true;

            return (
                <Row key={ index } className='condition-row__box'>
                    <Col md={ 11 }>
                        <Row className='condition__row'>
                            <Col md={ 4 }>
                                <Field
                                    name={ `${ field }.question_id` }
                                    component={ VocFormSelectField }
                                    options={ question }
                                    placeholder={ 'Select' }
                                    onChange={ el => changeCondition(index, el, 'question_id') }
                                    required
                                    portal
                                    isCreatable
                                    format={ val => val && val.label && val.value ? val : null }
                                />
                            </Col>
                            <Col md={ 4 }>
                                <Field
                                    name={ `${ field }.expression` }
                                    component={ VocFormSelectField }
                                    options={ expression }
                                    placeholder={ 'Select' }
                                    onChange={ el => changeCondition(index, el, 'expression') }
                                    required
                                    portal
                                />
                            </Col>
                            <Col md={ 4 }>
                                {
                                    matchingValue.length && isNotEmpty
                                        ? <Field
                                            name={ `${ field }.matching_value` }
                                            component={ VocFormSelectField }
                                            options={ matchingValue }
                                            placeholder={ 'Select' }
                                            onChange={ el => changeCondition(index, el, 'matching_value') }
                                            required
                                            portal
                                        />
                                        : <Field
                                            type={ 'text' }
                                            placeholder={ 'Fill value' }
                                            name={ `${ field }.matching_value` }
                                            defaultValue={ '' }
                                            disabled={ !isNotEmpty }
                                            component={ VocFormTextField }
                                            required={ isNotEmpty }
                                            withError={ isNotEmpty }
                                            normalize={ val => val.replace(/^\s+/, "") }
                                            format={ val => (HelperService.isObjectValue(val) || !val) ? '' : val }
                                        />
                                }
                            </Col>
                        </Row>

                    </Col>

                    <Col md={ 1 }>
                        <span onClick={ () => fields.remove(index) } className='condition-flow__icon'>
                            <img src={ iconCross } alt=""/>
                        </span>
                    </Col>
                </Row>
            );
        },
        )
    );
};
