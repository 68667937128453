import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';
import {
    getLayoutsList,
    getUnitThemeList,
    getSMSGatewaysList,
} from '/feedback/modules/VocFeedback.modules';
import { getThemesBySurvey } from '/feedback/scenes/Survey/modules/survey.modules';

import { SettingsService } from '/feedback/services/SettingsService';

export const GET_SURVEY_SETTINGS_REQUEST = 'GET_SURVEY_SETTINGS_REQUEST';
export const GET_SURVEY_SETTINGS_SUCCESS = 'GET_SURVEY_SETTINGS_SUCCESS';
export const GET_SURVEY_SETTINGS_ERROR = 'GET_SURVEY_SETTINGS_ERROR';

export const PUT_SURVEY_SETTINGS_REQUEST = 'PUT_SURVEY_SETTINGS_REQUEST';
export const PUT_SURVEY_SETTINGS_SUCCESS = 'PUT_SURVEY_SETTINGS_SUCCESS';
export const PUT_SURVEY_SETTINGS_ERROR = 'PUT_SURVEY_SETTINGS_ERROR';

export const GET_CHANNEL_SETTINGS_REQUEST = 'GET_CHANNEL_SETTINGS_REQUEST';
export const GET_CHANNEL_SETTINGS_SUCCESS = 'GET_CHANNEL_SETTINGS_SUCCESS';
export const GET_CHANNEL_SETTINGS_ERROR = 'GET_CHANNEL_SETTINGS_ERROR';

export const SURVEY_SETTINGS_LOADING = 'SURVEY_SETTINGS_LOADING';
export const SURVEY_SETTINGS_LOADED = 'SURVEY_SETTINGS_LOADED';

export const SET_DIGI_THEME_SETTINGS = 'SET_DIGI_THEME_SETTINGS';

export const ATTACH_THEME_TO_SURVEY_REQUEST = 'ATTACH_THEME_TO_SURVEY_REQUEST';
export const ATTACH_THEME_TO_SURVEY_SUCCESS = 'ATTACH_THEME_TO_SURVEY_SUCCESS';
export const ATTACH_THEME_TO_SURVEY_ERROR = 'ATTACH_THEME_TO_SURVEY_ERROR';

export const UPDATE_SURVEY_THEME_REQUEST = 'UPDATE_SURVEY_THEME_REQUEST';
export const UPDATE_SURVEY_THEME_SUCCESS = 'UPDATE_SURVEY_THEME_SUCCESS';
export const UPDATE_SURVEY_THEME_ERROR = 'UPDATE_SURVEY_THEME_ERROR';

export const CREATE_SURVEY_THEME_REQUEST = 'CREATE_SURVEY_THEME_REQUEST';
export const CREATE_SURVEY_THEME_SUCCESS = 'CREATE_SURVEY_THEME_SUCCESS';
export const CREATE_SURVEY_THEME_ERROR = 'CREATE_SURVEY_THEME_ERROR';

export const SURVEY_SETTINGS_CLEANUP = 'SURVEY_SETTINGS_CLEANUP';

export const modelKeys = {
    ...SettingsService.getKeysForFilter('advanced', false),
};

function getInitialState() {
    return {
        loading: true,
        sending: false,
        surveySettings: {},
        globalSettings: {},
        layouts: [],
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case SURVEY_SETTINGS_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case SURVEY_SETTINGS_LOADED: {
            return {
                ...state,
                loading: false,
            };
        }

        case PUT_SURVEY_SETTINGS_REQUEST: {
            return {
                ...state,
                sending: true,
            };
        }

        case PUT_SURVEY_SETTINGS_ERROR: {
            return {
                ...state,
                sending: false,
            };
        }

        case PUT_SURVEY_SETTINGS_SUCCESS: {
            return {
                ...state,
                surveySettings: {
                    ...state.surveySettings,
                    ...HelperService.pick(action.response, modelKeys[action.params]),
                },
                sending: false,
            };
        }

        case GET_CHANNEL_SETTINGS_SUCCESS: {
            return {
                ...state,
                globalSettings: {
                    ...state.globalSettings,
                    ...HelperService.pick(action.response, modelKeys[action.params]),
                },
            };
        }

        case SET_DIGI_THEME_SETTINGS: {
            return {
                ...state,
                globalSettings: {
                    ...state.globalSettings,
                    ...HelperService.pick(action.payload, modelKeys['themeDIGI']),
                },
            };
        }

        case GET_SURVEY_SETTINGS_SUCCESS: {
            return {
                ...state,
                surveySettings: {
                    ...state.surveySettings,
                    ...HelperService.pick(action.response, modelKeys[action.params]),
                },
            };
        }

        case GET_CHANNEL_SETTINGS_ERROR:
        case GET_SURVEY_SETTINGS_ERROR: {
            return {
                ...state,
            };
        }

        case SURVEY_SETTINGS_CLEANUP: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getSettings(obj) {
    return HelperService.pick({ ...obj }, modelKeys['themeDIGI']);
}

export function manegeSurveyTheme(surveyTheme, model, idChannel, idTheme) {
    return async dispatch => {
        let request,
            errorObject = null;

        const modelTheme = getSettings(model.body),
            modelSettings = HelperService.pick({ ...model.body }, modelKeys['DIGI']);

        const {
            deferredResolve,
            deferredReject,
            promise,
        } = HelperService.getDeferredPromise();

        if (surveyTheme !== null) {
            try {
                request = await dispatch(updateSurveyTheme({
                    ...modelTheme,
                    id: surveyTheme.id,
                    parent_theme: idTheme,
                })).promise;
            } catch (error) {
                console.error(error);
                errorObject = {
                    ...errorObject,
                    ...error.response,
                };
            }
        } else {
            const isChanged = Object.keys(modelTheme).some(el => modelTheme[el]);

            try {
                request = isChanged
                    ? await dispatch(createSurveyTheme({
                        ...modelTheme,
                        name: `new survey theme for ${ idChannel }`,
                        survey: idChannel,
                        parent_theme: idTheme,
                    })).promise
                    : null;
            } catch (error) {
                console.error(error);
                errorObject = {
                    ...errorObject,
                    ...error.response,
                };
            }
        }

        try {
            dispatch(getThemesBySurvey(idChannel));
            await dispatch(putSurveySettingsRequest({ ...model, body: { ...modelSettings }, idChannel })).promise;
        } catch (error) {
            console.error(error);
            errorObject = {
                ...errorObject,
                ...error.response,
            };
        }

        if (!errorObject) {
            deferredResolve(request);
        } else {
            deferredReject({ response: { ...errorObject } });
        }

        return promise.catch(SettingsService.catchSettingsErrors);
    };
}

export function getSurveySettings({ idChannel, ...params }) {
    return dispatch => {
        dispatch(startProcessing());
        const channelSettingRequest = dispatch(getChannelSurveySetting(params));
        const surveySettingRequest = dispatch(getSurveySetting(params));
        const requests = [ channelSettingRequest, surveySettingRequest ];
        const promises = [ channelSettingRequest.promise, surveySettingRequest.promise ];

        let request;

        if (params.channel === 'WEB') {
            request = dispatch(getLayoutsList());
        } else if (params.channel === 'SMS') {
            request = dispatch(getSMSGatewaysList());
        } else if (params.channel === 'DIGI') {
            request = [
                dispatch(getUnitThemeList(params.unit)),
                dispatch(getThemesBySurvey(idChannel)),
            ];
        }

        if (request && request.length) {
            request.forEach(el => {
                promises.push(el.promise);
                requests.push(el);
            });
        } else if (request) {
            promises.push(request.promise);
            requests.push(request);
        }

        Promise.all(promises)
            .then(() => dispatch(endProcessing()), ({ status }) => {
                if (status !== 0) {
                    return dispatch(endProcessing());
                }
            });

        return requests;
    };
}

export function putSurveySettings(model) {
    return dispatch => {
        return dispatch(putSurveySettingsRequest(model)).promise
            .catch(SettingsService.catchSettingsErrors);
    };
}

export function startProcessing() {
    return {
        type: SURVEY_SETTINGS_LOADING,
    };
}

export function endProcessing() {
    return {
        type: SURVEY_SETTINGS_LOADED,
    };
}

export function getChannelSurveySetting({ channel, unit }) {
    return {
        [CALL_API]: {
            endpoint: `unit/${ unit }/get-channel-settings/${ channel }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CHANNEL_SETTINGS_REQUEST, GET_CHANNEL_SETTINGS_SUCCESS, GET_CHANNEL_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            params: channel,
        },
    };
}

export function getSurveySetting({ surveyGroup, channel }) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${ surveyGroup }/get-channel-settings/${ channel }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_SETTINGS_REQUEST, GET_SURVEY_SETTINGS_SUCCESS, GET_SURVEY_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            params: channel,
        },
    };
}

export function putSurveySettingsRequest({ id, body, channel }) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${ id }/set-settings/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ PUT_SURVEY_SETTINGS_REQUEST, PUT_SURVEY_SETTINGS_SUCCESS, PUT_SURVEY_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            params: channel,
            body,
            logger: true,
            loggerMessages: {
                success: 'Survey settings were successfully updated',
                error: 'Something went wrong',
            },
        },
    };
}

export function attachThemeToSurvey(body) {
    return {
        [CALL_API]: {
            endpoint: '/digi-theme/attach-theme/',
            method: 'PATCH',
            contentType: 'application/json',
            types: [
                ATTACH_THEME_TO_SURVEY_REQUEST,
                ATTACH_THEME_TO_SURVEY_SUCCESS,
                ATTACH_THEME_TO_SURVEY_ERROR,
            ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function createSurveyTheme(body) {
    return {
        [CALL_API]: {
            endpoint: '/digi-theme/digi-theme-survey/',
            method: 'POST',
            contentType: 'application/json',
            types: [
                CREATE_SURVEY_THEME_REQUEST,
                CREATE_SURVEY_THEME_SUCCESS,
                CREATE_SURVEY_THEME_ERROR,
            ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function updateSurveyTheme(body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/digi-theme-survey-update/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [
                UPDATE_SURVEY_THEME_REQUEST,
                UPDATE_SURVEY_THEME_SUCCESS,
                UPDATE_SURVEY_THEME_ERROR,
            ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function cleanup() {
    return {
        type: SURVEY_SETTINGS_CLEANUP,
    };
}
