import { scaleOrdinal } from 'd3';
import moment from 'moment';

import { HelperService } from '/services';
import { DependencyMatrixService } from '/visual/services';
import {
    barData, barSliceData, chartDataItem, color,
    contentSettingsFunc, drillDownOptionType, EUpdateGadgetTypes,
    filterLabel, filterRequiredWithScore, IAddMissingNpsSegments,
    ICheckNPS, IDataForFormatting, IFormattedDataByChartType,
    IGadgetData, IGetColor, IGetRandomColorSet, lineItem,
    lineLabel, mockNpsType, TNpsItemWithParts, TNpsItemPart, preLineItem,
    preLineLabel, THighlightGadget, TLabelData, TPieSegment,
    TRangeTooltipValueFormatter, wordDataType, TGetCustomChartElementId, TParentData,
} from '../models';
import { allNpsSegments, charts, chartTypes, customIdReplacerStr, d3ColorSchema } from '../contstants';

export class GadgetService {
    static getRandomColorSet({ items, selectedColor }: IGetRandomColorSet) {
        let colorsForScaling = d3ColorSchema;

        if (selectedColor) {
            colorsForScaling = HelperService.getColorGradation(selectedColor, items.length);
        }

        return items.map(scaleOrdinal(colorsForScaling));
    }

    static getColor({ itemId, ids, colors, selectedColor, randomColorSet, func }: IGetColor) {
        // get color from prev saved settings
        let color = GadgetService.getSavedColor(
            HelperService.checkNotNullOrUndefined(itemId)
                ? itemId
                : 'No value',
            colors,
        );

        // check if the barSlice has Nps color
        if (!color && !selectedColor) {
            color = (func === 'affection')
                ? GadgetService.getAffectionColor(itemId)
                : GadgetService.getNpsColor(itemId);
        }

        // get from custom colors or set default
        if (!color) {
            color = randomColorSet[ids.findIndex((id: string) => itemId === id)];
        }

        return color;
    }

    static getSavedLabel(key: string | null, labels: TLabelData[], type?: string) {
        if (key === null || key === undefined) return null;

        return labels
            .filter(label => type ? label.type === type : label.type)
            .find(labelData => labelData.key === key?.toString().toLowerCase());
    }

    static getNpsColor(barId: string) {
        const npsColors: any = {
            detractors: '#d2322d',
            passives: '#ed9c28',
            promoters: '#47a447',
            npsscore: '#1975D1',
            'null': '#428bca',
        };

        return npsColors[barId.toString().toLowerCase()];
    }

    static getAffectionColor(id: string) {
        const affectionColors: any = {
            positive: '#47a447',
            negative: '#d2322d',
        };

        return affectionColors[id.toString().toLowerCase()];
    }

    static getSavedColor(id: string, colors: color[]) {
        const findSavedColor = colors
            .find((colorData: color) => colorData.key.toString() === id.toString());

        return findSavedColor?.value;
    }

    static getSavedColorIndex(id: string, colors: color[]) {
        return colors
            .findIndex((colorData: color) => colorData.key.toString() === id.toString());
    }

    static mapOrder(array: any[] = [], order: any[] = [], byKey: string) {
        if (!order) return array;

        return array.sort((a, b) => {
            if (order?.indexOf(a[byKey]) < 0) {
                return 0;
            }

            return order?.indexOf(a[byKey]) > order?.indexOf(b[byKey])
                ? 1
                : -1;
        });
    }

    static isNPSChart({ factTypes = [], func = '', secondGroupBy = '' }: ICheckNPS) {
        const hasOnlyNpsFactTypes = factTypes?.length
            && factTypes?.every(type => type === 'NPS_SEGMENT' || type === 'CHOICE_NPS_SEGMENT');

        return hasOnlyNpsFactTypes && func === 'count' && !secondGroupBy;
    }

    static addMissingEmptyNPSSegments({ segments = [], customIdMaker }: IAddMissingNpsSegments) {
        const missingSegments = allNpsSegments.filter(segment => !segments.find(item => item.id === segment));

        return segments.concat(missingSegments.map(segment => ({
            color: 'white',
            count: 0,
            customId: customIdMaker && typeof customIdMaker === 'function' ? customIdMaker(segment) : segment,
            id: segment,
            label: segment,
            percent: 0,
            score: 0,
            value: segment,
            values: [],
            percents: [],
            data: {
                absolute: [],
                relative: [],
            },
        })));
    }

    static getDefaultNpsData = () => ({
        data: [],
        id: '',
        label: '',
        color: 'black',
        customId: '',
    });

    static getNpsFormattedDataForLines({
        labels = [],
        lines = [],
        config,
    }: {
        labels: lineLabel[],
        lines: lineItem[],
        config: { id: string, label: string, colors: color[] },
    }) {
        const data = labels.map((label: lineLabel, index: number) => ({
            parts: lines
                .map((line: lineItem) => Object.assign({
                    percent: line.percents[index],
                    count: line.values[index],
                }, line))
                .filter((line: { id: string }) => line.id !== 'npsScore'),
            label: label.label,
            id: label.id,
        }));

        return GadgetService.getNpsFormattedData({ data, config });
    }

    static getNpsFormattedDataForBarGroups({
        groups = [],
        config,
    }: {
        groups: barData[],
        config: { id: string, label: string, colors: color[] },
    }) {
        const data = groups.map((barGroup: barData) => ({
            ...barGroup,
            parts: barGroup.bars,
        }));

        return GadgetService.getNpsFormattedData({ data, config });
    }

    static getNpsFormattedData({
        data = [],
        config,
    }: {
        data: TNpsItemWithParts[],
        config: { id: string, label: string, colors: color[] },
    }) {
        const complementNpsObject = (parts: TNpsItemPart[]) => {
            let isNps = false;
            const mockNpsObject = [
                {
                    id: 'Detractors',
                    label: 'Detractors',
                    percent: 0,
                    count: 0,
                    value: 'Detractors',
                },
                {
                    id: 'Passives',
                    label: 'Passives',
                    percent: 0,
                    count: 0,
                    value: 'Passives',
                },
                {
                    id: 'Promoters',
                    label: 'Promoters',
                    percent: 0,
                    count: 0,
                    value: 'Promoters',
                },
            ];

            const formattedNpsItem = mockNpsObject.map((mockItem: mockNpsType) => {
                let existedPart = null;

                parts.forEach(part => {
                    if ((part.id as string).toLowerCase() === mockItem.id.toLowerCase()) {
                        existedPart = part;
                        isNps = true;
                    }
                });

                return existedPart ? existedPart : mockItem;
            });

            return isNps ? formattedNpsItem : null;
        };

        const dataSet = data.map((npsItem: TNpsItemWithParts, index: number) => {
            let detractors = 0, promoters = 0, count = 0;

            if (npsItem.parts.length <= 3) {
                const complementedNpsObject = complementNpsObject(npsItem.parts);

                detractors = complementedNpsObject ? complementedNpsObject[0].percent : 0;
                promoters = complementedNpsObject ? complementedNpsObject[2].percent : 0;
            }

            for (let i = 0; i < npsItem.parts.length; i++) {
                const partCount = npsItem.parts[i].count as number;

                if (Array.isArray(partCount)) {
                    const currentCount = partCount[index];

                    count += currentCount !== null ? currentCount : 0;
                } else {
                    count += !isNaN(+partCount) ? partCount : 0;
                }
            }

            const customId = GadgetService.getCustomChartElementId({
                prefix: 'npsCircle',
                groupId: config.id.toString(),
                itemId: index.toString(),
            });

            return {
                id: npsItem.id,
                label: npsItem.label,
                score: promoters - detractors,
                count,
                customId,
            };
        });

        const customId = GadgetService.getCustomChartElementId({
            prefix: 'npsLine',
            itemId: config.id.toString(),
        });

        return {
            data: dataSet as any,
            id: config.id,
            label: config.label,
            customId,
            color: GadgetService.getSavedColor(config.id, config.colors) || GadgetService.getNpsColor(config.id),
        };
    }

    static getAffectionFormattedData({ items }: { items: chartDataItem[] }) {
        const dataSetWithTwoColumns = items
            .map((item: chartDataItem) => ({
                ...item,
                items: item.items.map((subItem: chartDataItem) => ({
                    ...subItem,
                    affection: Math.abs(+subItem.id - 7) * subItem.count,
                })),
            }))
            .map((item: chartDataItem) => {
                const thirdLevelItem = item.items;

                const getGroupSum = (type: string) => {
                    return thirdLevelItem.reduce((acc: number, item: chartDataItem) => {
                        const itemId = +item.id;

                        if (type === 'positive') {
                            return acc + (itemId > 8 ? item.count * itemId : 0);
                        } else {
                            return acc + (itemId >= 0 && itemId < 7 ? item.count * itemId : 0);
                        }
                    }, 0);
                };

                const getAccumulation = (type: string, func: string) => {
                    return thirdLevelItem.reduce((acc: number, item: chartDataItem) => {
                        const itemId = +item.id;
                        const valueByFunc = item[func as keyof contentSettingsFunc];

                        if (type === 'positive') {
                            return itemId > 8 && valueByFunc !== null ? acc + valueByFunc : acc;
                        } else {
                            return itemId >= 0 && itemId < 7 && valueByFunc !== null ? acc + valueByFunc : acc;
                        }
                    }, 0);
                };

                const getAverage = (type: string, func: string, defaultValue: number) => {
                    const average = getGroupSum(type) / getAccumulation(type, func);

                    return isNaN(average) ? defaultValue : average;
                };

                return {
                    ...item,
                    items: [
                        {
                            accSum: 0,
                            affection: getAccumulation('negative', 'affection'),
                            average: getAverage('negative', 'count', 0),
                            count: getAccumulation('negative', 'count'),
                            id: 'negative',
                            items: thirdLevelItem
                                .filter(({ id }: { id: string }) => +id > 0 && +id < 7)
                                .map((elem: chartDataItem) => ({ ...elem, level: 1 })),
                            label: 'Negative',
                            level: 2,
                            median: 0,
                            name: 'Negative',
                            npsScore: 0,
                            percent: null,
                            sum: 0,
                            type: 'mixed',
                            variance: 0,
                        },
                        {
                            accSum: 0,
                            affection: getAccumulation('positive', 'affection'),
                            average: getAverage('positive', 'count', 10),
                            count: getAccumulation('positive', 'count'),
                            id: 'positive',
                            items: thirdLevelItem
                                .filter(({ id }: { id: string }) => +id > 8)
                                .map((elem: chartDataItem) => ({ ...elem, level: 1 })),
                            label: 'Positive',
                            level: 2,
                            median: 0,
                            name: 'Positive',
                            npsScore: 0,
                            percent: null,
                            sum: 0,
                            type: 'mixed',
                            variance: 0,
                        },
                    ],
                };
            });

        const allDetractors = dataSetWithTwoColumns
            .reduce((acc: number, item: chartDataItem) => acc + item.items[0].count, 0);

        return dataSetWithTwoColumns
            .map((item: chartDataItem) => {
                const deltaMinMax = Math.abs(item.items[1].average - item.items[0].average);
                const offset = item.items[0].count * 1000 / allDetractors;

                return {
                    ...item,
                    priority: Math.round(deltaMinMax * offset) / 1000,
                };
            })
            .sort((a: any, b: any) => {
                return b.priority - a.priority;
            });
    }

    static getOrderingList({ cleanOrdering, clientOrdering }: { cleanOrdering: string[], clientOrdering: string[] }) {
        let orderingList = [] as string[];

        const commonKeys = cleanOrdering.filter((value: string) => clientOrdering.includes(value));
        const distinctKeys = HelperService.arrDiff(clientOrdering, cleanOrdering);

        if (cleanOrdering.length === 0 || commonKeys.length === 0) {
            return clientOrdering;
        }

        if (cleanOrdering.length < clientOrdering.length) {
            orderingList = cleanOrdering.concat(distinctKeys);
        }

        if (cleanOrdering.length > clientOrdering.length) {
            orderingList = commonKeys.concat(distinctKeys);
        }

        if (cleanOrdering.length === clientOrdering.length) {
            orderingList = cleanOrdering;
        }

        return orderingList;
    }

    static getBarsDefaultOrder({ data, typeProp }: { data: any, typeProp: string }) {
        const barsId = data.flatMap((item: any) => item[typeProp].map(({ id }: { id: string }) => id));

        return GadgetService.getDefaultOrder({ ids: barsId });
    }

    static getDefaultOrder({ ids = [] }: { ids: any[] }) {
        const dateMasks = [ 'DD/MM/YYYY', 'MM/YYYY', 'DD.MM.YYYY', 'w / YYYY', 'MM.YYYY' ];
        const uniqId = HelperService.uniqeArray(ids, null);

        const isNumber = (id: any) => typeof id === 'number';
        const isDate = (id: any) => (dateMasks.some((mask: string) => moment(id, mask, true).isValid()));
        const isString = (id: any) => !isNumber(id) && !isDate(id);

        const numericIds = uniqId.filter(isNumber).sort((a: number, b: number) => a - b);
        const dateIds = uniqId.filter(isDate).sort((a: string, b: string) => {
            const [ currentMask ] = dateMasks.filter((mask: string) => moment(a, mask, true).isValid());
            const dateA = moment(a, currentMask);
            const dateB = moment(b, currentMask);

            return dateA.diff(dateB);
        });
        const stringIds = uniqId.filter(isString).sort();

        return numericIds.concat(stringIds).concat(dateIds);
    }

    static getChartSizes({
        wrapperRef,
        isNpsChart = false,
        chartType,
        xAxisSize = 0,
        showScore = false,
    }: {
        wrapperRef: HTMLDivElement | null,
        isNpsChart?: boolean,
        chartType: string,
        xAxisSize?: number | null,
        showScore?: boolean | null,
    }) {
        const wrapperWidth = wrapperRef?.clientWidth || 0;
        const wrapperHeight = wrapperRef?.clientHeight || 0;
        const {
            minInternalWidth,
            minInternalHeight,
            yLeftLabelOffset,
            getYRightLabelOffset,
            xLabelOffset,
            yTickOffset,
            marginTop,
            marginBottom,
            marginTopWithTitles,
            rightOffset,
        } = charts[chartType];
        const yRightLabelOffset = getYRightLabelOffset(isNpsChart);

        const showYLabels = wrapperWidth > minInternalWidth;
        const showXLabels = wrapperHeight > minInternalHeight;
        const internalWidth = showYLabels ? wrapperWidth - yLeftLabelOffset - yRightLabelOffset : wrapperWidth - (isNpsChart ? 0 : rightOffset);
        const internalHeight = showXLabels ? wrapperHeight - xLabelOffset : wrapperHeight;
        const boundedWidth = internalWidth - yTickOffset * (isNpsChart ? 2 : 1) - rightOffset;
        let boundedHeight = internalHeight - (showScore && marginTopWithTitles ? marginTopWithTitles : marginTop);

        // if xAxisSize is null or 0, still add marginBottom so that the charts maintain consistent height
        // and negative charts don't get hidden by the SVG size
        if (xAxisSize) {
            boundedHeight = boundedHeight - xAxisSize;
        } else {
            boundedHeight = boundedHeight - marginBottom;
        }

        return {
            showYLabels,
            showXLabels,
            internalWidth: Math.round(internalWidth),
            internalHeight: Math.round(internalHeight),
            boundedWidth: Math.round(boundedWidth),
            boundedHeight: Math.round(boundedHeight),
        };
    }

    static isDrillDownEnable({ gadget }: { gadget: IGadgetData }) {
        type resultType = {
            errorMessageTransKey: string | null,
            isEnabled: boolean,
        };

        const result: resultType = {
            errorMessageTransKey: null,
            isEnabled: gadget.contentSettings?.drillDown,
        };

        const isMultiFuncSuperChart = (gadget.dataSettings.facts || []).length > 1;

        if (isMultiFuncSuperChart && gadget.contentSettings.chartType !== chartTypes.WORD && gadget.contentSettings?.drillDown) {
            result.errorMessageTransKey = 'warningText.drillDownMultifactError';
            result.isEnabled = false;
        }

        return result;
    }

    static getDrillDownFilterValue({
        group,
        superGroup,
        func,
        groupBy,
        secondGroupBy,
    }: {
        group: string | number | null,
        superGroup: string | number | null,
        func: string,
        groupBy: string | null,
        secondGroupBy: string | null,
    }) {
        if (![ 'count', 'just_show' ].includes(func)) {
            if (!groupBy) return { group: null, superGroup: null };
            if (func === 'affection') return { group: null, superGroup };
            if (groupBy && !secondGroupBy) return { group: null, superGroup: group };

            return { group, superGroup };
        } else {
            return { group, superGroup };
        }
    }

    static getSuperGroupByChartType({
        superGroup,
        chartType,
        level,
    }: {
        superGroup?: { id: string | number | null } | null,
        chartType: string,
        level?: number,
    }) {
        switch (chartType) {
            case chartTypes.LINE:
                return superGroup && level !== 2 ? superGroup.id : null;
            case chartTypes.WORD:
            case chartTypes.PIE:
                return null;
            case chartTypes.STACKED_BAR:
            case chartTypes.SURVEYS_BAR:
                return superGroup ? superGroup.id : null;

            default:
                return superGroup ? superGroup.id : null;
        }
    }

    static isDrillDownDashboardExist(gadget: IGadgetData): boolean {
        return Boolean(gadget?.hasChildDashboard && gadget?.childDashboardId);
    }

    static getFormattedDataByChartType({
        chartType,
        dataForFormatting,
    }: IFormattedDataByChartType) {
        switch (chartType) {
            case chartTypes.SURVEYS_BAR:
            case chartTypes.STACKED_BAR:
                return GadgetService.formatBarDataHandler(dataForFormatting);
            case chartTypes.LINE:
                return GadgetService.formatLineDataHandler(dataForFormatting);
            case chartTypes.PIE:
                return GadgetService.formatPieDataHandler(dataForFormatting);
            case chartTypes.WORD:
                return GadgetService.formatWordCloudHandler(dataForFormatting);
        }
    }

    static formatBarDataHandler({ chartData, gadgetData, forSelectorOnly = false }: IDataForFormatting) {
        const {
            dataSettings,
            contentSettings,
            parent,
            id: gadgetId,
            type: gadgetType,
            labels,
            colors,
        } = gadgetData;
        const {
            items,
            count,
            level,
            id: rootId,
            variance,
            average,
            percent,
            npsScore,
            median,
            sum,
            affection,
        } = chartData;
        const { function: func, chartType, order: settingsOrder } = contentSettings;
        const { secondGroupBy, factTypes, factName, groupBy, facts } = dataSettings;
        // custom color
        const customColorSet = JSON.parse(dataSettings.customColorSet);
        const selectedColor = customColorSet && customColorSet.other[customColorSet.theOne];
        const inheritedLabels = parent ? parent.labels : [];
        const isNpsChart = GadgetService.isNPSChart({
            factTypes: factTypes,
            func: func,
            secondGroupBy: secondGroupBy,
        }) && DependencyMatrixService.isNpsSegmentChart(chartType);
        let npsData = GadgetService.getDefaultNpsData();

        const groupConverter = (group: chartDataItem, parentData: TParentData) => {
            const groupId = (group.id !== '' ? group.id : group.name).toString();
            const customId = GadgetService.getCustomChartElementId({
                prefix: `bar__gadget_${ gadgetId }`,
                groupId: parentData.id,
                itemId: groupId,
            });

            return {
                id: group.id,
                customId,
                label: group.name,
                value: group.id,
                average: group.average,
                affection: group.affection,
                count: group.count,
                median: group.median,
                npsScore: group.npsScore,
                percent: group.percent,
                sum: group.sum,
                variance: group.variance,
                bars: group.items,
                parentData,
            };
        };

        const toBarFormat = (element: barSliceData, total: number) => {
            const allLabels = labels.concat(inheritedLabels);
            const scoreValue = element[func as keyof contentSettingsFunc];
            let eLabel = element.label;
            let eId = (element.id === undefined) ? eLabel : element.id;

            // fix for empty label 'super_chart'
            if (gadgetType === 'super_chart' && (eId === '' || eId === 'root')) {
                const valueIdAndLabel = (func !== 'count' && !groupBy) ? factName : 'No value';

                eId = valueIdAndLabel;
                eLabel = valueIdAndLabel;
            }

            return {
                color: 'white',
                count: element.count,
                customId: element.customId,
                id: eId,
                label: GadgetService.getSavedLabel(eId, allLabels, 'legend')?.value || eLabel,
                parentData: element.parentData,
                percent: element.percent !== null ? element.percent : (element.count / total) * 100,
                score: (func && HelperService.checkNotNullOrUndefined(scoreValue))
                    ? scoreValue as number
                    : element.count,
                value: element.value,
            };
        };

        const toGroupFormat = (group: barData) => {
            const label = chartType === 'surveys_bar_chart' && (secondGroupBy || groupBy)
                ? GadgetService.getSavedLabel(group.id, labels, 'tick')?.value || group.label
                : group.label;
            const total = group.count !== null
                ? group.count
                : HelperService.sumBy(group.bars, (item: barData) => item.count);

            return {
                customId: group.customId,
                bars: group.bars.map((bar: barSliceData) => toBarFormat(bar, total)),
                id: group.value,
                label: label,
                value: group.value,
                count: group.count,
                percent: group.percent,
                total: group.bars.length,
                totalAnswers: group.bars.length,
                type: 'barGroup',
            };
        };

        const formatBarDataForLevel3 = () => items.map((item: chartDataItem) => {
            const savedItemId = GadgetService.getSavedLabel(item.id, labels, 'tick')?.value;
            const parentLabel = HelperService.checkNotNullOrUndefined(savedItemId)
                ? savedItemId as string
                : item.id;
            const barSlicesParentData: TParentData = {
                label: parentLabel,
                id: item.id,
            };

            const barItems = item.items?.length > 0
                ? item.items
                : [ item ];

            const bars = barItems
                .map((elem: chartDataItem) => groupConverter(elem, barSlicesParentData));
            const itemId = item.id.toString().replace(customIdReplacerStr, '') !== ''
                ? item.id.toString()
                : item.name.toString();
            const customId = GadgetService.getCustomChartElementId({
                prefix: `barGroup__gadget_${ gadgetId }`,
                itemId,
            });

            return {
                customId,
                bars,
                id: item.id,
                label: GadgetService.getSavedLabel(item.id, labels, 'tick')?.value || item.name,
                value: item.id,
                count: item.count,
                percent: item.percent,
                total: bars.length,
                totalAnswers: bars.length,
                type: 'barGroup',
                variance: item.variance,
                average: item.average,
                npsScore: item.npsScore,
                median: item.median,
                sum: item.sum,
                affection: item.affection,
            };
        });

        const formatBarDataForLevel2 = () => {
            const customId = GadgetService.getCustomChartElementId({
                prefix: `barGroup__gadget_${ gadgetId }`,
                itemId: rootId.toString(),
            });

            const barSlicesParentData: TParentData = {
                label: rootId,
                id: rootId,
            };

            return [{
                customId,
                bars: items.map((elem: chartDataItem) => groupConverter(elem, barSlicesParentData)),
                id: rootId,
                label: '',
                value: rootId,
                count,
                percent,
                total: items.length,
                totalAnswers: items.length,
                type: 'barGroup',
                variance,
                average,
                npsScore,
                median,
                sum,
                affection,
            }];
        };

        let barGroups: barData[] = level > 2
            ? formatBarDataForLevel3()
            : formatBarDataForLevel2();

        let conditionToReduce;

        switch (chartType) {
            case 'stacked_bar_chart':
                conditionToReduce = (facts && facts.length)
                    && !secondGroupBy && func !== 'count';
                break;

            case 'surveys_bar_chart':
                conditionToReduce = (facts && facts.length)
                    && !secondGroupBy
                    && (func !== 'count' && func !== 'affection');
                break;

            default:
                conditionToReduce = false;
        }

        if (conditionToReduce) {
            barGroups = [{
                customId: '',
                bars: barGroups as barSliceData[],
                id: '',
                label: '',
                value: '',
                count: 0,
                percent: 0,
                total: barGroups.length,
                totalAnswers: barGroups.length,
                type: 'barGroup',
            }];
        }

        barGroups = barGroups.map(toGroupFormat);

        // ordering
        const serverOrdering = settingsOrder || [];
        const clientOrdering = GadgetService.getBarsDefaultOrder({ data: barGroups, typeProp: 'bars' });
        const cleanOrdering = serverOrdering.filter((x: string) => clientOrdering.includes(x));
        let order = GadgetService.getOrderingList({ cleanOrdering, clientOrdering });

        // order items according to tickOrder from contentSettings
        if (contentSettings.tickOrder) {
            barGroups = GadgetService.mapOrder(barGroups, contentSettings.tickOrder, 'id');
        }

        if (isNpsChart && !forSelectorOnly) {
            barGroups = barGroups.map((bar: barData) => ({
                ...bar,
                bars: GadgetService.addMissingEmptyNPSSegments({
                    segments: bar.bars,
                    customIdMaker: (id: string) => GadgetService.getCustomChartElementId({
                        prefix: `bar__gadget_${ gadgetId }`,
                        itemId: id.toString(),
                    }),
                }),
            }));
        }

        const allowSort = barGroups.length === 1 && barGroups[0].bars.length > 1;

        if (order && Array.isArray(order)) {
            if (isNpsChart) {
                order = [ 'Detractors', 'Passives', 'Promoters', '' ];
                if (allowSort && Array.isArray(settingsOrder)) {
                    // npsScore always must be at the end
                    const clearNpsSettingsOrder = settingsOrder.filter((oItem:string) => oItem !== 'npsScore');
                    const isMatch = allNpsSegments.every((oItem: string) => clearNpsSettingsOrder.includes(oItem));

                    order = isMatch ? [ ...clearNpsSettingsOrder, 'npsScore' ] : order;
                }
            }

            barGroups = barGroups.map((barGroup: barData) => ({
                ...barGroup,
                bars: GadgetService.mapOrder(barGroup.bars, order, 'id'),
            }));
        }

        // set color for each bar slice
        const colorSet = GadgetService.getRandomColorSet({ items: order, selectedColor });

        barGroups.forEach((barGroup: barData) => (
            barGroup.bars.forEach((bar: barSliceData) => {
                bar.color = GadgetService.getColor({
                    itemId: bar.id,
                    ids: order,
                    colors,
                    selectedColor,
                    randomColorSet: colorSet,
                    func,
                });
            })
        ));

        const filterLabels: filterRequiredWithScore[] = barGroups
            .flatMap(({ bars = [] }: { bars: barSliceData[] }) =>
                bars.map((barSlice: barSliceData) => ({
                    id: barSlice.id,
                    customId: `label_${barSlice.customId}`,
                    color: barSlice.color,
                    label: GadgetService.getSavedLabel(barSlice.id, labels, 'legend')?.value || barSlice.label,
                    score: barSlice.score,
                })),
            )
            .reduce((acc: filterRequiredWithScore[], item: filterRequiredWithScore) =>
                acc.some(({ id: accLabel }) => item.id === accLabel)
                    ? acc
                    : acc.concat(item),
            []);

        if (isNpsChart) {
            npsData = GadgetService.getNpsFormattedDataForBarGroups({
                groups: barGroups,
                config: {
                    id: 'npsScore',
                    label: GadgetService.getSavedLabel('npsScore', labels, 'tick')?.value || 'Nps score',
                    colors: colors,
                },
            });

            const id = 'npsScore';

            filterLabels.push({
                id,
                customId: `label_${ npsData.customId }`,
                label: GadgetService.getSavedLabel('npsScore', labels, 'legend')?.value || 'Nps score',
                color: npsData.color,
                score: 0,
            });
        }

        return {
            groups: barGroups,
            filterLabels: GadgetService.mapOrder(filterLabels, order, 'id'),
            records: count as number,
            axisLabels: {
                count: GadgetService.getSavedLabel('count', labels, 'axis')?.value || null,
                group: GadgetService.getSavedLabel('group', labels, 'axis')?.value || null,
                score: GadgetService.getSavedLabel('score', labels, 'axis')?.value || null,
            },
            allowSort,
            isNpsChart,
            npsData,
        };
    }

    static formatLineDataHandler({ chartData, gadgetData, forSelectorOnly }: IDataForFormatting) {
        const {
            dataSettings,
            contentSettings,
            parent,
            labels,
            colors,
        } = gadgetData;
        const { items, level, count } = chartData;
        const { function: func, chartType, order: settingsOrder } = contentSettings;
        const { secondGroupBy, factTypes } = dataSettings;
        // custom color
        const customColorSet = JSON.parse(dataSettings.customColorSet);
        const selectedColor = customColorSet && customColorSet.other[customColorSet.theOne];
        const inheritedLabels = parent ? parent.labels : [];
        const isNpsChart = GadgetService.isNPSChart({
            factTypes: factTypes,
            func: func,
            secondGroupBy: secondGroupBy,
        }) && DependencyMatrixService.isNpsSegmentChart(chartType);
        let npsData = GadgetService.getDefaultNpsData();

        const capitalizeString = (str: string) => {
            return str ? str[0].toUpperCase() + str.slice(1) : '';
        };

        const getItemLabel = (item: chartDataItem) => {
            return item.name === 'No value' ? 'No value' : item.id;
        };

        const labelConverter = (item: chartDataItem) => {
            return {
                label: getItemLabel(item),
                value: item.id,
            };
        };

        const lineValueConverter = (item: chartDataItem, key = func) => {
            const value = item ? item[key as keyof contentSettingsFunc] : 0;

            return value ? value : 0;
        };

        const chartLinesConverter = (label: string, items: chartDataItem[]) => {
            let values: number[] = [];
            const percents: number[] = [];

            items.forEach((item: chartDataItem) => {
                const val = item?.items?.find(e => e.id === label);

                values.push(lineValueConverter(val));
                percents.push(lineValueConverter(val, 'percent'));
            });

            if (func === 'accSum') {
                values = values.reduce((acc: number[], item: number, idx: number) => {
                    const value = (item === 0 && idx > 0) && acc[idx - 1] > 0 ? acc[idx - 1] : item;

                    acc.push(value);
                    return acc;
                }, []);
            }

            return {
                label,
                type: label,
                values,
                percents,
            };
        };

        const accumulationLinesConverter = (item: chartDataItem, index: number, arr: chartDataItem[]) => {
            return {
                label: getItemLabel(item),
                type: item.type,
                values: arr.map((i, idx) => idx === index ? lineValueConverter(item) : null),
                percents: arr.map((i, idx) => idx === index ? lineValueConverter(item, 'percent') : null),
            };
        };

        const accumulationLineModel = (chartLines: preLineItem[]) => {
            const label = gadgetData.contentSettings.function;
            const values: (number | null)[] = [],
                percents: (number | null)[] = [];

            chartLines.forEach(chartLine => {
                values.push(...chartLine.values.filter(value => value !== null));
                percents.push(...chartLine.percents.filter(value => value !== null));
            });

            return [{
                label: capitalizeString(label),
                type: '',
                values,
                percents,
            }];
        };

        const threeLevelDimension = () => {
            let lines: preLineItem[];

            if ([ 'count', 'accSum' ].includes(gadgetData.contentSettings.function)) {
                const tempDict: any = {};

                items.forEach((item: chartDataItem) => {
                    item.items?.forEach(element => tempDict[element.id] = element.id);
                });

                const distinct = Object.keys(tempDict).map(key => tempDict[key]);

                lines = distinct.map((label: string) => chartLinesConverter(label, items));
            } else {
                lines = accumulationLineModel(items.map(accumulationLinesConverter));
            }

            return lines;
        };

        const twoLevelDimensionLines = () => {
            if ([ 'count', 'accSum' ].includes(gadgetData.contentSettings.function)) {
                return chartData.items?.map(accumulationLinesConverter) || [];
            } else {
                return [{
                    label: capitalizeString(gadgetData.contentSettings.function),
                    type: 'mixed',
                    values: [ lineValueConverter(chartData) ],
                    percents: [ lineValueConverter(chartData, 'percent') ],
                }];
            }
        };

        const twoLevelDimensionLabels = () => {
            const settingsFunc = gadgetData.contentSettings.function;

            if ([ 'count', 'accSum' ].includes(settingsFunc)) {
                return items.map(labelConverter);
            } else {
                return [{
                    label: capitalizeString(settingsFunc),
                    value: capitalizeString(settingsFunc),
                }];
            }
        };

        const fourthLevelDimension = () => {
            let itemsList: chartDataItem[] = [];

            items.forEach((item: chartDataItem) => {
                item.items?.forEach(it => itemsList.push(it));
            });

            itemsList = itemsList
                .filter((obj, pos, arr) => arr
                    .map(mapObj => mapObj.id)
                    .indexOf(obj.id) === pos)
                .sort((a, b) => a.id > b.id ? 1 : -1);

            const distinct = itemsList.map(labelConverter).map(item => item.value);

            return distinct.map((value: string) => chartLinesConverter(value, items));
        };

        let chartLines;
        let chartLabels: preLineLabel[] | lineLabel[] = items.map(labelConverter);

        if (level === 3) {
            chartLines = threeLevelDimension();
        } else if (level === 2) {
            chartLines = twoLevelDimensionLines();
            chartLabels = twoLevelDimensionLabels();
        } else {
            chartLines = fourthLevelDimension();
        }

        chartLabels = chartLabels.map((label: preLineLabel): lineLabel => ({
            id: label.value,
            label: GadgetService.getSavedLabel(label.value, labels, 'tick')?.value || label.label,
        }));

        chartLines = chartLines.map((line: preLineItem): lineItem => {
            const id = line.label === '' ? 'No value' : line.label;
            const label = GadgetService.getSavedLabel(id, labels, 'legend')?.value
                || GadgetService.getSavedLabel(id, inheritedLabels, 'tick')?.value
                || id;
            const customId = GadgetService.getCustomChartElementId({
                prefix: 'line',
                itemId: id.toString(),
            });

            return {
                ...line,
                id,
                label,
                customId,
                data: {
                    absolute: line.values,
                    relative: line.percents,
                },
                color: 'white',
            };
        });

        const serverOrdering = settingsOrder || [];
        const clientOrdering = GadgetService.getDefaultOrder({
            ids: chartLines.map((line: lineItem) => line.id),
        });
        const cleanOrdering = serverOrdering.filter((x: string) => clientOrdering.includes(x));
        let order = GadgetService.getOrderingList({ cleanOrdering, clientOrdering });

        if (isNpsChart) {
            order = [ 'Detractors', 'Passives', 'Promoters', 'npsScore' ];
            const npsScoreModel = {
                color: 'white',
                count: 0,
                customId: 'line_npsScore',
                id: 'npsScore',
                label: 'Nps score',
                percent: 0,
                value: 'npsScore',
                values: [],
                percents: [],
                data: {
                    absolute: [],
                    relative: [],
                },
            };

            if (!forSelectorOnly) {
                if (chartLines.length < 3) {
                    chartLines = GadgetService.addMissingEmptyNPSSegments({
                        segments: chartLines,
                        customIdMaker: (id: string) => GadgetService.getCustomChartElementId({
                            prefix: 'line',
                            itemId: id,
                        }),
                    });
                }

                chartLines.push(npsScoreModel);
            }
        }

        // sort by order lines and labels (for the future)
        chartLines = GadgetService.mapOrder(chartLines, order, 'id');

        // set color for each line
        const colorSet = GadgetService.getRandomColorSet({ items: order, selectedColor });

        chartLines.forEach((line: lineItem) => {
            line.color = GadgetService.getColor({
                itemId: line.id,
                ids: order,
                colors,
                selectedColor,
                randomColorSet: colorSet,
                func,
            });
        });

        const filterLabels = chartLines.map((line: lineItem) => {
            return ({
                id: line.id,
                customId: `label_${line.customId}`,
                color: line.color,
                label: GadgetService.getSavedLabel(line.id, labels, 'legend')?.value || line.label,
            });
        });

        if (isNpsChart) {
            npsData = GadgetService.getNpsFormattedDataForLines({
                lines: chartLines,
                labels: chartLabels,
                config: {
                    id: 'npsScore',
                    label: GadgetService.getSavedLabel('npsScore', labels, 'tick')?.value || 'Nps score',
                    colors: colors,
                },
            });
        }

        return {
            labels: chartLabels,
            lines: chartLines,
            level,
            filterLabels,
            records: count,
            axisLabels: {
                count: GadgetService.getSavedLabel('count', labels, 'axis')?.value || null,
                group: GadgetService.getSavedLabel('group', labels, 'axis')?.value || null,
                score: GadgetService.getSavedLabel('score', labels, 'axis')?.value || null,
            },
            isNpsChart,
            npsData,
        };
    }

    static isPieNps({ factTypes, func }) {
        return (factTypes.filter(type => type !== 'NPS_SEGMENT' && type !== 'CHOICE_NPS_SEGMENT').length === 0)
            && func === 'count'
            || (factTypes.filter(type => type !== 'NPS' && type !== 'CHOICE_NPS').length === 0)
            && func === 'npsScore';
    }

    static formatPieDataHandler({
        chartData,
        gadgetData,
    }: IDataForFormatting) {
        const {
            dataSettings,
            contentSettings,
            labels,
            colors,
        } = gadgetData;
        const { items, percent, count } = chartData;
        const { function: func, order: settingsOrder, innerText } = contentSettings;
        const { factName, factTypes } = dataSettings;
        const isChartMathFunction = [ 'average', 'sum', 'variance', 'median' ].includes(func);
        const isNpsChart = GadgetService.isPieNps({ factTypes, func });
        // custom color
        const customColorSet = JSON.parse(dataSettings.customColorSet);
        const selectedColor = customColorSet && customColorSet.other[customColorSet.theOne];
        const serverOrdering = settingsOrder || [];

        const getNpsScore = (data: { id: any, percent?: any }[]) => {
            const promoters = data.find(i => i.id === 'Promoters') || { percent: 0 };
            const detractors = data.find(i => i.id === 'Detractors') || { percent: 0 };

            return (promoters.percent - detractors.percent).toFixed(1);
        };

        let formattedPieData: TPieSegment[];

        if (isChartMathFunction) {
            const colorSet = GadgetService
                .getRandomColorSet({ items: [ factName ], selectedColor });

            formattedPieData = [
                {
                    id: factName,
                    label:
                        GadgetService.getSavedLabel(factName, labels, 'legend')?.value
                        || factName,
                    percent: percent,
                    count: count,
                    customId: GadgetService.getCustomChartElementId({
                        prefix: 'pieItemData',
                        itemId: factName,
                    }),
                    color: GadgetService.getColor({
                        itemId: factName,
                        ids: [ factName ],
                        colors,
                        selectedColor,
                        randomColorSet: colorSet,
                        func,
                    }),
                },
            ];
        } else {
            const getSegmentId = (segment: chartDataItem) => segment.name === 'No value'
                ? segment.name
                : segment.id;

            const segmentWithFormattedId = items
                .map((pieSegment: chartDataItem) => ({ ...pieSegment, id: getSegmentId(pieSegment) }));
            const segmentIds = segmentWithFormattedId
                .map((pieSegment: chartDataItem) => pieSegment.id);
            const clientOrdering = GadgetService
                .getDefaultOrder({ ids: segmentIds });
            const cleanOrdering = serverOrdering.filter((x: string) => clientOrdering.includes(x));

            const order = isNpsChart
                ? [ 'Detractors', 'Passives', 'Promoters', 'npsScore' ]
                : GadgetService.getOrderingList({ cleanOrdering, clientOrdering });
            const colorSet = GadgetService
                .getRandomColorSet({ items: clientOrdering, selectedColor });
            const orderedSegments: chartDataItem[] = GadgetService
                .mapOrder(segmentWithFormattedId, order || [], 'id');

            formattedPieData = orderedSegments.map((pieSegment: chartDataItem) => ({
                id: pieSegment.id,
                label:
                    GadgetService.getSavedLabel(pieSegment.id, labels, 'legend')?.value
                    || pieSegment.name,
                percent: pieSegment.percent,
                count: pieSegment.count,
                customId: GadgetService.getCustomChartElementId({
                    prefix: 'pieItemData',
                    itemId: pieSegment.id,
                }),
                color: GadgetService.getColor({
                    itemId: pieSegment.id,
                    ids: clientOrdering,
                    colors,
                    selectedColor,
                    randomColorSet: colorSet,
                    func,
                }),
            }));
        }

        const filterLabels = formattedPieData
            .map(({ id, label, color = '#000000', customId, count }) => ({
                id,
                color,
                label,
                customId,
                score: count,
            }))
            .reduce(
                (acc: filterLabel[], item: filterLabel) =>
                    acc.some(({ id: accLabel }) => item.id === accLabel)
                        ? acc
                        : acc.concat(item),
                [],
            );

        return {
            filterLabels: GadgetService.mapOrder(filterLabels, settingsOrder, 'id'),
            data: {
                ...chartData,
                items: formattedPieData,
                totalLabelTransKey: isNpsChart? 'npsScore' : 'total',
                totalValue: isNpsChart
                    ? (chartData.npsScore || getNpsScore(formattedPieData))
                    : chartData[func as keyof contentSettingsFunc],
                innerText,
            },
        };
    }

    static formatWordCloudHandler({ chartData }:IDataForFormatting) {
        if (chartData && Object.prototype.hasOwnProperty.call(chartData, 'words') && chartData.words) {
            return chartData.words
                .map((item: wordDataType) => GadgetService
                    .convertToDrillDownOptionType({ id: item._id, label: item._id }),
                );
        }

        return [];
    }

    static getListPropertyByChartType({
        chartType,
        formattedData,
    }: {
        chartType: string,
        formattedData: any
    }) {
        switch (chartType) {
            case chartTypes.SURVEYS_BAR:
            case chartTypes.STACKED_BAR:
                return GadgetService.convertBarForSelector(
                    formattedData['groups'],
                    'bars',
                );
            case chartTypes.LINE:
                return GadgetService.convertLineForSelector(
                    formattedData['lines'],
                    formattedData['labels'],
                    formattedData.level,
                );
            case chartTypes.PIE:
                return GadgetService.convertPieDataForSelector(formattedData['data']);
            case chartTypes.WORD:
                return Array.isArray(formattedData) ? formattedData : [];

            default:
                return [];
        }
    }

    static convertToDrillDownOptionType(item: { label: string | number, id: string | number }): drillDownOptionType {
        return {
            label: item.label,
            value: item.id,
            list: [],
        };
    }

    static convertBarForSelector(array: any[], childArrayProp: string): any[] {
        const isParentRoot = array.length === 1 && (array[0].id === 'root' || array[0].id === '');

        const workWithArray = isParentRoot
            ? array[0][childArrayProp] || []
            : array;

        return workWithArray.map((option: any) => {
            const isOptionHasChildArrayProp = option[childArrayProp] && Array.isArray(option[childArrayProp]);

            return {
                ...GadgetService.convertToDrillDownOptionType(option),
                list: isOptionHasChildArrayProp
                    ? GadgetService.convertBarForSelector(option[childArrayProp], childArrayProp)
                    : [],
            };
        }) || [];
    }

    static convertLineForSelector(lines: any[], labels: any[], level: number) {
        const isSimpleLevelData = level === 2;
        const workWithArray = isSimpleLevelData
            ? lines
            : labels;

        return workWithArray.map((item: any) => {
            return {
                ...GadgetService.convertToDrillDownOptionType(item),
                list: !isSimpleLevelData
                    ? lines.map(GadgetService.convertToDrillDownOptionType)
                    : [],
            };
        }) || [];
    }

    static convertPieDataForSelector(dataObj: any) {
        const isHasItems = dataObj && Object.prototype.hasOwnProperty.call(dataObj, 'items');

        return isHasItems
            ? dataObj.items.map((item: any) => GadgetService.convertToDrillDownOptionType(item))
            : [];
    }

    static rangeTooltipValueFormatter({ index, list }: TRangeTooltipValueFormatter) {
        const value = list[index]?.label;

        return HelperService.checkNotEmpty(value) ? value : list[index].id;
    }

    static highlightGadget({ updateType, gadgetNode }: THighlightGadget) {
        switch (updateType) {
            case EUpdateGadgetTypes.MANUAL_UPDATE:
                gadgetNode?.classList?.add('shortHighlight');

                setTimeout(() => {
                    gadgetNode?.classList?.remove('shortHighlight');
                }, 1500);
                break;
            case EUpdateGadgetTypes.SOCKET_UPDATE:
                gadgetNode?.classList?.add('highlight');

                setTimeout(() => {
                    gadgetNode?.classList?.remove('highlight');
                }, 35000);
                break;
        }
    }

    static getCustomChartElementId({ prefix, groupId, itemId, divider = '_' }: TGetCustomChartElementId) {
        const doubleDivider = `${ divider }${ divider }`;
        const _prefix = HelperService.checkNotNullOrUndefined(prefix) ? prefix : '';
        let _group = HelperService.checkNotNullOrUndefined(groupId) ? `group${ divider }${ groupId }` : '';
        let _item = HelperService.checkNotNullOrUndefined(itemId) ? `item${ divider }${ itemId }` : '';

        if (_prefix !== '' && _group !== '') {
            _group = `${ doubleDivider }${ _group }`;
        }

        if ((_group !== '' || _prefix !== '') && _item !== '') {
            _item = `${ doubleDivider }${ _item }`;
        }

        const value = `${ _prefix }${ _group }${ _item }`;

        return value.replace(customIdReplacerStr, (substring: string) => {
            return substring === ' ' ? divider : `${ divider }utf16${ substring.charCodeAt(0).toString() }${ divider }`;
        });
    }
}
