import React, { Component } from 'react';
import { FormGroup, Label, Input, Col, Row, FormText } from 'reactstrap';
import PropTypes from 'prop-types';

import './FormFieldItem.scss';

export class FormFieldItem extends Component {
    render() {
        const {
            isHorizontal,
            label,
            type,
            disabled,
            value,
            id,
            placeholder,
            handleChange,
            className,
            onLabelCLick,
            isRequired,
            withError,
            textError,
            isValid,
            onBlur,
        } = this.props;

        return (
            <FormGroup className={ className }>
                <Row>
                    <Col
                        md={ isHorizontal ? 3 : 12 }
                        className={ 'text-field-control-label' }
                    >
                        <Label onClick={ onLabelCLick }>
                            { label }
                            { isRequired ? <span className={ 'star' }> * </span> : '' }
                        </Label>
                    </Col>
                    <Col md={ isHorizontal ? 9 : 12 }>
                        <Input
                            type={ type }
                            id={ id }
                            disabled={ disabled }
                            value={ value }
                            placeholder={ placeholder || '' }
                            onChange={ handleChange }
                            onBlur={ onBlur }
                        />
                        {
                            withError && !isValid
                                ? <FormText className={ 'app-form-control__error' }>
                                    { textError }
                                </FormText>
                                : null
                        }
                    </Col>
                </Row>
            </FormGroup>
        );
    }
}

FormFieldItem.propTypes = {
    isRequired: PropTypes.bool,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isHorizontal: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    withError: PropTypes.bool,
    isValid: PropTypes.bool,
    textError: PropTypes.string,
    handleChange: PropTypes.func,
    onLabelCLick: PropTypes.func,
    onBlur: PropTypes.func,
};

FormFieldItem.defaultProps = {
    isHorizontal: true,
    placeholder: '',
    withError: false,
    isValid: true,
    textError: 'Some error',
    onLabelCLick: () => {},
    onBlur: () => {},
};
