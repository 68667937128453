import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { VocTableV2, CollapseSection, TableRightsColumn, VocRightsHOC, TableFilter } from "/components";
import { RightsTableActionFormatter } from "/mine/components";
import { getSharedRights } from '/modules';
import { isSupportUserSelector, sharesSelector } from "/modules/selectors";
import {
    getDictionaryLanguages,
    getDictionaryLemmates,
    getDictionaryTitles,
} from "/dictionaries/modules/dictionary.modules";
import {
    dictionariesLoadingSelector,
    dictionariesTotalSelector,
    dictionaryLanguagesSelector,
    dictionaryLemmatesSelector,
    dictionaryTitlesSelector,
} from "/dictionaries/modules/dictionary.selectors";

import './DictionaryTable.scss';

export const DictionaryTable = ({
    data,
    filters,
    requestTrigger,
    updateFilters,
    openEditModal,
    openDeleteModal,
    openInviteModal,
    filterField,
    openPopoverFilter,
    togglePopover,
    isOpenPopover,
    targetFilter,
    applyFilters,
    collapse,
    setCollapse,
}) => {
    useEffect(() => {
        requestTrigger();
    }, []);

    const dispatch = useDispatch();

    const isSupportUser = useSelector(isSupportUserSelector);
    const dictionaryTitles = useSelector(dictionaryTitlesSelector);
    const dictionaryLanguages = useSelector(dictionaryLanguagesSelector);
    const dictionaryLemmates = useSelector(dictionaryLemmatesSelector);
    const shares = useSelector(sharesSelector);
    const total = useSelector(dictionariesTotalSelector);
    const loading = useSelector(dictionariesLoadingSelector);

    const getTitles = name => {
        dispatch(getDictionaryTitles(name));
    };

    const getLanguages = name => {
        dispatch(getDictionaryLanguages(name));
    };

    const getLemmates = name => {
        dispatch(getDictionaryLemmates(name));
    };

    const getDictionaryShares = id => dispatch(getSharedRights(id, 'dictionary'));

    const onCollapseChanged = name => setCollapse(name);

    const lemmataFormatter = value => (
        value
            ? (
                <CollapseSection className={ 'dictionary-lemmata' }
                    title={ value }
                    noTitleTooltip={ true }
                    onCollapseChanged={ () => onCollapseChanged(value) }
                    collapse={ collapse === value }
                >
                    <p className='lemmata-formatter'>
                        { value }
                    </p>
                </CollapseSection>
            )
            : ''
    );

    const sharedFormatter = id => (
        id
            ? <TableRightsColumn
                id={ id }
                getShares={ getDictionaryShares }
                shares={ shares }
            /> : ''
    );

    const actionsFormatter = cell => (
        cell.id && cell.values
            ? <RightsTableActionFormatter
                { ...cell }
                openEditModal={ openEditModal }
                openDeleteModal={ openDeleteModal }
                openInviteModal={ openInviteModal }
                isSupportUser={ isSupportUser }
                tooltipTitle={ 'dictionary' }
            /> : ''
    );

    const tableFilter = (name, filterData, getFilterData) => (
        <TableFilter
            targetFilter={ targetFilter }
            isOpenPopover={ isOpenPopover }
            togglePopover={ togglePopover }
            name={ name }
            filterData={ filterData }
            apply={ applyFilters }
            getFilterData={ getFilterData }
        />
    );

    const dataSettings = [
        {
            field: 'title',
            title: 'Title',
            sort: true,
            formatter: 'conditionTooltip',
            filter: () => tableFilter('title', dictionaryTitles, getTitles),
            style: { width: '28%' },
        },
        {
            field: 'language',
            title: 'Language',
            sort: true,
            filter: () => tableFilter('language', dictionaryLanguages, getLanguages),
            formatter: 'conditionTooltip',
            style: { width: '12%' },
        },
        {
            field: 'lemmata',
            title: 'Lemmas',
            filter: () => tableFilter('lemmata', dictionaryLemmates, getLemmates),
            formatter: lemmataFormatter,
            sort: true,
            style: { width: '45%' },
        },
        {
            field: 'user',
            title: 'Role',
            sort: false,
            formatter: sharedFormatter,
            style: { width: '5%', textAlign: 'center' },
        },
        {
            field: 'actions',
            title: '',
            formatter: actionsFormatter,
            style: {},
        },
    ];

    const getTableOptions = () => ({
        requestTrigger,
        updateFilters,
        filterField,
        openPopoverFilter,
    });

    useEffect(() => {
        document.querySelectorAll('.dictionary-lemmata').forEach(({ childNodes }) => {
            const cardHeader = childNodes[0];
            const titleText = cardHeader.childNodes[0].childNodes[0];

            cardHeader.className.includes('card-header--no-events') && cardHeader.classList.remove('card-header--no-events');
            if (titleText.clientWidth <= cardHeader.clientWidth - 40) {
                cardHeader.classList.add('card-header--no-events');
            }
        });
    }, [ data ]);

    return (
        <div>
            <VocTableV2
                data={ data }
                total={ total }
                loading={ loading }
                dataSettings={ dataSettings }
                options={ getTableOptions() }
                filters={ filters }/>
        </div>
    );
};

export default VocRightsHOC({
    type: 'dictionary',
})(DictionaryTable);
