import React, { useContext, memo, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { SettingsForm, ControlGlobalLangs } from '/feedback/components';
import { HelperService } from '/services';
import { ThemeService } from '/feedback/services';
import { Preloader } from '/components';
import { Context } from '../../';

import styles from './style.module.scss';

const onChangeDebounce = HelperService.debounce(({
    value,
    key,
    setInitValue,
    initValue,
}) => {
    setInitValue({
        ...initValue,
        [key]: {
            ...initValue[key],
            channelValue: value,
        },
    });

    ThemeService.updateStyle({
        [key.toUpperCase()]: value,
    });
}, 500);

export const LanguageSettings = memo(() => {
    const {
        themeSettings: {
            themeId,
            initialValues: initialValuesTheme,
        },
        commonLang: {
            themeLangId,
            globalLanguages,
        },
        languageSettings: {
            loading,
            sendingLangStatus,
            fieldsMap,
            initialValues,
            getAssets,
            uploadAsset,
            assets,
        },
        match: {
            params,
        },
        setLang,
        permission,
        setActiveLang,
        languageSettings,
        handleCreateLang,
    } = useContext(Context);

    const [ initValue, setInitValue ] = useState({});

    const loadingLanguages = globalLanguages.enableLangLoading || globalLanguages.langUpdating || languageSettings.loading
        || languageSettings.sending || languageSettings.sendingLangStatus;

    const switchLanguage = lang => setActiveLang({
        themeId: params.themeId,
        langId: lang.value,
    });

    useEffect(() => {
        if (window.VocSignal && window.VocSignal.isInit) {
            ThemeService.composeSettings(initValue, 'UpdateStyle');
        }
    }, [ initialValues ]);

    useEffect(() => {
        setInitValue({
            ...initialValuesTheme,
            ...initialValues,
        });

        return () => setInitValue({});
    }, []);

    function updateLang(values) {
        return setLang(themeLangId.id, { ...themeLangId, ...values }).promise;
    }

    const initRunnerDemo = () => {
        ThemeService.composeSettings(initValue, 'SetMockedData');
    };

    const getInfoForEnableLang = () => (
        <Row>
            <Col md={ 12 } className={ 'enable-language__box' }>
                <span className={ 'language-list__box' }>
                    {
                        themeId
                            ? 'No active languages. Please select language first'
                            : 'No saved theme. Please save the theme first'
                    }
                </span>
            </Col>
        </Row>
    );

    const getBody = () => (
        <>
            <div className={ styles.controlGlobalLangsWrapper }>
                {
                    themeId
                    && <ControlGlobalLangs
                        globalLanguages={ globalLanguages }
                        activeLang={ globalLanguages.languages.find(lang => lang.active) || {} }
                        switchLanguage={ switchLanguage }
                        changeHandler={ handleCreateLang }
                        classWrapper={ 'template-header__language' }
                        selectClass={ 'template-header__selector' }
                        loading={ loadingLanguages }
                        disabled={ !themeId }
                    />
                }
            </div>

            {
                themeLangId
                    ? <SettingsForm
                        fieldsMap={ fieldsMap }
                        initialValues={ initialValues }
                        putSettings={ updateLang }
                        sendingSettings={ loading }
                        wrappedCollapse={ false }
                        checkUpdate={ themeLangId ? themeLangId.id : null }
                        form={ 'GlobalLangSettingsForm' }
                        initRunnerDemo={ initRunnerDemo }
                        channel={ 'DIGI' }
                        themeSettings={ true }
                        onChangeField={ (value, key) => onChangeDebounce({ value, key, setInitValue, initValue }) }
                        assets={ assets }
                        getAssets={ getAssets }
                        uploadAsset={ uploadAsset }
                        permission={ permission }
                    />
                    : getInfoForEnableLang()
            }
        </>
    );

    return (
        <>
            {
                loading || sendingLangStatus
                    ? <Preloader/>
                    : getBody()
            }
        </>
    );
});
