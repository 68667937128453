import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { userInfoSelector } from '/modules/selectors';

const conditionTypes = [
    {
        value: 'answer',
        label: 'Answer',
    },
    {
        value: 'metadata',
        label: 'Metadata',
    },
    {
        value: 'classifier',
        label: 'Classifier',
    },
];

const conditionSigns = [
    {
        value: '<',
        label: '<',
    },
    {
        value: '<=',
        label: '<=',
    },
    {
        value: '>',
        label: '>',
    },
    {
        value: '=',
        label: '=',
    },
    {
        value: '>=',
        label: '>=',
    },
    {
        value: '!=',
        label: '!=',
    },
    {
        value: 'like%',
        label: 'like%',
    },
    {
        value: '%like%',
        label: '%like%',
    },
    {
        value: 'likeNot%',
        label: 'likeNot%',
    },
    {
        value: '%likeNot%',
        label: '%likeNot%',
    },
    {
        label: 'is not empty',
        value: 'is_not_empty',
    },
];

const conditionJoin = [
    { value: 'and', label: 'AND' },
    { value: 'or', label: 'OR' },
];

export const exception = [ 'is_not_empty' ];

export const caseAlertsListSelector = state => state.VocFeedback.globalSettings.caseAlerts;
export const classifierSelector = state => state.VocFeedback.common.classifier;
export const caseAlertsFilters = createSelector([ caseAlertsListSelector ], ({ filters }) => filters);
export const caseAlertsTableData = createSelector([ caseAlertsListSelector ], ({ results }) => results);
export const caseAlertsClassifiersSelector = createSelector([ classifierSelector, userInfoSelector ], (classifier, user) => {
    return classifier.results.map(classifier => ({
        value: classifier.classifierId,
        label: classifier.title,
        enterprise_id: user.enterpriseId,
        ...classifier,
    }));
});
export const caseAlertSubmitSelector = createSelector([ caseAlertsListSelector ],
    ({ caseAlertSubmitted }) => caseAlertSubmitted);
export const caseAlertConditionSubmitSelector = createSelector([ caseAlertsListSelector ],
    ({ caseAlertConditionSubmitted }) => caseAlertConditionSubmitted);

const conditionsFormFieldsSelector = formValueSelector('ConditionsCaseAlertForm');

export const caseAlertConditionFieldsMap = createSelector([ caseAlertsClassifiersSelector ],
    classifiers => {
        return {
            classifiers,
            typeOptions: conditionTypes,
            classifier: {
                fields: [
                    {
                        name: 'classifier_id',
                        title: 'Classifier',
                        type: 'select',
                        options: classifiers,
                    },
                    {
                        name: 'category_id',
                        title: 'Category',
                        type: 'select',
                        options: [],
                        uniq: true,
                    },
                    {
                        title: 'Join Condition',
                        name: 'link_condition',
                        type: 'select',
                        options: conditionJoin,
                    },
                ],
            },
            answer: {
                fields: [
                    {
                        name: 'alert_condition',
                        title: 'Alert condition',
                        type: 'select',
                        options: conditionSigns,
                    },
                    {
                        title: 'Answer Value',
                        name: 'value',
                        type: 'text',
                    },
                    {
                        title: 'Join Condition',
                        name: 'link_condition',
                        type: 'select',
                        options: conditionJoin,
                    },
                ],
            },
            metadata: {
                fields: [
                    {
                        name: 'alert_condition',
                        title: 'Alert condition',
                        type: 'select',
                        options: conditionSigns,
                    },
                    {
                        title: 'Metadata Value',
                        name: 'value',
                        type: 'text',
                    },
                    {
                        title: 'Join Condition',
                        name: 'link_condition',
                        type: 'select',
                        options: conditionJoin,
                    },
                ],
            },
        };
    });

export const caseAlertsListTableStateSelector = createSelector([ caseAlertsListSelector ], caseAlerts => {
    const {
        page,
        limit,
    } = caseAlerts.filters;

    return {
        dataTotalSize: caseAlerts.count,
        page: page,
        sizePerPage: limit,
        loading: caseAlerts.loading,
    };
});

export const conditionsSelector = createSelector([ caseAlertsListSelector ], ({ conditions }) => conditions);
export const caseAlertSelector = createSelector([ caseAlertsListSelector ], ({ case_alert }) => case_alert);

export const caseAlertCurrentConditionSelector = createSelector([ caseAlertsListSelector ], ({ condition }) => {
    const complexValue = typeof condition.value === 'object';
    const additionalFields = complexValue ? { ...condition.value } : {};

    return {
        ...condition,
        ...additionalFields,
        complexValue,
    };
});

export const conditionsFormValuesSelector = state => conditionsFormFieldsSelector(
    state,
    'alert_condition',
    'case_alert',
    'case_alert_type',
    'external_identifier',
    'id',
    'link_condition',
    'self',
    'value',
    'classifier_id',
    'category_id',
);
