import { JourneyService } from '/services/JourneyService/';
import { HelperService } from '/services/HelperService/HelperService';
import { showToast } from '/components/';
import { CALL_API, FEEDBACK } from 'store/middleware/api';

export const GET_PROJECTS_LIST = 'GET_PROJECTS_LIST';
export const GET_TOUCHPOINTS_LIST = 'GET_TOUCHPOINTS_LIST';
export const GET_PROJECTS_LIST_EXPANDED = 'GET_PROJECTS_LIST_EXPANDED';
export const GET_PROJECTS_LIST_EXPANDED_REQUEST = 'GET_PROJECTS_LIST_EXPANDED_REQUEST';
export const GET_SURVEYS_BY_TOUCHPOINT_REQUEST = 'GET_SURVEYS_BY_TOUCHPOINT_REQUEST';
export const GET_SURVEYS_BY_TOUCHPOINT_STORE = 'GET_SURVEYS_BY_TOUCHPOINT_STORE';
export const CLEAN_SURVEYS_BY_TOUCHPOINT = 'CLEAN_SURVEYS_BY_TOUCHPOINT';
export const ACTIVE_PROJECT_CHANGED = 'ACTIVE_PROJECT_CHANGED';
export const OPEN_TOUCHPOINT_BY_ID = 'OPEN_TOUCHPOINT_BY_ID';
export const CLOSE_TOUCHPOINT_BY_ID = 'CLOSE_TOUCHPOINT_BY_ID';

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_ERROR = 'ADD_PROJECT_ERROR';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';

export const DELETE_TOUCHPOINT_REQUEST = 'DELETE_TOUCHPOINT_REQUEST';
export const DELETE_TOUCHPOINT_SUCCESS = 'DELETE_TOUCHPOINT_SUCCESS';
export const DELETE_TOUCHPOINT_ERROR = 'DELETE_TOUCHPOINT_ERROR';

export const REORDER_TOUCHPOINT_REQUEST = 'REORDER_TOUCHPOINT_REQUEST';
export const REORDER_TOUCHPOINT_SUCCESS = 'REORDER_TOUCHPOINT_SUCCESS';
export const REORDER_TOUCHPOINT_ERROR = 'REORDER_TOUCHPOINT_ERROR';

export const ATTACH_SURVEY_TO_TOUCH_POINT_REQUEST = 'ATTACH_SURVEY_TO_TOUCH_POINT_REQUEST';
export const ATTACH_SURVEY_TO_TOUCH_POINT_SUCCESS = 'ATTACH_SURVEY_TO_TOUCH_POINT_SUCCESS';
export const ATTACH_SURVEY_TO_TOUCH_POINT_ERROR = 'ATTACH_SURVEY_TO_TOUCH_POINT_ERROR';

export const REMOVE_SURVEY_FROM_TOUCH_POINT_REQUEST = 'REMOVE_SURVEY_FROM_TOUCH_POINT_REQUEST';
export const REMOVE_SURVEY_FROM_TOUCH_POINT_SUCCESS = 'REMOVE_SURVEY_FROM_TOUCH_POINT_SUCCESS';
export const REMOVE_SURVEY_FROM_TOUCH_POINT_ERROR = 'REMOVE_SURVEY_FROM_TOUCH_POINT_ERROR';

export const GET_PROJECTS_BASE_REQUEST = 'GET_PROJECTS_BASE_REQUEST';
export const GET_PROJECTS_BASE_SUCCESS = 'GET_PROJECTS_BASE_SUCCESS';
export const GET_PROJECTS_BASE_ERROR = 'GET_PROJECTS_BASE_ERROR';

export const ADD_NEW_TOUCH_POINT_REQUEST = 'ADD_NEW_TOUCH_POINT_REQUEST';
export const ADD_NEW_TOUCH_POINT_SUCCESS = 'ADD_NEW_TOUCH_POINT_SUCCESS';
export const ADD_NEW_TOUCH_POINT_ERROR = 'ADD_NEW_TOUCH_POINT_ERROR';

export const EDIT_TOUCH_POINT_REQUEST = 'EDIT_TOUCH_POINT_REQUEST';
export const EDIT_TOUCH_POINT_SUCCESS = 'EDIT_TOUCH_POINT_SUCCESS';
export const EDIT_TOUCH_POINT_ERROR = 'EDIT_TOUCH_POINT_ERROR';

export function getProjectsListAction(data) {
    return {
        type: GET_PROJECTS_LIST,
        data,
    };
}

export function getProjectsListExpandedAction(data) {
    return {
        type: GET_PROJECTS_LIST_EXPANDED,
        data,
    };
}

export function getProjectsListExpandedRequest() {
    return {
        type: GET_PROJECTS_LIST_EXPANDED_REQUEST,
    };
}

export function getTouchPointsAction(data) {
    return {
        type: GET_TOUCHPOINTS_LIST,
        data,
    };
}

export function getSurveysByTouchPointAction(data) {
    return {
        type: GET_SURVEYS_BY_TOUCHPOINT_STORE,
        data,
    };
}

export function getProjects() {
    return dispatch => {
        return dispatch(getProjectsBase()).promise
            .then(projects => dispatch(getProjectsListAction(projects)));
    };
}

export function getProjectsExpanded() {
    return dispatch => {
        dispatch(getProjectsListExpandedRequest());
        const request = JourneyService.getProjectsExpanded();

        return request.promise.then(projects => {
            const touchPoints = [];

            projects.results.forEach(project => {
                project.touch_points.forEach(touchpoint => touchPoints.push(touchpoint));
            });
            dispatch(getProjectsListExpandedAction(projects));
            dispatch(getTouchPointsAction(touchPoints));
            dispatch(getSurveysForOpenTouchPoints());
        },
        error => HelperService.authErrorHandler(error, dispatch),
        );
    };
}

export function getSurveysByTouchPointId(id) {
    return dispatch => {
        dispatch({
            type: GET_SURVEYS_BY_TOUCHPOINT_REQUEST,
            data: id,
        });
        const request = JourneyService.getSurveysByTouchPointId(id);

        request.promise.then(
            surveys => {
                const touchpoint = {
                    id,
                    surveys: surveys.results,
                };

                dispatch(getSurveysByTouchPointAction(touchpoint));
            },
            () => dispatch(getSurveysByTouchPointAction({ id, surveys: [] })),
        );
        return request;
    };
}

export function cleanSurveysByTouchPointId(id) {
    return dispatch => {
        const action = {
            type: CLEAN_SURVEYS_BY_TOUCHPOINT,
            data: id,
        };

        return dispatch(action);
    };
}

export function reorderTouchPoint(params) {
    return dispatch => {
        return dispatch(reorderTouchPointRequest(params)).promise
            .then(() => {
                dispatch(showToast({ text: 'Touchpoint was updated successfully!', type: 'success' }));
                return dispatch(getProjectsExpanded());
            });
    };
}

export function editTouchPoint(id) {
    return dispatch => {
        return dispatch(editTouchPointRequest(id)).promise
            .then(() => {
                dispatch(showToast({ text: 'Touchpoint was updated successfully!', type: 'success' }));
                return dispatch(getProjectsExpanded());
            },
            error => {
                HelperService.mapResponseServerError(error, showToast, dispatch);
                return Promise.reject();
            },
            );
    };
}

export function addTouchPoint(params) {
    return dispatch => {
        return dispatch(addNewTouchPoint(params)).promise
            .then(
                () => {
                    dispatch(showToast({ text: 'Touchpoint was added successfully!', type: 'success' }));
                    return dispatch(getProjectsExpanded());
                },
                error => {
                    HelperService.mapResponseServerError(error, showToast, dispatch);
                    return Promise.reject();
                },
            );
    };
}

export function removeTouchPointById(id) {
    return dispatch => {
        dispatch(closeTouchPointAccordion(id));
        dispatch(removeTouchPointRequest(id)).promise
            .then(() => {
                dispatch(getProjectsExpanded());
            });
    };
}

export function changeActiveProject(id) {
    return dispatch => {
        dispatch({
            type: ACTIVE_PROJECT_CHANGED,
            data: id,
        });
    };
}

export function attachSurveyToTouchPoint(params) {
    return dispatch => {
        return dispatch(attachSurveyToTouchPointRequest(params)).promise
            .then(() => {
                dispatch(getProjectsExpanded());
                dispatch(showToast({ text: 'Surveys in touchpoint successfully updated', type: 'success' }));
            });
    };
}

export function updateTouchPointSurveys(params) {
    return dispatch => {
        const excludeModel = {
            survey_groups: params.exclude,
            id: params.id,
        };
        const includeModel = {
            survey_groups: params.include,
            id: params.id,
        };

        if (params.exclude.length && params.include.length) {
            dispatch(removeSurveysFromTouchPointRequest(excludeModel)).promise
                .then(() => dispatch(attachSurveyToTouchPoint(includeModel)));
        } else if (params.exclude.length && !params.include.length) {
            dispatch(removeSurveysFromTouchPointRequest(excludeModel)).promise
                .then(() => {
                    dispatch(getProjectsExpanded());
                    dispatch(showToast({ text: 'Surveys from touchpoint successfully deleted', type: 'success' }));
                });
        } else if (!params.exclude.length && params.include.length) {
            dispatch(attachSurveyToTouchPoint(includeModel));
        }
    };
}

export function openTouchPointAccordion(id) {
    return dispatch => {
        dispatch({
            type: OPEN_TOUCHPOINT_BY_ID,
            data: id,
        });
    };
}

export function closeTouchPointAccordion(id) {
    return dispatch => {
        dispatch({
            type: CLOSE_TOUCHPOINT_BY_ID,
            data: id,
        });
    };
}

export function getSurveysForOpenTouchPoints() {
    return (dispatch, getState) => {
        const openedTouchPoints = getState().VocFeedback.journeyMap.touchPoints.opened;

        openedTouchPoints.forEach(id => dispatch(getSurveysByTouchPointId(id)));
    };
}

//todo refactor
export function addProject(data) {
    return dispatch => {
        return dispatch(addProjectRequest(data)).promise
            .then(() => {
                dispatch(showToast({ text: 'Customer Journey was created successfully!', type: 'success' }));
                return dispatch(getProjectsExpanded());
            }, error => {
                HelperService.mapResponseServerError(error, showToast, dispatch);
                return Promise.reject();
            });
    };
}

export function updateProject(id, data) {
    return dispatch => {
        return dispatch(updateProjectRequest(id, data)).promise
            .then(() => {
                dispatch(showToast({ text: 'Customer Journey was updated successfully!', type: 'success' }));
                return dispatch(getProjectsExpanded());
            }, error => {
                HelperService.mapResponseServerError(error, showToast, dispatch);
                return Promise.reject();
            });
    };
}

export function deleteProject(id) {
    return dispatch => {
        return dispatch(deleteProjectRequest(id)).promise
            .then(() => {
                dispatch(showToast({ text: 'Customer Journey was removed successfully!', type: 'success' }));
                return dispatch(getProjectsExpanded());
            });
    };
}

export function deleteTouchPoint(id) {
    return dispatch => {
        return dispatch(removeTouchPointRequest(id)).promise
            .then(() => {
                dispatch(showToast({ text: 'Touchpoint was removed successfully!', type: 'success' }));
                return dispatch(getProjectsExpanded());
            });
    };
}

export function getProjectsBase() {
    return {
        [CALL_API]: {
            endpoint: '/project/?limit=9999',
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_PROJECTS_BASE_REQUEST, GET_PROJECTS_BASE_SUCCESS, GET_PROJECTS_BASE_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function addNewTouchPoint(data) {
    return {
        [CALL_API]: {
            endpoint: '/touch-point/',
            method: 'POST',
            contentType: 'application/json',
            types: [ ADD_NEW_TOUCH_POINT_REQUEST, ADD_NEW_TOUCH_POINT_SUCCESS, ADD_NEW_TOUCH_POINT_ERROR ],
            apiType: FEEDBACK,
            body: data,
        },
    };
}

export function addProjectRequest(data) {
    return {
        [CALL_API]: {
            endpoint: '/project/',
            method: 'POST',
            contentType: 'application/json',
            types: [ ADD_PROJECT_REQUEST, ADD_PROJECT_SUCCESS, ADD_PROJECT_ERROR ],
            apiType: FEEDBACK,
            body: data,
        },
    };
}

export function updateProjectRequest(pk, data) {
    return {
        [CALL_API]: {
            endpoint: `/project/${pk}/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ UPDATE_PROJECT_REQUEST, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_ERROR ],
            apiType: FEEDBACK,
            body: data,
        },
    };
}

export function deleteProjectRequest(pk) {
    return {
        [CALL_API]: {
            endpoint: `/project/${pk}/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function editTouchPointRequest(data) {
    return {
        [CALL_API]: {
            endpoint: `/touch-point/${data.id}/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ EDIT_TOUCH_POINT_REQUEST, EDIT_TOUCH_POINT_SUCCESS, EDIT_TOUCH_POINT_ERROR ],
            apiType: FEEDBACK,
            body: data,
        },
    };
}

export function removeTouchPointRequest(id) {
    return {
        [CALL_API]: {
            endpoint: `/touch-point/${id}/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_TOUCHPOINT_REQUEST, DELETE_TOUCHPOINT_SUCCESS, DELETE_TOUCHPOINT_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function attachSurveyToTouchPointRequest(params) {
    return {
        [CALL_API]: {
            endpoint: `/touch-point/${params.id}/survey-groups-add/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [
                ATTACH_SURVEY_TO_TOUCH_POINT_REQUEST,
                ATTACH_SURVEY_TO_TOUCH_POINT_SUCCESS,
                ATTACH_SURVEY_TO_TOUCH_POINT_ERROR,
            ],
            apiType: FEEDBACK,
            body: {
                survey_groups: params.survey_groups,
            },
        },
    };
}

export function removeSurveysFromTouchPointRequest(params) {
    return {
        [CALL_API]: {
            endpoint: `/touch-point/${params.id}/survey-groups-pop/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [
                REMOVE_SURVEY_FROM_TOUCH_POINT_REQUEST,
                REMOVE_SURVEY_FROM_TOUCH_POINT_SUCCESS,
                REMOVE_SURVEY_FROM_TOUCH_POINT_ERROR,
            ],
            apiType: FEEDBACK,
            body: {
                survey_groups: params.survey_groups,
            },
        },
    };
}

export function reorderTouchPointRequest(data) {
    return {
        [CALL_API]: {
            endpoint: `/touch-point/${data.current}/move-after/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [
                REORDER_TOUCHPOINT_REQUEST,
                REORDER_TOUCHPOINT_SUCCESS,
                REORDER_TOUCHPOINT_ERROR,
            ],
            apiType: FEEDBACK,
            body: {
                destination_id: data.target,
            },
        },
    };
}
