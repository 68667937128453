import React from 'react';

import { PopoverList } from '/visual/components';
import { TAction } from '/visual/models';
import { IAddElementMenu } from './models';

import styles from './style.module.scss';

export const AddElementMenu = ({
    disabled,
    dashboard,
    onToggleModal,
    onAddGadget,
    onAddPage,
    onMediaBlock,
}: IAddElementMenu) => {
    const actionsList: TAction[] = [
        {
            title: 'Add gadget',
            onClick: onAddGadget,
        },
        {
            title: 'Add page',
            onClick: onAddPage,
        },
        {
            title: 'Add media block',
            onClick: onMediaBlock,
        },
    ];

    return (
        <div className={ styles.actionMenuWrapper }>
            <PopoverList
                popoverFade={ false }
                target={ `addElementButtonDashboard_${dashboard.id}` }
                disabled={ disabled || !dashboard.id }
                tagClassName={ styles.actionButton }
                buttonColor='success'
                buttonIcon='plus-square'
                buttonText='Add element'
                trigger='hover'
                placement='left-start'
                actionList={ actionsList }
                popperModifiers={{
                    offset: {
                        offset: '-5px, 1px',
                    },
                }}
                delay={{ hide: 200, show: 0 }}
                onToggleModal={ (type: string) => onToggleModal({ type, dashboardId: dashboard.id }) }
            />
        </div>
    );
};
