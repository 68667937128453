import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import uuidV4 from 'uuid/v4';

import { VocMetaFilterItem } from './components';

import './VocMetaFilter.scss';

const getInitialState = () => ({
    drag: {
        curYPos: null,
        curXPos: null,
        curDown: false,
    },
});

export class VocMetaFilter extends Component {
    state = getInitialState();

    vocMetaItem = createRef();

    renderItem({ metaQuery, parent, index, isRoot }) {
        const { source, onChangedMetaQueryItem, attributeValueKey, attributePlaceholder, withLemmas, isVisual } = this.props;
        const hasItems = metaQuery.items && metaQuery.items.length > 0;

        return (
            <div className="voc-meta-filter__or-wrapper" key={ index }>
                <div className="voc-meta-filter__item-wrapper">
                    <VocMetaFilterItem
                        vocMetaItem={ this.vocMetaItem }
                        attributeValueKey={ attributeValueKey }
                        parent={ parent }
                        attributePlaceholder={ attributePlaceholder }
                        getBaseItem={ this.getBaseItem }
                        setScrollOffset={ this.setScrollOffset }
                        metaQuery={ metaQuery }
                        hasItems={ hasItems }
                        onChangedMetaQueryItem={ onChangedMetaQueryItem }
                        attributes={ source.attributes }
                        withLemmas={ withLemmas }
                        isVisual={ isVisual }
                        isRoot={ isRoot }
                    />
                </div>
                {
                    hasItems
                    && this.mapItems(metaQuery)
                }
            </div>
        );
    }

    mapItems(metaQuery, isRoot) {
        const result = metaQuery.items.map((queryItem, index) => {
            return this.renderItem({
                metaQuery: queryItem,
                parent: metaQuery,
                index,
                isRoot: Boolean(isRoot && index === 0),
            });
        });

        return (
            <div className="voc-meta-filter__or-blocks">
                { result }
            </div>
        );
    }

    @autobind
    setMetafilterNode(element) {
        this.metafilterNode = element;
    }

    @autobind
    setScrollOffset() {
        Promise
            .resolve()
            .then(() => this.vocMetaItem.current.scrollIntoView({ behavior: 'smooth' }));
    }

    getBaseItem() {
        return {
            id: '',
            operator: '',
            value: null,
            hash: uuidV4(),
            items: [],
        };
    }

    @autobind
    dragAction(event) {
        const { drag: { curDown, curYPos, curXPos } } = this.state;

        if (curDown) {
            const scrollXValue = this.metafilterNode.scrollLeft + (curXPos - event.pageX);
            const scrollYValue = this.metafilterNode.scrollTop + (curYPos - event.pageY);

            this.metafilterNode.scrollTo(scrollXValue, scrollYValue);
            this.setState({
                drag: {
                    curYPos: event.pageY,
                    curXPos: event.pageX,
                    curDown: true,
                },
            });
        }
    }

    @autobind
    enableDrag(event) {
        const notControls = Array.from(event.target.classList).every(className => {
            return ![
                'Select-control',
                'voc-meta-filter__button',
                'voc-meta-filter__select-option',
                'Select-placeholder',
                'voc-meta-filter__item-form',
                'Select-multi-value-wrapper',
            ].includes(className);
        });

        if (notControls) {
            this.setState({
                drag: {
                    curYPos: event.pageY,
                    curXPos: event.pageX,
                    curDown: true,
                },
            });
        }
    }

    @autobind
    disableDrag() {
        this.setState(getInitialState());
    }

    render() {
        const { metaQuery } = this.props;
        const isEmptyQuery = metaQuery.items.length === 0;

        if (isEmptyQuery) {
            metaQuery.items.push(this.getBaseItem());
        }

        return (
            <div
                onMouseMove={ this.dragAction }
                onMouseDown={ this.enableDrag }
                onMouseUp={ this.disableDrag }
                onMouseLeave={ this.disableDrag }
                ref={ this.setMetafilterNode }
                className="voc-meta-filter"
            >
                {
                    this.mapItems(metaQuery, true)
                }
            </div>
        );
    }
}

VocMetaFilter.propTypes = {
    onChangedMetaQueryItem: PropTypes.func,
    source: PropTypes.object,
    attributeValueKey: PropTypes.oneOf([ 'id', 'name', 'index' ]),
    metaQuery: PropTypes.object,
    attributePlaceholder: PropTypes.string,
};

VocMetaFilter.defaultProps = {
    readyOnly: false,
    attributePlaceholder: 'Attribute',
};
