import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';

export const GET_IMPORTS_LIST_REQUEST = 'GET_IMPORTS_LIST_REQUEST';
export const GET_IMPORTS_LIST_REQUEST_SUCCESS = 'GET_IMPORTS_LIST_REQUEST_SUCCESS';
export const GET_IMPORTS_LIST_REQUEST_ERROR = 'GET_IMPORTS_LIST_REQUEST_ERROR';

export const ADD_IMPORT_REQUEST = 'ADD_IMPORT_REQUEST';
export const ADD_IMPORT_SUCCESS = 'ADD_IMPORT_SUCCESS';
export const ADD_IMPORT_ERROR = 'ADD_IMPORT_ERROR';

export const ADD_IMPORT_SUCCESS_MESSAGE = 'Import was successfully saved';
export const ADD_IMPORT_ERROR_MESSAGE = 'Failed to save import';

export const UPLOAD_IMPORT_FILE_REQUEST = 'UPLOAD_IMPORT_FILE_REQUEST';
export const UPLOAD_IMPORT_FILE_SUCCESS = 'UPLOAD_IMPORT_FILE_SUCCESS';
export const UPLOAD_IMPORT_FILE_ERROR = 'UPLOAD_IMPORT_FILE_ERROR';

export const UPDATE_IMPORT_LIST = 'UPDATE_IMPORT_LIST';

export const GET_IMPORT_SURVEYS_LIST_REQUEST = 'GET_IMPORT_SURVEYS_LIST_REQUEST';
export const GET_IMPORT_SURVEYS_LIST_REQUEST_SUCCESS = 'GET_IMPORT_SURVEYS_LIST_REQUEST_SUCCESS';
export const GET_IMPORT_SURVEYS_LIST_REQUEST_ERROR = 'GET_IMPORT_SURVEYS_LIST_REQUEST_ERROR';

export const DOWNLOAD_IMPORT_FILE_REQUEST = 'DOWNLOAD_IMPORT_FILE_REQUEST';
export const DOWNLOAD_IMPORT_FILE_SUCCESS = 'DOWNLOAD_IMPORT_FILE_SUCCESS';
export const DOWNLOAD_IMPORT_FILE_ERROR = 'DOWNLOAD_IMPORT_FILE_ERROR';

export const CLEANUP_IMPORTS_PAGE = 'CLEANUP_IMPORTS_PAGE';
export const IMPORTS_LIST_FILTERS_CHANGED = 'IMPORTS_LIST_FILTERS_CHANGED';

function getInitialState() {
    return {
        filters: {
            page: 1,
            limit: 10,
            sort_field: '',
            sort_order: '',
            search: '',
            hide_empty_imports: false,
        },
        imports: {
            data: [],
            count: 0,
            loading: true,
        },
        surveys: {
            data: [],
            count: 0,
            selected: null,
            loading: true,
        },
    };
}

export function ImportsReducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_IMPORTS_LIST_REQUEST:
            return {
                ...state,
                imports: {
                    ...state.imports,
                    loading: true,
                },
            };

        case GET_IMPORTS_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                imports: {
                    count: Number(action.response.count),
                    data: action.response.results,
                    loading: false,
                },
            };

        case GET_IMPORTS_LIST_REQUEST_ERROR:
            return {
                ...state,
                error: action.error,
            };

        case IMPORTS_LIST_FILTERS_CHANGED:
            const filters = {
                ...state.filters,
                ...action.payload,
            };

            HelperService.setInStorage(localStorage, 'VocStore.ImportsList.filters', filters);
            return {
                ...state,
                filters,
            };

        case GET_IMPORT_SURVEYS_LIST_REQUEST:
            return {
                ...state,
                surveys: {
                    ...state.surveys,
                    loading: true,
                },
            };

        case GET_IMPORT_SURVEYS_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                surveys: {
                    count: Number(action.response.count),
                    data: action.response.results,
                    loading: false,
                },
            };

        case GET_IMPORT_SURVEYS_LIST_REQUEST_ERROR:
            return {
                ...state,
                error: action.error,
            };

        case UPDATE_IMPORT_LIST:
            const importsList = state.imports.data.map(item => {
                if (item.id === action.updatedImport.id) {
                    item = {
                        ...item,
                        ...action.updatedImport,
                    };
                }
                return item;
            });

            return {
                ...state,
                imports: {
                    ...state.imports,
                    data: importsList,
                },
            };

        case CLEANUP_IMPORTS_PAGE:
            return getInitialState();

        default:
            return state;
    }
}

export function getDataConstructor(endpoint, body, types) {
    return {
        [CALL_API]: {
            endpoint: endpoint,
            method: 'GET',
            contentType: 'application/json',
            apiType: FEEDBACK,
            types: types,
            body,
        },
    };
}

export function getImports(body) {
    return getDataConstructor(`/v2/import/expand-basic/`,
        body,
        [
            GET_IMPORTS_LIST_REQUEST,
            GET_IMPORTS_LIST_REQUEST_SUCCESS,
            GET_IMPORTS_LIST_REQUEST_ERROR,
        ],
    );
}

export function getSurveys() {
    return getDataConstructor(`/survey-group/expand-full/`,
        {
            limit: 9999,
            hide_deleted_surveys: true,
        },
        [
            GET_IMPORT_SURVEYS_LIST_REQUEST,
            GET_IMPORT_SURVEYS_LIST_REQUEST_SUCCESS,
            GET_IMPORT_SURVEYS_LIST_REQUEST_ERROR,
        ],
    );
}

export function updateFilters(payload) {
    return {
        type: IMPORTS_LIST_FILTERS_CHANGED,
        payload,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_IMPORTS_PAGE,
    };
}

export function makeImport(file, payload) {
    return dispatch => {
        return dispatch(uploadImportFile(file)).promise
            .then(({ filename: original_file }) => {
                return dispatch(addImportData({ ...payload, original_file })).promise;
            });
    };
}

export function addImportData(payload) {
    return {
        [CALL_API]: {
            endpoint: '/v2/import/',
            method: 'POST',
            contentType: 'application/json',
            apiType: FEEDBACK,
            types: [
                ADD_IMPORT_REQUEST,
                ADD_IMPORT_SUCCESS,
                ADD_IMPORT_ERROR,
            ],
            body: payload,
            logger: true,
            loggerMessages: {
                success: ADD_IMPORT_SUCCESS_MESSAGE,
                error: ADD_IMPORT_ERROR_MESSAGE,
            },
        },
    };
}

export function uploadImportFile(formData) {
    return {
        [CALL_API]: {
            endpoint: '/v2/import/upload-importfile/',
            method: 'POST',
            contentType: 'multipart/form-data',
            apiType: FEEDBACK,
            types: [
                UPLOAD_IMPORT_FILE_REQUEST,
                UPLOAD_IMPORT_FILE_SUCCESS,
                UPLOAD_IMPORT_FILE_ERROR,
            ],
            logger: true,
            body: {
                file: formData,
            },
        },
    };
}

export function downloadImportFile({ id, type }) {
    return {
        [CALL_API]: {
            endpoint: `/v2/import/${id}/download-${type}/`,
            method: 'GET',
            contentType: 'application/json',
            apiType: FEEDBACK,
            types: [
                DOWNLOAD_IMPORT_FILE_REQUEST,
                DOWNLOAD_IMPORT_FILE_SUCCESS,
                DOWNLOAD_IMPORT_FILE_ERROR,
            ],
        },
    };
}

export function updateImportList(updatedImport) {
    return {
        type: UPDATE_IMPORT_LIST,
        updatedImport,
    };
}
