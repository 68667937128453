import React, { Fragment, Component } from 'react';
import { SettingFieldContainer } from '../components';
import { VocSelect } from '/components';
import { autobind } from 'core-decorators';
import { FormText } from 'reactstrap';

export class SettingSelectField extends Component {
    @autobind
    onChange(selected) {
        const {
            normalizer,
            multiNormalizer,
            isMulti,
            input: {
                onChange,
                value,
            },
        } = this.props;

        const model = {
            ...value,
            value: isMulti ? multiNormalizer(selected) : normalizer(selected),
        };

        onChange(model);
    }

    @autobind
    onFocus() {
        this.props.input.onFocus(this.props.input.value);
    }

    @autobind
    onBlur() {
        this.props.input.onBlur(this.props.input.value);
    }

    render() {
        const {
            input,
            formatter,
            clearable,
            withError,
            meta: {
                error,
                touched,
                dirty,
            },
            label,
            options,
            isMulti,
            input: {
                value: {
                    redefined,
                    value,
                },
            },
        } = this.props;

        return (
            <Fragment>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                <SettingFieldContainer
                    checked={ redefined }
                    field={ input }
                    className={ `${dirty ? 'changed' : ''}` }
                >
                    <VocSelect
                        id={ input.name }
                        className={ 'voc-setting-field__select' }
                        disabled={ !redefined }
                        onChange={ this.onChange }
                        onFocus={ this.onFocus }
                        onBlur={ this.onBlur }
                        isClearable={ clearable }
                        options={ options }
                        value={ formatter(options, value) }
                        isMulti={ isMulti }
                    />
                </SettingFieldContainer>

                {
                    Boolean(touched && error && withError)
                        ? <FormText className="app-form-control__error">{ error }</FormText>
                        : false
                }

            </Fragment>
        );
    }
}

SettingSelectField.defaultProps = {
    withError: true,
    clearable: true,
};
