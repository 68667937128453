import { createSelector } from 'reselect';
import { isSupportUserSelector } from '/modules/selectors';
import { UsersRoleService } from '/services';

export const _getSystemUploadSelect = state => state.VocStore.vocSystemUpload;

export const systemUploadSelect = createSelector(
    [ _getSystemUploadSelect, isSupportUserSelector ],
    (uploads, isSupportUser) => {
        return {
            ...uploads,
            tableData: [
                ...uploads.tableData.map(upload => {
                    const rights = UsersRoleService.getRightsForSystemUploads(isSupportUser, upload.currentUserPermission);

                    return {
                        ...upload,
                        languages: upload.languages.map(lang => lang.name).join().replace(',', ', '),
                        isShares: !!upload?.shares?.length,
                        rights,
                        uuidMongo: upload.id,
                        attrByDate: upload.attributes.filter(attribute => attribute.type === 'DATE'),
                    };
                }),
            ],
        };
    },
);
