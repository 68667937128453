export enum EDashboardActionType {
    COPY = 'copyDashboard',
    CREATE = 'createDashboard',
}

export type TPlaceholderObject = {
    minX: number;
    maxW: number;
    minY: number;
    maxY: number;
};

export type TPlaceholders = Record<string, TPlaceholderObject[]>

export type TPageLayout = {
    x: number;
    y: number;
    cols: number;
}
