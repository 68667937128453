import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TooltipWrapper } from '/components';

import styles from './style.module.scss';

export const ActionFormatter = ({
    openModalDelete,
    openModalCreateClassifierAppliance,
    openSharesModal,
    currentUserPermission,
}) => {
    const isView = currentUserPermission === 'view';
    const isEdit = currentUserPermission === 'edit';

    return (
        <div className={ styles.actionFormatterWrapper }>
            <TooltipWrapper force value='Share'>
                <span>
                    <Button disabled={ isView || isEdit } onClick={ !isView && !isEdit && openSharesModal } color='link'>
                        <FontAwesomeIcon icon='share'/>
                    </Button>
                </span>
            </TooltipWrapper>
            <TooltipWrapper force value='Apply to source'>
                <span>
                    <Button onClick={ openModalCreateClassifierAppliance } color='link'>
                        <FontAwesomeIcon icon='bullseye'/>
                    </Button>
                </span>
            </TooltipWrapper>
            <TooltipWrapper force value='Delete'>
                <span>
                    <Button disabled={ isView || isEdit } onClick={ !isView && !isEdit && openModalDelete } color='link'>
                        <FontAwesomeIcon icon='trash'/>
                    </Button>
                </span>
            </TooltipWrapper>
        </div>
    );
};
