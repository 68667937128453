import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { DynamicFormButton } from './components/DynamicFormButton';
import { DynamicFormDataCreation } from './components/DynamicFormDataCreation';
import './DynamicForm.scss';

export class DynamicForm extends Component {
    state = {};

    @autobind
    addNewForm() {
        const { typeFieldsForm } = this.props.settings;
        const objEmptyFields = typeFieldsForm.reduce((result, field) => ({ ...result, [field]: '' }), {});

        this.props.addNewForm(this.props.name, objEmptyFields);
    }

    render() {
        const {
            data,
            settings,
            name,
            handlChange,
            removeForm,
            onSortEnd,
        } = this.props;

        return (
            <div className="dynamic-form_container">
                {
                    data.length
                        ? (
                            <DynamicFormDataCreation
                                settings={ settings }
                                name={ name }
                                handlChange={ handlChange }
                                addNewForm={ this.addNewForm }
                                removeForm={ removeForm }
                                onSortEnd={ onSortEnd }
                                data={ data }
                            />
                        )
                        : (
                            <Fragment>
                                <p className='dynamic-form_box-empty'>{ settings.emptyForm }</p>

                                <DynamicFormButton
                                    button={ settings.btnName }
                                    addNewForm={ this.addNewForm }
                                />
                            </Fragment>
                        )
                }


            </div>
        );
    }
}
