import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { PageRequests } from '/services';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    CaseAlertsListTable,
    CaseAlertsConditionsPopover,
    CaseAlertManageForm,
    CaseAlertConditionForm,
} from './components';
import { VocCleaupHOC } from '/components';
import { getClassifierList } from '/feedback/modules/VocFeedback.modules';

import {
    getCaseAlertsList,
    cleanup,
    updateFilters,
    deleteCaseAlert,
    getConditionsList,
    updateCaseAlertCurrent,
    cleanupCaseAlert,
    manageCaseAlertActions,
    updateCurrentCaseAlertCondition,
    cleanupCondition,
    deleteCaseAlertCondition,
    manageCaseAlertCondition,
    setCurrentConditionCaseAlert,
} from './modules/caseAlerts.modules';
import {
    caseAlertsTableData,
    caseAlertsFilters,
    caseAlertsListTableStateSelector,
    conditionsSelector,
    caseAlertSelector,
    conditionsFormValuesSelector,
    caseAlertConditionFieldsMap,
    caseAlertCurrentConditionSelector,
    caseAlertSubmitSelector,
    caseAlertConditionSubmitSelector,
} from './modules/caseAlerts.selectors';
import { getPermissionSelector } from '../../modules/globalSettings.selectors';

import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import './CaseAlerts.scss';

const mapStateToProps = state => ({
    tableData: caseAlertsTableData(state),
    tableState: caseAlertsListTableStateSelector(state),
    filters: caseAlertsFilters(state),
    conditions: conditionsSelector(state),
    caseAlert: caseAlertSelector(state),
    conditionsFormValues: conditionsFormValuesSelector(state),
    conditionFieldsMap: caseAlertConditionFieldsMap(state),
    currentCondition: caseAlertCurrentConditionSelector(state),
    caseAlertSubmit: caseAlertSubmitSelector(state),
    caseAlertConditionSubmit: caseAlertConditionSubmitSelector(state),
    permission: getPermissionSelector(state),
});

const mapDispatchToProps = {
    getCaseAlertsList,
    cleanup,
    updateFilters,
    showConfirm,
    deleteCaseAlert,
    getClassifierList,
    getConditionsList,
    updateCaseAlertCurrent,
    cleanupCaseAlert,
    manageCaseAlertActions,
    updateCurrentCaseAlertCondition,
    cleanupCondition,
    deleteCaseAlertCondition,
    manageCaseAlertCondition,
    setCurrentConditionCaseAlert,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class CaseAlerts extends Component {
    pageRequests = new PageRequests();
    state = {
        showCaseAlertPopover: false,
        caseAlertPopoverTarget: null,
        caseAlertModalOpen: false,
        caseAlertConditionModalOpen: false,
    };

    componentDidMount() {
        const classifiersRequest = this.props.getClassifierList();

        this.pageRequests.addRequest(classifiersRequest);
        this.requestTrigger();
    }

    getTableOptions() {
        return {
            requestTrigger: this.requestTrigger,
            updateFilters: this.props.updateFilters,
            rowStyle: {
                height: '38px',
            },
            haveSearch: true,
        };
    }

    @autobind
    requestTrigger({ limit, page, search } = this.props.filters) {
        this.pageRequests.cleanupById('caseAlerts');
        const request = this.props.getCaseAlertsList({ limit, page, search });

        this.pageRequests.addRequest(request, 'caseAlerts');
    }

    @autobind
    openCaseAlertConditionModal(value) {
        const { condition, id } = value;

        condition && this.props.updateCurrentCaseAlertCondition(condition);
        id && this.props.setCurrentConditionCaseAlert(id);
        this.setState({
            caseAlertConditionModalOpen: true,
            showCaseAlertPopover: false,
        });
    }

    @autobind
    closeCaseAlertConditionModal() {
        this.props.cleanupCondition();
        this.setState({
            caseAlertConditionModalOpen: false,
        });
    }

    @autobind
    openCaseAlertModal(caseAlert = null) {
        caseAlert && this.props.updateCaseAlertCurrent(caseAlert);
        this.setState({ caseAlertModalOpen: true });
    }

    @autobind
    closeCaseAlertModal() {
        this.props.cleanupCaseAlert();
        this.setState({
            caseAlertModalOpen: false,
        });
    }

    @autobind
    openModalDelete(id) {
        this.props.showConfirm({
            header: 'Warning!',
            content: 'You are going to delete the Case Alert Settings, continue?',
            successCallback: () => this.props.deleteCaseAlert(id).promise
                .then(() => this.requestTrigger()),
        });
    }

    @autobind
    deleteCaseAlertCondition(caseAlertId, condition) {
        this.props.showConfirm({
            header: 'Warning!',
            content: 'You are going to delete the Case Alert Settings, continue?',
            successCallback: () => this.props.deleteCaseAlertCondition(caseAlertId, condition.id),
        });
    }

    @autobind
    toggleConditionPopover() {
        const newState = {
            showCaseAlertPopover: !this.state.showCaseAlertPopover,
        };

        if (this.state.showCaseAlertPopover) {
            newState.caseAlertPopoverTarget = null;
        }

        this.setState(newState);
    }

    @autobind
    openConditionPopover(caseAlertPopoverTarget, id) {
        this.setState({
            caseAlertPopoverTarget,
            showCaseAlertPopover: true,
            currentCaseAlertId: id,
        });
        this.props.getConditionsList(id);
        this.props.setCurrentConditionCaseAlert(id);
    }

    @autobind
    submitCaseAlertConditionForm(condition, id) {
        this.props.manageCaseAlertCondition(condition, id).promise
            .then(() => {
                this.closeCaseAlertConditionModal();
                this.requestTrigger();
            });
    }

    @autobind
    submitCaseAlertForm(case_alert) {
        return this.props.manageCaseAlertActions(case_alert).then(() => {
            this.closeCaseAlertModal();
            this.requestTrigger();
        });
    }

    render() {
        const {
            filters,
            tableData,
            tableState,
            conditions,
            caseAlert,
            currentCondition,
            conditionsFormValues,
            conditionFieldsMap,
            caseAlertSubmit,
            caseAlertConditionSubmit,
            permission,
        } = this.props;

        const {
            caseAlertModalOpen,
            showCaseAlertPopover,
            caseAlertPopoverTarget,
            caseAlertConditionModalOpen,
        } = this.state;

        return (
            <DocumentTitle title={ 'Case Alerts' }>
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Case Alerts</span>
                        <div className={ 'panel__header-buttons' }>
                            <Button className={ 'panel__header-button' }
                                color="white"
                                onClick={ () => permission.isPermitEdit && this.openCaseAlertModal() }
                                disabled={ !permission.isPermitEdit }
                                outline
                            >
                                <FontAwesomeIcon icon='plus' className='color-green'/>
                                <span> Add Case Alert</span>
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <CaseAlertsListTable
                            filters={ filters }
                            options={ this.getTableOptions() }
                            data={ tableData }
                            tableState={ tableState }
                            openModalAddCondition={ this.openCaseAlertConditionModal }
                            openModalDelete={ this.openModalDelete }
                            openConditionPopover={ this.openConditionPopover }
                            openCaseAlertModal={ this.openCaseAlertModal }
                            permission={ permission }
                        />
                        {
                            showCaseAlertPopover
                            && <CaseAlertsConditionsPopover
                                toggle={ this.toggleConditionPopover }
                                target={ caseAlertPopoverTarget }
                                show={ showCaseAlertPopover }
                                conditions={ conditions }
                                openCaseAlertConditionForm={ this.openCaseAlertConditionModal }
                                deleteCaseAlertCondition={ this.deleteCaseAlertCondition }
                                caseAlert={ currentCondition.case_alert }
                                permission={ permission }
                            />
                        }
                        <CaseAlertManageForm
                            initialValues={ caseAlert }
                            show={ caseAlertModalOpen }
                            close={ this.closeCaseAlertModal }
                            submitCaseAlertForm={ this.submitCaseAlertForm }
                            caseAlertSubmit={ caseAlertSubmit }
                        />
                        <CaseAlertConditionForm
                            hidden={ currentCondition.hidden }
                            initialValues={ currentCondition }
                            show={ caseAlertConditionModalOpen }
                            close={ this.closeCaseAlertConditionModal }
                            formValuesSelector={ conditionsFormValues }
                            fieldsMap={ conditionFieldsMap }
                            submitCaseAlertConditionForm={ this.submitCaseAlertConditionForm }
                            caseAlertConditionSubmit={ caseAlertConditionSubmit }
                            permission={ permission }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}
