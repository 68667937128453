import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusSquare as farPlusSquare, faDotCircle, faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import {
    faSortAmountDownAlt,
    faSortAmountUp,
    faTh,
    faExclamationCircle,
    faCompressAlt,
    faExpandArrowsAlt,
    faSyncAlt,
    faFolder,
    faLayerGroup,
    faTextHeight,
    faChartBar,
    faCloud,
    faBan,
    faDownload,
    faSitemap,
    faList,
    faFileImport,
    faFileExport,
    faFilePdf,
    faFilePowerpoint,
    faTrash,
    faTrashAlt,
    faEye,
    faEyeSlash,
    faListUl,
    faPencilAlt,
    faPlus,
    faArrowCircleRight,
    faArrowCircleLeft,
    faArrowCircleDown,
    faCheck,
    faTimes,
    faRocket,
    faCog,
    faBell,
    faMinus,
    faInfoCircle,
    faComments,
    faToggleOn,
    faArrowDown,
    faArrowRight,
    faArrowLeft,
    faToggleOff,
    faCircleNotch,
    faSave,
    faStar,
    faCheckCircle,
    faMinusCircle,
    faPlusCircle,
    faCopy,
    faCaretDown,
    faCaretUp,
    faBars,
    faFilter,
    faAngleDown,
    faCaretSquareDown,
    faSearch,
    faCheckSquare,
    faCloudUploadAlt,
    faAngleDoubleLeft,
    faCloudDownloadAlt,
    faCaretRight,
    faThList,
    faClock,
    faEnvelope,
    faCaretSquareUp,
    faShareSquare,
    faShare,
    faAngleUp,
    faPlusSquare,
    faExclamationTriangle,
    faUpload,
    faAngleRight,
    faAngleLeft,
    faAngleDoubleRight,
    faAngleDoubleUp,
    faAngleDoubleDown,
    faSort,
    faChevronUp,
    faChevronDown,
    faChartLine,
    faExchangeAlt,
    faMobileAlt,
    faUser,
    faUserEdit,
    faUserCog,
    faUserSecret,
    faUsers,
    faUsersCog,
    faGlobe,
    faCircle,
    faLock,
    faLockOpen,
    faPaperPlane,
    faFileSignature,
    faEllipsisH,
    faCode,
    faColumns,
    faBullseye,
    faAlignJustify,
    faRegistered,
    faEraser,
    faPalette,
    faImage,
    faCrosshairs,
    faSync,
    faWrench,
    faCalendarCheck,
    faThumbsUp,
    faArrowsAltH,
    faPrint,
    faCalendarAlt,
    faBookmark,
    faComment,
    faPen,
    faPaste,
    faHandScissors,
    faArrowsAlt,
    faCut,
    faLevelUpAlt,
    faWindowMinimize,
    faDesktop,
    faUndo,
    faExpandAlt,
    faFlag,
    faBookOpen,
    faTasks,
} from '@fortawesome/free-solid-svg-icons';

export function fontAwesomeInit() {
    library.add(
        faWindowMinimize,
        faExclamationCircle,
        faTh,
        faSortAmountDownAlt,
        faSortAmountUp,
        faPen,
        faComment,
        faExpandAlt,
        faDesktop,
        faCut,
        faCompressAlt,
        faExpandArrowsAlt,
        faBookmark,
        faBan,
        faDownload,
        faSitemap,
        faList,
        faEyeSlash,
        faEye,
        faFileImport,
        faFileExport,
        faFilePdf,
        faFilePowerpoint,
        faTrash,
        faTrashAlt,
        faListUl,
        faPencilAlt,
        faPlus,
        faArrowCircleRight,
        faArrowCircleLeft,
        faArrowCircleDown,
        faCheck,
        faTimes,
        faCog,
        faBell,
        faMinus,
        faInfoCircle,
        faComments,
        faToggleOn,
        faToggleOff,
        faCircleNotch,
        faSave,
        faCloud,
        faStar,
        faCheckCircle,
        faDotCircle,
        faMinusCircle,
        faPlusCircle,
        faCloudDownloadAlt,
        faCopy,
        faCaretDown,
        faCaretRight,
        faRocket,
        faCaretUp,
        faFilter,
        faBars,
        faThList,
        faClock,
        faSearch,
        faEnvelope,
        faChartBar,
        faCaretSquareUp,
        faCaretSquareDown,
        faShareSquare,
        faShare,
        faAngleDown,
        faAngleUp,
        faCheckSquare,
        faPlusSquare,
        faCloudUploadAlt,
        faArrowDown,
        faArrowRight,
        faArrowLeft,
        faExclamationTriangle,
        faUpload,
        faAngleRight,
        faAngleLeft,
        faAngleDoubleRight,
        faAngleDoubleLeft,
        faAngleDoubleUp,
        faAngleDoubleDown,
        faSort,
        faChevronUp,
        faChevronDown,
        faFileSignature,
        faEllipsisH,
        faChartLine,
        faExchangeAlt,
        faUser,
        faUserEdit,
        faUserCog,
        faUserSecret,
        faUsers,
        faUsersCog,
        faGlobe,
        faCircle,
        faLock,
        faLockOpen,
        faPaperPlane,
        faCode,
        faColumns,
        faMobileAlt,
        faBullseye,
        faFolder,
        faRegistered,
        faLayerGroup,
        faCloud,
        faTextHeight,
        faSyncAlt,
        faAlignJustify,
        faEraser,
        faPalette,
        faImage,
        faCrosshairs,
        faSync,
        faWrench,
        faCalendarCheck,
        faThumbsUp,
        faArrowsAltH,
        faPrint,
        faCalendarAlt,
        faPaste,
        faHandScissors,
        faArrowsAlt,
        faLevelUpAlt,
        farPlusSquare,
        faUndo,
        faWindowRestore,
        faFlag,
        faBookOpen,
        faTasks,
    );
}
