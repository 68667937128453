import React from 'react';
import { TooltipWrapper, PreloaderV2 } from '/components';

import informationSign from '/images/information_sign.png';
import './StatisticResultsBlock.scss';

export const StatisticResultsBlock = ({
    value,
    label,
    information = null,
    decimals = null,
    loading,
    channel,
    about,
}) => {
    return (
        <div className={ 'statistic_result__block' }>
            <div className={ 'statistic_result__block-value' }>
                <span>
                    {
                        loading ? <PreloaderV2/>
                            : <span>
                                { about && about }
                                { value }
                                { decimals && <span>{ decimals }</span> }
                            </span>
                    }
                </span>

            </div>
            <div className={ 'statistic_result__block-label' }>
                <span>{ label }</span>
                { (information && channel !== 'DIGI')
                && <div className={ 'statistic_result__block-label_information' }>
                    <TooltipWrapper value={ information } force={ true }>
                        <img src={ informationSign }/>
                    </TooltipWrapper>
                </div>
                }
            </div>
        </div>
    );
};
