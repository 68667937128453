import { createSelector } from 'reselect';

export const questionsStatisticsSelector = state => state.VocFeedback.survey.questionsStatistics;

export const questionsStatisticsFiltersSelector = createSelector([ questionsStatisticsSelector ],
    statistics => statistics.filters);

export const questionsStatisticsResponsesSelector = createSelector([ questionsStatisticsSelector ], statistics => {
    return {
        loading: statistics.loading,
        values: statistics.values.map(statValue => ({
            title: statValue.title,
            responses: statValue.answers_total,
            collapsible: Boolean(statValue.answers.length),
            answers: statValue.answers.map(({ title, value }) => ({
                title: title === 'dont_know' ? "don't know" : title,
                value,
                responseRatio: value ? ((value / statValue.answers_total) * 100).toFixed(2) : 0,
            })),
        })),
    };
});
