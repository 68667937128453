import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

export const PreviewModeButton = ({ onClick, className, disabled }) => {
    return (
        <Button
            onClick={ onClick }
            className={ `util-button ${className}` }
            color={ 'white' }
            disabled={ disabled }
            outline
        >
            <FontAwesomeIcon icon={ `eye` } className={ 'util-button__icon util-button__icon--lock' }/>
            <span>Preview</span>
        </Button>
    );
};
