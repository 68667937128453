import { sockets } from '/services';
import { updateImportList, getImports } from './imports.modules';
import { filtersSelector } from './imports.selectors';

function socketCallback(updatedImport, store) {
    if (location.pathname === '/feedback/import') {
        const { total_row_count, processed_row_count, failed_row_count } = updatedImport;

        store.dispatch(updateImportList(updatedImport));

        if ( total_row_count === ( processed_row_count + failed_row_count )) {
            const state = store.getState();
            const filters = filtersSelector(state);

            store.dispatch(getImports(filters));
        }
    }
}

export function socketImportUpdateListener() {
    return sockets.on('feedback_imports_progress', socketCallback);
}
