import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import { AdditionalSelectFilters } from "./components";
import { SelectAsyncPaginate } from "/components";
import { ISelectAsyncPaginateWithFiltersProps } from "./models";

import styles from './style.module.scss';

export const SelectAsyncPaginateWithFilters = ({
    toogleAdditionalFilters,
    id,
    openedAdditionalFilters,
    setPage,
    exclude_sources,
    placeholder,
    total,
    filters,
    onChange,
    value,
    loading,
    getSources,
    options,
    setSelectFilters,
    radioFilters,
    surveyFilters,
    disabled,
    disableOutsideClose,
}: ISelectAsyncPaginateWithFiltersProps) => {
    const toggleOpenFilters = () => {
        toogleAdditionalFilters({ openedAdditionalFilters: !openedAdditionalFilters, id });
    };

    const renderSelect = () => {
        return (
            <div className={ styles.vocSelectFilters }>
                <div
                    className={ cx(styles.vocSelectFiltersIcon, { active: openedAdditionalFilters }) }
                    onClick={ toggleOpenFilters }
                >
                    <FontAwesomeIcon icon={ 'filter' }/>
                </div>
                <SelectAsyncPaginate
                    setPage={ setPage }
                    exclude_sources={ exclude_sources }
                    placeholder={ placeholder }
                    total={ total }
                    className={ cx(styles.sourceSelect, 'vochub-select-control no-margin') }
                    filters={ filters }
                    id={ id }
                    onChange={ onChange }
                    value={ value }
                    loading={ loading }
                    options={ options }
                    getSources={ getSources }
                    setSelectFilters={ setSelectFilters }
                    isDisabled={ disabled }
                    closeMenuOnSelect={ true }
                />
            </div>
        );
    };

    return (
        <div className={ cx( styles.remoteSelectWrapper, { "disabled": disabled } ) }>
            {
                renderSelect()
            }

            {
                openedAdditionalFilters
                && <AdditionalSelectFilters
                    id={ id }
                    filters={ filters }
                    radioFilters={ radioFilters }
                    surveyFilters={ surveyFilters }
                    setSelectFilters={ setSelectFilters }
                    disableOutsideClose={ disableOutsideClose }
                    toogleAdditionalFilters={ toogleAdditionalFilters }
                />
            }
        </div>
    );
};
