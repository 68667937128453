import { createPortal } from 'react-dom';
import { usePortal } from '/services';

type PortalProps = { id: string, children: JSX.Element };

export const Portal = ({ id, children }: PortalProps) => {
    const target = usePortal(id);

    return createPortal(
        children,
        target,
    );
};
