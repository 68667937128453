import React from "react";
import { useTranslation } from 'react-i18next';

import { VocMetaFilter } from "/components";
import { MetaQueryService, useForceUpdate } from "/services";
import { ISourceData } from "/visual/scenes/Dashboard/models";

import styles from './style.module.scss';

interface IMetaFilterWrapperProps {
    source: ISourceData;
    metaQuery: {
        hash: string;
        items: any[];
    };
    onChange: () => void;
}

export const MetaFilterWrapper = ({ source, metaQuery, onChange }: IMetaFilterWrapperProps) => {
    const { t } = useTranslation();
    const forceUpdate = useForceUpdate();

    const onChangeHandler = () => {
        onChange();
        forceUpdate();
    };

    const getQueryString = () => (
        MetaQueryService.clientQueryToString(
            {
                query: metaQuery,
                source: source,
            },
        )
    );

    return (
        <>
            <VocMetaFilter
                attributeValueKey={ 'index' }
                attributePlaceholder={ t('field.inputVariable') }
                metaQuery={ metaQuery }
                onChangedMetaQueryItem={ onChangeHandler }
                source={ source }
                isVisual
            />
            <p className={ styles.queryString }>{ getQueryString() }</p>
        </>
    );
};
