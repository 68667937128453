export class DependencyMatrixService {
    /* Helper */
    static getAttr(settings: [any], name: string) {
        const { selected, options } = settings.find(({ id }: any) => id === name);
        const attrId = selected ? selected[0] : selected;
        const currentAttr = options.find(({ id }: any) => id === attrId);
        const type = currentAttr && currentAttr.type;

        return type || null;
    }

    static getSelected(settings: [any], name: string) {
        return settings.find(({ id }: any) => id === name).selected[0] || null;
    }

    static getSettings(settings: [any], name: string) {
        return settings.find(({ id }: any) => id === name);
    }

    static npsSegmentFactTypes = [ 'NPS_SEGMENT', 'CHOICE_NPS_SEGMENT' ];
    static npsSegmentsCharts = [ 'line_chart', 'radar_chart', 'stacked_bar_chart' ];
    static npsScoreSingleGroupCharts = [ 'radar_chart', 'stacked_bar_chart', 'surveys_bar_chart', 'custom_columns_table' ];

    static isNpsFactsOnly(factTypes: any) {
        return (factTypes && factTypes.length)
            ? factTypes.every((type: any) => DependencyMatrixService.npsSegmentFactTypes.includes(type))
            : false;
    }

    static isNpsSegmentChart(chartType: any) {
        return chartType
            ? DependencyMatrixService.npsSegmentsCharts.includes(chartType)
            : false;
    }

    static isSingleGroupNpsChart(chartType: any) {
        return chartType
            ? DependencyMatrixService.npsScoreSingleGroupCharts.includes(chartType)
            : false;
    }

    /* Matrix */
    static getConditionResult(condition: any, hashForCompare: any) {
        const fields = Object.keys(condition);
        let valid: any = true;

        fields.forEach(field => {
            const isArray = Array.isArray(hashForCompare[field]);
            const getIncludes = (type: string) => {
                return isArray
                    ? hashForCompare[field].some(data => condition[field][type].includes(data))
                    : condition[field][type].includes(hashForCompare[field]);
            };

            valid = Object.prototype.hasOwnProperty.call(condition[field], 'is')
                ? valid && getIncludes('is')
                : valid && !getIncludes('not');
        });

        return valid;
    }

    static makeRestriction(restriction: any, settings: any) {
        const fields = Object.keys(restriction);

        fields.forEach( field => {
            const setting = DependencyMatrixService.getSettings(settings, field);

            if (!setting) {
                return;
            }

            setting.options = setting.options.filter( ({ value, id, type }: any) => {
                const compareValue = type ? type : id || value;

                return (Object.prototype.hasOwnProperty.call(restriction[field], 'is'))
                    ? restriction[field].is.includes(compareValue)
                    : !restriction[field].not.includes(compareValue);
            });
        });
    }

    static restrictUploadsList(hashForCompare: any, settings: any) {
        const uploadSetting = settings.find(({ id }: any) => id === 'sourceId');

        const rules = {
            chartType: [
                {
                    condition: [ 'surveys_bar_chart', 'line_chart', 'stacked_bar_chart', 'generic_pie_chart', 'radar_chart' ],
                    onlyOpen: false,
                },
                {
                    condition: [ 'nps_bubble_chart' ],
                    hasNPS: true,
                },
            ],
            function: [
                {
                    condition: [ 'count' ],
                    onlyOpen: false,
                },
                {
                    condition: [ 'average', 'variance', 'median', 'sum' ],
                    hasNumeric: true,
                },
                {
                    condition: [ 'npsScore', 'affection' ],
                    hasNPS: true,
                },
            ],
        };

        for (const field in rules) {
            if (Object.prototype.hasOwnProperty.call(rules, field)) {
                if (!hashForCompare[field]) {
                    continue;
                }

                rules[field].forEach( (subRule: any) => {
                    const conditionResult = subRule.condition.includes(hashForCompare[field]);

                    if (conditionResult) {
                        const ruleKeys = Object.keys(subRule);

                        ruleKeys.splice(0, 1);

                        uploadSetting.options = uploadSetting.options.filter( (dataSource: any) => {
                            let visibleSourceFlag = false;

                            ruleKeys.forEach( infoField => {
                                visibleSourceFlag = subRule[infoField] === dataSource[infoField];
                            });

                            return visibleSourceFlag;
                        });
                    }
                });
            }
        }
    }

    static sortFactForWordCloud(factSetting: any) {
        const openTextOptions: any = [];

        factSetting.options.forEach( (item: any, index: number) => {
            if (item.type === 'TEXT') {
                openTextOptions.push(item);
                delete factSetting.options[index];
            }
        });

        factSetting.options = openTextOptions.concat(factSetting.options.filter((option: any) => option));
    }

    static rules = [
        {
            condition: {
                facts: {
                    is: [ 'NPS', 'CHOICE_NPS' ],
                },
                function: {
                    is: [ 'npsScore' ],
                },
                groupBy: {
                    is: [ 'NPS_SEGMENT' ],
                },
                secondGroupBy: {
                    not: [ 'TEXT', null ],
                },
            },
            restriction: {
                chartType: {
                    is: [ 'surveys_bar_chart', 'line_chart', 'custom_columns_table', 'stacked_bar_chart' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    not: [ 'line_chart', null ],
                },
            },
            restriction: {
                function: {
                    not: [ 'accSum' ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'accSum' ],
                },
            },
            restriction: {
                chartType: {
                    is: [ 'line_chart' ],
                },
                facts: {
                    is: [ 'NUMERIC', 'SCALABLE', 'NPS', 'CHOICE_NPS', 'CHOICE_NUMERIC' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    is: [ 'line_chart' ],
                },
            },
            restriction: {
                function: {
                    is: [ 'count', 'average', 'sum', 'variance', 'median', 'accSum', 'npsScore' ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'impact' ],
                },
            },
            restriction: {
                chartType: {
                    is: [ 'nps_bubble_chart' ],
                },
                facts: {
                    is: [ 'NPS', 'SCALABLE', 'NUMERIC', 'CHOICE_NPS', 'CHOICE_NUMERIC' ],
                },
                groupBy: {
                    is: [ 'META', 'DATE', 'NPS_SEGMENT', 'CHOICE', 'CHOICE_NPS_SEGMENT' ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'impact' ],
                },
                facts: {
                    is: [ 'SCALABLE', 'NUMERIC', 'CHOICE_NUMERIC' ],
                },
            },
            restriction: {
                chartType: {
                    is: [ 'nps_bubble_chart' ],
                },
                groupBy: {
                    is: [ 'META', 'DATE', 'CHOICE' ],
                },
            },
        },
        {
            condition: {
                function: {
                    not: [ 'count', 'average', 'sum', 'variance', 'median', 'accSum', 'npsScore', null ],
                },
            },
            restriction: {
                chartType: {
                    not: [ 'line_chart' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    is: [ 'nps_bubble_chart' ],
                },
            },
            restriction: {
                facts: {
                    is: [ 'NPS', 'SCALABLE', 'NUMERIC', 'CHOICE_NUMERIC' ],
                },
                function: {
                    is: [ 'impact' ],
                },
                groupBy: {
                    is: [ 'META', 'DATE', 'NPS_SEGMENT', 'CHOICE', 'CHOICE_NPS_SEGMENT' ],
                },
                secondGroupBy: {
                    not: [ 'TEXT' ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'just_show' ],
                },
            },
            restriction: {
                chartType: {
                    is: [ 'custom_columns_table', 'lemmata_word_cloud' ],
                },
                groupBy: {
                    is: [ null ],
                },
                secondGroupBy: {
                    is: [ null ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    is: [ 'lemmata_word_cloud' ],
                },
                groupBy: {
                    is: [ null ],
                },
                secondGroupBy: {
                    is: [ null ],
                },
            },
            restriction: {
                function: {
                    is: [ 'just_show' ],
                },
            },
        },
        {
            condition: {
                function: {
                    not: [ 'just_show', null ],
                },
            },
            restriction: {
                chartType: {
                    not: [ 'lemmata_word_cloud' ],
                },
                facts: {
                    not: [ 'TEXT' ],
                },
            },
        },
        {
            condition: {
                groupBy: {
                    is: [ null ],
                },
                secondGroupBy: {
                    is: [ null ],
                },
            },
            restriction: {
                secondGroupBy: {
                    is: [ null ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    not: [ 'lemmata_word_cloud', 'custom_columns_table', null ],
                },
                facts: {
                    not: [ 'TEXT' ],
                },
            },
            restriction: {
                function: {
                    not: [ 'just_show' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    not: [ 'NPS', 'CHOICE_NPS', null ],
                },
                function: {
                    not: [ 'just_show', null ],
                },
            },
            restriction: {
                'function': {
                    not: [ 'npsScore' ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'npsScore' ],
                },
            },
            restriction: {
                facts: {
                    is: [ 'NPS', 'CHOICE_NPS' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    is: [ 'TEXT' ],
                },
            },
            restriction: {
                function: {
                    is: [ 'just_show' ],
                },
                chartType: {
                    is: [ 'lemmata_word_cloud', 'custom_columns_table' ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'affection' ],
                },
            },
            restriction: {
                chartType: {
                    is: [ 'surveys_bar_chart' ],
                },
                facts: {
                    is: [ 'NPS', 'CHOICE_NPS' ],
                },
                secondGroupBy: {
                    is: [ null ],
                },
            },
        },
        {
            condition: {
                groupBy: {
                    not: [ null ],
                },
            },
            restriction: {
                chartType: {
                    not: [ 'generic_pie_chart', 'lemmata_word_cloud' ],
                },
                function: {
                    not: [ 'just_show' ],
                },
            },
        },
        {
            condition: {
                secondGroupBy: {
                    not: [ null ],
                },
            },
            restriction: {
                chartType: {
                    not: [ 'generic_pie_chart', 'radar_chart', 'lemmata_word_cloud' ],
                },
                function: {
                    not: [ 'just_show' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    is: [ 'generic_pie_chart', 'lemmata_word_cloud' ],
                },
            },
            restriction: {
                groupBy: {
                    is: [ null ],
                },
                secondGroupBy: {
                    is: [ null ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    is: [ 'radar_chart' ],
                },
            },
            restriction: {
                secondGroupBy: {
                    is: [ null ],
                },
            },
        },
        {
            condition: {
                groupBy: {
                    not: [ 'NPS_SEGMENT', 'META', 'DATE', 'CHOICE', 'CHOICE_NPS_SEGMENT', null ],
                },
            },
            restriction: {
                chartType: {
                    not: [ 'nps_bubble_chart' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    is: [ 'generic_pie_chart' ],
                },
                facts: {
                    is: [ 'NPS', 'CHOICE_NPS' ],
                },
            },
            restriction: {
                function: {
                    is: [ 'count', 'npsScore', 'average', 'sum', 'variance', 'median' ],
                },
            },
        },
        {
            condition: {
                function: {
                    not: [ 'impact', null ],
                },
            },
            restriction: {
                chartType: {
                    not: [ 'nps_bubble_chart' ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'count' ],
                },
                facts: {
                    is: [ 'NPS_SEGMENT', 'CHOICE_NPS_SEGMENT' ],
                },
                chartType: {
                    is: [ 'stacked_bar_chart' ],
                },
            },
            restriction: {
                secondGroupBy: {
                    is: [ null ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'count' ],
                },
                facts: {
                    is: [ 'NPS_SEGMENT', 'CHOICE_NPS_SEGMENT' ],
                },
                chartType: {
                    is: [ 'line_chart' ],
                },
            },
            restriction: {
                secondGroupBy: {
                    is: [ null ],
                },
            },
        },
        {
            condition: {
                facts: {
                    is: [ 'NPS_SEGMENT', 'CHOICE_NPS_SEGMENT' ],
                },
            },
            restriction: {
                function: {
                    is: [ 'count', 'just_show' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    not: [ 'NPS', 'CHOICE_NPS', null ],
                },
            },
            restriction: {
                function: {
                    not: [ 'affection' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    not: [ 'NPS', 'NUMERIC', 'SCALABLE', 'CHOICE_NPS', 'CHOICE_NUMERIC', 'TOPIC_SENTIMENT', null ],
                },
            },
            restriction: {
                function: {
                    not: [ 'impact' ],
                },
                chartType: {
                    not: [ 'nps_bubble_chart' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    is: [ 'NUMERIC', 'SCALABLE', 'META', 'DATE', 'LANG', 'CHOICE', 'CHOICE_NUMERIC' ],
                },
            },
            restriction: {
                function: {
                    not: [ 'npsScore' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    not: [ 'nps_bubble_chart', null ],
                },
            },
            restriction: {
                function: {
                    not: [ 'impact' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    not: [ 'surveys_bar_chart', null ],
                },
            },
            restriction: {
                function: {
                    not: [ 'affection' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    not: [ 'lemmata_word_cloud', 'custom_columns_table', null ],
                },
            },
            restriction: {
                facts: {
                    not: [ 'TEXT' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    is: [ 'CLASSIFICATION', 'META', 'DATE', 'CHOICE', 'LANG', 'DATE_FORMAT' ],
                },
            },
            restriction: {
                function: {
                    is: [ 'count', 'just_show' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    is: [ 'choice', 'CHOICE' ],
                },
            },
            restriction: {
                function: {
                    is: [ 'count', 'just_show' ],
                },
            },
        },
        {
            condition: {
                function: {
                    is: [ 'average', 'variance', 'median', 'sum' ],
                },
            },
            restriction: {
                facts: {
                    is: [ 'NUMERIC', 'SCALABLE', 'NPS', 'CHOICE_NPS', 'CHOICE_NUMERIC' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    not: [ 'NUMERIC', 'SCALABLE', 'NPS', 'CHOICE_NUMERIC', 'CHOICE_NPS', null ],
                },
            },
            restriction: {
                function: {
                    not: [ 'average', 'variance', 'median', 'accSum', 'sum' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    is: [ 'NPS_SEGMENT', 'CHOICE_NPS_SEGMENT' ],
                },
                secondGroupBy: {
                    not: [ null ],
                },
            },
            restriction: {
                chartType: {
                    not: [ 'stacked_bar_chart' ],
                },
            },
        },
        {
            condition: {
                facts: {
                    is: [ 'NPS_SEGMENT', 'CHOICE_NPS_SEGMENT' ],
                },
                secondGroupBy: {
                    not: [ null ],
                },
            },
            restriction: {
                chartType: {
                    not: [ 'line_chart' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    is: [ 'stacked_bar_chart' ],
                },
                secondGroupBy: {
                    not: [ null ],
                },
            },
            restriction: {
                facts: {
                    not: [ 'NPS_SEGMENT', 'CHOICE_NPS_SEGMENT' ],
                },
            },
        },
        {
            condition: {
                chartType: {
                    is: [ 'line_chart' ],
                },
                function: {
                    is: [ 'count' ],
                },
                secondGroupBy: {
                    not: [ null ],
                },
            },
            restriction: {
                facts: {
                    not: [ 'NPS_SEGMENT', 'CHOICE_NPS_SEGMENT' ],
                },
            },
        },
        {
            condition: 'always',
            restriction: {
                groupBy: {
                    not: [ 'TEXT' ],
                },
                secondGroupBy: {
                    not: [ 'TEXT' ],
                },
            },
        },
    ];

    static processRules(settings: any, dataSettings: any) {
        const hashForCompare = {
            groupBy: DependencyMatrixService.getAttr(settings, 'groupBy'),
            secondGroupBy: DependencyMatrixService.getAttr(settings, 'secondGroupBy'),
            function: DependencyMatrixService.getSelected(settings, 'function'),
            chartType: DependencyMatrixService.getSelected(settings, 'chartType'),
            upload: DependencyMatrixService.getSelected(settings, 'sourceId'),
            facts: null,
        };

        // facts
        const fact = dataSettings.facts || [];
        const factsSetting = DependencyMatrixService.getSettings(settings, 'facts');
        const selectedFacts = factsSetting.selected;
        const selectedFactTypes = dataSettings.factTypes || [];
        const isMulti = selectedFacts.length >= 2;
        const isSingleFact = selectedFacts.length === 1;
        // move to hashForCompare
        const currentFacts = factsSetting.options
            .filter(({ id }: any) => fact.includes(id))
            .map(({ type, originType }: any) => originType === 'TOPIC_SENTIMENT' ? originType : type);

        if(currentFacts.length) {
            hashForCompare.facts = currentFacts;
        }

        // groupBy
        const groupBy = DependencyMatrixService.getSettings(settings, 'groupBy');
        const secondGroupBy = DependencyMatrixService.getSettings(settings, 'secondGroupBy');
        // function
        const funcOptions = DependencyMatrixService.getSettings(settings, 'function');
        // chartType
        const chartOptions = DependencyMatrixService.getSettings(settings, 'chartType');

        const isNpsSegmentChart = DependencyMatrixService.isNpsSegmentChart(hashForCompare.chartType);
        const isSingleGroupNpsChart = DependencyMatrixService.isSingleGroupNpsChart(hashForCompare.chartType);

        // Changes Object settings
        DependencyMatrixService.rules.forEach(rule => {
            const conditionResult = rule.condition === 'always'
                || DependencyMatrixService.getConditionResult(rule.condition, hashForCompare);

            if (conditionResult) {
                DependencyMatrixService.makeRestriction(rule.restriction, settings);
            }
        });
        //Matrix processing

        //Additional rules processings
        DependencyMatrixService.restrictUploadsList(hashForCompare, settings);

        if (hashForCompare.function === 'impact') {
            const groupBy = settings.find(({ id }: any) => id === 'groupBy');

            groupBy.options = groupBy.options.filter(({ type, originType }: any) => {
                return type === 'META'
                    ? originType === 'RECODER'
                    : true;
            });

            factsSetting.options = factsSetting.options.filter(({ type }: any) => {
                return type === 'NUMERIC'
                    ? groupBy.options.some(({ type }: any) =>
                        [ 'CHOICE_NPS', 'CHOICE_NPS_SEGMENT', 'CHOICE_NUMERIC', 'CHOICE' ].includes(type),
                    )
                    : true;
            });
        }

        if (hashForCompare.chartType === 'lemmata_word_cloud') {
            DependencyMatrixService.sortFactForWordCloud(settings.find(({ id }: any) => id === 'facts'));
        }

        /**
         * Multifact additional proccessing
         */
        // TODO: Remove affection restrictions when function will be fixed on BE
        if (isMulti) {
            secondGroupBy.options = [];
            secondGroupBy.selected = [];
            funcOptions.options = funcOptions.options.filter(({ id }: any) => id !== 'affection');
        }

        if ([ 'affection', 'impact' ].includes(hashForCompare.function)) {
            factsSetting.isMulti = false;
        }

        if (!isMulti && secondGroupBy.selected.length) {
            factsSetting.isMulti = false;
        }

        if (isMulti) {
            chartOptions.options = chartOptions.options
                .filter(({ value }: any) => value !== 'generic_pie_chart' && value !== 'nps_bubble_chart');
            funcOptions.options = funcOptions.options
                .filter(({ value }: any) => value !== 'impact');
        }

        if (isNpsSegmentChart) {
            if (isMulti && DependencyMatrixService.isNpsFactsOnly(selectedFactTypes)) {
                groupBy.options = [];
                groupBy.selected = [];
            } else if (
                (
                    isSingleFact
                    && DependencyMatrixService.npsSegmentFactTypes.includes(selectedFactTypes[0])
                )
                && groupBy.selected[0]
            ) {
                factsSetting.options = factsSetting.options
                    .filter(({ type, id }: any) =>
                        !DependencyMatrixService.npsSegmentFactTypes.includes(type)
                            || id === factsSetting.selected[0],
                    );
            }
        }

        if (hashForCompare.chartType === 'generic_pie_chart' || hashForCompare.chartType === 'nps_bubble_chart') {
            factsSetting.isMulti = false;
        }


        // https://sandsiv.atlassian.net/browse/VOC-9736
        if (hashForCompare.function === 'npsScore') {
            if (isMulti && groupBy.selected[0]) {
                chartOptions.options = chartOptions.options
                    .filter(({ id }: any) => !DependencyMatrixService.npsScoreSingleGroupCharts.includes((id)));
            }

            if (isSingleGroupNpsChart && isMulti) {
                groupBy.options = [];
                groupBy.selected = [];
            }

            if (isSingleGroupNpsChart && groupBy.selected[0]) {
                factsSetting.isMulti = false;
            }
        }

        return settings;
    }

    static checkIsSelectedExist(settings: any) {
        const isExist = (sValue: string, options: any[]) => {
            return !!options.find(({ value: oValue }: any) => sValue === oValue);
        };

        settings.forEach((setting: any) => {
            if (setting.id === 'sourceId') return setting;

            setting.selected = setting.selected.every((sValue: string) => isExist(sValue, setting.options))
                ? setting.selected
                : [];
        });

        return settings;
    }
}
