import React, { Component } from 'react';
import { SwitchControl } from '/components/';
import './DeletedSurveysFilter.scss';
import PropTypes from 'prop-types';

export class DeletedSurveysFilter extends Component {
    render() {
        const toggleStatus = this.props.hideDeletedSurveys;
        const toggleAction = () => {
            this.props.applyFilter({
                loading: true,
                hideDeletedSurveys: !toggleStatus,
                page: 1,
            });
        };

        return (
            <label className='deleted-surveys__button' htmlFor={ 'showDeletedSurveys' }>
                <span className="deleted-survey__button-switch">
                    <SwitchControl name={ 'showDeletedSurveys' }
                        changeHandler={ toggleAction }
                        value={ !toggleStatus } type={ 'small' }/>
                </span>
                Show deleted surveys
            </label>
        );
    }
}

DeletedSurveysFilter.propTypes = {
    applyFilter: PropTypes.func,
};
DeletedSurveysFilter.defaultProps = {};
