import { CALL_API, FEEDBACK } from 'store/middleware/api';

const CLEANUP_THEME_LIST = 'CLEANUP_THEME_LIST';

const GET_THEMES_LIST_REQUEST = 'GET_THEMES_LIST_REQUEST';
const GET_THEMES_LIST_SUCCESS = 'GET_THEMES_LIST_SUCCESS';
const GET_THEMES_LIST_ERROR = 'GET_THEMES_LIST_ERROR';

const DELETE_THEME_REQUEST = 'DELETE_THEME_REQUEST';
const DELETE_THEME_SUCCESS = 'DELETE_THEME_SUCCESS';
const DELETE_THEME_ERROR = 'DELETE_THEME_ERROR';

const COPY_THEME_REQUEST = 'COPY_THEME_REQUEST';
const COPY_THEME_SUCCESS = 'COPY_THEME_SUCCESS';
const COPY_THEME_ERROR = 'COPY_THEME_ERROR';

const THEME_GET_DIGI_RUNNER_URL_REQUEST = 'THEME_GET_DIGI_RUNNER_URL_REQUEST';
const THEME_GET_DIGI_RUNNER_URL_SUCCESS = 'THEME_GET_DIGI_RUNNER_URL_SUCCESS';
const THEME_GET_DIGI_RUNNER_URL_ERROR = 'THEME_GET_DIGI_RUNNER_URL_ERROR';

const THEME_GET_LANGUAGE_SETTINGS_REQUEST = 'THEME_GET_LANGUAGE_SETTINGS_REQUEST';
const THEME_GET_LANGUAGE_SETTINGS_SUCCESS = 'THEME_GET_LANGUAGE_SETTINGS_SUCCESS';
const THEME_GET_LANGUAGE_SETTINGS_ERROR = 'THEME_GET_LANGUAGE_SETTINGS_ERROR';

const THEME_RESET_SELECTED_FILTERS = 'THEME_RESET_SELECTED_FILTERS';
const THEME_SET_SELECTED_FILTERS = 'THEME_SET_SELECTED_FILTERS';
const THEME_FILTERS_CHANGED = 'THEME_FILTERS_CHANGED';

const GET_THEMES_TAGS_REQUEST = 'GET_THEMES_TAGS_REQUEST';
const GET_THEMES_TAGS_SUCCESS = 'GET_THEMES_TAGS_SUCCESS';
const GET_THEMES_TAGS_ERROR = 'GET_THEMES_TAGS_ERROR';

const SET_THEME_DATA_REQUEST = 'SET_THEME_DATA_REQUEST';
const SET_THEME_DATA_SUCCESS = 'SET_THEME_DATA_SUCCESS';
const SET_THEME_DATA_ERROR = 'SET_THEME_DATA_ERROR';

const extraFilters = JSON.parse(localStorage.getItem('voc_feedback_theme_filters'));

function getInitialState(extraFilters) {
    return {
        loading: false,
        results: [],
        filters: {
            page: 1,
            limit: 10,
            search: '',
            sort_field: '',
            sort_order: '',
            extraFilters: extraFilters
                ? {
                    ...extraFilters,
                }
                : { tags: [] },
        },
        count: 0,
        tags: {
            loading: true,
            data: [],
        },
    };
}

export function reducer(state = (getInitialState(extraFilters)), action = {}) {
    switch (action.type) {
        case GET_THEMES_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_THEMES_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.response,
            };
        }

        case GET_THEMES_LIST_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case THEME_FILTERS_CHANGED: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case CLEANUP_THEME_LIST: {
            return getInitialState();
        }

        case THEME_RESET_SELECTED_FILTERS: {
            localStorage.removeItem('voc_feedback_theme_filters');

            return {
                ...state,
                filters: {
                    ...state.filters,
                    extraFilters: action.payload ? {
                        ...state.filters.extraFilters,
                        [action.payload]: [],
                    } : {
                        ...getInitialState().filters.extraFilters,
                    },
                },
            };
        }

        case THEME_SET_SELECTED_FILTERS: {
            const { name, value } = action.payload;
            const extraFilters = {
                ...state.filters.extraFilters,
                [name]: value,
            };

            localStorage.setItem('voc_feedback_theme_filters', JSON.stringify(extraFilters));

            return {
                ...state,
                filters: {
                    ...state.filters,
                    extraFilters: {
                        ...extraFilters,
                    },
                },
            };
        }

        case GET_THEMES_TAGS_REQUEST: {
            return {
                ...state,
                tags: {
                    data: [],
                    loading: true,
                },
            };
        }

        case GET_THEMES_TAGS_SUCCESS:
            return {
                ...state,
                tags: {
                    data: [ ...action.response.results ],
                    loading: false,
                },
            };

        case GET_THEMES_TAGS_ERROR: {
            return {
                ...state,
                tags: {
                    data: [],
                    loading: false,
                },
            };
        }

        default:
            return state;
    }
}

export function cleanup() {
    return {
        type: CLEANUP_THEME_LIST,
    };
}

export function getThemeList(body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_THEMES_LIST_REQUEST, GET_THEMES_LIST_SUCCESS, GET_THEMES_LIST_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            body: {
                ...body,
                all_global: 1,
            },
        },
    };
}

export function deleteTheme(id) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/${ id }/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_THEME_REQUEST, DELETE_THEME_SUCCESS, DELETE_THEME_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'THEME was successfully deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function copyTheme(body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ COPY_THEME_REQUEST, COPY_THEME_SUCCESS, COPY_THEME_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'THEME was successfully copied',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function updateFilters(payload) {
    return {
        type: THEME_FILTERS_CHANGED,
        payload,
    };
}

export function getDigiRunnerUrl(id) {
    return {
        [CALL_API]: {
            endpoint: `/unit/${id}/get-channel-settings/DIGI/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ THEME_GET_DIGI_RUNNER_URL_REQUEST, THEME_GET_DIGI_RUNNER_URL_SUCCESS, THEME_GET_DIGI_RUNNER_URL_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getLangSettings(digi_theme_id) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-language/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ THEME_GET_LANGUAGE_SETTINGS_REQUEST, THEME_GET_LANGUAGE_SETTINGS_SUCCESS, THEME_GET_LANGUAGE_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            body: {
                digi_theme_id,
            },
        },
    };
}

export function resetFilters(payload) {
    return {
        type: THEME_RESET_SELECTED_FILTERS,
        payload,
    };
}

export function setSelected(payload) {
    return {
        type: THEME_SET_SELECTED_FILTERS,
        payload,
    };
}

export function getTags() {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-tag`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_THEMES_TAGS_REQUEST, GET_THEMES_TAGS_SUCCESS, GET_THEMES_TAGS_ERROR ],
            apiType: FEEDBACK,
            unique: true,
            body: {
                limit: 99999,
                page: 1,
            },
        },
    };
}

export function setThemeData(data) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/${ data.id }/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ SET_THEME_DATA_REQUEST, SET_THEME_DATA_SUCCESS, SET_THEME_DATA_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Theme settings were successfully updated',
                error: 'Something went wrong',
            },
            body: data,
        },
    };
}
