import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { HomePage } from './scenes/HomePage/';
import { About } from './scenes/About';
import { Settings } from './scenes/Settings';

import { connect } from 'react-redux';
import { VocRoutesSelector } from '/modules/selectors';
import { VocSocketHoc } from '/components';

@connect(state => ({
    VocRoutes: VocRoutesSelector(state),
}))

@VocSocketHoc('null')
export class OtherRoutes extends Component {
    render() {
        const { ENDPOINT_HOME_PAGE, ENDPOINT_ABOUT_PAGE, ENDPOINT_SETTINGS_PAGE } = this.props.VocRoutes;

        return (
            <Switch>
                <Route exact
                    path={ ENDPOINT_HOME_PAGE }
                    component={ HomePage }>
                </Route>
                <Route exact
                    path={ ENDPOINT_ABOUT_PAGE }
                    component={ About }>
                </Route>
                <Route exact
                    path={ ENDPOINT_SETTINGS_PAGE }
                    component={ Settings }>
                </Route>
            </Switch>
        );
    }
}
