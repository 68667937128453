import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { VocModal, FormFieldItem, VocSelect, Preloader } from '/components';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelperService } from '/services/HelperService/HelperService';
import './TouchPointSettingsModal.scss';

const getInitialState = () => ({
    isValid: false,
    project: '',
    name: '',
    url: '',
    isRequestPending: false,
});

export class TouchPointSettingsModal extends Component {
    state = getInitialState();

    @autobind
    initModal() {
        this.setState({ loading: true });
        this.props.getProjects()
            .then(() => {
                const currentTouchPoint = this.props.touchPoints.find(touchPoint => touchPoint.id === this.props.activeTouchPoint);

                this.setState({
                    loading: false,
                    url: currentTouchPoint && currentTouchPoint.url || '',
                    name: currentTouchPoint && currentTouchPoint.name || '',
                    project: currentTouchPoint && String(currentTouchPoint.project) || '',
                });
            });
    }

    @autobind
    cleanupModal() {
        this.setState(getInitialState());
    }

    @autobind
    checkValidation() {
        const checkValue = value => (value && value !== '') ? value : null;
        const isValid = !!(
            checkValue(this.state.name)
            && checkValue(this.state.project)
        );

        return isValid && !this.state.isRequestPending;
    }

    @autobind
    onChangeProject(project) {
        this.setState({ project });
    }

    @autobind
    handleChangeNameInput(event) {
        const name = event.target.value;

        this.setState({ name });
    }

    @autobind
    handleChangeUrlInput(event) {
        const url = event.target.value;

        this.setState({ url });
    }

    @autobind
    onAddTouchPointClicked() {
        const params = {
            ...this.state,
        };

        params.project = params.project.value;

        this.setState({
            isRequestPending: true,
        });
        this.props.actionHandler(params);
        this.props.close();
    }

    @autobind
    onAddTouchPointClosed() {
        this.props.close();
    }

    getModalBody() {
        let { project } = this.state;
        const { loading, name, url } = this.state;
        const projectOptions = HelperService.mapProjectsToSelectModel(this.props.projectList);

        project = HelperService.getSelectedValue(projectOptions, project);

        if (loading) {
            return (
                <div className="invite-modal-preloader-wrapper">
                    <Preloader/>
                </div>
            );
        }

        return (
            <Fragment>
                <FormFieldItem
                    controlId="touchpoint_name"
                    type="text"
                    isHorizontal={ true }
                    value={ name }
                    label="Name *"
                    handleChange={ this.handleChangeNameInput }
                />
                <VocSelect
                    label="Customer Journey *"
                    placeholder="Select a Customer Journey"
                    isClearable={ false }
                    isHorizontal={ true }
                    options={ projectOptions }
                    value={ project }
                    onChange={ this.onChangeProject }
                />
                <FormFieldItem
                    controlId="touchpoint_url"
                    type="text"
                    isHorizontal={ true }
                    value={ url }
                    label="Dashboard Link"
                    placeholder={ 'http://example.com' }
                    handleChange={ this.handleChangeUrlInput }
                />
            </Fragment>
        );
    }

    getModalFooter() {
        return (
            <Fragment>
                <Button onClick={ this.onAddTouchPointClosed } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button onClick={ this.onAddTouchPointClicked }
                    disabled={ !this.checkValidation() }
                    color="primary">
                    <FontAwesomeIcon icon="check"/>
                    <span> Save</span>
                </Button>
            </Fragment>
        );
    }

    render() {
        const { show, close } = this.props;

        return (
            <VocModal
                modalClassName='edit-touchpoint-modal-wrapper'
                isOpen={ show }
                onOpened={ this.initModal }
                onClosed={ this.cleanupModal }
                toggle={ close }
                size={ 'md' }
                header={ 'Edit Touchpoint' }
                footer={ this.getModalFooter() }>
                { this.getModalBody() }
            </VocModal>
        );
    }
}

TouchPointSettingsModal.propTypes = {
    show: PropTypes.bool,
};
