import moment from 'moment';
import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';
import { IDashboard, IDashboards, ISendToEmailBody } from '../models';
import { TGetDashboards, TCreateDrillDownDashboard, TChangeDashboardSettingsBody } from './models';

const CLEANUP_VOC_VISUAL = 'CLEANUP_VOC_VISUAL';

const GET_DASHBOARDS_REQUEST = 'GET_DASHBOARDS_REQUEST';
const GET_DASHBOARDS_SUCCESS = 'GET_DASHBOARDS_SUCCESS';
const GET_DASHBOARDS_ERROR = 'GET_DASHBOARDS_ERROR';

const GET_ALL_DASHBOARDS_REQUEST = 'GET_ALL_DASHBOARDS_REQUEST';
const GET_ALL_DASHBOARDS_SUCCESS = 'GET_ALL_DASHBOARDS_SUCCESS';
const GET_ALL_DASHBOARDS_ERROR = 'GET_ALL_DASHBOARDS_ERROR';

const GET_DEFAULT_DASHBOARD_REQUEST = 'GET_DEFAULT_DASHBOARD_REQUEST';
const GET_DEFAULT_DASHBOARD_SUCCESS = 'GET_DEFAULT_DASHBOARD_SUCCESS';
const GET_DEFAULT_DASHBOARD_ERROR = 'GET_DEFAULT_DASHBOARD_ERROR';

const GET_OLD_DASHBOARDS_REQUEST = 'GET_OLD_DASHBOARDS_REQUEST';
const GET_OLD_DASHBOARDS_SUCCESS = 'GET_OLD_DASHBOARDS_SUCCESS';
const GET_OLD_DASHBOARDS_ERROR = 'GET_OLD_DASHBOARDS_ERROR';

const CONVERT_DASHBOARD_REQUEST = 'CONVERT_DASHBOARD_REQUEST';
const CONVERT_DASHBOARD_SUCCESS = 'CONVERT_DASHBOARD_SUCCESS';
const CONVERT_DASHBOARD_ERROR = 'CONVERT_DASHBOARD_ERROR';

const GET_FAVOURITES_DASHBOARDS_REQUEST = 'GET_FAVOURITES_DASHBOARDS_REQUEST';
const GET_FAVOURITES_DASHBOARDS_SUCCESS = 'GET_FAVOURITES_DASHBOARDS_SUCCESS';
const GET_FAVOURITES_DASHBOARDS_ERROR = 'GET_FAVOURITES_DASHBOARDS_ERROR';

const CREATE_DASHBOARD_REQUEST = 'CREATE_DASHBOARD_REQUEST';
const CREATE_DASHBOARD_SUCCESS = 'CREATE_DASHBOARD_SUCCESS';
const CREATE_DASHBOARD_ERROR = 'CREATE_DASHBOARD_ERROR';

const CREATE_DRILL_DOWN_DASHBOARD_REQUEST = 'CREATE_DRILL_DOWN_DASHBOARD_REQUEST';
const CREATE_DRILL_DOWN_DASHBOARD_SUCCESS = 'CREATE_DRILL_DOWN_DASHBOARD_SUCCESS';
const CREATE_DRILL_DOWN_DASHBOARD_ERROR = 'CREATE_DRILL_DOWN_DASHBOARD_ERROR';

const CREATE_COPY_DASHBOARD_REQUEST = 'CREATE_COPY_DASHBOARD_REQUEST';
const CREATE_COPY_DASHBOARD_SUCCESS = 'CREATE_COPY_DASHBOARD_SUCCESS';
const CREATE_COPY_DASHBOARD_ERROR = 'CREATE_DASHBOARD_ERROR';

const PUT_DASHBOARD_REQUEST = 'PUT_DASHBOARD_REQUEST';
const PUT_DASHBOARD_SUCCESS = 'PUT_DASHBOARD_SUCCESS';
const PUT_DASHBOARD_ERROR = 'PUT_DASHBOARD_ERROR';

const DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST';
const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
const DELETE_DASHBOARD_ERROR = 'DELETE_DASHBOARD_ERROR';

const SEND_TO_EMAIL_REQUEST = 'SEND_TO_EMAIL_REQUEST';
const SEND_TO_EMAIL_SUCCESS = 'SEND_TO_EMAIL_SUCCESS';
const SEND_TO_EMAIL_ERROR = 'SEND_TO_EMAIL_ERROR';

const GET_SCHEDULER_REQUEST = 'GET_SCHEDULER_REQUEST';
const GET_SCHEDULER_SUCCESS = 'GET_SCHEDULER_SUCCESS';
const GET_SCHEDULER_ERROR = 'GET_SCHEDULER_ERROR';

const SAVE_SCHEDULER_REQUEST = 'SAVE_SCHEDULER_REQUEST';
const SAVE_SCHEDULER_SUCCESS = 'SAVE_SCHEDULER_SUCCESS';
const SAVE_SCHEDULER_ERROR = 'SAVE_SCHEDULER_ERROR';

const TOGGLE_FAVOURITE_IN_LIST_REQUEST = 'TOGGLE_FAVOURITE_IN_LIST_REQUEST';
const TOGGLE_FAVOURITE_IN_LIST_SUCCESS = 'TOGGLE_FAVOURITE_IN_LIST_SUCCESS';
const TOGGLE_FAVOURITE_IN_LIST_ERROR = 'TOGGLE_FAVOURITE_IN_LIST_ERROR';

const DASHBOARD_LIST_FILTERS_CHANGED = 'DASHBOARD_LIST_FILTERS_CHANGED';
const DASHBOARD_LIST_FILTERS_RESET = 'DASHBOARD_LIST_FILTERS_RESET';
const TOGGLE_IS_CREATED = 'TOGGLE_IS_CREATED';

export const baseFilters = {
    sort_field: '',
    sort_order: '',
    search: '',
    limit: 10,
    page: 1,
    isFavourite: 0,
};

export const getInitialState = (): IDashboards => ({
    dashboards: {
        data: [],
        loading: false,
        count: 0,
        filters: baseFilters,
    },
    allDashboards: {
        data: [],
        loading: true,
    },
    oldDashboards: {
        data: [],
        loading: true,
    },
    oldDashboardData: {
        data: {},
        loading: false,
    },
    favouritesDashboards: {
        data: [],
        loading: false,
    },
    defaultDashboard: {
        id: null,
        loading: false,
        loaded: false,
    },
    sortFiled: '',
    sortDirection: '',
    scheduler: {
        options: {
            active: false,
            date: moment().format('DD-MM-YYYY'),
            email: '',
            message: '',
            schedule: 'P1D',
            sendToOwnEmail: false,
            subject: '',
            time: '00:00',
        },
        loading: false,
        request: {
            loading: false,
            status: 'success',
        },
    },
    securityDashboard: {
        loading: false,
        data: [],
    },
    isCreated: false,
});

export function reducer(state: IDashboards = getInitialState(), action: any = {}): IDashboards {
    switch (action.type) {
        case DELETE_DASHBOARD_REQUEST: {
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    loading: true,
                },
            };
        }

        case DELETE_DASHBOARD_SUCCESS: {
            const { params } = action;
            const { defaultDashboard } = state;

            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    loading: true,
                },
                defaultDashboard: {
                    ...defaultDashboard,
                    id: defaultDashboard.id === params.id
                        ? null
                        : defaultDashboard.id,
                },
            };
        }

        case DASHBOARD_LIST_FILTERS_CHANGED: {
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    loading: true,
                    filters: {
                        ...state.dashboards.filters,
                        ...action.payload,
                    },
                },
            };
        }

        case DASHBOARD_LIST_FILTERS_RESET: {
            const notResetFields = action.payload
                .reduce((acc: Partial<TGetDashboards>, field: keyof TGetDashboards) => ({
                    ...acc,
                    [field]: state.dashboards.filters[field],
                }), {});

            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    loading: true,
                    filters: {
                        ...baseFilters,
                        ...notResetFields,
                    },
                },
            };
        }

        case CREATE_COPY_DASHBOARD_SUCCESS:
        case CREATE_DRILL_DOWN_DASHBOARD_SUCCESS:
        case CREATE_DASHBOARD_SUCCESS: {
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    loading: false,
                },
                isCreated: action.params.openNewDashboard,
            };
        }

        case DELETE_DASHBOARD_ERROR:
        case CREATE_DASHBOARD_REQUEST:
        case CREATE_DRILL_DOWN_DASHBOARD_REQUEST:
        case CREATE_COPY_DASHBOARD_REQUEST:
        case CREATE_DASHBOARD_ERROR:
        case CREATE_DRILL_DOWN_DASHBOARD_ERROR: {
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    loading: false,
                },
            };
        }

        case GET_DASHBOARDS_ERROR:
        case GET_DASHBOARDS_REQUEST: {
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    loading: true,
                },
            };
        }

        case GET_DASHBOARDS_SUCCESS: {
            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    ...action.response,
                    loading: false,
                },
            };
        }

        case GET_DEFAULT_DASHBOARD_ERROR: {
            return {
                ...state,
                defaultDashboard: {
                    loading: false,
                    id: null,
                    loaded: true,
                },
            };
        }

        case GET_DEFAULT_DASHBOARD_REQUEST: {
            return {
                ...state,
                defaultDashboard: {
                    ...state.defaultDashboard,
                    loading: true,
                    loaded: false,
                },
            };
        }

        case GET_DEFAULT_DASHBOARD_SUCCESS: {
            return {
                ...state,
                defaultDashboard: {
                    loading: false,
                    id: action.response.data[0]?.id || null,
                    loaded: true,
                },
            };
        }

        case GET_ALL_DASHBOARDS_ERROR:
        case GET_ALL_DASHBOARDS_REQUEST: {
            return {
                ...state,
                allDashboards: {
                    ...state.allDashboards,
                    loading: true,
                },
            };
        }

        case GET_OLD_DASHBOARDS_ERROR: {
            return {
                ...state,
                oldDashboards: {
                    ...state.oldDashboards,
                    loading: false,
                },
            };
        }

        case GET_OLD_DASHBOARDS_REQUEST: {
            return {
                ...state,
                oldDashboards: {
                    ...state.oldDashboards,
                    loading: true,
                },
            };
        }

        case GET_OLD_DASHBOARDS_SUCCESS: {
            return {
                ...state,
                oldDashboards: {
                    data: action.response,
                    loading: false,
                },
            };
        }

        case GET_ALL_DASHBOARDS_SUCCESS: {
            return {
                ...state,
                allDashboards: {
                    ...action.response,
                    loading: false,
                },
            };
        }

        case GET_FAVOURITES_DASHBOARDS_REQUEST: {
            return {
                ...state,
                favouritesDashboards: {
                    ...state.favouritesDashboards,
                    loading: true,
                },
            };
        }

        case GET_FAVOURITES_DASHBOARDS_ERROR:{
            return {
                ...state,
                favouritesDashboards: {
                    ...state.favouritesDashboards,
                    data: [],
                },
            };
        }

        case GET_FAVOURITES_DASHBOARDS_SUCCESS: {
            return {
                ...state,
                favouritesDashboards: {
                    data: [ ...action.response.data ],
                    loading: false,
                },
            };
        }

        case PUT_DASHBOARD_SUCCESS: {
            const { params, data } = action;
            const getModifiedDashboardList = (dashboardList: IDashboard[]) => dashboardList
                .map(dashboard => ({
                    ...dashboard,
                    ... dashboard.id === params.dashboardId
                        ? { ...data }
                        : { isDefault: false },
                }));

            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    data: getModifiedDashboardList(state.dashboards.data),
                },
                favouritesDashboards: {
                    ...state.favouritesDashboards,
                    data: getModifiedDashboardList(state.favouritesDashboards.data),
                },
                defaultDashboard: {
                    ...state.defaultDashboard,
                    id: data.isDefault ? params.dashboardId : null,
                },
            };
        }

        case TOGGLE_FAVOURITE_IN_LIST_SUCCESS: {
            const { params, response } = action;

            const newDashboards = state.dashboards.data.map((dashboard: IDashboard) => {
                return dashboard.id === params.dashboardId
                    ? {
                        ...dashboard,
                        isFavourite: response.isFavourite,
                    }
                    : dashboard;
            });

            return {
                ...state,
                dashboards: {
                    ...state.dashboards,
                    data: newDashboards,
                },
            };
        }

        case PUT_DASHBOARD_REQUEST:
        case PUT_DASHBOARD_ERROR: {
            return {
                ...state,
            };
        }

        case SEND_TO_EMAIL_REQUEST:
        case SEND_TO_EMAIL_SUCCESS:
        case SEND_TO_EMAIL_ERROR: {
            return {
                ...state,
            };
        }

        case GET_SCHEDULER_REQUEST: {
            return {
                ...state,
                scheduler: {
                    ...state.scheduler,
                    loading: true,
                },
            };
        }

        case GET_SCHEDULER_SUCCESS: {
            return {
                ...state,
                scheduler: {
                    ...state.scheduler,
                    options: Array.isArray(action.response)
                        ? getInitialState().scheduler.options
                        : { ...action.response },
                    loading: false,
                },
            };
        }

        case GET_SCHEDULER_ERROR: {
            return {
                ...state,
                scheduler: {
                    ...state.scheduler,
                    loading: false,
                },
            };
        }

        case SAVE_SCHEDULER_REQUEST: {
            return {
                ...state,
                scheduler: {
                    ...state.scheduler,
                    request: {
                        ...state.scheduler.request,
                        loading: true,
                    },
                },
            };
        }

        case SAVE_SCHEDULER_SUCCESS: {
            return {
                ...state,
                scheduler: {
                    ...state.scheduler,
                    request: {
                        loading: false,
                        status: 'success',
                    },
                },
            };
        }

        case SAVE_SCHEDULER_ERROR: {
            return {
                ...state,
                scheduler: {
                    ...state.scheduler,
                    request: {
                        loading: false,
                        status: 'error',
                    },
                },
            };
        }

        case CLEANUP_VOC_VISUAL: {
            return getInitialState();
        }

        case TOGGLE_IS_CREATED: {
            return {
                ...state,
                isCreated: !state.isCreated,
            };
        }

        default: {
            return state;
        }
    }
}

export function updateFilters(payload: TGetDashboards) {
    return {
        type: DASHBOARD_LIST_FILTERS_CHANGED,
        payload,
    };
}

export function resetFilterExceptField(payload: (keyof TGetDashboards)[]) {
    return {
        type: DASHBOARD_LIST_FILTERS_RESET,
        payload,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_VOC_VISUAL,
    };
}

export function toggleIsCreated() {
    return {
        type: TOGGLE_IS_CREATED,
    };
}

export function getDashboards({ sort_field, sort_order,...body }: TGetDashboards) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/`,
            method: 'GET',
            types: [ GET_DASHBOARDS_REQUEST, GET_DASHBOARDS_SUCCESS, GET_DASHBOARDS_ERROR ],
            logger: true,
            body: {
                ...body,
                [`sort[${sort_field}]`]: sort_order,
            },
        },
    };
}

export function getAllDashboards() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/`,
            method: 'GET',
            types: [ GET_ALL_DASHBOARDS_REQUEST, GET_ALL_DASHBOARDS_SUCCESS, GET_ALL_DASHBOARDS_ERROR ],
            logger: true,
        },
    };
}

export function getDefaultDashboard() {
    return {
        [CALL_API]: {
            endpoint: '/public_api/visual/dashboards/v2/?isDefault=1',
            method: 'GET',
            types: [ GET_DEFAULT_DASHBOARD_REQUEST, GET_DEFAULT_DASHBOARD_SUCCESS, GET_DEFAULT_DASHBOARD_ERROR ],
            logger: true,
        },
    };
}

export function getOldDashboards() {
    return {
        [CALL_API]: {
            endpoint: `/api/visual/dashboards/`,
            method: 'GET',
            types: [ GET_OLD_DASHBOARDS_REQUEST, GET_OLD_DASHBOARDS_SUCCESS, GET_OLD_DASHBOARDS_ERROR ],
            logger: true,
        },
    };
}

export function convertDashboard(props: any) {
    const { t } = getI18n();
    const { oldDashboardId, ...body } = props;

    return {
        [CALL_API]: {
            endpoint: `/api/visual/dashboards/${oldDashboardId}/import`,
            method: 'POST',
            types: [ CONVERT_DASHBOARD_REQUEST, CONVERT_DASHBOARD_SUCCESS, CONVERT_DASHBOARD_ERROR ],
            logger: true,
            loggerMessages: {
                success: t('messages.initializationOldDashboardConvertSuccessful'),
                error: t('messages.initializationOldDashboardConvertFailed'),
            },
            body,
        },
    };
}

export function getFavouritesDashboards() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/?isFavourite=1`,
            method: 'GET',
            types: [
                GET_FAVOURITES_DASHBOARDS_REQUEST,
                GET_FAVOURITES_DASHBOARDS_SUCCESS,
                GET_FAVOURITES_DASHBOARDS_ERROR,
            ],
            logger: true,
        },
    };
}

export function toggleDashboardFavoriteFromList(dashboardId: string, isFavorite: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/favourite`,
            method: isFavorite ? 'DELETE' : 'POST',
            types: [
                TOGGLE_FAVOURITE_IN_LIST_REQUEST,
                TOGGLE_FAVOURITE_IN_LIST_SUCCESS,
                TOGGLE_FAVOURITE_IN_LIST_ERROR,
            ],
            logger: true,
            params: { dashboardId },
        },
    };
}

export function createDashboard(body: any, openNewDashboard?: boolean) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/`,
            method: 'POST',
            types: [ CREATE_DASHBOARD_REQUEST, CREATE_DASHBOARD_SUCCESS, CREATE_DASHBOARD_ERROR ],
            logger: true,
            body: {
                ...body,
                prototype: false,
            },
            params: { openNewDashboard },
        },
    };
}

export function createDrillDownDashboard(
    { byGadgetId, dashboardTitle }: TCreateDrillDownDashboard,
    openNewDashboard?: boolean,
) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/drill-down/${byGadgetId}`,
            method: 'POST',
            types: [ CREATE_DASHBOARD_REQUEST, CREATE_COPY_DASHBOARD_SUCCESS, CREATE_COPY_DASHBOARD_ERROR ],
            logger: true,
            body: { title: dashboardTitle },
            params: { openNewDashboard },
        },
    };
}

export function copyDashboard(body: any, id: string, openNewDashboard?: boolean) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${id}/copy`,
            method: 'POST',
            types: [ CREATE_COPY_DASHBOARD_REQUEST, CREATE_COPY_DASHBOARD_SUCCESS, CREATE_COPY_DASHBOARD_ERROR ],
            logger: true,
            body,
            params: { openNewDashboard },
            loggerMessages: {
                success: t('messages.successfullyCopied', { entity: t('dashboard') }),
                error: t('messages.somethingWentWrong'),
            },
        },
    };
}

export function deleteDashboard(id: string) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${id}`,
            method: 'DELETE',
            types: [ DELETE_DASHBOARD_REQUEST, DELETE_DASHBOARD_SUCCESS, DELETE_DASHBOARD_ERROR ],
            logger: true,
            params: { id },
            loggerMessageIsPrior: true,
            loggerMessages: {
                success: t('messages.entityIsDeleted', { entity: t('dashboard') }),
                error: t('messages.somethingWentWrong'),
            },
        },
    };
}

export function changeDashboardSettings(dashboardId: string, body: TChangeDashboardSettingsBody, success?: string) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}`,
            method: 'PUT',
            types: [ PUT_DASHBOARD_REQUEST, PUT_DASHBOARD_SUCCESS, PUT_DASHBOARD_ERROR ],
            logger: true,
            body,
            params: { dashboardId },
            loggerMessageIsPrior: true,
            loggerMessages: {
                success,
                error: t('messages.somethingWentWrong'),
            },
        },
    };
}

export function sendToEmail(dashboardId: string, body: ISendToEmailBody) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/mail/send`,
            method: 'POST',
            types: [ SEND_TO_EMAIL_REQUEST, SEND_TO_EMAIL_SUCCESS, SEND_TO_EMAIL_ERROR ],
            logger: true,
            loggerMessageIsPrior: true,
            loggerMessages: {
                success: t('messages.entityIsSent', { entity: t('dashboard') }),
                error: t('messages.somethingWentWrong'),
            },
            body,
        },
    };
}

export function getScheduler(dashboardId: string) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/mail/scheduler`,
            method: 'GET',
            types: [ GET_SCHEDULER_REQUEST, GET_SCHEDULER_SUCCESS, GET_SCHEDULER_ERROR ],
        },
    };
}

export function saveScheduler(dashboardId: string, body: any) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/visual/dashboards/v2/${dashboardId}/mail/scheduler`,
            method: 'POST',
            types: [ SAVE_SCHEDULER_REQUEST, SAVE_SCHEDULER_SUCCESS, SAVE_SCHEDULER_ERROR ],
            logger: true,
            body,
        },
    };
}
