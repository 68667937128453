import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './QuestionFormControls.scss';

export const QuestionFormControls = ({
    handleSubmit,
    onSubmit,
    onCloseForm,
    persist,
    loading,
    rightsSurvey: { isPermitEdit },
}) => {
    const applyHandler = handleSubmit(values => onSubmit(values, false));
    const saveHandler = handleSubmit(values => onSubmit(values, true));

    return (
        <Row className="question__controls">
            <Col md={{ size: 4, offset: 8 }}>
                <Button
                    onClick={ onCloseForm }
                    className={ 'question__controls-button' }
                    color={ 'white' }
                    disabled={ persist || loading }
                    outline
                >
                    <FontAwesomeIcon className={ 'question__controls-icon' } icon={ 'times' }/>
                    Cancel
                </Button>
                <Button
                    onClick={ applyHandler }
                    className={ 'question__controls-button' }
                    color={ 'info' }
                    disabled={ persist || loading || !isPermitEdit }
                >
                    <FontAwesomeIcon className={ 'question__controls-icon' } icon={ 'check' }/>
                    Apply
                </Button>
                <Button
                    onClick={ saveHandler }
                    className={ 'question__controls-button' }
                    color={ 'primary' }
                    disabled={ persist || loading || !isPermitEdit }
                >
                    <FontAwesomeIcon className={ 'question__controls-icon' } icon={ 'check' }/>
                    Save
                </Button>
            </Col>
        </Row>
    );
};
