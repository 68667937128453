import React, { useEffect, useState } from 'react';

import { AdvancedFilter } from './components';
import { QueryExecuteService, MetaQueryService } from '/services';
import { ModalFooter } from '../ModalFooter';

import './AdvancedForm.scss';

export const AdvancedForm = ({
    close,
    name,
    onSubmit,
    initialValues,
    source,
}) => {
    const [ queryValid, setQueryValid ] = useState(false);
    const [ query, setQuery ] = useState(MetaQueryService.emptyQuery());

    useEffect(() => {
        const { isChange, inxCurrentFilter, filters } = initialValues;

        if (isChange) {
            const currentFilter = filters.find((el, inx) => inx === inxCurrentFilter);

            setQuery(
                MetaQueryService.serverToClientFormatter({ query: currentFilter }),
            );
        } else {
            setQuery(MetaQueryService.emptyQuery());
        }
    }, [ initialValues ]);

    const submit = () => {
        const { isChange, condition, filters, inxCurrentFilter } = initialValues;
        const queryForServer = MetaQueryService.clientToServerFormatter({ query, source }, true);

        const newFilters = isChange
            ? filters.map((filter, inx) => inx === inxCurrentFilter ? queryForServer : filter)
            : [
                ...filters,
                queryForServer,
            ];

        onSubmit({
            condition,
            filters: newFilters,
        }, name);
    };

    const onChangeQuery = () => {
        const isQueryValid = QueryExecuteService.queryItemsValidation(query);

        setQueryValid(isQueryValid);
    };

    return (
        <>
            <div className={ 'modal-body' }>
                <AdvancedFilter
                    source={ JSON.parse(JSON.stringify(source)) }
                    query={ query }
                    onChangeQuery={ onChangeQuery }
                />
            </div>
            <ModalFooter
                close={ close }
                onClick={ submit }
                disabled={ !queryValid }
                addBtn={ initialValues.nameButton }
            />
        </>

    );
};

AdvancedForm.defaultProps = {
    initialValues: {},
};
