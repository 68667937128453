import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { VocTableV2 } from '/components';
import { CaseAlertsListActionsFormatter } from '../CaseAlertsListActionsFormatter';

export class CaseAlertsListTable extends Component {
    settings = [
        {
            field: 'name',
            title: 'Name',
            formatter: this.nameFormatter,
        },
        {
            field: 'id',
            formatter: this.caseAlertsListActionsFormatter,
            title: 'Actions',
            style: {
                width: '115px',
            },
        },
    ];

    @autobind
    caseAlertsListActionsFormatter(cell, obj) {
        const {
            openModalAddCondition,
            openModalDelete,
            openConditionPopover,
            permission,//permission.isPermitEdit
        } = this.props;

        return <CaseAlertsListActionsFormatter
            { ...obj }
            openModalAddCondition={ openModalAddCondition }
            openModalDelete={ openModalDelete }
            openConditionPopover={ openConditionPopover }
            permission={ permission }
        />;
    }

    @autobind
    nameFormatter(cell, obj) {
        return (
            <span className={ 'td-case-alerts_name' } onClick={ () => this.props.openCaseAlertModal(obj) }>
                { cell }
            </span>
        );
    }

    render() {
        const {
            tableState: {
                dataTotalSize: total,
                loading,
            },
            filters,
            options,
            data,
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                options={ options }
                filters={ filters }
                data={ data }
                total={ total }
                loading={ loading }
            />
        );
    }
}
