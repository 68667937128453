import { createSelector } from 'reselect';
import { usersAndGroupsSelector } from '/modules/selectors';


export const shareRightsSelector = state => state.VocFeedback.globalSettings.shareRights;
export const shareRightsLoadingSelector = state => shareRightsSelector(state).loading;

export const rightsSelector = createSelector(
    shareRightsSelector, usersAndGroupsSelector,
    (shareRightsData, { options }) => {
        const result = [];

        shareRightsData.results.forEach(shareRight => {
            const findUserData = options.find(option => option.value === shareRight.owner_id);

            findUserData && shareRight.permissions.forEach(permission => {
                result.push(
                    {
                        ...findUserData,
                        permission: permission.id,
                    },
                );
            });
        });

        return result;
    });
