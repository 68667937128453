import React, { useEffect, memo } from 'react';
import * as d3 from 'd3';
import { EmptyData } from '/components';

import './CoOccurrenceGraph.scss';

const getInitials = () => {
    linkedByIndex = {};
    text = null;
    circle = null;
    simulation = null;
    link = null;
};

const highlightColor = "blue",
    defaultLinkColor = "#888",
    baseNodeSize = 10;

let linkedByIndex = {},
    text,
    link,
    circle,
    simulation;

export const CoOccurrenceGraph = memo(({
    graphData,
    onWordClick,
    changeClass,
    className,
    width,
    height,
    isEmpty,
    tooltip,
}) => {
    const update = () => {
        d3.select('#graph')
            .selectAll("circle")
            .on("click", word => onWordClick && onWordClick(word.name, event));
    };

    const isConnected = (a, b) => linkedByIndex[a.index + "," + b.index] || linkedByIndex[b.index + "," + a.index] || a.index === b.index;

    const onTickActions = () => {
        circle && circle
            .attr("cx", d => d.x)
            .attr("cy", d => d.y);
        text && text
            .attr("x", d => d.x)
            .attr("y", d => d.y - 13);
        link && link
            .attr("x1", d => d.source.x)
            .attr("y1", d => d.source.y)
            .attr("x2", d => d.target.x)
            .attr("y2", d => d.target.y);
    };

    const drawLayout = () => {
        const zoom = d3.zoom()
            .scaleExtent([ .5, 10 ])
            .on("zoom", () => {
                d3.select('#main').attr("transform", d3.event.transform);
            });

        d3.select('#svg')
            .attr("viewBox", `0 0 ${ width } ${ height }`)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .style("cursor", "move")
            .call(zoom);
    };

    const drawCircles = nodes => {
        circle = d3.select('#nodes')
            .selectAll('circle')
            .data(nodes)
            .join(
                enter => enter
                    .append('circle')
                    .attr("r", baseNodeSize)
                    .attr("class", d => changeClass && changeClass(d.name))
                    .style("stroke", "white")
                    .on("mouseover", d => {
                        d3.select('#svg').style("cursor", "pointer");
                        circle.style("stroke", o => isConnected(d, o) ? highlightColor : 'white');
                        text.style("font-weight", o => isConnected(d, o) ? "bold" : "normal");
                        link.style("stroke", o => o.source.index === d.index || o.target.index === d.index
                            ? highlightColor
                            : defaultLinkColor,
                        );
                    })
                    .on("mouseout", () => {
                        d3.select('#svg').style("cursor", "move");
                        circle.style("stroke", "white");
                        text.style("font-weight", "normal");
                        link.style("stroke", defaultLinkColor);
                    })
                    .call(
                        d3.drag()
                            .on("start", () => {
                                simulation.stop();
                            })
                            .on("drag", d => {
                                d.fx = d3.event.x;
                                d.fy = d3.event.y;
                                d.x += d3.event.dx;
                                d.y += d3.event.dy;
                                onTickActions();
                            })
                            .on("end", d => {
                                d.fixed = true;
                                onTickActions();
                            }),
                    ),
                update => update
                    .transition()
                    .duration(500)
                    .attr("class", d => changeClass && changeClass(d.name)),
                exit => exit.remove(),
            );
    };

    const drawTexts = nodes => {
        text = d3.select('#nodes')
            .selectAll('text')
            .data(nodes)
            .join(
                enter => enter
                    .append('text')
                    .attr("class", "texts")
                    .attr('text-anchor', 'middle')
                    .text(d => d.name),
                update => update
                    .transition()
                    .duration(500)
                    .text(d => d.name),
                exit => exit.remove(),
            );
    };

    const drawLinks = links => {
        link = d3.select('#links')
            .selectAll("line")
            .data(links)
            .join(
                enter => enter
                    .append("line")
                    .attr("stroke", defaultLinkColor)
                    .attr("stroke-width", d => d.strokeWidth)
                    .attr("stroke-opacity", d => d.strokeOpacity)
                    .on("mouseover", d => {
                        d3.select('#svg').style("cursor", "pointer");
                        d3.select('.tooltip')
                            .style("opacity", 1);
                        d3.select('.tooltip').html(`${tooltip}: ${d.value}`)
                            .style("left", d3.event.offsetX + "px")
                            .style("top", d3.event.offsetY + "px");
                        link.style("stroke", o => o.index === d.index
                            ? highlightColor
                            : defaultLinkColor,
                        );
                    })
                    .on("mouseout", () => {
                        d3.select('#svg').style("cursor", "move");
                        d3.select('.tooltip')
                            .style("opacity", 0);
                        link.style("stroke", defaultLinkColor);
                    }),
                update => update
                    .transition()
                    .duration(500)
                    .attr("stroke-width", d => d.strokeWidth)
                    .attr("stroke-opacity", d => d.strokeOpacity),
                exit => exit.remove(),
            );
    };

    const forceSimulation = (nodes, links) => {
        simulation = d3.forceSimulation()
            .nodes(nodes)
            .force('linkForce', d3.forceLink(links).distance(75).id(d => d.name))
            .force('charge', d3.forceManyBody().strength(-150))
            .force("center", d3.forceCenter(width / 2, height / 2))
            .force("x", d3.forceX(width / 2).strength(0.1))
            .force("y", d3.forceY(height / 2).strength(0.1))
            .alphaTarget(0)
            .alphaDecay(0.5)
            .on('tick', onTickActions);
    };

    useEffect(() => {
        const { nodes, links } = graphData;

        linkedByIndex = {};

        drawLayout();
        drawCircles(nodes);
        drawTexts(nodes);
        drawLinks(links);
        forceSimulation(nodes, links);

        links.forEach(({ source, target }) => linkedByIndex[source.index + "," + target.index] = true);
    }, [ graphData ]);

    useEffect(() => {
        onWordClick && update();
    });

    useEffect(() => {
        return () => getInitials();
    }, []);

    return (
        <div className={ className } id='graph'>
            <div className='tooltip'/>
            <svg id='svg'>
                {
                    isEmpty
                        ? <foreignObject className={ 'foreignObject' }>
                            <EmptyData errorText={ graphData.error }/>
                        </foreignObject>
                        : <g id='main'>
                            <g id='links'/>
                            <g id='nodes'/>
                        </g>
                }
            </svg>
        </div>
    );
});

CoOccurrenceGraph.defaultProps = {
    className: '',
    isEmpty: false,
};
