import React, { useEffect, useState, FC } from 'react';
import { Button, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';

import {
    VocFormDatePicker,
    VocFormSelectField,
    VocModal,
    VocFormSwitchControl,
    VocFormTextField,
    Preloader,
} from '/components';
import { schedulerSelector } from '/visual/modules/VocVisual.selectors';
import { getScheduler, saveScheduler } from '/visual/modules/VocVisual.modules';
import { HelperService } from '/services';

import { getSchedulerPeriodOptions, schedulerTimeOptions } from './schedulerModalOptions';
import { schedulerModalValidate } from './schedulerModalValidate';
import { ISchedulerModalForm, IScheduleModalProps } from './models';
import { ChooseFormatFileRadio } from '/visual/components';
import { EExportFormats } from '/visual/models';

import styles from './style.module.scss';

const SchedulerModalForm: FC<InjectedFormProps<ISchedulerModalForm, IScheduleModalProps> & IScheduleModalProps> = ({
    show,
    toggle,
    handleSubmit,
    initialize,
    dashboardId,
    valid,
    t, // also needed in validator
}) => {
    const { options: scheduler, loading, request } = useSelector(schedulerSelector);
    const [ isActive, setIsActive ] = useState(false);
    const [ isToMe, setIsToMe ] = useState(false);
    const [ fileFormat, setFileFormat ] = useState<EExportFormats>(EExportFormats.PDF);
    const schedulerPeriodOptions = getSchedulerPeriodOptions(t);

    const dispatch = useDispatch();

    const submitForm = (data: ISchedulerModalForm) => {
        dispatch(saveScheduler(dashboardId, {
            ...data,
            time: data.time.value,
            schedule: data.schedule.value,
            date: moment(data.date).format('DD-MM-YYYY'),
            nextSend: undefined,
            format: fileFormat,
        }));
    };

    const getBody = () => (
        <form className={ styles.form }>
            <div className={ styles.formRow }>
                <label className={ cx(styles.formLabel, styles.noMargin) }>
                    { t('field.active') }
                    <Field
                        name={ 'active' }
                        checked={ isActive }
                        className={ styles.centeredSwitch }
                        component={ VocFormSwitchControl }
                        onChange={ () => setIsActive(!isActive) }
                    />
                </label>

                <div className={ styles.centeredContent }>
                    <label htmlFor={ 'schedule' } className={ styles.formLabel }>
                        <span>
                            { t('field.scheduledPeriod') }
                            &nbsp;
                            <span className={ 'star' }>*</span>
                        </span>
                    </label>

                    <Field
                        disabled={ !isActive }
                        name={ 'schedule' }
                        className={ cx(styles.periodSelect, styles.noMargin) }
                        options={ schedulerPeriodOptions }
                        component={ VocFormSelectField }
                        id={ 'scheduleSelect' }
                    />
                </div>
            </div>

            <div className={ styles.formRow }>
                <div className={ styles.centeredContent }>
                    <label htmlFor={ 'time' } className={ styles.formLabel }>
                        <span>
                            { t('field.selectTime') }
                            &nbsp;
                            <span className='star'>*</span>
                        </span>
                    </label>

                    <Field
                        disabled={ !isActive }
                        name={ 'time' }
                        className={ cx(styles.timeSelect, styles.noMargin) }
                        options={ schedulerTimeOptions }
                        component={ VocFormSelectField }
                        id={ 'timeSelect' }
                    />
                </div>

                <label htmlFor={ 'date' } className={ styles.dateLabel }>
                    <span>
                        { t('field.selectDate') }
                        &nbsp;
                        <span className={ 'star' }>*</span>
                    </span>
                </label>
                <InputGroup className={ cx(styles.dateSelectGroup) }>
                    <Field
                        disabled={ !isActive }
                        name={ 'date' }
                        type={ 'datepicker' }
                        component={ VocFormDatePicker }
                        wrapClassName={ 'voc-datepicker voc-datepicker__custom_style' }
                        className={ cx(styles.noRadiusRight, 'form-control') }
                        minDate={ new Date() }
                    />

                    <InputGroupAddon addonType={ 'append' }>
                        <label htmlFor={ 'date' } className={ styles.noMargin }>
                            <InputGroupText
                                className={ styles.inputGroupText }
                                style={{
                                    borderBottomLeftRadius: 0,
                                    borderTopLeftRadius: 0,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={ 'calendar-alt' }
                                    size={ '1x' }
                                />
                            </InputGroupText>
                        </label>
                    </InputGroupAddon>
                </InputGroup>
            </div>

            <label htmlFor={ 'email' }>
                { t('field.to') }:
            </label>
            <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                    <label>
                        <InputGroupText
                            className={ styles.inputGroupText }
                            style={{
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                            }}
                        >
                            <span>{ t('field.me') }</span>
                            <Field
                                disabled={ !isActive }
                                name={ 'sendToOwnEmail' }
                                checked={ isToMe }
                                type={ 'checkbox' }
                                className={ styles.checkbox }
                                component={ 'input' }
                                onChange={ () => setIsToMe(!isToMe) }
                            />
                        </InputGroupText>
                    </label>
                </InputGroupAddon>

                <Field
                    disabled={ !isActive || isToMe }
                    name={ 'email' }
                    placeholder={ t('field.email') }
                    className={ cx(styles.emailInput, styles.noRadiusLeft) }
                    component={ VocFormTextField }
                />
            </InputGroup>

            <ChooseFormatFileRadio
                labelStyles={ styles.labelWithMargin }
                fileFormat={ fileFormat }
                setFileFormat={ setFileFormat }
            />

            <label className={ styles.labelWithMargin } htmlFor={ 'subject' }>
                { t('field.subject') }:
            </label>
            <Field
                disabled={ !isActive }
                name={ 'subject' }
                component={ VocFormTextField }
            />

            <label className={ styles.labelWithMargin } htmlFor={ 'message' }>
                { t('field.message') }:
            </label>
            <Field
                name={ 'message' }
                type={ 'textarea' }
                disabled={ !isActive }
                className={ styles.textarea }
                component={ VocFormTextField }
                rows={ '5' }
            />
        </form>
    );

    const getFooter = () => (
        <>
            <Button
                outline
                color={ 'white' }
                onClick={ toggle }
            >
                <FontAwesomeIcon className={ styles.buttonIcon } icon={ 'times' } />
                <span>{ t('close') }</span>
            </Button>
            <Button
                disabled={ loading || request.loading || !valid }
                color={ 'primary' }
                onClick={ handleSubmit(submitForm.bind(this)) }
            >
                <FontAwesomeIcon className={ styles.buttonIcon } icon={ 'check' } />
                <span>{ t('save') }</span>
            </Button>
        </>
    );

    useEffect(() => {
        show && dispatch(getScheduler(dashboardId));
    }, [ show ]);

    useEffect(() => {
        show
        && !request.loading
        && request.status === 'success'
        && toggle();
    }, [ request.loading ]);

    useEffect(() => {
        if (show && !loading) {
            setIsActive(scheduler.active);
            setIsToMe(scheduler.sendToOwnEmail);

            // @todo
            initialize({
                ...scheduler,
                schedule: schedulerPeriodOptions.find(({ value }) => value === scheduler.schedule) || {
                    label: t('schedule.everyday'),
                    value: 'P1D',
                },
                time: schedulerTimeOptions.find(({ value }) => value === scheduler.time) || {
                    label: '00:00 - 03:00',
                    value: '00:00',
                },
                date: new Date(HelperService.formatDate(scheduler.date, 'MM-DD-YYYY', 'DD-MM-YYYY')),
            });
        }
    }, [ scheduler ]);

    return (
        <VocModal
            isOpen={ show }
            toggle={ toggle }
            header={ t('sendDashboardToEmailSchedule') }
            size={ 'md' }
            footer={ getFooter() }
            modalClassName={ 'modal-scrollable' }
        >
            { loading ? <Preloader /> : getBody() }
        </VocModal>
    );
};

export const SchedulerModal = withTranslation()(reduxForm<ISchedulerModalForm, IScheduleModalProps>({
    form: 'VisualSchedulerForm',
    validate: schedulerModalValidate,
})(SchedulerModalForm));
