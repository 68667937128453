import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { TimeDateGridContainer } from '/components';
import { PageRequests, dispatchWrapper } from '/services';
import {
    getSurveyTimeFrameRegular,
    getGlobalTimeFrameRegular,
    getSurveyTimeFrameSpecial,
    reFetchTimeFrameSpecial,
    createTimeFrameRegular,
    updateTimeFrameRegular,
    manageTimeFrameSpecial,
    updateFiltersActions,
    deleteSurveyTimeFrameRegular,
    cleanup,
} from './modules/SurveyTimeSlots.modules';
import { rightsSurveySelector } from '../../modules/survey.selectors';
import { timeSlotsDataSelector, isSurveySettingDefaultSelector } from './modules/SurveyTimeSlots.selectors';
import { channelListSelector, channelUnitSelector } from '/feedback/modules/VocFeedback.selectors';
import { showConfirm, dirtyStatusConfirm } from '/scenes/Confirm/modules/confirm.index';

const pageRequests = new PageRequests();

export const SurveyTimeSlots = () => {
    const rightsSurvey = useSelector(rightsSurveySelector);
    const timeSlotsData = useSelector(timeSlotsDataSelector);
    const isSurveySettingDefault = useSelector(isSurveySettingDefaultSelector);
    const {
        survey_id,
        unit,
        activeChannel,
    } = useSelector(channelUnitSelector);

    const channelList = useSelector(channelListSelector);

    const [ surveySettingOn, setSurveySettingOn ] = useState( false);

    useEffect(() => {
        setSurveySettingOn(isSurveySettingDefault);
    }, [ isSurveySettingDefault, activeChannel ]);

    const [
        actionGetSurveyTimeFrameRegular, actionGetGlobalTimeFrameRegular,
        actionCreateTimeFrameRegular, actionUpdateTimeFrameRegular,
        actionGetTimeFrameSpecial, actionManageTimeFrameSpecial,
        actionUpdateFiltersActions, actionDirtyStatusConfirm,
        actionShowConfirm, actionCleanup, actionReFetchTimeFrameSpecial,
        actionDeleteSurveyTimeFrameRegular,
    ] = dispatchWrapper([
        getSurveyTimeFrameRegular, getGlobalTimeFrameRegular,
        createTimeFrameRegular, updateTimeFrameRegular,
        getSurveyTimeFrameSpecial, manageTimeFrameSpecial,
        updateFiltersActions, dirtyStatusConfirm,
        showConfirm, cleanup, reFetchTimeFrameSpecial,
        deleteSurveyTimeFrameRegular,
    ]);

    const globalTimeFrameRegularRequest = (timeSlotIsRegular, params) => {
        pageRequests.addRequest(
            timeSlotIsRegular
                ? actionGetGlobalTimeFrameRegular(unit, params.channel__name)
                : actionGetTimeFrameSpecial(params),
        );
    };

    const requestTrigger = async(filtersObj, channel = activeChannel) => {
        const { timeSlotIsRegular } = timeSlotsData;
        const request = timeSlotIsRegular
            ? actionGetSurveyTimeFrameRegular(survey_id)
            : actionGetTimeFrameSpecial({
                ...filtersObj,
                survey_id,
            });

        pageRequests.addRequest(request);

        try {
            await request.promise;
        } catch (e) {
            console.error(e);
        }

        if (timeSlotIsRegular) {
            globalTimeFrameRegularRequest(
                timeSlotIsRegular,
                {
                    ...filtersObj,
                    channel__name: channel,
                    unit_id: unit,
                },
            );
        }
    };

    const manageRegularDataHandler = timeSlots => {
        if (!timeSlots.length) {
            return actionUpdateTimeFrameRegular([]);
        }

        const timeSlotsIsSurvey = timeSlots[0].survey;
        let objTimeSlots = timeSlots;

        if (!timeSlotsIsSurvey) {
            objTimeSlots = objTimeSlots.map(timeSlot => ({
                ...timeSlot,
                survey: survey_id,
            }));
        }

        return timeSlotsIsSurvey
            ? actionUpdateTimeFrameRegular(objTimeSlots)
            : actionCreateTimeFrameRegular(objTimeSlots);
    };

    const handlingTimeFrameSpecial = ({ updateObj, deleteObj, createObj, filterModel }) => {
        const createTimeSlots = createObj.length
            ? createObj.map(slot => ({
                ...slot,
                survey: survey_id,
            }))
            : createObj;

        actionManageTimeFrameSpecial(updateObj, deleteObj, createTimeSlots).then(() => {
            actionUpdateFiltersActions(filterModel);
            pageRequests.addRequest(
                actionReFetchTimeFrameSpecial({
                    ...filterModel,
                    survey_id,
                }),
            );
        });
    };

    return (
        <TimeDateGridContainer
            timeSlotsData={ timeSlotsData }
            unit={ unit }
            activeChannel={ activeChannel }
            survey_id={ survey_id }
            channelList={ channelList }
            requestTrigger={ requestTrigger }
            manageRegularDataHandler={ manageRegularDataHandler }
            getTimeFrameSpecial={ actionGetTimeFrameSpecial }
            manageTimeFrameSpecial={ handlingTimeFrameSpecial }
            setSurveySettingOn={ setSurveySettingOn }
            surveySettingOn={ surveySettingOn }
            isSurveySetting={ isSurveySettingDefault }
            updateFiltersActions={ actionUpdateFiltersActions }
            dirtyStatusConfirm={ actionDirtyStatusConfirm }
            showConfirm={ actionShowConfirm }
            cleanup={ actionCleanup }
            pageRequests={ pageRequests }
            pageTitle={ "Feedback :: Survey Time slots" }
            isActive={ !!survey_id && [ 'WEB', 'SMS', 'IVR' ].includes(activeChannel) }
            deleteSurveyTimeFrameRegular={ actionDeleteSurveyTimeFrameRegular }
            isSurveyTimeSlot
            rights={ rightsSurvey }
        />
    );
};
