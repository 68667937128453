import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocModal, FormFieldItem, VocSelect } from '/components';
import { SurveysService, HelperService } from '/services';

import './SendInvintation.scss';

const getInitialState = () => ({
    channel: {},
    address: '',
    language: {},
    languages: [],
    loading: false,
    isAddressValid: false,
    addressTextError: null,
    checkedAddressField: false,
    isButtonDisabled: true,
});

export class SendInvitation extends Component {
    state = getInitialState();
    addressPlaceholders = {
        'email/phone': 'someone@example.com / +1234567890',
        'phone': '+1234567890',
    };

    @autobind
    getActiveChannels() {
        if (!this.props.surveyGroup) {
            return [];
        }

        return this.props.surveyGroup.surveys
            .filter(survey => survey.type !== 'deleted')
            .map(survey => ({
                value: String(survey.id),
                label: survey.channel,
            }));
    }

    @autobind
    onChangeChannel(channel) {
        this.setState({ channel }, () => {
            this.checkValidation();
        });
    }

    @autobind
    onChangeLanguage(language) {
        this.setState({ language });
    }

    @autobind
    handleChangeAddressInput(event) {
        this.setState({
            address: event.target.value,
        });

        if (this.state.checkedAddressField) {
            this.checkValidation(event.target.value);
        }
    }

    @autobind
    initModal() {
        this.setState({
            loading: true,
            isButtonDisabled: true,
        });
        this.props.getSurveysGroupLangSet(this.props.surveyGroup.id)
            .promise.then(response => {
                this.setState({
                    loading: false,
                    isButtonDisabled: false,
                    languages: response,
                });
            });
    }

    @autobind
    cleanupModal() {
        this.setState(getInitialState());
    }

    @autobind
    getAddressInputId() {
        const { label } = this.state.channel;

        return (label === 'IVR' || label === 'SMS')
            ? 'phone'
            : 'email/phone';
    }

    @autobind
    mapLanguages() {
        if (this.state.languages.length === 0 || this.props.languages.length === 0) {
            return [];
        }

        return this.state.languages
            .filter(lang => lang.hasLanguageText)
            .map(lang => {
                const langFull = this.props.languages
                    .find(value => value.iso_6391_code === lang.language);

                return {
                    value: lang.language,
                    label: langFull ? langFull.name : lang.language,
                };
            });
    }

    @autobind
    onSendClicked() {
        this.setState({ isButtonDisabled: true });
        const { language, channel, address } = this.state;

        this.props.actionHandler({
            language: language.value,
            survey: parseInt(channel.value),
            address: address,
            source: 'manual_invite',
        })
            .then(this.props.close, () => {
                this.setState({
                    isButtonDisabled: false,
                });
            });
    }

    @autobind
    onCloseClicked() {
        this.setState(getInitialState());
        this.props.close();
    }

    @autobind
    checkValidation(value) {
        const { address } = this.state;
        const valueToCheck = typeof value === 'string'
            ? value
            : address;

        let errorMessage = null;
        const id = this.getAddressInputId();
        const addressValidation = id === 'phone'
            ? HelperService.validatePhone(valueToCheck)
            : (HelperService.validateEmail(valueToCheck) || HelperService.validatePhone(valueToCheck));

        if (!addressValidation) {
            errorMessage = id === 'phone'
                ? 'Wrong phone format'
                : 'Wrong email or phone format';
        }

        this.setState({
            isAddressValid: addressValidation,
            addressTextError: errorMessage,
            checkedAddressField: true,
        });
    }

    @autobind
    checkIsFormValid() {
        const {
            isAddressValid,
            channel,
            address,
            language,
            isButtonDisabled,
        } = this.state;

        return !Boolean(
            language
            && Object.keys(language).length
            && channel
            && address
            && !isButtonDisabled
            && isAddressValid,
        );
    }

    getModalFooter() {
        return (
            <>
                <Button
                    color={ 'white' }
                    outline
                    onClick={ this.onCloseClicked }
                >
                    <FontAwesomeIcon icon={ 'times' } />
                    <span> Cancel</span>
                </Button>
                <Button
                    disabled={ this.checkIsFormValid() }
                    color={ 'primary' }
                    onClick={ this.onSendClicked }
                >
                    <FontAwesomeIcon icon={ 'check' } />
                    <span> Send invitation</span>
                </Button>
            </>
        );
    }

    @autobind
    getModalBody() {
        const {
            channel,
            loading,
            language,
            address,
            isAddressValid,
            addressTextError,
        } = this.state;
        const id = this.getAddressInputId();

        return (
            <>
                <VocSelect
                    portal
                    label='Channel'
                    placeholder='Select a channel'
                    id='channel_field_inv'
                    isClearable={ false }
                    isHorizontal={ true }
                    options={ this.getActiveChannels() }
                    value={ channel }
                    onChange={ this.onChangeChannel }
                />
                {
                    this.state.loading
                        ? <div className={ 'dropdown-wrapper' }>
                            <div className={ 'form-group' }>
                                <div className={ 'row' }>
                                    <div className={ 'select-control-label col-md-3' }>
                                        <label className={ 'control-label' }>Language</label>
                                    </div>
                                    <div className={ 'col-md-9' }>
                                        <p style={{ margin: '8px' }}>
                                            <FontAwesomeIcon icon={ 'circle-notch' } spin />
                                            <span> Loading...</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <VocSelect
                            portal
                            label='Language'
                            id='language_field_inv'
                            placeholder='Select a language'
                            isClearable={ false }
                            isHorizontal={ true }
                            options={ this.mapLanguages() }
                            value={ language }
                            onChange={ this.onChangeLanguage }
                        />
                }

                <FormFieldItem
                    controlId={ 'invitation_email' }
                    placeholder={ this.addressPlaceholders[id] }
                    className={ 'addressField app-form-control__container with-error' }
                    type={ id }
                    id={ id }
                    isHorizontal={ true }
                    disabled={ loading }
                    value={ address }
                    label={ 'Address' }
                    textError={ addressTextError }
                    isValid={ isAddressValid }
                    withError
                    handleChange={ this.handleChangeAddressInput }
                    onBlur={ this.checkValidation }
                />
            </>
        );
    }

    render() {
        return (
            <VocModal
                isOpen={ this.props.show }
                modalClassName={ 'modal-scrollable' }
                header={ 'Send invitation' }
                toggle={ this.onCloseClicked }
                onOpened={ this.initModal }
                onClosed={ this.cleanupModal }
                footer={ this.getModalFooter() }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}

SendInvitation.propTypes = {
    surveyGroup: PropTypes.any,
    show: PropTypes.bool,
    close: PropTypes.func,
    actionHandler: PropTypes.func,
};
