import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormText, Label } from 'reactstrap';
import cx from 'classnames';

import './VocFormCheckbox.scss';

export const VocFormCheckbox = ({
    input,
    className,
    theme,
    type,
    label,
    placeholder,
    passChecked,
    required,
    withError,
    disabled,
    meta: {
        touched,
        error,
    },
}) => {
    const config = {};

    if (passChecked) {
        config['checked'] = input.value;
    }

    return (
        <div className={ cx(theme, className, 'app-form-control__container') }>
            <Label className={ cx('voc-checkbox', { 'voc-checkbox_disabled': disabled }) }>
                <Input
                    id={ input.name }
                    value={ input.value }
                    className={ `app-form-control` }
                    { ...{
                        type,
                        placeholder,
                        required,
                    } }
                    { ...input }
                    { ...config }
                />
                <span className="voc-checkbox__checkmark"/>
                { label && <span className={ 'voc-checkbox__label' }>{ label }</span> }
            </Label>

            { Boolean(touched && error && withError)
                ? <FormText className="app-form-control__error">{ error }</FormText>
                : false
            }
        </div>

    );
};

VocFormCheckbox.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    withError: PropTypes.bool,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    passChecked: PropTypes.bool,
};

VocFormCheckbox.defaultProps = {
    withError: true,
    type: 'checkbox',
    className: '',
    theme: 'defaultTheme',
};
