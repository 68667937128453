import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { SubmissionError } from 'redux-form';

const GET_CASE_ALERTS_REQUEST = '/api/case_alerts/request';
const GET_CASE_ALERTS_SUCCESS = '/api/case_alerts/success';
const GET_CASE_ALERTS_ERROR = '/api/case_alerts/error';

const DELETE_CASE_ALERT_REQUEST = 'DELETE_CASE_ALERT_REQUEST';
const DELETE_CASE_ALERT_SUCCESS = 'DELETE_CASE_ALERT_SUCCESS';
const DELETE_CASE_ALERT_ERROR = 'DELETE_CASE_ALERT_ERROR';

const CREATE_CONDITION_REQUEST = 'CREATE_CONDITION_REQUEST';
const CREATE_CONDITION_SUCCESS = 'CREATE_CONDITION_SUCCESS';
const CREATE_CONDITION_ERROR = 'CREATE_CONDITION_ERROR';

const UPDATE_CONDITION_REQUEST = 'UPDATE_CONDITION_REQUEST';
const UPDATE_CONDITION_SUCCESS = 'UPDATE_CONDITION_SUCCESS';
const UPDATE_CONDITION_ERROR = 'UPDATE_CONDITION_ERROR';

const DELETE_CONDITION_REQUEST = 'DELETE_CONDITION_REQUEST';
const DELETE_CONDITION_SUCCESS = 'DELETE_CONDITION_SUCCESS';
const DELETE_CONDITION_ERROR = 'DELETE_CONDITION_ERROR';

const GET_CONDITIONS_LIST_REQUEST = 'GET_CONDITIONS_LIST_REQUEST';
const GET_CONDITIONS_LIST_SUCCESS = 'GET_CONDITIONS_LIST_SUCCESS';
const GET_CONDITIONS_LIST_ERROR = 'GET_CONDITIONS_LIST_ERROR';

const CREATE_CASE_ALERT_REQUEST = 'CREATE_CASE_ALERT_REQUEST';
const CREATE_CASE_ALERT_SUCCESS = 'CREATE_CASE_ALERT_SUCCESS';
const CREATE_CASE_ALERT_ERROR = 'CREATE_CASE_ALERT_ERROR';

const UPDATE_CASE_ALERT_REQUEST = 'UPDATE_CASE_ALERT_REQUEST';
const UPDATE_CASE_ALERT_SUCCESS = 'UPDATE_CASE_ALERT_SUCCESS';
const UPDATE_CASE_ALERT_ERROR = 'UPDATE_CASE_ALERT_ERROR';

const CASE_ALERTS_FILTERS_CHANGE = 'CASE_ALERTS_FILTERS_CHANGE';
const UPDATE_CURRENT_CASE_ALERT = 'UPDATE_CURRENT_CASE_ALERT';
const UPDATE_CURRENT_CASE_ALERT_CONDITION = 'UPDATE_CURRENT_CASE_ALERT_CONDITION';
const SET_CURRENT_CONDITION_CASE_ALERT = 'SET_CURRENT_CONDITION_CASE_ALERT';

const CLEANUP_CASE_ALERTS_PAGE = 'CLEANUP_CASE_ALERTS_PAGE';
const CLEANUP_CASE_ALERT = 'CLEANUP_CASE_ALERT';
const CLEAN_UP_CASE_ALERT_CONDITION = 'CLEAN_UP_CASE_ALERT_CONDITION';

function getInitialState() {
    return {
        loading: false,
        results: [],
        filters: {
            page: 1,
            limit: 10,
        },
        conditions: {
            loading: false,
            results: [],
        },
        condition: {
            caseAlert: null,
            caseAlertType: 'answer',
            conditionSubmitted: false,
        },
        caseAlert: {
            name: null,
            target_emails: [],
        },
        count: 0,
        caseAlertConditionSubmitted: false,
        caseAlertSubmitted: false,
    };
}

export function reducer(state = (getInitialState()), action = {}) {
    switch (action.type) {
        case DELETE_CONDITION_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case DELETE_CONDITION_SUCCESS: {
            return {
                ...state,
                loading: false,
                results: state.results.map(caseAlert => caseAlert.id === action.params.caseAlertId
                    ? {
                        ...caseAlert,
                        conditions: caseAlert.conditions.filter(el => +(el.match(/\d+/)[0]) !== action.params.id),
                    }
                    : caseAlert,
                ),
            };
        }

        case DELETE_CONDITION_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case GET_CASE_ALERTS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_CASE_ALERTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.response,
            };
        }

        case GET_CASE_ALERTS_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case CASE_ALERTS_FILTERS_CHANGE: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case GET_CONDITIONS_LIST_REQUEST: {
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    loading: true,
                },
            };
        }

        case GET_CONDITIONS_LIST_SUCCESS: {
            return {
                ...state,
                conditions: {
                    results: [ ...action.response ],
                    loading: false,
                },
            };
        }

        case GET_CONDITIONS_LIST_ERROR: {
            return {
                ...state,
                conditions: {
                    ...state.conditions,
                    loading: false,
                },
            };
        }

        case SET_CURRENT_CONDITION_CASE_ALERT: {
            return {
                ...state,
                condition: {
                    ...state.condition,
                    case_alert: action.id,
                },
            };
        }

        case UPDATE_CURRENT_CASE_ALERT: {
            return {
                ...state,
                case_alert: {
                    ...state.case_alert,
                    ...action.case_alert,
                },
            };
        }

        case UPDATE_CURRENT_CASE_ALERT_CONDITION: {
            return {
                ...state,
                condition: {
                    ...state.condition,
                    ...action.payload,
                    hidden: true,
                },
            };
        }

        case CREATE_CONDITION_REQUEST:
        case UPDATE_CONDITION_REQUEST: {
            return {
                ...state,
                caseAlertConditionSubmitted: true,
            };
        }

        case CREATE_CONDITION_ERROR:
        case UPDATE_CONDITION_ERROR: {
            return {
                ...state,
                caseAlertConditionSubmitted: false,
            };
        }

        case CREATE_CASE_ALERT_REQUEST:
        case UPDATE_CASE_ALERT_REQUEST: {
            return {
                ...state,
                caseAlertSubmitted: true,
            };
        }

        case CREATE_CASE_ALERT_ERROR:
        case UPDATE_CASE_ALERT_ERROR: {
            return {
                ...state,
                caseAlertSubmitted: false,
            };
        }

        case CLEAN_UP_CASE_ALERT_CONDITION: {
            return {
                ...state,
                caseAlertConditionSubmitted: false,
                condition: {
                    case_alert_type: 'answer',
                    conditionSubmitted: false,
                    hidden: false,
                },
            };
        }

        case CLEANUP_CASE_ALERT: {
            return {
                ...state,
                caseAlertSubmitted: false,
                case_alert: {
                    name: null,
                    target_emails: [],
                },

            };
        }

        case CLEANUP_CASE_ALERTS_PAGE: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getCaseAlertsList(body) {
    return {
        [CALL_API]: {
            endpoint: `casealert/expand-basic/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CASE_ALERTS_REQUEST, GET_CASE_ALERTS_SUCCESS, GET_CASE_ALERTS_ERROR ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function getConditionsList(id) {
    return {
        [CALL_API]: {
            endpoint: `casealert/${ id }/conditions-list/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CONDITIONS_LIST_REQUEST, GET_CONDITIONS_LIST_SUCCESS, GET_CONDITIONS_LIST_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function updateCaseAlertCurrent(case_alert) {
    return {
        type: UPDATE_CURRENT_CASE_ALERT,
        case_alert,
    };
}

export function setCurrentConditionCaseAlert(id) {
    return {
        type: SET_CURRENT_CONDITION_CASE_ALERT,
        id,
    };
}

export function cleanupCondition() {
    return {
        type: CLEAN_UP_CASE_ALERT_CONDITION,
    };
}

export function cleanupCaseAlert() {
    return {
        type: CLEANUP_CASE_ALERT,
    };
}

export function manageCaseAlertActions(case_alert) {
    return dispatch => {
        const { id = null, name, target_emails } = case_alert;
        const request = id
            ? dispatch(updateCaseAlert(id, case_alert))
            : dispatch(createCaseAlert({ name, target_emails }));

        return request.promise
            .catch(catchCaseAlertErrors);
    };
}

export function catchCaseAlertErrors({ response }) {
    throw new SubmissionError(response);
}

function createCaseAlert(body) {
    return {
        [CALL_API]: {
            endpoint: `/casealert/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_CASE_ALERT_REQUEST, CREATE_CASE_ALERT_SUCCESS, CREATE_CASE_ALERT_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Case alert was successfully created',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

function updateCaseAlert(id, body) {
    return {
        [CALL_API]: {
            endpoint: `/casealert/${ id }/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ UPDATE_CASE_ALERT_REQUEST, UPDATE_CASE_ALERT_SUCCESS, UPDATE_CASE_ALERT_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Case alert was successfully saved',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function deleteCaseAlert(id) {
    return {
        [CALL_API]: {
            endpoint: `/casealert/${ id }/`,
            method: 'DELETE',
            types: [ DELETE_CASE_ALERT_REQUEST, DELETE_CASE_ALERT_SUCCESS, DELETE_CASE_ALERT_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Case alert was successfully deleted',
                error: 'Something went wrong',
            },
        },
    };
}

function createCaseAlertCondition(body) {
    return {
        [CALL_API]: {
            endpoint: `/casealert-condition/`,
            method: 'POST',
            types: [ CREATE_CONDITION_REQUEST, CREATE_CONDITION_SUCCESS, CREATE_CONDITION_ERROR ],
            apiType: FEEDBACK,
            body,
            logger: true,
            loggerMessages: {
                success: 'Condition was successfully created',
                error: 'Something went wrong',
            },
        },
    };
}

function updateCaseAlertCondition(id, body) {
    return {
        [CALL_API]: {
            endpoint: `/casealert-condition/${ id }/`,
            method: 'PUT',
            types: [ UPDATE_CONDITION_REQUEST, UPDATE_CONDITION_SUCCESS, UPDATE_CONDITION_ERROR ],
            apiType: FEEDBACK,
            body,
            logger: true,
            loggerMessages: {
                success: 'Condition was successfully updated',
                error: 'Something went wrong',
            },
        },
    };
}

export function manageCaseAlertCondition(condition, id) {
    return dispatch => {
        return id
            ? dispatch(updateCaseAlertCondition(id, condition))
            : dispatch(createCaseAlertCondition(condition));
    };
}

export function deleteCaseAlertCondition(caseAlertId, id) {
    return {
        [CALL_API]: {
            endpoint: `/casealert-condition/${ id }/`,
            method: 'DELETE',
            types: [ DELETE_CONDITION_REQUEST, DELETE_CONDITION_SUCCESS, DELETE_CONDITION_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Condition was successfully deleted',
                error: 'Something went wrong',
            },
            params: {
                caseAlertId,
                id,
            },
        },
    };
}

export function updateCurrentCaseAlertCondition(payload) {
    return {
        type: UPDATE_CURRENT_CASE_ALERT_CONDITION,
        payload,
    };
}

export function updateFilters(payload) {
    return {
        type: CASE_ALERTS_FILTERS_CHANGE,
        payload,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_CASE_ALERTS_PAGE,
    };
}
