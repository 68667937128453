import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VocModal, FormFieldItem, VocSelect } from '/components';
import { HelperService } from '/services';

import './NewSurveyModal.scss';

const initialState = {
    unit: '',
    name: '',
    isButtonDisabled: false,
};

export class NewSurveyModal extends Component {
    state = initialState;

    @autobind
    onSurveyAddClicked() {
        this.setState({ isButtonDisabled: true });

        const model = {
            unit: this.state.unit,
            name: this.state.name,
        };

        this.props.actionHandler(model);
        this.props.close();
    }

    @autobind
    checkValidation() {
        const {
            name,
            unit,
            isButtonDisabled,
        } = this.state;

        return !Boolean(name && unit && !isButtonDisabled);
    }

    getModalFooter() {
        return (
            <div key={ 1 }>
                <Button onClick={ this.props.close } color={ 'white' } outline>
                    <FontAwesomeIcon icon='times'/>
                    <span> Cancel</span>
                </Button>
                <Button onClick={ this.onSurveyAddClicked }
                    color='primary'
                    disabled={ this.checkValidation() }>
                    <FontAwesomeIcon icon='check'/>
                    <span> Add survey</span>
                </Button>
            </div>
        );
    }

    @autobind
    handleChangeNameInput(event) {
        let name = event.target.value;

        if(name.indexOf(' ') === 0) {
            name = name.trim();
        }

        this.setState({ name });
    }

    @autobind
    handleChangeUnitInput(selectedOption) {
        this.setState({
            unit: selectedOption ? selectedOption.value : '',
        });
    }

    @autobind
    cleanupModal() {
        this.setState(initialState);
    }

    render() {
        const { units, show, close } = this.props;

        const selectOptions = HelperService.mapUnitsToSelectModel(units);
        const unitValue = HelperService.getSelectedValue(selectOptions, this.state.unit);

        return (
            <VocModal
                isOpen={ show }
                toggle={ close }
                onClosed={ this.cleanupModal }
                header={ 'Add survey' }
                size={ 'md' }
                footer={ this.getModalFooter() }
                modalClassName='modal-scrollable'
            >
                <FormFieldItem
                    controlId="survey_name"
                    type="text"
                    isHorizontal={ true }
                    value={ this.state.name }
                    label="Name *"
                    handleChange={ this.handleChangeNameInput }
                />
                <VocSelect
                    label="Unit *"
                    placeholder="Select a unit"
                    isClearable={ false }
                    isHorizontal={ true }
                    options={ selectOptions }
                    value={ unitValue }
                    onChange={ this.handleChangeUnitInput }
                    portal
                />
                <p className='new-survey__modal-note modal-note'>
                    New survey will be added to unassigned Touchpoint
                    <em>(by default)</em>.
                </p>
            </VocModal>
        );
    }
}

NewSurveyModal.propTypes = {
    units: PropTypes.array,
    actionHandler: PropTypes.func,
    show: PropTypes.bool,
    close: PropTypes.func,
};
NewSurveyModal.defaultProps = {};
