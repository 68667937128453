import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { Popover } from 'reactstrap';
import './QuarantinesFlowPopover.scss';
import { QuarantineFlowConditionString } from '../QuarantineFlowConditionString';

export class QuarantinesFlowPopover extends Component {
    @autobind
    renderFlowItems() {
        const { formValue } = this.props;

        return (
            <Fragment>
                {
                    formValue.conditions && formValue.action_type
                    && <QuarantineFlowConditionString
                        formValuesSelector={ formValue }
                    />
                }
            </Fragment>
        );
    }

    render() {
        const { show, toggle, target } = this.props;

        return (
            <Popover
                id='flow-structure-popover'
                className='quarantine-flow-popover-inner'
                isOpen={ show }
                hideArrow={ true }
                placement="bottom"
                target={ target }
                toggle={ toggle }
                trigger="legacy"
            >
                { this.renderFlowItems() }
            </Popover>
        );
    }
}
