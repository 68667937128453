import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { editGadgetTitle } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { TooltipWrapper, Icons } from '/components';
import { HelperService } from '/services';

import styles from './style.module.scss';

const IsDrilledDownGadgetIcon = ({ canEdit }: { canEdit?: boolean }) => {
    const { t } = useTranslation();

    return (
        <div className={ cx(styles.drillDownIconWrapper, { [styles.showTooltip]: !canEdit }) }>
            <TooltipWrapper value={ t('drilledDownInfo') } force>
                <Icons icon={ 'drillDownIcon' } />
            </TooltipWrapper>
        </div>
    );
};

export const Title = ({
    title,
    id,
    canEdit = true,
    drilledDown = false,
    fontSize = 16,
    tooltipProps = {
        tooltipPlacement: 'top',
        withWrapperObserver: false,
    },
}: {
    title: string,
    id: string,
    canEdit?: boolean,
    drilledDown?: boolean,
    fontSize?: number,
    tooltipProps?: {
        tooltipPlacement?: string,
        withWrapperObserver?: boolean,
    },
}) => {
    const [ titleValue, setTitleValue ] = useState(title);
    const [ editTitle, setEditTitle ] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onChangeTitleHandler = () => {
        const isTitleEmpty = !HelperService.checkNotEmpty(titleValue.trim());
        const valueToSave = isTitleEmpty
            ? title
            : titleValue;

        dispatch(editGadgetTitle(id, valueToSave, true));
        setEditTitle(false);

        if (isTitleEmpty) {
            setTitleValue(title);
        }
    };

    useEffect(() => {
        setTitleValue(title);
    }, [ title ]);

    const handleOnMouseUp = () => setEditTitle(canEdit);

    const getTitleInput = () => (
        <Input
            autoFocus
            value={ titleValue }
            className={ cx('disabled-drag') }
            onBlur={ onChangeTitleHandler }
            onKeyPress={ ({ key }) => key === 'Enter' && onChangeTitleHandler() }
            onChange={ ({ target }) => setTitleValue(target.value) }
        />
    );

    const getTitleParagraph = () => (
        <div
            className={ cx(styles.title, { [styles.editable]: canEdit }) }
            onClick={ handleOnMouseUp }
        >
            <TooltipWrapper
                value={ titleValue }
                placement={ tooltipProps.tooltipPlacement }
                withWrapperObserver={ tooltipProps.withWrapperObserver }
            >
                <span style={{ fontSize: `${ fontSize }px` }}>{ titleValue }</span>
            </TooltipWrapper>
        </div>
    );

    return (
        <>
            {
                drilledDown && <IsDrilledDownGadgetIcon canEdit={ canEdit } />
            }
            {
                editTitle
                    ? getTitleInput()
                    : getTitleParagraph()
            }
        </>
    );
};
