import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { autobind } from 'core-decorators';

import { CollapseSection } from '/components';
import {
    SettingTextField, SettingColorPickerField, SettingHtmlField,
    SettingSelectField, SettingControlGroupField, SettingJSField,
    PreviewField, StaticLinkField, SettingCssField,
    DynamicGroupField, ConfigurableGroup,
} from './components/SettingsFormFields';
import { HelperService } from '/services';

import './SettingsFormSections.scss';

export class SettingsFormSections extends Component {
    state = {
        fields: this.props.fieldsMap.map((field, index) => ({ ...field, collapse: index === 0 })),
    };

    getFieldComponent(type) {
        let component = SettingTextField;

        switch (type) {
            case 'html':
                component = SettingHtmlField;
                break;
            case 'select':
                component = SettingSelectField;
                break;
            case 'staticLink':
                component = StaticLinkField;
                break;
            case 'color':
                component = SettingColorPickerField;
                break;
            case 'preview':
                component = PreviewField;
                break;
            case 'dynamicGroup':
                component = DynamicGroupField;
                break;
            case 'configurableGroup':
                component = ConfigurableGroup;
                break;
            case 'group':
                component = SettingControlGroupField;
                break;
            case 'css':
                component = SettingCssField;
                break;
            case 'javascript':
                component = SettingJSField;
                break;
        }

        return component;
    }

    @autobind
    getAdditionalFieldProps({
        type,
        options,
        key,
        properties,
        clearable = true,
        additionalPlugin = false,
    }) {
        const additionalProps = {};

        properties && properties.forEach(({ name, value }) => additionalProps[name] = value);

        switch (type) {
            case 'select':
                additionalProps['options'] = options;
                additionalProps['normalizer'] = HelperService.normalizeSelectValue;
                additionalProps['multiNormalizer'] = HelperService.normalizeMultiSelectValue;
                additionalProps['formatter'] = HelperService.getSelectedValue;
                additionalProps['clearable'] = clearable;
                break;

            case 'configurableGroup':
                additionalProps['options'] = options;
                additionalProps['normalizer'] = HelperService.normalizeSelectValue;
                additionalProps['multiNormalizer'] = HelperService.normalizeMultiSelectValue;
                additionalProps['formatter'] = HelperService.getSelectedValue;
                additionalProps['clearable'] = clearable;
                break;

            case 'staticLink':
                additionalProps['showToast'] = this.props.showToast;
                additionalProps['getNewLink'] = this.props.getStaticLink;
                break;

            case 'dynamicGroup':
                additionalProps['options'] = options;
                additionalProps['change'] = this.props.change;
                break;

            case 'preview':
                additionalProps['showToast'] = this.props.showToast;
                additionalProps['copyToClipboard'] = this.props.copyToClipboard;
                break;

            case 'html':
                additionalProps['assets'] = this.props.assets;
                additionalProps['getAssets'] = this.props.getAssets;
                additionalProps['uploadAsset'] = this.props.uploadAsset;
                additionalProps['additionalPlugin'] = additionalPlugin;
                break;
        }

        if (this.props.onChangeField) {
            additionalProps['onChange'] = ({ value }) => this.props.onChangeField(value, key);
        }

        return additionalProps;
    }

    @autobind
    onCollapseChanged(collapse) {
        this.setState({
            fields: this.state.fields.map(field => ({
                ...field,
                collapse: field.label === collapse,
            })),
        });
    }

    @autobind
    getChildrenField(item) {
        return (
            item.children.map((child, key) => {
                return <div className={ 'settings-form__input-wrapper' } key={ key }>
                    <Field
                        index={ key }
                        name={ child.key }
                        label={ child.label }
                        type={ child.type }
                        redefined={ child.redefined }
                        regexData={ child.regexData }
                        placeholder={ child.placeholder }
                        errorMessage={ child.errorMessage }
                        component={ this.getFieldComponent(child.type) }
                        { ...this.getAdditionalFieldProps(child) }
                    />
                </div>;
            },
            )
        );
    }

    @autobind
    getFields(item, index) {
        const { fields } = this.state;

        return item.label === null || !this.props.wrappedCollapse
            ? this.getChildrenField(item)
            : <CollapseSection className={ 'settings-form__section' }
                key={ index }
                title={ `${ item.label }` }
                titleAddon={ `${ index + 1 }/${ fields.length }` }
                collapse={ item.collapse }
                collapseDisable={ item.collapseDisable }
                onCollapseChanged={ this.onCollapseChanged }
            >
                {
                    this.getChildrenField(item)
                }
            </CollapseSection>;
    }

    render() {
        const { fields } = this.state;

        return (
            <>
                {
                    fields.map((item, index) => (
                        !!item.children.length && this.getFields(item, index)
                    ))
                }
            </>
        );
    }
}

SettingsFormSections.defaultProps = {
    fieldsMap: [],
    wrappedCollapse: true,
};

SettingsFormSections.propTypes = {
    fieldsMap: PropTypes.array,
    getStaticLink: PropTypes.func,
    showToast: PropTypes.func,
    copyToClipboard: PropTypes.func,
};
