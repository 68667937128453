import React, { Fragment, useEffect } from 'react';
import { Label } from 'reactstrap';
import { VocSelect } from '/components';
import { InputLoader } from '/feedback/components/InputLoader';

export const ExportForVerification = ({
    getClassifierList,
    setFormData,
    classifiers: {
        data,
        loading,
    },
}) => {
    const onChange = option => setFormData({ classifierId: option.value });

    useEffect(() => {
        getClassifierList();
    }, []);

    return (
        <Fragment>
            <Label> Classifier </Label>
            {
                loading
                    ? <InputLoader/>
                    : <VocSelect
                        options={ data }
                        placeholder={ 'Select classifier' }
                        onChange={ onChange }
                        portal
                    />
            }
        </Fragment>
    );
};
