import React, { Fragment, Component } from 'react';
import { autobind } from 'core-decorators';
import { ChromePicker } from 'react-color';
import { FormText, Input } from 'reactstrap';
import { OutsideAlerter } from '/components';
import { SettingFieldContainer } from '../components';

import './SettingColorPickerField.scss';

export class SettingColorPickerField extends Component {
    state = {
        showColorPicker: false,
    };

    @autobind
    onChange(event) {
        const {
            input: {
                onChange,
                value,
            },
        } = this.props;

        const model = {
            ...value,
            value: event.hex ? event.hex : event.target.value,
        };

        onChange(model);
    }

    @autobind
    onFocus() {
        this.props.input.onFocus(this.props.input.value);
        this.setState({ showColorPicker: true });
    }

    @autobind
    onBlur() {
        this.props.input.onBlur(this.props.input.value);
    }

    @autobind
    handleClick() {
        this.setState({ showColorPicker: !this.state.showColorPicker });
    }

    @autobind
    handleClickOutside() {
        this.setState({ showColorPicker: false });
    }

    render() {
        const {
            input,
            withError,
            meta: {
                error,
                touched,
                dirty,
            },
            label,
            input: {
                value: {
                    redefined,
                    value,
                },
            },
        } = this.props;

        return (
            <Fragment>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                <OutsideAlerter handleClickOutside={ this.handleClickOutside }>
                    <SettingFieldContainer
                        checked={ redefined }
                        field={ input }
                        className={ `${ dirty ? 'changed' : '' } color-picker` }
                    >

                        <div className={ `swatch-color-picker ${ redefined ? '' : ' disabled' }` }
                            onClick={ this.handleClick } style={{ background: value }}/>

                        <Input
                            id={ input.name }
                            type={ 'text' }
                            className={ 'voc-setting-field__input' }
                            disabled={ !redefined }
                            placeholder={ label }
                            value={ value }
                            onChange={ this.onChange }
                            onFocus={ this.onFocus }
                            onBlur={ this.onBlur }
                        />

                        {
                            this.state.showColorPicker
                            && <div className={ 'popover' }>
                                <ChromePicker
                                    color={ value }
                                    onChangeComplete={ this.onChange }
                                    disableAlpha={ true }
                                />
                            </div>
                        }

                        {
                            Boolean(touched && error && withError)
                                ? <FormText className="app-form-control__error">{ error }</FormText>
                                : false
                        }

                    </SettingFieldContainer>
                </OutsideAlerter>
            </Fragment>
        );
    }
}

SettingColorPickerField.defaultProps = {
    withError: true,
};
