import { HelperService } from '/services';
import { SettingsService } from '/feedback/services';
import { createSelector } from 'reselect';

export const getCommonLanguage = state => state.VocFeedback.common.languages.results;
export const getActiveLang = state => state.VocFeedback.themeSettings.activeLangId;
export const getSendingLangStatus = state => state.VocFeedback.themeSettings.sendingLangStatus;
export const getDigiLangState = state => state.VocFeedback.themeSettings.languageSettings;

const keysLangSettings = SettingsService.getKeysForFilter('ThemeLanguages');

export const defaultLangSettings = () => {
    const model = {};

    keysLangSettings.map(key => {
        model[key] = '';
    });

    return model;
};

const activeLanguageSelector = createSelector(
    [ getCommonLanguage, getDigiLangState, getActiveLang ],
    (commonLangs, { results }, activeLangId) => {
        let activeLang = activeLangId;

        if (results.length && activeLang === null) {
            const idENLang = commonLangs.find(el => el.iso_6391_code === 'EN').id;
            const hasENLang = results.find(theme => theme.language === idENLang);

            activeLang = hasENLang ? hasENLang.language : results.sort((a, b) => a.language - b.language)[0].language;
            if (activeLangId === null || !!hasENLang) {
                SettingsService.setterLocalStorage('digiThemes', { [results[0].digi_theme]: activeLang });
            }
        }

        return activeLang;
    });

export const commonLanguageSelector = createSelector([ getCommonLanguage, getDigiLangState, activeLanguageSelector ],
    (commonLangs, { loading, sending, results }, activeLang) => {
        return {
            globalLanguages: {
                languages: commonLangs.map(commonLang => {
                    const appliedLang = results.find(themeLang => themeLang.language === commonLang.id) || {};

                    return {
                        language: commonLang.iso_6391_code,
                        value: commonLang.id,
                        label: commonLang.iso_6391_code,
                        hasLanguageText: !!appliedLang.id,
                        active: activeLang === commonLang.id,
                    };
                }),
                enableLangLoading: loading,
                langUpdating: sending,
            },
            activeLangId: activeLang,
            themeLangId: results.find(themeLang => themeLang.language === activeLang),
        };
    });

const getDigiLangSettings = createSelector(
    [ getDigiLangState, getActiveLang ],
    ({ results }, activeLang) => {
        const activeLangSettings = results.find(langSettings => langSettings.language === activeLang) || {};

        return HelperService.pick(activeLangSettings, keysLangSettings);
    });

const initialFormValuesSelector = createSelector([ getDigiLangSettings ],
    settings => {
        const commonSettings = SettingsService.composeGlobalSettings(settings);
        const fields = SettingsService.getFieldsByThemeLanguageSettings();

        return {
            fieldsMap: fields.map(field => ({
                ...field,
                children: field.children.map(child => ({
                    ...child,
                    redefined: true,
                    isGlobal: true,
                })),
            })),
            initialValues: commonSettings,
        };
    });

export const languageFormSettingsSelector = createSelector([ initialFormValuesSelector, getDigiLangState, getSendingLangStatus ],
    (fields, { loading, sending }, sendingLangStatus) => {
        return {
            loading,
            sending,
            sendingLangStatus,
            ...fields,
        };
    });
