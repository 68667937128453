import { exportConditionsList } from '../../modules/exporter.selectors';

const required = "This field shouldn't be empty";
const formatDate = "Both fields must be either filled or empty";
const mutationErrors = (errors, row, index, field) => {
    errors[row][index] = {
        ...errors[row][index],
        [field]: required,
    };
};

export const exporterValidation = (values, props) => {
    const errors = {
        export_conditions: [],
        custom_columns: [],
    };

    const {
        name, output_type, delimiter, question_column_header, filter_by_states,
        language, export_type, export_conditions, custom_columns, date_format,
        time_format, filter_by_date,
    } = values;

    const isExportTypeBlacklist = export_type === 'blacklist';

    if(export_type === "emulate_legacy_default" && !filter_by_date) {
        errors.filter_by_date = required;
    }

    if (!name) {
        errors.name = required;
    }

    if (!output_type) {
        errors.output_type = required;
    }

    if ((time_format || date_format) && (!time_format || !date_format)) {
        errors.time_format = formatDate;
        errors.date_format = formatDate;
    }

    if (!delimiter && output_type === 'csv') {
        errors.delimiter = required;
    }

    if (!question_column_header && !isExportTypeBlacklist) {
        errors.question_column_header = required;
    }

    if (!language && question_column_header === 'question_text') {
        errors.language = required;
    }

    if (!filter_by_states && !isExportTypeBlacklist) {
        errors.filter_by_states = required;
    }

    if (!export_type) {
        errors.export_type = required;
    }

    export_conditions?.forEach((condition, index) => {
        exportConditionsList.forEach(item => !condition[item] && mutationErrors(errors, 'export_conditions', index, item));
    });

    if(custom_columns?.length) {
        const flatTypeOptions = props.typeOptions.reduce((acc, currentValue) => [ ...acc, ...currentValue.options ], []);

        custom_columns.forEach(({ column_title, data_type, data_value }, index) => {
            const dateType = data_type?.value || data_type;
            const typeOption = flatTypeOptions.find(el => el.value === dateType);

            if(!column_title) mutationErrors(errors, 'custom_columns', index, 'column_title');
            if(!dateType) mutationErrors(errors, 'custom_columns', index, 'data_type');
            if(!data_value && (!typeOption || typeOption.valueRequired)) mutationErrors(errors, 'custom_columns', index, 'data_value');
        });
    }

    return errors;
};
