import { CALL_API, FEEDBACK } from 'store/middleware/api';

export const GET_CUSTOMERS_LIST_REQUEST = 'GET_CUSTOMERS_LIST_REQUEST';
export const GET_CUSTOMERS_LIST_SUCCESS = 'GET_CUSTOMERS_LIST_SUCCESS';
export const GET_CUSTOMERS_LIST_ERROR = 'GET_CUSTOMERS_LIST_ERROR';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

export const ADD_CUSTOMER_TO_BLACKLIST_REQUEST = 'ADD_CUSTOMER_TO_BLACKLIST_REQUEST';
export const ADD_CUSTOMER_TO_BLACKLIST_SUCCESS = 'ADD_CUSTOMER_TO_BLACKLIST_SUCCESS';
export const ADD_CUSTOMER_TO_BLACKLIST_ERROR = 'ADD_CUSTOMER_TO_BLACKLIST_ERROR';

export const GET_ID_FOR_BLACKLISTED_REQUEST = 'GET_ID_FOR_BLACKLISTED_REQUEST';
export const GET_ID_FOR_BLACKLISTED_SUCCESS = 'GET_ID_FOR_BLACKLISTED_SUCCESS';
export const GET_ID_FOR_BLACKLISTED_ERROR = 'GET_ID_FOR_BLACKLISTED_ERROR';

export const ADDRESS_TO_BLACKLIST_REQUEST = 'ADDRESS_TO_BLACKLIST_REQUEST';
export const ADDRESS_TO_BLACKLIST_SUCCESS = 'ADDRESS_TO_BLACKLIST_SUCCESS';
export const ADDRESS_TO_BLACKLIST_ERROR = 'ADDRESS_TO_BLACKLIST_ERROR';

export const REMOVE_CUSTOMER_FROM_BLACKLIST_REQUEST = 'REMOVE_CUSTOMER_FROM_BLACKLIST_REQUEST';
export const REMOVE_CUSTOMER_FROM_BLACKLIST_SUCCESS = 'REMOVE_CUSTOMER_FROM_BLACKLIST_SUCCESS';
export const REMOVE_CUSTOMER_FROM_BLACKLIST_ERROR = 'REMOVE_CUSTOMER_FROM_BLACKLIST_ERROR';

export const UPDATE_CONTACTS_REQUEST = 'UPDATE_CONTACTS_REQUEST';
export const UPDATE_CONTACTS_SUCCESS = 'UPDATE_CONTACTS_SUCCESS';
export const UPDATE_CONTACTS_ERROR = 'UPDATE_CONTACTS_ERROR';

export const ADD_CUSTOMERS_ADDRESS_REQUEST = 'ADD_CUSTOMERS_ADDRESS_REQUEST';
export const ADD_CUSTOMERS_ADDRESS_SUCCESS = 'ADD_CUSTOMERS_ADDRESS_SUCCESS';
export const ADD_CUSTOMERS_ADDRESS_ERROR = 'ADD_CUSTOMERS_ADDRESS_ERROR';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';

export const CUSTOMER_DOES_NOT_EXISTS = 'CUSTOMER_DOES_NOT_EXISTS';

export const CUSTOMERS_LIST_FILTERS_CHANGED = 'CUSTOMERS_LIST_FILTERS_CHANGED';

export const CLEANUP = 'feedback/customers/cleanup';

function getInitialState() {
    return {
        loading: false,
        filters: {
            status: {
                label: 'All',
                value: 0,
                partly_blacklisted: null,
            },
            page: 1,
            limit: 10,
            sort_order: '',
            search: '',
        },
        results: [],
        count: 0,
        blacklistForm: {
            submitted: false,
            customerId: null,
            phone: null,
            email: null,
        },
        submitted: false,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_CUSTOMERS_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_CUSTOMERS_LIST_SUCCESS: {
            return {
                ...state,
                ...action.response,
                loading: false,
            };
        }

        case CUSTOMERS_LIST_FILTERS_CHANGED: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case DELETE_CUSTOMER_SUCCESS: {
            return {
                ...state,
                results: state.results.filter(result => result.id !== action.id),
            };
        }

        case GET_ID_FOR_BLACKLISTED_REQUEST: {
            return {
                ...state,
                submitted: true,
            };
        }

        case ADDRESS_TO_BLACKLIST_ERROR:
        case ADD_CUSTOMER_TO_BLACKLIST_SUCCESS:
        case CUSTOMER_DOES_NOT_EXISTS:
        case GET_CUSTOMERS_LIST_ERROR: {
            return {
                ...state,
                submitted: false,
            };
        }

        case CLEANUP:
            return getInitialState();

        default:
            return state;
    }
}

export function getCustomersRequest(body) {
    return {
        [CALL_API]: {
            endpoint: `/customer/expand-full/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CUSTOMERS_LIST_REQUEST, GET_CUSTOMERS_LIST_SUCCESS, GET_CUSTOMERS_LIST_ERROR ],
            body: {
                state_exclude: 3,
                ...body,
            },
            apiType: FEEDBACK,
        },
    };
}

export function addAllToBlacklist(id, email = null, phone = null, callbackRequest, callbackAction) {
    return dispatch => {
        const body = { state: 16 };
        const addToBlacklistPromises = [ dispatch(addBlacklist({ body, id })).promise ];

        if (email || phone) {
            email && addToBlacklistPromises.push(dispatch(putAddressToBlacklist({
                address: email,
                customer: id,
                protocol: "EMAIL",
                state: "25",
            })).promise);

            phone && addToBlacklistPromises.push(dispatch(putAddressToBlacklist({
                address: phone,
                customer: id,
                protocol: "SMS",
                state: "25",
            })).promise);
        }

        Promise.all(addToBlacklistPromises).then(() => {
            callbackRequest();
            callbackAction();
        });
    };
}

export function getIdForBlacklisted(id) {
    return {
        [CALL_API]: {
            endpoint: `/customer/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_ID_FOR_BLACKLISTED_REQUEST, GET_ID_FOR_BLACKLISTED_SUCCESS, GET_ID_FOR_BLACKLISTED_ERROR ],
            body: {
                external_id: id,
            },
            apiType: FEEDBACK,
            logger: true,
            loggerMessageIsPrior: true,
            loggerMessages: {
                success: '',
                error: `Customer with external ID '${id}' doesn\'t exist.`,
            },
        },
    };
}

export function createCustomer(body) {
    return {
        [CALL_API]: {
            endpoint: `/customer/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_CUSTOMER_REQUEST, CREATE_CUSTOMER_SUCCESS, CREATE_CUSTOMER_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessageIsPrior: true,
            loggerMessages: {
                success: 'Customer was successfully added to Blacklist',
                error: 'Something went wrong',
            },
        },
    };
}

export function customerDoesNotExists() {
    return {
        type: CUSTOMER_DOES_NOT_EXISTS,
    };
}

export function putAddressToBlacklist(body) {
    return {
        [CALL_API]: {
            endpoint: `/address/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ ADDRESS_TO_BLACKLIST_REQUEST, ADDRESS_TO_BLACKLIST_SUCCESS, ADDRESS_TO_BLACKLIST_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
        },
    };
}

export function addBlacklist({ body, id }) {
    return {
        [CALL_API]: {
            endpoint: `/customer/${ id }/blacklist-add/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ ADD_CUSTOMER_TO_BLACKLIST_REQUEST, ADD_CUSTOMER_TO_BLACKLIST_SUCCESS, ADD_CUSTOMER_TO_BLACKLIST_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
        },
    };
}

export function removeBlacklist({ body, id }) {
    return {
        [CALL_API]: {
            endpoint: `/customer/${ id }/blacklist-remove/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ REMOVE_CUSTOMER_FROM_BLACKLIST_REQUEST, REMOVE_CUSTOMER_FROM_BLACKLIST_SUCCESS, REMOVE_CUSTOMER_FROM_BLACKLIST_ERROR ],
            body,
            apiType: FEEDBACK,
        },
    };
}

export function addCustomerAddress(body) {
    return {
        [CALL_API]: {
            endpoint: `/address/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ ADD_CUSTOMERS_ADDRESS_REQUEST, ADD_CUSTOMERS_ADDRESS_SUCCESS, ADD_CUSTOMERS_ADDRESS_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Customer Address was added',
                error: 'Something went wrong',
            },
        },
    };
}

export function updateCustomerContacts(body) {
    return {
        [CALL_API]: {
            endpoint: `/address/multiple-set-state/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ UPDATE_CONTACTS_REQUEST, UPDATE_CONTACTS_SUCCESS, UPDATE_CONTACTS_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Customer Contacts was updated',
                error: 'Something went wrong',
            },
        },
    };
}

export function updateFilters(payload) {
    return {
        type: CUSTOMERS_LIST_FILTERS_CHANGED,
        payload,
    };
}

export function deleteCustomer(id) {
    return {
        [CALL_API]: {
            endpoint: `/customer/${ id }/clear/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Customer deleted',
                error: '',
            },
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP,
    };
}

export function manageBlacklist({ body, id }) {
    return dispatch => {
        return body.state === 1
            ? dispatch(removeBlacklist({ body, id }))
            : dispatch(addBlacklist({ body, id }));
    };
}
