import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    InputGroup,
    InputGroupAddon,
    Input,
    Button,
} from 'reactstrap';

export class SearchFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFilter: props.search,
        };
    }

    @autobind
    blurHandler() {
        setTimeout(() => {
            this.setState({
                searchFilter: this.props.search,
            });
        }, 300);
    }

    @autobind
    changeHandler(event) {
        const value = event.target.value || '';

        this.setState({ searchFilter: value });
    }

    @autobind
    clearHandler() {
        this.setState({ searchFilter: '' });
        this.props.applyFilter({
            loading: true,
            search: '',
            page: 1,
        });
    }

    @autobind
    submitHandler(event) {
        this.props.applyFilter({
            loading: true,
            search: this.state.searchFilter,
            page: 1,
        });
        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={ this.submitHandler }
                className='filter-search form-horizontal'
                role='search'>
                <InputGroup>
                    <Input type='text'
                        value={ this.state.searchFilter || '' }
                        onChange={ this.changeHandler }
                        onBlur={ this.blurHandler }
                        className='form-control'
                        placeholder='Search'/>
                    <InputGroupAddon addonType="append">
                        <Button onClick={ this.clearHandler }
                            type='button'
                            color={ 'white' }
                            outline
                            className='btn btn-default'>
                            <FontAwesomeIcon icon={ 'times' }/>
                        </Button>
                        <Button type='submit'
                            className='btn btn-success'>
                            <FontAwesomeIcon icon={ 'search' }/>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </form>
        );
    }
}

SearchFilter.propTypes = {
    applyFilter: PropTypes.func,
    search: PropTypes.any,
};
SearchFilter.defaultProps = {};

