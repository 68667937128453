export class TinyEditorService {
    static fonts = `
        @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Open%20Sans&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Carlito&display=swap');
    `;

    static getFonts() {
        return this.fonts;
    }

    static setFonts(documentPath = document) {
        const fontStyles = documentPath.createElement('style');

        fontStyles.innerHTML = this.fonts;

        documentPath.head.appendChild(fontStyles);
    }
}
