import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageRequests } from '/services';
import { SettingsService, ThemeService } from '../../../../services';
import { ThemesTable, ThemeModalCopy, ThemeDetailsModal } from './components';
import { VocCleaupHOC, showToast, TableResetFilter } from '/components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import {
    getThemeList,
    deleteTheme,
    updateFilters,
    copyTheme,
    cleanup,
    getDigiRunnerUrl,
    getLangSettings,
    resetFilters,
    getTags,
    setSelected,
} from './modules/themes.modules';
import {
    tagsSelector,
    themeFiltersSelector,
    themeResultsSelector,
    themeTableDataSelector,
} from './modules/themes.selectors';
import { getPermissionSelector } from '../../modules/globalSettings.selectors';
import { getUnitId } from '/feedback/modules/VocFeedback.selectors';

import './Themes.scss';

const mapStateToProps = state => ({
    tableData: themeTableDataSelector(state),
    filters: themeFiltersSelector(state),
    themeList: themeResultsSelector(state),
    unitId: getUnitId(state),
    permission: getPermissionSelector(state),
    tags: tagsSelector(state),
});

const mapDispatchToProps = {
    getThemeList,
    deleteTheme,
    updateFilters,
    copyTheme,
    showToast,
    showConfirm,
    cleanup,
    getLangSettings,
    getDigiRunnerUrl,
    resetFilters,
    getTags,
    setSelected,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class Themes extends Component {
    pageRequests = new PageRequests();
    state = {
        openModalCopy: false,
        theme: {},
        openModalDetails: false,
        detailsTheme: {},
        isOpenPopoverFilter: false,
        targetFilter: null,
        filterField: '',
    };

    componentDidMount() {
        this.requestTrigger();
        this.props.getDigiRunnerUrl(this.props.unitId)
            .promise
            .then(({ DIGI_RUNNER_URL }) => DIGI_RUNNER_URL && SettingsService.injectDigiRunnerUrl(DIGI_RUNNER_URL));
    }

    componentWillUnmount() {
        SettingsService.removeDigiRunnerScript();
    }

    @autobind
    onOpenPopoverFilter(currentTarget, sort_field) {
        const { targetFilter } = this.state;

        if (!targetFilter || targetFilter !== currentTarget) {
            this.setState({
                targetFilter: currentTarget,
                isOpenPopoverFilter: true,
                filterField: sort_field,
            });
        }
    }

    getTableOptions() {
        return {
            requestTrigger: this.requestTrigger,
            updateFilters: this.props.updateFilters,
            haveSearch: true,
            customTableHeader: this.customTableHeader(),
            filterField: this.state.filterField,
            openPopoverFilter: this.onOpenPopoverFilter,
            rowStyle: {
                height: '38px',
            },
        };
    }

    @autobind
    resetFilters(name) {
        this.props.resetFilters(name);
        setTimeout(() => this.requestTrigger(), 0);
    }

    @autobind
    customTableHeader() {
        const { filters: { extraFilters } } = this.props;

        return [
            <TableResetFilter
                key={ 2 }
                filters={ extraFilters }
                reset={ this.resetFilters }
            />,
        ];
    }

    @autobind
    closeModal() {
        this.setState({
            openModalCopy: false,
            openModalDetails: false,
        });
    }

    @autobind
    resetModal() {
        this.setState({
            theme: {},
            detailsTheme: {},
        });
    }

    @autobind
    openConfirmCopy(param) {
        this.setState({
            openModalCopy: true,
            theme: param,
        });
    }

    @autobind
    openConfirmDelete(id) {
        this.props.showConfirm({
            header: 'Warning!',
            // eslint-disable-next-line max-len
            content: 'This theme can be selected in the settings and after removing it you may need to check the settings and select another layout in DIGI theme dropdown, continue?',
            successCallback: () => this.deleteTheme(id),
        });
    }

    @autobind
    copyTheme(param) {
        this.props.copyTheme(param).promise
            .then(() => {
                this.requestTrigger({ ...this.props.filters, page: 1 });
                this.props.updateFilters({ ...this.props.filters, page: 1 });
                this.closeModal();
            });
    }

    @autobind
    openDetails(theme) {
        this.setState({
            openModalDetails: true,
            detailsTheme: theme,
        });
    }

    @autobind
    deleteTheme(id) {
        this.props.deleteTheme(id).promise
            .then(() => {
                this.requestTrigger({ ...this.props.filters, page: 1 });
                this.props.updateFilters({ ...this.props.filters, page: 1 });
            });
    }

    @autobind
    requestTrigger({ limit, page, search, sort_field, sort_order, extraFilters } = this.props.filters) {
        const body = {
            sort: sort_field ? `${sort_order === 'desc' ? '' : '-'}${sort_field}` : null,
            limit: limit,
            page: page,
            search: search || null,
            tags: extraFilters.tags?.length ? JSON.stringify(extraFilters.tags.map(tag => tag.value)) : null,
        };

        this.pageRequests.cleanupById('themes');
        const request = this.props.getThemeList(body);

        this.pageRequests.addRequest(request, 'themes');
    }

    @autobind
    initRunnerDemo(id) {
        const theme = this.props.themeList.find(item => item.id === id);
        const themeSettings = {};

        this.props.getLangSettings(id).promise
            .then(( results ) => {
                let initValue = {
                    ...theme,
                };

                if (results.length) {
                    initValue = { ...initValue, ...results[results.length -1] };
                }

                Object.entries(initValue).map(el => {
                    themeSettings[el[0].toUpperCase()] = el[1];
                });

                ThemeService.setMockedData(themeSettings);
            });
    }

    @autobind
    applyFilters({ name, value }) {
        this.props.setSelected({ name, value });
        this.requestTrigger({
            ...this.props.filters,
            extraFilters: {
                ...this.props.filters.extraFilters,
                [name]: value,
            },
        });
    }

    @autobind
    togglePopover() {
        this.setState({
            targetFilter: null,
            isOpenPopoverFilter: false,
            filterField: '',
        });
    }

    render() {
        const { history, tableData, filters, showToast, permission: { isPermitEdit }, getTags, tags } = this.props;

        return (
            <DocumentTitle title={ 'Feedback :: DIGI Themes' }>
                <Card className={ 'page__wrapper theme-page-wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Themes</span>
                        <div className={ 'panel__header-buttons' }>
                            <Button className={ 'panel__header-button' }
                                outline
                                color="white"
                                onClick={ () => isPermitEdit && history.push('/feedback/theme/create/theme-settings') }
                                disabled={ !isPermitEdit }
                            >
                                <FontAwesomeIcon icon='plus' className='color-green'/>
                                <span> Add Themes</span>
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ThemesTable
                            openConfirmCopy={ this.openConfirmCopy }
                            openConfirmDelete={ this.openConfirmDelete }
                            openDetails={ this.openDetails }
                            initRunnerDemo={ this.initRunnerDemo }
                            filters={ filters }
                            options={ this.getTableOptions() }
                            data={ tableData }
                            showToast={ showToast }
                            isPermitEdit={ isPermitEdit }
                            getTags={ getTags }
                            apply={ this.applyFilters }
                            tags={ tags }
                            targetFilter={ this.state.targetFilter }
                            isOpenPopoverFilter={ this.state.isOpenPopoverFilter }
                            togglePopover={ this.togglePopover }
                        />
                        <ThemeModalCopy
                            show={ this.state.openModalCopy }
                            copyTheme={ this.copyTheme }
                            close={ this.closeModal }
                            theme={ this.state.theme }
                            reset={ this.resetModal }
                        />
                        <ThemeDetailsModal
                            show={ this.state.openModalDetails }
                            close={ this.closeModal }
                            theme={ this.state.detailsTheme }
                            reset={ this.resetModal }
                            refreshTable={ this.requestTrigger }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}
