import { ISource } from '/ai/models';

export interface ITopicSetApplianceModal {
    onSubmit: (data: any) => void,
    initFormValues: any,
    onClosed: () => void,
}

export interface IFormValues {
    column?: {
        id: string,
        index: string,
        label: string,
        value: string,
    },
    edit?: boolean,
    sentiment?: boolean,
    source?: ISource,
    topicSet?: any
    threshold: number,
    maxCategories: number,
    minCategories: number,
    multiTopic: boolean,
}

export interface IGetSources {
    body: {
        exclude_sources?: string[],
        limit: number,
        page: number,
        search: string,
    },
    params: {
        id: string,
    },
}

export enum ESourceField {
    topicsSet = 'topicsSet',
    sourcesList = 'sourcesList',
}
