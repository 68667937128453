import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import { HelperService } from "/services";
import { VocModal } from '/components';
import { LayoutEditor } from './components';

import './LayoutModal.scss';

export class LayoutModal extends Component {
    static getDerivedStateFromProps(props, state) {
        if (props.show && state.template === undefined) {
            return {
                ...state,
                template: props.layoutValue,
            };
        }

        return state;
    }

    state = this.getInitialState();

    getInitialState() {
        return {
            template: undefined,
            disabledSubmit: false,
            validationErrors: [],
        };
    }

    @autobind
    onClosed() {
        this.setState({ ...this.getInitialState() });
    }

    @autobind
    onTemplateChanged(val) {
        this.setState(prevState => ({
            prevState,
            template: val,
        }));
    }

    @autobind
    toggleDisabledSubmit(isDisabledSubmit) {
        this.setState(prevState => ({
            ...prevState,
            disabledSubmit: isDisabledSubmit,
        }));
    }

    @autobind
    htmlValidation(errors) {
        this.setState(prevState => ({
            ...prevState,
            validationErrors: errors,
        }));
    }

    @autobind
    getModalBody() {
        return (
            <div>
                <LayoutEditor
                    value={ this.state.template }
                    onChange={ this.onTemplateChanged }
                    disableSubmit={ this.toggleDisabledSubmit }
                    disabledLayoutTab={ this.state.disabledSubmit }
                    validationCallback={ this.htmlValidation }
                    assets={ this.props.assets }
                    getAssets={ this.props.getAssets }
                    uploadAsset={ this.props.uploadAsset }
                />
            </div>
        );
    }

    @autobind
    onSubmit() {
        this.props.applyLayoutChanges(HelperService.parseHtmlString(this.state.template, true));
    }

    @autobind
    getFooter() {
        const {
            validationErrors,
            template,
            disabledSubmit,
        } = this.state;

        return (
            <div>
                <div className={ `layouts-modal__validation__text` }>
                    { !!validationErrors.length
                        ? validationErrors[0].text
                        : null
                    }
                </div>
                <Button onClick={ this.props.close } color={ 'white' } outline>
                    Cancel
                </Button>
                <Button
                    disabled={ !template || disabledSubmit }
                    onClick={ this.onSubmit }
                    color="primary"
                >
                    Save
                </Button>
            </div>
        );
    }

    render() {
        const { show, close } = this.props;

        return (
            <VocModal
                toggle={ close }
                onClosed={ this.onClosed }
                modalClassName={ 'modal-scrollable layout-modal' }
                header={ 'Save Layout' }
                isOpen={ show }
                size={ 'lg' }
                footer={ this.getFooter() }
                zIndex={ 1030 }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}

LayoutModal.propTypes = {
    close: PropTypes.func,
    show: PropTypes.bool,
    assets: PropTypes.shape({
        loading: PropTypes.bool,
        files: PropTypes.array,
    }),
    getAssets: PropTypes.func,
    uploadAsset: PropTypes.func,
    layoutValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    applyLayoutChanges: PropTypes.func,
};
