export const AddQueryFormValidation = (values = {}) => {
    const errors = {};
    const errText = 'Field must not be empty';

    const {
        title_field,
        source_field,
        text_column_field,
        language_field,
    } = values;

    if (!(title_field && title_field.trim())) {
        errors.title_field = errText;
    }

    if (!source_field) {
        errors.source_field = errText;
    }

    if (!text_column_field) {
        errors.text_column_field = errText;
    }

    if (!language_field) {
        errors.language_field = errText;
    }

    return errors;
};
