import React, { Component } from 'react';
import cx from 'classnames';
import { autobind } from 'core-decorators';
import { TabPane, TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { TinyEditor, Editor } from '/components';

import './MarkupEditor.scss';

export class MarkupEditor extends Component {
    state = {
        activeTab: 1,
    };

    componentDidUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled) {
            this.setState({
                activeTab: 1,
            });
        }
    }

    @autobind
    togglePane(tab) {
        this.setState({
            activeTab: tab,
        });
    }

    render() {
        const {
            activeTab,
        } = this.state;
        const {
            value,
            disabled,
            onChange,
            onBlur,
            onFocus,
            assets,
            getAssets,
            uploadAsset,
            height,
            resizeCallback,
            baseHeight,
            additionalPlugin,
        } = this.props;

        return (
            <>
                {
                    !disabled
                    && <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={ `markup-editor__pane-header ${ activeTab === 1 ? 'active' : null }` }
                                onClick={ () => {
                                    this.togglePane(1);
                                } }
                            >
                                <FontAwesomeIcon className={ 'markup-editor__pane-icon' } icon={ 'columns' }/>
                                <span>Preview HTML</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={ `markup-editor__pane-header ${ activeTab === 2 ? 'active' : null }` }
                                onClick={ () => {
                                    this.togglePane(2);
                                } }
                            >
                                <FontAwesomeIcon className={ 'markup-editor__pane-icon' } icon={ 'code' }/>
                                <span>Edit HTML</span>
                            </NavLink>

                        </NavItem>
                    </Nav>
                }
                <TabContent activeTab={ activeTab }
                    className={ cx('markup-editor__tabs', { 'collapsed': disabled }) }>
                    <TabPane tabId={ 1 } className={ cx('markup-editor__pane', { 'disabled': disabled }) }>
                        {
                            activeTab === 1
                            && <TinyEditor
                                value={ value }
                                onChange={ onChange }
                                assets={ assets }
                                getAssets={ getAssets }
                                uploadAsset={ uploadAsset }
                                height={ height }
                                resizeCallback={ resizeCallback }
                                baseHeight={ baseHeight }
                                additionalPlugin={ additionalPlugin }
                            />
                        }
                    </TabPane>
                    <TabPane tabId={ 2 } className={ 'markup-editor__pane' }>
                        {
                            activeTab === 2
                            && <Editor
                                height={ height }
                                resizeCallback={ resizeCallback }
                                baseHeight={ baseHeight }
                                onChange={ onChange }
                                onBlur={ onBlur }
                                onFocus={ onFocus }
                                value={ value }
                                disabled={ disabled }
                            />
                        }
                    </TabPane>
                </TabContent>
            </>
        );
    }
}

MarkupEditor.defaultProps = {
    disabled: false,
};

MarkupEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
};
