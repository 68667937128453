import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import './QueryChartBarItem.scss';
import { TooltipWrapper } from '/components';

export const QueryChartBarItem = ({
    lemma,
    count,
    countLemmataAll,
    isInclude,
    isExclude,
    onWordClick,
}) => {
    const ProgressBar = () => {
        const progress = count / countLemmataAll * 100;
        const progressLemmata = progress < 10 ? progress.toFixed(2) : progress.toFixed(0);

        return (
            <div className="query-details-chart__item-progress">
                <TooltipWrapper value={ lemma }>
                    <div className="query-details-chart__item-progress__title">{ lemma }</div>
                </TooltipWrapper>
                <div className="query-details-chart__item-progress__value">{ progressLemmata }% ({ count })</div>
                <div className="query-details-chart__item-progress__bar" style={{ width: `${ progress }%` }}>
                    <div className="query-details-chart__item-progress__bar__percent"/>
                </div>
            </div>
        );
    };

    return (
        <div className="query-details-chart__item">
            {
                ProgressBar()
            }
            <div className="btns-wrapper">
                <button className={ cx('btn', { active: isInclude }) }
                    onClick={ () => onWordClick(lemma, 'lemmaToInclude', 'lemmaToExclude') }>
                    <FontAwesomeIcon className="icon" icon={ 'eye' }/>
                    <span>Include</span>
                </button>
                <button className={ cx('btn', { active: isExclude }) }
                    onClick={ () => onWordClick(lemma, 'lemmaToExclude', 'lemmaToInclude') }>
                    <FontAwesomeIcon className="icon" icon={ 'eye-slash' }/>
                    <span>Exclude</span>
                </button>
            </div>
        </div>
    );
};

QueryChartBarItem.defaultProps = {
    isInclude: false,
    isExclude: false,
};
