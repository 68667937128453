import React from 'react';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';

import { GadgetService } from '/visual/scenes/Dashboard/components';
import { GadgetActionMenu } from '/visual/scenes/Dashboard/components/GadgetActionMenu';
import { Title } from '/visual/scenes/Dashboard/components/Gadget/components';
import { ISuperChartHeader } from './models';

import styles from './style.module.scss';

export const SuperChartHeader = ({
    isEditable,
    gadgetData,
    canTitleEdit,
    rights,
    onCut,
    onCopy,
    onDelete,
    onMoveToSave,
    onEdit,
    onSync,
    onMoreInfo,
}: ISuperChartHeader) => {
    const { id, title, contentSettings } = gadgetData;

    return (
        <div className={ cx(styles.superChartHeader, { [styles.dragCursor]: isEditable, 'enabled-drag': !isMobile }) }>
            <Title
                id={ id }
                title={ title }
                canEdit={ canTitleEdit }
                drilledDown={ GadgetService.isDrillDownDashboardExist(gadgetData) && contentSettings.drillDown }
            />
            <div className={ cx('disabled-drag', styles.gadgetActionMenu) }>
                <GadgetActionMenu
                    gadgetId={ id }
                    isEditable={ isEditable }
                    gadgetDescription={ contentSettings.gadgetDescription }
                    rights={ rights }
                    onCut={ onCut }
                    onSync={ onSync }
                    onCopy={ onCopy }
                    onEdit={ onEdit }
                    onMoreInfo={ onMoreInfo }
                    onDelete={ onDelete }
                    onMoveToSave={ onMoveToSave }
                />
            </div>
        </div>
    );
};
