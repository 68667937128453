import React, { useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { CollapseSection, WordCloudChartD3, PopoverChartTooltip } from '/components';
import { PopoverRangeSlider, useDrillDown, useHover } from '/visual/scenes/Dashboard/components';
import { setContentSettings } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { d3ColorSchema, charts } from '/visual/scenes/Dashboard/components/Gadget/contstants';
import { FooterInfo } from '/visual/scenes/Dashboard/components/Gadget/components';
import { HelperService, useResizeObserver } from '/services';

import { IChartData, excludeLemma, wordDataType, IResizeObserverDimensions } from '../../models';
import commonStyles from '../BaseChart/style.module.scss';

import styles from './style.module.scss';

// with open LemmasExclude field ( field for two line )
const { sizeGadgetFooter } = charts.lemmata_word_cloud;

const changeRangeTimeout = HelperService.debounce(({ callback }: any) => {
    callback();
}, 200, false);

export const WordCloudChart = ({ chartGadgetData }: { chartGadgetData: IChartData }) => {
    const { t } = useTranslation();
    const { chartData, gadgetData } = chartGadgetData;
    const { contentSettings } = gadgetData;
    const defaultRageSize: number = contentSettings?.maxCount > chartData?.words?.length || !contentSettings?.maxCount
        ? chartData?.words?.length
        : contentSettings?.maxCount;

    const [ rangeSize, setRangeSize ] = useState<number>(defaultRageSize);
    const [ excludeLemmas, setExcludeLemmas ] = useState<excludeLemma[]>(contentSettings?.exclude || []);
    const [ collapse, setCollapse ] = useState(false);

    const tooltipRef = createRef<HTMLDivElement>();
    const { getDrillDownDashboard } = useDrillDown({ gadgetData });
    const { isHovered, hoverRef, mouseOver, mouseOut } = useHover<HTMLDivElement>(chartData?.words?.length < 2);
    const dimensions: IResizeObserverDimensions | null = useResizeObserver(hoverRef);

    const dispatch = useDispatch();

    const maxValue: number = chartData?.words?.length < 2
        ? chartData?.words?.length + 1
        : chartData?.words?.length;

    //need to exclude only the number of lemmas present in the dataset
    let countLemmaExclude = 0;
    let wordsFormatter = chartData?.words
        .map((wordData: wordDataType) => ({
            text: wordData._id,
            count: wordData.count,
        }))
        .filter((wordData: { text: string | number }) => {
                const hasLemmaExclude = excludeLemmas?.some(excludeLemma => excludeLemma.word === wordData.text)

                hasLemmaExclude && countLemmaExclude++
                return !hasLemmaExclude
        })

    const wordsSpliceValue = (chartData?.words?.length - countLemmaExclude) - rangeSize;
    wordsFormatter = wordsFormatter.splice(wordsSpliceValue > 0 ? wordsSpliceValue : 0);

    const onChangeRangeSize = (value: number) => {
        setRangeSize(value);

        changeRangeTimeout({
            callback: () => dispatch(setContentSettings(gadgetData.id, {
                ...contentSettings,
                maxCount: value,
            })),
        });
    };

    const onCollapseChanged = () => {
        setCollapse(!collapse);
    };

    const onWordClick = ({ text }: { text: string | number }) => {
        if(collapse) {
            const findExcludeLemmas = excludeLemmas.find(excludeLemma => excludeLemma.value === text);
            const newExcludeLemmas = findExcludeLemmas
                ? [ ...excludeLemmas ].filter(excludeLemma => excludeLemma.value !== text)
                : [
                    ...excludeLemmas,
                    {
                        value: text,
                        word: text,
                    },
                ];

            setExcludeLemmas(newExcludeLemmas);
            dispatch(setContentSettings(gadgetData.id, {
                ...contentSettings,
                exclude: newExcludeLemmas,
            }));
        } else {
            getDrillDownDashboard({ group: { id: text } });
        }
    };

    const getFontSizeProps = () => {
        let maxFontSize = 50;
        let minFontSize;

        if(dimensions?.height <= 510 && dimensions?.width <= 360) {
            maxFontSize = 32;
            minFontSize = 12;
        }

        return {
            maxFontSize,
            minFontSize,
        };
    };

    return (
        <div
            className={ cx(commonStyles.chartWrapper, styles.wordCloudChartWrapper) }
            ref={ hoverRef }
            onMouseOver={ mouseOver }
            onMouseLeave={ mouseOut }
        >
            {
                dimensions
                    ? <WordCloudChartD3
                        onWordOver
                        words={ wordsFormatter }
                        animation={ false }
                        colors={ d3ColorSchema }
                        toolTipRef={ tooltipRef }
                        onWordClick={ onWordClick }
                        height={ dimensions?.height - sizeGadgetFooter }
                        width={ dimensions?.width }
                        { ...getFontSizeProps() }
                    />
                    : null
            }


            <CollapseSection
                className={ styles.lemmasExcludeCard }
                onCollapseChanged={ onCollapseChanged }
                title={ t('lemmasToExclude') }
                collapse={ collapse }
            >
                <>
                    {
                        excludeLemmas?.map(excludeLemma => (
                            <div
                                className={ styles.lemmasExcludeItem }
                                key={ excludeLemma.value }
                            >
                                <span>{ excludeLemma.word }</span>
                                <button
                                    onClick={ () => onWordClick({ text: excludeLemma.word }) }
                                    className={ styles.closeButton }
                                >
                                    +
                                </button>
                            </div>
                        ))
                    }
                </>
            </CollapseSection>

            <FooterInfo records={ chartData.count } lastHealthCheckTime={ gadgetData.lastHealthCheckTime } />

            <PopoverRangeSlider
                hovered={ isHovered }
                sliderData={{
                    min: 1,
                    max: maxValue,
                    value: [ rangeSize ],
                    disableSlider: chartData?.words?.length < 2,
                }}
                chartContainerRef={ hoverRef }
                onChangeSlider={ onChangeRangeSize }
            />

            <PopoverChartTooltip ref={ tooltipRef } />
        </div>
    );
};
