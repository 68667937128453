import { createSelector } from 'reselect';

import { VocAIServices } from '../services';
import { IAIReducer } from '/ai/modules/models';
import { ISource } from '/ai/models';

const commonSelector = (state: { VocAi: { common: IAIReducer; }; }) => state.VocAi.common;

export const _getTopicSets = createSelector([ commonSelector ], ({ topicSets }) => ({
    ...topicSets,
    data: topicSets.data?.map(({
        id, title, languages,
    }) => ({
        id,
        title,
        label: title,
        value: id,
        categoriesCount: VocAIServices.getCategoriesCount(languages),
    })),
}));

export const _getSourcesList = createSelector([ commonSelector ], ({ sources }) => ({
    ...sources,
    data: sources.data?.map(({
        id, title, attributes, type, state, channel,
    }) => ({
        labelStatusClass: state?.toLowerCase(),
        state: state?.toLowerCase(),
        channel: (channel !== 'upload' && channel !== 'virtual') ? channel.toUpperCase() : null,
        type: type.toUpperCase(),
        id,
        title,
        attributes,
        label: title,
        value: id,
    })),
}));

export const _getSelectedSourceColumns = (_: any, source?: ISource) => source?.attributes
    .filter(attribute => attribute.type === 'TEXT' || attribute.type === 'freetext')
    .map(({ id, name, index }) => ({
        id,
        index,
        label: name,
        value: id,
    }));

export const _getCreateTopicSetApplianceModalState = createSelector([ commonSelector ],
    ({ topicSetApplianceModalOpen }) => topicSetApplianceModalOpen,
);
export const _getCreateTopicSetApplianceModalLoading = createSelector([ commonSelector ],
    ({ topicSetApplianceModalLoading }) => topicSetApplianceModalLoading,
);
