import React, { useState } from 'react';
import { Button } from "reactstrap";
import { SketchPicker } from 'react-color';
import cx from "classnames";
import { useTranslation } from 'react-i18next';

import { RangeSlider } from "/visual/scenes/Dashboard/components";
import { IColorData, IColorsRequest, ICustomColorSetProp } from "./models";

import styles from './style.module.scss';
import { HelperService } from "/services";

export const CustomColorSet = ({ colorsData, onChange }: ICustomColorSetProp) => {
    const { t } = useTranslation();
    const [ openColorPicker, setOpenColorPicker ] = useState(false);
    const [ colorPickerData, setColorPickerData ] = useState({
        color: '',
        brighten: 50,
        id: 0,
    });

    const colorsDataFormat = {
        ...colorsData,
        other: colorsData.other.map((colorData: IColorData, index: number) => ({
            ...colorData,
            id: index,
        })),
    };

    const sendData = (data: IColorsRequest) => {
        const formatData = {
            ...data,
            other: data.other.map(({ id, ...rest }) => ({ ...rest })),
        };

        onChange({ customColorSet: JSON.stringify(formatData) });
        setOpenColorPicker(false);
    };

    const onSelectColor = (data: IColorData) => {
        const formatData = {
            ...colorsDataFormat,
            theOne: data.id,
        };

        sendData(formatData);
    };

    const onAddColor = (data: IColorData) => {
        const formatData = {
            theOne: data.id,
            other: [
                ...colorsDataFormat.other,
                data,
            ],
        };

        sendData(formatData);
    };

    const onDeleteColor = (data: IColorData) => {
        const theOne = data.id! > colorsDataFormat.theOne
            ? colorsDataFormat.theOne
            : colorsDataFormat.theOne - 1;

        const formatData = {
            theOne,
            other: colorsDataFormat.other.filter((colorData: IColorData) => colorData.id !== data.id),
        };

        sendData(formatData);
    };

    const getColorBlock = (colorData: IColorData, key: number | string) => {
        const colors = colorData.colors
            ? colorData.colors
            : HelperService.getColorGradation(colorData);

        return (
            <div
                className={ cx(styles.colorBlocks, { [styles.active]: colorData.id === colorsDataFormat.theOne }) }
                key={ key }
            >
                {
                    colors.map((color, inx) => (
                        <div
                            className={ styles.colorBlock }
                            key={ inx }
                            onClick={ () => onSelectColor(colorData) }
                            style={{ background: color }}
                        />
                    ))
                }
                {
                    key > 25 && <div className={ styles.closeButton } onClick={ () => onDeleteColor(colorData) }>x</div>
                }
            </div>
        );
    };

    const colorPicker = () => (
        <>
            {
                !openColorPicker
                    ? <Button
                        className={ styles.addColorButton }
                        color="primary"
                        onClick={ () => setOpenColorPicker(true) }
                    >
                        { t('add') }
                    </Button>
                    : <div className={ styles.colorPickerWindow }>
                        {
                            getColorBlock(colorPickerData, 'colorPickerPreview')
                        }
                        <div className={ styles.sketchPickerWrapper }>
                            <SketchPicker
                                className={ styles.sketchPicker }
                                onChange={ e => setColorPickerData({
                                    ...colorPickerData,
                                    color: e.hex,
                                }) }
                                presetColors={ [] }
                                color={ colorPickerData.color }
                                disableAlpha
                            />
                            <div className={ styles.brightSlider }>
                                <RangeSlider
                                    step={ 1 }
                                    min={ 1 }
                                    max={ 50 }
                                    values={ [ colorPickerData.brighten ] }
                                    onChange={ (e: number | number[]) => setColorPickerData({
                                        ...colorPickerData,
                                        brighten: Number(e),
                                    }) }
                                />
                                <p>{ colorPickerData.brighten }</p>
                            </div>
                            <div className={ styles.controls }>
                                <Button
                                    className={ styles.addColorButton }
                                    color="white"
                                    onClick={ () => setOpenColorPicker(false) }
                                    outline
                                >
                                    { t('cancel') }
                                </Button>
                                <Button
                                    className={ styles.addColorButton }
                                    color="primary"
                                    onClick={ () => onAddColor({
                                        ...colorPickerData,
                                        id: colorsDataFormat?.other?.length,
                                    }) }
                                >
                                    { t('ok') }
                                </Button>
                            </div>
                        </div>
                    </div>
            }
        </>
    );

    return (
        <div className={ styles.customColorSet }>
            {
                colorsDataFormat?.other?.map((colorData: IColorData, inx: number) => getColorBlock(colorData, inx))
            }
            { colorPicker() }
        </div>
    );
};
