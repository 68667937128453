import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { reduxForm, Field } from 'redux-form';
import { Button, Row, Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocModal, VocFormTextField } from '/components';
import { AddToBlackListModalFormValidation } from './AddToBlackListModalFormValidation';

import './AddToBlackListModal.scss';

@reduxForm({
    form: 'CustomerAddToBlackListForm',
    enableReinitialize: true,
    validate: AddToBlackListModalFormValidation,
})
export class AddToBlackListModal extends Component {
    state = {
        isCreate: false,
    };

    @autobind
    submitHandler() {
        const { customerId: id, email, phone } = this.props.formValues;

        this.props.onSubmit({ id, email, phone })
            .promise.catch(err => {
                err.status === 404 && this.setState({
                    isCreate: true,
                });
            });
    }

    @autobind
    createCustomer() {
        const { customerId: external_id } = this.props.formValues;

        this.props.createCustomer({ state: 16, external_id })
            .promise.then(() => {
                this.setState({
                    isCreate: false,
                });

                this.submitHandler();
            });
    }

    @autobind
    getModalFooter() {
        const { submitted, valid } = this.props;

        return (
            <>
                <Button onClick={ this.closeModal } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel </span>
                </Button>
                <Button onClick={ this.submitHandler }
                    disabled={ submitted || !valid }
                    color="primary">
                    <FontAwesomeIcon icon="check"/>
                    <span> Add </span>
                </Button>
            </>
        );
    }

    @autobind
    getModalBody() {
        const { submitted, valid } = this.props;

        return (
            <>
                <Row className={ 'add-to-blacklist-form_row' }>
                    <Col md={ 3 }>
                        <Label htmlFor={ 'customerId' }>
                            Customer ID
                            <span className='star'>&nbsp;*</span>
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            type={ 'text' }
                            placeholder={ 'Customer external id' }
                            name={ 'customerId' }
                            className={ 'general-section__input' }
                            component={ VocFormTextField }
                            required
                        />
                    </Col>
                </Row>
                <Row className={ 'add-to-blacklist-form_row' }>
                    <Col md={ 3 }>
                        <Label htmlFor={ 'email' }>
                            Email
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            type={ 'text' }
                            placeholder={ 'Email' }
                            name={ 'email' }
                            className={ 'general-section__input' }
                            component={ VocFormTextField }
                            required
                        />
                    </Col>
                </Row>
                <Row className={ 'add-to-blacklist-form_row' }>
                    <Col md={ 3 }>
                        <Label htmlFor={ 'phone' }>
                            Phone
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            type={ 'text' }
                            placeholder={ 'Phone' }
                            name={ 'phone' }
                            className={ 'general-section__input' }
                            component={ VocFormTextField }
                            required
                        />
                    </Col>
                </Row>

                {
                    this.state.isCreate ? (
                        <Row>
                            <Col md={ 12 }>
                                <div className={ 'blacklist-form__create' }>
                                    <span> Customer with this external id does not exists. Do you want create? </span>
                                    <Button disabled={ submitted || !valid } onClick={ this.createCustomer }
                                        color="primary">
                                        <span> Create </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    ) : null
                }

            </>
        );
    }

    @autobind
    closeModal() {
        this.props.closeModal();

        this.setState({
            isCreate: false,
        });
    }

    render() {
        return (
            <VocModal
                isOpen={ this.props.show }
                toggle={ this.closeModal }
                header={ 'Add Customer to Blacklist' }
                footer={ this.getModalFooter() }
                modalClassName={ 'modal-edit_customers modal-scrollable' }
                size={ 'md' }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}
