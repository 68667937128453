import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { autobind } from 'core-decorators';
import { Row, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { StatisticFilters } from '../../components/StatisticFilters';
import { StatisticList } from './components/StatisticList';
import { Preloader, VocCleaupHOC } from '/components';
import { possibleRangesSelector, dateRangesSelector, activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import {
    questionsStatisticsFiltersSelector,
    questionsStatisticsResponsesSelector,
} from './modules/questionsStatistics.selectors';
import { updateFilters, getStatistic, cleanup } from './modules/questionsStatistics.modules';
import { HelperService, DateRangesService } from '/services';

const mapStateToProps = state => ({
    activeSurvey: activeSurveySelector(state),
    possibleRanges: possibleRangesSelector(state),
    dateRanges: dateRangesSelector(state),
    filters: questionsStatisticsFiltersSelector(state),
    statistics: questionsStatisticsResponsesSelector(state),
});

const mapDispatchToProps = {
    updateFilters,
    getStatistic,
    cleanup,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class QuestionsStatistics extends Component {
    state = {
        dateRange: 'yesterday',
    };

    componentDidMount() {
        const { channel } = this.props.activeSurvey;

        channel && this.getStatistic();
    }

    componentDidUpdate(prevProps) {
        const { channel } = this.props.activeSurvey;

        if (channel !== prevProps.activeSurvey.channel) {
            this.getStatistic();
        }
    }

    @autobind
    getStatistic(filters = this.props.filters) {
        const { channel } = this.props.activeSurvey;

        const { surveyGroup } = this.props.match.params;

        return this.props.getStatistic(surveyGroup, { ...filters, survey__channel__name: channel });
    }

    @autobind
    applyFilters() {
        this.getStatistic(this.props.filters);
    }

    @autobind
    onChangePossibleRanges({ value }) {
        this.props.updateFilters({ range: value });
    }

    @autobind
    onChangeDateRanges({ value }) {
        const dateObject = DateRangesService(value);
        const dates = {
            from: dateObject.start_time,
            to: dateObject.end_time,
        };

        this.setState({
            dateRange: value,
        });

        this.dateRangeHandler(dates);
    }

    @autobind
    dateRangeHandler({ from, to }) {
        const dates = {
            start_time: from ? HelperService.formatDate(from, 'YYYY-MM-DD') : from,
            end_time: to ? HelperService.formatDate(to, 'YYYY-MM-DD') : to,
        };

        this.props.updateFilters(dates);
    }

    renderStatisticList() {
        const {
            statistics,
            activeSurvey: {
                channel,
            },
        } = this.props;

        return (
            channel ? <StatisticList channel={ channel } values={ statistics.values }/>
                : <Alert color={ 'info' } className={ 'text-center' }>
                    To see the statistics, please activate or select an activated channel first
                </Alert>
        );
    }

    render() {
        const {
            possibleRanges,
            dateRanges,
            filters,
            statistics,
        } = this.props;

        return (
            <DocumentTitle title={ 'Feedback :: Questions Statistics' }>
                <Row>
                    <Col md={ 4 }>
                        <StatisticFilters
                            possibleRanges={ possibleRanges }
                            statisticDateRanges={ dateRanges }
                            filters={ filters }
                            dateRange={ this.state.dateRange }
                            onChangePossibleRanges={ this.onChangePossibleRanges }
                            onChangeDateRanges={ this.onChangeDateRanges }
                            dateRangeHandler={ this.dateRangeHandler }
                            applyFilters={ this.applyFilters }
                            loading={ statistics.loading }
                            showDateRangeFilter={ false }
                        />
                    </Col>
                    <Col md={ 8 }>
                        {
                            statistics.loading
                                ? <Preloader/>
                                : this.renderStatisticList()
                        }
                    </Col>
                </Row>
            </DocumentTitle>
        );
    }
}
