export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const CLOSE_CONFIRM = 'CLOSE_CONFIRM';
export const ACCEPT_CONFIRM = 'ACCEPT_CONFIRM';
export const CHANGE_DIRTY_STATUS = 'CHANGE_DIRTY_STATUS';

const initialState = {
    successCallback: function() {},
    show: false,
    dirty: false,
    checkDirty: false,
    header: null,
    content: null,
};

export function ConfirmReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SHOW_CONFIRM: {
            return {
                ...state,
                show: true,
                ...action.data,
            };
        }

        case CLOSE_CONFIRM: {
            return {
                ...initialState,
                dirty: state.dirty,
            };
        }

        case ACCEPT_CONFIRM: {
            return {
                ...initialState,
            };
        }

        case CHANGE_DIRTY_STATUS: {
            return {
                ...state,
                ...action.data,
            };
        }

        default: {
            return state;
        }
    }
}

export function showConfirm(data) {
    return {
        type: SHOW_CONFIRM,
        data,
    };
}

export function closeConfirm() {
    return {
        type: CLOSE_CONFIRM,
    };
}

export function acceptConfirm() {
    return {
        type: ACCEPT_CONFIRM,
    };
}

export function dirtyStatusConfirm(data) {
    return {
        type: CHANGE_DIRTY_STATUS,
        data,
    };
}
