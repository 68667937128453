import { HelperService } from '/services';

export class ResponseMessageConverter {
    static getErrorMessage(response) {
        let message = response.message ? response.message : response;

        if (HelperService.isObjectValue(message)) {
            message = Object.keys(message).map(key => HelperService.isObjectValue(message[key])
                ? [ JSON.stringify(message[key]) ]
                : message[key],
            );
        } else if (Array.isArray(message)) {
            const message2 = [];

            message.map(el => typeof el === 'object'
                ? Object.keys(el).forEach(el2 =>
                    message2.push(el[el2]),
                )
                : message2.push(el),
            );
            message = [ ...message2 ];
        }

        return (
            typeof message === 'string' || typeof message[0] === 'string'
            || message.every(message => typeof message === 'string')
            || typeof message[0][0] === 'string'
        ) ? message : '';
    }

    static getSuccessMessage(response) {
        const message = response[0] ? response[0] : response.message;

        return typeof message === 'string' ? message : '';
    }
}
