import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import { ApplianceService } from '../../services/ApplianceService';
import { TooltipWrapper, VocSelect } from '/components';

import './ApplianceSegmentHeader.scss';

@withTranslation()
export class ApplianceSegmentHeader extends Component {
    getDeleteContent() {
        const { showConfirmDelete, tooogleConfirmDelete, onDeleteClick, t } = this.props;

        if (showConfirmDelete) {
            return (
                <div className={ 'delete-content' }>
                    <strong>
                        { t('warningText.areYouWantDeleteLabel') }
                        &nbsp;
                    </strong>
                    <ButtonGroup>
                        <ButtonGroup>
                            <TooltipWrapper value={ t('yes') } force>
                                <Button
                                    className="appliance-segment-header__button"
                                    color="danger"
                                    onClick={ onDeleteClick }
                                >
                                    <FontAwesomeIcon icon='check' />
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper value={ t('no') } force>
                                <Button
                                    className="appliance-segment-header__button"
                                    color={ 'white' }
                                    outline
                                    onClick={ tooogleConfirmDelete }
                                >
                                    <FontAwesomeIcon icon='times' />
                                </Button>
                            </TooltipWrapper>
                        </ButtonGroup>
                    </ButtonGroup>
                </div>
            );
        }

        return (
            <TooltipWrapper value={ t('deleteLabel') } force>
                <Button
                    color="danger"
                    onClick={ tooogleConfirmDelete }
                >
                    <FontAwesomeIcon icon='trash' />
                </Button>
            </TooltipWrapper>
        );
    }

    render() {
        const {
            open, toogleOpenSegment, segmentName,
            onChangeSegmentName, segmentOptions,
            dataType,
            t,
        } = this.props;

        const selectValue = ApplianceService.formatToSelectValue(segmentName);

        return (
            <div className={ 'segment-group-header' }>
                <div className={ 'segment-group-header-left' }>
                    <TooltipWrapper value={ t(open ? 'collapseLabel' : 'expandLabel') } force>
                        <Button color="info" onClick={ toogleOpenSegment }>
                            <FontAwesomeIcon icon={ `arrow-${ open ? 'down' : 'right' }` }/>
                        </Button>
                    </TooltipWrapper>

                    <div className="appliance-name-form-group">
                        <label onClick={ toogleOpenSegment } className="control-label">
                            { t('outputLabel') }
                        </label>
                        <span className='star'> * </span>
                        <VocSelect
                            className={
                                cx('appliance-attribute-select',
                                    { 'appliance-attribute-select--invalid': this.props.isInvalid })
                            }
                            value={ selectValue }
                            options={ segmentOptions }
                            onChange={ onChangeSegmentName }
                            placeholder={ t('placeholders.choose') }
                            isCreatable={ dataType.value !== 'NPS_SEGMENT' }
                            isClearable
                            withTooltip
                        />
                    </div>
                </div>
                { this.getDeleteContent() }
            </div>
        );
    }
}

ApplianceSegmentHeader.propTypes = {
    toogleOpenSegment: PropTypes.func,
    onChangeSegmentName: PropTypes.func,
    open: PropTypes.bool,
    tooogleConfirmDelete: PropTypes.func,
    onDeleteClick: PropTypes.func,
    segmentName: PropTypes.string,
};
