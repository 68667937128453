import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { combineReducers } from 'redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UploadNewFile } from './scenes/UploadNewFile';
import { UploadSocialNetwork } from './scenes/UploadSocialNetwork';
import { PrivateRoute } from '/components/PrivateRoute';
import { Navigation } from '/components';
import { reducer as parserReducer } from './scenes/UploadSocialNetwork/modules';
import { reducer as uploadNewFileReducer } from './scenes/UploadNewFile/modules';
import { isSupportUserSelector } from '/modules/selectors';

export const UploadData = ({ match }) => {
    const { t } = useTranslation();
    const isSupportUser = useSelector(isSupportUserSelector);

    const paths = {
        newUpload: `${match.url}/upload-new-file`,
        newSocialUpload: `${match.url}/upload-social-network`,
        appendToUpload: `${match.url}/upload-new-file/:id(\\d+)`,
    };

    const settingsChildRoute = [
        {
            title: t('uploadNewFile'),
            link: paths.newUpload,
            isReactLink: true,
        },
        {
            title: t('uploadSocialNetworkData'),
            link: paths.newSocialUpload,
            isReactLink: true,
        },
    ];

    const getRouts = () => (
        <Switch>
            <Route
                exact
                path={ [ paths.newUpload, paths.appendToUpload ] }
                component={ UploadNewFile }
            />
            <PrivateRoute
                exact
                path={ paths.newSocialUpload }
                component={ UploadSocialNetwork }
                hasAccess={ isSupportUser }
            />
            <Redirect to={ paths.newUpload }/>
        </Switch>
    );

    const id = useRouteMatch(paths.appendToUpload)?.params?.id;

    return (
        <>
            {
                isSupportUser && !id
                    ? <div className='page__wrapper'>
                        <Navigation
                            navigationInfo={ settingsChildRoute }
                            className='export-navigation'
                        />
                    </div> : null
            }
            {
                getRouts()
            }
        </>
    );
};


export const UploadDataReducer = combineReducers({
    uploadNewFile: uploadNewFileReducer,
    uploadSocialNetwork: parserReducer,
});
