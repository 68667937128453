import { CALL_API } from 'store/middleware/api';
import { GET_SOURCE_BY_ID_REQUEST, GET_SOURCE_BY_ID_SUCCESS, GET_SOURCE_BY_ID_ERROR } from '/modules';

export const UPDATE_SEGMENTATOR_REQUEST = 'UPDATE_SEGMENTATOR_REQUEST';
export const UPDATE_SEGMENTATOR_SUCCESS = 'UPDATE_SEGMENTATOR_SUCCESS';
export const UPDATE_SEGMENTATOR_ERROR = 'UPDATE_SEGMENTATOR_ERROR';

export const SHOW_APPLIANCE_MODAL = 'SHOW_APPLIANCE_MODAL';
export const HIDE_APPLIANCE_MODAL = 'HIDE_APPLIANCE_MODAL';

export const CREATE_SEGMENTATOR_REQUEST = 'CREATE_SEGMENTATOR_REQUEST';
export const CREATE_SEGMENTATOR_SUCCESS = 'CREATE_SEGMENTATOR_SUCCESS';
export const CREATE_SEGMENTATOR_ERROR = 'CREATE_SEGMENTATOR_ERROR';

export const CLEANUP_SEGMENTATOR_STORE = 'CLEANUP_SEGMENTATOR_STORE';

export const getInitialState = () => ({
    id: null,
    showApplianceModal: false,
    sourceId: null,
    mode: null,
    upsentSource: null,
    source: {},
    applianceId: null,
    segmentType: null,
    virtualFieldName: null,
    segments: [],
    applianceModalLoading: true,
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case UPDATE_SEGMENTATOR_REQUEST:
        case CREATE_SEGMENTATOR_REQUEST: {
            return {
                ...state,
                applianceModalLoading: true,
            };
        }

        case UPDATE_SEGMENTATOR_SUCCESS:
        case UPDATE_SEGMENTATOR_ERROR: {
            return {
                ...state,
                applianceModalLoading: false,
            };
        }

        case SHOW_APPLIANCE_MODAL: {
            let segments = [];

            if (action.data.segments) {
                segments = JSON.parse(JSON.stringify(action.data.segments));
            }

            return {
                ...state,
                showApplianceModal: true,
                ...action.data,
                segments,
            };
        }

        case HIDE_APPLIANCE_MODAL: {
            return {
                ...getInitialState(),
            };
        }

        case GET_SOURCE_BY_ID_REQUEST: {
            return {
                ...state,
                applianceModalLoading: true,
            };
        }

        case GET_SOURCE_BY_ID_SUCCESS: {
            return {
                ...state,
                applianceModalLoading: false,
                source: action.response,
            };
        }

        case CREATE_SEGMENTATOR_SUCCESS:
        case CREATE_SEGMENTATOR_ERROR: {
            return {
                ...state,
                applianceModalLoading: false,
            };
        }

        case GET_SOURCE_BY_ID_ERROR: {
            return {
                ...state,
                upsentSource: true,
                applianceModalLoading: false,
            };
        }

        case CLEANUP_SEGMENTATOR_STORE: {
            return getInitialState();
        }

        default: {
            return state;
        }
    }
}

export function cleanup() {
    return {
        type: CLEANUP_SEGMENTATOR_STORE,
    };
}

export function showApplianceModal(data) {
    return {
        type: SHOW_APPLIANCE_MODAL,
        data,
    };
}

export function hideApplianceModal() {
    return {
        type: HIDE_APPLIANCE_MODAL,
    };
}

export function createSegmentator(body) {
    return {
        [CALL_API]: {
            endpoint: `ENDPOINT_VOC_STORE_CREATE_SEGMENTATOR`,
            types: [ CREATE_SEGMENTATOR_REQUEST, CREATE_SEGMENTATOR_SUCCESS, CREATE_SEGMENTATOR_ERROR ],
            method: 'POST',
            logger: true,
            loggerMessages: {
                success: 'Recoder created successfully',
                error: 'There was server error in creating recoder.',
            },
            body,
        },
    };
}

export function updateSegmentator({ id, body }) {
    return {
        [CALL_API]: {
            endpoint: `ENDPOINT_VOC_STORE_UPDATE_SEGMENTATOR`,
            urlParams: { id },
            types: [ UPDATE_SEGMENTATOR_REQUEST, UPDATE_SEGMENTATOR_SUCCESS, UPDATE_SEGMENTATOR_ERROR ],
            method: 'PUT',
            logger: true,
            loggerMessages: {
                success: 'Recoder was updated successfully',
                error: 'There was server error in updating recoder.',
            },
            body,
        },
    };
}
