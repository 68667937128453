import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { GadgetService } from '/visual/scenes/Dashboard/components';
import {
    persistSelectedGroupFilter, toggleModal,
    DashboardModalType, updateGadgetsListForActiveDashboard,
} from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { _getDashboardData, isDashboardChangedSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { createDrillDownDashboard } from '/visual/modules/VocVisual.modules';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { useVisualNavigation } from '/visual/customHooks';
import { showToast } from '/components';

import { IDrillDownSelection, IGadgetData } from '../../models';

export const useDrillDown = ({ gadgetData }: { gadgetData: IGadgetData }) => {
    const { t } = useTranslation();
    const [ drillDownSelection, setDrillDownSelection ] = useState<IDrillDownSelection | null>(null);
    const [ updateWithoutReload, setUpdateWithoutReload ] = useState(false);
    const dashboard = useSelector(_getDashboardData);
    const isDashboardChanged = useSelector(isDashboardChangedSelector);
    const { goToDashboard } = useVisualNavigation();
    const dispatch = useDispatch();

    const getDrillDownTitle = () => t('drillDownTitle', {
        dashboardTitle: dashboard.title,
        gadgetTitle: gadgetData.title,
    });

    // persist drill down
    const persistDrilledDownGadget = async (data: IDrillDownSelection) => {
        const persistValues = GadgetService.getDrillDownFilterValue({
            group: data.group.id,
            superGroup: GadgetService.getSuperGroupByChartType({
                superGroup: data.superGroup,
                chartType: gadgetData.contentSettings.chartType,
                level: data.level,
            }),
            func: gadgetData.contentSettings.function,
            groupBy: gadgetData.dataSettings.groupBy,
            secondGroupBy: gadgetData.dataSettings.secondGroupBy,
        });

        await dispatch(persistSelectedGroupFilter(
            gadgetData.id,
            { values: persistValues },
        )).promise;
    };

    const updateAllGadgets = async () => {
        dispatch(updateGadgetsListForActiveDashboard());

        setUpdateWithoutReload(false);
    };

    // show confirmation to create a new drill-down dashboard
    const handleCreateDrillDownDashboard = async () => {
        const newDashboard = await dispatch(createDrillDownDashboard({
            byGadgetId: gadgetData.id,
            dashboardTitle: getDrillDownTitle(),
        })).promise;

        // navigate to the created nested dashboard without routing confirmation
        // cause the confirmation was given before creation
        goToDashboard(newDashboard.id, null, true);
    };

    const confirmCreatingDrillDownDashboard = () => {
        dispatch(toggleModal({
            type: DashboardModalType.drillDownGadget,
            title: getDrillDownTitle(),
            gadgetId: gadgetData.id,
        }));
    };

    const navigateToDrillDownDashboard = async (data: IDrillDownSelection, withConfirmation?: boolean) => {
        await persistDrilledDownGadget(data);

        if (GadgetService.isDrillDownDashboardExist(gadgetData)) {
            // navigate to the existed nested dashboard without routing confirmation
            // cause the confirmation was given before creation
            updateWithoutReload
                ? await updateAllGadgets()
                : goToDashboard(gadgetData.childDashboardId as string, null, true);
        } else {
            // show confirm or just create dashboard (confirmation was made before inside 'checkEnabledForDrillDown')
            withConfirmation
                ? confirmCreatingDrillDownDashboard()
                : await handleCreateDrillDownDashboard();
        }
    };

    const checkEnabledForDrillDown = async (data: IDrillDownSelection) => {
        const { errorMessageTransKey, isEnabled } = GadgetService.isDrillDownEnable({ gadget: gadgetData });

        if (isEnabled) {
            if (isDashboardChanged) {
                const warning = t('warningText.unsavedChanges');
                const actionText = `${ t(updateWithoutReload ? 'update' : 'create').toLowerCase() } ${ t('drillDownDashboard') }`;
                const contentMessage = `${ warning }${ t('warningText.areYouWantContinueAnd', { message: actionText }) }`;

                dispatch(showConfirm({
                    checkDirty: true,
                    dirty: isDashboardChanged,
                    header: t('warning'),
                    content: contentMessage,
                    successCallback: () => navigateToDrillDownDashboard(data, false),
                }));
            } else {
                await navigateToDrillDownDashboard(data, true);
            }
        } else if (errorMessageTransKey) {
            dispatch(showToast({
                text: t(errorMessageTransKey),
                type: 'error',
            }));
        }
    };

    useEffect(() => {
        if (drillDownSelection) {
            // noinspection JSIgnoredPromiseFromCall
            checkEnabledForDrillDown(drillDownSelection);
        }
    }, [ drillDownSelection ]);

    const getDrillDownDashboard = (data: IDrillDownSelection, updateWithoutReload = false) => {
        setUpdateWithoutReload(updateWithoutReload);
        setDrillDownSelection(data);
    };

    return { getDrillDownDashboard };
};
