import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { TooltipWrapper } from '/components';

import './DownloadLink.scss';

export const DownloadLink = ({ title, subtitle, iconColor, disabled, onClick }) => {
    const getTitle = () => (
        <div className={ 'download_link-tooltip' }>
            <span>{ title }</span>
            { subtitle && <span>{ subtitle }</span>}
        </div>
    );

    return (
        <span>
            <TooltipWrapper value={ getTitle() } force>
                <div
                    className={ cx(`download_link ${ iconColor }`, { disabled }) }
                    onClick={ onClick }
                >
                    <FontAwesomeIcon icon={ 'cloud-download-alt' } />
                </div>
            </TooltipWrapper>
        </span>
    );
};

DownloadLink.defaultProps = {
    iconColor: '',
    disabled: false,
};
