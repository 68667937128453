import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { TooltipWrapper, VocTableV2, VocRightsHOC, TableRightsColumn } from '/components';
import { ActionFormatter } from './components';

import styles from './styles.module.scss';

const TopicSetsTable = ({
    tableData,
    filters,
    totalCount,
    loading,
    requestTrigger,
    updateFilters,
    openModalDelete,
    openModalTopicSetSetAppliance,
    openInviteModal,
    getSharedRights,
    shares,
}) => {
    const actionsFormatter = cell => (
        <ActionFormatter
            currentUserPermission={ cell.currentUserPermission }
            openModalDelete={ () => openModalDelete(cell.id) }
            openModalCreateClassifierAppliance={ () => openModalTopicSetSetAppliance({ topicSet: cell.topicSet }) }
            openSharesModal={ () => openInviteModal(cell.id) }
        />
    );

    const customTableHeader = () =>
        [
            <Link to='/ai/topic-set' key={ 0 }>
                <Button outline color='success'>
                    <FontAwesomeIcon icon='plus'/>
                    <span> Add topic set</span>
                </Button>
            </Link>,
        ];

    const stringFormatter = cell => (
        <TooltipWrapper value={ cell }>
            <span className={ styles.stringFormatter }>{cell}</span>
        </TooltipWrapper>
    );

    const sharedFormatter = (cell: any, { id }) => {
        return <TableRightsColumn
            id={ id }
            getShares={ id => getSharedRights(id, 'topic-set') }
            shares={ shares }
        />;
    };

    const titleFormatter = (_, rowData) => (
        <TooltipWrapper value={ rowData.title }>
            <Link className={ styles.stringFormatter } to={ `/ai/topic-set/${rowData.id}` }>
                { rowData.title }
            </Link>
        </TooltipWrapper>
    );

    const settings = [
        {
            field: 'title',
            title: 'TopicSet',
            formatter: titleFormatter,
            sort: true,
        },
        {
            field: 'languages',
            title: 'Languages',
            formatter: stringFormatter,
            sort: true,
        },
        {
            field: 'sources',
            title: 'Sources',
            formatter: stringFormatter,
            sort: false,
        },
        {
            field: 'shared',
            title: 'Role',
            formatter: sharedFormatter,
            style: {
                textAlign: 'center',
                width: '70px',
            },
        },
        {
            field: 'actions',
            title: 'Actions',
            formatter: actionsFormatter,
            style: {
                width: '150px',
                textAlign: 'center',
            },
        },
    ];

    const options = {
        haveSearch: true,
        requestTrigger,
        updateFilters,
        customTableHeader: customTableHeader(),
    };

    return (
        <VocTableV2
            data={ tableData }
            total={ totalCount }
            loading={ loading }
            dataSettings={ settings }
            options={ options }
            filters={ filters }
        />
    );
};

export default VocRightsHOC({
    type: 'topic-set',
})(TopicSetsTable);
