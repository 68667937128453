export interface IStickyBox {
    children: any,
    position: StickyBoxPosition,
    offset?: {
        query?: null | string,
        size?: null | number,
    },
    threshold?: number,
    root?: string | null,
    stuckClass?: string,
    unstuckClass?: string,
    onChange?: (data: boolean) => void,
}

export enum StickyBoxPosition {
    TOP = 'top',
}
