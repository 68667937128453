import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { HelperService } from '/services';

import { isDirtySelector } from '../../Question/modules/question.selectors';
import { CLEANUP } from '../../../modules/survey.modules';

export const GET_QUESTION_REQUEST = 'get_question-value /feedback/question-extend/get/request';
export const GET_QUESTION_SUCCESS = 'get_question-value /feedback/question-extend/get/success';
export const GET_QUESTION_ERROR = 'get_question-value /feedback/question-extend/get/error';

export const GET_QUESTIONS_TEXT_REQUEST = 'GET_QUESTIONS_TEXT_REQUEST';
export const GET_QUESTIONS_TEXT_SUCCESS = 'GET_QUESTIONS_TEXT_SUCCESS';
export const GET_QUESTIONS_TEXT_ERROR = 'GET_QUESTIONS_TEXT_ERROR';

export const REORDER_FLOW_REQUEST = 'REORDER_FLOW_REQUEST';
export const REORDER_FLOW_SUCCESS = 'REORDER_FLOW_SUCCESS';
export const REORDER_FLOW_ERROR = 'REORDER_FLOW_ERROR';

export const GET_QUESTION_LIST_REQUEST = 'GET_QUESTION_LIST_REQUEST';
export const GET_QUESTION_LIST_REQUEST_SUCCESS = 'GET_QUESTION_LIST_REQUEST_SUCCESS';
export const GET_QUESTION_LIST_REQUEST_ERROR = 'GET_QUESTION_LIST_REQUEST_ERROR';

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_REQUEST_SUCCESS = 'DELETE_QUESTION_REQUEST_SUCCESS';
export const DELETE_QUESTION_REQUEST_ERROR = 'DELETE_QUESTION_REQUEST_ERROR';

export const COPY_QUESTION_REQUEST = 'COPY_QUESTION_REQUEST';
export const COPY_QUESTION_REQUEST_SUCCESS = 'COPY_QUESTION_REQUEST_SUCCESS';
export const COPY_QUESTION_REQUEST_ERROR = 'COPY_QUESTION_REQUEST_ERROR';

export const DELETE_FLOW_REQUEST = 'DELETE_FLOW_REQUEST';
export const DELETE_FLOW_REQUEST_SUCCESS = 'DELETE_FLOW_REQUEST_SUCCESS';
export const DELETE_FLOW_REQUEST_ERROR = 'DELETE_FLOW_REQUEST_ERROR';
export const DELETE_FLOWS_FROM_STORE = 'DELETE_FLOWS_FROM_STORE';

const DOWNLOAD_GRAPH_FILE_REQUEST = 'DOWNLOAD_GRAPH_FILE_REQUEST';
const DOWNLOAD_GRAPH_FILE_SUCCESS = 'DOWNLOAD_GRAPH_FILE_SUCCESS';
const DOWNLOAD_GRAPH_FILE_ERROR = 'DOWNLOAD_GRAPH_FILE_ERROR';

export const CREATE_FLOW_REQUEST = 'CREATE_FLOW_REQUEST';
export const CREATE_FLOW_REQUEST_SUCCESS = 'CREATE_FLOW_REQUEST_SUCCESS';
export const CREATE_FLOW_REQUEST_ERROR = 'CREATE_FLOW_REQUEST_ERROR';

export const UPDATE_FLOW_REQUEST = 'UPDATE_FLOW_REQUEST';
export const UPDATE_FLOW_REQUEST_SUCCESS = 'UPDATE_FLOW_REQUEST_SUCCESS';
export const UPDATE_FLOW_REQUEST_ERROR = 'UPDATE_FLOW_REQUEST_ERROR';

export const REORDER_QUESTION_REQUEST = 'REORDER_QUESTION_REQUEST';
export const REORDER_QUESTION_REQUEST_SUCCESS = 'REORDER_QUESTION_REQUEST_SUCCESS';
export const REORDER_QUESTION_REQUEST_ERROR = 'REORDER_QUESTION_REQUEST_ERROR';

export const TOGGLE_QUESTION_VISIBILITY = 'TOGGLE_QUESTION_VISIBILITY';
export const REORDER_QUESTION_ACTION = 'REORDER_QUESTION_ACTION';

export const SET_QUESTION_ID = 'SET_QUESTION_ID';
export const SET_FLOW_ID = 'SET_FLOW_ID';

export const SET_QUESTION_LAYOUT = 'SET_QUESTION_LAYOUT';

function getInitialState() {
    return {
        loading: true,
        flowSubmitted: false,
        flowLoading: false,
        questionId: null,
        flowId: null,
        list: [],
        questionsText: {
            loading: true,
            results: [],
        },
        questionValue: {
            loading: true,
            data: {},
        },
        activeQuestionId: null,
        showQuestionDetails: false,
        inheritDataFromId: null,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_QUESTION_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_QUESTION_LIST_REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.response.results,
            };
        }

        case GET_QUESTION_LIST_REQUEST_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case GET_QUESTIONS_TEXT_REQUEST: {
            return {
                ...state,
                questionsText: {
                    ...state.questionsText,
                    loading: true,
                },
            };
        }

        case GET_QUESTIONS_TEXT_SUCCESS: {
            return {
                ...state,
                questionsText: {
                    results: action.response.results,
                    loading: false,
                },
            };
        }

        case GET_QUESTIONS_TEXT_ERROR: {
            return {
                ...state,
                questionsText: {
                    ...state.questionsText,
                    loading: false,
                },
            };
        }

        case TOGGLE_QUESTION_VISIBILITY: {
            const contractedQuestions = HelperService.getFromStorage(localStorage, 'contractedQuestions') || {};

            const newContractedQuestions = {
                ...contractedQuestions,
                [action.data.id]: action.data.expanded,
            };

            HelperService.setInStorage(localStorage, 'contractedQuestions', newContractedQuestions);

            return {
                ...state,
                list: state.list.map(question => ({
                    ...question,
                    expanded: question.id === action.data.id ? action.data.expanded : question.expanded,
                    title: question.isEmptyTitle ? '' : question.title,
                })),
            };
        }

        case REORDER_QUESTION_ACTION: {
            return {
                ...state,
                list: [
                    state.list[0],
                    ...transformFromTreeToFlat(action.data),
                ],
            };
        }

        case SET_FLOW_ID:
        case SET_QUESTION_ID: {
            return {
                ...state,
                ...action.data,
            };
        }

        case CLEANUP: {
            return getInitialState();
        }

        case DELETE_FLOW_REQUEST_SUCCESS: {
            return {
                ...state,
                list: state.list.map(question => ({
                    ...question,
                    question_flows: question.question_flows.filter(flow => flow.id !== action.params.id),
                })),
            };
        }

        case DELETE_FLOWS_FROM_STORE: {
            return {
                ...state,
                list: state.list.map(question => ({
                    ...question,
                    question_flows: question.question_flows.filter(flow => !action.flowsId.includes(flow.id)),
                })),
            };
        }

        case CREATE_FLOW_REQUEST_SUCCESS: {
            return {
                ...state,
                list: changeQuestionFlows(state.list, action.response, createNewFlow),
                flowSubmitted: false,
            };
        }

        case UPDATE_FLOW_REQUEST_SUCCESS: {
            return {
                ...state,
                list: changeQuestionFlows(state.list, action.response, updateFlowList),
                flowSubmitted: false,
            };
        }

        case REORDER_FLOW_REQUEST: {
            return {
                ...state,
                flowLoading: true,
            };
        }

        case REORDER_FLOW_SUCCESS: {
            return {
                ...state,
                list: state.list.map(question => ({
                    ...question,
                    question_flows: reorderQuestionFlows(question.question_flows, action.data),
                })),
                flowLoading: false,
            };
        }

        case REORDER_FLOW_ERROR: {
            return {
                ...state,
                flowLoading: false,
            };
        }

        case CREATE_FLOW_REQUEST:
        case UPDATE_FLOW_REQUEST: {
            return {
                ...state,
                flowSubmitted: true,
            };
        }

        case CREATE_FLOW_REQUEST_ERROR:
        case UPDATE_FLOW_REQUEST_ERROR: {
            return {
                ...state,
                flowSubmitted: false,
            };
        }

        case GET_QUESTION_REQUEST: {
            return {
                ...state,
                questionValue: {
                    loading: true,
                    data: {},
                },
            };
        }

        case GET_QUESTION_SUCCESS: {
            return {
                ...state,
                questionValue: {
                    loading: false,
                    data: {
                        ...action.response,
                    },
                },
            };
        }

        case GET_QUESTION_ERROR: {
            return {
                ...state,
                questionValue: {
                    loading: false,
                    data: {},
                },
            };
        }

        case SET_QUESTION_LAYOUT:
            const { activeQuestionId, showQuestionDetails, inheritDataFromId } = action.data;

            return {
                ...state,
                activeQuestionId: activeQuestionId !== undefined
                    ? activeQuestionId
                    : state.activeQuestionId,
                showQuestionDetails: showQuestionDetails !== undefined
                    ? showQuestionDetails
                    : state.showQuestionDetails,
                inheritDataFromId: HelperService.checkNotNullOrUndefined(inheritDataFromId)
                    ? inheritDataFromId
                    : null,
            };

        default:
            return state;
    }
}

function setQuestionLayout(data) {
    return {
        type: SET_QUESTION_LAYOUT,
        data,
    };
}

export function setQuestionLayoutHandler({
    checkForDirty = true,
    activeQuestionId,
    showQuestionDetails = true,
    inheritDataFromId = null,
}) {
    return (dispatch, getState) => {
        const state = getState();
        const dirtyForm = isDirtySelector(state);

        const proceedOperation = () => {
            return dispatch(setQuestionLayout({
                activeQuestionId,
                showQuestionDetails,
                inheritDataFromId,
            }));
        };

        if (checkForDirty && dirtyForm) {
            return dispatch(showConfirm({
                header: 'Warning!',
                content: 'You might have some unsaved changes. Are you sure you want to proceed?',
                successCallback: proceedOperation,
            }));
        }

        return proceedOperation();
    };
}

export function getQuestionRequest(id) {
    return {
        [CALL_API]: {
            endpoint: `/question-extend/${ id }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_QUESTION_REQUEST, GET_QUESTION_SUCCESS, GET_QUESTION_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getQuestionsListById(survey_id) {
    return {
        [CALL_API]: {
            endpoint: `/question/expand-basic/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_QUESTION_LIST_REQUEST, GET_QUESTION_LIST_REQUEST_SUCCESS, GET_QUESTION_LIST_REQUEST_ERROR ],
            body: {
                limit: 9999,
                survey_id,
            },
            apiType: FEEDBACK,
            unique: true,
        },
    };
}

export function deleteQuestionById(questionId) {
    return {
        [CALL_API]: {
            endpoint: `/question/${ questionId }/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_QUESTION_REQUEST, DELETE_QUESTION_REQUEST_SUCCESS, DELETE_QUESTION_REQUEST_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function copyQuestionById(questionId) {
    return {
        [CALL_API]: {
            endpoint: `/question/${ questionId }/copy/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ COPY_QUESTION_REQUEST, COPY_QUESTION_REQUEST_SUCCESS, COPY_QUESTION_REQUEST_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Question successfully copied',
                error: 'Something went wrong',
            },
        },
    };
}

export function deleteQuestionFlowById(flowId) {
    return {
        [CALL_API]: {
            endpoint: `/question-flow-rule/${ flowId }/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_FLOW_REQUEST, DELETE_FLOW_REQUEST_SUCCESS, DELETE_FLOW_REQUEST_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Flow was successfully deleted',
                error: 'There was server error in flow deleting.',
            },
            params: {
                id: flowId,
            },
        },
    };
}

export function deleteQuestionFlowFromStore(flowsId) {
    return {
        type: DELETE_FLOWS_FROM_STORE,
        flowsId,
    };
}

export function createQuestionFlow(flow) {
    return {
        [CALL_API]: {
            endpoint: `/question-flow-rule/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_FLOW_REQUEST, CREATE_FLOW_REQUEST_SUCCESS, CREATE_FLOW_REQUEST_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Flow was successfully created',
                error: 'There was server error in flow creating.',
            },
            body: {
                ...flow,
            },
        },
    };
}

export function updateQuestionFlow(flow, flowID) {
    return {
        [CALL_API]: {
            endpoint: `/question-flow-rule/${ flowID }/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ UPDATE_FLOW_REQUEST, UPDATE_FLOW_REQUEST_SUCCESS, UPDATE_FLOW_REQUEST_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Flow was updated successfully',
                error: 'There was server error in updating flow.',
            },
            body: {
                ...flow,
            },
        },
    };
}

export function updateRemoteTreeData(model) {
    return dispatch => {
        return model.isFirstChild
            ? dispatch(setElementFirstChild(model))
            : dispatch(setElementNextSibling(model));
    };
}

export function setElementFirstChild({ questionId, parentId }) {
    return {
        [CALL_API]: {
            endpoint: `/question/${ questionId }/first-child/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ REORDER_QUESTION_REQUEST, REORDER_QUESTION_REQUEST_SUCCESS, REORDER_QUESTION_REQUEST_ERROR ],
            apiType: FEEDBACK,
            body: {
                parent_id: parentId,
            },
            logger: true,
            loggerMessages: {
                success: 'Questions order saved',
                error: 'Something went wrong',
            },
        },
    };
}

export function setReorderFlow(body) {
    return {
        [CALL_API]: {
            endpoint: `/question-flow-rule/reorder/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ REORDER_FLOW_REQUEST, REORDER_FLOW_SUCCESS, REORDER_FLOW_ERROR ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function setElementNextSibling({ questionId, parentId }) {
    return {
        [CALL_API]: {
            endpoint: `/question/${ questionId }/next-sibling/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ REORDER_QUESTION_REQUEST, REORDER_QUESTION_REQUEST_SUCCESS, REORDER_QUESTION_REQUEST_ERROR ],
            apiType: FEEDBACK,
            body: {
                parent_id: parentId,
            },
            logger: true,
            loggerMessages: {
                success: 'Questions order saved',
                error: 'Something went wrong',
            },
        },
    };
}

export function toggleQuestionVisibilityById(data) {
    return {
        type: TOGGLE_QUESTION_VISIBILITY,
        data,
    };
}

export function reorderQuestion(data) {
    return {
        type: REORDER_QUESTION_ACTION,
        data,
    };
}

export function setQuestionId(id) {
    return {
        type: SET_QUESTION_ID,
        data: {
            questionId: id,
        },
    };
}

export function setFlowId(id) {
    return {
        type: SET_FLOW_ID,
        data: {
            flowId: id,
        },
    };
}

export function getQuestionsTextByLanguage({ activeLang, activeSurvey }) {
    return {
        [CALL_API]: {
            endpoint: `/question-language/expand-basic/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_QUESTIONS_TEXT_REQUEST, GET_QUESTIONS_TEXT_SUCCESS, GET_QUESTIONS_TEXT_ERROR ],
            apiType: FEEDBACK,
            unique: true,
            body: {
                language__iso_6391_code: activeLang,
                question__survey_id: activeSurvey,
                limit: 9999,
            },
        },
    };
}

export function downloadGraphFile(surveyId, fileName) {
    return {
        [CALL_API]: {
            endpoint: `/survey/${ surveyId }/structuregraph/`,
            method: 'GET',
            contentType: 'application/pdf',
            apiType: FEEDBACK,
            responseType: 'blob',
            fileName,
            types: [
                DOWNLOAD_GRAPH_FILE_REQUEST,
                DOWNLOAD_GRAPH_FILE_SUCCESS,
                DOWNLOAD_GRAPH_FILE_ERROR,
            ],
        },
    };
}

export function transformFromTreeToFlat(questions, nestedLeft = 2, nestedLevel = 1) {
    let result = [];

    questions.forEach(question => {
        const nestedRight = question.children.length ? nestedLeft + calculateChilds(question) * 2 + 1 : nestedLeft + 1;

        const temp = {
            ...question,
            children: [],
            nested_left: nestedLeft,
            nested_right: nestedRight,
            nested_level: nestedLevel,
            title: question.isEmptyTitle ? '' : question.title,
        };

        result.push(temp);

        if (Array.isArray(question.children)) {
            result = result.concat(transformFromTreeToFlat(question.children, nestedLeft + 1, nestedLevel + 1));
        }

        nestedLeft = nestedRight + 1;
    });

    return result;
}

function changeQuestionFlows(questions, payload, method) {
    return questions.map(question => {
        if (question.id === payload.question) {
            question.question_flows = method(question.question_flows, payload);
        }
        return question;
    });
}

function reorderQuestionFlows(flows, order) {
    return flows.map(flow => {
        const reordered = order.find(item => item.id === flow.id);

        return {
            ...flow,
            rule_priority: reordered ? reordered.rule_priority : flow.rule_priority,
        };
    });
}

function updateFlowList(flows, payload) {
    return flows.map(el => el.id === payload.id ? payload : el);
}

function createNewFlow(flows, payload) {
    return [ ...flows, payload ];
}

function calculateChilds(item) {
    let counter = 0;

    item.children.forEach(child => {
        counter++;

        if (child.children.length) {
            counter += calculateChilds(child);
        }
    });

    return counter;
}
