import React, { memo } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ThemeSettings, LanguageSettings } from './scenes';

export const ThemeRoute = memo(({ match }) => {
    return (
        <Switch>
            <Route exact
                path={ `${ match.url }/theme-settings` }
                component={ ThemeSettings }>
            </Route>
            <Route exact
                path={ `${ match.url }/language-settings` }
                component={ LanguageSettings }>
            </Route>
            <Redirect to={ `/feedback/settings/theme-list` }/>
        </Switch>
    );
});
