import React from 'react';

import { SuperChartHeader, StaticAreaHeader } from './components';
import { EGadgetType } from '/visual/models';
import { IGadgetHeader } from './models';

export const GadgetHeader = ({
    isHovered,
    isEditable,
    containerRef,
    gadgetData,
    isDragEvent,
    rights,
    actions,
}: IGadgetHeader) => {
    const getHeaderByType = () => {
        switch (gadgetData.type) {
            case EGadgetType.STATIC_AREA:
                return <StaticAreaHeader
                    showHeader={ !isDragEvent && isEditable }
                    hovered={ isHovered }
                    containerRef={ containerRef }
                    gadgetData={ gadgetData }
                    onCut={ actions.onCut }
                    onCopy={ actions.onCopy }
                    onDelete={ actions.onDelete }
                    onMoveToSave={ actions.onMoveToSave }
                    onEdit={ actions.onEdit }
                />;

            case EGadgetType.SUPER_CHART:
                return <SuperChartHeader
                    isEditable={ isEditable }
                    gadgetData={ gadgetData }
                    canTitleEdit={ !isDragEvent && isEditable }
                    rights={ rights }
                    onCut={ actions.onCut }
                    onCopy={ actions.onCopy }
                    onDelete={ actions.onDelete }
                    onMoveToSave={ actions.onMoveToSave }
                    onEdit={ actions.onEdit }
                    onSync={ actions.onSync }
                    onMoreInfo={ actions.onMoreInfo }
                />;

            default:
                return null;
        }
    };

    return getHeaderByType();
};
