export class QueryAttributesMatrix {
    //todo add common matrix interface
    static rules = [
        {
            condition: {
                attribute: {
                    is: [ 'TEXT' ],
                },
            },
            restriction: {
                operator: {
                    is: [ 'EQA', 'NEA', 'EQN', 'NEN', 'LNEN', 'LEQN' ],
                },
            },
            clone: true,
        },

        {
            condition: {
                attribute: {
                    is: [ 'CHOICE', 'CHOICE_NPS_SEGMENT' ],
                },
            },
            restriction: {
                operator: {
                    is: [ 'NE', 'EQ', 'EQA', 'NEA', 'INC', 'EXC', 'EQN', 'NEN' ],
                },
            },
            clone: true,
        },

        {
            condition: {
                attribute: {
                    is: [ 'CHOICE_NPS', 'CHOICE_NUMERIC' ],
                },
            },
            restriction: {
                operator: {
                    is: [ 'NE', 'EQ', 'EQA', 'NEA', 'INC', 'EXC', 'EQN', 'NEN', 'GTE', 'GT', 'LT', 'LTE' ],
                },
            },
            clone: true,
        },

        {
            condition: {
                operator: {
                    not: [ 'EQA', 'NEA', 'EQN', 'NEN', 'LNEN', 'LEQN', '' ],
                },
            },
            restriction: {
                attribute: {
                    not: [ 'TEXT' ],
                },
            },
        },

        {
            condition: {
                operator: {
                    is: [ 'LNEN', 'LEQN' ],
                },
            },
            restriction: {
                attribute: {
                    is: [ 'TEXT' ],
                },
            },
        },

        {
            condition: {
                operator: {
                    is: [ 'EQ', 'NE' ],
                },
            },
            restriction: {
                attribute: {
                    is: [ 'NUMERIC', 'SCALABLE', 'NPS', 'META', 'NPS_SEGMENT', 'CLASSIFICATION', 'LANG',
                        'CHOICE', 'CHOICE_NPS', 'CHOICE_NUMERIC', 'CHOICE_NPS_SEGMENT' ],
                },
            },
        },

        {
            condition: {
                attribute: {
                    is: [ 'NPS_SEGMENT' ],
                },
            },
            restriction: {
                operator: {
                    is: [ 'EQ', 'NE', 'EQN', 'NEN', 'EQA', 'NEA' ],
                },
            },
        },

        {
            condition: {
                attribute: {
                    not: [ 'TEXT', '' ],
                },
            },
            restriction: {
                operator: {
                    not: [ 'LNEN', 'LEQN' ],
                },
            },
        },

        {
            condition: {
                attribute: {
                    not: [ 'NUMERIC', 'SCALABLE', 'NPS', '', 'META', 'NPS_SEGMENT', 'CHOICE',
                        'CLASSIFICATION', 'LANG', 'CHOICE_NPS', 'CHOICE_NUMERIC', 'CHOICE_NPS_SEGMENT' ],
                },
            },
            restriction: {
                operator: {
                    not: [ 'EQ', 'NE' ],
                },
            },
            clone: true,
        },

        {
            condition: {
                attribute: {
                    not: [ 'NUMERIC', 'SCALABLE', 'NPS', 'CHOICE_NPS', 'CHOICE_NUMERIC', '' ],
                },
            },
            restriction: {
                operator: {
                    not: [ 'GTE', 'GT', 'LT', 'LTE' ],
                },
            },
        },

        {
            condition: {
                attribute: {
                    not: [ 'CHOICE', 'CHOICE_NPS', 'CHOICE_NUMERIC', 'CHOICE_NPS_SEGMENT', 'NPS_SEGMENT' ],
                },
            },
            restriction: {
                operator: {
                    not: [ 'INC', 'EXC' ],
                },
            },
            clone: true,
        },

        {
            condition: {
                operator: {
                    is: [ 'GTE', 'GT', 'LT', 'LTE' ],
                },
            },
            restriction: {
                value: {
                    not: [ '' ],
                },
            },
        },

        {
            condition: {
                value: {
                    is: [ '' ],
                },
            },
            restriction: {
                operator: {
                    not: [ 'GTE', 'GT', 'LT', 'LTE' ],
                },
            },
        },
    ];

    static dublicateRules() {
        const newRules = [];

        QueryAttributesMatrix.rules.forEach(rule => {
            newRules.push(rule);

            if (rule.clone) {
                newRules.push({
                    condition: rule.restriction,
                    restriction: rule.condition,
                });
            }
        });

        return newRules;
    }

    static getRuleConditionResult({ condition, hashForCompare }) {
        const fields = Object.keys(condition);
        let valid = true;

        fields.forEach(field => {
            valid &= Object.prototype.hasOwnProperty.call(condition[field],'is')
                ? condition[field].is.includes(hashForCompare[field])
                : !condition[field].not.includes(hashForCompare[field]);
        });

        return valid;
    }

    static makeSettingsRestriction({ restriction, settings }) {
        const fields = Object.keys(restriction);

        fields.forEach(field => {
            settings[field + 's'] = settings[field + 's'].filter(({ type, id }) => {
                const compareValue = type || id;

                return Object.prototype.hasOwnProperty.call(restriction[field],'is')
                    ? restriction[field].is.includes(compareValue)
                    : !restriction[field].not.includes(compareValue);
            });
        });

        return settings;
    }

    static processMartix({ queryItem, settings, attributeValueKey }) {
        const attribute = settings.attributes.find(attribute => attribute[attributeValueKey] === queryItem.id);
        const attrValue = attribute ? attribute.type : '';
        const hashForCompare = {
            attribute: attrValue,
            value: settings.value,
            operator: queryItem.operator,
        };

        QueryAttributesMatrix.dublicateRules();

        QueryAttributesMatrix.rules.forEach(rule => {
            const conditionResult = QueryAttributesMatrix.getRuleConditionResult({
                condition: rule.condition,
                hashForCompare,
            });

            if (conditionResult) {
                settings = QueryAttributesMatrix.makeSettingsRestriction({ restriction: rule.restriction, settings });
            }
        });

        return settings;
    }
}

