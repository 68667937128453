import { arrayMove } from 'react-sortable-hoc';
import { TFunction } from 'i18next';

import { IBoard, IAddNewPage, IDeletePageInStore, IPages, PaginatedLayout } from '/visual/scenes/Dashboard/models';
import { IGadgetData, IDrillDownBreadCrumb, IDrillDownSettingsConf } from '/visual/models';
import { HelperService } from '/services';

export class DashboardService {
    static isHasParentDashboard (dashboard: IBoard) {
        return (Object.prototype.hasOwnProperty.call(dashboard, 'parentDashboardId')
            && Boolean(dashboard.parentDashboardId)
        || (Object.prototype.hasOwnProperty.call(dashboard, 'parentGadget')
            && Boolean(dashboard.parentGadget)));
    }

    static getParentGadget (dashboard: IBoard) {
        return dashboard.parentGadget;
    }

    static getAllParentGadget (dashboard: IBoard) {
        const parentGadget = DashboardService.getParentGadget(dashboard);
        const gadgets: IGadgetData[] = [];

        const collectGadgets = (gadget: IGadgetData, list: IGadgetData[]): IGadgetData[] => {
            // if parent gadget doesn't exist
            if (!gadget) return list;

            // add gadget to list
            list.push(gadget);

            // if gadget has nested parent gadget
            return gadget.parent ? collectGadgets(gadget.parent, list) : list;
        };

        return collectGadgets(parentGadget, gadgets);
    }

    static getDrillDownDashboardSettings (parent: IGadgetData, t: TFunction) {
        const dataSettings = parent ? parent.dataSettings : null;
        const fieldNames: IDrillDownSettingsConf = {
            title: '',
            factType: t('factType'),
            factName: t('factName'),
            selectedGroup: t('selectedGroup'),
            selectedSuperGroup: t('selectedSuperGroup'),
            groupByName: t('groupByName'),
            secondGroupByName: t('secondGroupByName'),
        };
        let settingsConf: IDrillDownSettingsConf;

        if (!dataSettings) {
            settingsConf = { title: t('parentDashboard') };
        } else {
            const {
                factName,
                factTypes,
                selectedGroup,
                selectedSuperGroup,
                groupByName,
                secondGroupByName,
            } = dataSettings;

            settingsConf = {
                title: null,
                // Drill down is not available for multi facts, so we always get 0 index
                factType: factTypes ? factTypes[0] : null,
                factName,
                selectedGroup: selectedGroup === '' ? 'No value' : selectedGroup,
                selectedSuperGroup,
                groupByName,
                secondGroupByName,
            };
        }

        return Object.keys(settingsConf)
            .filter((field: string) =>
                HelperService.checkNotNullOrUndefined(settingsConf[field as keyof IDrillDownSettingsConf]),
            )
            .map((field: string) => ({
                value: settingsConf[field as keyof IDrillDownSettingsConf],
                title: fieldNames[field as keyof IDrillDownSettingsConf],
                field: field,
            }));
    }

    static getDashboardStructure ({
        data,
        existedBreadCrumbs = [],
    }: {
        data: IBoard | IGadgetData,
        existedBreadCrumbs?: IDrillDownBreadCrumb[],
    }): IDrillDownBreadCrumb[] {
        let parentData = null;

        if ('parentGadget' in data) parentData = data.parentGadget;
        if ('parent' in data) parentData = data.parent;

        existedBreadCrumbs.unshift({
            id: ('parentGadget' in data) ? data.id : data.dashboardId,
            title: data.title,
            isActive: Object.prototype.hasOwnProperty.call(data, 'parentGadget'),
            isRoot: ('parent' in data) && data.parent === null,
            scrollToGadgetId: ('parentGadget' in data) ? null : data.id,
            gadgetId: parentData ? parentData.id : '',
        });

        return parentData
            ? DashboardService.getDashboardStructure({
                data: parentData,
                existedBreadCrumbs: existedBreadCrumbs,
            })
            : existedBreadCrumbs;
    }

    static addNewPage({ moveToPageIndex, storePart, newPage }: IAddNewPage) {
        const extraPages = {
            ...storePart.extraPages,
            [newPage.id]: { ...newPage },
        };

        const pages = [
            ...storePart.pages,
            { ...newPage },
        ];

        const newList = [ ...storePart.pagesOrder, newPage.id ];

        return {
            extraPages,
            pages,
            pagesOrder: HelperService.checkNotNullOrUndefined(moveToPageIndex)
                ? arrayMove(newList, newList.length - 1, moveToPageIndex as number)
                : newList,
        };
    }

    static deletePageInStore({ deletedPageId, storePart }: IDeletePageInStore) {
        // new extra pages without deleted page
        const extraPages = HelperService.removeKeyFromObject(storePart.extraPages, deletedPageId);

        // all gadgets, that were placed on the deleted page
        const newWildGadget = storePart.pages.find((p: IPages) => p.id === deletedPageId)
            ?.coordinates?.map((c: PaginatedLayout) => c.gadgetId) || [];
        // delete key of wild gadgets from extraGadgets using array method
        const filteredEntries = storePart.extraGadgets
            && Object.entries(storePart.extraGadgets).filter(([ key ]) =>
                newWildGadget.every((deletedGadgetId: string | undefined) => deletedGadgetId !== key),
            );
        // back from array to object format
        const extraGadgets = filteredEntries ? Object.fromEntries(filteredEntries) : null;

        // new pages list without deleted page
        const pages = storePart.pages.filter((page: IPages) => page.id !== deletedPageId);

        // new pages order list without deleted page
        const pagesOrder = storePart.pagesOrder.filter((pageId: string) => pageId !== deletedPageId);

        return {
            extraPages,
            extraGadgets,
            pages,
            pagesOrder,
        };
    }
}
