import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from "react";

export const useResponsiveQuery = () => {
    const [ isTabletAndBelow, setIsTabletAndBelow ] = useState(useMediaQuery({ query: '(max-width: 1069px)' }));
    const [ isPrintMode, setIsPrintMode ] = useState(false);
    const [ isMobile, setIsIsMobile ] = useState(useMediaQuery({ query: '(max-width: 829px)' }));

    const useTabletAndBelowQuery = useMediaQuery({ query: '(max-width: 1069px)' });
    const useMobileMediaQuery = useMediaQuery({ query: '(max-width: 829px)' });

    const handlePrintChange = (e: MediaQueryListEvent) => setIsPrintMode(e.matches);

    useEffect(() => {
        window.matchMedia('print')
            .addEventListener('change', handlePrintChange);

        return () => {
            window.matchMedia('print')
                .removeEventListener('change', handlePrintChange);

            setIsPrintMode(false);
        };
    }, []);


    if( isTabletAndBelow !== useTabletAndBelowQuery && !isPrintMode ) {
        setIsTabletAndBelow(useTabletAndBelowQuery);
    }

    if( isMobile !== useMobileMediaQuery && !isPrintMode) {
        setIsIsMobile(useMobileMediaQuery);
    }

    return { isTabletAndBelow, isMobile };
};
