import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Button, ListGroupItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { TooltipWrapper } from '/components';

import './RightsTableActionFormatter.scss';

export const RightsTableActionFormatter = ({
    openSourceDetailsModal,
    initiateSourceExport,
    showApplianceModal,
    openInviteModal,
    attrByDate,
    attributes,
    uuidMongo,
    rights,
    id,
}) => {
    const { t } = useTranslation();

    const onCreateApplianceClick = () => {
        rights.isPermitRecord && showApplianceModal({ sourceId: uuidMongo, mode: 'create' });
    };

    const downloadSurvey = () => {
        rights.isPermitDownloadSource && initiateSourceExport(uuidMongo, attrByDate, 'system');
    };

    const onSourceDetails = () => {
        rights.isPermitSourceDetails
        && openSourceDetailsModal({
            uuidMongo,
            tableData: attributes.map(({ id, type }) => {
                return (
                    <ListGroupItem key={ id }>
                        { id } : <strong>{ type }</strong>
                    </ListGroupItem>
                );
            }),
        });
    };

    return (
        <div className='table-action__container table-action__system-upload-list center'>
            <TooltipWrapper value={ t('share') } force>
                <span
                    className={ cx('table-action__query-list-icon', { disabled: !rights.isPermitShare }) }
                    onClick={ () => rights.isPermitShare && openInviteModal(id) }
                >
                    <FontAwesomeIcon icon='share' />
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ t('recodeVariables') } force>
                <span className={ cx('table-action-btn-wrapper', { disabled: !rights.isPermitRecord }) }>
                    <Button
                        color='link'
                        onClick={ onCreateApplianceClick }
                    >
                        <FontAwesomeIcon icon='sitemap' />
                    </Button>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ t('sourceDetails') } force>
                <span className={ cx('table-action-btn-wrapper', { disabled: !rights.isPermitSourceDetails }) }>
                    <Button
                        className='table-action-btn-wrapper'
                        color='link'
                        onClick={ () => onSourceDetails() }
                    >
                        <FontAwesomeIcon icon='list' className='table-action__glyph' />
                    </Button>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ t('downloadSource') } force>
                <span className={ cx('table-action-btn-wrapper', { disabled: !rights.isPermitDownloadSource }) }>
                    <Button
                        color='link'
                        onClick={ downloadSurvey }
                    >
                        <FontAwesomeIcon icon='cloud-download-alt' />
                    </Button>
                </span>
            </TooltipWrapper>
        </div>
    );
};
