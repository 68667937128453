import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { IEmptyGadget } from '/visual/scenes/Dashboard/components/Gadget/models';
import { Preloader } from '/components';
import { HelperService } from '/services';

import styles from './style.module.scss';

export const EmptyGadget = ({
    errorMessage = null,
    message,
    onEdit,
    isLoading,
}: IEmptyGadget) => {
    const loaderWrapperRef = useRef();
    const { t } = useTranslation();
    let newMessage = message;

    if (!HelperService.checkNotEmpty(message)) {
        newMessage = t(errorMessage
            ? 'checkConfiguration'
            : 'configureGadget',
        );
    }

    return (
        <div className={ styles.container }>
            {
                !!errorMessage
                && <h6 style={ isLoading ? { maxWidth: 250 } : {} }>{ errorMessage }</h6>
            }

            {
                !isLoading
                    ? <Button
                        outline
                        color='primary'
                        className={ styles.button }
                        onClick={ onEdit }
                    >
                        <FontAwesomeIcon icon='cog' />
                        <span>{ newMessage }</span>
                    </Button>
                    : <div
                        ref={ loaderWrapperRef }
                        className={ styles.loaderWrapper }
                    >
                        <Preloader
                            transparentByRefNode={ loaderWrapperRef }
                            autoSizeByContainerRef={ loaderWrapperRef }
                        />
                    </div>
            }
        </div>
    );
};
