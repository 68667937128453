import React from 'react';
import { StatisticResultsBlock } from '../StatisticResultsBlock';
import { CollapseSection } from '/components';

export const StatisticResultsBlocksSection = ({ statistic, loading, channel }) => (
    <CollapseSection
        title={ 'Statistic results' }
        collapse={ true }
        collapseDisable={ true }
        className={ 'statistic_result__card' }
    >
        { statistic.map((item, index) =>
            <StatisticResultsBlock { ...item } key={ index } loading={ loading } channel={ channel }/>)
        }
    </CollapseSection>
);
