import React, { useEffect, useState } from 'react';
import { Preloader } from '/components';

import styles from './style.module.scss';

export const LoaderWrapper = ({ color }: {color?: string}) => {
    const [ showMessage, setShowMessage ] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowMessage(true);
        }, 5000);
    }, []);

    return (
        <div className={ styles.loaderWrapper }>
            <Preloader color={ color }/>
            {
                showMessage
                    ? <span className={ styles.message }>
                        This process can sometimes take a while (up to 5 minutes). Please
                        wait while data is loaded.
                    </span>
                    : null
            }
        </div>
    );
};
