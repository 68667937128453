import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flexRender } from '@tanstack/react-table';

import styles from './style.module.scss';

export const DragAndDropZone = ({
    column,
    visibleColumns,
    setColumnOrder,
    isEditable,
    headerGroupLength,
}: any) => {
    const changeOrder = ({
        columns,
        itemId,
        destinationId,
    }: {
        columns: any[],
        itemId: number,
        destinationId: number
    }) => {
        const itemIndex = columns.findIndex(columnData => columnData === itemId);
        const destinationIndex = columns.findIndex(columnData => columnData === destinationId);
        const newColumns = [ ...columns ];

        if (itemIndex !== destinationIndex) {
            newColumns.splice(itemIndex, 1);
            newColumns.splice(destinationIndex, 0, columns[itemIndex]);
        }

        return newColumns;
    };

    const [{ isDragging }, drag ] = useDrag(() => ({ // ?
        type: 'divth',
        item: column,
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: !isEditable,
    }), [ isEditable ]);

    const [{ isOver }, drop ] = useDrop(() => ({ // ?
        accept: 'divth',
        collect: monitor => ({
            isOver: monitor.isOver(),
        }),
        drop: (item: any) => {
            const columnItemData = item.column.columnDef;
            const columnData = column.column.columnDef;

            if (columnItemData.id === columnData.id) return;

            const orderDataModel = {
                columns: visibleColumns,
                itemId: columnItemData.id,
                destinationId: columnData.id,
            };

            setColumnOrder(changeOrder(orderDataModel));
        },
    }), [ visibleColumns ]);

    return (
        <div className={ styles.headerWrapper }>
            <div
                ref={ drag }
                className={ cx(styles.th, { [styles.disableClick]: column.column.getIsResizing() }) }
                style={{ width: column.getSize() }}
                onClick={ () => column.column.toggleSorting() }
            >
                <div ref={ drop } className={ styles.innerTh }>
                    {
                        column.isPlaceholder
                            ? null
                            : <span>
                                {
                                    flexRender(
                                        column.column.columnDef.header,
                                        column.getContext(),
                                    )
                                }
                            </span>
                    }
                    <span className={ styles.sortIcon }>
                        {
                            column.column.getIsSorted()
                                ? column.column.getIsSorted() === 'desc'
                                    ? <FontAwesomeIcon icon={ 'caret-down' } />
                                    : <FontAwesomeIcon icon={ 'caret-up' } />
                                : ''
                        }
                    </span>
                </div>
            </div>
            {
                headerGroupLength
                && <div
                    onDoubleClick={ () => column.column.resetSize() }
                    onMouseDown={ column.getResizeHandler() }
                    onTouchStart={ column.getResizeHandler() }
                    className={ cx(styles.resizer, { [styles.isResizing]: column.column.getIsResizing() }) }
                />
            }
        </div>
    );
};
