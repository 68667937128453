import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { Button, Row, Col, Input, FormText } from 'reactstrap';
import { HelperService } from '/services/HelperService';
import { VocFormCheckbox, VocModal } from '/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subscribePhoneList, subscribeEmailList } from './';

import './EditCustomersModal.scss';

const getInitialState = ({ initialValues }) => {
    const id = initialValues ? initialValues.id : null;
    const creation = initialValues && initialValues.creation ? `Date added: ${ initialValues.creation }` : '';

    return {
        id,
        creation,
        email: {
            label: 'Enter email address',
            protocol: 'EMAIL',
            name: 'email',
            error: false,
            errorText: 'Wrong email format or not unique',
            value: '',
        },
        phone: {
            label: 'Enter phone number',
            protocol: 'SMS',
            name: 'phone',
            error: false,
            errorText: 'Wrong phone format or not unique',
            value: '',
        },
        addContact: false,
        initProtocol: false,
        changeCheckbox: false,
    };
};

@reduxForm({
    form: 'customersModal',
    enableReinitialize: true,
})
export class EditCustomersModal extends Component {
    state = getInitialState(this.props);

    @autobind
    cleanupModal() {
        const { addContact, initProtocol } = this.state;

        (addContact || initProtocol) && this.props.requestTrigger();

        this.setState(getInitialState({}));
    }

    @autobind
    onModalEntered() {
        this.setState(getInitialState(this.props));
    }

    getModalFooter() {
        const { handleSubmit, close } = this.props;

        return (
            <Fragment>
                <Button onClick={ close } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel </span>
                </Button>
                <Button
                    onClick={ handleSubmit(this.submit) }
                    disabled={ !this.state.changeCheckbox }
                    color="primary">
                    <FontAwesomeIcon icon="check"/>
                    <span> Apply </span>
                </Button>
            </Fragment>
        );
    }

    getEmailList(param) {
        return (
            <Fragment>
                { this.getControlAddNew(this.state.email) }
                <FieldArray
                    name="emails"
                    component={ subscribeEmailList }
                    initialValues={ param.initialValues }
                    valueCustomerBlacklist={ param.state }
                    onChange={ this.onChangeCheckbox }
                />
            </Fragment>
        );
    }

    getPhoneList(param) {
        return (
            <Fragment>
                { this.getControlAddNew(this.state.phone) }
                <FieldArray
                    name="phones"
                    component={ subscribePhoneList }
                    initialValues={ param.initialValues }
                    valueCustomerBlacklist={ param.state }
                    onChange={ this.onChangeCheckbox }
                    initProtocol={ this.initPhoneProtocol }
                />
            </Fragment>
        );
    }

    @autobind
    getProtocol({ id, protocol, state }) {
        return {
            blacklisted: false,
            id: id,
            name: protocol === "VOICE" ? 'IVR' : protocol,
            protocol: protocol,
            protocolId: protocol === "VOICE" ? 3 : 1,
            state: state,
        };
    }

    @autobind
    initPhoneProtocol(address) {
        const { push, shift, pop, unshift } = this.props.array;

        this.props.addAddress(address).promise.then(resp => {
            {
                const { phones } = this.props.customerFormState;

                phones.forEach((phone, index) => {
                    if (phone.address === resp.address) {
                        const stringPath = `phones[${ index }].protocols`;

                        resp.protocol === "VOICE"
                            ? pop(stringPath) && push(stringPath, this.getProtocol(resp))
                            : shift(stringPath) && unshift(stringPath, this.getProtocol(resp));
                    }

                    this.setState({ initProtocol: true });
                });
            }
        });
    }

    @autobind
    addContactToForm({ resp, name }) {
        const { push } = this.props.array;

        const contacts = {
            address: resp.address,
            customer: resp.customer,
            id: resp.id,
            protocols: name === 'phone'
                ? [
                    {
                        ...this.getProtocol(resp),
                    },
                    {
                        ...this.getProtocol({ id: null, protocol: "VOICE", state: 1 }),
                        address: resp.address,
                        customer: resp.customer,
                    },
                ]
                : [
                    {
                        ...this.getProtocol(resp),
                    },
                ],
        };

        push(`${ name }s`, contacts);
    }

    @autobind
    submitNewField({ target }) {
        const { protocol, value, name, error } = this.state[target.name];
        const data = [{
            protocol,
            address: value,
            customer: this.state.id,
            state: 1,
        }];

        !error && this.props.addAddress(data).promise.then(({ success }) => {
            this.setState({
                ...this.state,
                addContact: true,
                [name]: {
                    ...this.state[name],
                    value: '',
                },
            });

            this.addContactToForm({ resp: { ...success[0] }, name });
        });
    }

    @autobind
    submit(event) {
        const { initialValues: { state }, manageBlacklist, close, updateContacts } = this.props;
        const isChangeBlacklist = event.state !== state;

        const addresses = [ ...event.emails, ...event.phones ].reduce((acc, address) => {
            {
                const protocols = address.protocols.length < 2
                    ? [{
                        state: address.state,
                        id: address.id,
                    }]
                    : address.protocols.map(protocol => ({
                        state: protocol.state,
                        id: protocol.id,
                    })).filter(protocol => protocol.id);

                return [ ...acc, ...protocols ];
            }
        }, []);

        const promiseBlacklist = isChangeBlacklist && manageBlacklist({ body: { state: event.state }, id: event.id }).promise;
        const promiseUpdateContact = addresses.length && updateContacts(addresses).promise;

        Promise.all([ promiseBlacklist, promiseUpdateContact ]).then(() => {
            this.props.requestTrigger();
            close();
        });
    }

    @autobind
    onChangeCheckbox() {
        const { changeCheckbox } = this.state;

        !changeCheckbox && this.setState({ changeCheckbox: true });
    }

    checkValidation(name, value) {
        const { emails, phones } = this.props.customerFormState;
        let valid, unique;

        if (name === 'email') {
            valid = !HelperService.validateEmail(value);
            unique = emails.some(el => el.address.toLowerCase() === value.toLowerCase());
        } else {
            valid = !HelperService.validatePhone(value);
            unique = phones.some(el => el.address.toLowerCase() === value.toLowerCase());
        }

        return {
            error: valid || unique,
        };
    }

    @autobind
    onTypeText({ target: { name, value } }) {
        this.setState({
            ...this.state,
            [name]: {
                ...this.state[name],
                ...this.checkValidation(name, value),
                value: value,
            },
        });
    }

    getControlAddNew({ label, name }) {
        const { value, error, errorText } = this.state[name];
        const isDisabled = value === '' || error;

        return (
            <div className={ 'add-subscribe__control' }>
                <Row>
                    <Col md={ 9 }>
                        { error && <FormText className="add-subscribe__error">{ errorText }</FormText> }
                        <Input
                            placeholder={ label }
                            name={ name }
                            className={ 'add-subscribe__input' }
                            type={ 'text' }
                            onChange={ this.onTypeText }
                            value={ value }
                        />
                    </Col>
                    <Col md={ 3 }>
                        <Button name={ name } onClick={ this.submitNewField } disabled={ isDisabled } color={ 'success' }> Add new </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    @autobind
    getModalBody() {
        const { initialValues, customerFormState: { state } } = this.props;
        const { creation } = this.state;

        return (
            <Fragment>

                <Row>
                    <Col md={ 6 }>
                        <Field
                            name={ 'state' }
                            onChange={ this.onChangeCheckbox }
                            label='Block all user’s contacts'
                            component={ VocFormCheckbox }
                            className={ 'checkbox-block-all' }
                            type={ 'checkbox' }
                            normalize={ value => value ? 16 : 1 }
                            format={ value => value === 16 }
                        />
                    </Col>
                    <Col md={ 6 }>
                        <p className='modal-customers__creation'>{ creation }</p>
                    </Col>
                </Row>

                <Row className="modal-customers-subscribe__row">
                    <Col md={ 6 }>
                        <p className="modal-customers-subscribe__title">Available email</p>
                        { this.getEmailList({ initialValues, state }) }
                    </Col>
                    <Col md={ 6 }>
                        <p className="modal-customers-subscribe__title">Available phone number</p>
                        { this.getPhoneList({ initialValues, state }) }
                    </Col>
                </Row>

            </Fragment>
        );
    }

    render() {
        const {
            show,
            close,
            external_id,
        } = this.props;

        return (
            <VocModal
                isOpen={ show }
                onOpened={ this.onModalEntered }
                onClosed={ this.cleanupModal }
                toggle={ close }
                header={ `Customer ID: ${ external_id }` }
                footer={ this.getModalFooter() }
                modalClassName={ 'modal-edit_customers modal-scrollable' }
                size={ 'lg' }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}

EditCustomersModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    addAddress: PropTypes.func.isRequired,
    updateContacts: PropTypes.func.isRequired,
    manageBlacklist: PropTypes.func.isRequired,
    requestTrigger: PropTypes.func.isRequired,
    customerFormState: PropTypes.object,
};
