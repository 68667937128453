import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { GeneralSection } from '../GeneralSection/';
import { QuestionTextSection } from '../QuestionTextSection/';
import { AnswersSection } from '../AnswersSection/';
import { ConfigurableValuesSection } from '../ConfigurableValuesSection';
import { QuestionAudioSection } from '../QuestionAudioSection';
import { QuestionFormControls } from '../QuestionFormControls';
import { LayoutModal } from '/feedback/components';
import { questionFormValidation } from '../../modules/QuestionFormValidation';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';
import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';
import { setQuestionLayoutHandler } from '../../../Questions/modules/questions.modules';
import { showQuestionDetailsSelector } from '../../../Questions/modules/questions.selectors';
import { dirtyStatusConfirm } from '/scenes/Confirm/modules/confirm.index';
import { getCommonSettingsActiveChanel } from '../../../SurveyCommonSettings/modules/SurveyCommonSettings.selectors';
import { HelperService } from '/services';
import { answersSelector, audioFilesSelector, configurableFieldsSelector } from '../../modules/question.selectors';
import { getIvrTextFile, cleanupIvrTextFile } from '../../modules/question.modules';

import './QuestionForm.scss';

const mapStateToProps = state => ({
    showQuestionDetails: showQuestionDetailsSelector(state),
    assets: assetsSelector(state),
    activeChanel: getCommonSettingsActiveChanel(state),
    answers: answersSelector(state),
    audioFiles: audioFilesSelector(state),
    configurableFields: configurableFieldsSelector(state),
});

const mapDispatchToProps = {
    getAssets,
    uploadAsset,
    dirtyStatusConfirm,
    setQuestionLayoutHandler,
    getIvrTextFile,
    cleanupIvrTextFile,
};

@reduxForm({
    form: 'QuestionForm',
    enableReinitialize: true,
    shouldValidate: () => true,
    validate: questionFormValidation,
})
@connect(mapStateToProps, mapDispatchToProps)
export class QuestionForm extends PureComponent {
    state = {
        isOpenModal: false,
        initialFieldValue: null,
        fieldName: null,
    };

    componentDidUpdate(prevProps) {
        const { dirty, activeChanel, dirtyStatusConfirm, showQuestionDetails } = this.props;

        if (dirty !== prevProps.dirty) {
            dirtyStatusConfirm({ dirty });
        }

        if (activeChanel !== prevProps.activeChanel) {
            this.resetForm();
        }

        if (!showQuestionDetails && showQuestionDetails !== prevProps.showQuestionDetails) {
            this.resetForm();
        }
    }

    @autobind
    onClose() {
        const { setQuestionLayoutHandler } = this.props;

        setQuestionLayoutHandler?.({
            activeQuestionId: null,
            showQuestionDetails: false,
            inheritDataFromId: null,
        });
    }

    @autobind
    getAudioFile(type) {
        const { initialValues, language, getIvrTextFile } = this.props;
        const langId = initialValues.language_values[language].id;

        getIvrTextFile({
            id: langId,
            type,
        });
    }

    @autobind
    cleanUpAudio(type) {
        this.props.cleanupIvrTextFile(type);
    }

    @autobind
    resetForm() {
        this.props.reset('QuestionForm');
    }

    @autobind
    openModal({ value, name }) {
        this.setState({
            isOpenModal: true,
            initialFieldValue: HelperService.parseHtmlString(value),
            fieldName: name,
        });
    }

    @autobind
    closeModal() {
        this.setState({
            isOpenModal: false,
            initialFieldValue: null,
            fieldName: null,
        });
    }

    @autobind
    applyLayoutChanges(fieldValue) {
        this.props.change(this.state.fieldName, fieldValue);
        this.closeModal();
    }

    @autobind
    onSubmitWithConfirmation(values, isSave) {
        const { questionType, responsesCount, dirty, showConfirm, onSubmit } = this.props;
        const withConfirmation = questionType?.includes('QuestionScalable') && (responsesCount > 0) && dirty;

        if (!withConfirmation) {
            return onSubmit(values, isSave);
        }

        const paragraphs = [
            // eslint-disable-next-line max-len
            `CAUTION! By ${isSave ? 'saving' : 'applying'} changes, please expect that the extended answer
            range in the database will contain previous answers mixed with new answers.`,
            `Example: previous answering range from 1 to 5 and new answering range from 1 to 10. As a result,
            answers from 1 to 5 will be mixed with a new range from 1 to 10. Previous answers aren’t
            deleted after the range change.`,
        ];

        showConfirm({
            header: `${isSave ? 'Save' : 'Apply'} changes`,
            content: paragraphs.map((p, i) =>
                <p className='questionFlowConfirmationParagraph' key={ `paragraph_${i}` }>{p}</p>),
            successCallback: () => onSubmit(values, isSave),
        });
    }

    @autobind
    getQuestionFormControls( persist ) {
        const {
            handleSubmit,
            valid,
            loading,
            rightsSurvey,
        } = this.props;

        return (
            <QuestionFormControls
                onCloseForm={ this.onClose }
                handleSubmit={ handleSubmit }
                onSubmit={ this.onSubmitWithConfirmation }
                valid={ valid }
                loading={ loading }
                rightsSurvey={ rightsSurvey }
                { ...( persist ? { persist } : {} ) }
            />
        );
    }

    render() {
        const {
            language,
            configurableValues,
            array: {
                move,
                push,
                removeAll,
            },
            questionTypesOption,
            showAnswerSection,
            configurableFields,
            handleSubmit,
            showConfirm,
            survey,
            questionId,
            ivrErrors,
            answersErrors,
            loading,
            dirty,
            assets,
            getAssets,
            uploadAsset,
            questionType,
            persist,
            answers,
            questionViewForm,
            audioFiles,
            change,
        } = this.props;

        return (
            <>
                <Prompt
                    when={ dirty }
                    message='Seems you might have some unsaved changes. Are you sure you want to continue?'
                />

                <form onSubmit={ handleSubmit } className='question__form'>
                    {
                        this.getQuestionFormControls()
                    }

                    <GeneralSection
                        loading={ loading }
                        questionTypesOption={ questionTypesOption }
                        questionId={ questionId }
                        viewConfig={ questionViewForm.generalSection }
                    />

                    {
                        survey.channel === 'IVR'
                            ? <QuestionAudioSection
                                loading={ loading }
                                language={ language }
                                errors={ ivrErrors }
                                questionId={ questionId }
                                audioFiles={ audioFiles }
                                getAudioFile={ this.getAudioFile }
                                cleanUpAudio={ this.cleanUpAudio }
                            />
                            : <QuestionTextSection
                                loading={ loading }
                                language={ language }
                                showEditor={ survey.channel !== 'SMS' }
                                viewConfig={ questionViewForm.textSection }
                                htmlEditorCallback={ this.openModal }
                            />
                    }

                    {
                        showAnswerSection
                        && <AnswersSection
                            language={ language }
                            loading={ loading }
                            errors={ answersErrors }
                            showConfirm={ showConfirm }
                            move={ move }
                            push={ push }
                            removeAll={ removeAll }
                            channel={ survey.channel }
                            htmlEditorCallback={ this.openModal }
                            showEditor={ survey.channel !== 'IVR' && !questionViewForm.answersSection.name.disabled }
                            answers={ answers }
                            questionType={ questionType }
                            viewConfig={ questionViewForm.answersSection }
                        />
                    }

                    {
                        configurableValues.length > 0
                        && <ConfigurableValuesSection
                            loading={ loading }
                            options={ configurableValues }
                            language={ language }
                            questionType={ questionType }
                            viewConfig={ questionViewForm.configSection }
                            change={ change }
                            configurableFields={ configurableFields }
                        />
                    }

                    {
                        this.getQuestionFormControls(persist)
                    }
                </form>
                <LayoutModal
                    show={ this.state.isOpenModal }
                    close={ this.closeModal }
                    layoutValue={ this.state.initialFieldValue }
                    applyLayoutChanges={ this.applyLayoutChanges }
                    getAssets={ getAssets }
                    uploadAsset={ uploadAsset }
                    assets={ assets }
                />
            </>
        );
    }
}

QuestionForm.defaultProps = {
    questionId: false,
};
