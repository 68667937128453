import { createSelector } from 'reselect';
import { UsersRoleFeedbackService } from '/services';
import { userInfoSelector } from '/feedback/modules/VocFeedback.selectors';

export const projectsSelector = state => state.VocFeedback.journeyMap.projects;
export const projectLoadingSelector = state => state.VocFeedback.journeyMap.projects.loading;
export const touchPointsSelector = state => state.VocFeedback.journeyMap.touchPoints;
export const activeProjectSelector = state => state.VocFeedback.journeyMap.activeProject;

export const projectListSelector = createSelector([ projectsSelector ], projects => {
    return projects.results.map(project => ({
        name: project.name,
        id: project.id,
    }));
});

export const allTouchpointsSelector = createSelector([ touchPointsSelector ],
    touchpoints => {
        const results = touchpoints.results
            .map(touchpoint => ({
                surveys: [],
                ...touchpoint,
                loading: touchpoints.loading.includes(touchpoint.id),
                expanded: touchpoints.opened.includes(touchpoint.id),
            }))
            .sort((first, second) => {
                if (first.project === second.project) {
                    return first.sequence < second.sequence ? -1 : 1;
                }
                return first.project < second.project ? -1 : 1;
            });

        const unAssignedTouchPointIndex = results.findIndex(x => x.id === 1);

        if (unAssignedTouchPointIndex !== -1) {
            const unAssignedTouchPoint = results.splice(unAssignedTouchPointIndex, 1)[0];

            results.push(unAssignedTouchPoint);
        }

        return results;
    });

const userPermissionSelector = state => state.VocFeedback.common.userPermission;

export const touchPointsListSelector = createSelector(
    [ allTouchpointsSelector, activeProjectSelector, userPermissionSelector, userInfoSelector ],
    (touchPoints, activeProject, userPermission, isSupportUser) => {
        return touchPoints
            .filter(touchPoint => touchPoint.project === activeProject)
            .map(el => {
                return {
                    ...el,
                    surveys: el.surveys.map(el => {
                        const permissionForUnit = isSupportUser
                            ? { permissionsName: 'Manager' }
                            : userPermission.mainPermission.find(
                                permission => permission.unit === el.unit.id,
                            );

                        return permissionForUnit
                            ? {
                                ...el,
                                rights: UsersRoleFeedbackService.getRightsForAll(permissionForUnit.permissionsName).customerJourneyList,
                            }
                            : undefined;
                    }).filter(el => el),
                };
            });
    });
