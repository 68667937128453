import { CALL_API, FEEDBACK } from 'store/middleware/api';

export const MANAGE_LANGUAGE_REQUEST = 'MANAGE_LANGUAGE_REQUEST';
export const MANAGE_LANGUAGE_SUCCESS = 'MANAGE_LANGUAGE_SUCCESS';
export const MANAGE_LANGUAGE_ERROR = 'MANAGE_LANGUAGE_ERROR';

export const GET_ENABLE_LANGUAGE_REQUEST = 'GET_ENABLE_LANGUAGE_REQUEST';
export const GET_ENABLE_LANGUAGE_SUCCESS = 'GET_ENABLE_LANGUAGE_SUCCESS';
export const GET_ENABLE_LANGUAGE_ERROR = 'GET_ENABLE_LANGUAGE_ERROR';

export const SWITCH_SETTING_CHANNEL = 'SWITCH_SETTING_CHANNEL';
export const SWITCH_SETTING_UNIT = 'SWITCH_SETTING_UNIT';

export const GLOBAL_SWITCH_LANGUAGE = 'GLOBAL_SWITCH_LANGUAGE';
export const GLOBAL_SWITCH_TIME_SLOT_TYPE = 'GLOBAL_SWITCH_TIME_SLOT_TYPE';

function getInitialState() {
    return {
        loading: false,
        langUpdating: false,
        timeSlotType: 'regular',
        activeLanguage: {},
        enableLanguage: [],
        unit: {},
        channel: 'WEB',
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_ENABLE_LANGUAGE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_ENABLE_LANGUAGE_SUCCESS: {
            return {
                ...state,
                loading: false,
                enableLanguage: [
                    ...action.response.SURVEY_USER_LANGUAGES,
                ],
            };
        }

        case GET_ENABLE_LANGUAGE_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case MANAGE_LANGUAGE_REQUEST: {
            return {
                ...state,
                langUpdating: true,
                activeLanguage: action.params,
            };
        }

        case MANAGE_LANGUAGE_SUCCESS: {
            return {
                ...state,
                langUpdating: false,
                enableLanguage: [
                    ...action.response.SURVEY_USER_LANGUAGES,
                ],
            };
        }

        case MANAGE_LANGUAGE_ERROR: {
            return {
                ...state,
                langUpdating: false,
            };
        }

        case SWITCH_SETTING_CHANNEL: {
            return {
                ...state,
                channel: action.data,
            };
        }

        case SWITCH_SETTING_UNIT: {
            return {
                ...state,
                unit: action.data,
            };
        }

        case GLOBAL_SWITCH_LANGUAGE: {
            return {
                ...state,
                activeLanguage: {
                    ...action.data,
                },
            };
        }

        case GLOBAL_SWITCH_TIME_SLOT_TYPE: {
            return {
                ...state,
                timeSlotType: action.data,
            };
        }

        default:
            return state;
    }
}

export function switchChannel(channel) {
    return {
        type: SWITCH_SETTING_CHANNEL,
        data: channel,
    };
}

export function switchLanguage(data) {
    return {
        type: GLOBAL_SWITCH_LANGUAGE,
        data,
    };
}

export function switchTimeSlotType(data) {
    return {
        type: GLOBAL_SWITCH_TIME_SLOT_TYPE,
        data,
    };
}

export function switchUnit(unit) {
    return {
        type: SWITCH_SETTING_UNIT,
        data: unit,
    };
}

export function getEnableLanguages({ unit, channel }) {
    return {
        [CALL_API]: {
            endpoint: `unit/${ unit }/get-channel-settings/${ channel }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_ENABLE_LANGUAGE_REQUEST, GET_ENABLE_LANGUAGE_SUCCESS, GET_ENABLE_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
            params: channel,
        },
    };
}

export function manageLanguages({ unit, body, activeLang }) {
    return {
        [CALL_API]: {
            endpoint: `/unit/${ unit }/set-settings/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ MANAGE_LANGUAGE_REQUEST, MANAGE_LANGUAGE_SUCCESS, MANAGE_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
            body,
            params: activeLang,
        },
    };
}

