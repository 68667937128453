import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { VocFormDatePicker, VocFormTextField, VocFormSelectField } from '/components';
import { lengthHandler, MAX_DELIMITER_LENGTH, MAX_TITLE_LENGTH } from '../../../../modules/selectors';
import { ExtendUploadModal } from '../ExtendUploadModal';

import './UploadNewFileForm.scss';

const Form = ({
    handleSubmit,
    onSubmit,
    languages,
    newHeaders,
    afterReload,
    allNewHeadersInOldHeaders,
    formValues,
    loading,
    submitting,
    isChoiceDelimiterRequired,
    isFileRequired,
    initialValues,
    showToast,
}) => {
    const { t } = useTranslation();
    const [ showModalExtendingAfterReload, setShowModalExtendingAfterReload ] = useState(false);

    const submitHandler = value => {
        if (afterReload && newHeaders.length) {
            allNewHeadersInOldHeaders
                ? setShowModalExtendingAfterReload(true)
                : showToast({
                    text: t('provideAllExistingFields'),
                    type: 'error' ,
                });
        } else {
            onSubmit(value);
        }
    };

    const requiredChoiceDelimiter = isChoiceDelimiterRequired && !formValues?.choiceDelimiter;

    const isDisableSubmitButton = () => {
        if (!formValues) {
            return true;
        }

        const { title, capturedAt } = formValues;

        return !title || !capturedAt || requiredChoiceDelimiter || isFileRequired;
    };

    return (
        <form onSubmit={ handleSubmit } className='parser-form__group'>
            <FormGroup row>
                <Label for="title" md={ 12 }>
                    { t('field.title') }
                    &nbsp;
                    <span className='star'>*</span>
                </Label>
                <Col md={ 12 }>
                    <Field
                        type={ 'text' }
                        placeholder={ t('placeholders.enterTitle') }
                        name={ 'title' }
                        component={ VocFormTextField }
                        normalize={ value => lengthHandler(value, MAX_TITLE_LENGTH) }
                        disabled={ afterReload }
                        required
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="capturedAt" md={ 12 }>
                    { t('field.capturedAt') }
                    &nbsp;
                    <span className='star'>*</span>
                </Label>
                <Col md={ 12 }>
                    <Field
                        name={ 'capturedAt' }
                        component={ VocFormDatePicker }
                        wrapClassName="voc-datepicker voc-datepicker__custom_style"
                        className="app-form-control form-control"
                        defaultDate={ initialValues.capturedAt }
                        showMonthDropdown
                        showYearDropdown
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="language" md={ 12 }>
                    { t('field.language') }
                </Label>
                <Col md={ 12 }>
                    <Field
                        placeholder={ t('placeholders.selectLang') }
                        name={ 'language' }
                        options={ languages }
                        component={ VocFormSelectField }
                        required
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="choiceDelimiter" md={ 12 }>
                    { t('placeholders.choiceDelimiter') }
                </Label>
                <Col md={ 12 }>
                    <Field
                        placeholder={ t('placeholders.choiceDelimiter') }
                        name={ 'choiceDelimiter' }
                        component={ VocFormTextField }
                        normalize={ value => lengthHandler(value, MAX_DELIMITER_LENGTH) }
                        required
                        className={ cx({ 'field-required': requiredChoiceDelimiter }) }
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="csvDelimiter" md={ 12 }>
                    { t('placeholders.csvDelimiter') }
                </Label>
                <Col md={ 12 }>
                    <Field
                        placeholder={ t('placeholders.csvDelimiter') }
                        name={ 'csvDelimiter' }
                        component={ VocFormTextField }
                        normalize={ value => lengthHandler(value, MAX_DELIMITER_LENGTH) }
                        required
                    />
                </Col>
            </FormGroup>
            <div className="parser-form__buttons-container">
                <Button
                    color="primary"
                    onClick={ handleSubmit(submitHandler) }
                    disabled={ loading || submitting || isDisableSubmitButton() }
                >
                    <FontAwesomeIcon icon={ 'save' }/>
                    &nbsp;
                    { t('save') }
                </Button>
            </div>
            <ExtendUploadModal
                show={ showModalExtendingAfterReload }
                actionHandler={ () => onSubmit(formValues) }
                newHeaders={ newHeaders }
                close={ () => setShowModalExtendingAfterReload(false) }
            />
        </form>
    );
};

export const UploadNewFileForm = reduxForm({
    form: 'UploadNewFileForm',
    enableReinitialize: true,
})(Form);
