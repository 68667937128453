import React from 'react';
import { WarningLabs } from '../../components/WarningLabs';
import DocumentTitle from 'react-document-title';

const src = 'https://zero2hero.sandsiv.com/';

export const ZeroShot = function() {
    return (
        <DocumentTitle title="Zero shot - Labs">
            <div>
                <WarningLabs/>
                <iframe
                    src={ src }
                    referrerPolicy="unsafe-url"
                    width="100%"
                    height="1160px"
                    frameBorder="0">
                </iframe>
            </div>
        </DocumentTitle>
    );
};
