import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { autobind } from 'core-decorators';
import { Alert } from 'reactstrap';
import { SettingsFormControls, SettingsFormSections } from "/feedback/components";
import { GatewaysSettingsService } from '/feedback/services';
import { dirtyStatusConfirm } from '/scenes/Confirm/modules/confirm.index';
import { Preloader, showToast } from '/components';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    dirtyStatusConfirm,
    showToast,
};

@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})
export class SettingsForm extends Component {
    @autobind
    resetForm() {
        const { dispatch, form } = this.props;

        dispatch(reset(form));
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.checkUpdate && nextProps.checkUpdate !== this.props.checkUpdate) {
            this.resetForm();
        }

        return true;
    }

    @autobind
    submitForm(body) {
        const model = Object.keys(body).reduce((acc, item) => {
            const setting = item === 'GATEWAYS_CHAIN'
                ? GatewaysSettingsService.getSubmitSettings(body[item])
                : body[item].value;

            const value = setting === '' ? null : setting;

            acc[item] = body[item].redefined ? value : null;
            return acc;
        }, {});

        const request = this.props.putSettings(model);

        return request && request.then(() => this.props.reset());
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dirty !== this.props.dirty) {
            this.props.dirtyStatusConfirm({ dirty: this.props.dirty });
        }
    }

    @autobind
    initRunnerDemo() {//todo button Demo Submit  when haven't rights
        const {
            dispatch,
            handleSubmit,
            initRunnerDemo,
            activeSurveyState,
            showToast,
            justShowRunner,
        } = this.props;
        const isStateActive = activeSurveyState === 1;

        if(justShowRunner) {
            initRunnerDemo(this.props);
        } else if(isStateActive) {
            const requestSubmitForm = dispatch(handleSubmit(values => this.submitForm(values)));

            return requestSubmitForm && requestSubmitForm.then(() => {
                this.props.submitFailed && console.warn('submitFailed = ', this.props.submitFailed);
                !this.props.submitFailed && initRunnerDemo();
            });
        } else {
            showToast({ text: `The survey must be Active to show the Demo pop-up window`, type: 'error' });
        }
    }

    render() {
        const {
            fieldsMap,
            wrappedCollapse,
            handleSubmit,
            getStaticLink,
            showToast,
            sendingSettings,
            onChangeField,
            channel,
            themeSettings = false,
            languageSettings = false,
            change,
            dirty,
            customDirty = false,
            initialValues,
            assets,
            getAssets,
            uploadAsset,
            permission = {},
        } = this.props;

        const demoBtnDisabled = languageSettings && channel === 'DIGI' && !!initialValues.DIGI_RUNNER_URL.errorMessage;

        return (
            <>
                <Prompt when={ dirty || customDirty }
                    message='Seems you might have some unsaved changes. Are you sure you want to continue?'
                />

                <div className={ 'survey-common-settings__form' }>
                    {
                        sendingSettings && <div className={ 'survey-settings__loader' }>
                            <Preloader/>
                        </div>
                    }
                    {
                        channel === 'DIGI' && !themeSettings
                        && <Alert fade={ false } color={ 'info' }>All the current changes made to language settings will be
                            saved. Please keep
                            in
                            mind: All answers, provided in the demonstration DIGI pop-up window will be saved to the
                            related
                            survey after hitting the "Submit" button and will affect the final survey results. You can
                            always
                            delete the results of the demo at the "Customers" tab.</Alert>
                    }

                    {
                        themeSettings && !channel
                        && <Alert fade={ false } color={ 'info' }>
                            Please customize theme settings copied from the default theme settings. Note: To use the
                            demo button save the theme first.
                        </Alert>
                    }
                    <div>
                        <SettingsFormSections
                            fieldsMap={ fieldsMap }
                            getStaticLink={ getStaticLink }
                            showToast={ showToast }
                            change={ change }
                            onChangeField={ onChangeField }
                            wrappedCollapse={ wrappedCollapse }
                            assets={ assets }
                            getAssets={ getAssets }
                            uploadAsset={ uploadAsset }
                        />
                    </div>
                    <SettingsFormControls
                        cancelChanges={ this.resetForm }
                        handleSubmit={ handleSubmit }
                        submit={ this.submitForm }
                        initRunnerDemo={ this.initRunnerDemo }
                        channel={ channel }
                        demoBtnDisabled={ demoBtnDisabled }
                        permission={ permission }
                    />
                </div>
            </>
        );
    }
}

SettingsForm.propTypes = {
    fieldsMap: PropTypes.array,
    putSettings: PropTypes.func,
    initRunnerDemo: PropTypes.func,
    initialValues: PropTypes.object,
};
