import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { TabPane, TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import { TinyEditor } from '/feedback/components';
import { Editor } from '/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export class LayoutEditor extends Component {
    state = {
        activeTab: 1,
    };

    componentDidUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled) {
            this.setState({
                activeTab: 1,
            });
        }
    }

    @autobind
    togglePane(tab) {
        this.setState({
            activeTab: tab,
        });
    }

    render() {
        const {
            activeTab,
        } = this.state;
        const {
            value,
            onChange,
            onBlur,
            onFocus,
            assets,
            getAssets,
            uploadAsset,
        } = this.props;

        return (
            <Fragment>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={ `markup-editor__pane-header ${ activeTab === 1 ? 'active' : null }` }
                            onClick={ () => {
                                this.togglePane(1);
                            } }
                        >
                            <FontAwesomeIcon className={ 'markup-editor__pane-icon' } icon={ 'columns' }/>
                            <span>Preview</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={ `markup-editor__pane-header ${ activeTab === 2 ? 'active' : null }` }
                            onClick={ () => {
                                this.togglePane(2);
                            } }
                        >
                            <FontAwesomeIcon className={ 'markup-editor__pane-icon' } icon={ 'code' }/>
                            <span>Edit HTML</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={ activeTab }
                    className={ `markup-editor__tabs` }>
                    <TabPane tabId={ 1 } className={ `markup-editor__pane` }>
                        {
                            activeTab === 1
                            && <TinyEditor
                                value={ value }
                                onChange={ onChange }
                                assets={ assets }
                                getAssets={ getAssets }
                                uploadAsset={ uploadAsset }
                            />
                        }
                    </TabPane>
                    <TabPane tabId={ 2 } className={ 'markup-editor__pane' }>
                        {
                            activeTab === 2
                            && <Editor
                                onChange={ onChange }
                                onBlur={ onBlur }
                                onFocus={ onFocus }
                                value={ value }
                            />
                        }
                    </TabPane>
                </TabContent>
            </Fragment>
        );
    }
}

LayoutEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
};
