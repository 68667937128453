import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Label, Alert } from 'reactstrap';
import { Trans, withTranslation } from 'react-i18next';

const uuidv4 = require('uuid/v4');

import { VocSelect, Preloader } from '/components';
import { ApplianceSegment } from '../ApplianceSegment/';
import { ApplianceService } from '../../services/ApplianceService';

@withTranslation()
export class ApplianceModalContent extends Component {
    mapSegments() {
        const {
            segments,
            source,
            attributeValueKey,
            deleteSegment,
            onChangeSegment,
            dataType,
        } = this.props;

        const initialOptions = ApplianceService.formatAttributesToSelectOptions(source.attributes, dataType.value);
        const segmentOptions = ApplianceService.getFilteredSegmentOptions(initialOptions, segments);

        return segments.map((segment, index) => {
            if (!segment.hash) {
                segment.hash = uuidv4();
            }

            const hasEmptyItems = !ApplianceService.hasEmptyItems(segment.query, source, dataType, segments);

            return (
                <ApplianceSegment
                    segment={ segment }
                    source={ source }
                    key={ index }
                    initialOptions={ initialOptions }
                    segmentOptions={ segmentOptions }
                    attributeValueKey={ attributeValueKey }
                    deleteSegment={ deleteSegment }
                    onChangeSegment={ onChangeSegment }
                    dataType={ dataType }
                    hasEmptyItems={ hasEmptyItems }
                />
            );
        });
    }

    render() {
        const {
            name,
            hideApplianceModal,
            applianceModalLoading,
            onAddSegmentCLick,
            upsentSource,
            validAppliance,
            onChangeName,
            onDataTypeChange,
            dataType,
            dataTypes,
            mode,
            t,
        } = this.props;

        if (applianceModalLoading) {
            return <Preloader />;
        }

        if (upsentSource) {
            hideApplianceModal();
            return <Preloader />;
        }

        return (
            <Fragment>
                <Alert className={ 'appliance-content-info' } color="info">
                    <Trans i18nKey='warningText.recodeVariables'>
                        <strong>Important!</strong> If you switch between NPS_SEGMENT and other data types you may lose part your configuration.
                    </Trans>
                </Alert>
                <div className={ 'recorder-name-block' }>
                    <Label className={ 'recorder-name-label' }>
                        { t('field.outputTitle') }
                    </Label>
                    <Input
                        className={ 'recorder-name-input' }
                        type={ 'text' }
                        value={ name }
                        onChange={ onChangeName }
                    />
                    <div className="title_select">
                        <VocSelect
                            className={ 'no-margin' }
                            placeholder={ t('placeholders.chooseDataType') }
                            value={ dataType }
                            disabled={ mode === 'edit' }
                            options={ dataTypes }
                            onChange={ onDataTypeChange }
                        />
                    </div>
                </div>
                { this.mapSegments() }
                <Button
                    color="success"
                    onClick={ onAddSegmentCLick }
                >
                    <FontAwesomeIcon icon='plus' />
                    &nbsp;
                    { t('addOutputLabel') }
                </Button>
                {
                    !validAppliance
                    && <div className={ 'appliance-content-alert' }>
                        { t('warningText.applianceIsInvalid') }
                    </div>
                }
            </Fragment>
        );
    }
}

ApplianceModalContent.propTypes = {
    validAppliance: PropTypes.bool,
    name: PropTypes.string,
    source: PropTypes.object,
    hideApplianceModal: PropTypes.func,
    applianceModalLoading: PropTypes.bool,
    upsentSource: PropTypes.any,
    segments: PropTypes.array,
    attributeValueKey: PropTypes.string,
    deleteSegment: PropTypes.func,
    onChangeSegment: PropTypes.func,
    onChangeName: PropTypes.func,
    onAddSegmentCLick: PropTypes.func,
};
ApplianceModalContent.defaultProps = {};
