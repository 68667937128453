import React from 'react';
import { Field } from 'redux-form';
import cx from 'classnames';
import { Card, CardHeader, CardBody, FormGroup, Label, Col, Row } from 'reactstrap';

import { VocFormTextField, VocFormErrorField, VocFormSelectField } from '/components';
import { HelperService } from '/services';
import { IGeneralSection } from './models';

import './GeneralSection.scss';

export const GeneralSection = ({ loading, questionTypesOption, questionId, viewConfig }: IGeneralSection) => {
    const { display_class, external_identifier } = viewConfig;

    return (
        <Card className='question__section'>
            <CardHeader>
                <Row>
                    <Col lg={ 6 }>
                        <span>General</span>
                    </Col>
                    {
                        questionId
                        && <Col lg={ 6 }>
                            <div className='question__id--header'>
                                Question ID: { questionId }
                            </div>
                        </Col>
                    }
                </Row>
            </CardHeader>
            <CardBody className={ cx( 'question__section-body', { 'question__section--loading': loading } ) }>
                <FormGroup>
                    <Row>
                        <Col md={ 12 }>
                            <FormGroup>
                                <Label
                                    className='general-section__label'
                                    htmlFor='title'
                                >
                                    Title
                                    <span className='star'> *</span>
                                </Label>
                                <Field
                                    type='text'
                                    placeholder='Enter question title'
                                    name='title'
                                    withError={ false }
                                    component={ VocFormTextField }
                                    normalize={ HelperService.normalizeTextValue }
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={ 12 }>
                            <Field
                                name='title_error'
                                className='question-form__validation-error'
                                component={ VocFormErrorField }
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        {
                            display_class.show
                            && <Col md={ external_identifier.show ? 6 : 12 }>
                                <FormGroup>
                                    <Label
                                        className='general-section__label'
                                        htmlFor='display_class'
                                    >
                                        Type
                                        <span className='star'> *</span>
                                    </Label>
                                    <Field
                                        name='display_class'
                                        component={ VocFormSelectField }
                                        options={ questionTypesOption }
                                        placeholder='Select question type'
                                        normalize={ HelperService.normalizeSelectValue }
                                        format={ (value: string) => HelperService.getSelectedValue(questionTypesOption, value) }
                                        className='no-margin'
                                        withError={ false }
                                        disabled={ display_class.disabled }
                                        portal
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            external_identifier.show
                            && <Col md={ 6 }>
                                <FormGroup>
                                    <Label className='general-section__label' htmlFor='external_identifier'>
                                        External id
                                    </Label>
                                    <Field
                                        type='text'
                                        placeholder='Enter question external ID'
                                        name='external_identifier'
                                        className='general-section__input'
                                        withError={ false }
                                        component={ VocFormTextField }
                                        disabled={ external_identifier.disabled }
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            display_class.show
                            && <Col md={ 6 }>
                                <Field
                                    name='display_class'
                                    className='question-form__validation-error'
                                    component={ VocFormErrorField }
                                />
                            </Col>
                        }
                    </Row>
                </FormGroup>
            </CardBody>
        </Card>
    );
};
