import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { HelperService } from '/services';
import { MenuList, SourcePaginateSelectValue, SourcePaginateSelectOption } from '/components';

export class SelectAsyncPaginate extends Component {
    searchTypeDebouncer = HelperService.debounce((...args) => {
        const { setSelectFilters, getSources } = this.props;

        setSelectFilters && setSelectFilters({ filters: args[0].body, id: this.props.id });
        getSources(...args);
    }, 500);

    state = {
        open: false,
    };

    @autobind
    onMenuScrollToBottom(event) {
        const { scrollHeight, scrollTop, offsetHeight } = event.target;

        // VOC-13107
        // The issue you're encountering with the scroll logic not working when the browser window is zoomed to 67%
        // is likely due to the way the scroll position is calculated. When you zoom the browser, the dimensions of
        // the scrollable element change, which can affect the scrollHeight, scrollTop, and offsetHeight values.
        //
        // One approach to address this is to modify the scroll detection logic to use a small tolerance value, which
        // accounts for any minor discrepancies in the scroll position calculations.
        //
        // The Math.abs function is used to check if the difference between scrollHeight - scrollTop - offsetHeight is
        // within a small tolerance value. This accounts for any minor inaccuracies that might occur due to zooming.
        const tolerance = 2;

        if (Math.abs(scrollHeight - scrollTop - offsetHeight) > tolerance) return;

        const { total, filters, setPage, id, loading, getSources } = this.props;
        const { page, limit } = filters;

        if (page * limit < total && !loading) {
            setPage({ page: page + 1, id });
            getSources({ body: { ...filters, page: page + 1 }, params: { id } });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const menuList = document.querySelector('.vochub-select-control__menu-list');

        if (!prevState.open && this.state.open) {
            menuList && menuList.addEventListener('scroll', this.onMenuScrollToBottom);
        } else if (prevState.open && !this.state.open) {
            menuList && menuList.removeEventListener('scroll', this.onMenuScrollToBottom);
        }
    }

    @autobind
    onInputChange(search, { action }) {
        const { id, filters, exclude_sources } = this.props;

        if (search !== filters.search && (action === 'input-change' || action === 'set-value')) {
            this.searchTypeDebouncer({ body: { ...filters, search, exclude_sources }, params: { id } });
        }
    }

    @autobind
    onMenuOpen() {
        const { filters, options, loading, id, exclude_sources } = this.props;

        if ((options.length === 0 || options.length % 15 !== 0) && !loading && !this.state.open) {
            this.searchTypeDebouncer({ body: { ...filters, exclude_sources }, params: { id } });
        }

        this.setState({
            open: true,
        });
    }

    @autobind
    onMenuClose() {
        const { onClose, setSelectFilters, filters, id } = this.props;

        this.setState({
            open: false,
        });

        setSelectFilters && setSelectFilters({ filters: { ...filters, search: '', page: 1 }, id });
        onClose && onClose();
    }

    render() {
        const {
            value,
            onChange,
            placeholder,
            options,
            loading,
            className,
            onBlur,
            isDisabled,
            isClearable = true,
            selectOptionClass,
        } = this.props;

        return (
            <Select
                isDisabled={ isDisabled }
                onMenuOpen={ this.onMenuOpen }
                onMenuClose={ this.onMenuClose }
                value={ value?.value ? value : null }
                onChange={ onChange }
                blurInputOnSelect
                onBlur={ onBlur }
                className={ className }
                selectOptionClass={ selectOptionClass }
                classNamePrefix={ 'Select-menu__small vochub-select-control' }
                options={ options }
                isLoading={ loading }
                filterOption={ false }
                menuIsOpen={ this.state.open }
                isSearchable
                isClearable={ isClearable }
                onInputChange={ this.onInputChange }
                placeholder={ placeholder }
                styles={{ menuPortal: base => ({ ...base, zIndex: 1051 }) }}
                menuPortalTarget={ document.body }
                menuShouldScrollIntoView={ false }
                menuPlacement={ 'bottom' }
                components={{
                    MenuList,
                    Option: SourcePaginateSelectOption,
                    ValueContainer: SourcePaginateSelectValue,
                }}
            />
        );
    }
}

SelectAsyncPaginate.propTypes = {
    options: PropTypes.array,
    value: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.bool,
    setSelectFilters: PropTypes.func,
    id: PropTypes.string,
    total: PropTypes.number,
    getSources: PropTypes.func,
    setPage: PropTypes.func,
};
