import { TGadgetCoordinates } from '/visual/models';

interface IFreeSpace {
    top: number;
    bottom: number;
}

interface IPagesData {
    [key: string]: {
        oldDashboardId: string,
        coordinates: TGadgetCoordinates[]
    }
}

type currentState = 'top' | 'bottom';

export class ImportOldDashboardService {
    static height = 12;

    static getData(oldDashboardData: any) {
        const { gadgets, id, title } = oldDashboardData;

        let freeSpace: IFreeSpace = {
            top: 12,
            bottom: 12,
        };

        let pagesData: IPagesData = {};
        let currentState: currentState = 'top';
        let pageCounter = 1;

        gadgets.forEach((gadget: any) => {
            if(freeSpace.top < gadget.width) {
                currentState = 'bottom';
            }

            if(currentState === 'bottom' && freeSpace.bottom < gadget.width) {
                freeSpace = {
                    top: 12,
                    bottom: 12,
                };

                pageCounter = pageCounter + 1;
                currentState = 'top';
            }

            const pageId = `page${pageCounter}`;
            const getX = 12 - freeSpace[currentState];
            const getY = currentState === 'bottom'
                ? this.height
                : 0;

            pagesData = {
                ...pagesData,
                [pageId]: {
                    oldDashboardId: id,
                    coordinates: [
                        ...( pagesData[pageId]?.coordinates || [] ),
                        {
                            childDashboard: gadget.childDashboard ? this.getData(gadget.childDashboard) : null,
                            gadgetId: gadget.id,
                            h: this.height,
                            w: gadget.width,
                            x: getX,
                            y: getY,
                        },
                    ],
                },
            };

            freeSpace = {
                ...freeSpace,
                [currentState]: freeSpace[currentState] - gadget.width,
            };
        });

        return {
            oldDashboardId: id,
            title,
            pages: Object.keys(pagesData).reduce((pages, key) => ([
                ...pages,
                pagesData[key],
            ]), []),
        };
    }
}
