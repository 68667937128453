import React, { Component } from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { autobind } from 'core-decorators';
import moment from 'moment';
import cx from 'classnames';

import { HelperService } from '/services';

import './RangeDatePicker.scss';

export class RangeDatePicker extends Component {
    fromDatePicker = React.createRef();
    toDatePicker = React.createRef();
    offset = new Date().getTimezoneOffset();
    state = {
        to: null,
        from: null,
    };

    static getDerivedStateFromProps(props, state) {
        const { values: { to, from } } = props;

        if (to !== state.to || from !== state.from) {
            return {
                to,
                from,
            };
        }
        return null;
    }

    componentDidUpdate() {
        const {
            values = {},
        } = this.props;

        if (this.isDatesChanged()) {
            this.setState({
                to: values.to,
                from: values.from,
            });
        } else if (this.isDateReseted()) {
            this.setState({
                to: values.to,
                from: values.from,
            });
        }
    }

    isDateReseted() {
        const {
            values = {},
        } = this.props;

        return (values.to === null && values.to !== this.state.to) || (values.from === null && values.from !== this.state.from);
    }

    isDatesChanged() {
        const {
            values = {},
        } = this.props;

        return (values.to !== null && !moment(values.to).isSame(this.state.to))
            || (values.from !== null && !moment(values.from).isSame(this.state.from));
    }

    @autobind
    clickOutside() {
        const from = this.fromDatePicker.current.input;
        const to = this.toDatePicker.current.input;
        const fromValue = this.formatDate(moment(from.value, this.props.dateFormat.toLocaleUpperCase()));
        const toValue = this.formatDate(moment(to.value, this.props.dateFormat.toLocaleUpperCase()));
        const currentDate = new Date();

        this.fromDatePicker.current.cancelFocusInput();
        this.fromDatePicker.current.setOpen(false);
        this.toDatePicker.current.cancelFocusInput();
        this.toDatePicker.current.setOpen(false);

        if (from.value && !moment(from.value, this.props.dateFormat.toLocaleUpperCase(), true).isValid()) {
            this.setState({
                from: this.formatDate(moment(currentDate)),
            });
            this.fromDatePicker.current.setSelected(moment(currentDate, this.props.dateFormat.toLocaleUpperCase()));
        }

        if (to.value && !moment(to.value, this.props.dateFormat.toLocaleUpperCase(), true).isValid() || (from.value && fromValue > toValue)) {
            this.setState({
                to: this.formatDate(moment(currentDate)),
            });
            this.toDatePicker.current.setSelected(moment(currentDate, this.props.dateFormat.toLocaleUpperCase()));
        }

        this.props.handleChange(this.state);
    }

    @autobind
    dateFromRangeChanged(value) {
        this.dateRangeChanged('from', value);
    }

    @autobind
    dateToRangeChanged(value) {
        this.dateRangeChanged('to', value);
    }

    @autobind
    dateRangeChanged(type, value) {
        const { defaultDate } = this.props;
        const valueIsMoment = moment(value);

        const typeDateMap = {
            from: this.fromDatePicker.current.setSelected,
            to: this.toDatePicker.current.setSelected,
        };

        const defaultValue = defaultDate ? moment(defaultDate) : defaultDate;
        const date = (valueIsMoment && valueIsMoment.year() > 0) ? this.formatDate(valueIsMoment) : defaultValue;
        const endDate = type => {
            let newDate = null;
            const tempDate = new Date(date);
            const endDate = new Date(this.state.to);

            if (type === 'from' && this.state.to) {
                newDate = endDate <= tempDate ? date : this.state.to;
            } else if (type === 'to') {
                newDate = date;
            }
            return newDate;
        };

        const newState = {
            from: type === 'from' ? date : this.state.from,
            to: endDate(type),
        };

        if (defaultDate && (!valueIsMoment || valueIsMoment.year() === 0)) {
            typeDateMap[type](date);
        } else {
            this.setState(newState);
        }

        this.props.handleChange(newState);
    }

    @autobind
    formatDate(value) {
        // ToDo: Not Work
        const { separator } = this.props;

        return separator ? value.format(`YYYY${ separator }MM${ separator }DD`) : value.format('YYYY-MM-DD') + ' 00:00:00';
    }

    render() {
        const {
            minDate,
            maxDate,
            labels,
            dateFormat,
        } = this.props;

        const {
            start = 'Start',
            end = 'End',
        } = labels || {};

        const {
            from,
            to,
        } = this.state;

        return (
            <div>
                <FormGroup id={ this.props.controlId }
                    className={ `voc-datepicker
                           ${ this.props.noMargin && 'no-margin' }
                           ${ this.props.isHorizontal && 'range-datepicker__horizontal' }` }>
                    <Row>
                        {
                            this.props.withLabel
                            && <Col md={ this.props.isHorizontal ? 0 : 3 }
                                className="clearfix datepicker-control-label">
                                <label className={ 'range-datepicker__label' }>{ start }:</label>
                            </Col>
                        }
                        <Col md={ this.props.isHorizontal ? 0 : 9 } className={ 'datepicker-control-wrapper' }>
                            <DatePicker
                                ref={ this.fromDatePicker }
                                className="voc-datepicker form-control col-md-12"
                                selectsStart
                                selected={ from ? moment(from || new Date()).toDate() : null }
                                startDate={ from ? moment(from || new Date()).toDate() : null }
                                endDate={ to ? moment(to || new Date()).toDate() : null }
                                minDate={ minDate }
                                maxDate={ to ? moment(to || new Date()).toDate() : maxDate }
                                dateFormat={ dateFormat }
                                onChange={ this.dateFromRangeChanged }
                                onClickOutside={ this.clickOutside }
                                placeholderText={ HelperService.defaultDateMask }
                                disabledKeyboardNavigation
                                utcOffset={ this.offset }
                                popperClassName={ cx('popperClassName', 'voc-datepicker') }
                                portalId={ 'root-portal' }
                            />
                        </Col>
                        { !this.props.isHorizontal && <div className="datepicker-control__spacer"/> }
                        {
                            this.props.withLabel
                            && <Col md={ this.props.isHorizontal ? 0 : 3 }
                                className="clearfix datepicker-control-label">
                                <label className={ 'range-datepicker__label' }>{ end }:</label>
                            </Col>
                        }
                        <Col md={ this.props.isHorizontal ? 0 : 9 } className={ 'datepicker-control-wrapper' }>
                            <DatePicker
                                ref={ this.toDatePicker }
                                className="form-control col-md-12"
                                selectsEnd
                                selected={ to ? moment(to || new Date()).toDate() : null }
                                startDate={ from ? moment(from || new Date()).toDate() : null }
                                endDate={ to ? moment(to || new Date()).toDate() : null }
                                minDate={ from ? moment(from || new Date()).toDate() : minDate }
                                maxDate={ maxDate }
                                dateFormat={ dateFormat }
                                onChange={ this.dateToRangeChanged }
                                onClickOutside={ this.clickOutside }
                                placeholderText={ HelperService.defaultDateMask }
                                popperClassName={ cx('popperClassName', 'voc-datepicker') }
                                disabledKeyboardNavigation
                                utcOffset={ this.offset }
                                popperProps={{
                                    positionFixed: true,
                                }}
                                portalId={ 'root-portal' }
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        );
    }
}

RangeDatePicker.defaultProps = {
    utcOffset: 0,
    defaultDate: null,
    withLabel: true,
    enablePlaceholder: false,
    noMargin: false,
    minDate: null,
    maxDate: null,
};

RangeDatePicker.propTypes = {
    dateFormat: PropTypes.string,
    withLabel: PropTypes.bool,
    labels: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    isHorizontal: PropTypes.bool,
    noMargin: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
};
