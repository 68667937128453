import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';
import { selectedListSelector, filtersSelector } from './exportResults.selectors';

export const EXPORT_RESULTS_REQUEST = 'EXPORT_RESULTS_REQUEST';
export const EXPORT_RESULTS_SUCCESS = 'EXPORT_RESULTS_SUCCESS';
export const EXPORT_RESULTS_ERROR = 'EXPORT_RESULTS_ERROR';

export const CLEANUP_RESULTS_PAGE = 'CLEANUP_SURVEYS_PAGE';
export const EXPORT_RESULTS_FILTERS_CHANGED = 'EXPORT_RESULTS_FILTERS_CHANGED';

export const ADD_TO_SELECTED = 'ADD_TO_SELECTED';
export const REMOVE_FROM_SELECTED = 'REMOVE_FROM_SELECTED';
export const CLEAR_SELECTION = 'CLEAR_SELECTION';

export const EXPORT_SURVEY_REQUEST = 'EXPORT_SURVEY_REQUEST';
export const EXPORT_SURVEY_SUCCESS = 'EXPORT_SURVEY_SUCCESS';
export const EXPORT_SURVEY_ERROR = 'EXPORT_SURVEY_ERROR';

export const GET_STATISTICS_SUMMARY_REQUEST = 'GET_STATISTICS_SUMMARY_REQUEST';
export const GET_STATISTICS_SUMMARY_SUCCESS = 'GET_STATISTICS_SUMMARY_SUCCESS';
export const GET_STATISTICS_SUMMARY_ERROR = 'GET_STATISTICS_SUMMARY_ERROR';

export const NEW_EXPORT_SURVEY_REQUEST = 'NEW_EXPORT_SURVEY_REQUEST';
export const NEW_EXPORT_SURVEY_SUCCESS = 'NEW_EXPORT_SURVEY_SUCCESS';
export const NEW_EXPORT_SURVEY_ERROR = 'NEW_EXPORT_SURVEY_ERROR';

function getInitialState() {
    return {
        loading: false,
        filters: {
            channel: 'WEB',
            page: 1,
            limit: 10,
            range: 'creation',
            sort_field: '',
            sort_order: '',
            search: '',
            start_time: HelperService.formatDate(getYesterday(), 'YYYY-MM-DD'),
            end_time: HelperService.formatDate(new Date(), 'YYYY-MM-DD'),
            show_only_deleted_survey_export: false,
        },
        results: [],
        selected: [],
        summary: {
            loading: null,
            statistics: {},
        },
        count: 0,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case EXPORT_RESULTS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EXPORT_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.response,
            };

        case EXPORT_RESULTS_ERROR:
            return {
                ...state,
                loading: false,
            };

        case EXPORT_RESULTS_FILTERS_CHANGED:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };

        case ADD_TO_SELECTED: {
            return {
                ...state,
                selected: [ ...state.selected, action.data ],
            };
        }

        case REMOVE_FROM_SELECTED: {
            return {
                ...state,
                selected: state.selected.filter(item => item.id !== action.data),
            };
        }

        case CLEAR_SELECTION: {
            return {
                ...state,
                selected: [],
            };
        }

        case GET_STATISTICS_SUMMARY_REQUEST: {
            return {
                ...state,
                summary: {
                    loading: true,
                    statistics: {},
                },
            };
        }

        case GET_STATISTICS_SUMMARY_SUCCESS: {
            const statistics = action.response[action.data.survey__channel__name];

            return {
                ...state,
                summary: {
                    loading: false,
                    statistics: {
                        ...HelperService.replaceKey(statistics, 'aborted', 'partial'),
                    },
                },
            };
        }

        case GET_STATISTICS_SUMMARY_ERROR: {
            return {
                ...state,
                summary: {
                    loading: false,
                },
            };
        }

        case CLEANUP_RESULTS_PAGE: {
            const {
                filters,
            } = getInitialState();

            return {
                ...getInitialState(),
                filters: {
                    ...filters,
                    channel: state.filters.channel,
                },
            };
        }

        default:
            return state;
    }
}

export function getExportResults(body) {
    return {
        [CALL_API]: {
            endpoint: `/statistics/survey/questionnaire/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ EXPORT_RESULTS_REQUEST, EXPORT_RESULTS_SUCCESS, EXPORT_RESULTS_ERROR ],
            body,
            apiType: FEEDBACK,
        },
    };
}

export function exportSurveyRequest(body) {
    return {
        [CALL_API]: {
            endpoint: `/export/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ EXPORT_SURVEY_REQUEST, EXPORT_SURVEY_SUCCESS, EXPORT_SURVEY_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Selected surveys were successfully added to queue',
                error: 'Something went wrong',
            },
        },
    };
}

export function newExportSurveyRequest(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-info/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ NEW_EXPORT_SURVEY_REQUEST, NEW_EXPORT_SURVEY_SUCCESS, NEW_EXPORT_SURVEY_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Selected surveys were successfully added to queue',
                error: 'Something went wrong',
            },
        },
    };
}

export function getStatisticsSummary(body) {
    return {
        [CALL_API]: {
            endpoint: `/statistics/summary/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_STATISTICS_SUMMARY_REQUEST, GET_STATISTICS_SUMMARY_SUCCESS,GET_STATISTICS_SUMMARY_ERROR ],
            body,
            apiType: FEEDBACK,
        },
    };
}

export function updateFilters(payload) {
    return {
        type: EXPORT_RESULTS_FILTERS_CHANGED,
        payload,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_RESULTS_PAGE,
    };
}

export function addToSelected(item) {
    return {
        type: ADD_TO_SELECTED,
        data: item,
    };
}

export function removeFromSelected(id) {
    return {
        type: REMOVE_FROM_SELECTED,
        data: id,
    };
}

export function clearSelection() {
    return {
        type: CLEAR_SELECTION,
    };
}

export function createExports(exporters) {
    return (dispatch, getState) => {
        const state = getState();
        const selectedSurveys = selectedListSelector(state);
        const filters = filtersSelector(state);
        const params = selectedSurveys.reduce((acc, survey) => {
            exporters.forEach(exporter => {
                //todo remove after delete old exporters
                const date_from = HelperService.convertToRequestDate(filters.start_time);
                const date_to = HelperService.convertToRequestDate(filters.end_time, false);
                let exporterData = {
                    exporter: String(exporter.value),
                    date_start: date_from,
                    date_end: date_to,
                };

                if(exporter.isNewExporter) {
                    exporterData = {
                        export_config: exporter.value.split(' ')[0],
                        date_to,
                        date_from,
                    };
                }

                const temp = {
                    ...exporterData,
                    unit: survey.unit,
                    survey: survey.id,
                };

                acc[exporter.isNewExporter ? 'new': 'old'].push(temp);
            });

            return acc;
        }, { new: [], old: [] });

        const request = [];

        if(params.new.length) request.push(dispatch(newExportSurveyRequest(params.new)).promise);
        if(params.old.length) request.push(dispatch(exportSurveyRequest(params.old)).promise);

        return Promise.all(request);
    };
}

function getYesterday() {
    const date = new Date();

    date.setDate(date.getDate() - 1);
    return date;
}
