import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { HelperService } from '/services';

import { IFooterInfo } from './models';

import styles from './style.module.scss';

export const FooterInfo = ({ records, lastHealthCheckTime }: IFooterInfo) => {
    const { t } = useTranslation();
    const isRecordsFieldExist = HelperService.checkNotNullOrUndefined(records);

    return (
        <div className={ cx(styles.footerInfo, {
            [styles.left]: isRecordsFieldExist && !lastHealthCheckTime,
            [styles.right]: lastHealthCheckTime && !isRecordsFieldExist,
        }) }>
            {
                isRecordsFieldExist
                && <span className={ styles.recordsLabel }>
                    { `${ t('field.records') }: ${ records }` }
                </span>
            }

            {
                lastHealthCheckTime
                && <span className={ styles.lastHealthCheckTime }>
                    { lastHealthCheckTime }
                </span>
            }
        </div>
    );
};
