import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { VocTableV2 } from '/components';
import { LayoutNameFormatter, LayoutActionsFormatter } from '../';

export class LayoutsListTable extends Component {
    settings = [
        {
            field: 'name',
            title: 'Name',
            formatter: this.LayoutNameFormatter,
            sort: false,
        },
        {
            field: 'id',
            title: 'Actions',
            formatter: this.tableActionsFormatter,
            style: {
                width: '130px',
                textAlign: 'center',
            },
        },
    ];

    @autobind
    LayoutNameFormatter(cell, obj) {
        return (
            <LayoutNameFormatter
                layout={ obj }
                openModalManage={ this.props.openModalManage }
            />
        );
    }

    @autobind
    tableActionsFormatter(cell, obj) {
        return (
            <LayoutActionsFormatter
                layout={ obj }
                openModalManage={ this.props.openModalManage }
                openConfirmDelete={ this.props.openConfirmDelete }
                openModalCopy={ this.props.openModalCopy }
                permission={ this.props.permission }
            />
        );
    }

    getTableOptions() {
        return {
            requestTrigger: this.props.requestTrigger,
            updateFilters: this.props.updateFilters,
            haveSearch:true,
        };
    }

    render() {
        const {
            tableState,
            filters,
            data,
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                options={ this.getTableOptions() }
                filters={ filters }
                data={ data }
                total={ tableState.count }
                loading={ tableState.loading }
            />
        );
    }
}
