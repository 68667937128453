import React, { PureComponent } from 'react';
import { components } from 'react-select';
import './MenuList.scss';

export class MenuList extends PureComponent {
    render() {
        const configMenuList = {
            ...this.props,
            className: (this.props.isLoading && this.props.options.length === 0) ? 'select-initial-menu' : '',
        };

        return (
            <components.MenuList { ...configMenuList }>
                { this.props.children }
            </components.MenuList>
        );
    }
}
