// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import uuidv4 from 'uuid/v4';

import { TInjectDigiRunnerUrl, TShowDigiPopup, TUpdateRunnerFullScreenStyles } from './models';

export class PreviewService {
    static injectDigiRunnerUrl({ path, onLoadHandler, iframeRef }: TInjectDigiRunnerUrl){
        const iframeDocument = iframeRef.current?.contentWindow?.document;

        if(!iframeDocument) return;

        const scriptName = 'digi_runner.js';
        const script = iframeDocument.getElementById('digiRunnerUrl');

        if (!script) {
            const runner = iframeDocument.createElement('script');

            runner.onload = onLoadHandler;
            runner.setAttribute('id', 'digiRunnerUrl');
            runner.setAttribute('src', path + `/${scriptName}`);
            iframeDocument.head.appendChild(runner);
        }
    }
    static showDigiPopup({ language, id, apiUrl, iframeRef, setLoadingRunner, selectedThemeId }: TShowDigiPopup) {
        const iframeWindow = iframeRef.current?.contentWindow?.window;

        if (!iframeWindow?.VocSignal_ListenEvent) {
            return;
        }

        iframeWindow.VocSignal_ListenEvent('SetParametersCallback', () => {
            iframeWindow.VocSignal_SendEvent('InitBySettings');
            setLoadingRunner(false);
        });

        iframeWindow.VocSignal_SendEvent('SetParameters', {
            apiUrl,
            customerId: 'popup_preview_mode' + uuidv4(),
            language: language,
            surveyId: id,
            selectedThemeId,
            isPreviewMode: true,
        });
    }

    static updateRunnerFullScreenStyles({ iframeRef, viewType }: TUpdateRunnerFullScreenStyles){
        const iframeDocument = iframeRef?.current?.contentWindow?.document;

        if(!iframeDocument) return;

        const isStyleExist = iframeDocument.getElementById('digiRunnerFullScreenStyle');

        if(isStyleExist) {
            isStyleExist.remove();
        }

        if(viewType === 'full-screen') {
            const style = iframeDocument.createElement('style');

            style.setAttribute('id', 'digiRunnerFullScreenStyle');

            style.innerHTML = `
                #VocSignalApp .SandSIV_Feedback_Modal {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: auto;
                    width: auto;
                    transform: none;
                }
    
                #VocSignalApp .SandSIV_Feedback_Modal_Close {
                    display: none;
                }
    
                .supportMessage {
                    width: 100%;
                    margin-top: 100px;
                    text-align: center;
                }
            `;

            iframeDocument.head.appendChild(style);
        }
    }
}
