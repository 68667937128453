import moment from 'moment';
import tinyColor from 'tinycolor2';

export class HelperService {
    static capturedAtMask = 'D MMM YYYY';
    static datePickerMask = 'yyyy-MM-dd';
    static defaultDateTimeMask = 'YYYY-MM-DD HH:mm';
    static defaultDateMask = 'YYYY-MM-DD';
    static defaultFont = "10px sans-serif";

    static format(str, ...args) {
        return str.replace(/{(\d+)}/g, (match, number) => {
            return typeof args[number] !== 'undefined' ? args[number] : match;
        });
    }

    static isNil(value) {
        return value == null;
    }

    static formatDate(date, mask, currentDateFormat) {
        if (currentDateFormat) {
            return moment(date, currentDateFormat).format(mask);
        } else {
            return moment(date).format(mask);
        }
    }

    static copyToClipboard(text) {
        const elem = document.createElement('textarea');

        elem.value = text;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
    }

    static cancelPrintAction(printEvent, printAction) {
        if (printEvent.keyCode === 80
            && (printEvent.ctrlKey || printEvent.metaKey)
            && !printEvent.altKey
            && (!printEvent.shiftKey || window.chrome || window.opera)
        ) {
            printEvent.preventDefault();

            if (printEvent.stopImmediatePropagation) {
                printEvent.stopImmediatePropagation();
            } else {
                printEvent.stopPropagation();
            }

            printAction?.();
        }
    }

    static convertToRequestDate(date, start = true) {
        const dateDict = {
            endTime: ' 23:59:59',
            startTime: ' 00:00:00',
        };

        return moment(date).format('YYYY-MM-DD') + (start ? dateDict.startTime : dateDict.endTime);
    }

    static getColorGradation(colorData, colorsCount = 6) {
        const colorList = [];
        const { brighten, color, colors } = colorData;
        const num = (Number(brighten || -15) + 46) / colorsCount;

        // from "start" color to "end" (e.x. from "red" to "green")
        if (color && !brighten && colors && colors.length > 0) {
            for (let i = 0; i < colorsCount; i++) {
                if (colorsCount === 1 || (colorsCount === 3 && i === 1)) {
                    // middle slice color (if total slices equal three items, or it's just one item in barGroup)
                    colorList.push(tinyColor(colors[Math.floor((colors.length - 1) / 2)]).toHexString());
                } else if (i === 0) {
                    // first slice color
                    colorList.push(tinyColor(colors[0]).toHexString());
                } else if (i === colorsCount - 1) {
                    // last slice color
                    colorList.push(tinyColor(colors[colors.length - 1]).toHexString());
                } else if (((colorsCount - 1) / 2) >= i) {
                    const step = i + 1;
                    const middle = tinyColor(colors[Math.floor((colors.length - 1) / 2)]).toRgb();
                    const start = tinyColor(colors[0]).toRgb();
                    const r = (middle.r - start.r) / (colorsCount / 2);
                    const g = (middle.g - start.g) / (colorsCount / 2);
                    const b = (middle.b - start.b) / (colorsCount / 2);
                    const newColor = {
                        r: start.r + (step * r),
                        g: start.g + (step * g),
                        b: start.b + (step * b),
                    };

                    colorList.push(tinyColor(newColor).toHexString());
                } else {
                    const step = i - ((colorsCount - 1) / 2);
                    const middle = tinyColor(colors[Math.floor((colors.length - 1) / 2)]).toRgb();
                    const end = tinyColor(colors[colors.length - 1]).toRgb();
                    const r = (end.r - middle.r) / (colorsCount / 2);
                    const g = (end.g - middle.g) / (colorsCount / 2);
                    const b = (end.b - middle.b) / (colorsCount / 2);
                    const newColor = {
                        r: middle.r + (step * r),
                        g: middle.g + (step * g),
                        b: middle.b + (step * b),
                    };

                    colorList.push(tinyColor(newColor).toHexString());
                }
            }
        } else if (colors) {
            // gradient of chosen colors (after setting the given colors, these colors are darkened in order)
            const colorsRange = colors.length;

            for (let i = 0; i < colorsCount; i++) {
                if (i < colorsRange) {
                    colorList.push(colors[i]);
                } else {
                    const colorIndex = i % colorsRange;
                    const contrast = Math.floor(i / colorsRange) * colorsRange;

                    colorList.push(tinyColor(colors[colorIndex]).darken(num * contrast).toHexString());
                }
            }
        } else {
            // gradient of chosen color (opacity)
            for (let i = 0; i < colorsCount; i++) {
                colorList.push(tinyColor(color).brighten(i * num).toHexString());
            }
        }

        return colorList;
    }

    static getContrastYIQ(hexColor) {
        hexColor = hexColor.replace("#", "");
        const r = parseInt(hexColor.substr(0,2),16);
        const g = parseInt(hexColor.substr(2,2),16);
        const b = parseInt(hexColor.substr(4,2),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;

        return (yiq >= 128) ? 'black' : 'white';
    }

    static getFromStorage(storage, key) {
        const value = storage.getItem(key);

        return (typeof value === 'string' && value.startsWith('{'))
            ? JSON.parse(value)
            : value;
    }

    static popFromStorage(storage, key) {
        const item = storage.getItem(key);

        storage.removeItem(key);
        return item;
    }

    static setInStorage(storage, key, value) {
        const valueToStore = typeof value === 'object' ? JSON.stringify(value) : value;

        return storage.setItem(key, valueToStore);
    }

    static formatDefaultDate(date) {
        return moment(date).format(HelperService.datePickerMask.toLocaleUpperCase());
    }

    static formatCapturedAtDate(date) {
        return moment(date).format(HelperService.capturedAtMask);
    }

    static debounce(func, wait, immediate) {
        let timeout;

        return function() {
            const context = this, args = arguments;
            const later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    static capittalize(str) {
        return str[0].toUpperCase() + str.slice(1);
    }

    static throttle(callback, delay) {
        let isThrottled = false, args, context;

        function wrapper() {
            if (isThrottled) {
                args = arguments;
                context = this;
                return;
            }

            isThrottled = true;
            callback.apply(this, arguments);

            setTimeout(() => {
                isThrottled = false;
                if (args) {
                    wrapper.apply(context, args);
                    args = context = null;
                }
            }, delay);
        }

        return wrapper;
    }

    static deepFind(obj, path, def = '') {
        const paths = path.split('.');
        let current = obj || {};

        for (let i = 0; i < paths.length; ++i) {
            if (!current[paths[i]]) {
                return def;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    }

    //Find diff of arrays of primitive values
    static arrDiff(arr1, arr2) {
        return arr1.filter(arr1Item => !arr2.find(arr2Item => arr2Item === arr1Item));
    }

    static pick(object, props) {
        const temp = {};

        props.forEach(prop => temp[prop] = object[prop]);
        return temp;
    }

    static takeFilled(object) {
        const result = {};

        Object.keys(object).filter(el => {
            if (!!object[el]) {
                result[el] = object[el];
            }
        });

        return result;
    }

    static removeNull(obj) {
        const newObj = {};

        Object.keys(obj).forEach(prop => {
            if (obj[prop] !== null) {
                newObj[prop] = obj[prop];
            }
        });

        return newObj;
    }

    static removeEmptyValues(obj) {
        const newObj = {};

        Object.keys(obj).forEach(prop => {
            if (HelperService.checkNotNullOrUndefined(obj[prop]) && obj[prop] !== '') {
                newObj[prop] = obj[prop];
            }
        });

        return newObj;
    }

    static checkNotEmpty(value) {
        return HelperService.checkNotNullOrUndefined(value) && value !== '' && !Number.isNaN(value);
    }

    static fromString(value) {
        if (Array.isArray(value)) {
            return value;
        }

        switch (value) {
            case null:
            case undefined:
            case 'NaN':
            case 'null':
            case 'undefined':
            case '':
                return null;
            case true:
            case 'true':
                return true;
            case false:
            case 'false':
                return false;

            default:
                return (!isNaN(+value))
                    ? parseInt(value, 10)
                    : value;
        }
    }

    static isObjectValue(value) {
        return value && typeof value === 'object' && value.constructor === Object;
    }

    static getPossibleChannels() {
        return [ 'WEB', 'SMS', 'IVR', 'DIGI' ];
    }

    static mapUnitsToSelectModel(units) {
        return units.map(unit => ({
            value: String(unit.id),
            label: unit.name,
        }));
    }

    static mapProjectsToSelectModel(units) {
        return units.map(unit => ({
            value: String(unit.id),
            label: unit.name,
        }));
    }

    static mapArrayToSelectModel(array) {
        return array.map(value => ({
            value: value,
            label: value,
        }));
    }

    static mapResponseServerError(error, showToast, dispatch, displayFieldName = true) {
        return Object.keys(error.data).forEach(key => {
            const isListError = Array.isArray(error.data[key]);
            let errorText = isListError
                ? error.data[key][0]
                : error.data[key];

            if (displayFieldName && isListError) {
                const fieldName = key.charAt(0).toUpperCase() + key.slice(1);

                errorText = `${ fieldName } : ${ errorText }`;
            }

            return dispatch(showToast({ text: errorText, type: 'error' }));
        });
    }

    static uniqeArray(array, key) {
        return array.reduce((acc, item) => {
            const conditionToInclude = key
                ? !acc.find(x => x[key] === item[key])
                : !acc.includes(item);

            if (conditionToInclude) {
                acc.push(item);
            }

            return acc;
        }, []);
    }

    static authErrorHandler(error, dispatch) {
        if (error.status === 401 || error.status === 403) {
            const temp = {
                type: 'AUTH_ERROR',
                data: {
                    type: error.status,
                    text: error.statusText,
                },

            };

            dispatch(temp);
        }
    }

    static screenTagsWithinSymbols = string => {
        const matched = matched => {
            return matched
                .replace(/\[\[/gi, '').replace(/</gi, '&lt;')
                .replace(/\]\]/gi, '').replace(/</gi, '&lt;');
        };

        return string.replace(/\[\[(\n|.)+?\]\]/gi, matched);
    };

    static parseHtmlString = (string, showAttribute) => {
        const compose = (...functions) => args => functions.reduce((arg, fn) => fn(arg), args);
        const removeEvents = [ 'onerror', 'onload' ];

        const screenTags = tagsArray => string => {
            let resultString = string;
            const matched = matched => matched.replace(/</gi, '&lt;');

            tagsArray.forEach(tagName => {
                if(tagName === 'img') {
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(resultString, 'text/html');

                    htmlDoc
                        .querySelectorAll('img')
                        .forEach(img => {
                            removeEvents.forEach(event => {
                                const replaceAttr = `${event}_preview`;

                                if(img.hasAttribute(event) && !showAttribute) {
                                    img.setAttributeNS(
                                        event,
                                        replaceAttr,
                                        img.getAttribute(event),
                                    );
                                    img.removeAttribute(event);
                                }

                                if(img.hasAttribute(replaceAttr) && showAttribute) {
                                    img.setAttributeNS(
                                        replaceAttr,
                                        event,
                                        img.getAttribute(replaceAttr),
                                    );
                                    img.removeAttribute(replaceAttr);
                                }
                            });
                        });

                    resultString = htmlDoc.body.innerHTML;
                } else {
                    const regex = new RegExp(`</?${ tagName }/?>?`, 'gi');

                    resultString = resultString.replace(regex, matched);
                }
            });

            return resultString;
        };

        return string
            ? compose(HelperService.screenTagsWithinSymbols, screenTags([ 'img' ]))(string)
            : string;
    };

    static getFromSessionStorage(key) {
        return JSON.parse(HelperService.fromString(window.sessionStorage.getItem(key)));
    }

    static decodeHtmlFromString(string) {
        return string.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }

    static flattenDeep(collection) {
        return collection.reduce((acc, val) => (val.options && Array.isArray(val.options))
            ? acc.concat(HelperService.flattenDeep(val.options))
            : acc.concat(val), []);
    }

    static getGroupedSelectedValue(collection, value) {
        const flattenCollection = HelperService.flattenDeep(collection);
        const fixedValueItem = flattenCollection.find(item => item.value === `fixed-` + value);

        return fixedValueItem
            ? fixedValueItem
            : flattenCollection.find(item => item.value === value);
    }

    static getSelectedValue(collection = [], value, convertToFlat) {
        const flatCollection = convertToFlat
            ? collection.reduce((acc, currentValue) => [ ...acc, ...currentValue.options ], [])
            : [ ...collection ];

        if (Array.isArray(value) && typeof value[0] !== 'object') {
            return value.map(valueItem => flatCollection.find(item => item.value === valueItem));
        }

        if ((typeof value === 'object' && value !== null ) || value?.constructor === Object) {
            return value;
        }

        const collectionItem = flatCollection.find(item => item.value === value);

        return collectionItem ? collectionItem : null;
    }

    static joinRoute(base, path) {
        return base.charAt(base.length - 1) === '/'
            ? base.slice(0, -1) + path
            : base + path;
    }

    static normalizeSelectValue(option) {
        return option && option.value;
    }

    static normalizeMultiSelectValue(option) {
        return option && option.map(({ value }) => value);
    }

    static normalizeTextValue(value) {
        return value.trimStart();
    }

    static normalizeSelectMultiValue(option) {
        return option && option.reduce((acc, el) => {
            return [ ...acc, el.value ];
        }, []);
    }

    static getOptionsWithAllProps(options, value, label) {
        if (!options) return [];
        return options.map(val => {
            return {
                ...val,
                value: val[value],
                label: val[label ? label : value],
            };
        });
    }

    static normalizeBoolean(value) {
        if (value === "true") {
            return true;
        }

        if (value === "false") {
            return false;
        }

        return value;
    }

    static mapValueToLabel(arr, value) {
        const map = arr.reduce((acc, val) => {
            acc[val.value] = val.label;
            return acc;
        }, {});

        return map[value];
    }

    static validateEmail(email) {
        // eslint-disable-next-line max-len
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return regex.test(String(email).toLowerCase());
    }

    static validatePhone(phone) {
        const regex = /^[+]+[0-9]{9,15}$/;

        return regex.test(String(phone).toLowerCase());
    }

    static replaceKey(obj, replaceable, replacing) {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key === replaceable ? replacing : key] = obj[key];
            return acc;
        }, {});
    }

    static mapObjToSelect(arr, value, label, callback = val => val) {
        if (!arr) return [];
        return arr.reduce((acc, val) => {
            value
                ? acc.push({
                    value: val[value],
                    label: callback(val[label ? label : value]),
                })
                : acc.push({
                    value: val,
                    label: callback(val),
                });

            return acc;
        }, []);
    }

    static mapObjToSelectWithNull(arr, value, label, textForNull) {
        if (!arr) return [];
        return arr.reduce((acc, val) => {
            value
                ? acc.push({
                    value: val[value] !== null ? val[value] : textForNull,
                    label: val[value] !== null ? val[label] : textForNull,
                })
                : acc.push({
                    value: val !== null ? val : textForNull,
                    label: val !== null ? val : textForNull,
                });

            return acc;
        }, []);
    }

    static mixData({ param, key, conditionKey, obj, target }) {
        return obj.map(el => el[key] === conditionKey
            ? {
                ...el,
                [target]: {
                    ...param,
                },
            }
            : el,
        );
    }

    static removeData({ key, conditionKey, obj, target }) {
        return obj.map(el => el[key] === conditionKey
            ? {
                ...el,
                [target]: null,
            }
            : el,
        );
    }

    static sortObject(array, key, condition) {
        return array.sort((a, b) => condition.indexOf(a[key]) - condition.indexOf(b[key]));
    }

    static sortArrayWithObjects(array, objectKey, sortDirection, useLowerCase) {
        return !sortDirection
            ? array
            : useLowerCase
                ? array.sort((a, b) => {
                    const aValue = typeof a[objectKey] === "string" ? a[objectKey].toLowerCase() : a[objectKey];
                    const bValue = typeof b[objectKey] === "string" ? b[objectKey].toLowerCase() : b[objectKey];

                    return (sortDirection === 'asc'
                        ? aValue > bValue
                        : aValue < bValue) && 1 || -1;
                })
                : array.sort((a, b) => (sortDirection === 'asc'
                    ? a[objectKey] > b[objectKey]
                    : a[objectKey] < b[objectKey]) && 1 || -1,
                );
    }

    static deepCompare() {
        let i, l, leftChain, rightChain;

        function compare2Objects(x, y) {
            let p;

            if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
                return true;
            }

            if (x === y) {
                return true;
            }

            if ((typeof x === 'function' && typeof y === 'function')
                || (x instanceof Date && y instanceof Date)
                || (x instanceof RegExp && y instanceof RegExp)
                || (x instanceof String && y instanceof String)
                || (x instanceof Number && y instanceof Number)) {
                return x.toString() === y.toString();
            }

            if (!(x instanceof Object && y instanceof Object)) {
                return false;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
                return false;
            }

            if (x.constructor !== y.constructor) {
                return false;
            }

            if (x.prototype !== y.prototype) {
                return false;
            }

            if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
                return false;
            }

            for (p in y) {
                // eslint-disable-next-line no-prototype-builtins
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                } else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }
            }

            for (p in x) {
                // eslint-disable-next-line no-prototype-builtins
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                } else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }

                switch (typeof (x[p])) {
                    case 'object':
                    case 'function':

                        leftChain.push(x);
                        rightChain.push(y);

                        if (!compare2Objects(x[p], y[p])) {
                            return false;
                        }

                        leftChain.pop();
                        rightChain.pop();
                        break;

                    default:
                        if (x[p] !== y[p]) {
                            return false;
                        }
                        break;
                }
            }

            return true;
        }

        if (arguments.length < 1) {
            return true;
        }

        for (i = 1, l = arguments.length; i < l; i++) {
            leftChain = [];
            rightChain = [];

            if (!compare2Objects(arguments[0], arguments[i])) {
                return false;
            }
        }

        return true;
    }

    static shallowCompare(prev, next) {
        return Object.keys({ ...prev, ...next }).every(key => prev[key] === next[key]);
    }

    static getIdFromTree(obj) {
        return obj.reduce((acc, { children, id }) => {
            const idList = [];

            if (children.length) {
                children.map(el => idList.push(el.id));
            }

            return [
                ...acc,
                {
                    root: id,
                    children: idList,
                },
            ];
        }, []);
    }

    static getDefaultSettings(array) {
        const temp = {};

        array.forEach(setting => temp[setting] = null);
        return temp;
    }

    static getDeferredPromise() {
        let deferredResolve, deferredReject;
        const promise = new Promise((resolve, reject) => {
            deferredResolve = resolve;
            deferredReject = reject;
        });

        return {
            deferredResolve,
            deferredReject,
            promise,
        };
    }

    static deepEqual(a, b) {
        if ((typeof a == 'object' && a != null)
            && (typeof b == 'object' && b != null)) {
            const count = [ 0, 0 ];

            // eslint-disable-next-line no-unused-vars
            for (const key in a) count[0]++;
            // eslint-disable-next-line no-unused-vars
            for (const key in b) count[1]++;
            if (count[0] - count[1] != 0) {
                return false;
            }
            for (const key in a) {
                if (!(key in b) || !HelperService.deepEqual(a[key], b[key])) {
                    return false;
                }
            }
            for (const key in b) {
                if (!(key in a) || !HelperService.deepEqual(b[key], a[key])) {
                    return false;
                }
            }
            return true;
        } else {
            return a === b;
        }
    }

    static getComputedTranslateY(obj) {
        const style = getComputedStyle(obj);
        const { transform } = style;
        let mat = transform.match(/^matrix3d\((.+)\)$/);

        if (mat) {
            return parseFloat(mat[1].split(', ')[13]);
        }
        mat = transform.match(/^matrix\((.+)\)$/);
        return mat ? parseFloat(mat[1].split(', ')[5]) : 0;
    }

    static filterObjectProperties(obj, props) {
        return Object.keys(obj).reduce((acc, val) => !props.includes(val)
            ? { ...acc, [val]: obj[val] }
            : acc, {},
        );
    }

    static isEmojiInString(string) {
        const regex = /([\u2700-\u27BF]|(?:\uD83C[\uDE00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83D[\uDE80-\uDEFF]|\uD83D[\uDF00-\uDFFF]|\uD83E[\uDD00-\uDDFF]|[\u2600-\u26FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDDFF])[\uFE0E\uFE0F]?(\uD83C[\uDFFF\uDC00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|\uD83D[\uDE00-\uDEFF]|\uD83C[\uDC00-\uDDFF])?)/g;

        return regex.test(string);
    }

    static removeKeyFromObject(obj, key) {
        // eslint-disable-next-line no-unused-vars
        const { [key]: deletedValue, ...rest } = obj;

        return rest;
    }

    static getTextWidth(text, font = this.defaultFont) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        context.font = font;
        const metrics = context.measureText(text);

        return Math.round(metrics.width);
    }

    static trimByWidth(text, maxWidth = 70, font = this.defaultFont) {
        let finalText = '';
        let finalWidth;
        const dotsLength = 10;

        if (this.getTextWidth(text,font) <= maxWidth) {
            return text;
        }

        for (const char of String(text)) {
            finalText += char;
            finalWidth = this.getTextWidth(finalText, font);

            if (finalWidth > (maxWidth - dotsLength)) {
                finalText += '...';
                return finalText;
            }
        }

        return finalText;
    }

    static mapOrder(array = [], order = [], byKey = '') {
        return array.sort((a, b) => {
            if (order?.indexOf(a[byKey]) < 0) {
                return 0;
            }

            return order?.indexOf(a[byKey]) > order?.indexOf(b[byKey])
                ? 1
                : -1;
        });
    }

    static sumBy(array = [], iteratee = item => item) {
        let result = 0;

        array.forEach(item => {
            const currentValue = iteratee(item);

            if (currentValue !== undefined && typeof currentValue === 'number') {
                result = result === 0 ? currentValue : (result + currentValue);
            }
        });

        return result;
    }

    static checkNotNullOrUndefined(value) {
        return value !== undefined && value !== null;
    }

    static isEmptyObject = (obj = {}) => Object.keys(obj).length === 0;

    static getObjectValue = (obj = {}) => Object.values(obj);

    static deepClone(originalObject) {
        if (typeof originalObject !== 'object' || originalObject === null || originalObject instanceof Blob) {
            return originalObject;
        }

        const isArray = Array.isArray(originalObject);
        const clone = isArray ? [] : {};

        if (isArray) {
            for (let i = 0; i < originalObject.length; i++) {
                clone[i] = HelperService.deepClone(originalObject[i]);
            }
        } else {
            for (const key in originalObject) {
                if (Object.prototype.hasOwnProperty.call(originalObject, key)) {
                    clone[key] = HelperService.deepClone(originalObject[key]);
                }
            }
        }

        return clone;
    }
}
