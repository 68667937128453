import React from 'react';
import { SortableItem } from '../';
import { SortableContainer } from 'react-sortable-hoc';
import { ListGroup } from 'reactstrap';

export const SortableList = SortableContainer(props => {
    return (
        <ListGroup tag="ul">
            {
                props.touchpoints.map((touchpoint, index) => {
                    const activeClass = props.selectedTouchPoint && touchpoint.id === props.selectedTouchPoint.id
                        ? 'active'
                        : '';
                    const classList = `list-group-item ${activeClass} manage_projects__list-element`;

                    return (
                        <SortableItem
                            key={ `item-${touchpoint.id}` }
                            index={ index }
                            touchpoint={ touchpoint }
                            classList={ classList }
                            onSelectTouchPoint={ props.onSelectTouchPoint }
                            deleteTouchPoint={ props.deleteTouchPoint }
                        />
                    );
                })
            }
        </ListGroup>

    );
});

