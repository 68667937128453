import { event } from 'd3';
import cx from 'classnames';
import { autobind } from 'core-decorators';

import type {
    barData,
    barSliceData,
    barSliceWithCoordinatesData,
    IDrawStackedBars,
} from '/visual/scenes/Dashboard/components/Gadget/models';

import { HelperService } from '/services';
import { BarChartService } from './BarChartService';
import styles from '../../BaseChart/services/style.module.scss';

export class StackedBarChartService extends BarChartService {
    /* public constants */
    groupClass = 'barStackedGroup';

    /* private methods */
    private getStackedBarsPerGroup(data: barData[]) {
        return data.map((bar: barData) => {
            let offset = 0;
            const bars = bar.bars.map((barSlice: barSliceData, index, selfArray: barSliceData[]) => {
                offset += index ? this._percentToDecimal(selfArray[index - 1].percent) : 0;
                const yStartPosition = this._percentToDecimal(barSlice.percent) + offset;
                const yPosition = offset;
                const xPosition = bar.id;

                return { yPosition, yStartPosition, xPosition, ...barSlice };
            });

            return { ...bar, bars: bars };
        });
    }

    /* protected methods */
    @autobind
    protected _getWidthForSlice() {
        return this.xScale.bandwidth();
    }

    @autobind
    protected _getHeightForSlice(d: barSliceWithCoordinatesData) {
        return this.yScale(d.yPosition) - this.yScale(d.yStartPosition);
    }

    @autobind
    protected _getStartXForSlice(d: barSliceWithCoordinatesData) {
        return this.xScale(d.xPosition);
    }

    @autobind
    protected _getStartYForSlice(d: barSliceWithCoordinatesData) {
        return this.yScale(d.yStartPosition);
    }

    protected _getBarScore(d: barData) {
        const result = d.bars.reduce(
            (result, d: any) => ({
                ...result,
                ...d,
                count: result.count + d.count,
                xPosition: d.xPosition,
                // get start coordinate base on summary of difference each of slice
                yStartPosition: result.yStartPosition + (d.yStartPosition - d.yPosition),
            }),
            { count: 0, customId: d.customId, xPosition: 0, yStartPosition: 0 },
        );

        return [ result ];
    }

    @autobind
    protected _animatedBarSliceWidth() {
        return this._getWidthForSlice() * this.scaleXBand;
    }

    @autobind
    protected _animatedBarSliceX(d: barSliceWithCoordinatesData) {
        const scaledWidth = this._getWidthForSlice() * this.scaleXBand;
        const diffWidth = scaledWidth - this._getWidthForSlice();

        return this._getStartXForSlice(d) - (diffWidth / 2);
    }

    @autobind
    protected _barTextX(d: barSliceWithCoordinatesData) {
        return this._getStartXForSlice(d) + this._getWidthForSlice() / 2;
    }

    @autobind
    protected _barTextY(d: barSliceWithCoordinatesData) {
        const { barTextYNegativeOffset, barTextYPositiveOffset } = this.chartConfig;
        const score = this._getValue(d);
        const offset = score < 0 ? barTextYNegativeOffset : -barTextYPositiveOffset;

        return this._getStartYForSlice(d) + offset;
    }

    @autobind
    protected _barText(d: barSliceWithCoordinatesData) {
        return d.count;
    }

    @autobind
    protected _onItemOver(d: barSliceWithCoordinatesData) {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        this._onItemOverMove();

        const bars = this.chartContainer.selectAll(`.${this.groupItemClass}`);
        const curBar = bars.filter((bar: { customId: string }) => bar.customId === d.customId);

        curBar
            .transition()
            .duration(this.animationDuration)
            .attr('opacity', 0.4)
            .attr('width', this._animatedBarSliceWidth)
            .attr('x', this._animatedBarSliceX);
    }

    @autobind
    protected _onItemOut(d: barSliceWithCoordinatesData) {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        const { target } = event;
        const bars = this.chartContainer.selectAll(`.${this.groupItemClass}`);
        const curBar = bars.filter(( bar: { customId: string }) => bar.customId === d.customId);

        target.classList.remove(this.itemHoveredClass);

        this.toolTipRef
            .style('visibility', 'hidden')
            .html('');

        curBar
            .transition()
            .duration(this.animationDuration)
            .attr('opacity', 1)
            .attr('width', this._getWidthForSlice)
            .attr('x', this._getStartXForSlice);
    }

    /* public methods */
    drawStackedBars({ dataSet = [], groupClass = '' }: IDrawStackedBars) {
        // get list of stacks
        this.groups = dataSet;
        const stackedBars = this.getStackedBarsPerGroup(dataSet);

        this.chartContainer
            .select(`.${this.elementsContainerClass}`)
            .selectAll(`.${this.groupClass}`)
            // a sequence of barSlices in which barGroup can mutate,
            // so d3 should recognize it using the second argument (key) of the "data" method
            .data(stackedBars, (d: barData) => d.bars?.length)
            .join(
                (enter: any) => {
                    // init bar groups and their attributes
                    const barGroups = enter
                        .append('g')
                        .attr('opacity', this._getBarGroupOpacity)
                        .attr('class', cx(this.groupClass, groupClass));

                    this._enterBars(barGroups);

                    // add score at the top of each bar group inside <text /> element
                    if (this._showScore) {
                        barGroups
                            .selectAll(`.${this.scoreClass}`)
                            .data(this._getBarScore)
                            .join('text')
                            .attr('id', (d: any) => `text_${d.customId}`)
                            .attr('class', cx(this.scoreClass, styles.scoreNumber))
                            .attr('x', this._barTextX)
                            .attr('y', this._barTextY)
                            .text(this._barText);
                    }
                },
                (update: any) => {
                    // add new bar group if needed and remove if bar group doesn't exist in dataset
                    update.exit().remove();
                    update.attr('opacity', this._getBarGroupOpacity);

                    this._updateBars(update);

                    update
                        .selectAll(`.${this.groupItemClass}`)
                        .data((d: barData) => d.bars)
                        .exit()
                        .remove();

                    if (this._showScore) {
                        update
                            .selectAll(`.${this.scoreClass}`)
                            .data(this._getBarScore)
                            .attr('x', this._barTextX)
                            .attr('y', this._barTextY)
                            .text(this._barText);
                    }
                },
            );

        return this;
    }
}
