export const VocStoreRootSelector = state => {
    if (state.Segmentator.source.attributes) {
        state.Segmentator.source.attributes = state.Segmentator
            .source
            .attributes
            .filter(attr => attr.originType !== 'service_captured_at' && attr.originType !== 'DATE');
    }

    return state.Segmentator;
};
