import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { _getGraphsData } from '/modules/topicSetModules/topicSet.selectors';
import { TopicSetChartsServices } from '/services';
import { ButtonsPanelSelector, EmptyData } from '/components';
import { BarChart, TableChart, LoaderWrapper } from '../';

import styles from './styles.module.scss';

const viewOptions = [
    {
        label: 'Chart view',
        value: 'chart_view',
        icon: 'barChart',
    },
    {
        label: 'Table view',
        value: 'table_view',
        icon: 'dictionariesBlack',
    },
];

export const VisualBlock = ({ topics }: any) => {
    const [ view, setView ] = useState('chart_view');
    const graphs = useSelector(_getGraphsData);

    const getChart = () => {
        return !!topics?.length && graphs.data
            ? <>
                <ButtonsPanelSelector
                    value={ view }
                    onChange={ viewId => setView(viewId) }
                    options={ viewOptions }
                />

                <hr/>

                {
                    view === 'table_view'
                        ? TopicSetChartsServices
                            .getTableChartData(graphs.data)
                            .map((chartElement: any, inx: number) =>
                                <TableChart key={ inx } chartData={ chartElement }/>,
                            )
                        : TopicSetChartsServices
                            .getBarChartData(graphs.data)
                            .map((chartData: any, inx: number) =>
                                <BarChart key={ inx } chartData={ chartData } />,
                            )
                }
            </>
            : null;
    };

    return (
        <div className={ styles.classifierVisualSection }>
            {
                graphs.loading
                    ? <div className={ styles.preloaderGraphs }>
                        <LoaderWrapper color={ '#f4f4f4' }/>
                    </div>
                    : graphs.error ? <EmptyData errorText={ graphs.error }/> : getChart()
            }
        </div>
    );
};
