import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { SortableItem } from '../';

export const SortableList = SortableContainer(props => {
    return <div className={ 'exporter-sortable__item' }>
        {
            props.fields.map((field, idRow) =>
                <SortableItem
                    key={ `item-${idRow}` }
                    index={ idRow }
                    propsData={{ field, idRow, ...props }}
                />,
            )
        }
    </div>;
});
