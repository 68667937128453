export class SurveysService {
    static checkIsNestedQuestionValid(childList, allowedTypes) {
        return childList
            .every(({ type: childType }) =>
                Array.isArray(allowedTypes)
                    ? allowedTypes.includes(childType)
                    : childType === allowedTypes,
            );
    }

    static checkIsTreeDataValid({ newTreeData, isStepByStepSurvey }) {
        let validationMessage = null;

        // used for adding validation message if needed
        const withValidationMessage = (isValid, message, validationType = 'error') => {
            if (!isValid) {
                validationMessage = {
                    text: message,
                    type: validationType,
                };
            }

            return isValid;
        };

        const isValid = newTreeData.every(({ type: parentType, children }, parentIndex) => {
            switch (parentType) {
                case 'Finish Survey':
                    // only 'Text Message' can be nested inside 'Finish Survey'
                    return SurveysService.checkIsNestedQuestionValid(children, 'Text Message');

                case 'Unsubscribe':
                    // 'Unsubscribe' question cannot be the first one, if survey is stepByStep
                    return withValidationMessage(
                        isStepByStepSurvey ? parentIndex !== 0 : true,
                        'StepByStep survey can not use as unsubscribe.',
                    );

                case 'MatrixScalable':
                    // Only 'Scalable' can be nested inside 'MatrixScalable'
                    return withValidationMessage(
                        SurveysService.checkIsNestedQuestionValid(children, 'Scalable'),
                        'Only "Scalable" can be nested inside "MatrixScalable".',
                    );

                case 'MatrixConfigurable':
                    // Only 'Configurable' can be nested inside 'MatrixConfigurable'
                    return withValidationMessage(
                        SurveysService.checkIsNestedQuestionValid(children, 'Configurable'),
                        'Only "Configurable" can be nested inside "MatrixConfigurable".',
                    );

                default:
                    return children
                        .every(({ type: childType }, childIndex) => {
                            switch (childType) {
                                case 'Finish Survey':
                                    // child 'Finish Survey' can be nested only as the last one
                                    return childIndex === children.length - 1;

                                case 'MatrixConfigurable':
                                case 'MatrixScalable':
                                    return withValidationMessage(
                                        false,
                                        `${childType} cannot be nested inside other question type`,
                                    );

                                default:
                                    return true;
                            }
                        });
            }
        });

        return [ isValid, validationMessage ];
    }
}
