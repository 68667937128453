import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { HelperService } from '/services';
import { Input } from 'reactstrap';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import './VocTableHeader.scss';

@withTranslation()
export class VocTableHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: props.search,
        };

        this.searchTypeDebouncer = HelperService.debounce((...args) => {
            props.onSearchType(...args);
        }, props.debounceSearchTypeDelay);
    }

    @autobind
    onSearchType(event) {
        const search = event.target.value;

        this.setState({ search });
        this.searchTypeDebouncer(search);
    }

    renderSearchTypeInput() {
        const { placeholder, t } = this.props;

        return (
            <Input
                placeholder={ placeholder ?? t('table.search') }
                type={ 'text' }
                className={ 'voc-table_header-search' }
                value={ this.state.search }
                onChange={ this.onSearchType }/>
        );
    }

    render() {
        const { children, haveSearch } = this.props;

        return children && children.length
            ? (
                <Fragment>
                    <div className={ `voc-table_header-container voc-table_header-container__right` }>
                        {
                            haveSearch ? this.renderSearchTypeInput() : null
                        }
                        <div className='voc-table_header-new-filters' >
                            { children[0] }
                        </div>

                    </div>
                    { children[1] }
                </Fragment>
            )
            : (
                <div className={ `voc-table_header-container` }>
                    <div
                        className='voc-table_header-custom-filters'>
                        { children }
                    </div>
                    {
                        haveSearch ? this.renderSearchTypeInput() : null
                    }
                </div>
            );
    }
}

VocTableHeader.propTypes = {
    search: PropTypes.string,
    haveSearch: PropTypes.bool,
    onSearchType: PropTypes.func,
    debounceSearchTypeDelay: PropTypes.number,
};
VocTableHeader.defaultProps = {
    debounceSearchTypeDelay: 500,
    placeholder: null,
};
