import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Button, ListGroupItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuestionFlowConditionString, DragHandle } from '/feedback/components';
import { TooltipWrapper } from '/components';
import { QuestionsServices } from '/scenes/VocFeedback/services';


export const SortableItem = SortableElement(props => {
    const {
        flow,
        flowOptions,
        removeRowForm,
        updateStateFlow,
        flows,
        parentId,
        optionForCommand,
        optionForChangeLanguage,
        rightsSurvey: { isPermitEdit },
        activeQuestionId,
    } = props;

    const isThisQuestionUsedInFlow = activeQuestionId
        && QuestionsServices.isQuestionUsedInCurrentFlow(
            activeQuestionId,
            flow.question,
            flow,
        );

    return (
        <ListGroupItem className={ 'flow__list-item' }>
            {
                flows.length !== 1 && <DragHandle className='flow__drag' />
            }
            <QuestionFlowConditionString
                formValuesSelector={ flow }
                questionsList={ flowOptions }
                optionForCommand={ optionForCommand }
                optionForChangeLanguage={ optionForChangeLanguage }
            />
            <div className="flow__list-action">
                <TooltipWrapper value={ 'Edit Flow' } force>
                    <Button
                        color="link"
                        disabled={ !isPermitEdit }
                        className={ 'flow__list-wrap-icon' }
                        onClick={ () => isPermitEdit && updateStateFlow({ ...flow, parentId }) }
                    >
                        <FontAwesomeIcon
                            icon={ 'cog' }
                            className={ `flow__list-icon ${flow.corrupted ? 'warning' : ''}` }
                            color={ isThisQuestionUsedInFlow ? QuestionsServices.activeIconColor : '' }
                        />
                    </Button>
                </TooltipWrapper>
                <TooltipWrapper value={ 'Remove Flow' } force>
                    <Button
                        color="link"
                        className={ 'flow__list-wrap-icon' }
                        disabled={ !isPermitEdit }
                        onClick={ () => isPermitEdit && removeRowForm(flow.id) }
                    >
                        <FontAwesomeIcon
                            icon={ 'trash' }
                            className={ 'flow__list-icon' }

                        />
                    </Button>
                </TooltipWrapper>
            </div>
        </ListGroupItem>
    );
});
