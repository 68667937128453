import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import { InputLoader } from '/components';
import { LanguagesList } from "/feedback/components";

import './ControlGlobalLangs.scss';

export const ControlGlobalLangs = ({
    globalLanguages,
    activeLang,
    switchLanguage,
    changeHandler,
    classWrapper,
    selectClass,
    loading,
    disabled,
}) => {
    const isChecked = activeLang ? !activeLang.hasLanguageText : true;
    const isDisabled = loading || disabled || globalLanguages.enableLangLoading || globalLanguages.langUpdating;

    return (
        <div className={ classWrapper }>
            {
                globalLanguages.enableLangLoading
                    ? <InputLoader className='global-settings__lang'/>
                    : <Row>
                        <Col md={ 5 }>
                            <LanguagesList
                                data={{ ...globalLanguages, loading: globalLanguages.enableLangLoading }}
                                changeHandler={ switchLanguage }
                                selectClass={ selectClass }
                                disabled={ isDisabled }
                            />
                        </Col>
                        {
                            activeLang.language
                            && <Col md={ 7 } className={ `enable-language__box` }>
                                <Input
                                    type={ 'checkbox' }
                                    id={ 'enable_language' }
                                    checked={ !isChecked }
                                    onChange={ changeHandler }
                                    disabled={ isDisabled }
                                />
                                <label htmlFor={ 'enable_language' }>Enable language</label>
                            </Col>
                        }
                    </Row>
            }
        </div>
    );
};

ControlGlobalLangs.defaultProps = {
    classWrapper: 'manage-language__wrap',
    selectClass: 'selector-manage-languages',
};
