import uuid from 'uuid/v1';
import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';
import { HelperService } from '/services';
import { checkType } from './VirtualSource.selectors';

export const VIRTUAL_SOURCE_NAME_CHANGED = 'VIRTUAL_SOURCE_NAME_CHANGED';
export const ADD_VIRTUAL_SOURCE_COLUMN = 'ADD_VIRTUAL_SOURCE_COLUMN';
export const ADD_VIRTUAL_SOURCE_ROW = 'ADD_VIRTUAL_SOURCE_ROW';
export const UPDATE_COLUMN_NAME = 'UPDATE_COLUMN_NAME';
export const UPDATE_COLUMN_TYPE = 'UPDATE_COLUMN_TYPE';
export const UPDATE_SELECTED_SOURCE = 'UPDATE_SELECTED_SOURCE';
export const UPDATE_VIRTUAL_SOURCE_CELL_DATA = 'UPDATE_VIRTUAL_SOURCE_CELL_DATA';
export const REMOVE_VIRTUAL_SOURCE_COLUMN = 'REMOVE_VIRTUAL_SOURCE_COLUMN';
export const REMOVE_VIRTUAL_SOURCE_ROW = 'REMOVE_VIRTUAL_SOURCE_ROW';

export const CREATE_VIRTUAL_SOURCE_REQUEST = 'CREATE_VIRTUAL_SOURCE_REQUEST';
export const CREATE_VIRTUAL_SOURCE_SUCCESS = 'CREATE_VIRTUAL_SOURCE_REQUEST';
export const CREATE_VIRTUAL_SOURCE_ERROR = 'CREATE_VIRTUAL_SOURCE_REQUEST';

export const UPDATE_VIRTUAL_SOURCE_CI_SYNC = 'UPDATE_VIRTUAL_SOURCE_CI_SYNC';

export const UPDATE_VIRTUAL_SOURCE_REQUEST = 'UPDATE_VIRTUAL_SOURCE_REQUEST';
export const UPDATE_VIRTUAL_SOURCE_SUCCESS = 'UPDATE_VIRTUAL_SOURCE_SUCCESS';
export const UPDATE_VIRTUAL_SOURCE_ERROR = 'UPDATE_VIRTUAL_SOURCE_ERROR';

export const GET_VIRTUAL_SOURCE_REQUEST = 'GET_VIRTUAL_SOURCE_REQUEST';
export const GET_VIRTUAL_SOURCE_REQUEST_SUCCESS = 'GET_VIRTUAL_SOURCE_REQUEST_SUCCESS';
export const GET_VIRTUAL_SOURCE_REQUEST_ERROR = 'GET_VIRTUAL_SOURCE_REQUEST_ERROR';

export const GET_VIRTUAL_RELATION_REQUEST = 'GET_VIRTUAL_RELATION_REQUEST';
export const GET_VIRTUAL_RELATION_SUCCESS = 'GET_VIRTUAL_RELATION_SUCCESS';
export const GET_VIRTUAL_RELATION_ERROR = 'GET_VIRTUAL_RELATION_ERROR';

export const AUTOFILL_ROW = 'AUTOFILL_ROW';
export const AUTOFILL_SECOND_ROW = 'AUTOFILL_SECOND_ROW';

export const CLEANUP_SOURCE = 'CLEANUP_SOURCE';

export const REORDER_VIRTUAL_SOURCE_COLUMN = 'REORDER_VIRTUAL_SOURCE_COLUMN';
const redirect = '/store/virtual-sources';

export const getInitialState = () => ({
    loading: false,
    source: {
        name: '',
        ciSync: false,
        ciSyncRequired: false,
        sources: [],
        columns: [],
    },
    touched: false,
    virtualRelations: {
        gadgets: [],
        virtualSources: [],
        segmentators: [],
        queries: [],
        virtualSourceIds: [],
    },
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case VIRTUAL_SOURCE_NAME_CHANGED: {
            return {
                ...state,
                source: {
                    ...state.source,
                    name: action.name,
                },
                touched: true,
            };
        }

        case ADD_VIRTUAL_SOURCE_COLUMN: {
            const { columns, sources } = state.source;

            const newColumn = {
                mapping: sources.map(() => ({
                    index: null,
                    data_id: null,
                })),
                name: "",
                type: "",
            };

            const newColumns = columns.reduce((acc, item, index) => {
                if (index === 0) {
                    acc[index].mapping = item.mapping.map(el => ({
                        data_id: el.data_id,
                        index: null,
                    }));
                }

                acc.push(item);

                return acc;
            }, [ newColumn ]);

            return {
                ...state,
                source: {
                    ...state.source,
                    columns: newColumns,
                },
                touched: true,
            };
        }

        case AUTOFILL_ROW: {
            const sources = state.source.sources.map((source, index) => {
                let item = source;

                if (index === action.data.id) {
                    item = {
                        ...source,
                        ...action.data.source,
                    };
                }
                return item;
            });
            const { attributes, id } = sources[0];

            const columns = attributes.reduceRight((acc, item) => {
                acc.push({
                    mapping: [{
                        data_id: id,
                        index: item.id,
                    }],
                    name: item.name,
                    type: checkType(item),
                });

                return acc;
            }, []);

            return {
                ...state,
                source: {
                    ...state.source,
                    sources: sources,
                    columns: columns,
                },
                touched: true,
            };
        }

        case AUTOFILL_SECOND_ROW: {
            const { attributes, id } =action.data.source;
            const columns = state.source.columns.map(column => {
                const findColumnAttribute = [ ...attributes ].find(attribute => attribute.name === column.name);

                const columnMapping = column.mapping.map(mapData => (
                    mapData.data_id === id && findColumnAttribute && !mapData?.index
                        ? {
                            ...mapData,
                            index: findColumnAttribute.id,
                        }: mapData),
                );

                return {
                    ...column,
                    mapping: columnMapping,
                };
            });


            return {
                ...state,
                source: {
                    ...state.source,
                    columns,
                },
            };
        }

        case ADD_VIRTUAL_SOURCE_ROW: {
            const { sources } = state.source;
            const sourceTemplate = {
                attributes: [],
                channel: "",
                id: "",
                languages: [],
                state: null,
                title: "",
                totalRowsCount: 0,
                type: "",
                updated: "",
                customId: uuid(),
                isNew: true,
                currentUserPermission: 'full',
            };

            action.isAutoFill ? sources.splice(1, 0, sourceTemplate) : sources.unshift(sourceTemplate);

            return {
                ...state,
                source: {
                    ...state.source,
                    sources,
                    columns: state.source.columns.map(column => {
                        const fillItem = action.isAutoFill && column.mapping.splice(0, 1)[0];
                        const data = {
                            ...column,
                            mapping: [
                                {
                                    index: null,
                                    data_id: null,
                                },
                                ...column.mapping,
                            ],
                        };

                        fillItem && data.mapping.unshift(fillItem);
                        return data;
                    }),
                },
                touched: true,
            };
        }

        case UPDATE_COLUMN_NAME: {
            return {
                ...state,
                source: {
                    ...state.source,
                    columns: state.source.columns.map((column, index) => {
                        const item = column;

                        if (index === action.data.id) {
                            item.name = action.data.value;
                        }
                        return item;
                    }),
                },
                touched: true,
            };
        }

        case UPDATE_COLUMN_TYPE: {
            const { value, columnIndex, id } = action.data;

            return {
                ...state,
                source: {
                    ...state.source,
                    columns: state.source.columns.map((column, index) => {
                        if (index === columnIndex) {
                            column.type = value;
                            column.mapping.forEach((cell, inx) => {
                                column.mapping[inx] = {
                                    index: null,
                                    data_id: cell.data_id,
                                };
                            });
                            column.id = id;
                        }

                        return column;
                    }),
                },
                touched: true,
            };
        }

        case UPDATE_SELECTED_SOURCE: {
            return {
                ...state,
                source: {
                    ...state.source,
                    sources: state.source.sources.map((source, index) => {
                        let item = source;

                        if (index === action.data.id) {
                            item = {
                                ...source,
                                ...action.data.source,
                                ...!action.data.source.id
                                    ? { currentUserPermission: 'full' }
                                    : {},
                            };
                        }
                        return item;
                    }),
                    columns: state.source.columns.map(column => {
                        column.mapping[action.data.id] = {
                            ...(column.mapping[action.data.id] || {}),
                            data_id: action.data.source.id,
                        };
                        return column;
                    }),
                },
                touched: true,
            };
        }

        case UPDATE_VIRTUAL_SOURCE_CELL_DATA: {
            return {
                ...state,
                source: {
                    ...state.source,
                    columns: state.source.columns.map((column, index) => {
                        const temp = column;

                        if (index === action.data.columnId) {
                            temp.mapping[action.data.rowId] = action.data.value;
                        }

                        return temp;
                    }),
                },
                touched: true,
            };
        }

        case REMOVE_VIRTUAL_SOURCE_COLUMN: {
            return {
                ...state,
                source: {
                    ...state.source,
                    columns: [
                        ...state.source.columns.slice(0, action.id),
                        ...state.source.columns.slice(action.id + 1, state.source.columns.length),
                    ],
                },
                touched: true,
            };
        }

        case REORDER_VIRTUAL_SOURCE_COLUMN: {
            return {
                ...state,
                source: {
                    ...state.source,
                    columns: action.data,
                },
                touched: true,
            };
        }

        case REMOVE_VIRTUAL_SOURCE_ROW: {
            return {
                ...state,
                source: {
                    ...state.source,
                    sources: [
                        ...state.source.sources.slice(0, action.id),
                        ...state.source.sources.slice(action.id + 1, state.source.sources.length),
                    ],
                    columns: state.source.columns.map(column => {
                        column.mapping = [
                            ...column.mapping.slice(0, action.id),
                            ...column.mapping.slice(action.id + 1, column.mapping.length),
                        ];
                        return column;
                    }),
                },
                touched: true,
            };
        }

        case GET_VIRTUAL_RELATION_REQUEST:
        case GET_VIRTUAL_SOURCE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_VIRTUAL_SOURCE_REQUEST_SUCCESS: {
            return {
                ...state,
                source: {
                    ...action.response,
                    sources: action.response.sources.map(source => ({
                        ...source,
                        customId: uuid(),
                    })),
                },
                loading: false,
            };
        }

        case GET_VIRTUAL_RELATION_SUCCESS: {
            return {
                ...state,
                loading: false,
                virtualRelations: {
                    ...action.response,
                },
            };
        }

        case UPDATE_VIRTUAL_SOURCE_CI_SYNC: {
            return {
                ...state,
                source: {
                    ...state.source,
                    ...action.data,
                },
            };
        }

        case GET_VIRTUAL_RELATION_ERROR:
        case GET_VIRTUAL_SOURCE_REQUEST_ERROR: {
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        }

        case CLEANUP_SOURCE: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function createVirtualSource(body) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/virtual-source/`,
            types: [ CREATE_VIRTUAL_SOURCE_REQUEST, CREATE_VIRTUAL_SOURCE_SUCCESS, CREATE_VIRTUAL_SOURCE_ERROR ],
            method: 'POST',
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyCreated', { entity: t('virtualSource') }),
                error: t('errorDuringVirtualSourceCreateProcess'),
            },
            body,
        },
    };
}

export function updateVirtualSource(body, id) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/virtual-source/${ id }`,
            types: [ UPDATE_VIRTUAL_SOURCE_REQUEST, UPDATE_VIRTUAL_SOURCE_SUCCESS, UPDATE_VIRTUAL_SOURCE_ERROR ],
            method: 'PUT',
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyUpdated', { entity: t('virtualSource') }),
                error: t('errorDuringVirtualSourceUpdateProcess'),
            },
            body,
        },
    };
}

export function getVirtualSourceById(id) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/virtual-source/${ id }`,
            method: 'GET',
            types: [
                GET_VIRTUAL_SOURCE_REQUEST,
                GET_VIRTUAL_SOURCE_REQUEST_SUCCESS,
                GET_VIRTUAL_SOURCE_REQUEST_ERROR,
            ],
            logger: true,
            loggerSuccessNotShow: true,
            loggerMessages: {
                success: '',
                error: t('messages.somethingWentWrong'),
            },
            body: {},
        },
    };
}

export function getVirtualRelations(id) {
    return {
        [CALL_API]: {
            endpoint: ` /public_api/store/virtual-source/${ id }/relations`,
            method: 'GET',
            types: [
                GET_VIRTUAL_RELATION_REQUEST,
                GET_VIRTUAL_RELATION_SUCCESS,
                GET_VIRTUAL_RELATION_ERROR,
            ],
            redirect,
            body: {},
        },
    };
}

export function updateVirtualSourceName(name) {
    return {
        type: VIRTUAL_SOURCE_NAME_CHANGED,
        name,
    };
}

export function addVirtualSourceColumn() {
    return {
        type: ADD_VIRTUAL_SOURCE_COLUMN,
    };
}

export function addVirtualSourceRow(isAutoFill = false) {
    return {
        type: ADD_VIRTUAL_SOURCE_ROW,
        isAutoFill,
    };
}

export function autofillRow(data) {
    return {
        type: AUTOFILL_ROW,
        data,
    };
}

export function autofillSecondRow(data) {
    return {
        type: AUTOFILL_SECOND_ROW,
        data,
    };
}

export function updateColumnName(data) {
    return {
        type: UPDATE_COLUMN_NAME,
        data,
    };
}

export function updateColumnType(data) {
    return {
        type: UPDATE_COLUMN_TYPE,
        data,
    };
}

export function updateSelectedSource(data) {
    return {
        type: UPDATE_SELECTED_SOURCE,
        data,
    };
}

export function updateCellData(data) {
    return {
        type: UPDATE_VIRTUAL_SOURCE_CELL_DATA,
        data,
    };
}

export function removeVirtualSourceColumn(id) {
    return {
        type: REMOVE_VIRTUAL_SOURCE_COLUMN,
        id,
    };
}

export function removeVirtualSourceRow(id) {
    return {
        type: REMOVE_VIRTUAL_SOURCE_ROW,
        id,
    };
}

export function updateVirtualSourceCISync(data) {
    return {
        type: UPDATE_VIRTUAL_SOURCE_CI_SYNC,
        data,
    };
}

export function reorderVirtualSourceColumns(data) {
    return {
        type: REORDER_VIRTUAL_SOURCE_COLUMN,
        data,
    };
}

export function processVirtualSource() {
    return (dispatch, getState) => {
        const state = getState();
        const { source } = state.VocStore.virtualSource;
        const modelKeys = [
            'columns',
            'name',
            'ciSync',
            'ciSyncRequired',
        ];

        const model = {
            ...HelperService.pick(source, modelKeys),
            source_ids: source.sources.map(source => source.id),
        };

        if (!source.id) {
            return dispatch(createVirtualSource(model));
        } else {
            return dispatch(updateVirtualSource(model, source.id));
        }
    };
}

export function cleanupSource() {
    return {
        type: CLEANUP_SOURCE,
    };
}
