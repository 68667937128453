import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { TooltipWrapper } from '/components';

export const ReorderButton = ({ enable, changeHandler, className, disabled = false }) => {
    return (
        <TooltipWrapper value={ enable ? 'Enable questions reorder' : 'Disable questions reorder' } force={ true }>
            <Button
                onClick={ changeHandler }
                className={ `util-button ${className}` }
                color={ 'white' }
                disabled={ disabled }
                outline
            >
                <FontAwesomeIcon icon={ `${enable ? 'lock' : 'lock-open'}` } className={ 'util-button__icon util-button__icon--lock' }/>
                <span>Reorder</span>
            </Button>
        </TooltipWrapper>
    );
};
