import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Preloader } from '/components';
import { EGadgetType } from '/visual/models';
import { DashboardGridService } from '/visual/scenes/Dashboard/services';
import { scrollIntoViewSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { RESET_SCROLL_INTO_VIEW } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import {
    BarChart, EmptyGadget, GadgetHeader, LineChart,
    NpsBubbleChart, PieChart, RadarChart, StaticArea,
    TableChart, WordCloudChart, FooterInfo,
} from './components';
import { useGadgetData, useHover } from './customHooks';
import { IGadgetProps } from '../../models';

import styles from './style.module.scss';

const GadgetComponent = ({
    isEditable,
    isDragEvent,
    gadgetId,
    onCopy,
    onEdit,
    onMoreInfo,
    onDelete,
    onCut,
    onMoveToSave,
    setHidePlaceholders,
    newStaticAreaGadgetId,
    setNewStaticAreaGadgetId,
}: IGadgetProps) => {
    const { t } = useTranslation();
    const [ gadgetSize, setGadgetSize ] = useState({ width: null, height: null });

    const { isHovered, mouseOver, mouseOut } = useHover(!isEditable || isDragEvent);
    const gadgetRef = useRef<HTMLDivElement | null>(null);
    const { gadget, chart, onSync, rights } = useGadgetData(gadgetId, gadgetRef);
    const {
        scrollToGadget,
        scrollToPage,
        startSmoothScrolling,
    } = useSelector(scrollIntoViewSelector);
    const dispatch = useDispatch();

    // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    const gadgetSizeRef = useCallback(node => {
        if (node !== null) {
            setGadgetSize({
                width: node.offsetWidth,
                height: node.offsetHeight,
            });
        }

        gadgetRef.current = node;
    }, []);

    useEffect(() => {
        // Smooth scroll to the top of the gadget with animation
        // Skip this behaviour if the page needs to be scrolled into view
        // The page is scrolled into view inside GadgetsVirtualList.tsx (useEffect based on "scrollToPage")
        if (
            !scrollToPage
            && startSmoothScrolling
            && scrollToGadget
            && gadgetRef?.current
            && scrollToGadget === gadgetId
        ) {
            // scroll to specific gadget (after click on the "Parent Dashboard" button)
            DashboardGridService.scrollElementIntoView(gadgetRef.current, 'flesh');

            dispatch({ type: RESET_SCROLL_INTO_VIEW });
        }
    }, [ gadgetRef.current, scrollToGadget, startSmoothScrolling ]);

    const getSuperChart = () => {
        const { width, height } = gadgetSize;

        const isWordCloudNoData = gadget?.contentSettings.chartType === 'lemmata_word_cloud'
            && chart?.chartData?.gadgetId
            && !chart?.chartData?.words?.length;

        if (chart?.chartData?.gadgetId && width && height && !isWordCloudNoData) {
            switch (gadget.contentSettings.chartType) {
                case 'custom_columns_table':
                    return chart.chartData.filters
                        && <TableChart
                            chartData={ chart }
                            gadgetSize={{ width, height }}
                        />;

                case 'surveys_bar_chart':
                case 'stacked_bar_chart':
                    return <BarChart chartData={ chart } />;

                case 'line_chart':
                    return <LineChart chartGadgetData={ chart } />;

                case 'nps_bubble_chart':
                    return <NpsBubbleChart
                        chartGadgetData={ chart }
                        onEdit={ onEdit }
                    />;

                case 'lemmata_word_cloud':
                    return <WordCloudChart chartGadgetData={ chart } />;

                case 'generic_pie_chart':
                    return <PieChart chartGadgetData={ chart } />;

                case 'radar_chart':
                    return <RadarChart chartData={ chart } />;

                default:
                    return <EmptyGadget
                        isLoading={ chart?.showEmptyGadgetLoading }
                        errorMessage={ chart?.error }
                        onEdit={ onEdit }
                    />;
            }
        }

        const emptyGadgetError = isWordCloudNoData
            ? `${ t('noData') }.`
            : chart?.error;

        return <EmptyGadget
            isLoading={ chart?.showEmptyGadgetLoading }
            errorMessage={ emptyGadgetError }
            onEdit={ onEdit }
        />;
    };

    const getStaticArea = () => {
        return <StaticArea
            setNewStaticAreaGadgetId={ setNewStaticAreaGadgetId }
            newStaticAreaGadgetId={ newStaticAreaGadgetId }
            setHidePlaceholders={ setHidePlaceholders }
            chartData={ chart }
            isEditable={ isEditable }
            onEditHtml={ onEdit }
            isPermitEdit={ rights.isPermitEdit }
        />;
    };

    const getGadgetContentByType = () => {
        switch (gadget.type) {
            case EGadgetType.SUPER_CHART:
                return getSuperChart();
            case EGadgetType.STATIC_AREA:
                return getStaticArea();
        }
    };

    return (
        <div
            className={ cx(styles.gadget, {
                'enabled-drag': gadget.type === EGadgetType.STATIC_AREA
                    && isEditable
                    && !isDragEvent,
                [styles.staticAreaGadget]: gadget.type === EGadgetType.STATIC_AREA,
                [styles.isEditableGadget]: isEditable,
            }) }
            ref={ gadgetSizeRef }
            id={ `gadget_${gadgetId}` }
            onMouseOver={ mouseOver }
            onMouseLeave={ mouseOut }
        >
            <GadgetHeader
                isHovered={ isHovered }
                isEditable={ isEditable }
                containerRef={ gadgetRef }
                gadgetData={ gadget }
                isDragEvent={ isDragEvent }
                rights={ rights }
                actions={{
                    onCut,
                    onCopy,
                    onDelete,
                    onMoveToSave,
                    onEdit,
                    onSync,
                    onMoreInfo,
                }}
            />

            <div className={ `${styles.chartContainer} chartContainer` }>
                {
                    chart.loading || !chart.loaded
                        ? <Preloader
                            transparentByRefNode={ gadgetRef }
                            autoSizeByContainerRef={ gadgetRef }
                        />
                        : getGadgetContentByType()
                }
            </div>
            {/* show lastHealthCheckTime during chart data loading */}
            {
                chart.loading || !chart.loaded
                && <FooterInfo lastHealthCheckTime={ gadget.lastHealthCheckTime } />
            }
        </div>
    );
};

GadgetComponent.displayName = 'Gadget';

export const Gadget = memo(GadgetComponent);
