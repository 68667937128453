import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import App from './App';
import { sockets } from './services';

// import registerServiceWorker from './registerServiceWorker';

const store = configureStore();

export default store;

sockets.setStore(store);

ReactDOM.render(
    <Provider store={ store }>
        <App sockets={ sockets }/>
    </Provider>,
    document.getElementById('body-container'),
);

// registerServiceWorker();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
