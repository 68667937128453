import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { OutsideAlerter, TinyEditor } from '/components';
import { changeStaticAreaData } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { IStaticArea } from './models';
import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';
import { TinyEditorService } from '/services';

import styles from './style.module.scss';

export const StaticArea = ({
    isEditable,
    chartData,
    setHidePlaceholders,
    newStaticAreaGadgetId,
    setNewStaticAreaGadgetId,
    onEditHtml,
    isPermitEdit,
}: IStaticArea) => {
    const [ value, setValue ] = useState('');
    const [ isOpenPreview, setIsOpenPreview ] = useState(false);
    const [ contentRef, setContentRef ] = useState<HTMLIFrameElement | null>(null);
    const dispatch = useDispatch();

    const assets = useSelector(assetsSelector);
    const mountNode = contentRef?.contentWindow?.document?.body;

    const onDoubleClickHandler = () => {
        setIsOpenPreview(true);
        isEditable && setHidePlaceholders(false);
    };

    const setDefaultStylesForIframeBody = (style: CSSStyleDeclaration) => {
        style.cursor = 'pointer';
        style.overflow = 'hidden';
        style.wordBreak = 'break-word';
        style.margin = '8px';
    };

    useEffect(() => {
        if (mountNode) {
            const iframeBody = contentRef.contentWindow.document.body;

            isPermitEdit && iframeBody.addEventListener('dblclick', onDoubleClickHandler);
            iframeBody.addEventListener('click', () => {
                contentRef.contentWindow?.parent.postMessage({
                    type: 'clickIframe',
                    id: chartData.gadgetData.id,
                }, '*');
            });

            // set content for iframe
            iframeBody.innerHTML = value;

            const insertedStyles = document.createElement('style');

            insertedStyles.innerHTML = `
                h1, h2, h3, h4, h5, h6 { font-weight: unset }
            `;

            iframeBody.appendChild(insertedStyles);

            // set default styles for iframe body
            setDefaultStylesForIframeBody(iframeBody.style);

            TinyEditorService.setFonts(contentRef.contentWindow.document);

            newStaticAreaGadgetId === chartData.gadgetData.id && onDoubleClickHandler();
        }
    }, [ mountNode, value, newStaticAreaGadgetId ]);

    useEffect(() => {
        setValue(chartData?.chartData);
    }, [ chartData?.chartData ]);

    const onEditorDetach = (contentStr: string) => {
        dispatch(changeStaticAreaData(chartData.gadgetData.id, contentStr));
    };

    const handleClickOutside = (e: Event) => {
        setNewStaticAreaGadgetId(null);
        const target = e.target as HTMLElement;

        if (
            !target.closest('.tox-silver-sink')
            && !target.closest('.editor__file-manager')
            && !target.closest('.voc-dialog')
        ) {
            setIsOpenPreview(false);
        }
    };

    const onClickAnotherIframe = (event: { data: { type: string; id: string; }; }) => {
        if (event.data.type === 'clickIframe' && event.data.id !== chartData.gadgetData.id) {
            setIsOpenPreview(false);
        }
    };

    useEffect(() => {
        window.addEventListener('message', onClickAnotherIframe);

        return () => {
            window.removeEventListener('message', onClickAnotherIframe);
        };
    }, []);

    return (
        <div
            className={ cx(styles.staticAreaWrapper, { [styles.staticEdit]: isEditable }) }
            onDoubleClick={ isEditable ? onDoubleClickHandler : () => null }
        >
            <OutsideAlerter handleClickOutside={ handleClickOutside }>
                {
                    isOpenPreview
                        ? <TinyEditor
                            onlyBlankLinks
                            autofocus
                            height='100%'
                            assets={ assets }
                            value={ value || '' }
                            onChange={ setValue }
                            onEditorDetach={ onEditorDetach }
                            getAssets={ (folder: string | undefined) => dispatch(getAssets(folder)) }
                            uploadAsset={ (uploadData: any) => dispatch(uploadAsset(uploadData)) }
                            onOpenHtmlEditor={ onEditHtml }
                            auxiliaryModalClass='aux-modal-mediaBlock'
                        />
                        : <iframe id={ chartData.gadgetData.id } className={ styles.iframe } ref={ setContentRef } />
                }
            </OutsideAlerter>
        </div>
    );
};
