import { createStore, applyMiddleware, compose } from 'redux';
import api from './middleware/api';
import cacheGetter from './middleware/cacheGetter';
import cacheInvalidator from './middleware/cacheInvalidator';
import cacheSetter from './middleware/cacheSetter';
import makeRootReducer from './reducers';
import thunk from 'redux-thunk';

function configureStore(initialState = {}) {
    const middleware = [ thunk, cacheGetter, api, cacheSetter, cacheInvalidator ];
    const enhancers = [];
    let composeEnhancers;

    if(typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true });
    } else {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    if (process.NODE_ENV === 'development') {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('./reducers', () => {
                store.replaceReducer(makeRootReducer());
            });
        }
    }

    const store = createStore(
        makeRootReducer(),
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware),
            ...enhancers,
        ),
    );

    store.asyncReducers = {};

    return store;
}

export default configureStore;
