import React, { useEffect, createContext, useState } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import { SettingsService } from '/feedback/services/SettingsService';
import { getUnitId } from '/feedback/modules/VocFeedback.selectors';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';
import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';
import { ThemeRoute } from './ThemeRoute';
import {
    getThemeById,
    setTheme,
    getLangSettings,
    setLang,
    setActiveLang,
    createLang,
    deleteLang,
    getDigiRunnerUrl,
    getSettingsDefaultTheme,
    cleanup,
    getAllTags,
} from './modules/theme.modules';
import {
    themeFormSettingsSelector,
    commonLanguageSelector,
    languageFormSettingsSelector,
    defaultLangSettings,
    allTagsSelector,
} from './modules/theme.selectors';
import { getPermissionSelector } from '../GlobalSettings/modules/globalSettings.selectors';
import { Navigation } from '/components';
import { HeaderTheme } from './components';

import './Theme.scss';

export const Context = createContext();

const mapStateToProps = state => ({
    themeSettings: themeFormSettingsSelector(state),
    languageSettings: languageFormSettingsSelector(state),
    commonLang: commonLanguageSelector(state),
    defaultLang: defaultLangSettings(state),
    unitId: getUnitId(state),
    assets: assetsSelector(state),
    permission: getPermissionSelector(state),
    allTags: allTagsSelector(state),
});

const mapDispatchToProps = {
    cleanup,
    getThemeById,
    setTheme,
    getLangSettings,
    setLang,
    setActiveLang,
    getDigiRunnerUrl,
    getSettingsDefaultTheme,
    createLang,
    deleteLang,
    getAllTags,
    getAssets,
    uploadAsset,
};

const ConnectedComponent = ({
    match,
    getThemeById,
    getLangSettings,
    cleanup,
    themeSettings: {
        themeDescription,
        themeName,
        themeTags,
        themeId,
    },
    themeSettings,
    setActiveLang,
    commonLang,
    languageSettings,
    getDigiRunnerUrl,
    getSettingsDefaultTheme,
    defaultLang,
    createLang,
    deleteLang,
    setTheme,
    setLang,
    unitId,
    getAssets,
    uploadAsset,
    permission,
    assets,
    getAllTags,
    allTags,
}) => {
    const [ name, setThemeName ] = useState('');
    const [ description, setThemeDescription ] = useState('');
    const [ nameIsError, setNameIsError ] = useState(false);
    const [ tags, setThemeTags ] = useState([]);
    const [ dirtyThemeHeader, setDirtyThemeHeader ] = useState(true);

    const pageLinks = [
        { title: 'Theme settings', link: `${ match.url }/theme-settings`, isReactLink: true },
        { title: 'Language settings', link: `${ match.url }/language-settings`, isReactLink: true },
    ];

    useEffect(() => {
        getAllTags();
        getDigiRunnerUrl(unitId).promise.then(({ DIGI_RUNNER_URL }) => {
            DIGI_RUNNER_URL && SettingsService.injectDigiRunnerUrl(DIGI_RUNNER_URL);
        });

        if (match.params.themeId) {
            getThemeById(match.params.themeId);
            getLangSettings(match.params.themeId);
        } else {
            getSettingsDefaultTheme(unitId);
        }

        return () => {
            SettingsService.removeDigiRunnerScript();
            cleanup();
        };
    }, []);

    useEffect(() => {
        themeName && setThemeName(themeName);
    }, [ themeName ]);

    useEffect(() => {
        themeDescription && setThemeDescription(themeDescription);
    }, [ themeDescription ]);

    useEffect(() => {
        themeTags && setThemeTags(themeTags);
    }, [ themeTags ]);

    useEffect(() => {
        const isChanged = description !== themeDescription || name !== themeName || tags?.length !== themeTags?.length;

        setDirtyThemeHeader(isChanged);
    }, [ description, tags, name ]);

    const handleCreateLang = ({ target }) => {
        target.checked
            ? createLang({
                ...defaultLang,
                digi_theme: match.params.themeId,
                language: commonLang.activeLangId,
            })
            : deleteLang(commonLang.themeLangId.id);
    };

    return (
        <DocumentTitle title={ 'Feedback :: DIGI Theme' }>
            <Context.Provider value={{
                languageSettings: {
                    ...languageSettings,
                    getAssets,
                    uploadAsset,
                    assets,
                },
                themeSettings,
                commonLang,
                handleCreateLang,
                setThemeName,
                setActiveLang,
                match,
                themeId,
                name,
                description,
                setThemeDescription,
                setTheme,
                setLang,
                nameIsError,
                setNameIsError,
                permission,
                tags,
                setThemeTags,
                allTags,
                dirtyThemeHeader,
            }}>
                <HeaderTheme/>
                <Navigation navigationInfo={ pageLinks } className={ 'template-main__navigator' }/>
                <ThemeRoute match={ match }/>
            </Context.Provider>
        </DocumentTitle>
    );
};

export const Theme = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent);
