import React, { Fragment } from 'react';
import uuidV4 from 'uuid/v4';
import { getI18n } from 'react-i18next';

import { SourceService } from '/services';

import './MetaQueryService.scss';

export class MetaQueryService {
    static translate = (key, options) => getI18n()?.t(`metaQuery.${ key }`, options);

    static getVisualMetaOperator = () => ({
        EQ_CI: MetaQueryService.translate('EQ_CI'),
        NE_CI: MetaQueryService.translate('NE_CI'),
    });

    static getFrontOperators = () => ({
        LNEN: MetaQueryService.translate('LNEN'),
        LEQN: MetaQueryService.translate('LEQN'),
        EQN: MetaQueryService.translate('EQN'),
        NEN: MetaQueryService.translate('NEN'),
        ...this.getVisualMetaOperator(),
    });

    static getOperators = () => ({
        EQ: MetaQueryService.translate('EQ'),
        NE: MetaQueryService.translate('NE'),
        INC: MetaQueryService.translate('INC'),
        EXC: MetaQueryService.translate('EXC'),
        EQA: MetaQueryService.translate('EQA'),
        NEA: MetaQueryService.translate('NEA'),
        GTE: '>=',
        GT: '>',
        LT: '<',
        LTE: '<=',
        ...this.getFrontOperators(),
    });

    static emptyQuery = () => ({
        hash: 'root',
        items: [
            {
                id: '',
                operator: '',
                value: null,
                hash: uuidV4(),
                items: [],
            },
        ],
    });

    //Query validator
    static validateViewDataBeforeSend(query) {
        let valid = true;

        if (query.hash !== 'root') {
            const { operator, value, id } = query;
            const isNullOperator = [ 'EQN', 'NEN' ].includes(operator);
            const isFullValue = Array.isArray(value) ? value.length > 0 : ![ undefined, null ].includes(value);

            valid = isNullOperator
                ? operator && id
                : isFullValue && operator && id;
        }

        if (query.items) {
            query.items.forEach(item => {
                valid = valid && MetaQueryService.validateViewDataBeforeSend(item);
            });
        }

        return valid;
    }

    //Client to server formatter
    static clientToServerFormatter({ query, attributeValueKey, source }, notDecorateAttr) {
        query = JSON.parse(JSON.stringify(query));

        const { items } = query;

        if (!query.items.length) {
            return JSON.stringify({});
        }

        function addOr(items) {
            const orArray = [];

            items.forEach(item => {
                const result = item.items && item.items.length
                    ? { and: addAnd(item) }
                    : MetaQueryService.setServerItemData({ item, attributeValueKey, source }, notDecorateAttr);

                orArray.push(result);
            });

            return orArray;
        }

        function addAnd(item) {
            const andArray = [];
            const obj = MetaQueryService.setServerItemData({ item, attributeValueKey, source }, notDecorateAttr);

            andArray.push(obj);

            if (item.items && item.items.length) {
                item.items.length > 1
                    ? andArray.push({ or: addOr(item.items) })
                    : andArray.push(...addAnd(item.items[0]));
            }

            return andArray;
        }

        return items.length > 1
            ? { or: addOr(items) }
            : { and: addAnd(items[0]) };
    }

    static setServerItemData({ item, source }, notDecorateAttr) {
        const matrix = [
            {
                condition: {
                    operator: 'EQN',
                },
                changes: {
                    operator: 'EQ',
                    value: null,
                },
            },
            {
                condition: {
                    operator: 'NEN',
                },
                changes: {
                    operator: 'NE',
                    value: null,
                },
            },
            {
                condition: {
                    value: '',
                },
                changes: {
                    value: null,
                },
            },
            {
                condition: {
                    operator: 'LNEN',
                },
                changes: {
                    operator: 'NE',
                    id: `lemmas.${ item.id }`,
                    value: null,
                },
            },
            {
                condition: {
                    operator: 'LEQN',
                },
                changes: {
                    operator: 'EQ',
                    id: `lemmas.${ item.id }`,
                    value: null,
                },
            },
        ];

        item = MetaQueryService.processMatrix({ matrix, item });
        const { id, value, operator } = item;

        const decorateAttr = attrName => {
            const attr = source.attributes.find(x => x.index === attrName);
            const name = attr && attr.name;

            return name ? `[placeholder]${ name }[placeholder]` : null;
        };

        item = {
            relation: operator,
            id: notDecorateAttr ? id : decorateAttr(id),
            value,
        };

        return item.relation && item.id
            ? item
            : [];
    }

    //matrix processing generation
    static processMatrixCondition({ condition, item, single }) {
        const extraInspection = item => {
            let valid = false;

            const conditions = [
                !single,
                single && !Array.isArray(item),
                single && Array.isArray(item) && item.length === 1,
            ];

            valid = conditions.some(x => x);

            return valid;
        };

        return Object.keys(condition).every(field => {
            return Array.isArray(item[field]) && extraInspection(item[field])
                ? item[field].includes(condition[field])
                : item[field] === condition[field];
        });
    }

    static processMatrix({ matrix, item }) {
        matrix.forEach(rule => {
            const { condition, changes, single } = rule;
            const { operator } = condition;
            const isConditionTrue = MetaQueryService.processMatrixCondition({ condition, item, single });
            const emptyOperators = [ 'EQN', 'NEN', 'LNEN', 'LEQN' ];

            if (isConditionTrue) {
                Object.keys(changes).forEach(key => {
                    if (Array.isArray(item[key]) && !emptyOperators.includes(operator)) {
                        item[key] = item[key].map(x => {
                            return x === condition[key] ? changes[key] : x;
                        });
                    } else {
                        item[key] = changes[key];
                    }
                });
            }
        });

        return item;
    }

    //Query string generation
    static getOrClientStringPart({ query, source }) {
        const result = [];

        query.items.forEach((item, index) => {
            result.push(MetaQueryService.clientQueryToString({ query: item, source }));

            if (index !== query.items.length - 1) {
                const or = <strong className={ 'blue-label' } key={ uuidV4() }> OR </strong>;

                result.push(or);
            }
        });

        return result;
    }

    static clientQueryToString({ query, source }) {
        if (query.hash === 'root') {
            return MetaQueryService.getOrClientStringPart({ query, source });
        }
        let { id, value = '' } = query;
        const { operator } = query;
        const attrObject = source.attributes.find(x => x.index === id);

        const operatorsList = { ...MetaQueryService.getOperators() };

        id = attrObject && attrObject.name;

        if (Array.isArray(value)) {
            value = value.map(valueItem => {
                const valueName = SourceService.getValueById({ attribute: attrObject, id: valueItem }).name;

                return valueName === '' ? 'No value' : valueName;
            }).join(', ');
            value = `[ ${ value } ]`;
        } else {
            const valueName = SourceService.getValueById({ attribute: attrObject, id: value }).name;

            value = valueName === '' ? 'No value' : valueName;
        }

        if (value === '' && ![ 'EQN', 'NEN', 'LNEN', 'LEQN' ].includes(operator)) {
            value = 'No value';
        }

        if (attrObject?.type === 'TEXT') {
            operatorsList.EQA = MetaQueryService.translate('EQA_Lem');
            operatorsList.NEA = MetaQueryService.translate('NEA_Lem');
        }

        const currentItemQuery = (
            <Fragment key={ uuidV4() }>
                <strong>
                    &nbsp;
                    { id }
                </strong>
                <strong className={ 'blue-label' }>
                    &nbsp;{ operatorsList[operator] || '' }&nbsp;
                </strong>
                {
                    value
                    && ![ 'EQN', 'NEN', 'LNEN', 'LEQN' ].includes(operator)
                    && (
                        <strong>
                            { value }
                        &nbsp;
                        </strong>
                    )
                }
            </Fragment>
        );

        let orItemsQuery;

        if (query.items && query.items.length > 0) {
            orItemsQuery = MetaQueryService.getOrClientStringPart({ query, source });
        }

        const andItems = orItemsQuery && (
            <Fragment key={ uuidV4() }>
                <strong className={ 'blue-label' }> AND (</strong>
                { orItemsQuery }
                <strong className={ 'blue-label' }> )</strong>
            </Fragment>
        );

        return (
            <Fragment key={ uuidV4() }>
                <strong className={ 'blue-label' }>( </strong>
                { currentItemQuery }
                <strong className={ 'blue-label' }> )</strong>
                { andItems }
            </Fragment>
        );
    }

    //Server to client formatter
    static serverToClientFormatter({ query, placeholdersMapping }) {
        let result;

        function makeOrSlice(items) {
            const dataToView = [];

            items.forEach(item => {
                const result = item.and
                    ? makeAndSlice(item.and)
                    : MetaQueryService.setClientItemData({ query: item, placeholdersMapping });

                dataToView.push(result);
            });

            return dataToView;
        }

        function makeAndSlice(items) {
            let root = {};

            for (let index = 0; index < items.length; index++) {
                const item = items[index];

                if (item.or) {
                    return makeOrSlice(item.or);
                }

                root = MetaQueryService.setClientItemData({ query: item, placeholdersMapping });

                if (items.length > 1) {
                    const sliceResult = makeAndSlice(items.splice(index + 1));

                    root.items = Array.isArray(sliceResult) ? [ ...sliceResult ] : [ sliceResult ];
                }
            }

            return root;
        }

        if (query.or) {
            result = makeOrSlice(query.or);
        } else if (query.and) {
            result = [ makeAndSlice(query.and) ];
        } else {
            result = [ MetaQueryService.setClientItemData({ query, placeholdersMapping }) ];
        }

        return {
            hash: 'root',
            items: result,
        };
    }

    static setClientItemData({ query, placeholdersMapping }) {
        const operator = query.relation;
        const attribute = placeholdersMapping ? placeholdersMapping[query.id] : query.id;
        const { value } = query;

        const conditionId = attribute.includes('lemmas.') ? attribute : `lemmas.${ attribute }`;
        const changesId = attribute.includes('lemmas.') ? attribute.replace(/lemmas./g, '') : attribute;

        const EQConditionId = !attribute.includes('lemmas.') ? attribute : `lemmas.${ attribute }`;

        const matrix = [
            {
                single: true,
                condition: {
                    operator: 'EQ',
                    id: EQConditionId,
                    value: null,
                },
                changes: {
                    operator: 'EQN',
                    value: '',
                },
            },
            {
                single: true,
                condition: {
                    operator: 'NE',
                    id: EQConditionId,
                    value: null,
                },
                changes: {
                    operator: 'NEN',
                    value: '',
                },
            },
            {
                condition: {
                    value: null,
                },
                changes: {
                    value: '',
                },
            },
            {
                single: true,
                condition: {
                    operator: 'EQ',
                    id: conditionId,
                },
                changes: {
                    operator: 'LEQN',
                    id: changesId,
                },
            },
            {
                single: true,
                condition: {
                    operator: 'NE',
                    id: conditionId,
                },
                changes: {
                    operator: 'LNEN',
                    id: changesId,
                },
            },
        ];

        return MetaQueryService.processMatrix({
            matrix,
            item: {
                id: attribute,
                operator,
                value,
                hash: uuidV4(),
            },
        });
    }
}
