import React, { useRef, useEffect, useState } from 'react';
import { format } from 'd3';
import { useTranslation } from 'react-i18next';

import { useResizeObserver } from '/services';
import { ChartAxis } from '/visual/scenes/Dashboard/components/Gadget/components';
import { IBarStackedChart, barData } from '/visual/scenes/Dashboard/components/Gadget/models';
import { charts } from '/visual/scenes/Dashboard/components/Gadget/contstants';
import { GadgetService } from '/visual/scenes/Dashboard/components';

import { StackedBarChartService } from '../../services';
import styles from '../../style.module.scss';

const {
    marginTop,
    yLeftLabelOffset,
    rightOffset,
    innerPaddingForBarGroups,
    outerPaddingForBarGroups,
} = charts.stacked_bar_chart;

export const StackedBarChartD3 = ({
    dataSet,
    allGroups,
    gadgetId,
    showScore,
    percentage,
    decimalDigits,
    highlightedLabel,
    axisLabels,
    gadgetFunction,
    drillDownEnabled,
    chartType,
    isNpsChart,
    npsData,
    toolTipRef,
    onApplyLabelModal,
    resetTickData,
    setChangeTickModal,
    saveTickOrder,
    setColorPickerData,
    drillDownFromSelection,
}: IBarStackedChart) => {
    const { t } = useTranslation();
    const [ chartInstance, setChartInstance ] = useState<StackedBarChartService | null>(null);
    const [ xAxisSize, setXAxisSize ] = useState<number | null>(null);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const wrapperRef = useRef<HTMLHeadingElement | null>(null);
    const dimensions = useResizeObserver(wrapperRef);
    const {
        showYLabels,
        showXLabels,
        internalWidth,
        internalHeight,
        boundedWidth,
        boundedHeight,
    } = GadgetService.getChartSizes({
        wrapperRef: wrapperRef.current,
        isNpsChart: isNpsChart,
        chartType,
        xAxisSize,
    });

    const drawChart = (list: barData[], allList: barData[]) => {
        if (chartInstance && chartInstance.isInitialized && boundedHeight > 0 && boundedWidth > 0) {
            const tickFormat = format('.0%');

            chartInstance
                .preConfig({
                    redraw: drawChart,
                    withDnD: true,
                    dragAndDropDataSet: list,
                    allList: allList,
                    xAxisSize: xAxisSize,
                    tickList: list,
                })
                .drawContainer({
                    svgWidth: internalWidth,
                    svgHeight: internalHeight,
                    chartWidth: boundedWidth,
                    chartHeight: boundedHeight,
                    transformValue: `translate(${showYLabels ? yLeftLabelOffset : yLeftLabelOffset + rightOffset / 2}px, ${marginTop}px)`,
                    isNpsChart,
                    npsData,
                })
                .drawYAxis({
                    domainMin: 0,
                    domainMax: 1,
                    // from top of the cord system (chart height) to origin (0)
                    rangeFrom: boundedHeight,
                    rangeTo: 0,
                    tickFormat: tickFormat,
                })
                .drawXAxis({
                    domainValues: list.map(({ id }) => id !== null ? id : ''),
                    // from origin of the cord system (0) to right
                    rangeFrom: 0,
                    rangeTo: boundedWidth,
                    bandInnerPadding: innerPaddingForBarGroups,
                    bandOuterPadding: outerPaddingForBarGroups,
                    additionalSettings: [{ prop: 'style', value: [ 'transform', `translateY(${boundedHeight}px)` ] }],
                })
                .drawRightAxis()
                .drawStackedBars({ dataSet: list, groupClass: styles.barGroup })
                .drawNpsLine();
        }
    };

    useEffect(() => {
        if (svgRef.current && toolTipRef.current) {
            const chartInstance = new StackedBarChartService({
                chartType: chartType,
                svgRef: svgRef.current as SVGSVGElement,
                toolTipRef: toolTipRef.current,
                gadgetFunction: gadgetFunction,
                drillDownEnabled: drillDownEnabled,
                gadgetId: gadgetId,
                percentage: percentage,
                decimalDigits,
                withVerticalGrid: true,
                withHorizontalGrid: true,
                showScore,
                t,
                setTickModal: setChangeTickModal,
                resetTickModal: resetTickData,
                saveTickOrder: saveTickOrder,
                setXAxisSize: setXAxisSize,
                setColorPickerData: setColorPickerData,
                drillDownFromSelection: drillDownFromSelection,
            });

            setChartInstance(chartInstance);
        }

        return () => {
            if (chartInstance) {
                chartInstance.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (dataSet && dataSet.length > 0 && dimensions !== null) {
            drawChart(dataSet, allGroups);
        }
    }, [ dataSet, dimensions, xAxisSize ]);

    useEffect(() => {
        if (chartInstance && chartInstance.isInitialized) {
            chartInstance.setHighlightedSlice(highlightedLabel);
        }
    }, [ highlightedLabel ]);

    return (
        <div
            ref={ wrapperRef }
            className={ styles.barChartWrapper }
        >
            <ChartAxis
                axisLabels={ axisLabels }
                axisSize={{ width: boundedWidth, height: boundedHeight }}
                gadgetId={ gadgetId }
                chartSettings={{
                    marginTop,
                    isNpsChart,
                    showYLabels: showYLabels,
                    showXLabels: showXLabels,
                    xOffsetForNps: isNpsChart ? rightOffset + yLeftLabelOffset : rightOffset,
                }}
                onApplyLabelModalData={ onApplyLabelModal }
            >
                <svg ref={ svgRef } />
            </ChartAxis>
        </div>
    );
};
