import { createSelector } from 'reselect';
import { TFunction } from 'i18next';

import { IDashboards, IRights } from '../models';
import { UsersRoleService } from '/services';
import { isSupportUserSelector, _getAllUserInfo, hasVisualWriteAccessSelector } from '/modules/selectors';

const _getDashboards = (state: { VocVisual: { common: IDashboards } }) => state.VocVisual.common;

export const _getDashboard = (state: { VocVisual: { common: IDashboards } }) => state.VocVisual?.dashboard?.dashboard;

export const _getIsCreated = (state: { VocVisual: { common: IDashboards } }) => state.VocVisual.common.isCreated;

export const dashboardsSelector = createSelector(
    [ _getDashboards ], dashboard => {
        return dashboard;
    },
);

export const oldDashboardsSelector = createSelector(
    [ _getDashboards ], ({ oldDashboards }) => {
        return {
            ...oldDashboards,
            data: oldDashboards.data.map(dashboard => ({
                label: dashboard.title,
                value: dashboard.id,
            })),
        };
    },
);

export const tableDataSelector = (t: TFunction) => createSelector(
    [ _getDashboards, isSupportUserSelector, _getAllUserInfo, hasVisualWriteAccessSelector ],
    ( { dashboards }, isSupportUser, { allUser }, hasVisualWriteAccess ) => {
        return dashboards.data.map(dashboard => {
            const rights: IRights = UsersRoleService.getRightsForVisual(isSupportUser, dashboard.currentUserPermission, hasVisualWriteAccess);
            const owner = dashboard.shares.find(user => user.permission === 'owner')?.ownerId;
            const ownerId = allUser.userList.find(user => user.ssoId === owner)?.fullName || '';

            return {
                ...dashboard,
                shared: {
                    isPermitViewInfoShare: rights.isPermitViewInfoShare,
                },
                actions: {
                    rights,
                },
                ownerId,
                source: dashboard.sources.map(source => source.title).join(', ') || t('noSources'),
            };
        }) || [];
    },
);

export const copyDashboardListSelector = createSelector(
    [ dashboardsSelector ], ({ allDashboards: { data } }) =>
        data.map(dashboardData => ({
            value: dashboardData.id,
            label: dashboardData.title,
        })),
);

const _getScheduler = (state: {VocVisual: {common: IDashboards}}) => state.VocVisual.common.scheduler;

export const schedulerSelector = createSelector(
    [ _getScheduler ], scheduler => scheduler,
);
