import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';
import { SettingsService } from '/feedback/services/SettingsService';
import { getUnsubscribeSurveyGroupId } from '/feedback/modules/VocFeedback.modules';

export const GLOBAL_COMMON_SETTINGS_START_LOADING = 'GLOBAL_COMMON_SETTINGS_START_LOADING';
export const GLOBAL_COMMON_SETTINGS_END_LOADING = 'GLOBAL_COMMON_SETTINGS_END_LOADING';

export const GET_GLOBAL_COMMON_SETTINGS_REQUEST = 'GET_GLOBAL_COMMON_SETTINGS_REQUEST';
export const GET_GLOBAL_COMMON_SETTINGS_SUCCESS = 'GET_GLOBAL_COMMON_SETTINGS_SUCCESS';
export const GET_GLOBAL_COMMON_SETTINGS_ERROR = 'GET_GLOBAL_COMMON_SETTINGS_ERROR';

export const PUT_GLOBAL_COMMON_SETTINGS_REQUEST = 'PUT_GLOBAL_COMMON_SETTINGS_REQUEST';
export const PUT_GLOBAL_COMMON_SETTINGS_SUCCESS = 'PUT_GLOBAL_COMMON_SETTINGS_SUCCESS';
export const PUT_GLOBAL_COMMON_SETTINGS_ERROR = 'PUT_GLOBAL_COMMON_SETTINGS_ERROR';

const GET_GLOBAL_CLASSIFIER_LIST_REQUEST = 'GET_GLOBAL_CLASSIFIER_LIST_REQUEST';
const GET_GLOBAL_CLASSIFIER_LIST_SUCCESS = 'GET_GLOBAL_CLASSIFIER_LIST_SUCCESS';
const GET_GLOBAL_CLASSIFIER_LIST_ERROR = 'GET_GLOBAL_CLASSIFIER_LIST_ERROR';

export const GLOBAL_SETTINGS_CLEANUP = 'GLOBAL_SETTINGS_CLEANUP';

const modelKeys = SettingsService.getKeysForFilter('common', true);

function getInitialState() {
    return {
        loading: false,
        sending: false,
        settings: {},
        classifier: {
            loading: false,
            results: [],
        },
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GLOBAL_COMMON_SETTINGS_START_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case GLOBAL_COMMON_SETTINGS_END_LOADING: {
            return {
                ...state,
                loading: false,
            };
        }

        case GET_GLOBAL_COMMON_SETTINGS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_GLOBAL_COMMON_SETTINGS_SUCCESS: {
            return {
                ...state,
                loading: false,
                settings: {
                    ...HelperService.pick(action.response, modelKeys),
                },
            };
        }

        case GET_GLOBAL_COMMON_SETTINGS_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case PUT_GLOBAL_COMMON_SETTINGS_REQUEST: {
            return {
                ...state,
                sending: true,
            };
        }

        case PUT_GLOBAL_COMMON_SETTINGS_SUCCESS: {
            return {
                ...state,
                settings: {
                    ...HelperService.pick(action.response, modelKeys),
                },
                sending: false,
            };
        }

        case PUT_GLOBAL_COMMON_SETTINGS_ERROR: {
            return {
                ...state,
                sending: false,
            };
        }

        case GET_GLOBAL_CLASSIFIER_LIST_REQUEST: {
            return {
                ...state,
                classifier: {
                    loading: true,
                    results: [],
                },
            };
        }

        case GET_GLOBAL_CLASSIFIER_LIST_SUCCESS: {
            return {
                ...state,
                classifier: {
                    loading: false,
                    results: [
                        ...action.response,
                    ],
                },
            };
        }

        case GET_GLOBAL_CLASSIFIER_LIST_ERROR: {
            return {
                ...state,
                classifier: {
                    loading: false,
                    results: [],
                },
            };
        }

        case GLOBAL_SETTINGS_CLEANUP:
            return getInitialState();

        default:
            return state;
    }
}

export function getGlobalCommonSetting(unit) {
    return dispatch => {
        dispatch(startProcessing());
        const channelSettingRequest = dispatch(getGlobalCommonSettingRequest(unit));
        const unsubscribeSurveyGroupRequest = dispatch(getUnsubscribeSurveyGroupId(unit));

        const requests = [ channelSettingRequest, unsubscribeSurveyGroupRequest ];
        const promises = [ channelSettingRequest.promise, unsubscribeSurveyGroupRequest.promise ];

        Promise.all(promises)
            .then(() => dispatch(endProcessing()), ({ status }) => {
                if (status !== 0) {
                    return dispatch(endProcessing());
                }
            });

        return requests;
    };
}

export function startProcessing() {
    return {
        type: GLOBAL_COMMON_SETTINGS_START_LOADING,
    };
}

export function endProcessing() {
    return {
        type: GLOBAL_COMMON_SETTINGS_END_LOADING,
    };
}

export function getGlobalCommonSettingRequest(unit) {
    return {
        [CALL_API]: {
            endpoint: `unit/${ unit }/get-channel-settings/WEB/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_GLOBAL_COMMON_SETTINGS_REQUEST, GET_GLOBAL_COMMON_SETTINGS_SUCCESS, GET_GLOBAL_COMMON_SETTINGS_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function putGlobalCommonSettings(model) {
    return dispatch => {
        return dispatch(putGlobalCommonSettingsRequest(model)).promise
            .catch(SettingsService.catchSettingsErrors);
    };
}

export function putGlobalCommonSettingsRequest({ unit, body }) {
    return {
        [CALL_API]: {
            endpoint: `/unit/${ unit }/set-settings/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ PUT_GLOBAL_COMMON_SETTINGS_REQUEST, PUT_GLOBAL_COMMON_SETTINGS_SUCCESS, PUT_GLOBAL_COMMON_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Global Common settings were successfully updated',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function getGlobalClassifierList() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/classify/classifier/list`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_GLOBAL_CLASSIFIER_LIST_REQUEST, GET_GLOBAL_CLASSIFIER_LIST_SUCCESS, GET_GLOBAL_CLASSIFIER_LIST_ERROR ],
        },
    };
}


export function cleanup() {
    return {
        type: GLOBAL_SETTINGS_CLEANUP,
    };
}
