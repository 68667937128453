import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { VocModal } from '/components';
import './ExtendUploadModal.scss';

export const ExtendUploadModal = ({
    close,
    actionHandler,
    show,
    newHeaders,
}) => {
    const { t } = useTranslation();

    const getModalFooter = () =>
        <div>
            <Button onClick={ close }>
                { t('cancel') }
            </Button>
            <Button onClick={ actionHandler } bsStyle="primary">
                { t('ok') }
            </Button>
        </div>;

    return (
        <VocModal
            isOpen={ show }
            toggle={ close }
            header={ t('addingNewColumnToScheme') }
            footer={ getModalFooter() }
        >
            <p>{ t('extendSourceSchemeWithNewFields') }</p>
            <div className='modal-body_scroll'>
                <ul>
                    {
                        newHeaders.map((header, i) => (
                            <li key={ i }>
                                <strong>
                                    { i } : { header }
                                </strong>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <p>{ t('undoneDoYouWantToProceed') }</p>
        </VocModal>
    );
};

ExtendUploadModal.propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    newHeaders: PropTypes.array.isRequired,
    actionHandler: PropTypes.func,
};

