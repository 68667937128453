import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './TimeDateGridDayPicker.scss';

export const TimeDateGridDayPicker = ({
    isDatePickerOpen,
    setIsDatePickerOpen,
    selectedDate,
    datePickHandler,
    datesToDisable,
    minData,
    disable = false,
}) => {
    return (
        <div className={ 'time-date-grid__date-picker' }>
            <Button
                onClick={ () => !isDatePickerOpen && setIsDatePickerOpen(true) }
                color={ 'white' }
                outline
                disabled={ isDatePickerOpen || disable }
            >
                <FontAwesomeIcon icon='plus' className='color-green'/>
                <span> { 'Add' } </span>
            </Button>
            <DatePicker
                dayClassName={ date =>
                    datesToDisable.includes(moment(date).format('YYYY-MM-DD'))
                        ? 'disabled-date' : ''
                }
                selected={ selectedDate }
                minDate={ minData ? moment().toDate() : minData }
                onChange={ datePickHandler }
                onClickOutside={ () => isDatePickerOpen && setIsDatePickerOpen(false) }
                open={ isDatePickerOpen }
            />
        </div>
    );
};
