import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/free-regular-svg-icons';

import { TooltipWrapper } from '/components';

import { IButtonProps } from './models';

export const ButtonComponent = ({
    isShow = true,
    buttonProps,
    icon,
    iconList = [],
    text,
    tooltipProps,
}: IButtonProps) => {
    const getButton = () => {
        return isShow
            ? <Button
                outline
                { ...buttonProps }
            >
                {
                    icon && <FontAwesomeIcon icon={ icon }/>
                }

                {
                    iconList?.length > 0 && iconList.map((iconName: IconName) => (
                        <FontAwesomeIcon key={ iconName } icon={ iconName } />
                    ))
                }

                {
                    text && <span> { text } </span>
                }
            </Button>
            : null;
    };

    return tooltipProps && isShow
        ? <TooltipWrapper { ...tooltipProps }>
            { getButton() }
        </TooltipWrapper>
        : getButton();
};
