import React, { Component } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { autobind } from 'core-decorators';

import { SortableAnswerItem } from '../AnswerItem/';

class AnswersListComponent extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.items.length !== this.props.items.length
            || nextProps.language !== this.props.language
            || nextProps.isUnsubscribe !== this.props.isUnsubscribe
            || nextProps.isOneUnchecked !== this.props.isOneUnchecked
            || nextProps.viewConfig !== this.props.viewConfig;
    }

    @autobind
    onDeleteAnswerCLick(event) {
        const { target: { dataset: { id } } } = event;
        const { items, showConfirm } = this.props;

        showConfirm({
            header: 'Delete answer',
            content: 'Are you sure, you want to delete answer?',
            successCallback: () => items.remove(id),
        });
    }

    render() {
        const {
            items,
            language,
            channel,
            htmlEditorCallback,
            showEditor,
            isUnsubscribe,
            answers,
            isOneUnchecked,
            viewConfig,
        } = this.props;

        return (
            <ul className='question__answers-list'>
                {
                    items.map((item, index) => {
                        return (
                            <SortableAnswerItem
                                key={ `item-${ index }` }
                                htmlEditorCallback={ htmlEditorCallback }
                                showEditor={ showEditor }
                                isUnsubscribe={ isUnsubscribe }
                                answer={ answers[index] }
                                isOneUnchecked={ isOneUnchecked }
                                viewConfig={ viewConfig }
                                { ...{
                                    language,
                                    index,
                                    id: index,
                                    item,
                                    channel,
                                    onDeleteAnswerCLick: this.onDeleteAnswerCLick,
                                } }
                            />
                        );
                    })
                }
            </ul>
        );
    }
}

export const AnswersList = SortableContainer(AnswersListComponent);
