import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { showToast } from '/scenes/Toasts/modules/Toasts.modules';
import { HelperService } from '/services';

export const GET_SURVEY_GROUP_REQUEST = 'GET_SURVEY_GROUP_REQUEST';
export const GET_SURVEY_GROUP_SUCCESS = 'GET_SURVEY_GROUP_SUCCESS';
export const GET_SURVEY_GROUP_ERROR = 'GET_SURVEY_GROUP_ERROR';

export const GET_SURVEYS_GROUP_LANG_SET_REQUEST = 'GET_SURVEYS_GROUP_LANG_SET_REQUEST';
export const GET_SURVEYS_GROUP_LANG_SET_SUCCESS = 'GET_SURVEYS_GROUP_LANG_SET_SUCCESS';
export const GET_SURVEYS_GROUP_LANG_SET_ERROR = 'GET_SURVEYS_GROUP_LANG_SET_ERROR';

const GET_QUESTIONNAIRE_REQUEST = 'GET_QUESTIONNAIRE_REQUEST';
const GET_QUESTIONNAIRE_SUCCESS = 'GET_QUESTIONNAIRE_SUCCESS';
const GET_QUESTIONNAIRE_ERROR = 'GET_QUESTIONNAIRE_ERROR';

const GET_IMPORT_EXAMPLE_REQUEST = 'GET_IMPORT_EXAMPLE_REQUEST';
const GET_IMPORT_EXAMPLE_SUCCESS = 'GET_IMPORT_EXAMPLE_SUCCESS';
const GET_IMPORT_EXAMPLE_ERROR = 'GET_IMPORT_EXAMPLE_ERROR';

const GET_UNITS_LIST_REQUEST = 'GET_UNITS_LIST_REQUEST';
const GET_UNITS_LIST_SUCCESS = 'GET_UNITS_LIST_SUCCESS';
const GET_UNITS_LIST_ERROR = 'GET_UNITS_LIST_ERROR';

const GET_CHANNEL_LIST_REQUEST = 'GET_CHANNEL_LIST_REQUEST';
const GET_CHANNEL_LIST_SUCCESS = 'GET_CHANNEL_LIST_SUCCESS';
const GET_CHANNEL_LIST_ERROR = 'GET_CHANNEL_LIST_ERROR';

const GET_LAYOUTS_REQUEST = 'GET_LAYOUTS_REQUEST';
const GET_LAYOUTS_ERROR = 'GET_LAYOUTS_ERROR';
const GET_LAYOUTS_SUCCESS = 'GET_LAYOUTS_SUCCESS';

const GET_SMS_GATEWAYS_LIST_REQUEST = 'GET_SMS_GATEWAYS_LIST_REQUEST';
const GET_SMS_GATEWAYS_LIST_SUCCESS = 'GET_SMS_GATEWAYS_LIST_SUCCESS';
const GET_SMS_GATEWAYS_LIST_ERROR = 'GET_SMS_GATEWAYS_LIST_ERROR';

const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
const GET_LANGUAGES_ERROR = 'GET_LANGUAGES_ERROR';

const GET_STATES_REQUEST = 'GET_STATES_REQUEST';
const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
const GET_STATES_ERROR = 'GET_STATES_ERROR';

const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST';
const SEND_INVITE_ERROR = 'SEND_INVITE_ERROR';

const GET_QUESTION_TYPES_REQUEST = 'api/rest/question-type/request';
const GET_QUESTION_TYPES_SUCCESS = 'api/rest/question-type/success';
const GET_QUESTION_TYPES_ERROR = 'api/rest/question-type/error';

const GET_QUESTION_TYPES_OPTIONS_REQUEST = 'api/rest/question-type-option/request';
const GET_QUESTION_TYPES_OPTIONS_SUCCESS = 'api/rest/question-type-option/success';
const GET_QUESTION_TYPES_OPTIONS_ERROR = 'api/rest/question-type-option/error';

const GET_UNIT_THEME_LIST_REQUEST = 'GET_UNIT_THEME_LIST_REQUEST';
const GET_UNIT_THEME_LIST_SUCCESS = 'GET_UNIT_THEME_LIST_SUCCESS';
const GET_UNIT_THEME_LIST_ERROR = 'GET_UNIT_THEME_LIST_ERROR';

const GET_UNSUBSCRIBE_SURVEYS_REQUEST = 'GET_UNSUBSCRIBE_SURVEYS_REQUEST';
const GET_UNSUBSCRIBE_SURVEYS_SUCCESS = 'GET_UNSUBSCRIBE_SURVEYS_SUCCESS';
const GET_UNSUBSCRIBE_SURVEYS_ERROR = 'GET_UNSUBSCRIBE_SURVEYS_ERROR';

const METADATA_REQUEST = 'METADATA_REQUEST';
const METADATA__SUCCESS = 'METADATA__SUCCESS';
const METADATA__ERROR = 'METADATA__ERROR';

const GET_SURVEY_LIST_REQUEST = 'GET_SURVEY_LIST_REQUEST';
const GET_SURVEY_LIST_SUCCESS = 'GET_SURVEY_LIST_SUCCESS';
const GET_SURVEY_LIST_ERROR = 'GET_SURVEY_LIST_ERROR';

const GET_SURVEYS_WITH_DELETED_REQUEST = 'GET_SURVEYS_WITH_DELETED_REQUEST';
const GET_SURVEYS_WITH_DELETED_SUCCESS = 'GET_SURVEYS_WITH_DELETED_SUCCESS';
const GET_SURVEYS_WITH_DELETED_ERROR = 'GET_SURVEYS_WITH_DELETED_ERROR';

const GET_CLASSIFIER_LIST_ERROR = 'GET_CLASSIFIER_LIST_ERROR';
const GET_CLASSIFIER_LIST_REQUEST = 'GET_CLASSIFIER_LIST_REQUEST';
const GET_CLASSIFIER_LIST_SUCCESS = 'GET_CLASSIFIER_LIST_SUCCESS';

const GET_PERMISSION_LIST_REQUEST = 'GET_PERMISSION_LIST_REQUEST';
const GET_PERMISSION_LIST_SUCCESS = 'GET_PERMISSION_LIST_SUCCESS';
const GET_PERMISSION_LIST_ERROR = 'GET_PERMISSION_LIST_ERROR';

const GET_USER_PERMISSION_REQUEST = 'GET_USER_PERMISSION_REQUEST';
const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS';
const GET_USER_PERMISSION_ERROR = 'GET_USER_PERMISSION_ERROR';

const GET_SUBSTATE_REQUEST = 'GET_SUBSTATE_REQUEST';
const GET_SUBSTATE_SUCCESS = 'GET_SUBSTATE_SUCCESS';
const GET_SUBSTATE_ERROR = 'GET_SUBSTATE_ERROR';

function getInitialState() {
    return {
        languages: {
            count: 0,
            next: null,
            loading: null,
            previous: null,
            results: [],
        },
        unitsList: {
            count: 0,
            loading: null,
            next: null,
            previous: null,
            results: [],
        },
        channelList: {
            count: 0,
            results: [],
            loading: false,
        },
        states: {
            address: [],
            customer: [],
            export: [],
            import: [],
            loading: null,
            questionnaire: [],
            survey: [],
        },
        questionTypes: {
            loading: null,
            results: [],
        },
        questionTypeOptions: {
            loading: null,
            results: [],
        },
        layouts: {
            loading: null,
            results: [],
        },
        phoneForSender: {
            loading: null,
            results: [],
        },
        SMSGatewaysList: {
            loading: null,
            results: [],
        },
        themeList: {
            loading: null,
            results: [],
        },
        unsubscribeSurveys: {
            loading: false,
            results: [],
        },
        metaData: {
            loading: null,
            results: [],
        },
        surveyList: {
            loading: null,
            results: [],
        },
        surveysWithDeleted: {
            loading: null,
            results: [],
        },
        classifier: {
            loading: false,
            results: [],
        },
        permissionList: {
            loading: false,
            results: [],
        },
        userPermission: {
            loading: false,
            loaded: false,
            results: [],
            mainPermission: [],
        },
        subState: {
            loading: false,
            results: [],
        },
    };
}

export function CommonReducer(state = (getInitialState()), action = {}) {
    switch (action.type) {
        case GET_UNITS_LIST_REQUEST: {
            return {
                ...state,
                unitsList: {
                    ...state.unitsList,
                    loading: true,
                },
            };
        }

        case GET_UNITS_LIST_SUCCESS: {
            return {
                ...state,
                unitsList: {
                    ...action.response,
                    loading: false,
                },
            };
        }

        case GET_LANGUAGES_ERROR:
        case GET_UNITS_LIST_ERROR: {
            return state;
        }

        case GET_CHANNEL_LIST_REQUEST: {
            return {
                ...state,
                channelList: {
                    ...state.unitsList,
                    loading: true,
                },
            };
        }

        case GET_CHANNEL_LIST_SUCCESS: {
            return {
                ...state,
                channelList: {
                    ...action.response,
                    loading: false,
                },
            };
        }

        case GET_CHANNEL_LIST_ERROR: {
            return {
                state,
                channelList: {
                    ...state.channelList,
                    loading: false,
                },
            };
        }

        case GET_LANGUAGES_REQUEST: {
            return {
                ...state,
                languages: {
                    ...state.languages,
                    loading: true,
                },
            };
        }

        case GET_STATES_SUCCESS: {
            return {
                ...state,
                states: action.response.results.reduce((acc, item) => {
                    const key = Object.keys(item)[0];

                    acc[key] = item[key];

                    key === 'questionnaire' && (
                        acc[key] = item[key].map(status => status.name === 'aborted'
                            ? {
                                ...status,
                                name: 'partial',
                            }
                            : status,
                        )
                    );

                    return acc;
                }, { loading: false }),
            };
        }

        case GET_LAYOUTS_REQUEST:
        case GET_LAYOUTS_ERROR: {
            return {
                ...state,
                layouts: {
                    ...state.layouts,
                    loading: true,
                },
            };
        }

        case GET_LAYOUTS_SUCCESS: {
            return {
                ...state,
                layouts: {
                    ...action.response,
                    loading: false,
                },
            };
        }

        case GET_SMS_GATEWAYS_LIST_REQUEST: {
            return {
                ...state,
                SMSGatewaysList: {
                    ...state.SMSGatewaysList,
                    loading: true,
                },
            };
        }

        case GET_SMS_GATEWAYS_LIST_ERROR: {
            return {
                ...state,
                SMSGatewaysList: {
                    ...state.SMSGatewaysList,
                    loading: false,
                },
            };
        }

        case GET_SMS_GATEWAYS_LIST_SUCCESS: {
            return {
                ...state,
                SMSGatewaysList: {
                    results: [ ...action.response ],
                    loading: false,
                },
            };
        }

        case GET_LANGUAGES_SUCCESS: {
            return {
                ...state,
                languages: {
                    ...action.response,
                    loading: false,
                },
            };
        }

        case GET_QUESTION_TYPES_REQUEST: {
            return {
                ...state,
                questionTypes: {
                    ...state.questionTypes,
                    loading: true,
                },
            };
        }

        case GET_QUESTION_TYPES_SUCCESS: {
            return {
                ...state,
                questionTypes: {
                    ...state.questionTypes,
                    loading: false,
                    results: action.response.results,
                },
            };
        }

        case GET_QUESTION_TYPES_ERROR: {
            return {
                ...state,
                questionTypes: {
                    ...state.questionTypes,
                    loading: false,
                },
            };
        }

        case GET_QUESTION_TYPES_OPTIONS_REQUEST: {
            return {
                ...state,
                questionTypeOptions: {
                    ...state.questionTypeOptions,
                    loading: true,
                },
            };
        }

        case GET_QUESTION_TYPES_OPTIONS_SUCCESS: {
            return {
                ...state,
                questionTypeOptions: {
                    ...state.questionTypeOptions,
                    loading: false,
                    results: action.response,
                },
            };
        }

        case GET_QUESTION_TYPES_OPTIONS_ERROR: {
            return {
                ...state,
                questionTypeOptions: {
                    ...state.questionTypeOptions,
                    loading: false,
                },
            };
        }

        case GET_UNIT_THEME_LIST_REQUEST: {
            return {
                ...state,
                themeList: {
                    ...state.themeList,
                    loading: true,
                },
            };
        }

        case GET_UNIT_THEME_LIST_SUCCESS: {
            return {
                ...state,
                themeList: {
                    ...state.themeList,
                    loading: false,
                    results: action.response,
                },
            };
        }

        case GET_UNIT_THEME_LIST_ERROR: {
            return {
                ...state,
                themeList: {
                    ...state.themeList,
                    loading: false,
                },
            };
        }

        case GET_UNSUBSCRIBE_SURVEYS_REQUEST: {
            return {
                ...state,
                unsubscribeSurveys: {
                    ...state.unsubscribeSurveys,
                    loading: true,
                },
            };
        }

        case GET_UNSUBSCRIBE_SURVEYS_SUCCESS: {
            return {
                ...state,
                unsubscribeSurveys: {
                    ...state.unsubscribeSurveys,
                    loading: false,
                    results: action.response,
                },
            };
        }

        case GET_UNSUBSCRIBE_SURVEYS_ERROR: {
            return {
                ...state,
                unsubscribeSurveys: {
                    ...state.unsubscribeSurveys,
                    loading: false,
                },
            };
        }

        case METADATA_REQUEST: {
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    loading: true,
                },
            };
        }

        case METADATA__SUCCESS: {
            return {
                ...state,
                metaData: {
                    loading: false,
                    results: [
                        ...action.response.results,
                    ],
                },
            };
        }

        case METADATA__ERROR: {
            return {
                ...state,
                metaData: {
                    loading: false,
                    results: [],
                },
            };
        }

        case GET_SURVEY_LIST_REQUEST: {
            return {
                ...state,
                surveyList: {
                    ...state.surveyList,
                    loading: true,
                },
            };
        }

        case GET_SURVEY_LIST_SUCCESS: {
            return {
                ...state,
                surveyList: {
                    loading: false,
                    results: [
                        ...action.response.results,
                    ],
                },
            };
        }

        case GET_SURVEY_LIST_ERROR: {
            return {
                ...state,
                surveyList: {
                    ...state.surveyList,
                    loading: false,
                },
            };
        }

        case GET_SURVEYS_WITH_DELETED_REQUEST: {
            return {
                ...state,
                surveysWithDeleted: {
                    ...state.surveyList,
                    loading: true,
                },
            };
        }

        case GET_SURVEYS_WITH_DELETED_SUCCESS: {
            return {
                ...state,
                surveysWithDeleted: {
                    loading: false,
                    results: [
                        ...action.response.results,
                    ],
                },
            };
        }

        case GET_SURVEYS_WITH_DELETED_ERROR: {
            return {
                ...state,
                surveysWithDeleted: {
                    ...state.surveyList,
                    loading: false,
                },
            };
        }

        case GET_CLASSIFIER_LIST_REQUEST: {
            return {
                ...state,
                classifier: {
                    loading: true,
                    results: [],
                },
            };
        }

        case GET_CLASSIFIER_LIST_SUCCESS: {
            return {
                ...state,
                classifier: {
                    loading: false,
                    results: [
                        ...action.response,
                    ],
                },
            };
        }

        case GET_CLASSIFIER_LIST_ERROR: {
            return {
                ...state,
                classifier: {
                    loading: false,
                    results: [],
                },
            };
        }

        case GET_PERMISSION_LIST_REQUEST: {
            return {
                ...state,
                permissionList: {
                    loading: true,
                    results: [],
                },
            };
        }
        case GET_PERMISSION_LIST_SUCCESS: {
            return {
                ...state,
                permissionList: {
                    loading: false,
                    results: action.response.results,
                },
            };
        }
        case GET_PERMISSION_LIST_ERROR: {
            return {
                ...state,
                permissionList: {
                    loading: false,
                    results: [],
                },
            };
        }

        case GET_USER_PERMISSION_REQUEST: {
            return {
                ...state,
                userPermission: {
                    loading: true,
                    loaded: false,
                    mainPermission: [],
                    results: [],
                },
            };
        }

        case GET_USER_PERMISSION_SUCCESS: {
            const mainPermission = action.response
                .filter(({ permissions }) => permissions.length)
                .map(({ unit, permissions }) => {
                    const sorted = [];

                    permissions.forEach(permission => sorted.push(permission.name));

                    return {
                        unit,
                        permissionsName: sorted,
                    };
                });

            return {
                ...state,
                userPermission: {
                    loading: false,
                    loaded: true,
                    mainPermission,
                    results: action.response,
                },
            };
        }

        case GET_USER_PERMISSION_ERROR: {
            return {
                ...state,
                userPermission: {
                    loading: false,
                    loaded: true,
                    mainPermission: [],
                    results: [],
                },
            };
        }

        case GET_SUBSTATE_REQUEST: {
            return {
                ...state,
                subState: {
                    loading: true,
                    results: [],
                },
            };
        }

        case GET_SUBSTATE_SUCCESS: {
            return {
                ...state,
                subState: {
                    loading: true,
                    ...action.response,
                },
            };
        }

        case GET_SUBSTATE_ERROR: {
            return {
                ...state,
                subState: {
                    loading: true,
                    results: [],
                },
            };
        }

        default:
            return state;
    }
}

export function getLayoutsList() {
    return {
        [CALL_API]: {
            endpoint: `/template/`,
            method: 'GET',
            types: [ GET_LAYOUTS_REQUEST, GET_LAYOUTS_SUCCESS, GET_LAYOUTS_ERROR ],
            apiType: FEEDBACK,
            body: {
                limit: 9999,
            },
        },
    };
}

export function getImportExampleFile() {
    return {
        [CALL_API]: {
            endpoint: `/download-template/`,
            method: 'GET',
            types: [ GET_IMPORT_EXAMPLE_REQUEST, GET_IMPORT_EXAMPLE_SUCCESS, GET_IMPORT_EXAMPLE_ERROR ],
            apiType: FEEDBACK,
            contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            responseType: 'blob',
            fileName: 'Import_File_Template',
        },
    };
}

export function getSMSGatewaysList() {
    return {
        [CALL_API]: {
            endpoint: `/channel/sms-gateways/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SMS_GATEWAYS_LIST_REQUEST, GET_SMS_GATEWAYS_LIST_SUCCESS, GET_SMS_GATEWAYS_LIST_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getUnitsList(params) {
    return {
        [CALL_API]: {
            endpoint: `/unit/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_UNITS_LIST_REQUEST, GET_UNITS_LIST_SUCCESS, GET_UNITS_LIST_ERROR ],
            body: params,
            apiType: FEEDBACK,
        },
    };
}

export function getChannelList() {
    return {
        [CALL_API]: {
            endpoint: `/channel/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CHANNEL_LIST_REQUEST, GET_CHANNEL_LIST_SUCCESS, GET_CHANNEL_LIST_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getStates() {
    return {
        [CALL_API]: {
            endpoint: '/state/',
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_STATES_REQUEST, GET_STATES_SUCCESS, GET_STATES_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getLanguages(params) {
    return {
        [CALL_API]: {
            endpoint: `/language/?limit=9999`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_LANGUAGES_REQUEST, GET_LANGUAGES_SUCCESS, GET_LANGUAGES_ERROR ],
            body: params,
            apiType: FEEDBACK,
        },
    };
}

export function getQuestionTypes() {
    return {
        [CALL_API]: {
            endpoint: `/question-type/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_QUESTION_TYPES_REQUEST, GET_QUESTION_TYPES_SUCCESS, GET_QUESTION_TYPES_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getQuestionTypeOptions() {
    return {
        [CALL_API]: {
            endpoint: '/question-type-option/',
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_QUESTION_TYPES_OPTIONS_REQUEST, GET_QUESTION_TYPES_OPTIONS_SUCCESS, GET_QUESTION_TYPES_OPTIONS_ERROR ],
            body: {
                limit: 9999,
                // This filter is used for getting only QuestionTypeOption with appropriate settings in FIELD_TYPES_MAP.
                // Without it all options will be returned.
                has_field_type_map: 1,
            },
            apiType: FEEDBACK,
        },
    };
}

export function sendInviteSuccess() {
    return {
        type: SEND_INVITE_SUCCESS,
    };
}

export function sendInviteRequestAction(data) {
    return {
        type: SEND_INVITE_REQUEST,
        data,
    };
}

export function sendInvite(data) {
    return dispatch => {
        return dispatch(getQuestionnaire(data)).promise
            .then(questionnaire => {
                dispatch(sendInviteRequest(questionnaire.hash)).promise.then(() => {
                    dispatch(showToast({ text: 'Invitation was successfully sent!', type: 'success' }));
                });
            }, error => {
                HelperService.mapResponseServerError(error, showToast, dispatch);
                return Promise.reject();
            });
    };
}

export function getQuestionnaire(data) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_QUESTIONNAIRE_REQUEST, GET_QUESTIONNAIRE_SUCCESS, GET_QUESTIONNAIRE_ERROR ],
            body: data,
            apiType: FEEDBACK,
        },
    };
}

export function sendInviteRequest(hash) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire/${ hash }/invite/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ SEND_INVITE_REQUEST, SEND_INVITE_SUCCESS, SEND_INVITE_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getUnitThemeList(unit) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/list-unit-level/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_UNIT_THEME_LIST_REQUEST,
                GET_UNIT_THEME_LIST_SUCCESS,
                GET_UNIT_THEME_LIST_ERROR,
            ],
            apiType: FEEDBACK,
            body: {
                limit: 9999,
                id: unit,
            },
        },
    };
}

export function getUnsubscribeSurveyGroupId(unit) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/unsubscribe-survey-groups/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_UNSUBSCRIBE_SURVEYS_REQUEST,
                GET_UNSUBSCRIBE_SURVEYS_SUCCESS,
                GET_UNSUBSCRIBE_SURVEYS_ERROR,
            ],
            apiType: FEEDBACK,
            body: {
                limit: 9999,
                unit: unit,
            },
        },
    };
}

export function getSurveysGroup(params) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_GROUP_REQUEST, GET_SURVEY_GROUP_SUCCESS, GET_SURVEY_GROUP_ERROR ],
            body: params,
            apiType: FEEDBACK,
        },
    };
}

export function getSurveysGroupLangSet(surveyGroupId) {
    return {
        [CALL_API]: {
            endpoint: `survey-group/${ surveyGroupId }/has-language-settings/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_SURVEYS_GROUP_LANG_SET_REQUEST,
                GET_SURVEYS_GROUP_LANG_SET_SUCCESS,
                GET_SURVEYS_GROUP_LANG_SET_ERROR,
            ],
            apiType: FEEDBACK,
        },
    };
}

export function getMetaData() {
    return {
        [CALL_API]: {
            endpoint: `/customer-data-name/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ METADATA_REQUEST, METADATA__SUCCESS, METADATA__ERROR ],
            apiType: FEEDBACK,
            body: {
                limit: 9999,
            },
        },
    };
}

export function getSurveyList() {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_LIST_REQUEST, GET_SURVEY_LIST_SUCCESS, GET_SURVEY_LIST_ERROR ],
            apiType: FEEDBACK,
            body: {
                page: 1,
                limit: 9999,
                sort: '-sequence',
                hide_deleted_surveys: 'yes',
            },
        },
    };
}

export function getSurveysWithDeleted() {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEYS_WITH_DELETED_REQUEST, GET_SURVEYS_WITH_DELETED_SUCCESS, GET_SURVEYS_WITH_DELETED_ERROR ],
            apiType: FEEDBACK,
            body: {
                page: 1,
                limit: 9999,
                sort: '-sequence',
            },
        },
    };
}

export function getClassifierList() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/classify/classifier/list`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CLASSIFIER_LIST_REQUEST, GET_CLASSIFIER_LIST_SUCCESS, GET_CLASSIFIER_LIST_ERROR ],
        },
    };
}

export function getPermissionList() {
    return {
        [CALL_API]: {
            endpoint: `/access-permission/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_PERMISSION_LIST_REQUEST, GET_PERMISSION_LIST_SUCCESS, GET_PERMISSION_LIST_ERROR ],
            apiType: FEEDBACK,
            logger: true,
        },
    };
}

export function getUserPermission({ groupIds, userSsoId }) {
    const groups_owners = JSON.stringify(groupIds);

    const URL = new URLSearchParams({
        groups_owners,
    }).toString();

    return {
        [CALL_API]: {
            endpoint: `/access-permission-owner/user-permissions-combined/?user_owner=${userSsoId}&${URL}`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_USER_PERMISSION_REQUEST, GET_USER_PERMISSION_SUCCESS, GET_USER_PERMISSION_ERROR ],
            apiType: FEEDBACK,
            logger: true,
        },
    };
}

export function getSubState() {
    return {
        [CALL_API]: {
            endpoint: `/substate/questionnaire/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SUBSTATE_REQUEST, GET_SUBSTATE_SUCCESS, GET_SUBSTATE_ERROR ],
            apiType: FEEDBACK,
        },
    };
}
