import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

import './ChannelsControl.scss';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    showConfirm,
};

@connect(mapStateToProps, mapDispatchToProps)
export class ChannelControl extends Component {
    componentDidMount() {
        this.timeSlotChannelsHandler();
    }

    componentDidUpdate() {
        this.timeSlotChannelsHandler();
    }

    timeSlotChannelsHandler() {
        const {
            channel,
            disabledChannel,
            activeChannel,
            channelsHandler,
        } = this.props;

        if (activeChannel !== 'SMS' && channel === 'SMS' && disabledChannel) {
            channelsHandler('SMS');
        }
    }

    render() {
        const { channel, activeChannel, disabledChannel, channelsHandler } = this.props;

        let isActiveChannel = channel === activeChannel;
        let className = isActiveChannel ? 'active' : '';

        if (disabledChannel) {
            isActiveChannel = true;
            className = `${ channel !== 'SMS' ? 'inactive' : 'active' }`;
        }

        return (
            <Button className={ `channels-controls ${ className }` }
                onClick={ () => channelsHandler(channel) }
                color={ 'white' }
                outline
                disabled={ isActiveChannel }
            >
                { channel }
            </Button>
        );
    }
}
