import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InputLoader = ({ label, isRequired }) => {
    return (
        <div className={ 'dropdown-wrapper' } key={ 2 }>
            <div className={ 'form-group' }>
                <div className={ 'row' }>
                    <div className={ 'select-control-label col-md-3' }>
                        <label className={ 'control-label' }>{ label } { isRequired ? <span className='star'> *</span> : '' }</label>
                    </div>
                    <div className={ 'col-md-9' }>
                        <p style={{ margin: '8px' }}>
                            <FontAwesomeIcon icon='circle-notch' spin/>
                            <span> Loading...</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
