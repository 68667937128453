import { createSelector } from 'reselect';

export const _getLayoutSelector = state => state.VocFeedback.globalSettings.layouts;
export const layoutFilters = createSelector([ _getLayoutSelector ], ({ filters }) => filters);

export const tableSettingsSelector = createSelector([ _getLayoutSelector ], ({ count, loading }) => {
    return {
        count,
        loading,
    };
});

export const tableDataSelector = createSelector([ _getLayoutSelector ], ({ results }) => results);
