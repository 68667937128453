import React, { useState, useEffect } from 'react';
import { DateForm } from '../TEMPORARILY/DateForm';
import { ClassificationForm } from '../TEMPORARILY/ClassificationForm';
import { AttributesForm } from '../TEMPORARILY/AttributesForm';
import { TextFilterForm } from '../TEMPORARILY/TextFilterForm';
import { LemmataFilterForm } from '../TEMPORARILY/LemmataFilterForm';
import { AdvancedForm } from '../TEMPORARILY/AdvancedForm';
import { VocModal } from '/components';

import './FiltersModal.scss';

const getOppositeName = {
    lemma: {
        lemmaToInclude: 'lemmaToExclude',
        lemmaToExclude: 'lemmaToInclude',
    },
    text: {
        textToInclude: 'textToExclude',
        textToExclude: 'textToInclude',
    },
};

export const FiltersModal = ({
    show,
    name,
    filters,
    indexFilter,
    close,
    onSubmit,
    source,
    attributes,
    getClassification,
    classification,
}) => {
    const [ currentFilter, setCurrentFilter ] = useState({});

    useEffect(() => {
        name && setCurrentFilter(filters.find(filter => filter.id === name));

        return () => {
            setCurrentFilter({});
        };
    }, [ name ]);

    const getValueForMultiCondition = initialValues => {
        const isChange = Boolean(Number.isInteger(indexFilter));

        return {
            ...initialValues,
            inxCurrentFilter: indexFilter,
            isChange,
            nameButton: isChange ? 'Save filter' : 'Add filter',
        };
    };

    const getInitialValues = () => {
        const initialValues = currentFilter.initialValues && currentFilter.initialFilters
            ? currentFilter.initialValues
            : currentFilter.initialFilters;

        const value = initialValues
            ? getValueForMultiCondition(initialValues)
            : initialValues;

        return value ? JSON.parse(JSON.stringify(value)) : value;
    };

    const getPropsIncludeExcludeFilter = (name, type) => {
        const oppositeName = getOppositeName[type][name];
        const notInclude = filters.find(filter => filter.id === oppositeName).initialValues;

        return notInclude ? notInclude.values : [];
    };

    const getModalBody = () => {
        const initialValues = name ? getInitialValues() : {};

        switch (name) {
            case 'classification':
                return <ClassificationForm
                    show={ show }
                    close={ close }
                    onSubmit={ onSubmit }
                    name={ name }
                    filter={ currentFilter }
                    initialValues={ initialValues }
                    getClassification={ getClassification }
                    classification={ classification }
                />;

            case 'date':
                return <DateForm
                    close={ close }
                    onSubmit={ onSubmit }
                    name={ name }
                    filter={ currentFilter }
                    initialValues={ initialValues }
                    attributes={ attributes.filter(attr =>
                        attr.originType === 'DATE'
                        || attr.originType === "service_captured_at",
                    ) }
                />;

            case 'lemmaToInclude':
            case 'lemmaToExclude':
                return <LemmataFilterForm
                    close={ close }
                    onSubmit={ onSubmit }
                    name={ name }
                    notInclude={ getPropsIncludeExcludeFilter(name, 'lemma') }
                    initialValues={ initialValues }
                    filter={ currentFilter }
                />;

            case 'textToInclude':
            case 'textToExclude':
                return <TextFilterForm
                    close={ close }
                    onSubmit={ onSubmit }
                    name={ name }
                    notInclude={ getPropsIncludeExcludeFilter(name, 'text') }
                    initialValues={ initialValues }
                    filter={ currentFilter }
                />;

            case 'attributes':
                return <AttributesForm
                    close={ close }
                    onSubmit={ onSubmit }
                    name={ name }
                    initialValues={ initialValues }
                    queryAttributes={ attributes }
                />;

            case 'advanced':
                return <AdvancedForm
                    close={ close }
                    onSubmit={ onSubmit }
                    name={ name }
                    initialValues={ initialValues }
                    source={ source }
                />;

            default:
                return null;
        }
    };

    const cleanupModal = () => close();

    const getHeader = () => currentFilter.labelForm;

    return (
        <VocModal
            modalClassName={ `query-filters-modal__${name}` }
            isOpen={ show }
            toggle={ close }
            header={ getHeader() }
            onClosed={ cleanupModal }
            wrapperBody={ false }
            size="ms"
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};

