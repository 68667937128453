import { createSelector } from 'reselect';

import { SettingsService } from '/feedback/services/SettingsService';
export * from './language.selectors';

export const _getDigiThemeStore = state => state.VocFeedback.themeSettings;
export const getThemeState = state => _getDigiThemeStore(state).themeSettings;
export const getThemeId = state => _getDigiThemeStore(state).themeId;
export const getThemeName = state => _getDigiThemeStore(state).themeName;
const getThemeDescription = state => _getDigiThemeStore(state).themeDescription;
const getThemeTags = state => _getDigiThemeStore(state).themeTags;
const getAllTagsSelector = state => _getDigiThemeStore(state).allTags;

const getDigiThemeSettings = createSelector([ getThemeState ], ({ settings }) => settings);

export const initialFormValuesSelector = createSelector([ getDigiThemeSettings ], settings => {
    const commonSettings = SettingsService.composeGlobalSettings(settings);
    const fields = SettingsService.getFieldsByThemeSettings();

    return {
        fieldsMap: fields.map(field => ({
            ...field,
            children: field.children.map(child => ({
                ...child,
                redefined: true,
                isGlobal: true,
            })),
        })),
        initialValues: commonSettings,
    };
});

export const themeFormSettingsSelector = createSelector(
    [
        initialFormValuesSelector,
        getThemeState,
        getThemeId,
        getThemeName,
        getThemeDescription,
        getThemeTags,
        _getDigiThemeStore,
    ],
    (
        fields,
        { loading },
        themeId,
        themeName,
        themeDescription,
        themeTags,
        { sendingTheme },
    ) => {
        return {
            themeId,
            themeName,
            themeDescription,
            themeTags,
            loading,
            sendingTheme,
            ...fields,
        };
    });

export const allTagsSelector = createSelector(
    [ getAllTagsSelector ],
    ({ data, loading }) => {
        return {
            options: data.map(tag => ({
                label: tag.name,
                value: tag.name,
            })),
            loading,
        };
    });
