import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, Input } from 'reactstrap';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

import { Preloader } from '/components';
import { FileManagerItem } from './components';

import './FileManager.scss';

const mapDispatchToProps = { showConfirm };

@connect(() => null, mapDispatchToProps)
export class FileManager extends Component {
    state = {
        search: '',
        page: '',
    };
    fileInput = React.createRef();

    @autobind
    onClosed() {
        this.setState({
            search: '',
        });
    }

    @autobind
    resetPage() {
        this.setState({
            page: '',
        });

        this.props.getAssets();
    }

    @autobind
    uploadFile() {
        this.fileInput.current.click();

        // This fixes it - HTML input file selection event not firing upon selecting the same file
        this.fileInput.current.value = null;
    }

    @autobind
    onFilePicked(event) {
        const isFileExist = this.props.assets.files.some(file => file.name === event.target.files[0].name );

        this.props.showConfirm({
            checkDirty: true,
            dirty: isFileExist,
            header: 'Warning!',
            content: 'File already exists! Do you want replace by new?',
            successCallback: () => this.props.uploadAsset({ file: event.target.files[0] }),
        });
    }

    @autobind
    onItemClick({ itemType, name, url }) {
        const {
            fillEditorValue,
            getAssets,
            toggle,
        } = this.props;

        if (itemType === 'folder') {
            getAssets(`${ name }/`);

            this.setState({
                page: name,
            });
        } else {
            fillEditorValue(url);
            toggle();
        }
    }

    @autobind
    filterAssets(event) {
        const { value } = event.target;

        if (this.state.search !== value) {
            this.setState({
                search: value,
            });
        }
    }

    @autobind
    getAssets() {
        return this.props.assets.files.filter(file => file.name.search(this.state.search) >= 0);
    }

    @autobind
    renderModalHeader() {
        return (
            <>
                <strong>File manager</strong>
                <div className="file-manager__nav">
                    <Row>
                        <Col lg={ 2 }>
                            <Button color={ 'primary' } onClick={ this.uploadFile }>Upload</Button>
                            <input type="file"
                                id="fileManagerInput"
                                ref={ this.fileInput }
                                onChange={ this.onFilePicked }
                                className={ 'file-manager__input' }
                            />
                        </Col>
                        <Col lg={{ size: 5, offset: 0 }} className={ 'breadcrumbs' }>
                            <ul className={ 'breadcrumbs__list' }>
                                <li className={ 'breadcrumbs__list-item' }
                                    onClick={ this.resetPage }>All files
                                </li>
                                {
                                    this.state.page
                                    && <li className={ 'breadcrumbs__list-item' }>{ this.state.page }</li>
                                }
                                <li className={ 'breadcrumbs__list-item' }>Images</li>
                            </ul>
                        </Col>
                        <Col lg={{ size: 4, offset: 1 }}>
                            <Input
                                type={ 'text' }
                                placeholder={ 'Filter by filename...' }
                                onChange={ this.filterAssets }/>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    @autobind
    renderModalBody() {
        return (
            this.props.assets.loading
                ? <Preloader/>
                : <div className="file-manager__content">
                    <ul className={ 'file-manager__list' }>
                        {
                            this.getAssets().map((item, index) =>
                                <FileManagerItem
                                    onClick={ this.onItemClick }
                                    { ...item }
                                    key={ index }
                                />,
                            )
                        }
                    </ul>
                </div>
        );
    }

    @autobind
    renderModalFooter() {
        return (
            !this.props.assets.loading
            && <p className="file-manager__total text-center">
                Found { this.getAssets().length } items.
            </p>
        );
    }

    render() {
        const {
            isOpen,
            toggle,
        } = this.props;

        return (
            <Modal
                isOpen={ isOpen }
                onClosed={ this.onClosed }
                toggle={ toggle }
                className={ 'editor__file-manager modal-scrollable voc-dialog' }
                size={ 'lg' }
            >
                <ModalHeader toggle={ toggle }>
                    { this.renderModalHeader() }
                </ModalHeader>
                <ModalBody>
                    { this.renderModalBody() }
                </ModalBody>
                <ModalFooter>
                    { this.renderModalFooter() }
                </ModalFooter>
            </Modal>
        );
    }
}

FileManager.defaultProps = {
    assets: {
        files: [],
    },
};

FileManager.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    assets: PropTypes.shape({
        loading: PropTypes.bool,
        files: PropTypes.array,
    }),
    getAssets: PropTypes.func,
    uploadAsset: PropTypes.func,
    fillEditorValue: PropTypes.func,
};
