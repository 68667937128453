import { createSelector } from 'reselect';
import { UsersRoleService } from '/services';
import { isSupportUserSelector } from "/modules/selectors";

export const virtualSourcesSelector = state => state.VocStore.virtualSources;
export const virtualSourcesCopyLoadingSelector = state => state.VocStore.virtualSources.copyLoading;
export const virtualSourcesInfoSelector = state => state.VocStore.virtualSources.virtualInfo;

export const virtualSourcesTableDataSelector = createSelector(
    [ virtualSourcesSelector, isSupportUserSelector ],
    (sources, isSupportUser) => {
        return sources.list.data.map(source => {
            const rights = UsersRoleService.getRightsForVirtual(isSupportUser, source.currentUserPermission);

            return {
                name: source.name,
                created: source.created,
                updated: source.updated,
                sources: source.sources.length
                    ? source.sources.reduce((acc, item) => {
                        !acc ? acc += item.title : acc += `, ${ item.title }`;
                        return acc;
                    }, '')
                    : 'None',
                shared: {
                    id: source.id,
                    isPermitViewInfoShare: rights.isPermitViewInfoShare,
                },
                actions: {
                    id: source.id,
                    name: source.name,
                    attrByDate: source.columns.filter(attribute => attribute.type === 'DATE'),
                    rights,
                    description: source.description,
                },
                ciSyncRequired: {
                    ciSync: source.ciSync,
                    ciSyncRequired: source.ciSyncRequired,
                },
            };
        });
    });

export const virtualToDeleteSelector = createSelector([ virtualSourcesSelector ], sources => {
        const { gadgets, segmentators, virtualSources, queries } = sources.virtualToDelete;

        return {
            ...sources.virtualToDelete,
            needToShowDetails: ![
                gadgets.length,
                virtualSources.length,
                segmentators.length,
                queries.length,
            ].includes(0),
        };
    });

export const virtualSourcesTableStateSelector = createSelector([ virtualSourcesSelector ], sources => {
        return {
            dataTotalSize: sources.list.count,
            page: sources.filters.page,
            limit: sources.filters.limit,
            loading: sources.loading,
            descriptionSending: sources.descriptionSending,
        };
    });

export const virtualSourcesFiltersSelector = createSelector([ virtualSourcesSelector ], sources => {
        return sources.filters;
    });
