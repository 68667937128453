import React, { Fragment, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { VocSelect } from '/components';
import { InputLoader } from '/feedback/components/InputLoader';

export const ExportForTraining = ({
    getClassifierList,
    setFormData,
    classifiers: {
        data,
        loading,
    },
}) => {
    const [ category, setCategory ] = useState([]);
    const [ classifier, setClassifier ] = useState({});

    const onChangeClassifier = option => {
        setClassifier(option);
        setCategory([]);
    };

    const onChangeCategory = option => {
        setCategory(option);
        setFormData({
            classifierId: classifier.value,
            isNew: option.__isNew__,
            category: option.value,
        });
    };

    useEffect(() => {
        getClassifierList();
        return () => {
            setClassifier({});
        };
    }, []);

    return (
        <Fragment>
            <Label> Classifier </Label>
            {
                loading
                    ? <InputLoader/>
                    : <VocSelect
                        options={ data }
                        placeholder={ 'Select classifier' }
                        onChange={ onChangeClassifier }
                        portal
                    />
            }

            <VocSelect
                label={ 'Category' }
                value={ category }
                options={ classifier.category }
                placeholder={ 'Select category' }
                onChange={ onChangeCategory }
                disabled={ loading || !classifier.value }
                isCreatable
                portal
            />
        </Fragment>
    );
};
