import { notificationsSelector } from './selectors';

export const CREATE_NOTIFICATION_ITEM = 'CREATE_NOTIFICATION_ITEM';
export const UPDATE_NOTIFICATION_ITEM = 'UPDATE_NOTIFICATION_ITEM';
export const REMOVE_NOTIFICATION_ITEM = 'REMOVE_NOTIFICATION_ITEM';
export const REMOVE_NOTIFICATION_REQUEST = 'REMOVE_NOTIFICATION_REQUEST';
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';

export function updateNotificationItemAction(data) {
    return {
        type: UPDATE_NOTIFICATION_ITEM,
        data,
    };
}

export function createNotification(data) {
    return {
        type: CREATE_NOTIFICATION_ITEM,
        data,
    };
}

export function updateNotificationItem(notification) {
    return (dispatch, getState) => {
        const state = getState();
        const notificationList = notificationsSelector(state);
        const isItemExists = notificationList.find(item => item.id === notification.id);

        if(isItemExists) {
            dispatch(updateNotificationItemAction(notification));
        } else {
            dispatch(createNotification(notification));
        }
    };
}

export function removeNotification(data) {
    return {
        type: REMOVE_NOTIFICATION_ITEM,
        data,
    };
}
