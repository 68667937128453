import React from "react";
import { Link } from "react-router-dom";

import { TooltipWrapper } from "/components";

import styles from './style.module.scss';

export const NameFormatter = ({
    name,
    id,
    description,
    onClickDescription,
    theme,
}) => (
    <div className={ styles.nameFormatterWrapper }>
        <Link to={ `/feedback/theme/${ id }/theme-settings` } className={ styles.name }>
            { name }
        </Link>
        <TooltipWrapper value={ description }>
            <span className={ styles.description } onClick={ () => onClickDescription(theme) }>
                { description }
            </span>
        </TooltipWrapper>
    </div>
);
