import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Label } from 'reactstrap';
import { Field } from 'redux-form';
import cx from 'classnames';

import { VocFormSelectField, VocFormTextField, VocFormErrorField, VocFormCheckbox } from '/components';
import { HelperService } from '/services';

const MAX_NUMBER_OF_CHOICES_DISPLAY_TEXT = 'Max number of choices (0 means unlimited)';
const MAX_NUMBER_OF_CHOICES_LABEL = 'Max number of choices';
const MAX_NUMBER_OF_CHOICES_PLACEHOLDER = '0 means unlimited';
const LAST_IS_OPEN_DISPLAY_TEXT = 'Last is open';
const LAST_IS_OPEN_LABEL = 'Last question';

export const ConfigurableValuesSection = ({
    loading,
    options,
    language,
    questionType,
    viewConfig,
    change,
    configurableFields,
}) => {
    const visibleConfigOption = options
        .filter(option => viewConfig[option.name]
            ? viewConfig[option.name].show
            : true,
        );

    const getFormField = option => {
        let component;

        const config = {
            required: option.required,
            name: `configurable.${ option.name }`,
            id: `configurable.${ option.name }`,
            withError: false,
            disabled: viewConfig[option.name] ? viewConfig[option.name].disabled : false,
        };

        const renameOptionsValues = option => {
            switch (option.value) {
                case 'no':
                    return 'Standard';
                case 'single line':
                    return 'Open single line';
                case 'multiline':
                    return 'Open multi line';

                default:
                    return option.label;
            }
        };

        const optionsValues = option?.values
            ? option.values.map(optionData => ({
                ...optionData,
                label: renameOptionsValues(optionData),
            }))
            : [];

        switch (option.field_type) {
            case 'Select':
                component = VocFormSelectField;
                config.options = optionsValues;
                config.normalize = HelperService.normalizeSelectValue;
                config.className = 'no-margin';
                config.format = value => HelperService.getSelectedValue(optionsValues, value);
                if(option.name === 'render_type') {
                    config.onChange = el => {
                        if(el.value === 'buttons' && configurableFields?.is_multichoice !== '0') {
                            change(`configurable.is_multichoice`, '0');
                            change(`configurable.min`, `${configurableFields?.required ? '1': '0'}`);
                            change(`configurable.max`, '1');
                        }
                    };
                }
                break;
            case 'StringLang':
                component = VocFormTextField;
                config.normalize = HelperService.normalizeTextValue;
                config.name = `language_values.${ language }.configurable.${ option.name }`;
                break;
            case 'Checkbox':
                if(option.name === 'is_multichoice') {
                    config.disabled = configurableFields?.render_type === 'buttons';
                }
                component = VocFormCheckbox;
                config.normalize = value => value ? '1' : '0';
                config.format = value => value === '1';
                config.type = 'checkbox';
                break;
            case 'Number':
                if(option.name === 'max') {
                    config.disabled = configurableFields?.render_type === 'buttons';
                }
                component = VocFormTextField;
                config.type = 'number';
                config.onWheel = event => event.target.blur();
                // Dirty hack for setting placeholder without backend
                config.placeholder = option.display_text === MAX_NUMBER_OF_CHOICES_DISPLAY_TEXT
                    ? MAX_NUMBER_OF_CHOICES_PLACEHOLDER
                    : '';
                break;

            default:
                component = VocFormTextField;
                config.normalize = HelperService.normalizeTextValue;
        }

        return (
            <Field component={ component } { ...config }/>
        );
    };

    const renderLabel = ({ display_text }) => {
        switch (display_text) {
            case MAX_NUMBER_OF_CHOICES_DISPLAY_TEXT:
                return MAX_NUMBER_OF_CHOICES_LABEL;
            case LAST_IS_OPEN_DISPLAY_TEXT:
                return LAST_IS_OPEN_LABEL;

            default:
                return display_text;
        }
    };

    const isShowLanguage = () =>
        [ 'QuestionOpen', 'QuestionScalable' ].includes(questionType);

    return (
        <Card className='question__section'>
            <CardHeader>
                <Row>
                    <Col lg={ 10 }>
                        <span>Configurable values</span>
                    </Col>
                    {
                        isShowLanguage()
                        && <Col lg={ 2 }>
                            <div className='language__header-value'>
                                { language }
                            </div>
                        </Col>
                    }
                </Row>
            </CardHeader>
            <CardBody className={ cx('question__section-body', { 'question__section--loading': loading }) }>
                <FormGroup>
                    <Row>
                        {
                            visibleConfigOption.map((option, index) => (
                                <Col md={ 6 } key={ index }>
                                    <FormGroup>
                                        <Row>
                                            <Col md={ 12 } className='general-section__field_container'>
                                                <FormGroup>
                                                    <Label
                                                        className='general-section__label'
                                                        htmlFor={ `configurable.${ option.name }` }
                                                    >
                                                        { renderLabel(option) }
                                                        {
                                                            option.required
                                                            && <span className='star'> *</span>
                                                        }
                                                    </Label>
                                                    { getFormField(option) }
                                                </FormGroup>
                                            </Col>
                                            <Col md={ 12 }>
                                                <Field
                                                    name={ `configurable.${ option.name }` }
                                                    className='question-form__validation-error'
                                                    component={ VocFormErrorField }
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            ))
                        }
                    </Row>
                </FormGroup>
            </CardBody>
        </Card>
    );
};
