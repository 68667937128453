import React from 'react';
import { SortableItem } from './SortableItem';
import { SortableContainer } from 'react-sortable-hoc';
import { ListGroup } from 'reactstrap';

export const SortableList = SortableContainer(props => (
    <ListGroup className={ 'flow__list' }>
        {
            props.flows.map((flow, index) => (
                <SortableItem
                    key={ `item-${ index }` }
                    index={ index }
                    flow={ flow }
                    { ...props }
                />
            ))
        }
    </ListGroup>
));
