import React, { Fragment } from 'react';
import { TagItems } from '/components';
import { TextGroupControl } from './components';
import { FormText } from 'reactstrap';
import './VocFormTextGroupField.scss';

export const VocFormTextGroupField = ({
    input: {
        onChange,
        value,
        name,
    },
    withError,
    notInclude,
    meta: {
        error,
        touched,
    },
    label,
    trim,
    toLowerCase,
}) => {
    const addText = inpValue => {
        const text = trim ? inpValue.trim() : inpValue;
        const model = [ ...value ];

        model.push(text);
        onChange(model);
    };

    const _onChange = arr => {
        onChange(arr);
    };

    return (
        <Fragment>
            <label className={ 'text-group-field__input-label' } htmlFor={ name }>{ label }</label>
            <div className={ 'text-group-field__container' }>
                <TextGroupControl
                    addText={ addText }
                    toLowerCase={ toLowerCase }
                    value={ value }
                    notInclude={ notInclude }
                />
                {
                    value.length
                        ? <div className={ 'text-group-field__value-container' }>
                            <TagItems
                                values={ value }
                                onChange={ _onChange }
                            />
                        </div> : null
                }
            </div>

            {
                Boolean(touched && error && withError)
                    ? <FormText className="app-form-control__error">{ error.message }</FormText>
                    : false
            }
        </Fragment>
    );
};

VocFormTextGroupField.defaultProps = {
    trim: true,
    notInclude: [],
    toLowerCase: false,
};
