import React from 'react';
import { Row, Col } from 'reactstrap';
import { Field } from 'redux-form';
import { VocFormTextField, VocFormSelectField, VocFormErrorField } from '/components';
import { HelperService } from '/services';
import DeleteIcon from '/images/ic_delete.png';

export const DynamicGroupFieldView = ({ fields, sourceType, source, change, error, disabled }) => (
    <>
        {
            fields.length
                ? <Row className={ 'row-label__dynamic-group' }>
                    <Col md={ 5 }>VocACT variable name <span className={ 'label-star' }>*</span></Col>
                    <Col md={ 3 }>Type <span className={ 'label-star' }>*</span></Col>
                    <Col md={ 3 }>Metadata name / Classifier <span className={ 'label-star' }>*</span></Col>
                </Row> : ''
        }
        {
            fields.map((field, index) => {
                const fieldValue = fields.get(index);
                const valueSourceTypeSystem = 1;
                const valueSourceTypeClassifier = 3;
                const propsForSource = {};
                const errors = error ? error[index] : {};

                if ([ valueSourceTypeSystem, valueSourceTypeClassifier ].includes(fieldValue.source_type)) {
                    const options = fieldValue.source_type === valueSourceTypeSystem
                        ? source.options
                        : source.classifierOptions;

                    propsForSource['options'] = options;
                    propsForSource['component'] = VocFormSelectField;
                    propsForSource['normalize'] = HelperService.normalizeSelectValue;
                    propsForSource['placeholder'] = 'Select';
                    propsForSource['format'] = value => HelperService.getSelectedValue(options, value);
                } else {
                    propsForSource['component'] = VocFormTextField;
                }

                return (
                    <Row key={ index }>
                        <Col md={ 5 }>
                            <Field
                                name={ `${ field }.field_name` }
                                type={ 'text' }
                                placeholder={ 'Field Name' }
                                component={ VocFormTextField }
                                withError={ false }
                                disabled={ disabled }
                            />
                            {
                                errors.field_name && <VocFormErrorField
                                    className={ 'app-form-control__error form-text text-muted' }
                                    meta={{
                                        touched: true,
                                        error: errors.field_name,
                                    }}
                                />
                            }
                        </Col>
                        <Col md={ 3 }>
                            <Field
                                name={ `${ field }.source_type` }
                                options={ sourceType.options }
                                normalize={ HelperService.normalizeSelectValue }
                                format={ value => HelperService.getSelectedValue(sourceType.options, value) }
                                placeholder={ 'Select' }
                                component={ VocFormSelectField }
                                onChange={ () => change(`${ field }.source`, null) }
                                withError={ false }
                                disabled={ disabled }
                            />
                            {
                                errors.source_type && <VocFormErrorField
                                    className={ 'app-form-control__error form-text text-muted' }
                                    meta={{
                                        touched: true,
                                        error: errors.source_type,
                                    }}
                                />
                            }
                        </Col>
                        <Col md={ 3 }>
                            <Field
                                name={ `${ field }.source` }
                                placeholder={ 'Metadata name' }
                                withError={ false }
                                disabled={ disabled }
                                { ...propsForSource }
                            />
                            {
                                errors.source && <VocFormErrorField
                                    className={ 'app-form-control__error form-text text-muted' }
                                    meta={{
                                        touched: true,
                                        error: errors.source,
                                    }}
                                />
                            }
                        </Col>
                        <Col md={ 1 }>
                            <button
                                className={ 'setting-control-group_button' }
                                onClick={ () => fields.remove(index) }
                                disabled={ disabled }
                            >
                                <img src={ DeleteIcon } alt=""/>
                            </button>
                        </Col>
                    </Row>
                );
            })
        }
    </>
);
