import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import cx from 'classnames';

import { TooltipWrapper } from '/components';

import './NotificationItem.scss';

export const NotificationItem = ({ name, url, urls, icon, value, removeNotification, cancelUrl }) => {
    const [ isOpen, setIsOpen ] = useState(true);

    const cancelHandler = () => removeNotification(cancelUrl);

    const toggle = () => setIsOpen(!isOpen);

    const getDownloadItem = ({ hasChild, url, name, value, cancelHandler }) => {
        return (
            <p className={ cx('notification-item__messagePartContainer', { parent: hasChild }) }>
                {
                    icon && !hasChild
                    && <span className={ `icon ${ icon }` } />
                }
                <TooltipWrapper value={ name }>
                    {
                        url
                            ? <a
                                className='notification__text'
                                href={ url }
                                download={ icon === 'download' }
                            >
                                { name }
                            </a>
                            : <span
                                className='notification__text'
                                onClick={ hasChild && toggle }
                            >
                                { name }
                            </span>
                    }
                </TooltipWrapper>

                {
                    (value || value === 0)
                    && <strong className='notification-item__value'>{ value }</strong>
                }

                {
                    cancelHandler
                    && <button
                        type='button'
                        className='close'
                        aria-label='Close'
                        onClick={ cancelHandler }
                    >
                        &times;
                    </button>
                }
            </p>
        );
    };

    return (
        <div className='notification-item__container'>
            {
                getDownloadItem({
                    url,
                    name,
                    value,
                    hasChild: urls?.length > 0 ,
                    cancelHandler: cancelUrl && cancelHandler,
                })
            }
            {
                urls?.length
                && <Collapse isOpen={ isOpen }>
                    <div className='notification-item__severalItemsContainer'>
                        {
                            urls.map(({ url: childUrl, name: childName }) =>
                                <Fragment key={ childUrl }>
                                    {
                                        getDownloadItem({
                                            url: childUrl,
                                            name: childName,
                                        })
                                    }
                                </Fragment>,
                            )
                        }
                    </div>
                </Collapse>
            }
        </div>
    );
};

NotificationItem.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    urls: PropTypes
        .arrayOf(PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        })),
    cancelUrl: PropTypes.string,
    value: PropTypes.any,
    removeNotification: PropTypes.func,
};
