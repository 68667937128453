import { useDispatch, useSelector } from 'react-redux';
import { reduceGadgetsSelector } from "/visual/scenes/Dashboard/modules/Dashboard.selectors";
import { moveToSave, changeGadgetsPosition, dropGadget, cutGadget } from "/visual/scenes/Dashboard/modules/Dashboard.modules";
import { TDataDrop, TDataCut } from '/visual/scenes/Dashboard/models';

export const useLocalState = ( ) => {
    const dispatch = useDispatch();
    const {
        gadgets,
        wildGadgets,
        pages,
        gadgetPending,
    } = useSelector(reduceGadgetsSelector);

    const onChangeGadgetsPosition = (
        data: TDataDrop | TDataCut,
        type: string,
    ) => {
        if(type === 'drop') return dispatch(dropGadget(data as TDataDrop));
        if(type === 'cut') return dispatch(cutGadget(data as TDataCut));

        return dispatch(changeGadgetsPosition(data as TDataDrop));
    };

    const onMoveToSave = ({ id, page }: { id: string, page: string }) => {
        return dispatch(moveToSave(id, page));
    };

    return { gadgets, wildGadgets, pages, gadgetPending, onChangeGadgetsPosition, onMoveToSave };
};
