import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Preloader } from '/components';
import { IPlaceholder } from './models';

import styles from './style.module.scss';

export const Placeholder = ({
    loading,
    withGadget,
    coords,
    copyHandler,
    setNewStaticAreaGadgetId,
    createHandler,
    setLoading,
    isCopy,
    isCut,
}: IPlaceholder) => {
    const { t } = useTranslation();
    const placeholderRef = useRef<HTMLDivElement | null>(null);

    const handleCreate = (type: string) => {
        setLoading(true);

        createHandler(coords, type)
            .then(({ id }: { id: string }) => {
                if (type === 'static_area') setNewStaticAreaGadgetId(id);

                setLoading(false);
            });
    };

    const handlePaste = () => {
        if (isCopy || isCut) {
            setLoading(true);

            const request = isCopy
                ? copyHandler(coords)
                : createHandler(coords);

            request.then(() => setLoading(false));
        }
    };

    const getPlaceholderContent = () => (
        isCopy || isCut
            ? <FontAwesomeIcon icon='paste' />
            : <>
                {
                    withGadget
                    && <p
                        className={ styles.textWithSvg }
                        onClick={ () => handleCreate('super_chart') }
                    >
                        <span>{ t('createGadget') }</span>
                        <FontAwesomeIcon icon={ [ 'far', 'plus-square' ] } />
                    </p>
                }

                <p
                    className={ cx({
                        [styles.textWithoutSvg]: !withGadget,
                        [styles.textWithSvg]: withGadget,
                    }) }
                    onClick={ () => handleCreate('static_area') }
                >
                    <span>{ t('createMediaBlock') }</span>
                    <FontAwesomeIcon icon={ [ 'far', 'plus-square' ] } />
                </p>
            </>
    );

    return (
        <div
            className={ cx(styles.pasteWrapper, {
                [styles.disabled]: loading,
                [styles.isCopy]: isCopy || isCut,
            }) }
            onClick={ handlePaste }
            ref={ placeholderRef }
        >
            {
                loading
                    ? <Preloader color='#e1e3e7' autoSizeByContainerRef={ placeholderRef }/>
                    : getPlaceholderContent()
            }
        </div>
    );
};
