import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { IPreviewMode } from './models';

const GET_PREVIEW_REQUEST = 'GET_PREVIEW_REQUEST';
const GET_PREVIEW_SUCCESS = 'GET_PREVIEW_SUCCESS';
const GET_PREVIEW_ERROR = 'GET_PREVIEW_ERROR';

const GET_PREVIEW_RUNNER_URL_REQUEST = 'GET_PREVIEW_RUNNER_URL_REQUEST';
const GET_PREVIEW_RUNNER_URL_SUCCESS = 'GET_PREVIEW_RUNNER_URL_SUCCESS';
const GET_PREVIEW_RUNNER_URL_ERROR = 'GET_PREVIEW_RUNNER_URL_ERROR';

const SAVE_PREVIEW_REQUEST = 'SAVE_PREVIEW_REQUEST';
const SAVE_PREVIEW_SUCCESS = 'SAVE_PREVIEW_SUCCESS';
const SAVE_PREVIEW_ERROR = 'SAVE_PREVIEW_ERROR';

const CHANGE_EDIT_DATA = 'CHANGE_EDIT_DATA';

const CLEANUP_PREVIEW = 'CLEANUP_PREVIEW';

const surveyGroupsPath = '/feedback/survey-list';

const getInitialState = (): IPreviewMode => ({
    loading: true,
    saveLoading: false,
    name: null,
    languages: [],
    themes: [],
    selectedThemeId: null,
    unitId: null,
    digiId: null,
    runnerUrl: null,
    editData: {
        selectedThemeId: null,
        selectedLanguage: null,
    },
});

export function reducer(state: IPreviewMode = getInitialState(), action: any = {}) {
    switch (action.type) {
        case GET_PREVIEW_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case SAVE_PREVIEW_REQUEST: {
            return {
                ...state,
                saveLoading: true,
            };
        }

        case GET_PREVIEW_RUNNER_URL_SUCCESS: {
            return {
                ...state,
                runnerUrl: action.response.DIGI_RUNNER_URL,
            };
        }

        case SAVE_PREVIEW_SUCCESS: {
            return {
                ...state,
                saveLoading: false,
                selectedThemeId: action.response.digi_theme,
            };
        }

        case SAVE_PREVIEW_ERROR: {
            return {
                ...state,
                saveLoading: false,
            };
        }

        case GET_PREVIEW_SUCCESS: {
            const { survey_group_name, survey_id, survey_theme, parent_theme, themes, unit_id, languages } = action?.response;

            return {
                ...state,
                loading: false,
                name: survey_group_name,
                digiId: survey_id,
                selectedThemeId: parent_theme,
                previewThemeId: survey_theme,
                themes,
                languages,
                unitId: unit_id,
            };
        }

        case GET_PREVIEW_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case CHANGE_EDIT_DATA: {
            return {
                ...state,
                editData: {
                    ...state.editData,
                    ...action.payload,
                },
            };
        }

        case CLEANUP_PREVIEW: {
            return getInitialState();
        }

        default: {
            return state;
        }
    }
}

export function getPreviewData(surveyGroupId: string | number) {
    return {
        [CALL_API]: {
            endpoint: `/digi-preview/${surveyGroupId}/`,
            method: 'GET',
            types: [ GET_PREVIEW_REQUEST, GET_PREVIEW_SUCCESS, GET_PREVIEW_ERROR ],
            logger: true,
            apiType: FEEDBACK,
            redirect: surveyGroupsPath,
        },
    };
}

export function savePreviewTheme(body: any) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/attach-theme/`,
            method: 'PATCH',
            types: [ SAVE_PREVIEW_REQUEST, SAVE_PREVIEW_SUCCESS, SAVE_PREVIEW_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Theme was successfully applied',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function changeEditData(data: any) {
    return {
        type: CHANGE_EDIT_DATA,
        payload: data,
    };
}

export function getRunnerUrl(unitId: number) {
    return {
        [CALL_API]: {
            endpoint: `/unit/${unitId}/get-channel-settings/DIGI/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_PREVIEW_RUNNER_URL_REQUEST,
                GET_PREVIEW_RUNNER_URL_SUCCESS,
                GET_PREVIEW_RUNNER_URL_ERROR,
            ],
            apiType: FEEDBACK,
        },
    };
}

export function cleanUpPreview() {
    return {
        type: CLEANUP_PREVIEW,
    };
}
