import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

import './CustomersTableActionsFormatter.scss';

export const CustomersTableActionsFormatter = ({
    openModalDelete,
    openEditModal,
    isPermitEdit,
    external_id,
    editModal,
    creation,
    name,
    id,
}) => {
    const handleDelete = () => isPermitEdit && openModalDelete(id);
    const handleEdit = () => isPermitEdit && openEditModal({ external_id, creation, ...editModal });

    return (
        <div
            id={ 'table-action__container' }
            className='table-action__container table-action__exporters-list'
        >
            <TooltipWrapper value={ 'Edit customer' } force>
                <span
                    className={ cx(
                        'table-action__exporters-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ handleEdit }
                >
                    <FontAwesomeIcon icon="pencil-alt"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ "Delete customer" } force>
                <span
                    className={ cx(
                        'table-action__exporters-list-icon',
                        { 'disabled': name === 'Default Exporter' || !isPermitEdit },
                    ) }
                    onClick={ handleDelete }
                >
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>
        </div>
    );
};
