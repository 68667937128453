import React from 'react';
import { TooltipWrapper } from '/components';
import PropTypes from 'prop-types';
import './SurveyChannel.scss';

export const SurveyChannel = ({ channel, state }) => {
    const classList = new Set([ 'survey-channel', state.toLowerCase() ]);

    return (
        <TooltipWrapper value={ state } force>
            <span className={ Array.from(classList).join(' ') }>
                { channel }
            </span>
        </TooltipWrapper>
    );
};

SurveyChannel.propTypes = {
    state: PropTypes.string,
    channel: PropTypes.string,
};
