import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    VocTableV2,
    TooltipWrapper,
    Icons,
    TableRightsColumn,
    VocRightsHOC,
    TableFilter,
    TableResetFilter,
} from '/components';
import { RightsTableActionFormatter } from '/mine/components';
import { QueriesTableDatePicker, QueriesTitleFormatter } from './components';

import './QueriesTable.scss';

export class QueriesTable extends Component {
    settings = [
        {
            field: 'title',
            title: 'Title',
            filter: this.titleFilter,
            formatter: this.titleFormatter,
            sort: true,
            style: {
                width: '200px',
            },
        },
        {
            field: 'tag',
            title: 'Tag',
            formatter: this.tagFormatter,
            sort: true,
            filter: this.tagFilter,
            style: {
                width: '150px',
            },
        },
        {
            field: 'created',
            title: 'Created',
            formatter: 'date',
            sort: true,
            filter: this.createdFilter,
            style: {
                width: '160px',
            },
        },
        {
            field: 'updated',
            title: 'Updated',
            formatter: 'date',
            sort: true,
            filter: this.updatedFilter,
            style: {
                width: '160px',
            },
        },
        {
            field: 'sourceId',
            title: 'Source',
            formatter: 'conditionTooltip',
            sort: true,
            filter: this.sourceFilter,
            style: {
                width: '110px',
            },
        },
        {
            field: 'language',
            title: 'Language',
            sort: true,
            style: {
                textAlign: 'center',
                width: '130px',
            },
        },
        {
            field: 'shared',
            title: 'Role',
            formatter: this.sharedFormatter,
            style: {
                textAlign: 'center',
                width: '70px',
            },
        },
        {
            field: 'actions',
            title: '',
            formatter: this.actionsFormatter,
            style: {
                width: '110px',
            },
        },
    ];

    @autobind
    titleFormatter({ title, id }) {
        return <QueriesTitleFormatter
            title={ title }
            id={ id }
        />;
    }

    @autobind
    tagFormatter(cell) {
        return cell ? (
            <TooltipWrapper value={ cell }>
                <span className={ 'table-tag__label' }>{ cell }</span>
            </TooltipWrapper>
        ) : '';
    }

    @autobind
    sharedFormatter(cell) {
        const { getSharedRights, shares } = this.props;

        return (
            <TableRightsColumn
                { ...cell }
                getShares={ id => getSharedRights(id, 'query') }
                shares={ shares }
            />
        );
    }

    @autobind
    actionsFormatter(cell) {
        const {
            openModalDelete,
            openInviteModal,
            openChangeNameModal,
            isSupportUser,
        } = this.props;

        return <RightsTableActionFormatter
            { ...cell }
            openDeleteModal={ openModalDelete }
            openInviteModal={ openInviteModal }
            openEditModal={ openChangeNameModal }
            isSupportUser={ isSupportUser }
        />;
    }

    @autobind
    titleFilter() {
        const { targetFilter, isOpenPopoverFilter, togglePopover, getQueryTitles, queryTitles, apply } = this.props;

        return (
            <TableFilter
                targetFilter={ targetFilter }
                isOpenPopover={ isOpenPopoverFilter }
                togglePopover={ togglePopover }
                filterData={ queryTitles }
                apply={ apply }
                getFilterData={ getQueryTitles }
                name={ 'title' }
            />
        );
    }

    @autobind
    tagFilter() {
        const { targetFilter, isOpenPopoverFilter, togglePopover, queryTags, getTags, apply } = this.props;

        return (
            <TableFilter
                targetFilter={ targetFilter }
                isOpenPopover={ isOpenPopoverFilter }
                togglePopover={ togglePopover }
                filterData={ queryTags }
                apply={ apply }
                getFilterData={ getTags }
                name={ 'tag' }
            />
        );
    }

    @autobind
    sourceFilter() {
        const {
            targetFilter,
            isOpenPopoverFilter,
            togglePopover,
            getQuerySources,
            querySources,
            apply,
            filterField,
        } = this.props;

        return (
            <TableFilter
                targetFilter={ targetFilter }
                isOpenPopover={ isOpenPopoverFilter }
                togglePopover={ togglePopover }
                filterData={ querySources }
                apply={ apply }
                getFilterData={ getQuerySources }
                name={ filterField }
            />
        );
    }

    @autobind
    createdFilter() {
        const { targetFilter, isOpenPopoverFilter, togglePopover, queryDates: { created }, apply } = this.props;

        return (
            <QueriesTableDatePicker
                targetFilter={ targetFilter }
                isOpenPopover={ isOpenPopoverFilter }
                togglePopover={ togglePopover }
                date={ created }
                apply={ apply }
                name={ 'created' }
            />
        );
    }

    @autobind
    updatedFilter() {
        const { targetFilter, isOpenPopoverFilter, togglePopover, queryDates: { updated }, apply } = this.props;

        return (
            <QueriesTableDatePicker
                targetFilter={ targetFilter }
                isOpenPopover={ isOpenPopoverFilter }
                togglePopover={ togglePopover }
                date={ updated }
                apply={ apply }
                name={ 'updated' }
            />
        );
    }

    @autobind
    onSearchType(search) {
        this.props.updateFilters({ search, page: 1 });
        this.props.requestTrigger({ ...this.props.filters, search, page: 1 });
    }

    getTableOptions() {
        return {
            haveSearch: true,
            requestTrigger: this.props.requestTrigger,
            updateFilters: this.props.updateFilters,
            customTableHeader: this.customTableHeader(),
            filterField: this.props.filterField,
            openPopoverFilter: this.props.openPopoverFilter,
        };
    }

    customTableHeader() {
        const { filters: { extraFilters }, resetFilters, url } = this.props;
        const baseUrl = url.split('/', 2).join('/');

        return [
            <div key={ 1 } className={ 'mine-table__btn-controls-wrapper' }>
                <Button className={ 'panel__header-button' }
                    outline
                    color="success"
                    onClick={ this.props.openModal } key={ 1 }>
                    <FontAwesomeIcon icon='plus' className='color-white'/>
                    <span> Add query </span>
                </Button>
                <NavLink
                    to={ `${ baseUrl }/dictionaries` }
                    className={ 'mine-table__btn-dictionary' }>
                    <Icons
                        icon='dictionaries'
                        className={ 'dictionaries-icon' }
                    />
                    <span>Dictionaries</span>
                </NavLink>
            </div>,
            <TableResetFilter
                key={ 2 }
                filters={ extraFilters }
                reset={ resetFilters }
            />,
        ];
    }

    render() {
        const {
            tableState,
            filters,
            tableData,
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                data={ tableData }
                options={ this.getTableOptions() }
                filters={ filters }
                total={ tableState.total }
                loading={ tableState.loading }
            />
        );
    }
}

export default VocRightsHOC({
    type: 'query',
})(QueriesTable);

QueriesTable.propTypes = {
    requestTrigger: PropTypes.func,
    updateFilters: PropTypes.func,
    tableData: PropTypes.array,
    filters: PropTypes.object,
    tableState: PropTypes.shape({
        page: PropTypes.number,
        loading: PropTypes.bool,
    }),
};
