import React from 'react';
import { RangeSlider } from '/components';
import { IMultiTopic } from './models';

import styles from './styles.module.scss';

export const MultiTopic = ({ formValues, onChange }: IMultiTopic) => {
    const { threshold, topicSet, maxCategories, minCategories } = formValues;

    return (
        <>
            <div className={ styles.rangeWrapper }>
                <label htmlFor='voc_ai_choose_classifier_column'>
                    Minimum Topic Count
                </label>
                <div className={ styles.rangeSliderWrapper }>
                    <span>{ 0 }</span>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <RangeSlider
                        tooltip
                        step={ 1 }
                        min={ 0 }
                        max={ topicSet.categoriesCount || 1 }
                        values={ [ minCategories || 0 ] }
                        colors={{
                            rail: '#ccc',
                            track: [ '#337ab7' ],
                            hand: [ '#337ab7', '#337ab7' ],
                        }}
                        handleStyles={ [{ height: 20, width: 20 }] }
                        onChange={ (data: number) => onChange('minCategories', data) }
                    />
                    <span>{ topicSet.categoriesCount }</span>
                </div>
            </div>
            <div className={ styles.rangeWrapper }>
                <label htmlFor='voc_ai_choose_classifier_column'>
                    Maximum Topic Count
                </label>
                <div className={ styles.rangeSliderWrapper }>
                    <span>{ 1 }</span>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <RangeSlider
                        tooltip
                        step={ 1 }
                        min={ 1 }
                        max={ topicSet.categoriesCount || 1 }
                        values={ [ maxCategories || 1 ] }
                        colors={{
                            rail: '#ccc',
                            track: [ '#337ab7' ],
                            hand: [ '#337ab7', '#337ab7' ],
                        }}
                        handleStyles={ [{ height: 20, width: 20 }] }
                        onChange={ (data: number) => onChange('maxCategories', data) }
                    />
                    <span>{ topicSet.categoriesCount }</span>
                </div>
            </div>
            <div className={ styles.rangeWrapper }>
                <label htmlFor='voc_ai_choose_classifier_column'>
                    Accuracy Threshold
                </label>
                <div className={ styles.rangeSliderWrapper }>
                    <span>{ 0 }</span>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <RangeSlider
                        tooltip
                        step={ 0.1 }
                        min={ 0.0 }
                        max={ 0.9 }
                        values={ [ threshold ] }
                        colors={{
                            rail: '#ccc',
                            track: [ '#337ab7' ],
                            hand: [ '#337ab7', '#337ab7' ],
                        }}
                        handleStyles={ [{ height: 20, width: 20 }] }
                        onChange={ (data: number) => onChange('threshold', data) }
                    />
                    <span>{ 1 }</span>
                </div>
            </div>
        </>
    );
};
