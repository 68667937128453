import React, { Component } from 'react';
import { Input, Label, FormGroup, Col, Row } from 'reactstrap';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';

import './FormDynamicInput.scss';

export class FormDynamicInput extends Component {
    @autobind
    changeHandler(e) {
        const { name, row, item, handleChange } = this.props;

        handleChange(e, name, { row, item });
    }

    render() {
        const {
            isHorizontal, type, disabled, value,
            label = '', placeholder = '', row, item,
        } = this.props;

        return (
            <FormGroup>
                <Row>
                    <Col md={ isHorizontal ? 3 : 12 }
                        className="text-field-control-label">
                        <Label>{ label }</Label>
                    </Col>
                    <Col md={ isHorizontal ? 9 : 12 }>
                        <Input
                            type={ type }
                            disabled={ disabled }
                            row={ row }
                            item={ item }
                            value={ value }
                            placeholder={ placeholder }
                            onChange={ this.changeHandler }
                        />
                    </Col>
                </Row>
            </FormGroup>
        );
    }
}

FormDynamicInput.propTypes = {
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isHorizontal: PropTypes.bool,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
};
