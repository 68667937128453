import React from 'react';
import { Badge } from 'reactstrap';
import { components } from 'react-select';

import { TooltipWrapper } from '/components';
import { getLabelClassByType } from '/modules/selectors';

import './SourcePaginateSelectValue.scss';

export const SourcePaginateSelectValue = ({ children, ...props }) => {
    const valueItem = props.getValue();
    let title, channel, state, type;

    if (valueItem.length) {
        ({ title, channel, type, state } = valueItem[0]);

        if(channel?.toLowerCase?.() === 'system') {
            type = null;
            state = 'info';
        }
    }

    return (
        <div className="Select-value source-select-value">
            {
                props.hasValue
                    ? <>
                        <div className="Select-value__title">
                            <TooltipWrapper value={ title }>
                                <div>
                                    <components.ValueContainer { ...props }>
                                        { children }
                                    </components.ValueContainer>
                                </div>
                            </TooltipWrapper>
                        </div>
                        {
                            (channel || type)
                            && <div className="Select-value__labels">
                                {
                                    channel
                                    && <Badge
                                        color={ getLabelClassByType(state?.toLowerCase?.()) }
                                        className={ state?.toLowerCase?.() }
                                    >
                                        { channel }
                                    </Badge>
                                }

                                {
                                    type && <Badge color={ getLabelClassByType(type?.toLowerCase?.()) }>{ type }</Badge>
                                }
                            </div>
                        }
                    </>
                    : <div className="Select-value__title">
                        <components.ValueContainer { ...props }>
                            { children }
                        </components.ValueContainer>
                    </div>
            }
        </div>
    );
};


