import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import { QuestionsServices } from '/scenes/VocFeedback/services';
import { TooltipWrapper } from '/components';
import { IQuestionTreeItemControls, TQuestionTreeControl } from './models';

import styles from './style.module.scss';

export const QuestionTreeItemControls = ({
    actionDisabled,
    activeQuestionId,
    showQuestionDetails,
    inheritDataFromId,
    isPermitEdit,
    node,
    copyQuestionHandler,
    showQuestionFlow,
    questionFlowHandler,
    removeQuestionHandler,
    openConfigurablePopover,
    setQuestionLayoutHandler,
}: IQuestionTreeItemControls) => {
    const questionId = node.id;
    const conIdElement = `icon-con-${ questionId }`;
    const listIdElement = `icon-list-${ questionId }`;
    const isThisQuestionUsedInFlows = activeQuestionId && QuestionsServices.isQuestionUsedInFlows(
        activeQuestionId,
        questionId,
        node.question_flows,
    );

    const actionList: TQuestionTreeControl[] = [
        {
            title: 'Copy',
            key: 'copy',
            disabled: !isPermitEdit,
            icon: 'copy',
            onClick: () => isPermitEdit && copyQuestionHandler(node),
        },
        {
            title: 'Flows',
            key: 'flows',
            className: cx({ warning: node.hasCorruptedFlow }),
            disabled: !node.hasFlow,
            icon: 'cog',
            targetPopover: conIdElement,
            iconColor: isThisQuestionUsedInFlows ? QuestionsServices.activeIconColor : '',
            onClick: () => showQuestionFlow(conIdElement, node),
        },
        {
            title: 'Add Flow',
            key: 'add-flow',
            disabled: !isPermitEdit,
            icon: 'plus',
            onClick: () => isPermitEdit && questionFlowHandler({ node }),
        },
        {
            title: 'Remove Question',
            key: 'remove-question',
            disabled: !isPermitEdit,
            icon: 'trash',
            onClick: () => isPermitEdit && removeQuestionHandler(node),
        },
        {
            title: 'Configurable values',
            key: 'config-values',
            disabled: !node.showConfigurablePopover,
            icon: 'list',
            targetPopover: listIdElement,
            iconClassName: 'table-action__glyph',
            onClick: () => openConfigurablePopover(listIdElement, node.id),
        },
    ];

    if (QuestionsServices.isMatrixQuestion(node.type)) {
        const isActive = inheritDataFromId === node.id && showQuestionDetails && !activeQuestionId;

        actionList.unshift({
            title: 'Add subquestion',
            key: 'add-subquestion',
            disabled: !isPermitEdit,
            icon: 'plus-square',
            iconColor: isActive
                ? QuestionsServices.activeIconColor
                : '',
            onClick: () => setQuestionLayoutHandler({
                activeQuestionId: null,
                inheritDataFromId: isActive ? null : node.id,
                showQuestionDetails: !isActive,
            }),
        });
    }

    return <>
        {
            actionList.map(action =>
                <TooltipWrapper
                    force
                    value={ action.title ?? '' }
                    boundariesElement='body'
                    offset='0, 4px'
                    key={ action.key }
                >
                    <div className={ cx('rst__toolbarButton', styles.actionItem) }>
                        <div
                            id={ action.targetPopover }
                            className={ cx('rst__actions', { disabled: actionDisabled }, action.className) }
                        >
                            <Button
                                color='link'
                                disabled={ action.disabled }
                                onClick={ action?.onClick }
                            >
                                <FontAwesomeIcon
                                    icon={ action.icon as IconName }
                                    color={ action.iconColor ?? '' }
                                    className={ action.className ?? '' }
                                />
                            </Button>
                        </div>
                    </div>
                </TooltipWrapper>,
            )
        }
    </>;
};
