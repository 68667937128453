import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';

export const GET_SEGMENTATORS_LIST_REQUEST = 'GET_SEGMENTATORS_LIST_REQUEST';
export const GET_SEGMENTATORS_LIST_SUCCESS = 'GET_SEGMENTATORS_LIST_SUCCESS';
export const GET_SEGMENTATORS_LIST_ERROR = 'GET_SEGMENTATORS_LIST_ERROR';

export const DOWNLOAD_EXPORT_REQUEST = 'DOWNLOAD_EXPORT_REQUEST';
export const DOWNLOAD_EXPORT_SUCCESS = 'DOWNLOAD_EXPORT_SUCCESS';
export const DOWNLOAD_EXPORT_ERROR = 'DOWNLOAD_EXPORT_ERROR';

export const SHOW_EXPORT_FILTER_MODAL = 'SHOW_EXPORT_FILTER_MODAL';
export const CLOSE_EXPORT_FILTER_MODAL = 'CLOSE_EXPORT_FILTER_MODAL';

export const GET_MINE_QUERY_BY_SOURCES_REQUEST = 'GET_MINE_QUERY_BY_SOURCES_REQUEST';
export const GET_MINE_QUERY_BY_SOURCES_SUCCESS = 'GET_MINE_QUERY_BY_SOURCES_SUCCESS';
export const GET_MINE_QUERY_BY_SOURCES_ERROR = 'GET_MINE_QUERY_BY_SOURCES_ERROR';

export const TRUNCATE_UPLOAD_REQUEST = 'TRUNCATE_UPLOAD_REQUEST';
export const TRUNCATE_UPLOAD_SUCCESS = 'TRUNCATE_UPLOAD_SUCCESS';
export const TRUNCATE_UPLOAD_ERROR = 'TRUNCATE_UPLOAD_ERROR';

export const CLEANUP_VOC_STORE = 'CLEANUP_VOC_STORE';

export const SHOW_TRUNCATE_MODAL_MODAL = 'SHOW_TRUNCATE_MODAL_MODAL';
export const CLOSE_TRUNCATE_MODAL_MODAL = 'CLOSE_TRUNCATE_MODAL_MODAL';

export const getInitialState = () => ({
    loadMineQuery: false,
    queries: [],
    exportFilterModal: {
        show: false,
        sourceId: null,
        dateOptions: [],
        type: '',
    },
    truncateUploadModal: {
        show: false,
        uploadId: null,
    },
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case SHOW_EXPORT_FILTER_MODAL: {
            return {
                ...state,
                exportFilterModal: {
                    ...state.exportFilterModal,
                    show: true,
                    sourceId: action.data.sourceId,
                    dateOptions: action.data.attrByDate,
                    type: action.data.type,
                },
            };
        }

        case CLOSE_EXPORT_FILTER_MODAL: {
            return {
                ...state,
                exportFilterModal: {
                    ...state.exportFilterModal,
                    show: false,
                    sourceId: null,
                    dateOptions: [],
                },
            };
        }

        case SHOW_TRUNCATE_MODAL_MODAL: {
            return {
                ...state,
                truncateUploadModal: {
                    ...state.truncateUploadModal,
                    show: true,
                    uploadId: action.data.uploadId,
                    type: action.data.type,
                    dateOptions: action.data.attrByDate,
                },
            };
        }

        case CLOSE_TRUNCATE_MODAL_MODAL: {
            return {
                ...state,
                truncateUploadModal: {
                    ...state.truncateUploadModal,
                    show: false,
                    uploadId: null,
                    dateOptions: [],
                },
            };
        }

        case GET_MINE_QUERY_BY_SOURCES_REQUEST: {
            return {
                ...state,
                loadMineQuery: true,
            };
        }

        case GET_MINE_QUERY_BY_SOURCES_SUCCESS: {
            return {
                ...state,
                loadMineQuery: false,
                queries: [ ...action.response.queries ],
            };
        }

        case GET_MINE_QUERY_BY_SOURCES_ERROR: {
            return {
                ...state,
                loadMineQuery: false,
            };
        }

        case CLEANUP_VOC_STORE: {
            return getInitialState();
        }

        default: {
            return state;
        }
    }
}

export function cleanup() {
    return {
        type: CLEANUP_VOC_STORE,
    };
}

export function showExportFilterModal(id, attrByDate, type = '') {
    return {
        type: SHOW_EXPORT_FILTER_MODAL,
        data: {
            sourceId: id,
            attrByDate,
            type,
        },
    };
}

export function closeExportFilterModal() {
    return {
        type: CLOSE_EXPORT_FILTER_MODAL,
    };
}

export function showTruncateUploadModal(id, attrByDate, type) {
    return {
        type: SHOW_TRUNCATE_MODAL_MODAL,
        data: {
            uploadId: id,
            attrByDate,
            type,
        },
    };
}

export function closeTruncateUploadModal() {
    return {
        type: CLOSE_TRUNCATE_MODAL_MODAL,
    };
}

export function getSegmentatorsList(body) {
    return {
        [CALL_API]: {
            endpoint: 'ENDPOINT_VOC_STORE_GET_SEGMENTATORS',
            method: 'GET',
            types: [
                GET_SEGMENTATORS_LIST_REQUEST,
                GET_SEGMENTATORS_LIST_SUCCESS,
                GET_SEGMENTATORS_LIST_ERROR,
            ],
            body,
        },
    };
}

export function truncateUpload({ id, data }) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/upload/truncate/${id}`,
            types: [ TRUNCATE_UPLOAD_REQUEST, TRUNCATE_UPLOAD_SUCCESS, TRUNCATE_UPLOAD_ERROR ],
            method: 'POST',
            logger: true,
            body: data,
        },
    };
}

export function initiateExport({ id, body }) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/export/${ id }/initiate`,
            types: [ DOWNLOAD_EXPORT_REQUEST, DOWNLOAD_EXPORT_SUCCESS, DOWNLOAD_EXPORT_ERROR ],
            method: 'POST',
            logger: true,
            loggerMessages: {
                success: t('messages.exportWasSuccessfullyInitiated'),
                error: t('messages.somethingWentWrong'),
            },
            body,
        },
    };
}

export function getMineQueryBySources(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/sources/${ id }`,
            types: [
                GET_MINE_QUERY_BY_SOURCES_REQUEST,
                GET_MINE_QUERY_BY_SOURCES_SUCCESS,
                GET_MINE_QUERY_BY_SOURCES_ERROR,
            ],
            method: 'GET',
            logger: true,
        },
    };
}
