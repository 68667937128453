import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { Button, Row, Col, Label } from 'reactstrap';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocModal, NumberInput, VocSelect, SelectAsyncPaginate } from '/components';
import { InputLoader } from '/feedback/components';
import { HelperService } from '/services';
import { SelectComponentOption, SelectComponentValue } from '../../../../components';
import { cleanupSurveysGroupBasic, getAllSurveysGroupBasic, getSurveyGroupBasic } from "../../../../modules/export.index";
import { mapSurveysToModal } from "../../../../modules/export.selector";

import './ManageSchedulesModal.scss';

const initialState = {
    surveySelect: null,
    channelSelect: null,
    exporterSelect: null,
    periodSelect: null,
    hourSelect: '',
    minuteSelect: '',
    dayOfMonthSelect: null,
    dayOfWeekSelect: null,
    unit: null,
    pendingRequest: true,
    filters: {
        page: 1,
    },
};

const mapStateToProps = state => ({
    surveys: mapSurveysToModal(state),
});

@connect(mapStateToProps, {
    getAllSurveysGroupBasic,
    cleanupSurveysGroupBasic,
    getSurveyGroupBasic,
})

export class ManageSchedulesModal extends Component {
    state = {
        ...initialState,
    };

    @autobind
    valueChangedInput(group, name) {
        this.setState({
            [name]: +group,
        });
    }

    @autobind
    onChangeSurvey(group) {
        this.setState({
            surveySelect: group,
            unit: group.unit,
            channelSelect: null,
        });
    }

    @autobind
    onChangeChannel({ value }) {
        this.setState({
            channelSelect: value,
        });
    }

    @autobind
    onChangeExporters({ value }) {
        this.setState({
            exporterSelect: value,
        });
    }

    @autobind
    onChangeDayOfWeek({ value }) {
        this.setState({
            dayOfWeekSelect: value,
        });
    }

    @autobind
    initModal() {
        const { getSurveyGroupBasic, manageSchedules } = this.props;
        const { selected, parameters } = manageSchedules;

        this.setState({
            ...initialState,
            ...selected,
            unit: parameters.unit,
        });

        if(selected.surveySelect) {
            getSurveyGroupBasic(selected.surveySelect).promise
                .then(res => {
                    if(res?.results) {
                        const surveyData = res.results[0];
                        const surveyStateDeleted = 541;

                        const formatSurveyData = {
                            channels: surveyData.surveys
                                .filter(survey => survey.state !== surveyStateDeleted)
                                .map(survey => ({
                                    label: survey.channel,
                                    value: String(survey.id),
                                })),
                            value: String(surveyData.id),
                            label: `${surveyData.id}-${surveyData.name}`,
                            title: `${surveyData.id}-${surveyData.name}`,
                            unit: HelperService.deepFind(surveyData, 'unit.id'),
                        };

                        this.setState({
                            surveySelect: formatSurveyData,
                        });
                    }
                });
        }
    }

    @autobind
    onChangeExportPeriod({ value }) {
        const isSelectPrevMonth = value === 'prev_month';

        let dayOfMonthSelect = null;

        if (isSelectPrevMonth) {
            dayOfMonthSelect = !this.state.dayOfMonthSelect ? 1 : this.state.dayOfMonthSelect;
        }

        this.setState({
            periodSelect: value,
            dayOfMonthSelect,
        });
    }

    @autobind
    onAddTouchPointClosed() {
        this.props.close();
    }

    @autobind
    selectAsyncPaginateSetPage(e) {
        const {
            filters,
        } = this.state;

        this.setState({
            filters: {
                ...filters,
                page: e.page,
            },
        });
    }

    @autobind
    selectAsyncPaginateGetSurveys({ body }) {
        const { getAllSurveysGroupBasic, cleanupSurveysGroupBasic } = this.props;
        const { filters } = this.state;

        if(filters.search !== body.search) {
            cleanupSurveysGroupBasic();
            getAllSurveysGroupBasic({
                ...body,
                page: 1,
            });
            this.setState({
                filters: {
                    ...body,
                    page: 1,
                },
            });
        } else {
            getAllSurveysGroupBasic(body);
            this.setState({
                filters: body,
            });
        }
    }

    @autobind
    onClose() {
        const { cleanupSurveysGroupBasic } = this.props;

        cleanupSurveysGroupBasic();
        this.setState({
            filters: {
                page: 1,
            },
        });
    }

    whatSelectPeriod(strOrArray, ifTrue, ifFalse) {
        const isEqual = Array.isArray(strOrArray)
            ? strOrArray.includes(this.state.periodSelect)
            : this.state.periodSelect === strOrArray;

        return isEqual ? ifTrue : ifFalse;
    }

    @autobind
    submitHandler() {
        const {
            channelSelect,
            exporterSelect,
            periodSelect,
            hourSelect,
            minuteSelect,
            dayOfMonthSelect,
            dayOfWeekSelect,
            unit,
        } = this.state;

        this.setState({
            pendingRequest: false,
        });

        const { parameters } = this.props.manageSchedules;
        const getId = exporterSelect.split(' ');
        const dayOfWeekFor7Days = this.whatSelectPeriod('prev_7d', +dayOfWeekSelect, null) || null;
        const dayOfWeekForPrevWeek = this.whatSelectPeriod([ 'prev_week', 'prev_full' ], +dayOfWeekSelect, null);

        const model = {
            id: parameters.id,
            self: parameters.self,
            unit: unit,
            creation: parameters.creation,
            last_interaction: parameters.last_interaction,
            survey: channelSelect,
            dayofweek: dayOfWeekForPrevWeek || dayOfWeekFor7Days,
            dayofmonth: this.whatSelectPeriod('prev_month', +dayOfMonthSelect, null),
            hour: hourSelect,
            minute: minuteSelect,
            type: periodSelect,
            //todo remove after delete old exporters
            ...getId.length >= 2 && getId[1] === 'newExporter'
                ? { export_config: getId[0] }
                : { exporter: exporterSelect },
        };

        this.props.submitHandler(model).finally(() => {
            this.setState({
                pendingRequest: true,
            });
        });
    }

    @autobind
    isSubmitDisabled() {
        const {
            channelSelect,
            exporterSelect,
            periodSelect,
            dayOfWeekSelect,
            hourSelect,
            minuteSelect,
            pendingRequest,
        } = this.state;
        const timeValidation = Number.isInteger(hourSelect) && Number.isInteger(minuteSelect);
        const dayOfWeek = this.whatSelectPeriod([ 'prev_week', 'prev_full' ], +dayOfWeekSelect, true);

        return [ channelSelect, exporterSelect, timeValidation, periodSelect, dayOfWeek, !this.props.surveys.loading, pendingRequest ]
            .every(field => field);
    }

    getModalFooter() {
        return (
            <div>
                <Button onClick={ this.onAddTouchPointClosed }
                    outline
                    color={ 'white' }>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button
                    onClick={ this.submitHandler }
                    disabled={ !this.isSubmitDisabled() }
                    color={ 'primary' }>
                    <FontAwesomeIcon icon="check"/>
                    <span> Save</span>
                </Button>
            </div>
        );
    }

    getModalBody() {
        const {
            surveys,
            exporters,
            exportPeriod,
            exportDayOfWeek,
        } = this.props;

        const {
            surveySelect,
            periodSelect,
            exporterSelect,
            channelSelect,
            dayOfMonthSelect,
            hourSelect,
            minuteSelect,
            filters,
        } = this.state;

        let {
            dayOfWeekSelect,
        } = this.state;

        const channelGroup = surveySelect?.channels || [];
        const isPreviousWeekSelected = this.whatSelectPeriod([ 'prev_week', 'prev_full' ], true, false);
        const isPreviousMonthSelected = this.whatSelectPeriod('prev_month', true, false);
        const isPrevious7DaysSelected = this.whatSelectPeriod('prev_7d', true, false);

        if (typeof dayOfWeekSelect === 'number') {
            dayOfWeekSelect = String(dayOfWeekSelect);
        }

        const dayOfWeekValue = HelperService.getSelectedValue(exportDayOfWeek, dayOfWeekSelect);
        const exporterValue = HelperService.getSelectedValue(exporters.results, exporterSelect);

        const isSelectedOldExporter
            = !exporters.results.find(exporter => exporter.value === exporterValue?.value)?.isNewExporter;
        const formatExportPeriod = exportPeriod.filter(period => isSelectedOldExporter && exporterValue?.value
            ? period.value !== 'prev_full'
            : period.value,
        );

        const periodValue = HelperService.getSelectedValue(formatExportPeriod, periodSelect);
        const channelValue = HelperService.getSelectedValue(channelGroup, channelSelect);
        const surveyValue = HelperService.getSelectedValue(surveys.results, surveySelect);

        return (
            <div className='schedule-modal'>
                <Row>
                    <Col className='select-control-label' md={ 3 }>
                        <Label>
                            Survey<span className='star'> *</span>
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <SelectAsyncPaginate
                            setPage={ this.selectAsyncPaginateSetPage }
                            placeholder={ 'Choose survey' }
                            className='vochub-select-control'
                            total={ surveys.total }
                            filters={{
                                ...filters,
                                limit: 15,
                            }}
                            onChange={ this.onChangeSurvey }
                            value={ surveyValue }
                            loading={ surveys.loading }
                            getSources={ this.selectAsyncPaginateGetSurveys }
                            options={ surveys.results }
                            closeMenuOnSelect={ true }
                            onClose={ this.onClose }
                            isClearable={ false }
                        />
                    </Col>
                </Row>

                <VocSelect
                    controlId="channel"
                    label="Channel"
                    isRequired={ true }
                    name="channelSelect"
                    isClearable={ false }
                    placeholder="Choose channel"
                    isHorizontal={ true }
                    options={ channelGroup }
                    value={ channelValue }
                    portal
                    disabled={ !channelGroup.length }
                    onChange={ this.onChangeChannel }
                />

                {
                    exporters.loading
                        ? <InputLoader label="Exporter *"/>
                        : <VocSelect
                            controlId="exporter"
                            label="Exporter"
                            isRequired={ true }
                            isClearable={ false }
                            placeholder="Choose exporter"
                            name="exporterSelect"
                            isHorizontal={ true }
                            options={ exporters.results }
                            value={ exporterValue }
                            portal
                            onChange={ this.onChangeExporters }
                            components={{
                                Option: SelectComponentOption,
                                ValueContainer: SelectComponentValue,
                            }}
                        />
                }

                <VocSelect
                    controlId="period"
                    label="Export period"
                    isRequired={ true }
                    isClearable={ false }
                    placeholder="Choose period"
                    name="periodSelect"
                    isHorizontal={ true }
                    options={ formatExportPeriod }
                    value={ periodValue }
                    portal
                    onChange={ this.onChangeExportPeriod }
                />

                {
                    (isPreviousWeekSelected || isPrevious7DaysSelected) && <VocSelect
                        label="Day of week"
                        isRequired={ !isPrevious7DaysSelected }
                        isClearable={ false }
                        key={ 5 }
                        isHorizontal={ true }
                        options={ exportDayOfWeek }
                        value={ dayOfWeekValue }
                        portal
                        onChange={ this.onChangeDayOfWeek }
                    />

                }

                {
                    isPreviousMonthSelected && <div className="form-group" key={ 6 }>
                        <Row>
                            <Col md={ 3 } className="select-control-label">
                                <label className="settings__input-label">
                                    { `Day of month` } <span className='star'> *</span>
                                </label>
                            </Col>
                            <Col md={ 3 }>
                                <NumberInput
                                    required={ true }
                                    step={ 1 }
                                    min={ 1 }
                                    max={ 28 }
                                    changeHandler={ this.valueChangedInput }
                                    name={ 'dayOfMonthSelect' }
                                    value={ dayOfMonthSelect ? dayOfMonthSelect : 1 }
                                />
                            </Col>
                        </Row>
                    </div>
                }

                <div className="form-group" key={ 7 }>
                    <Row>
                        <Col md={ 3 } className="select-control-label">
                            <label className="settings__input-label">
                                { `Hour` } <span className='star'> *</span>
                            </label>
                        </Col>
                        <Col md={ 3 }>
                            <NumberInput
                                required={ true }
                                step={ 1 }
                                min={ 0 }
                                max={ 23 }
                                changeHandler={ this.valueChangedInput }
                                name={ 'hourSelect' }
                                value={ hourSelect }
                            />
                        </Col>

                        <Col md={ 3 } className="select-control-label--left select-control-label">
                            <label className="settings__input-label">
                                { `Minute` } <span className='star'> *</span>
                            </label>
                        </Col>
                        <Col md={ 3 }>
                            <NumberInput
                                required={ true }
                                step={ 1 }
                                min={ 0 }
                                max={ 59 }
                                changeHandler={ this.valueChangedInput }
                                name={ 'minuteSelect' }
                                value={ minuteSelect }
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    render() {
        return (
            <VocModal
                isOpen={ this.props.show }
                toggle={ this.props.close }
                header={ 'Save Schedule' }
                modalClassName='modal-scrollable'
                footer={ this.getModalFooter() }
                onOpened={ this.initModal }
                size={ 'md' }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}

ManageSchedulesModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    submitHandler: PropTypes.func,
};
