import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { VocProgressBar } from '/components/VocProgressBar/';
import './UploadProgressSection.scss';

export const UploadProgressSection = props => {
    const { t } = useTranslation();
    const {
        hasInformationAboutFile,
        processedItemsCount,
        cancelledItemsCount,
        failedItemsCount,
        progressItemsCount,
        totalItemsCount,
    } = props;

    if (!hasInformationAboutFile) {
        return <p className={ 'progress-preparing-text' }>
            { t('preparing') }
        </p>;
    }

    return (
        <VocProgressBar
            processed={ processedItemsCount }
            cancelled={ cancelledItemsCount }
            failed={ failedItemsCount }
            pending={ progressItemsCount }
            total={ totalItemsCount }
        />
    );
};

UploadProgressSection.propTypes = {
    hasInformationAboutFile: PropTypes.bool,
    processedItemsCount: PropTypes.number,
    cancelledItemsCount: PropTypes.number,
    failedItemsCount: PropTypes.number,
    progressItemsCount: PropTypes.number,
    totalItemsCount: PropTypes.number,
};

