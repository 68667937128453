import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { NotificationItem } from './components';
import { notificationsSelector } from './modules/selectors';
import { removeNotification, clearAllNotifications } from './modules';

import Bell from './images/bell.svg';
import NotificationBell from './images/notification-bell.svg';

import './NotificationWidget.scss';

export const NotificationWidget = () => {
    const [ popoverOpen, setPopoverOpen ] = useState(false);
    const prevNotificationCount = useRef(0);
    const bellRef = useRef(null);
    const notifications = useSelector(notificationsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        const isAnimateBellIcon = prevNotificationCount.current < notifications.length;

        if (isAnimateBellIcon) {
            setTimeout(() => bellRef?.current?.classList.add('animateBellIcon'), 100);
        }

        prevNotificationCount.current = notifications.length;

        return () => {
            // reset bell img class list
            bellRef?.current?.classList.remove('animateBellIcon');
        };
    }, [ notifications.length ]);

    const handleRemoveNotification = cancelUrl => dispatch(removeNotification(cancelUrl));

    const handleClearAllNotification = () => dispatch(clearAllNotifications());

    const togglePopover = () => setPopoverOpen(!popoverOpen);

    const renderEmptyTemplate = () => (
        <div className='has-no-notifications'>
            <img src={ NotificationBell } alt='' />
            <p className='notification__text'>No new notifications</p>
        </div>
    );

    const renderNotificationItems = () => {
        return notifications.map((notification, index) => (
            <NotificationItem key={ index } { ...notification } removeNotification={ handleRemoveNotification }/>
        ));
    };

    const renderNotificationsPopover = () => (
        <Popover
            placement='bottom-end'
            target='bellPopoverTarget'
            id='voc-notifications-container'
            trigger='legacy'
            modifiers={{
                offset: {
                    offset: '28px, 19px',
                },
            }}
            isOpen={ popoverOpen }
            toggle={ togglePopover }
            positionFixed={ true }
        >
            {
                notifications.length > 0
                && <PopoverHeader className='notification__widget-header'>
                    <div>Notifications <span className='notifications__counter'>({ notifications.length })</span></div>
                    <button
                        className='notification__widget__clear-btn'
                        onClick={ handleClearAllNotification }
                    >
                        Clear all
                    </button>
                </PopoverHeader>
            }
            <PopoverBody className='notification__widget-body'>
                {
                    notifications.length === 0
                        ? renderEmptyTemplate()
                        : renderNotificationItems()
                }
            </PopoverBody>
        </Popover>
    );

    return (
        <>
            <div
                className='bell__container'
                id='bellPopoverTarget'
                onClick={ togglePopover }
            >
                <img
                    alt=''
                    src={ Bell }
                    ref={ bellRef }
                />

                {
                    notifications.length > 0
                    && <span className='bell__counter' >
                        { notifications.length }
                    </span>
                }
            </div>

            { renderNotificationsPopover() }
        </>
    );
};
