import React from 'react';
import PropTypes from 'prop-types';
import { TooltipWrapper } from '/components';
import { HelperService } from '/services';

export function RowChannels(props) {
    const channelsList = HelperService.getPossibleChannels();
    const channels = channelsList.map(channel => {
        const getStatusData = value => {
            const prefix = 'label ';

            switch (value) {
                case 1:
                    return { type: 'active', class: `${ prefix }label-success` };
                case 2:
                    return { type: 'paused', class: `${ prefix }label-warning` };
                case 131:
                    return { type: 'stopped', class: `${ prefix }label-info` };
                case 541:
                    return { type: 'deleted', class: `${ prefix }label-danger` };

                default:
                    return { type: 'inactive', class: `${ prefix }label-default` };
            }
        };

        const emptySurveyData = { channel: channel },
            existingSurveyData = props.surveys.find(survey => survey.channel === channel),
            surveyData = existingSurveyData || emptySurveyData;

        surveyData.class = getStatusData(surveyData.state).class;
        surveyData.type = getStatusData(surveyData.state).type;

        return surveyData;
    });

    return (
        <div className='channels noselect'>
            {
                channels.map((value, index) => {
                    const valueString = `Status: ${ value.type }${ value.id ? '; Survey ID: ' + value.id : '' }`;

                    return (
                        <TooltipWrapper key={ index } value={ valueString } placement='bottom' force>
                            <span className={ value.class }>
                                { value.channel }
                            </span>
                        </TooltipWrapper>
                    );
                })
            }
        </div>
    );
}

RowChannels.propTypes = {
    surveys: PropTypes.array,
};

RowChannels.defaultProps = {};
