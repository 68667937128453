interface ICalculateRelativeDate {
    start: number;
    end: number;
    period: string;
}

export class DateService {
    static getYesterdayDate() {
        const date = new Date();

        date.setDate(date.getDate() - 1);

        return {
            from: date,
            to: date,
        };
    }

    static getWeekDate(offset = 0) {
        const date = new Date();

        const commonDate = date.getDate() - date.getDay() - offset;
        const firstDayOfWeek = new Date(date.setDate(commonDate));
        const lastDayOfWeek = new Date(date.setDate(date.getDate() + 6));

        return {
            from: firstDayOfWeek,
            to: lastDayOfWeek,
        };
    }

    static getMonthDate(offset = 0) {
        const date = new Date();

        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() - offset, 1);
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + (!offset ? 1 : 0), 0);

        return {
            from: firstDayOfMonth,
            to: lastDayOfMonth,
        };
    }

    static getQuarterDate(offset = 0) {
        const date = new Date();

        const calculateQuarter = Math.floor((date.getMonth() / 3)) - offset;
        const firstDayOfQuarter = new Date(date.getFullYear(), calculateQuarter * 3, 1);
        const lastDayOfQuarter = new Date(firstDayOfQuarter.getFullYear(), firstDayOfQuarter.getMonth() + 3, 0);

        return {
            from: firstDayOfQuarter,
            to: lastDayOfQuarter,
        };
    }

    static getYearDate(offset = 0) {
        const date = new Date();

        const firstDayOfYear = new Date(date.getFullYear() - offset, 0, 1);
        const lastDayOfYear = new Date(date.getFullYear() - offset, 11, 31);

        return {
            from: firstDayOfYear,
            to: lastDayOfYear,
        };
    }

    static getRelativeDayDate(start: number, end: number) {
        const date = new Date();

        const firstDay = new Date(new Date().setDate(date.getDate() - start));
        const lastDay = new Date(new Date().setDate(date.getDate() - end));

        return {
            from: firstDay,
            to: lastDay,
        };
    }

    static getRelativeWeekDate(start: number, end: number, offset = 0) {
        const firstDayOfWeekDate = new Date();
        const firstDayOfWeekCalculation = firstDayOfWeekDate.getDate() - (firstDayOfWeekDate.getDay() - (1 - offset)) - (7 * start);
        const firstDayOfWeek = new Date(firstDayOfWeekDate.setDate(firstDayOfWeekCalculation));

        const lastDayOfWeekDate = new Date();
        const lastDayOfWeekCalculation = lastDayOfWeekDate.getDate() - lastDayOfWeekDate.getDay() - (7 * end);

        lastDayOfWeekDate.setDate(lastDayOfWeekCalculation);
        const lastDayOfWeek = new Date(lastDayOfWeekDate.setDate(lastDayOfWeekDate.getDate() + (7 - offset)));

        return {
            from: firstDayOfWeek,
            to: lastDayOfWeek,
        };
    }

    static getRelativeMonthDate(start: number, end: number) {
        const date = new Date();

        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() - Number(start), 1);
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() - Number(end) + 1, 0);

        return {
            from: firstDayOfMonth,
            to: lastDayOfMonth,
        };
    }

    static getRelativeQuarterDate(start: number, end: number) {
        const date = new Date();

        const findQuarterForFirstDay = Math.floor((date.getMonth() / 3)) - start;
        const firstDayOfQuarter = new Date(date.getFullYear(), findQuarterForFirstDay * 3, 1);

        const findQuarterForLastDay = Math.floor((date.getMonth() / 3)) - end;
        const calculateQuarterForLastDay = new Date(date.getFullYear(), findQuarterForLastDay * 3, 1);
        const lastDayOfQuarter = new Date(calculateQuarterForLastDay.getFullYear(), calculateQuarterForLastDay.getMonth() + 3, 0);

        return {
            from: firstDayOfQuarter,
            to: lastDayOfQuarter,
        };
    }

    static calculateRelativeDate({ start, end, period }: ICalculateRelativeDate) {
        const {
            getRelativeDayDate,
            getRelativeWeekDate,
            getRelativeMonthDate,
            getRelativeQuarterDate,
            getYearDate,
        } = DateService;

        switch (period) {
            case "DAY":
                return getRelativeDayDate(start, end);

            case "WEEK_MON":
                return getRelativeWeekDate(start, end);

            case "WEEK_SUN":
                return getRelativeWeekDate(start, end, 1);

            case "MONTH":
                return getRelativeMonthDate(start, end);

            case "QUARTER":
                return getRelativeQuarterDate(start, end);

            case "YEAR": {
                return {
                    from: getYearDate(start).from,
                    to: getYearDate(end).to,
                };
            }

            default: {
                return {
                    from: null,
                    to: null,
                };
            }
        }
    }

    static calculateAbsoluteDate(type: string) {
        const { getYesterdayDate, getWeekDate, getMonthDate, getQuarterDate, getYearDate } = DateService;

        switch (type) {
            case "wholeDay":
                return {
                    from: new Date(),
                    to: new Date(),
                };

            case "yesterday":
                return getYesterdayDate();

            case "thisWeek":
                return getWeekDate();

            case "lastWeek":
                return getWeekDate(7);

            case "thisMonth":
                return getMonthDate();

            case "lastMonth":
                return getMonthDate(1);

            case "thisQuarter":
                return getQuarterDate();

            case "lastQuarter":
                return getQuarterDate(1);

            case "thisYear":
                return getYearDate();

            case "lastYear":
                return getYearDate(1);

            case "last3Months":
                return getMonthDate(3);

            case "last6Months":
                return getMonthDate(6);

            case "last12Months":
                return getMonthDate(12);

            case "all": {
                return {
                    from: null,
                    to: null,
                };
            }

            default:
                return {
                    from: null,
                    to: null,
                };
        }
    }

    static calculateAbsoluteToRelative(from: number, to: number) {
        const oneDay = 24 * 60 * 60 * 1000;

        const fromFormatDate = new Date(from.replace(/(\d+[-])(\d+[-])/, '$2$1'));
        const toFormatDate = new Date(to.replace(/(\d+[-])(\d+[-])/, '$2$1'));

        const todayFromDifferent = Math.floor(Math.abs((fromFormatDate - new Date()) / oneDay));
        const todayToDifferent = Math.floor(Math.abs((toFormatDate - new Date()) / oneDay));

        return {
            start: -Number(todayFromDifferent),
            end: toFormatDate > new Date()
                ? 0
                : -Number(todayToDifferent),
        };
    }
}
