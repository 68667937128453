import { event, format, select } from 'd3';
import { autobind } from 'core-decorators';
import { isMobile } from 'react-device-detect';

import {
    IRadarChart,
    getDotCoordsAxisEnum,
    IChartServiceInit,
    IColorPickerData,
    radarDotType,
    radarPolygonType,
} from '/visual/scenes/Dashboard/components/Gadget/models';

export class RadarChartService {
    /* public constants */
    containerClass = 'container';
    dotClass = 'circle';
    polygonClass = 'polygon';
    animationDuration = 300;
    dotRadius = 5;
    hoveredDotRadius = 6;
    polygonOpacity = 0.5;
    polygonInactiveOpacity = 0.1;
    opacity = 1;
    opacityInactive = 0.1;

    /* class properties */
    radius: any;
    w = 0;
    h = 0;
    factor = 1;
    // selected manually to calculate the factorLegend { this.w / numForFactorLegend = factorLegend }
    numForFactorLegend = 411;
    defaultFactorLegend = .85;
    factorLegend = .85;
    levels = 10;
    maxValue = 0;
    radians: number = 2 * Math.PI;
    ToRight = 5;
    isInitialized: boolean;
    svgContainer: any;
    chartContainer: any;
    toolTipRef: any;
    data;
    allAxis;
    wrapperRef;
    svgRef;
    gadgetId: string;
    gadgetFunction: string;
    Format;
    chartType: string;
    total: any;
    protected readonly _setColorPickerData: (data: IColorPickerData) => void;

    constructor({
        chartType,
        svgRef,
        toolTipRef,
        gadgetId,
        gadgetFunction = 'count',
        wrapperRef,
        data,
        maxValue,
        allAxis,
        setColorPickerData,
    }: IRadarChart) {
        this.chartType = chartType;
        this.svgRef = svgRef;
        this.toolTipRef = null;
        this.gadgetId = gadgetId;
        this.gadgetFunction = gadgetFunction;
        this.wrapperRef = wrapperRef;
        this.data = data;
        this.maxValue = maxValue;
        this.allAxis = allAxis;
        this._setColorPickerData = setColorPickerData;

        this.isInitialized = false;
        this.Format = format(".0%");
        this.calculateSize();
        this.calculateFactorLegend(data);
        this.additionalSettings();
        this.init({
            svgRef,
            toolTipRef,
            gadgetId,
        });
    }

    @autobind
    calculateFactorLegend(data) {
        let lengthAxis = true;

        //todo hotfix rewrite
        data?.map(({ data }) => data?.map(({ axis }) => {
            if (axis.length > 4) {
                lengthAxis = false;
            }
        }));

        if (lengthAxis) {
            this.factorLegend = this.w / this.numForFactorLegend;
            if (this.factorLegend > this.defaultFactorLegend) {
                this.factorLegend = this.defaultFactorLegend;
            }
        }
    }

    @autobind
    calculateSize(wrapperRef = this.wrapperRef) {
        const internalWidth = wrapperRef?.clientWidth || 100;
        const internalHeight = wrapperRef?.clientHeight || 100;

        if (internalWidth < internalHeight) {
            this.w = internalWidth - 100;
            this.h = internalWidth - 100;
        } else {
            this.w = internalHeight - 50;
            this.h = internalHeight - 50;
        }
    }

    @autobind
    additionalSettings() {
        this.total = this.allAxis.length;
        this.radius = this.factor * Math.min(this.w / 2, this.h / 2);
    }

    @autobind
    getLevelFactor(inx: number) {
        return this.factor * this.radius * ((inx + 1) / this.levels);
    }

    @autobind
    protected _onColorPickerOpen(radarData) {
        this._setColorPickerData({
            open: true,
            coords: { y: event.pageY, x: event.pageX },
            target: `${this.chartType}_colorPicker_${this.gadgetId}`,
            color: radarData.color,
            elementId: radarData.id,
        });

        event.preventDefault();
    }

    @autobind
    protected _getFormattedPolygonData() {
        return this.data.map(y => {
            const polygonPoints = y.data.map((j, i) => {
                return [
                    (this.w / 2)
                    * (1
                        - (parseFloat(Math.max(j.value, 0).toString()) / this.maxValue)
                        * this.factor
                        * Math.sin((i * this.radians) / this.total)
                    ),
                    (this.h / 2)
                    * (1
                        - (parseFloat(Math.max(j.value, 0).toString()) / this.maxValue)
                        * this.factor
                        * Math.cos((i * this.radians) / this.total)
                    ),
                ];
            });

            polygonPoints.push(polygonPoints[0]); // align the last point

            return {
                polygonPoints: polygonPoints,
                color: y.color,
                customId: y.customId,
                id: y.id,
            };
        });
    }

    @autobind
    protected _getPolygonPointsAsString(d: radarPolygonType) {
        return d.polygonPoints.reduce((polygonPoints, current) =>
            `${polygonPoints}${current[0]},${current[1]} `, '',
        );
    }

    @autobind
    protected _getDotCoords(d: radarDotType, i: number, axis = getDotCoordsAxisEnum) {
        const halfDimension = (axis === 'y' ? this.h : this.w) / 2;
        const mathFunction = axis === 'y' ? 'cos' : 'sin';

        return (
            halfDimension
            * (1
                - (Math.max(d.value, 0) / this.maxValue)
                * this.factor
                * Math[mathFunction]((i * this.radians) / this.total)
            )
        );
    }

    @autobind
    protected _onDotEnter(d: radarDotType) {
        this.toolTipRef.html('');

        this.toolTipRef
            .append('span')
            .text(`Percentage: ${this.Format(d.value / this.data[0].totalCount) || 'No value'}`);

        this.toolTipRef
            .append('span')
            .text(`Point count: ${d.value}`);

        this.toolTipRef
            .append('span')
            .text(`Category: ${d.parentLabel}`);

        this.toolTipRef
            .append('span')
            .text(`Category ${this.gadgetFunction}: ${d.categoryValue}`);
    }

    @autobind
    protected _onDotOverMove() {
        !isMobile && this.toolTipRef
            .style('visibility', 'visible')
            .style('top', `${event.clientY + 15}px`)
            .style('left', `${event.clientX + 15}px`);
    }

    @autobind
    protected _onDotOver(d: radarDotType) {
        this._onDotOverMove();

        const dots = this.chartContainer
            .selectAll(`.${this.dotClass}`);

        dots
            .filter((j: radarDotType) => j.customId === d.customId)
            .transition()
            .duration(this.animationDuration)
            .attr('r', (j: radarDotType) =>
                j.customId === d.customId ? this.hoveredDotRadius : this.dotRadius,
            );

        dots
            .filter((j: radarDotType) => j.parentId !== d.parentId)
            .transition()
            .duration(this.animationDuration)
            .style('opacity', this.opacityInactive);

        this.chartContainer
            .selectAll(`.${this.polygonClass}`)
            .transition()
            .duration(this.animationDuration)
            .style('fill-opacity', this.polygonInactiveOpacity)
            .style('opacity', (p: radarPolygonType) =>
                d.parentId !== p.id ? this.opacityInactive : this.opacity,
            );
    }

    @autobind
    protected _onDotOut() {
        this.toolTipRef
            .style('visibility', 'hidden')
            .html('');

        this.chartContainer
            .selectAll(`.${this.dotClass}`)
            .transition()
            .duration(this.animationDuration)
            .style('opacity', this.opacity)
            .attr('r', this.dotRadius);

        this.chartContainer
            .selectAll(`.${this.polygonClass}`)
            .transition()
            .duration(this.animationDuration)
            .style('fill-opacity', this.polygonOpacity)
            .style('opacity', this.opacity);
    }

    @autobind
    protected _onPolygonOver(p: radarPolygonType) {
        this.chartContainer
            .selectAll(`.${this.polygonClass}`)
            .transition()
            .duration(this.animationDuration)
            .style('fill-opacity', this.polygonInactiveOpacity)
            .style('opacity', (j: radarPolygonType) =>
                j.customId !== p.customId ? this.opacityInactive : this.opacity,
            );

        this.chartContainer
            .selectAll(`.${this.dotClass}`)
            .filter((d: radarDotType) => d.parentId !== p.id)
            .transition()
            .duration(this.animationDuration)
            .style('opacity', this.opacityInactive);
    }

    @autobind
    protected _onPolygonOut() {
        this.chartContainer
            .selectAll('polygon')
            .transition()
            .duration(this.animationDuration)
            .style('fill-opacity', this.polygonOpacity)
            .style('opacity', this.opacity);

        this.chartContainer
            .selectAll(`.${this.dotClass}`)
            .transition()
            .duration(this.animationDuration)
            .style('opacity', this.opacity);
    }

    @autobind
    setHighlightedSlice(highlightedLabel: string | null) {
        if (this.isInitialized) {
            this.chartContainer
                .selectAll(`.${this.polygonClass}`)
                .transition()
                .duration(this.animationDuration)
                .style('opacity', (p: radarPolygonType) => highlightedLabel && p.id !== highlightedLabel
                    ? this.opacityInactive
                    : this.opacity,
                );

            this.chartContainer
                .selectAll(`.${this.dotClass}`)
                .transition()
                .duration(this.animationDuration)
                .style('opacity', (d: radarDotType) => highlightedLabel && d.parentId !== highlightedLabel
                    ? this.opacityInactive
                    : this.opacity,
                );
        }
    }

    @autobind
    drawContainer() {
        const internalWidth = this.wrapperRef?.clientWidth || 100;
        const internalHeight = this.wrapperRef?.clientHeight || 100;

        // this.h manually selected shift in the y-axis for g.container
        const cfgH = internalWidth < internalHeight ? internalWidth - 93 : internalHeight - 43;
        const x = internalWidth / 2 - this.w / 2,
            y = internalHeight / 2 - cfgH / 2;

        return this.svgContainer
            .attr('width', internalWidth)
            .attr('height', internalHeight)
            .append('g')
            .attr('class', this.containerClass)
            .attr('id', `${this.containerClass}_${this.gadgetId}`)
            .style('transform', `translate(${x}px, ${y}px)`);
    }

    @autobind
    drawCircularSegments(allAxis = this.allAxis, total = this.total) {
        for (let j = 0; j < this.levels - 1; j++) {
            const levelFactor = this.getLevelFactor(j);

            this.chartContainer.selectAll(".levels")
                .data(allAxis)
                .enter()
                .append("svg:line")
                .attr("x1", (d, i) => levelFactor * (1 - this.factor * Math.sin((i * this.radians) / total)))
                .attr("y1", (d, i) => levelFactor * (1 - this.factor * Math.cos((i * this.radians) / total)))
                .attr("x2", (d, i) => levelFactor * (1 - this.factor * Math.sin(((i + 1) * this.radians) / total)))
                .attr("y2", (d, i) => levelFactor * (1 - this.factor * Math.cos(((i + 1) * this.radians) / total)))
                .attr("class", "line")
                .style("stroke", "grey")
                .style("stroke-opacity", "0.75")
                .style("stroke-width", "0.3px")
                .attr("transform", `translate(${(this.w / 2 - levelFactor)},${(this.h / 2 - levelFactor)})`);
        }
    }

    @autobind
    drawYAxis(maxValue = this.maxValue, data = this.data) {
        //Text indicating at what % each level is
        for (let j = 0; j < this.levels; j++) {
            const levelFactor = this.getLevelFactor(j);

            this.chartContainer.selectAll('.levels')
                .data([ 1 ])
                .enter()
                .append('svg:text')
                .attr('x', () => levelFactor * (1 - this.factor * Math.sin(0)))
                .attr('y', () => levelFactor * (1 - this.factor * Math.cos(0)))
                .attr('class', 'legend')
                .style('font-family', 'sans-serif')
                .style('font-size', '10px')
                .attr('transform', `translate( ${(this.w / 2 - levelFactor + this.ToRight)}, ${(this.h / 2 - levelFactor)})`)
                .attr('fill', '#737373')
                .text(this.Format((j + 1) * (maxValue / data[0].totalCount) / this.levels));
        }
    }

    @autobind
    drawAxis(allAxis = this.allAxis, total = this.total) {
        const axis = this.chartContainer.selectAll(".axis")
            .data(allAxis)
            .enter()
            .append("g")
            .attr("class", "axis");

        axis
            .append("line")
            .attr("x1", this.w / 2)
            .attr("y1", this.h / 2)
            .attr("x2", (d, i) => (this.w / 2) * (1 - this.factor * Math.sin((i * this.radians) / total)))
            .attr("y2", (d, i) => (this.h / 2) * (1 - this.factor * Math.cos((i * this.radians) / total)))
            .attr("class", "line")
            .style("stroke", "grey")
            .style("stroke-width", "1px");

        axis
            .append("text")
            .attr("class", "legend legend-text")
            .text(d => d)
            .style("font-family", "sans-serif")
            .style("font-size", "11px")
            .attr("text-anchor", "middle")
            .attr("dy", "1.5em")
            .attr("transform", () => "translate(0, -10)")
            .attr("x", (d, i) => {
                return (
                    (this.w / 2)
                    * (1 - this.factorLegend * Math.sin((i * this.radians) / total))
                    - 60 * Math.sin((i * this.radians) / total)
                );
            })
            .attr("y", (d, i) => {
                return (
                    (this.h / 2) * (1 - Math.cos((i * this.radians) / total))
                    - 20 * Math.cos((i * this.radians) / total)
                );
            });
    }

    @autobind
    drawEachPolygon() {
        this.chartContainer.selectAll(`.${this.polygonClass}`)
            .data(this._getFormattedPolygonData)
            .enter()
            .append('polygon')
            .attr('class', this.polygonClass)
            .attr('id', (d: radarPolygonType) => d.customId)
            .attr('points', this._getPolygonPointsAsString)
            .style('stroke-width', '2px')
            .style('stroke', (d: radarPolygonType) => d.color)
            .style('fill', (d: radarPolygonType) => d.color)
            .style('fill-opacity', this.polygonOpacity)
            // add event listeners for each polygon
            .on('contextmenu', this._onColorPickerOpen)
            .on('mouseover', this._onPolygonOver)
            .on('mouseout', this._onPolygonOut);
    }

    @autobind
    drawDots() {
        this.data.forEach(y => {
            this.chartContainer.selectAll(`.nodes`)
                .data(y.data)
                .enter()
                .append('circle')
                .attr('class', this.dotClass)
                .attr('id', (d: radarDotType) => d.customId)
                .attr('r', 5)
                .attr('alt', j => j.value || 0)
                .attr('cx', (j: radarDotType, i: number) =>
                    this._getDotCoords(j, i, getDotCoordsAxisEnum.X),
                )
                .attr('cy', (j: radarDotType, i: number) =>
                    this._getDotCoords(j, i, getDotCoordsAxisEnum.Y),
                )
                .attr('data-id', ({ axis }) => axis)
                .style('fill', y.color)
                .style('fill-opacity', 0.9)
                // add event listeners for each polygon's dot
                .on('contextmenu', () => this._onColorPickerOpen(y))
                .on('mouseenter', this._onDotEnter)
                .on('mouseover', this._onDotOver)
                .on('mousemove', this._onDotOverMove)
                .on('mouseout', this._onDotOut);
        });
    }

    init({ svgRef, toolTipRef, gadgetId }: IChartServiceInit) {
        const svgContainer = select(svgRef);
        const toolTipContainer = select(toolTipRef);
        const existedChart = svgContainer.select(`g#${this.containerClass}_${gadgetId}`);

        this.svgContainer = svgContainer;
        this.toolTipRef = toolTipContainer;

        if (existedChart && existedChart.node()) {
            this.remove();
        }

        this.chartContainer = this.drawContainer();

        this.drawCircularSegments();

        this.drawYAxis();

        this.drawAxis();

        this.drawEachPolygon();

        this.drawDots();

        this._setInitialized(true);

        return this;
    }

    protected _setInitialized(isInitialized: boolean) {
        this.isInitialized = !!this.svgContainer && !!this.chartContainer && isInitialized;
    }

    remove() {
        if (!this.svgContainer) return;

        const chartElements = this.svgContainer.select('*');

        if (chartElements) {
            chartElements.remove();
            this._setInitialized(false);
        }
    }
}
