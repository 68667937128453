import { useDispatch } from "react-redux";

export function dispatchWrapper(actions) {
    const actionsWithDispatcher = [];
    const dispatch = useDispatch();

    actions.forEach(action => {
        actionsWithDispatcher.push((...args) => dispatch(action(...args)));
    });

    return actionsWithDispatcher;
}
