import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';

import { CollapseSection } from '/components';
const EXAMPLE_FILE_URL = '/store/upload/example/Mine%20upload%20with%20date.xlsx';
const CHOICE_FILE_URL = '/store/upload/example/Mine%20upload%20with%20choice.xlsx';
const META_DATA_FILE_URL = '/store/upload/example/Mine%20upload%20with%20metadata.xlsx';

export const Info = ({
    collapse,
    afterReloadState,
}) => {
    const { t } = useTranslation();

    const afterUploadDataItems = [
        <Trans key='uploadInfoList.1' i18nKey='uploadInfoList.1'>
            Please, upload your data in <strong>*.xls</strong>, <strong>*.xlsx</strong> or <strong>*.csv</strong> format.
        </Trans>,
        <Trans key='appendInfoList.2' i18nKey='appendInfoList.2'>
            <strong>IMPORTANT:</strong> Column headers in the file must be the same, as in the original source.
        </Trans>,
        <Trans key='appendInfoList.3' i18nKey='appendInfoList.3'>
            Optional: In the appended file can miss some columns, that exists in the original structure. In this case
            values for missed columns will be empty, and in the verification section for these columns you will see
            symbol <strong>'-'</strong>.
        </Trans>,
        <Trans key='appendInfoList.4' i18nKey='appendInfoList.4'>
            Optional: In the appended file can be additional columns, that do not yet exist in the original structure.
            In this case source structure will be extended with new column, and fin the verification section for these
            columns you will see symbol <strong>'+'</strong>.
        </Trans>,
        <Trans key='appendInfoList.5' i18nKey='appendInfoList.5'>
            <strong>IMPORTANT:</strong> For extending structure with new columns it is mandatory to have all already
            existing columns in the append file. There can't be columns with <strong>'-'</strong> and
            <strong>'+'</strong> on the verification section at the same time.
        </Trans>,
        <Trans key='appendInfoList.6' i18nKey='appendInfoList.6'>
            <strong>IMPORTANT:</strong> Extending of the source structure with new columns is not reversible.
        </Trans>,
        <Trans key='appendInfoList.7' i18nKey='appendInfoList.7'>
            Optional: When source structure is extending, new columns type should be selected correctly.
        </Trans>,
    ];

    const uploadDataItem = [
        <Trans key='uploadInfoList.1' i18nKey='uploadInfoList.1'>
            Please, upload your data in <strong>*.xls</strong>, <strong>*.xlsx</strong> or <strong>*.csv</strong> format.
        </Trans>,
        <Trans key='uploadInfoList.2' i18nKey='uploadInfoList.2'>
            <strong>IMPORTANT:</strong> by default all the fields are marked as Metadata, please follow the instruction below to specify the data type you want to import:
        </Trans>,
        <Trans key='uploadInfoList.3' i18nKey='uploadInfoList.3'>
            1111Optional: if you want to upload file contains customer feedback text columns to analyze with MINE,
            the header of the column must include the tag "<strong>[TEXT]</strong>". For example: "[TEXT] opinion text
            from customers". NB: please mark as text only comments fields. Columns as First Name, Last Name, or similar
            are Metadata and NOT Text.
        </Trans>,
        <Trans key='uploadInfoList.4' i18nKey='uploadInfoList.4'>
            Optional: the language code (i.e. en, de, fr, it etc.) with the header tag "<strong>[LANG]</strong>" will
            be considered as the language of the text - <a href={ EXAMPLE_FILE_URL }>example file</a>.
        </Trans>,
        <Trans key='uploadInfoList.5' i18nKey='uploadInfoList.5'>
            Optional: the date column with the header tag <strong>[DATE]</strong> will be considered as the capture
            date of the text - <a href={ EXAMPLE_FILE_URL }>example file</a>.
        </Trans>,
        <Trans key='uploadInfoList.6' i18nKey='uploadInfoList.6'>
            Optional: the NPS column with the header tag "<strong>[NPS]</strong>" will be considered as the NPS, for
            working with it on Visual - <a href={ CHOICE_FILE_URL }>example file</a>. Please note, that NPS column type
            should include numbers from 0 to 10 or empty cells. All other non-integer values will fail to be processed
            by the System.
        </Trans>,
        <Trans key='uploadInfoList.7' i18nKey='uploadInfoList.7'>
            Optional: the Choice column with the header tag "<strong>[CHOICE]</strong>" will be considered as the
            Choice column - <a href={ CHOICE_FILE_URL }>example file</a>. Please note, that values of such column
            will be divided by "Choice delimiter". If you don't specify value for choice delimiter '|' will be set
            as default.
        </Trans>,
        <Trans key='uploadInfoList.8' i18nKey='uploadInfoList.8'>
            Optional: the metadata with the header tag <strong>[NUMERIC]</strong> will be considered as a numeric type
            of values and math functions can be applied to them on Visual. Please note, that the numeric rows including
            symbols, letters, special characters and other non-integers will be turned to null values -
            <a href={ META_DATA_FILE_URL }>example file</a>.
        </Trans>,
        <Trans key='uploadInfoList.9' i18nKey='uploadInfoList.9'>
            Optional: add metadata with respective headers in other columns -
            <a href={ META_DATA_FILE_URL }>example file</a>.
        </Trans>,
        <Trans key='uploadInfoList.10' i18nKey='uploadInfoList.10'>
            When file for upload is chosen please check in verification section whether the System correctly recognized
            the type of data in file columns. You can manually change the type of columns even in case when specific
            tag (e.g. [TEXT], [LANG], [DATE], [NPS], [NUMERIC]) is present in column title.
        </Trans>,
        <Trans key='uploadInfoList.11' i18nKey='uploadInfoList.11'>
            Values from <strong>[DATE]</strong> column would be used for the system column: "captured_at"
        </Trans>,
        <Trans key='uploadInfoList.12' i18nKey='uploadInfoList.12'>
            The first left column <strong>[DATE]</strong> is taken if the table has more than one column with
            <strong>[DATE]</strong>
        </Trans>,
    ];

    const getInfoText = () => {
        const infoList = afterReloadState
            ? afterUploadDataItems
            : uploadDataItem;

        return infoList.map((infoSnippet, index) => (
            <li key={ index }>
                <span>
                    { infoSnippet }
                </span>
            </li>
        ));
    };

    return (
        <CollapseSection
            title={ t('field.info') }
            className="info-section__header"
            collapse={ collapse }
        >
            <Alert color="info">
                <ul className={ 'text-sm-left' }>
                    {
                        getInfoText()
                    }
                </ul>
            </Alert>
        </CollapseSection>
    );
};

