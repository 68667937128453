import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';

import { IPreloader } from '/components/Preloader/models';
import { useResizeObserver } from '/services';

import './Preloader.scss';

const MAX_FONT_SIZE = 11;

export const Preloader = ({ color, transparentByRefNode, autoSizeByContainerRef }: IPreloader) => {
    const [ background, setBackground ] = useState(color ?? '');
    const [ fontSize, setFontSize ] = useState<number | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);
    const dimensions = useResizeObserver(autoSizeByContainerRef);

    const getClosestBackgroundColor = (node?: HTMLDivElement | null) => {
        if (!node) return;

        const { backgroundColor } = window.getComputedStyle(node);

        if (backgroundColor && backgroundColor !== 'rgba(0, 0, 0, 0)' && backgroundColor !== 'transparent') {
            setBackground(backgroundColor);
        } else {
            getClosestBackgroundColor(node.parentNode as HTMLDivElement);
        }
    };

    useEffect(() => {
        getClosestBackgroundColor(transparentByRefNode?.current);
    }, [ transparentByRefNode ]);

    useEffect(() => {
        if (autoSizeByContainerRef) {
            const loaderNode = ref.current;

            if (loaderNode) {
                const { width, height } = window.getComputedStyle(loaderNode.parentNode as Element);
                const containerSize = Math.min(Number.parseInt(width), Number.parseInt(height));

                if (containerSize) {
                    const calculatedFontSize = containerSize * 0.75 / 10;

                    setFontSize(calculatedFontSize < MAX_FONT_SIZE ? calculatedFontSize : MAX_FONT_SIZE);
                }
            }
        }
    }, [ autoSizeByContainerRef, dimensions ]);

    return <div
        style={{ background, fontSize: fontSize ?? '' }}
        className={ cx('loader', { centered: autoSizeByContainerRef && fontSize }) }
        ref={ ref }
    />;
};
