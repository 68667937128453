import { createSelector } from 'reselect';
import { HelperService } from '/services';
import { formValueSelector } from 'redux-form';
import {
    actionType,
    expressionTypes,
    metaDataToOptionSelector,
    surveyExpressionTypes,
    mathExpression,
    optionsBlockedBy,
    transformQuarantineConditionsToReduxForm,
    unitsListSelector,
} from '/feedback/modules/VocFeedback.selectors';
import { DynamicFormServices } from '../../../../Export/components/DynamicForm/services';

const _getQuarantinesSettings = state => state.VocFeedback.survey.quarantines;
const _getCurrentSurvey = state => state.VocFeedback.survey.surveyCommon.id;
const _getSurveyList = state => state.VocFeedback.common.surveyList.results;
const _getSurveysWithDeleted = state => state.VocFeedback.common.surveysWithDeleted.results;

export const filtersSelector = state => _getQuarantinesSettings(state).filters;

const selector = formValueSelector('QuarantinesFlow');

export const formValuesSelector = state => selector(
    state,
    'survey_group',
    'logical_operation',
    'days',
    'hours',
    'action_type',
    'conditions',
    'id',
);

export const tableSettingsSelector = createSelector(
    [ _getQuarantinesSettings ],
    ({ count, loading, submitted }) => ({
        count,
        loading,
        submitted,
    }));

export const tableDataSelector = createSelector(
    [ unitsListSelector, _getQuarantinesSettings ],
    (units, { results }) => {
        const unitsWithPermissions = units?.map(unit => {
            return unit.permissionsName?.includes('Editor') || unit.permissionsName?.includes('Manager')
                ? unit.id
                : undefined;
        }) || [];

        return results.map(result => ({
            ...result,
            days: result.action.days || '-',
            permission: {
                isPermitEdit: unitsWithPermissions.includes(result?.survey_group?.unit),
            },
        }));
    });

export const initialValuesSelector = createSelector(
    [ _getQuarantinesSettings, tableDataSelector, _getCurrentSurvey, _getSurveysWithDeleted ],
    (quarantinesSettings, quarantineList, surveyId, surveyList) => {
        const currentSurvey = surveyList.find(({ id }) => id === surveyId);
        const quarantineData = quarantineList.find(({ id }) => id === quarantinesSettings.quarantineId);

        if (quarantineData !== undefined) {
            const { id, action, survey_group, logical_operation, conditions } = quarantineData;

            return {
                id,
                action_type: getActionType(action.type),
                days: action.days || 0,
                hours: action.hours || 0,
                survey_group: {
                    label: survey_group.name,
                    value: survey_group.id,
                },
                logical_operation: logical_operation,
                conditions: transformQuarantineConditionsToReduxForm(conditions),
            };
        } else if (currentSurvey) {
            return {
                days: 0,
                hours: 0,
                survey_group: {
                    label: currentSurvey.name,
                    value: currentSurvey.id,
                },
            };
        }

        return { days: 0, hours: 0 };
    });

export const selectOptionsSelector = createSelector(
    [ unitsListSelector, _getSurveyList ],
    (units, surveyList) => {
        const unitsWithRequiredPermissions = units?.map(unit => {
            return unit.permissionsName?.includes('Editor') || unit.permissionsName?.includes('Manager')
                ? unit.id
                : undefined;
        }) || [];

        const filteredSurveysData = surveyList.filter(
            survey => unitsWithRequiredPermissions.includes(survey.unit),
        );

        const options = HelperService.mapObjToSelect(filteredSurveysData, 'id', 'name');

        return {
            affectedOptions: [ ...options.map(({ label, value }) => {
                return {
                    label,
                    value,
                    condition_type: 'survey_group',
                };
            }) ],
        };
    });

export const quarantinesFlowSelector = createSelector(
    [ selectOptionsSelector, metaDataToOptionSelector ],
    ({ affectedOptions }, metaDataList) => {
        const blocked_by = [
            {
                label: 'Additional',
                options: [ ...optionsBlockedBy.additional ],
            },
            {
                label: 'Survey',
                options: [
                    ...optionsBlockedBy.survey,
                    ...affectedOptions,
                ],
            },
            {
                label: 'MetaData',
                options: metaDataList,
            },
        ];

        return {
            expression: expressionTypes,
            surveyExpression: surveyExpressionTypes,
            mathExpression: mathExpression,
            blocked_by,
        };
    });

export const collectFlowOptions = createSelector(
    [ formValuesSelector, quarantinesFlowSelector ],
    (reduxFormValue, blockedByFlowOptions) => {
        const flowOptions = DynamicFormServices.deepCopyObj(blockedByFlowOptions);

        const collectionOptions = [];

        if (Object.keys(reduxFormValue).length && reduxFormValue.conditions && reduxFormValue.conditions.length) {
            reduxFormValue.conditions.forEach(() => {
                collectionOptions.push({
                    ...flowOptions,
                });
            });
        }

        return collectionOptions.length ? collectionOptions : [ blockedByFlowOptions ];
    });

const getActionType = type => {
    return actionType.find(item => item.value === type);
};

