import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * @returns {IResizeObserverDimensions | null}
 */
export const useResizeObserver = ref => {
    const [ dimensions, setDimensions ] = useState(null);

    useEffect(() => {
        let observeTarget, resizeObserver;

        if(ref?.current) {
            observeTarget = ref.current;
            resizeObserver = new ResizeObserver(entries => {
                entries.forEach(entry => {
                    setDimensions(entry.contentRect);
                });
            });

            resizeObserver.observe(observeTarget);
        }

        return () => {
            resizeObserver?.unobserve(observeTarget);
        };
    }, [ ref ]);

    return dimensions;
};
