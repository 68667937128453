import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';
import { SettingsService } from '/feedback/services';
import { SubmissionError } from 'redux-form';

const GET_THEME_SETTINGS_REQUEST = 'GET_THEME_SETTINGS_REQUEST';
const GET_THEME_SETTINGS_SUCCESS = 'GET_THEME_SETTINGS_SUCCESS';
const GET_THEME_SETTINGS_ERROR = 'GET_THEME_SETTINGS_ERROR';

const UPDATE_THEME_SETTINGS_REQUEST = 'UPDATE_THEME_SETTINGS_REQUEST';
const UPDATE_THEME_SETTINGS_SUCCESS = 'UPDATE_THEME_SETTINGS_SUCCESS';
const UPDATE_THEME_SETTINGS_ERROR = 'UPDATE_THEME_SETTINGS_ERROR';

const CREATE_THEME_SETTINGS_REQUEST = 'CREATE_THEME_SETTINGS_REQUEST';
const CREATE_THEME_SETTINGS_SUCCESS = 'CREATE_THEME_SETTINGS_SUCCESS';
const CREATE_THEME_SETTINGS_ERROR = 'CREATE_THEME_SETTINGS_ERROR';

const GET_LANGUAGE_SETTINGS_REQUEST = 'GET_LANGUAGE_SETTINGS_REQUEST';
const GET_LANGUAGE_SETTINGS_SUCCESS = 'GET_LANGUAGE_SETTINGS_SUCCESS';
const GET_LANGUAGE_SETTINGS_ERROR = 'GET_LANGUAGE_SETTINGS_ERROR';

const CREATE_LANGUAGE_REQUEST = 'CREATE_LANGUAGE_REQUEST';
const CREATE_LANGUAGE_SUCCESS = 'CREATE_LANGUAGE_SUCCESS';
const CREATE_LANGUAGE_ERROR = 'CREATE_LANGUAGE_ERROR';

const GET_DIGI_RUNNER_URL_REQUEST = 'GET_DIGI_RUNNER_URL_REQUEST';
const GET_DIGI_RUNNER_URL_SUCCESS = 'GET_DIGI_RUNNER_URL_SUCCESS';
const GET_DIGI_RUNNER_URL_ERROR = 'GET_DIGI_RUNNER_URL_ERROR';

const DELETE_LANGUAGE_REQUEST = 'DELETE_LANGUAGE_REQUEST';
const DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS';
const DELETE_LANGUAGE_ERROR = 'DELETE_LANGUAGE_ERROR';

const UPDATE_LANGUAGE_REQUEST = 'UPDATE_LANGUAGE_REQUEST';
const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
const UPDATE_LANGUAGE_ERROR = 'UPDATE_LANGUAGE_ERROR';

const GET_SETTINGS_DEFAULT_THEME_REQUEST = 'GET_SETTINGS_DEFAULT_THEME_REQUEST';
const GET_SETTINGS_DEFAULT_THEME_SUCCESS = 'GET_SETTINGS_DEFAULT_THEME_SUCCESS';
const GET_SETTINGS_DEFAULT_THEME_ERROR = 'GET_SETTINGS_DEFAULT_THEME_ERROR';

const GET_ALL_TAGS_REQUEST = 'GET_ALL_TAGS_REQUEST';
const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS';
const GET_ALL_TAGS_ERROR = 'GET_ALL_TAGS_ERROR';

const SET_ACTIVE_LANG_FOR_LANGUAGE_SETTINGS = 'SET_ACTIVE_LANG_FOR_LANGUAGE_SETTINGS';

const CLEANUP_THEME = 'CLEANUP_THEME';

const initialsThemeSettings = {
    digi_runner_font_size: null,
    digi_popup_window_height: null,
    digi_popup_window_width: null,
    digi_popup_indent: null,
    digi_popup_bg_color: null,
    digi_popup_font_family: null,
    digi_popup_question_bg_color: null,
    digi_popup_question_font_color: null,
    digi_popup_header_bg_color: null,
    digi_popup_header_font_color: null,
    digi_popup_button_bg_color: null,
    digi_popup_button_font_color: null,
    digi_theme_css_custom: null,
    digi_theme_css_external: null,
    digi_custom_header: null,
    digi_custom_footer: null,
};

function getInitialState() {
    return {
        themeId: null,
        themeName: '',
        themeDescription: '',
        themeTags: [],
        activeLangId: null,
        sendingLangStatus: false,
        sendingTheme: false,
        themeSettings: {
            loading: false,
            settings: {
                ...initialsThemeSettings,
            },
        },
        languageSettings: {
            loading: false,
            sending: false,
            results: [],
        },
        allTags: {
            loading: true,
            data: [],
        },
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_THEME_SETTINGS_REQUEST:
        case GET_SETTINGS_DEFAULT_THEME_REQUEST: {
            return {
                ...state,
                themeSettings: {
                    ...state.themeSettings,
                    loading: true,
                },
            };
        }

        case GET_THEME_SETTINGS_SUCCESS: {
            return {
                ...state,
                themeId: action.response.id,
                themeName: action.response.name,
                themeDescription: action.response.title,
                themeTags: action.response.digi_theme_tags?.map(tag => ({
                    value: tag.name,
                    label: tag.name,
                })),
                activeLangId: SettingsService.getterLocalStorage('digiThemes', action.response.id),
                themeSettings: {
                    ...state.themeSettings,
                    settings: {
                        ...HelperService.pick(action.response, Object.keys(initialsThemeSettings)),
                    },
                    loading: false,
                },
            };
        }

        case GET_SETTINGS_DEFAULT_THEME_SUCCESS: {
            return {
                ...state,
                themeSettings: {
                    ...state.themeSettings,
                    settings: {
                        ...HelperService.pick(action.response.results[0], Object.keys(initialsThemeSettings)),
                    },
                    loading: false,
                },
            };
        }

        case GET_THEME_SETTINGS_ERROR:
        case GET_SETTINGS_DEFAULT_THEME_ERROR: {
            return {
                ...state,
                themeSettings: {
                    ...state.themeSettings,
                    loading: false,
                },
            };
        }

        case CREATE_THEME_SETTINGS_REQUEST:
        case UPDATE_THEME_SETTINGS_REQUEST: {
            return {
                ...state,
                sendingTheme: true,
                themeSettings: {
                    ...state.themeSettings,
                },
            };
        }

        case CREATE_THEME_SETTINGS_SUCCESS:
        case UPDATE_THEME_SETTINGS_SUCCESS: {
            return {
                ...state,
                themeId: action.response.id,
                themeName: action.response.name,
                themeDescription: action.response.title,
                themeTags: action.response.digi_theme_tags?.map(tag => ({
                    value: tag.name,
                    label: tag.name,
                })),
                sendingTheme: false,
                themeSettings: {
                    ...state.themeSettings,
                    settings: {
                        ...HelperService.pick(action.response, Object.keys(initialsThemeSettings)),
                    },
                },
            };
        }

        case CREATE_THEME_SETTINGS_ERROR:
        case UPDATE_THEME_SETTINGS_ERROR: {
            return {
                ...state,
                sendingTheme: false,
                themeSettings: {
                    ...state.themeSettings,
                },
            };
        }

        case GET_LANGUAGE_SETTINGS_REQUEST: {
            return {
                ...state,
                languageSettings: {
                    ...state.languageSettings,
                    loading: true,
                },
            };
        }

        case GET_LANGUAGE_SETTINGS_SUCCESS: {
            return {
                ...state,
                languageSettings: {
                    ...state.languageSettings,
                    results: [
                        ...action.response,
                    ],
                    loading: false,
                },
            };
        }

        case GET_LANGUAGE_SETTINGS_ERROR: {
            return {
                ...state,
                languageSettings: {
                    ...state.languageSettings,
                    loading: false,
                },
            };
        }

        case SET_ACTIVE_LANG_FOR_LANGUAGE_SETTINGS:
            SettingsService.setterLocalStorage('digiThemes', { [action.params.themeId]: action.params.langId });

            return {
                ...state,
                activeLangId: action.params.langId,
            };

        case CREATE_LANGUAGE_REQUEST:
        case UPDATE_LANGUAGE_REQUEST:
        case DELETE_LANGUAGE_REQUEST: {
            return {
                ...state,
                sendingLangStatus: true,
            };
        }

        case CREATE_LANGUAGE_SUCCESS: {
            return {
                ...state,
                sendingLangStatus: false,
                languageSettings: {
                    ...state.languageSettings,
                    results: [
                        ...state.languageSettings.results,
                        { ...action.response },
                    ],
                },
            };
        }

        case UPDATE_LANGUAGE_SUCCESS: {
            return {
                ...state,
                sendingLangStatus: false,
                languageSettings: {
                    ...state.languageSettings,
                    results: state.languageSettings.results.map(item => item.id === action.response.id ? action.response : item),
                },
            };
        }

        case DELETE_LANGUAGE_SUCCESS: {
            return {
                ...state,
                sendingLangStatus: false,
                languageSettings: {
                    ...state.languageSettings,
                    results: [
                        ...state.languageSettings.results.filter(lang => lang.id !== action.params),
                    ],
                },
            };
        }

        case CREATE_LANGUAGE_ERROR:
        case UPDATE_LANGUAGE_ERROR:
        case DELETE_LANGUAGE_ERROR: {
            return {
                ...state,
                sendingLangStatus: false,
            };
        }


        case GET_ALL_TAGS_REQUEST: {
            return {
                ...state,
                allTags: {
                    data: [],
                    loading: true,
                },
            };
        }

        case GET_ALL_TAGS_SUCCESS:
            return {
                ...state,
                allTags: {
                    data: [ ...action.response.results ],
                    loading: false,
                },
            };

        case GET_ALL_TAGS_ERROR: {
            return {
                ...state,
                allTags: {
                    data: [],
                    loading: false,
                },
            };
        }

        case CLEANUP_THEME: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getThemeById(id) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/${ id }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_THEME_SETTINGS_REQUEST,
                GET_THEME_SETTINGS_SUCCESS,
                GET_THEME_SETTINGS_ERROR,
            ],
            apiType: FEEDBACK,
        },
    };
}

export function setTheme(id, name, description, tags, values) {
    return dispatch => {
        const formatTags = tags?.map(tag => tag.value);
        const body = { name, title: description, tags: formatTags || [], ...values };

        const request = id
            ? dispatch(updateTheme(id, body))
            : dispatch(createTheme({ ...body, unit: null }));

        return request.promise
            .catch(({ response }) => {
                throw new SubmissionError(response);
            });
    };
}

function updateTheme(id, body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/${ id }/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ UPDATE_THEME_SETTINGS_REQUEST, UPDATE_THEME_SETTINGS_SUCCESS, UPDATE_THEME_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Theme settings were successfully updated',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function setLang(id, body) {
    return dispatch => {
        return dispatch(updateLang(id, { ...body }));
    };
}

function updateLang(id, body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-language/${ id }/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ UPDATE_LANGUAGE_REQUEST, UPDATE_LANGUAGE_SUCCESS, UPDATE_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Language settings were successfully updated',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function getDigiRunnerUrl(id) {
    return {
        [CALL_API]: {
            endpoint: `/unit/${id}/get-channel-settings/DIGI/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_DIGI_RUNNER_URL_REQUEST, GET_DIGI_RUNNER_URL_SUCCESS, GET_DIGI_RUNNER_URL_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

function createTheme(body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_THEME_SETTINGS_REQUEST, CREATE_THEME_SETTINGS_SUCCESS, CREATE_THEME_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'THEME was successfully created',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function getLangSettings(digi_theme_id) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-language/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_LANGUAGE_SETTINGS_REQUEST, GET_LANGUAGE_SETTINGS_SUCCESS, GET_LANGUAGE_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            body: {
                digi_theme_id,
            },
        },
    };
}

export function createLang(body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-language/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_LANGUAGE_REQUEST, CREATE_LANGUAGE_SUCCESS, CREATE_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function getSettingsDefaultTheme(id) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/?unit_id=${ id }`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_SETTINGS_DEFAULT_THEME_REQUEST,
                GET_SETTINGS_DEFAULT_THEME_SUCCESS,
                GET_SETTINGS_DEFAULT_THEME_ERROR,
            ],
            apiType: FEEDBACK,
        },
    };
}

export function deleteLang(id) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-language/${ id }/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_LANGUAGE_REQUEST, DELETE_LANGUAGE_SUCCESS, DELETE_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
            params: id,
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP_THEME,
    };
}

export function setActiveLang(params) {
    return {
        type: SET_ACTIVE_LANG_FOR_LANGUAGE_SETTINGS,
        params,
    };
}

export function getAllTags() {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-tag`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_ALL_TAGS_REQUEST, GET_ALL_TAGS_SUCCESS, GET_ALL_TAGS_ERROR ],
            apiType: FEEDBACK,
            unique: true,
            body: {
                limit: 99999,
                page: 1,
            },
        },
    };
}
