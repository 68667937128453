import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { SettingsService } from '/feedback/services/SettingsService';
import { getThemesBySurvey } from '/feedback/scenes/Survey/modules/survey.modules';
import { HelperService } from '/services';

export const GET_CHANNEL_LANGUAGE_REQUEST = 'GET_CHANNEL_LANGUAGE_REQUEST';
export const GET_CHANNEL_LANGUAGE_SUCCESS = 'GET_CHANNEL_LANGUAGE_SUCCESS';
export const GET_CHANNEL_LANGUAGE_ERROR = 'GET_CHANNEL_LANGUAGE_ERROR';

export const GET_SURVEY_LANGUAGE_REQUEST = 'GET_SURVEY_LANGUAGE_REQUEST';
export const GET_SURVEY_LANGUAGE_SUCCESS = 'GET_SURVEY_LANGUAGE_SUCCESS';
export const GET_SURVEY_LANGUAGE_ERROR = 'GET_SURVEY_LANGUAGE_ERROR';

export const GET_SURVEY_LINK_REQUEST = 'GET_SURVEY_LINK_REQUEST';
export const GET_SURVEY_LINK_SUCCESS = 'GET_SURVEY_LINK_SUCCESS';
export const GET_SURVEY_LINK_ERROR = 'GET_SURVEY_LINK_ERROR';

export const PUT_SURVEY_LANGUAGE_REQUEST = 'PUT_SURVEY_LANGUAGE_REQUEST';
export const PUT_SURVEY_LANGUAGE_SUCCESS = 'PUT_SURVEY_LANGUAGE_SUCCESS';
export const PUT_SURVEY_LANGUAGE_ERROR = 'PUT_SURVEY_LANGUAGE_ERROR';

export const GET_DIGI_LINK_REQUEST = 'GET_DIGI_LINK_REQUEST';
export const GET_DIGI_LINK_SUCCESS = 'GET_DIGI_LINK_SUCCESS';
export const GET_DIGI_LINK_ERROR = 'GET_DIGI_LINK_ERROR';

export const GET_DIGI_LANGUAGE_REQUEST = 'GET_DIGI_LANGUAGE_REQUEST';
export const GET_DIGI_LANGUAGE_SUCCESS = 'GET_DIGI_LANGUAGE_SUCCESS';
export const GET_DIGI_LANGUAGE_ERROR = 'GET_DIGI_LANGUAGE_ERROR';

export const CREATE_SURVEY_LANGUAGE_REQUEST = 'CREATE_SURVEY_LANGUAGE_REQUEST';
export const CREATE_SURVEY_LANGUAGE_SUCCESS = 'CREATE_SURVEY_LANGUAGE_SUCCESS';
export const CREATE_SURVEY_LANGUAGE_ERROR = 'CREATE_SURVEY_LANGUAGE_ERROR';

export const CREATE_SURVEY_THEME_FOR_LANG_REQUEST = 'CREATE_SURVEY_THEME_FOR_LANG_REQUEST';
export const CREATE_SURVEY_THEME_FOR_LANG_SUCCESS = 'CREATE_SURVEY_THEME_FOR_LANG_SUCCESS';
export const CREATE_SURVEY_THEME_FOR_LANG_ERROR = 'CREATE_SURVEY_THEME_FOR_LANG_ERROR';

export const UPDATE_LANGUAGE_SETTINGS_REQUEST = 'UPDATE_LANGUAGE_SETTINGS_REQUEST';
export const UPDATE_LANGUAGE_SETTINGS_SUCCESS = 'UPDATE_LANGUAGE_SETTINGS_SUCCESS';
export const UPDATE_LANGUAGE_SETTINGS_ERROR = 'UPDATE_LANGUAGE_SETTINGS_ERROR';

export const SURVEY_LANGUAGES_LOADING = 'SURVEY_LANGUAGES_LOADING';
export const SURVEY_LANGUAGES_LOADED = 'SURVEY_LANGUAGES_LOADED';

export const SURVEY_LANGUAGES_CLEANUP = 'SURVEY_LANGUAGES_CLEANUP';

function getInitialState() {
    return {
        loading: true,
        sending: false,
        surveySettings: {},
        globalSettings: {},
        digiLangSettings: {
            global_theme_language: null,
            parent_unit_theme_language: null,
            survey_theme_language: null,
        },
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case SURVEY_LANGUAGES_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case SURVEY_LANGUAGES_LOADED: {
            return {
                ...state,
                loading: false,
            };
        }

        case GET_SURVEY_LANGUAGE_SUCCESS: {
            return {
                ...state,
                surveySettings: {
                    ...action.response,
                },
            };
        }

        case GET_CHANNEL_LANGUAGE_SUCCESS: {
            return {
                ...state,
                globalSettings: {
                    ...action.response,
                },
            };
        }

        case PUT_SURVEY_LANGUAGE_REQUEST: {
            return {
                ...state,
                sending: true,
            };
        }

        case PUT_SURVEY_LANGUAGE_SUCCESS: {
            return {
                ...state,
                surveySettings: {
                    ...action.response,
                },
                sending: false,
            };
        }

        case PUT_SURVEY_LANGUAGE_ERROR: {
            return {
                ...state,
                sending: false,
            };
        }

        case GET_DIGI_LANGUAGE_SUCCESS: {
            return {
                ...state,
                digiLangSettings: {
                    ...action.response,
                },
            };
        }

        case CREATE_SURVEY_THEME_FOR_LANG_REQUEST:
        case CREATE_SURVEY_LANGUAGE_REQUEST: {
            return {
                ...state,
                sending: true,
            };
        }

        case CREATE_SURVEY_THEME_FOR_LANG_ERROR:
        case CREATE_SURVEY_LANGUAGE_ERROR: {
            return {
                ...state,
                sending: false,
            };
        }

        case CREATE_SURVEY_LANGUAGE_SUCCESS:
        case UPDATE_LANGUAGE_SETTINGS_SUCCESS: {
            return {
                ...state,
                digiLangSettings: {
                    ...state.digiLangSettings,
                    survey_theme_language: {
                        ...action.response,
                    },
                },
            };
        }

        case SURVEY_LANGUAGES_CLEANUP: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getLanguageSettings(params) {
    return dispatch => {
        dispatch(startProcessing());
        const channelLanguageRequest = dispatch(getChannelLanguageSettings(params));
        const surveyLanguageRequest = dispatch(getSurveyLanguageSettings(params));
        const promises = [ channelLanguageRequest.promise, surveyLanguageRequest.promise ];
        const request = [ channelLanguageRequest, surveyLanguageRequest ];

        if (params.channel === 'DIGI') {
            const digiLanguageRequest = dispatch(getCompositeLanguageTheme(params));

            promises.push(digiLanguageRequest.promise);
            request.push(digiLanguageRequest);
        }

        Promise.all(promises)
            .then(() => dispatch(endProcessing()), ({ status }) => {
                if (status !== 0) {
                    return dispatch(endProcessing());
                }
            });

        return request;
    };
}

export function startProcessing() {
    return {
        type: SURVEY_LANGUAGES_LOADING,
    };
}

export function endProcessing() {
    return {
        type: SURVEY_LANGUAGES_LOADED,
    };
}

export function getChannelLanguageSettings({ channel, language, unit }) {
    //1-1
    return {
        [CALL_API]: {
            endpoint: `unit/${ unit }/get-channel-language-settings/${ channel }/${ language }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CHANNEL_LANGUAGE_REQUEST, GET_CHANNEL_LANGUAGE_SUCCESS, GET_CHANNEL_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getSurveyLanguageSettings({ id, channel, language }) {
    //1
    return {
        [CALL_API]: {
            endpoint: `survey-group/${ id }/get-channel-language-settings/${ channel }/${ language }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_LANGUAGE_REQUEST, GET_SURVEY_LANGUAGE_SUCCESS, GET_SURVEY_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getCompositeLanguageTheme({ idActiveLang, channelId }) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-language/language-theme-extended/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_DIGI_LANGUAGE_REQUEST, GET_DIGI_LANGUAGE_SUCCESS, GET_DIGI_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
            body: {
                survey_id: channelId,
                language_id: idActiveLang,
            },
        },
    };
}

export function getStaticLink({ id, language }) {
    return {
        [CALL_API]: {
            endpoint: `/survey/${ id }/staticlink/${ language }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_LINK_REQUEST, GET_SURVEY_LINK_SUCCESS, GET_SURVEY_LINK_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getDigiShortenedLink({ survey_id, language }) {
    return {
        [CALL_API]: {
            endpoint: `/digi-shortened-link/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_DIGI_LINK_REQUEST, GET_DIGI_LINK_SUCCESS, GET_DIGI_LINK_ERROR ],
            apiType: FEEDBACK,
            body: {
                survey_id,
                language,
            },
        },
    };
}

export function putSurveyLanguageSettings(model) {
    return dispatch => {
        return dispatch(putSurveyLanguageSettingsRequest(model)).promise
            .catch(SettingsService.catchSettingsErrors);
    };
}

export function manegeSurveyThemeLang({ name, survey, parent_theme, digi_theme, language, model }) {
    return async dispatch => {
        const createBody = {
            digi_theme,
            language,
            ...model.body,
        };

        const request = parent_theme
            ? await dispatch(createSurveyTheme({
                name,
                survey,
                parent_theme,
            })).promise
            : null;

        const dispatchCreate = id => {
            id && dispatch(getThemesBySurvey(survey));

            return dispatch(createUpdateSurveyThemeLang({
                ...createBody,
                digi_theme: id ? id : createBody.digi_theme,
            },
            model,
            true,
            )).promise;
        };

        return request
            ? dispatchCreate(request.id)
            : dispatchCreate();
    };
}

export function createUpdateSurveyThemeLang(createBody = {}, model, isCreate) {
    return dispatch => {
        const { id, language, languageIso, surveyGroupId, digi_theme } = model;

        const body = {
            ...model.body,
            ...createBody,
        };

        const {
            themeLangSettings,
            langSettings,
        } = SettingsService.settingsModel.createThemeLangSettingsForFilter;

        const bodyThemeSettings = HelperService.pick(body, themeLangSettings);
        const bodyLangSettings = HelperService.pick(body, langSettings);

        if (isCreate) {
            return dispatch(createSurveyLang({
                ...bodyThemeSettings,
            })).promise.then(() => {
                dispatch(putSurveyLanguageSettings(
                    { ...model, body: { ...bodyLangSettings }, language: languageIso, id: surveyGroupId },
                ),
                );
            });
        } else {
            return dispatch(updateSurveyLang(id, {
                ...bodyThemeSettings,
                language,
                digi_theme,
            })).promise.then(() => {
                dispatch(putSurveyLanguageSettings(
                    { ...model, body: { ...bodyLangSettings }, language: languageIso, id: surveyGroupId },
                ),
                );
            });
        }
    };
}

export function createSurveyTheme(body) {
    return {
        [CALL_API]: {
            endpoint: '/digi-theme/digi-theme-survey/',
            method: 'POST',
            contentType: 'application/json',
            types: [
                CREATE_SURVEY_THEME_FOR_LANG_REQUEST,
                CREATE_SURVEY_THEME_FOR_LANG_SUCCESS,
                CREATE_SURVEY_THEME_FOR_LANG_ERROR,
            ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function createSurveyLang(body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-language/`,
            method: 'POST',
            contentType: 'application/json',
            types: [
                CREATE_SURVEY_LANGUAGE_REQUEST,
                CREATE_SURVEY_LANGUAGE_SUCCESS,
                CREATE_SURVEY_LANGUAGE_ERROR,
            ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function updateSurveyLang(id, body) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme-language/${ id }/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [
                UPDATE_LANGUAGE_SETTINGS_REQUEST,
                UPDATE_LANGUAGE_SETTINGS_SUCCESS,
                UPDATE_LANGUAGE_SETTINGS_ERROR,
            ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Language settings were successfully updated',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function putSurveyLanguageSettingsRequest({ id, language, body }) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${ id }/set-language-settings/${ language }/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ PUT_SURVEY_LANGUAGE_REQUEST, PUT_SURVEY_LANGUAGE_SUCCESS, PUT_SURVEY_LANGUAGE_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Language settings were successfully updated',
                error: 'Something went wrong',
            },
        },
    };
}

export function cleanup() {
    return {
        type: SURVEY_LANGUAGES_CLEANUP,
    };
}
