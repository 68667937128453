import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { TooltipWrapper } from '/components';
import { LabelModal } from '../';
import { axisLabelType, ELabelType, TLabelPopoverData } from '../../models';
import { IChartAxis } from './models';

import styles from './style.module.scss';

export const ChartAxis = ({
    axisLabels,
    gadgetId,
    axisSize,
    chartSettings,
    onApplyLabelModalData,
    children,
}: IChartAxis) => {
    const [ axisData, setAxisData ] = useState<TLabelPopoverData | null>(null);
    const { showYLabels, showXLabels, isNpsChart, marginTop, xOffsetForNps } = chartSettings;

    useEffect(() => {
        setAxisData(null);
    }, [ showYLabels, showXLabels ]);

    const onClickAxisLabel = (axisType: keyof axisLabelType) => {
        setAxisData(null);

        setTimeout(() => {
            setAxisData({
                open: true,
                target: axisType + `axis${gadgetId}`,
                id: axisType,
                value: axisLabels[axisType as keyof axisLabelType] as string,
                type: ELabelType.AXIS,
            });
        }, 1);
    };

    const onApplyLabelModalDataHandler = (inputValue: TLabelPopoverData['value']) => {
        if (axisData) {
            onApplyLabelModalData({
                id: axisData.id,
                value: inputValue,
                type: axisData.type,
            });
        }
    };

    const getAxis = (key: keyof axisLabelType) => {
        const value = axisLabels?.[key];

        return (
            <div
                className={ cx(styles.axisInner, styles[`${key}_axis`]) }
                id={ `${key}axis${gadgetId}` }
                onClick={ () => onClickAxisLabel(key) }
            >
                {
                    value
                        ? <TooltipWrapper value={ value }>
                            <span>{ value }</span>
                        </TooltipWrapper>
                        : <FontAwesomeIcon
                            color={ '#b6b6b6' }
                            icon={ 'pen' }
                            style={{ transform: `rotate(${ key === 'group' ? '0' : '180deg' })` }}
                        />
                }
            </div>
        );
    };

    return (
        <div className={ cx(styles.container) }>
            {/* left yAxis */}
            {
                showYLabels && <div
                    className={ cx(styles.axis, styles.axisLeftYColumn) }
                    style={{ height: axisSize.height, marginTop: marginTop || 0 }}
                >
                    { getAxis('count') }
                </div>
            }

            <div className={ cx(styles.chartColumn) }>
                {/* chart */}
                { children }

                {/* xAxis */}
                {
                    showXLabels && <div
                        className={ cx(styles.axis, styles.axisXColumn) }
                        style={{
                            width: axisSize.width,
                            marginRight: xOffsetForNps ? xOffsetForNps : 0,
                        }}
                    >
                        { getAxis('group') }
                    </div>
                }
            </div>

            {/* right yAxis */}
            {
                showYLabels && isNpsChart && <div
                    className={ cx(styles.axis, styles.axisRightYColumn) }
                    style={{ height: axisSize.height, marginTop: marginTop || 0 }}
                >
                    { getAxis('score') }
                </div>
            }

            <LabelModal
                maxLength={ 180 }
                labelModalData={ axisData }
                labelType={ ELabelType.AXIS }
                setLabelModalData={ setAxisData }
                onApplyLabelModalData={ onApplyLabelModalDataHandler }
            />
        </div>
    );
};
