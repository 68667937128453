import { initNotificationSocket } from './Header/scenes/NotificationWidget/modules/notifications.sockets';
import { sockedUploadUpdateListener, sockedUploadDeleteListener } from './VocStore/scenes/UploadsList/modules/uploadList.sockets';
import { sockedQueryUpdateListener } from './VocMine/scenes/Query/modules/query.sockets';
import { sockedUploadCreateListener } from './VocStore/scenes/UploadData/scenes/UploadNewFile/modules/uploadData.sockets';
import { initCountersSocket } from './Header/scenes/VocMenu/modules/counter.sockets';
import { initMineQuerySocket } from './Header/scenes/VocMenu/modules/mineQueries.sockets';
import { socketImportUpdateListener } from './VocFeedback/scenes/Imports/modules/Imports.sockets';
import { socketGadgetUpdateListener } from '/visual/scenes/Dashboard/modules/Dashboard.sockets';
import { sockedTopicSetUpdateListener } from '/modules/topicSetModules/topicSet.sockets';

export const socketsListeners = [
    initNotificationSocket,
    sockedUploadDeleteListener,
    sockedUploadCreateListener,
    sockedUploadUpdateListener,
    socketImportUpdateListener,
    sockedQueryUpdateListener,
    sockedTopicSetUpdateListener,
    initCountersSocket,
    initMineQuerySocket,
    socketGadgetUpdateListener,
];
