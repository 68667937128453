import React from 'react';
import { SortableItem } from '../SortableItem';
import { SortableContainer } from 'react-sortable-hoc';

export const SortableList = SortableContainer(props => {
    const { items } = props;

    return (
        <div>
            {
                items.map((row, idRow) => (
                    <SortableItem
                        key={ `item-${idRow}` }
                        index={ idRow }
                        propsData={{ row, idRow, ...props }}
                    />
                ))
            }
        </div>
    );
});
