import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { SourceSelect } from '../SourceSelect';
import { ConfirmModificationVirtualContent } from '../ConfirmModificationVirtualContent';

import './VirtualSourceFormSidebarItem.scss';

@withTranslation()
export class VirtualSourceFormSidebarItem extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.id !== nextProps.id
            || this.props.customId !== nextProps.customId
            || this.props.excludeIds.toString() !== nextProps.excludeIds.toString()
            || this.state.showConfirmationModal !== nextState.showConfirmationModal
            || this.state.model !== nextState.model
        );
    }

    state = {
        showConfirmationModal: false,
        model: null,
    };

    @autobind
    removeRow() {
        const {
            index,
            removeVirtualSourceRow,
            showChangeNotificationModal,
            setShowChangeNotificationModal,
            showConfirm,
            setAutoFill,
            virtualRelation,
            isNewRow,
            t,
        } = this.props;

        if (virtualRelation.needToShowModal && showChangeNotificationModal && !isNewRow) {
            showConfirm({
                header: t('warning'),
                content: <ConfirmModificationVirtualContent virtualRelation={ virtualRelation }/>,
                successCallback: () => {
                    setShowChangeNotificationModal(false);
                    removeVirtualSourceRow(index);
                },
            });
        } else {
            removeVirtualSourceRow(index);
        }
        index === 0 && setAutoFill(false);
    }

    @autobind
    closeModal() {
        this.setState({
            showConfirmationModal: false,
        });
    }

    @autobind
    onCloseHandler() {
        this.props.handleSourceChange(this.state.model);
        this.props.setAutoFill(false);
        this.closeModal();
    }

    @autobind
    onSubmitHandler() {
        this.props.autofillColumnsHandler(this.state.model);
        this.closeModal();
    }

    @autobind
    renderModalFooter() {
        const { t } = this.props;

        return (
            <div className='virtual-source-action-buttons'>
                <Button
                    outline
                    color='white'
                    onClick={ this.onCloseHandler }
                >
                    <FontAwesomeIcon icon='times' />
                    &nbsp;
                    <span>{ t('no') }</span>
                </Button>
                <Button
                    color='primary'
                    onClick={ this.onSubmitHandler }
                >
                    <FontAwesomeIcon icon='check' />
                    &nbsp;
                    <span>{ t('yes') }</span>
                </Button>
            </div>
        );
    }

    @autobind
    onChange(option) {
        const sourceTemplate = {
            attributes: [],
            channel: '',
            id: '',
            customId: this.props.customId,
            languages: [],
            state: null,
            title: '',
            totalRowsCount: 0,
            type: '',
            updated: '',
        };

        const optionValue = option ? option : {};

        if(this.props.id !== optionValue.value) {
            const model = {
                id: this.props.index,
                source: optionValue.value
                    ? optionValue.source
                    : sourceTemplate,
            };

            if (this.props.isFirstVirtualSourceSidebarLength && option !== null) {
                this.setState({ model: model });
                this.setState({ showConfirmationModal: true });
            } else {
                this.props.index === 0 && this.props.setAutoFill(false);
                this.props.secondAutofillColumnsHandler(model);
                this.props.handleSourceChange(model);
            }
        }
    }

    render() {
        const {
            selectedOption,
            excludeIds,
            customId,
            index,
            isAutoFill,
            t,
        } = this.props;

        const isAutoFillStyle = () => index === 0 && isAutoFill;

        return (
            <>
                <div className="fixed-table__row">
                    <div
                        style={{ zIndex: `${ 1000 - index }` }}
                        className={ `fixed-table__col fixed-table__col--sidebar ${ isAutoFillStyle() && 'fixed-table__col--sidebar__autofill'}` }
                    >
                        <div className={ 'fixed-table__col-icon vs-container-cross' } onClick={ this.removeRow }>
                            <FontAwesomeIcon icon='times' />
                        </div>
                        <div className={ 'fixed-table__col-input' }>
                            <SourceSelect
                                placeholder={ t('placeholders.select') }
                                id={ customId }
                                value={ selectedOption }
                                onChange={ this.onChange }
                                exclude_sources={ excludeIds.filter(Boolean) }
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={ this.state.showConfirmationModal }
                    size={ 'xs' }
                    className='virtual-source__modal_autofill_confirm'
                    onClosed={ this.closeModal }
                >
                    <ModalBody>
                        { t('automaticallyFillInColumnData') }
                    </ModalBody>
                    <ModalFooter>
                        { this.renderModalFooter() }
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

VirtualSourceFormSidebarItem.propTypes = {
    handleSourceChange: PropTypes.func,
    autofillColumnsHandler: PropTypes.func,
    id: PropTypes.string,
};
