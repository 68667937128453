import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { statesSelector, unitsListSelector } from '/feedback/modules/VocFeedback.selectors';
import { DownloadFileService } from '/services';

export const filtersSelector = state => state.VocFeedback.imports.filters;

export const importsSelector = state => state.VocFeedback.imports;

export const surveysListSelector = state => state.VocFeedback.imports.surveys;

export const importsTableSettingsSelector = createSelector(
    [ importsSelector ],
    list => {
        const { imports: { loading, count } } = list;

        return {
            loading,
            total: count,
        };
    });

export const getPermissionSelector = createSelector(
    [ unitsListSelector ],
    units => {
        const permissionImport = units?.some(({ permissionsName }) =>
            permissionsName?.includes('Inviter') || permissionsName?.includes('Manager'),
        );

        return permissionImport;
    });

const formFieldsSelector = getFormValues('ImportForm');

export const importFormValuesSelector = createSelector([ formFieldsSelector ], (values = {}) => {
    const {
        surveyId = {},
        file,
    } = values;

    return {
        channels: surveyId && (surveyId.channels || []),
        surveyId: surveyId && (surveyId.value),
        file,
    };
});

export const allSurveyGroupSelector = createSelector(
    [ surveysListSelector, statesSelector, unitsListSelector ],
    (surveys, states, units) => {
        const unitsWithRequiredPermissions = units?.map(unit => {
            return unit.permissionsName?.includes('Inviter') || unit.permissionsName?.includes('Manager')
                ? unit.id
                : undefined;
        }) || [];

        const filteredSurveysData = surveys.data.filter(
            survey => unitsWithRequiredPermissions.includes(survey.unit.id),
        );

        return {
            ...surveys,
            results: filteredSurveysData.map(group => ({
                ...group,
                surveys: group.surveys.map(survey => {
                    const stateData = states.survey.find(state => state.id === survey.state);

                    return {
                        ...survey,
                        stateName: stateData && stateData.name,
                    };
                }),
            })),
        };
    });

export const mapSurveysGroupToModal = createSelector([ allSurveyGroupSelector ], surveys => {
    return {
        loading: surveys.loading,
        results: surveys.results.filter(group =>
            group.surveys.filter(survey => (survey.stateName !== 'deleted')).length > 0,
        ).map(group => ({
            channels: group.surveys
                .map(survey => ({
                    label: survey.channel,
                    value: String(survey.id),
                })),
            value: String(group.id),
            label: `${group.id}-${group.name}`,
        })),
    };
});

export const importsListTableDataSelector = state => {
    return state.VocFeedback.imports.imports.data.map(importItem => {
        const { processed_row_count, failed_row_count, total_row_count } = importItem;
        const stateData = statesSelector(state)['import'].find(state => state.id === importItem.state);
        const isManySurvey = importItem.survey.length >= 2;
        const STATUS_CREATED = 700;
        const STATUS_IN_PROGRESS = 702;

        const stateName = () => {
            let stateStatus;

            if (stateData.id === STATUS_CREATED || stateData.id === STATUS_IN_PROGRESS) {
                stateStatus = `in progress (${
                    !total_row_count
                        ? '0'
                        : Math.round((processed_row_count + failed_row_count) / total_row_count * 100)
                }%)`;
            }

            return stateStatus || stateData.name;
        };

        let surveyData = {
            channel: '',
            id: '',
            survey_group_name: '',
        };

        if(isManySurvey) {
            const listGroupName = [];

            importItem.survey.forEach(({ id, survey_group_name }, inx) => {
                const comma = !!inx ? ', ' : '';

                surveyData.id = `${surveyData.id}${comma}${id}`;
                listGroupName.push(survey_group_name);
            });

            surveyData.survey_group_name = [ ...new Set(listGroupName) ]
                .toString()
                .replaceAll(',', ', ');
        } else if(importItem.survey.length) {
            surveyData = importItem.survey[0];
        }

        return {
            control: isManySurvey,
            rowClass: `import-history-row ${ isManySurvey ? 'row-control' : ''}`,
            additionalRow: isManySurvey ? importItem.survey : null,
            id: importItem.id,
            survey_id: surveyData.id,
            survey__survey_group__name: surveyData.survey_group_name,
            survey__channel__name: surveyData.channel,
            result_file: {
                fileName: DownloadFileService.getCatalogLastPart({ catalogPath: importItem.result_file }),
                processedItemsCount: importItem.processed_row_count,
                id: importItem.id,
                type: 'resultfile',
                isDeleted: !Boolean(importItem.result_file && importItem.processed_row_count !== 0),
            },
            error_file: {
                fileName: DownloadFileService.getCatalogLastPart({ catalogPath: importItem.error_file }),
                failedItemsCount: importItem.failed_row_count,
                id: importItem.id,
                type: 'errorfile',
                isDeleted: !Boolean(importItem.error_file && importItem.failed_row_count !== 0),
            },
            original_file: {
                fileName: DownloadFileService.getCatalogLastPart({ catalogPath: importItem.original_file }),
                totalItemsCount: importItem.total_row_count,
                id: importItem.id,
                type: 'importfile',
                isDeleted: !Boolean(importItem.original_file),
            },
            creation: importItem.creation,
            end_time: importItem.end_time,
            state: stateName(),
        };
    });
};

export const importsListTableCountSelector = state => state.VocFeedback.imports.imports.count;
