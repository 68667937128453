import React from 'react';
import { components } from 'react-select';
import { Badge } from 'reactstrap';
import { optionLabel } from '../../modules/export.selector';

//todo remove after delete old exporters
export const MultiValueLabel = props => (
    <>
        <components.MultiValueLabel { ...props } />
        {
            <div className="export-results__labels-container">
                <Badge color={ optionLabel(props?.data.isNewExporter).color }
                    className={ 'export-results__source-label' }>
                    { optionLabel(props?.data.isNewExporter).title }
                </Badge>
            </div>
        }
    </>
);
