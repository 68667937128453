import React from 'react';

import { TContentList, TCustomElementProps } from '/query/models';

const CustomElement: React.FC<TCustomElementProps> = ({ children, Tag, styles }) =>
    <Tag style={ styles }>{ children }</Tag>;

export class InsightNarratorService {
    static chartsStoreName = [ 'texts', 'lemmata', 'coOccurrence', 'correlation' ];

    static insightInitial = {
        loaded: false,
        data: null,
        timestamp: null,
        options: [],
    }

    static getInsightNarratorData({ state, action, type }) {
        const { insight } = action.response;

        return {
            ...state.insightNarrator,
            [type]: {
                loaded: true,
                data: insight?.body || insight,
                timestamp: insight?.created || insight,
                options: insight?.options || insight,
            },
        };
    }

    static getInsightInitialStore() {
        return InsightNarratorService.chartsStoreName.reduce((accumulator, currentKey) => {
            return {
                ...accumulator,
                [currentKey]: InsightNarratorService.insightInitial,
            };
        }, {});
    }

    static updateOptionsOnRequest(state: any, action: any, type: string) {
        const newOptions = action.body.options;

        return {
            ...state.insightNarrator,
            [type]: {
                ...state.insightNarrator[type],
                options: newOptions || state.insightNarrator[type].options,
            },
        };
    }

    static getContentId = (header: string, prefix = '') =>
        `${ prefix }__${ header.replace(/\W/g, '') }`;

    static copyContent({
        contentList,
        onCopySuccess,
        onCopyFailed,
    }: {
        contentList: TContentList,
        onCopySuccess: () => void,
        onCopyFailed: () => void,
    }) {
        const elements: { contentNode: HTMLElement | null, headerNode: HTMLHeadElement }[] = [];
        const tempNodesWrapper = document.createElement('div');

        contentList.forEach(({
            loading,
            header,
            content,
        }) => {
            if (!loading && content) {
                const contentId = InsightNarratorService.getContentId(header, 'content');

                // create and fill header for coping
                const headerElement = document.createElement('h1');

                headerElement.innerHTML = header;
                headerElement.style.fontWeight = 'bold';

                elements.push({
                    contentNode: document.getElementById(contentId),
                    headerNode: headerElement,
                });
            }
        });

        elements.forEach(element => {
            element.headerNode
            && tempNodesWrapper.appendChild(element.headerNode.cloneNode(true));

            element.contentNode
            && tempNodesWrapper.appendChild(element.contentNode.cloneNode(true));
        });

        document.body.appendChild(tempNodesWrapper);

        // create new instance of Range
        const range = document.createRange();

        // insert tempNodesWrapper into Range
        range.selectNodeContents(tempNodesWrapper);

        // get all selection from Range
        const selection = window.getSelection();

        // clear previous selection (reset buffer)
        selection?.removeAllRanges();
        // add tempNodesWrapper to selection
        selection?.addRange(range);

        try {
            document.execCommand('copy');
            onCopySuccess();
        } catch (err) {
            console.error(err);
            onCopyFailed();
        }

        document.body.removeChild(tempNodesWrapper);
    }

    // added inline styles for make it bolder
    static overrideFontWeight(tags: string[]) {
        const result: Record<string, any> = {};

        tags.forEach(tag => {
            result[tag] = {
                component: CustomElement,
                props: { Tag: tag, styles: { fontWeight: 700 } },
            };
        });

        return result;
    }

    static overrideMarkdown({ forFontWeight = false }) {
        return {
            // add override method for other tags if needed,
            ...forFontWeight
                ? InsightNarratorService.overrideFontWeight([ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'b', 'strong' ])
                : {},
        };
    }
}
