import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Label } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { IAddTopicsModal } from './models';
import { showToast, VocModal } from '/components';

export const AddTopicsModal = ({
    modalIsOpen,
    onClose,
    onSubmit,
}: IAddTopicsModal) => {
    const dispatch = useDispatch();
    const [ value, setValue ] = useState('');

    const getModalBody = () => {
        return (
            <div>
                <Label>Topics</Label>
                <Input onChange={ e => setValue(e.target.value) } type='textarea' value={ value }/>
            </div>
        );
    };

    const handleSubmit = () => {
        const isOneInvalid = value.split(',').some(topic => topic.trim().length > 100);

        if(isOneInvalid) {
            dispatch(showToast({ text: `Topic title length must be less than 100 symbols`, type: 'error' }));

            return;
        }

        const valuesForSubmit = value
            .trim()
            .split(',')
            .filter(topic => topic)
            .reduce((topicsAcc, currentTopic) => ({
                ...topicsAcc,
                [currentTopic.trim()]: 1,
            }), {});

        onSubmit(valuesForSubmit);

        onClose();
    };

    const getFooter = () => {
        return (
            <div>
                <Button outline onClick={ onClose } color='white'>
                    Cancel
                </Button>
                <Button
                    disabled={ !value.trim() }
                    onClick={ handleSubmit }
                    color='primary'
                >
                    <FontAwesomeIcon icon='check'/>
                    <span> Save</span>
                </Button>
            </div>
        );
    };

    const onClosedHandler = () => {
        setValue('');
    };

    return (
        <VocModal
            toggle={ onClose }
            onClosed={ onClosedHandler }
            header='Add topics'
            modalClassName='modal-scrollable'
            isOpen={ modalIsOpen }
            size='md'
            footer={ getFooter() }
        >
            { getModalBody() }
        </VocModal>
    );
};
