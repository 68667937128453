import { VocAIServices } from '/ai/services';
import { createSelector } from 'reselect';

const topicSetsSelector = state => state.VocAi.topicSets;

export const _getTopicSets = createSelector([ topicSetsSelector ], topicSets => ({
    ...topicSets,
    data: topicSets.data?.map(topicSet => ({
        id: topicSet.id,
        title: topicSet.title,
        languages: Object.keys(topicSet.languages).join(', '),
        sources: topicSet.sources.map(source => source.title)?.join(', '),
        actions: {
            id: topicSet.id,
            currentUserPermission: topicSet.currentUserPermission,
            topicSet: {
                ...topicSet,
                label: topicSet.title,
                value: topicSet.id,
                categoriesCount: VocAIServices.getCategoriesCount(topicSet.languages),
            },
        },
    })),
}));
