import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { HelperService } from '/services';

const searchTypeDebouncer = HelperService.debounce(({ value, onSearchType }) => {
    onSearchType(value);
}, 300);

export const VocInputSearch = ({
    onSearchType,
    className,
}) => {
    const [ searchValue, setSearchValue ] = useState("");

    const searchHandler = ({ target }) => {
        setSearchValue(target.value);
        searchTypeDebouncer({
            value: target.value,
            onSearchType,
        });
    };

    return (
        <div>
            <Input
                placeholder={ 'Search' }
                className={ className }
                type={ 'text' }
                value={ searchValue }
                onChange={ searchHandler }/>
        </div>
    );
};

VocInputSearch.defaultProps = {
    className: 'export-results__toolbar-search',
};
