import React, { useEffect, useState } from 'react';
import {
    DndContext, DragOverlay, KeyboardSensor,
    PointerSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';

import { ELabelType, filterLabel, TLabelPopoverData } from '/visual/scenes/Dashboard/components/Gadget/models';
import { LabelModal } from '../LabelModal';
import { ChartLabelItem } from './ChartLabelItem';
import { IChartLabelsList } from './models';

import styles from './style.module.scss';

export const ChartLabelsSortableList = ({
    dragDisable = false,
    labels,
    longLabel,
    gadgetId = '',
    chartType,
    updateSelectedLabel,
    setColorPickerData,
    onApplyLabelModal,
    onSortEnd,
    tickData,
}: IChartLabelsList) => {
    const [ activeId, setActiveId ] = useState(null);
    const [ items, setItems ] = useState<filterLabel[]>([]);
    const [ legendData, setLegendData ] = useState<TLabelPopoverData | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const onMouseEnter = (id: string) => {
        updateSelectedLabel(id);
    };

    const onMouseLeave = () => {
        updateSelectedLabel(null);
    };

    useEffect(() => {
        setLegendData(null);
    }, [ tickData ]);

    const handleDragStart = (event: { active: { id: React.SetStateAction<null>; }; }) => setActiveId(event.active.id);

    const handleDragOver = (event: { active: any; over: any; }) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            setTimeout(() => {
                setItems(items => {
                    const oldIndex = items.findIndex(i => i.customId === active.id);
                    const newIndex = items.findIndex(i => i.customId === over.id);

                    return arrayMove(items, oldIndex, newIndex);
                });
            }, 0);
        }
    };

    const handleDragEnd = () => {
        setActiveId(null);
        onSortEnd?.(items.map(item => item.id));
    };

    const onColorPickerOpen = (data: filterLabel) => {
        setLegendData(null);

        setColorPickerData({
            open: true,
            coords: data.coords,
            target: longLabel ? `${chartType}_colorPicker_${gadgetId}` : data.target,
            color: data.color,
            elementId: data.id,
        });
    };

    const onCLickLabel = (target: string, value: string, barId: string, coords: any) => {
        setLegendData(null);
        setColorPickerData(null);

        setTimeout(() => {
            setLegendData({
                ...legendData,
                open: true,
                target: longLabel ? `${chartType}_${ ELabelType.LEGEND }_${gadgetId}` : target,
                id: barId,
                value,
                type: ELabelType.LEGEND,
                coords,
            });
        }, 100);
    };

    const onApplyLabelModalDataHandler = (inputValue: TLabelPopoverData['value']) => {
        if (legendData) {
            onApplyLabelModal({
                id: legendData.id,
                value: inputValue,
                type: legendData.type,
            });
        }
    };

    useEffect(() => {
        labels?.length && setItems(labels);
    }, [ labels ]);

    return (
        <DndContext
            sensors={ sensors }
            onDragStart={ handleDragStart }
            onDragOver={ handleDragOver }
            onDragEnd={ handleDragEnd }
            onDragCancel={ handleDragEnd }
        >
            <SortableContext items={ items.map(i => i.customId) } strategy={ () => {} }>
                <div className={ styles.filterButtons }>
                    {
                        items.map(itemData => (
                            <ChartLabelItem
                                isDisabled={ dragDisable }
                                isActive={ activeId === itemData.customId }
                                key={ itemData.customId }
                                labelData={ itemData }
                                longLabel={ longLabel }
                                gadgetId={ gadgetId }
                                onColorPickerOpen={ onColorPickerOpen }
                                onMouseEnter={ onMouseEnter }
                                onMouseLeave={ onMouseLeave }
                                onCLickLabel={ onCLickLabel }
                            />
                        ))
                    }

                    <LabelModal
                        isControlledPositionTooltip
                        maxLength={ 250 }
                        labelModalData={ legendData }
                        gadgetId={ gadgetId }
                        chartType={ chartType }
                        labelType={ ELabelType.LEGEND }
                        setLabelModalData={ setLegendData }
                        onApplyLabelModalData={ onApplyLabelModalDataHandler }
                    />
                </div>
            </SortableContext>
            {
                createPortal(
                    <DragOverlay>
                        {
                            activeId ? (
                                <ChartLabelItem
                                    isDisabled={ dragDisable }
                                    labelData={ items.find(item => item.customId === activeId) }
                                    longLabel={ longLabel }
                                    gadgetId={ gadgetId }
                                    onColorPickerOpen={ onColorPickerOpen }
                                    onMouseEnter={ onMouseEnter }
                                    onMouseLeave={ onMouseLeave }
                                    onCLickLabel={ onCLickLabel }
                                    isOverlay
                                />
                            ) : null
                        }
                    </DragOverlay>,
                    document.querySelector('body') as Element,
                )
            }
        </DndContext>
    );
};
