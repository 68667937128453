import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VocModal, Preloader, FormFieldItem } from '/components';

export class RenameSurveyModal extends Component {
    state = {
        newName: this.props.name,
    };

    iApplyDisabled() {
        return this.state.newName === '' || this.props.loading;
    }

    @autobind
    onApplyClick() {
        this.props.actionHandler(this.state.newName);
    }

    getModalFooter() {
        return (
            <div>
                <Button onClick={ this.onHideHandler } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button onClick={ this.onApplyClick }
                    disabled={ this.iApplyDisabled() }
                    color="primary">
                    <FontAwesomeIcon icon='check'/>
                    <span> Rename survey</span>
                </Button>
            </div>
        );
    }

    @autobind
    onChangeName({ target }) {
        let newName = target.value;

        if(newName.indexOf(' ') === 0) {
            newName = newName.trim();
        }

        this.setState({ newName });
    }

    getModalBody() {
        return this.props.loading
            ? <Preloader/>
            : (
                <FormFieldItem
                    value={ this.state.newName }
                    label={ 'New name' }
                    type={ 'text' }
                    handleChange={ this.onChangeName }
                />
            );
    }

    @autobind
    onHideHandler() {
        const { toggle, name } = this.props;

        this.setState({ newName: name });
        toggle();
    }

    render() {
        const { isOpen, toggle } = this.props;

        return (
            <VocModal
                isOpen={ isOpen }
                onClosed={ this.onHideHandler }
                header={ 'Rename survey' }
                toggle={ toggle }
                footer={ this.getModalFooter() }
                modalClassName={ 'voc-survey-modal' }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}

RenameSurveyModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    name: PropTypes.string,
    actionHandler: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

RenameSurveyModal.defaultProps = {
    name: '',
};
