import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'markdown-to-jsx';
import cx from 'classnames';

import { TooltipWrapper, CollapseSection, showToast } from '/components';
import { userRightsSelector } from '/modules/selectors';
import { TContentList, TContentListItem } from '/query/models';
import { chartTypesSelector } from '/query/modules/query.selectors';
import { InsightNarratorService } from '/query/services';
import { IInsightContent } from './models';

import styles from './style.module.scss';

export const InsightContent = ({ data, chartType }: IInsightContent) => {
    const [ contentList, setContentList ] = useState<TContentList>([]);
    const isInsightNarratorTab = chartType === 'insightNarrator';
    const hideCopy = contentList.length === 0
        || isInsightNarratorTab
        ? contentList.every(({ content }) => !content)
        : contentList.some(({ loading, typeOfType, content }) => typeOfType === chartType && (loading || !content));
    const chartTypeList = useSelector(chartTypesSelector(chartType));
    const { hasMineInsightAccess } = useSelector(userRightsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        const newContent = Object
            .keys(data)
            .reduce((acc, key) => {
                    const { loading, timestamp, data: content, error } = data[key];
                    const header = chartTypeList.find(type => type.value === key)?.insightNarratorLabel || '';

                return isInsightNarratorTab || key === chartType
                    ? [ ...acc, { header, content, loading, timestamp, typeOfType: key, error }]
                    : acc;
            }, [] as TContentList);

        setContentList(newContent);
    }, [ data, chartType ]);

    const handleCopy = () =>
        InsightNarratorService.copyContent({
            contentList,
            onCopySuccess: () => dispatch(showToast({ text: 'Data successfully copied', type: 'success' })),
            onCopyFailed: () => dispatch(showToast({ text: 'Could not copy data', type: 'error' })),
        });

    const getContent = (
        header: TContentListItem['header'],
        content: string,
        timestamp: TContentListItem['timestamp'],
    ) => (
        <div className={ styles.content }>
            <Markdown
                options={{ overrides: InsightNarratorService.overrideMarkdown({ forFontWeight: true }) }}
                id={ InsightNarratorService.getContentId(header, 'content') }
            >
                { content }
            </Markdown>
            <div className={ styles.timestamp }>
                <span>{timestamp}</span>
            </div>
        </div>
    );

    const getEmptyOrError = (error: TContentListItem['error']) => {
        const infoText = error || 'There is no data to display';

        return <div className={ cx(styles.emptyData, { [styles.centered]: !isInsightNarratorTab }) }>
            <p className={ styles.emptyDataTitle }> { infoText } </p>
            {
                !error
                    ? <TooltipWrapper
                        force
                        value='Configure prompt and click on "Run Narrator" to get Insight'
                        className='mine-info-box__for-visual'
                    >
                        <div className={ styles.emptyDataInfo }>
                            <FontAwesomeIcon icon="info-circle"/>
                        </div>
                    </TooltipWrapper>
                    : null
            }
        </div>;
    };

    return (
        <div className={ styles.insightContainer }>
            {
                !hasMineInsightAccess
                && <Alert
                    color='warning'
                    className={ cx(styles.warningBlock, { [styles.withRightOffset]: isInsightNarratorTab }) }
                >
                    <span>
                        Insight Narrator is a paid feature and is not activated for your user/instance. Please reach out to our sales team or customer success manager.
                    </span>
                </Alert>
            }
            <div className={ styles.insightContent }>
                {
                    contentList.map(({ header, content, loading, timestamp, error }) => {
                        return <CollapseSection
                            className={ cx(styles.collapseSection, {
                                [styles.hideHeader]: !isInsightNarratorTab,
                                [styles.withError]: Boolean(error),
                            }) }
                            collapseDisable={ loading }
                            collapse={ !isInsightNarratorTab }
                            isCollapseLoading={ loading }
                            loader={ true }
                            title={ header }
                            key={ InsightNarratorService.getContentId(header) }
                            titleAddon={
                                <>
                                    {
                                        loading
                                            ? <FontAwesomeIcon
                                                className={ styles.insightPartLoader }
                                                icon='circle-notch'
                                                spin
                                            />
                                            : <FontAwesomeIcon
                                                className={ cx(styles.contentMark, { [styles.exist]: content }) }
                                                icon={ 'circle' }
                                                color={ 'yellow' }
                                            />
                                    }
                                </>
                            }
                        >
                            {
                                content
                                    ? getContent(header, content, timestamp)
                                    : getEmptyOrError(error)
                            }
                        </CollapseSection>;
                    })
                }
            </div>

            {
                !hideCopy
                && <button
                    className={ styles.copyButton }
                    onClick={ handleCopy }
                >
                    <FontAwesomeIcon icon='copy' />
                </button>
            }
        </div>
    );
};
