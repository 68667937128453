import i18n, { hasLoadedNamespace, setDefaultNamespace } from 'i18next';
import { initReactI18next } from 'react-i18next';

export class I18nService {
    static fallbackLanguage = 'en';
    static fallbackNS = 'common';

    static getActualLanguage() {
        return i18n.language;
    }

    static changeDefaultNS(pathname: string) {
        const module = pathname?.split('/')?.[1];

        if (i18n.isInitialized && i18n.options.defaultNS !== module) {
            const fallbackNS = hasLoadedNamespace(I18nService.fallbackNS)
                ? I18nService.fallbackNS
                : false;
            const actualDefaultNS = hasLoadedNamespace(module)
                ? module
                : fallbackNS;

            // change default namespace
            if (actualDefaultNS) {
                setDefaultNamespace(actualDefaultNS);
            }

            // change fallback namespace
            if (i18n.options.fallbackNS !== fallbackNS) {
                i18n.options.fallbackNS = fallbackNS;
            }
        }
    }

    static async loadDictionary(language?: string) {
        try {
            const dictionary = await import(`../../dictionaries/${ language }.json`);

            return dictionary.default;
        } catch (error) {
            console.warn(`Dictionary file for language ${language} not found`);
            return {};
        }
    }

    static async changeLanguage(language: string) {
        const newDictionary = await I18nService.loadDictionary(language);

        // add new resources to i18n for each namespace
        Object.keys(newDictionary).forEach(ns => {
            i18n.addResourceBundle(language, ns, newDictionary[ns], true, true);
        });

        // change the language
        await i18n.changeLanguage(language);
    }

    static async init() {
        const lng = I18nService.fallbackLanguage;
        // get default dictionary
        const defaultDictionary = await I18nService.loadDictionary(lng);
        // string or array of namespaces to load
        const supportedNamespaces = Object.keys(defaultDictionary);

        i18n.on('initialized', () => {
            const { pathname } = window.location;

            I18nService.changeDefaultNS(pathname);
        });

        return i18n
            .use(initReactI18next)
            .init({
                // set true for show full config after initialization in console
                debug: process.env.NODE_ENV === 'development',
                resources: { [lng]: defaultDictionary },
                lng,
                fallbackLng: lng,
                defaultNS: false,
                fallbackNS: false,
                ns: supportedNamespaces,
                interpolation: {
                    escapeValue: false,
                },
            });
    }
}
