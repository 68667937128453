export class SourceService {
    static getValueById({ attribute, id }) {
        if (attribute) {
            const option = attribute.options.find(option => {
                if ([ null, '' ].includes(id)) {
                    return [ null, '' ].includes(option.id);
                }
                return option.id === id;
            });

            return option || { name: id };
        }

        return { name: id };
    }

    static isNumericAttribute(attr) {
        return [ 'SCALABLE', 'NPS', 'NUMERIC' ].includes(attr.type);
    }
}
