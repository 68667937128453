import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import {
    UnitsFilter,
    DeletedSurveysFilter,
    PerPageFilter,
    SearchFilter,
} from './components/';

export class SurveysListHeader extends Component {
    render() {
        const {
            unitsList,
            unit,
            applyFilter,
            unitLabel,
            search,
            hideDeletedSurveys,
            itemsPerPage,
            MAX_LIMIT,
        } = this.props;

        return (
            <div className='surveys-list-modern-filters '>
                <Row>
                    {
                        unitsList?.length
                            ? <>
                                <Col md={ '2' }>
                                    <UnitsFilter
                                        unitsList={ unitsList }
                                        unit={ unit }
                                        applyFilter={ applyFilter }
                                        unitLabel={ unitLabel }
                                    />
                                </Col>
                                <Col md={ '6' } className={ 'filter-search__wrapper' }>
                                    <SearchFilter
                                        applyFilter={ applyFilter } search={ search }/>
                                </Col>
                                <Col md={ '4' } className={ 'filters-right__wrapper' }>
                                    <div className={ 'surveys-list-modern-filters__page-options' }>
                                        <DeletedSurveysFilter
                                            applyFilter={ applyFilter }
                                            hideDeletedSurveys={ hideDeletedSurveys }
                                        />
                                        <PerPageFilter
                                            itemsPerPage={ itemsPerPage }
                                            applyFilter={ applyFilter }
                                            MAX_LIMIT={ MAX_LIMIT }
                                        />
                                    </div>
                                </Col>
                            </> : null
                    }
                </Row>
            </div>
        );
    }
}

SurveysListHeader.propTypes = {
    applyFilter: PropTypes.func,
    unitLabel: PropTypes.any,
    unit: PropTypes.any,
    unitsList: PropTypes.any,
    MAX_LIMIT: PropTypes.any,
    itemsPerPage: PropTypes.any,
};

SurveysListHeader.defaultProps = {};
