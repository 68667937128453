import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { VocTableV2, TableFilter } from '/components';
import { ThemesActionsFormatter } from '../';
import { HelperService } from '/services';

import { NameFormatter, TagsFormatter } from "./components";

export class ThemesTable extends Component {
    settings = [
        {
            field: 'name',
            title: 'Name',
            formatter: this.nameFormatter,
            sort: true,
        },
        {
            field: 'tags',
            title: 'Tags',
            style: {
                width: '250px',
            },
            formatter: this.tagsFormatter,
            filter: this.tagsFilter,
            sort: false,
        },
        {
            field: 'creation',
            title: 'Created date',
            formatter: 'date',
            sort: true,
            style: {
                width: '140px',
                textAlign: 'left',
            },
            mask: HelperService.defaultDateMask,
            currentDateFormat: 'DD/MM/YYYY',
        },
        {
            field: 'last_interaction',
            title: 'Modified date',
            formatter: 'date',
            sort: true,
            style: {
                width: '140px',
                textAlign: 'left',
            },
            mask: HelperService.defaultDateMask,
            currentDateFormat: 'DD/MM/YYYY',
        },
        {
            field: 'actions',
            title: 'Actions',
            formatter: this.tableActionsFormatter,
            style: {
                width: '150px',
                textAlign: 'center',
            },
        },
    ];

    @autobind
    nameFormatter(cell, obj) {
        return <NameFormatter
            { ...cell }
            onClickDescription={ this.props.openDetails }
            theme={ obj.theme }
        />;
    }

    @autobind
    tagsFormatter(cell) {
        return <TagsFormatter tags={ cell }/>;
    }

    @autobind
    tagsFilter() {
        const { targetFilter, isOpenPopoverFilter, togglePopover, tags, getTags, apply } = this.props;

        return (
            <TableFilter
                targetFilter={ targetFilter }
                isOpenPopover={ isOpenPopoverFilter }
                togglePopover={ togglePopover }
                filterData={ tags }
                apply={ apply }
                getFilterData={ getTags }
                name={ 'tags' }
            />
        );
    }

    @autobind
    tableActionsFormatter(cell, obj) {
        const { openConfirmDelete, openConfirmCopy, openDetails, showToast, initRunnerDemo, isPermitEdit } = this.props;

        return (
            <ThemesActionsFormatter
                openConfirmDelete={ openConfirmDelete }
                openConfirmCopy={ openConfirmCopy }
                openDetails={ openDetails }
                initRunnerDemo={ initRunnerDemo }
                id={ cell }
                theme={ obj.theme }
                showToast={ showToast }
                isPermitEdit={ isPermitEdit }
            />
        );
    }

    render() {
        const {
            filters,
            options,
            data: {
                count,
                loading,
                results,
            },
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                options={ options }
                filters={ filters }
                data={ results }
                total={ count }
                loading={ loading }
            />
        );
    }
}
