import React from 'react';
import PropTypes from 'prop-types';

import { VocProgressBar } from '/components/';

export const VocTableProgressFormatter = ({ field }) => {
    return (
        <VocProgressBar
            processed={ field.processedItemsCount }
            cancelled={ field.cancelledItemsCount }
            failed={ field.failedItemsCount }
            pending={ field.progressItemsCount }
            total={ field.totalItemsCount }
        />
    );
};

VocTableProgressFormatter.propTypes = {
    field: PropTypes.object,
};


