import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import cx from 'classnames';

import { HelperService } from '/services';

import './VocFormDatePicker.scss';

export const VocFormDatePicker = ({
    input,
    label,
    className,
    wrapClassName,
    showMonthDropdown,
    showYearDropdown,
    defaultDate,
    disabled,
    minDate,
    customDateFormat,
    customDatePlaceholder,
}) => {
    const onBlurHandler = () => {
        const inputValue = input.value || defaultDate;

        input.onBlur && input.onBlur(inputValue);
    };

    const onChangeHandler = value => {
        const callback = input ? input.onChange : null;

        if (!!callback && typeof callback === 'function') {
            input.onChange(value);
        }
    };

    return (
        <div className={ `${ wrapClassName } app-form-control__container` }>
            {
                label && <Label>{ label }</Label>
            }
            <DatePicker
                disabled={ disabled }
                id={ input.name }
                className={ cx(className) }
                selected={ input.value }
                dateFormat={ customDateFormat || HelperService.datePickerMask }
                showMonthDropdown={ showMonthDropdown }
                showYearDropdown={ showYearDropdown }
                placeholderText={ customDatePlaceholder || HelperService.defaultDateMask }
                popperClassName={ cx('popperClassName', 'voc-datepicker') }
                yearDropdownItemNumber={ 8 }
                onChange={ onChangeHandler }
                onBlur={ onBlurHandler }
                minDate={ minDate }
                portalId={ 'root-portal' }
            />
        </div>
    );
};

VocFormDatePicker.propTypes = {
    className: PropTypes.string,
    wrapClassName: PropTypes.string,
    label: PropTypes.string,
    onBlur: PropTypes.func,
};

VocFormDatePicker.defaultProps = {
    wrapClassName: '',
    className: 'form-control',
    defaultDate: new Date(),
};
