export default {
    "settings": {
        "DIGI_SURVEY_IS_STEPBYSTEP": false,
        "DIGI_ACTIVATE_BY_BUTTON": false,
        "DIGI_PROBABILITY_OF_APPEARANCE": 100,
        "DIGI_QUIET_TIME": 0,
        "DIGI_CUSTOMER_EXT_ID_WHITELISTED": [],
        "DIGI_DONT_SHOW_SURVEY_FOR_REFUSERS_PERIOD": 9,
        "DIGI_ABANDONMENT_TRIGGER_DELAY": 10,
        "DIGI_TRIGGERED_BUTTON_ID": "",
        "DIGI_SHOW_LEAVE_POPUP": false,
        "DIGI_SEND_INITIAL_URL": false,
        "DIGI_SURVEY_IS_STEPBYSTEP_GROUPED": false,
        "DIGI_CHAT_MODE": false,
        "DIGI_SHOW_NAVBAR": false,
        "DIGI_RUNNER_URL": "/",
        "DIGI_TRIGGERED_BUTTON_EVENT": null,
        "DIGI_VISITS_COUNTER": null,
        "DIGI_NUMBER_OF_PAGES_VISITED": null,
        "DIGI_UNITE_TRIGGERS": null,
        "DIGI_SESSION_TIMEOUT": null,
        "DIGI_PAGE_TIMEOUT": null,
        "DIGI_DONT_SHOW_SURVEY_FOR_REFUSERS": null,
        "DIGI_NEXT_QUESTION_DELAY": null,
        "DIGI_POPUP_WINDOW_HEIGHT": null,
        "DIGI_POPUP_WINDOW_WIDTH": null,
        "DIGI_POPUP_BG_COLOR": null,
        "DIGI_POPUP_FONT_FAMILY": null,
        "DIGI_POPUP_QUESTION_BG_COLOR": null,
        "DIGI_POPUP_QUESTION_FONT_COLOR": null,
        "DIGI_POPUP_INDENT": null,
        "DIGI_POPUP_HEADER_BG_COLOR": null,
        "DIGI_POPUP_HEADER_FONT_COLOR": null,
        "DIGI_POPUP_BUTTON_BG_COLOR": null,
        "DIGI_POPUP_BUTTON_FONT_COLOR": null,
        "DIGI_RUNNER_FONT_SIZE": null,
        "DIGI_THEME_CSS_CUSTOM": null,
        "DIGI_THEME_CSS_EXTERNAL": null,
        "DIGI_BUTTON_GO_TO_PREVIOUS_TEXT": "",
        "DIGI_BUTTON_START_AGAIN_TEXT": "",
        "DIGI_BUTTON_NEXT_QUESTION_TEXT": "",
        "DIGI_SURVEY_STATIC_LINK": null,
        "DIGI_TRIGGERED_BUTTON_TEXT": null,
        "DIGI_BUTTON_SUBMIT_TEXT": null,
        "DIGI_THANKS_MESSAGE": null,
        "DIGI_BUTTON_FINISH_TEXT": null,
        "DIGI_POPUP_TITLE": null,
        "DIGI_THEME_CSS_CUSTOM_LANGUAGE": null,
        "DIGI_THEME_CSS_EXTERNAL_LANGUAGE": null,
    },
    "hash": "dOm86AyNNGzVKvrhJk1AI9HV62AoR0BY",
    "questions": [
        {
            "id": 1675,
            "type": "QuestionOpenDIGI",
            "language": {
                "text": "Lorem ipsum dolor sit amet",
                "error": "Error text",
            },
            "nested_level": 1,
            "options": {
                "required": "0",
            },
            "answers": [],
        },
        {
            "id": 1676,
            "type": "QuestionScalableDIGI",
            "language": {
                "text": "Duis aute irure dolor in reprehenderit",
                "error": "Error tex",
            },
            "nested_level": 1,
            "options": {
                "required": "1",
                "number": "10",
                "start_from_0": "0",
                "render_type": "star rating",
            },
            "answers": [],
        },
        {
            "id": 1677,
            "type": "QuestionConfigurableDIGI",
            "language": {
                "text": "Nemo enim ipsam voluptatem",
                "error": "Error tex",
            },
            "nested_level": 1,
            "options": {
                "render_type": "line",
                "required": "1",
                "min": "2",
                "max": "2",
                "is_multichoice": "1",
                "last_is_open": "multiline",
            },
            "answers": [
                {
                    "id": 869,
                    "value": "1",
                    "name": "answer 1",
                },
                {
                    "id": 870,
                    "value": "2",
                    "name": "answer 2",
                },
                {
                    "id": 871,
                    "value": "3",
                    "name": "answer 3",
                },
            ],
        },
        {
            "id": 1678,
            "type": "QuestionFinishDIGI",
            "language": {
                // eslint-disable-next-line max-len
                "text": "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment",
                "error": "Error tex",
            },
            "nested_level": 1,
            "options": {},
            "answers": [],
        },
    ],
    "finish": false,
    "state": "Created",
};
