import React, { useEffect, useState, ChangeEvent, KeyboardEvent, FC } from 'react';
import { Input, Row, Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { IProps } from './models';
import { VocModal } from '/components';

import styles from './style.module.scss';

export const EditTitleModal: FC<IProps> = ({
    close,
    show,
    source,
    updateUploadTitle
}) => {
    const { t } = useTranslation();
    const [ title, setTitle ] = useState<string>('');
    const [ sending, setSending ] = useState<boolean>(false);

    useEffect(() => {
        setTitle(show ? source?.title ?? '' : '');
        setSending(false);
    }, [ source?.title, show ]);

    const onApply = (): void => {
        const titleTrim = title.trim();

        if(titleTrim) {
            setSending(true);
            updateUploadTitle(source!.id, titleTrim).promise.then(
                () => close(),
                () => setSending(false)
            );
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        let newName = event.target.value;

        if (newName.indexOf(' ') === 0) {
            newName = newName.trim();
        }

        setTitle(newName);
    };

    const onPressEnter = (event: KeyboardEvent<HTMLInputElement>): void => {
        event.key === 'Enter' && onApply();
    };

    const getModalBody = () => (
        <div className={ styles.modalWrapper }>
            <Row className={ styles.fieldWrapper }>
                <Label className={ styles.fieldLabel }>
                    <Label htmlFor='title_field'>
                        <span>
                            { t('field.title') }
                            &nbsp;
                            <span className='star'>*</span>
                        </span>
                    </Label>
                    <Input
                        autoFocus
                        id='title_field'
                        value={ title }
                        onChange={ onChange }
                        onKeyUp={ onPressEnter }
                    />
                </Label>
            </Row>
        </div>
    );

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color='white'
                onClick={ close }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color='primary'
                disabled={ !title || sending }
                onClick={ onApply }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                { t('save') }
            </Button>
        </div>
    );

    return (
        <VocModal
            isOpen={ show }
            size='ms'
            header={ t('editUploadTitle') }
            modalClassName='modal-scrollable'
            footer={ getModalFooter() }
            toggle={ close }
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};
