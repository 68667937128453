import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { GadgetsGrid } from '/visual/scenes/Dashboard/components/GadgetsGrid';
import {
    setGadgetData,
    toggleModal,
    DashboardModalType,
    copyGadget,
} from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { useLocalState } from '/visual/customHooks';
import { ICopyModel } from '/visual/models';
import { HelperService } from '/services';
import { copyGadgetType, ReducedGadgetType } from '/visual/scenes/Dashboard/models';

import styles from './style.module.scss';

interface IGadgetsWrapperProps {
    isEditable: boolean;
    handleCreateGadget: () => void;
}

export const GadgetsWrapper = ({
    isEditable,
    handleCreateGadget,
}: IGadgetsWrapperProps) => {
    const { t } = useTranslation();
    const {
        gadgets,
        wildGadgets,
        pages,
        gadgetPending,
        onChangeGadgetsPosition,
        onMoveToSave,
    } = useLocalState();

    const dispatch = useDispatch();

    const openConfigureGadgetModal = (gadget: ReducedGadgetType) => {
        if(gadget.type === "static_area") {
            dispatch(toggleModal({ type: DashboardModalType.editStaticAreaCode, gadgetId: gadget.id }));
        } else {
            dispatch(setGadgetData(gadget.id));
            dispatch(toggleModal({ type: DashboardModalType.configureGadget, gadgetId: gadget.id }));
        }
    };

    const openGadgetDescriptionModal = (gadget: ReducedGadgetType) => {
        dispatch(toggleModal({ type: DashboardModalType.descriptionGadget, gadgetId: gadget.id }));
    };

    const openDeleteGadgetModal = (gadget: ReducedGadgetType, gadgetType?: string | null) => {
        dispatch(toggleModal({ type: DashboardModalType.deleteGadget, gadgetId: gadget.id, gadgetType }));
    };

    const copyGadgetHandler = async (gadget: copyGadgetType | string) => {
        const copyWithCoordinates = typeof gadget !== 'string';
        const id = gadget?.gadgetId || gadget;
        const model: ICopyModel = copyWithCoordinates
            ? {
                id,
                message: t('copyGadgetToPage'),
                pageId: gadget.page,
                coordinate: HelperService.pick(gadget, [ 'x', 'y', 'w', 'h' ]),
            }
            : {
                id,
                message: t('copyGadgetToTemporaryBox'),
            };

        return dispatch(copyGadget(model));
    };

    return (
        <div className={ styles.dashboardBody }>
            <div>
                <GadgetsGrid
                    gadgets={ gadgets }
                    pages={ pages }
                    wildGadgets={ wildGadgets }
                    isEditable={ isEditable }
                    gadgetPending={ gadgetPending }
                    onChangeGadgetsPosition={ onChangeGadgetsPosition }
                    openGadgetModal={ openConfigureGadgetModal }
                    openGadgetDescriptionModal={ openGadgetDescriptionModal }
                    openDeleteGadgetModal={ openDeleteGadgetModal }
                    onMoveToSave={ onMoveToSave }
                    onCopyGadget={ copyGadgetHandler }
                    handleCreateGadget={ handleCreateGadget }
                />
            </div>
        </div>
    );
};
