import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TableRightsItem = ({
    usersGroup,
    title,
}) => {
    return usersGroup.length ? (
        <div className={ 'shared-popover-role__item' }>
            <div className={ 'shared-popover-role__column shared-popover-role-column_title' }>
                { title }:
            </div>
            <ol className={ 'shared-popover-role__column' }>
                {
                    usersGroup.map((user, inx) => (
                        <li key={ `${ title }-${ inx }` }>
                            { user.icon ? <FontAwesomeIcon icon={ user.icon }/> : null }
                            <div title={ user.label }>
                                { user.label }
                            </div>
                        </li>
                    ))
                }
            </ol>
        </div>
    ) : '';
};
