import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { VocModal } from '/components';
import { deleteDashboard, getDashboards, updateFilters } from '/visual/modules/VocVisual.modules';
import { dashboardsSelector, _getDashboard } from '/visual/modules/VocVisual.selectors';
import { IDeleteDashboardModal } from './models';

import style from './style.module.scss';

export const DeleteDashboardModal = ({
    show,
    deleteDashboardId,
    toggle,
    cleanupModal,
}: IDeleteDashboardModal) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { push, location: { pathname } } = useHistory();
    const { dashboardId } = useParams<{ dashboardId: string }>();
    const { dashboards: { filters } } = useSelector(dashboardsSelector);
    const { parentDashboardId } = useSelector(_getDashboard);
    const onDeleteDashboard = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        deleteDashboardId
        && dispatch(deleteDashboard(deleteDashboardId)).promise.then(() => {
            if(!parentDashboardId) {
                const filtersModel = {
                    ...filters,
                    page: 1,
                };

                dispatch(updateFilters(filtersModel));
                dispatch(getDashboards(filtersModel));
            }

            // delete dashboardId from url, only when delete process finished
            // Issue: https://sandsiv.atlassian.net/browse/VOC-12976
            dashboardId
            && push({
                pathname: pathname.replace(`/${dashboardId}`, parentDashboardId ? `/${parentDashboardId}` : ''),
                state: { skipConfirm: true },
            });
        });

        toggle();
    };

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color='white'
                onClick={ toggle }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color='primary'
                onClick={ onDeleteDashboard }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                { t('confirm') }
            </Button>
        </div>
    );

    return (
        <VocModal
            isOpen={ show }
            modalClassName='modal-scrollable'
            header={ t('deleteDashboard') }
            size='ms'
            footer={ getModalFooter() }
            toggle={ toggle }
            onClosed={ cleanupModal }
        >
            <p className={ style.modalText }>
                { t('removeDashboardDescription') }
            </p>
        </VocModal>
    );
};
