/*
*  @permission: ['none', 'view', 'edit', 'full', 'owner']
* */
export class UsersRoleService {
    static getUsersByRole(users, currentUserId, { allUser, allGroups }) {
        const commonUsers = [
            ...allUser.userList,
            ...allGroups.groupList,
        ];

        const allUsers = users.map(({ ownerId, ownerType, permission }) => {
            const commonUser = commonUsers.find(
                commonUser => ownerType === "group"
                    ? commonUser.id === ownerId
                    : commonUser.ssoId === ownerId,
            );
            let isCurrentUser = false, name = '', icon, model = {};

            if (commonUser) {
                isCurrentUser = commonUser.ssoId === currentUserId;
                name = commonUser.fullName ? commonUser.fullName : commonUser.name;
                icon = ownerType === 'group' ? 'users' : 'user';

                model = {
                    value: ownerId,
                    permission,
                    ownerType,
                    icon: permission === "owner" ? 'user-cog' : icon,
                    label: isCurrentUser ? name + ' (You)' : name,
                };
            }

            return model;
        });

        const usersByRole = allUsers.reduce((acc, user) => {
            const composed = { ...acc };

            user.permission === "owner" && composed.Owner.push({ ...user });
            user.permission === "full" && composed.Full.push({ ...user });
            user.permission === "edit" && composed.Editors.push({ ...user });
            user.permission === "view" && composed.Viewer.push({ ...user });
            return { ...composed };
        }, { Owner: [], Full: [], Editors: [], Viewer: [] });

        return {
            allUsers,
            usersByRole,
        };
    }

    static getRightsForRecoded = (isSupportUser, permission, currentUserFirstName, allUsersData, segmentatorUserId) => {
        const { isPermitView, isPermitEdit } = UsersRoleService.getRights(isSupportUser, permission);
        const findUser = allUsersData.allUser.userList.find(user => user.firstName === currentUserFirstName);
        const isRecodedOwner = findUser?.ssoId === segmentatorUserId;

        return {
            isPermitView: permission === 'view',
            isPermitEdit: isPermitView,
            isPermitDelete: !isPermitEdit ? isPermitView && isRecodedOwner : isPermitEdit,
        };
    };

    static getRightsForSourceInVirtual = (isSupportUser, permission) => {
        const { isPermitView } = UsersRoleService.getRights(isSupportUser, permission);

        return {
            isPermitEdit: isPermitView,
        };
    };

    static getRightsForVirtual = (isSupportUser, permission) => {
        const { isPermitFull, isPermitEdit, isPermitView } = UsersRoleService.getRights(isSupportUser, permission);

        return {
            isPermitViewInfoShare: isPermitView,
            isPermitShare: isPermitFull,
            isPermitDownloadSource: isPermitView,
            isPermitRecord: isPermitView,
            isPermitEdit: isPermitEdit,
            isPermitCopy: isPermitView,
            isPermitDelete: isPermitFull,
        };
    };

    static getRightsForUploads = (isSupportUser, permission) => {
        const { isPermitFull, isPermitEdit, isPermitView } = UsersRoleService.getRights(isSupportUser, permission);

        return {
            isPermitViewInfoShare: isPermitView,
            isPermitShare: isPermitFull,
            isPermitDownloadSource: isPermitView,
            isPermitCancel: isPermitEdit,
            isPermitDownloadErrors: isPermitView,
            isPermitRecord: isPermitView,
            isPermitSourceDetails: true,
            isPermitAppendFile: isPermitEdit,
            isPermitReLemmatization: isPermitEdit,
            isPermitDelete: isPermitFull,
            isPermitEdit: isPermitEdit,
        };
    };

    static getRightsForVisual = (isSupportUser, permission, hasVisualWriteAccess) => {
        const { isPermitFull, isPermitEdit, isPermitView } = UsersRoleService.getRights(isSupportUser, permission);

        return {
            isPermitViewInfoShare: isPermitView,
            isPermitView: isPermitView,
            isPermitShare: isPermitFull,
            isPermitRename: isPermitEdit,
            isPermitDefaultDashboard: isPermitEdit,
            isPermitSend: isPermitView,
            isPermitCopy: isPermitView,
            isPermitScheduler: isPermitEdit,
            isPermitPrint: isPermitView,
            isPermitDelete: isPermitFull,
            isPermitEdit: isPermitEdit,
            hasVisualWriteAccess: hasVisualWriteAccess,
        };
    };


    static getRightsForSystemUploads = (isSupportUser, permission) => {
        const { isPermitFull, isPermitView } = UsersRoleService.getRights(isSupportUser, permission);

        return {
            isPermitShare: isPermitFull,
            isPermitRecord: isPermitView,
            isPermitDownloadSource: isPermitView,
            isPermitSourceDetails: true,
        };
    };

    static getRightsForSurvey = (isSupportUser, permission) => {
        const { isPermitFull, isPermitView } = UsersRoleService.getRights(isSupportUser, permission);

        return {
            isPermitViewInfoShare: isPermitView,
            isPermitShare: isPermitFull,
            isPermitRecord: isPermitView,
            isPermitDownloadSource: isPermitView,
        };
    };

    static getRights(isSupportUser, permission) {
        const isPermitFull = [ 'full','owner' ].includes(permission) || isSupportUser;
        const isPermitEdit = permission === 'edit' || isPermitFull;
        const isPermitView = permission === 'view' || isPermitEdit;

        return {
            isPermitFull,
            isPermitEdit,
            isPermitView,
        };
    }
}
