import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { VocModal, Preloader, FormFieldItem } from '/components';
import './SourceModalInfo.scss';

export const SourceModalInfo = ({
    updateDescription,
    cleanupInfo,
    sourcesInfo,
    close,
    show,
    source,
    header,
}) => {
    const { t } = useTranslation();
    const [ description, setDescription ] = useState('');
    const [ edit, setEdit ] = useState(false);
    const [ sending, setSending ] = useState(false);

    useEffect(() => {
        sourcesInfo.activeSource?.id && setDescription(sourcesInfo.activeSource?.description);
    }, [ sourcesInfo.activeSource?.description ]);

    useEffect(() => {
        sourcesInfo.activeSource?.id && setDescription(sourcesInfo.activeSource?.description);
    }, [ sourcesInfo.activeSource ]);

    const getDashboardList = () => {
        const list = sourcesInfo?.data?.dashboards?.map((dashboard, inx, arr) =>
            <a
                href={ `${window.location.origin}/dashboard/${dashboard.id}` }
                key={ inx }
                target='_blank'
                rel='noreferrer'
            >
                { dashboard.title }{ arr.length !== ++inx ? ', ' : '' }
            </a>,
        );

        return list?.length ? list : t('noDashboards');
    };

    const getModalBody = () => {
        return !sourcesInfo.loading
            ? <>
                {
                    edit
                        ? <FormFieldItem
                            type='textarea'
                            className='info-textarea'
                            disabled={ sourcesInfo.loading }
                            isHorizontal={ false }
                            value={ description }
                            label={ t('field.description') }
                            placeholder={ t('placeholders.enterDescription') }
                            handleChange={ event => setDescription(event.target.value) }
                            textError={ t('validation.nameMustBeNoMoreThanCharacters', { charCount: 50 }) }
                        />
                        : <>
                            <p className='p-label'>
                                { t('field.description') }
                            </p>
                            <p className='p-textarea'>
                                {
                                    description
                                        ? description
                                        : t('noDescriptionProvided')
                                }
                            </p>
                        </>
                }
                <div>
                    <p className='p-label'>
                        { t('field.dashboards') }
                    </p>
                    {
                        getDashboardList()
                    }
                </div>
            </>
            : <Preloader/>;
    };

    const toggle = () => {
        close();
    };

    const handlerOnSubmit = () => {
        setSending(true);
        updateDescription(sourcesInfo.activeSource?.id, description)
            .promise.then( () => {
                setEdit(false);
                setSending(false);
            });
    };

    const getModalFooter = () => {
        return (
            edit
                ? <>
                    <Button
                        onClick={ () => {
                            setDescription(sourcesInfo.activeSource?.description);
                            setEdit(false);
                        } }
                        outline
                        color={ 'white' }
                    >
                        <FontAwesomeIcon icon='times' />
                        &nbsp;
                        <span>
                            { t('cancel') }
                        </span>
                    </Button>
                    <Button
                        onClick={ () => handlerOnSubmit() }
                        disabled={ sending }
                        color={ 'primary' }
                    >
                        <FontAwesomeIcon icon='check' />
                        &nbsp;
                        <span>
                            { t('save') }
                        </span>
                    </Button>
                </>
                : <>
                    <Button
                        onClick={ toggle }
                        outline
                        color={ 'white' }
                    >
                        <FontAwesomeIcon icon='times' />
                        &nbsp;
                        <span>
                            { t('close') }
                        </span>
                    </Button>
                    <Button
                        onClick={ () => source?.rights?.isPermitEdit && setEdit(true) }
                        disabled={ sourcesInfo.loading || !source?.rights?.isPermitEdit }
                        color={ 'primary' }
                    >
                        <FontAwesomeIcon icon='check' />
                        &nbsp;
                        <span>
                            { t('edit') }
                        </span>
                    </Button>
                </>
        );
    };

    const cleanupModal = () => {
        setDescription('');
        setEdit(false);
        setSending(false);
        cleanupInfo();
    };

    return (
        <VocModal
            isOpen={ show }
            toggle={ toggle }
            modalClassName='modal-scrollable'
            header={ header }
            footer={ getModalFooter() }
            onClosed={ cleanupModal }
            size="lg"
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};
