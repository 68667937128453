import { useEffect, useState } from 'react';

import { IChartData, BodyItem } from '/visual/scenes/Dashboard/components/Gadget/models';

export const useTableChart = (initChartData: IChartData) => {
    const { contentSettings, dataSettings } = initChartData.gadgetData;

    const [ formatData, setFormatData ] = useState({});

    const formatDataHandler = () => {
        const newChartData = JSON.parse(JSON.stringify(initChartData));

        const { chartData: { body, headers, filters, types, count } } = newChartData;
        const isPrimaryTable = Boolean(body?.type);
        const isPercent = contentSettings.relativeMode;
        const showPercent = isPercent ? '%' : '';
        const functionType = contentSettings.function === 'just_show' ? 'count' : contentSettings.function;
        const isMultipleFactTypes = dataSettings.facts?.length >= 2;
        const getColumn = (bodyItem: any, columnName = functionType) => {
            const bodyItemIndex = isPercent ? 'percent' : functionType;

            return {
                [columnName]: (bodyItem[bodyItemIndex] + showPercent)?.toString(),
            };
        };

        //Removes all spaces and square brackets from a string.
        const removeSpacesAndBrackets = (text: string): string => {
            return text.replace(/\s|[\[\]:()]/g, '');
        };

        newChartData.chartData = {
            ...newChartData.chartData,
            filters,
        };

        if(isPrimaryTable) {
            let formatBody = [];

            if(functionType === 'count' || (headers.length && dataSettings.secondGroupBy)) {
                formatBody = body.items.map((bodyItem: any) => {
                    let bodyModel = {
                        '0_first_column': bodyItem.id?.toString() || 'N/A',
                    };

                    bodyModel = {
                        ...bodyModel,
                        ...bodyItem.items && dataSettings.groupBy
                            ? bodyItem.items?.reduce((acc: any, cur: any) => {
                                const columnName = removeSpacesAndBrackets(cur?.id?.toString() || 'N/A');

                                return {
                                    ...acc,
                                    ...getColumn(cur, columnName),
                                };
                            }, {})
                            : getColumn(bodyItem),
                    };

                    return bodyModel;
                });
            } else if (functionType !== 'count' && (dataSettings.groupBy || isMultipleFactTypes)) {
                formatBody = body.items.map((bodyItem: any) => ({
                    '0_first_column': bodyItem.id?.toString() || 'N/A',
                    ...getColumn(bodyItem),
                }));
            } else {
                formatBody = [{
                    '0_first_column': dataSettings.factName,
                    ...getColumn(body),
                }];
            }

            let formatHeader = [{
                id: '0_first_column',
                data: '',
            }];

            formatHeader = headers?.length && (functionType === 'count' || dataSettings.secondGroupBy)
                ? [
                    ...formatHeader,
                    ...Object.keys(headers)
                        .map(headerKey => ({
                            id: headers[headerKey] || "N/A",
                            data: headers[headerKey] || "N/A",
                        })),
                ]
                : [
                    ...formatHeader,
                    {
                        id: functionType || "null",
                        data: functionType || "null",
                    },
                ];


            newChartData.chartData = {
                ...newChartData.chartData,
                disableReorder: true,
                disableServerSort: true,
                disableServerPagination: true,
                body: formatBody,
                headers: formatHeader,
                records: count.toString(),
            };
        } else {
            const formatBody = newChartData.chartData.body.map((bodyItem: BodyItem) => (
                Object.keys(bodyItem).reduce<BodyItem>((acc, curr) => {
                    const item = types[curr] === 'DATE' && !contentSettings.showFullDate
                        ? bodyItem[curr].split(' ')[0]
                        : bodyItem[curr];

                    acc[removeSpacesAndBrackets(curr)] = item?.toString();

                    return acc;
                }, {})
            ));

            newChartData.chartData = {
                ...newChartData.chartData,
                records: count.toString(),
                disableReorder: false,
                disableServerSort: false,
                disableServerPagination: false,
                body: formatBody,
                headers: dataSettings.facts.map((fact: string) => ({
                    id: fact,
                    data: headers[fact],
                })),
            };
        }

        newChartData.chartData = {
            ...newChartData.chartData,
            filters: {
                ...newChartData.chartData.filters,
                limit: contentSettings.rowsPerPage || newChartData.chartData.filters.limit,
            },
            headers: newChartData.chartData.headers.map((headerData: any) => {
                const findHeaderWidth = contentSettings.saveTableLayout
                    && contentSettings.tableColumnsWidth.find((column: any) => column.name == headerData.id && column.width !== "*");

                return {
                    ...headerData,
                    width: findHeaderWidth?.width,
                };
            }),
        };

        return newChartData;
    };

    useEffect(() => {
        setFormatData(formatDataHandler());
    }, [ initChartData.chartData, initChartData.gadgetData ]);

    return formatData;
};
