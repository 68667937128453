import React from 'react';
import { Input, InputGroup, Label } from 'reactstrap';

import { EExportFormats } from '/visual/models';
import { IChooseFormatFileRadio } from './models';

import styles from './style.module.scss';

export const ChooseFormatFileRadio = ({ labelStyles, fileFormat, setFileFormat }: IChooseFormatFileRadio) => {
    return (
        <>
            <label className={ labelStyles }>File format:</label>
            <InputGroup className={ styles.checkboxesWrapper }>
                {
                    Object.values(EExportFormats).map(format => (
                        <Label
                            className={ styles.radio_label }
                            for={ `radio_${ format }` }
                            key={ format }
                        >
                            <Input
                                checked={ fileFormat === format }
                                id={ `radio_${ format }` }
                                type='radio'
                                onChange={ () => setFileFormat(format) }
                            />
                            <span>{ format }</span>
                        </Label>
                    ))
                }
            </InputGroup>
        </>
    );
};
