import React, { Component, Children, cloneElement } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import uuidv4 from 'uuid/v4';
import { UncontrolledTooltip } from 'reactstrap';
import textContent from 'react-addons-text-content';
import { isMobile } from "react-device-detect";

import { HelperService, TextService } from '/services';

import './TooltipWrapper.scss';

export class TooltipWrapper extends Component {
    _input = null;
    wrapperResizeObserver = null;

    state = {
        childStyle: {},
        textConditions: {},
        id: 'a' + uuidv4(),
        targetReady: false,
        controlledShowTooltip: false,
    };

    ref = React.createRef();

    @autobind
    getTooltipTarget() {
        return this.ref.current;
    }

    componentDidMount() {
        const { force, withWrapperObserver } = this.props;

        if (!force) {
            const { fontSize, letterSpacing, fontFamily, display, fontWeight } = window.getComputedStyle(this._input);

            this.setState({
                childStyle: {
                    display,
                },
                textConditions: {
                    fontSize,
                    letterSpacing,
                    fontFamily,
                    fontWeight,
                },
            });
        }

        // we must be sure, that target is already mounted in the DOM, for targeting tooltip to it
        this.setState({ targetReady: !!this._input });

        // if wrapper element has transition logic (e.x. wrapper element render inside Fade)
        // we should check each rerender and get actual width
        if (withWrapperObserver && this._input?.parentElement) {
            this.addWrapperResizeObserver();
        }
    }

    componentWillUnmount() {
        if (this.props.withWrapperObserver && this.wrapperResizeObserver && this._input?.parentElement) {
            this.wrapperResizeObserver.unobserve(this._input.parentElement);
        }
    }

    @autobind
    addWrapperResizeObserver() {
        // TODO: when this component will be rewritten to functional component -> use useResizeObserver(this._input.parentElement)
        this.wrapperResizeObserver = new ResizeObserver(HelperService.debounce(entries => {
            entries.forEach(() => {
                const { children } = this.props;
                const text = textContent(children);

                this.setState({ controlledShowTooltip: this.needToShowTooltip(text) });
            });
        }, 300));

        this.wrapperResizeObserver.observe(this._input.parentElement);
    }

    @autobind
    getSize() {
        const {
            props: {
                getLastElementChildSize,
            },
            _input: {
                lastElementChild,
                parentElement,
            },
        } = this;

        const { clientWidth, scrollWidth } = getLastElementChildSize
            ? lastElementChild
            : parentElement;

        const { paddingLeft, paddingRight } = window.getComputedStyle(
            getLastElementChildSize
                ? lastElementChild
                : parentElement,
        );

        return {
            clientWidth,
            scrollWidth,
            paddingLeft,
            paddingRight,
        };
    }

    @autobind
    getWrapperWidth() {
        if (this._input) {
            const { clientWidth, scrollWidth, paddingLeft, paddingRight } = this.getSize();
            const { reduceParentWidth } = this.props;

            const {
                paddingLeft: inputPaddingLeft,
                paddingRight: inputPaddingRight,
            } = window.getComputedStyle(this._input);

            const padding = parseFloat(paddingLeft) + parseFloat(paddingRight);
            const inputPadding = parseFloat(inputPaddingLeft) + parseFloat(inputPaddingRight);

            return (clientWidth || scrollWidth) - padding - inputPadding - reduceParentWidth;
        } else {
            return null;
        }
    }

    mapChildren(id) {
        const { children } = this.props;

        return (
            Children.map(children, child =>
                cloneElement(child, {
                    ref: node => {
                        this._input = !(node instanceof Element)
                            ? findDOMNode(node)
                            : node;

                        // this._input = node;
                        (id && this._input) ? this._input.id = id : null;
                        const { ref } = child;

                        // example of the usage ref:
                        // https://github.com/facebook/react/issues/8873#issuecomment-512687497
                        if (typeof ref === 'function') {
                            ref(node);
                        } else if (ref !== null) {
                            ref.current = node;
                        }
                    },
                }),
            )
        );
    }

    needToShowTooltip(text) {
        const { condition: { custom }, force } = this.props;

        if (force) {
            return true;
        }

        const width = this.getWrapperWidth();
        const { textConditions } = this.state;
        const compoundConditions = { ...textConditions, width };
        let result = true;

        if (custom) {
            result = custom;
        }

        if (width) {
            result = result && TextService.isGreater({ text, ...compoundConditions });
        }

        return result;
    }

    getValue() {
        const { truncateTo, value } = this.props;

        return value.length <= truncateTo
            ? value
            : value.slice(0, truncateTo) + '...';
    }

    render() {
        const {
            className,
            innerClassName,
            delayShow,
            placement,
            offset,
            boundariesElement,
            fade,
            autohide,
            truncateTo,
            value,
            children,
            withWrapperObserver,
        } = this.props;
        const { id, targetReady, controlledShowTooltip } = this.state;
        let showTooltip = false;

        if (!withWrapperObserver) {
            const text = textContent(children);

            showTooltip = this.needToShowTooltip(text);
        }

        const show = withWrapperObserver ? controlledShowTooltip : showTooltip;

        return (
            <>
                { this.mapChildren(show && id) }

                {
                    (show && targetReady && !isMobile)
                        ? <UncontrolledTooltip
                            className={ className }
                            placement={ placement }
                            target={ id }
                            boundariesElement={ boundariesElement }
                            trigger={ 'hover' }
                            autohide={ autohide }
                            offset={ offset }
                            delay={ delayShow }
                            fade={ fade }
                            innerClassName={ innerClassName }
                        >
                            {
                                truncateTo
                                    ? this.getValue()
                                    : value
                            }
                        </UncontrolledTooltip>
                        : null
                }
            </>
        );
    }
}

TooltipWrapper.propTypes = {
    value: PropTypes.any.isRequired,
    condition: PropTypes.object,
    placement: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    delayShow: PropTypes.number,
    boundariesElement: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    reduceParentWidth: PropTypes.number,
    truncateTo: PropTypes.number,
    getLastElementChildSize: PropTypes.bool,
    withWrapperObserver: PropTypes.bool,
};

TooltipWrapper.defaultProps = {
    condition: {},
    value: '',
    className: '',
    delayShow: 0,
    offset: '0, 2px',
    boundariesElement: 'body',
    placement: 'top',
    fade: false,
    autohide: false,
    reduceParentWidth: 0,
    truncateTo:  null,
    getLastElementChildSize: false,
    withWrapperObserver: false,
};
