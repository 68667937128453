import { createSelector } from 'reselect';
import { TFunction } from 'i18next';

import { getLabelClassByType, isSupportUserSelector, hasVisualWriteAccessSelector } from '/modules/selectors';
import { GadgetSettingsService } from '/visual/services';
import { IDataSettings, IContentSettings, IRights, IDashboards } from '/visual/models';
import { HelperService, UsersRoleService } from '/services';
import { DashboardGridService } from '/visual/scenes/Dashboard/services';
import type { IDashboard, ISwitcher, IPages, IBoard } from '/visual/scenes/Dashboard/models';
import { GadgetService } from '/visual/scenes/Dashboard/components';
import { ConfigureGadgetModalService } from '/visual/scenes/Dashboard/components/ConfigureGadgetModal/services';
import { IChartData } from '/visual/scenes/Dashboard/components/Gadget/models';

const _getDashboard = (state: { VocVisual: { dashboard: IDashboard } }) => state.VocVisual.dashboard;
const _getModalState = (state: { VocVisual: { dashboard: IDashboard } }) => state.VocVisual.dashboard.modalState;

export const _getIsEditable = (state: { VocVisual: { dashboard: IDashboard } }) => state.VocVisual.dashboard.isEditable;

const _getEditedChanges = (state: { VocVisual: { dashboard: IDashboard } }) =>
    state.VocVisual.dashboard.stateOfEdit.changed;

const _getGadgetsForGrid = (state: { VocVisual: { dashboard: IDashboard } }) => state.VocVisual.dashboard.dashboard.gadgets;
const _getDashboardForGrid = (state: { VocVisual: { dashboard: IDashboard } }) => state.VocVisual.dashboard.dashboard;

export const scrollIntoViewSelector = (state: { VocVisual: { dashboard: IDashboard } }) =>
    state.VocVisual.dashboard.stateOfEdit.scrollTo;

export const getDashboardPages = (state: { VocVisual: { dashboard: IDashboard } }) =>
    state.VocVisual.dashboard.stateOfEdit.pages;

export const gadgetChartData = createSelector(
    [ _getDashboard, (_: any, gadgetId: string) => gadgetId ],
    ({ gadgetsChartsData }: { gadgetsChartsData: Record<string, IChartData> }, gadgetId: string) => {
        const data = gadgetsChartsData && gadgetsChartsData[gadgetId];

        if (
            data
            && data.chartData
            && !data.loading
            && data.gadgetData?.contentSettings?.function === 'affection'
        ) {
            const list = GadgetService.getAffectionFormattedData({ items: [ ...data.chartData.items ] });

            return {
                ...data,
                chartData: {
                    ...data.chartData,
                    items: list,
                },
            };
        }

        return data || { loading: true, chartData: null, loaded: false };
    },
);

export const gadgetDescriptionSelector = createSelector(
    [ _getModalState, _getDashboard ], ({ gadgetId, type }, { gadgetsChartsData }) => {
        if (type === 'descriptionGadget') {
            const gadget = gadgetsChartsData[gadgetId].gadgetData;

            return gadget?.contentSettings || null;
        }

        return null;
    },
);

export const gadgetStaticAreaValueSelector = createSelector(
    [ _getModalState, _getDashboard ],
    ({ gadgetId }, { gadgetsChartsData }) => {
        return gadgetId ? {
            id: gadgetId,
            value: gadgetsChartsData[gadgetId].chartData,
        } : null;
    },
);

export const modalStateSelector = createSelector([ _getModalState ], modalState => modalState);

export const _getDashboardData = createSelector(
    [ _getDashboardForGrid, isSupportUserSelector, hasVisualWriteAccessSelector ],
    (
        {
            parentDashboardId,
            id,
            title,
            description,
            gadgets,
            pages,
            isDefault,
            isFavourite,
            pagesOrder,
            parentGadget,
            isUseCustomTitle,
            currentUserPermission,
            extraGadgets,
        },
        isSupportUser,
        hasVisualWriteAccess,
    ): IBoard & { rights: IRights } => {
        const rights: IRights = UsersRoleService.getRightsForVisual(isSupportUser, currentUserPermission, hasVisualWriteAccess);

        return {
            parentDashboardId,
            id,
            title,
            description,
            gadgets,
            pages,
            isDefault,
            isFavourite,
            pagesOrder,
            parentGadget,
            isUseCustomTitle,
            currentUserPermission,
            extraGadgets,
            rights,
        };
    });

export const _getDashboardLoading = (state: { VocVisual: { dashboard: IDashboard } }) => state.VocVisual.dashboard.loading;

export const _getDashboardLoadingData = (
    state: { VocVisual: { common: IDashboards, dashboard: IDashboard } },
) => {
    const { common, dashboard } = state.VocVisual;

    return {
        defaultDashboardLoading: common.defaultDashboard.loading,
        defaultDashboardLoaded: common.defaultDashboard.loaded,
        dashboardLoading: dashboard.loading,
    };
};

export const _getDefaultDashboardId = (state: { VocVisual: { common: IDashboards } }) =>
    state.VocVisual.common.defaultDashboard.id;

const _getFavourites = (state: { VocVisual: { common: IDashboards } }) => state.VocVisual.common.favouritesDashboards;

export const favouriteListSelector = createSelector(
    [ _getFavourites ], favouriteList => favouriteList,
);

export const gadgetsSelector = createSelector(
    [ _getGadgetsForGrid ],gadgets => gadgets,
);

export const enableDashboardFilterSelector = createSelector(
    [ gadgetsSelector ],
    gadgets => gadgets.some(gadget => gadget.dataSettings.sourceId),
);

export const gadgetClipboardSelector = (state: { VocVisual: {dashboard: IDashboard} }) => state.VocVisual.dashboard.gadgetClipboard;
export const reduceGadgetsSelector = createSelector(
    [ _getDashboardForGrid, _getDashboard, gadgetClipboardSelector ],
    ( dashboardForGrid, { stateOfEdit, isEditable, gadgetPending }, gadgetClipboard) => {
        const getSource = (isEditable: boolean) => isEditable ? stateOfEdit : dashboardForGrid;
        const { extraGadgets, gadgets, pages, pagesOrder } = getSource(isEditable);

        const idGadgetsInPages = extraGadgets
            ? Object.keys(extraGadgets)
            : [];
        const orderedPages: IPages[] = HelperService.mapOrder(pages, pagesOrder, 'id');

        const model = {
            gadgets: [],
            wildGadgets: [],
            pages: [],
            gadgetPending,
        };

        gadgets.forEach(gadget => {
            const gadgetModel = {
                type: gadget.type,
                id: gadget.id,
                title: gadget.title,
            };

            if(idGadgetsInPages.includes(gadget.id)) {
                let coordinates;

                orderedPages.find(page => {
                    return !!page.coordinates?.find(coordinat => {
                        if(coordinat.gadgetId === gadget.id) {
                            coordinates = {
                                gadgetId: coordinat.gadgetId,
                                h: +coordinat.h,
                                w: +coordinat.w,
                                x: +coordinat.x,
                                y: +coordinat.y,
                                page: page.id,
                            };

                            return true;
                        }
                    });
                });

                try {
                    model.gadgets.push({
                        ...gadgetModel,
                        coordinates,
                        page: coordinates?.page,
                    });
                } catch (e) {
                    //floating bug
                    // eslint-disable-next-line no-console
                    console.log('--------e, extraGadgets, gadgets, pages---------', e, extraGadgets, gadgets, pages);
                    // eslint-disable-next-line no-console
                    console.log('---------model, gadget, coordinates---------', model, gadget, coordinates);
                    // eslint-disable-next-line no-debugger
                    debugger;
                }
            } else {
                model.wildGadgets.push({
                    ...gadgetModel,
                    contentSettings: { gadgetDescription: gadget.contentSettings.gadgetDescription },
                });
            }
        });

        const gadgetClipboardSize: [number, number] | null = gadgetClipboard
            ? [ gadgetClipboard.coordinates.w, gadgetClipboard.coordinates.h ]
            : null;

        return {
            ...model,
            pages: DashboardGridService.generatePages(
                { gadgets: model.gadgets, allPages: orderedPages },
                gadgetClipboardSize,
            ),
        };
    },
);

const _getSource = (state: { VocVisual: {dashboard: IDashboard} }) => state.VocVisual.dashboard.dataSource;

export const getGadgetData = createSelector(_getDashboard,({ gadgetData }) => gadgetData);

export const dashboardFilterData = createSelector(
    _getDashboard,
    ({ dashboardFilter }) => ({
        ...dashboardFilter,
        dataSource: dashboardFilter.dataSource.map(source => ({
            ...source,
            label: source.title,
            value: source.id,
            labelStatusClass: source.state?.toLowerCase(),
            channel: source.channel !== source.type ? source.channel : false,
            typeColor: getLabelClassByType(source.type),
            labelChannelClass: getLabelClassByType(source.type),
        })),
    }),
);

export const dashboardFilterActiveSource = createSelector(
    dashboardFilterData,
    ({ dataSource, activeSourceId }) => dataSource.find(source => source.id === activeSourceId),
);

export const dashboardFilterSourceDate = createSelector(dashboardFilterActiveSource,source => {
    return source?.attributes?.filter(attribute => attribute.type === 'DATE' || attribute.originType?.includes('service'))
        .map(attribute => {
            const badge = attribute.originType || attribute.type;

            return {
                value: attribute.id,
                label: attribute.name,
                badge: GadgetSettingsService.getLabel(badge),
            };
        }) || [];
});

export const sourceSelector = createSelector(_getSource, sourceData => {
    return {
        ...sourceData,
        data: sourceData.data.map(source => ({
            ...source,
            value: source.id,
            label: source.title,
            labelStatusClass: source.state?.toLowerCase(),
            channel: source.channel !== source.type ? source.channel : false,
            typeColor: getLabelClassByType(source.type),
            labelChannelClass: getLabelClassByType(source.type),
            languages: HelperService.getOptionsWithAllProps(source.languages, 'id', 'name'),

        })),
    };
});

export const activeSourceSelector = createSelector(_getDashboard,({ activeSource: { data, loading, loaded } }) => {
    // insert 'badge', 'value', 'label' inside each attribute for option displaying
    const attributes = data
        ? HelperService.getOptionsWithAllProps(
            GadgetSettingsService.addBadgeForOptions(data.attributes),
            'id',
            'name',
        )
        : [];

    return {
        ...data,
        channel: data.channel !== data.type
            ? data.channel
            : false,
        attributes,
        loading,
        loaded,
    };
});

export const activeSourceSelectorWithoutDate = createSelector(activeSourceSelector,activeSource => {
    return {
        ...activeSource,
        attributes: activeSource.attributes.filter(attribute => attribute.type !== 'DATE' && attribute.type !== 'DATE_FORMAT'),
    };
});

export const sourceCustomDate = createSelector(activeSourceSelector,({ attributes }) => {
    return attributes?.filter(attribute => attribute.originType === 'DATE' || attribute.originType.includes('service'))
        .map(attribute => {
            const badge = attribute.originType || attribute.type;

            return {
                value: attribute.id,
                label: attribute.name,
                badge: GadgetSettingsService.getLabel(badge),
            };
        });
});

const pullToSwitchers = (existedList: ISwitcher[], options: ISwitcher[]) => {
    return [ ...existedList, ...options ];
};

export const SwitchersSelector = (t: TFunction) => createSelector(activeSourceSelector, source => {
    // default switchers
    let switchers = [
        {
            title: t('showScore'),
            type: 'annotateScore',
        },
        {
            title: t('customColorSet'),
            type: 'customColorSet',
        },
        {
            title: t('longLabels'),
            type: 'longLabel',
        },
        {
            title: t('enableRecordThreshold'),
            type: 'recThresholdEnabled',
        },
    ];

    if (source.type === 'survey') {
        switchers = pullToSwitchers(
            switchers,
            [{ title: t('configureStates'), type: 'state' }],
        );
    }

    return switchers;
});

export const ChartSpecificSwitchersSelector = (localContentSettings: IContentSettings, t: TFunction) => createSelector(
    activeSourceSelector,
    () => {
        // default switchers
        let switchers: any[] = [];

        const drilledDownChartTypes = [
            'surveys_bar_chart',
            'stacked_bar_chart',
            'generic_pie_chart',
            'line_chart',
            'lemmata_word_cloud',
        ];

        const altChoicesChartTypes = [
            'stacked_bar_chart',
            'generic_pie_chart',
        ];

        if (drilledDownChartTypes.includes(localContentSettings.chartType)) {
            switchers = pullToSwitchers(
                switchers,
                [{ title: t('drillDown'), type: 'drillDown' }],
            );
        }

        if (altChoicesChartTypes.includes(localContentSettings.chartType)) {
            switchers = pullToSwitchers(
                switchers,
                [{
                    title: t('calculateBy'),
                    type: 'altChoices',
                    render: 'select',
                    options: [
                        {
                            label: t('recordsCount'),
                            value: true,
                        },
                        {
                            label: t('valueCount'),
                            value: false,
                        },
                    ],
                }],
            );
        }

        if (localContentSettings.chartType === 'lemmata_word_cloud') {
            switchers = pullToSwitchers(
                switchers,
                [{ title: t('hideNoValue'), type: 'hideNoValue' }],
            );
        }

        const isPercentageSettingEnable = ConfigureGadgetModalService.checkIsPercentageSettingEnable({
            chartType: localContentSettings.chartType,
            func: localContentSettings.function,
        });

        if (isPercentageSettingEnable) {
            switchers = pullToSwitchers(
                switchers,
                [
                    {
                        title: t('percentage'),
                        type: 'relativeMode',
                    },
                ],
            );
        }

        if('surveys_bar_chart' === localContentSettings.chartType) {
            switchers = pullToSwitchers(
                switchers,
                [
                    {
                        title: t('numberOfDecimalDigits'),
                        type: 'decimalDigits',
                        render: 'select',
                        options: [
                            {
                                label: t('auto'),
                                value: 'auto',
                            },
                            {
                                label: '0',
                                value: '0',
                            },
                            {
                                label: '1',
                                value: '1',
                            },
                            {
                                label: '2',
                                value: '2',
                            },
                            {
                                label: '3',
                                value: '3',
                            },
                        ],
                    },
                ],
            );
        }

        if(localContentSettings.chartType === 'custom_columns_table') {
            switchers = pullToSwitchers(
                switchers,
                [
                    ...localContentSettings.function !== 'just_show'
                        ? [
                            {
                                title: t('percentage'),
                                type: 'relativeMode',
                            },
                            {
                                title: t('roundUpDecimals'),
                                type: 'roundUp',
                            },
                        ]
                        : [
                            {
                                title: t('showFullDate'),
                                type: 'showFullDate',
                            },
                        ],
                    {
                        title: t('saveTableLayout'),
                        type: 'saveTableLayout',
                    },
                    {
                        title: t('wordWrap'),
                        type: 'wordwrapMode',
                    },
                ],
            );
        }

        return switchers;
    },
);

export const ColorsSelector = (localDataSettings: IDataSettings) => createSelector(getGadgetData, ({ dataSettings }) => {
    const initColors = {
        theOne: null,
        other: [
            { 'color': 'red', 'colors': [ '#d2322d', '#e2632a', '#ed9c28', '#cc9e2e', '#aa9f34', '#46ac46' ] },

            { 'colors': [ '#4e80bc', '#bf4f4c', '#9aba58', '#7f63a1', '#4aabc5', '#f69545' ] },
            { 'colors': [ '#4e80bc', '#9aba58', '#4aabc5', '#2b4c74', '#5e742f', '#26697b' ] },
            { 'colors': [ '#bf4f4c', '#7f63a1', '#f69545', '#762b29', '#4c3a61', '#b55607' ] },
            { 'colors': [ '#f69545', '#4aabc5', '#7f63a1', '#b55607', '#26697b', '#4c3a61' ] },
            { 'colors': [ '#36688D', '#F3CD05', '#F49F05', '#F18904', '#BDA589', '#696969' ] },
            { 'colors': [ '#f69545', '#0584F2', '#0AAFF1', '#EDF259', '#A79674', '#696969' ] },
            { 'colors': [ '#6465A5', '#6975A6', '#F3E96B', '#F28A30', '#F05837', '#696969' ] },
            { 'colors': [ '#ABA6BF', '#595775', '#583E2E', '#F1E0D6', '#BF988F', '#696969' ] },
            { 'colors': [ '#DAA2DA', '#DBB4DA', '#DE8CF0', '#BED905', '#93A806', '#696969' ] },
            { 'colors': [ '#A3586D', '#5C4A72', '#F3B05A', '#F4874B', '#F46A4E', '#696969' ] },
            { 'colors': [ '#1F1641', '#012172', '#0486DB', '#05ACD3', '#BBBF95', '#696969' ] },
            { 'colors': [ '#1B1924', '#B5C1B4', '#DCD9C6', '#74593D', '#3F3232', '#696969' ] },
            { 'colors': [ '#C2D3DA', '#81A3A7', '#585A56', '#F1F3F2', '#272424', '#bdbdbd' ] },
            { 'colors': [ '#BB1924', '#EE6C81', '#F092A5', '#777CA8', '#AFBADC', '#696969' ] },
            { 'colors': [ '#003D73', '#0878A4', '#1ECFD6', '#EDD170', '#C05640', '#696969' ] },
            { 'colors': [ '#55D9C0', '#C7F6EC', '#107050', '#02231C', '#4DD8AD', '#696969' ] },
            { 'colors': [ '#F4ED71', '#8A8604', '#CAC7D7', '#04030C', '#383140', '#696969' ] },
            { 'colors': [ '#A3445D', '#8D2D56', '#0D050E', '#2B193E', '#D53C3C', '#696969' ] },
            { 'colors': [ '#E0E8F0', '#51A2D9', '#53C0F0', '#B9E5F3', '#8A140E', '#696969' ] },
            { 'colors': [ '#DA291C', '#000000', '#585858', '#BBBBBB', '#EFEFEF', '#6295AC' ] },

            { 'color': '#375c89', 'brighten': 10 },
            { 'color': '#8b3735', 'brighten': 10 },
            { 'color': '#70883e', 'brighten': 10 },
            { 'color': '#5b4675', 'brighten': 10 },
            { 'color': '#347c90', 'brighten': 10 },
        ],
    };

    if (localDataSettings?.customColorSet) {
        return JSON.parse(localDataSettings.customColorSet);
    }

    return dataSettings?.customColorSet
        ? JSON.parse(dataSettings?.customColorSet)
        : initColors;
});

export const ConfigureStatesSelector = (t: TFunction) => ([
    {
        title: t('states.completed'),
        type: 'Completed',
    },
    {
        title: t('states.terminated'),
        type: 'Terminated',
    },
    {
        title: t('states.partial'),
        type: 'Partial',
    },
    {
        title: t('states.expired'),
        type: 'Expired',
    },
    {
        title: t('states.invited'),
        type: 'Invited',
    },
    {
        title: t('states.started'),
        type: 'Started',
    },
    {
        title: t('states.notDelivered'),
        type: 'Not Delivered',
    },
    {
        title: t('states.blacklisted'),
        type: 'Blacklisted',
    },
    {
        title: t('states.quarantined'),
        type: 'Quarantined',
    },
]);

export const isDashboardChangedSelector = createSelector(
    _getEditedChanges, ({ page, deleteGadget, isPageOrderChanged, deletedPages }) =>
        page?.length > 0 || deleteGadget?.length > 0 || isPageOrderChanged || deletedPages?.length > 0,
);
