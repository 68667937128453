import React from 'react';
import { Button, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './VocFormNumberInput.scss';

export const VocFormNumberInput = ({
    input,
    className,
    required,
    withError,
    min = 1,
    max = 100,
    step = 1,
    disableSymbols,
    meta: {
        touched,
        error,
    },
}) => {
    const blurHandler = () => {
        input.onBlur();
    };

    function calculateValue(condition) {
        const {
            value,
            onChange,
        } = input;
        let newValue;

        if (condition === 'plus') {
            newValue = +value + step > max ? max : +value + step;
        } else {
            newValue = value - step < min ? min : value - step;
        }

        onChange(newValue);
    }

    function changeHandler(e) {
        const inputValue = disableSymbols
            ? e.target.value.replace(/\D+/g, '')
            : +e.target.value;

        let newValue;

        switch (true) {
            case(inputValue > max):
                newValue = max;
                break;
            case(inputValue < min):
                newValue = min;
                break;

            default:
                newValue = !inputValue.length
                    ? ''
                    : +inputValue;
        }

        input.onChange(newValue);
    }

    return(
        <div className={ `${className} app-form-control__container ${withError ? 'with-error': ''}` }>
            <div className="input-group input-group-number">
                <span className="input-group-btn">
                    <Button onClick={ calculateValue }
                        color={ 'white' }
                        outline
                        className="voc-number-input__button voc-number-input__button--first">
                        <FontAwesomeIcon icon="minus"/>
                    </Button>
                </span>
                <input
                    name={ name }
                    id={ input.name }
                    min={ min }
                    max={ max }
                    step={ step }
                    value={ input.value }
                    required={ required }
                    { ...input }
                    onChange = { changeHandler }
                    type={ disableSymbols ? 'text' : 'number' }
                    className="voc-number-input form-control"
                    onBlur={ blurHandler }
                />
                <span className="input-group-btn">
                    <Button onClick={ () => calculateValue('plus') }
                        color={ 'white' }
                        outline
                        className="voc-number-input__button voc-number-input__button--last">
                        <FontAwesomeIcon icon="plus"/>
                    </Button>
                </span>
            </div>
            { Boolean(touched && error && withError)
                ? <FormText className="app-form-control__error">{ error }</FormText>
                : false
            }
        </div>
    );
};
