import React, { useEffect, useState } from 'react';
import { ModalFooter } from '../ModalFooter';
import { RangeDatePicker, VocSelect } from '/components';
import { HelperService } from '/services';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import './DateForm.scss';

const initialFormValue = { from: null, to: null, column: null };

export const DateForm = ({
    close,
    filter,
    name,
    onSubmit,
    attributes,
    initialValues,
}) => {
    const [ formData, setFormData ] = useState({ ...initialFormValue });

    useEffect(() => {
        const { initialValues } = filter;
        let column = null;

        if (initialValues?.column) {
            column = attributes.find(attr => attr.value === initialValues.column);
        } else if (initialValues?.from || initialValues?.to) {
            column = attributes.find(attr => attr.value === null);
        }

        setFormData({
            from: initialValues && initialValues.from ? initialValues.from : null,
            to: initialValues && initialValues.to ? initialValues.to : null,
            column,
        });

        return () => {
            setFormData({ ...initialFormValue });
        };
    }, [ filter ]);

    const onDatesChanged = value => {
        setFormData({
            ...formData,
            from: value.from,
            to: value.to,
        });
    };

    const onColumnChange = value => {
        setFormData({
            ...formData,
            column: { ...value },
        });
    };

    const validateField = () => {
        const { column, from, to } = formData;
        const isValidData = !!from || !!to;
        const isValidColumn = !!column;

        return !(isValidData && isValidColumn);
    };

    const submit = () => {
        const { column, from, to } = formData;
        const modifyParam = { column: column.value };
        const { defaultDateMask } = HelperService;

        from ? modifyParam['from'] = moment(from).format(defaultDateMask) : '';
        to ? modifyParam['to'] = moment(to).format(defaultDateMask) : '';
        onSubmit(modifyParam, name);
    };

    return (
        <>
            <div className={ 'modal-body' }>
                <div className='mine-filter-datepicker'>

                    <Row>
                        <Col>From</Col>
                        <Col>To</Col>
                    </Row>

                    <RangeDatePicker
                        dateFormat={ HelperService.datePickerMask }
                        handleChange={ onDatesChanged }
                        values={ formData }
                        withLabel={ false }
                        minDate={ new Date('1900/01/01') }
                        maxDate={ new Date('2100/12/31') }
                        isHorizontal
                    />
                    <Row className={ 'mine-filter-datepicker__select' }>
                        <Col md={ 12 }>
                            <VocSelect
                                isClearable={ false }
                                isHorizontal={ true }
                                options={ attributes }
                                placeholder={ 'Select' }
                                value={ formData.column }
                                onChange={ onColumnChange }
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <ModalFooter
                close={ close }
                onClick={ submit }
                disabled={ validateField() }
                addBtn={ initialValues.nameButton }
            />
        </>
    );
};

