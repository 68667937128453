import { useRef, useEffect } from 'react';

export function usePreviousProps(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [ value ]);

    return ref.current;
}
