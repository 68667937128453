import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { TooltipWrapper, CollapseSection } from '/components';
import { Topic } from './components';

import styles from './style.module.scss';

export const TopicsList = ({
    isMine,
    topics,
    onSelect,
    onSelectForMove,
    addTopics,
    viewType,
    openedCategories,
    setOpenedCategories,
    openCategoryModal,
    onDeleteCategory,
}) => {
    const filterCategory = topics.filter(category => category.id !== 'Uncategorized');
    const uncategorizedCategory = topics.find(category => category.id === 'Uncategorized');

    const onOpenCategory = categoryId => {
        setOpenedCategories({
            ...openedCategories,
            [categoryId]: !openedCategories[categoryId],
        });
    };

    const getTopicsList = (topicsList: any, isCategory?: boolean) => (
        <div className={ cx(styles.topicList, { [styles.topicsInCategories]: isCategory }) }>
            {
                topicsList.map(topic => (
                    <Topic
                        isMine={ isMine }
                        key={ topic.id }
                        topic={ topic }
                        onSelect={ onSelect }
                        onSelectForMove={ onSelectForMove }
                    />
                ))
            }
        </div>
    );

    const openCategoryModalHandler = (e, categoryData) => {
        e.stopPropagation();

        openCategoryModal({
            open: true,
            initCategory: categoryData.name,
            categoryId: categoryData.id,
        });
    };

    const deleteCategoryHandler = (e, categoryId) => {
        e.stopPropagation();

        onDeleteCategory(categoryId);
    };

    const getCategoryActions = categoryData => (
        <div className={ styles.categoriesActions }>
            <button>
                <TooltipWrapper value={ 'Edit category name' } force>
                    <span style={{ color: '#337ab7' }}>
                        <FontAwesomeIcon
                            icon={ 'pencil-alt' }
                            onClick={ e => openCategoryModalHandler(e, categoryData) }
                        />
                    </span>
                </TooltipWrapper>
            </button>
            <button>
                <TooltipWrapper value={ 'Delete category' } force>
                    <span style={{ color: '#337ab7' }}>
                        <FontAwesomeIcon
                            icon={ 'trash' }
                            onClick={ e => deleteCategoryHandler(e, categoryData.id) }
                        />
                    </span>
                </TooltipWrapper>
            </button>
        </div>
    );

    const getCategoryCollapse = categoryData => (
        <CollapseSection
            collapse={ openedCategories && openedCategories[categoryData.id] }
            key={ categoryData.id }
            title={ categoryData.name }
            handlerElement={ categoryData.id === 'Uncategorized' ? null : getCategoryActions(categoryData) }
            noTitleTooltip={ false }
            onCollapseChanged={ () => onOpenCategory(categoryData.id) }
        >
            {
                categoryData.topics?.length
                    ? getTopicsList(categoryData.topics, true)
                    : <p className={ styles.noTopicsLabel }>No topics</p>
            }
        </CollapseSection>
    );

    const getCategoriesList = categoriesList => (
        <div className={ styles.categoriesList }>
            {
                categoriesList.map(categoryData => getCategoryCollapse(categoryData))
            }
            {
                getCategoryCollapse(uncategorizedCategory)
            }
        </div>
    );

    return (
        <div className={ styles.topicWrapper }>

            {
                viewType === 'grid' || isMine
                    ? getTopicsList(topics)
                    : getCategoriesList(filterCategory)
            }

            <div className={ styles.addTopic }>
                <button className={ styles.actionButton } >+</button>
                <div className={ styles.menuList }>
                    <button onClick={ addTopics }>
                        Add topics
                    </button>
                    {
                        !isMine
                        && <button onClick={ () => openCategoryModal({ open: true }) }>
                                Add category
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};
