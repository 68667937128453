import { createSelector } from 'reselect';
import { isLoadedPermissionSelector } from '/scenes/VocFeedback/scenes/Export/modules/export.selector';

const _getResults = state => state.VocFeedback.export.results;

export const filtersSelector = state => _getResults(state).filters;

export const selectedListSelector = state => _getResults(state).selected;

export const tableSettingsSelector = createSelector(
    [ _getResults, isLoadedPermissionSelector ],
    (exports, isLoadedPermission) => {
        return {
            total: exports.count,
            loading: exports.count === 0 && isLoadedPermission
                ? isLoadedPermission
                : exports.loading,
        };
    });

export const possibleRangesSelector = () => [
    { label: 'Creation Date', value: 'creation' },
    { label: 'Response Date', value: 'end_time' },
];

export const exportSummarySelector = createSelector([ _getResults, filtersSelector, possibleRangesSelector ],
    (exports, filters, ranges) => {
        const rangeValue = ranges.find(range => range.value === filters.range);
        const period = [
            {
                label: 'Filtered by',
                value: rangeValue && rangeValue.label,
            },
            {
                label: 'Period',
                value: `${filters.start_time} ... ${filters.end_time}`,
            },
        ];
        const statistics = Object.keys(exports.summary.statistics).map(key => ({
            label: key,
            value: exports.summary.statistics[key],
        }));

        statistics.splice(1, 0, ...period);
        return {
            ...exports.summary,
            statistics,
        };
    });

export const tableDataSelector = createSelector([ _getResults ], exports => {
    return exports.results.map(result => {
        const temp = {
            checkbox: {
                item: {
                    id: result.id,
                    unit: result.units,
                },
                selected: Boolean(exports.selected.find(item => item.id === result.id)),
                disabled: result.state === 541,
            },
            id: result.id,
            name: result.name,
            aborted: 0,
            imported: 0,
            invited: 0,
            started: 0,
            completed: 0,
            terminated: 0,
            expired: 0,
            visited: 0,
            triggered: 0,
            ...result.statistic,
        };

        temp.response_rate = {
            response_rate: {
                label: 'Response rate',
                value: temp.response_rate + '%',
            },
            response_rate_with_partial: {
                label: 'Response rate with partial',
                value: temp.response_rate_with_partial + '%',
            },
        };

        return temp;
    });
});
