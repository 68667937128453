import React, { useState, useEffect } from 'react';
import { string, number, func, arrayOf, bool, oneOfType } from 'prop-types';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import './InputMinMaxNumber.scss';

export const InputMinMaxNumber = ({
    selectedAttrName,
    className,
    elementId,
    isRequired,
    labels,
    limits,
    onChange,
    step,
    title,
    values,
}) => {
    const [ localLimits, setLocalLimits ] = useState({});
    const [ minValue, setMinValue ] = useState('');
    const [ maxValue, setMaxValue ] = useState('');
    const [ minError, setMinError ] = useState('');
    const [ maxError, setMaxError ] = useState('');

    useEffect(() => {
        calcLimits(limits);
        setMinValue(typeof values[0] === 'number' || values[0] ? +values[0] : '');
        setMaxValue(typeof values[1] === 'number' || values[1] ? +values[1] : '');
    }, [ selectedAttrName ]);

    useEffect(() => {
        if (minError || maxError || (isRequired && (minValue === '' || maxValue === ''))) {
            onChange && onChange([]);
        } else {
            onChange && onChange([
                checkNotEmpty(minValue) ? +minValue : NaN,
                checkNotEmpty(maxValue) ? +maxValue : NaN,
            ]);
        }
    }, [ minValue, maxValue ]);

    const checkNotEmpty = val => val !== undefined && val !== null && val !== "" && !isNaN(val) && !isNaN(+val);

    const calcLimits = limits => {
        const _limits = {};

        if (limits.length === 2 && limits[0] <= limits[1]) {
            if (checkNotEmpty(limits[0])) {
                _limits.isMin = true;
                _limits.min = +limits[0];
            }
            if (checkNotEmpty(limits[1])) {
                _limits.isMax = true;
                _limits.max = +limits[1];
            }
        }

        setLocalLimits(_limits);
    };

    const errorText = (type, value) => `${ type } value - ${ value }`;

    const changeMin = newMin => {
        if (newMin === '') {
            setMinError(isRequired ? "Can't be empty" : "");
        } else if (localLimits.isMin && +newMin < localLimits.min) {
            setMinError(errorText('Min', localLimits.min));
        } else if (+newMin > +maxValue && checkNotEmpty(maxValue) && +maxValue !== 0) {
            setMinError(errorText('Max', maxValue));
        } else {
            setMinError('');
        }

        setMinValue(newMin);
    };

    const changeMax = newMax => {
        if (newMax === '') {
            setMaxError(isRequired ? "Can't be empty" : "");
        } else if (localLimits.isMax && +newMax > +localLimits.max) {
            setMaxError(errorText('Max', localLimits.max));
        } else if (+newMax < +minValue && checkNotEmpty(minValue)) {
            setMaxError(errorText('Min', minValue));
        } else {
            setMaxError('');
        }
        setMaxValue(newMax);
    };

    const blurMin = () => {
        if (isRequired) {
            if (!checkNotEmpty(minValue)) {
                if (localLimits.isMin) {
                    changeMin(localLimits.min);
                } else {
                    setMinError("Can't be empty");
                }
            } else {
                let newValue = +minValue;

                if (localLimits.isMin && newValue < +localLimits.min) {
                    newValue = +localLimits.min;
                }
                if (localLimits.isMax && newValue > +localLimits.max) {
                    newValue = +localLimits.max;
                }
                if (newValue > +maxValue && checkNotEmpty(maxValue) && +maxValue !== 0) {
                    newValue = +maxValue;
                }
                changeMin(newValue);
            }
        }
    };

    const blurMax = () => {
        if (isRequired) {
            if (!checkNotEmpty(maxValue)) {
                if (localLimits.isMax) {
                    changeMax(localLimits.max);
                } else {
                    setMaxError("Can't be empty");
                }
            } else {
                let newValue = +maxValue;

                if (localLimits.isMax && newValue > +localLimits.max) {
                    newValue = +localLimits.max;
                }
                if (localLimits.isMin && newValue < +localLimits.min) {
                    newValue = +localLimits.min;
                }
                if (newValue < +minValue && checkNotEmpty(minValue)) {
                    newValue = +minValue;
                }
                changeMax(newValue);
            }
        }
    };

    return (
        <div className={ className }>
            { title
                ? <label className={ 'title' }>
                    { title }
                    { isRequired ? <span className={ 'star' }>*</span> : null }
                </label>
                : null
            }
            <Form className={ 'form' }>
                <FormGroup className={ 'field-group' }>
                    <div className={ 'input-place' }>
                        <Input
                            className={ 'input' }
                            id={ elementId + title + labels[0] }
                            min={ localLimits.isMin ? +localLimits.min : null }
                            max={ checkNotEmpty(maxValue) ? +maxValue : localLimits.isMax ? +localLimits.max : null }
                            onBlur={ blurMin }
                            onChange={ e => changeMin(e.target.value) }
                            placeholder={ localLimits.isMin ? localLimits.min : '' }
                            step={ step }
                            type="number"
                            value={ minValue }
                        />
                        <Label for={ elementId + title + labels[0] } className={ 'label' }>
                            { labels[0] }
                        </Label>
                    </div>
                    <FormText className={ 'error' }>
                        { isRequired && minError ? minError : '' }
                    </FormText>
                </FormGroup>
                <FormGroup className={ 'field-group' }>
                    <div className={ 'input-place' }>
                        <Input
                            className={ 'input' }
                            id={ elementId + title + labels[1] }
                            min={ checkNotEmpty(minValue) ? +minValue : localLimits.isMin ? +localLimits.min : null }
                            max={ localLimits.isMax ? +localLimits.max : null }
                            onBlur={ blurMax }
                            onChange={ e => changeMax(e.target.value) }
                            placeholder={ localLimits.isMax ? localLimits.max : '' }
                            step={ step }
                            type="number"
                            value={ maxValue }
                        />
                        <Label for={ elementId + title + labels[1] } className={ 'label' }>
                            { labels[1] }
                        </Label>
                    </div>
                    <FormText className={ 'error' }>
                        { isRequired && maxError ? maxError : '' }
                    </FormText>
                </FormGroup>
            </Form>
        </div>
    );
};

InputMinMaxNumber.propTypes = {
    className: string,
    elementId: string,
    isRequired: bool,
    labels: arrayOf(string),
    limits: arrayOf(number),
    onChange: func,
    step: number,
    title: string,
    values: oneOfType([
        arrayOf(number),
        arrayOf(string),
    ]),
};

InputMinMaxNumber.defaultProps = {
    className: 'input-min-max-number',
    elementId: 'inputMinMax',
    isRequired: false,
    labels: [ 'Min', 'Max' ],
    limits: [ 0, 100 ],
    values: [],
    step: 1,
    title: '',
};
