import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Graph1 } from './graphIcons/bar_chart.svg';
import { ReactComponent as Graph2 } from './graphIcons/generic_pie_chart.svg';
import { ReactComponent as Graph3 } from './graphIcons/lemmata_word_cloud.svg';
import { ReactComponent as Graph4 } from './graphIcons/radar_chart.svg';
import { ReactComponent as Graph5 } from './graphIcons/nps_bubble_chart.svg';
import { ReactComponent as Graph6 } from './graphIcons/table.svg';
import { ReactComponent as Graph7 } from './graphIcons/stacked_bar_chart.svg';
import { ReactComponent as Graph8 } from './graphIcons/line_chart.svg';

import styles from './style.module.scss';

interface IChartsProps {
    chartsData: {
        value: string;
        label: string;
    }[],
    onChange: (data: any) => void;
    selected: string | [null];
}

export const Charts = ({ chartsData, onChange, selected }: IChartsProps) => {
    const { t } = useTranslation();
    const graphIcons = [
        {
            Icon: Graph1,
            type: 'surveys_bar_chart',
            title: t('barChart'),
            id: 1,
        },
        {
            Icon: Graph2,
            type: 'generic_pie_chart',
            title: t('pieChart'),
            id: 2,
        },
        {
            Icon: Graph3,
            type: 'lemmata_word_cloud',
            title: t('wordCloudChart'),
            id: 3,
        },
        {
            Icon: Graph4,
            type: 'radar_chart',
            title: t('radarChart'),
            id: 4,
        },
        {
            Icon: Graph5,
            type: 'nps_bubble_chart',
            title: t('bubbleChart'),
            id: 5,
        },
        {
            Icon: Graph6,
            type: 'custom_columns_table',
            title: t('tableChart'),
            id: 6,
        },
        {
            Icon: Graph7,
            type: 'stacked_bar_chart',
            title: t('stackedBarChart'),
            id: 7,
        },
        {
            Icon: Graph8,
            type: 'line_chart',
            title: t('lineChart'),
            id: 7,
        },
    ];

    return(
        <div className={ styles.graphIcons }>
            {
                chartsData.map(({ value }) => {
                    const findIconData = graphIcons.find(iconData => iconData.type === value);

                    return findIconData && (
                        <findIconData.Icon
                            id={ value }
                            className={ cx( { [styles.active]: selected[0] === findIconData?.type } ) }
                            key={ value }
                            title={ findIconData.title }
                            onClick={
                                () => onChange(selected[0] === findIconData?.type
                                    ? null
                                    : findIconData.type)
                            }
                        />
                    );
                })
            }
        </div>
    );
};
