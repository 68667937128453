import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { combineReducers } from 'redux';
import { connect } from 'react-redux';

import {
    CustomerJourney,
    Surveys,
    Export,
    Exporter,
    Imports,
    Survey,
    Customers,
    GlobalSettings,
    Theme,
    PreviewMode,
    ExportReducer,
    SurveyReducer as survey,
    GlobalSettingsReducer as globalSettings,
} from './scenes';
import {
    getLanguages,
    getUnitsList,
    getChannelList,
    getStates,
    getQuestionTypes,
    getQuestionTypeOptions,
    getPermissionList,
    getUserPermission,
    getSubState,
    CommonReducer as common,
} from './modules/VocFeedback.modules';
import { getCurrentUser } from '/modules';
import { dataForPermissionSelector, loaderSelector } from './modules/VocFeedback.selectors';

import { SurveysReducer as surveys } from './scenes/Surveys/modules';
import { reducer as preview } from './scenes/PreviewMode/modules/PreviewMode.modules';
import { ImportsReducer as imports } from './scenes/Imports/modules/imports.modules';
import { reducer as exporter } from './scenes/Exporter/modules/exporter.modules';
import { reducer as customers } from './scenes/Customers/modules/customers.modules';
import { JourneyMap as journeyMap } from './scenes/CustomerJourney/modules';
import { reducer as themeSettings } from './scenes/Theme/modules/theme.modules';

import { Preloader, VocSocketHoc } from '/components';

@connect(state => ({
    loading: loaderSelector(state),
    dataForPermission: dataForPermissionSelector(state),
}), {
    getLanguages,
    getUnitsList,
    getChannelList,
    getStates,
    getQuestionTypes,
    getQuestionTypeOptions,
    getPermissionList,
    getUserPermission,
    getCurrentUser,
    getSubState,
})
@VocSocketHoc('feedback')
export class VocFeedback extends Component {
    componentDidMount() {
        /**
         * TODO: Remove after responsive templating
         */
        document.querySelector('#react-xircl').classList.add('voc-container-fixed');
        document.querySelector('body').classList.add('voc-container-fixed');
        // document.querySelector('#header-container').classList.add('voc-container-fixed');
        this.props.getLanguages();
        this.props.getUnitsList();
        this.props.getChannelList();
        this.props.getStates();
        this.props.getQuestionTypes();
        this.props.getQuestionTypeOptions();

        this.props.getPermissionList();
        this.props.getCurrentUser();
        this.props.getSubState();
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps?.dataForPermission;
        const {
            userPermission,
            userSsoId,
            groupIds,
            unitIds,
        } = this.props.dataForPermission;

        if (
            (
                prev.groupIds !== groupIds
                || prev.userSsoId !== userSsoId
                || prev.unitIds !== unitIds
            )
            && groupIds?.length && userSsoId && unitIds?.length
            && !userPermission.loading && !userPermission.loaded
        ) {
            this.props.getUserPermission({ groupIds, userSsoId });
        }
    }

    componentWillUnmount() {
        document.querySelector('#react-xircl').classList.remove('voc-container-fixed');
        document.querySelector('body').classList.remove('voc-container-fixed');
    }

    render() {
        return (
            this.props.loading !== false
                ? <Preloader/>
                : <Switch>
                    <Route exact
                        path={ `${ this.props.match.url }/survey-list` }
                        component={ Surveys }
                    />
                    <Route exact
                        path={ `${ this.props.match.url }/customer-journey` }
                        component={ CustomerJourney }
                    />
                    <Route exact
                        path={ `${ this.props.match.url }/import` }
                        component={ Imports }
                    />
                    <Route
                        path={ `${ this.props.match.url }/export` }
                        component={ Export }
                    />
                    <Route
                        path={ `${ this.props.match.url }/survey/:surveyGroup` }
                        component={ Survey }
                    />
                    <Route
                        path={ `${ this.props.match.url }/preview-mode/:surveyId` }
                        component={ PreviewMode }
                    />
                    <Route
                        path={ `${ this.props.match.url }/customers` }
                        component={ Customers }
                    />
                    <Route
                        path={ `${ this.props.match.url }/settings` }
                        component={ GlobalSettings }
                    />
                    <Route
                        path={ `${ this.props.match.url }/theme/:themeId(\\d+)` }
                        component={ Theme }
                    />
                    <Route
                        path={ `${ this.props.match.url }/theme/create` }
                        component={ Theme }
                    />
                    <Route exact path={ [
                        `${ this.props.match.url }/exporter/create`,
                        `${ this.props.match.url }/exporter/:exporterId`,
                        `${ this.props.match.url }/exporter/copy/:exporterId`,
                    ] } component={ Exporter }/>
                    <Redirect to={ `${ this.props.match.url }/survey-list` }/>
                </Switch>
        );
    }
}

export const FeedbackReducer = combineReducers({
    export: ExportReducer,
    common,
    exporter,
    survey,
    globalSettings,
    themeSettings,
    surveys,
    preview,
    journeyMap,
    imports,
    customers,
});
