import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { autobind } from 'core-decorators';
import { CardHeader, Button, Card, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    getProjects, getProjectsExpanded, changeActiveProject,
    getSurveysByTouchPointId, cleanSurveysByTouchPointId, addTouchPoint,
    updateTouchPointSurveys, openTouchPointAccordion, editTouchPoint,
    closeTouchPointAccordion, removeTouchPointById, reorderTouchPoint,
    addProject, updateProject, deleteProject, deleteTouchPoint,
} from './modules/actions';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { addSurvey, sendInvite } from '../Surveys/modules/actions';
import { showToast, TooltipWrapper } from '/components';
import { ScrollUp } from '/components/ScrollUp/ScrollUp';
import {
    projectListSelector,
    allTouchpointsSelector,
    activeProjectSelector,
    touchPointsListSelector,
    projectLoadingSelector,
} from './modules/selectors';
import { commonLanguageSelector, unitsListSelector, userInfoSelector } from '/feedback/modules/VocFeedback.selectors';
import { getSurveysGroup, getSurveysGroupLangSet } from '/scenes/VocFeedback/modules/VocFeedback.modules';

import { NewSurveyModal, SendInvitation } from '/feedback/components';

import {
    AddTouchPointModal,
    ManageProjectsModal,
    TouchPointSettingsModal,
    AttachSurveyModal,
    TouchPoint,
} from './components/';

import { JourneyService, PageRequests } from '/services';

import './CustomerJourney.scss';

const mapDispatchToProps = {
    editTouchPoint, getProjects,
    getProjectsExpanded, changeActiveProject,
    getSurveysByTouchPointId, cleanSurveysByTouchPointId,
    addTouchPoint, updateTouchPointSurveys,
    openTouchPointAccordion, closeTouchPointAccordion,
    addProject, updateProject, getSurveysGroup,
    deleteProject, deleteTouchPoint, getSurveysGroupLangSet,
    removeTouchPointById, reorderTouchPoint,
    showToast, addSurvey, sendInvite, showConfirm,
};

const mapStateToProps = state => ({
    projectList: projectListSelector(state),
    touchpointsAll: allTouchpointsSelector(state),
    touchpoints: touchPointsListSelector(state),
    activeProject: activeProjectSelector(state),
    unitsList: unitsListSelector(state),
    languages: commonLanguageSelector(state),
    loading: projectLoadingSelector(state),
    isSupportUser: userInfoSelector(state),
});

const getInitialState = () => ({
    openModalNew: false,
    openModalTouchPoint: false,
    openModalTouchPointSettings: false,
    openModalManageProjects: false,
    openModalAttachSurvey: false,
    openModalInvite: false,
    dataForAttachSurveyModal:  {
        touchPointsSurveys: [],
        surveys: [],
    },
});

@connect(mapStateToProps, mapDispatchToProps)
export class CustomerJourney extends Component {
    state = {
        ...getInitialState(),
        touchPointId: null,
    };
    pageRequests = new PageRequests();
    headerRef = React.createRef();
    containerRef = React.createRef();

    componentDidMount() {
        this.props.getProjectsExpanded();
        window.addEventListener('scroll', this.windowScrollHandler);
    }

    componentWillUnmount() {
        this.pageRequests.cleanup();
        window.removeEventListener('scroll', this.windowScrollHandler);
    }

    @autobind
    openModal(value, key, type) {
        const hash = {
            [type]: true,
        };

        if (value && key) {
            hash[key] = value;
        }

        if(hash.openModalAttachSurvey) {
            this.getSurveysByTouchPointIdForModal(hash.touchPointId)
                .then(response => {
                    const surveys = response.results
                        .filter(survey => this.getFilteredByUnit(survey, survey.unit.id))
                        .map(survey => ({
                            value: String(survey.id),
                            label: survey.name,
                        }));

                    this.setState({
                        ...hash,
                        dataForAttachSurveyModal:  {
                            touchPointsSurveys: surveys,
                            surveys: surveys,
                        },
                    });
                });
        } else {
            this.setState({
                ...hash,
                dataForAttachSurveyModal:  {
                    touchPointsSurveys: [],
                    surveys: [],
                },
            });
        }
    }

    @autobind
    getFilteredByUnit(survey, unitId) {
        return this.props.unitsList.some(unit => {
            return unit.id === unitId
                && (
                    unit?.permissionsName?.includes('Editor')
                    || unit?.permissionsName?.includes('Manager')
                    || this.props.isSupportUser
                );
        });
    }

    @autobind
    getSurveysByTouchPointIdForModal(touchPointId) {
        return JourneyService.getSurveysByTouchPointId(touchPointId).promise;
    }


    @autobind
    windowScrollHandler() {
        const tableHeader = this.headerRef.current;
        const tableContainer = this.containerRef.current;
        const containerTop = tableContainer.getClientRects()[0].top;
        const sizeHeader = document.getElementsByClassName('header')[0].clientHeight;

        if (containerTop < sizeHeader) {
            tableHeader.classList.add('cjm__table-header--sticky');
            tableHeader.style.top = Math.abs(containerTop - sizeHeader) + 'px';
        } else {
            tableHeader.classList.remove('cjm__table-header--sticky');
            tableHeader.style.width = '100%';
        }
    }

    @autobind
    closeModal() {
        this.setState(getInitialState());
    }

    @autobind
    editTouchPoint(params) {
        const model = {
            name: params.name,
            url: params.url,
            project: params.project,
            id: this.state.touchPointId,
        };

        return this.props.editTouchPoint(model);
    }

    @autobind
    removeTouchPointById(touchPointId) {
        return this.props.removeTouchPointById(touchPointId);
    }

    @autobind
    setProject(id) {
        if (id !== this.props.activeProject) {
            this.props.changeActiveProject(id);
        }
    }

    @autobind
    getSurveysByTouchPointId(id) {
        const point = this.props.touchpoints.find(touchpoint => touchpoint.id === id);

        if (point && !point.loading) {
            const request = this.props.getSurveysByTouchPointId(id);

            this.pageRequests.addRequest(request, id);
        }
    }

    @autobind
    cancelRequest(id) {
        this.pageRequests.cleanupById(id);
    }

    @autobind
    closeHandler(id) {
        this.props.cleanSurveysByTouchPointId(id);
        this.props.closeTouchPointAccordion(id);
    }

    @autobind
    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    @autobind
    getProjectsDropDown() {
        const currentProject = this.props.projectList.find(project => project.id === this.props.activeProject);

        if (this.props.loading) {
            return (
                <span className='pull-left padding-l-15 project-dropdown-loader'>
                    <FontAwesomeIcon icon='circle-notch' size='lg' spin/>
                    <span> Loading...</span>
                </span>
            );
        }

        const title = currentProject && currentProject.name || 'Project';

        return (
            [
                <div key={ 1 }
                    className='selectedProjectWrapper pull-left'>

                    <ButtonDropdown
                        isOpen={ this.state.dropdownOpen }
                        toggle={ this.toggle }
                    >
                        <DropdownToggle
                            color="white"
                            outline
                            caret
                            className={ 'cjm_button__toggle' }
                        >
                            <TooltipWrapper value={ title }>
                                <span className='wrap'> { title }</span>
                            </TooltipWrapper>
                        </DropdownToggle>
                        <DropdownMenu className={ 'cjm__dropdown-menu' }>
                            { this.props.projectList.map(project =>
                                (
                                    <DropdownItem
                                        key={ project.id }
                                        className={ this.props.activeProject === project.id ? 'active' : '' }
                                        onClick={ () => this.setProject(project.id) }
                                    >
                                        <span>
                                            <TooltipWrapper value={ project.name }>
                                                <span className='cjm__dropdown-menu--item'>{ project.name } </span>
                                            </TooltipWrapper>
                                        </span>
                                    </DropdownItem>
                                )) }
                        </DropdownMenu>
                    </ButtonDropdown>

                </div>,

                <FontAwesomeIcon
                    key={ 2 }
                    icon='cog'
                    className={ 'pull-left openManageProjects' }
                    onClick={ () => this.openModal(null, null, 'openModalManageProjects') }
                />,
            ]
        );
    }

    renderHeader() {
        const unitsList = this.getFilteredUnitByPermit();

        return (
            <CardHeader className='panel__header panel__header--buttons'>

                <div className="panel__header-right-block">
                    <span className={ 'panel__header-title' }>Customer Journey</span>
                    {
                        this.getProjectsDropDown()
                    }
                </div>

                <div className={ 'panel__header-buttons' }>

                    <Button className={ 'panel__header-button' }
                        outline
                        color={ 'white' }
                        onClick={ () => this.openModal(null, null, 'openModalTouchPoint') }
                    >
                        <FontAwesomeIcon icon='plus'/>
                        <span> Add Touchpoint</span>
                    </Button>

                    <Button className={ 'panel__header-button' }
                        outline
                        color={ 'white' }
                        onClick={ () => unitsList.length && this.openModal(null, null, 'openModalNew') }
                        disabled={ !unitsList.length }
                    >
                        <FontAwesomeIcon icon='plus' className={ 'color-green' }/>
                        <span> Add Survey</span>
                    </Button>

                </div>
            </CardHeader>
        );
    }

    renderTableHeader() {
        return (
            <div className={ 'surveys-list-modern noselect' }>
                <div className={ 'surveys-list-modern-list' }>
                    <table className={ 'cjm__table-header table table-header table-hover table-fixed' } ref={ this.headerRef }>
                        <thead>
                            <tr>
                                <th>Group ID</th>
                                <th>Title</th>
                                <th>Channels / Status</th>
                                <th/>
                                <th/>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        );
    }

    renderTouchpointList() {
        const isPermitRemoveTouchPoint = this.props.unitsList
            ?.some(unit => unit.rights?.customerJourneyList?.isPermitRemoveTouchPoint);

        return (
            <div ref={ this.containerRef }>
                { this.renderTableHeader() }
                <div className='touchpoints-list fadeIn'>
                    {
                        !this.props.loading && this.props.touchpoints.map((touchpoint, index) => (
                            <TouchPoint
                                key={ touchpoint.id }
                                eventKey={ index }
                                title={ touchpoint.name }
                                url={ touchpoint.url }
                                id={ touchpoint.id }
                                expanded={ touchpoint.expanded }
                                enterHandler={ this.getSurveysByTouchPointId }
                                exitHandler={ this.cancelRequest }
                                loading={ touchpoint.loading }
                                surveys={ touchpoint.surveys }
                                channels={ touchpoint.channels }
                                openModal={ this.openModal }
                                showToast={ this.props.showToast }
                                openHandler={ this.props.openTouchPointAccordion }
                                closeHandler={ this.closeHandler }
                                removeTouchPointById={ this.removeTouchPointById }
                                showConfirm={ this.props.showConfirm }
                                unitsList={ this.props.unitsList }
                                filteredUnitByPermit={ this.getFilteredUnitByPermit() }
                                isPermitRemoveTouchPoint={ isPermitRemoveTouchPoint }
                                touchpoint
                            />
                        ))
                    }
                </div>
            </div>
        );
    }

    @autobind
    getFilteredUnitByPermit(){
        const { unitsList } = this.props;

        return unitsList.filter(
            unit => unit.id !== null && unit.rights.customerJourneyList.isPermitAddSurvey,
        ) || [];
    }

    @autobind
    onChangeSurveysData(surveys) {
        this.setState({
            ...this.state,
            dataForAttachSurveyModal: {
                ...this.state.dataForAttachSurveyModal,
                surveys,
            },
        });
    }

    render() {
        const isNoTouchpoints = !this.props.loading && !(this.props.touchpoints.length > 0);

        return (
            <DocumentTitle title="Feedback">
                <div className={ 'CJMView fadeIn' }>
                    <Card className={ 'page__wrapper' }>
                        { this.renderHeader() }
                        {
                            isNoTouchpoints
                            && <div className='project-empty fadeIn'>
                                <span>There are no Touchpoints in this Customer Journey.</span>
                            </div>
                        }
                        {
                            !isNoTouchpoints && this.renderTouchpointList()
                        }
                    </Card>
                    {
                        this.props.loading && <div className='preloader-progressbar'/>
                    }
                    <NewSurveyModal
                        close={ this.closeModal }
                        show={ this.state.openModalNew }
                        units={ this.getFilteredUnitByPermit() }
                        actionHandler={ this.props.addSurvey }
                    />
                    <AddTouchPointModal
                        getProjects={ this.props.getProjects }
                        show={ this.state.openModalTouchPoint }
                        projectList={ this.props.projectList }
                        actionHandler={ this.props.addTouchPoint }
                        close={ this.closeModal }
                    />
                    <ManageProjectsModal
                        projectList={ this.props.projectList }
                        touchpoints={ this.props.touchpointsAll }
                        editTouchPoint={ this.props.editTouchPoint }
                        show={ this.state.openModalManageProjects }
                        addProject={ this.props.addProject }
                        updateProject={ this.props.updateProject }
                        deleteProject={ this.props.deleteProject }
                        deleteTouchPoint={ this.props.deleteTouchPoint }
                        activeProject={ this.props.activeProject }
                        projectsLoadingState={ this.props.projectsLoadingState }
                        reorderTouchPoint={ this.props.reorderTouchPoint }
                        close={ this.closeModal }
                    />
                    <TouchPointSettingsModal
                        getProjects={ this.props.getProjects }
                        show={ this.state.openModalTouchPointSettings }
                        projectList={ this.props.projectList }
                        touchPoints={ this.props.touchpoints }
                        activeTouchPoint={ this.state.touchPointId }
                        actionHandler={ this.editTouchPoint }
                        close={ this.closeModal }
                    />

                    <AttachSurveyModal
                        show={ this.state.openModalAttachSurvey }
                        data={ this.state.dataForAttachSurveyModal || {} }
                        onChangeSurveysData={ this.onChangeSurveysData }
                        getFilteredByUnit={ this.getFilteredByUnit }
                        activeTouchPoint={ this.state.touchPointId }
                        actionHandler={ this.props.updateTouchPointSurveys }
                        close={ this.closeModal }
                        getSurveysGroup={ this.props.getSurveysGroup }
                    />

                    {
                        this.state.surveyGroup && this.state.surveyGroup.surveys.length > 0
                        && <SendInvitation
                            show={ this.state.openModalInvite }
                            surveyGroup={ this.state.surveyGroup }
                            languages={ this.props.languages }
                            actionHandler={ this.props.sendInvite }
                            close={ this.closeModal }
                            getSurveysGroupLangSet={ this.props.getSurveysGroupLangSet }
                        />
                    }
                    <ScrollUp/>
                </div>
            </DocumentTitle>
        );
    }
}

CustomerJourney.propTypes = {
    projectList: PropTypes.array,
    touchpoints: PropTypes.array,
    touchpointsAll: PropTypes.array,
    activeProject: PropTypes.number,
    unitsList: PropTypes.array,
};
