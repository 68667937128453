import React, { useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { SwitchControl, VocModal, VocSelect } from '/components';
import { convertDashboard, getDashboards, getOldDashboards, updateFilters } from '/visual/modules/VocVisual.modules';
import { dashboardsSelector, oldDashboardsSelector } from '/visual/modules/VocVisual.selectors';
import { IFieldsData, IModalProps } from './models';

import styles from './style.module.scss';

const initFieldsData = {
    as_default: false,
    as_favorite: false,
    dashboardName: '',
};

export const ImportOldDashboardModal = ({ toggle, show }: IModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dashboards = useSelector(oldDashboardsSelector);
    const { dashboards: { filters } } = useSelector(dashboardsSelector);

    const [ fieldsData, setFieldsData ] = useState<IFieldsData>(initFieldsData);
    const [ disabled, setDisabled ] = useState(false);

    const isApplyDisabled = dashboards.loading
        || !fieldsData.dashboard
        || !fieldsData.dashboardName?.trim();

    const onApply = () => {
        setDisabled(true);
        dispatch(convertDashboard({
            oldDashboardId: fieldsData?.dashboard?.value,
            title: fieldsData.dashboardName.trim(),
            isDefault: fieldsData.as_default,
            isFavourite: fieldsData.as_favorite,
        })).promise.then(
            () => {
                toggle();
                setDisabled(false);

                const filtersModel = {
                    ...filters,
                    page: 1,
                };

                dispatch(updateFilters(filtersModel));
                dispatch(getDashboards(filtersModel));
            },
            () => {
                setDisabled(false);
            });
    };

    const onChange = (fieldName: string, data: any) => {
        setFieldsData({
            ...fieldsData,
            [fieldName]: data,
        });
    };

    const onChangeDashboardTitle = event => {
        let name = event.target.value;

        if(name.indexOf(' ') === 0) {
            name = name.trim();
        }

        onChange('dashboardName', name);
    };

    const onClosed = () => setFieldsData(initFieldsData);

    useEffect(() => {
        show && dispatch(getOldDashboards());
    }, [ show ]);

    useEffect(() => {
        return () => {
            setDisabled(false);
        };
    }, []);

    useEffect(() => {
        fieldsData.dashboard && onChange('dashboardName', fieldsData.dashboard.label);
    }, [ fieldsData.dashboard ]);

    const getModalBody = () => (
        <>
            <div className={ styles.fieldWrapper }>
                <Label htmlFor='dashboard'>
                    { t('dashboard') }
                </Label>
                <VocSelect
                    portal
                    isHorizontal
                    name='dashboard'
                    placeholder={ t('field.selectDashboard') }
                    isClearable={ false }
                    isLoading={ dashboards.loading }
                    onChange={ data => onChange('dashboard', data) }
                    options={ dashboards.data }
                    value={ fieldsData.dashboard }
                />
            </div>
            <div className={ styles.fieldWrapper }>
                <Label htmlFor='dashboardName'>
                    { t('field.dashboardTitle') }
                </Label>
                <Input
                    name='dashboardName'
                    placeholder={ t('enterDashboardTitle') }
                    type='text'
                    disabled={ !fieldsData.dashboard }
                    value={ fieldsData.dashboardName }
                    onChange={ onChangeDashboardTitle }
                />
            </div>
            <div className={ styles.fieldWrapper }>
                <Label htmlFor='as_default'>
                    { t('setAsDefault') }
                </Label>
                <SwitchControl
                    name='as_default'
                    changeHandler={ () => onChange('as_default', !fieldsData.as_default) }
                    value={ fieldsData.as_default }
                />
            </div>
            <div className={ styles.fieldWrapper }>
                <Label htmlFor='as_favorite'>
                    { t('setAsFavorite') }
                </Label>
                <SwitchControl
                    name='as_favorite'
                    changeHandler={ () => onChange('as_favorite', !fieldsData.as_favorite) }
                    value={ fieldsData.as_favorite }
                />
            </div>
        </>
    );

    const getModalFooter = () => (
        <>
            <Button
                outline
                color='white'
                onClick={ toggle }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color='primary'
                disabled={ isApplyDisabled || disabled }
                onClick={ () => !isApplyDisabled && onApply() }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                { t('confirm') }
            </Button>
        </>
    );

    return (
        <VocModal
            modalClassName='modal-scrollable'
            header={ t('importOldDashboard') }
            size='ms'
            isOpen={ show }
            toggle={ toggle }
            onClosed={ onClosed }
            footer={ getModalFooter() }
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};
