import { createSelector } from 'reselect';
import { HelperService } from '/services';
import { statesSelector } from '/scenes/VocFeedback/modules/VocFeedback.selectors';

export const exportCommonSelector = state => state.VocFeedback.export.common;

export const allExportersSelector = state => exportCommonSelector(state).exporters;

export const allSurveysGroupSelector = createSelector([ exportCommonSelector, statesSelector ], (common, states) => {
    return {
        results: common.surveys.results.map(group => {
            return {
                ...group,
                surveys: group.surveys.map(survey => {
                    const surveyState = states.survey.find(state => state.id === survey.state);

                    return {
                        ...survey,
                        stateName: surveyState && surveyState.name,
                    };
                }),
            };
        }),
        total: common.surveys.count,
        loading: common.surveys.loading,
    };
});

export const isLoadedPermissionSelector = state =>
    state.VocFeedback.common.userPermission.loading
    || !state.VocFeedback.common.userPermission.loaded;

export const mapExportersToSelectModel = createSelector([ allExportersSelector ], exporters => {
    return {
        loading: exporters.loading,
        results: exporters.results.map(exporter => ({
            value: String(exporter.id),
            label: exporter.name,
            //todo remove after delete old exporters
            ... exporter.isNewExporter
                ? { isNewExporter: exporter.isNewExporter }
                : {},
        })),
        defaultExporter: exporters.results.find(exporter => exporter.name === 'Default Exporter' && exporter.isNewExporter),
    };
});

export const mapSurveysToModal = createSelector([ allSurveysGroupSelector ], surveys => {
    return {
        loading: surveys.loading,
        total: surveys.total,
        results: surveys.results
            .map(group => ({
                channels: group.surveys
                    .filter(survey => survey.stateName !== 'deleted')
                    .map(survey => ({
                        label: survey.channel,
                        value: String(survey.id),
                    })),
                value: String(group.id),
                label: `${group.id}-${group.name}`,
                title: `${group.id}-${group.name}`,
                unit: HelperService.deepFind(group, 'unit.id'),
            })),
    };
});


export const optionLabel = isNewExporter => isNewExporter
    ? {
        title: 'NEW',
        color: 'success',
    }
    : {
        title: 'OLD',
        color: 'danger',
    };
