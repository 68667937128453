import { createSelector } from 'reselect';
import { UsersRoleService } from '/services';
import { userInfoSelector } from "/modules/selectors";
import { _getAllUserInfo } from "/modules/rightsModules/rights.selectors";

const _getSegmentatorsSlice = state => state.VocStore.SegmentatorsSlice;

export const segmentatorsSelector = state => _getSegmentatorsSlice(state).segmentators;
export const filtersSelector = state => _getSegmentatorsSlice(state).filters;
export const loadingSelector = state => _getSegmentatorsSlice(state).loading;
export const segmentatorsCountSelector = state => _getSegmentatorsSlice(state).segmentators.count;

export const segmentatorsTableDataSelector = createSelector(
    [ segmentatorsSelector, userInfoSelector, _getAllUserInfo ],
    (segmentators, { isSupportUser, firstName: currentUserFirstName }, allUsersData) => {
        return segmentators.data.map(segmentator => {
            const {
                name,
                segmentatorAppliances,
                created,
                updated,
                id,
                segments,
                segmentType,
                currentUserPermission,
            } = segmentator;

            const rights = UsersRoleService.getRightsForRecoded(
                isSupportUser,
                currentUserPermission,
                currentUserFirstName,
                allUsersData,
                segmentator.user.ssoId,
            );

            const appliance = segmentatorAppliances[0];
            const appliedSourceName = appliance && appliance.sourceName;
            const appliedSourceId = appliance && appliance.dataId;
            const virtualFieldName = appliance && appliance.virtualFieldName;
            const applianceId = appliance && appliance.id;
            const sourceType = appliance && appliance.sourceType;
            const placeholdersMapping = appliance && appliance.placeholdersMapping;

            return {
                created,
                updated,
                sourceType: {
                    name: sourceType,
                    color: [ 'survey', 'system' ].includes(sourceType) ? 'info' : 'success',
                },
                actionsData: {
                    id,
                    name,
                    virtualFieldName,
                    segments,
                    placeholdersMapping,
                    applianceId,
                    sourceId: appliedSourceId,
                    segmentType,
                    rights,
                },
                name,
                segmentatorSources: appliedSourceName,
            };
        });
    });
