import React from 'react';

export function VocCleaupHOC(Component) {
    return class VocRequestClean extends React.Component {
        componentRef = React.createRef();

        componentWillUnmount() {
            this.props.cleanup();

            if (this.componentRef.current.pageRequests) {
                this.componentRef.current.pageRequests.cleanup();
            }
        }

        render() {
            return <Component { ...this.props } ref={ this.componentRef }/>;
        }
    };
}
