import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { autobind } from 'core-decorators';

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './UnitsFilter.scss';

export class UnitsFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    @autobind
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        const setFilter = value => {
            this.props.applyFilter({
                unit: value.id,
                unitLabel: value.name,
                page: 1,
                loading: true,
            });
        };

        return (
            <div className='pull-left'>
                <ButtonDropdown
                    className={ 'units__filter-btn' }
                    isOpen={ this.state.isOpen }
                    toggle={ this.toggle }
                >
                    {
                        this.props.unitLabel
                            ? <DropdownToggle caret color={ 'white' } outline> { this.props.unitLabel } </DropdownToggle>
                            : null
                    }

                    <DropdownMenu>
                        {
                            this.props.unitsList.map((value, index) => {
                                return index === 0 && value.id === null
                                    ? (
                                        <div key={ index }>
                                            <DropdownItem key='all'
                                                onClick={ () => setFilter(value) }
                                                className={ !this.props.unit ? 'active' : '' }
                                            >
                                                { value.name }
                                            </DropdownItem>
                                            <DropdownItem key='divider' divider/>
                                        </div>
                                    )
                                    : (
                                        <DropdownItem
                                            key={ index }
                                            className={ this.props.unit === value.id ? 'active' : '' }
                                            onClick={ () => setFilter(value) }
                                        >
                                            { value.name }
                                        </DropdownItem>
                                    );
                            })
                        }
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        );
    }
}

UnitsFilter.propTypes = {
    applyFilter: PropTypes.func,
    unitLabel: PropTypes.any,
    unit: PropTypes.any,
    unitsList: PropTypes.array,
};
UnitsFilter.defaultProps = {};
