import React, { Fragment, Component } from 'react';
import { SettingFieldContainer } from '../components';
import { DynamicGroupFieldView } from './components';
import { autobind } from 'core-decorators';
import { FieldArray } from 'redux-form';
import PlusIcon from '/images/plus_sign.png';

export * from './DynamicGroupField.scss';

export class DynamicGroupField extends Component {
    state = {};

    @autobind
    addFieldsGroup() {
        const initialValue = [{
            field_name: null,
            source_type: 1,
            source: null,
        }];

        const {
            input: {
                onChange,
                value,
            },
        } = this.props;

        const model = {
            ...value,
            value: value.value
                ? [
                    ...value.value,
                    ...initialValue,
                ]
                : [
                    ...initialValue,
                ],
        };

        onChange(model);
    }

    render() {
        const {
            input,
            options,
            change,
            meta: {
                error,
                dirty,
            },
            label,
            input: {
                value: {
                    redefined,
                },
                name,
            },
        } = this.props;

        return (
            <Fragment>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                <SettingFieldContainer
                    checked={ redefined }
                    field={ input }
                    className={ `${ dirty ? 'changed' : '' }` }
                >
                    <div className={ 'setting-dynamic-group__container' }>
                        <button
                            className={ 'setting-dynamic-group_button' }
                            onClick={ this.addFieldsGroup }
                            disabled={ !redefined }
                        >
                            <span> Add </span>
                            <img src={ PlusIcon } alt=""/>
                        </button>

                        <FieldArray name={ `${ name }.value` } component={ DynamicGroupFieldView }
                            props={{ ...options, change, error, disabled: !redefined }}/>
                    </div>
                </SettingFieldContainer>
            </Fragment>
        );
    }
}

DynamicGroupField.defaultProps = {
    withError: true,
};
