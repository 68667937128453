//This is deprecated stuff - will be removed in next time
export class VoCVisualDBService {
    static updateVisualDB(id) {
        const DBOpenRequest = indexedDB.open('VoC-Visual-v3', 10);

        DBOpenRequest.onsuccess = function() {
            const onRecordDeleted = function() {
                console.info('Record from Visual DB was deleted!');
            };

            try {
                const transaction = DBOpenRequest.result.transaction([ 'UploadsCache' ], 'readwrite'),
                    objectStore = transaction.objectStore('UploadsCache');

                objectStore.openCursor().onsuccess = function(event) {
                    const cursor = event.target.result;
                    const { uploads } = cursor.value;

                    if (uploads[id]) {
                        delete uploads[id];

                        const request = cursor.update({ timestamp: cursor.value.timestamp, uploads });

                        request.onsuccess = onRecordDeleted;
                    }
                };
            } catch(e) {
                console.error('Error in UploadsCache --- custom log');
            }
        };
    }
}
