import { CALL_API } from 'store/middleware/api';
import {
    CREATE_NOTIFICATION_ITEM,
    UPDATE_NOTIFICATION_ITEM,
    REMOVE_NOTIFICATION_ITEM,
    REMOVE_NOTIFICATION_REQUEST,
    CLEAR_ALL_NOTIFICATIONS,
} from './actions';

const initialState = {
    notifications: [],
};

export function NotificationsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE_NOTIFICATION_ITEM: {
            return {
                ...state,
                notifications: [
                    action.data,
                    ...state.notifications,
                ],
            };
        }
        case UPDATE_NOTIFICATION_ITEM: {
            return {
                ...state,
                notifications: [
                    ...state.notifications.map(notification => {
                        if (notification.id === action.data.id) {
                            return action.data;
                        }
                        return notification;
                    }),
                ],
            };
        }
        case REMOVE_NOTIFICATION_ITEM: {
            return {
                ...state,
                notifications: [
                    ...state.notifications
                        .filter(notification => notification.id !== action.data.id),
                ],
            };
        }

        default:
            return state;
    }
}

export function removeNotification(url) {
    return {
        [CALL_API]: {
            endpoint: url,
            method: 'GET',
            types: [ REMOVE_NOTIFICATION_REQUEST ],
        },
    };
}

export function clearAllNotifications() {
    return {
        [CALL_API]: {
            endpoint: '/socket_notification/clear_all',
            method: 'GET',
            types: [ CLEAR_ALL_NOTIFICATIONS ],
        },
    };
}
