import React from 'react';
import PropTypes from 'prop-types';
import { FormText } from 'reactstrap';
import './VocFormSwitchControl.scss';

export const VocFormSwitchControl = ({
    input,
    className,
    type,
    label,
    placeholder,
    required,
    withError,
    disabled,
    size,
    meta: {
        touched,
        error,
    },
}) => {
    return (
        <div className={ `${ className } app-form-control__container switch-wrap ${ withError ? 'with-error' : '' } ${ disabled ? 'disabled' : '' }` }>
            <input className="switch__checkbox app-form-control"
                id={ input.name }
                checked={ input.value }
                type={ type }
                placeholder={ placeholder }
                required={ required }
                disabled={ disabled }
                { ...input }
            />
            <label className={ `switch switch__${ size }` } htmlFor={ input.name }>
                <span className={ `switch__toombler switch__toombler__${ size }` }/>
            </label>
            {
                Boolean(touched && error && withError)
                    ? <FormText className="app-form-control__error">{ error }</FormText>
                    : false
            }
        </div>
    );
};

VocFormSwitchControl.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    withError: PropTypes.bool,
    label: PropTypes.string,
};

VocFormSwitchControl.defaultProps = {
    withError: true,
    type: 'checkbox',
    className: '',
    disabled: false,
};
