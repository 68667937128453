import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';
import { Link } from 'react-router-dom';
import { HelperService } from '/services';

const filterProperties = [ 'id', 'last_interaction', 'creation' ];

export const ThemesActionsFormatter = ({
    id,
    openConfirmDelete,
    openConfirmCopy,
    openDetails,
    theme,
    showToast,
    initRunnerDemo,
    isPermitEdit,
}) => {
    const handleDelete = () => {
        if (theme.unit && isPermitEdit) {
            showToast({ text: 'You cannot delete the default theme', type: 'info' });
        } else if(isPermitEdit) {
            openConfirmDelete(id);
        }
    };

    const handleCopy = () => {
        isPermitEdit
        && openConfirmCopy({
            ...HelperService.filterObjectProperties(theme, filterProperties),
            copy_digi_theme_id: theme.id,
            unit: null,
        });
    };

    const handleRunnerDemo = () => {
        initRunnerDemo(id);
    };

    const handleOpenDetails = () => {
        openDetails(theme);
    };

    const isDeleteDisabled = id === 1 || !!theme.unit;

    return (
        <div
            className='table-action__container'>
            <TooltipWrapper value={ 'Details ' } force>
                <span>
                    <FontAwesomeIcon icon="info-circle" onClick={ handleOpenDetails }/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Edit theme ' } force>
                <span>
                    <Link to={ `/feedback/theme/${ id }/theme-settings` }>
                        <FontAwesomeIcon icon="cog"/>
                    </Link>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Copy theme ' } force>
                <span
                    className={ cx(
                        'theme-list__item',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ handleCopy }
                >
                    <FontAwesomeIcon icon="copy"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Delete theme ' } force>
                <span
                    className={ cx(
                        'theme-list__item',
                        { 'disabled': isDeleteDisabled || !isPermitEdit },
                    ) }
                    onClick={ handleDelete }
                >
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Demo ' } force>
                <span
                    className={ cx(
                        'theme-list__item',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ handleRunnerDemo }
                >
                    <FontAwesomeIcon icon="eye"/>
                </span>
            </TooltipWrapper>
        </div>
    );
};
