import { useState, useRef, useEffect, MutableRefObject, MouseEventHandler, MouseEvent } from 'react';

// Use hoverRef to indicate which element and his child should be triggered on hover
export const useHover = <T>(disabled: boolean, delayOut?: number): {
    isHovered: boolean,
    hoverRef: MutableRefObject<T | null>
    mouseOver: MouseEventHandler<T>,
    mouseOut: (event?: MouseEvent<T>) => void,
} => {
    const [ value, setValue ] = useState(false);
    const hoverRef: MutableRefObject<T | null> = useRef<T | null>(null);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            timerRef.current && clearTimeout(timerRef.current);
        };
    }, []);

    const mouseOver = (event: MouseEvent<T>) => {
        !disabled && isMounted.current && setValue(true);

        return event;
    };

    const mouseOut = (event?: MouseEvent<T>) => {
        timerRef.current && clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            isMounted.current && setValue(false);
        }, delayOut || 0);

        return event;
    };

    return {
        isHovered: value,
        hoverRef,
        mouseOver,
        mouseOut,
    };
};
