import React, { Fragment, Component } from 'react';
import { SettingFieldContainer } from '../components';
import { SettingControlGroup, SettingControlGroupValue } from './components';
import { autobind } from 'core-decorators';
import { FormText } from 'reactstrap';

export * from './SettingControlGroupField.scss';

export class SettingControlGroupField extends Component {
    state = {
        emails: [],
        currentEmail: '',
        customError: '',
    };

    @autobind
    onFocus() {
        this.props.input.onFocus(this.props.input.value);
    }

    @autobind
    onBlur() {
        this.props.input.onBlur(this.props.input.value);
    }

    @autobind
    addEmail() {
        const {
            input: {
                onChange,
                value,
            },
            errorMessage,
        } = this.props;

        const { currentEmail } = this.state;

        if(!this.validateByRegex(currentEmail)) {
            this.setState({ customError: errorMessage });
            return;
        }

        if (currentEmail) {
            const model = {
                ...value,
                value: [ ...value.value, currentEmail ],
            };

            onChange(model);
            this.setState({ currentEmail: '', customError: '' });
        }
    }

    @autobind
    setEmail(e) {
        this.setState({ currentEmail: e.target.value, customError: '' });
    }

    @autobind
    deleteEmail(element) {
        const {
            input: {
                onChange,
                value,
            },
        } = this.props;

        const model = {
            ...value,
            value: value.value.filter(item => item !== element),
        };

        onChange(model);
    }

    validateByRegex(value) {
        const { regexData } = this.props;

        return regexData
            ? new RegExp(regexData.regex, regexData.flag).test(value)
            : true;
    }

    render() {
        const {
            input,
            withError,
            meta: {
                error,
                touched,
                dirty,
            },
            label,
            input: {
                name,
                value: {
                    redefined,
                    value = [],
                },
            },
            placeholder,
        } = this.props;

        const { currentEmail, customError } = this.state;

        return (
            <Fragment>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                <SettingFieldContainer
                    checked={ redefined }
                    field={ input }
                    className={ `${ dirty ? 'changed' : '' }` }
                >
                    <div className={ 'setting-control-group__container' }>
                        <SettingControlGroup
                            addEmail={ this.addEmail }
                            value={ currentEmail }
                            setEmail={ this.setEmail }
                            checked={ redefined }
                            placeholder={ placeholder }
                            type={ name }
                        />
                        {
                            value.length
                                ? <div className={ 'setting-control-group__emails-container' }>
                                    <h5 className={ 'setting-control-group__emails-title' }>Added values</h5>
                                    {
                                        value.map((email, index) =>
                                            <SettingControlGroupValue
                                                value={ email }
                                                key={ index }
                                                invalid={ error && error.id && error.id.find(id => +id === index) }
                                                deleteEmail={ this.deleteEmail }
                                                checked={ redefined }
                                            />)
                                    }
                                </div> : null
                        }
                    </div>

                    {
                        Boolean((touched && error && withError) || customError)
                            ? <FormText className="app-form-control__error">{ customError || error.message }</FormText>
                            : false
                    }

                </SettingFieldContainer>
            </Fragment>
        );
    }
}

SettingControlGroupField.defaultProps = {
    withError: true,
};
