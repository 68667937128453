import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import uuidV4 from 'uuid/v4';
import { useTranslation } from 'react-i18next';

import { VocModal, Preloader } from '/components';
import { Tabs } from '/visual/scenes/Dashboard/components';
import {
    getDashboardFilterData,
    reloadAllGadgets,
    sendDashboardFilter,
} from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import {
    dashboardFilterActiveSource,
    dashboardFilterData,
} from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { ITab } from '/visual/models';
import { HelperService, MetaQueryService } from '/services';
import { General, MetadataFilter } from './components';

import styles from './style.module.scss';

interface IDashboardFilterModalProps {
    cleanupModal?: () => void;
    show: boolean,
    toggle: () => void;
    dashboardId: string
}

export const DashboardFilterModal = ({
    show,
    toggle,
    cleanupModal,
    dashboardId,
}: IDashboardFilterModalProps) => {
    const { t } = useTranslation();
    const emptyQuery = {
        hash: 'root',
        items: [
            {
                id: '',
                operator: '',
                value: null,
                hash: uuidV4(),
                items: [],
            },
        ],
    };

    const dispatch = useDispatch();

    const { dataFilter, loading } = useSelector(dashboardFilterData);
    const activeSource = useSelector(dashboardFilterActiveSource);

    const [ activeTab, setActiveTab ] = useState<ITab['id']>('general');
    const [ filters, setFilters ]: any = useState(dataFilter);
    const [ isValidQuery, setIsValidQuery ] = useState(true);
    const [ metaQuery, setMetaQuery ] = useState({});

    useEffect(() => {
        show && dispatch(getDashboardFilterData(dashboardId));
    }, [ show ]);

    useEffect(() => {
        if(activeSource) {
            setFilters(dataFilter);

            if(!loading) {
                const activeSourceFilters = dataFilter.sourceDataFilters.find(source => source.sourceId === activeSource.id);

                const metaQueryData = HelperService.deepClone(activeSourceFilters.metadataQuery)
                const initMetaQuery = metaQueryData
                    ? MetaQueryService.serverToClientFormatter({ query: metaQueryData })
                    : emptyQuery;

                setMetaQuery(initMetaQuery);
            }
        }
    }, [ activeSource, loading ]);

    const setFiltersHandler = filterData => {
        const newFilters = { ...filters };
        const { sourceDataFilters, dateFilter } = newFilters;
        const changedSourceIndex = sourceDataFilters.findIndex(source => source.sourceId === activeSource.id);

        if(!filterData.dateFilter && !filterData.thresholdFilters) {
            sourceDataFilters[changedSourceIndex] = {
                ...sourceDataFilters[changedSourceIndex],
                ...filterData,
            };
        }

        setFilters({
            ...filters,
            ...filterData.thresholdFilters ? filterData.thresholdFilters : {},
            dateFilter: filterData.dateFilter ? filterData.dateFilter : dateFilter,
            sourceDataFilters,
        });
    };

    const sendFilters = () => {
        const { dateFilter, sourceDataFilters, recThreshold, recThresholdEnabled } = filters;

        const filterRequestData = {
            recThreshold,
            recThresholdEnabled,
            dateFilter,
            sourceDataFilters: sourceDataFilters.map(({ dateFilter, ...filter }) => filter),
        };

        dispatch(sendDashboardFilter(dashboardId, filterRequestData)).promise.then(() => {
            toggle();
            dispatch(reloadAllGadgets());
        });
    };

    const onClosedHandler = () => {
        setActiveTab('general');
        cleanupModal && cleanupModal();
    };

    const tabs: ITab[] = [
        {
            title: t('general'),
            component:
                <General
                    filters={ filters }
                    onChange={ setFiltersHandler }
                />,
            id: 'general',
        },
        {
            title: t('metadataFilter'),
            component:
                <MetadataFilter
                    metaQuery={ metaQuery }
                    setIsValidQuery={ setIsValidQuery }
                    onChange={ setFiltersHandler }
                />,
            id: 'metadata_filter',
        },
    ];

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color={ 'white' }
                onClick={ toggle }
            >
                <FontAwesomeIcon icon={ 'times' } />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color={ 'primary' }
                onClick={ sendFilters }
                disabled={ !isValidQuery }
            >
                <FontAwesomeIcon icon={ 'check' } />
                &nbsp;
                { t('save') }
            </Button>
        </div>
    );

    return (
        <VocModal
            modalClassName={ 'modal-scrollable' }
            isOpen={ show }
            toggle={ toggle }
            header={ t('dashboardFilter') }
            footer={ getModalFooter() }
            onClosed={ onClosedHandler }
            size={ 'md' }
        >
            <div className={ styles.modalInner }>
                {
                    !loading && Object.keys(filters).length
                        ? <Tabs
                            activeTab={ activeTab }
                            tabs={ tabs }
                            tabSelectorClassname={ styles.customTabsSelectorWrapper }
                            onChange={ setActiveTab }
                        />
                        : <Preloader />
                }
            </div>
        </VocModal>
    );
};
