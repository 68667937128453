import React from 'react';
import { TooltipWrapper } from '/components';
import { components } from 'react-select';
import { Badge } from 'reactstrap';

import './ComponentValue.scss';

export const ComponentValue = ({ children, ...props }) => {
    const valueItem = props.getValue();
    const item = valueItem[0];

    return (
        <div className={ 'component-value-label_container' }>
            {
                props.hasValue
                    ? <TooltipWrapper value={ item.label }>
                        <div>
                            <components.ValueContainer { ...props }>
                                <div className={ 'component-value-label_title' }>
                                    { children }
                                </div>
                                {
                                    Boolean(item?.badge)
                                    && <div className={ 'component-value-label_badge' }>
                                        <Badge color={ item.badge.color }>
                                            { item.badge.title }
                                        </Badge>
                                    </div>
                                }
                            </components.ValueContainer>
                        </div>
                    </TooltipWrapper>
                    : <div className="component-value-label_title">
                        <components.ValueContainer { ...props }>
                            { children }
                        </components.ValueContainer>
                    </div>
            }
        </div>
    );
};
