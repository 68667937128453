import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentTitle from 'react-document-title';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { cleanupSystemUpload, getSystemUpload } from './modules/VocSystemUpload.modules';
import { showApplianceModal, cleanup } from '/modules/segmentatorModules/segmentator.modules.js';
import { VocStoreRootSelector } from '/modules/segmentatorModules/segmentator.selectors.js';
import { showExportFilterModal } from '/scenes/VocStore/modules/VocStore.modules';
import { systemUploadSelect } from './modules/VocSystemUpload.selectors';
import { ApplianceModal } from '/components';
import { PageRequests } from '/services';
import { SystemUploadTable } from './components';
import { SourceDetailsModal } from '../../components';

const pageRequests = new PageRequests();

export const VocSystemUpload = () => {
    const { t } = useTranslation();
    const { loading, tableData, total } = useSelector(systemUploadSelect);
    const [ showSourceDetails, useShowSourceDetails ] = useState(false);
    const [ sourceDetailsData, useSourceDetailsData ] = useState(null);
    const VocStoreRoot = useSelector(VocStoreRootSelector);
    const dispatch = useDispatch();

    const requestTrigger = () => {
        const request = dispatch(getSystemUpload());

        pageRequests.addRequest(request);
    };

    useEffect(() => {
        requestTrigger();

        return () => {
            dispatch(cleanup());
            dispatch(cleanupSystemUpload());
            pageRequests.cleanup();
        };
    }, []);

    const toggleSourceDetailsModal = detailsData => {
        useShowSourceDetails(!showSourceDetails);
        useSourceDetailsData(
            detailsData
                ? detailsData
                : sourceDetailsData,
        );
    };

    return (
        <>
            <DocumentTitle title={ t('systemSource') }>
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons card-header' }>
                        <span className={ 'panel__header-title' }>
                            <FontAwesomeIcon icon='list-ul' />
                            &nbsp;
                            { t('systemSource') }
                        </span>
                        <div className={ 'panel__header-buttons' }/>
                    </CardHeader>
                    <CardBody>
                        <SystemUploadTable
                            requestTrigger={ requestTrigger }
                            loading={ loading }
                            tableData={ tableData }
                            total={ total }
                            showApplianceModal={ obj => dispatch(showApplianceModal(obj)) }
                            toggleSourceDetailsModal={ toggleSourceDetailsModal }
                            initiateSourceExport={
                                (uuidMongo, attrByDate, type) => dispatch(showExportFilterModal(uuidMongo, attrByDate, type))
                            }
                        />

                        <ApplianceModal
                            VocStoreRoot={ VocStoreRoot }
                        />

                        <SourceDetailsModal
                            sourceDetailsData={ sourceDetailsData }
                            show={ showSourceDetails }
                            toggle={ toggleSourceDetailsModal }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        </>
    );
};
