import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

import './QuarantinesListActionsFormatter.scss';

export const QuarantinesListActionsFormatter = ({ ...props }) => {
    const { permission: { isPermitEdit } } = props;
    const openModalDelete = () => isPermitEdit && props.openModalDelete(props.id);
    const openModalEdit = () => isPermitEdit && props.openModalEdit(props.id);

    return (
        <div
            id={ 'table-action__container' }
            className='table-action__container table-action__exporters-list'
        >
            <TooltipWrapper value={ 'Edit quarantine' } force>
                <span
                    className={ cx(
                        'table-action__exporters-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ openModalEdit }>
                    <FontAwesomeIcon icon="cog"/>
                </span>
            </TooltipWrapper>
            <TooltipWrapper value={ ' Delete quarantine' } force>
                <span
                    className={ cx(
                        'table-action__exporters-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ openModalDelete }
                >
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>
        </div>
    );
};
