import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { VocModal, VocSelectMine } from "/components";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePreviousProps } from "/services";
import { setSelectedDictionaries } from '/query/modules/query.modules';

import './DictionariesModal.scss';

export const DictionariesModal = ({
    dictionaryOptions,
    selectedDictionaries,
    type,
    show,
    toggle,
}) => {
    const dispatch = useDispatch();
    const dictionariesListRef = useRef(null);
    const [ selectedItems, setSelectedItems ] = useState([]);
    const prevValue = usePreviousProps(selectedItems);

    useEffect(() => {
        show && setSelectedItems(selectedDictionaries[type]);
    }, [ show ]);

    useEffect(() => {
        const { current } = dictionariesListRef;

        if (current) {
            if (prevValue && selectedItems && prevValue.length < selectedItems.length) {
                current.scroll(0, current.scrollHeight);
            }
        }
    }, [ selectedItems ]);

    const onSubmitDictionary = () => {
        const selectedIds = selectedItems.map(({ value }) => value);
        const selectedDictionariesModel = Object.keys(selectedDictionaries)
            .reduce((acc, val) => (
                {
                    ...acc,
                    [val]: val === type
                        ? selectedIds
                        : selectedDictionaries[val].map(({ value }) => value)
                            .filter(id => !selectedIds.includes(id)),
                }
            ), {});

        dispatch(
            setSelectedDictionaries(selectedDictionariesModel),
        );

        toggle();
    };

    const getModalBody = () => (
        <Row className={ 'dictionaries-modal__wrapper' }>
            <Col md={ 12 }>
                <VocSelectMine
                    additionalValueKey={ 'userName' }
                    placeholder={ 'Select dictionary' }
                    globalClass={ 'classifier-multi-select__row' }
                    className={ 'dictionaries-modal__select' }
                    value={ selectedItems }
                    options={ dictionaryOptions }
                    onChange={ setSelectedItems }
                    closeMenuOnSelect={ false }
                    filterName={ 'No dictionaries selected' }
                    id={ 'dictionary' }
                    isMulti
                    isMultiOutside
                    withoutActionButton
                />
            </Col>
        </Row>
    );

    const getModalFooter = () =>
        <>
            <Button onClick={ toggle }
                outline
                color={ 'white' }
            >
                <FontAwesomeIcon icon="times"/>
                <span> Cancel</span>
            </Button>
            <Button
                disabled={ !selectedItems.length && !dictionaryOptions.length }
                onClick={ onSubmitDictionary }
                color={ 'primary' }>
                <FontAwesomeIcon icon="check"/>
                <span> Save</span>
            </Button>
        </>;

    return (
        <VocModal
            isOpen={ show }
            onClosed={ () => setSelectedItems(selectedDictionaries[type]) }
            toggle={ toggle }
            header={ type === 'synonyms' ? 'Synonyms' : 'Stop words' }
            footer={ getModalFooter() }
            size="lg"
        >
            { getModalBody() }
        </VocModal>
    );
};
