import { createSelector } from 'reselect';
import { HelperService, UsersRoleService } from '/services';
import { isSupportUserSelector } from "/modules/selectors";
import { SocialSourceService } from "../../../services";

const pickProps = [
    'id',
    'created',
    'afterloadedAt',
];

const _getUploadList = state => state.VocStore.uploadsList;
const _getImporterInfo = state => _getUploadList(state).importerInfo;

export const sourcesInfoSelector = state => _getUploadList(state).uploadInfo;

export const uploadsSelector = state => _getUploadList(state).uploads;
export const filtersSelector = state => _getUploadList(state).filters;
export const loadingSelector = state => _getUploadList(state).loading;
export const uploadsCountSelector = state => _getUploadList(state).uploads.count;
export const uploadToDeleteSelector = state => {
    const { uploadToDelete } = _getUploadList(state);
    const { classifiers, gadgets, queries, segmentators } = uploadToDelete;

    uploadToDelete.needToShowDetails = ![
        classifiers.length,
        gadgets.length,
        queries.length,
        segmentators.length,
    ].includes(0);

    return uploadToDelete;
};

export const importerInfoLoadingSelector = state => _getImporterInfo(state).loading;

export const importerInfoQqlSelector = createSelector([ _getImporterInfo ], ({ data: { settings } }) => settings?.qql);

export const syncLoadingSelector = createSelector([ _getUploadList ], ({
    syncLoading,
    followLoading,
}) => ({ syncLoading, followLoading }));

export const importerInfoSelector = t => createSelector([ _getImporterInfo ], ({ data: { settings } }) => {
    return settings ? [
        {
            title: t('usernameClientId'),
            value: settings.login,
        },
        {
            title: t('passwordSecret'),
            value: settings.password,
        },
        {
            title: t('profileId'),
            value: settings.profileId,
        },
        {
            title: t('query'),
            value: settings.qql,
        },
    ] : [];
});

export const socialUploadInfoSelector = selectedUploadId => createSelector([ uploadsSelector ], ({ data }) => {
    const socialUpload = data.find(upload => upload.importer?.id === selectedUploadId);

    return socialUpload
        ? {
            prevIsFollowed: SocialSourceService.formatStatusToClient(socialUpload.importer.status),
            language: socialUpload.importer.language?.toUpperCase() || 'N/A',
            title: socialUpload.title,
            startFrom: HelperService.formatDefaultDate(socialUpload.importer.startSyncFrom),
        }
        : {
            prevIsFollowed: false,
            language: '',
            title: '',
            startFrom: '',
        };
});

export const uploadListTableDataSelector = createSelector([ uploadsSelector, isSupportUserSelector ],
    (uploads, isSupportUser) => {
        return uploads.data.map(upload => {
            const {
                processedItemsCount = 0,
                failedItemsCount,
                totalItemsCount,
                totalRowsCount,
                currentUserPermission,
            } = upload;

            let { cancelledItemsCount = 0 } = upload;

            const attrByDate = upload.headerRowType.filter(attribute => attribute.type === 'DATE');
            const hasAttrText = upload.headerRowType.some(attribute => attribute.type === 'TEXT');

            let progressItemsCount = 0;

            const count = totalItemsCount - processedItemsCount - failedItemsCount;

            if (upload.cancelled) {
                cancelledItemsCount = count;
            } else {
                progressItemsCount = count;
            }

            const rights = UsersRoleService.getRightsForUploads(isSupportUser, currentUserPermission);

            return {
                ...HelperService.pick(upload, pickProps),
                originalName: upload.originalName || '',
                title: upload.title,
                processedItemsCount: {
                    hasInformationAboutFile: upload.hasInformationAboutFile,
                    totalItemsCount,
                    processedItemsCount,
                    failedItemsCount,
                    cancelledItemsCount,
                    progressItemsCount,
                },
                totalRowsCount,
                shared: {
                    id: upload.id,
                    isPermitViewInfoShare: rights.isPermitViewInfoShare,
                },
                data: {
                    rights,
                    isFollowed: SocialSourceService.formatStatusToClient(upload.importer?.status),
                    isSocial: upload.type === 'import',
                    socialUploadId: upload.importer?.id,
                    hasInformationAboutFile: upload.hasInformationAboutFile,
                    headerRowType: upload.headerRowType,
                    progressItemsCount,
                    isCancelledSource: upload.cancelled,
                    failedItemsCount,
                    totalItemsCount,
                    uploadId: upload.id,
                    uuidMongo: upload.uuid,
                    uploadTitle: upload.title,
                    hidden: upload.hidden,
                    isAppendFileIconDisabled: !upload.hasInformationAboutFile || upload.cancelled || progressItemsCount > 0,
                    attrByDate: attrByDate.map(attr => ({ name: attr.id, id: attr.index + '' })),
                    showReLemmatization: hasAttrText && isSupportUser,
                },
            };
        });
    });
