import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ChannelsControl.scss';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    showConfirm,
};

@connect(mapStateToProps, mapDispatchToProps)
export class ChannelControl extends Component {
    getBtnClass() {
        const { activeSurvey, survey: { channel } } = this.props;

        return `channels-controls ${ channel === activeSurvey.channel ? 'active' : '' }`;
    }

    @autobind
    createSurveyHandler(survey) {
        //Timeout because showConfirm in 'onClick' function and in 'createSurveyHandler' dispatched in one time
        setTimeout(() => {
            this.props.showConfirm({
                header: 'Confirmation',
                content: `Do you want to activate ${survey.channel} channel?`,
                successCallback: () => this.props.createSurvey(survey),
            });
        }, 300);
    }

    @autobind
    surveyHandler() {
        const stateByDefault = 2;
        const { switchSurvey, survey } = this.props;
        const { channel, id, state, survey_group } = survey;

        return id
            ? switchSurvey({ channel, id, state: state || stateByDefault, survey_group })
            : this.createSurveyHandler(survey);
    }

    @autobind
    onClick() {
        this.props.showConfirm({
            checkDirty: true,
            header: 'Warning!',
            content: `Seems you might have some unsaved changes. Are you sure you want to continue?`,
            successCallback: () => this.surveyHandler(),
        });
    }

    getChannelTitle() {
        const { survey: { channel, id }, activeSurvey } = this.props;

        return (
            <>
                { activeSurvey.channel === channel ? this.getChannelIcon() : '' }
                { channel }
                { id ? ' (ID:' + id + ')' : '' }
            </>
        );
    }

    getChannelIcon() {
        const { state } = this.props.survey;
        const stateDeleted = 541;

        return state !== stateDeleted
            ? <FontAwesomeIcon icon={ 'check' }/>
            : <FontAwesomeIcon icon={ 'times' }/>;
    }

    getDisabledStatus() {
        const {
            activeSurvey: { channel },
            isTimeSlots,
            name,
            rightsSurvey,
            survey: { id },
        } = this.props;

        return channel === name
            || ( isTimeSlots && [ 'DIGI' ].includes(name) )
            || !id && !rightsSurvey.isPermitEdit;
    }

    render() {
        const { isCreating } = this.props.survey;

        return (
            <Button className={ this.getBtnClass() }
                onClick={ this.onClick }
                color={ 'white' }
                outline
                disabled={ this.getDisabledStatus() }>
                {
                    isCreating
                        ? <span>
                            <FontAwesomeIcon icon='circle-notch' size='lg' spin/>
                            Creating...
                        </span>
                        : this.getChannelTitle()
                }
            </Button>
        );
    }
}
