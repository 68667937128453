import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

export const ExportersListActionsFormatter = props => {
    const isNewExporter = !props.self;
    const id = parseInt(props.id);

    const openModalManage = () => props.openModalManage(props, isNewExporter);
    const openModalDelete = () => props.openModalDelete(id, isNewExporter);
    const openModalCopy = () => props.openModalCopy(props, isNewExporter);

    return (
        <div
            id={ 'table-action__container' }
            className='table-action__container table-action__exporters-list'>
            {
                isNewExporter
                    ? <>
                        <TooltipWrapper value={ `Open Exporter\'s settings` } force>
                            <span
                                className={ `table-action__exporters-list-icon ${props.name === 'Default Exporter' && 'disabled'}` }>
                                <Link to={ `/feedback/exporter/${id}` }>
                                    <FontAwesomeIcon icon="cog"/>
                                </Link>
                            </span>
                        </TooltipWrapper>

                        <TooltipWrapper value={ 'Copy Exporter' } force>
                            <span
                                className={ 'table-action__exporters-list-icon' }
                                onClick={ openModalCopy }>
                                <Link to={ `/feedback/exporter/copy/${id}` }>
                                    <FontAwesomeIcon icon="copy"/>
                                </Link>
                            </span>
                        </TooltipWrapper>
                    </>
                    : <>
                        <TooltipWrapper value={ `Open legacy Exporter\'s settings` } force>
                            <span
                                className={ `table-action__exporters-list-icon ${props.name === 'Default Exporter' && 'disabled'}` }
                                onClick={ openModalManage }>
                                <FontAwesomeIcon icon="cog"/>
                            </span>
                        </TooltipWrapper>

                        <TooltipWrapper value={ 'Copy legacy Exporter' } force>
                            <span
                                className={ 'table-action__exporters-list-icon' }
                                onClick={ openModalCopy }>
                                <FontAwesomeIcon icon="copy"/>
                            </span>
                        </TooltipWrapper>
                    </>
            }

            <TooltipWrapper value={ 'Delete Exporter' } force>
                <span
                    className={ `table-action__exporters-list-icon ${props.name === 'Default Exporter' && 'disabled'}` }
                    onClick={ openModalDelete }>
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>
        </div>
    );
};
