import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { SelectAsyncPaginateWithFilters } from '/components';
import {
    totalSelector,
    loadingSelector,
    sourceSelectFiltersSelector,
    sourceSelectOptionsSelector,
    radioFiltersSelector,
    surveyFiltersSelector,
    openedAdditionalFiltersSelector,
    excludedSourcesSelector,
} from './modules/source-select.selectors';
import {
    getSources,
    cleanSelectSources,
    setSelectFilters,
    setPage,
    toogleAdditionalFilters,
    initSelect,
} from './modules/source-select.modules';

const mapStateToProps = (state, props) => {
    const getOptions = sourceSelectOptionsSelector(state, props);
    const getTotal = totalSelector(state, props);
    const getExcludedSources = excludedSourcesSelector(state, props);
    const getFilters = sourceSelectFiltersSelector(state, props);
    const getOpenedAdditionalFilters = openedAdditionalFiltersSelector(state, props);
    const getRadioFilters = radioFiltersSelector(props.t);
    const getSurveyFilters = surveyFiltersSelector(props.t);

    return (state, props) => {
        return {
            total: getTotal(state, props),
            exclude_sources: getExcludedSources(state, props),
            loading: loadingSelector(state, props),
            radioFilters: getRadioFilters(state),
            surveyFilters: getSurveyFilters(state),
            filters: getFilters(state, props),
            openedAdditionalFilters: getOpenedAdditionalFilters(state, props),
            options: getOptions(state, props),
        };
    };
};

const mapDispatchToProps = {
    getSources,
    cleanSelectSources,
    setSelectFilters,
    setPage,
    toogleAdditionalFilters,
    initSelect,
};

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
export class SourceSelect extends Component {
    componentDidMount() {
        const { id, initSelect } = this.props;

        initSelect(id);
    }

    render() {
        const {
            radioFilters,
            surveyFilters,
            setSelectFilters,
            id,
            getSources,
            toogleAdditionalFilters,
            openedAdditionalFilters,
            total,
            withVirtualColumn: {
                with_virtual_column,
                ...withVirtualColumn
            },
            loading,
            placeholder,
            value,
            initSelect,
            onChange,
            setPage,
            exclude_sources,
            options,
        } = this.props;

        const filters = {
            ...this.props.filters,
            with_virtual_column,
        };

        return total !== undefined
            ? <SelectAsyncPaginateWithFilters
                id={ id }
                openedAdditionalFilters={ openedAdditionalFilters }
                total={ total }
                loading={ loading }
                placeholder={ placeholder }
                value={ value }
                withVirtualColumn={ withVirtualColumn }
                filters={ filters }
                toogleAdditionalFilters={ toogleAdditionalFilters }
                initSelect={ initSelect }
                setSelectFilters={ setSelectFilters }
                onChange={ onChange }
                getSources={ getSources }
                setPage={ setPage }
                exclude_sources={ exclude_sources }
                options={ options }
                radioFilters={ radioFilters }
                surveyFilters={ surveyFilters }
            />
            : null;
    }
}

SourceSelect.propTypes = {
    id: PropTypes.string.isRequired,
    withVirtualColumn: PropTypes.number,
    exclude_sources: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.object,
};

SourceSelect.defaultProps = {
    withVirtualColumn: 0,
};
