import mockData from '../scenes/Theme/modules/mockedRunnerData';

export class ThemeService {
    static composeSettings(obj, type) {
        const themeSettings = {};

        Object.keys(obj).map(el => {
            themeSettings[el.toUpperCase()] = obj[el].channelValue
                ? obj[el].channelValue
                : obj[el].value;
        });

        type === 'UpdateStyle' && ThemeService.updateStyle(themeSettings);
        type === 'SetMockedData' && ThemeService.setMockedData(themeSettings);

        return themeSettings;
    }

    static updateStyle(obj) {
        window.VocSignal && window.VocSignal_SendEvent('UpdateStyle', obj);
    }

    static setMockedData(obj) {
        window.VocSignal && window.VocSignal_SendEvent('SetMockedData', {
            apiUrl: '/',
            mockData: {
                ...mockData,
                settings: {
                    ...mockData.settings,
                    ...obj,
                },
            },
        });
    }
}
