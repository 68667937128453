import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { reduxForm, Field, FieldArray } from 'redux-form';

import { Button, Row, Col, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseAlertFormEmailItem } from './CaseAlertFormEmailItem';

import { VocFormTextField, VocModal } from '/components';
import { CaseAlertFormValidation } from './CaseAlertFormValidation';
import './CaseAlertManageForm.scss';

@reduxForm({
    form: 'CaseAlertForm',
    enableReinitialize: true,
    validate: CaseAlertFormValidation,
})
export class CaseAlertManageForm extends PureComponent {
    @autobind
    addCondition() {
        this.props.array.push('target_emails');
    }

    @autobind
    getModalBody() {
        return (
            <form className={ 'case_alert-form_modal-body' } onSubmit={ this.props.handleSubmit(this.onSubmit) }>
                <Row className={ 'case_alert_email-row__box' }>
                    <Col md={ 12 }>
                        <Label htmlFor={ 'name' }>
                            Name
                        </Label>
                    </Col>
                    <Col md={ 12 }>
                        <Field
                            name={ 'name' }
                            component={ VocFormTextField }
                            className={ 'no-margin' }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={ 8 }>
                        <Label htmlFor={ 'target_emails' }>
                            Emails
                        </Label>
                    </Col>
                    <Col md={ 4 } className={ 'text-right' }>
                        <Button outline color="primary" onClick={ this.addCondition }>
                            <FontAwesomeIcon icon={ 'plus' }/>
                            <span className='button-primary__text'> Add email</span>
                        </Button>
                    </Col>
                </Row>
                <div className='case_alert_emails__box'>
                    <FieldArray
                        name="target_emails"
                        component={ CaseAlertFormEmailItem }
                    />
                </div>
            </form>
        );
    }

    @autobind
    onSubmit(values) {
        this.props.reset();
        return this.props.submitCaseAlertForm(values);
    }

    @autobind
    getFooter() {
        const { valid, caseAlertSubmit, dirty, handleSubmit } = this.props;

        return (
            <div className={ 'question-flow_footer-buttons' }>
                <Button onClick={ this.props.close } color={ 'white' } outline>
                    Cancel
                </Button>
                <Button
                    disabled={ !dirty || !valid || caseAlertSubmit }
                    onClick={ handleSubmit(values => this.onSubmit(values)) }
                    color="primary"
                >
                    <FontAwesomeIcon icon={ 'check' }/>
                    <span> Save</span>
                </Button>
            </div>
        );
    }

    @autobind
    cleanupModal() {
        this.props.reset();
    }

    render() {
        const { show, close } = this.props;

        return (
            <VocModal
                toggle={ close }
                onClosed={ this.cleanupModal }
                modalClassName={ 'modal-scrollable' }
                header={ 'Save Case Alert' }
                isOpen={ show }
                size={ 'md' }
                footer={ this.getFooter() }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}
