import React, { useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SplitPane, { Pane } from 'split-pane-react';
import { isMobile } from 'react-device-detect';

import {
    ChartLabelsSortableList,
    ChartColorPicker, FooterInfo,
} from '/visual/scenes/Dashboard/components/Gadget/components';
import { usePieChart, useDrillDown } from '/visual/scenes/Dashboard/components';
import { sendChartColor, sendLabel, setContentSettings } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { PopoverChartTooltip, SplitPaneSash } from '/components';
import { HelperService, useSplitPane } from '/services';
import { PieChartD3 } from './components';
import { LabelModal } from '../LabelModal';
import {
    IColorPickerData, changeColorHandlerType, IChartData,
    TApplyLabelModalData, ELabelType, TLabelPopoverData,
} from '../../models';

import commonStyles from '../BaseChart/style.module.scss';
import styles from './style.module.scss';

export const PieChart = ({ chartGadgetData }: { chartGadgetData: IChartData }) => {
    const dispatch = useDispatch();
    const { gadgetData, chartData } = chartGadgetData;
    const [ colorPickerData, setColorPickerData ] = useState<IColorPickerData | null>(null);
    const [ highlightedLabel, setHighlightedLabel ] = useState<string | null>(null);
    const [ innerCircleData, setInnerCircleData ] = useState<TLabelPopoverData | null>(null);

    const {
        filterLabels,
        data,
        changePieColor,
        changeLabel,
        changePieSort,
    } = usePieChart(chartGadgetData);

    const {
        sizes,
        onChange,
        onDragEnd,
    } = useSplitPane({
        initSizes: gadgetData.contentSettings?.labelsSize,
        sizeChanged: newSizes => dispatch(setContentSettings(gadgetData.id, {
            ...gadgetData.contentSettings,
            labelsSize: newSizes,
        })),
    });

    const tooltipRef = createRef<HTMLDivElement>();
    const { getDrillDownDashboard } = useDrillDown({ gadgetData });

    const changeColorHandler = ({ elementId = '', colorHex }: changeColorHandlerType) => {
        changePieColor(
            elementId,
            colorHex,
            (colorData, isChanged) => {
                dispatch(sendChartColor(gadgetData.id, colorData, isChanged));
            },
        );
    };

    const changePiesSortHandler = (direction: string) => {
        changePieSort(direction, data => {
            dispatch(setContentSettings(gadgetData.id, data));
        });
    };

    const changeLabelOrderHandler = (order: string[]) => {
        dispatch(setContentSettings(gadgetData.id, {
            ...chartGadgetData.gadgetData.contentSettings,
            order,
        }));
    };

    const onApplyLabelModal = (labelData: TApplyLabelModalData) => {
        changeLabel(
            labelData.id,
            labelData.type,
            labelData.value,
            (labelRes, isChanged) => {
                dispatch(sendLabel(gadgetData.id, labelRes, isChanged));
            });
    };

    const onApplyCenterCircleDataHandler = (inputValue: TLabelPopoverData['value']) => {
        dispatch(setContentSettings(gadgetData.id, {
            ...gadgetData.contentSettings,
            innerText: inputValue,
        }));
    };

    const resetCenterCircleDataToInitial = () => {
        onApplyCenterCircleDataHandler(null);
        setInnerCircleData(null);
    };

    const getSortButton = (sortDirection: string) => {
        const iconName = sortDirection === 'asc'
            ? 'sort-amount-up'
            : 'sort-amount-down-alt';

        return data && data?.items?.length > 1
            ? <button onClick={ () => changePiesSortHandler(sortDirection) }>
                <FontAwesomeIcon icon={ iconName } className={ 'fa-rotate-270' } color={ '#979797' } />
            </button>
            : null;
    };

    return (
        <div className={ styles.pieChartWrapper } id={ `pieChartWrapper_${gadgetData.id}` }>
            <SplitPane
                allowResize={ !isMobile }
                performanceMode
                resizerSize={ 1 }
                split='horizontal'
                sizes={ sizes }
                onChange={ onChange }
                onDragEnd={ onDragEnd }
                sashRender={ () => <SplitPaneSash split='horizontal'/> }
            >
                <Pane className={ commonStyles.graphSplitPanePadding } minSize='35%'>
                    <PieChartD3
                        data={ data }
                        percentage={ gadgetData.contentSettings.relativeMode }
                        showScore={ gadgetData.contentSettings.annotateScore }
                        gadgetFunction={ gadgetData.contentSettings.function }
                        highlightedLabel={ highlightedLabel }
                        gadgetId={ gadgetData.id }
                        drillDownEnabled={ gadgetData.contentSettings.drillDown }
                        chartType={ gadgetData.contentSettings.chartType }
                        toolTipRef={ tooltipRef }
                        setColorPickerData={ setColorPickerData }
                        setInnerCircleData={ setInnerCircleData }
                        drillDownFromSelection={ getDrillDownDashboard }
                    />
                </Pane>
                <Pane minSize='20%' maxSize='50%' className={ commonStyles.labelsSplitPanePadding }>
                    <div className={ commonStyles.labelsContainer }>
                        { getSortButton('asc') }
                        <ChartLabelsSortableList
                            dragDisable={ isMobile }
                            labels={ filterLabels }
                            longLabel={ gadgetData.contentSettings.longLabel }
                            gadgetId={ gadgetData.id }
                            chartType={ gadgetData.contentSettings.chartType }
                            updateSelectedLabel={ setHighlightedLabel }
                            setColorPickerData={ setColorPickerData }
                            onApplyLabelModal={ onApplyLabelModal }
                            onSortEnd={ changeLabelOrderHandler }
                        />
                        { getSortButton('desc') }
                    </div>
                </Pane>
            </SplitPane>

            <FooterInfo records={ chartData.count } lastHealthCheckTime={ gadgetData.lastHealthCheckTime } />

            <ChartColorPicker
                chartType={ gadgetData.contentSettings.chartType }
                gadgetId={ gadgetData.id }
                colorPickerData={ colorPickerData }
                setColorPickerData={ setColorPickerData }
                changeColorCallback={ changeColorHandler }
            />

            <PopoverChartTooltip ref={ tooltipRef } />

            {/* inner circle text editor */}
            <LabelModal
                isControlledPositionTooltip
                labelModalData={ innerCircleData }
                placement={ 'auto' }
                gadgetId={ gadgetData.id }
                chartType={ gadgetData.contentSettings.chartType }
                labelType={ ELabelType.PIE_CENTER }
                setLabelModalData={ setInnerCircleData }
                additionalButton={{
                    color: 'danger',
                    outline: true,
                    icon: 'undo',
                    disabled: !HelperService.checkNotNullOrUndefined(gadgetData.contentSettings.innerText),
                    onCLick: resetCenterCircleDataToInitial,
                }}
                onApplyLabelModalData={ onApplyCenterCircleDataHandler }
            />
        </div>
    );
};
