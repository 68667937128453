import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { containerIsFluidSelector } from '/modules/selectors';
import { TooltipWrapper, ThinSpinner } from '/components';
import { changeDashboardSettings, getFavouritesDashboards } from '/visual/modules/VocVisual.modules';
import { favouriteListSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { IDashboardSliderProps } from './models';
import { IDashboard } from '../../models';

import styles from './style.module.scss';

export const DashboardsSlider = ({
    onSelectDashboard,
    activeDashboardId,
    isPermitDefaultDashboard,
}: IDashboardSliderProps) => {
    const { t } = useTranslation();
    const isFluidContainer = useSelector(containerIsFluidSelector);
    const favouriteList = useSelector(favouriteListSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFavouritesDashboards());
    }, []);

    const handleToggleDefault = (dashboard: IDashboard) => {
        const message = t(dashboard.isDefault
            ? 'dashboardUnmarkedAsDefault'
            : 'dashboardMarkedAsDefault',
        );

        if (isPermitDefaultDashboard) {
            dispatch(changeDashboardSettings(dashboard.id, {
                title: dashboard.title,
                isDefault: !dashboard.isDefault,
            }, message));
        }
    };

    const getDashboard = () => {
        return Boolean(favouriteList.data.length)
            && favouriteList.data.map((dashboard: IDashboard) => (
                <div key={ dashboard.id } className={ styles.buttonWrapper }>
                    <button
                        key={ dashboard.id }
                        className={
                            cx(
                                styles.dashboardItem,
                                { [styles.dashboardItemActive]: activeDashboardId === dashboard.id },
                            )
                        }
                        onClick={ () => { onSelectDashboard(dashboard.id); } }
                    >
                        <TooltipWrapper value={ dashboard.title }>
                            <span> {dashboard.title} </span>
                        </TooltipWrapper>
                    </button>
                    <FontAwesomeIcon
                        icon={ 'star' }
                        className={ cx(
                            styles.dashboardIcon,
                            {
                                [styles.dashboardIconActive]: dashboard.isDefault,
                                [styles.dashboardIconDisabled]: !isPermitDefaultDashboard,
                            },
                        ) }
                        onClick={ () => handleToggleDefault(dashboard) }
                    />
                </div>
            ));
    };

    return (
        <div className={
            cx(
                styles.dashBoardsSliderWrapper,
                {
                    [styles.dashBoardsSliderWrapperIsFluidContainer]: isFluidContainer,
                    [styles.dashboardsSliderHasItems]: favouriteList.data.length,
                },
            )
        }>
            {
                favouriteList.loading
                    ? <div className={ styles.loader }>
                        <ThinSpinner />
                    </div>
                    : getDashboard()
            }
        </div>
    );
};
