import React, { useEffect, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from "react-redux";
import { Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VocModal } from '/components/VocModal';
import { ThinSpinner } from "/components/Preloader";
import { VocFormTextField, VocFormSelectField, VocFormSelectAsyncPaginate } from '/components';
import { HelperService } from '/services';
import { DictionaryFormValidation } from './DictionaryFormValidation';
import {
    dictionaryFormValuesSelector,
    preprocessLoadingSelector,
    loadingSelector,
} from "/dictionaries/modules/dictionary.selectors";

import {
    getDictionaryData,
    getNestedDictionaries,
    setNestedDictionaries,
    setNestedDictionariesFilters,
    cleanupNestedDictionaries,
} from "../../modules/dictionary.modules";
import { activeDictionarySelector, nestedDictionariesSelector } from "../../modules/dictionary.selectors";

import './DictionaryForm.scss';

const initialNestedDictionaryFilters = {
    page: 1,
    limit: 10,
    search: '',
};

const DictionaryFormModel = ({
    dictionaryId,
    isOpen,
    submitDictionary,
    submitForPreprocessData,
    closeModal,
    selectOptions,
    isAddButtonActiveHandler,
    setIsAddButtonActiveHandler,
    handleSubmit,
    isChecked,
    setIsChecked,
    reset,
}) => {
    const formValues = useSelector(dictionaryFormValuesSelector);
    const preprocessLoading = useSelector(preprocessLoadingSelector);
    const loading = useSelector(loadingSelector);
    const nestedDictionaries = useSelector(nestedDictionariesSelector);
    const activeDictionary = useSelector(activeDictionarySelector);

    const [ selectedNestedDictionaries, setSelectedNestedDictionaries ] = useState([]);

    const dispatch = useDispatch();

    const languageOnChangeHandler = () => {
        setIsChecked(false);
    };

    const lemmataOnChangeHandler = value => {
        const testedValue = (/([\wа-ёіїє0-9\u0621-\u064A])+/gi).test(value);

        if (testedValue) !isAddButtonActiveHandler && setIsAddButtonActiveHandler(true);
        if (!value || !testedValue) isAddButtonActiveHandler && setIsAddButtonActiveHandler(false);

        setIsChecked(false);
    };

    const submitHandler = isChecked
        ? handleSubmit(values => {
            //for Umlauts ß
            const title = values.title
                .replace('ß', '\u9999')
                .toLocaleUpperCase()
                .replace('\u9999', 'ß');

            return submitDictionary(
                { ...values, title, children: selectedNestedDictionaries?.map(dictionary => dictionary.value) || [] },
                dictionaryId);
        })
        : () => submitForPreprocessData(formValues);

    useEffect(() => {
        isOpen && dictionaryId && dispatch(getDictionaryData(dictionaryId));

        setSelectedNestedDictionaries([]);
    }, [ isOpen ]);

    useEffect(() => {
        if(activeDictionary?.data) {
            setSelectedNestedDictionaries(activeDictionary?.data.children.map(childrenData => ({
                label: childrenData.title,
                value: childrenData.id,
            })));
        }
    }, [ activeDictionary ]);

    useEffect(() => {
        formValues?.language && setSelectedNestedDictionaries([]);
    }, [ formValues?.language ]);

    const getDictionaries = (filters, excluded_dictionaries, customSelectedNestedDictionaries) => {
        dispatch(getNestedDictionaries({
            ...filters,
            excluded_dictionaries: excluded_dictionaries
                || dictionaryId
                + ','
                + (
                    customSelectedNestedDictionaries
                    || selectedNestedDictionaries)?.map(dictionary => dictionary.value,
                ).join(','),
            language: formValues.language,
        }));
    };

    const updateDictionariesFilters = filters => {
        if(filters.page === 1 && !filters.search && !filters.limit) {
            dispatch(cleanupNestedDictionaries());
        }

        dispatch(setNestedDictionariesFilters(filters));
    };

    const onChangeDictionariesSelect = values => {
        clearDictionariesSelect();

        setSelectedNestedDictionaries(values);
    };

    const clearDictionariesSelect = () => {
        dispatch(setNestedDictionaries([]));
        dispatch(setNestedDictionariesFilters(initialNestedDictionaryFilters));
    };

    const renderBodyMarkup = () => {
        const { data, loading, count } = nestedDictionaries;

        const formatDictionariesOptions = data?.map(dictionaryData => ({
            value: dictionaryData.id,
            label: dictionaryData.title,
        })) || [];

        return (
            <form className='dictionary-form'>

                <Label
                    className='field-label'
                    htmlFor='title'
                >
                    Dictionary name
                </Label>
                <Field
                    name='title'
                    placeholder='Enter name'
                    component={ VocFormTextField }
                />

                {
                    dictionaryId
                    && <>
                        <Label className='field-label'
                            htmlFor='include_dictionaries'
                        >
                            Include dictionaries
                        </Label>
                        <Field
                            portal
                            isMulti
                            closeMenuOnSelect
                            name={ 'include_dictionaries' }
                            component={ VocFormSelectAsyncPaginate }
                            total={ count }
                            loading={ loading || activeDictionary.loading }
                            requestHandler={ getDictionaries }
                            options={ formatDictionariesOptions }
                            filters={ nestedDictionaries.filters }
                            setFilters={ updateDictionariesFilters }
                            onChange={ onChangeDictionariesSelect }
                            customValue={ selectedNestedDictionaries }
                            hideSelectedOptions={ false }
                            withError={ false }
                            isTooltip={ false }
                        />
                    </>
                }

                <div className='dictionary-form__loader'>

                    {
                        preprocessLoading
                        && <ThinSpinner className={ 'thin-spinner__dictionary-modal' }/>
                    }

                    <Label
                        className='field-label field-label--select'
                        htmlFor='language'
                    >
                        Language:
                    </Label>
                    <Field
                        portal
                        name='language'
                        onChange={ languageOnChangeHandler }
                        component={ VocFormSelectField }
                        normalize={ HelperService.normalizeSelectValue }
                        format={ value => HelperService.getSelectedValue(selectOptions, value) }
                        options={ selectOptions }
                    />

                    <Label
                        className='field-label'
                        htmlFor='lemmata'
                    >
                        List of lemmas
                    </Label>
                    <Field
                        className='dictionary-form__textarea-size'
                        name='lemmata'
                        onChange={ lemmataOnChangeHandler }
                        placeholder='Write or paste text here'
                        component={ VocFormTextField }
                        type='textarea'
                    />
                </div>
            </form>
        );
    };

    const renderFooterMarkup = () => (
        <div className='add-dictionary__footer'>

            <Button
                className='add-dictionary__footer-button'
                onClick={ () => {
                    reset();
                    closeModal();
                } }
            >
                <FontAwesomeIcon icon="times"/>
                <span> Close</span>
            </Button>

            <Button
                disabled={ loading || !isAddButtonActiveHandler }
                onClick={ submitHandler }
                className='add-dictionary__footer-button'
            >
                <FontAwesomeIcon icon="check"/>
                <span>
                    {
                        !isChecked
                            ? 'Check'
                            : dictionaryId ? 'Save' : 'Add'
                    }
                </span>
            </Button>

        </div>
    );

    return (
        <VocModal
            header={ dictionaryId ? "Edit dictionary" : "Add dictionary" }
            modalClassName='add-dictionary-modal modal-scrollable'
            isOpen={ isOpen }
            footer={ renderFooterMarkup() }
            onExit={ clearDictionariesSelect }
        >
            { renderBodyMarkup() }
        </VocModal>
    );
};

export const DictionaryForm = reduxForm({
    form: 'DictionaryForm',
    enableReinitialize: true,
    validate: DictionaryFormValidation,
})(DictionaryFormModel);
