export class TopicSetChartsServices {
    static barChartRound = 10;
    static bars = [];
    static tableRows = [];

    static mathRound(labels_scores) {
        if (labels_scores === 1) {
            return 1;
        }

        return Math.ceil(labels_scores * this.barChartRound) / this.barChartRound;
    }

    static mathRoundTableChart(labels_scores) {
        return ((labels_scores * this.barChartRound) / this.barChartRound).toFixed(2);
    }

    static findBarChart(bars, final_label, dataValue) {
        const finalLabelBar = bars.find((barData, inx) => {
            if (barData.name === final_label) {
                const { labels_scores } = bars[inx];
                const newlabels_scores = this.mathRound(dataValue.score[final_label]);
                const findlabels_scores = labels_scores.find((score, inx) => {
                    if (score.labels_scores === newlabels_scores) {
                        labels_scores[inx] = { ...labels_scores[inx], count: ++labels_scores[inx].count };
                        return true;
                    }
                });

                if (!findlabels_scores) {
                    labels_scores.push({
                        labels_scores: newlabels_scores,
                        name: `[ ${(newlabels_scores * 10 - 1) / 10} - ${newlabels_scores} ]`,
                        count: 1,
                    });
                }

                bars[inx].labels_scores = [ ...labels_scores ];
                return true;
            }
        });

        if (!finalLabelBar) this.pushBarChart(bars, final_label, dataValue);
    }

    static pushBarChart(bars, final_label, dataValue) {
        const labels_scores = this.mathRound(dataValue.score[final_label]);

        bars.push({
            name: final_label,
            labels_scores: [
                {
                    labels_scores,
                    name: `[ ${(labels_scores * 10 - 1) / 10} - ${labels_scores} ]`,
                    count: 1,
                },
            ],
        });
    }

    static pushOtherDataToBottom(chartData) {
        let newChartData: any = [ ...chartData ];
        const extracted = newChartData.find(chart => chart.name === "other");

        newChartData = newChartData.filter(chart => chart.name !== "other");

        extracted && newChartData.push(extracted);

        return newChartData;
    }

    static sortChartData(a, b) {
        if(a.name < b.name) return -1;
        if(a.name > b.name) return 1;
        return 0;
    }

    static getBarChartData(data) {
        this.bars = [];

        data.map(dataValue => {
            Object.keys(dataValue.score).map(finalLabel =>
                !this.bars.length
                    ? this.pushBarChart(this.bars, finalLabel, dataValue)
                    : this.findBarChart(this.bars, finalLabel, dataValue),
            );
        });

        const barChartData = this.bars
            .map(barData => ({
                ...barData,
                labels_scores: barData.labels_scores.sort((score1, score2) => score1.labels_scores - score2.labels_scores),
            }))
            .sort(this.sortChartData);

        return this.pushOtherDataToBottom(barChartData);
    }

    static pushTableChart(tableRows, final_label, dataValue) {
        const labels_scores = this.mathRoundTableChart(dataValue.score[final_label]);

        tableRows.push({
            name: final_label,
            labels_scores: [
                {
                    labels_scores,
                    text: dataValue.sequence,
                },
            ],
        });
    }

    static findTableChart(tableRows, final_label, dataValue) {
        const finalTableRow = tableRows.find((el2, inx) => {
            if (el2.name === final_label) {
                const { labels_scores } = tableRows[inx];
                const newlabels_scores = this.mathRoundTableChart(dataValue.score[final_label]);

                labels_scores.push({
                    labels_scores: newlabels_scores,
                    text: dataValue.sequence,
                });

                tableRows[inx].labels_scores = [ ...labels_scores ];
                return true;
            }
        });

        if (!finalTableRow) this.pushTableChart(tableRows, final_label, dataValue);
    }

    static getTableChartData(data) {
        this.tableRows = [];

        data.map(dataValue => {
            Object.keys(dataValue.score).map(finalLabel =>
                !this.tableRows.length
                    ? this.pushTableChart(this.tableRows, finalLabel, dataValue)
                    : this.findTableChart(this.tableRows, finalLabel, dataValue),
            );
        });

        const tableChartData = this.tableRows
            .map(row => ({
                ...row,
                labels_scores: row.labels_scores.sort((score1, score2) => score1.labels_scores - score2.labels_scores),
            }))
            .sort(this.sortChartData);

        return this.pushOtherDataToBottom(tableChartData);
    }
}
