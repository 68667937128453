import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import {
    getGlobalCommonSetting,
    putGlobalCommonSettings,
    cleanup,
    getGlobalClassifierList,
} from './modules/commonSettings.modules';
import { PageRequests } from '/services';
import { VocCleaupHOC, Preloader } from '/components';
import { getPermissionByActiveSelector, unitSelector } from '../../modules/globalSettings.selectors';
import { SettingsForm } from "/feedback/components";
import { commonFormSettingsSelector } from "./modules/commonSettings.selectors";
import { getEnterpriseId } from '/feedback/modules/VocFeedback.selectors';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';
import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';
import { SettingsService } from '/feedback/services/SettingsService';

const mapStateToProps = state => ({
    commonSettings: commonFormSettingsSelector(state),
    unit: unitSelector(state),
    assets: assetsSelector(state),
    enterpriseId: getEnterpriseId(state),
    permission: getPermissionByActiveSelector(state),
});

const mapDispatchToProps = {
    getGlobalCommonSetting,
    putGlobalCommonSettings,
    getGlobalClassifierList,
    cleanup,
    getAssets,
    uploadAsset,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class CommonSettings extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        const { unit = {}, getGlobalClassifierList } = this.props;

        getGlobalClassifierList();
        unit.value && this.getGlobalCommonSettings();
    }

    componentDidUpdate(prevProps) {
        const { unit = {} } = this.props;

        if (unit.value !== prevProps.unit.value) {
            this.getGlobalCommonSettings();
        }
    }

    getGlobalCommonSettings() {
        const { unit, getGlobalCommonSetting } = this.props;

        this.pageRequests.cleanup();
        this.pageRequests.addRequest(getGlobalCommonSetting(unit.value));
    }

    @autobind
    putCommonSettings(body) {
        const { enterpriseId, unit } = this.props;
        const model = SettingsService.changeBodyCommonSettings({
            unit: unit.value,
            body,
        }, enterpriseId);

        return this.props.putGlobalCommonSettings(model);
    }

    render() {
        const {
            commonSettings: {
                loading,
                sending,
                classifierLoading,
                unsubscribeLoading,
                fieldsMap,
                initialValues,
            },
            assets,
            getAssets,
            uploadAsset,
            permission,
        } = this.props;

        const isLoading = loading || classifierLoading || unsubscribeLoading;

        return (
            <DocumentTitle title="Feedback :: Common Settings">
                <Fragment>
                    {
                        isLoading
                            ? <Preloader/>
                            : <SettingsForm
                                fieldsMap={ fieldsMap }
                                initialValues={ initialValues }
                                putSettings={ this.putCommonSettings }
                                sendingSettings={ sending }
                                form={ 'GlobalCommonSettingsForm' }
                                assets={ assets }
                                getAssets={ getAssets }
                                uploadAsset={ uploadAsset }
                                permission={ permission }
                            />
                    }
                </Fragment>
            </DocumentTitle>
        );
    }
}
