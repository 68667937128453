import React, { Component } from 'react';
import { VocMetaFilter, TooltipWrapper } from '/components';
import { autobind } from 'core-decorators';
import { MetaQueryService } from '/services';
import './AdvancedFilter.scss';

export class AdvancedFilter extends Component {
    @autobind
    onChangedMetaQueryItem() {
        const { onChangeQuery } = this.props;

        onChangeQuery();
        this.forceUpdate();
    }

    getQueryString() {
        const { source, query } = this.props;

        return (
            <div className="queryString">
                { MetaQueryService.clientQueryToString({ query: query, source }) }
            </div>
        );
    }

    render() {
        const queryString = this.getQueryString();
        const { source, query } = this.props;

        return (
            <div className={ 'advanced-filter__box' }>
                <VocMetaFilter
                    attributeValueKey={ 'index' }
                    attributePlaceholder={ 'Input variable' }
                    metaQuery={ query }
                    onChangedMetaQueryItem={ this.onChangedMetaQueryItem }
                    source={ source }
                />

                <TooltipWrapper
                    className={ 'query-string-tooltip' }
                    value={ queryString }
                >
                    { queryString }
                </TooltipWrapper>
            </div>
        );
    }
}
