import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { saveTopicSet } from '/modules/topicSetModules/topicSet.modules';
import { HelperService } from '/services';
import { _getGraphsData } from '/modules/topicSetModules/topicSet.selectors';

export const useTopics = ({
    topicSetId,
    language,
    languages = [],
    initCategories,
    minedTopics,
    loading,
    topicSetName,
    initTopicSetName,
    isViewPermission,
    isColumnSelected,
}) => {
    const graphs = useSelector(_getGraphsData);
    const history = useHistory();
    const dispatch = useDispatch();

    const [ categories, setCategories ] = useState(initCategories);
    const [ topics, setTopics ] = useState({});
    const [ selectedTopics, setSelectedTopics ] = useState({});
    const [ selectedTopicsForMove, setSelectedTopicsForMove ] = useState({});
    const [ savedTopics, setSavedTopics ] = useState({});

    const [ viewType, setViewType ] = useState('grid');

    const getSavedTopics = languages[language] || {};
    const moveToCount = Object.keys(selectedTopicsForMove).filter(topic => !!selectedTopicsForMove[topic]).length;
    const categoriesSelect = categories
        ? Object.keys(categories).map(categoryId => ({
            label: categoryId,
            value: categoryId,
        }))
        : [];
    const isChangedSaved = getSavedTopics && !HelperService.deepCompare(savedTopics, getSavedTopics);
    const isChangedCategories = categories && !HelperService.deepCompare(categories, {
        ...initCategories,
        Uncategorized: categories.Uncategorized,
    });

    const getTopicsWithoutCategory = inputCategoryData => Object.keys(savedTopics).filter(topic =>
        !Object.keys(inputCategoryData)
            .some(categoryId => inputCategoryData[categoryId]
                .some(categoryTopic => categoryTopic === topic),
            ),
    );

    const topicMapper = topic => ({
        id: topic,
        label: topic,
        isSaved: !!savedTopics[topic],
        isMined: !!(minedTopics && minedTopics[topic] && selectedTopics[topic]),
        isSelected: !!selectedTopics[topic],
        isSelectedForMove: !!selectedTopicsForMove[topic],
    });

    const formattedCategories = categoriesData => {
        const allTopics = Object.keys(savedTopics);
        const categoriesForUncategorized = Object.keys(categoriesData).reduce((sum, category) => ({
            ...sum,
            ...category === 'Uncategorized' ? {} : { [category]: categoriesData[category] },
        }), {});

        return [
            ...Object.keys(categoriesData)
                .map(categoryId => ({
                    name: categoryId,
                    id: categoryId,
                    topics: (
                        categoryId === 'Uncategorized'
                            ? getTopicsWithoutCategory(categoriesForUncategorized)
                            : categoriesData[categoryId]
                    )
                        .filter(topicId => allTopics.some(allTopicId => topicId === allTopicId))
                        .map(topic => topicMapper(topic)),
                })),
        ];
    };

    const formatTopicsListData = topicsObj => Object.keys(topicsObj)
        .reduce((topicsAcc, topic) => {
            return [
                ...topicsAcc,
                topicMapper(topic),
            ];
        }, []);

    const formatTopicsData = topicsObj => {
        const dataForGrid = formatTopicsListData(topicsObj);

        const dataForList = categories
            ? formattedCategories(categories)
            : [];

        return viewType === 'grid'
            ? dataForGrid
            : dataForList;
    };

    const topicsForGraphs = [
        ...Object.keys(selectedTopics),
        ...Object.keys(savedTopics),
    ];

    const topicsForSave = topics => {
        return {
            ...languages,
            [language]: {
                ...languages[language],
                ...topics.reduce((topicsAcc, topic) => ({
                    ...topicsAcc,
                    [topic.id]: topic.isSaved || topic.isSelected ? 1 : 0,
                }), {}),
            },
        };
    };

    const onSelectTopics = topic => {
        const newSelectedTopics = { ...selectedTopics };
        const newSavedTopics = { ...savedTopics };

        const isSelected = newSelectedTopics[topic.id] !== undefined;
        const isSelectedSaved = !!newSavedTopics[topic.id];

        const selectedTopicsModel = {
            ...selectedTopics,
            [topic.id]: 1,
        };

        isSelected && delete newSelectedTopics[topic.id];
        isSelectedSaved && delete newSavedTopics[topic.id];

        !isSelectedSaved && setSelectedTopics(isSelected ? newSelectedTopics : selectedTopicsModel);
        setSavedTopics(newSavedTopics);
    };

    const onSelectTopicsForMove = topic => {
        const newSelectedTopics = { ...selectedTopicsForMove };

        const selectedTopicsModel = {
            ...newSelectedTopics,
            [topic.id]: !newSelectedTopics[topic.id],
        };

        setSelectedTopicsForMove(selectedTopicsModel);
    };

    const onMoveTo = category => {
        const newCategories = Object.keys({ ...categories })
            .reduce((sum, categoryId) => ({
                ...sum,
                [categoryId]: categories[categoryId]
                    .filter(topic => !Object.keys(selectedTopicsForMove)
                        .some(selectedTopic => selectedTopic === topic),
                    ),
            }), {});

        setSavedTopics({
            ...savedTopics,
            ...Object.keys(selectedTopicsForMove)
                .reduce((sum, topic) => ({
                    ...sum,
                    [topic]: 1,
                }), {}),
        });

        setCategories({
            ...newCategories,
            [category]: [
                ...newCategories[category] ? newCategories[category] : [],
                ...Object.keys(selectedTopicsForMove),
            ],
        });

        setSelectedTopicsForMove({});
    };

    const onAddTopics = addedTopics => {
        setTopics({
            ...addedTopics,
            ...topics,
        });

        setSelectedTopics({
            ...addedTopics,
            ...selectedTopics,
        });
    };

    const onCreateCategory = categoryName => {
        setCategories({
            ...categories,
            [categoryName]: [],
        });
    };

    const onEditCategory = (prevCategory, newCategory) => {
        const newCategories = Object.keys({ ...categories })
            .map(category => {
                if(category === prevCategory) {
                    return newCategory;
                }
                return category;
            }).reduce((sum, category) => ({
                ...sum,
                ...category === newCategory
                    ? { [category]: categories[prevCategory] }
                    : { [category]: categories[category] },
            }), {});

        setCategories(newCategories);
    };

    const onDeleteCategory = categoryName => {
        const newCategories = { ...categories };

        delete newCategories[categoryName];

        setCategories(newCategories);
    };

    const onResetTopics = () => {
        setSelectedTopics({});

        setTopics({
            ...topics,
            ...minedTopics,
        });

        setCategories({
            ...initCategories,
            Uncategorized: getTopicsWithoutCategory(initCategories),
        });
        setSavedTopics(getSavedTopics);
    };

    const onSaveTopics = () => {
        const newCategories = { ...categories };

        delete newCategories.Uncategorized;

        setSelectedTopics({});

        setSavedTopics({
            ...savedTopics,
            ...selectedTopics,
        });

        dispatch(saveTopicSet({
            title: topicSetName,
            languages: topicsForSave(formatTopicsListData({
                ...getSavedTopics,
                ...minedTopics,
                ...selectedTopics,
            })),
            topicSetId,
            categories: newCategories,
        })).promise.then(res => !topicSetId && history.push(`/ai/topic-set/${res.id}`));
    };

    const cleanUpTopics = () => {
        setTopics({});
        setSelectedTopics({});
    };

    const isEnabledSave = () => {
        const isOneSelected = !!Object.keys(selectedTopics).length;
        const isOneSaved = !!Object.keys(savedTopics).length;
        const isOneTopic = !!Object.keys(topics).length;

        const isSelectedOrSaved = isOneSelected || isOneSaved;
        const changesInTopicName = (initTopicSetName && topicSetName !== initTopicSetName && isSelectedOrSaved);

        return !!(isOneTopic
                && topicSetName
                && (
                    isOneSelected
                    || (isOneSaved && isChangedSaved)
                    || (isChangedCategories && isSelectedOrSaved)
                    || changesInTopicName
                ))
            && !isViewPermission;
    };

    const isEnabledReset = () => Object.keys(topics).length
        && (Object.keys(selectedTopics).length || isChangedSaved || isChangedCategories);

    const isEnabledValidateColumns = () => {
        let atLeastOneSelected = false;

        Object.keys(topics)
            .forEach(topic => {
                if(!atLeastOneSelected) {
                    atLeastOneSelected = !!topics[topic] && !!selectedTopics[topic];
                }
            });

        return (atLeastOneSelected || Object.keys(selectedTopics).length || Object.keys(savedTopics).length)
            && isColumnSelected && !graphs.loading;
    };

    useEffect(() => {
        if(language) {
            setTopics(getSavedTopics);
            setSavedTopics(getSavedTopics);
            Object.keys(getSavedTopics).length && setViewType('list');
            setSelectedTopics({});
            setSelectedTopicsForMove({});
        }
    }, [ language ]);

    useEffect(() => {
        setCategories({
            ...initCategories,
            Uncategorized: getTopicsWithoutCategory(initCategories),
        });
    }, [ initCategories ]);

    useEffect(() => {
        if(minedTopics) {
            setTopics({
                ...getSavedTopics,
                ...selectedTopics,
                ...minedTopics,
            });

            //preselected topics
            let topicsForSelect = {};

            Object.keys(minedTopics).forEach(topic => {
                if(getSavedTopics[topic] !== undefined ? false : !!minedTopics[topic]) {
                    topicsForSelect = {
                        ...topicsForSelect,
                        [topic]: minedTopics[topic],
                    };
                }
            });

            setSelectedTopics({
                ...selectedTopics,
                ...topicsForSelect,
            });
        }
    }, [ minedTopics ]);

    return {
        viewType,
        moveToCount,
        categoriesSelect,
        onMoveTo,
        onCreateCategory,
        onDeleteCategory,
        onEditCategory,
        setViewType,
        topics: formatTopicsData(topics),
        topicsForGraphs,
        loading,
        isEnabledSave: isEnabledSave(),
        isEnabledReset: isEnabledReset(),
        isEnabledValidateColumns: isEnabledValidateColumns(),
        onSelectTopics,
        onSelectTopicsForMove,
        onAddTopics,
        onResetTopics,
        onSaveTopics,
        cleanUpTopics,
    };
};
