import { CALL_API } from 'store/middleware/api';

export const GET_DICTIONARIES_REQUEST = 'GET_DICTIONARIES_REQUEST';
export const GET_DICTIONARIES_SUCCESS = 'GET_DICTIONARIES_SUCCESS';
export const GET_DICTIONARIES_ERROR = 'GET_DICTIONARIES_ERROR';

export const GET_NESTED_DICTIONARIES_REQUEST = 'GET_NESTED_DICTIONARIES_REQUEST';
export const GET_NESTED_DICTIONARIES_SUCCESS = 'GET_NESTED_DICTIONARIES_SUCCESS';
export const GET_NESTED_DICTIONARIES_ERROR = 'GET_NESTED_DICTIONARIES_ERROR';

export const GET_DICTIONARY_DATA_REQUEST = 'GET_DICTIONARY_DATA_REQUEST';
export const GET_DICTIONARY_DATA_SUCCESS = 'GET_DICTIONARY_DATA_SUCCESS';
export const GET_DICTIONARY_DATA_ERROR = 'GET_DICTIONARY_DATA_ERROR';

export const SET_NESTED_DICTIONARIES = 'SET_NESTED_DICTIONARIES';

export const SET_NESTED_DICTIONARIES_FILTERS = 'SET_NESTED_DICTIONARIES_FILTERS';

export const DATA_PREPROCESS_REQUEST = 'DATA_PREPROCESS_REQUEST';

export const DATA_PREPROCESS_SUCCESS = 'DATA_PREPROCESS_SUCCESS';
export const DATA_PREPROCESS_ERROR = 'DATA_PREPROCESS_ERROR';
export const ADD_DICTIONARY_REQUEST = 'ADD_DICTIONARY_REQUEST';

export const ADD_DICTIONARY_SUCCESS = 'ADD_DICTIONARY_SUCCESS';
export const ADD_DICTIONARY_ERROR = 'ADD_DICTIONARY_ERROR';

export const DELETE_DICTIONARY_REQUEST = 'DELETE_DICTIONARY_REQUEST';
export const DELETE_DICTIONARY_SUCCESS = 'DELETE_DICTIONARY_SUCCESS';
export const DELETE_DICTIONARY_ERROR = 'DELETE_DICTIONARY_ERROR';

export const EDIT_DICTIONARY_REQUEST = 'EDIT_DICTIONARY_REQUEST';
export const EDIT_DICTIONARY_SUCCESS = 'EDIT_DICTIONARY_SUCCESS';
export const EDIT_DICTIONARY_ERROR = 'EDIT_DICTIONARY_ERROR';

export const GET_DICTIONARIES_TITLES_REQUEST = 'GET_DICTIONARIES_TITLES_REQUEST';
export const GET_DICTIONARIES_TITLES_SUCCESS = 'GET_DICTIONARIES_TITLES_SUCCESS';
export const GET_DICTIONARIES_TITLES_ERROR = 'GET_DICTIONARIES_TITLES_ERROR';

export const GET_DICTIONARIES_LANGUAGES_REQUEST = 'GET_DICTIONARIES_LANGUAGES_REQUEST';
export const GET_DICTIONARIES_LANGUAGES_SUCCESS = 'GET_DICTIONARIES_LANGUAGES_SUCCESS';
export const GET_DICTIONARIES_LANGUAGES_ERROR = 'GET_DICTIONARIES_LANGUAGES_ERROR';

export const GET_DICTIONARIES_LEMMATES_REQUEST = 'GET_DICTIONARIES_LEMMATES_REQUEST';
export const GET_DICTIONARIES_LEMMATES_SUCCESS = 'GET_DICTIONARIES_LEMMATES_SUCCESS';
export const GET_DICTIONARIES_LEMMATES_ERROR = 'GET_DICTIONARIES_LEMMATES_ERROR';

export const CLEANUP_NESTED_DICTIONARIES = 'CLEANUP_NESTED_DICTIONARIES';

export const SET_SELECTED_DICTIONARIES_FILTERS = 'SET_SELECTED_DICTIONARIES_FILTERS';

export const RESET_SELECTED_DICTIONARIES_FILTERS = 'RESET_SELECTED_DICTIONARIES_FILTERS';

export const CLEANUP_DICTIONARIES_LIST = 'CLEANUP_DICTIONARIES_LIST';

export const CHANGE_DICTIONARY_FILTERS = 'CHANGE_DICTIONARY_FILTERS';

const getInitialState = () => ({
    loading: false,
    addEditLoading: false,
    filters: {
        page: 1,
        limit: 10,
        search: '',
        sort_field: null,
        sort_order: null,
        extraFilters: {
            title: [],
            language: [],
            lemmata: [],
        },
    },
    results: {
        count: 0,
        data: [],
    },
    titles: {
        data: [],
        loading: false,
    },
    languages: {
        data: [],
        loading: false,
    },
    lemmata: {
        data: [],
        loading: false,
    },
    preprocess: {
        loading: false,
    },
    nestedDictionaries: {
        count: 0,
        loading: false,
        data: [],
        filters: {
            page: 1,
            limit: 10,
            search: '',
        },
    },
    activeDictionary: {
        data: null,
        loading: false,
    },
});

export const reducer = (state = getInitialState(),
    action = {}) => {
    switch (action.type) {
        case GET_DICTIONARIES_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_DICTIONARIES_SUCCESS:
            return {
                ...state,
                results: action.response,
                loading: false,
            };

        case GET_DICTIONARIES_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_NESTED_DICTIONARIES_REQUEST:
            const nestedDictionaries = action.body.page === 1
                ? {
                    ...state.nestedDictionaries,
                    data: [],
                    count: 0,
                }
                : {
                    ...state.nestedDictionaries,
                };

            return {
                ...state,
                nestedDictionaries: {
                    ...nestedDictionaries,
                    loading: true,
                },
            };

        case GET_NESTED_DICTIONARIES_SUCCESS:
            return {
                ...state,
                nestedDictionaries: {
                    ...state.nestedDictionaries,
                    count: action.response.count,
                    data: [ ...state.nestedDictionaries.data, ...action.response.data ],
                    loading: false,
                },
            };

        case GET_NESTED_DICTIONARIES_ERROR:
            return {
                ...state,
                nestedDictionaries: {
                    ...state.nestedDictionaries,
                    loading: true,
                },
            };

        case GET_DICTIONARY_DATA_REQUEST:
            return {
                ...state,
                activeDictionary: {
                    ...state.activeDictionary,
                    data: null,
                    loading: true,
                },
            };

        case GET_DICTIONARY_DATA_SUCCESS:
            return {
                ...state,
                activeDictionary: {
                    ...state.activeDictionary,
                    data: action.response,
                    loading: false,
                },
            };

        case SET_NESTED_DICTIONARIES:
            return {
                ...state,
                nestedDictionaries: {
                    ...state.nestedDictionaries,
                    data: action.dictionaries,
                },
            };

        case GET_DICTIONARY_DATA_ERROR:
            return {
                ...state,
                activeDictionary: {
                    ...state.activeDictionary,
                    loading: true,
                },
            };

        case SET_NESTED_DICTIONARIES_FILTERS:
            return {
                ...state,
                nestedDictionaries: {
                    ...state.nestedDictionaries,
                    filters: {
                        ...state.nestedDictionaries.filters,
                        ...action.filters,
                    },
                },
            };

        case CLEANUP_NESTED_DICTIONARIES: {
            return {
                ...state,
                nestedDictionaries: {
                    ...getInitialState().nestedDictionaries,
                },
            };
        }

        case DATA_PREPROCESS_REQUEST:
            return {
                ...state,
                preprocess: {
                    ...state.preprocess,
                    loading: true,
                },
            };

        case DATA_PREPROCESS_SUCCESS:
        case DATA_PREPROCESS_ERROR:
            return {
                ...state,
                preprocess: {
                    ...state.preprocess,
                    loading: false,
                },
            };

        case ADD_DICTIONARY_REQUEST:
        case EDIT_DICTIONARY_REQUEST:
            return {
                ...state,
                addEditLoading: true,
            };

        case ADD_DICTIONARY_SUCCESS:
        case ADD_DICTIONARY_ERROR:
        case EDIT_DICTIONARY_SUCCESS:
        case EDIT_DICTIONARY_ERROR:
            return {
                ...state,
                addEditLoading: false,
            };

        case CHANGE_DICTIONARY_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };

        case GET_DICTIONARIES_TITLES_REQUEST:
            return {
                ...state,
                titles: {
                    ...state.titles,
                    loading: true,
                },

            };

        case GET_DICTIONARIES_TITLES_SUCCESS:
            return {
                ...state,
                titles: {
                    ...state.titles,
                    data: action.response,
                    loading: false,
                },
            };

        case GET_DICTIONARIES_TITLES_ERROR:
            return {
                ...state,
                titles: {
                    ...state.titles,
                    loading: false,
                },
            };

        case GET_DICTIONARIES_LANGUAGES_REQUEST:
            return {
                ...state,
                languages: {
                    ...state.languages,
                    loading: true,
                },

            };

        case GET_DICTIONARIES_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: {
                    ...state.languages,
                    data: action.response,
                    loading: false,
                },
            };

        case GET_DICTIONARIES_LANGUAGES_ERROR:
            return {
                ...state,
                languages: {
                    ...state.languages,
                    loading: false,
                },
            };

        case GET_DICTIONARIES_LEMMATES_REQUEST:
            return {
                ...state,
                lemmata: {
                    ...state.lemmata,
                    loading: true,
                },

            };

        case GET_DICTIONARIES_LEMMATES_SUCCESS:
            return {
                ...state,
                lemmata: {
                    ...state.lemmata,
                    data: action.response,
                    loading: false,
                },
            };

        case GET_DICTIONARIES_LEMMATES_ERROR:
            return {
                ...state,
                lemmata: {
                    ...state.lemmata,
                    loading: false,
                },
            };

        case SET_SELECTED_DICTIONARIES_FILTERS: {
            const { name, value } = action.payload;

            const extraFilters = {
                ...state.filters.extraFilters,
                [name]: value,
            };

            localStorage.setItem('voc_mine_dictionary_filters', JSON.stringify(extraFilters));

            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: 1,
                    extraFilters: {
                        ...extraFilters,
                    },
                },
            };
        }

        case RESET_SELECTED_DICTIONARIES_FILTERS: {
            localStorage.removeItem('voc_mine_dictionary_filters');

            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: 1,
                    extraFilters: action.payload ? {
                        ...state.filters.extraFilters,
                        [action.payload]: [],
                    } : {
                        ...getInitialState().filters.extraFilters,
                    },
                },
            };
        }

        case CLEANUP_DICTIONARIES_LIST: {
            return getInitialState();
        }

        default:
            return state;
    }
};

export function getDictionaries(body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/list`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_DICTIONARIES_REQUEST, GET_DICTIONARIES_SUCCESS, GET_DICTIONARIES_ERROR ],
            logger: true,
            unique: true,
            body,
        },
    };
}

export function getNestedDictionaries(body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/list`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_NESTED_DICTIONARIES_REQUEST, GET_NESTED_DICTIONARIES_SUCCESS, GET_NESTED_DICTIONARIES_ERROR ],
            logger: true,
            unique: true,
            body: {
                ...body,
                is_short: true,
            },
        },
    };
}

export function getDictionaryData(dictionaryId) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/children/${dictionaryId}`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_DICTIONARY_DATA_REQUEST, GET_DICTIONARY_DATA_SUCCESS, GET_DICTIONARY_DATA_ERROR ],
            logger: true,
            unique: true,
        },
    };
}

export function setNestedDictionariesFilters(filters) {
    return {
        type: SET_NESTED_DICTIONARIES_FILTERS,
        filters,
    };
}

export function cleanupNestedDictionaries() {
    return {
        type: CLEANUP_NESTED_DICTIONARIES,
    };
}

export function setNestedDictionaries(dictionaries) {
    return {
        type: SET_NESTED_DICTIONARIES,
        dictionaries,
    };
}

export function preprocessData(body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/lemmatize`,
            method: 'POST',
            contentType: 'application/json',
            types: [ DATA_PREPROCESS_REQUEST, DATA_PREPROCESS_SUCCESS, DATA_PREPROCESS_ERROR ],
            logger: true,
            unique: true,
            loggerMessageIsPrior: true,
            loggerMessages: {
                success: 'Your data was successfully reprocessed',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function addDictionary(body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/create`,
            method: 'POST',
            contentType: 'application/json',
            types: [ ADD_DICTIONARY_REQUEST, ADD_DICTIONARY_SUCCESS, ADD_DICTIONARY_ERROR ],
            logger: true,
            unique: true,
            loggerMessages: {
                success: 'Your dictionary was successfully added',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function deleteDictionaryById(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/delete/${ id }`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_DICTIONARY_REQUEST, DELETE_DICTIONARY_SUCCESS, DELETE_DICTIONARY_ERROR ],
            logger: true,
            unique: true,
            loggerMessages: {
                success: 'Your dictionary was successfully deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function editDictionary(id, body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/edit/${ id }`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ EDIT_DICTIONARY_REQUEST, EDIT_DICTIONARY_SUCCESS, EDIT_DICTIONARY_ERROR ],
            body,
            unique: true,
            logger: true,
            loggerMessages: {
                success: 'Your dictionary was successfully saved',
                error: 'Something went wrong',
            },
        },
    };
}

export function getDictionaryTitles() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/titles`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_DICTIONARIES_TITLES_REQUEST, GET_DICTIONARIES_TITLES_SUCCESS, GET_DICTIONARIES_TITLES_ERROR ],
            unique: true,
        },
    };
}

export function getDictionaryLanguages() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/languages`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_DICTIONARIES_LANGUAGES_REQUEST, GET_DICTIONARIES_LANGUAGES_SUCCESS, GET_DICTIONARIES_LANGUAGES_ERROR ],
            unique: true,
        },
    };
}

export function getDictionaryLemmates() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/lemmatas`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_DICTIONARIES_LEMMATES_REQUEST, GET_DICTIONARIES_LEMMATES_SUCCESS, GET_DICTIONARIES_LEMMATES_ERROR ],
            unique: true,
        },
    };
}

export function setSelectedDictionariesFilters(payload) {
    return {
        type: 'SET_SELECTED_DICTIONARIES_FILTERS',
        payload,
    };
}

export function resetDictionariesFilters(payload) {
    return {
        type: 'RESET_SELECTED_DICTIONARIES_FILTERS',
        payload,
    };
}

export function cleanupDictionaries() {
    return {
        type: CLEANUP_DICTIONARIES_LIST,
    };
}

export function changeDictionaryFilters(payload) {
    return {
        type: CHANGE_DICTIONARY_FILTERS,
        payload,
    };
}
