import React from 'react';
import './QuarantinesDaysFormatter.scss';

export const QuarantinesDaysFormatter = ({ quarantine }) => {
    const { days, hours, type } = quarantine.action;

    return (
        <div
            id={ 'table-action__container' }
            className='table-action__quarantine-list'>
            <span className={ `table-action__quarantine-link cell-data-wrapper` }>
                {
                    type === 'BLOCK'
                        ? 'Block'
                        : `Delay for ${ days } day(s) and ${ hours } hour(s)`
                }
            </span>
        </div>
    );
};
