import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MarkupPreview.scss';

export class MarkupPreview extends Component {
    iframeRef = React.createRef();

    componentDidMount() {
        if (this.iframeRef.current && this.props.value) {
            this.iframeRef.current.contentDocument.body.innerHTML = this.props.value;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.iframeRef.current.contentDocument.body.innerHTML = this.props.value;
        }
    }

    render() {
        return (
            <iframe className={ 'preview-iframe' } ref={ this.iframeRef }/>
        );
    }
}

MarkupPreview.propTypes = {
    value: PropTypes.string,
};
