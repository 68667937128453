import { createSelector } from 'reselect';
import { _getTypeTimeSlotSelector } from '../../../modules/survey.selectors';

const _getTimeSlotsResults = state => state.VocFeedback.survey.surveyTimeSlots;


export const timeSlotsDataSelector = createSelector(
    [ _getTimeSlotsResults, _getTypeTimeSlotSelector ],
    (data, timeSlotValue) => {
        return {
            ...data,
            timeSlotIsRegular: timeSlotValue === 'regular',
        };
    });

export const isSurveySettingDefaultSelector = createSelector([ _getTimeSlotsResults, _getTypeTimeSlotSelector ],
    (data, timeSlotValue) => {
        return timeSlotValue === 'regular' && Boolean(data.results.length);
    },
);
