import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { NumberInput } from '/components';
import { HelperService } from '/services';

export class MetaFilterItemValueControl extends Component {
    getManualValueControl() {
        const { attr, onChangeNumericValue, onChangeTextValue, value } = this.props;

        switch (attr.type) {
            case 'NUMERIC':
            case 'SCALABLE':
            case 'NPS':
                let valueToNumberInput = value;

                if (Array.isArray(valueToNumberInput)) {
                    valueToNumberInput = valueToNumberInput[0];
                }

                if (valueToNumberInput === null) {
                    onChangeNumericValue(0);
                }

                return (
                    <NumberInput
                        value={ valueToNumberInput || 0 }
                        changeHandler={ onChangeNumericValue }/>
                );

            default:
                return (
                    <input
                        type={ 'text' }
                        className={ 'form-control' }
                        value={ value || '' }
                        placeholder={ 'Value' }
                        onChange={ onChangeTextValue }
                    />
                );
        }
    }

    isValidNewOption(inputValue, value, options) {
        let returnValue = true;

        options.forEach(option => {
            if (inputValue.toLowerCase() === String(option.label) || inputValue.toLowerCase() === '') {
                returnValue = false;
            }
        });
        return returnValue;
    }

    mixCreatedValues(selectValue, value, options) {
        let newSelectValue = selectValue;
        let newValue;

        if (!selectValue && typeof value === 'string') {
            newSelectValue = { value, label: value };
        } else if (selectValue && typeof selectValue.length === 'number') {
            const hasUndefinedValue = selectValue.some(el => !el);

            if (hasUndefinedValue) {
                newValue = value.filter(val => !options.find(option => option.value === val));

                newValue.map(value => {
                    let counterOfReplace = 0;

                    newSelectValue = newSelectValue.map(el => {
                        if (!el && !counterOfReplace) {
                            counterOfReplace = 1;
                            return { value: value, label: value };
                        } else {
                            return el;
                        }
                    });

                    return value;
                });
            }
        }

        return newSelectValue;
    }

    filterOption(option, inputValue) {
        // characters are escaped here: - [ ] / { } ( ) * + ? . \ ^ $ |
        return (
            option.label.toString().toLowerCase().match(
                inputValue.toLowerCase().replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"),
            ) || []).length > 0;
    }

    render() {
        const {
            isMultiOperator,
            isChoiceAttribute,
            value,
            values,
            mapSelectOptions,
            onChangeValues,
            onChangeSelectValue,
            onOptionAdd,
            components,
            isSeparateValue,
        } = this.props;
        let options;
        let selectValue;

        if (isChoiceAttribute) {
            options = mapSelectOptions({ options: values });
            selectValue = HelperService.getSelectedValue(options, value);
        }

        const onCreateOption = option => {
            if(isSeparateValue) {
                const optionArray = option.split(',');

                optionArray.forEach(optionFromArray => {
                    onOptionAdd(optionFromArray.trim());
                });
            } else {
                onOptionAdd(option);
            }
        };

        return isChoiceAttribute
            ? <CreatableSelect
                className="vochub-select-control"
                value={ this.mixCreatedValues(selectValue, value, options) }
                isValidNewOption={ this.isValidNewOption }
                classNamePrefix="vochub-select-control"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={ document.body }
                components={ components }
                isClearable={ !isMultiOperator }
                filterOption={ this.filterOption }
                onCreateOption={ onCreateOption }
                isMulti={ isMultiOperator }
                options={ options }
                placeholder={ 'Value' }
                onChange={ isMultiOperator ? onChangeValues : onChangeSelectValue }
            />
            : this.getManualValueControl();
    }
}

MetaFilterItemValueControl.propTypes = {
    values: PropTypes.array,
    mapSelectOptions: PropTypes.func,
    value: PropTypes.any,
    isChoiceAttribute: PropTypes.bool,
    isMultiOperator: PropTypes.bool,
    onChangeValues: PropTypes.func,
    onChangeSelectValue: PropTypes.func,
    onOptionAdd: PropTypes.func,
};
MetaFilterItemValueControl.defaultProps = {};
