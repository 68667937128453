import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { autobind } from 'core-decorators';
import cx from 'classnames';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Editor } from '/components';
import { SettingFieldContainer } from '../components';
import { FileManager } from '/feedback/components';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';
import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';

import './SettingCssField.scss';

const defaultHeight = '300px';

const mapStateToProps = state => ({
    assets: assetsSelector(state),
});

const mapDispatchToProps = {
    getAssets,
    uploadAsset,
};

@connect(mapStateToProps, mapDispatchToProps)
export class SettingCssField extends Component {
    state = {
        height: defaultHeight,
        expand: false,
        isFileManagerOpen: false,
        url: '',
    };

    @autobind
    setHeight(value) {
        this.setState(prevState => ({
            ...prevState,
            height: value,
        }));
    }

    @autobind
    toggleExpand() {
        this.setState(prevState => ({
            ...prevState,
            expand: !prevState.expand,
        }));
    }

    @autobind
    toggleManager() {
        this.setState(prevState => ({
            ...prevState,
            isFileManagerOpen: !prevState.isFileManagerOpen,
        }));
        this.props.getAssets();
        ReactDOM.findDOMNode(this.buttonFileManager).blur();
    }

    @autobind
    getValue() {
        return this.props.input.value.value;
    }

    @autobind
    onChange(val) {
        const {
            input: {
                onChange,
                value,
            },
        } = this.props;

        const model = {
            ...value,
            value: val,
        };

        onChange(model);
    }

    @autobind
    onFocus() {
        this.props.input.onFocus(this.props.input.value);
    }

    @autobind
    onBlur() {
        this.props.input.onBlur(this.props.input.value);
    }

    @autobind
    insertUrl(url = '') {
        this.setState(prevState => ({
            ...prevState,
            url,
        }));
    }

    render() {
        const {
            input,
            meta: { dirty },
            label,
            input: {
                value: {
                    redefined,
                },
            },
            assets,
            getAssets,
            uploadAsset,
        } = this.props;

        const editorHeight = () => {
            return redefined && this.state.expand
                ? this.state.height
                : defaultHeight;
        };

        const disableExpandButton = () => parseInt(this.state.height) <= parseInt(defaultHeight);

        return (
            <>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                <SettingFieldContainer
                    checked={ redefined }
                    className={ `${ dirty ? 'changed' : '' }` }
                    field={ input }
                >
                    <div className={ 'setting-css__container' }
                        style={{
                            height: editorHeight(),
                        }}
                    >
                        <div className={ cx('editor', { 'collapsed': !redefined }) }>
                            <div className={ cx({ 'disabled': !redefined }) }>
                                <Editor
                                    onChange={ this.onChange }
                                    onBlur={ this.onBlur }
                                    onFocus={ this.onFocus }
                                    value={ this.getValue() }
                                    mode="css"
                                    disabled={ !redefined }
                                    height={ editorHeight() }
                                    resizeCallback={ this.setHeight }
                                    baseHeight={ defaultHeight }
                                    url={ this.state.url }
                                    clearUrl={ this.insertUrl }
                                />
                            </div>
                            <Button onClick={ this.toggleManager }
                                className={ 'setting-css__button btn button__file-manager' }
                                color={ 'white' }
                                disabled={ !redefined }
                                innerRef={ instance => {
                                    this.buttonFileManager = instance;
                                } }
                            >
                                <FontAwesomeIcon
                                    icon='upload'
                                    className={ 'setting-css__button-icon' }
                                />
                            </Button>
                            <Button onClick={ this.toggleExpand }
                                className={ 'setting-css__button btn button__expand' }
                                color={ 'white' }
                                disabled={ disableExpandButton() }
                            >
                                <FontAwesomeIcon
                                    icon={ this.state.expand ? 'compress-alt' : 'expand-arrows-alt' }
                                    className={ 'setting-css__button-icon' }
                                />
                            </Button>
                        </div>
                    </div>
                    <FileManager
                        toggle={ this.toggleManager }
                        isOpen={ this.state.isFileManagerOpen }
                        fillEditorValue={ this.insertUrl }
                        assets={ assets }
                        uploadAsset={ uploadAsset }
                        getAssets={ getAssets }
                    />
                </SettingFieldContainer>
            </>
        );
    }
}
