import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

import './SchedulesActionsFormatter.scss';

export const SchedulesActionsFormatter = props => {
    const { parameters, selected } = props;
    const openModalManage = () => props.openModalManage({ parameters, selected });
    const openModalDelete = () => props.openModalDelete(props.parameters.id);

    return (
        <div
            id={ 'table-action__container' }
            className='table-action__container table-action__export-schedule'>

            <TooltipWrapper value={ 'Edit schedule' } force>
                <span
                    className={ 'table-action__export-schedule-icon' }
                    onClick={ openModalManage }
                >
                    <FontAwesomeIcon icon="pencil-alt"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Delete schedule' } force>
                <span
                    className={ 'table-action__export-schedule-icon' }
                    onClick={ openModalDelete }
                >
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>

        </div>
    );
};
