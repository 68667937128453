import React from 'react';
import { TooltipWrapper } from '/components/index';
import { components } from 'react-select';
import { Badge } from 'reactstrap';

import './MultiValueLabel.scss';

export const MultiValueLabel = props => {
    const { data: { badge, label } } = props;

    return (
        <div className={ 'multi-value-label_container' }>
            <TooltipWrapper value={ label }>
                <div>
                    <components.MultiValueLabel { ...props }>
                        <div className={ 'multi-value-label_title' }>
                            { label }
                        </div>
                        {
                            Boolean(badge)
                            && <div className={ 'multi-value-label_badge' }>
                                <Badge color={ badge.color }>
                                    { badge.title }
                                </Badge>
                            </div>
                        }
                    </components.MultiValueLabel>
                </div>
            </TooltipWrapper>
        </div>
    );
};
