import { createSelector } from 'reselect';
import { SettingsService } from '/feedback/services/SettingsService';
import { channelSelector } from '../../../modules/globalSettings.selectors';

export const getAdvancedState = state => state.VocFeedback.globalSettings.languagesSettings;
export const getLanguagesList = state => state.VocFeedback.common.languages;

export const fieldsMapSelector = createSelector(
    [ channelSelector ],
    channel =>
        SettingsService.getFieldsByGlobalLanguageSpecific(channel),
);

export const initialFormValuesSelector = createSelector([ getAdvancedState, fieldsMapSelector ],
    ({ settings: advancedSettings }, fields) => {
        const settings = SettingsService.composeGlobalSettings(advancedSettings);

        return {
            fieldsMap: fields.map(field => ({
                ...field,
                children: field.children.map(child => ({
                    ...child,
                    redefined: true,
                    isGlobal: true,
                })),
            })),
            initialValues: settings,
        };
    });

export const advancedFormSettingsSelector = createSelector([ getAdvancedState, initialFormValuesSelector ],
    ({ loading, sending }, fields) => {
        return {
            loading,
            sending,
            ...fields,
        };
    });
