import React, { useState } from 'react';
import { Fade } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TooltipWrapper } from '/components';
import { ButtonComponent } from '/visual/components';
import {
    movePageTo, createPage,
    deletePageInStore,
} from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { IPageControls } from '/visual/scenes/Dashboard/models';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

import styles from './PageControls.module.scss';

export const PageControls = ({ pageId, pageIndex, pagesCount, dashboardId, show }: IPageControls) => {
    const { t } = useTranslation();
    const pageNumber = pageIndex + 1;
    const [ disabled, setDisabled ] = useState(false);
    const dispatch = useDispatch();

    // for disabling user's fast clicking
    const disableButtons = (callback: any) => {
        setDisabled(true);
        callback();

        setTimeout(() => {
            setDisabled(false);
        }, 300);
    };

    const movePageToIndex = (newPageIndex: number) => {
        disableButtons(() => dispatch(movePageTo({
            pageIndex,
            newPageIndex,
            pageId,
        })));
    };

    const handleMoveUp = () => movePageToIndex(pageIndex - 1);

    const handleMoveDown = () => movePageToIndex(pageIndex + 1);

    const handleCreatePageBefore = () =>
        disableButtons(() => dispatch(createPage(dashboardId, pageIndex)));

    const handleCreatePageAfter = () =>
        disableButtons(() => dispatch(createPage(dashboardId, pageIndex + 1)));

    const handleDeletePage = () =>
        disableButtons(() => {
            dispatch(showConfirm({
                header: t('deletePage'),
                content: t('deletePageMessage'),
                successCallback: () => dispatch(deletePageInStore(pageId)),
            }));
        });

    return (
        <Fade in={ show }>
            <div
                className={ styles.pageControlsWrapper }
                style={{ transform: `translate(100%) rotateY(${show ? 0 : 90}deg)` }}
            >
                <ButtonComponent
                    tooltipProps={{
                        force: true,
                        value: t('movePageUp'),
                        placement: 'left',
                    }}
                    buttonProps={{
                        disabled: pageNumber === 1 || disabled,
                        color: 'primary',
                        className: styles.actionButton,
                        id: 'move_up',
                        onClick: handleMoveUp,
                    }}
                    icon='angle-double-up'
                />
                <ButtonComponent
                    tooltipProps={{
                        force: true,
                        value: t('addPageBeforeThis'),
                        placement: 'left',
                    }}
                    buttonProps={{
                        disabled: disabled,
                        color: 'success',
                        className: styles.actionButton,
                        id: 'add_new_before',
                        onClick: handleCreatePageBefore,
                    }}
                    iconList={ [ 'chevron-up', 'plus-circle' ] }
                />

                <div className={ styles.pageNumber }>
                    <TooltipWrapper value={ `${ pageNumber } / ${ pagesCount }` }>
                        <span><b>{ pageNumber }</b> &#47; { pagesCount }</span>
                    </TooltipWrapper>
                </div>

                <ButtonComponent
                    tooltipProps={{
                        force: true,
                        value: t('addPageAfterThis'),
                        placement: 'left',
                    }}
                    buttonProps={{
                        disabled: disabled,
                        color: 'success',
                        className: styles.actionButton,
                        id: 'add_new_after',
                        onClick: handleCreatePageAfter,
                    }}
                    iconList={ [ 'plus-circle', 'chevron-down' ] }
                />
                <ButtonComponent
                    tooltipProps={{
                        force: true,
                        value: t('movePageDown'),
                        placement: 'left',
                    }}
                    buttonProps={{
                        disabled: pageNumber === pagesCount || disabled,
                        color: 'primary',
                        className: styles.actionButton,
                        id: 'move_down',
                        onClick: handleMoveDown,
                    }}
                    icon='angle-double-down'
                />
                <ButtonComponent
                    tooltipProps={{
                        force: true,
                        value: t('deletePage'),
                        placement: 'left',
                    }}
                    buttonProps={{
                        disabled: pagesCount === 1 || disabled,
                        color: 'danger',
                        className: styles.actionButton,
                        id: '3',
                        onClick: handleDeletePage,
                    }}
                    icon='trash'
                />
            </div>
        </Fade>
    );
};
