import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { VocFormTextField } from '/components';
import iconCross from '/images/icon-cross.svg';
import './CaseAlertFormEmailItem.scss';

export const CaseAlertFormEmailItem = ({ fields }) => {
    return (
        fields.map((field, index) => {
            return (
                <Row key={ index } className='case_alert_email-row__box'>
                    <Col md={ 11 } className='case_alert_email-row'>
                        <Field
                            type={ 'text' }
                            placeholder={ 'Enter your email address' }
                            name={ field }
                            defaultValue={ '' }
                            component={ VocFormTextField }
                        />
                    </Col>
                    <Col md={ 1 }>
                        <span onClick={ () => fields.remove(index) } className='case_alert_email__icon'>
                            <img src={ iconCross } alt=""/>
                        </span>
                    </Col>
                </Row>
            );
        })
    );
};
