import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { surveysPanelSelector } from '../../../modules/survey.selectors';

import { switchSurvey } from '../../../modules/survey.modules';

const mapStateToProps = state => ({
    dataSurveys: surveysPanelSelector(state),
    activeSurvey: activeSurveySelector(state),
});

const mapDispatchToProps = {
    switchSurvey,
};

@connect(mapStateToProps, mapDispatchToProps)
export class ChannelsPanelQuestionInfo extends PureComponent {
    componentDidMount() {
        const {
            match,
            activeSurvey,
        } = this.props;
        const paramsChannelId = +match.params.surveyId;
        const paramsSurveyId = +match.params.surveyGroup || activeSurvey.survey_group;

        if (paramsChannelId !== activeSurvey.id) {
            const survey = this.props.dataSurveys.surveys.find(survey => survey.id === paramsChannelId);

            survey && this.props.switchSurvey({ ...survey, survey_group: paramsSurveyId });
        }
    }

    render() {
        const { match, activeSurvey } = this.props;

        return (
            <div className="text-right question-channels-panel__info">
                {
                    match.params.questionId
                    && <div>
                        <span>Question ID:</span>
                        <b id='questionId'>{ match.params.questionId }</b>
                    </div>
                }
                <div>
                    <span>Channel:</span>
                    <b>{ activeSurvey.channel }</b>
                </div>
            </div>
        );
    }
}
