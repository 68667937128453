import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from "react-device-detect";
import cx from "classnames";

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import './VocModal.scss';

export const VocModal = ({
    modalClassName,
    isOpen,
    backdrop,
    onEnter,
    onOpened,
    onClosed,
    size,
    header,
    toggle,
    onExit,
    children,
    footer,
    wrapperBody,
    zIndex,
}) => {
    return (
        <Modal
            isOpen={ isOpen }
            backdrop={ backdrop }
            modalClassName={ cx('voc-dialog', modalClassName, { 'voc-dialog-mobile': isMobile }) }
            onEnter={ onEnter }
            onOpened={ onOpened }
            onClosed={ onClosed }
            modalTransition={{ timeout: 100 }}
            backdropTransition={{ timeout: 0 }}
            toggle={ toggle }
            size={ size }
            onExit={ onExit }
            zIndex={ zIndex }
            autoFocus={ false }
        >
            <ModalHeader toggle={ toggle }>
                <span> { header } </span>
            </ModalHeader>

            {
                wrapperBody
                    ? <ModalBody> { children } </ModalBody>
                    : children
            }

            {
                footer
                    ? <ModalFooter> { footer } </ModalFooter>
                    : null
            }

        </Modal>
    );
};

VocModal.propTypes = {
    header: PropTypes.any,
    footer: PropTypes.any,
    onEnter: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    onExit: PropTypes.func,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    size: PropTypes.string,
    backdrop: PropTypes.any,
    modalClassName: PropTypes.string,
};

VocModal.defaultProps = {
    autoFocus: true,
    backdrop: true,
    zIndex: 1050,
    wrapperBody: true,
};
