import React from 'react';
import PropTypes from 'prop-types';

export const VocFormErrorField = ({
    className,
    meta: {
        touched,
        error,
    },
}) => (
    <>
        {
            Boolean(touched && error)
                ? <span className={ `${className}` }>{ error }</span>
                : false
        }
    </>
);

VocFormErrorField.propTypes = {
    className: PropTypes.string,
};

VocFormErrorField.defaultProps = {
    className: '',
};
