import React from 'react';
import PropTypes from 'prop-types';
import { HelperService } from '/services';

export const VocTableDateFormatter = ({ field, currentDateFormat, mask = HelperService.defaultDateTimeMask }) => {
    const date = field && field.split('+')[0];

    return <span>
        {
            date?.length
                ? HelperService.formatDate(date, mask, currentDateFormat)
                : ''
        }
    </span>;
};

VocTableDateFormatter.propTypes = {
    field: PropTypes.any,
};

VocTableDateFormatter.defaultProps = {};
