import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PopoverBody, Button, ListGroupItem, UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import { VoCVisualDBService } from '/services';
import { ConfirmDeleteUploadContent } from '../ConfirmDeleteUploadContent/';

import './UploadActionsFormatter.scss';

@withTranslation()
export class UploadActionsFormatter extends Component {
    state = {
        isOpenActionModal: false,
    };

    deleteUploadProcess(id) {
        const {
            uploadToDelete: {
                virtualSourceIds,
            },
            deleteUpload,
            pageRequests,
            filters,
            userRights,
            uuidMongo,
            updateFilters,
            requestTrigger,
        } = this.props;
        const request = deleteUpload(id);

        pageRequests.addRequest(request);

        const params = {
            ...filters,
            page: 1,
        };

        request
            .promise
            .then(() => {
                if (userRights.hasVisualAccess) {
                    VoCVisualDBService.updateVisualDB(uuidMongo);
                    // BE requirements
                    virtualSourceIds.forEach(id => {
                        VoCVisualDBService.updateVisualDB(String(id));
                    });
                }
            })
            .then(() => {
                updateFilters(params);
                requestTrigger(params);
            });
    }

    @autobind
    onClickDeleteButton() {
        const { getUploadRelations, uploadId, rights } = this.props;

        rights.isPermitDelete && getUploadRelations(uploadId).promise
            .then(this.showConfirm);
    }

    @autobind
    showConfirm() {
        const {
            showConfirm,
            uploadId,
            uploadToDelete,
            t,
        } = this.props;

        showConfirm({
            header: t('deleteUpload'),
            content: <ConfirmDeleteUploadContent uploadToDelete={ uploadToDelete }/>,
            successCallback: () => this.deleteUploadProcess(uploadId),
        });
    }

    @autobind
    reLemmatization() {
        const {
            showConfirm,
            reLemmatization,
            uuidMongo,
            rights,
            t,
        } = this.props;

        rights.isPermitReLemmatization && showConfirm({
            header: t('warning'),
            content: t('confirmReLemmatizationProcess'),
            successCallback: () => reLemmatization(uuidMongo),
        });
    }

    mapHeaderRowType() {
        return this.props.headerRowType.map(({ id, type }) => {
            return (
                <ListGroupItem key={ id }>
                    { id } : <strong>{ type }</strong>
                </ListGroupItem>
            );
        });
    }

    @autobind
    getLinkClassName() {
        return this.props.isAppendFileIconDisabled || !this.props.rights.isPermitAppendFile
            ? 'table-action-link--disabled  upload-action-link'
            : ' upload-action-link';
    }

    @autobind
    onUncoverButtonClick() {
        const { uncoverUpload, uploadId, pageRequests, requestTrigger } = this.props;
        const request = uncoverUpload(uploadId);

        pageRequests.addRequest(request);

        request.promise.then(() => requestTrigger());
    }

    @autobind
    onDownloadErrorsClick() {
        const { downloadErrors, uploadId, rights } = this.props;

        rights.isPermitDownloadErrors && downloadErrors(uploadId);
    }

    @autobind
    onCancelButtonClick() {
        const { cancelUpload, uploadId, rights } = this.props;

        rights.isPermitCancel && cancelUpload(uploadId);
    }

    @autobind
    downloadSource() {
        const { initiateSourceExport, attrByDate, uuidMongo, rights } = this.props;

        rights.isPermitDownloadSource && initiateSourceExport(uuidMongo, attrByDate, 'upload');
    }

    @autobind
    onAfterReloaderClick() {
        this.props.rights.isPermitAppendFile
        && this.props.updateCurrentLocation(this.getAfterReloadLink());
    }

    @autobind
    onCreateApplianceClick() {
        const { uuidMongo, showApplianceModal, rights } = this.props;

        rights.isPermitRecord && showApplianceModal({ sourceId: uuidMongo, mode: 'create' });
    }

    isRecodeBtnDisabled() {
        const { isAppendFileIconDisabled, failedItemsCount, totalItemsCount } = this.props;

        return isAppendFileIconDisabled || (totalItemsCount === failedItemsCount);
    }

    getAfterReloadLink() {
        return `/store/upload-data/upload-new-file/${ this.props.uploadId }`;
    }

    @autobind
    toggleSocialSourceModal() {
        const { toggleSocialSourceModal, socialUploadId, rights } = this.props;

        rights.isPermitAppendFile && toggleSocialSourceModal(socialUploadId);
    }

    @autobind
    toggleActionModal() {
        this.setState(prevState => ({
            isOpenActionModal: !prevState.isOpenActionModal,
        }));
    }

    @autobind
    toggleTruncateUploadModal() {
        const {
            toggleTruncateUploadModal,
            uploadId,
            rights,
            attrByDate,
            progressItemsCount,
            totalItemsCount,
        } = this.props;

        totalItemsCount
        && progressItemsCount === 0
        && rights.isPermitDelete
        && toggleTruncateUploadModal(uploadId, attrByDate, 'upload');
    }

    render() {
        const {
            progressItemsCount,
            totalItemsCount,
            failedItemsCount,
            uploadId,
            uuidMongo,
            isSocial,
            showReLemmatization,
            isFollowed,
            openInviteModal,
            openSourceDetailsModal,
            onInfoButtonClick,
            onRenameButtonClick,
            rights: {
                isPermitShare,
                isPermitDownloadSource,
                isPermitCancel,
                isPermitDownloadErrors,
                isPermitRecord,
                isPermitSourceDetails,
                isPermitAppendFile,
                isPermitReLemmatization,
                isPermitDelete,
                isPermitEdit,
            },
            t,
        } = this.props;

        const popoverTarget = `source-icon-${ uploadId }`;

        return (
            <div className='table-actions uploads-list'>
                <div
                    id={ popoverTarget }
                    className={ 'table-main-action-btn-wrapper' }
                    onClick={ this.toggleActionModal }
                >
                    <FontAwesomeIcon icon={ 'ellipsis-h' } />
                </div>
                <UncontrolledPopover
                    trigger="legacy"
                    placement="right"
                    target={ popoverTarget }
                    className={ 'uploadListPopover' }
                    isOpen={ this.state.isOpenActionModal }
                    toggle={ this.toggleActionModal }
                >
                    <PopoverBody>
                        <div className='uploads-list-popover-body' onClick={ this.toggleActionModal }>

                            <span className={ 'table-action-btn-wrapper' }>
                                <Button
                                    onClick={ () => onInfoButtonClick({
                                        id: uploadId,
                                        rights: this.props.rights,
                                    }) }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='info-circle' />
                                    { t('field.info') }
                                </Button>
                            </span>
                            
                            <span className={ cx(
                                'table-action-btn-wrapper', { disabled: !isPermitEdit }
                            ) }
                            >
                                <Button
                                    disabled={ !isPermitEdit }
                                    onClick={ () => isPermitEdit && onRenameButtonClick(uploadId) }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='pencil-alt'/>
                                    { t('field.renameTitle') }
                                </Button>
                            </span>
                            
                            <span className={ cx(
                                'table-action__btn-share table-action-btn-wrapper', { disabled: !isPermitShare },
                            ) }
                            >
                                <Button
                                    disabled={ !isPermitShare }
                                    onClick={ () => isPermitShare && openInviteModal(uploadId, uuidMongo) }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='share' />
                                    { t('share') }
                                </Button>
                            </span>

                            <span className={ cx(
                                'table-action-btn-wrapper', { disabled: !isPermitDownloadSource },
                            ) }>
                                <Button
                                    disabled={ progressItemsCount !== 0 || !totalItemsCount }
                                    onClick={ this.downloadSource }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='cloud-download-alt' />
                                    { t('downloadSource') }
                                </Button>
                            </span>

                            <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitCancel }) }>
                                <Button
                                    disabled={ progressItemsCount === 0 }
                                    onClick={ this.onCancelButtonClick }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='ban' />
                                    { t('cancel') }
                                </Button>
                            </span>

                            <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitDownloadErrors }) }>
                                <Button
                                    disabled={ failedItemsCount === 0 }
                                    onClick={ this.onDownloadErrorsClick }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='download' />
                                    { t('downloadErrors') }
                                </Button>
                            </span>

                            <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitRecord }) }>
                                <Button
                                    disabled={ this.isRecodeBtnDisabled() }
                                    onClick={ this.onCreateApplianceClick }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='sitemap' />
                                    { t('recodeVariables') }
                                </Button>
                            </span>

                            <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitSourceDetails }) }>
                                <Button
                                    className={ 'table-action-btn-wrapper' }
                                    onClick={ () => isPermitSourceDetails
                                        && openSourceDetailsModal({
                                            uuidMongo, tableData: this.mapHeaderRowType(),
                                        })
                                    }
                                    color={ 'link' }
                                    id={ popoverTarget }
                                >
                                    <FontAwesomeIcon icon="list" className='table-action__glyph' />
                                    { t('sourceDetails') }
                                </Button>
                            </span>

                            {
                                !isSocial
                                    ? <span
                                        className={ cx('table-action-btn-wrapper', { disabled: !isPermitAppendFile }) }
                                        onClick={ this.onAfterReloaderClick }
                                    >
                                        <Button color="link">
                                            <Link
                                                className={ this.getLinkClassName() }
                                                to={ this.getAfterReloadLink() }
                                            >
                                                <FontAwesomeIcon icon='file-import' />
                                                { t('appendFile') }
                                            </Link>
                                        </Button>
                                    </span>
                                    : <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitAppendFile }) }>
                                        <Button
                                            className={ isFollowed ? 'target-followed-btn' : '' }
                                            onClick={ this.toggleSocialSourceModal }
                                            color="link"
                                        >
                                            <FontAwesomeIcon icon='crosshairs' />
                                            { t('updateSocialSource') }
                                        </Button>
                                    </span>
                            }

                            <span
                                className={
                                    cx('table-action-btn-wrapper', { disabled: !isPermitReLemmatization })
                                }
                            >
                                <Button
                                    disabled={ !showReLemmatization }
                                    onClick={ this.reLemmatization }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='registered' />
                                    { t('reLemmatization') }
                                </Button>
                            </span>

                            <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitDelete }) }>
                                <Button
                                    disabled={ progressItemsCount !== 0 || !totalItemsCount }
                                    onClick={ this.toggleTruncateUploadModal }
                                    color="link"
                                >
                                    <FontAwesomeIcon icon='cut' />
                                    { t('truncateUpload') }
                                </Button>
                            </span>

                            <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitDelete }) }>
                                <Button onClick={ this.onClickDeleteButton } color="link">
                                    <FontAwesomeIcon icon='trash' />
                                    { t('deleteUpload') }
                                </Button>
                            </span>

                        </div>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }
}

UploadActionsFormatter.propTypes = {
    updateCurrentLocation: PropTypes.func,
    reLemmatization: PropTypes.func,
    showReLemmatization: PropTypes.bool,
    progressItemsCount: PropTypes.number,
    isAppendFileIconDisabled: PropTypes.bool,
    failedItemsCount: PropTypes.number,
    headerRowType: PropTypes.array,
    uploadId: PropTypes.any,
    uuidMongo: PropTypes.any,
    downloadErrors: PropTypes.func,
    uncoverUpload: PropTypes.func,
    requestTrigger: PropTypes.func,
    totalItemsCount: PropTypes.number,
    uploadToDelete: PropTypes.object,
    userRights: PropTypes.object,
    pageRequests: PropTypes.object,
    showConfirm: PropTypes.func,
    showApplianceModal: PropTypes.func,
};
