import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Icons } from '/components';

import './ButtonsPanelSelector.scss';

export const ButtonsPanelSelector = ({
    disabled,
    options,
    value,
    onChange,
}) => {
    const onSelect = val => {
        onChange && onChange(val);
    };

    const buttonList = useMemo(() => {
        return options.map(item => {
            return (
                <Button
                    key={ item.value }
                    disabled={ disabled || item.disabled }
                    className={ cx('selector-view-buttons-panel__button', { active: item.value === value }) }
                    onClick={ () => onSelect(item.value) }
                    color='link'
                    outline
                >
                    {
                        item.fontAwesomeIcon
                            ? <FontAwesomeIcon
                                icon={ item.fontAwesomeIcon }
                                className='icon'
                            />
                            : <Icons
                                icon={ item.icon }
                                className='icon'
                            />
                    }
                    <span> { item.label } </span>
                </Button>

            );
        });
    }, [ options, value, disabled ]);

    return (
        <div className='selector-view-buttons-panel'>
            { buttonList }
        </div>
    );
};

ButtonsPanelSelector.propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes
        .arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            icon: PropTypes.string,
            fontAwesomeIcon: PropTypes.string,
        }))
        .isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

ButtonsPanelSelector.defaultProps = {
    disabled: false,
};
