import { createSelector } from 'reselect';
import { formValueSelector, isDirty } from 'redux-form';
import { exportModalDateFormatSelect, exportModalTimeFormatSelect } from '/modules/selectors';

const _getExporter = state => state.VocFeedback.exporter;
const _getLanguages = state => state.VocFeedback.common.languages.results;

export const exporterMapData = {
    delimiterMap: [
        { label: ' , (comma delimiter)', value: ',' },
        { label: ' ; (semicolon delimiter)', value: ';' },
        { label: ' . (dot delimiter)', value: '.' },
        { label: ' | (vertical bar delimiter)', value: '|' },
        { label: ' ↹ (tab delimiter)', value: '\t' },
        // { label: 'special combination', value: '$|' },
    ],
    columnHeadersMap: [
        { label: 'Question Text', value: 'question_text' },
        { label: 'Question Label', value: 'question_title' },
        { label: 'External Identifier', value: 'question_external_id' },
    ],
    columnHeadersCustom: [
        { label: 'Custom', value: 'question_label' },
    ],
    fileTypeMap: [
        { value: 'xls', label: 'xls' },
        { value: 'csv', label: 'csv' },
        { value: 'xlsx', label: 'xlsx' },
        // { value: 'sav', label: 'sav' },
        { value: 'json', label: 'json' },
    ],
    statusMap: [
        { value: 'Active', label: 'Started' },
        // { value: 'Created', label: 'Created' },
        { value: 'Invited', label: 'Invited' },
        { value: 'Terminated', label: 'Terminated' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Expired', label: 'Expired' },
        // { value: 'Extracted', label: 'Extracted' },
        { value: 'Aborted', label: 'Partial' },
        { value: 'Blacklisted', label: 'Blacklisted' },
        { value: 'Quarantined', label: 'Quarantined' },
        { value: 'Invite not delivered', label: 'Invite not delivered' },
    ],
    exportTypeMap: [
        { label: 'Default', value: 'default' },
        { label: 'Default (Emulate Default Legacy exporter)', value: 'emulate_legacy_default' },
        { label: 'Custom', value: 'custom' },
        { label: 'Blacklist', value: 'blacklist' },
    ],
    filterQuestionnairesMap: [
        { label: 'Creation Date', value: 'Creation date' },
        { label: 'Invitation Date', value: 'Invitation date' },
        { label: 'Start Date', value: 'Start date' },
        { label: 'End Date', value: 'End date' },
    ],
};

export const exporterDynamicFormMapData = {
    export_conditions: [
        { value: '<', label: '<' },
        { value: '<=', label: '<=' },
        { value: '>', label: '>' },
        { value: '=', label: '=' },
        { value: '>=', label: '>=' },
        { value: '!=', label: '!=' },
        { value: 'like%', label: 'like%' },
        { value: '%like%', label: '%like%' },
    ],
};

export const customColumnsList = [
    "column_title",
    "data_type",
    "data_value",
];

export const exportConditionsList = [
    "question_external_identifier",
    "expression",
    "matching_value",
];

export const languagesSelector = createSelector([ _getLanguages ], languages => {
    return languages.map(lang => ({ value: lang.iso_6391_code, label: lang.name }));
});

export const exporterDataSelector = createSelector(
    [ _getExporter, exportModalDateFormatSelect, exportModalTimeFormatSelect ],
    ({ loading, result }, dateFormatOptions, timeFormatOptions) => {
        const firstWhiteSpaceOccurrence = /^(\S+)\s(.*)/;

        const matchFirstWhiteSpaceOccurrence = result?.date_format
            ?.match(firstWhiteSpaceOccurrence)?.slice(1);

        const matchFirstBackSlash = result?.date_format
            ?.split("\\");

        const dates = (
            matchFirstWhiteSpaceOccurrence
                ? matchFirstWhiteSpaceOccurrence
                : matchFirstBackSlash
        ) || [];

        return {
            loading,
            initialValues: {
                ...result,
                ...{
                    date_format: dates[0],
                    time_format: dates[1],
                },
            },
            dateFormatOptions,
            timeFormatOptions,
        };
    });

const selector = formValueSelector('ExporterForm');

export const formValuesSelector = state => selector(
    state,
    'export_type',
    'custom_columns',
    'export_conditions',
    'output_type',
    'question_column_header',
    'date_format',
    'time_format',
    'filter_by_date',
    'filter_by_states',
);

export const getDirtyStatus = state => isDirty('ExporterForm')(state);

export const typeOptionsList = createSelector([ _getExporter ], ({ dataTypesList }) => {
    return dataTypesList.reduce((acc, option) => {
        const newOption = {
            value: option.data_type_name,
            label: option.ui_name,
            valueRequired: option.data_value_required,
        };

        const group = acc.find(item => item.label === option.group);

        if (group) {
            group.options.push(newOption);
        } else {
            acc.push({
                label: option.group,
                options: [ newOption ],
            });
        }

        return acc;
    }, []);
});
