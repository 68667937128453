import { Http } from './HttpClient';
import { showToast } from '/components';
import { ResponseMessageConverter } from '/services';
import { VocRoutesSelector } from '/modules/selectors';
import { getUserInfo, invalidateCache, setCache } from '/modules';

import sha1 from 'sha1';

export const CALL_API = Symbol('Call API');
export const FEEDBACK = 'FEEDBACK';

export function makeRequestAction({ requestType, params, body }) {
    return {
        type: requestType,
        params,
        body,
    };
}

const getUserMessage = request => request.loggerMessages || {};

function getSuccessMessage(response, request) {
    if (request.loggerMessageIsPrior) return getUserMessage(request).success || '';
    return ResponseMessageConverter.getSuccessMessage(response) || getUserMessage(request).success || '';
}

function getErrorMessage(response, request) {
    if (request.loggerMessageIsPrior) return getUserMessage(request).error || '';
    return ResponseMessageConverter.getErrorMessage(response) || getUserMessage(request).error || '';
}

function cacheLogic(cache, uniqueData, response, store) {
    const hash = sha1(JSON.stringify(uniqueData));
    const { namespaceName, collectionAccsessor } = cache;

    if (cache.write) {
        const config = {
            namespaceName,
            hash,
            collectionAccsessor,
            response,
        };

        store.dispatch(setCache(config));
    }

    if (cache.remove) {
        store.dispatch(invalidateCache(namespaceName));
    }
}

export default store => next => action => {
    const request = action[CALL_API];

    if (typeof request === 'undefined') {
        return next(action);
    }

    const {
        types: [
            requestType,
            successType,
            errorType,
        ],
        body,
        endpoint,
        params,
        cache,
    } = request;

    if (request.apiType !== FEEDBACK) {
        const url = VocRoutesSelector(store.getState())[request.endpoint];

        if (url) {
            request.endpoint = url;
        }

        if (request.urlParams) {
            Object.keys(request.urlParams).forEach(key => {
                request.endpoint = request.endpoint.replace(`{${ key }}`, request.urlParams[key]);
            });
        }
    }

    const successRequest = response => {
        const showLog = typeof request.logger === 'object' ? request.logger.success : request.logger;
        const loggerNotShow = request.loggerSuccessNotShow;

        if (showLog && !loggerNotShow) {
            const message = getSuccessMessage(response, request);

            message && store.dispatch(showToast({ text: message, type: 'success' }));
        }

        if (cache) {
            cacheLogic(cache, { body, endpoint }, response, store);
        }

        if (successType) {
            return next({
                response,
                data: body,
                params,
                type: successType,
            });
        }
    };

    const errorRequest = error => {
        const errorStatus = error.status;
        const showLog = typeof request.logger === 'object' ? request.logger.error : request.logger;
        const loggerNotShow = request.loggerErrorNotShow;

        if ((errorStatus === 401 || errorStatus === 403 || errorStatus === 404) && request.redirect) {
            window.location.href = request.redirect;
        } else if (errorStatus === 401 || errorStatus === 403) {
            const message = getErrorMessage(error.response, {});

            message && store.dispatch(showToast({ text: message, type: 'error' }));

            // Checking if the user is logged out
            store.dispatch(getUserInfo())
                .promise
                .then(
                    response => {
                        // If typeof response is 'string' then the user is logged out
                        typeof response === 'string' && window.location.reload();
                    },
                    error => {
                        console.warn('errorStatus 401 || 403', error);
                    },
                );
        } else if (showLog && errorStatus !== 0) {
            const messages = loggerNotShow ? null : getErrorMessage(error.response, request);

            if (Array.isArray(messages)) {
                messages.forEach(mes => store.dispatch(showToast({ text: mes, type: request.typeMessage || 'error' })));
            } else {
                messages && store.dispatch(showToast({ text: messages, type: request.typeMessage || 'error' }));
            }
        }

        // Skip if request status Unsent (Aborted)
        if (errorStatus === 0) {
            return;
        }

        if (errorType) {
            return next({
                error,
                params,
                type: errorType,
            });
        }
    };

    store.dispatch(makeRequestAction({ requestType, params, body }));

    if (!request.contentType) {
        request.contentType = 'application/json';
    }

    const apiRequest = Http[request.method.toLowerCase()](request);

    apiRequest.promise.then(successRequest, errorRequest);

    return apiRequest;
};
