import React, { PureComponent, Fragment } from 'react';
import { SettingFieldContainer } from '../components';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import { TooltipWrapper, CopyToClipboard } from '/components';
import './StaticLinkField.scss';

export class StaticLinkField extends PureComponent {
    state = {
        pending: false,
    };

    @autobind
    getNewLink() {
        if (this.state.pending) {
            return;
        }

        this.setState({
            pending: true,
        });

        this.props.getNewLink()
            .then(link => {
                this.props.input.onChange({
                    ...this.props.input.value,
                    value: link,
                });
                this.setState({
                    pending: false,
                });
            });
    }

    @autobind
    onCopy(status) {
        const {
            showToast,
        } = this.props;

        status
            ? showToast({ text: 'Link was successfully copied', type: 'success' })
            : showToast({ text: 'Something went wrong!', type: 'error' });
    }

    render() {
        const { pending } = this.state;
        const {
            input,
            label,
            input: {
                value: {
                    redefined,
                },
            },
        } = this.props;

        return (
            <Fragment>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                { input.value.value
                    ? <div className={ 'static-link__wrapper' }>
                        <div className={ 'static-link__value' }>
                            <a
                                href={ input.value.value }
                                target={ '_blank' }
                                rel='noreferrer'
                            >
                                { input.value.value }
                            </a>
                        </div>
                        <div className={ 'static-link__actions' }>
                            <TooltipWrapper value={ 'Will create a new link if survey url was changed' } force>
                                <Button className={ 'static-link__button' } color={ 'secondary' }
                                    onClick={ this.getNewLink }>Update</Button>
                            </TooltipWrapper>
                            <TooltipWrapper value={ 'Copy link to the clipboard' } force>
                                <CopyToClipboard text={ input.value.value } onCopy={ this.onCopy }>
                                    <Button className={ 'static-link__button' } color={ 'secondary' }>Copy link</Button>
                                </CopyToClipboard>
                            </TooltipWrapper>
                        </div>
                    </div>
                    : <SettingFieldContainer
                        checked={ redefined }
                        className={ 'static-link__container' }
                        field={ input }
                    >
                        <Button color={ 'primary' }
                            className={ 'static-link__button static-link__button--primary' }
                            onClick={ this.getNewLink }
                            disabled={ !redefined || pending }>Get static
                            link</Button>
                    </SettingFieldContainer>
                }
            </Fragment>
        );
    }
}
