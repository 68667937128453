import { max, min, scaleLinear, axisBottom, event } from 'd3';
import cx from 'classnames';
import { autobind } from 'core-decorators';
import tinyColor from 'tinycolor2';

import { HelperService } from '/services';
import type {
    IBaseChartService,
    IDrawBubbleXAxis,
    IDrawBubbles,
    bubbleData,
    segmentGroupData,
} from '/visual/scenes/Dashboard/components/Gadget/models';
import { AxisService } from '/visual/scenes/Dashboard/components';
import { BaseChartService } from '../../BaseChart/services';

import baseStyles from '../../BaseChart/services/style.module.scss';
import styles from '../style.module.scss';

export class BubbleChartService extends BaseChartService {
    /* public constants */
    groupItemClass = 'bubble';
    groupTitleClass = 'groupTitles'
    MAX_SEGMENT_TITLE_WIDTH = 63;
    GROUP_SEGMENT_TITLE_OFFSET = 5;

    /* class properties */
    bubbles: bubbleData[];
    longLabel: boolean;

    constructor(props: IBaseChartService) {
        super(props);

        this.bubbles = [];
        this.longLabel = false;
    }

    /* protected methods */
    @autobind
    protected _getScore(b: bubbleData) {
        return b.score;
    }

    @autobind
    protected _getValue(b: bubbleData) {
        return b.value;
    }

    @autobind
    protected _getCount(b: bubbleData) {
        return b.count;
    }

    @autobind
    protected _getVerticalBubbleGridX(value: number) {
        return this.xScale(value);
    }

    @autobind
    protected _updateVerticalBubbleLine(linesGroup: any) {
        return linesGroup
            .attr('x1', this._getVerticalBubbleGridX)
            .attr('x2', this._getVerticalBubbleGridX)
            .attr('y1', 0)
            .attr('y2', this.chartHeight);
    }

    @autobind
    protected _axisOffset(min: number, max: number) {
        return (max - min) * this.chartConfig.axisOffset;
    }

    @autobind
    protected _getBubbleCenterX(b: bubbleData) {
        return this.xScale(this._getValue(b));
    }

    @autobind
    protected _getBubbleCenterY(b: bubbleData) {
        return this.yScale(this._getScore(b));
    }

    @autobind
    protected _fillBubble(b: bubbleData) {
        return b.color;
    }

    @autobind
    protected _getBubbleRadius(b: bubbleData) {
        const maxValue = max(this.bubbles, (bubble: bubbleData) => this._getCount(bubble)) || 0;
        const ratio = this.chartWidth / this.chartHeight;
        const q = ratio > 10 ? 0.02 : 0.05;
        const radius = (b.count / maxValue * (this.chartWidth + this.chartHeight) * q);

        if (radius > this.chartConfig.maxRadius) {
            return this.chartConfig.maxRadius;
        }

        return radius < this.chartConfig.minRadius
            ? this.chartConfig.minRadius
            : radius;
    }

    @autobind
    protected _getBubbleStrokeColor(b: bubbleData) {
        const isColorLight = tinyColor(b.color).isLight();

        return isColorLight
            ? tinyColor(b.color).darken(5).toString()
            : tinyColor(b.color).lighten(5).toString();
    }

    @autobind
    protected _animatedRadius(b: bubbleData) {
        return this._getBubbleRadius(b) + this.chartConfig.overRadius;
    }

    @autobind
    protected _getGroupTitleText(g: segmentGroupData) {
        return this.longLabel
            ? g.label
            : HelperService.trimByWidth(g.label, this.MAX_SEGMENT_TITLE_WIDTH, '12px sans-serif');
    }

    @autobind
    protected _getGroupTitleTranslate(g: segmentGroupData) {
        return `translate(${this.xScale(g.value)}, ${-this.GROUP_SEGMENT_TITLE_OFFSET})`;
    }

    @autobind
    protected _onColorPickerOpen(b: bubbleData) {
        this._setColorPickerData({
            open: true,
            coords: { y: event.pageY, x: event.pageX },
            target: `${this.chartType}_colorPicker_${this.gadgetId}`,
            color: b.color,
            elementId: b.id,
        });

        event.preventDefault();
    }

    @autobind
    protected _onBubbleEnter(b: bubbleData) {
        this.toolTipRef.html('');

        this.toolTipRef
            .append('span')
            .text(`${ this._t('field.name') }: ${b.label}`);

        this.toolTipRef
            .append('span')
            .text(`${b.valueLabel}: ${this._getValue(b)}`);

        this.toolTipRef
            .append('span')
            .text(`${b.scoreLabel}: ${this._getScore(b).toFixed(2)}`);

        this.toolTipRef
            .append('span')
            .text(`${ this._t('field.answers') }: ${this._getCount(b)}`);
    }

    @autobind
    protected _onItemOver(b: bubbleData) {
        this._onItemOverMove();

        this.chartContainer.selectAll(`.${this.groupItemClass}`)
            .filter(( bubble: { customId: string, segmentGroupTitle: string }) =>
                bubble.customId === b.customId
                && bubble.segmentGroupTitle === b.segmentGroupTitle,
            )
            .attr('r', this._animatedRadius)
            .attr('class', cx(this.groupItemClass, styles.bubble, styles.hovered));
    }

    @autobind
    protected _onItemOut(b: bubbleData) {
        const { target } = event;

        this.chartContainer
            .selectAll(`.${this.groupItemClass}`)
            .filter(( bubble: { customId: string, segmentGroupTitle: string }) =>
                bubble.customId === b.customId
                && bubble.segmentGroupTitle === b.segmentGroupTitle,
            )
            .attr('class', cx(this.groupItemClass, styles.bubble))
            .attr('r', this._getBubbleRadius);

        target.classList.remove(this.itemHoveredClass);

        this.toolTipRef
            .style('visibility', 'hidden')
            .html('');
    }

    /* public methods */
    getDomain({ array = [], key }: { array: bubbleData[], key: string }) {
        let minValue, maxValue;

        if (key === 'score') {
            minValue = min(array, (bubble: bubbleData) => this._getScore(bubble)) || 0;
        } else {
            minValue = 0;
        }

        maxValue = max(array, (bubble: bubbleData) =>
            key === 'score' ? this._getScore(bubble) : this._getValue(bubble)) || 0;
        const offset = this._axisOffset(minValue, maxValue);

        minValue = key === 'score' ? minValue - offset : minValue;
        maxValue = maxValue + offset;

        if (key === 'score') {
            this.maxYValue = maxValue;
        } else {
            this.maxXValue = maxValue;
        }

        return [ minValue, maxValue ];
    }

    setHighlightedBubble(highlightedLabel: string | null) {
        const isHighlighted = (bubble: bubbleData) =>
            (HelperService.checkNotNullOrUndefined(highlightedLabel) || highlightedLabel === '')
            && bubble.id === highlightedLabel;

        this.chartContainer
            .selectAll(`.${this.groupItemClass}`)
            .attr('class', (bubble: bubbleData) =>
                cx(this.groupItemClass, styles.bubble, { [styles.hovered]: isHighlighted(bubble) }),
            )
            .attr('r', (bubble: bubbleData) =>
                isHighlighted(bubble)
                    ? this._animatedRadius(bubble)
                    : this._getBubbleRadius(bubble),
            );
    }

    drawBubbleXAxis({
        domainMin,
        domainMax,
        rangeFrom,
        rangeTo,
        additionalSettings,
        tickFormat,
    }: IDrawBubbleXAxis) {
        // tick count from config
        const tickCount = this.chartConfig.xBottomTicks;
        const xScale = scaleLinear()
            .domain([ domainMin, domainMax ])
            .range([ rangeFrom, rangeTo ]);

        // tick values without formatting
        const originalTickValues = xScale.ticks(tickCount);
        const xAxisBottom = axisBottom(xScale);

        // set count of ticks
        xAxisBottom.ticks(tickCount);

        // format each tick
        if (tickFormat) {
            xAxisBottom.tickFormat(tickFormat);
        }

        const xAxis = this.chartContainer
            .selectAll(`.${this.xAxisClass}`)
            .call(xAxisBottom);

        if (additionalSettings) {
            additionalSettings.forEach(({ prop, value }) => {
                Array.isArray(value) ? xAxis[prop](...value) : xAxis[prop](value);
            });
        }

        this.xScale = xScale;

        if (this._withVerticalGrid) {
            // get vertical grid container (<g /> tag) and search all vertical grid line
            const gridLines = this.chartContainer
                .select(`.${this.verticalGridClass}`)
                .selectAll(`.${this.gridLineClass}`)
                .data(originalTickValues);

            // build vertical grid line
            gridLines
                .enter()
                .append('line')
                .attr('class', cx(this.gridLineClass, baseStyles.gridLine))
                .call(this._updateVerticalBubbleLine);

            // remove unnecessary grid lines
            gridLines
                .exit()
                .remove();

            // update coordinates for each existed grid line
            gridLines.call(this._updateVerticalBubbleLine);
        }

        const xFormattedTickValues = this.chartContainer
            .selectAll(`.${this.xAxisClass} .${this.tickClass} > text`)
            .nodes()
            .map((i: SVGTextElement) => i.innerHTML);

        this.chartContainer
            .selectAll(`.${this.xAxisClass} .${this.tickClass}`)
            .data(xFormattedTickValues
                .map((d: string, index: number) => ({ label: originalTickValues[index] })),
            )
            .call(this._addTickHoverEvent);

        AxisService.rotateTicks({
            dataSet: xFormattedTickValues.map((i: string) => ({ label: i, id: i })),
            chartWidth: this.chartWidth,
            xAxisView: this.chartContainer.selectAll(`.${this.xAxisClass} .${this.tickClass} text`),
            withPseudoAxis: this._withPseudoAxis,
        });

        this.checkIfNeedRedrawing();

        return this;
    }

    drawBubbles({
        bubbles,
        longLabel,
    }: IDrawBubbles) {
        this.bubbles = bubbles;
        this.longLabel = longLabel;

        this.chartContainer
            .select(`.${this.elementsContainerClass}`)
            .selectAll(`.${this.groupItemClass}`)
            .data(bubbles, (b: bubbleData) => b.customId)
            .join(
                (enter: any) => {
                    enter
                        .append('circle')
                        .attr('class', cx(this.groupItemClass, styles.bubble))
                        .attr('cx', this._getBubbleCenterX)
                        .attr('cy', this._getBubbleCenterY)
                        .attr('fill', this._fillBubble)
                        .attr('r', this._getBubbleRadius)
                        .attr('stroke', this._getBubbleStrokeColor)
                        .attr('stroke-width', 1.5)
                        .sort((a: bubbleData, b: bubbleData) => this._getCount(b) - this._getCount(a))
                        // add event listeners for each nps dot
                        .on('contextmenu', this._onColorPickerOpen)
                        .on('mouseenter', this._onBubbleEnter)
                        .on('mouseover', this._onItemOver)
                        .on('mousemove', this._onItemOverMove)
                        .on('mouseout', this._onItemOut);
                },
                (update: any) => {
                    update
                        .attr('cx', this._getBubbleCenterX)
                        .attr('cy', this._getBubbleCenterY)
                        .attr('fill', this._fillBubble)
                        .attr('r', this._getBubbleRadius)
                        .attr('stroke', this._getBubbleStrokeColor)
                        // add event listeners for each nps dot
                        .on('contextmenu', this._onColorPickerOpen)
                        .on('mouseenter', this._onBubbleEnter)
                        .on('mouseover', this._onItemOver)
                        .on('mousemove', this._onItemOverMove)
                        .on('mouseout', this._onItemOut);

                    update.exit().remove();
                },
            );

        if (this._showScore) {
            const segmentGroups = HelperService
                .uniqeArray(bubbles, 'segmentGroupTitle')
                .map((bubble: bubbleData) => ({
                    label: bubble.segmentGroupTitle,
                    value: bubble.value,
                }));

            // init group titles container
            this.chartContainer.selectAll(`.${this.groupTitleClass}`).remove();
            const groupTitles = this.chartContainer
                .append('g')
                .attr('class', cx(this.groupTitleClass));

            groupTitles
                .selectAll(`.${this.scoreClass}`)
                .data(segmentGroups)
                .join(
                    (enter: any) => {
                        enter
                            .append('g')
                            .attr('transform', this._getGroupTitleTranslate)
                            .on('mouseenter', this.tickEnter)
                            .on('mouseover', this.tickOver)
                            .on('mousemove', this.tickOver)
                            .on('mouseout', this.tickOut)
                            .append('text')
                            .attr('class', cx(this.scoreClass, styles.circleNumber))
                            .text(this._getGroupTitleText);
                    },
                    (update: any) => {
                        update.exit().remove();
                        update
                            .selectAll(`.${this.scoreClass}`)
                            .remove();

                        update
                            .attr('transform', this._getGroupTitleTranslate)
                            .on('mouseenter', this.tickEnter)
                            .on('mouseover', this.tickOver)
                            .on('mousemove', this.tickOver)
                            .on('mouseout', this.tickOut)
                            .append('text')
                            .attr('class', cx(this.scoreClass, styles.circleNumber))
                            .text(this._getGroupTitleText);
                    },
                );
        }

        return this;
    }
}
