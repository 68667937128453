import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import { HelperService } from '/services';
import { ConfirmModificationVirtualContent } from '../ConfirmModificationVirtualContent';

@withTranslation()
export class VirtualSourceFormBodyHead extends Component {
    shouldComponentUpdate(nextProps) {
        return (this.props.name !== nextProps.name
            || this.props.type !== nextProps.type
            || this.props.invalid !== nextProps.invalid);
    }

    state = {
        showModal: false,
    };

    @autobind
    onTypeHandler(option) {
        const value = option ? option.value : '';
        const { virtualRelation, type, id, columnId = null } = this.props;

        if (!option || type !== option.value) {
            const model = {
                value: value,
                columnIndex: id,
                id: virtualRelation.needToShowModal ? null : columnId,
            };

            this.onChangeHandler(() => this.props.onTypeChange(model));
        }
    }

    @autobind
    onChangeHandler(action) {
        const {
            virtualRelation,
            showChangeNotificationModal,
            setShowChangeNotificationModal,
            showConfirm,
            columnId,
            t,
        } = this.props;

        if (virtualRelation.needToShowModal && showChangeNotificationModal && columnId) {
            showConfirm({
                header: t('warning'),
                content: <ConfirmModificationVirtualContent virtualRelation={ virtualRelation }/>,
                successCallback: () => {
                    setShowChangeNotificationModal(false);
                    action();
                },
            });
        } else {
            action();
        }
    }

    @autobind
    onNameHandler(e) {
        const { value } = e.target;

        if (this.props.name !== value) {
            const model = {
                value: value,
                id: this.props.id,
            };

            this.props.onNameChange(model);
        }
    }

    @autobind
    removeColumn() {
        this.onChangeHandler(() => this.props.removeVirtualSourceColumn(this.props.id));
    }

    render() {
        const { type, invalid, name, mapColumnTypesToSelect, t } = this.props;
        const columnOptions = mapColumnTypesToSelect();
        const columnValue = HelperService.getSelectedValue(columnOptions, type);

        return (
            <div className="fixed-table__th">
                <div className={ 'fixed-table__col-icon fixed-table__col-icon--fixed' } onClick={ this.removeColumn }>
                    <FontAwesomeIcon icon='times' />
                </div>
                <Input
                    type='text'
                    className={ `virtual-sources-form__header-input ${ invalid && 'invalid' }` }
                    value={ name }
                    placeholder={ t('placeholders.columnName') }
                    onChange={ this.onNameHandler }
                />
                <Select
                    value={ columnValue || '' }
                    classNamePrefix={ 'vochub-select-control' }
                    className={ `vochub-select-control virtual-sources-form__header-select ${ !type && 'invalid' }` }
                    isClearable={ true }
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={ document.body }
                    menuShouldScrollIntoView={ false }
                    menuPlacement={ 'auto' }
                    deleteRemoves={ false }
                    backspaceRemoves={ false }
                    options={ columnOptions }
                    placeholder={ t('placeholders.type') }
                    searchable={ true }
                    onChange={ this.onTypeHandler }
                />
            </div>
        );
    }
}
