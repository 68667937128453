import React from 'react';
import { useDispatch } from "react-redux";
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CopyToClipboard, showToast, TooltipWrapper } from '/components';

import './CopyTextButton.scss';

export const CopyTextButton = ({ textToCopyLabel = '', textToCopy = '', className = '' }) => {
    const dispatch = useDispatch();

    const onCopy = isCopied => {
        isCopied && dispatch(showToast(
            { text: `${textToCopyLabel} successfully copied`, type: 'success' },
        ));
    };

    return (
        <TooltipWrapper value={ `Copy ${textToCopyLabel.toLowerCase()}` } force>
            <CopyToClipboard { ...{ text: textToCopy, onCopy } }>
                <div className={ cx('copy-text-button', className) }>
                    <FontAwesomeIcon className='copy-text-button__icon' icon='copy'/>
                </div>
            </CopyToClipboard>
        </TooltipWrapper>
    );
};
