import { createSelector } from 'reselect';

const _getData = state => state.VocStore.uploadData.uploadSocialNetwork;
const _getHeaderRow = state => _getData(state).headerRow;
const _getHeaderRowType = state => _getData(state).headerRowType;

export const formDataSelector = createSelector(
    [ _getData ],
    ({ loading, error }) =>
        ({
            loading,
            error,
        }));

export const headerRowSelector = createSelector(
    [ _getHeaderRow ],
    value => convertHeaders(value),
);

export const headerRowTypeSelector = createSelector(
    [ _getHeaderRowType ],
    value => headersParserForDisabledButtons(value),
);

export const initialValuesSelector = t => createSelector(
    [ _getData ],
    () => ({
        language:  {
            value: '',
            label: t('severalLanguagesInFile'),
        },
        parser: {
            value: 'quintly',
            label: t('quintly'),
        },
        status: '0',
        choiceDelimiter: '|',
        startSyncFrom: new Date('01/01/2010'),
    }),
);

export const parserOptionsSelector = t => [{
    value: 'quintly',
    label: t('quintly'),
}];

export const headerTypes = [
    'ID',
    'TEXT',
    'LANG',
    'DATE',
    'NPS',
    'NUMERIC',
    'CHOICE',
];

const convertHeaders = columns => {
    return columns.reduce((acc, header, index) => {
        const column = {
            id: header,
            index,
            disabledHeaders: [
                {
                    id: 'ID',
                    disabled: false,
                },
                {
                    id: 'LANG',
                    disabled: false,
                },
            ],
        };

        const isKnownType = headerTypes.some(type => {
            const check = header.includes(`[${ type }]`);

            if (check) column.type = type;
            return check;
        });

        if (!isKnownType) column.type = 'META';

        acc.push(column);

        return acc;
    }, []);
};

const headersParserForDisabledButtons = value => {
    const isAnyIdChecked = value.find(item => item.type === 'ID');
    const isAnyLangChecked = value.find(item => item.type === 'LANG');

    value.map(item => {
        const idOverlap = item.index !== isAnyIdChecked?.index;
        const langOverlap = item.index !== isAnyLangChecked?.index;

        item.disabledHeaders[0].disabled = isAnyIdChecked ? idOverlap : false;
        item.disabledHeaders[1].disabled = isAnyLangChecked ? langOverlap : false;

        if (idOverlap && item.type === 'ID' || langOverlap && item.type === 'LANG') {
            item.type = 'META';
        }

        return item;
    });

    return value;
};
