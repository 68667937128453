import { CALL_API } from 'store/middleware/api';

const GET_TOPIC_SETS_REQUEST = 'GET_TOPIC_SETS_REQUEST';
const GET_TOPIC_SETS_SUCCESS = 'GET_TOPIC_SETS_SUCCESS';
const GET_TOPIC_SETS_ERROR = 'GET_TOPIC_SETS_ERROR';

const DELETE_TOPIC_SET_REQUEST = 'DELETE_TOPIC_SET_REQUEST';
const DELETE_TOPIC_SET_SUCCESS = 'DELETE_TOPIC_SET_SUCCESS';
const DELETE_TOPIC_SET_ERROR = 'DELETE_TOPIC_SET_ERROR';

const UPDATE_TOPIC_SETS_FILTERS = 'UPDATE_TOPIC_SETS_FILTERS';

const CLEANUP_TOPIC_SETS_TABLE = 'CLEANUP_TOPIC_SETS_TABLE';

const initialFilters = {
    limit: 10,
    page: 1,
    search: '',
    sort: '',
};

export const getInitialState = () => ({
    filters: initialFilters,
    data: [],
    loading: false,
    totalCount: 0,
});

export const reducer = (state = getInitialState(), action: any = {}) => {
    switch (action.type) {
        case UPDATE_TOPIC_SETS_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case GET_TOPIC_SETS_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.response.data,
                totalCount: action.response.count,
            };
        }

        case GET_TOPIC_SETS_REQUEST:
        case DELETE_TOPIC_SET_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_TOPIC_SETS_ERROR:
        case DELETE_TOPIC_SET_ERROR:
        case DELETE_TOPIC_SET_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case CLEANUP_TOPIC_SETS_TABLE: {
            return getInitialState();
        }

        default:
            return state;
    }
};

export function getTopicSets(filters) {
    const { sort_field, sort_order, ...restFilters } = filters;

    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set/`,
            method: 'GET',
            types: [ GET_TOPIC_SETS_REQUEST, GET_TOPIC_SETS_SUCCESS, GET_TOPIC_SETS_ERROR ],
            logger: true,
            contentType: 'application/json',
            body: {
                ...restFilters,
                ...sort_field
                    ? {
                        sort: JSON.stringify({ [sort_field]: sort_order }),
                    }
                    : {},
            },
        },
    };
}

export function updateTopicSetsFilters(filters) {
    return {
        type: UPDATE_TOPIC_SETS_FILTERS,
        payload: filters,
    };
}

export function deleteTopicSet(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set/${id}`,
            method: 'DELETE',
            types: [ DELETE_TOPIC_SET_REQUEST, DELETE_TOPIC_SET_SUCCESS, DELETE_TOPIC_SET_ERROR ],
            logger: true,
            loggerMessages: {
                success: `Topic set successfuly removed`,
                error: 'Something went wrong',
            },
        },
    };
}
