import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SwitchControl } from '/components';
import { ColorsSelector, SwitchersSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { IContentSettings, IGadgetData, setTempSettingsType } from '/visual/models';

import { CustomColorSet, ConfigureStates } from './components';
import styles from './style.module.scss';

export const AdvancedSettings = ({
    gadget,
    setTempSettings,
}: {
    gadget: IGadgetData,
    setTempSettings: setTempSettingsType,
}) => {
    const { t } = useTranslation();
    const { contentSettings, dataSettings } = gadget;

    const switchers = useSelector(SwitchersSelector(t));
    const customColors = useSelector(ColorsSelector(dataSettings));

    const [ configureStates, setConfigureStates ] = useState(dataSettings?.state || [ 'Completed' ]);
    const [ customColorsState, setCustomColorsState ] = useState(customColors);

    const onChangeContentSettings = (data: any) => {
        setTempSettings({
            contentSettings: { ...data },
        });
    };

    const onChangeConfigurableStates = (switcherState: boolean) => {
        const state = switcherState ? configureStates : null;

        setTempSettings({
            dataSettings: { state },
        });
    };

    const onChangeCustomColors = (switcherState: boolean) => {
        const theOne = switcherState
            ? (customColorsState.theOne || 0)
            : null;
        const formatData = {
            theOne,
            other: customColors.other,
        };

        setTempSettings({
            dataSettings: {
                customColorSet: JSON.stringify(formatData),
            },
        });
    };

    const onChangeDataSettings = (data: any) => {
        if(data.state) {
            setConfigureStates(data.state);
        }

        if(data.customColorSet) {
            setCustomColorsState(JSON.parse(data.customColorSet));
        }

        setTempSettings({
            dataSettings: { ...data },
        });
    };

    const onChangeRecThreshold = (value: number) => {
        onChangeContentSettings({
            recThreshold: (value < 0 || !value) ? 0 : value,
        });
    };

    const getSwitcher = (switcher: {title: string, type: string}) => {
        switch (switcher.type) {
            case 'state':
                return (
                    <>
                        <Label className={ styles.selectWrapper }>
                            <span>{ switcher.title }</span>
                            <SwitchControl
                                name={ `select${switcher.type}` }
                                value={ Boolean(dataSettings?.state) }
                                changeHandler={ onChangeConfigurableStates }
                            />
                        </Label>
                        {
                            Boolean(dataSettings?.state)
                            && <ConfigureStates
                                configureStates={ configureStates }
                                onChange={ onChangeDataSettings }
                            />
                        }
                    </>
                );

            case 'recThresholdEnabled':
                return (
                    <>
                        <Label className={ styles.selectWrapper }>
                            <span>{ switcher.title }</span>
                            <SwitchControl
                                name={ `select${switcher.type}` }
                                value={ Boolean(contentSettings?.recThresholdEnabled) }
                                changeHandler={ (state: boolean) =>
                                    onChangeContentSettings({ recThresholdEnabled: state })
                                }
                            />
                        </Label>
                        {
                            Boolean(contentSettings?.recThresholdEnabled)
                            && <div className={ styles.inputWrapper }>
                                <Label htmlFor={ `recThreshold` }>
                                    { t('recordThreshold') }
                                </Label>
                                <Input
                                    name={ `recThreshold` }
                                    onChange={ (event: any) => onChangeRecThreshold(event.target.value) }
                                    value={ contentSettings.recThreshold || 0 }
                                    type='number'
                                />
                            </div>
                        }
                    </>
                );

            case 'customColorSet':
                return (
                    <>
                        <Label className={ styles.selectWrapper }>
                            <span>{ switcher.title }</span>
                            <SwitchControl
                                name={ `select${switcher.type}` }
                                value={ Boolean(customColors.theOne?.toString()) }
                                changeHandler={ onChangeCustomColors }
                            />
                        </Label>
                        {
                            Boolean(customColors.theOne?.toString())
                            && <CustomColorSet
                                onChange={ onChangeDataSettings }
                                colorsData={ customColors }
                            />
                        }
                    </>
                );

            default:
                return (
                    <Label className={ styles.selectWrapper }>
                        <span>{ switcher.title }</span>
                        <SwitchControl
                            name={ `select${switcher.type}` }
                            value={ Boolean(contentSettings[switcher.type as keyof IContentSettings]) }
                            changeHandler={ (switcherState: boolean) =>
                                onChangeContentSettings({ [switcher.type]: switcherState })
                            }
                        />
                    </Label>
                );
        }
    };

    return (
        <>
            {
                switchers.map((switcher, inx) => (
                    <div key={ `${switcher.type}_${inx}` }>
                        { getSwitcher(switcher) }
                    </div>
                ))
            }
        </>
    );
};
