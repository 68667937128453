import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { VocModal, FormFieldItem } from '/components';

export class LayoutModalCopy extends Component {
    state = {
        name: '',
    };

    @autobind
    getModalBody() {
        return (
            <FormFieldItem
                type="text"
                isHorizontal={ true }
                value={ this.state.name }
                label="Name"
                isRequired={ true }
                placeholder="Enter name"
                handleChange={ this.valueChangedInput }
            />
        );
    }

    @autobind
    close() {
        this.props.close();
        this.setState({
            name: '',
        });
    }

    @autobind
    onSubmit() {
        const { name } = this.state;
        const { copyLayout, layout: { value } } = this.props;

        copyLayout({ name, value });
        this.close();
    }

    @autobind
    valueChangedInput(event) {
        let name = event.target.value;

        if(name.indexOf(' ') === 0) {
            name = name.trim();
        }

        this.setState({
            name,
        });
    }

    @autobind
    getFooter() {
        return (
            <div>
                <Button onClick={ this.close } color={ 'white' } outline>
                    Cancel
                </Button>
                <Button
                    disabled={ !this.state.name }
                    onClick={ this.onSubmit }
                    color="primary"
                >
                    <FontAwesomeIcon icon={ 'check' }/>
                    <span> Save</span>
                </Button>
            </div>
        );
    }

    render() {
        return (
            <VocModal
                toggle={ this.close }
                className='modal-scrollable'
                header={ 'Copy Layout' }
                isOpen={ this.props.show }
                size={ 'md' }
                footer={ this.getFooter() }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}
