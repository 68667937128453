import { createSelector } from 'reselect';
import { HelperService, UsersRoleService } from '/services';

export const _getShares = state => state.UserRights.shares;
export const _getAllUserInfo = state => state.UserRights;

export const usersAndGroupsSelector = createSelector(
    [ _getAllUserInfo ],
    ({ allUser, allGroups, currentUser, submittingRights }) => {
        const currentId = currentUser.currentUserData.ssoId;

        const userList = allUser.userList.map(user => ({
            label: user.ssoId === currentId ? user.fullName + ' (You)' : user.fullName,
            value: user.ssoId,
            ownerType: 'user',
            icon: 'user',
        }));

        const groupList = allGroups.groupList.map(group => ({
            label: group.name,
            value: group.id,
            ownerType: 'group',
            icon: 'users',
        }));

        return {
            loading: allUser.loading || allGroups.loading,
            submitting: submittingRights,
            options: [
                ...groupList,
                ...userList,
            ],
        };
    });

export const sharesSelector = createSelector(
    [ _getShares, _getAllUserInfo ],
    ({ id, loaded, user, users }, { allUser, allGroups, currentUser }) => {
        const currentUserId = currentUser.currentUserData.ssoId;
        const allUsers = [
            ...loaded && user.ssoId
                ? [{ ...user, ownerId: user.ssoId || null, permission: "owner" }]
                : [],
            ...HelperService.sortObject(users, 'ownerType', [ 'group', 'user' ]),
        ];

        const reComposedUsers = UsersRoleService.getUsersByRole(
            allUsers, currentUserId, { allUser, allGroups },
        );

        return {
            id,
            loaded,
            userRights: reComposedUsers.allUsers,
            usersByRole: reComposedUsers.usersByRole,
        };
    });
