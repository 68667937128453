import { sockets } from '/services';
import { aiSockedWithError, getGraphs, getTopics } from './topicSet.modules';
import { _getSocketData } from './topicSet.selectors';

const requestMethods = {
    GET_TOPICS_REQUEST: getTopics,
    GET_GRAPHS_REQUEST: getGraphs,
};

function socketCallback(updatedTopics: { id: string, error: string }, store: object) {
    const state = store.getState();
    const { id, actionName, requestData } = _getSocketData(state);

    if(id === updatedTopics.id) {
        store.dispatch(
            updatedTopics.error
                ? aiSockedWithError(actionName, id, updatedTopics.error)
                : requestMethods[actionName](requestData),
        );
    }
}

export function sockedTopicSetUpdateListener() {
    return sockets.on('voc_ai_cache_rebuilt', socketCallback);
}
