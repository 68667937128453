import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroupItem } from 'reactstrap';
import { TooltipWrapper } from '/components';

import './CaseAlertConditionItem.scss';

export const CaseAlertConditionItem = ({
    openCaseAlertConditionForm,
    condition,
    deleteCaseAlertCondition,
    caseAlertId,
    permission: { isPermitEdit },
}) => {
    const { case_alert_type, external_identifier, alert_condition, value } = condition;
    const countedValue = case_alert_type === 'classifier'
        ? `${ value.category_title }(${ value.classifier_title })`
        : value;

    return (
        <ListGroupItem>
            <div className={ 'case-alert-condition_container' }>
                <FontAwesomeIcon
                    icon={ 'bullseye' }
                    className={ 'case-alert-condition__list-item' }
                />
                <div className={ 'case-alert-condition_string' }>
                    <span>{ case_alert_type } </span>
                    <span>{ external_identifier } </span>
                    <span>{ alert_condition } </span>
                    <span>{ countedValue }</span>
                </div>
                <div className={ 'case_alerts-condition__list-action' }>
                    <TooltipWrapper value={ 'Edit condition' } force>
                        <span className={ 'case-alert-condition__list-wrap-icon' }>
                            <FontAwesomeIcon
                                icon={ 'cog' }
                                className={ 'case-alert-condition__list-item' }
                                onClick={ () => openCaseAlertConditionForm({ condition }) }
                            />
                        </span>
                    </TooltipWrapper>
                    <TooltipWrapper value={ 'Delete condition' } force>
                        <span
                            className={ cx(
                                'case-alert-condition__list-wrap-icon',
                                { 'disabled': !isPermitEdit },
                            ) }
                        >
                            <FontAwesomeIcon
                                icon={ 'trash' }
                                className={ 'case-alert-condition__list-item' }
                                onClick={ () => deleteCaseAlertCondition(caseAlertId, condition) }
                            />
                        </span>
                    </TooltipWrapper>
                </div>
            </div>
        </ListGroupItem>
    );
};
