import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { autobind } from 'core-decorators';
import {
    getSurveyList, getResponseRates,
    changeFilters, reorderSurveys, addSurvey,
    copySurvey, sendInvite,
} from './modules/actions';
import {
    surveysSelector,
    filtersSelector,
    activeUnitSelector,
    showPreloaderSelector,
    surveyListLoadingSelector,
    surveysListPaginationSelector,
    isRatesFullyLoadedSelector,
} from './modules/selectors';
import { unitsListSelector, commonLanguageSelector } from '/feedback/modules/VocFeedback.selectors';
import { getSurveysGroupLangSet } from '/scenes/VocFeedback/modules/VocFeedback.modules';
import { PageRequests, HelperService } from '/services';

import { ScrollUp, showToast } from '/components';
import { SurveysListTableView, CopySurveyModal } from './components';
import { NewSurveyModal, SendInvitation } from '../../components';
import './Surveys.scss';

const mapDispatchToProps = dispatch => ({
    getSurveyList: params => dispatch(getSurveyList(params)),
    getResponseRates: () => dispatch(getResponseRates()),
    changeFilters: filters => dispatch(changeFilters(filters)),
    reorderSurveys: params => dispatch(reorderSurveys(params)),
    addSurvey: data => dispatch(addSurvey(data)),
    copySurvey: data => dispatch(copySurvey(data)),
    sendInvite: data => dispatch(sendInvite(data)),
    showToast: text => dispatch(showToast(text)),
    getSurveysGroupLangSet: params => dispatch(getSurveysGroupLangSet(params)),
});

const mapStateToProps = state => ({
    surveysList: surveysSelector(state),
    unitsList: unitsListSelector(state),
    filters: filtersSelector(state),
    languages: commonLanguageSelector(state),
    activeUnit: activeUnitSelector(state),
    showPreloader: showPreloaderSelector(state),
    isSurveyListLoading: surveyListLoadingSelector(state),
    pagination: surveysListPaginationSelector(state),
    isRatesFullyLoaded: isRatesFullyLoadedSelector(state),
});

@connect(mapStateToProps, mapDispatchToProps)
export class Surveys extends Component {
    state = {
        responseRatesReady: false,
        loading: true,
        grayArea: false,
        openModalCopy: false,
        openModalInvite: false,
        openModalNew: false,
        copySurveyId: null,
    };
    MAX_LIMIT = 9999;
    pageRequests = new PageRequests();

    componentDidMount() {
        if(this.props.unitsList.length) {
            this.getSurveyList();
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.unitsList !== this.props.unitsList && this.props.unitsList.length) {
            this.getSurveyList();
        }
    }

    @autobind
    getSurveyList() {
        const filters = HelperService.getFromSessionStorage('filters');
        let model = {};

        if(filters?.unit !== undefined) {
            const unit = this.props.unitsList
                .find(unit => unit.id === filters.unit)
                || this.props.unitsList[0];

            model = {
                unit: unit.id,
                unitLabel: unit.name,
            };
        } else {
            model = {
                unit: this.props.unitsList[0].id,
                unitLabel: this.props.unitsList[0].name,
            };
        }

        this.applyFilter({
            ...model,
            page: 1,
            loading: true,
        });
    }

    componentWillUnmount() {
        this.pageRequests.cleanup();
    }

    @autobind
    openModal(value, key, type) {
        const hash = {
            [type]: true,
        };

        if (value && key) {
            hash[key] = value;
        }

        this.setState(hash);
    }

    @autobind
    closeModal() {
        this.setState({
            openModalCopy: false,
            openModalInvite: false,
            openModalNew: false,
        });
    }

    @autobind
    getResponseRates() {
        if (!this.props.isRatesFullyLoaded) {
            this.props.getResponseRates();
        }
    }

    @autobind
    applyFilter(_params) {
        let temp = {
            ...this.props.filters,
        };

        if (_params) {
            temp = {
                ...this.props.filters,
                ..._params,
            };
        }
        const params = this.setParams(temp);

        if (temp.limit !== 'CJM') {
            this.pageRequests.cleanup();
            const request = this.props.getSurveyList(params);

            this.pageRequests.addRequest(request);
        }
    }

    @autobind
    copySurvey(params) {
        const model = {
            ...params,
            id: this.state.copySurveyId,
        };

        return this.props.copySurvey(model);
    }

    setParams(input = {}) {
        const filters = {
            sortBy: HelperService.fromString(input.sortBy),
            search: input.search.trim(),
            limit: HelperService.fromString(input.limit),
            page: HelperService.fromString(input.page),
            hideDeletedSurveys: HelperService.fromString(input.hideDeletedSurveys),
            unit: HelperService.fromString(input.unit),
        };

        this.props.changeFilters(filters);

        return this.getRequestParams(filters);
    }

    getRequestParams(filters) {
        const params = {
            sort: filters.sortBy,
            search: filters.search,
            limit: filters.limit,
            page: filters.page,
            hide_deleted_surveys: filters.hideDeletedSurveys,
            unit: filters.unit,
        };

        return HelperService.removeNull(params);
    }

    @autobind
    reorderSurvey(params) {
        const reorderParams = this.reorderPrepareParams(params);

        if (reorderParams.current !== reorderParams.target) {
            this.props.reorderSurveys(reorderParams)
                .then(() => this.applyFilter());
        }
    }

    reorderPrepareParams(params) {
        const { surveysList } = this.props;
        const currentIndex = params.current;
        let targetIndex = params.target;
        const indexOffset = (currentIndex > targetIndex) ? -1 : 0;

        targetIndex = targetIndex + indexOffset;
        const fetchSurveyId = (index, list) => {
            return (list && list[index] && list[index].id)
                ? list[index].id
                : null;
        };
        const currentId = fetchSurveyId(currentIndex, surveysList);
        const targetId = fetchSurveyId(targetIndex, surveysList);

        return {
            current: currentId,
            target: targetId,
            currentIdx: params.current,
            targetIdx: params.target,
            filters: this.getRequestParams(this.props.filters),
        };
    }

    @autobind
    getRights() {
        const { unitsList, activeUnit: { id } } = this.props;
        const unit = unitsList.find(unit => unit.id === id);

        return {
            activeUnit: id,
            unitsList,
            rights: unit?.rights?.surveyList,
        };
    }


    @autobind
    getFilteredUnitByPermit(){
        const { unitsList } = this.props;

        return unitsList.filter(
            unit => unit.id !== null && unit.rights.surveyList.isPermitAddSurvey,
        );
    }

    render() {
        return (
            <DocumentTitle title={ 'Feedback' }>
                <div className='surveys-list-modern page__wrapper'>
                    <SurveysListTableView
                        openModal={ this.openModal }
                        pagination={ this.props.pagination }
                        showPreloader={ this.props.showPreloader }
                        surveysList={ this.props.surveysList }
                        getResponseRates={ this.getResponseRates }
                        applyFilter={ this.applyFilter }
                        filters={ this.props.filters }
                        showToast={ this.props.showToast }
                        loading={ this.props.isSurveyListLoading }
                        unitsList={ this.props.unitsList }
                        unitsByPermit={ this.getFilteredUnitByPermit() }
                        activeUnit={ this.props.activeUnit }
                        reorderSurvey={ this.reorderSurvey }
                        rightsData={ this.getRights() }
                    />
                    <NewSurveyModal
                        close={ this.closeModal }
                        loading={ this.props.loading }
                        show={ this.state.openModalNew }
                        units={ this.getFilteredUnitByPermit() }
                        actionHandler={ this.props.addSurvey }
                    />
                    <CopySurveyModal
                        close={ this.closeModal }
                        show={ this.state.openModalCopy }
                        units={ this.getFilteredUnitByPermit() }
                        actionHandler={ this.copySurvey }
                    />
                    <ScrollUp/>
                    {
                        this.state.surveyGroup && this.state.surveyGroup.surveys.length > 0
                        && <SendInvitation
                            show={ this.state.openModalInvite }
                            surveyGroup={ this.state.surveyGroup }
                            languages={ this.props.languages }
                            actionHandler={ this.props.sendInvite }
                            getSurveysGroupLangSet={ this.props.getSurveysGroupLangSet }
                            close={ this.closeModal }
                        />
                    }
                </div>
            </DocumentTitle>
        );
    }
}
