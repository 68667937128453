import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import { VocModal, showToast } from '/components';
import { getSourceById } from '/modules';
import { PageRequests, MetaQueryService, HelperService } from '/services';
import { hideApplianceModal, createSegmentator, updateSegmentator } from '/modules/segmentatorModules/segmentator.modules.js';

import { ApplianceService } from './services/ApplianceService';
import { ApplianceModalContent } from './components';

const uuidv4 = require('uuid/v4');

import './ApplianceModal.scss';

const mapDispatchToProps = {
    hideApplianceModal,
    getSourceById,
    createSegmentator,
    updateSegmentator,
    showToast,
};

const getInitialState = () => ({
    segments: [],
    name: '',
    attributeValueKey: 'index',
    dataType: { label: 'META', value: 'META' },
});

@withTranslation()
@connect(null, mapDispatchToProps)
export class ApplianceModal extends Component {
    state = getInitialState();
    pageReuests = new PageRequests();
    dataTypes = [
        { label: 'META', value: 'META' },
        { label: 'NPS', value: 'NPS' },
        { label: 'NUMERIC', value: 'NUMERIC' },
        { label: 'NPS_SEGMENT', value: 'NPS_SEGMENT' },
    ];

    @autobind
    onAddSegmentCLick() {
        this.setState(prevState => {
            return {
                ...prevState,
                segments: [
                    ...prevState.segments,
                    this.getNewSegment(),
                ],
            };
        });
    }

    getNewSegment() {
        return {
            segmentName: null,
            hash: uuidv4(),
            query: { hash: 'root', items: [] },
        };
    }

    @autobind
    initApplianceModal() {
        const {
            VocStoreRoot: {
                mode,
                sourceId,
                source,
                segments,
                segmentType,
                virtualFieldName,
                placeholdersMapping,
            },
            getSourceById,
        } = this.props;
        const { attributeValueKey } = this.state;
        const request = getSourceById({ id: sourceId, body: { with_virtual_column: 0 } });

        this.pageReuests.addRequest(request);

        if (mode === 'edit') {
            request.promise.then(({ attributes }) => {
                segments.forEach(segment => {
                    segment.query = MetaQueryService.serverToClientFormatter({
                        query: segment.query,
                        source,
                        placeholdersMapping,
                        attributeValueKey,
                    });

                    if (segment.placeholder) {
                        const attribute = attributes.find(({ id }) => id === placeholdersMapping[segment.placeholder]);

                        segment.segmentName = attribute ? attribute.name : null;

                        delete segment.placeholder;
                    }
                });

                this.setState({
                    segments,
                    name: virtualFieldName,
                    dataType: HelperService.getSelectedValue(this.dataTypes, segmentType),
                });
            });
        }
    }

    componentWillUnmount() {
        this.pageReuests.cleanup();
    }

    createSegmentatorRequest() {
        const {
            showToast,
            VocStoreRoot: { source },
            t,
        } = this.props;
        const { segments, name, attributeValueKey, dataType = {} } = this.state;

        if (!ApplianceService.validateSegmentsBeforeSend({
            showToast,
            segments,
            dataType,
            attributes: source.attributes,
            t,
        })) {
            return false;
        }

        return ApplianceService.convertRecodeDataToServer({
            segments,
            name,
            attributeValueKey,
            source,
            dataType: dataType.value,
        });
    }

    createApplianceRequest(placeholders) {
        const { VocStoreRoot: { sourceId, source: { attributes } } } = this.props;
        const { name } = this.state;

        const placeholders_mapping = ApplianceService.getSourcePlaceholdersMapping({ placeholders, attributes });

        return {
            data_id: sourceId,
            virtual_field_name: name,
            placeholders_mapping,
        };
    }

    @autobind
    async onApplianceActionClick() {
        const {
            hideApplianceModal,
            getSegmentatorsList,
            createSegmentator,
            updateSegmentator,
            filters,
            VocStoreRoot: { mode, id },
        } = this.props;

        const createSegmentatorResult = this.createSegmentatorRequest();

        if (!createSegmentatorResult) {
            return false;
        }

        const createApplianceRequest = this.createApplianceRequest(createSegmentatorResult.placeholders);

        const body = {
            segmentator: {
                ...createSegmentatorResult,
            },
            appliance: {
                ...createApplianceRequest,
            },
        };

        const request = mode === 'create' ? createSegmentator(body) : updateSegmentator({ id, body });

        this.pageReuests.addRequest(request);
        await request.promise;

        this.setState(getInitialState());
        mode === 'edit' && getSegmentatorsList(filters);
        hideApplianceModal();
    }

    @autobind
    deleteSegment(segment) {
        const { segments } = this.state;
        const indexToDelete = this.findSegmentIndexByHash(segment.hash);

        segments.splice(indexToDelete, 1);
        this.setState({ segments });
    }

    @autobind
    onChangeName(event) {
        this.setState({
            name: event.target.value,
        });
    }

    isValidAppliance() {
        const { source } = this.props.VocStoreRoot;
        const { segments, dataType } = this.state;

        if (!source.attributes) {
            return false;
        }

        return ApplianceService.isValidAppliance({ source, segments, dataType: dataType.value });
    }

    isActionButtonDisabled() {
        const { name, segments } = this.state;
        const { applianceModalLoading, upsentSource, isPermitView } = this.props.VocStoreRoot;

        return (
            !name.trim()
            || !segments.length
            || segments.some(({ segmentName }) => segmentName && !segmentName.trim() || !segmentName)
            || applianceModalLoading
            || upsentSource
            || !this.isValidAppliance()
            || isPermitView
        );
    }

    isCancelDisabled() {
        const { VocStoreRoot: { applianceModalLoading } } = this.props;

        return applianceModalLoading;
    }

    findSegmentIndexByHash(hash) {
        const { segments } = this.state;

        return segments.findIndex(x => x.hash === hash);
    }

    @autobind
    onChangeSegment({ hash, ...rest }) {
        const { segments } = this.state;
        const segmentIndexToChange = this.findSegmentIndexByHash(hash);

        Object
            .keys(rest)
            .forEach(key => {
                segments[segmentIndexToChange][key] = rest[key];
            });

        this.setState({ segments });
    }

    @autobind
    onDataTypeChange(obj = {}) {
        if (this.state.segments.length) {
            const segments = this.state.segments.map(el => ({ ...el, segmentName: null }));

            this.setState({
                segments,
                dataType: obj,
            });
        } else {
            this.setState({
                dataType: obj,
            });
        }
    }

    getModalFooter() {
        const { VocStoreRoot: { mode }, hideApplianceModal, t } = this.props;
        const disabledUpdateBtn = this.isActionButtonDisabled();

        return (
            <Fragment>
                <Button
                    disabled={ this.isCancelDisabled() }
                    color='white'
                    outline
                    onClick={ hideApplianceModal }
                >
                    <FontAwesomeIcon icon='times' />
                    &nbsp;
                    { t('cancel') }
                </Button>
                <Button
                    disabled={ disabledUpdateBtn }
                    color='primary'
                    onClick={ e => !disabledUpdateBtn && this.onApplianceActionClick(e) }
                >
                    <FontAwesomeIcon icon='check' />
                    &nbsp;
                    { t(mode === 'create' ? 'create' : 'update') }
                </Button>
            </Fragment>
        );
    }

    @autobind
    onClosed() {
        this.setState(getInitialState());
    }

    render() {
        const {
            hideApplianceModal,
            showToast,
            VocStoreRoot: {
                showApplianceModal,
                upsentSource,
                source,
                applianceModalLoading,
                mode,
            },
            t,
        } = this.props;
        const { name, attributeValueKey, segments, dataType } = this.state;

        return (
            <VocModal
                backdrop={ 'static' }
                onClosed={ this.onClosed }
                onOpened={ this.initApplianceModal }
                isOpen={ showApplianceModal }
                modalClassName={ 'source-segment-appliance-modal' }
                toggle={ hideApplianceModal }
                header={ t('recodeVariables') }
                footer={ this.getModalFooter() }
            >
                <ApplianceModalContent
                    validAppliance={ this.isValidAppliance() }
                    showToast={ showToast }
                    hideApplianceModal={ hideApplianceModal }
                    source={ source }
                    deleteSegment={ this.deleteSegment }
                    onChangeSegment={ this.onChangeSegment }
                    onChangeName={ this.onChangeName }
                    onDataTypeChange={ this.onDataTypeChange }
                    onAddSegmentCLick={ this.onAddSegmentCLick }
                    dataType={ dataType }
                    attributeValueKey={ attributeValueKey }
                    applianceModalLoading={ applianceModalLoading }
                    upsentSource={ upsentSource }
                    segments={ segments }
                    name={ name }
                    mode={ mode }
                    dataTypes={ this.dataTypes }
                />

            </VocModal>
        );
    }
}

ApplianceModal.propTypes = {
    VocStoreRoot: PropTypes.object,
    filters: PropTypes.object,
};

ApplianceModal.defaultProps = {
    getSegmentatorsList: () => {},
    filters: {},
};
