import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RowActions, RowChannels } from '/feedback/components';
import { TooltipWrapper } from '/components';

export class SurveyItem extends Component {
    render() {
        const tooltipCondition = {
            width: 380,
        };

        return (
            <tr>
                <td className='noselect'>
                    <span>{ this.props.survey.id }</span>
                </td>
                <td>
                    <TooltipWrapper value={ this.props.survey.name } condition={ tooltipCondition }>
                        <Link to={ `/feedback/survey/${this.props.survey.id}/questions` } className='wrap'>
                            <FontAwesomeIcon icon='comments'/>
                            { this.props.survey.name }
                        </Link>
                    </TooltipWrapper>
                </td>
                <td>
                    <RowChannels surveys={ this.props.survey.surveys }/>
                </td>
                <td/>
                <td>
                    <RowActions
                        id={ this.props.survey.id }
                        surveyGroup={ this.props.survey }
                        openModal={ this.props.openModal }
                        showToast={ this.props.showToast }
                        touchpoint={ true }
                        rights={ this.props.survey.rights }
                    />
                </td>
            </tr>
        );
    }
}
