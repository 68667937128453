import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import uuid from 'uuid/v1';
import { useTranslation } from 'react-i18next';

import { Preloader } from '/components';

import { ColumnButtonGroup } from '../ColumnButtonGroup';

import './VerificationSection.scss';

export const VerificationSection = ({
    loading,
    error,
    headers,
    headerTypes,
    setHeaders,
}) => {
    const { t } = useTranslation();

    const columnSelectHandler = (columnId, type, unsetFlag) => {
        const data = headers.map(item => {
            if (item.id === columnId) item.type = unsetFlag ? 'META' : type;

            return item;
        });

        setHeaders(data);
    };

    const getTitleText = () =>
        <strong className='panel__header'>
            { t('verificationSection') }
        </strong>;

    const getColumnButtonGroup = column =>
        <ColumnButtonGroup
            key={ uuid() }
            columnTypes={ headerTypes }
            onColumnTypeSelect={ columnSelectHandler }
            column={ column }
        />;

    if (loading && !error ) return <Preloader/>;

    if (!headers.length) return null;

    return (
        <Card className='metadata-columns-block'>
            <CardHeader>
                {getTitleText()}
            </CardHeader>
            <CardBody>
                { headers.map(item => getColumnButtonGroup(item)) }
            </CardBody>
        </Card>
    );
};
