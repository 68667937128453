import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';
import { getProjectsExpanded } from '../../CustomerJourney/modules/actions';

import { showToast } from '/components';
import { getSurveysGroup } from '/scenes/VocFeedback/modules/VocFeedback.modules';

export const MAX_LIMIT = 9999;

export const GET_SURVEYS_LIST_REQUEST = 'GET_SURVEYS_LIST_REQUEST';
export const GET_SURVEYS_LIST_SUCCESS = 'GET_SURVEYS_LIST_SUCCESS';
export const GET_SURVEYS_LIST_ERROR = 'GET_SURVEYS_LIST_ERROR';

export const REORDER_SURVEY_GROUP_REQUEST = 'REORDER_SURVEY_GROUP_REQUEST';
export const REORDER_SURVEY_GROUP_SUCCESS = 'REORDER_SURVEY_GROUP_SUCCESS';
export const REORDER_SURVEY_GROUP_ERROR = 'REORDER_SURVEY_GROUP_ERROR';

export const GET_QUESTIONNAIRE_REQUEST = 'GET_QUESTIONNAIRE_REQUEST';
export const GET_QUESTIONNAIRE_SUCCESS = 'GET_QUESTIONNAIRE_SUCCESS';
export const GET_QUESTIONNAIRE_ERROR = 'GET_QUESTIONNAIRE_ERROR';

export const GET_RESPONSE_RATES_REQUEST = 'GET_RESPONSE_RATES_REQUEST';
export const GET_RESPONSE_RATES_SUCCESS = 'GET_RESPONSE_RATES_SUCCESS';

export const GET_RESPONSE_RATES_ERROR = 'GET_RESPONSE_RATES_ERROR';

export const STORE_RESPONSE_RATES = 'STORE_RESPONSE_RATES';
export const ADD_RESPONSE_RATE = 'ADD_RESPONSE_RATE';
export const FILTERS_CHANGED = 'FILTERS_CHANGED';
export const ADD_SURVEY_REQUEST = 'ADD_SURVEY_REQUEST';
export const ADD_SURVEY_SUCCESS = 'ADD_SURVEY_SUCCESS';
export const ADD_SURVEY_ERROR = 'ADD_SURVEY_ERROR';
export const STORE_NEW_SURVEY = 'STORE_NEW_SURVEY';
export const COPY_SURVEY_REQUEST = 'COPY_SURVEY_REQUEST';
export const COPY_SURVEY_SUCCESS = 'COPY_SURVEY_SUCCESS';
export const COPY_SURVEY_ERROR = 'COPY_SURVEY_ERROR';

export const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST';
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
export const SEND_INVITE_ERROR = 'SEND_INVITE_ERROR';

export function storeResponseRatesAction(data, isFullyLoaded) {
    return {
        type: STORE_RESPONSE_RATES,
        data,
        isFullyLoaded,
    };
}

export function changeFilters(filters) {
    return {
        type: FILTERS_CHANGED,
        data: filters,
    };
}

export function addResponseRate(data) {
    return {
        type: ADD_RESPONSE_RATE,
        data,
    };
}

export function storeNewSurvey(data) {
    return {
        type: STORE_NEW_SURVEY,
        data,
    };
}

//todo remove this
// will be fixed in next version
function _getFiltersForResponseRates(state) {
    const { unit,
        limit,
        page,
        search: name,
        sortBy: sort,
        hideDeletedSurveys: hide_deleted_surveys,
    } = state.VocFeedback.surveys.filters;

    return {
        unit,
        limit,
        name,
        page,
        sort,
        hide_deleted_surveys,
    };
}

export function getResponseRates() {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(getResponseRatesCalcData([], _getFiltersForResponseRates(state)))
            .then(data => {
                dispatch(storeResponseRatesAction(data));

                return dispatch(getResponseRatesCalcData());
            })
            .then(data => {
                dispatch(storeResponseRatesAction(data, true));
            });
    };
}

export function reorderSurveys(params) {
    return dispatch => {
        if (params.filters.page !== 1 && params.targetIdx === 0) {
            const prevItemParams = {
                ...params.filters,
                page: params.filters.page - 1,
            };

            return dispatch(getSurveysGroup(prevItemParams)).promise.then(list => {
                const getLastIdFromPage = list.results[list.results.length - 1].id;

                return dispatch(reorderSurvey(params.current, getLastIdFromPage)).promise;
            });
        }

        return dispatch(reorderSurvey(params.current, params.target)).promise;
    };
}

export function addSurvey(survey) {
    return (dispatch, getState) => {
        const state = getState();

        return dispatch(addSurveyRequest(survey)).promise
            .then(data => {
                dispatch(showToast({ text: 'Survey added successfully!', type: 'success' }));

                if (state.VocFeedback.surveys.filters.limit === 'CJM') {
                    dispatch(getProjectsExpanded());
                }

                storeNewSurveyAction(state.VocFeedback.surveys, data, dispatch);

                return dispatch(getResponseRatesCalcData([ data.id ]));
            }, error => {
                HelperService.mapResponseServerError(error, showToast, dispatch, false);
                return Promise.reject();
            })
            .then(response => {
                dispatch(addResponseRate(response));
            });
    };
}

export function copySurvey(survey) {
    return (dispatch, getState) => {
        const state = getState();

        return dispatch(copySurveyRequest(survey.id, {
            channels: survey.channels,
            name: survey.name,
            unit: survey.unit,
        })).promise.then(data => {
            dispatch(showToast({ text: 'Survey copied successfully!', type: 'success' }));

            storeNewSurveyAction(state.VocFeedback.surveys, data, dispatch);

            return dispatch(getResponseRatesCalcData([ data.id ]));
        })
            .then(response => {
                dispatch(addResponseRate(response));
            })
            .catch(error => {
                HelperService.mapResponseServerError(error, showToast, dispatch);
                return Promise.reject();
            });
    };
}

export function sendInvite(data) {
    return dispatch => {
        return dispatch(getQuestionnaire(data)).promise
            .then(questionnaire => {
                dispatch(sendInviteRequest(questionnaire.hash)).promise.then(() => {
                    dispatch(showToast({ text: 'Invitation was successfully sent!', type: 'success' }));
                });
            }, error => {
                HelperService.mapResponseServerError(error, showToast, dispatch);
                return Promise.reject();
            });
    };
}

export function getResponseRatesCalcData(ids, filters) {
    return dispatch => {
        const params = { limit: MAX_LIMIT };

        if (Array.isArray(ids) && ids.length > 0) {
            params['id__in'] = ids.toString();
        }

        if (filters) {
            for (const key in filters) {
                // eslint-disable-next-line no-prototype-builtins
                if (filters.hasOwnProperty(key)) {
                    params[key] = filters[key];
                }
            }
        }

        return dispatch(getResponseRatesRequest(params)).promise;
    };
}

export function isSurveyInclude(filters, survey) {
    /**
     * TODO: Remove object checking after BE fix
     */
    const surveyUnit = HelperService.isObjectValue(survey.unit) ? survey.unit.id : survey.unit;

    const pageFilter = filters.page === 1;
    const unitFilter = filters.unit === surveyUnit || !filters.unit;
    const termFilter = filters.search ? survey.name.toLowerCase().includes(filters.search.toLowerCase()) : true;

    return pageFilter && unitFilter && termFilter;
}

function storeNewSurveyAction(state, survey, dispatch) {
    if (isSurveyInclude(state.filters, survey)) {
        let surveyList = [ ...state.surveysList.results ];

        if (surveyList.length === state.filters.limit) {
            surveyList = surveyList.slice(0, surveyList.length - 1);
        }

        const result = [
            survey,
            ...surveyList,
        ];

        dispatch(storeNewSurvey(result));
    }
}


/**
 * Reimplement new Actions
 */

export function getSurveyList(params) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/expand-full/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEYS_LIST_REQUEST, GET_SURVEYS_LIST_SUCCESS, GET_SURVEYS_LIST_ERROR ],
            body: params,
            apiType: FEEDBACK,
        },
    };
}

export function reorderSurvey(currentSurveyId, destinationId) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${currentSurveyId}/move-after/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ REORDER_SURVEY_GROUP_REQUEST, REORDER_SURVEY_GROUP_SUCCESS, REORDER_SURVEY_GROUP_ERROR ],
            body: { destination_id: destinationId },
            apiType: FEEDBACK,
        },
    };
}

export function addSurveyRequest(surveyData) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ ADD_SURVEY_REQUEST, ADD_SURVEY_SUCCESS, ADD_SURVEY_ERROR ],
            body: surveyData,
            apiType: FEEDBACK,
        },
    };
}

export function copySurveyRequest(surveyId, data) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${ surveyId }/copy/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ COPY_SURVEY_REQUEST, COPY_SURVEY_SUCCESS, COPY_SURVEY_ERROR ],
            body: data,
            apiType: FEEDBACK,
        },
    };
}

export function getQuestionnaire(data) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_QUESTIONNAIRE_REQUEST, GET_QUESTIONNAIRE_SUCCESS, GET_QUESTIONNAIRE_ERROR ],
            body: data,
            apiType: FEEDBACK,
        },
    };
}

export function sendInviteRequest(hash) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire/${ hash }/invite/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ SEND_INVITE_REQUEST, SEND_INVITE_SUCCESS, SEND_INVITE_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getResponseRatesRequest(params) {
    return {
        [CALL_API]: {
            endpoint: `/statistics/survey-group/response-rate/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_RESPONSE_RATES_REQUEST, GET_RESPONSE_RATES_SUCCESS, GET_RESPONSE_RATES_ERROR ],
            apiType: FEEDBACK,
            body: params,
        },
    };
}
