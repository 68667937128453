import { createDefaultToast } from './Toast.factory';
import { HelperService } from '/services';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

// export const SHOW_ALERT = 'SHOW_ALERT';
// export const LOG_ALERT = 'LOG_ALERT';
// export const CLEAR_ALERT = 'CLEAR_ALERT';

const initialState = {
    logger: [],
    list: [],
    active: {},
};

export function ToastsReducer(state = initialState, action) {
    const { data, type } = action;

    switch (type) {
        case ADD_TOAST:
            return {
                ...state,
                list: HelperService.uniqeArray([ ...state.list, data ], 'text'),
            };

        case REMOVE_TOAST:
            return {
                ...state,
                list: state.list.filter(toast => toast.id !== data),
                logger: [ ...state.logger, data ],
            };

        default:
            return state;
    }
}

export function showToast(options) {
    return {
        type: ADD_TOAST,
        data: createDefaultToast(options),
    };
}

export function removeToast(id) {
    return {
        type: REMOVE_TOAST,
        data: id,
    };
}
