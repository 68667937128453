import moment from 'moment';

export class QueryExecuteService {
    static convertFiltersToServer(filters) {
        return filters.reduce((acc, { id, initialValues }) => {
            return initialValues ? {
                ...acc,
                [id]: initialValues.filters
                    ? {
                        ...initialValues,
                        filters: initialValues.filters.map(value => {
                            return value.values
                                ? QueryExecuteService.convertValueToNull(value)
                                : value;
                        }),
                    }
                    : initialValues,
            } : acc;
        }, {});
    }

    static convertValueToNull(value) {
        return {
            ...value,
            values: value.values.map(val => val === 'no value' ? null : val),
        };
    }

    static convertNullToValue(value) {
        return {
            ...value,
            values: value.values.map(val => val === null ? 'no value' : val),
        };
    }

    static removeFilterCondition(filters, key, index, name) {
        const changedFilter = {
            ...filters[name],
            [key]: [
                ...filters[name][key].filter((el, inx) => inx !== index),
            ],
        };

        const newFilters = {
            ...filters,
            [name]: changedFilter,
        };

        !changedFilter[key].length && delete newFilters[name];
        return newFilters;
    }

    static convertObjToArray(obj) {
        return obj.reduce((acc, option) => [ ...acc, option.value === 'no value' ? null : option.value ], []);
    }

    static convertDate(date) {
        const dateFormat = 'DD/MM/YYYY';
        const newData = {};

        const from = date.from instanceof Date ? moment(date.from).format(dateFormat) : date.from;
        const to = date.to instanceof Date ? moment(date.to).format(dateFormat) : date.to;

        from && (newData['from'] = from);
        to && (newData['to'] = to);

        return newData;
    }

    static queryItemsValidation({ items }) {
        const valid = [];

        items.forEach(item => {
            valid.push(Boolean(item.id && QueryExecuteService.checkOperatorValue(item.operator, item.value)));

            if (item.items && item.items.length) {
                valid.push(QueryExecuteService.queryItemsValidation(item));
            }
        });

        return valid.every(item => item);
    }

    static checkIsQueryItemAbsentInSource(queryForClient, attributes) {
        const queryItemsIds = [];
        const sourceIds = attributes.map(({ id }) => id);

        const getItemIds = items => {
            items.forEach(item => {
                !queryItemsIds.includes(item.id) && queryItemsIds.push(item.id);
                item.items && getItemIds(item.items);
            });
        };

        getItemIds(queryForClient.items);

        return queryItemsIds.some(id => !sourceIds.includes(id));
    }

    static getSelectedAttributesLabels(attributeOptions, filterValues) {
        return attributeOptions
            .filter(({ value }) => filterValues.includes(value))
            .map(({ label }) => label);
    }

    static getActualOption(filterValues, attributeOptions) {
        const attributeValues = attributeOptions.map(({ value }) => value);

        return filterValues.some(option => !attributeValues.includes(option))
            ? []
            : QueryExecuteService.getSelectedAttributesLabels(attributeOptions, filterValues);
    }

    static checkOperatorValue(operator, value) {
        const emptyOperators = [ 'NEN', 'EQN', 'LNEN', 'LEQN' ];
        const emptyCondition = emptyOperators.includes(operator);
        const notEmptyCondition = (operator && !emptyCondition) && value !== null;

        return emptyCondition || notEmptyCondition;
    }

    static isEmpty(data) {
        const arrayBool = !!data.length;
        const objectBool = !!Object.keys(data).length;
        const stringBool = !!data;

        if (typeof data === 'string') return stringBool ? data : stringBool;
        if (Array.isArray(data)) return arrayBool ? data : arrayBool;

        return objectBool ? data : objectBool;
    }

    static checkIsEmptyQuery(metaQuery) {
        const [ firstItem ] = metaQuery.items;

        return !firstItem?.id
            && !firstItem?.value
            && !firstItem?.operator
            && metaQuery.items.length < 2
            && !firstItem?.items?.length;
    }
}
