import React from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Container } from 'reactstrap';
import { RenderCustomColumns, RenderConditions } from '../';

export const SortableItem = SortableElement(props => {
    const DragHandle = SortableHandle(() => <Col md={ 1 } className="exporter_dynamic-form_col col-drag"><FontAwesomeIcon icon="bars"/></Col>);

    return (
        <Container>
            <Row>
                <DragHandle/>
                {
                    props?.propsData?.fields?.name === 'custom_columns'
                        ? <RenderCustomColumns { ...props.propsData } />
                        : <RenderConditions { ...props.propsData } />
                }
            </Row>
        </Container>
    );
});
