import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Label, Col, Row, Button } from 'reactstrap';
import { MenuList, DropdownIndicator, Option } from '../SelectComponents';
import { usePreviousProps } from '/services';
import { VocSelectMineValueContainer } from './components';

import './VocSelectMine.scss';

export const VocSelectMine = ({
    name,
    value,
    isClearable,
    options,
    placeholder,
    className,
    globalClass,
    onChange,
    onFocus,
    onBlur,
    disabled,
    removeSelected,
    isMulti,
    isMultiOutside,
    isCreatable,
    closeMenuOnSelect,
    formatOptionLabel,
    isRequired,
    label,
    hint,
    isHorizontal,
    defaultValue,
    apply,
    portal,
    zIndex,
    filterName,
    withoutActionButton,
    additionalValueKey,
    id,
}) => {
    const [ isFocus, setFocus ] = useState(false);
    const selectEl = useRef(null);
    const controlLabels = useRef(null);
    const prevValue = usePreviousProps(value);

    useEffect(() => {
        const { current } = controlLabels;

        if (current) {
            if (prevValue && value && prevValue.length < value.length) {
                current.scroll(0, current.scrollHeight);
            }
        }
    }, [ value ]);

    const trimSelectedOption = (inx, select) => {
        const newSelect = [ ...select ];
        let { value, label } = newSelect[inx];

        if (typeof value !== "number") {
            value = value.trim();
            label = label.trim();
        }

        if (value) {
            newSelect[inx] = { value, label };
        }

        return !value ? newSelect.splice(0, inx) : newSelect;
    };

    const handleOnChange = select => {
        if (select === null) return false;

        const inxLastOption = select.length - 1;
        const newSelect = isCreatable ? trimSelectedOption(inxLastOption, select) : select;

        if (options.length === newSelect.length || options.length < newSelect.length) {
            selectEl.current.onMenuClose();
        }

        onChange(newSelect);
    };

    const removeSelectItem = unselect => {
        const newValue = value.filter(item => item.value !== unselect);

        onChange(newValue);
    };

    const handleValue = value => {
        setFocus(!!value.length);
    };

    const classList = new Set([ 'vochub-select-control', isMulti && 'multiselect' ]);

    const config = {
        className: Array.from(classList).concat(className).filter(Boolean).join(' '),
        classNamePrefix: "vochub-select-control",
        name,
        value,
        isClearable,
        components: { MenuList, DropdownIndicator, Option },
        options,
        placeholder,
        onBlur,
        onFocus,
        isDisabled: disabled,
        removeSelected,
        isMulti,
        defaultValue,
        ref: selectEl,
        onInputChange: handleValue,
        onChange: handleOnChange,
        closeMenuOnSelect,
        id: `react-select_${id}`,
    };

    if (!isClearable) {
        config['backspaceRemovesValue'] = false;
    }

    if (isMulti) {
        config['hideSelectedOptions'] = true;
    }

    if (formatOptionLabel) {
        config['formatOptionLabel'] = formatOptionLabel;
    }

    if (portal) {
        config['menuPortalTarget'] = document.body;
        config['styles'] = { menuPortal: base => ({ ...base, zIndex }) };
        config['menuShouldScrollIntoView'] = false;
        config['menuPlacement'] = "auto";
    }

    return (
        <Row className={ globalClass }>
            {
                label
                && <Col md={ isHorizontal ? 3 : 12 } className="select-control-label">
                    <Label for={ name }>
                        { label }
                        { isRequired ? <span className='star'> *</span> : '' }
                    </Label>
                    {
                        hint && <span className={ 'select-control-label__hint' }> { hint } </span>
                    }
                </Col>
            }
            <Col md={ withoutActionButton ? 6 : 5 } xs='8'>
                <div
                    className={ `voc-select-control-wrap voc-select-control-wrap_mine ${ isMultiOutside ? ' isMultiOutside' : '' }` }>
                    {
                        isCreatable
                            ? <CreatableSelect
                                { ...config }
                            />
                            : <Select
                                { ...config }
                            />
                    }
                    {
                        isMultiOutside && !isFocus
                        && <div className="voc-select-control-wrap__placeholder">{ placeholder }</div>
                    }
                </div>
            </Col>
            {
                !withoutActionButton
                && <Col md="auto" xs='4'>
                    {
                        apply
                        && <div className={ 'voc-select-control-wrap__btn' }>
                            <Button
                                color="primary"
                                onClick={ apply }>
                                Apply
                            </Button>
                        </div>
                    }
                </Col>
            }
            <Col md={ withoutActionButton ? 6 : 5 } xs='12'>
                { isMultiOutside
                && <div
                    className={ 'voc-select-control-labels' }
                    ref={ controlLabels }
                >
                    <VocSelectMineValueContainer
                        additionalValueKey={ additionalValueKey }
                        deleteValue={ removeSelectItem }
                        value={ value }
                    />
                    {
                        filterName && value && !value.length
                        && <div className={ 'voc-select-control-labels__items-list' }>
                            { filterName }
                        </div>
                    }
                </div>

                }
            </Col>
        </Row>
    );
};

VocSelectMine.propTypes = {
    isRequired: PropTypes.bool,
    additionalValueKey: PropTypes.string,

    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),

    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    })),

    isClearable: PropTypes.bool,
    isHorizontal: PropTypes.bool,
    removeSelected: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    isMulti: PropTypes.bool,
    isMultiOutside: PropTypes.bool,
    label: PropTypes.string,
    hint: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    portal: PropTypes.bool,
    filterName: PropTypes.string,
    withoutActionButton: PropTypes.bool,
    id: PropTypes.string,
};

VocSelectMine.defaultProps = {
    isCreatable: false,
    additionalValueKey: '',
    isMulti: false,
    withoutActionButton: false,
    placeholder: 'Select...',
    hint: '',
    zIndex: 9999,
};
