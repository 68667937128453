import React, { Component } from 'react';
import './Quoting.scss';

export class Quoting extends Component {
    quotes = {
        "quote0": {
            "text": "Customers are human and humans can view situations in unexpected ways",
            "author": "Marilyn Suttle",
        },
        "quote1": {
            "text": "Make a customer, not a sale",
            "author": "Katherine Barchetti",
        },
        "quote2": {
            "text": "Your most unhappy customers are your greatest source of learning",
            "author": "Bill Gates",
        },
        "quote3": {
            "text": "Customers don’t expect you to be perfect. They do expect you to fix things when they go wrong",
            "author": "Donald Porter",
        },
        "quote4": {
            // eslint-disable-next-line max-len
            "text": "Loyal customers, they don’t just come back, they don’t simply recommend you, they insist that their friends do business with you",
            "author": "Chip Bell",
        },
        "quote5": {
            "text": "The customer’s perception is your reality",
            "author": "Kate Zabriskie",
        },
        "quote6": {
            "text": "The purpose of a business is to create a customer who creates customers",
            "author": "Shiv Singh",
        },
        "quote7": {
            "text": "There are no traffic jams along the extra mile",
            "author": "Roger Staubach",
        },
        "quote8": {
            "text": "Customer service is not a department, it’s everyone’s job",
            "author": "Unknown",
        },
        "quote9": {
            "text": "To keep a customer demands as much skill as to win one",
            "author": "American Proverb",
        },
        "quote10": {
            "text": "Customers may forget what you said but they'll never forget how you made them feel",
            "author": "Unknown",
        },
        "quote11": {
            "text": "Well done is better than well said",
            "author": "Benjamin Franklin",
        },
        "quote12": {
            "text": "A man without a smiling face must not open a shop",
            "author": "Chinese Proverb",
        },
        "quote13": {
            "text": "Everything starts with the customer",
            "author": "Louis XIV",
        },
        "quote14": {
            "text": "A satisfied customer is the best business strategy of all",
            "author": "Michael LeBoeuf",
        },
        "quote15": {
            "text": "It is the customer who pays the wages",
            "author": "Henry Ford",
        },
    };

    state = {
        quote: this.quotes[`quote` + Math.floor(Math.random() * Object.keys(this.quotes).length)],
    };

    render() {
        const {
            text,
            author,
        } = this.state.quote;
        const classList = new Set([ 'quote', ...this.props.classList ]);

        return (
            <div className={ Array.from(classList).join(' ') }>
                <blockquote className='quote__container'>
                    <p className='quote__text'>{ text }</p>
                    <footer className='quote__author'>{ author }</footer>
                </blockquote>
            </div>
        );
    }
}
