import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TableResetFilter.scss';

export const TableResetFilter = ({
    className,
    filters,
    reset,
}) => {
    const hasFilters = Object.keys(filters).filter(el => filters[el].length);
    const getLabel = () => hasFilters.map((el, index) => (
        <div key={ index } className={ 'table-reset-filter__item' }>
            <span>{ el } </span> <FontAwesomeIcon icon="times" onClick={ () => reset(el) }/>
        </div>
    ));

    return (
        <Row key={ 2 }>
            <Col md={ 12 }>
                <div className={ `${ className } table-reset-filter` }>
                    {
                        hasFilters.length
                            ? <div className={ 'table-reset-filter__box' }>
                                {
                                    getLabel()
                                }
                                <Button onClick={ () => reset() } color="link">
                                    <FontAwesomeIcon icon="trash-alt"/>
                                    <span> Reset all filters</span>
                                </Button>
                            </div>
                            : ''
                    }
                </div>
            </Col>
        </Row>
    );
};

TableResetFilter.defaultProps = {
    className: '',
};
