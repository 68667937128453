/* Drop Zone */
export const COLLAPSED_DROP_ZONE_MIN_WIDTH = 75;
export const EXPANDED_DROP_ZONE_MIN_WIDTH = 250;
export const LEFT_FLUID_OFFSET = 60;
export const PAGE_CONTROLS = 49;
export const DASHBOARDS_CONTROLS_HEIGHT = 81.5;

export const MIN_DROPZONE_WIDTH = (isExpanded: boolean) => isExpanded
    ? EXPANDED_DROP_ZONE_MIN_WIDTH
    : COLLAPSED_DROP_ZONE_MIN_WIDTH;
