import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isDescendant } from 'react-sortable-tree';
import cx from 'classnames';
import { autobind } from 'core-decorators';

import { TooltipWrapper } from '/components';
import { QuestionsServices } from '/scenes/VocFeedback/services';
import { QuestionTreeItemControls } from '../QuestionTreeItemControls';

export class QuestionTreeItem extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.rowWrapperRef = React.createRef();
    }

    @autobind
    handleSetQuestionLayout() {
        const { activeQuestionId, node, parentNode, setQuestionLayoutHandler } = this.props;
        const isSameItem = activeQuestionId === node.id;
        const parentId = QuestionsServices.isMatrixQuestion(parentNode?.question_type?.display_class)
            ? parentNode.id
            : null;

        setQuestionLayoutHandler?.({
            activeQuestionId: isSameItem ? null : node.id,
            showQuestionDetails: !isSameItem,
            inheritDataFromId: isSameItem ? null : parentId,
        });
    }

    render() {
        const {
            scaffoldBlockPxWidth,
            toggleChildrenVisibility,
            connectDragPreview,
            connectDragSource,
            isDragging,
            canDrop,
            canDrag,
            node,
            title,
            subtitle,
            draggedNode,
            path,
            treeIndex,
            isSearchMatch,
            isSearchFocus,
            actionDisabled,
            isMatrixQuestionExist,
            isPermitEdit,
            showQuestionDetails,
            className,
            style,
            didDrop,
            // eslint-disable-next-line no-unused-vars
            treeId,
            // eslint-disable-next-line no-unused-vars
            isOver, // Not needed, but preserved for other renderers
            // eslint-disable-next-line no-unused-vars
            parentNode, // Needed for dndManager
            rowDirection,
            setQuestionLayoutHandler,
            copyQuestionHandler,
            showQuestionFlow,
            questionFlowHandler,
            removeQuestionHandler,
            openConfigurablePopover,
            activeQuestionId,
            inheritDataFromId,
            ...otherProps
        } = this.props;
        const nodeTitle = title || node.title;
        const nodeSubtitle = subtitle || node.subtitle;
        const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;
        let nodeType = node.type;
        let handle;

        if (QuestionsServices.isMatrixQuestion(node.type) && showQuestionDetails) {
            nodeType = node.type === 'MatrixConfigurable' ? 'MatrixConf...' : 'MatrixScal...';
        }

        if (canDrag) {
            if (typeof node.children === 'function' && node.expanded) {
                // Show a loading symbol on the handle when the children are expanded
                //  and yet still defined by a function (a callback to fetch the children)
                handle = (
                    <div className='rst__loadingHandle'>
                        <div className='rst__loadingCircle'>
                            { [ ...new Array(12) ].map((_, index) => (
                                <div
                                    key={ index }
                                    className={ `rst__loadingCirclePoint ${ rowDirectionClass }` }
                                />
                            )) }
                        </div>
                    </div>
                );
            } else {
                // Show the handle used to initiate a drag-and-drop
                handle = connectDragSource(
                    <div className='rst__moveHandle'>
                        <FontAwesomeIcon icon='bars' />
                    </div>,
                    { dropEffect: 'copy' },
                );
            }
        }

        const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
        const isLandingPadActive = !didDrop && isDragging;
        const level = this.props.path.length;
        const rowWrapperOffset = level * scaffoldBlockPxWidth;

        let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };

        if (rowDirection === 'rtl') {
            buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
        }

        return (
            <div
                className={ cx({ 'rst__node-unconditional-skip': node.isUnconditionalSkip }) }
                style={{ height: '100%' }} { ...otherProps }
            >
                {
                    toggleChildrenVisibility
                    && node.children
                    && (node.children.length > 0 || typeof node.children === 'function')
                    && (
                        <div>
                            <button
                                type='button'
                                aria-label={ node.expanded ? 'Collapse' : 'Expand' }
                                className={ `${ node.expanded ? 'rst__collapseButton' : 'rst__expandButton' } ${ rowDirectionClass }` }
                                style={ buttonStyle }
                                onClick={ () => toggleChildrenVisibility({ node, path, treeIndex }) }
                            />

                            {
                                node.expanded
                                && !isDragging
                                && <div
                                    style={{ width: scaffoldBlockPxWidth }}
                                    className={ `rst__lineChildren ${ rowDirectionClass }` }
                                />
                            }
                        </div>
                    )
                }

                <div
                    ref={ this.rowWrapperRef }
                    className={ cx('rst__rowWrapper', rowDirectionClass, { 'rst__rowWrapper--stripped': node.stripped }) }
                    style={{ maxWidth: `calc(100% - ${ rowWrapperOffset }px)` }}
                >
                    { /* Set the row preview to be used during drag and drop */ }
                    {
                        connectDragPreview(
                            <div
                                className={ cx(
                                    'rst__row',
                                    { 'rst__rowLandingPad': isLandingPadActive },
                                    { 'rst__rowCancelPad': isLandingPadActive && !canDrop },
                                    { 'rst__rowSearchMatch': isSearchMatch },
                                    { 'rst__rowSearchFocus': isSearchFocus },
                                    rowDirectionClass,
                                    className,
                                ) }
                                style={{
                                    opacity: isDraggedDescendant ? 0.5 : 1,
                                    ...style,
                                }}
                            >
                                { handle }
                                <div className={ cx(
                                    'rst__rowContents',
                                    { 'rst__rowContentsDragDisabled': !canDrag },
                                    rowDirectionClass,
                                    { 'rst__rowContents--highlighted': activeQuestionId === node.id },
                                ) }
                                >
                                    <div
                                        className={ `rst__rowLabel ${ rowDirectionClass }` }
                                        onClick={ this.handleSetQuestionLayout }
                                    >
                                        <TooltipWrapper value={ nodeTitle }>
                                            <div className={ cx(
                                                'rst__rowTitle',
                                                { 'rst__rowTitleWithSubtitle': node.subtitle },
                                                { 'rst__rowTitleDefaultValue': node.isEmptyTitle },
                                            ) }>
                                                <div>
                                                    <span className='rst__rowTitleId'>ID: { node.id } </span>
                                                    {
                                                        typeof nodeTitle === 'function'
                                                            ? nodeTitle({
                                                                node,
                                                                path,
                                                                treeIndex,
                                                            })
                                                            : nodeTitle
                                                    }
                                                </div>
                                            </div>
                                        </TooltipWrapper>


                                        {
                                            nodeSubtitle && (
                                                <span className='rst__rowSubtitle'>
                                                    {
                                                        typeof nodeSubtitle === 'function'
                                                            ? nodeSubtitle({
                                                                node,
                                                                path,
                                                                treeIndex,
                                                            })
                                                            : <TooltipWrapper value={ nodeSubtitle }>
                                                                <span>{ nodeSubtitle }</span>
                                                            </TooltipWrapper>
                                                    }
                                                </span>
                                            )
                                        }
                                    </div>

                                    {
                                        node.type
                                        && <div>
                                            <div className='rst_question__label'>
                                                { nodeType }
                                            </div>
                                        </div>
                                    }

                                    <div className={ cx('rst__rowToolbar', { matrixQuestionExist: isMatrixQuestionExist }) }>
                                        <QuestionTreeItemControls
                                            actionDisabled={ actionDisabled }
                                            activeQuestionId={ activeQuestionId }
                                            showQuestionDetails={ showQuestionDetails }
                                            inheritDataFromId={ inheritDataFromId }
                                            isPermitEdit={ isPermitEdit }
                                            node={ node }
                                            copyQuestionHandler={ copyQuestionHandler }
                                            showQuestionFlow={ showQuestionFlow }
                                            questionFlowHandler={ questionFlowHandler }
                                            removeQuestionHandler={ removeQuestionHandler }
                                            openConfigurablePopover={ openConfigurablePopover }
                                            setQuestionLayoutHandler={ setQuestionLayoutHandler }
                                        />
                                    </div>
                                </div>
                            </div>,
                        )
                    }
                </div>
            </div>
        );
    }
}

QuestionTreeItem.defaultProps = {
    isSearchMatch: false,
    isSearchFocus: false,
    canDrag: false,
    toggleChildrenVisibility: null,
    actionDisabled: false,
    isMatrixQuestionExist: false,
    isPermitEdit: false,
    showQuestionDetails: false,
    className: '',
    style: {},
    parentNode: null,
    draggedNode: null,
    canDrop: false,
    title: null,
    subtitle: null,
    rowDirection: 'ltr',
};

QuestionTreeItem.propTypes = {
    node: PropTypes.shape({}).isRequired,
    title: PropTypes.oneOfType([ PropTypes.func, PropTypes.node ]),
    subtitle: PropTypes.oneOfType([ PropTypes.func, PropTypes.node ]),
    path: PropTypes.arrayOf(
        PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    ).isRequired,
    treeIndex: PropTypes.number.isRequired,
    treeId: PropTypes.string.isRequired,
    isSearchMatch: PropTypes.bool,
    isSearchFocus: PropTypes.bool,
    canDrag: PropTypes.bool,
    scaffoldBlockPxWidth: PropTypes.number.isRequired,
    toggleChildrenVisibility: PropTypes.func,
    actionDisabled: PropTypes.bool,
    isMatrixQuestionExist: PropTypes.bool,
    isPermitEdit: PropTypes.bool,
    showQuestionDetails: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    copyQuestionHandler: PropTypes.func,
    showQuestionFlow: PropTypes.func,
    questionFlowHandler: PropTypes.func,
    removeQuestionHandler: PropTypes.func,
    openConfigurablePopover: PropTypes.func,

    // Drag and drop API functions
    // Drag source
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    parentNode: PropTypes.shape({}), // Needed for dndManager
    isDragging: PropTypes.bool.isRequired,
    didDrop: PropTypes.bool.isRequired,
    draggedNode: PropTypes.shape({}),
    // Drop target
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool,

    // rtl support
    rowDirection: PropTypes.string,
};
