/*
*  @permission:
 { "id": 5, "name": "Viewer" },
 { "id": 4, "name": "Inviter" },
 { "id": 3, "name": "Approver" },
 { "id": 2, "name": "Editor" },
 { "id": 1, "name": "Manager" }
* */
export class UsersRoleFeedbackService {
    static getRightsForAll = permission => {
        return {
            surveyList: UsersRoleFeedbackService.getRightsForSurveyList(permission),
            customerJourneyList: UsersRoleFeedbackService.getRightsForCustomerJourney(permission),
        };
    };

    static getRightsForSurveyList(permission) {
        const {
            isEditorPermit,
            isInviterPermit,
        } = UsersRoleFeedbackService.getRights(permission);

        return {
            isPermitCopy: isEditorPermit,
            isPermitAddSurvey: isEditorPermit,
            isPermitInvite: isInviterPermit,
        };
    }

    static getRightsForCustomerJourney(permission) {
        const { isInviterPermit, isEditorPermit } = UsersRoleFeedbackService.getRights(permission);

        return {
            isPermitAddSurvey: isEditorPermit,
            isPermitInvite: isInviterPermit,
            isPermitRemoveTouchPoint: isEditorPermit,
        };
    }

    static getRightsForSurvey(permission) {
        const {
            isEditorPermit,
            isApproverPermit,
            isInviterPermit,
        } = UsersRoleFeedbackService.getRights(permission);

        return {
            isPermitEdit: isEditorPermit,
            isPermitApprove: isApproverPermit,
            isPermitInvite: isInviterPermit,
        };
    }

    static getRightsForGlobal(permission) {
        const { isManagerPermit } = UsersRoleFeedbackService.getRights(permission);

        return {
            isPermitEdit: isManagerPermit,
        };
    }

    static getRightsForCustomers(permission, isSupportUser) {
        const { isEditorPermit, isInviterPermit } = UsersRoleFeedbackService.getRights(permission);

        return {
            isPermitEdit: isEditorPermit || isInviterPermit || isSupportUser,
        };
    }

    static getRights(permission) {
        const isManagerPermit = permission.includes('Manager');
        const isEditorPermit = permission.includes('Editor') || isManagerPermit;
        const isApproverPermit = permission.includes('Approver') || isManagerPermit;
        const isInviterPermit = permission.includes('Inviter') || isManagerPermit;
        const isViewerPermit = permission.includes('Viewer') || isManagerPermit;

        return {
            isManagerPermit,
            isEditorPermit,
            isApproverPermit,
            isInviterPermit,
            isViewerPermit,
        };
    }
}
