import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ButtonComponent, PopoverList } from '/visual/components';
import { TAction } from '/visual/models';
import { IGadgetActionMenu } from '../../models';

import styles from './style.module.scss';

const MAX_TOOLTIP_LENGTH = 112;

export const GadgetActionMenu = ({
    gadgetId,
    isEditable,
    gadgetDescription,
    onCut,
    onSync,
    onCopy,
    onEdit,
    onMoreInfo,
    onDelete,
    onMoveToSave,
    rights,
}: IGadgetActionMenu) => {
    const { t } = useTranslation();
    const target = `menuButtonGadget_${ gadgetId }`;
    const hasDescription = Boolean(gadgetDescription);

    let controls: TAction[] = [
        {
            title: t('cutGadget'),
            icon: 'arrows-alt' as IconProp,
            className: styles.cutGadget,
            onClick: onCut,
        },
        {
            title: t('syncGadget'),
            icon: 'sync' as IconProp,
            onClick: onSync,
        },
        {
            title: t('copyGadget'),
            icon: 'copy' as IconProp,
            className: styles.copyGadget,
            onClick: onCopy,
        },
        {
            title: t('deleteGadget'),
            icon: 'trash' as IconProp,
            onClick: onDelete,
        },
        {
            title: t('moveToSave'),
            icon: 'minus-circle' as IconProp,
            className: styles.moveToSave,
            onClick: onMoveToSave,
        },
    ];

    if (isMobile) {
        const allowedControls = [ t('syncGadget'), t('deleteGadget') ];

        controls = controls.filter((action: TAction) => allowedControls.includes(action.title));
    }

    return (
        <div className={ styles.actionWrapper }>
            {
                isEditable && <PopoverList
                    target={ target }
                    tagClassName={ styles.actionButton }
                    placement='left-start'
                    popoverClassName={ styles.popoverWrapper }
                    actionList={ controls }
                />
            }

            <ButtonComponent
                isShow={ rights.isPermitView }
                buttonProps={{
                    color: hasDescription ? 'primary' : 'secondary',
                    className: styles.actionButton,
                    onClick: onMoreInfo,
                }}
                icon={ 'info-circle' }
                tooltipProps={{
                    force: hasDescription,
                    value: `${ t('field.description') }: ${gadgetDescription}`,
                    truncateTo: MAX_TOOLTIP_LENGTH,
                }}
            />

            <ButtonComponent
                isShow={ rights.isPermitView }
                buttonProps={{
                    color: 'secondary',
                    className: styles.actionButton,
                    onClick: onEdit,
                }}
                icon={ 'cog' }
            />

        </div>
    );
};
