import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

import { IChartData } from '/ai/scenes/TopicSet/models';
import { useResizeObserver } from '/services';

import styles from './style.module.scss';

export const BarChart = ({ chartData }: { chartData: IChartData }) => {
    const svgRef = useRef(null);
    const tooltipRef = useRef(null);
    const wrapperRef = useRef(null);
    const dimensions = useResizeObserver(wrapperRef);

    const chartWidth = wrapperRef.current;

    const render = () => {
        const widthSvg = chartWidth?.clientWidth || 2;
        const heightSvg = widthSvg / 2;

        const svg = d3.select(svgRef.current).attr('class', 'background-style'),
            margin = { top: 20, right: 20, bottom: 50, left: 48 },
            width = +widthSvg - margin.left - margin.right,
            height = Math.abs(+heightSvg - margin.top - margin.bottom);

        svg.attr('width', widthSvg);
        svg.attr('height', heightSvg);


        const x = d3.scaleBand().rangeRound([ 0, width ]).padding(0.05),
            y = d3.scaleLinear().rangeRound([ height, 0 ]);

        const g = svg.append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const data = chartData.labels_scores;

        x.domain(data.map(function(d) { return d.name === '[ NaN - NaN ]' ? '[ 0 - 1 ]' : d.name; }));
        y.domain([ 0, d3.max(data, d => d.count) || 0 ]);

        g.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x))
            .append('text')
            .attr('y', 6)
            .attr('dy', '2.5em')
            .attr('dx', width/2 - margin.left)
            .attr('text-anchor', 'start')
            .attr('fill', 'black')
            .style('font-size', '15px')
            .text('Probability score');

        g.append('g')
            .attr('class', 'axis axis--y')
            .call(d3.axisLeft(y).ticks(10))
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', -35)
            .attr('dx', -(height/2 - margin.top - 15))
            .attr('text-anchor', 'end')
            .attr('fill', 'black')
            .style('font-size', '15px')
            .text('Occurrence');

        const _onItemEnter = (
            data: { name: string | null; count: any; },
            inx: string | number,
            elements: { [x: string]: any; },
        ) => {
            const toolTip = d3.select(tooltipRef.current);

            d3.select(elements[inx]).style('opacity', 0.5);

            toolTip
                .html('');
            toolTip
                .append('span')
                .text(data.name
                    ? data.name === '[ NaN - NaN ]'
                        ? '[ 0 - 1 ]'
                        : data.name
                    : "",
                );
            toolTip
                .append('span')
                .text(`Count: ${data.count}`);
        };

        const _onItemOverMove = () => {
            const toolTip = d3.select(tooltipRef.current);

            toolTip.style('visibility', 'visible')
                .style('top', `${d3.event.clientY + 15}px`)
                .style('left', `${d3.event.clientX + 15}px`);
        };

        const _onItemOut = (data: any, inx: string | number, elements: { [x: string]: any; }) => {
            d3.select(elements[inx]).style('opacity', 1);

            const toolTip = d3.select(tooltipRef.current);

            toolTip.style('visibility', 'hidden')
                .html('');
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        g.selectAll('.bar')
            .data(data)
            .enter().append('rect')
            .attr('class', 'bar')
            .attr('fill', '#1f77b4')
            .attr('x', d => x(d.name))
            .attr('y', d => y(d.count))
            .attr('width', x.bandwidth())
            .attr('height', d => height - y(d.count))
            .on('mouseenter', _onItemEnter)
            .on('mousemove', _onItemOverMove)
            .on('mouseout', _onItemOut);
    };

    const removeD3 = () => {
        const chartElements = d3.select(svgRef.current).select('*');

        if (chartElements) {
            chartElements.remove();
        }
    };

    useEffect(() => {
        render();

        return () => removeD3();
    }, [ dimensions, chartWidth ]);

    return (
        <div className={ styles.barChartWrapper } ref={ wrapperRef }>
            <p className={ styles.barChartLabel }>{ chartData?.name }</p>
            <div className={ styles.toolTipWrapper } ref={ tooltipRef }/>
            <svg ref={ svgRef }/>
        </div>
    );
};
