import { exception } from '../../modules/caseAlerts.selectors';

export const CaseAlertConditionFormValidation = (values = []) => {
    const errors = {
        target_emails: [],
    };

    const {
        alert_condition,
        case_alert,
        external_identifier,
        link_condition,
        caseAlertType,
        case_alert_type,
        value,
    } = values;

    const model = {
        alert_condition,
        case_alert,
        external_identifier,
        link_condition,
        caseAlertType,
        case_alert_type,
        value,
    };

    Object.keys(model).forEach(value => {
        const isRequired = !(value === 'value' && exception.includes(alert_condition));

        if (!model[value] && isRequired) {
            errors[value] = 'Field must not be empty';
        }
    });

    return errors;
};
