import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import { VocSelect } from '/components';
import { CustomersTableSelected } from '../CustomersTableSelected/';
import { HelperService } from '/services';

import './CustomersTableToolbar.scss';

export class CustomersTableToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: props.search,
        };

        this.searchTypeDebouncer = HelperService.debounce((...args) => {
            props.onSearchType(...args);
        }, 300);
    }

    @autobind
    onSearchType(event) {
        const search = event.target.value;

        this.setState({ search });
        this.searchTypeDebouncer(search);
    }

    render() {
        const {
            selectedList,
            removeFromSelected,
            state,
            subState,
            changeState,
            states,
            subStates,
        } = this.props;

        const stateValue = HelperService.getSelectedValue(states, state);
        const subStateValue = HelperService.getSelectedValue(subStates, subState);

        return (
            <Row className='export-results__toolbar'>
                <Col md={ 7 } className='export-results__toolbar_filters'>
                    <div className='export-results__toolbar_filters_select_wrapper'>
                        <label htmlFor={ 'channelSelect' }
                            className={ 'customers-toolbar__label export-results-toolbar-label' }>
                                Select State:
                        </label>
                        <VocSelect
                            name="channelSelect"
                            isClearable={ false }
                            isHorizontal={ true }
                            options={ states }
                            value={ stateValue }
                            onChange={ state => changeState(state, 'state') }
                            className={ 'customers-toolbar__select export-results-toolbar-select' }
                        />
                    </div>

                    <div className='export-results__toolbar_filters_select_wrapper'>
                        <label htmlFor={ 'channelSelect' }
                            className={ 'customers-toolbar__label export-results-toolbar-label' }>
                                Select Substate:
                        </label>
                        <VocSelect
                            name="channelSelect"
                            isClearable={ false }
                            isHorizontal={ true }
                            options={ subStates }
                            value={ subStateValue }
                            onChange={ state => changeState(state, 'substate') }
                            className={ 'customers-toolbar__select export-results-toolbar-select' }
                        />
                    </div>
                </Col>
                <Col md={{ size: 4, offset: 1 }}>
                    <div className="form-group form-group-sm react-bs-table-search-form">
                        <Input
                            placeholder={ 'Allowed search format: text or *text or text*' }
                            className={ 'export-results__toolbar-search' }
                            type={ 'text' }
                            value={ this.state.search }
                            onChange={ this.onSearchType }/>
                    </div>
                </Col>
                <Col md={ 12 }>
                    <CustomersTableSelected
                        selectedList={ selectedList }
                        removeFromSelected={ removeFromSelected }
                    />
                </Col>
            </Row>
        );
    }
}

CustomersTableToolbar.propTypes = {
    removeFromSelected: PropTypes.func,
    selectedList: PropTypes.array,
};
