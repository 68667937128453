import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, hasAccess, customProps, ...rest }) => (
    <Route { ...rest } render={ props => (
        hasAccess === true
            ? <Component { ...customProps } { ...props } />
            : <Redirect to='/' />
    ) } />
);
