import React, { memo } from 'react';
import { TooltipWrapper } from '/components';

const hours = new Array(24).fill('').map((el, idx) => idx < 10 ? `0${ idx }` : idx);

export const Thead = memo(({
    handlerSelectAll,
    hasData,
}) => {
    const getCellData = () => (
        hasData
            ? <span onClick={ handlerSelectAll }>{ 'Select all' }</span>
            : null
    );

    return (
        <thead>
            <tr>
                <th>{ getCellData() }</th>
                {
                    hours.map(el => (
                        <th key={ el } className={ 'timeDateGrid__th' }>
                            <TooltipWrapper value={ `start time ${ el }:00 - end time ${ el }:59` }
                                force={ true }>
                                <span> { el } </span>
                            </TooltipWrapper>
                        </th>
                    ))
                }
            </tr>
        </thead>
    );
}, () => true);
