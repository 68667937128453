import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import { getSurveys, updateFilters, reLemmatization, cleanup } from './modules';
import { filtersSelector, loadingSelector, surveysCountSelector, surveysListTableDataSelector } from './modules/selectors';
import { VocCleaupHOC } from '/components';
import { getSharedRights, saveStoreRights } from '/modules';
import { PageRequests } from '/services';
import { SurveysTable } from './components';
import { showExportFilterModal } from '/scenes/VocStore/modules/VocStore.modules';
import { showApplianceModal } from '/modules/segmentatorModules/segmentator.modules.js';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

const mapStateToProps = state => ({
    tableData: surveysListTableDataSelector(state),
    surveysCount: surveysCountSelector(state),
    filters: filtersSelector(state),
    loading: loadingSelector(state),
});

const mapDispatchToProps = {
    getSurveys,
    updateFilters,
    showApplianceModal,
    showExportFilterModal,
    reLemmatization,
    showConfirm,
    getSharedRights,
    saveStoreRights,
    cleanup,
};

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class SurveysList extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        this.requestTrigger();
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        this.pageRequests.cleanup();
        const request = this.props.getSurveys(filters);

        this.pageRequests.addRequest(request);
    }

    render() {
        const {
            filters,
            loading,
            surveysCount,
            updateFilters,
            tableData,
            getSharedRights,
            showApplianceModal,
            showExportFilterModal,
            reLemmatization,
            showConfirm,
            t,
        } = this.props;

        return (
            <>
                <DocumentTitle title={ t('surveysList') }>
                    <Card className={ 'page__wrapper' }>
                        <CardHeader className={ 'panel__header' }>
                            <span className={ 'panel__header-title' }>
                                <FontAwesomeIcon icon='list-ul' />
                                &nbsp;
                                { t('surveysList') }
                            </span>
                        </CardHeader>
                        <CardBody>
                            <SurveysTable
                                tableData={ tableData }
                                requestTrigger={ this.requestTrigger }
                                updateFilters={ updateFilters }
                                loading={ loading }
                                surveysCount={ surveysCount }
                                filters={ filters }
                                getSharedRights={ getSharedRights }
                                showApplianceModal={ showApplianceModal }
                                showExportFilterModal={ showExportFilterModal }
                                reLemmatization={ reLemmatization }
                                showConfirm={ showConfirm }
                            />
                        </CardBody>
                    </Card>
                </DocumentTitle>
            </>
        );
    }
}

SurveysList.propTypes = {
    tableData: PropTypes.array,
    filters: PropTypes.object,
    loading: PropTypes.bool,
};
