import { createSelector } from 'reselect';
import { SettingsService } from '/feedback/services/SettingsService';
import { unsubscribeSurveysSelector } from '/feedback/modules/VocFeedback.selectors';

export const getCommonState = state => state.VocFeedback.globalSettings.commonSettings;

export const classifierOptionSelector = createSelector(
    [ getCommonState ],
    ({ classifier }) =>
        classifier?.results.map(classifier => ({ value: classifier.classifierId, label: classifier.title })));


export const initialFormValuesSelector = createSelector(
    [ getCommonState, SettingsService.getFieldsByCommonGlobal, classifierOptionSelector, unsubscribeSurveysSelector ],
    ({ settings }, fields, classifierOptions, unsubscribeSurveys) => {
        const commonSettings = SettingsService.composeGlobalSettings(settings);

        const unsubscribeSurveyGroupListOptions = unsubscribeSurveys.results.map(template => ({
            value: template.id,
            label: template.name,
        }));

        return {
            fieldsMap: fields.map(field => ({
                ...field,
                children: SettingsService.changeFieldsCommonSettings(field, classifierOptions, child => ({
                    ...child,
                    options: child.key === "UNSUBSCRIBE_SURVEY_GROUP"
                        ? unsubscribeSurveyGroupListOptions
                        : child.options,
                    redefined: true,
                    isGlobal: true,
                }), settings),
            })),
            initialValues: commonSettings,
        };
    });

export const commonFormSettingsSelector = createSelector(
    [ getCommonState, initialFormValuesSelector, unsubscribeSurveysSelector ],
    ({ loading, sending, classifier }, fields, unsubscribe) => {
        return {
            loading,
            sending,
            ...fields,
            classifierLoading: classifier.loading,
            unsubscribeLoading: unsubscribe.loading,
        };
    });
