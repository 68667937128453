import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { VocTableV2 } from '/components';
import { CustomersActionsFormatter } from '../';
import { CustomersTableToolbar } from '../CustomersTableToolbar';
import { CustomersTableCheckbox } from '../CustomersTableCheckbox';

export class CustomersTable extends Component {
    settings = [
        {
            field: 'checkbox',
            title: '',
            formatter: this.checkboxFormatter,
            addToSelected: this.props.manageSelectAll,
            sort: false,
            style: {
                textAlign: 'center',
                width: '30px',
            },
        },
        {
            field: 'id',
            title: 'Id',
            sort: true,
            style: {
                width: '70px',
            },
        },
        {
            field: 'import_id',
            title: 'Import id',
            sort: true,
            style: {
                width: '100px',
            },
        },
        {
            field: 'language__iso_6391_code',
            title: 'Language',
            sort: true,
        },
        {
            field: 'state',
            title: 'State',
            sort: true,
            style: {
                wordBreak: 'break-word',
                width: '96px',
            },
        },
        {
            field: 'substate',
            title: 'Substate',
            formatter: 'conditionTooltip',
            sort: true,
            style: {
                wordBreak: 'break-word',
                width: '95px',
            },
        },
        {
            field: 'creation',
            title: 'Created',
            formatter: 'date',
            sort: true,
            style: {
                width: '135px',
            },
        },
        {
            field: 'start_time',
            title: 'Start date',
            formatter: 'date',
            sort: true,
            style: {
                width: '135px',
            },
        },
        {
            field: 'end_time',
            title: 'End date',
            formatter: 'date',
            sort: true,
            style: {
                width: '135px',
            },
        },
        {
            field: 'link',
            formatter: this.actionsFormatter,
            title: '',
            style: {
                width: '95px',
            },
        },
    ];

    @autobind
    getTableHeaders() {
        return [
            ...this.settings.slice(0, 3),
            ...this.getDynamicSettings(),
            ...this.settings.slice(4),
        ];
    }

    @autobind
    getDynamicSettings() {
        if (this.props.channel === 'DIGI') {
            return [
                {
                    field: 'address',
                    title: 'Address',
                    style: {
                        width: '100px',
                    },
                },
                {
                    field: 'externalId',
                    title: 'External id',
                    formatter: 'conditionTooltip',
                    style: {
                        width: '100px',
                    },
                },
            ];
        } else {
            return [
                {
                    field: 'address',
                    title: 'Address',
                    style: {
                        width: '100px',
                    },
                },
            ];
        }
    }

    @autobind
    checkboxFormatter(cell) {
        return <CustomersTableCheckbox
            { ...cell }
            addToSelected={ this.props.addToSelected }
            removeFromSelected={ this.props.removeFromSelected }
        />;
    }

    @autobind
    actionsFormatter(cell, rowData) {
        const isEnableReminder = ![
            'completed',
            'expired',
            'terminated',
            'created',
            'quarantined',
            'blacklisted',
            'invite not delivered',
        ].includes(rowData.state);
        const hasAddress = !!rowData.address;

        return (
            <CustomersActionsFormatter
                { ...cell }
                isEnableReminder={ hasAddress && isEnableReminder }
                isEnableInvitation={ hasAddress }
                onClickReminder={ id => this.onClickReminder(id, rowData.state) }
                onClickDelete={ this.onClickDelete }
                onClickSend={ this.onClickInvitation }
                rightsSurvey={ this.props.rightsSurvey }
            />
        );
    }

    @autobind
    onClickReminder(id, state) {
        this.props.sendReminder(id, state);
    }

    @autobind
    onClickDelete(id, hash) {
        this.props.showConfirm({
            header: 'Warning!',
            content: `You are going to delete questionnaire id: ${id}, continue?`,
            successCallback: () => this.onConfirmDelete(hash),
        });
    }

    @autobind
    onClickInvitation(creation, hash) {
        this.props.showConfirm({
            header: 'Do you really want to resend the invitation?',
            content: <div> The original invitation has been send on { creation }. <p>When you resend the invitation this
                date will be reset to the current date</p></div>,
            successCallback: () => this.props.invitationCustomers(hash),
        });
    }

    @autobind
    onConfirmDelete(hash) {
        this.props.deleteCustomers(hash).promise
            .then(() => {
                this.props.clearSelection();
                this.props.requestTrigger();
            });
    }

    @autobind
    createCustomToolbar() {
        return (
            <CustomersTableToolbar
                selectedList={ this.props.selected }
                removeFromSelected={ this.props.removeFromSelected }
                onSearchType={ this.props.onSearchType }
                search={ this.props.tableState.filters.search }
                states={ this.props.states }
                subStates={ this.props.subStates }
                changeState={ this.props.changeState }
                state={ this.props.tableState.filters.state }
                subState={ this.props.tableState.filters.substate }
            />
        );
    }

    getOptions() {
        return {
            customTableHeader: this.createCustomToolbar(),
            requestTrigger: this.props.requestTrigger,
            haveSearch: false,
            updateFilters: this.props.updateFilters,
        };
    }

    render() {
        const {
            tableState: {
                count,
                loading,
                filters,
            },
            data,
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.getTableHeaders() }
                data={ data }
                options={ this.getOptions() }
                filters={ filters }
                total={ count }
                loading={ loading }
            />
        );
    }
}

CustomersTable.propTypes = {
    data: PropTypes.array,
    deleteCustomers: PropTypes.func,
    invitationCustomers: PropTypes.func,
    showConfirm: PropTypes.func,
    tableState: PropTypes.shape({
        count: PropTypes.number,
        filters: PropTypes.object,
        loading: PropTypes.bool,
    }),
};
