import React, { useRef, useEffect } from 'react';
import { PermissionSelect } from '../PermissionSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePreviousProps } from '/services';

export const SelectedValues = ({
    optionsRights,
    onChange,
    value,
}) => {
    const permissionList = useRef();
    const prevValue = usePreviousProps(value);

    useEffect(() => {
        const { current } = permissionList;

        if (current) {
            if (prevValue && value && prevValue.length < value.length) {
                current.scroll(0, current.scrollHeight);
            }
        }
    },
    [ value ]);

    const handlerChange = (selected, index) => {
        const newVal = selected.value === 'remove'
            ? [ ...value.filter((el, inx) => inx !== index) ]
            : [ ...value.map((el, inx) => inx === index ? { ...el, permission: selected.value } : el) ];

        onChange(newVal);
    };

    return (
        <div
            className={ 'voc-select-value__wrapper' }
            ref={ permissionList }
        >
            {
                value.map((item, index) => (
                    <div key={ index } className={ 'voc-select-value__item' }>
                        { item.icon
                            ? <FontAwesomeIcon className={ 'voc-select-value__icon' } icon={ item.icon }/> : null
                        }
                        <p className={ 'voc-select-value__label' }>{ item.label } </p>
                        <PermissionSelect
                            onChange={ value => handlerChange(value, index) }
                            options={ optionsRights }
                            dropdownIndicator={ false }
                            className={ 'voc-selected-value-rights' }
                            value={ optionsRights.find(option => option.value === item.permission) }
                        />
                    </div>
                ))
            }
        </div>
    );
};

SelectedValues.defaultProps = {
    optionsRights: [],
    value: [],
};
