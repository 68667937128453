import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { TooltipWrapper } from '/components';

export const VirtualSourcesTableCiSync = ({ source }) => {
    const { t } = useTranslation();
    const { ciSync, ciSyncRequired } = source;

    const iconType = ciSyncRequired ? 'sync' : 'check';
    const tooltipValue = t(ciSyncRequired
        ? 'vsNeedSynchronised'
        : 'vsSynchronized',
    );

    return (
        ciSync
            ? <TooltipWrapper value={ tooltipValue } force>
                <span className='table-action-btn-wrapper'>
                    <FontAwesomeIcon icon={ iconType } />
                </span>
            </TooltipWrapper>
            : '—'
    );
};
