import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';

export function RowRates(props) {
    const rateTemplate = (label, percent, rates, style = 'success') => {
        return (
            <div className='rate-cell'>
                <span className='info-title'>{ label }</span>
                <Progress color={ style } value={ percent } className={ 'surveys-list__progress' }/>
                { rates && <span className='info-status'>{ `${percent}%` }</span> }
                { !rates && <span className='info-status'>...</span> }
            </div>
        );
    };

    const fetchRate = (value, num) => (value && value[num] && value[num].rate) ? value[num].rate : 0,
        rateStart = fetchRate(props.rates, 0),
        rateEnd = fetchRate(props.rates, 1),
        className = `rate-wrapper${props.rates && props.rates.length === 0 ? ' disabled' : ''}`;

    return (
        <div className={ className }>
            { rateTemplate('yesterday', rateStart, props.rates) }
            { rateTemplate('last 30 days', rateEnd, props.rates) }
        </div>
    );
}

RowRates.propTypes = {
    rates: PropTypes.any,
};

RowRates.defaultProps = {};

