import { useEffect, useState } from 'react';

import { HelperService } from '/services';
import { GadgetService, RadarChartService } from '/visual/scenes/Dashboard/components';
import { IChartData, TLabelData } from '/visual/scenes/Dashboard/components/Gadget/models';

const onChangeRadarColorTimeout = HelperService.debounce(({ callback }: any) => {
    callback();
}, 200, false);

let maxItemCount: number;

export const useRadarChart = (initChartData: IChartData) => {
    const [ formatData, setFormatData ] = useState({});

    const { colors, labels, dataSettings, contentSettings } = initChartData.gadgetData;

    const customColorSet = JSON.parse(dataSettings.customColorSet);
    const selectedColor = customColorSet && customColorSet.other[customColorSet.theOne];

    const getColor = (id, items) => GadgetService.getSavedColor(id, colors)
        || !selectedColor && GadgetService.getNpsColor(id)
        || RadarChartService.getD3Color({
            items,
            radarItemId: id,
            selectedColor,
        });

    const IsMaxValue = (maxValue, count) => {
        return maxValue > count
            ? maxValue
            : count;
    };

    const oneDimension = (items, count, categoryValue) => {
        maxItemCount = 0;

        const chartDataObj = {
            id: 'No value-2',
            label:
                GadgetService.getSavedLabel('No value-2', labels, 'tick')?.value
                || initChartData.gadgetData.dataSettings.factName || 'No value',
            data: [],
            color: getColor('No value-2', [{ id: 'No value-2' }]),
            totalCount: count,
            customId: GadgetService.getCustomChartElementId({
                prefix: 'radarItemData',
                itemId: 'Novalue2',
            }),
        };

        for (const key in items) {
            chartDataObj.data.push({
                axis: items[key].name,
                value: items[key].count,
                categoryValue: categoryValue,
                customId: GadgetService.getCustomChartElementId({
                    prefix: 'radarItemData_dot',
                    groupId: chartDataObj.id,
                    itemId: items[key].name,
                }),
                parentId: chartDataObj.id,
                parentLabel: chartDataObj.label,
            });

            maxItemCount = IsMaxValue(maxItemCount, items[key]['count']);
        }

        return [ chartDataObj ];
    };

    const twoDimension = (items, count) => {
        const fieldRegistry = {};

        maxItemCount = 0;

        items.forEach(obj => {
            obj?.items?.forEach(item => {
                if (!fieldRegistry[item.id]) {
                    fieldRegistry[item.name] = {};
                }

                fieldRegistry[item.name][obj.id] = item['count'];
                maxItemCount = IsMaxValue(maxItemCount, item['count']);
            });
        });

        const formattedRadarData = items.map(radarItemData => {
            const polygonId = radarItemData.id !== '' ? radarItemData.id : radarItemData.name;
            const customId = GadgetService.getCustomChartElementId({
                prefix: 'radarItemData',
                itemId: polygonId,
            });

            const chartDataObj = {
                id: radarItemData.id,
                label:
                    GadgetService.getSavedLabel(radarItemData.id, labels, 'legend')?.value
                    || radarItemData.name || 'No value',
                data: [],
                color: getColor(radarItemData.id, items),
                totalCount: count,
                count: radarItemData.count,
                customId,
            };

            for (const key in fieldRegistry) {
                const customId = GadgetService.getCustomChartElementId({
                    prefix: 'radarItemData_dot',
                    groupId: polygonId,
                    itemId: key,
                });

                chartDataObj.data.push({
                    axis: key,
                    value: fieldRegistry[key][radarItemData.id] || 0,
                    categoryValue: radarItemData[contentSettings.function],
                    customId,
                    parentId: chartDataObj.id,
                    parentLabel: chartDataObj.label,
                });
            }

            return chartDataObj;
        });

        return contentSettings.order
            ? HelperService.mapOrder(formattedRadarData, contentSettings.order, 'id')
            : formattedRadarData;
    };

    const formatDataHandler = () => {
        const { items, count, level } = initChartData.chartData;

        const formattedRadarData
            = dataSettings.facts.length === 1 && (!dataSettings.groupBy && !dataSettings.secondGroupBy) && level <= 2
                ? oneDimension(items, count, initChartData.chartData[contentSettings.function])
                : twoDimension(items, count);

        const filterLabels = formattedRadarData
            .map(({ id, label, color = "#000000", customId, count }) => ({
                id,
                color,
                label,
                customId,
                count,
            }))
            .reduce(
                (acc: [], { id, ...rest }: { id: string }) =>
                    acc.some(({ id: accLable }) => id === accLable)
                        ? acc
                        : acc.concat({ id, ...rest }),
                [],
            );

        return {
            filterLabels: GadgetService.mapOrder(filterLabels, contentSettings.order, 'id'),
            data: {
                data: formattedRadarData,
                allAxis: formattedRadarData[0].data.map(({ axis }) => axis),
                count,
                maxValue: maxItemCount,
            },
        };
    };

    useEffect(() => {
        setFormatData(formatDataHandler());
    }, [ initChartData.gadgetData, initChartData.chartData ]);

    const changeRadarColor = (
        id: string,
        color: string,
        onChangeColorCallback: (data: any, isChanged: boolean) => void,
    ) => {
        const isChanged = GadgetService.getSavedColorIndex(id, initChartData.gadgetData.colors) > -1;
        const colorObject = {
            key: id,
            value: color,
        };

        onChangeRadarColorTimeout({
            callback: () => {
                onChangeColorCallback(colorObject, isChanged);
            },
        });
    };

    const changeLabel = (
        id: string,
        type: string,
        value: string | null,
        onChangeAxisLabelCallback: (labelRes: any, isChanged: any) => void,
    ) => {
        const formatLabelId = id.toString().toLowerCase();

        const axisObject = {
            key: formatLabelId,
            type,
            value,
        };

        const isChanged = initChartData.gadgetData.labels.findIndex((labelData: TLabelData) => labelData.key === formatLabelId) > -1;

        onChangeAxisLabelCallback(axisObject, isChanged);
    };

    return {
        gadgetData: initChartData.gadgetData,
        chartData: initChartData.chartData,
        changeRadarColor,
        ...formatData,
        changeLabel,
    };
};
