import React from 'react';
import PropTypes from 'prop-types';
import { TooltipWrapper } from '/components';
import { SortableElement } from 'react-sortable-hoc';
import { Badge } from 'reactstrap';
import { DragHandle } from '/scenes/VocFeedback/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SortableItem = SortableElement(props => {
    return (
        <li className={ props.classList }
            onClick={ () => props.onSelectTouchPoint(props.touchpoint) }>
            <div className={ 'noselect' }>
                <DragHandle className={ 'touchpoint-list-drag-handle' }/>
                <TooltipWrapper value={ props.touchpoint.name }>
                    <span className='wrap'>{ props.touchpoint.name }</span>
                </TooltipWrapper>
            </div>
            <Badge
                color={ 'danger' }
                pill
                className='manage_projects__list-icon pointer'
                onClick={ event => {
                    const message = 'Are you sure to delete this Touchpoint?';
                    const confirm = window.confirm(message);

                    if (confirm) {
                        event.stopPropagation();
                        props.deleteTouchPoint(props.touchpoint);
                    }
                } }>
                <FontAwesomeIcon className="delete-icon" icon='times'/>
            </Badge>
        </li>
    );
});

SortableItem.propTypes = {
    children: PropTypes.node,
    classList: PropTypes.string,
    clickHandler: PropTypes.func,
    onSelectTouchPoint: PropTypes.func,
    touchpoint: PropTypes.object,
};

SortableItem.defaultProps = {};
