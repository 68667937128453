import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TooltipWrapper } from '/components';

export const AudioData = ({ name, audioFile, onRemoveFile, dirty, getAudioFile, isServerFile }) => {
    useEffect(() => {
        isServerFile && getAudioFile();
    }, [ isServerFile ]);

    return (
        <>
            <div className='voc-form-dropzone__uploaded'>
                <audio src={ audioFile || '' } controls preload='metadata' />

                <div className='dropzone__uploaded__icon' onClick={ onRemoveFile }>
                    <FontAwesomeIcon
                        className='question__controls-icon'
                        icon='times'
                    />
                </div>
            </div>

            <span className='voc-form-dropzone__text'>
                {
                    dirty
                    && <b>New file: </b>
                }
                <TooltipWrapper value={ name }>
                    <a
                        className='voc-form-dropzone__name'
                        href={ audioFile }
                        download
                    >
                        { name }
                    </a>
                </TooltipWrapper>
            </span>
        </>
    );
};
