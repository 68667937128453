import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { autobind } from 'core-decorators';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageRequests, HelperService } from '/services';
import { VocCleaupHOC } from '/components';
import {
    getExportQueueList,
    addExportQueue,
    addNewExportQueue,
    getNewExportersList,
    updateFilters,
    cleanupPagination,
    cleanup,
    downloadExportFile,
} from './modules/exportQueue.index';
import { tableDataSelector, tableSettingsSelector, filtersSelector } from './modules/exportQueue.selectors';
import { availableUnitsId } from '/scenes/VocFeedback/modules/VocFeedback.selectors';
import { isLoadedPermissionSelector, mapExportersToSelectModel } from '../../modules/export.selector';
import { AddToQueueModal } from './components/AddToQueueModal/';
import { ExportQueueTable } from './components/ExportQueueTable/';

const mapStateToProps = state => ({
    tableData: tableDataSelector(state),
    tableState: tableSettingsSelector(state),
    filters: filtersSelector(state),
    exporters: mapExportersToSelectModel(state),
    availableUnitsId: availableUnitsId(state),
    isLoadedPermission: isLoadedPermissionSelector(state),
});

const mapDispatchToProps = {
    getExportQueueList,
    getNewExportersList,
    cleanupPagination,
    cleanup,
    updateFilters,
    addExportQueue,
    addNewExportQueue,
    downloadExportFile,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class ExportQueue extends Component {
    state = {
        openModal: false,
        showNewQueue: true,
    };

    pageRequests = new PageRequests();

    componentDidMount() {
        this.props.getNewExportersList({
            units:`[${ this.props.availableUnitsId?.toString()}]`,
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isLoadedPermission !== this.props.isLoadedPermission) {
            this.requestTrigger();
        }
    }

    @autobind
    toggleShowNewQueue(type) {
        this.props.cleanupPagination();

        this.setState({
            showNewQueue: type,
        }, () => this.requestTrigger());
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        const model = {
            sort: filters.sort_field
                ? (filters.sort_order === 'desc' ? '' : '-') + filters.sort_field
                : '',
            limit: filters.limit,
            page: filters.page,
            search: filters.search,
            units: `[${this.props.availableUnitsId?.toString()}]`,
        };

        this.pageRequests.cleanup();
        const request = this.state.showNewQueue
            ? this.props.getNewExportersList(model)
            : this.props.getExportQueueList(model);

        this.pageRequests.addRequest(request);
    }

    @autobind
    openModal() {
        this.setState({
            openModal: true,
        });
    }

    @autobind
    closeModal() {
        this.setState({
            openModal: false,
        });
    }

    @autobind
    addExportQueue(params) {
        let body = {};
        const { date_end, date_start, ...rest } = params;
        const date_to = params.date_end ? HelperService.convertToRequestDate(date_end, false) : '2100-01-01 00:00:00';
        const date_from = params.date_start ? HelperService.convertToRequestDate(date_start) : '2000-01-01 00:00:00';

        //todo remove after delete old exporters
        body = params.export_config ? {
            ...rest,
            date_to,
            date_from,
            state: 701,
        } : {
            ...params,
            // Rest API requirements for obtaining data for the entire period
            date_end: date_to,
            date_start: date_from,
            // Rest API can't create export without hard-coded value from client about current state, 701 mean in
            // progress
            state: 701,
        };

        const request = body.export_config
            ? this.props.addNewExportQueue(body)
            : this.props.addExportQueue(body);

        return request.promise
            .then(() => {
                if(
                    body.export_config && this.state.showNewQueue
                    || !body.export_config && !this.state.showNewQueue
                ) {
                    this.requestTrigger();
                }

                this.closeModal();
            });
    }

    @autobind
    handleDownloadFile(downloadData) {
        this.props.downloadExportFile(downloadData);
    }

    render() {
        const {
            tableData,
            tableState,
            exporters,
            filters,
        } = this.props;

        return (
            <DocumentTitle title={ 'Feedback :: Export Queue' }>
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Export Queue</span>
                        <div className={ 'panel__header-buttons' }>
                            <Button className={ 'panel__header-button' }
                                outline
                                color={ 'white' }
                                onClick={ this.openModal }>
                                <FontAwesomeIcon icon={ 'plus' } className={ 'color-green' } />
                                <span> Add to Queue</span>
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ExportQueueTable
                            filters={ filters }
                            data={ tableData }
                            tableState={ tableState }
                            updateFilters={ this.props.updateFilters }
                            requestTrigger={ this.requestTrigger }
                            toggleShowNewQueue={ this.toggleShowNewQueue }
                            showNewQueue={ this.state.showNewQueue }
                            onDownloadFile={ this.handleDownloadFile }
                        />
                        <AddToQueueModal
                            show={ this.state.openModal }
                            closeModal={ this.closeModal }
                            exporters={ exporters }
                            submitHandler={ this.addExportQueue }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}

ExportQueue.propTypes = {};
