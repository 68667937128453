import { sockets } from '/services';
import {
    getTexts,
    getLemmata,
    getLemmataForCoOccurrence,
    getLemmataForCorrelation,
    getLemmataForCloud,
    sockedWithError,
    GET_LEMMATA_REQUEST,
    GET_LEMMATA_CLOUD_REQUEST,
    GET_TEXTS_REQUEST,
    GET_COOCCURRENCE_REQUEST,
    GET_CORRELATION_REQUEST,
    updatePaginationFilters,
} from './query.modules';
import {
    queryIdSelector,
    textFiltersQuerySelector,
    lemmataFiltersQuerySelector,
    lemmataColoudFiltersQuerySelector,
    requestBodyQuerySelector,
    requestQueryPercentageSelector,
    socketDataSelector,
} from './query.selectors';
import { _getSocketData as topicSetSocketDataSelector } from '/modules/topicSetModules/topicSet.selectors';
import {
    GET_TOPICS_REQUEST,
    GET_GRAPHS_REQUEST,
    getGraphs,
    getTopics,
    aiSockedWithError,
} from '/modules/topicSetModules/topicSet.modules';
import { showToast } from '/components';

const GET_LEMMATA_INSIGHT = 'getLemmata';
const GET_TEXTS_INSIGHT = 'getTexts';
const GET_CORRELATION_INSIGHT = 'getCorrelation';
const GET_COOCCURRENCE_INSIGHT = 'getCooccurrence';

const requestMethodsForInsight = {
    [GET_LEMMATA_INSIGHT]: getLemmata,
    [GET_TEXTS_INSIGHT]: getTexts,
    [GET_CORRELATION_INSIGHT]: getLemmataForCorrelation,
    [GET_COOCCURRENCE_INSIGHT]: getLemmataForCoOccurrence,
};

const requestMethods = {
    GET_TEXTS_REQUEST: getTexts,
    GET_LEMMATA_REQUEST: getLemmata,
    GET_LEMMATA_CLOUD_REQUEST: getLemmataForCloud,
    GET_CORRELATION_REQUEST: getLemmataForCorrelation,
    GET_COOCCURRENCE_REQUEST: getLemmataForCoOccurrence,
    GET_TOPICS_REQUEST: getTopics,
    GET_GRAPHS_REQUEST: getGraphs,
    ...requestMethodsForInsight,
};

function socketCallback(updatedQuery, store) {
    const state = store.getState();

    const { socketQueryId: socketQueryIds, actionName } = socketDataSelector(state);
    const topicSetSocketData = topicSetSocketDataSelector(state);
    let mineAiActionName = actionName
        ? actionName
        : topicSetSocketData?.actionName;

    const isInsightNarrator = state.VocMine.query.actionName === 'INSIGHT_NARRATOR';

    const isTopicSet = !!topicSetSocketData.id;

    const queryId = queryIdSelector(state);

    const socketQueryId = isInsightNarrator && socketQueryIds
        ? socketQueryIds?.find(id => id === updatedQuery.id)
        : socketQueryIds;

    if(isInsightNarrator) {
        mineAiActionName = socketQueryId?.split('FullData')[0];
    }

    const chartTypeRequest = requestMethods[mineAiActionName];

    // може бути масив socketQueryId   isInsightNarrator && socketQueryId === updatedQuery.id
    // todo розділити socketQueryId: socketQueryIds на id як у topicSetSocketData
    if (socketQueryId === updatedQuery.id || topicSetSocketData.id === updatedQuery.id) {
        if (updatedQuery.error) {
            isInsightNarrator
            && store.dispatch(showToast({ text: `${mineAiActionName}: ${updatedQuery.error}`, type: 'error' }));

            return socketQueryId === updatedQuery.id
                ? store.dispatch(sockedWithError(mineAiActionName, queryId, updatedQuery.error))
                : store.dispatch(aiSockedWithError(mineAiActionName, queryId, updatedQuery.error));
        }

        let requestData;
        const requestBody = {
            body: {
                ...requestBodyQuerySelector(state),
            },
        };

        switch (mineAiActionName) {
            case GET_TEXTS_INSIGHT:
            case GET_TEXTS_REQUEST: {
                requestData = {
                    ...textFiltersQuerySelector(state),
                    requestBody,
                };
                break;
            }

            case GET_LEMMATA_INSIGHT:
            case GET_LEMMATA_REQUEST: {
                requestData = {
                    ...lemmataFiltersQuerySelector(state),
                    requestBody,
                };
                break;
            }

            case GET_CORRELATION_INSIGHT:
            case GET_COOCCURRENCE_INSIGHT:
            case GET_CORRELATION_REQUEST:
            case GET_COOCCURRENCE_REQUEST: {
                requestData = {
                    requestBody: {
                        body: {
                            ...requestBody.body,
                            ...requestQueryPercentageSelector(state),
                        },
                    },
                };
                break;
            }

            case GET_LEMMATA_CLOUD_REQUEST: {
                requestData = {
                    ...lemmataColoudFiltersQuerySelector(state),
                    requestBody,
                };
                break;
            }

            case GET_TOPICS_REQUEST:
            case GET_GRAPHS_REQUEST: {
                requestData = {
                    ...topicSetSocketData.requestData,
                };
                break;
            }

            default:
                return;
        }

        const insightSocketId = isInsightNarrator && socketQueryId === updatedQuery.id
            ? socketQueryId
            : null;

        requestMethod(store, chartTypeRequest, queryId, requestData, isTopicSet, insightSocketId )
            .promise
            .then(response => {
                /*
                     * this logic is for two graphs ( Text, Bar )
                     * requested a page and sent new filters at the same time
                     * find out the new last page and re-request
                */
                if (
                    response.id
                        || (![ GET_TEXTS_REQUEST, GET_LEMMATA_REQUEST ].includes(mineAiActionName))
                        || (response?.texts?.length || response?.lemmata?.length)
                ) return false;

                if (requestData.page !== 1) {
                    const typeRequest = response.texts ? 'texts' : 'lemmata';
                    const responseCount = response[`${typeRequest}Count`];
                    const lastPage = Math.ceil(responseCount / requestData.limit);

                    requestData.page = lastPage;
                    store.dispatch(updatePaginationFilters({ page: lastPage, name: `${typeRequest}Data` }));
                }

                requestMethod(store, chartTypeRequest, queryId, requestData, insightSocketId);
            });
    }
}

const requestMethod = (store, chartTypeRequest, queryId, requestData, isTopicSet, insightSocketId) => {
    const payload = isTopicSet
        ? {
            queryId,
            scopeType: 'mine',
            ...requestData,
        }
        : queryId;

    return store.dispatch(chartTypeRequest(payload, requestData, { insightSocketId }));
};

export function sockedQueryUpdateListener() {
    return sockets.on('mine_cache_rebuilt', socketCallback);
}
