import React from 'react';

import './IframeComponent.scss';

export const IframeComponent = ({ url, noUrlMessage }) => {
    return (
        <div className='voc-iframe__wrapper'>
            {
                url
                    ? <iframe
                        className='voc-iframe'
                        frameBorder="0"
                        src={ url }/>
                    : <p className='voc-iframe__no-config-message'>{ noUrlMessage }</p>
            }
        </div>
    );
};
