import { useEffect, useState } from 'react';
import { HelperService } from "/services";

export const useSortFilter = initialData => {
    const [ data, setData ] = useState(initialData);
    const [ sortOptions, setSortOptions ] = useState({
        sortType: null,
        key: '',
    });
    const [ filterOptions, setFilterOptions ] = useState([]);
    const [ searchOptions, setSearchOptions ] = useState({
        key: '',
        search: '',
    });

    const onFilter = inputData => {
        let filteredList = [ ...inputData ];

        if(filterOptions.length) {
            const filterValues = filterOptions.map(filterItem => filterItem.value);

            filteredList = filteredList.filter(sortedListItem => filterValues.includes(sortedListItem.permission));
        }

        return filteredList;
    };

    const onSearch = inputData => {
        if(!searchOptions.search) {
            return inputData;
        }

        const newInputData = [ ...inputData ];

        return newInputData.filter(inputValue => inputValue[searchOptions.key].includes(searchOptions.search));
    };

    const changeSortOptions = key => {
        const newSortData = {
            ...sortOptions,
            key,
        };

        if(!newSortData.sortType) {
            newSortData.sortType = 'asc';
        } else if(newSortData.sortType === 'asc') {
            newSortData.sortType = 'desc';
        } else {
            newSortData.sortType = null;
        }

        return newSortData;
    };

    const onProcess = options => {
        switch (options.type) {
            case 'sort':
                setSortOptions(changeSortOptions(options.value));
                break;
            case 'filter':
                setFilterOptions(options.value);
                break;
            case 'search':
                setSearchOptions(options.value);
                break;

            default:
                setFilterOptions(options.value);
        }
    };

    const clearAll = () => {
        setSortOptions({});
        setFilterOptions([]);
    };

    useEffect(() => {
        const sortedData = sortOptions.key
            ? HelperService.sortArrayWithObjects([ ...initialData ], sortOptions.key, sortOptions.sortType)
            : initialData;
        const filteredData = onFilter(sortedData);
        const searchedData = onSearch(filteredData);

        setData(searchedData);
    }, [ sortOptions, filterOptions, initialData, searchOptions ]);

    return {
        data,
        filterOptions,
        sortOptions,
        onProcess,
        clearAll,
        setData,
    };
};
