import React, { useState } from 'react';
import { Button, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocSelect, VocModal } from '/components';

export const MoveTopicToModal = ({
    modalIsOpen,
    onClose,
    onSubmit,
    categories,
}) => {
    const [ value, setValue ] = useState(null);

    const onClosedHandler = () => {
        setValue(null);
    };

    const handleSubmit = () => {
        onSubmit(value.value);

        onClose();
    };

    const getModalBody = () => {
        return (
            <div>
                <Label>Category</Label>
                <VocSelect
                    portal
                    isHorizontal
                    isCreatable
                    placeholder='Select category'
                    options={ categories }
                    value={ value }
                    onChange={ setValue }
                />
            </div>
        );
    };

    const getFooter = () => {
        return (
            <div>
                <Button outline onClick={ onClose } color='white'>
                    Cancel
                </Button>
                <Button
                    disabled={ !value }
                    onClick={ handleSubmit }
                    color='primary'
                >
                    <FontAwesomeIcon icon='check'/>
                    <span> Save</span>
                </Button>
            </div>
        );
    };

    return (
        <VocModal
            toggle={ onClose }
            onClosed={ onClosedHandler }
            header={ 'Move topics' }
            modalClassName='modal-scrollable'
            isOpen={ modalIsOpen }
            size='md'
            footer={ getFooter() }
        >
            { getModalBody() }
        </VocModal>
    );
};
