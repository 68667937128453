import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import {
    VocFormTextField,
    VocFormSelectField,
    VocFormPasswordField,
    VocFormCheckbox,
    VocFormDatePicker,
} from '/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';

import { lengthHandler, MAX_DELIMITER_LENGTH, MAX_TITLE_LENGTH } from '../../../../modules/selectors';
import './UploadSocialNetworkForm.scss';

const Form = ({
    handleSubmit,
    languages,
    submitting,
    pristine,
    getQuintlyHeaders,
    onSubmit,
    formValues,
    parserOptions,
    loading,
    disabledSubmit,
    initialValues,
    dirtyStatusConfirm,
    isDirty,
}) => {
    const { t } = useTranslation();
    const isDirtyQqlField = Boolean(formValues?.qql);

    useEffect(() => {
        dirtyStatusConfirm({ dirty: isDirtyQqlField });
    }, [ isDirtyQqlField ]);

    const parserName = formValues?.parser?.label || '';
    const handleParserHeaders = ({ password, login, profileId, qql }) => {
        const qqlOnBase64 = btoa(qql);

        getQuintlyHeaders({
            password,
            login,
            profileId,
            qql: qqlOnBase64,
        });
    };

    const isDisableParseButton = () => {
        if (!formValues) {
            return true;
        }
        const { profileId, password, login, qql } = formValues;

        return !qql || !profileId || !password || !login;
    };

    const isDisableSubmitButton = () => {
        const { title, parser } = formValues;

        return disabledSubmit || !title || !parser || isDisableParseButton();
    };

    const autoSyncLabel = <span>
        <FontAwesomeIcon
            icon='sync'
            className='parser-form__cvg_icon'
        />
        { t('autoSync') }
    </span>;

    return (
        <>
            <Prompt
                when={ isDirty }
                message={ `${ t('warningText.unsavedChanges') }${ t('warningText.areYouWantContinue') }` }
            />
            <form onSubmit={ handleSubmit } className='parser-form__group'>
                <Row>
                    <Col md={ 8 }>
                        <FormGroup row>
                            <Label for='title' md={ 12 }>
                                { t('field.title') }
                                &nbsp;
                                <span className='star'>*</span>
                            </Label>
                            <Col md={ 12 }>
                                <Field
                                    type={ 'text' }
                                    placeholder={ t('placeholders.enterTitle') }
                                    name={ 'title' }
                                    component={ VocFormTextField }
                                    normalize={ value => lengthHandler(value, MAX_TITLE_LENGTH) }
                                    required
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={ 4 }>
                        <FormGroup row>
                            <Label for='language' md={ 12 }>
                                { t('field.language') }
                                &nbsp;
                                <span className='star'>*</span>
                            </Label>
                            <Col md={ 12 }>
                                <Field
                                    placeholder={ t('placeholders.selectLang') }
                                    name={ 'language' }
                                    options={ languages }
                                    component={ VocFormSelectField }
                                    required
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={ 8 }>
                        <FormGroup row>
                            <Label for='parser' md={ 12 }>
                                { t('parserGrabber') }
                                &nbsp;
                                <span className='star'>*</span>
                            </Label>
                            <Col md={ 12 }>
                                <Field
                                    placeholder={ t('placeholders.selectParser') }
                                    name={ 'parser' }
                                    options={ parserOptions }
                                    component={ VocFormSelectField }
                                    required
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={ 4 }>
                        <FormGroup row>
                            <Field
                                name={ 'status' }
                                required
                                label={ autoSyncLabel }
                                type={ 'checkbox' }
                                normalize={ value => value ? '1' : '0' }
                                format={ value => value === '1' }
                                className={ 'parser-form__checkbox' }
                                component={ VocFormCheckbox }
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={ 8 }>
                        <FormGroup row>
                            <Label for='choiceDelimiter' md={ 12 }>
                                { t('placeholders.choiceDelimiter') }
                                &nbsp;
                                <span className='star'>*</span>
                            </Label>
                            <Col md={ 12 }>
                                <Field
                                    placeholder={ t('placeholders.choiceDelimiter') }
                                    name={ 'choiceDelimiter' }
                                    component={ VocFormTextField }
                                    normalize={ value => lengthHandler(value, MAX_DELIMITER_LENGTH) }
                                    required
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={ 4 }>
                        <FormGroup row>
                            <Label for='startSyncFrom' md={ 12 }>
                                { t('startDateFrom') }
                            </Label>
                            <Col md={ 12 }>
                                <Field
                                    name={ 'startSyncFrom' }
                                    component={ VocFormDatePicker }
                                    wrapClassName="voc-datepicker"
                                    className="app-form-control form-control"
                                    defaultDate={ initialValues.startSyncFrom }
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

                <div className='parser_form__container'>
                    <FormGroup row>
                        <Label for='login' md={ 12 }>
                            { `${ parserName } ${ t('usernameClientId') }` }
                            &nbsp;
                            <span className='star'>*</span>
                        </Label>
                        <Col md={ 12 }>
                            <Field
                                type={ 'text' }
                                placeholder={ t('enterUsernameClientId') }
                                name={ 'login' }
                                component={ VocFormTextField }
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for='password' md={ 12 }>
                            { `${ parserName } ${ t('passwordSecret') }` }
                            &nbsp;
                            <span className='star'>*</span>
                        </Label>
                        <Col md={ 12 }>
                            <Field
                                placeholder={ t('placeholders.enterPasswordSecret') }
                                name={ 'password' }
                                component={ VocFormPasswordField }
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for='profileId' md={ 12 }>
                            { `${ parserName } ${ t('profileId') }` }
                            &nbsp;
                            <span className='star'>*</span>
                        </Label>
                        <Col md={ 12 }>
                            <Field
                                type={ 'text' }
                                placeholder={ t('placeholders.enterProfileId') }
                                name={ 'profileId' }
                                component={ VocFormTextField }
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for='qql' md={ 12 }>
                            { `${ parserName } ${ t('queryTo') }` }
                            &nbsp;
                            <span className='star'>*</span>
                        </Label>
                        <Col md={ 12 }>
                            <Field
                                className='parser-form__textarea'
                                type='textarea'
                                placeholder={ t('placeholders.enterQql') }
                                name={ 'qql' }
                                component={ VocFormTextField }
                                required
                            />
                        </Col>
                    </FormGroup>
                    <div className='parser-form__buttons-container'>
                        <Button
                            color='primary'
                            onClick={ handleSubmit(handleParserHeaders) }
                            disabled={ loading || isDisableParseButton() }
                        >
                            <FontAwesomeIcon icon='check' />
                            &nbsp;
                            { t('check') }
                        </Button>
                    </div>
                </div>

                <div className='parser-form__buttons-container'>
                    <Button
                        color='primary'
                        onClick={ handleSubmit(onSubmit) }
                        disabled={ loading || pristine || submitting || isDisableSubmitButton() }
                    >
                        <FontAwesomeIcon icon='save' />
                        &nbsp;
                        { t('submit') }
                    </Button>
                </div>
            </form>
        </>
    );
};


export const UploadSocialNetworkForm = reduxForm({
    form: 'ParserForm',
    enableReinitialize: true,
})(Form);
