import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { autobind } from 'core-decorators';
import { withTranslation } from 'react-i18next';

import { VocTableGoToPage } from '../VocTableGoToPage';
import { VocTablePagination } from '../VocTablePagination/';

import './VocTableFooter.scss';

@withTranslation()
export class VocTableFooter extends Component {
    static defaultLimitRange = [
        10, 15, 30, 50,
    ];

    state = {
        openLimitDropDown: false,
    };

    @autobind
    togglePerPage() {
        this.setState(prevState => ({
            openLimitDropDown: !prevState.openLimitDropDown,
        }));

        setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0);
    }

    @autobind
    onLimitItemClick(event) {
        const { onLimitChange } = this.props;
        const limit = Number(event.target.innerText);

        onLimitChange(limit);
    }

    mapLimitItems() {
        const limitRange = VocTableFooter.defaultLimitRange;

        return limitRange.map((limitItem, index) => <DropdownItem onClick={ this.onLimitItemClick }
            key={ index }>{ limitItem }</DropdownItem>);
    }

    getMetaInformation() {
        const { page, total, limit, t } = this.props;
        const start = page === 1 ? 1 : ((page - 1) * limit + 1);
        const nextLimitRange = page === 1 ? limit : page * limit;
        const to = total < nextLimitRange ? total : nextLimitRange;

        return (
            <p className={ 'voc-table__total-row' }>
                { t('pagination.totalRow', { start, to, total }) }
            </p>
        );
    }

    render() {
        const { openLimitDropDown } = this.state;
        const { limit, page, total, onPageChange, goToPage, withoutPagination } = this.props;

        return withoutPagination ? (
            <div className={ 'voc-table__footer' }>
                <div className={ 'voc-table__pagination-box' } id={ 'paginationBox' }/>
                { this.getMetaInformation() }
            </div>
        ) : (
            <div className={ 'voc-table__footer' }>
                <Dropdown
                    isOpen={ openLimitDropDown }
                    toggle={ this.togglePerPage }>
                    <DropdownToggle
                        color={ 'white' }
                        outline
                        caret>
                        { limit }
                    </DropdownToggle>
                    <DropdownMenu>
                        { this.mapLimitItems() }
                    </DropdownMenu>
                </Dropdown>
                <div className={ 'voc-table__pagination-box' } id={ 'paginationBox' }>
                    <VocTablePagination
                        page={ page }
                        limit={ limit }
                        total={ total }
                        onPageChange={ onPageChange }
                    />
                    {
                        (goToPage.show && total / limit > 1)
                        && <VocTableGoToPage
                            page={ page }
                            limit={ limit }
                            total={ total }
                            onPageChange={ onPageChange }
                            closeOnChange={ goToPage.closeOnChange }
                        />
                    }
                </div>
                { this.getMetaInformation() }
            </div>
        );
    }
}

VocTableFooter.propTypes = {
    limit: PropTypes.number,
    onLimitChange: PropTypes.func,
    onPageChange: PropTypes.func,
    total: PropTypes.number,
    page: PropTypes.number,
};

VocTableFooter.defaultProps = {
    limit: 10,
    showGoToPage: true,
};
