import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { VocTableV2, TableRightsColumn, VocRightsHOC } from '/components';
import { VirtualSourcesTableActions } from '../VirtualSourcesTableActions';
import { VirtualSourcesTableCiSync } from '../VirtualSourcesTableCiSync';

@withTranslation()
class VirtualSourcesTable extends Component {
    constructor(props) {
        super(props);
    }

    getCiSync() {
        const { t } = this.props;

        return {
            field: 'ciSyncRequired',
            title: t('field.ciSync'),
            formatter: this.ciSyncFormatter,
            style: {
                width: '85px',
            },
            sort: true,
        };
    }

    getSettings() {
        const { t, isVocCIIntegration } = this.props;

        return [
            {
                field: 'name',
                title: t('field.virtualSource'),
                formatter: 'conditionTooltip',
                sort: true,
                style: {
                    width: '235px',
                },
            },
            {
                field: 'created',
                title: t('field.createdDate'),
                formatter: 'date',
                sort: true,
            },
            {
                field: 'updated',
                title: t('field.lastUpdated'),
                formatter: 'date',
                sort: true,
            },
            {
                field: 'sources',
                title: t('field.usedSources'),
                formatter: 'conditionTooltip',
                style: {
                    width: '210px',
                },
            },
            ...isVocCIIntegration
                ? [ this.getCiSync() ]
                : [],
            {
                field: 'shared',
                title: t('field.role'),
                formatter: this.sharedFormatter,
                style: {
                    textAlign: 'center',
                    width: '70px',
                },
            },
            {
                field: 'actions',
                title: t('field.actions'),
                formatter: this.tableActionsFormatter,
                style: {
                    width: '205px',
                },
            },
        ];
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.tableState.loading !== this.props.tableState.loading
            || nextProps.shares.loaded !== this.props.shares.loaded
            || nextProps.tableState.descriptionSending !== this.props.tableState.descriptionSending;
    }

    @autobind
    tableActionsFormatter(source) {
        const {
            showApplianceModal,
            showExportFilterModal,
            getVirtualRelations,
            deleteVirtualSource,
            onCopyButtonClick,
            onInfoButtonClick,
            showConfirm,
            virtualToDelete,
            openInviteModal,
            requestTrigger,
        } = this.props;

        return (
            <VirtualSourcesTableActions
                showApplianceModal={ showApplianceModal }
                showExportFilterModal={ showExportFilterModal }
                onInfoButtonClick={ onInfoButtonClick }
                onCopyButtonClick={ onCopyButtonClick }
                getVirtualRelations={ getVirtualRelations }
                showConfirm={ showConfirm }
                deleteVirtualSource={ deleteVirtualSource }
                virtualToDelete={ virtualToDelete }
                openInviteModal={ openInviteModal }
                source={ source }
                requestTrigger={ requestTrigger }
            />
        );
    }

    @autobind
    sharedFormatter(cell) {
        const { getSharedRights, shares } = this.props;

        return (
            <TableRightsColumn
                { ...cell }
                getShares={ id => getSharedRights(id, 'virtual-source') }
                shares={ shares }
            />
        );
    }

    @autobind
    ciSyncFormatter(source) {
        return <VirtualSourcesTableCiSync source={ source } />;
    }

    render() {
        const {
            tableState: {
                dataTotalSize: total,
                loading,
            },
            options,
            filters,
            data,
        } = this.props;

        return (
            <VocTableV2
                filters={ filters }
                dataSettings={ this.getSettings() }
                options={ options }
                total={ total }
                data={ data }
                loading={ loading }
            />
        );
    }
}

export default VocRightsHOC({
    type: 'virtual-source',
})(VirtualSourcesTable);

VirtualSourcesTable.propTypes = {
    filters: PropTypes.object,
    deleteVirtualSource: PropTypes.func,
    showConfirm: PropTypes.func,
    data: PropTypes.array,
    showApplianceModal: PropTypes.func,
    tableState: PropTypes.shape({
        dataTotalSize: PropTypes.number,
        loading: PropTypes.bool,
    }),
};
