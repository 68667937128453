import { createSelector } from 'reselect';

const _getTimeSlotsResults = state => state.VocFeedback.globalSettings.timeSlots;

export const _getGlobalTimeSlotType = state => state.VocFeedback.globalSettings.common.timeSlotType;

export const TimeSlotsDataSelector = createSelector(
    [ _getTimeSlotsResults, _getGlobalTimeSlotType ],
    (data, timeSlotValue) => {
        return {
            ...data,
            timeSlotIsRegular: timeSlotValue === 'regular',
        };
    });
