import React, { useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import SplitPane, { Pane } from 'split-pane-react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { useNpsBubbleChart } from '/visual/scenes/Dashboard/components';
import { sendChartColor, sendLabel, setContentSettings } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import {
    EmptyGadget, ChartLabelsSortableList,
    ChartColorPicker, FooterInfo,
} from '/visual/scenes/Dashboard/components/Gadget/components';
import { PopoverChartTooltip, SplitPaneSash } from '/components';
import { useSplitPane } from '/services';
import { BubbleChartD3 } from './components';
import { LabelModal } from '../LabelModal';
import {
    IChartData, IColorPickerData, TLabelPopoverData,
    changeColorHandlerType, TApplyLabelModalData, ELabelType,
} from '../../models';

import commonStyles from '../BaseChart/style.module.scss';

export const NpsBubbleChart = ({
    chartGadgetData,
    onEdit,
}: {
    chartGadgetData: IChartData,
    onEdit: () => void,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { gadgetData } = chartGadgetData;

    const {
        bubbles,
        filterLabels,
        records,
        axisLabels,
        errorMessage,
        changeLabel,
        changeItemColor,
    } = useNpsBubbleChart(chartGadgetData);

    const {
        sizes,
        onChange,
        onDragEnd,
    } = useSplitPane({
        initSizes: gadgetData.contentSettings?.labelsSize,
        sizeChanged: newSizes => dispatch(setContentSettings(gadgetData.id, {
            ...gadgetData.contentSettings,
            labelsSize: newSizes,
        })),
    });

    const tooltipRef = createRef<HTMLDivElement>();

    const [ highlightedLabel, setHighlightedLabel ] = useState<string | null>(null);
    const [ colorPickerData, setColorPickerData ] = useState<IColorPickerData | null>(null);
    const [ tickData, setTickData ] = useState<TLabelPopoverData | null>(null);

    const changeColorHandler = ({ elementId = '', colorHex }: changeColorHandlerType) => {
        changeItemColor(
            elementId,
            colorHex,
            (colorData, isChanged) => {
                dispatch(sendChartColor(gadgetData.id, colorData, isChanged));
            },
        );
    };

    const onApplyLabelModal = (labelData: TApplyLabelModalData) => {
        changeLabel(
            labelData.id,
            labelData.type,
            labelData.value,
            (labelRes, isChanged) => {
                dispatch(sendLabel(gadgetData.id, labelRes, isChanged));
            });
    };

    const onApplyLabelModalDataHandler = (inputValue: TLabelPopoverData['value']) => {
        if (tickData) {
            onApplyLabelModal({
                id: tickData.id,
                value: inputValue,
                type: tickData.type,
            });
        }
    };

    if (errorMessage) {
        return (
            <EmptyGadget
                errorMessage={ errorMessage }
                onEdit={ onEdit }
            />
        );
    }

    return (
        <div className={ commonStyles.chartWrapper }>
            <SplitPane
                allowResize={ !isMobile }
                performanceMode
                resizerSize={ 1 }
                split='horizontal'
                sizes={ sizes }
                onChange={ onChange }
                onDragEnd={ onDragEnd }
                sashRender={ () => <SplitPaneSash split='horizontal'/> }
            >
                <Pane className={ commonStyles.graphSplitPanePadding } minSize='35%'>
                    <BubbleChartD3
                        bubbles={ bubbles }
                        showScore={ gadgetData.contentSettings.annotateScore }
                        longLabel={ gadgetData.contentSettings.longLabel }
                        percentage={ false }
                        axisLabels={ axisLabels }
                        highlightedLabel={ highlightedLabel }
                        gadgetId={ gadgetData.id }
                        gadgetFunction={ gadgetData.contentSettings.function }
                        chartType={ gadgetData.contentSettings.chartType }
                        toolTipRef={ tooltipRef }
                        onApplyLabelModal={ onApplyLabelModal }
                        setColorPickerData={ setColorPickerData }
                    />
                </Pane>

                <Pane minSize='20%' maxSize='50%' className={ commonStyles.labelsSplitPanePadding }>
                    <div className={ commonStyles.labelsContainer }>
                        <ChartLabelsSortableList
                            dragDisable={ true }
                            labels={ filterLabels }
                            longLabel={ gadgetData.contentSettings.longLabel }
                            gadgetId={ gadgetData.id }
                            chartType={ gadgetData.contentSettings.chartType }
                            updateSelectedLabel={ setHighlightedLabel }
                            setColorPickerData={ setColorPickerData }
                            onApplyLabelModal={ onApplyLabelModal }
                            tickData={ tickData }
                        />
                    </div>
                </Pane>
            </SplitPane>

            <FooterInfo records={ records } lastHealthCheckTime={ gadgetData.lastHealthCheckTime } />

            <ChartColorPicker
                chartType={ gadgetData.contentSettings.chartType }
                gadgetId={ gadgetData.id }
                colorPickerData={ colorPickerData }
                setColorPickerData={ setColorPickerData }
                changeColorCallback={ changeColorHandler }
            />

            <PopoverChartTooltip ref={ tooltipRef } />

            <LabelModal
                labelModalData={ tickData }
                labelType={ ELabelType.TICK }
                setLabelModalData={ setTickData }
                onApplyLabelModalData={ onApplyLabelModalDataHandler }
            />
        </div>
    );
};
