import React, { useMemo, useRef } from "react";
import { useVirtualizer } from '@tanstack/react-virtual';

import { DragAndDropZone } from "../";
import { TableBody, MemoizedTableBody } from "./components";

import styles from './style.module.scss';

export const Table = ({
    getHeaderGroups,
    wordwrapMode,
    table,
    isEditable,
    disableReorder,
    onChangeColumnOrder,
}: any) => {
    const tableContainerRef = useRef(null);

    const columnSizeVars = useMemo(() => {
        const headers = table.getFlatHeaders();
        const colSizes: { [key: string]: number } = {};

        for (let i = 0; i < headers.length; i++) {
            const header = headers[i]!;

            colSizes[`--header-${header.id}-size`] = header.getSize();
            colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
        }
        return colSizes;
    }, [ table.getState().columnSizing ]);


    const getHeader = () => {
        return getHeaderGroups().map(headerGroup => {
            return <div className={ styles.tr } key={ headerGroup.id }>
                {
                    headerGroup.headers.map((column, inx) => (
                        <DragAndDropZone
                            column={ column }
                            visibleColumns={ table.getState().columnOrder }
                            setColumnOrder={ onChangeColumnOrder }
                            key={ column.id }
                            disableReorder={ disableReorder }
                            isEditable={ isEditable }
                            headerGroupLength={ inx + 1 < headerGroup.headers.length }
                        />
                    ))
                }
            </div>;
        });
    };

    const rowVirtualizer = useVirtualizer({
        count: table.getRowModel().rows.length,
        estimateSize: () => 37,
        getScrollElement: () => tableContainerRef.current,
    });

    return (
        <div
            ref={ tableContainerRef }
            className={ styles.table }
            role="table"
            style={{ ...columnSizeVars }}
        >
            <div className={ styles.header }>
                {
                    getHeader()
                }
            </div>

            <div
                className={ styles.rowgroup }
                style={{ minHeight: rowVirtualizer.getTotalSize() }}
            >
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${rowVirtualizer.getVirtualItems()[0]?.start ?? 0}px)`,
                }}>
                    {
                        table.getState().columnSizingInfo.isResizingColumn
                            ? <MemoizedTableBody rowVirtualizer={ rowVirtualizer } table={ table } wordwrapMode={ wordwrapMode }/>
                            : <TableBody rowVirtualizer={ rowVirtualizer } table={ table } wordwrapMode={ wordwrapMode }/>
                    }
                </div>
            </div>
        </div>
    );
};
