import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import { VocModal, RangeDatePicker, VocSelect, SelectAsyncPaginate } from '/components';
import { HelperService } from '/services';
import { InputLoader } from '/feedback/components/InputLoader';
import { SelectComponentOption, SelectComponentValue } from '../../../../components';
import { cleanupSurveysGroupBasic, getAllSurveysGroupBasic } from "../../../../modules/export.index";
import { mapSurveysToModal } from "../../../../modules/export.selector";

import './AddToQueueModal.scss';

const getInitialState = () => ({
    channelList: [],
    startDate: null,
    endDate: null,
    survey: null,
    channel: null,
    pendingRequest: true,
    exporter: null,
    filters: {
        page: 1,
    },
});

const mapStateToProps = state => ({
    surveys: mapSurveysToModal(state),
});

@connect(mapStateToProps, {
    getAllSurveysGroupBasic,
    cleanupSurveysGroupBasic,
})

export class AddToQueueModal extends Component {
    state = getInitialState();

    @autobind
    cleanupModal() {
        this.setState(getInitialState());
    }

    @autobind
    onChangeSurvey(group) {
        if(!group) {
            this.setState({
                survey: null,
                channelList: [],
                channel: null,
                unit: null,
            });
            return;
        }
        this.setState({
            survey: group,
            channelList: group.channels,
            channel: null,
            unit: group.unit,
        });
    }

    @autobind
    onChangeChannel(channel) {
        this.setState({ channel });
    }

    @autobind
    onChangeExporters(exporter) {
        this.setState({ exporter });
    }

    getDateRangeLabels() {
        return {
            start: 'Start date',
            end: 'End date',
        };
    }

    getDateRangeValues() {
        return {
            from: this.state.startDate,
            to: this.state.endDate,
        };
    }

    @autobind
    dateRangeChanged(date) {
        this.setState({
            startDate: date.from,
            endDate: date.to,
        });
    }

    @autobind
    isSubmitDisabled() {
        const { channel, exporter, survey, pendingRequest } = this.state;

        return !(channel && exporter && survey && pendingRequest);
    }

    @autobind
    submitHandler() {
        this.setState({
            pendingRequest: false,
        });

        const {
            endDate: date_end,
            startDate: date_start,
            exporter,
            channel: survey,
            unit,
        } = this.state;

        const getId = exporter.value.split(' ');

        const model = {
            date_end,
            date_start,
            //todo remove after delete old exporters
            ...getId.length >= 2 && getId[1] === 'newExporter'
                ? { export_config: getId[0] }
                : { exporter: exporter.value },
            survey: survey.value,
            unit,
        };

        this.props.submitHandler(model).finally(() => {
            this.setState({
                pendingRequest: true,
            });
        });
    }

    @autobind
    getModalFooter() {
        return (
            <Fragment>
                <Button onClick={ this.props.closeModal } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button onClick={ this.submitHandler }
                    disabled={ this.isSubmitDisabled() }
                    color="primary">
                    <FontAwesomeIcon icon="check"/>
                    <span> Add</span>
                </Button>
            </Fragment>
        );
    }

    @autobind
    selectAsyncPaginateSetPage(e) {
        const {
            filters,
        } = this.state;

        this.setState({
            filters: {
                ...filters,
                page: e.page,
            },
        });
    }

    @autobind
    selectAsyncPaginateGetSurveys({ body }) {
        const { getAllSurveysGroupBasic, cleanupSurveysGroupBasic } = this.props;
        const { filters } = this.state;

        if(filters.search !== body.search) {
            cleanupSurveysGroupBasic();
            getAllSurveysGroupBasic({
                ...body,
                page: 1,
            });
            this.setState({
                filters: {
                    ...body,
                    page: 1,
                },
            });
        } else {
            getAllSurveysGroupBasic(body);
            this.setState({
                filters: body,
            });
        }
    }

    @autobind
    onClose() {
        const { cleanupSurveysGroupBasic } = this.props;

        cleanupSurveysGroupBasic();
        this.setState({
            filters: {
                page: 1,
            },
        });
    }

    @autobind
    getModalBody() {
        const { surveys, exporters } = this.props;

        const { survey, channelList, channel, exporter, filters } = this.state;

        const getSurveyValue = HelperService.getSelectedValue(surveys.results, survey);
        const surveyValue = getSurveyValue ? {
            ...getSurveyValue,
            title: getSurveyValue?.label,
        } : null;
        const channelValue = HelperService.getSelectedValue(channelList, channel);
        const exporterValue = HelperService.getSelectedValue(exporters.results, exporter);

        return (
            <div>
                <Row>
                    <Col className='select-control-label' md={ 3 }>
                        <Label>
                            Survey<span className='star'> *</span>
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <SelectAsyncPaginate
                            setPage={ this.selectAsyncPaginateSetPage }
                            placeholder={ 'Choose survey' }
                            className='vochub-select-control'
                            total={ surveys.total }
                            filters={{
                                ...filters,
                                limit: 15,
                            }}
                            onChange={ this.onChangeSurvey }
                            value={ surveyValue }
                            loading={ surveys.loading }
                            getSources={ this.selectAsyncPaginateGetSurveys }
                            options={ surveys.results }
                            closeMenuOnSelect={ true }
                            onClose={ this.onClose }
                        />
                    </Col>
                </Row>


                <VocSelect
                    label="Channel"
                    isRequired={ true }
                    placeholder="Choose channel"
                    isClearable={ false }
                    isHorizontal={ true }
                    portal={ true }
                    options={ channelList }
                    value={ channelValue }
                    disabled={ channelList.length <= 0 }
                    onChange={ this.onChangeChannel }
                />
                {
                    exporters.loading
                        ? <InputLoader
                            label="Exporter *"
                        />
                        : <VocSelect
                            label="Exporter"
                            isRequired={ true }
                            className={ 'no-margin' }
                            placeholder="Choose exporter"
                            isClearable={ false }
                            portal={ true }
                            isHorizontal={ true }
                            options={ exporters.results }
                            value={ exporterValue }
                            onChange={ this.onChangeExporters }
                            components={{
                                Option: SelectComponentOption,
                                ValueContainer: SelectComponentValue,
                            }}
                        />
                }
                <div className="exportQueue__modal-spacing">
                    <RangeDatePicker
                        dateFormat={ HelperService.datePickerMask }
                        handleChange={ this.dateRangeChanged }
                        labels={ this.getDateRangeLabels() }
                        values={ this.getDateRangeValues() }
                    />
                </div>
            </div>
        );
    }

    render() {
        const {
            show,
            closeModal,
        } = this.props;

        return (
            <VocModal
                isOpen={ show }
                onClosed={ this.cleanupModal }
                toggle={ closeModal }
                header={ 'Add to Queue' }
                footer={ this.getModalFooter() }
                modalClassName='modal-scrollable'
            >
                {
                    this.getModalBody()
                }
            </VocModal>
        );
    }
}

AddToQueueModal.propTypes = {
    show: PropTypes.bool,
    closeModal: PropTypes.func,
    submitHandler: PropTypes.func,
};
