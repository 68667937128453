import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { DropdownMenu, Dropdown, DropdownToggle } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './Navigation.scss';
import { withRouter } from "react-router";

@withRouter
export class Navigation extends Component {
    state = {
        navigationInfo: this.props.navigationInfo.map(navigation => ({
            ...navigation,
            isOpen: !(navigation.items && navigation.items.length > 0),
        })),
    };
    unsubscribe = this.props.history.listen(this.onRouteChange);

    componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidUpdate(nextProps) {
        const nextPropsLinks = nextProps.navigationInfo.map(navigation => navigation.link);
        const prevStateLinks = this.state.navigationInfo.map(navigation => navigation.link);

        if (!prevStateLinks.every(link => nextPropsLinks.includes(link))) {
            this.setState({
                navigationInfo: this.props.navigationInfo.map(navigation => ({
                    ...navigation,
                    isOpen: !(navigation.items && navigation.items.length > 0),
                })),
            });
        }
    }

    @autobind
    onRouteChange() {
        this.setState({
            navigationInfo: this.props.navigationInfo.map(navigation => ({
                ...navigation,
                isOpen: !(navigation.items && navigation.items.length > 0),
            })),
        });
    }

    @autobind
    toggleDropDown(navigation) {
        const target = navigation.title;

        this.setState({
            navigationInfo: this.state.navigationInfo.map(navigation => ({
                ...navigation,
                isOpen: navigation.title === target ? !navigation.isOpen : navigation.isOpen,
            })),
        });
    }

    @autobind
    getItemContent(item) {
        const {
            title,
        } = item;

        return <span className={ 'voc-tabs__list-text' }>{ title }</span>;
    }

    checkMatchLinks(pathname, link) {
        return pathname.endsWith("/")
            ? `${link}/` === pathname
            : link === pathname;
    }

    @autobind
    getNavigationItem(navigation, index) {
        const {
            isReactLink,
            link,
            title,
        } = navigation;

        const isMatchLinks = this.checkMatchLinks(this.props.location.pathname, link);

        return (
            <Fragment key={ index }>
                {
                    isReactLink
                        ? <NavLink
                            strict
                            to={ link }
                            className={ cx('voc-tabs__list-item', {
                                'disabled': isMatchLinks && title !== 'Questions',
                            }) }
                        >
                            { this.getItemContent(navigation) }
                        </NavLink>
                        : <a className={ 'voc-tabs__list-item ' } href={ link }>
                            <span className={ 'voc-tabs__list-text' }>{ title }</span>
                        </a>
                }
            </Fragment>
        );
    }

    @autobind
    getNavigationControl(navigation, key) {
        const {
            items,
            isOpen,
        } = navigation;

        const isMatchLinks = (items && items.length) && items.map(item => item.link).includes(this.props.location.pathname);

        return (
            items && items.length
                ? <div key={ key } className={ `voc-tabs__list-dropdown ${ isMatchLinks ? 'active' : '' }` }>
                    <Dropdown toggle={ () => this.toggleDropDown(navigation) } isOpen={ isOpen }>
                        <DropdownToggle color={ 'white' } className={ 'navigation__dropdown-btn' }>
                            { this.getNavigationItem(navigation, key) }
                        </DropdownToggle>
                        <DropdownMenu>
                            { items.map((item, key) => this.getNavigationItem(item, key)) }
                        </DropdownMenu>
                    </Dropdown>
                </div>
                : this.getNavigationItem(navigation, key)
        );
    }

    renderTabsList() {
        return this.state.navigationInfo.map((navigation, key) => this.getNavigationControl(navigation, key));
    }

    render() {
        return (
            <div className={ this.props.className }>
                <div className={ 'voc-tabs__list' }>
                    { this.renderTabsList() }
                </div>
            </div>
        );
    }
}

Navigation.propTypes = {
    className: PropTypes.string,
    navigationInfo: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.string,
            isReactLink: PropTypes.bool,
        }),
    ),
};
