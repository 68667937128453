import { createSelector } from 'reselect';
import { userInfoSelector } from '/modules/selectors';

const topicSetSelector = (state: { VocAiTopicSet: any; }) => state.VocAiTopicSet;

const topicSetInitDataSelector = createSelector([ topicSetSelector ],
    ({ topicSetInitData }) => topicSetInitData);

export const languagesSelector = createSelector([ userInfoSelector ], user => {
    return user.languages.map(languageData => ({
        ...languageData,
        label: languageData.label.replace(/Only /g, ''),
    }));
});

export const topicsLoading = createSelector([ topicSetSelector ], ({ topics }) => topics.loading);

export const _getSourcesList = createSelector([ topicSetSelector ], ({ sources }) => ({
    ...sources,
    data: sources.data?.map(source => ({
        labelStatusClass: source.state?.toLowerCase(),
        state: source.state?.toLowerCase(),
        channel: (source.channel !== 'upload' && source.channel !== 'virtual') ? source.channel.toUpperCase() : null,
        id: source.id,
        title: source.title,
        label: source.title,
        value: source.id,
        attributes: source.attributes,
        type: source.type.toUpperCase(),
    })),
}));

export const _getTopicSetInitData = createSelector([ topicSetInitDataSelector ], topicSetInitData => {
    const { languages, categories } = topicSetInitData.data;

    const formattedLang = languages
        && Object.keys(languages)
            .reduce((langAcc, lang) => ({
                ...langAcc,
                [lang]: languages[lang].reduce((topicsAcc, topic) => ({
                    ...topicsAcc,
                    [topic]: 1,
                }), {}),
            }), {}) || {};

    return {
        ...topicSetInitData,
        data: {
            ...topicSetInitData.data,
            languages: formattedLang,
            categories,
        },
    };
});

export const _getTopicsData = createSelector([ topicSetSelector ], ({ topics }) => topics);
export const _getSocketData = createSelector([ topicSetSelector ], ({ socket }) => socket);
export const _getGraphsData = createSelector([ topicSetSelector ], ({ graphs }) => graphs);
