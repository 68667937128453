import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import './Greeting.scss';

export class Greeting extends Component {
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidMount() {
        this.clockTick();
    }

    constructor(props) {
        super(props);
        this.state = {
            currentTime: this.generateCurrentTime(new Date()),
        };
    }

    clockTick() {
        this.interval = setInterval(() => {
            const newTime = this.generateCurrentTime(new Date());

            if (newTime !== this.state.currentTime) {
                this.setState({
                    ...this.state,
                    currentTime: this.generateCurrentTime(new Date()),
                });
            }
        }, 1000);
    }

    generateCurrentTime(date) {
        return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    }

    render() {
        const currentHour = new Date().getHours();
        const currentDayTime = currentHour < 12 ? 'morning' : currentHour < 18 ? 'afternoon' : 'evening';
        const { firstName } = this.props;

        return (
            <Col lg={{ size: 6, offset: 6 }} className="greeting text-center">
                <h1 className='greeting__time'>{ this.state.currentTime }</h1>
                <p className="greeting__text">Good { currentDayTime } { firstName }!</p>
            </Col>
        );
    }
}

Greeting.propTypes = {
    firstName: PropTypes.string,
};
