import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { PopoverList } from '/visual/components';
import { Title } from '/visual/scenes/Dashboard/components/Gadget/components';
import { TAction, TPopoverListForwardRef } from '/visual/models';
import { IStaticAreaHeader } from './models';

import styles from './style.module.scss';

export const StaticAreaHeader = ({
    showHeader = false,
    hovered = false,
    gadgetData,
    onCut,
    onCopy,
    onDelete,
    onMoveToSave,
}: IStaticAreaHeader) => {
    const { t } = useTranslation();
    const target = `menuButtonGadget_${ gadgetData.id }`;
    const { id, title } = gadgetData;
    const popoverListRef = useRef<TPopoverListForwardRef | null>(null);
    const [ isPopoverOpen, setIsPopoverOpen ] = useState(false);

    let controls: TAction[] = [
        {
            title: t('cutGadget'),
            icon: 'arrows-alt' as IconProp,
            className: styles.cutGadget,
            onClick: onCut,
        },
        {
            title: t('copyGadget'),
            icon: 'copy' as IconProp,
            className: styles.copyGadget,
            onClick: onCopy,
        },
        {
            title: t('deleteGadget'),
            icon: 'trash' as IconProp,
            onClick: onDelete,
        },
        {
            title: t('moveToSave'),
            icon: 'minus-circle' as IconProp,
            className: styles.moveToSave,
            onClick: onMoveToSave,
        },
    ];

    if (isMobile) {
        const allowedControls = [ t('deleteGadget') ];

        controls = controls.filter((action: TAction) => allowedControls.includes(action.title));
    }

    useEffect(() => {
        setIsPopoverOpen(popoverListRef?.current?.isPopoverOpen || false);
    }, [ popoverListRef?.current?.isPopoverOpen ]);

    return (
        <div className={ cx(
            'disabled-drag',
            styles.actionWrapper,
            { [styles.actionWrapperHovered]: isPopoverOpen || hovered },
        ) }>
            {
                !isMobile && <PopoverList
                    ref={ popoverListRef }
                    target={ target }
                    tagClassName={ styles.actionButton }
                    placement='left-start'
                    popoverFade={ false }
                    actionList={ controls }
                    popoverClassName={ styles.popoverListWrapper }
                    popoverHeader={
                        <Title
                            id={ id }
                            title={ title }
                            canEdit={ showHeader }
                            fontSize={ 14 }
                        />
                    }
                    onBeforeHide={ () => setIsPopoverOpen(false) }
                />
            }
        </div>
    );
};
