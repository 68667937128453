import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Card, CardBody, Col, Row, CardHeader } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { FormUploadFileItem, showToast } from '/components';

import { Info, UploadNewFileForm, VerificationSection } from './components';
import { FileParser } from './services/FileParser';
import { languagesSelector, UPLOAD_LIST_ROUTE } from '../../modules/selectors';

import './UploadNewFile.scss';

import {
    getHeaders,
    cleanup,
    setUploadData,
    setAfterReloadData,
    getUploadById,
} from './modules/index';

import {
    newHeadersSelector,
    allNewHeadersInOldHeaders as allNewHeadersInOldHeadersSelector,
    columnsSelector,
    clientToServerDataConverter,
    formDataSelector,
    getInitialValues,
    isChoiceDelimiterRequired as isChoiceDelimiterRequiredSelector,
    isFileRequired as isFileRequiredSelector,
} from './modules/selectors';

const FILE_SIZE_30MB_IN_BYTES = 31457280;
const ACCEPT_FiLE_FORMAT = '.xls, .xlsx, text/csv, application/csv, .csv';

export const UploadNewFile = ({ history }) => {
    const { t } = useTranslation();
    const formData = useSelector(formDataSelector);
    const newHeaders = useSelector(newHeadersSelector);
    const allNewHeadersInOldHeaders = useSelector(allNewHeadersInOldHeadersSelector);
    const columns = useSelector(columnsSelector);
    const languages = useSelector(languagesSelector(t));
    const formValues = useSelector(getFormValues('UploadNewFileForm'));
    const initialValues = useSelector(getInitialValues(t));
    const isChoiceDelimiterRequired = useSelector(isChoiceDelimiterRequiredSelector);
    const isFileRequired = useSelector(isFileRequiredSelector);
    const [ collapsedInfo, setCollapsedInfo ] = useState(true);
    const { loading, error, afterReload, file, headerRowType } = formData;
    const dispatch = useDispatch();

    const { id } = useParams();

    useEffect(() => {
        if (id && !afterReload && !loading && !error) {
            dispatch(getUploadById(id));
        }

        return () => {
            dispatch(cleanup());
        };
    }, [ id ]);

    const onFileDrop = file => {
        dispatch(getHeaders(file, formValues.csvDelimiter));
        setCollapsedInfo(false);
    };

    const getTitleText = () => t(!afterReload ? 'uploadNewFile' : 'appendNewFile');

    const getPanelHeader = () =>
        <strong className={ 'panel__header' }>
            <FontAwesomeIcon icon={ 'cloud' } />
            &nbsp;
            { getTitleText() }
        </strong>;

    const onSubmit = value => {
        const apiMethod = afterReload ? setAfterReloadData : setUploadData;
        const data = clientToServerDataConverter(value, file, headerRowType);

        dispatch(apiMethod(data))
            .promise.then(() => history.push(UPLOAD_LIST_ROUTE));
    };

    const handleShowToast = data => dispatch(showToast(data));

    return (
        <DocumentTitle title={ getTitleText() }>
            <Card className={ 'vochub-card' }>
                <CardHeader>{ getPanelHeader() }</CardHeader>
                <CardBody>
                    <Row>
                        <Col md={ 6 }>
                            <UploadNewFileForm
                                onSubmit={ onSubmit }
                                languages={ languages }
                                newHeaders={ newHeaders }
                                afterReload={ afterReload }
                                initialValues={ initialValues }
                                showToast={ handleShowToast }
                                allNewHeadersInOldHeaders={ allNewHeadersInOldHeaders }
                                formValues={ formValues }
                                loading={ loading }
                                isChoiceDelimiterRequired={ isChoiceDelimiterRequired }
                                isFileRequired={ isFileRequired }
                            />
                            <FormUploadFileItem
                                controlId={ 'store_upload_file' }
                                file={ file }
                                accept={ ACCEPT_FiLE_FORMAT }
                                fileSize={ FILE_SIZE_30MB_IN_BYTES }
                                onDrop={ onFileDrop }
                                showErrorAlert={ handleShowToast }
                            />
                        </Col>
                        <Col md={ 6 }>
                            <Info
                                collapse={ collapsedInfo }
                                afterReloadState={ Boolean(afterReload) }
                            />
                            <VerificationSection
                                loading={ loading }
                                columns={ columns }
                                FileParser={ FileParser }
                                error={ error }
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </DocumentTitle>
    );
};
