import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import cx from 'classnames';
import { connect } from 'react-redux';
import { CardHeader, Card, CardBody, Button } from 'reactstrap';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Preloader } from '/components';
import {
    activeLanguageSelector,
    isLoadingSurveySelector,
    rightsSurveySelector,
    responsesCountSelector,
    surveyGroupSelector,
} from '../../modules/survey.selectors';
import { questionsListSelector } from './modules/questionsList.selector';
import {
    QuestionsTreeList,
    ReorderButton,
    GraphButton,
    QuestionFlowForm,
    QuestionTreePlaceholder,
    PreviewModeButton,
} from './components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import {
    isDirtySelector,
    questionTypeSelector,
    answersSelector,
    questionFormLoadingSelector,
} from '../Question/modules/question.selectors';
import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';

import './Questions.scss';

import {
    getQuestionsListById,
    deleteQuestionById,
    copyQuestionById,
    toggleQuestionVisibilityById,
    reorderQuestion,
    updateRemoteTreeData,
    deleteQuestionFlowById,
    getQuestionsTextByLanguage,
    createQuestionFlow,
    updateQuestionFlow,
    getQuestionRequest,
    setReorderFlow,
    setQuestionId,
    setFlowId,
    downloadGraphFile,
    setQuestionLayoutHandler,
} from './modules/questions.modules';
import {
    checkQuestionResponseById,
    getSurveyGroupLanguages,
    toggleIsUnsubscribeGroup,
    getIsUnsubscribeGroup,
} from '../../modules/survey.modules';
import {
    rootQuestionSelector,
    questionValueSelector,
    activeQuestionIdSelector,
    inheritQuestionDataFromIdSelector,
    showQuestionDetailsSelector,
} from './modules/questions.selectors';

import { getCommonSettingsActiveChanel } from '../SurveyCommonSettings/modules/SurveyCommonSettings.selectors';

import {
    getFlowList,
    getFlowItem,
    collectFlowOptions,
    formValuesSelector,
    getStatusFlow,
    currentFlowQuestion,
} from './modules/flows.selectors';
import { HelperService } from '/services';
import { getMetaData, getClassifierList } from '/feedback/modules/VocFeedback.modules';

const mapStateToProps = state => ({
    questionFormLoading: questionFormLoadingSelector(state),
    activeQuestionId: activeQuestionIdSelector(state),
    inheritDataFromId: inheritQuestionDataFromIdSelector(state),
    showQuestionDetails: showQuestionDetailsSelector(state),
    surveyGroup: surveyGroupSelector(state),
    activeSurvey: activeSurveySelector(state),
    isLoadingSurvey: isLoadingSurveySelector(state),
    questionsList: questionsListSelector(state),
    rootQuestion: rootQuestionSelector(state),
    activeLang: activeLanguageSelector(state),
    formValuesSelector: formValuesSelector(state),
    questionValue: questionValueSelector(state),
    flowList: getFlowList(state),
    flowItem: getFlowItem(state),
    statusFlow: getStatusFlow(state),
    flowOptions: collectFlowOptions(state),
    currentFlowQuestion: currentFlowQuestion(state),
    dirtyForm: isDirtySelector(state),
    activeChanel: getCommonSettingsActiveChanel(state),
    questionType: questionTypeSelector(state),
    answers: answersSelector(state),
    rightsSurvey: rightsSurveySelector(state),
    responsesCount: responsesCountSelector(state),
});

const mapDispatchToProps = {
    getQuestionsListById,
    checkQuestionResponseById,
    getSurveyGroupLanguages,
    deleteQuestionById,
    copyQuestionById,
    toggleQuestionVisibilityById,
    reorderQuestion,
    updateRemoteTreeData,
    deleteQuestionFlowById,
    showConfirm,
    getQuestionsTextByLanguage,
    createQuestionFlow,
    updateQuestionFlow,
    getQuestionRequest,
    getMetaData,
    getClassifierList,
    setReorderFlow,
    setQuestionId,
    setFlowId,
    toggleIsUnsubscribeGroup,
    getIsUnsubscribeGroup,
    downloadGraphFile,
    setQuestionLayoutHandler,
};

const getInitialState = () => ({
    reorder: false,
    actionDisabled: false,
    showFlow: false,
    isUpdateFlow: false,
    addButtonDisabled: false,
    isNewQuestionCreated: false,
});

@connect(mapStateToProps, mapDispatchToProps)
export class Questions extends Component {
    state = getInitialState();

    componentDidMount() {
        const { getMetaData, getClassifierList, activeSurvey, getIsUnsubscribeGroup } = this.props;

        getMetaData();
        getClassifierList();
        this.getQuestionsList(false);

        activeSurvey.survey_group && getIsUnsubscribeGroup(activeSurvey.survey_group);
    }

    componentDidUpdate(prevProps) {
        const {
            getQuestionsListById,
            getQuestionsTextByLanguage,
            activeSurvey,
            activeChanel,
            questionsList,
            getIsUnsubscribeGroup,
            activeQuestionId,
            showQuestionDetails,
            setQuestionLayoutHandler,
            questionFormLoading,
            questionListLoading,
        } = this.props;

        if (activeChanel !== prevProps.activeChanel) {
            setQuestionLayoutHandler({
                activeQuestionId: null,
                showQuestionDetails: false,
                checkForDirty: false,
            });
            this.enableAddButton();

            activeChanel === 'DIGI' && getIsUnsubscribeGroup(activeSurvey.survey_group);
        }

        if(activeSurvey.survey_group !== prevProps.activeSurvey.survey_group) {
            getIsUnsubscribeGroup(activeSurvey.survey_group);
        }

        if (activeSurvey.id !== prevProps.activeSurvey.id) {
            getQuestionsListById(activeSurvey.id);
            getQuestionsTextByLanguage({ activeSurvey: activeSurvey.id });
        }

        if (activeQuestionId !== prevProps.activeQuestionId) {
            activeQuestionId && this.recalculateLayout();

            (activeQuestionId || !showQuestionDetails)
                ? this.enableAddButton()
                : this.disableAddButton();
        }

        if (showQuestionDetails !== prevProps.showQuestionDetails) {
            if(!showQuestionDetails) {
                this.enableAddButton();
                this.calculateContainerHeight();
            }
        }

        if (
            this.getQuestionsListLength(questionsList) !== this.getQuestionsListLength(prevProps.questionsList)
            && activeQuestionId
        ) {
            this.recalculateLayout();
            this.enableAddButton();
        }

        if(questionFormLoading !== prevProps.questionFormLoading && !questionFormLoading && !questionListLoading) {
            this.recalculateLayout();
        }
    }

    componentWillUnmount() {
        // reset Question layout
        this.props.setQuestionLayoutHandler({
            activeQuestionId: null,
            showQuestionDetails: false,
            checkForDirty: false,
        });

        this.setState(getInitialState());
    }

    @autobind
    getQuestionsListLength(questions) {
        return questions.list.flat()?.reduce((sum, { children, ...listItem }) => {
            sum?.push(...[ listItem, ...children ]);
            return sum;
        }, []).length;
    }

    @autobind
    setIsNewQuestionCreated(id = null, isSave = true) {
        if(id && !isSave) {
            this.props.setQuestionLayoutHandler({
                checkForDirty: false,
                activeQuestionId: id,
            });
        }

        this.setState({ isNewQuestionCreated: Boolean(id) });
    }

    @autobind
    enableAddButton() {
        this.setState({ addButtonDisabled: false });
    }

    @autobind
    disableAddButton() {
        this.setState({ addButtonDisabled: true });
    }

    @autobind
    centralizeQuestionDetailPosition() {
        const header = document.querySelector('header');
        const form = document.querySelector('.question__form');
        const warning = document.querySelector('.question-warning');

        if (form && form.style) {
            form.style.transform = 'unset';
        }
        const bounding = warning ? warning.getBoundingClientRect() : form.getBoundingClientRect();
        const marginValue = warning ? 14 : 0;
        const warningOffsetHeight = warning ? warning.offsetHeight : 0;

        const stickyControlPanelHeight = 45;

        if (bounding.top < 0) {
            form.style.transform = `translateY(${ Math.abs(bounding.top) + header.offsetHeight - warningOffsetHeight - marginValue + stickyControlPanelHeight }px)`;
        }
    }

    @autobind
    calculateContainerHeight() {
        const container = document.querySelector('.questions__sortable-container');
        const warningBlock = document.querySelector('.question-warning');
        const paddingTopHeight = warningBlock ? warningBlock.offsetHeight : 0;

        if (container && container.style) {
            this.props.showQuestionDetails
                ? container.style.minHeight = `${ this.getFormOffsetHeight() + paddingTopHeight + 20 }px`
                : container.style.minHeight = 'unset';
        }
    }

    @autobind
    recalculateLayout() {
        // Timeout for awaiting question form render
        setTimeout(() => {
            this.calculateContainerHeight();
        }, 500);

        this.centralizeQuestionDetailPosition();
    }

    @autobind
    getFormOffsetHeight() {
        const form = document.querySelector('.question__form');
        const offsetHeight = form ? form.offsetHeight : 0;

        return offsetHeight + HelperService.getComputedTranslateY(form);
    }

    @autobind
    addNewQuestion() {
        this.props.setQuestionLayoutHandler({
            activeQuestionId: null,
            showQuestionDetails: true,
            inheritDataFromId: null,
        });

        this.disableAddButton();
        this.recalculateLayout();
    }

    @autobind
    openPreview() {
        const { history, activeSurvey: { survey_group } } = this.props;

        history.push(`/feedback/preview-mode/${survey_group}`);
    }

    @autobind
    getQuestionsList(surveyGroupRequest = true) {
        const { activeSurvey, getQuestionsListById, getQuestionsTextByLanguage, getSurveyGroupLanguages } = this.props;

        if (activeSurvey && activeSurvey.id) {
            return [
                getQuestionsListById(activeSurvey.id).promise,
                getQuestionsTextByLanguage({ activeSurvey: activeSurvey.id }).promise,
                ...(surveyGroupRequest ? [ getSurveyGroupLanguages(activeSurvey.survey_group).promise ] : []),
            ];
        }
    }

    @autobind
    reorderToggle() {
        this.setState({ reorder: !this.state.reorder });
    }

    @autobind
    removeQuestionHandler(question) {
        const { showConfirm, toggleIsUnsubscribeGroup, surveyGroup } = this.props;

        const isUnsubscribeDeleting = question.type === "Unsubscribe"
            && surveyGroup.isUnsubscribeSurvey
            && question.nested_left === 2;

        this.setState({
            actionDisabled: true,
        });

        let confirmState = question.children.length ? 'multiple' : 'single';

        const confirmContent = {
            'single': 'Are you sure you want to delete this question?',
            'singleResponse': this.getConfirmContent,
            'multiple': this.getConfirmContentMultiple(),
            'multipleResponse': count => this.getConfirmContent(count, 'multiple'),
        };

        this.props.checkQuestionResponseById(question.id).promise
            .then(({ count }) => {
                this.setState({
                    actionDisabled: false,
                });

                if (count) {
                    confirmState = confirmState === 'multiple' ? 'multipleResponse' : 'singleResponse';
                }

                showConfirm({
                    header: 'Warning!',
                    content: <div>
                        {
                            isUnsubscribeDeleting
                                ? <>
                                    {
                                        `Survey will be detached from all survey groups and
                                        units for which it used as "Unsubscribe survey"`
                                    }
                                    <br />
                                    <br />
                                </>
                                : ''
                        }
                        {
                            count
                                ? confirmContent[confirmState](count)
                                : confirmContent[confirmState]
                        }
                    </div>,
                    labelSwitch: count && `I confirm I want to delete ${ count } already provided customers responses.`,
                    successCallback: () => {
                        this.removeQuestionProcessing(question.id);
                        isUnsubscribeDeleting && toggleIsUnsubscribeGroup(false);
                    },
                });
            });
    }

    @autobind
    copyQuestionHandler(question) {
        this.props.showConfirm({
            header: 'Copy question',
            size: 'md',
            content: `Are you sure you want to copy ${ question.title } question?`,
            successCallback: () => this.copyQuestionProcessing(question.id),
        });
    }

    @autobind
    getConfirmContentMultiple() {
        return (
            <>
                <p> Deleting this question will result in deletion of all questions nested to it. </p>
                <p className={ 'remove-margin' }>Please confirm your action.</p>
            </>
        );
    }

    getConfirmContent(count, isMultiple) {
        return (
            <div className={ 'remove-questions__modal-body' }>
                <div>
                    <p className={ 'remove-questions__modal-accented' }>
                        { count } Customers already provided their responses for this question!
                    </p>
                    <p>
                        We strongly recommend you do not delete those responses. You can use 'question flow' to skip
                        this question for new Customers in case you don't need it any more.
                    </p>
                    {
                        isMultiple
                        && <p>Please keep in mind, nested questions and already given answers will be deleted too.</p>
                    }
                    <p>
                        Please confirm your action
                    </p>
                </div>
            </div>
        );
    }

    renderWarning() {
        const { responsesCount, activeQuestionId } = this.props;

        return responsesCount > 0 && activeQuestionId
            ? <div className="alert alert-danger question-warning">
                <strong>Warning!</strong>
                &nbsp;
                Please be aware, some users already answered this question. Your
                changes may confuse results of the survey!
            </div>
            : null;
    }

    @autobind
    removeFlowHandler(id) {
        this.props.showConfirm({
            header: 'Warning!',
            content: 'You are going to delete this flow, continue?',
            successCallback: () => this.props.deleteQuestionFlowById(id),
        });
    }

    @autobind
    questionFlowHandler({ node }) {
        this.props.setQuestionId(node.id);

        this.setState({
            showFlow: true,
            question: node.id,
            survey: node.survey.id,
            parentId: node.parentId,
        });
    }

    @autobind
    updateStateFlow({ id, question, parentId }) {
        this.props.setFlowId(id);

        this.setState({
            showFlow: true,
            isUpdateFlow: true,
            question: question,
            parentId: parentId,
        });
    }

    @autobind
    closeQuestionFlow() {
        this.props.setFlowId(null);
        this.props.setQuestionId(null);

        this.setState({
            showFlow: false,
            isUpdateFlow: false,
            question: null,
            survey: null,
            parentId: null,
        });
    }

    @autobind
    submitFlow(data, flowID) {
        const { createQuestionFlow, updateQuestionFlow } = this.props;

        return flowID
            ? updateQuestionFlow(data, flowID)
            : createQuestionFlow(data);
    }

    @autobind
    removeQuestionProcessing(questionId) {
        const {
            activeQuestionId,
            deleteQuestionById,
            getQuestionsListById,
            activeSurvey,
            setQuestionLayoutHandler,
        } = this.props;

        const isActiveQuestionDelete = questionId === activeQuestionId;

        deleteQuestionById(questionId).promise
            .then(() => {
                if (isActiveQuestionDelete) {
                    setQuestionLayoutHandler({
                        activeQuestionId: null,
                        showQuestionDetails: false,
                        inheritDataFromId: null,
                    });
                }
                return getQuestionsListById(activeSurvey.id);
            });
    }

    @autobind
    copyQuestionProcessing(questionId) {
        const {
            copyQuestionById,
            getQuestionsListById,
            activeSurvey,
            getQuestionsTextByLanguage,
        } = this.props;

        copyQuestionById(questionId).promise
            .then(() => {
                getQuestionsListById(activeSurvey.id);
                getQuestionsTextByLanguage({ activeSurvey: activeSurvey.id });
            });
    }

    @autobind
    onClickGraphButton() {
        const { activeSurvey: { id }, downloadGraphFile } = this.props;

        downloadGraphFile(id, 'graphData');
    }

    @autobind
    getPanelHtml() {
        const {
            activeSurvey,
            isLoadingSurvey,
            rightsSurvey,
            questionsList,
        } = this.props;

        const isStateForPreview = [ 1, 2 ].includes(activeSurvey.state); //is state Active or Paused;
        const isButtonsDisabled = !activeSurvey.channel || isLoadingSurvey;
        const classNameForPermitEdit = cx(
            'panel__header-button',
            { 'disabled': !rightsSurvey.isPermitEdit },
        );

        return (
            <>
                <span className={ 'panel__header-title' }>Questions</span>
                <div className={ 'panel__header-buttons questions-util-button-group' }>
                    <ReorderButton
                        className={ classNameForPermitEdit }
                        enable={ !this.state.reorder }
                        changeHandler={ this.reorderToggle }
                        disabled={ !rightsSurvey.isPermitEdit }
                    />
                    {
                        activeSurvey.channel === 'DIGI'
                        && <PreviewModeButton
                            className={ 'panel__header-button' }
                            onClick={ this.openPreview }
                            disabled={ !questionsList?.list?.length || !isStateForPreview }
                        />
                    }
                    <GraphButton
                        className={ 'panel__header-button' }
                        disabled={ isButtonsDisabled || activeSurvey.channel === 'DIGI' }
                        onClick={ this.onClickGraphButton }
                    />
                    <Button
                        className={ classNameForPermitEdit }
                        outline
                        color="white"
                        disabled={
                            isButtonsDisabled
                            || this.state.addButtonDisabled
                            || !rightsSurvey.isPermitEdit
                        }
                        onClick={ this.addNewQuestion }
                    >
                        <FontAwesomeIcon icon='plus' className='color-green'/>
                        <span> Add question</span>
                    </Button>
                </div>
            </>
        );
    }

    @autobind
    getPreloader() {
        const { questionsList: { loading, list }, activeSurvey: { channel }, isLoadingSurvey } = this.props;
        const isCreatedChannel = channel || isLoadingSurvey;

        return (
            isCreatedChannel
                ? loading
                    ? <div className={ `questions__loading-wrapper` }><Preloader/></div>
                    : !list.length && <QuestionTreePlaceholder/>
                : <div className="loading-placeholder">
                    To create a question, please activate or select an activated channel first
                </div>
        );
    }

    render() {
        const {
            activeQuestionId,
            inheritDataFromId,
            showQuestionDetails,
            questionsList: {
                list,
                activeChannel,
                loading,
            },
            formValuesSelector,
            toggleQuestionVisibilityById,
            reorderQuestion,
            updateRemoteTreeData,
            rootQuestion,
            flowOptions,
            getQuestionRequest,
            questionValue,
            setReorderFlow,
            flowList,
            flowItem,
            statusFlow,
            currentFlowQuestion,
            setQuestionId,
            rightsSurvey,
            showConfirm,
            surveyGroup,
            toggleIsUnsubscribeGroup,
            setQuestionLayoutHandler,
        } = this.props;

        const {
            actionDisabled,
            showFlow,
            reorder,
            question,
            survey,
            parentId,
            isNewQuestionCreated,
            isUpdateFlow,
        } = this.state;

        return (
            <DocumentTitle title="Feedback :: Survey Questions">
                <div>
                    <Card className={ 'page__wrapper survey_questions__wrapper' }>
                        <CardHeader className={ 'panel__header panel__header--buttons' }>
                            { this.getPanelHtml() }
                        </CardHeader>
                        <CardBody className={ 'questions__sortable-container' }>
                            { this.renderWarning() }
                            <div className="questions__sortable-wrapper">
                                <QuestionsTreeList
                                    toggleIsUnsubscribeGroup={ toggleIsUnsubscribeGroup }
                                    surveyGroup={ surveyGroup }
                                    showConfirm={ showConfirm }
                                    questionListLoading={ loading }
                                    updateStateFlow={ this.updateStateFlow }
                                    canDrag={ reorder }
                                    flows={ flowList }
                                    statusFlow={ statusFlow }
                                    treeData={ list }
                                    toggleExpanded={ toggleQuestionVisibilityById }
                                    removeQuestionHandler={ this.removeQuestionHandler }
                                    copyQuestionHandler={ this.copyQuestionHandler }
                                    questionFlowHandler={ this.questionFlowHandler }
                                    reorderHandler={ reorderQuestion }
                                    actionDisabled={ actionDisabled }
                                    updateRemoteTreeData={ updateRemoteTreeData }
                                    rootQuestion={ rootQuestion }
                                    removeFlowHandler={ this.removeFlowHandler }
                                    flowOptions={ flowOptions }
                                    getQuestionRequest={ getQuestionRequest }
                                    questionValue={ questionValue }
                                    getQuestionsList={ this.getQuestionsList }
                                    setReorderFlow={ setReorderFlow }
                                    activeChannel={ activeChannel }
                                    getPreloader={ this.getPreloader }
                                    setQuestionLayoutHandler={ setQuestionLayoutHandler }
                                    setQuestionId={ setQuestionId }
                                    activeQuestionId={ activeQuestionId }
                                    inheritDataFromId={ inheritDataFromId }
                                    enableAddButton={ this.enableAddButton }
                                    showQuestionDetails={ showQuestionDetails }
                                    setIsNewQuestionCreated={ this.setIsNewQuestionCreated }
                                    isNewQuestionCreated={ isNewQuestionCreated }
                                    rightsSurvey={ rightsSurvey }
                                />
                            </div>
                        </CardBody>
                    </Card>
                    <QuestionFlowForm
                        toggleIsUnsubscribeGroup={ toggleIsUnsubscribeGroup }
                        surveyGroup={ surveyGroup }
                        showConfirm={ showConfirm }
                        initialValues={ flowItem }
                        statusFlow={ statusFlow }
                        flowOptions={ flowOptions }
                        show={ showFlow }
                        close={ this.closeQuestionFlow }
                        submitFlow={ this.submitFlow }
                        formValuesSelector={ formValuesSelector }
                        questionId={ question }
                        surveyId={ survey }
                        parentId={ parentId }
                        currentFlowQuestion={ currentFlowQuestion }
                        activeChannel={ activeChannel }
                        isUpdateFlow={ isUpdateFlow }
                    />
                </div>
            </DocumentTitle>
        );
    }
}
