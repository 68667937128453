import { createSelector } from 'reselect';
import { SettingsService } from '/feedback/services/SettingsService';
import { unsubscribeSurveysSelector } from '/feedback/modules/VocFeedback.selectors';

const getCommonSettings = state => state.VocFeedback.survey.commonSettings;

export const classifierOptionSelector = createSelector(
    [ getCommonSettings ],
    ({ classifier }) =>
        classifier?.results.map(classifier => ({ value: classifier.classifierId, label: classifier.title })));

export const getCommonSettingsActiveChanel = state => state.VocFeedback.survey.surveyCommon.activeChannel;

export const initialFormValuesSelector = createSelector(
    [
        getCommonSettings,
        SettingsService.getFieldsByCommonSurvey,
        classifierOptionSelector,
        unsubscribeSurveysSelector,
    ],
    ({ globalSettings, surveySettings }, fields, classifierOptions, unsubscribeSurveys) => {
        const settings = SettingsService.composeSurveySettings(globalSettings, surveySettings);

        const unsubscribeSurveysOptions = [
            {
                value: 'blocked',
                label: 'Disable global setting',
            },
            ...unsubscribeSurveys.results.map(template => ({
                value: template.id,
                label: template.name,
            })),
        ];

        return {
            fieldsMap: fields.map(field => ({
                ...field,
                children: SettingsService.changeFieldsCommonSettings(field, classifierOptions,
                    (child, settings) => ({
                        ...child,
                        options: child.key === "UNSUBSCRIBE_SURVEY_GROUP"
                            ? unsubscribeSurveysOptions
                            : child.options,
                        redefined: settings[child.key] && settings[child.key].redefined,
                    }), settings),
            })),
            initialValues: settings,
        };
    });

export const surveyFormSettingsSelector = createSelector(
    [ getCommonSettings, initialFormValuesSelector, unsubscribeSurveysSelector ],
    ({ loading, sending, classifier }, fields, unsubscribe) => {
        return {
            loading,
            sending,
            ...fields,
            classifierLoading: classifier.loading,
            unsubscribeLoading: unsubscribe.loading,
        };
    });
