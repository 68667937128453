import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { autobind } from 'core-decorators';
import './PerPageFilter.scss';

export class PerPageFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

	@autobind
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

	render() {
	    let label = 'Show per page';

	    const showPerPageLabel = val => {
	        const value = parseInt(val, 10);

	        if (value > 0) {
	            return (value === this.props.MAX_LIMIT)
	                ? (<span>Show All</span>)
	                : (<span>Show <span className='number'>{ value }</span> rows per page</span>);
	        }
	    };

	    const setFilter = value => {
	        label = showPerPageLabel(value);
	        const currentValue = this.props.itemsPerPage;

	        if (value !== currentValue) {
	            this.props.applyFilter({
	                limit: value,
	                page: 1,
	                loading: true,
	            });
	        }
	    };

	    const filterValues = [ 10, 20, 50, 100, this.props.MAX_LIMIT ];
	    const currentValue = this.props.itemsPerPage;

	    label = showPerPageLabel(this.props.itemsPerPage || 10) || '';

	    return (
	        <div>
	            <Dropdown
	                id='filter-show-per-page'
	                isOpen={ this.state.isOpen }
	                toggle={ this.toggle }
	                className={ 'filters__per-page' }
	            >
	                <DropdownToggle color={ 'white' } caret outline>
	                    { label }
	                </DropdownToggle>
	                <DropdownMenu>
	                    { filterValues.map((value, index) => {
	                        return (value)
	                            ? (
	                                <DropdownItem
	                                    key={ index }
	                                    className={ currentValue === value ? 'active' : '' }
	                                    onClick={ () => setFilter(value) }
	                                >
	                                    { showPerPageLabel(value) }
	                                </DropdownItem>)
	                            : (
	                                <DropdownItem key={ index } divider/>
	                            );
	                    }) }
	                </DropdownMenu>
	            </Dropdown>
	        </div>
	    );
	}
}

PerPageFilter.propTypes = {
    applyFilter: PropTypes.func,
    MAX_LIMIT: PropTypes.any,
    itemsPerPage: PropTypes.any,
};
PerPageFilter.defaultProps = {};

