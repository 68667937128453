import React from 'react';
import { SortableItem } from '../SortableItem/';
import { SortableContainer } from 'react-sortable-hoc';

export const SortableList = SortableContainer(({ items, self }) => {
    return (
        <tbody>
            {
                items.map((value, index) => {
                    return <SortableItem
                        key={ `item-${ index }` }
                        index={ index }
                        value={ value }
                        self={ self }
                    />;
                })
            }
        </tbody>
    );
});
