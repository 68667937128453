import { TGetCatalogLastPart, TPrepareAndDownloadFile } from './models';

export class DownloadFileService {
    static prepareAndDownloadFile({ data, type, fileName }: TPrepareAndDownloadFile) {
        const blob = new Blob([ data ], { type });

        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static streamingDownloadFile(xhr: XMLHttpRequest, fileName: string) {
        if (xhr?.status === 200 ) {
            const blob = new Blob(
                [ xhr.response ],
                { type: xhr.response.type },
            );
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    static getCatalogLastPart({ catalogPath, catalogPathDivider = '/' }: TGetCatalogLastPart) {
        if (!catalogPath) return '';

        const fileNameSegments = catalogPath.split(catalogPathDivider);

        return fileNameSegments[fileNameSegments.length - 1];
    }
}
