import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SettingsFormControls = ({
    cancelChanges,
    handleSubmit,
    submit,
    channel,
    initRunnerDemo,
    demoBtnDisabled,
    permission,
}) => {
    return (
        <Row className="survey-languages__controls">
            <Col md={{ size: 4, offset: 8 }}>
                <Button
                    onClick={ cancelChanges }
                    className={ 'question__controls-button' }
                    color={ 'white' }
                    outline
                >
                    Cancel
                </Button>
                {
                    channel === 'DIGI'
                    && <Button
                        onClick={ initRunnerDemo }
                        className={ 'question__controls-button' }
                        disabled={ !permission.isPermitEdit || demoBtnDisabled }
                        color={ 'info' }
                    >
                        <FontAwesomeIcon className={ 'question__controls-icon' } icon={ 'eye' }/>
                        Demo
                    </Button>
                }
                <Button
                    onClick={ handleSubmit(values => submit(values)) }
                    className={ 'question__controls-button' }
                    color={ 'primary' }
                    disabled={ !permission.isPermitEdit }
                >
                    <FontAwesomeIcon className={ 'question__controls-icon' } icon={ 'check' }/>
                    Save
                </Button>
            </Col>
        </Row>
    );
};
