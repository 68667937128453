import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TooltipWrapper } from '/components';

import styles from './style.module.scss';

export const ActionFormatter = ({ openModalDelete, openModalEditClassifierAppliance, reApplySetAppliance }) => (
    <div className={ styles.actionFormatterWrapper }>
        <TooltipWrapper force value='Re-Apply'>
            <span>
                <Button color='link' onClick={ reApplySetAppliance }>
                    <FontAwesomeIcon icon='sync-alt'/>
                </Button>
            </span>
        </TooltipWrapper>
        <TooltipWrapper force value='Edit'>
            <span>
                <Button onClick={ openModalEditClassifierAppliance } color='link'>
                    <FontAwesomeIcon icon='pencil-alt'/>
                </Button>
            </span>
        </TooltipWrapper>
        <TooltipWrapper force value='Delete'>
            <span>
                <Button onClick={ openModalDelete } color='link'>
                    <FontAwesomeIcon icon='trash'/>
                </Button>
            </span>
        </TooltipWrapper>
    </div>
);
