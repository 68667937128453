import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Label, FormGroup, Col, Row } from 'reactstrap';

import { VocFormErrorField, VocFormTextField } from '/components';
import { HelperService } from '/services';

export const QuestionTextSection = ({
    loading,
    language,
    viewConfig,
    showEditor,
    htmlEditorCallback,
}) => {
    const questionTextName = `language_values.${ language }.text`;
    const nextButtonTextName = `language_values.${ language }.configurable.next_button_text`;
    const placeholderTextName = `language_values.${ language }.configurable.select_placeholder`;
    const questionErrorName = `language_values.${ language }.error_text`;
    const questionAdditionalTextName = `language_values.${ language }.configurable.info_text`;
    const { error_text, info_text, next_button_text, select_placeholder } = viewConfig;

    return (
        <Card className='question__section'>
            <CardHeader>
                <Row>
                    <Col lg={ 10 }>
                        <span>Question text</span>
                    </Col>
                    <Col lg={ 2 }>
                        <div className='language__header-value'>
                            { language }
                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className={ `question__section-body ${ loading ? 'question__section--loading' : '' }` }>
                <FormGroup>
                    <Row>
                        <Col md={ 12 }>
                            <FormGroup>
                                <Label className={ 'general-section__label' } htmlFor='text'>
                                    Text
                                </Label>
                                <Field
                                    className='textarea-size'
                                    placeholder='Enter text'
                                    normalize={ HelperService.normalizeTextValue }
                                    type='textarea'
                                    id='text'
                                    name={ questionTextName }
                                    component={ VocFormTextField }
                                    withError={ false }
                                    editorCallback={ value => htmlEditorCallback({
                                        value,
                                        name: questionTextName,
                                    }) }
                                    showEditor={ showEditor }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={ 12 }>
                            <Field
                                name={ questionTextName }
                                className='question-form__validation-error'
                                component={ VocFormErrorField }
                            />
                        </Col>
                    </Row>
                </FormGroup>
                {
                    error_text.show
                    && <FormGroup>
                        <Row>
                            <Col md={ 12 }>
                                <FormGroup>
                                    <Label className='general-section__label' htmlFor='error_text'>
                                        Error text
                                    </Label>
                                    <Field
                                        placeholder='Enter error text'
                                        className='textarea-size'
                                        id='error_text'
                                        type='textarea'
                                        name={ questionErrorName }
                                        normalize={ HelperService.normalizeTextValue }
                                        component={ VocFormTextField }
                                        withError={ false }
                                        editorCallback={ value => htmlEditorCallback({
                                            value,
                                            name: questionErrorName,
                                        }) }
                                        showEditor={ showEditor && !error_text.disabled }
                                        disabled={ error_text.disabled }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={ 12 }>
                                <Field
                                    name={ questionErrorName }
                                    className='question-form__validation-error'
                                    component={ VocFormErrorField }
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                }
                {
                    info_text.show
                    && <FormGroup>
                        <Row>
                            <Col md={ 12 }>
                                <FormGroup>
                                    <Label className='general-section__label' htmlFor='additional_text'>
                                        Text below answer area
                                    </Label>
                                    <Field
                                        placeholder='Enter additional text'
                                        className='textarea-size'
                                        id='additional_text'
                                        type='textarea'
                                        name={ questionAdditionalTextName }
                                        normalize={ HelperService.normalizeTextValue }
                                        component={ VocFormTextField }
                                        withError={ false }
                                        editorCallback={ value => htmlEditorCallback({
                                            value,
                                            name: questionAdditionalTextName,
                                        }) }
                                        showEditor={ showEditor && !info_text.disabled }
                                        disabled={ info_text.disabled }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                }
                {
                    next_button_text.show
                    && <FormGroup>
                        <Row>
                            <Col md={ 12 }>
                                <FormGroup>
                                    <Label className='general-section__label' htmlFor='next_button_text'>
                                        Next button text
                                    </Label>
                                    <Field
                                        placeholder='Enter text'
                                        normalize={ HelperService.normalizeTextValue }
                                        type='input'
                                        id='next_button_text'
                                        name={ nextButtonTextName }
                                        component={ VocFormTextField }
                                        withError={ false }
                                        disabled={ next_button_text.disabled }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={ 12 }>
                                <Field
                                    name={ questionTextName }
                                    className='question-form__validation-error'
                                    component={ VocFormErrorField }
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                }
                {
                    select_placeholder.show
                    && <FormGroup>
                        <Row>
                            <Col md={ 12 }>
                                <FormGroup>
                                    <Label className='general-section__label' htmlFor='select_placeholder'>
                                        Default text in a dropdown
                                    </Label>
                                    <Field
                                        placeholder='Enter text'
                                        normalize={ HelperService.normalizeTextValue }
                                        type='input'
                                        id='select_placeholder'
                                        name={ placeholderTextName }
                                        component={ VocFormTextField }
                                        withError={ false }
                                        disabled={ select_placeholder.disabled }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={ 12 }>
                                <Field
                                    name={ questionTextName }
                                    className='question-form__validation-error'
                                    component={ VocFormErrorField }
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                }
            </CardBody>
        </Card>
    );
};

QuestionTextSection.propTypes = {
    language: PropTypes.string,
    showEditor: PropTypes.bool,
};
