export const DictionaryFormValidation = (values ={}) => {
    const errors = {};

    const model = { ...values };

    const letterDigitTest = string => /([\wа-ёіїє0-9\u0621-\u064A])+/gi.test(string);

    Object.keys(model).forEach(value => {
        const isNotEmpty = model[value];

        if (!isNotEmpty) {
            errors[value] = 'Field must not be empty';
        }
        if (isNotEmpty && !letterDigitTest(isNotEmpty)) {
            errors[value] = 'Field must contain at least one letter or digit';
        }
        if(value === 'include_dictionaries') {
            delete errors['include_dictionaries'];
        }
    });

    return errors;
};
