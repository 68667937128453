import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

import { TableFilter, TooltipWrapper, VocTableV2, TableResetFilter } from '/components';
import { ActionFormatter } from './components';

import styles from './styles.module.scss';

export const TopicSetApplianceTable = ({
    tableData,
    filters,
    totalCount,
    loading,
    requestTrigger,
    updateFilters,
    openPopoverFilter,
    openModalDelete,
    filterPopoverState,
    closePopoverFilter,
    getPopoverFilterData,
    applyPopoverFilter,
    openModalCreateTopicSetAppliance,
    openModalEditTopicSetAppliance,
    topicSetsFilters,
    sourcesFilters,
    languagesFilters,
    columnsFilters,
    resetFilters,
    reApplySetAppliance,
}) => {
    const actionsFormatter = (cell: any) => (
        <ActionFormatter
            openModalDelete={ () => openModalDelete(cell.id) }
            openModalEditClassifierAppliance={ () => openModalEditTopicSetAppliance(cell) }
            reApplySetAppliance={ () => reApplySetAppliance(cell.id) }
        />
    );

    const customTableHeader = () => {
        return [
            <Button outline onClick={ openModalCreateTopicSetAppliance } key={ 0 } color='success'>
                <FontAwesomeIcon icon='plus'/>
                <span> Apply Topic Set</span>
            </Button>,
            <TableResetFilter
                key={ 2 }
                filters={ filters.extraFilters }
                reset={ resetFilters }
            />,
        ];
    };

    const filterFormatter = (name: string) => {
        const getFilterData = () => {
            switch (name) {
                case 'topicSet':
                    return topicSetsFilters;
                case 'source':
                    return sourcesFilters;
                case 'languages':
                    return languagesFilters;
                case 'columns':
                    return columnsFilters;

                default:
                    return topicSetsFilters;
            }
        };

        return (
            <TableFilter
                targetFilter={ filterPopoverState.targetFilter }
                isOpenPopover={ filterPopoverState.isOpenPopoverFilter }
                togglePopover={ closePopoverFilter }
                filterData={ getFilterData() }
                apply={ applyPopoverFilter }
                getFilterData={ getPopoverFilterData }
                name={ name }
            />
        );
    };

    const stringFormatter = cell => (
        <TooltipWrapper value={ cell }>
            <span className={ styles.stringFormatter }>{cell}</span>
        </TooltipWrapper>
    );

    const settings = [
        {
            field: 'topicSet',
            title: 'TopicSet',
            filter: () => filterFormatter('topicSet'),
            formatter: stringFormatter,
            sort: true,
        },
        {
            field: 'source',
            title: 'Source',
            filter: () => filterFormatter('source'),
            formatter: stringFormatter,
            sort: true,
        },
        {
            field: 'column',
            title: 'Column',
            filter: () => filterFormatter('columns'),
            formatter: stringFormatter,
            sort: true,
        },
        {
            field: 'languages',
            title: 'Language',
            filter: () => filterFormatter('languages'),
            formatter: stringFormatter,
            sort: true,
        },
        {
            field: 'threshold',
            title: 'Accuracy Threshold',
            sort: true,
        },
        {
            field: 'actions',
            title: 'Actions',
            formatter: actionsFormatter,
            style: {
                width: '150px',
                textAlign: 'center',
            },
        },
    ];

    const options = {
        haveSearch: false,
        requestTrigger,
        updateFilters,
        customTableHeader: customTableHeader(),
        filterField: filterPopoverState.filterField,
        openPopoverFilter,
    };

    return (
        <VocTableV2
            data={ tableData }
            total={ totalCount }
            loading={ loading }
            dataSettings={ settings }
            options={ options }
            filters={ filters }
        />
    );
};
