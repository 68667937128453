import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocModal, FormFieldItem } from '/components';
import { setThemeData } from "../../modules/themes.modules";

import styles from './style.module.scss';

export const ThemeDetailsModal = ({
    show,
    close,
    theme,
    reset,
    refreshTable,
}) => {
    const dispatch = useDispatch();
    const [ description, setDescription ] = useState('');
    const [ edit, setEdit ] = useState(false);

    useEffect(() => {
        setDescription(theme?.title);
    }, [ theme?.title ]);

    const handlerOnSubmit = () => {
        setEdit(false);
        const { digi_theme_tags, ...restThemeData } = theme;

        const dataModel = {
            ...restThemeData,
            tags: digi_theme_tags.map(tag => tag.name),
            title: description,
        };

        dispatch(setThemeData(dataModel)).promise.then(() => refreshTable());
    };

    const onClosedHandler = () => {
        reset();
        setEdit(false);
    };

    const getModalFooter = () => {
        return (
            edit
                ? <>
                    <Button
                        onClick={ () => {
                            setDescription(theme?.title);
                            setEdit(false);
                        } }
                        outline
                        color={ 'white' }
                    >
                        <FontAwesomeIcon icon='times'/>
                        <span> Cancel</span>
                    </Button>
                    <Button
                        onClick={ () => handlerOnSubmit() }
                        color={ 'primary' }
                    >
                        <FontAwesomeIcon icon='check'/>
                        <span> Save</span>
                    </Button>
                </>
                : <>
                    <Button
                        onClick={ close }
                        outline
                        color={ 'white' }
                    >
                        <FontAwesomeIcon icon='times'/>
                        <span> Close</span>
                    </Button>
                    <Button
                        onClick={ () => setEdit(true) }
                        color={ 'primary' }
                    >
                        <FontAwesomeIcon icon='check'/>
                        <span> Edit</span>
                    </Button>
                </>
        );
    };

    const getModalBody = () => (
        <div>
            <div className={ styles.listWrapper }>
                <p className={ styles.listLabel }><b>Units:</b></p>
                <div className={ styles.list }>
                    {
                        theme?.units?.length
                            ? theme.units.map(unitName => (
                                <div>
                                    <p>{unitName}</p>
                                </div>
                            ))
                            : <p>Not set</p>
                    }
                </div>
            </div>
            {
                edit
                    ? <FormFieldItem
                        type='textarea'
                        className={ 'info-textarea' }
                        isHorizontal={ false }
                        value={ description }
                        label='Description'
                        placeholder='Enter description'
                        handleChange={ event => setDescription(event.target.value) }
                        textError={ 'Name must be no more than 50 characters' }
                    />
                    : <p>
                        <b>Description: </b>
                        {
                            description
                                ? description
                                : 'No description provided'
                        }
                    </p>
            }
            <div className={ styles.listWrapper }>
                <p className={ styles.listLabel }><b>Surveys:</b></p>
                <div className={ styles.list }>
                    {
                        theme?.digi_theme_surveys?.length
                            ? theme?.digi_theme_surveys.map(survey => (
                                <div>
                                    <Link to={ `/feedback/survey/${survey.survey__survey_group}/questions` }>
                                        <p>{survey.survey__survey_group__name}</p>
                                    </Link>
                                </div>
                            ))
                            : <p>Not set</p>
                    }
                </div>
            </div>
        </div>
    );

    return (
        <VocModal
            toggle={ close }
            onClosed={ onClosedHandler }
            className='modal-scrollable'
            header={ 'Theme Details' }
            isOpen={ show }
            size={ 'md' }
            footer={ getModalFooter() }
        >
            { getModalBody() }
        </VocModal>
    );
};
