import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ScrollUp.scss';

export class ScrollUp extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    @autobind
    handleScroll() {
        const distance = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
        const topOffset = 100;

        this.setState({
            visible: distance >= topOffset,
        });
    }

    @autobind
    clickHandler() {
        this.scrollToTop(300);
    }

    scrollToTop(scrollDuration) {
        const scrollStep = -window.scrollY / (scrollDuration / 15),
            scrollInterval = setInterval(function() {
                if (window.scrollY !== 0) {
                    window.scrollBy(0, scrollStep);
                } else clearInterval(scrollInterval);
            }, 15);
    }

    render() {
        return (
            <div>
                { this.state.visible
                && <button className="scrollup btn btn-primary" onClick={ this.clickHandler }>
                    <FontAwesomeIcon icon="chevron-up"/>
                </button> }
            </div>
        );
    }
}
