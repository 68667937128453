import React, { useContext, memo, useMemo, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { SettingsForm } from "/feedback/components";
import { ThemeService } from "/feedback/services";
import { Preloader } from '/components';
import { Context } from '../../';

export const ThemeSettings = memo(() => {
    const {
        themeSettings: {
            loading,
            fieldsMap,
            initialValues,
            themeId,
            sendingTheme,
        },
        languageSettings: {
            initialValues: initialValuesLanguage,
            getAssets,
            uploadAsset,
            assets,
        },
        name,
        description,
        tags,
        setTheme,
        nameIsError,
        setNameIsError,
        permission,
        dirtyThemeHeader,
    } = useContext(Context);

    const history = useHistory();

    const [ initValue, setInitValue ] = useState({});

    const initRunner = () => {
        const themeSettings = {};

        Object.keys(initValue).map(el => {
            themeSettings[el.toUpperCase()] = initValue[el]?.channelValue !== undefined
                ? initValue[el].channelValue
                : initValue[el];
        });

        ThemeService.setMockedData(themeSettings);
    };

    useEffect(() => {
        !loading && setInitValue({
            ...initialValuesLanguage,
            ...initialValues,
        });
    }, [ loading ]);

    useEffect(() => {
        setInitValue({
            ...initialValuesLanguage,
            ...initialValues,
        });

        return () => setInitValue({});
    }, []);

    const putSettings = values => {
        if (name) nameIsError && setNameIsError(false);
        if (!name) !nameIsError && setNameIsError(true);

        return name
            ? setTheme(themeId, name, description, tags, {
                ...values,
            }).then(e => {
                setTimeout(() => {
                    history.push(`/feedback/theme/${ e.id }/theme-settings`);
                }, 0);
            })
            : null;
    };

    useEffect(() => {
        if (nameIsError) {
            if (name) nameIsError && setNameIsError(false);
            if (!name) !nameIsError && setNameIsError(true);
        }
    }, [ name, nameIsError ]);

    return useMemo(() => {
        return (
            loading
                ? <Preloader/>
                : <SettingsForm
                    justShowRunner
                    customDirty={ dirtyThemeHeader }
                    fieldsMap={ fieldsMap }
                    initialValues={ initialValues }
                    putSettings={ putSettings }
                    sendingSettings={ sendingTheme }
                    wrappedCollapse={ false }
                    channel={ themeId ? 'DIGI' : null }
                    themeSettings={ true }
                    initRunnerDemo={ initRunner }
                    form={ 'GlobalThemeSettingsForm' }
                    getAssets={ getAssets }
                    uploadAsset={ uploadAsset }
                    assets={ assets }
                    onChangeField={ (value, key) => {
                        setInitValue({
                            ...initValue,
                            [key]: {
                                ...initValue[key],
                                channelValue: value,
                            },
                        });

                        ThemeService.updateStyle({
                            [key.toUpperCase()]: value,
                        });
                    } }
                    permission={ permission }
                />
        );
    }, [ loading, name, description, tags, themeId, sendingTheme, assets, dirtyThemeHeader, initValue ]);
});
