import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const VocFormTextBlock = ({
    input,
    className,
}) => {
    return (
        <Fragment>
            <span className={ `${className}` }>{ input.value }</span>
        </Fragment>
    );
};

VocFormTextBlock.propTypes = {
    className: PropTypes.string,
};

VocFormTextBlock.defaultProps = {
    className: '',
};
