import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { dirtyStatusConfirm } from '/scenes/Confirm/modules/confirm.index';
import { confirmDataSelector } from '/scenes/Confirm/modules/confirm.selectors';
import { HelperService } from '/services';

import { cleanup, createImport, getQuintlyHeaders, setHeaderRowType } from './modules';
import { Info, UploadSocialNetworkForm, VerificationSection } from './components';
import { languagesSelector, UPLOAD_LIST_ROUTE } from '../../modules/selectors';
import {
    formDataSelector,
    headerRowSelector,
    headerTypes,
    initialValuesSelector,
    parserOptionsSelector,
    headerRowTypeSelector,
} from './modules/selectors';

import './UploadSocialNetwork.scss';

export const UploadSocialNetwork = ({ history }) => {
    const { t } = useTranslation();
    const formData = useSelector(formDataSelector);
    const languages = useSelector(languagesSelector(t));
    const formValues = useSelector(getFormValues('ParserForm'));
    const initialValues = useSelector(initialValuesSelector(t));
    const parserOptions = parserOptionsSelector(t);
    const headersRowSelector = useSelector(headerRowSelector);
    const headerRowType = useSelector(headerRowTypeSelector);
    const confirmData = useSelector(confirmDataSelector);
    const [ collapsedInfo, setCollapsedInfo ] = useState(true);
    const { error, loading } = formData;
    const dispatch = useDispatch();

    useEffect(() => {
        if (headersRowSelector.length) {
            setCollapsedInfo(false);
            dispatch(setHeaderRowType(headersRowSelector));
        }
    }, [ headersRowSelector ]);

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        };
    }, []);

    const getTitleText = t('socialNetworkGrabberConfiguration');

    const getPanelHeader = () =>
        <strong className='panel__header'>
            { getTitleText }
        </strong>;

    const onSubmit = value => {
        const type = value.parser.value;
        const qqlOnBase64= btoa(value.qql);
        const data = {
            title: value.title,
            language: value?.language?.value || null,
            headerRowType: headerRowType.map(value => value.type),
            status: value.status === '1' ? 'active': 'stopped',
            choiceDelimiter: value.choiceDelimiter,
            startSyncFrom: HelperService
                .formatCapturedAtDate(value.startSyncFrom)
                .replace(',', ''),
            settings: {
                login: value.login,
                password: value.password,
                profileId: value.profileId,
                qql: qqlOnBase64,
            },
        };

        dispatch(createImport(type, data))
            .promise.then(() => {
                handleDirtyStatusConfirm({ dirty: false });
                history.push(UPLOAD_LIST_ROUTE);
            });
    };

    const handleGetQuintlyHeaders = data => dispatch(getQuintlyHeaders(data));

    const handleDirtyStatusConfirm = data => dispatch(dirtyStatusConfirm(data));

    return (
        <DocumentTitle title={ getTitleText }>
            <Card className='vochub-card'>
                <CardHeader>{ getPanelHeader() }</CardHeader>
                <CardBody className='upload-social-network_card-body'>
                    <Row>
                        <Col md={ 6 }>
                            <UploadSocialNetworkForm
                                cleanup={ cleanup }
                                languages={ languages }
                                getQuintlyHeaders={ handleGetQuintlyHeaders }
                                onSubmit={ onSubmit }
                                formValues={ formValues }
                                initialValues={ initialValues }
                                parserOptions={ parserOptions }
                                loading={ loading }
                                disabledSubmit={ !headerRowType.length }
                                dirtyStatusConfirm={ handleDirtyStatusConfirm }
                                isDirty={ confirmData.dirty }
                            />
                        </Col>
                        <Col md={ 6 }>
                            <Info collapse={ collapsedInfo } />
                            {
                                <VerificationSection
                                    error={ error }
                                    loading={ loading }
                                    headers={ headerRowType }
                                    headerTypes={ headerTypes }
                                />
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </DocumentTitle>
    );
};
