import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';

import {
    VocTableFooter,
    VocTableHeader,
    VocTableTooltipFormatter,
    VocTableProgressFormatter,
    VocTableDateFormatter,
    VocTableCheckboxHeader,
} from './components';

import { ThinSpinner } from '/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autobind } from 'core-decorators';

import './VocTableV2.scss';

@withTranslation()
export class VocTableV2 extends Component {
    static formatters = {
        tooltip: VocTableTooltipFormatter,
        progress: VocTableProgressFormatter,
        date: VocTableDateFormatter,
        conditionTooltip: VocTableTooltipFormatter,
    };

    tableHeaderRef = React.createRef();
    tableBodyRef = React.createRef();

    componentDidMount() {
        window.addEventListener('scroll', this.windowScrollHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.windowScrollHandler);
    }

    @autobind
    windowScrollHandler() {
        const tableHeader = this.tableHeaderRef.current;
        const tableContainer = this.tableBodyRef.current;
        const containerTop = tableContainer.getClientRects()[0].top;
        const sizeHeader = document.getElementsByClassName('header')[0].clientHeight;

        if (containerTop < sizeHeader) {
            tableHeader.classList.add('voc-table__header--sticky');
            tableHeader.style.top = Math.abs(containerTop - sizeHeader) + 'px';
        } else {
            tableHeader.classList.remove('voc-table__header--sticky');
            tableHeader.style.width = '100%';
        }
    }

    @autobind
    onSortHeaderCellClick(event) {
        let { sort_field } = event.currentTarget.dataset;
        const { onSortChange } = this.getTableOptions();
        let sort_order;

        if (sort_field !== this.props.filters.sort_field) {
            sort_order = 'asc';
            onSortChange({ sort_field, sort_order });
            return;
        }

        switch (this.props.filters.sort_order) {
            case '': {
                sort_order = 'asc';
                break;
            }
            case 'asc': {
                sort_order = 'desc';
                break;
            }
            case 'desc': {
                sort_order = '';
                sort_field = '';
            }
        }

        onSortChange({ sort_field, sort_order });
    }

    @autobind
    onFilterHeaderCellClick(event) {
        const { dataset: { sort_field }, id } = event.currentTarget;

        this.props.options.openPopoverFilter(id, sort_field);
    }

    getSortCaretIcon(field) {
        const { filters: { sort_order } } = this.props;

        return sort_order
            ? <FontAwesomeIcon
                data-sort_field={ field }
                icon={ sort_order === 'asc' ? 'angle-down' : 'angle-up' }/>
            : null;
    }

    @autobind
    mapTableHead() {
        const { dataSettings } = this.props;

        return dataSettings.map((datum, index) => {
            return (
                <th
                    key={ index }
                    className='voc-table_th-cell'
                    style={ datum.style }
                >
                    { this.getTableHeadControllers(datum, index) }
                </th>
            );
        });
    }

    @autobind
    getTableHeadControllers(tableHeadSettings, index) {
        const { data } = this.props;
        const { filter, sort, field, title, addToSelected } = tableHeadSettings;

        if (filter || sort) {
            return <div className='th-contentWrapper'>
                {
                    filter && this.getHeaderFilter(filter, field, title, index)
                }
                {
                    sort
                        ? this.getHeaderSort(field, title)
                        : <span>{ title }</span>
                }
            </div>;
        } else if (addToSelected) {
            return <div className='export-results__checkbox-container'>
                <VocTableCheckboxHeader
                    data={ data }
                    addToSelected={ addToSelected }
                />
            </div>;
        } else {
            return <span>{ title }</span>;
        }
    }

    getHeaderFilter(filter, field, title, index) {
        const { extraFilters } = this.props.filters;
        const { options } = this.props;
        const hasFilters = Object.keys(extraFilters).filter(el => extraFilters[el].length);

        return (
            <div
                className={ cx('th-filter-header-cell', { 'filter-active': hasFilters.includes(field) }) }
                id={ `${ title }${ index }` }
                data-sort_field={ field }
                onClick={ this.onFilterHeaderCellClick }
            >
                {
                    options.filterField === field ? filter() : ''
                }
                <FontAwesomeIcon icon={ 'filter' }/>
            </div>
        );
    }

    getHeaderSort(field, title) {
        const { sort_field } = this.props.filters;

        return (
            <div
                className='th-sort-header-cell'
                data-sort_field={ field }
                onClick={ this.onSortHeaderCellClick }
            >
                <span>{ title }</span>
                {
                    sort_field === field
                        ? this.getSortCaretIcon(field)
                        : <FontAwesomeIcon
                            data-sort_field={ field }
                            icon='sort'
                        />
                }
            </div>
        );
    }

    getTableCellFormatter(formatter) {
        return formatter
            ? VocTableV2.formatters[formatter]
            : null;
    }

    mapRow(item) {
        const { dataSettings } = this.props;

        return dataSettings.map(({ field, formatter, style, mask, currentDateFormat = null, forceRerender = null }, index) => {
            const dataField = item[field];

            if (typeof formatter === 'function') {
                const keyIndex = !forceRerender ? index : index + item.external_id;

                return (
                    <td
                        key={ keyIndex }
                        style={ style }
                        {
                            ...field === 'control'
                                ? { 'data-control': true }
                                : {}
                        }
                    >
                        { formatter(dataField, item) }
                    </td>
                );
            }

            const TableCellFormatter = this.getTableCellFormatter(formatter);

            return (
                <td key={ index } style={ style }>
                    {
                        TableCellFormatter
                            ? <TableCellFormatter
                                field={ dataField }
                                mask={ mask }
                                currentDateFormat={ currentDateFormat }
                            />
                            : dataField
                    }
                </td>
            );
        });
    }

    mapTableBodyRows() {
        const { data, options: { rowStyle }, openedRowId, mapAdditionalRow } = this.props;

        return data.map((item, index) => {
            return <Fragment key={ index }>
                <tr
                    style={ rowStyle }
                    className={ item.rowClass }
                    {
                        ...item.control
                            ? { 'data-id': item.id }
                            : {}
                    }
                >
                    { this.mapRow(item) }
                </tr>
                {
                    openedRowId && item.id === openedRowId
                        ? <>
                            { mapAdditionalRow?.(item.additionalRow) }
                        </>
                        : null
                }
            </Fragment>;
        });
    }

    getEmptyTable() {
        const { dataSettings, t } = this.props;
        const cellsLength = dataSettings.length;

        return (
            <tr className='voc-table__empty'>
                <td colSpan={ cellsLength }>
                    { t('table.noData') }
                </td>
            </tr>
        );
    }

    getTableOptions() {
        const defaultOptions = {
            onSortChange: this.onSortChange,
            onLimitChange: this.onLimitChange,
            onSearchType: this.onSearchType,
            onPageChange: this.onPageChange,
            goToPage: {
                show: true,
                closeOnChange: true,
            },
        };

        return { ...defaultOptions, ...this.props.options };
    }

    @autobind
    onSortChange({ sort_field, sort_order }) {
        const { options: { updateFilters, requestTrigger }, filters } = this.props;

        updateFilters({ sort_field, sort_order });
        requestTrigger?.({ ...filters, sort_field, sort_order });
    }

    @autobind
    onPageChange(page) {
        const { options: { updateFilters, requestTrigger }, filters } = this.props;

        updateFilters({ page });
        requestTrigger?.({ ...filters, page });
    }

    @autobind
    onLimitChange(limit) {
        const { options: { updateFilters, requestTrigger }, filters } = this.props;

        updateFilters({ limit, page: 1 });
        requestTrigger?.({ ...filters, limit, page: 1 });
    }

    @autobind
    onClickRow(e) {
        const { onOpenRow } = this.props;

        onOpenRow?.(e);
    }

    @autobind
    onSearchType(search) {
        const { options: { updateFilters, requestTrigger }, filters } = this.props;

        updateFilters({ search, page: 1 });
        requestTrigger?.({ ...filters, search, page: 1 });
    }

    render() {
        const {
            onPageChange,
            onLimitChange,
            rowStyle,
            onSearchType,
            goToPage,
            haveSearch,
            searchPlaceholder,
            customTableHeader,
        } = this.getTableOptions();

        const {
            filters: {
                limit,
                search,
                page,
            },
            data,
            total,
            loading,
            options,
        } = this.props;

        return (
            <div className={ cx('voc-table__container', { 'voc-table__container-loading': loading }) }>
                <VocTableHeader
                    haveSearch={ haveSearch }
                    placeholder={ searchPlaceholder }
                    search={ search }
                    onSearchType={ onSearchType }
                >
                    { customTableHeader }
                </VocTableHeader>
                <div className={ cx('voc-table__wrapper', { 'voc-table__wrapper-loading': loading }) }
                    ref={ this.tableBodyRef }>
                    { loading && <ThinSpinner/> }
                    <Table striped hover className='voc-table'>
                        <thead ref={ this.tableHeaderRef } className='voc-table__header'>
                            <tr style={ rowStyle }>
                                { this.mapTableHead() }
                            </tr>
                        </thead>
                        <tbody className='voc-table__body' onClick={ this.onClickRow }>
                            {
                                data.length
                                    ? this.mapTableBodyRows()
                                    : this.getEmptyTable()
                            }
                        </tbody>
                    </Table>
                </div>
                <VocTableFooter
                    total={ total }
                    page={ page }
                    limit={ limit }
                    withoutPagination={ options.withoutPagination }
                    goToPage={ goToPage }
                    onPageChange={ onPageChange }
                    onLimitChange={ onLimitChange }
                />
            </div>

        );
    }
}

VocTableV2.propTypes = {
    data: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    dataSettings: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        formatter: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),
        style: PropTypes.shape({
            width: PropTypes.string,
            textAlign: PropTypes.string,
        }),
        sort: PropTypes.bool,
        mask: PropTypes.string,
        addToSelected: PropTypes.func,
    })).isRequired,

    filters: PropTypes.shape({
        sort_order: PropTypes.string,
        sort_field: PropTypes.string,
        search: PropTypes.string,
        limit: PropTypes.number.isRequired,
        page: PropTypes.number.isRequired,
    }).isRequired,

    options: PropTypes.shape({
        onSortChange: PropTypes.func,
        updateFilters: PropTypes.func,
        haveSearch: PropTypes.bool,
        requestTrigger: PropTypes.func,
        goToPage: PropTypes.shape({
            show: PropTypes.bool,
            closeOnChange: PropTypes.bool,
        }),
        rowStyle: PropTypes.shape({
            height: PropTypes.string,
        }),
        customTableHeader: PropTypes.any,
        onSearchType: PropTypes.func,
        onPageChange: PropTypes.func,
        onLimitChange: PropTypes.func,
    }).isRequired,

    openedRowId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([ null ]),
    ]),
    mapAdditionalRow: PropTypes.func,
    onOpenRow: PropTypes.func,
};
