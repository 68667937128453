import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

import './LayoutActionsFormatter.scss';

export const LayoutActionsFormatter = ({
    permission: { isPermitEdit },
    ...props
}) => {
    const openModalManage = () => props.openModalManage(props.layout);
    const openConfirmDelete = () => isPermitEdit && props.openConfirmDelete(props.layout);
    const openModalCopy = () => isPermitEdit && props.openModalCopy(props.layout);

    return (
        <div
            id={ 'table-action__container' }
            className='table-action__container table-action__layout-list'>
            <TooltipWrapper value={ 'Edit layout' } force>
                <span
                    className={ `table-action__exporters-list-icon` }
                    onClick={ openModalManage }>
                    <FontAwesomeIcon icon="cog"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Copy layout' } force>
                <span
                    className={ cx(
                        'table-action__layout-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ openModalCopy }>
                    <FontAwesomeIcon icon="copy"/>
                </span>
            </TooltipWrapper>

            <TooltipWrapper value={ 'Delete layout' } force>
                <span
                    className={ cx(
                        'table-action__layout-list-icon',
                        { 'disabled': !isPermitEdit },
                    ) }
                    onClick={ openConfirmDelete }>
                    <FontAwesomeIcon icon="trash"/>
                </span>
            </TooltipWrapper>
        </div>
    );
};
