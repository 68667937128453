import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label, Col, Button, ButtonGroup, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import './ColumnButtonGroup.scss';

export const ColumnButtonGroup = ({
    columnTypes,
    onColumnTypeSelect,
    column,
}) => {
    const { t } = useTranslation();
    const statusIcons = {
        ok: {
            icon: 'check-circle',
            class: 'info',
            tooltip: t('uploadStatus.ok'),
        },
        absent: {
            icon: 'minus-circle',
            class: 'danger',
            tooltip: t('uploadStatus.absent'),
        },
        new: {
            icon: 'plus-circle',
            class: 'success',
            tooltip: t('uploadStatus.new'),
        },
    };

    const getStatusMarkerClass = columnStatus => `column-status-marker column-status-marker__${ statusIcons[columnStatus].class }`;

    const onButtonClick = event => {
        const { type } = event.target.dataset;
        const unsetFlag = column.type === type;

        onColumnTypeSelect(column.id, type, unsetFlag);
    };

    const getStatusMarker = () => {
        const { status } = column;

        if (status) {
            const { icon } = statusIcons[status];

            return (
                <FontAwesomeIcon icon={ icon } className={ getStatusMarkerClass(status) }/>
            );
        }
    };

    const disableButtonHandler = (type, column) => type === 'LANG' && column.disabledLang;

    const mapButtonTypes = () =>
        columnTypes.map((type, i) => {
            const color = column.type === type ? 'primary' : 'white';

            return (
                <Button color={ color }
                    outline={ column.type !== type }
                    className="column-btn-type_button"
                    data-type={ type }
                    disabled={ column.disabledRow || disableButtonHandler(type, column) }
                    key={ i }
                    onClick={ onButtonClick }
                >
                    { type }
                </Button>
            );
        });

    return (
        <FormGroup row className="col-column-btn-group_form-group">
            <Col md={ 12 } className="col-column-btn-group">
                <Label className="label__column-title">
                    { column.id }
                </Label>
                <ButtonGroup className="column-buttons-group">
                    { getStatusMarker() }
                    { mapButtonTypes() }
                </ButtonGroup>
            </Col>
        </FormGroup>
    );
};

ColumnButtonGroup.propTypes = {
    onColumnTypeSelect: PropTypes.func.isRequired,
    columnTypes: PropTypes.array,
    column: PropTypes.object.isRequired,
};
