import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DynamicFormButton = ({ button, addNewForm }) => {
    return (
        <Button color="primary" className='dynamic-form_btn' onClick={ addNewForm }>
            <FontAwesomeIcon icon='plus-circle'/> { button || 'Add' }
        </Button>
    );
};
