import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';

@withTranslation()
export class ConfirmModificationVirtualContent extends PureComponent {
    mapSourceData() {
        const { virtualRelation, t } = this.props;

        const sourceDataCollections = {
            'gadgets': t('gadgets'),
            'segmentators': t('recoders'),
            'virtualSources': t('virtualSources'),
            'queries': t('queries'),
        };

        return Object.entries(sourceDataCollections)
            .map(([ sourceDataCollection, title ]) => {
                if (virtualRelation[sourceDataCollection].length) {
                    return (
                        <details key={ title }>
                            <summary>{ title }</summary>
                            <ul>
                                { this.mapCollectionItems(sourceDataCollection) }
                            </ul>
                        </details>
                    );
                }
            });
    }

    mapCollectionItems(collection) {
        const { virtualRelation } = this.props;

        return virtualRelation[collection].map((item, i) => <li key={ i }> { item }</li>);
    }

    render() {
        const { virtualRelation, t } = this.props;
        const {
            gadgets,
            segmentators,
            virtualSources,
            queries,
        } = virtualRelation;

        return (
            <div>
                <p>
                    {
                        t('modificationVirtualSourceInfo', {
                            virtualSourcesCount: virtualSources.length,
                            segmentatorsCount: segmentators.length,
                            queriesCount: queries.length,
                            gadgetsCount: gadgets.length,
                        })
                    }
                </p>
                <strong>{ `${ t('details') }:` }</strong>
                <br/>
                { this.mapSourceData() }
                <br/>
                <p>
                    <Trans i18nKey={ 'modificationVirtualSourceQuestion' } />
                </p>
            </div>
        );
    }
}

ConfirmModificationVirtualContent.propTypes = {
    virtualRelation: PropTypes.object,
};
