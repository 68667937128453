import { max, min, event, line, curveMonotoneX, format } from 'd3';
import cx from 'classnames';
import { autobind } from 'core-decorators';

import type {
    IBaseChartService,
    lineItem,
    lineLabel,
    dotData,
    IDrawLines,
    lineItemDataType,
} from '/visual/scenes/Dashboard/components/Gadget/models';
import { HelperService } from '/services';
import { BaseChartService } from '../../BaseChart/services';

import styles from '../style.module.scss';

export class LineChartService extends BaseChartService {
    /* public constants */
    groupItemClass = 'lineGroup';
    dotClass = 'circle';
    linePathClass = 'line-path';
    scoreClass = 'circleScore';

    /* class properties */
    lines: lineItem[];
    labels: lineLabel[];
    level: number;
    lineFn: any;

    constructor(props: IBaseChartService) {
        super(props);

        this.lines = [];
        this.labels = [];
        this.level = 0;
        this.lineFn = line<any>()
            .defined((d: dotData) => d.value !== null)
            .x(this._getLineX)
            .y(this._getLineY)
            .curve(curveMonotoneX);
    }

    /* protected methods */
    @autobind
    protected _getValue(d: dotData) {
        return this._percentage ? this._percentToDecimal(d.value || 0) : d.value;
    }

    @autobind
    protected _getDataByMode(d: lineItem) {
        return this._percentage ? d.data.relative : d.data.absolute;
    }

    @autobind
    protected _getFormattedDotData(d: lineItem, parentIndex: number) {
        return this._getDataByMode(this.lines[parentIndex])
            .map((v: number | null, index: number) => ({
                value: v,
                lineIndex: parentIndex,
                customId: `circle_${d.customId}_${index}`,
            }));
    }

    @autobind
    protected _getLineX(d: dotData, index: number) {
        return this.xScale(this.labels[index].id) + this.xScale.bandwidth() / 2;
    }

    @autobind
    protected _getLineY(d: dotData) {
        return this.yScale(this._getValue(d));
    }

    @autobind
    protected _getLineScoreY(d: dotData) {
        const { circleTextPositiveOffset } = this.chartConfig;

        return this._getLineY(d) - circleTextPositiveOffset;
    }

    @autobind
    protected _getDotColor(d: dotData) {
        return this.lines[d.lineIndex].color;
    }

    @autobind
    protected _getDotId(d: dotData) {
        return d.customId;
    }

    @autobind
    protected _getDotClass(d: dotData) {
        return cx(
            this.dotClass,
            {
                [styles.transparentCircle]: d.value === null,
                [this.interactiveClass]: this.drillDownEnabled,
            },
        );
    }

    @autobind
    protected _getLineColor(d: lineItem) {
        const changedLineIndex = this.lines.findIndex(l => l.id === d.id);

        return this.lines[changedLineIndex].color;
    }

    @autobind
    protected _getDotScore(d: dotData) {
        const value = d.value !== null ? d.value : '';

        return value && this._percentage ? format('.1%')(+value / 100) : value;
    }

    @autobind
    protected _onColorPickerOpen(d: dotData) {
        const line = this.lines[d.lineIndex];

        this._setColorPickerData({
            open: true,
            coords: { y: event.pageY, x: event.pageX },
            target: `${this.chartType}_colorPicker_${this.gadgetId}`,
            color: line.color,
            elementId: line.id,
        });

        event.preventDefault();
    }

    @autobind
    protected _dotEnter(d: dotData, index: number) {
        const currentLine = this.lines[d.lineIndex];

        const data = {
            label: currentLine.label,
            percent: currentLine.data.relative[index],
            score: currentLine.data.absolute[index],
        };

        this._onItemEnter(data);
    }

    @autobind
    protected _dotOver(d: dotData) {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        this._onItemOverMove();
        const highlightedLineId = this.lines[d.lineIndex].id;

        this.setHighlightedLine(highlightedLineId, d.customId);
    }

    @autobind
    protected _dotOut() {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        const { target } = event;

        this.setHighlightedLine(null);

        this.toolTipRef
            .style('visibility', 'hidden')
            .html('');

        target.classList.remove(this.itemHoveredClass);
    }

    @autobind
    protected _dotClick(d: dotData, index: number) {
        this._drillDownFromSelection?.({
            group: this.lines[d.lineIndex],
            superGroup: this.labels[index],
            level: this.level,
        });
    }

    @autobind
    protected _getLinePath(d: lineItem) {
        // find index by actual lineList, not inside already rendered
        // user could swap label and order of the already rendered lines would be irrelevant
        const lineDataSet: dotData[] = this._getFormattedDotData(d, this.lines.findIndex(l => l.id === d.id));

        return this.lineFn(lineDataSet);
    }

    @autobind
    protected _buildDots(selection: any) {
        selection
            .selectAll(`.${this.dotClass}`)
            .data(this._getFormattedDotData)
            .join(
                (enter: any) => {
                    enter
                        .append('circle')
                        .attr('tabindex', 0)
                        .attr('r', this.chartConfig.dotRadius)
                        .attr('cx', this._getLineX)
                        .attr('cy', this._getLineY)
                        .attr('fill', this._getDotColor)
                        .attr('id', this._getDotId)
                        .attr('class', this._getDotClass)
                        // add event listeners for each line
                        .on('contextmenu', this._onColorPickerOpen)
                        .on('mouseenter', this._dotEnter)
                        .on('mouseover', this._dotOver)
                        .on('mousemove', this._onItemOverMove)
                        .on('mouseout', this._dotOut)
                        .on('click', this._dotClick);
                },
                (update: any) => {
                    update
                        .attr('cx', this._getLineX)
                        .attr('cy', this._getLineY)
                        .attr('class', this._getDotClass)
                        .attr('fill', this._getDotColor)
                        // add event listeners for each line
                        .on('contextmenu', this._onColorPickerOpen)
                        .on('mouseenter', this._dotEnter)
                        .on('mouseover', this._dotOver)
                        .on('mousemove', this._onItemOverMove)
                        .on('mouseout', this._dotOut)
                        .on('click', this._dotClick);
                },
            );

        return selection;
    }

    @autobind
    protected _appendLine(selection: any) {
        selection
            .append('path')
            .attr('class', cx(this.linePathClass))
            .attr('d', this._getLinePath)
            .attr('stroke', this._getLineColor)
            .attr('fill', 'none')
            .attr('stroke-width', this.chartConfig.lineStrokeWidth);

        return selection;
    }

    @autobind
    protected _updateLine(selection: any) {
        selection
            .selectAll(`.${this.linePathClass}`)
            .attr('d', this._getLinePath)
            .attr('stroke', this._getLineColor);
    }

    @autobind
    protected _appendScore(selection: any) {
        selection
            .selectAll(`.${this.scoreClass}`)
            .data(this._getFormattedDotData)
            .join(
                (enter: any) => {
                    enter
                        .append('text')
                        .attr('x', this._getLineX)
                        .attr('y', this._getLineScoreY)
                        .attr('class', cx(this.scoreClass, styles.circleNumber))
                        .text(this._getDotScore);
                },
                (update: any) => {
                    update
                        .attr('x', this._getLineX)
                        .attr('y', this._getLineScoreY)
                        .text(this._getDotScore);
                },
            );

        return selection;
    }

    /* public methods */
    @autobind
    setHighlightedLine(highlightedLabel: string | number | null, dotCustomId?: string) {
        super._setHighlightedLine({
            highlightedLineId: highlightedLabel,
            dotCustomId,
            lineGroupClass: this.groupItemClass,
            dotClass: this.dotClass,
            lineClass: this.linePathClass,
        });
    }

    getDomain({ array = [] }: { array: lineItem[] }) {
        let minValue, maxValue;

        const isDataExist = (line: lineItem, key: string) => {
            const lineDataByKey = line?.data
                ? line?.data[key as keyof lineItemDataType]
                : null;

            return lineDataByKey?.length !== 0
                ? lineDataByKey
                : null;
        };

        const getValuesHandler = ({
            line,
            lineKey,
            handler,
            valuesFormatter,
        }: {
            line: lineItem,
            lineKey: string,
            handler: any,
            valuesFormatter?: (p: number | null) => number,
        }) => {
            const lineList = isDataExist(line, lineKey);

            if (!lineList) return 0;

            const values = (Array.isArray(lineList)
                ? lineList.filter((v: number | null) => v !== null)
                : [ lineList ])
                .map((v: number | null) => valuesFormatter?.(v) || v);

            return handler(values);
        };

        if (this._percentage) {
            minValue = 0;
            maxValue = max(array, (line: lineItem) => getValuesHandler({
                line,
                lineKey: 'relative',
                handler: max,
                valuesFormatter: this._percentToDecimal,
            })) || 0;
        } else {
            maxValue = max(array, (line: lineItem) => getValuesHandler({
                line,
                lineKey: 'absolute',
                handler: max,
            })) || 0;

            minValue = min(array, (line: lineItem) => getValuesHandler({
                line,
                lineKey: 'absolute',
                handler: min,
            })) || 0;
        }

        this.maxYValue = maxValue;

        return [ minValue, maxValue ];
    }

    drawLines({ lines, labels, level }: IDrawLines) {
        this.lines = lines;
        this.labels = labels;
        this.level = level;

        // init line container (container for circles, texts and path-line)
        this.chartContainer
            .select(`.${this.elementsContainerClass}`)
            .selectAll(`.${this.groupItemClass}`)
            .data(lines, (l: lineItem) => l.id)
            .join(
                (enter: any) => {
                    enter
                        .append('g')
                        .attr('class', cx(this.groupItemClass, styles.lineGroup))
                        .call(this._appendLine)
                        // update each dots on each line inside chart
                        .call(this._buildDots)
                        .call((selection: any) => {
                            if (this._showScore) {
                                this._appendScore(selection);
                            }
                        });
                },
                (update: any) => {
                    // remove line container group if it doesn't exist in dataset
                    update
                        .exit()
                        .remove();

                    update
                        // update each lines inside chart
                        .call(this._updateLine)
                        .call(this._buildDots)
                        .call((selection: any) => {
                            if (this._showScore) {
                                this._appendScore(selection);
                            }
                        });
                },
            );

        return this;
    }
}
