import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { autobind } from 'core-decorators';
import { PageRequests } from '/services';
import { VocCleaupHOC, Preloader, showToast } from '/components';
import { SettingsForm } from "/feedback/components";
import { SettingsService } from '/feedback/services/SettingsService';
import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';

import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';
import { activeLanguageSelector, rightsSurveySelector, themeSettingsSelector } from '../../modules/survey.selectors';
import {
    getLanguageSettings,
    cleanup,
    putSurveyLanguageSettings,
    getStaticLink,
    getDigiShortenedLink,
    manegeSurveyThemeLang,
    createUpdateSurveyThemeLang,
} from './modules/surveyLanguages.modules';
import {
    surveyFormSettingsSelector,
    idLanguagesSelector,
    themeLanguageSettingsSelector,
} from './modules/surveyLanguages.selectors';

import './SurveyLanguages.scss';

const mapStateToProps = state => ({
    activeSurvey: activeSurveySelector(state),
    activeLang: activeLanguageSelector(state),
    surveySettings: surveyFormSettingsSelector(state),
    themeSettings: themeSettingsSelector(state),
    themeLanguageSettings: themeLanguageSettingsSelector(state),
    idActiveLang: idLanguagesSelector(state),
    assets: assetsSelector(state),
    rightsSurvey: rightsSurveySelector(state),
});

const mapDispatchToProps = {
    getLanguageSettings,
    cleanup,
    putSurveyLanguageSettings,
    createUpdateSurveyThemeLang,
    getStaticLink,
    getDigiShortenedLink,
    manegeSurveyThemeLang,
    showToast,
    getAssets,
    uploadAsset,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class SurveyLanguages extends Component {
    pageRequests = new PageRequests();

    state = {
        checked: false,
        testValue: {
            value: 0,
            label: 0,
        },
    };

    componentDidMount() {
        const { channel } = this.props.activeSurvey;

        channel && this.getLanguageSettings();
    }

    componentDidUpdate(prevProps) {
        const { channel } = this.props.activeSurvey;

        if (channel !== prevProps.activeSurvey.channel || this.props.activeLang !== prevProps.activeLang) {
            this.getLanguageSettings();
        }
    }

    @autobind
    getLanguageSettings() {
        this.pageRequests.cleanup();
        const {
            idActiveLang,
            activeLang,
            getLanguageSettings,
            activeSurvey: {
                channel,
                survey_group,
                unit,
                id: channelId,
            },
        } = this.props;

        const model = {
            channel,
            id: survey_group,
            channelId,
            language: activeLang,
            unit,
            idActiveLang,
        };

        const requests = getLanguageSettings(model);

        requests.forEach(request => this.pageRequests.addRequest(request));
    }

    @autobind
    putSurveyLanguageSettings(body) {
        const {
            putSurveyLanguageSettings,
            idActiveLang,
            activeLang: language,
            activeSurvey: {
                channel,
                survey_group,
                id: channelId,
            },
        } = this.props;

        const model = {
            channel,
            id: survey_group,
            channelId,
            language,
            body,
        };

        return channel === 'DIGI'
            ? this.putSurveyLanguageSettingsForDigi({ ...model, idActiveLang, surveyGroupId: survey_group })
            : putSurveyLanguageSettings(model);
    }

    @autobind
    putSurveyLanguageSettingsForDigi(model) {
        const {
            manegeSurveyThemeLang,
            createUpdateSurveyThemeLang,
            activeLang,
            activeSurvey: {
                id: idChannel,
            },
            themeSettings: {
                global_theme,
                parent_unit_theme,
                survey_theme,
            },
            themeLanguageSettings: {
                survey_theme_language,
            },
        } = this.props;

        const getIdParentTheme = () => {
            let parent_theme = global_theme ? global_theme.id : null;

            parent_theme = parent_unit_theme ? parent_unit_theme.id : parent_theme;

            return survey_theme ? survey_theme.id : parent_theme;
        };

        if (survey_theme_language) {
            return createUpdateSurveyThemeLang(null, {
                ...model,
                id: survey_theme_language.id,
                digi_theme: survey_theme.id,
                language: survey_theme_language.language,
                languageIso: activeLang,
            });
        } else if (survey_theme) {
            const digi_theme = getIdParentTheme();

            return manegeSurveyThemeLang({
                digi_theme,
                language: model.idActiveLang,
                model: {
                    ...model,
                    language: model.idActiveLang,
                    languageIso: activeLang,
                },
            });
        } else {
            const parent_theme = getIdParentTheme();

            return manegeSurveyThemeLang({
                name: `new survey theme for ${ idChannel }`,
                survey: idChannel,
                parent_theme,
                digi_theme: null,
                language: model.idActiveLang,
                model: {
                    ...model,
                    language: model.idActiveLang,
                    languageIso: activeLang,
                },
            });
        }
    }

    @autobind
    getStaticLink() {
        const { channel } = this.props.activeSurvey;
        const isDigi = channel === 'DIGI';
        const model = {
            [isDigi ? 'survey_id' : 'id']: this.props.activeSurvey.id,
            language: this.props.activeLang,
        };

        return isDigi
            ? this.props.getDigiShortenedLink(model).promise
            : this.props.getStaticLink(model).promise;
    }

    @autobind
    initRunnerDemo() {
        const {
            activeLang,
            activeSurvey: {
                id,
            },
        } = this.props;

        SettingsService.showDigiPopup({ language: activeLang, id });
    }

    getSettingsBody() {
        const {
            surveySettings: {
                loading,
                sending,
                fieldsMap,
                initialValues,
            },
            activeSurvey: {
                channel,
                state,
            },
            showToast,
            assets,
            getAssets,
            uploadAsset,
            rightsSurvey,
        } = this.props;

        return (
            loading
                ? <Preloader/>
                : <SettingsForm
                    fieldsMap={ fieldsMap }
                    showToast={ showToast }
                    getLanguageSettings={ this.getLanguageSettings }
                    putSettings={ this.putSurveyLanguageSettings }
                    getStaticLink={ this.getStaticLink }
                    initRunnerDemo={ this.initRunnerDemo }
                    channel={ channel }
                    languageSettings={ true }
                    initialValues={ initialValues }
                    sendingSettings={ sending }
                    form={ 'SurveyLanguagesForm' }
                    assets={ assets }
                    getAssets={ getAssets }
                    uploadAsset={ uploadAsset }
                    permission={ rightsSurvey }
                    activeSurveyState={ state }
                />
        );
    }

    render() {
        const {
            activeSurvey: {
                channel,
            },
        } = this.props;

        return (
            <DocumentTitle title="Feedback :: Survey Languages">
                <Fragment>
                    {
                        channel
                            ? this.getSettingsBody()
                            : <Alert color={ 'info' } className={ 'text-center' }>
                                Please activate or select an activated channel first
                            </Alert>
                    }
                </Fragment>
            </DocumentTitle>
        );
    }
}
