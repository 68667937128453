import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Button, Row } from 'reactstrap';
import { AddressListItem } from '../AddressListItem';

import './CustomerTableCellExpandFormatter.scss';

export class CustomerTableCellExpandFormatter extends Component {
    state = {
        showHiddenItems: false,
    };

    @autobind
    showMoreItems() {
        this.setState({
            showHiddenItems: true,
        });
    }

    render() {
        const { hiddenLength, shouldCollapse, visibleItems, hiddenItems, className } = this.props;
        const { showHiddenItems } = this.state;

        return (
            <Row className={ className }>
                {
                    visibleItems.map((item, index) => <AddressListItem { ...item } key={ index }/>)
                }
                {
                    (shouldCollapse && !showHiddenItems)
                    && <Button color="link" onClick={ this.showMoreItems }>
                        ...show more items({ hiddenLength })
                    </Button>
                }
                {
                    showHiddenItems && hiddenItems.map((item, index) => <AddressListItem { ...item }
                        key={ index }/>)
                }
            </Row>
        );
    }
}

