import React from "react";
import PropTypes from "prop-types";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { autobind } from 'core-decorators';

export class VocTablePagination extends React.Component {
    state = { pager: {} };

    setPage(page) {
        const { pager } = this.state;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        this.props.onPageChange(page, this.props.name);
    }

    getPager() {
        const { limit, total, page } = this.props;

        // calculate total pages
        const totalPages = Math.ceil(total / limit);

        let startPage, endPage;

        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (page <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (page + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = page - 5;
                endPage = page + 4;
            }
        }

        // calculate start and end item indexes
        const startIndex = (page - 1) * limit;
        const endIndex = Math.min(startIndex + page - 1, total - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = [ ...Array((endPage + 1) - startPage).keys() ].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: total,
            currentPage: page,
            pageSize: limit,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages,
        };
    }

    @autobind
    prevClicked() {
        this.setPage(1);
    }

    @autobind
    nextClicked() {
        const pager = this.getPager();

        this.setPage(pager.totalPages);
    }

    @autobind
    pageClicked(event) {
        event.preventDefault();
        const page = parseInt(event.target.getAttribute("href"));

        if (page !== this.props.page) {
            this.setPage(page);
        }
    }

    render() {
        const { page } = this.props;
        const pager = this.getPager();

        if (!pager.pages || pager.pages.length <= 1) {
            return null;
        }

        return (
            <Pagination>
                {
                    page !== 1
                    && <PaginationItem>
                        <PaginationLink first onClick={ this.prevClicked }/>
                    </PaginationItem>
                }

                { pager.pages.map(page => {
                    return (
                        <PaginationItem key={ page } active={ page === this.props.page }>
                            <PaginationLink onClick={ this.pageClicked } href={ page }>
                                { page }
                            </PaginationLink>
                        </PaginationItem>
                    );
                }) }

                {
                    page !== pager.endPage
                    && <PaginationItem>
                        <PaginationLink last onClick={ this.nextClicked }/>
                    </PaginationItem>
                }
            </Pagination>
        );
    }
}

VocTablePagination.propTypes = {
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

VocTablePagination.defaultProps = {
    initialPage: 1,
};
