import { event, max, min } from 'd3';
import cx from 'classnames';
import { autobind } from 'core-decorators';

import type {
    barData,
    barSliceData,
    barSliceWithCoordinatesData,
    openColorPickerType,
    IDrawBars,
    IDrawXBandAxis,
    IBaseChartService,
} from '/visual/scenes/Dashboard/components/Gadget/models';
import { HelperService, TextService } from '/services';
import { BaseChartService } from '../../BaseChart/services';

import styles from '../../BaseChart/services/style.module.scss';

export class BarChartService extends BaseChartService {
    /* public constants */
    scoreClass = 'barScore';
    ZERO_BAR_HEIGHT = 1;
    groupsTextWidth: Record<number, number> = {};

    /* class properties */
    groups: barData[];

    constructor(props: IBaseChartService) {
        super(props);

        this.groups = [];
    }

    /* protected methods */
    @autobind
    protected _onColorPickerOpen(d: openColorPickerType) {
        this._setColorPickerData({
            open: true,
            coords: { y: event.pageY, x: event.pageX },
            target: `${this.chartType}_colorPicker_${this.gadgetId}`,
            color: d.color,
            elementId: d.id,
        });

        event.preventDefault();
    }

    protected _getPercent(d: barSliceData) {
        return d.percent;
    }

    protected _getScore(d: barSliceData) {
        return d.score;
    }

    @autobind
    protected _getValue(d: barSliceData | barSliceWithCoordinatesData): number {
        const value = this._percentage
            ? this._percentToDecimal(this._getPercent(d))
            : this._getScore(d);

        return HelperService.checkNotNullOrUndefined(value) ? value as number : 0;
    }

    @autobind
    protected _getWidthForSlice(d: barSliceData | barSliceWithCoordinatesData, i: number, e: string) {
        let size = this.xBandScale.bandwidth();

        if (e.length < this.maxCountSlicePerGroup) {
            size = this.xScale.bandwidth() / e.length;
        }

        return size;
    }

    @autobind
    protected _getHeightForSlice(d: barSliceData | barSliceWithCoordinatesData) {
        const score = this._getValue(d);

        return (score === 0)
            ? this.ZERO_BAR_HEIGHT
            : Math.abs(this.yScale(score) - this.yScale(0));
    }

    @autobind
    protected _getStartXForSlice(d: barSliceData | barSliceWithCoordinatesData, i: number, e: string) {
        let size = this.xBandScale(i);

        if (e.length < this.maxCountSlicePerGroup && i !== 0) {
            size = this.xScale.bandwidth() / e.length;
            size = size * i;
        }

        return size;
    }

    @autobind
    protected _getStartYForSlice(d: barSliceData | barSliceWithCoordinatesData) {
        const score = this._getValue(d);
        const slope = (score <= 0) ? 0 : 1;
        const minimalHeight = (score === 0) ? this.ZERO_BAR_HEIGHT / 2 : 0;
        const offset = (score <= 0) ? this.yScale(0) + minimalHeight : 0;

        return slope * this.yScale(score) + offset;
    }

    @autobind
    protected _getBarSliceColor(d: barSliceData | barSliceWithCoordinatesData) {
        return d.color;
    }

    @autobind
    protected _getBarSliceId(d: barSliceData | barSliceWithCoordinatesData) {
        return d.customId;
    }

    @autobind
    protected _getBarGroupOpacity(group: barData) {
        if (!HelperService.checkNotNullOrUndefined(this.draggedItemId)) return 1;

        return group.id === this.draggedItemId ? 1 : 0.4;
    }

    @autobind
    protected _animatedBarSliceHeight(d: barSliceData) {
        const score = this._getValue(d);
        const height = this._getHeightForSlice(d);
        const chartOffset = this.chartConfig[score < 0 ? 'marginBottom' : 'marginTop'];
        // 14 - height of score, 2 - just minimum offset from the title
        const maxAnimatedBarHeight = chartOffset - (this._showScore && score > 0 ? 14 : 2) + height;
        let animatedBarHeight = this.scaleYBand * height;

        // for the chart with large bar's height
        if (animatedBarHeight > maxAnimatedBarHeight) {
            animatedBarHeight = maxAnimatedBarHeight;
        }

        return animatedBarHeight;
    }

    @autobind
    protected _animatedBarSliceY(d: barSliceData) {
        const score = this._getValue(d);
        const scale = (score === 0) ? 0.5 : 1;

        return score < 0 ? this.yScale(0) : this.yScale(0) - scale * this._animatedBarSliceHeight(d);
    }

    @autobind
    protected _barTextX(d: barSliceData | barSliceWithCoordinatesData, i: number, e: string) {
        return this._getStartXForSlice(d, i, e) + this._getWidthForSlice(d, i, e) / 2;
    }

    @autobind
    protected _barTextY(d: barSliceData | barSliceWithCoordinatesData) {
        const { barTextYNegativeOffset, barTextYPositiveOffset } = this.chartConfig;
        const score = this._getValue(d);
        const offset = score < 0 ? this._getHeightForSlice(d) + barTextYNegativeOffset : -barTextYPositiveOffset;

        return this._getStartYForSlice(d) + offset;
    }

    @autobind
    protected _animatedBarTextY(d: barSliceData) {
        const { barTextYNegativeOffset, barTextYPositiveOffset } = this.chartConfig;
        const score = this._getValue(d);
        const offset = score < 0 ? this._getHeightForSlice(d) + barTextYNegativeOffset : -barTextYPositiveOffset;

        return this._animatedBarSliceY(d) + offset;
    }

    @autobind
    protected _barText(d: barSliceData, i: number, e: string) {
        const width = this._getWidthForSlice(d, i, e);

        this._decimalDigits === 'auto' && this.groupsTextWidthProcess(d, width);
        const scoreOrPercentMethod = this._percentage
            ? this._getPercent
            : this._getScore;

        let formattedNumber;

        if (this._decimalDigits?.toString()) {
            const byDecimalDigits
                = this._decimalDigits === 'auto' && HelperService.checkNotNullOrUndefined(d.groupIndex)
                    ? this.groupsTextWidth[d.groupIndex as number]
                    : this._decimalDigits;

            formattedNumber = parseFloat(scoreOrPercentMethod(d).toFixed(Number(byDecimalDigits)));
        } else {
            formattedNumber = scoreOrPercentMethod(d);
        }

        return this._percentage
            ? formattedNumber + '%'
            : formattedNumber;
    }

    @autobind
    protected _onItemOver(d: barSliceData | barSliceWithCoordinatesData) {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        this._onItemOverMove();

        const bars = this.chartContainer.selectAll(`.${this.groupItemClass}`);
        const barsScores = this.chartContainer.selectAll(`.${this.scoreClass}`);
        const curBar = bars.filter(( bar: { customId: string }) => bar.customId === d.customId);
        const curBarText = barsScores.filter(( bar: { customId: string }) => bar.customId === d.customId);

        curBar
            .transition()
            .duration(this.animationDuration)
            .attr('y', this._animatedBarSliceY)
            .attr('height', this._animatedBarSliceHeight)
            .attr('opacity', 0.4);

        curBarText
            .transition()
            .duration(this.animationDuration)
            .style('font-weight', 900)
            .attr('y', this._animatedBarTextY);
    }

    @autobind
    protected _onItemOut(d: barSliceData | barSliceWithCoordinatesData) {
        const { target } = event;
        const bars = this.chartContainer.selectAll(`.${this.groupItemClass}`);
        const barsScores = this.chartContainer.selectAll(`.${this.scoreClass}`);
        const curBar = bars.filter(( bar: { customId: string }) => bar.customId === d.customId);
        const curBarText = barsScores.filter(( bar: { customId: string }) => bar.customId === d.customId);

        target.classList.remove(this.itemHoveredClass);

        this.toolTipRef
            .style('visibility', 'hidden')
            .html('');

        curBar
            .transition()
            .duration(this.animationDuration)
            .attr('y', this._getStartYForSlice)
            .attr('height', this._getHeightForSlice)
            .attr('opacity', 1);

        curBarText
            .transition()
            .duration(this.animationDuration)
            .style('font-weight', 400)
            .attr('y', this._barTextY);
    }

    @autobind
    protected _onItemClick(d: barSliceData) {
        const parent = this.groups.find((group: barData) =>
            group.id === d.parentData?.id) || null;

        this._drillDownFromSelection?.({
            group: d,
            superGroup: parent && parent.id === 'root'
                // reset parent id for root item
                ? { ...parent, id: null }
                : parent,
        });
    }

    @autobind
    protected _enterBars(barGroups: any) {
        barGroups
            .selectAll(`.${this.groupItemClass}`)
            .data((d: barData) => d.bars)
            .join('rect')
            .attr('tabindex', 0)
            .attr('width', this._getWidthForSlice)
            .attr('height', this._getHeightForSlice)
            .attr('x', this._getStartXForSlice)
            .attr('y', this._getStartYForSlice)
            .attr('fill', this._getBarSliceColor)
            .attr('id', this._getBarSliceId)
            .attr('class', cx(this.groupItemClass, { [this.interactiveClass]: this.drillDownEnabled }))
            // add event listeners for each bar slice
            .on('contextmenu', this._onColorPickerOpen)
            .on('mouseenter', this._onItemEnter)
            .on('mouseover', this._onItemOver)
            .on('mousemove', this._onItemOverMove)
            .on('mouseout', this._onItemOut)
            .on('click', this._onItemClick);
    }

    @autobind
    protected _updateBars(update: any) {
        update
            .selectAll(`.${this.groupItemClass}`)
            .data((d: barData) => d.bars)
            .attr('width', this._getWidthForSlice)
            .attr('height', this._getHeightForSlice)
            .attr('x', this._getStartXForSlice)
            .attr('y', this._getStartYForSlice)
            .attr('fill', this._getBarSliceColor)
            .attr('id', this._getBarSliceId)
            // update context for event listeners (e.x. boundedHeight)
            .on('contextmenu', this._onColorPickerOpen)
            .on('mouseenter', this._onItemEnter)
            .on('mouseover', this._onItemOver)
            .on('mousemove', this._onItemOverMove)
            .on('mouseout', this._onItemOut)
            .on('click', this._onItemClick);
    }

    /* public methods */
    getDomain({ array = [], withoutSaving = false }: { array: barData[], withoutSaving?: boolean }) {
        let minValue, maxValue;

        if (this._percentage) {
            minValue = 0;
            maxValue = max(array, (d: barData) =>
                max(d.bars, (bSlice: barSliceData) => this._getValue(bSlice)),
            ) || 0;
        } else {
            minValue = min(array, (d: barData) =>
                min(d.bars, (bSlice: barSliceData) => this._getValue(bSlice)),
            ) || 0;
            maxValue = max(array, (d: barData) =>
                max(d.bars, (bSlice: barSliceData) => this._getValue(bSlice)),
            ) || 0;

            minValue = minValue > 0 ? 0 : minValue;
            maxValue = maxValue < 0 ? 0 : maxValue;
        }

        // if all values of domain equals to 0 (ex. [0, 0]), zero tick will be vertically centered
        // introduced in d3 v5.8: "For collapsed domains, use midpoint of domain or range rather than start."
        if (minValue === 0 && maxValue === 0) {
            maxValue += 1;
        }

        if (!withoutSaving) {
            this.maxYValue = maxValue;
        }

        return [ minValue, maxValue ];
    }

    groupsTextWidthProcess(d: barSliceData, width: number) {
        if (typeof d.groupIndex === 'number' && this.groupsTextWidth[d.groupIndex] === undefined) {
            let textFix = 5;

            do {
                textFix = textFix - 1;

                const textWidth = TextService.getTextLength({
                    fontSize: '12px',
                    fontFamily: 'Helvetica Neue',
                    fontWeight: '400',
                    text: d.maxNumber?.toFixed(Number(textFix)) + '%',
                    letterSpacing: '',
                });

                this.groupsTextWidth[d.groupIndex] = textFix;

                if(width >= textWidth) {
                    break;
                }
            } while (textFix > 0);
        }
    }

    scoreData(d: barData, i: number) {
        const maxNumber = Math.max(...d.bars.map(o => o.percent || 0));

        return d.bars.map(bar => ({
            ...bar,
            groupIndex: i,
            maxNumber,
        }));
    }

    drawXBandAxis({
        domainValues,
        rangeFrom,
        rangeTo,
        maxCount,
    }: IDrawXBandAxis) {
        this.maxCountSlicePerGroup = maxCount;
        this.xBandScale = this._xScaleBand({
            domainValues,
            rangeFrom,
            rangeTo,
        });

        return this;
    }

    drawBars({ groups = [], groupClass = '' }: IDrawBars) {
        if (this.onItemEventEnabled) {
            this.groups = groups;
            this.chartContainer
                .select(`.${this.elementsContainerClass}`)
                .selectAll(`.${this.groupClass}`)
                // a sequence of barSlices in which barGroup can mutate,
                // so d3 should recognize it using the second argument (key) of the "data" method
                .data(groups, (d: barData) => d.bars?.length)
                .join(
                    (enter: any) => {
                        // init bar groups and their attributes
                        const barGroups = enter
                            .append('g')
                            .attr('class', cx(this.groupClass, groupClass))
                            .attr('opacity', this._getBarGroupOpacity)
                            .attr('transform', (d: barData) => `translate(${this.xScale(d.id)}, 0)`);

                        this._enterBars(barGroups);

                        // add score|percentage at the top of each bar inside <text /> element
                        if (this._showScore) {
                            barGroups
                                .selectAll(`.${this.scoreClass}`)
                                .data(this.scoreData)
                                .join('text')
                                .attr('id', (d: barData) => `text_${d.customId}`)
                                .attr('class', cx(this.scoreClass, styles.scoreNumber))
                                .attr('x', this._barTextX)
                                .attr('y', this._barTextY)
                                .text(this._barText);
                        }
                    },
                    (update: any) => {
                        // add new bar group if needed and remove if bar group doesn't exist in dataset
                        update.exit().remove();
                        update
                            .attr('transform', (d: barData) => `translate(${this.xScale(d.id)}, 0)`)
                            .attr('opacity', this._getBarGroupOpacity);

                        this._updateBars(update);

                        update
                            .selectAll(`.${this.groupItemClass}`)
                            .data((d: barData) => d.bars)
                            .exit()
                            .remove();

                        if (this._showScore) {
                            update
                                .selectAll(`.${this.scoreClass}`)
                                .data(this.scoreData)
                                .attr('x', this._barTextX)
                                .attr('y', this._barTextY)
                                .text(this._barText);
                        }
                    },
                );
        }

        return this;
    }
}
