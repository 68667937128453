import { arc, event, pie, select, Selection, PieArcDatum } from 'd3';
import cx from 'classnames';
import { autobind } from 'core-decorators';
import { isMobile } from 'react-device-detect';

import type {
    contentSettingsFunc, IBaseChartService, IChartServiceInit,
    IDrawPieContainer, TPieChartData, TPieSegment,
} from '/visual/scenes/Dashboard/components/Gadget/models';
import { ELabelType } from '/visual/scenes/Dashboard/components/Gadget/models/global.model';
import { HelperService } from '/services';
import { BaseChartService } from '../components/BaseChart/services';

export class PieChartService extends BaseChartService {
    /* public constants */
    containerClass = 'container';
    groupItemClass = 'arc';
    shapeClass = 'shape';
    segmentShapeCenterClass = 'segmentShapeCenter'
    interactiveClass = 'interactive';
    scoreClass = 'label';
    textWrapperClass = 'textWrapper';
    centerCircleClass = 'centerCircle';
    transparentLabelClass = 'transparentLabel';
    innerTextDivider = '|';

    /* class properties */
    textContainer!: Selection<SVGGElement, any, any, any>;
    private readonly isChartMathFunction: boolean;
    private piePath: any;
    private radius: number;
    private innerRadius: number;
    private innerTextTooLong: boolean;

    constructor(props: IBaseChartService){
        super(props);

        // by default
        this.radius = 40; // 0.5 * minSize
        this.innerRadius = 20; // 0.25 * minSize
        this.innerTextTooLong = false;

        this.isChartMathFunction = [ 'average', 'sum', 'variance', 'median' ].includes(props.gadgetFunction);

        this.init({
            svgRef: props.svgRef,
            toolTipRef: props.toolTipRef,
            gadgetId: props.gadgetId,
        });
    }

    @autobind
    protected _pieEnter(pieSegment: { data: TPieSegment }, data: TPieChartData) {
        const { label } = this.chartConfig?.tooltip || {};

        this.toolTipRef.html('');

        this.toolTipRef
            .append('span')
            .text(`${ label(this._t) }: ${pieSegment.data.label}`);

        if(this.isChartMathFunction) {
            this.toolTipRef
                .append('span')
                .style('text-transform', 'capitalize')
                .text(`${this.gadgetFunction}: ${data[this.gadgetFunction as keyof contentSettingsFunc]}`);
        }

        this.toolTipRef
            .append('span')
            .text(`${ this._t('field.count') }: ${pieSegment.data.count}`);

        this.toolTipRef
            .append('span')
            .text(`${ this._t('field.percentage') }: ${pieSegment.data.percent}%`);
    }

    @autobind
    protected _pieOverMove(pieSegment: { data: TPieSegment }) {
        this._highlightSegment(pieSegment.data.id);

        !isMobile && this.toolTipRef
            .style('visibility', 'visible')
            .style('top', `${event.clientY + 15}px`)
            .style('left', `${event.clientX + 15}px`);
    }

    @autobind
    protected _pieOut() {
        this.toolTipRef
            .style('visibility', 'hidden')
            .html('');

        this._highlightSegment(null);
    }

    @autobind
    protected _highlightSegment(pieSegmentId: string | null) {
        this.chartContainer
            .selectAll(`.${this.groupItemClass}`)
            .classed(
                this.transparentClass,
                (pieData: { data: TPieSegment }) => HelperService.checkNotNullOrUndefined(pieSegmentId) && pieSegmentId !== pieData.data.id,
            )
            .select(`.${this.scoreClass}`)
            .classed(
                this.transparentLabelClass,
                (pieData: { data: TPieSegment }) => {
                    const isHighlighted = HelperService.checkNotNullOrUndefined(pieSegmentId) && pieSegmentId === pieData.data.id;

                    if (isHighlighted) return false;

                    return this._isSegmentTooSmall(pieData.data);
                },
            );
    }

    @autobind
    protected _pieClick(pieValue: { data: TPieSegment }) {
        this._drillDownFromSelection?.({ group: pieValue.data });
    }

    @autobind
    protected _onColorPickerOpen(pieData: { data: TPieSegment }) {
        this._setColorPickerData({
            open: true,
            coords: { y: event.pageY, x: event.pageX },
            target: `${this.chartType}_colorPicker_${this.gadgetId}`,
            color: pieData.data.color,
            elementId: pieData.data.id,
        });

        event.preventDefault();
    }

    @autobind
    protected _onInnerCircleClick(data: TPieChartData) {
        this._setInnerCircleData?.({
            open: !this.isChartMathFunction,
            target: `${ this.chartType }_${ ELabelType.PIE_CENTER }_${ this.gadgetId }`,
            value: data.innerText ?? `${ this._t(data.totalLabelTransKey) } ${ data.totalValue }`,
            type: ELabelType.PIE_CENTER,
            id: ELabelType.PIE_CENTER,
            coords: { y: event.pageY, x: event.pageX },
        });
    }

    @autobind
    protected _getMidAngle(pieItem: { data: TPieSegment }) {
        const centroid = this.piePath.centroid(pieItem);

        return Math.atan2(centroid[1], centroid[0]);
    }

    @autobind
    protected _lineX1(pieItem: { data: TPieSegment }) {
        const tick = this.radius + 5;

        return Math.cos(this._getMidAngle(pieItem)) * tick;
    }

    @autobind
    protected _lineY1(pieItem: { data: TPieSegment }) {
        const tick = this.radius + 5;

        return Math.sin(this._getMidAngle(pieItem)) * tick;
    }

    @autobind
    protected _lineX2(pieItem: { data: TPieSegment }) {
        const endX = this.radius + 18;

        return Math.cos(this._getMidAngle(pieItem)) * endX;
    }

    @autobind
    protected _lineY2(pieItem: { data: TPieSegment }) {
        const endY = this.radius + 18;

        return Math.sin(this._getMidAngle(pieItem)) * endY;
    }

    @autobind
    protected _textX(pieItem: { data: TPieSegment }) {
        const x = this._lineX2(pieItem),
            sign = (x > 0) ? 1 : -1;

        return x + (10 * sign);
    }

    @autobind
    protected _getLabelText({ data: pieSegmentData }: { data: TPieSegment }) {
        return this._percentage
            ? Number(pieSegmentData.percent).toFixed(1) + '%'
            : pieSegmentData.count;
    }

    @autobind
    protected _isSegmentTooSmall(pieSegmentData: TPieSegment) {
        return pieSegmentData.percent === null || pieSegmentData.percent < 4;
    }

    @autobind
    protected _getLabelContainerClasses({ data: pieSegmentData }: { data: TPieSegment }) {
        return cx(this.scoreClass, { [this.transparentLabelClass]: this._isSegmentTooSmall(pieSegmentData) });
    }

    @autobind
    setHighlightedSlice(highlightedLabel: string | null) {
        this._highlightSegment(highlightedLabel);
    }

    @autobind
    protected getMinSize() {
        // manually reduced the circle to have a place for a label
        // indents multiplied by 2 (two sides)
        const labelWidthDimensions = 150;
        const labelHeightDimensions = 58;
        const chartIndent = 10;

        const decreaseBy = {
            w: !this.isChartMathFunction && this._showScore ? labelWidthDimensions : chartIndent,
            h: !this.isChartMathFunction && this._showScore ? labelHeightDimensions : chartIndent,
        };

        const decreasedWidth = this.chartWidth - decreaseBy.w;
        let decreasedHeight = this.chartHeight - decreaseBy.h;

        //We’ll take less, but we need to see if there’s enough label space
        if (!this.isChartMathFunction && decreasedWidth > decreasedHeight) {
            const differenceBetween = this.chartWidth - this.chartHeight;

            if (( differenceBetween + labelHeightDimensions ) < labelWidthDimensions ) {
                decreasedHeight = this.chartHeight - decreaseBy.w;
            }
        }

        return Math.min(decreasedWidth, decreasedHeight);
    }

    @autobind
    protected createShapeToCaptureSegmentCenter(arcs: any) {
        // create rect 1x1 to mark the center of each pie segment shape
        arcs
            .append('rect')
            .attr('class', this.segmentShapeCenterClass)
            .attr('x', (d: PieArcDatum<TPieSegment>) => this.piePath.centroid(d)[0])
            .attr('y', (d: PieArcDatum<TPieSegment>) => this.piePath.centroid(d)[1]);
    }

    @autobind
    protected fillInnerCircleByText(value: string, fontSize: number, withDividerSymbol?: string | null): Selection<SVGTSpanElement, any, any, any> | void {
        const MIN_FONT_SIZE = 14;
        let newFontSize = MIN_FONT_SIZE;

        // line height calculating
        const fontAscent = 1.214; // lineHeight is on average 21.4% greater than fontSize
        const lineHeight = fontSize * fontAscent;
        const getFontSettings = (fontSize: number) => `bold ${ fontSize }px sans-serif`; // for getTextWidth helper

        // total NUMBER of lines that can be displayed in the circle
        let totalLineCount = Math.floor((this.innerRadius * 2) / lineHeight);

        if (totalLineCount <= 0 && !this.isChartMathFunction) {
            newFontSize = Math.floor((this.innerRadius * 2) / fontAscent);

            if (newFontSize <= MIN_FONT_SIZE && fontSize === MIN_FONT_SIZE) return;

            return this.fillInnerCircleByText(
                value,
                newFontSize < MIN_FONT_SIZE
                    ? MIN_FONT_SIZE
                    : newFontSize,
                withDividerSymbol,
            );
        }

        // if this method called recursively, need to reset textContainer
        this.textContainer.selectAll('text').remove();
        // text element creating and aligning
        const textNode = this.textContainer
            .append('text')
            .attr('class', cx(this.textWrapperClass, { mathFunctionText: this.isChartMathFunction }))
            .attr('font-size', fontSize)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle');

        // determine font size for single-line display in case of math function
        if (this.isChartMathFunction) {
            let fontSizeForMathFunction = fontSize;
            const HORIZONTAL_OFFSET = 12;
            const lineWidth = this.radius * 2 - HORIZONTAL_OFFSET;

            while (HelperService.getTextWidth(value, getFontSettings(fontSizeForMathFunction)) >= lineWidth) {
                fontSizeForMathFunction -= 0.5;
            }

            return textNode
                .attr('font-size', fontSizeForMathFunction)
                .append('tspan')
                .attr('x', 0)
                .attr('dy', 0) // initial dy
                .text(value.trim());
        }

        const maxLinesAmountReminder = (this.innerRadius * 2) / lineHeight - totalLineCount;
        // totalTextWidth - the length of the text if placed in a single line
        // But some lines within the inner circle will not be completely filled with this text, and the sum of the
        // differences in all these lines will cause the unexpected appearance of a new one
        // Therefore, assuming this text (split into lines) will occupy 5% more space than expected
        const TOTAL_TEXT_WIDTH_REMINDER = 1.05;
        // total WIDTH of text
        const totalTextWidth = HelperService.getTextWidth(value, getFontSettings(fontSize)) * TOTAL_TEXT_WIDTH_REMINDER;
        let totalTextWidthLeft = totalTextWidth;
        let linesCount = 0; // number of lines to be displayed
        // stored original width per lines
        let initialWithPerLines: number[] = [];

        const getTSpanContent = (lineCountWasDecreased = false): string[] => {
            // total WIDTH of lines that can be displayed in the circle
            let totalLinesWidth = 0;
            // calculating width for each line of the circle
            const widthPerLines = Array.from({ length: totalLineCount }).map((_, lineIndex, list) => {
                const isCheckingBottomOfLine = lineIndex >= (totalLineCount - 1) / 2;
                const reminderOffset = (maxLinesAmountReminder / 2) * lineHeight;
                const eachLineOffset = lineHeight - fontSize;
                const linesCoefficient = isCheckingBottomOfLine
                    ? list.length - (lineIndex + 1)
                    : lineIndex;
                const verticalLegLength = this.innerRadius - (reminderOffset + eachLineOffset + linesCoefficient * lineHeight);
                const lineWidth = Math.floor(2 * Math.sqrt(this.innerRadius ** 2 - verticalLegLength ** 2));

                totalLinesWidth += lineWidth;

                return lineWidth;
            });

            // save widthPerLines for first iteration in 'getTSpanContent' method
            if (!lineCountWasDecreased) {
                initialWithPerLines = widthPerLines;
            }

            const reduceFontSide = (actualTotalTextWidth: number) => {
                this.innerTextTooLong = true;
                // increase step of reducing fontSize based on the ratio
                const reducedFontSize = Math.round(fontSize / (actualTotalTextWidth / totalLinesWidth));

                return fontSize - reducedFontSize >= 1 ? reducedFontSize : fontSize - 1;
            };

            // if totalTextWidth > totalLineWidth start from "0", else start from center index - startIndexOffset
            let startIndexOffset = 0;

            if (totalTextWidth < totalLinesWidth) {
                this.innerTextTooLong = false;
                let startPos = Math.floor((widthPerLines.length - 1) / 2);
                let tempTextWidth = 0;

                startIndexOffset = startPos;

                // search for the optimal number of lines to fill with text, starting from the center of the circle
                for (let startIndexModifier = 1; startIndexModifier < widthPerLines.length + 1; startIndexModifier++) {
                    tempTextWidth += widthPerLines[startPos];
                    startIndexOffset = Math.min(startIndexOffset, startPos);

                    if (tempTextWidth >= totalTextWidth) break;

                    if (startIndexModifier % 2 !== 0) {
                        startPos += startIndexModifier;
                    } else {
                        startPos -= startIndexModifier;
                    }
                }
            } else {
                newFontSize = reduceFontSide(totalTextWidth);
                // inner text too long for this font size
                // try to fill the inner circle with a smaller font size
                if (fontSize > MIN_FONT_SIZE) return [];
            }

            const tspanArray: string[] = [];
            let text = value;
            let separatedByDividerSymbol = false;
            let isNotEnoughSpaceForTextWithDivider = false;

            while(text !== '') {
                let currentLine = '';
                let currentLineTextWidth = 0;

                for (const char of text) {
                    currentLine += char;
                    currentLineTextWidth = HelperService.getTextWidth(currentLine, getFontSettings(fontSize));
                    separatedByDividerSymbol = withDividerSymbol ? char === withDividerSymbol : false;
                    const breakCondition = withDividerSymbol
                        ? char === withDividerSymbol
                        : currentLineTextWidth > widthPerLines[linesCount + startIndexOffset];

                    if (breakCondition) {
                        currentLine = currentLine.slice(0, -1); // final text for line
                        currentLineTextWidth = HelperService.getTextWidth(currentLine, getFontSettings(fontSize)); // final text width for line
                        break;
                    }
                }

                // get new text and delete divider symbol if it was existed
                const newText = text.substring(currentLine.length + (separatedByDividerSymbol ? 1 : 0));

                totalTextWidthLeft = newText !== '' && !separatedByDividerSymbol
                    ? totalTextWidthLeft - widthPerLines[linesCount + startIndexOffset]
                    : totalTextWidthLeft - currentLineTextWidth;
                isNotEnoughSpaceForTextWithDivider = separatedByDividerSymbol && currentLineTextWidth > widthPerLines[linesCount + startIndexOffset];

                if (totalTextWidthLeft < 0 || isNotEnoughSpaceForTextWithDivider) {
                    // this need to add three dots to line, that was separated by divider symbol
                    if (isNotEnoughSpaceForTextWithDivider) {
                        this.innerTextTooLong = true;
                    }

                    const actualTotalTextWidth = totalTextWidth + Math.ceil(Math.abs(totalTextWidthLeft));

                    newFontSize = reduceFontSide(actualTotalTextWidth);
                    // inner text too long for this font size
                    // try to fill the inner circle with a smaller font size
                    if (fontSize > MIN_FONT_SIZE) return [];
                }

                linesCount += 1;
                tspanArray.push(currentLine);

                if (linesCount === totalLineCount || isNotEnoughSpaceForTextWithDivider) {
                    if (this.innerTextTooLong && fontSize <= MIN_FONT_SIZE) {
                        const getModifiedValue = (value: number) => `${currentLine.slice(0, -value).trim()}...`;

                        let amountToSlice = 1;
                        let modifiedValue = getModifiedValue(amountToSlice);

                        const lineWidth = isNotEnoughSpaceForTextWithDivider
                            // use original widths, to fill full line
                            ? initialWithPerLines[Math.ceil((initialWithPerLines.length / 2) - 1)]
                            : widthPerLines[linesCount - 1];

                        while (HelperService.getTextWidth(modifiedValue, getFontSettings(fontSize)) > lineWidth) {
                            modifiedValue = getModifiedValue(amountToSlice++);
                        }

                        tspanArray[tspanArray.length - 1] = modifiedValue;
                    }

                    break;
                }

                text = newText.trim();
            }

            // content will be centered only if the parity of totalLineCount and linesCount (rows with text) is the same
            if (widthPerLines.length % 2 !== linesCount % 2 && !isNotEnoughSpaceForTextWithDivider) {
                totalLineCount -= 1;
                totalTextWidthLeft = totalTextWidth;
                linesCount = 0;

                return getTSpanContent(true);
            }

            return tspanArray;
        };

        const tspanContentList = getTSpanContent();

        if (this.innerTextTooLong && fontSize > MIN_FONT_SIZE) {
            return this.fillInnerCircleByText(
                value,
                newFontSize < MIN_FONT_SIZE
                    ? MIN_FONT_SIZE
                    : newFontSize,
                withDividerSymbol,
            );
        }

        tspanContentList.forEach((text: string) =>
            textNode
                .append('tspan')
                .attr('x', 0)
                .attr('dy', lineHeight) // initial dy
                .text(text.trim()),
        );

        // dy prop make offset from the center of the element
        // need to lift first element by half of its lineHeight
        const linesOffset = -(linesCount / 2) * lineHeight;
        const halfLine = lineHeight / 2;

        this.textContainer
            .selectAll('tspan')
            .filter(':first-child')
            .attr('dy', linesOffset + halfLine);
    }

    @autobind
    protected onInnerTextOver(innerText: string) {
        this.toolTipRef.html('');
        this.toolTipRef
            .append('span')
            .text(innerText);
    }

    @autobind
    protected onInnerTextOverMove() {
        !isMobile && this.toolTipRef
            .style('visibility', 'visible')
            .style('top', `${event.clientY + 15}px`)
            .style('left', `${event.clientX + 15}px`);
    }

    @autobind
    protected onInnerTextOut() {
        this.toolTipRef
            .style('visibility', 'hidden')
            .html('');
    }

    drawPieContainer({ svgWidth, svgHeight, transformValue }: IDrawPieContainer) {
        this.chartWidth = svgWidth;
        this.chartHeight = svgHeight;
        const minSize = this.getMinSize();

        this.radius = minSize / 2;
        this.innerRadius = minSize / 4;

        this.svgContainer
            .attr('width', svgWidth)
            .attr('height', svgHeight);
        this.chartContainer
            .style('transform', transformValue);

        return this;
    }

    drawPie(data: TPieChartData) {
        const pies = pie<TPieSegment>()
            .sort(null)
            .value(d => Number(d.percent));

        this.piePath = arc()
            .outerRadius(this.radius)
            .innerRadius(this.isChartMathFunction ? 0 : this.innerRadius)
            .padAngle(0);

        // segment wrapper
        const arcs = this.chartContainer
            .selectAll(`.${this.groupItemClass}`)
            .data(pies(data.items))
            .enter()
            .append('g')
            .attr('tabindex', 0)
            .attr('class', cx(this.groupItemClass, { [this.interactiveClass]: this.drillDownEnabled }))
            .attr('id', ({ data: pieSegmentData }: { data: TPieSegment }) => `pie_${pieSegmentData.customId}`);

        const shapes = arcs.append('g')
            .attr('class', this.shapeClass)
            .on('mouseover', (pieSegment: { data: TPieSegment }) => this._pieEnter(pieSegment, data))
            .on('mousemove', this._pieOverMove)
            .on('mouseout', this._pieOut)
            .on('contextmenu', this._onColorPickerOpen)
            .on('click', this._pieClick);

        // segment body (filled by color) outer radius
        shapes.append('path')
            .attr('d', this.piePath)
            .attr('fill', (pieSegment: { data: TPieSegment }) => pieSegment.data.color);

        // for auto-test only
        this.createShapeToCaptureSegmentCenter(shapes);

        return this;
    }

    drawLabels(data: TPieChartData) {
        // inner circle (added once, not for each group)
        if (this.innerRadius > 0) {
            // reset innerTextTooLong
            this.innerTextTooLong = false;
            const isInnerTextExisted = HelperService.checkNotNullOrUndefined(data.innerText);
            let innerText = (data.totalValue ?? '').toString();
            let innerFontSize = 60;

            if (!this.isChartMathFunction) {
                // innerTextDivider - divide symbol. It will be truncated in 'fillInnerCircleByText' method
                innerText = isInnerTextExisted
                    ? (data.innerText as string)
                    : this._t(data.totalLabelTransKey) + this.innerTextDivider + data.totalValue;
                innerFontSize = 30;

                this.textContainer
                    .append('circle')
                    .attr('r', this.innerRadius)
                    .attr('class', this.centerCircleClass)
                    .on('click', () => this._onInnerCircleClick(data));
            }

            // use innerTextDivider symbol to force text separating
            this.fillInnerCircleByText(innerText, innerFontSize, isInnerTextExisted ? null : this.innerTextDivider);

            this.textContainer.raise();

            if (this.innerTextTooLong) {
                const popoverText = isInnerTextExisted
                    ? innerText
                    : innerText.replace(this.innerTextDivider, ' ');

                this.textContainer
                    .data([ popoverText ])
                    .on('mouseover', this.onInnerTextOver)
                    .on('mousemove', this.onInnerTextOverMove)
                    .on('mouseout', this.onInnerTextOut);
            }
        }

        if (!this.isChartMathFunction && this._showScore) {
            const labelsWrapper = this.chartContainer
                .selectAll(`.${this.groupItemClass}`)
                .append('g')
                .attr('class', this._getLabelContainerClasses);

            labelsWrapper
                .append('line')
                .attr('class', 'label-line')
                .attr('x1', this._lineX1)
                .attr('y1', this._lineY1)
                .attr('x2', this._lineX2)
                .attr('y2', this._lineY2);

            labelsWrapper
                .append('text')
                .attr('class', 'label-text')
                .text(this._getLabelText)
                .attr('x', this._textX)
                .attr('y', this._lineY2)
                .attr('text-anchor', (pieSegment: { data: TPieSegment }) => {
                    const x = Math.cos(this._getMidAngle(pieSegment));

                    return (x > 0) ? 'start' : 'end';
                });
        }

        return this;
    }

    init({ svgRef, toolTipRef, gadgetId }: IChartServiceInit) {
        const svgContainer = select(svgRef);
        const toolTip = select(toolTipRef);
        const existedChart = svgContainer.select(`g#${this.containerClass}_${gadgetId}`);

        this.svgContainer = svgContainer;
        this.toolTipRef = toolTip;

        if (existedChart && existedChart.node()) {
            this.remove();
        }

        // chart container
        this.chartContainer = svgContainer
            .append('g')
            .attr('class', this.containerClass)
            .attr('id', `${this.containerClass}_${gadgetId}`);

        // text container
        this.textContainer = this.chartContainer.append('g');

        this._setInitialized(true);

        return this;
    }
}
