import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Prompt } from 'react-router';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';

import { Preloader } from '/components';
import { HelperService, PageRequests, usePreviousProps } from '/services';
import { DashboardsSlider, VisualControlPanel } from '/visual/components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import {
    changeDashboardSettings,
    getDefaultDashboard,
    getFavouritesDashboards,
} from '/visual/modules/VocVisual.modules';
import { _getIsCreated } from '/visual/modules/VocVisual.selectors';
import { getDashboardForPrint, exportToFormat, toggleModal } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { EExportFormats } from '/visual/models';
import { EDashboardActionType } from '/visual/scenes/Dashboard/models';
import { useVisualNavigation } from '/visual/customHooks';

import {
    DashboardControls, DrillDashboardsNavigation,
    GadgetsWrapper, ModalWrapper, DashboardPagePreloader,
} from './components';
import {
    _getDashboardData, _getDashboardLoadingData,
    _getDefaultDashboardId, _getIsEditable,
    isDashboardChangedSelector,
} from './modules/Dashboard.selectors';
import {
    addGadget, cleanup, getDashboard,
    saveChanges, toggleDashboardFavorite,
    toggleIsEditable, updateDashboardData,
} from './modules/Dashboard.modules';

const dashboardPageRequests = new PageRequests();

export const Dashboard: FC<{ location: Location }> = ({ location }) => {
    const { t } = useTranslation();
    const { goToDashboard, goToDashboardList } = useVisualNavigation();
    const dispatch = useDispatch();

    const { dashboardId }: { dashboardId: string } = useParams();
    const prevDashboardId = usePreviousProps(dashboardId);

    const {
        defaultDashboardLoading,
        defaultDashboardLoaded,
        dashboardLoading,
    } = useSelector(_getDashboardLoadingData);
    const defaultDashboardId = useSelector(_getDefaultDashboardId);
    const dashboard = useSelector(_getDashboardData);
    const isEditable = useSelector(_getIsEditable);
    const isCreated = useSelector(_getIsCreated);
    const isDashboardChanged = useSelector(isDashboardChangedSelector);

    const switchIsEditable = () => dispatch(toggleIsEditable(!isEditable));

    const onSaveChanges = () => dispatch(saveChanges());

    const handleCreateGadget = (additionalProps: { type: string, coordinate?: object, pageId?: string, }) => {
        return dispatch(
            addGadget({
                id: dashboardId,
                ...additionalProps,
            }),
        );
    };

    const onExport = (format: EExportFormats) => dispatch(exportToFormat(dashboardId, format));

    const onPrintByHotKey = (event: KeyboardEvent) =>
        HelperService.cancelPrintAction(event, () => onExport(EExportFormats.PDF));

    const showNotReactPrompt = (e: { preventDefault: () => void; }) => {
        const showPromptMsg = isDashboardChanged && !isCreated;

        if(showPromptMsg) {
            e.preventDefault();
            return true;
        }
    };

    useEffect(() => {
        return () => {
            dispatch(cleanup());
        };
    }, []);

    useEffect(() => {
        window.addEventListener('beforeunload', showNotReactPrompt);

        return () => {
            window.removeEventListener('beforeunload', showNotReactPrompt);
        };
    }, [ isDashboardChanged, isCreated ]);

    useEffect(() => {
        if (!defaultDashboardLoading && !dashboardId) {
            if (defaultDashboardId) {
                goToDashboard(defaultDashboardId);
            } else {
                defaultDashboardLoaded
                    ? goToDashboardList()
                    : dispatch(getDefaultDashboard());
            }
        }
    }, [ defaultDashboardLoading, defaultDashboardLoaded, defaultDashboardId, dashboardId ]);

    useEffect(() => {
        if (dashboardId && dashboardId !== prevDashboardId) {
            const queryParams = new URLSearchParams(location.search);
            const pageParam = queryParams.get('page');

            dashboardPageRequests.addRequest(
                pageParam
                    ? dispatch(getDashboardForPrint(dashboardId, pageParam))
                    : dispatch(getDashboard(dashboardId)),
                'getVocVisualDashboard',
            );
        }

        window.addEventListener('keydown', onPrintByHotKey, true);

        return () => {
            window.removeEventListener('keydown', onPrintByHotKey, true);
            dashboardPageRequests.cleanupById('getVocVisualDashboard');
            dispatch(cleanup());
        };
    }, [ dashboardId ]);


    const handleSwitchIsEditable = () => {
        isEditable
            ? dispatch(showConfirm({
                checkDirty: true,
                dirty: isEditable ? isDashboardChanged : false,
                header: t('warning'),
                content: `${ t('warningText.unsavedChanges') }${ t('warningText.areYouWantContinue') }`,
                successCallback: switchIsEditable,
            }))
            : switchIsEditable();
    };

    const onMakeDefault = () => {
        if(dashboard.id) {
            dispatch(changeDashboardSettings(dashboard.id, {
                title: dashboard.title,
                isDefault: !dashboard.isDefault,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
            }, t(dashboard.isDefault
                ? 'dashboardUnmarkedAsDefault'
                : 'dashboardMarkedAsDefault',
            ))).promise.then(() => {
                dispatch(updateDashboardData({
                    isDefault: !dashboard.isDefault,
                }));
            });
        }
    };

    const onMakeFavourite = () => {
        dispatch(toggleDashboardFavorite(dashboard.id, dashboard.isFavourite)).promise
            .then(() => dispatch(getFavouritesDashboards()));
    };

    const dashboardActionHandler = (type: EDashboardActionType) => {
        dispatch(showConfirm({
            checkDirty: true,
            dirty: isEditable ? isDashboardChanged : false,
            header: t('warning'),
            content: `${ t('warningText.unsavedChanges') }${ t('warningText.areYouWantContinue') }`,
            successCallback: () => dispatch(toggleModal({
                dashboardId: type === EDashboardActionType.COPY ? dashboard.id : null,
                gadgetId: null,
                type,
            } )),
        }));
    };

    const handleShowPromptMessage = (nextLocation: Location<unknown>) => {
        const nextLocationState = nextLocation?.state as { skipConfirm: boolean };
        const showPromptMsg = isDashboardChanged
            && !isCreated
            && !nextLocationState?.skipConfirm;

        return showPromptMsg
            ? `${ t('warningText.unsavedChanges') }${ t('warningText.areYouWantContinue') }`
            : true;
    };

    const onCopyDashboard = () => dashboardActionHandler(EDashboardActionType.COPY);

    const onCreateDashboard = () => dashboardActionHandler(EDashboardActionType.CREATE);

    const getDashboardView = () => {
        return (
            <>
                <Prompt message={ handleShowPromptMessage } />

                <VisualControlPanel onCreateDashboard={ onCreateDashboard } />

                {
                    dashboardId
                        ? <>
                            <DashboardsSlider
                                onSelectDashboard={ goToDashboard }
                                activeDashboardId={ dashboardId }
                                isPermitDefaultDashboard={ dashboard.rights.isPermitDefaultDashboard }
                            />

                            <DrillDashboardsNavigation
                                actualDashboardId={ dashboardId }
                                onSelectDashboard={ goToDashboard }
                            />

                            <DashboardControls
                                isEditable={ isEditable }
                                switchIsEditable={ handleSwitchIsEditable }
                                onSaveChanges={ onSaveChanges }
                                onMakeDefault={ onMakeDefault }
                                onExport={ onExport }
                                onMakeFavourite={ onMakeFavourite }
                                goToParentDashboard={ goToDashboard }
                                onCopy={ onCopyDashboard }
                            />
                            {
                                dashboardLoading
                                    ? <DashboardPagePreloader />
                                    : <GadgetsWrapper
                                        isEditable={ isEditable }
                                        handleCreateGadget={ handleCreateGadget }
                                    />
                            }
                        </>
                        : null
                }

            </>
        );
    };

    return (
        <div>
            {
                defaultDashboardLoading
                    ? <Preloader />
                    : getDashboardView()
            }

            <ModalWrapper dashboardId={ dashboardId }/>
        </div>
    );
};
