import React, { useRef, useEffect } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const defaultTipFormatter = value => value.toString();

export const SliderHandle = ({
    originNode,
    visible,
    rangeValue,
    tipFormatter = defaultTipFormatter,
}) => {
    const tooltipRef = useRef();

    useEffect(() => {
        if (visible) {
            tooltipRef.current?.forceAlign();
        }
    }, [ rangeValue, visible ]);

    const getOverlay = () => {
        if (visible) {
            return tipFormatter?.(rangeValue);
        }
    };

    return (
        <Tooltip
            showArrow={ false }
            placement={ 'top' }
            visible={ visible }
            overlay={ getOverlay }
            overlayInnerStyle={{ minHeight: 'auto' }}
            ref={ tooltipRef }
        >
            { originNode }
        </Tooltip>
    );
};
