import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { DateOptions } from '../';
import { VocSelect } from '/components';
import { DateService } from '../../services';
import { HelperService } from '/services';

import styles from './style.module.scss';

const disallowedKeys = [ 'e', 'E', '+', '-', '.', ',' ];

export const RelativePeriod = ({ data, onChange }) => {
    const { t } = useTranslation();
    const agoOptions = [
        {
            label: t('agoOptions.days'),
            value: 'DAY',
        },
        {
            label: t('agoOptions.weeksMon'),
            value: 'WEEK_MON',
        },
        {
            label: t('agoOptions.weeksSan'),
            value: 'WEEK_SUN',
        },
        {
            label: t('agoOptions.month'),
            value: 'MONTH',
        },
        {
            label: t('agoOptions.quarter'),
            value: 'QUARTER',
        },
        {
            label: t('agoOptions.year'),
            value: 'YEAR',
        },
    ];

    const findSelectValue = agoOptions.find(option => option.value === data.period) || null;
    const [ dateResultString, setDateResultString ] = useState('');

    const isNumber = (value: any) => value && /^[0-9]+$/.test(value);

    const [ inputsValues, setInputsValues ] = useState({
        start: data?.start?.toString() ? Math.abs(data.start) : '',
        end: data?.end?.toString() ? Math.abs(data.end) : '',
    });

    const calculateDateResultString = ({ from, to }) => {
        let dateString = '';

        if(from && to) {
            const formatFrom = HelperService.formatDate(from, 'DD-MM-YYYY');
            const formatTo = HelperService.formatDate(to, 'DD-MM-YYYY');

            dateString = `${formatFrom} 00:00:00 to ${formatTo} 23:59:59`;
        }

        setDateResultString(dateString);
    };

    const onChangeDateOptions = ({ relativeData, type }) => {
        calculateDateResultString(DateService.calculateAbsoluteDate(type));

        onChange({
            ...data,
            ...relativeData,
        });

        const valuesModel = {
            start: relativeData.start !== null ? Math.abs(relativeData.start) : '',
            end:  relativeData.end !== null ? Math.abs(relativeData.end) : '',
        };

        setInputsValues(valuesModel);
    };

    const onChangeInputs = (type, value) => {
        if(isNumber(value) || !value) {
            calculateDateResultString(
                DateService.calculateRelativeDate({
                    ...data,
                    start: data.start && Math.abs(data.start),
                    end: data.end && Math.abs(data.end),
                    [type]: value && Math.abs(value),
                }),
            );

            onChange({
                ...data,
                [type]: value && -Number(value),
            });
        }

        setInputsValues({
            ...inputsValues,
            [type]: value && Math.abs(value),
        });
    };

    const onChangeSelect = selectData => {
        calculateDateResultString(
            DateService.calculateRelativeDate({
                ...data,
                start: data.start && Math.abs(data.start),
                end: data.end && Math.abs(data.end),
                period: selectData?.value,
            }),
        );

        onChange({
            ...data,
            start: data.start && -Number(data.start),
            end: data.end && -Number(data.end),
            period: selectData?.value,
        });
    };

    const onKeyDownInputs = e => {
        if (disallowedKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        calculateDateResultString(
            DateService.calculateRelativeDate({
                ...data,
                start: data.start && Math.abs(data.start),
                end: data.end && Math.abs(data.end),
            }),
        );
    }, []);

    return(
        <div className={ styles.relativePeriodWrapper }>
            <div className={ styles.selectsWrapper }>
                <div className={ styles.selectWrapper }>
                    <label>
                        { t('field.from') }
                    </label>
                    <Input
                        type={ 'number' }
                        className={ styles.fromToInput }
                        placeholder='XX'
                        value={ Number.isFinite(inputsValues.start) ? inputsValues.start : '' }
                        onKeyDown={ onKeyDownInputs }
                        onChange={ e => onChangeInputs('start', e.target.value || null) }
                    />
                </div>
                <div className={ styles.selectWrapper }>
                    <label>
                        { t('field.to') }
                    </label>
                    <Input
                        type={ 'number' }
                        className={ styles.fromToInput }
                        placeholder='YY'
                        value={ Number.isFinite(inputsValues.end) ? inputsValues.end : '' }
                        onKeyDown={ onKeyDownInputs }
                        onChange={ e => onChangeInputs('end', e.target.value || null) }
                    />
                </div>
                <div className={ cx(styles.selectWrapper, styles.selectWrapperDate) }>
                    <label>
                        { t('field.ago') }
                    </label>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <VocSelect
                        name={ 'selectData' }
                        placeholder={ t('field.selectAgo') }
                        portal
                        isHorizontal
                        options={ agoOptions }
                        value={ findSelectValue }
                        onChange={ onChangeSelect }
                    />
                </div>
            </div>
            <p className={ styles.timeString }>{ data.start?.toString() && data.end?.toString() ? dateResultString : '' }</p>
            <DateOptions onChange={ onChangeDateOptions } data={ data } type={ 'relative' }/>
        </div>
    );
};
