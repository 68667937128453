import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineReducers } from 'redux';
import { Navigation, PrivateRoute } from '/components';
import {
    AdvancedSettings,
    CommonSettings,
    CaseAlerts,
    TimeSlots,
    LanguagesSettings,
    Layouts,
    Themes,
    QuarantinesSettings,
    ShareRights,
} from './scenes';
import { GlobalSettingsControls } from './components/GlobalSettingsControls';
import { getPermissionByActiveSelector, settingsPageLinks } from './modules/globalSettings.selectors';
import { isSupportUserSelector } from '/modules/selectors';
import { reducer as advancedSettingsReducer } from './scenes/AdvancedSettings/modules/advancedSettings.modules';
import { reducer as commonSettingsReducer } from './scenes/CommonSettings/modules/commonSettings.modules';
import { reducer as LanguagesSettingsReducer } from './scenes/LanguagesSettings/modules/languagesSettings.modules';
import { reducer as LayoutsReducer } from './scenes/Layouts/modules/Layouts.modules';
import { reducer as common } from './modules/globalSettings.modules';
import { reducer as caseAlerts } from './scenes/CaseAlerts/modules/caseAlerts.modules';
import { reducer as TimeSlotsReducer } from './scenes/TimeSlots/modules/timeSlots.modules';
import { reducer as ThemesReducer } from './scenes/Themes/modules/themes.modules';
import { reducer as QuarantineReducer } from './scenes/QuarantinesSettings/modules/quarantines.modules';
import { reducer as ShareRightsReducer } from './scenes/ShareRights/modules/shareRights.modules';

import { HelperService } from '/services';

import './GlobalSettings.scss';

const mapStateToProps = state => ({
    pageLinks: settingsPageLinks(state),
    isSupportUser: isSupportUserSelector(state),
    permission: getPermissionByActiveSelector(state),
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
export class GlobalSettings extends Component {
    render() {
        const { pageLinks, match, isSupportUser, permission } = this.props;
        const routes = {
            advanced: `${ match.url }/advanced`,
            languages: `${ match.url }/language`,
            timeSlots: `${ match.url }/time-slots`,
            common: `${ match.url }/common`,
            caseAlerts: `${ this.props.match.url }/case-alerts`,
            layouts: `${ match.url }/layouts`,
            quarantines: `${ match.url }/quarantines`,
            theme: `${ match.url }/theme-list`,
            shareRights: `${ match.url }/share-rights`,
        };

        return (
            <div className='page__wrapper'>
                <Navigation
                    navigationInfo={ pageLinks }
                    className="survey-navigation"
                />
                <Switch>
                    <Route
                        path={ [ routes.advanced, routes.languages, routes.common, routes.timeSlots ] }
                        exact
                        render={ ({ location }) =>
                            <GlobalSettingsControls
                                pathname={ location.pathname }
                                routes={ routes }
                                permission={ permission }
                            />
                        }
                    />
                </Switch>
                <Switch>
                    <Route path={ routes.advanced } exact component={ AdvancedSettings }/>
                    <Route path={ routes.languages } exact component={ LanguagesSettings }/>
                    <Route path={ routes.timeSlots } exact component={ TimeSlots }/>
                    <Route path={ routes.common } exact component={ CommonSettings }/>
                    <Route path={ routes.quarantines } exact component={ QuarantinesSettings }/>
                    <Route path={ routes.caseAlerts } exact component={ CaseAlerts }/>
                    <Route path={ routes.layouts } exact component={ Layouts }/>
                    <Route path={ routes.theme } exact component={ Themes }/>
                    <PrivateRoute
                        exact
                        path={ routes.shareRights }
                        component={ ShareRights }
                        hasAccess={ isSupportUser }
                    />
                    <Redirect to={ HelperService.joinRoute(match.url, '/advanced') }/>
                </Switch>
            </div>
        );
    }
}

export const GlobalSettingsReducer = combineReducers({
    languagesSettings: LanguagesSettingsReducer,
    advancedSettings: advancedSettingsReducer,
    quarantines: QuarantineReducer,
    commonSettings: commonSettingsReducer,
    layouts: LayoutsReducer,
    themes: ThemesReducer,
    timeSlots: TimeSlotsReducer,
    caseAlerts,
    common,
    shareRights: ShareRightsReducer,
});
