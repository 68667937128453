import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ModalFooter = ({
    close,
    disabled,
    onClick,
    closeBtn,
    addBtn,
}) => {
    return (
        <div className="modal-footer">
            <Button onClick={ close } outline color={ 'white' }>
                <FontAwesomeIcon icon="times"/>
                <span> { closeBtn } </span>
            </Button>
            <Button
                onClick={ onClick }
                disabled={ disabled }
                color={ 'primary' }>
                <FontAwesomeIcon icon="check"/>
                <span> { addBtn } </span>
            </Button>
        </div>
    );
};

ModalFooter.defaultProps = {
    closeBtn: 'Cancel',
    addBtn: 'Add filter',
};
