import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CreatableSelect from "react-select/creatable";
import { Button, Col, Input, Row, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Context } from '../../Theme';

import './HeaderTheme.scss';

export const HeaderTheme = () => {
    const {
        themeId,
        name,
        description,
        tags,
        setThemeName,
        setThemeDescription,
        setThemeTags,
        nameIsError,
        allTags: {
            options,
            loading,
        },
    } = useContext(Context);

    const history = useHistory();

    const isOnThemeSettingsTab = (/theme-settings$/).test(history.location.pathname);
    const isHeaderInputDisabled = themeId === 1 || !isOnThemeSettingsTab;

    return (
        <>
            <Row>
                <Col md={ 12 }>
                    <div className={ 'template-header__go-back' }>
                        <Button color="link"
                            onClick={ () => history.push('/feedback/settings/theme-list') }
                        >
                            <FontAwesomeIcon icon='arrow-left'/>
                            <span> Back</span>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className='voc-setting-field_theme_name'>
                <Col md={ 12 }>
                    <Label htmlFor={ 'action_type' }>
                        Theme name
                    </Label>
                </Col>

                <Col md={ 8 }>
                    <div className={ 'voc-setting-field' }>
                        <Input id={ 'action_type' }
                            className={ 'voc-setting-field__input voc-setting-field__input--themeTab' }
                            type="text"
                            value={ name }
                            disabled={ isHeaderInputDisabled }
                            onChange={ ({ target }) => !isHeaderInputDisabled
                                ? setThemeName(target.value)
                                : null
                            }
                        />
                        {
                            nameIsError
                            && <small className='app-form-control__error form-text text-muted'>
                                A not empty value is required.
                            </small>
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={ 12 }>
                    <Label htmlFor={ 'action_type' }>
                        Theme tags
                    </Label>
                </Col>

                <Col md={ 8 }>
                    <CreatableSelect
                        isClearable
                        isMulti
                        isLoading={ loading }
                        options={ options }
                        isDisabled={ isHeaderInputDisabled }
                        onChange={ selectedTags => !isHeaderInputDisabled
                            ? setThemeTags(selectedTags)
                            : null
                        }
                        value={ tags }
                        closeMenuOnSelect={ false }
                        className={ 'voc-setting-field-select' }
                        classNamePrefix={ 'voc-setting-field-select' }
                    />
                </Col>
            </Row>
            <Row>
                <Col md={ 12 }>
                    <Label htmlFor={ 'action_type' }>
                        Theme description
                    </Label>
                </Col>

                <Col md={ 8 }>
                    <div className={ 'voc-setting-field' }>
                        <Input id={ 'action_type' }
                            className={ 'voc-setting-field__input voc-setting-field__input--themeTab' }
                            type="textarea"
                            value={ description }
                            disabled={ isHeaderInputDisabled }
                            onChange={ ({ target }) => !isHeaderInputDisabled
                                ? setThemeDescription(target.value)
                                : null
                            }
                        />
                    </div>
                </Col>
            </Row>
        </>
    );
};
