import React from 'react';
import { useTranslation } from 'react-i18next';

import { VocTableV2, TableRightsColumn, VocRightsHOC } from '/components';
import { RightsTableActionFormatter } from '../RightsTableActionFormatter';

const SystemUploadTable = ({
    getSharedRights,
    tableData,
    loading,
    shares = [],
    total,
    openInviteModal,
    showApplianceModal,
    initiateSourceExport,
    toggleSourceDetailsModal,
}) => {
    const { t } = useTranslation();

    const sharedFormatter = (cell, { id, isShares }) => {
        return <TableRightsColumn
            id={ id }
            getShares={ id => getSharedRights(id, 'systemUpload') }
            shares={ shares }
            isPermitViewInfoShare={ isShares }
        />;
    };

    const actionsFormatter = (cell, { id, rights, uuidMongo, attrByDate, attributes }) => {
        return <RightsTableActionFormatter
            openSourceDetailsModal={ toggleSourceDetailsModal }
            initiateSourceExport={ initiateSourceExport }
            showApplianceModal={ showApplianceModal }
            openInviteModal={ openInviteModal }
            attrByDate={ attrByDate }
            attributes={ attributes }
            uuidMongo={ uuidMongo }
            rights={ rights }
            id={ id }
        />;
    };

    const dataSettings = [
        {
            field: 'title',
            title: t('field.title'),
            formatter: 'conditionTooltip',
            style: {
                width: '50%',
            },
        },
        {
            field: 'languages',
            title: t('field.language'),
            formatter: 'conditionTooltip',
            style: {
                width: '10%',
                textAlign: 'center',
            },
        },

        {
            field: 'updated',
            title: t('field.lastUpdated'),
            formatter: 'date',
            style: {
                width: '20%',
                textAlign: 'center',
            },
        },
        {
            field: 'user',
            title: t('field.role'),
            formatter: sharedFormatter,
            style: {
                width: '5%',
                textAlign: 'center',
            },
        },
        {
            field: 'actions',
            title: t('field.actions'),
            formatter: actionsFormatter,
            style: {
                width: '15%',
                textAlign: 'center',
            },
        },
    ];

    return (
        <div>
            <VocTableV2
                data={ tableData }
                total={ total }
                loading={ loading }
                dataSettings={ dataSettings }
                options={{
                    withoutPagination: true,
                }}
                filters={{
                    page: 1,
                    limit: 1000,
                }}
            />
        </div>
    );
};

export default VocRightsHOC({
    type: 'systemUpload',
})(SystemUploadTable);
