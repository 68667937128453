import React from 'react';
import { TooltipWrapper } from '/components';
import './LayoutNameFormatter.scss';

export const LayoutNameFormatter = props => {
    const openModalManage = () => props.openModalManage(props.layout);

    return (
        <div
            id={ 'table-action__container' }
            className='table-action__container table-action__layout-list'>
            <TooltipWrapper value={ props.layout.name }>
                <span
                    className={ `table-action__layout-link` }
                    onClick={ openModalManage }>
                    { props.layout.name }
                </span>
            </TooltipWrapper>
        </div>
    );
};
