import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Prompt } from 'react-router';
import cx from 'classnames';
import SplitPane, { Pane } from 'split-pane-react';

import {
    ThinSpinner,
    TopBlock,
    SelectSourceBlock,
    VisualBlock,
    TopicsBlock,
    SplitPaneSash,
    MoveTopicToModal,
} from '/components';
import { useTopicSetFieldsData, useTopics, HelperService } from '/services';
import { _getTopicSetInitData, _getSourcesList, _getTopicsData } from '/modules/topicSetModules/topicSet.selectors';
import {
    cleanupTopicSet,
    cleanupTopicSetSocket,
    getTopicSet,
    getSources,
    getTopics,
    updateSourcesFilters,
    cleanupTopicSetSources,
} from '/modules/topicSetModules/topicSet.modules';
import { IGetSourcesHandler, IProps } from './models';

import styles from './styles.module.scss';

export const TopicSet = ({ match }: IProps) => {
    const { topicSetId } = match.params;
    const dispatch = useDispatch();
    const [ sizes, setSizes ] = useState([ 395, '60%' ]);
    const [ openedCategories, setOpenedCategories ] = useState({});
    const [ openMoveToModal, setOpenMoveToModal ] = useState(false);

    const isOneCategoryOpened = Object.keys(openedCategories).some(key => openedCategories[key]);
    const initFieldsData = useSelector(_getTopicSetInitData, (prev, next) => HelperService.deepEqual(prev, next)); // ?
    const sourcesList = useSelector(_getSourcesList);
    const topicsSelector = useSelector(_getTopicsData);
    const isViewPermission = initFieldsData.data?.currentUserPermission === 'view';

    const {
        fieldsData,
        onChange,
        cleanUpFields,
    } = useTopicSetFieldsData({
        initFieldsData,
    });

    const {
        topics,
        loading,
        isEnabledSave,
        isEnabledReset,
        isEnabledValidateColumns,
        topicsForGraphs,
        onSelectTopics,
        onSelectTopicsForMove,
        onCreateCategory,
        onEditCategory,
        onDeleteCategory,
        onAddTopics,
        onMoveTo,
        moveToCount,
        categoriesSelect,
        onResetTopics,
        onSaveTopics,
        cleanUpTopics,
        setViewType,
        viewType,
    } = useTopics({
        language: fieldsData?.language?.value,
        languages: initFieldsData?.data?.languages,
        minedTopics: topicsSelector.data,
        initCategories: initFieldsData?.data?.categories,
        loading: topicsSelector.loading,
        topicSetName: fieldsData.topicSetName,
        initTopicSetName: initFieldsData?.data?.title,
        isColumnSelected: !!fieldsData.column,
        isViewPermission,
        topicSetId,
    });

    const onClickGetTopics = () => {
        dispatch(getTopics({
            sourceId: fieldsData.source.id,
            indexes: fieldsData?.column?.map(columnData => columnData.index),
            language: fieldsData.language?.value,
        }));
    };

    const cleanStates = () => {
        cleanUpTopics();
        cleanUpFields();
        dispatch(cleanupTopicSet());
        dispatch(cleanupTopicSetSocket());
    };

    const getSourcesHandler = (data: IGetSourcesHandler) => {
        if(data.body.search !== sourcesList.filters.search) {
            dispatch(cleanupTopicSetSources());

            dispatch(updateSourcesFilters({
                ...data.body,
                page: 1,
            }));

            dispatch(getSources({
                ...data.body,
                page: 1,
            }, fieldsData?.language?.value));
        } else {
            dispatch(updateSourcesFilters(data.body));
            dispatch(getSources(data.body, fieldsData?.language?.value));
        }
    };

    const onSetPage = (filterPage: { page: string }) => dispatch(updateSourcesFilters({ page: filterPage.page }));

    const openAllCategoriesHandler = () => {
        setOpenedCategories(Object.keys(openedCategories).reduce((acc, id) => ({
            ...acc,
            [id]: !isOneCategoryOpened,
        }), {}));
    };

    useEffect(() => {
        topicSetId && dispatch(getTopicSet(topicSetId));

        return () => cleanStates();
    }, []);

    useEffect(() => {
        if(viewType === 'list' && topics.length && !Object.keys(openedCategories).length) {
            const formatCollapseData = topics.reduce((acc, { id }) => ({
                ...acc,
                [id]: false,
            }), {});

            setOpenedCategories(formatCollapseData);
        }
    }, [ viewType, topics, openedCategories ]);

    return (
        <DocumentTitle title='VocAI :: Topic set'>
            <div className={ styles.topicSetPageWrapper }>
                {
                    topicSetId
                    && <Prompt when={ isEnabledSave }
                        message='Seems you might have some unsaved changes. Are you sure you want to continue?'
                    />
                }

                {
                    initFieldsData.loading && <ThinSpinner />
                }
                <SplitPane
                    resizerSize={ 1 }
                    className={ cx(
                        { [styles.classifierWrapperLoading]: initFieldsData.loading },
                    ) }
                    split='vertical'
                    sizes={ sizes }
                    onChange={ setSizes }
                    sashRender={ SplitPaneSash }
                >
                    <Pane minSize={ 415 }>
                        <div className={ styles.classifierFieldsSection }>
                            <TopBlock
                                onChange={ onChange }
                                fieldsData={ fieldsData }
                                isViewPermission={ isViewPermission }
                            />

                            <hr/>

                            {
                                !!fieldsData.language?.value
                               && <SelectSourceBlock
                                   setPage={ onSetPage }
                                   sourcesList={ sourcesList }
                                   fieldsData={ fieldsData }
                                   getSources={ (data: IGetSourcesHandler) => getSourcesHandler(data) }
                                   onChange={ onChange }
                                   isDisabledReset={ !isEnabledReset }
                                   isDisabledApply={ topicsSelector.loading || !fieldsData.column }
                                   onClickReset={ onResetTopics }
                                   onClickApply={ onClickGetTopics }
                                   onCloseSourceSelect={ () => dispatch(cleanupTopicSetSources()) }
                                   setViewType={ setViewType }
                                   viewType={ viewType }
                                   openAllCategoriesHandler={ openAllCategoriesHandler }
                                   openAllCategories={ isOneCategoryOpened }
                                   moveToCount={ moveToCount }
                                   onMoveTo={ () => setOpenMoveToModal(true) }
                               />
                            }

                            <TopicsBlock
                                categories={ categoriesSelect }
                                onChange={ onChange }
                                onSaveTopics={ onSaveTopics }
                                isEnabledSave={ isEnabledSave }
                                topics={ topics }
                                topicsForGraphs={ topicsForGraphs }
                                fieldsData={ fieldsData }
                                onSelectTopics={ onSelectTopics }
                                onSelectTopicsForMove={ onSelectTopicsForMove }
                                onAddTopics={ onAddTopics }
                                onCreateCategory={ onCreateCategory }
                                onDeleteCategory={ onDeleteCategory }
                                onEditCategory={ onEditCategory }
                                loading={ loading }
                                isEnabledValidateColumns={ isEnabledValidateColumns }
                                viewType={ viewType }
                                openedCategories={ openedCategories }
                                setOpenedCategories={ setOpenedCategories }
                                topicsError={ topicsSelector.error }
                            />
                        </div>
                    </Pane>
                    <Pane className={ styles.visualPane } minSize={ '60%' }>
                        <VisualBlock topics={ topics }/>
                    </Pane>
                </SplitPane>
                <MoveTopicToModal
                    onClose={ () => setOpenMoveToModal(false) }
                    modalIsOpen={ openMoveToModal }
                    onSubmit={ onMoveTo }
                    categories={ categoriesSelect }
                />
            </div>
        </DocumentTitle>
    );
};
