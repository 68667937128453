import React from 'react';
import { VocPopover, RangeDatePickerV2 } from '/components';

import './QueriesTableDatePicker.scss';

export const QueriesTableDatePicker = ({
    isOpenPopover,
    togglePopover,
    targetFilter,
    classDefault,
    className,
    date,
    apply,
    name,
}) => {
    const onFiltered = value => {
        apply({ name, value });
        togglePopover();
    };

    const close = () => {
        togglePopover(name);
    };

    return (
        <VocPopover
            target={ targetFilter }
            isOpen={ isOpenPopover }
            toggle={ close }
            className={ ` ${ className }  ${ classDefault }` }
        >
            <RangeDatePickerV2
                apply={ onFiltered }
                startDate={ date[0] }
                endDate={ date[1] }
                maxDate={ new Date() }
            />
        </VocPopover>
    );
};

QueriesTableDatePicker.defaultProps = {
    classDefault: 'queries-table-date',
    loading: false,
    className: '',
};
