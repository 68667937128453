import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { NumberInput } from '/components/NumberInput';
import { SwitchControl } from '/components/SwitchControl';
import { userSettingsSelector } from '/modules/selectors';
import { updateUserSettings } from '/modules';
import './Settings.scss';

import { autobind } from 'core-decorators';

const mapDispatchToProps = {
    updateUserSettings,
};

const mapStateToProps = state => ({
    settings: userSettingsSelector(state),
});

@connect(mapStateToProps, mapDispatchToProps)
export class Settings extends Component {
    state = { ...this.props.settings };

    @autobind
    handleSubmitButton() {
        this.setState({ loading: true });
        const params = {
            ...this.state,
        };

        delete params['loading'];
        this.props.updateUserSettings(params).promise
            .then(
                () => this.setState({ loading: false }),
                () => this.setState({ loading: false }),
            );
    }

    @autobind
    valueChanged(value, name) {
        if (this.state[name] !== value && !this.state.loading) {
            this.setState({
                ...this.state,
                [name]: value,
            });
        }
    }

    render() {
        const {
            classifyTextsPerPage,
            showActiveSurveys,
            showDeletedSurveys,
            showPausedSurveys,
            showStoppedSurveys,
            showTrainingQueueInWidget,
            showVerificationQueueInWidget,
            widenLayout,
        } = this.state;

        return (
            <DocumentTitle title={ 'Settings - Hub' }>
                <div className={ 'page__wrapper' }>
                    <Button
                        color="success"
                        className="settings__button"
                        disabled={ this.state.loading }
                        onClick={ this.handleSubmitButton }>
                        { 'Save changes' }
                    </Button>
                    <div className="settings__wrapper">
                        <Card className="settings__panel">
                            <CardHeader>
                                <strong>
                                    { 'Classify configuration' }
                                </strong>
                            </CardHeader>
                            <CardBody>
                                <div className="settings-spacing">
                                    <label className="settings__input-label">
                                        { 'Texts per page' }
                                    </label>
                                    <div className="settings__input">
                                        <NumberInput required={ true } step={ 1 } min={ 1 } max={ 100 }
                                            changeHandler={ this.valueChanged }
                                            name={ 'classifyTextsPerPage' }
                                            value={ classifyTextsPerPage }/>
                                    </div>
                                </div>
                                <label className="settings__label">
                                    <span
                                        className="settings__label-text">
                                        { 'Show verification queue in statistics widget' }
                                    </span>
                                    <SwitchControl changeHandler={ this.valueChanged }
                                        name={ 'showVerificationQueueInWidget' }
                                        value={ showVerificationQueueInWidget }/>
                                </label>
                                <label className="settings__label">
                                    <span
                                        className="settings__label-text">
                                        { 'Show training queue in statistics widget' }
                                    </span>
                                    <SwitchControl changeHandler={ this.valueChanged }
                                        name={ 'showTrainingQueueInWidget' }
                                        value={ showTrainingQueueInWidget }/>
                                </label>
                            </CardBody>
                        </Card>
                        <Card className="settings__panel">
                            <CardHeader>
                                <strong>
                                    { 'Visual configuration' }
                                </strong>
                            </CardHeader>
                            <CardBody>
                                <label className="settings__label">
                                    <span className="settings__label-text">
                                        { 'Use wide layout' }
                                    </span>
                                    <SwitchControl
                                        changeHandler={ this.valueChanged }
                                        name='widenLayout'
                                        value={ widenLayout }/>
                                </label>
                                <label className="settings__label">
                                    <span className="settings__label-text">
                                        { 'Show active surveys' }
                                    </span>
                                    <SwitchControl
                                        changeHandler={ this.valueChanged }
                                        name={ 'showActiveSurveys' }
                                        value={ showActiveSurveys }/>
                                </label>
                                <label className="settings__label">
                                    <span className="settings__label-text">
                                        { 'Show deleted surveys' }
                                    </span>
                                    <SwitchControl
                                        changeHandler={ this.valueChanged }
                                        name={ 'showDeletedSurveys' }
                                        value={ showDeletedSurveys }/>
                                </label>
                                <label className="settings__label">
                                    <span className="settings__label-text">
                                        { 'Show paused surveys' }
                                    </span>
                                    <SwitchControl
                                        changeHandler={ this.valueChanged }
                                        name={ 'showPausedSurveys' }
                                        value={ showPausedSurveys }/>
                                </label>
                                <label className="settings__label">
                                    <span className="settings__label-text">
                                        { 'Show stopped surveys' }
                                    </span>
                                    <SwitchControl
                                        changeHandler={ this.valueChanged }
                                        name={ 'showStoppedSurveys' }
                                        value={ showStoppedSurveys }/>
                                </label>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}
