import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { ButtonGroup, Col, Row } from 'reactstrap';
import { VocSelect } from '/components';
import { ControlGlobalLangs } from "/feedback/components";
import { Switch, Route } from 'react-router-dom';
import { ChannelControl } from './components/ChannelsControl';
import {
    switchChannel,
    switchUnit,
    switchLanguage,
    manageLanguages,
    switchTimeSlotType,
} from '../../modules/globalSettings.modules';
import {
    channels,
    channelSelector,
    unitSelector,
    unitsListSelector,
    languagesSelector,
    activeLanguageSelector,
    activeTimeSlotTypeSelector,
    timeSlotTypeOptions,
} from '../../modules/globalSettings.selectors';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { autobind } from 'core-decorators';

import './GlobalSettingsControls.scss';

const mapStateToProps = state => ({
    channelsList: channels,
    unitsList: unitsListSelector(state),
    activeChannel: channelSelector(state),
    globalLanguages: languagesSelector(state),
    activeLang: activeLanguageSelector(state),
    activeUnit: unitSelector(state),
    unit: unitSelector(state),
    activeTimeSlotType: activeTimeSlotTypeSelector(state),
});
const mapDispatchToProps = {
    switchChannel,
    switchLanguage,
    manageLanguages,
    switchUnit,
    switchTimeSlotType,
    showConfirm,
};

@connect(mapStateToProps, mapDispatchToProps)
export class GlobalSettingsControls extends Component {
    componentDidMount() {
        const firstUnit = this.props.unitsList[0];

        this.props.switchUnit(firstUnit);
    }

    componentDidUpdate() {
        const { pathname, activeChannel, activeUnit, unitsList } = this.props;

        if(activeUnit === undefined && unitsList.length) {
            this.props.switchUnit(unitsList[0]);
        }

        if(pathname.includes('time-slots') && [ 'DIGI' ].includes(activeChannel)) {
            this.onSwitchChannel('WEB');
        }
    }

    @autobind
    changeHandler({ target }) {
        const { manageLanguages, unit, globalLanguages, activeLang } = this.props;
        const enableLanguagesList = target.checked
            ? [ ...globalLanguages.enableLanguage, activeLang.id ]
            : globalLanguages.enableLanguage.filter(id => id !== activeLang.id);
        const SURVEY_USER_LANGUAGES = enableLanguagesList.sort((a, b) => a - b);

        manageLanguages({
            unit: unit.value,
            body: { SURVEY_USER_LANGUAGES },
            activeLang,
        });
    }

    @autobind
    unsavedChangesAlert(successCallback) {
        this.props.showConfirm({
            checkDirty: true,
            header: 'Warning!',
            content: 'Seems you might have some unsaved changes. Are you sure you want to continue?',
            successCallback,
        });
    }

    @autobind
    languagesHandler(data) {
        this.unsavedChangesAlert(() => this.props.switchLanguage(data));
    }

    @autobind
    channelsHandler(param, action) {
        this.unsavedChangesAlert(() => action(param));
    }

    @autobind
    onTimeSlotTypeChange({ value }) {
        const { switchTimeSlotType } = this.props;

        this.channelsHandler(value, switchTimeSlotType);
    }

    @autobind
    onSwitchUnit(param) {
        const { switchUnit } = this.props;

        this.channelsHandler(param, switchUnit);
    }

    @autobind
    onSwitchChannel(param) {
        const { switchChannel } = this.props;

        this.channelsHandler(param, switchChannel);
    }

    render() {
        const {
            routes,
            unitsList,
            activeUnit,
            activeLang,
            channelsList,
            activeChannel,
            globalLanguages,
            activeTimeSlotType,
            permission,
        } = this.props;

        const channelSpecific = [ routes.advanced, routes.languages, routes.timeSlots ];

        const filteredChannelsList = location.pathname === routes.timeSlots
            ? channelsList.filter(el => [ 'WEB', 'SMS', 'IVR' ].includes(el))
            : channelsList;

        return (
            <Row className={ 'global-settings__config' }>
                <Col md={ 2 } className={ cx('global-settings__unit', { 'disabled': !unitsList.length }) }>
                    <VocSelect
                        placeholder="Choose units"
                        options={ unitsList }
                        value={ activeUnit }
                        onChange={ this.onSwitchUnit }
                        zIndex={ 90 }
                        portal
                    />
                </Col>
                <Switch>
                    <Route
                        path={ channelSpecific }
                        exact
                        render={ () =>
                            <Col md={ 6 } className={ 'text-right' }>
                                <ButtonGroup className={ 'channels-controls__global' }>
                                    {
                                        filteredChannelsList.map((channel, inx) => (
                                            <ChannelControl key={ inx }
                                                channel={ channel }
                                                activeChannel={ activeChannel }
                                                channelsHandler={ this.onSwitchChannel }
                                                name={ channel }
                                            />
                                        ))
                                    }
                                </ButtonGroup>
                            </Col>
                        }
                    />
                </Switch>

                {
                    <Switch>
                        <Route
                            path={ routes.languages }
                            exact
                            render={ () =>
                                <Col md={ 4 }>
                                    <ControlGlobalLangs
                                        globalLanguages={ globalLanguages }
                                        activeLang={ activeLang }
                                        switchLanguage={ this.languagesHandler }
                                        changeHandler={ this.changeHandler }
                                        disabled={ !unitsList.length || !permission?.isPermitEdit }
                                    />
                                </Col>
                            }
                        />
                    </Switch>
                }

                {
                    <Switch>
                        <Route
                            path={ routes.timeSlots }
                            exact
                            render={ () =>
                                <Col md={ 2 } className={ 'global-settings__time-slots' }>
                                    <VocSelect
                                        placeholder="Choose time slots type"
                                        options={ timeSlotTypeOptions }
                                        value={ activeTimeSlotType }
                                        onChange={ this.onTimeSlotTypeChange }
                                        zIndex={ 90 }
                                        portal
                                    />
                                </Col>
                            }
                        />
                    </Switch>
                }
            </Row>
        );
    }
}
