import { components } from "react-select";
import { SortableElement } from "react-sortable-hoc";
import React, { MouseEventHandler } from "react";

export const SortableMultiValue = SortableElement((props: any) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const innerProps = { ...props.innerProps, onMouseDown };

    return <components.MultiValue
        { ...props }
        className={ 'react-select_sort-element' }
        innerProps={ innerProps }
    />;
});
