import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DeleteCross from '/images/ic_delete.png';

import './TagItems.scss';

export const TagItems = ({
    values,
    onChange,
}) => {
    const onClick = index => {
        onChange(values.filter((el, inx) => inx !== index), index);
    };

    const tagList = useMemo(() => {
        return values.map((item, index) => {
            return (
                <div key={ index } className={ 'tag-block_item' }>
                    <span> { item }</span>
                    <img
                        className={ 'tag-block-item__delete-button' }
                        onClick={ () => onClick(index) }
                        src={ DeleteCross }
                        alt=""
                    />
                </div>
            );
        });
    }, [ values ]);

    return <div className={ 'tags___block' }>{ tagList }</div>;
};

TagItems.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
};

TagItems.defaultProps = {
    values: [],
};
