import React from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { FormatSortableItem } from '../FormatSortableItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SortableItem = SortableElement(props => {
    const DragHandle = SortableHandle(() => <div className="dynamic-form_col col-drag"><FontAwesomeIcon icon="bars"/></div>);

    return (
        <div className='dynamic-form_row'>
            <DragHandle/>
            <FormatSortableItem propsData={{ ...props.propsData }} />
        </div>
    );
});
