import React from 'react';
import { Row, Col } from 'reactstrap';
import PlusIcon from '/images/plus_sign.png';
import classNames from "classnames";
export * from './SettingControlGroup.scss';

export const SettingControlGroup = ({
    addEmail,
    setEmail,
    value,
    checked,
    placeholder,
    type,
}) => {
    const isScript = type === "DIGI_EMBEDDED_SCRIPTS";

    return(
        <div className={ 'setting-control-group' }>
            <Row>
                <Col
                    md={ isScript ? 12 : 6 }
                    className={ classNames({
                        'settings-control-group__script_col': isScript,
                    }) }
                >
                    <input
                        type="email"
                        onChange={ setEmail }
                        value={ value }
                        disabled={ !checked }
                        placeholder={ placeholder }
                        className={ classNames({
                            'settings-control-group__script_input': isScript,
                        }) }
                    />
                    <button
                        className={ 'setting-control-group_button' }
                        onClick={ addEmail }
                        disabled={ !checked }
                    >
                        <span>
                        Add
                        </span>
                        <img src={ PlusIcon } alt=""/>
                    </button>
                </Col>
                <Col md={ 6 }/>
            </Row>
        </div>
    );
};
