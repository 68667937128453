import React from "react";
import Select from "react-select";
import { Col, Label, Row } from "reactstrap";
import { SortableContainer, SortEndHandler } from "react-sortable-hoc";
import { isMobile } from 'react-device-detect';

import { DropdownIndicator, MenuList, Option, SortableMultiValue, SortableMultiValueLabel } from "/components";
import './VocSortableSelect.scss';

const arrayMove = (array:[], from: number, to: number) => {
    const slicedArray = array.slice();

    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0],
    );

    return slicedArray;
};

const SortableSelect: any = SortableContainer(Select);

export const VocSortableSelect = (
    {
        name,
        value,
        isClearable,
        options,
        placeholder,
        className,
        onChange,
        onFocus,
        onBlur,
        disabled,
        removeSelected,
        formatOptionLabel,
        components,
        isRequired,
        isSearchable,
        label,
        isHorizontal,
        defaultValue,
        portal,
        zIndex,
        id,
        closeMenuOnSelect,
    }: any,
) => {
    const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(value, oldIndex, newIndex);

        onChange(newValue);
    };

    const defaultComponents = {
        MenuList,
        DropdownIndicator,
        Option,
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
    };

    const config = {
        className:`vochub-select-control multiselect ${className}`,
        classNamePrefix: "vochub-select-control",
        name,
        value,
        isClearable,
        components: {
            ...defaultComponents,
            ...components,
        },
        options,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        isDisabled: disabled,
        removeSelected,
        defaultValue,
        isSearchable,
        closeMenuOnSelect,
        ...id ? { id } : {},
        ...formatOptionLabel ? { formatOptionLabel } : {},
        ...portal
            ? {
                menuPortalTarget: document.body,
                styles: { menuPortal: (base: any) => ({ ...base, zIndex }) },
                menuShouldScrollIntoView: false,
                menuPlacement: "auto",
            }
            : {},
    };

    const selectColumn = isHorizontal && label ? 9 : 12;

    const isDraggingSelectItems = { shouldCancelStart: () => isMobile };

    return (
        <>
            <Row>
                {
                    label
                    && <Col md={ isHorizontal ? 3 : 12 } className="select-control-label">
                        <Label for={ name }>
                            { label }
                            { isRequired ? <span className='star'> *</span> : '' }
                        </Label>
                    </Col>
                }
                <Col md={ selectColumn }>
                    {
                        <SortableSelect
                            { ...config }
                            axis="xy"
                            distance={ 4 }
                            onSortEnd={ onSortEnd }
                            getHelperDimensions={ ({ node }: any) => node.getBoundingClientRect() }
                            backspaceRemovesValue
                            useDragHandle
                            isMulti
                            { ...isDraggingSelectItems }
                        />
                    }
                </Col>
            </Row>
        </>
    );
};

VocSortableSelect.defaultProps = {
    closeMenuOnSelect: true,
    placeholder: 'Select...',
    zIndex: 9999,
    isSearchable: true,
    withTooltip: false,
};
