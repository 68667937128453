import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Label, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import { VocModal, TooltipWrapper, VocSelect } from '/components';
import { HelperService } from '/services';
import { userRightsSelector } from '/modules/selectors';
import { promptLanguageOutSelector, insightNarratorPromptSelector } from '/query/modules/query.selectors';
import { getAllInsight } from '/query/modules/query.modules';
import { IInsightPromptModal } from './models';
import { TInsightRequestBody, TPreconfiguredQuestion } from '../../models';

import styles from './style.module.scss';

const QUESTIONS: TPreconfiguredQuestion[] = [
    // TODO: add 'Executive Summary' logic
    // {
    //     label: 'Executive Summary',
    //     value: 'executive_summary',
    //     checked: false,
    // },
    // Text
    {
        label: 'Text Analytics',
        value: 'texts',
        checked: false,
        subQuestions: [
            {
                label: 'Semantic Analysis',
                value: 'semantic_analysis',
                checked: false,
                withAdditionalInfo: {
                    header: 'Semantic Analysis (Qualitative)',
                    // eslint-disable-next-line max-len
                    description: 'Semantic Analysis is the process of understanding the meaning and context of text by analyzing language patterns and structures. In Customer Experience, semantic analysis helps identify trends, sentiments, and key themes in customer feedback, providing deeper insights into customer needs and preferences. This allows businesses to improve products, services, and overall customer satisfaction by addressing specific concerns and enhancing positive experiences.',
                },
            },
            {
                label: 'Clustering Analysis',
                value: 'clustering_analysis',
                checked: false,
                withAdditionalInfo: {
                    header: 'Clustering Analysis (Qualitative)',
                    // eslint-disable-next-line max-len
                    description: 'Clustering Analysis is a technique that groups similar data points together based on their characteristics. In Customer Experience, clustering analysis helps segment customer feedback into distinct groups, revealing patterns and common issues. This allows businesses to tailor their responses and improvements to specific customer segments, enhancing personalized experiences and addressing the unique needs of different customer groups.',
                },
            },
            {
                label: 'Topic Detection and Sentiment Analysis',
                value: 'topic_detection_and_sentiment_analysis',
                checked: false,
                withAdditionalInfo: {
                    header: 'Topic Detection and Sentiment Analysis (Qualitative)',
                    // eslint-disable-next-line max-len
                    description: 'Topic Detection and Sentiment Analysis involve identifying key subjects in text data and assessing the emotional tone behind the words. In Customer Experience, these techniques help businesses understand what customers are talking about and how they feel about it. This provides actionable insights into customer concerns and sentiments, enabling companies to address issues promptly, enhance positive experiences, and improve overall customer satisfaction.',
                },
            },
            {
                label: 'Customer Journey Analysis',
                value: 'customer_journey_mapping',
                checked: false,
                withAdditionalInfo: {
                    header: 'Customer Journey Analysis',
                    // eslint-disable-next-line max-len
                    description: 'Customer Journey Analysis is the examination of a customer\'s complete experience with a brand, from initial contact to post-purchase interactions. In Customer Experience, this analysis helps identify critical touchpoints and pain points throughout the customer lifecycle. By understanding the customer\'s journey, businesses can optimize each stage, enhance customer satisfaction, improve retention rates, and create a more seamless and enjoyable experience.',
                },
            },
            {
                label: 'Pros & Cons',
                value: 'pros_and_cons',
                checked: false,
                withAdditionalInfo: {
                    header: 'Pros & Cons',
                    // eslint-disable-next-line max-len
                    description: 'Listing the top pros and cons mentioned by clients involves systematically identifying and summarizing the most frequently praised and criticized aspects of a product or service. In Customer Experience, this process helps businesses understand what customers value most and what issues need urgent attention. By focusing on these key areas, companies can enhance strengths, address weaknesses, and ultimately improve customer satisfaction and loyalty.',
                },
            },
            {
                label: 'Improvement Suggestions',
                value: 'improvement_suggestions',
                checked: false,
                withAdditionalInfo: {
                    header: 'Improvements suggestions',
                    // eslint-disable-next-line max-len
                    description: 'Listing improvement suggestions based on the difficulty of implementation involves categorizing feedback into actionable items that range from easy to hard to execute. In Customer Experience, this approach helps prioritize efforts by addressing simpler, quick-win improvements first while planning for more complex, long-term changes. This ensures efficient resource allocation and steady progress in enhancing customer satisfaction and overall service quality.',
                },
            },
            {
                label: 'SWOT Analysis',
                value: 'swot_analysis',
                checked: false,
                withAdditionalInfo: {
                    header: 'SWOT Analysis',
                    // eslint-disable-next-line max-len
                    description: 'SWOT Analysis is a strategic planning tool used to identify and evaluate the Strengths, Weaknesses, Opportunities, and Threats related to a business or project. In Customer Experience, conducting a SWOT analysis helps businesses understand their internal capabilities and external environment. Strengths and weaknesses focus on internal factors, such as service quality and customer support, while opportunities and threats address external factors like market trends and competitive actions. This comprehensive view aids in developing strategies to leverage strengths, address weaknesses, seize opportunities, and mitigate threats, ultimately enhancing the customer experience.',
                },
            },
            {
                label: 'TOWS Analysis',
                value: 'tows_analysis',
                checked: false,
                withAdditionalInfo: {
                    header: 'TOWS Analysis',
                    // eslint-disable-next-line max-len
                    custom: <>
                        <p>
                            {/* eslint-disable-next-line max-len */}
                            TOWS Analysis is a strategic tool that extends SWOT analysis by focusing on actionable strategies based on internal and external factors. In Customer Experience, TOWS analysis involves:
                        </p>
                        <p>
                            TOWS Matrix: This matrix helps businesses create strategies by combining different elements of SWOT:
                        </p>
                        <ul>
                            <li>
                                Strengths-Opportunities (SO): Leveraging strengths to take advantage of opportunities.
                            </li>
                            <li>
                                Weaknesses-Opportunities (WO): Overcoming weaknesses by capitalizing on opportunities.
                            </li>
                            <li>
                                Strengths-Threats (ST): Using strengths to counteract threats.
                            </li>
                            <li>
                                Weaknesses-Threats (WT): Minimizing weaknesses to defend against threats.
                            </li>
                        </ul>
                    </>,
                },
            },
            {
                label: 'Taxonomy',
                value: 'taxonomy',
                checked: false,
                withAdditionalInfo: {
                    header: 'Taxonomy',
                    description: 'Taxonomy provides a detailed overview of the topic classification structure, organized in two distinct levels: Categories (1st Level), for broad themes and domains, each branching into more specific Topics (2nd Level). Categories cover the main areas of focus relevant to the feedback (e.g., “Product Quality,” “Customer Service,” “User Experience”). Under each Category, Topics are identified that refine these areas into specific subjects (e.g., within “Product Quality,” topics might include “Durability,” “Materials,” and “Design”). The resulting structure provides a detailed structure used to help build TopicSets or classification models feedback by main themes, with a secondary layer to highlight particular areas of interest within each theme.',
                },
            },
        ],
    },
    {
        label: 'Chart Analytics',
        value: 'charts',
        checked: false,
        subQuestions: [
            // Bar
            {
                label: 'Frequency Analysis',
                value: 'lemmata',
                checked: false,
            },
            // CoOccurrence
            {
                label: 'Co-occurrence Analysis',
                value: 'lemmataForCoOccurrence',
                checked: false,
            },
            // Correlation
            {
                label: 'Correlation Analysis',
                value: 'lemmataForCorrelation',
                checked: false,
            },
        ],
    },
];

export const InsightPromptModal = ({
    show,
    pageRequests,
    toggle,
}: IInsightPromptModal) => {
    const {
        promptLanguageOutList,
        promptLanguageOutDefault,
    } = useSelector(promptLanguageOutSelector);
    const [ queryContext, setQueryContext ] = useState<string>('');
    const [ languageOutSelected, setLanguageOutSelected ] = useState<{
        value: string,
        label: string,
    }>(promptLanguageOutDefault);
    const [ isInputMode, setIsInputMode ] = useState(false);
    const [ checkedPreconfigured, setCheckedPreconfigured ] = useState<string[]>([]);
    const [ questionConfig, setQuestionConfig ] = useState<TPreconfiguredQuestion[]>(QUESTIONS);
    const { hasMineInsightAccess } = useSelector(userRightsSelector);
    const {
        inLoadingProcess,
        disabled,
        isExisted,
        initialContext,
    }: {
        inLoadingProcess: string[],
        disabled: string[],
        isExisted: string[],
        initialContext: string,
    } = useSelector(insightNarratorPromptSelector(QUESTIONS));
    const tooltipInfo = 'Here you can describe the context of the data query to improve the output of the narration. Please be as specific as you possible (i.e. specifying your industry, how the data was collected, by whom, etc.)';
    const runNarratorCondition = hasMineInsightAccess && checkedPreconfigured.length;
    const dispatch = useDispatch();

    const handleChangeQueryContext = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event?.target;

        setQueryContext(value);
    };

    const handleChangeLanguageOut = (option: { value: string, label: string }) =>
        setLanguageOutSelected(option);

    const toggleCheckbox = (
        questionValue: TPreconfiguredQuestion['value'],
        checkboxes: TPreconfiguredQuestion[],
    ): TPreconfiguredQuestion[] => {
        const markSubItems = (value: boolean, list?: TPreconfiguredQuestion[]): TPreconfiguredQuestion[] => list
            ? list.map(item => ({
                ...item,
                checked: value,
                subQuestions: item.subQuestions? markSubItems(value, item.subQuestions) : undefined,
            }))
            : [];

        return checkboxes.map(checkboxItem => {
            if (checkboxItem.value === questionValue) {
                const value = !checkboxItem.checked;

                return {
                    ...checkboxItem,
                    checked: value,
                    subQuestions: checkboxItem.subQuestions ? markSubItems(value, checkboxItem.subQuestions) : undefined,
                };
            } else if (checkboxItem.subQuestions) {
                const subQuestions = toggleCheckbox(questionValue, checkboxItem.subQuestions);

                return {
                    ...checkboxItem,
                    subQuestions: subQuestions,
                    checked: subQuestions.every(subQuestion => subQuestion.checked),
                };
            }

            return checkboxItem;
        });
    };

    // checked list
    const getCheckedQuestions = (array: TPreconfiguredQuestion[], acc: string[]) => {
        array.forEach(question => {
            question.checked && acc.push(question.value);
            question.subQuestions && getCheckedQuestions(question.subQuestions, acc);
        });

        return acc;
    };

    /* PRECONFIGURED QUESTIONS */
    const handleChangeCheckbox = (questionValue: TPreconfiguredQuestion['value']) => {
        const updatedPreconfiguredQuestions = toggleCheckbox(questionValue, questionConfig);

        // configuration
        setQuestionConfig(updatedPreconfiguredQuestions);

        setCheckedPreconfigured(getCheckedQuestions(updatedPreconfiguredQuestions, []));
    };

    const resetQueryContextChange = (context?: string) => {
        setQueryContext(context || initialContext);
        setIsInputMode(false);
    };

    const handleReset = () => {
        resetQueryContextChange(initialContext);
        setLanguageOutSelected(promptLanguageOutDefault);
        setCheckedPreconfigured([]);
        setQuestionConfig(QUESTIONS);
    };

    useEffect(() => {
        handleReset();
    }, [ show ]);

    const getCheckedOptionsBySection = (sectionValue: string) => {
        const section = QUESTIONS.find(({ value }) => value === sectionValue);
        let checkedValues = null;

        if (section) {
            const possibleValues = section.subQuestions?.map(({ value }) => value) || [];

            checkedValues = possibleValues.filter(value => checkedPreconfigured.includes(value));
        }

        return checkedValues?.length ? checkedValues : null;
    };

    const handleRunNarrator = () => {
        const insightRequestBody: TInsightRequestBody = {
            language_out: languageOutSelected.value,
            textsOptions: getCheckedOptionsBySection('texts'),
            chartsOptions: getCheckedOptionsBySection('charts'),
        };

        if (initialContext !== queryContext) {
            insightRequestBody.context = queryContext;
        }

        const requests = dispatch(getAllInsight(insightRequestBody)) as unknown as any[];

        requests.forEach(request => pageRequests?.addRequest(request));

        toggle();
    };

    /* QUERY CONTEXT */
    const toggleInputMode = () => setIsInputMode(!isInputMode);

    const getQueryContextField = () => isInputMode
        ? <Input
            id='queryContext'
            type='textarea'
            placeholder='i.e. Insurance company analysing customer feedback from the online payment touchpoint.'
            rows={ 3 }
            defaultValue={ queryContext }
            onChange={ handleChangeQueryContext }
        />
        : <span>{ queryContext }</span>;

    const getWarningBlock = () => (
        <Alert
            color='warning'
            className={ styles.warningBlock }
        >
            <span>
                Please, specify a <strong>Query Context</strong> for more precise <strong>Insight</strong> formation
            </span>
        </Alert>
    );

    const getQuestionTooltipContent = ({ header, custom, description }: TPreconfiguredQuestion['withAdditionalInfo'] = {}) =>
        <>
            {
                header
                && <h5>{ header }</h5>
            }
            {
                custom
                && <div className={ styles.popupWithList }>
                    { custom }
                </div>
            }
            {
                description
                && <p>{ description }</p>
            }
        </>;

    const getQuestionView = (question: TPreconfiguredQuestion) => {
        const {
            label,
            value,
            checked,
            withAdditionalInfo,
            subQuestions = [],
        } = question;
        const checkedSubQuestions = subQuestions
            ?.filter(
                subQuestion => checkedPreconfigured.includes(subQuestion.value),
            ) || [];
        const minusMark = checkedSubQuestions.length > 0
            && checkedSubQuestions.length < subQuestions.length;

        return (
            <div key={ value }>
                <Label
                    for={ `checkbox_${ value }` }
                    className={ cx(styles.preconfiguredQuestionLabel, { [styles.minusMark]: minusMark }) }
                >
                    <Input
                        type='checkbox'
                        id={ `checkbox_${ value }` }
                        checked={ checked }
                        disabled={ disabled.includes(value) }
                        onChange={ () => handleChangeCheckbox(question.value) }
                    />
                    <span>{ label }</span>
                    {
                        withAdditionalInfo
                        && <TooltipWrapper
                            force
                            innerClassName={ styles.questionInfoTooltip }
                            value={ getQuestionTooltipContent(withAdditionalInfo) }
                        >
                            <span className={ styles.infoBtn }>
                                <FontAwesomeIcon icon='info-circle' />
                            </span>
                        </TooltipWrapper>
                    }
                    {
                        inLoadingProcess.includes(value)
                        && <div className={ styles.loading } />
                    }
                </Label>
                {
                    isExisted.includes(value)
                    && <div className={ styles.existed }>
                        <FontAwesomeIcon icon='check' />
                    </div>
                }
                <div style={{ marginLeft: '1.75rem' }}>
                    {
                        subQuestions.length > 0
                        && subQuestions.map(getQuestionView)
                    }
                </div>
            </div>
        );
    };

    const getBody = () => {
        let mainBtnText = 'Reset';
        let mainBtnIcon: IconProp = 'undo';
        const isQueryContextExist = HelperService.checkNotEmpty(queryContext);

        if (!isInputMode) {
            mainBtnText = isQueryContextExist ? 'Edit' : 'Add';
            mainBtnIcon = isQueryContextExist ? 'pencil-alt' : 'plus';
        }

        return (
            <>
                <Label for='queryContext'>
                    Specify the context of the dataset:
                </Label>
                <TooltipWrapper
                    force
                    value={ tooltipInfo }
                >
                    <span className={ styles.infoBtn }>
                        <FontAwesomeIcon icon='info-circle' />
                    </span>
                </TooltipWrapper>
                <div className={ styles.queryContextFieldWrapper }>
                    <div className={ styles.queryContextField }>
                        {
                            isQueryContextExist || isInputMode
                                ? getQueryContextField()
                                : getWarningBlock()
                        }
                    </div>
                    <TooltipWrapper
                        force
                        value={ mainBtnText }
                    >
                        <Button
                            outline={ isInputMode }
                            color={ isInputMode ? 'danger' : 'primary' }
                            onClick={ isInputMode ? () => resetQueryContextChange() : toggleInputMode }
                        >
                            <FontAwesomeIcon icon={ mainBtnIcon } />
                        </Button>
                    </TooltipWrapper>
                </div>

                <div className={ styles.languageOutWrapper }>
                    <Label for='languageOut'>
                        Language:
                    </Label>
                    <VocSelect
                        className={ styles.languageOutField }
                        id='languageOut'
                        options={ promptLanguageOutList }
                        value={ languageOutSelected }
                        onChange={ handleChangeLanguageOut }
                    />
                </div>

                <Label>
                    Specify the text output of the dataset:
                </Label>
                <div className={ styles.preconfiguredQuestionsWrapper }>
                    {
                        questionConfig.map(getQuestionView)
                    }
                </div>
                <span className={ styles.grayHint }>
                    Insight Narrator uses APIs to call external LLMs.
                    By clicking “Run Narrator”, you agree to use these services
                    and agree to the following  {<a href='https://sandsiv.com/terms-of-use/'>terms</a>}.
                </span>
            </>
        );
    };

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color='white'
                onClick={ toggle }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                Cancel
            </Button>
            <Button
                className={ cx({ [styles.active]: runNarratorCondition }) }
                color='secondary'
                disabled={ !runNarratorCondition }
                onClick={ handleRunNarrator }
            >
                <FontAwesomeIcon icon='rocket' />
                &nbsp;
                <span>Run Narrator</span>
            </Button>
        </div>
    );

    return (
        <VocModal
            isOpen={ show }
            header='Insight Narrator Content Output'
            modalClassName={ cx(styles.insightPromptModal, 'modal-scrollable') }
            footer={ getModalFooter() }
            toggle={ toggle }
        >
            {
                getBody()
            }
        </VocModal>
    );
};
