import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Label, Col, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';

import { VocModal } from '/components';
import { HelperService } from '/services';

import './AttachSurveyModal.scss';

export class AttachSurveyModal extends Component {
    @autobind
    onAddTouchPointClicked() {
        const cacheSurveys = this.props.data.surveys?.map(survey => survey.value) || [];
        const selectedSurveys = this.props.data.touchPointsSurveys.map(survey => survey.value);
        const params = {
            id: this.props.activeTouchPoint,
            include: HelperService.arrDiff(cacheSurveys, selectedSurveys),
            exclude: HelperService.arrDiff(selectedSurveys, cacheSurveys),
        };

        this.props.actionHandler(params);
        this.props.close();
    }

    @autobind
    onAddTouchPointClosed() {
        this.props.close();
    }

    @autobind
    getSurveys(term, callback) {
        const excludeIds = this.props.data.surveys.map(survey => survey.value);
        const params = {
            name: term,
            touch_point_id: 1,
            limit: 100,
            sort: '-id',
            hide_deleted_surveys: true,
        };

        if (excludeIds.length) {
            params['exclude_by_ids'] = excludeIds.join(',');
        }

        this.props.getSurveysGroup(params)
            .promise.then(response => {
                const options = response.results
                    .filter(survey => this.props.getFilteredByUnit(survey, survey.unit))
                    .map(result => ({
                        value: String(result.id),
                        label: result.name,
                    }));

                callback(options);
            });
    }

    @autobind
    filterSurveys(option) {
        return !this.props.data.surveys?.some(
            survey => String(survey.value) === option.value,
        );
    }

    @autobind
    changeHandler(surveys) {
        this.props.onChangeSurveysData(surveys);
    }

    getModalFooter() {
        return (
            <div>
                <Button onClick={ this.onAddTouchPointClosed } color={ 'white' } outline>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button onClick={ this.onAddTouchPointClicked }
                    color="primary">
                    <FontAwesomeIcon icon={ 'check' }/>
                    <span> Save</span>
                </Button>
            </div>
        );
    }

    getModalBody() {
        const { surveys } = this.props?.data;

        return (
            <Row>
                <Col md={ 3 }>
                    <Label className="text-field-control-label">Select Surveys</Label>
                </Col>
                <Col md={ 9 }>
                    <AsyncSelect
                        name={ 'surveys-search' }
                        className="vochub-select-control"
                        classNamePrefix="vochub-select-control"
                        menuPortalTarget={ document.body }
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        value={ surveys }
                        loadOptions={ this.getSurveys }
                        onChange={ this.changeHandler }
                        removeSelected={ false }
                        placeholder={ 'Type in survey name' }
                        isMulti={ true }
                        defaultOptions={ true }
                        filterOption={ this.filterSurveys }
                        clearable={ false }
                    />
                </Col>
            </Row>
        );
    }

    render() {
        const { show, close } = this.props;

        return (
            <VocModal
                modalClassName={ 'attach-survey-modal' }
                isOpen={ show }
                toggle={ close }
                header={ 'Add Survey into Touchpoint' }
                footer={ this.getModalFooter() }
            >
                {
                    this.getModalBody()
                }
            </VocModal>
        );
    }
}
