import React, { useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import SplitPane, { Pane } from 'split-pane-react';
import { isMobile } from 'react-device-detect';

import {
    PopoverRangeSlider, useLineChart,
    useDrillDown, useHover, GadgetService,
} from '/visual/scenes/Dashboard/components';
import { sendChartColor, sendLabel, setContentSettings } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import {
    ChartLabelsSortableList,
    ChartColorPicker,
    FooterInfo,
} from '/visual/scenes/Dashboard/components/Gadget/components';
import { PopoverChartTooltip, SplitPaneSash } from '/components';
import { useSplitPane } from '/services';
import { LineChartD3 } from './components';
import { LabelModal } from '../LabelModal';
import {
    IChartData, IColorPickerData,
    changeColorHandlerType, TLabelPopoverData,
    TApplyLabelModalData, ELabelType,
} from '../../models';

import commonStyles from '../BaseChart/style.module.scss';

export const LineChart = ({ chartGadgetData }: { chartGadgetData: IChartData }) => {
    const dispatch = useDispatch();
    const { gadgetData } = chartGadgetData;

    const {
        labels,
        lines,
        level,
        filterLabels,
        allLabels,
        records,
        sliderData,
        axisLabels,
        isNpsChart,
        npsData,
        changeLabel,
        changeItemColor,
        changeSlider,
    } = useLineChart(chartGadgetData);

    const {
        sizes,
        onChange,
        onDragEnd,
    } = useSplitPane({
        initSizes: gadgetData.contentSettings?.labelsSize,
        sizeChanged: newSizes => dispatch(setContentSettings(gadgetData.id, {
            ...gadgetData.contentSettings,
            labelsSize: newSizes,
        })),
    });

    const tooltipRef = createRef<HTMLDivElement>();
    const { getDrillDownDashboard } = useDrillDown({ gadgetData });
    const { isHovered, hoverRef, mouseOver, mouseOut } = useHover<HTMLDivElement>(sliderData.disableSlider);

    const [ highlightedLabel, setHighlightedLabel ] = useState<string | null>(null);
    const [ colorPickerData, setColorPickerData ] = useState<IColorPickerData | null>(null);
    const [ tickData, setTickData ] = useState<TLabelPopoverData | null>(null);

    const resetTickData = () => setTickData(null);

    const changeColorHandler = ({ elementId = '', colorHex }: changeColorHandlerType) => {
        changeItemColor(
            elementId,
            colorHex,
            (colorData, isChanged) => {
                dispatch(sendChartColor(gadgetData.id, colorData, isChanged));
            },
        );
    };

    const onApplyLabelModal = (labelData: TApplyLabelModalData) => {
        changeLabel(
            labelData.id,
            labelData.type,
            labelData.value,
            (labelRes, isChanged) => {
                dispatch(sendLabel(gadgetData.id, labelRes, isChanged));
            });
    };

    const changeLabelOrderHandler = (order: string[]) => {
        dispatch(setContentSettings(gadgetData.id, {
            ...gadgetData.contentSettings,
            order,
        }));
    };

    const tooltipValueFormatter = (index: number) =>
        GadgetService.rangeTooltipValueFormatter({ index, list: allLabels });

    const onApplyLabelModalDataHandler = (inputValue: TLabelPopoverData['value']) => {
        if (tickData) {
            onApplyLabelModal({
                id: tickData.id,
                value: inputValue,
                type: tickData.type,
            });
        }
    };

    return (
        <div
            className={ cx(commonStyles.chartWrapper) }
            ref={ hoverRef }
            onMouseOver={ mouseOver }
            onMouseLeave={ mouseOut }
        >
            <SplitPane
                allowResize={ !isMobile }
                performanceMode
                resizerSize={ 1 }
                split='horizontal'
                sizes={ sizes }
                onChange={ onChange }
                onDragEnd={ onDragEnd }
                sashRender={ () => <SplitPaneSash split='horizontal'/> }
            >
                <Pane className={ commonStyles.graphSplitPanePadding } minSize='35%'>
                    <LineChartD3
                        labels={ labels }
                        lines={ lines }
                        level={ level }
                        showScore={ gadgetData.contentSettings.annotateScore }
                        percentage={ gadgetData.contentSettings.relativeMode && gadgetData.contentSettings.function !== 'npsScore' }
                        axisLabels={ axisLabels }
                        highlightedLabel={ highlightedLabel }
                        gadgetId={ gadgetData.id }
                        gadgetFunction={ gadgetData.contentSettings.function }
                        drillDownEnabled={ gadgetData.contentSettings.drillDown }
                        chartType={ gadgetData.contentSettings.chartType }
                        isNpsChart={ isNpsChart }
                        npsData={ npsData }
                        toolTipRef={ tooltipRef }
                        setChangeTickModal={ setTickData }
                        resetTickData={ resetTickData }
                        onApplyLabelModal={ onApplyLabelModal }
                        setColorPickerData={ setColorPickerData }
                        drillDownFromSelection={ getDrillDownDashboard }
                    />
                </Pane>

                <Pane minSize='20%' maxSize='50%' className={ commonStyles.labelsSplitPanePadding }>
                    <div className={ commonStyles.labelsContainer }>
                        <ChartLabelsSortableList
                            dragDisable={ isMobile }
                            labels={ filterLabels }
                            longLabel={ gadgetData.contentSettings.longLabel }
                            gadgetId={ gadgetData.id }
                            chartType={ gadgetData.contentSettings.chartType }
                            updateSelectedLabel={ setHighlightedLabel }
                            setColorPickerData={ setColorPickerData }
                            onApplyLabelModal={ onApplyLabelModal }
                            onSortEnd={ changeLabelOrderHandler }
                            tickData={ tickData }
                        />
                    </div>
                </Pane>
            </SplitPane>

            <FooterInfo records={ records } lastHealthCheckTime={ gadgetData.lastHealthCheckTime } />

            <ChartColorPicker
                chartType={ gadgetData.contentSettings.chartType }
                gadgetId={ gadgetData.id }
                colorPickerData={ colorPickerData }
                setColorPickerData={ setColorPickerData }
                changeColorCallback={ changeColorHandler }
            />

            <PopoverRangeSlider
                hovered={ isHovered }
                sliderData={ sliderData }
                chartContainerRef={ hoverRef }
                onChangeSlider={ changeSlider }
                tooltipValueFormatter={ tooltipValueFormatter }
            />

            <PopoverChartTooltip ref={ tooltipRef } />

            <LabelModal
                labelModalData={ tickData }
                maxLength={ 250 }
                labelType={ ELabelType.TICK }
                setLabelModalData={ setTickData }
                onApplyLabelModalData={ onApplyLabelModalDataHandler }
            />
        </div>
    );
};
