import { CALL_API } from 'store/middleware/api';

export const GET_QUERY_LIST_REQUEST = 'GET_QUERY_LIST_REQUEST';
export const GET_QUERY_LIST_SUCCESS = 'GET_QUERY_LIST_SUCCESS';
export const GET_QUERY_LIST_ERROR = 'GET_QUERY_LIST_ERROR';

export const DELETE_QUERY_REQUEST = 'DELETE_QUERY_REQUEST';
export const DELETE_QUERY_SUCCESS = 'DELETE_QUERY_SUCCESS';
export const DELETE_QUERY_ERROR = 'DELETE_QUERY_ERROR';

export const CREATE_QUERY_REQUEST = 'CREATE_QUERY_REQUEST';
export const CREATE_QUERY_SUCCESS = 'CREATE_QUERY_SUCCESS';
export const CREATE_QUERY_ERROR = 'CREATE_QUERY_ERROR';

export const GET_SOURCES_REQUEST = 'GET_SOURCES_REQUEST';
export const GET_SOURCES_SUCCESS = 'GET_SOURCES_SUCCESS';
export const GET_SOURCES_ERROR = 'GET_SOURCES_ERROR';

export const GET_QUERY_TITLE_REQUEST = 'GET_QUERY_TITLE_REQUEST';
export const GET_QUERY_TITLE_SUCCESS = 'GET_QUERY_TITLE_SUCCESS';
export const GET_QUERY_TITLE_ERROR = 'GET_QUERY_TITLE_ERROR';

export const GET_QUERY_SOURCE_REQUEST = 'GET_QUERY_SOURCE_REQUEST';
export const GET_QUERY_SOURCE_SUCCESS = 'GET_QUERY_SOURCE_SUCCESS';
export const GET_QUERY_SOURCE_ERROR = 'GET_QUERY_SOURCE_ERROR';

export const SAVE_NEW_NAME_REQUEST = 'SAVE_NEW_NAME_REQUEST';
export const SAVE_NEW_NAME_SUCCESS = 'SAVE_NEW_NAME_SUCCESS';
export const SAVE_NEW_NAME_ERROR = 'SAVE_NEW_NAME_ERROR';

export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const RESET_SELECTED_FILTERS = 'RESET_SELECTED_FILTERS';

export const QUERY_LIST_FILTERS_CHANGED = 'QUERY_LIST_FILTERS_CHANGED';
export const SOURCE_FILTERS_CHANGED = 'SOURCE_FILTERS_CHANGED';
export const CLEANUP_QUERY_LIST = 'CLEANUP_LIST_QUERIES';
export const CLEANUP_SOURCE = 'CLEANUP_SOURCE';

const extraFilters = JSON.parse(localStorage.getItem('voc_mine_filters'));

function getInitialState(extraFilters) {
    return {
        filters: {
            page: 1,
            limit: 10,
            search: '',
            sort_field: null,
            sort_order: null,
            extraFilters: extraFilters
                ? {
                    ...extraFilters,
                }
                : {
                    title: [],
                    tag: [],
                    created: [],
                    updated: [],
                    sourceId: [],
                },
        },
        queryList: {
            loading: false,
            data: [],
            count: 0,
        },
        queryTitles: {
            data: [],
            loading: false,
        },
        querySources: {
            data: {},
            loading: false,
        },
        source: {
            loading: false,
            data: [],
            filters: {
                limit: 15,
                type: 'all',
                page: 1,
                search: '',
            },
            count: 0,
        },
    };
}

export function reducer(state = getInitialState(extraFilters), action = {}) {
    switch (action.type) {
        case GET_QUERY_LIST_REQUEST:
            return {
                ...state,
                queryList: {
                    ...state.queryList,
                    loading: true,
                },
            };

        case GET_QUERY_LIST_SUCCESS:
            return {
                ...state,
                queryList: {
                    ...action.response,
                    loading: false,
                },
            };

        case GET_QUERY_LIST_ERROR:
            return {
                ...state,
                queryList: {
                    ...state.queryList,
                    loading: false,
                },
            };

        case GET_QUERY_TITLE_REQUEST:
            return {
                ...state,
                queryTitles: {
                    data: [],
                    loading: true,
                },
            };

        case GET_QUERY_TITLE_SUCCESS:
            return {
                ...state,
                queryTitles: {
                    data: [ ...action.response ],
                    loading: false,

                },
            };

        case GET_QUERY_TITLE_ERROR:
            return {
                ...state,
                queryTitles: {
                    data: [],
                    loading: false,
                },
            };

        case GET_QUERY_SOURCE_REQUEST:
            return {
                ...state,
                querySources: {
                    data: {},
                    loading: true,
                },
            };

        case GET_QUERY_SOURCE_SUCCESS:
            return {
                ...state,
                querySources: {
                    data: { ...action.response },
                    loading: false,
                },
            };

        case GET_QUERY_SOURCE_ERROR:
            return {
                ...state,
                querySources: {
                    data: {},
                    loading: false,
                },
            };

        case GET_SOURCES_REQUEST:
            const source = action.body.page === 1
                ? {
                    ...state.source,
                    data: [],
                    count: 0,
                }
                : {
                    ...state.source,
                };

            return {
                ...state,
                source: {
                    ...source,
                    loading: true,
                },
            };

        case GET_SOURCES_SUCCESS:
            return {
                ...state,
                source: {
                    ...state.source,
                    ...action.response,
                    data: [
                        ...state.source.data,
                        ...action.response.data,
                    ],
                    loading: false,
                },
            };

        case GET_SOURCES_ERROR:
            return {
                ...state,
                source: {
                    ...state.source,
                    loading: false,
                },
            };

        case SET_SELECTED_FILTERS: {
            const { name, value } = action.payload;
            const extraFilters = {
                ...state.filters.extraFilters,
                [name]: value,
            };

            localStorage.setItem('voc_mine_filters', JSON.stringify(extraFilters));

            return {
                ...state,
                filters: {
                    ...state.filters,
                    extraFilters: {
                        ...extraFilters,
                    },
                },
            };
        }

        case RESET_SELECTED_FILTERS: {
            localStorage.removeItem('voc_mine_filters');

            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: 1,
                    extraFilters: action.payload ? {
                        ...state.filters.extraFilters,
                        [action.payload]: [],
                    } : {
                        ...getInitialState().filters.extraFilters,
                    },
                },
            };
        }

        case CLEANUP_SOURCE:
            return {
                ...state,
                source: {
                    ...state.source,
                    data: [],
                    count: 0,
                },
            };

        case SOURCE_FILTERS_CHANGED:
            return {
                ...state,
                source: {
                    ...state.source,
                    filters: {
                        ...state.source.filters,
                        ...action.payload,
                    },
                },
            };

        case QUERY_LIST_FILTERS_CHANGED:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };

        case CLEANUP_QUERY_LIST: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getQueriesList(body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/`,
            method: 'POST',
            contentType: 'application/json',
            unique: true,
            types: [ GET_QUERY_LIST_REQUEST, GET_QUERY_LIST_SUCCESS, GET_QUERY_LIST_ERROR ],
            body,
        },
    };
}

export function deleteQuery(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_QUERY_REQUEST, DELETE_QUERY_SUCCESS, DELETE_QUERY_ERROR ],
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP_QUERY_LIST,
    };
}

export function updateFilters(payload) {
    return {
        type: QUERY_LIST_FILTERS_CHANGED,
        payload,
    };
}

export function createNewQuery(body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/create`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_QUERY_REQUEST, CREATE_QUERY_SUCCESS, CREATE_QUERY_ERROR ],
            logger: true,
            loggerMessages: {
                success: 'Query was successfully created',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function updateSourceFilters(payload) {
    return dispatch => {
        const { page, search } = payload;

        dispatch(updateSourceFiltersAction(payload));

        if (page === 1 && !search) {
            dispatch(cleanupSource());
        }
    };
}

export function updateSourceFiltersAction(payload) {
    return {
        type: SOURCE_FILTERS_CHANGED,
        payload,
    };
}

export function cleanupSource() {
    return {
        type: CLEANUP_SOURCE,
    };
}

export function getSources(filters) {
    return {
        [CALL_API]: {
            endpoint: '/public_api/mine/sources/with-texts',
            method: 'GET',
            contentType: 'application/json',
            unique: true,
            types: [ GET_SOURCES_REQUEST, GET_SOURCES_SUCCESS, GET_SOURCES_ERROR ],
            body: {
                ...filters,
            },
        },
    };
}

export function getQueryTitles() {
    return {
        [CALL_API]: {
            endpoint: '/public_api/mine/query/titles',
            method: 'GET',
            contentType: 'application/json',
            unique: true,
            types: [ GET_QUERY_TITLE_REQUEST, GET_QUERY_TITLE_SUCCESS, GET_QUERY_TITLE_ERROR ],
        },
    };
}

export function getQuerySources() {
    return {
        [CALL_API]: {
            endpoint: '/public_api/mine/sources/with-queries',
            method: 'GET',
            contentType: 'application/json',
            unique: true,
            types: [ GET_QUERY_SOURCE_REQUEST, GET_QUERY_SOURCE_SUCCESS, GET_QUERY_SOURCE_ERROR ],
        },
    };
}

export function setSelected(payload) {
    return {
        type: 'SET_SELECTED_FILTERS',
        payload,
    };
}

export function resetFilters(payload) {
    return {
        type: 'RESET_SELECTED_FILTERS',
        payload,
    };
}

export function saveNewName({ id, ...rest }) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }/edit`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ SAVE_NEW_NAME_REQUEST, SAVE_NEW_NAME_SUCCESS, SAVE_NEW_NAME_ERROR ],
            logger: true,
            unique: true,
            loggerMessages: {
                success: 'Query name changed',
                error: 'Something went wrong',
            },
            body: rest,
        },
    };
}
