import React, { memo } from 'react';
import cx from 'classnames';

import { TooltipWrapper } from '/components';

import styles from './style.module.scss';

export const TableBody = ({ table, wordwrapMode, rowVirtualizer }) => {
    return rowVirtualizer.getVirtualItems().map(virtualRow => {
        const row = table.getRowModel().rows[virtualRow.index];

        return <div
            data-index={ virtualRow.index }
            className={ cx(styles.tr, { [styles.trEven]: virtualRow.index % 2 !== 0 }) }
            key={ virtualRow.key }
            ref={ rowVirtualizer.measureElement }
        >
            {
                row.getVisibleCells().map(cell => {
                    return <div
                        className={ styles.td }
                        key={ cell.id }
                        style={{ width: `calc(var(--col-${cell.column.id}-size) * 1px)` }}
                    >
                        <div className={
                            cx(styles.innerTd,
                                {
                                    [styles.innerTdNoWrap]: !wordwrapMode,
                                },
                            )
                        }>
                            {
                                wordwrapMode
                                    ? <span>{ cell.renderValue() }</span>
                                    : <TooltipWrapper value={ cell.renderValue() }>
                                        <span>{ cell.renderValue() }</span>
                                    </TooltipWrapper>
                            }
                        </div>
                    </div>;
                })
            }
        </div>;
    });
};

//special memoized wrapper for our table body that we will use during column resizing
export const MemoizedTableBody = memo(
    TableBody,
    (prev, next) => prev.table.options.data === next.table.options.data,
) as typeof TableBody;
