import { FormErrors } from 'redux-form';
import { TFunction } from 'i18next';

import { HelperService } from '/services';
import { ISchedulerModalForm, TSchedulerModalValidate } from './models';

export const schedulerModalValidate = (values: ISchedulerModalForm, { t }: { t: TFunction }) => {
    const errors: FormErrors<TSchedulerModalValidate> = {};

    if (!values.email && !values.sendToOwnEmail) {
        errors.email = t('validation.mustBeEmail');
    }

    if (!values.schedule) {
        errors.schedule = t('validation.mustBeFilled');
    }

    if (!values.time) {
        errors.time = t('validation.mustBeFilled');
    }

    if (!values.sendToOwnEmail && values.email) {
        values.email.split(', ').forEach(email => {
            if (!HelperService.validateEmail(email)) {
                errors.email = t('validation.wrongEmailFormat');
            }
        });
    }

    return errors;
};
