export enum EWebQuestionType {
    QuestionScalable = 'QuestionScalable',
    QuestionConfigurable = 'QuestionConfigurable',
    QuestionOpen = 'QuestionOpen',
    QuestionDelimiter = 'QuestionDelimiter',
    QuestionEndConfigurable = 'QuestionEndConfigurable',
    QuestionRedirect = 'QuestionRedirect',
    QuestionTerminator = 'QuestionTerminator',
}

export enum ESmsQuestionType {
    QuestionScalableSMS = 'QuestionScalableSMS',
    QuestionOpenSMS = 'QuestionOpenSMS',
    QuestionYesNoSMS = 'QuestionYesNoSMS',
    QuestionFinishSMS = 'QuestionFinishSMS',
    QuestionTerminatorSMS = 'QuestionTerminatorSMS',
}

export enum EIvrQuestionType {
    QuestionScalableIVR = 'QuestionScalableIVR',
    QuestionConfigurableIVR = 'QuestionConfigurableIVR',
    QuestionDirectSpeechIVR = 'QuestionDirectSpeechIVR',
    QuestionNumberInputIVR = 'QuestionNumberInputIVR',
    QuestionEndSurveyIVR = 'QuestionEndSurveyIVR',
    QuestionTerminatorIVR = 'QuestionTerminatorIVR',
}

export enum EDigiQuestionType {
    QuestionScalableDIGI = 'QuestionScalableDIGI',
    QuestionOpenDIGI = 'QuestionOpenDIGI',
    QuestionFinishDIGI = 'QuestionFinishDIGI',
    QuestionConfigurableDIGI = 'QuestionConfigurableDIGI',
    QuestionTextMessageDIGI = 'QuestionTextMessageDIGI',
    QuestionUnsubscribeDIGI = 'QuestionUnsubscribeDIGI',
    MatrixQuestionScalableDIGI = 'MatrixQuestionScalableDIGI',
    MatrixQuestionConfigurableDIGI = 'MatrixQuestionConfigurableDIGI',
}
