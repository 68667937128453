import { CALL_API, FEEDBACK } from 'store/middleware/api';

export const EXPORT_SCHEDULES_REQUEST = 'EXPORT_SCHEDULES_REQUEST';
export const EXPORT_SCHEDULES_SUCCESS = 'EXPORT_SCHEDULES_SUCCESS';
export const EXPORT_SCHEDULES_ERROR = 'EXPORT_SCHEDULES_ERROR';

export const ADD_SCHEDULES_REQUEST = 'ADD_SCHEDULES_REQUEST';
export const ADD_SCHEDULES_SUCCESS = 'ADD_SCHEDULES_SUCCESS';
export const ADD_SCHEDULES_ERROR = 'ADD_SCHEDULES_ERROR';

export const UPDATE_SCHEDULES_REQUEST = 'UPDATE_SCHEDULES_REQUEST';
export const UPDATE_SCHEDULES_SUCCESS = 'UPDATE_SCHEDULES_SUCCESS';
export const UPDATE_SCHEDULES_ERROR = 'UPDATE_SCHEDULES_ERROR';

export const DELETE_SCHEDULES_REQUEST = 'DELETE_SCHEDULES_REQUEST';
export const DELETE_SCHEDULES_SUCCESS = 'DELETE_SCHEDULES_SUCCESS';
export const DELETE_SCHEDULES_ERROR = 'DELETE_SCHEDULES_ERROR';

export const CLEANUP_SCHEDULES_PAGE = 'CLEANUP_SCHEDULES_PAGE';

export const EXPORT_SCHEDULES_FILTERS_CHANGED = 'EXPORT_SCHEDULES_FILTERS_CHANGED';

function getInitialState() {
    return {
        loading: false,
        filters: {
            page: 1,
            limit: 10,
            sort_field: '',
            sort_order: '',
            search: '',
        },
        results: [],
        count: 0,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case EXPORT_SCHEDULES_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EXPORT_SCHEDULES_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.response,
            };

        case EXPORT_SCHEDULES_ERROR:
            return {
                ...state,
                loading: false,
            };

        case EXPORT_SCHEDULES_FILTERS_CHANGED:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };

        case CLEANUP_SCHEDULES_PAGE:
            return getInitialState();

        default:
            return state;
    }
}

export function getExportSchedules(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-schedule/expand-full/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ EXPORT_SCHEDULES_REQUEST, EXPORT_SCHEDULES_SUCCESS, EXPORT_SCHEDULES_ERROR ],
            body,
            apiType: FEEDBACK,
        },
    };
}

function addExportSchedules(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-schedule/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ ADD_SCHEDULES_REQUEST, ADD_SCHEDULES_SUCCESS, ADD_SCHEDULES_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export Schedules added',
                error: 'Something went wrong',
            },
        },
    };
}

function updateExportSchedules(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-schedule/${body.id}/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ UPDATE_SCHEDULES_REQUEST, UPDATE_SCHEDULES_SUCCESS, UPDATE_SCHEDULES_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export Schedules update',
                error: 'Something went wrong',
            },
        },
    };
}

export function deleteExportSchedules(id) {
    return {
        [CALL_API]: {
            endpoint: `/export-schedule/${id}/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_SCHEDULES_REQUEST, DELETE_SCHEDULES_SUCCESS, DELETE_SCHEDULES_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export Schedule item deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function manageExportSchedules(body) {
    return dispatch => {
        return body.id
            ? dispatch(updateExportSchedules(body))
            : dispatch(addExportSchedules(body));
    };
}

export function updateFilters(payload) {
    return {
        type: EXPORT_SCHEDULES_FILTERS_CHANGED,
        payload,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_SCHEDULES_PAGE,
    };
}
