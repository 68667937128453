import uuid from 'uuid/v1';

import { InsightNarratorService } from '/query/services';

export class StoreService {
    static getInitialChartsData({ state, initialState, isInsightNarrator, insightSocketId }) {
        let socketQueryId = null;
        let actionName = null;

        if(isInsightNarrator && insightSocketId) {
            socketQueryId = state.socketQueryId.filter( id => id !== insightSocketId);
            socketQueryId = socketQueryId.length ? socketQueryId : null;
            // actionName = socketQueryId?.length ? 'INSIGHT_NARRATOR' : null;
            actionName = 'INSIGHT_NARRATOR';
        } else if(isInsightNarrator) {
            actionName = 'INSIGHT_NARRATOR';
            socketQueryId = state.socketQueryId;
        }

        return {
            ...state,
            textsData: {
                ...initialState.textsData,
            },
            lemmataData: {
                ...initialState.lemmataData,
            },
            lemmataWordCloud: {
                ...initialState.lemmataWordCloud,
                filters: state.lemmataWordCloud.filters,
            },
            lemmataCorrelation: {
                ...initialState.lemmataCorrelation,
                filters: state.lemmataCorrelation.filters,
                correlationLimit: state.lemmataCorrelation.correlationLimit,
            },
            lemmataCoOccurrence: {
                ...initialState.lemmataCoOccurrence,
                filters: state.lemmataCoOccurrence.filters,
            },
            socketQueryId,
            actionName,
            needRunForm: false,
        };
    }

    static getStoreForChart({ action, state, initialState, type }) {
        //TODO: remove dirty hack for checking statusCode 202
        //getStateForSocket or state

        const isInsightNarrator = state.actionName === 'INSIGHT_NARRATOR';

        return action.response.id
            ? StoreService.getStateForSocket(state, action, isInsightNarrator)
            : {
                ...this.getInitialChartsData({ state, initialState, isInsightNarrator, insightSocketId: action.params?.insightSocketId }),
                ...StoreService.getInitialStateForChart(type, { state, action, initialState }),
                insightNarrator: {
                    ...InsightNarratorService.getInsightNarratorData({ state, action, type }),
                },
                needRunForm: false,
            };
    }

    static getInitialStateForChart(type, obj) {
        switch (type) {
            case 'texts': {
                return StoreService.texts(obj);
            }

            case 'lemmata': {
                return StoreService.lemmata(obj);
            }

            case 'wordCloud': {
                return StoreService.wordCloud(obj);
            }

            case 'coOccurrence': {
                return StoreService.coOccurrence(obj);
            }

            case 'correlation': {
                return StoreService.correlation(obj);
            }

            default: {
                return {
                    ...obj.state,
                };
            }
        }
    }

    static texts({ state, action }) {
        return {
            textsLoading: false,
            textsData: {
                ...state.textsData,
                ...action.response,
                texts: [
                    ...action.response.texts.map(text => ({
                        ...text,
                        id: uuid(),
                    })),
                ],
            },
        };
    }

    static lemmata({ state, action }) {
        return {
            lemmataLoading: false,
            lemmataData: {
                ...state.lemmataData,
                ...action.response,
            },
        };
    }

    static wordCloud({ state, action }) {
        return {
            lemmataWordCloudLoading: false,
            lemmataWordCloud: {
                ...state.lemmataWordCloud,
                ...action.response,
            },
        };
    }

    static coOccurrence({ state, action }) {
        return {
            lemmataCoOccurrenceLoading: false,
            lemmataCoOccurrence: {
                ...state.lemmataCoOccurrence,
                ...action.response,
            },
        };
    }

    static correlation({ state, action }) {
        return {
            lemmataCorrelationLoading: false,
            lemmataCorrelation: {
                ...state.lemmataCorrelation,
                ...action.response,
            },
        };
    }

    static getStateForSocket(state, { response }, isInsightNarrator) {
        let socketQueryId;

        if(isInsightNarrator && Array.isArray(state.socketQueryId)) {
            socketQueryId = [ ...state.socketQueryId, response.id ];
        } else if(isInsightNarrator) {
            socketQueryId = [ response.id ];
        } else {
            socketQueryId = response.id;
        }

        return {
            ...state,
            socketQueryId,
        };
    }
}
