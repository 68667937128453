import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';

const GET_UPLOAD_BY_ID_REQUEST = 'GET_UPLOAD_BY_ID_REQUEST';
const GET_UPLOAD_BY_ID_SUCCESS = 'GET_UPLOAD_BY_ID_SUCCESS';
const GET_UPLOAD_BY_ID_ERROR = 'GET_UPLOAD_BY_ID_ERROR';

const GET_HEADERS_REQUEST = 'GET_HEADERS_REQUEST';
const GET_HEADERS_SUCCESS = 'GET_HEADERS_SUCCESS';
const GET_HEADERS_ERROR = 'GET_HEADERS_ERROR';

const POST_UPLOAD_REQUEST = 'POST_SOURCE_REQUEST';
const POST_UPLOAD_SUCCESS = 'POST_SOURCE_SUCCESS';
const POST_UPLOAD_ERROR = 'POST_SOURCE_ERROR';

const POST_AFTER_RELOAD_REQUEST = 'POST_AFTER_RELOAD_REQUEST';
const POST_AFTER_RELOAD_SUCCESS = 'POST_AFTER_RELOAD_SUCCESS';
const POST_AFTER_RELOAD_ERROR = 'POST_AFTER_RELOAD_ERROR';

const SET_UPLOAD_NEW_HEADER_ROW_TYPE = 'SET_UPLOAD_NEW_HEADER_ROW_TYPE';
const CLEANUP_UPLOAD_NEW_PAGE = 'CLEANUP_UPLOAD_NEW_PAGE';
const redirect = '/store/uploads-list';

const getInitialState = () => ({
    loading: false,
    headers: [],
    afterReload: null,
    file: null,
    headerRowType: [],
    upload: {
        title: '',
        choiceDelimiter: '|',
        capturedAt: new Date(),
        language: { value: '', label: '' },
        csvDelimiter: ',',
    },
    error: null,
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case POST_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case POST_AFTER_RELOAD_REQUEST:
            return {
                ...state,
                loading: true,
                headers: [],
            };

        case GET_HEADERS_REQUEST:
            return {
                ...state,
                loading: true,
                headers: [],
                headerRowType: [],
                file: null,
            };

        case GET_UPLOAD_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                headers: [],
            };

        case GET_HEADERS_SUCCESS:
            return {
                ...state,
                loading: false,
                headers: action.response.headers,
                file: action.data.file,
            };

        case POST_UPLOAD_SUCCESS:
        case POST_AFTER_RELOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case GET_UPLOAD_BY_ID_SUCCESS:
            const { title, uuid } = action.response;

            return {
                ...state,
                loading: false,
                afterReload: action.response,
                upload: {
                    ...state.upload,
                    title,
                    uuid,
                },
            };

        case POST_UPLOAD_ERROR:
        case GET_HEADERS_ERROR:
        case GET_UPLOAD_BY_ID_ERROR:
        case POST_AFTER_RELOAD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case SET_UPLOAD_NEW_HEADER_ROW_TYPE:
            return {
                ...state,
                headerRowType: action.response.headerRowType,
            };

        case CLEANUP_UPLOAD_NEW_PAGE:
            return getInitialState();

        default:
            return state;
    }
}

export function setUploadData(formData) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: '/public_api/store/upload/file/upload',
            method: 'POST',
            contentType: 'multipart/form-data',
            types: [ POST_UPLOAD_REQUEST, POST_UPLOAD_SUCCESS, POST_UPLOAD_ERROR ],
            body: formData,
            logger: true,
            loggerMessages: {
                success: t('uploadInitialized'),
                error: t('messages.thereWasServerErrorIn', { action: t('upload') }),
            },
        },
    };
}

export function setAfterReloadData(formData) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: '/public_api/store/upload/file/upload_afterloading',
            method: 'POST',
            contentType: 'multipart/form-data',
            types: [ POST_AFTER_RELOAD_REQUEST, POST_AFTER_RELOAD_SUCCESS, POST_AFTER_RELOAD_ERROR ],
            body: formData,
            logger: true,
            loggerMessages: {
                success: t('aftereloadInitialized'),
                error: t('messages.thereWasServerErrorIn', { action: t('afterreload') }),
            },
        },
    };
}

export function getHeaders(file, csvDelimiter) {
    return {
        [CALL_API]: {
            endpoint: '/store/upload/getHeaders',
            method: 'POST',
            contentType: 'multipart/form-data',
            types: [ GET_HEADERS_REQUEST, GET_HEADERS_SUCCESS, GET_HEADERS_ERROR ],
            body: {
                file,
                csvDelimiter,
            },
            logger: true,
        },
    };
}

export function getUploadById(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/upload/${id}`,
            method: 'GET',
            types: [ GET_UPLOAD_BY_ID_REQUEST, GET_UPLOAD_BY_ID_SUCCESS, GET_UPLOAD_BY_ID_ERROR ],
            body: {},
            logger: true,
            redirect,
        },
    };
}

export function setHeaderRowType(headerRowType) {
    return {
        type: SET_UPLOAD_NEW_HEADER_ROW_TYPE,
        response: {
            headerRowType,
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP_UPLOAD_NEW_PAGE,
    };
}
