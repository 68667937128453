import React, { useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import SplitPane, { Pane } from 'split-pane-react';
import { isMobile } from 'react-device-detect';

import {
    GadgetService, PopoverRangeSlider,
    useBarChart, useDrillDown, useHover,
} from '/visual/scenes/Dashboard/components';
import {
    sendChartColor,
    sendLabel,
    setContentSettings,
    setTickOrderContentSettings,
} from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import {
    ChartLabelsSortableList,
    ChartColorPicker,
    FooterInfo,
} from '/visual/scenes/Dashboard/components/Gadget/components';
import { PopoverChartTooltip, SplitPaneSash } from '/components';
import { useSplitPane } from '/services';
import { BarChartD3, StackedBarChartD3 } from './components';
import { LabelModal } from '../LabelModal';
import {
    IChartData, IColorPickerData,
    changeColorHandlerType, TLabelPopoverData,
    TApplyLabelModalData, ELabelType,
} from '../../models';

import commonStyles from '../BaseChart/style.module.scss';

export const BarChart = ({ chartData: initChartData }: { chartData: IChartData, }) => {
    const dispatch = useDispatch();

    const {
        gadgetData,
        groups,
        filterLabels,
        allGroups,
        records,
        sliderData,
        axisLabels,
        allowSort,
        isNpsChart,
        npsData,
        changeLabel,
        changeBarsSort,
        changeItemColor,
        changeSlider,
    } = useBarChart(initChartData);

    const {
        sizes,
        onChange,
        onDragEnd,
    } = useSplitPane({
        initSizes: gadgetData.contentSettings?.labelsSize,
        sizeChanged: newSizes => dispatch(setContentSettings(gadgetData.id, {
            ...gadgetData.contentSettings,
            labelsSize: newSizes,
        })),
    });

    const tooltipRef = createRef<HTMLDivElement>();
    const { getDrillDownDashboard } = useDrillDown({ gadgetData });
    const { isHovered, hoverRef, mouseOver, mouseOut } = useHover<HTMLDivElement>(sliderData.disableSlider);

    const [ highlightedLabel, setHighlightedLabel ] = useState<string | null>(null);
    const [ colorPickerData, setColorPickerData ] = useState<IColorPickerData | null>(null);
    const [ tickData, setTickData ] = useState<TLabelPopoverData | null>(null);

    const resetTickData = () => setTickData(null);

    const changeColorHandler = ({ elementId = '', colorHex }: changeColorHandlerType) => {
        changeItemColor(
            elementId,
            colorHex,
            (colorData, isChanged) => {
                dispatch(sendChartColor(gadgetData.id, colorData, isChanged));
            },
        );
    };

    const onApplyLabelModal = (labelData: TApplyLabelModalData) => {
        changeLabel(
            labelData.id,
            labelData.type,
            labelData.value,
            (labelRes, isChanged) => {
                dispatch(sendLabel(gadgetData.id, labelRes, isChanged));
            });
    };

    const changeBarsSortHandler = (direction: string) => {
        changeBarsSort(direction, data => {
            dispatch(setContentSettings(gadgetData.id, data));
        });
    };

    const changeLabelOrderHandler = (order: string[]) => {
        dispatch(setContentSettings(gadgetData.id, {
            ...initChartData.gadgetData.contentSettings,
            order,
        }));
    };

    const saveTickOrder = (tickOrder: string[]) => {
        dispatch(setTickOrderContentSettings(gadgetData.id, tickOrder));
    };

    const getChartByType = () => {
        const { annotateScore, relativeMode, decimalDigits, chartType } = gadgetData.contentSettings;

        const commonProps = {
            decimalDigits,
            dataSet: groups,
            allGroups: allGroups,
            showScore: annotateScore,
            axisLabels: axisLabels,
            highlightedLabel: highlightedLabel,
            gadgetId: initChartData.gadgetData.id,
            gadgetFunction: initChartData.gadgetData.contentSettings.function,
            drillDownEnabled: initChartData.gadgetData.contentSettings.drillDown,
            chartType: chartType,
            toolTipRef: tooltipRef,
            setChangeTickModal: setTickData,
            resetTickData: resetTickData,
            onApplyLabelModal: onApplyLabelModal,
            saveTickOrder: saveTickOrder,
            setColorPickerData: setColorPickerData,
            drillDownFromSelection: getDrillDownDashboard,
            order: initChartData.gadgetData.contentSettings.order,
        };

        switch (chartType) {
            case 'surveys_bar_chart':
                return <BarChartD3
                    percentage={ relativeMode }
                    { ...commonProps }
                />;
            case 'stacked_bar_chart':
                return <StackedBarChartD3
                    isNpsChart={ isNpsChart }
                    npsData={ npsData }
                    percentage={ true }
                    { ...commonProps }
                />;

            default:
                return null;
        }
    };

    const getSortButton = (sortDirection: string) => {
        if (!allowSort) return;

        const iconName = sortDirection === 'asc'
            ? 'sort-amount-up'
            : 'sort-amount-down-alt';

        return allowSort
            ? <button onClick={ () => changeBarsSortHandler(sortDirection) }>
                <FontAwesomeIcon icon={ iconName } className='fa-rotate-270' color='#979797' />
            </button>
            : null;
    };

    const tooltipValueFormatter = (index: number) =>
        GadgetService.rangeTooltipValueFormatter({ index, list: allGroups });

    const onApplyLabelModalDataHandler = (inputValue: TLabelPopoverData['value']) => {
        if (tickData) {
            onApplyLabelModal({
                id: tickData.id,
                value: inputValue,
                type: tickData.type,
            });
        }
    };

    return (
        <div
            className={ cx(commonStyles.chartWrapper) }
            ref={ hoverRef }
            onMouseOver={ mouseOver }
            onMouseLeave={ mouseOut }
        >
            <SplitPane
                allowResize={ !isMobile }
                performanceMode
                resizerSize={ 1 }
                split='horizontal'
                sizes={ sizes }
                onChange={ onChange }
                onDragEnd={ onDragEnd }
                sashRender={ () => <SplitPaneSash split='horizontal'/> }
            >
                <Pane className={ commonStyles.graphSplitPanePadding } >
                    { getChartByType() }
                </Pane>
                <Pane minSize='20%' maxSize='50%' className={ commonStyles.labelsSplitPanePadding }>
                    <div className={ commonStyles.labelsContainer }>
                        { getSortButton('asc') }
                        <ChartLabelsSortableList
                            dragDisable={ isMobile }
                            labels={ filterLabels }
                            longLabel={ gadgetData.contentSettings.longLabel }
                            gadgetId={ gadgetData.id }
                            chartType={ gadgetData.contentSettings.chartType }
                            updateSelectedLabel={ setHighlightedLabel }
                            setColorPickerData={ setColorPickerData }
                            onApplyLabelModal={ onApplyLabelModal }
                            onSortEnd={ changeLabelOrderHandler }
                            tickData={ tickData }
                        />
                        { getSortButton('desc') }
                    </div>
                </Pane>
            </SplitPane>

            <FooterInfo records={ records } lastHealthCheckTime={ gadgetData.lastHealthCheckTime } />

            <ChartColorPicker
                chartType={ gadgetData.contentSettings.chartType }
                gadgetId={ gadgetData.id }
                colorPickerData={ colorPickerData }
                setColorPickerData={ setColorPickerData }
                changeColorCallback={ changeColorHandler }
            />

            <PopoverRangeSlider
                hovered={ isHovered }
                sliderData={ sliderData }
                chartContainerRef={ hoverRef }
                onChangeSlider={ changeSlider }
                tooltipValueFormatter={ tooltipValueFormatter }
            />

            <PopoverChartTooltip ref={ tooltipRef } />

            <LabelModal
                labelModalData={ tickData }
                maxLength={ 250 }
                labelType={ ELabelType.TICK }
                setLabelModalData={ setTickData }
                onApplyLabelModalData={ onApplyLabelModalDataHandler }
            />
        </div>
    );
};
