import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { VocTableV2 } from '/components';
import PropTypes from 'prop-types';
import { QuarantinesActionsFormatter, QuarantinesNameFormatter, QuarantinesCustomToolBar } from '../';
import { QuarantinesDaysFormatter, QuarantinesConditionFormatter } from '/feedback/components';
import { transformQuarantineConditionsToReduxForm } from '/feedback/modules/VocFeedback.selectors';

export class QuarantinesSettingsTable extends Component {
    settings = [
        {
            field: 'blocked_survey__name',
            title: 'Affected Survey',
            formatter: this.quarantineNameFormatter,
            sort: false,
            style: {
                width: '270px',
            },
        },
        {
            field: 'days',
            title: 'Action type',
            formatter: this.quarantineDaysFormatter,
            sort: false,
            style: {
                width: '240px',
            },
        },
        {
            field: 'condition',
            title: 'Condition',
            formatter: this.quarantineConditionFormatter,
            sort: false,
        },
        {
            field: 'link',
            title: 'Actions',
            formatter: this.tableActionsFormatter,
            style: {
                width: '110px',
                textAlign: 'center',
            },
        },
    ];

    createToolBar() {
        const {
            selectOptions,
            updateFilters,
            filters,
            requestTrigger,
            flowOptions,
            tableState: { allSurveyLoading },
        } = this.props;

        return (
            <QuarantinesCustomToolBar
                selectOptions={ selectOptions }
                updateFilters={ updateFilters }
                requestTrigger={ requestTrigger }
                filters={ filters }
                allSurveyLoading={ allSurveyLoading }
                flowOptions={ flowOptions }
            />
        );
    }

    @autobind
    quarantineNameFormatter(cell, obj) {
        return (
            <QuarantinesNameFormatter
                quarantine={ obj }
            />
        );
    }

    @autobind
    quarantineDaysFormatter(cell, obj) {
        return (
            <QuarantinesDaysFormatter
                quarantine={ obj }
            />
        );
    }

    @autobind
    quarantineConditionFormatter(cell, obj) {
        const { flowOptions, formValue, setQuarantineDataById } = this.props;

        return (
            <QuarantinesConditionFormatter
                flowOptions={ flowOptions }
                formValue={ formValue }
                setQuarantineDataById={ setQuarantineDataById }
                id={ obj.id }
                conditions={ transformQuarantineConditionsToReduxForm(obj.conditions) }
                logical_operation={ obj.logical_operation }
            />
        );
    }

    @autobind
    tableActionsFormatter(cell, obj) {
        const {
            flowOptions,
            openModalEdit,
            openModalDelete,
            formValue,
            setQuarantineDataById,
        } = this.props;

        return (
            <QuarantinesActionsFormatter
                quarantine={ obj }
                flowOptions={ flowOptions }
                openModalEdit={ openModalEdit }
                openModalDelete={ openModalDelete }
                formValue={ formValue }
                setQuarantineDataById={ setQuarantineDataById }
            />
        );
    }

    @autobind
    getTableOptions() {
        return {
            customTableHeader: this.createToolBar(),
            requestTrigger: this.props.requestTrigger,
            updateFilters: this.props.updateFilters,
        };
    }

    render() {
        const {
            tableState: {
                loading,
                count,
            },
            data,
            filters,
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                options={ this.getTableOptions() }
                data={ data }
                filters={ filters }
                total={ count }
                loading={ loading }
            />
        );
    }
}

QuarantinesSettingsTable.propTypes = {
    requestTrigger: PropTypes.func,
    updateFilters: PropTypes.func,
    data: PropTypes.array,
    tableState: PropTypes.shape({
        dataTotalSize: PropTypes.number,
        page: PropTypes.number,
        sizePerPage: PropTypes.number,
        loading: PropTypes.bool,
    }),
};
