import { CALL_API } from 'store/middleware/api';
import { StoreService, InsightNarratorService } from '../services';

const redirect = '/mine/query/list-queries';

export const GET_ALL_QUERY_REQUEST = 'GET_ALL_QUERY_REQUEST';
export const GET_ALL_QUERY_SUCCESS = 'GET_ALL_QUERY_SUCCESS';
export const GET_ALL_QUERY_ERROR = 'GET_ALL_QUERY_ERROR';

export const GET_QUERY_BY_ID_REQUEST = 'GET_QUERY_BY_ID_REQUEST';
export const GET_QUERY_BY_ID_SUCCESS = 'GET_QUERY_BY_ID_SUCCESS';
export const GET_QUERY_BY_ID_ERROR = 'GET_QUERY_BY_ID_ERROR';

export const SAVE_QUERY_BY_ID_REQUEST = 'SAVE_QUERY_BY_ID_REQUEST';
export const SAVE_QUERY_BY_ID_SUCCESS = 'SAVE_QUERY_BY_ID_SUCCESS';
export const SAVE_QUERY_BY_ID_ERROR = 'SAVE_QUERY_BY_ID_ERROR';

export const GET_CLASSIFICATION_REQUEST = 'GET_CLASSIFICATION_REQUEST';
export const GET_CLASSIFICATION_SUCCESS = 'GET_CLASSIFICATION_SUCCESS';
export const GET_CLASSIFICATION_ERROR = 'GET_CLASSIFICATION_ERROR';

export const GET_LEMMATA_REQUEST = 'GET_LEMMATA_REQUEST';
export const GET_LEMMATA_SUCCESS = 'GET_LEMMATA_SUCCESS';
export const GET_LEMMATA_ERROR = 'GET_LEMMATA_ERROR';

export const GET_LEMMATA_CLOUD_REQUEST = 'GET_LEMMATA_CLOUD_REQUEST';
export const GET_LEMMATA_CLOUD_SUCCESS = 'GET_LEMMATA_CLOUD_SUCCESS';
export const GET_LEMMATA_CLOUD_ERROR = 'GET_LEMMATA_CLOUD_ERROR';

export const GET_COOCCURRENCE_REQUEST = 'GET_COOCCURRENCE_REQUEST';
export const GET_COOCCURRENCE_SUCCESS = 'GET_COOCCURRENCE_SUCCESS';
export const GET_COOCCURRENCE_ERROR = 'GET_COOCCURRENCE_ERROR';

export const GET_CORRELATION_REQUEST = 'GET_CORRELATION_REQUEST';
export const GET_CORRELATION_SUCCESS = 'GET_CORRELATION_SUCCESS';
export const GET_CORRELATION_ERROR = 'GET_CORRELATION_ERROR';

export const GET_TEXTS_REQUEST = 'GET_TEXTS_REQUEST';
export const GET_TEXTS_SUCCESS = 'GET_TEXTS_SUCCESS';
export const GET_TEXTS_ERROR = 'GET_TEXTS_ERROR';

export const GET_TEXTS_INFO_REQUEST = 'GET_TEXTS_INFO_REQUEST';
export const GET_TEXTS_INFO_SUCCESS = 'GET_TEXTS_INFO_SUCCESS';
export const GET_TEXTS_INFO_ERROR = 'GET_TEXTS_INFO_ERROR';

export const GET_TEXTS_BY_FILTERS_REQUEST = 'GET_TEXTS_BY_FILTERS_REQUEST';
export const GET_TEXTS_BY_FILTERS_SUCCESS = 'GET_TEXTS_BY_FILTERS_SUCCESS';
export const GET_TEXTS_BY_FILTERS_ERROR = 'GET_TEXTS_BY_FILTERS_ERROR';

export const CREATE_NEW_QUERY_REQUEST = 'CREATE_NEW_QUERY_REQUEST';
export const CREATE_NEW_QUERY_SUCCESS = 'CREATE_NEW_QUERY_SUCCESS';
export const CREATE_NEW_QUERY_ERROR = 'CREATE_NEW_QUERY_ERROR';

export const GET_CLASSIFIER_LIST_REQUEST = 'GET_CLASSIFIER_LIST_REQUEST';
export const GET_CLASSIFIER_LIST_SUCCESS = 'GET_CLASSIFIER_LIST_SUCCESS';
export const GET_CLASSIFIER_LIST_ERROR = 'GET_CLASSIFIER_LIST_ERROR';

export const GET_QUERY_DICTIONARIES_REQUEST = 'GET_QUERY_DICTIONARIES_REQUEST';
export const GET_QUERY_DICTIONARIES_SUCCESS = 'GET_QUERY_DICTIONARIES_SUCCESS';
export const GET_QUERY_DICTIONARIES_ERROR = 'GET_QUERY_DICTIONARIES_ERROR';

export const EXPORT_REQUEST = 'EXPORT_REQUEST';
export const EXPORT_SUCCESS = 'EXPORT_SUCCESS';
export const EXPORT_ERROR = 'EXPORT_ERROR';

export const CHANGE_DIRTY_FILTERS = 'CHANGE_DIRTY_FILTERS';

export const PAGINATION_FILTERS_CHANGED = 'PAGINATION_FILTERS_CHANGED';

export const CORRELATION_LIMIT_CHANGED = 'CORRELATION_LIMIT_CHANGED';

export const QUERY_INFO_UPDATE_SHARES = 'QUERY_INFO_UPDATE_SHARES';

export const SET_SELECTED_DICTIONARIES = 'SET_SELECTED_DICTIONARIES';

const CHANGE_QUERY_CONTEXT_REQUEST = 'CHANGE_QUERY_CONTEXT_REQUEST';
const CHANGE_QUERY_CONTEXT_SUCCESS = 'CHANGE_QUERY_CONTEXT_SUCCESS';
const CHANGE_QUERY_CONTEXT_ERROR = 'CHANGE_QUERY_CONTEXT_ERROR';

const SET_ZERO_SHOT_EXPORT_DATA = 'SET_ZERO_SHOT_EXPORT_DATA';

export const CHANGE_LANG_SET_DATA = 'CHANGE_LANG_SET_DATA';
export const CHANGE_ACTION_NAME = 'CHANGE_ACTION_NAME';
const STOP_CHARTS_LOADING = 'STOP_CHARTS_LOADING';

export const SET_QUERY_ID = 'SET_QUERY_ID';

const MINE_SOCKED_ERROR = 'MINE_SOCKED_ERROR';

export const CLEANUP_QUERY_EXECUTE = 'CLEANUP_QUERY_EXECUTE';
const CLEANUP_QUERY_GRAPHS = 'CLEANUP_QUERY_GRAPHS';

function changeFilterExtraGraph(extraNameForFilter, state, limit) {
    return extraNameForFilter
        ? {
            [extraNameForFilter]: {
                ...state[extraNameForFilter],
                filters: {
                    ...state[extraNameForFilter].filters,
                    limit: limit,
                },
            },
        }
        : {};
}

const requestMethods = {
    GET_TEXTS_REQUEST: 'textsData',
    GET_LEMMATA_REQUEST: 'lemmataData',
    GET_LEMMATA_CLOUD_REQUEST: 'lemmataWordCloud',
    GET_CORRELATION_REQUEST: 'lemmataCorrelation',
    GET_COOCCURRENCE_REQUEST: 'lemmataCoOccurrence',
    //InsightNarrator
    getCorrelation: 'lemmataCorrelation',
    getCooccurrence: 'lemmataCoOccurrence',
    getLemmata: 'lemmataData',
    getTexts: 'textsData',
};

function getInitialState() {
    return {
        queryId: null,
        exportSending: false,
        queryInfoLoading: false,
        isSavingQuery: false,
        queryInfo: {
            filters: {},
        },
        queriesLoading: false,
        queries: [],
        classificationLoading: false,
        classification: [],
        filters: {},
        dirtyFilters: {},
        dictionaries: {
            loading: false,
            results: [],
        },
        selectedDictionaries: {
            synonyms: [],
            stopWords: [],
        },
        textsLoading: false,
        textsData: {
            texts: [],
            textsCount: 0,
            allTextsCount: 0,
            error: '',
            filters: {
                page: 1,
                limit: 10,
            },
        },
        textInfoLoading: false,
        textInfo: {
            text: '',
            lemmas: '',
            attributes: [],
            classification: [],
            captured_at: '',
        },
        lemmataLoading: false,
        lemmataData: {
            lemmata: [],
            allTextsCount: 0,
            lemmataCount: 0,
            textsCount: 0,
            error: '',
            filters: {
                page: 1,
                limit: 10,
            },
        },
        lemmataWordCloudLoading: false,
        lemmataWordCloud: {
            lemmata: [],
            allTextsCount: 0,
            textsCount: 0,
            error: '',
            filters: {
                page: 1,
                limit: 100,
            },
        },
        lemmataCoOccurrenceLoading: false,
        lemmataCoOccurrence: {
            cooccurrence: {
                nodesData: [],
                linksData: [],
            },
            allTextsCount: 0,
            textsCount: 0,
            error: '',
            filters: {
                page: 1,
                limit: 1,
            },
        },
        lemmataCorrelationLoading: false,
        lemmataCorrelation: {
            correlation: {
                nodesData: [],
                linksData: [],
            },
            allTextsCount: 0,
            textsCount: 0,
            correlationLimit: 0.01,
            error: '',
            filters: {
                page: 1,
                limit: 1,
            },
        },
        classifierList: {
            loading: false,
            data: [],
        },
        socketQueryId: null,
        actionName: null,
        isDirtyForm: false,
        needRunForm: false,
        zeroShotExportData: {
            labels: [],
            threshold: 0,
        },
        insightNarrator: {
            ...InsightNarratorService.getInsightInitialStore(),
        },
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case SET_QUERY_ID: {
            return {
                ...state,
                queryId: action.payload,
            };
        }

        case SET_ZERO_SHOT_EXPORT_DATA: {
            return {
                ...state,
                zeroShotExportData: action.payload,
            };
        }

        case GET_ALL_QUERY_REQUEST: {
            return {
                ...state,
                queriesLoading: true,
                queries: [],
            };
        }

        case GET_ALL_QUERY_SUCCESS: {
            return {
                ...state,
                queriesLoading: false,
                queries: [ ...action.response ],
                isDirtyForm: false,
                needRunForm: false,
            };
        }

        case GET_ALL_QUERY_ERROR: {
            return {
                ...state,
                queriesLoading: false,
            };
        }

        case CHANGE_QUERY_CONTEXT_REQUEST:
        case SAVE_QUERY_BY_ID_REQUEST: {
            return {
                ...state,
                queryInfo: {
                    ...state.queryInfo,
                    context: action.body.context,
                },
                isSavingQuery: true,
            };
        }

        case CHANGE_QUERY_CONTEXT_SUCCESS: {
            return {
                ...state,
                isSavingQuery: false,
                queryInfo: action.response,
            };
        }

        case CHANGE_QUERY_CONTEXT_ERROR:
        case SAVE_QUERY_BY_ID_ERROR: {
            return {
                ...state,
                isSavingQuery: false,
            };
        }

        case GET_QUERY_BY_ID_REQUEST: {
            return {
                ...state,
                queryInfoLoading: true,
                queryInfo: {
                    filters: {},
                },
            };
        }

        case SAVE_QUERY_BY_ID_SUCCESS:
        case GET_QUERY_BY_ID_SUCCESS: {
            const { synonyms, stopWords, filters } = action.response;

            return {
                ...state,
                queryInfoLoading: false,
                isSavingQuery: false,
                queryInfo: { ...action.response },
                filters,
                dirtyFilters: { ...filters },
                selectedDictionaries: {
                    synonyms,
                    stopWords,
                },
                isDirtyForm: false,
                needRunForm: false,
            };
        }

        case GET_QUERY_BY_ID_ERROR: {
            return {
                ...state,
                queryInfoLoading: false,
            };
        }

        case QUERY_INFO_UPDATE_SHARES: {
            return {
                ...state,
                queryInfo: {
                    ...state.queryInfo,
                    shares: [
                        ...action.payload,
                    ],
                },
            };
        }

        case GET_QUERY_DICTIONARIES_REQUEST: {
            return {
                ...state,
                dictionaries: {
                    ...state.dictionaries,
                    loading: true,
                },
            };
        }

        case GET_QUERY_DICTIONARIES_SUCCESS: {
            return {
                ...state,
                dictionaries: {
                    ...state.dictionaries,
                    results: action.response.data,
                    loading: false,
                },
            };
        }

        case GET_QUERY_DICTIONARIES_ERROR: {
            return {
                ...state,
                dictionaries: {
                    ...state.dictionaries,
                    loading: false,
                },
            };
        }

        case MINE_SOCKED_ERROR: {
            const { actionName, error } = action.payload;
            const reduxSliceName = requestMethods[actionName];
            const reduxSliceNameLoading = `${requestMethods[actionName].replace('Data', '')}Loading`;

            return {
                ...state,
                [reduxSliceNameLoading]: false,
                [reduxSliceName]: {
                    ...getInitialState()[reduxSliceName],
                    error,
                },
            };
        }

        case GET_CLASSIFICATION_REQUEST:
            return {
                ...state,
                classificationLoading: true,
            };

        case GET_CLASSIFICATION_SUCCESS:
            return {
                ...state,
                classificationLoading: false,
                classification: [
                    ...action.response.attributes.filter(attr => attr.originType === 'CLASSIFICATION'),
                ],
                queryInfo: {
                    ...state.queryInfo,
                    source: {
                        ...state.queryInfo.source,
                        attributes: [
                            ...action.response.attributes,
                        ],
                    },
                },
            };

        case GET_CLASSIFICATION_ERROR:
            return {
                ...state,
                classificationLoading: false,
            };

        case GET_TEXTS_REQUEST:
            return {
                ...state,
                textsLoading: true,
                ...!action.params?.insightSocketId
                    ? { actionName: action.type }
                    : {},
                insightNarrator: InsightNarratorService.updateOptionsOnRequest(state, action, 'texts'),
            };

        case GET_TEXTS_BY_FILTERS_REQUEST:
            return {
                ...state,
                textsLoading: true,
                textsData: {
                    ...getInitialState().textsData,
                },
            };

        case GET_TEXTS_BY_FILTERS_SUCCESS:
        case GET_TEXTS_SUCCESS:
            return {
                ...StoreService.getStoreForChart(
                    { action, state, initialState: getInitialState(), type: 'texts' },
                ),
            };

        case GET_TEXTS_ERROR:
        case GET_TEXTS_BY_FILTERS_ERROR:
            return {
                ...state,
                textsLoading: false,
            };

        case GET_TEXTS_INFO_REQUEST:
            return {
                ...state,
                textInfoLoading: true,
            };

        case GET_TEXTS_INFO_SUCCESS:
            return {
                ...state,
                textInfoLoading: false,
                textInfo: {
                    ...state.textInfo,
                    ...action.response,
                },
            };

        case GET_TEXTS_INFO_ERROR:
            return {
                ...state,
                textInfoLoading: false,
                textInfo: {
                    ...getInitialState().textInfo,
                },
            };

        case GET_LEMMATA_REQUEST:
            return {
                ...state,
                lemmataLoading: true,
                ...!action.params?.insightSocketId
                    ? { actionName: action.type }
                    : {},
                insightNarrator: InsightNarratorService.updateOptionsOnRequest(state, action, 'lemmata'),
            };

        case GET_LEMMATA_SUCCESS:
            return {
                ...StoreService.getStoreForChart(
                    { action, state, initialState: getInitialState(), type: 'lemmata' },
                ),
            };

        case GET_LEMMATA_ERROR:
            return {
                ...state,
                lemmataLoading: false,
            };

        case GET_LEMMATA_CLOUD_REQUEST:
            return {
                ...state,
                lemmataWordCloudLoading: true,
                actionName: action.type,
            };

        case GET_LEMMATA_CLOUD_SUCCESS:
            return {
                ...StoreService.getStoreForChart(
                    { action, state, initialState: getInitialState(), type: 'wordCloud' },
                ),
            };

        case GET_LEMMATA_CLOUD_ERROR:
            return {
                ...state,
                lemmataWordCloudLoading: false,
            };

        case GET_COOCCURRENCE_REQUEST:
            return {
                ...state,
                lemmataCoOccurrenceLoading: true,
                ...!action.params?.insightSocketId
                    ? { actionName: action.type }
                    : {},
                insightNarrator: InsightNarratorService.updateOptionsOnRequest(state, action, 'coOccurrence'),
            };

        case GET_COOCCURRENCE_SUCCESS:
            return {
                ...StoreService.getStoreForChart(
                    { action, state, initialState: getInitialState(), type: 'coOccurrence' },
                ),
            };

        case GET_COOCCURRENCE_ERROR:
            return {
                ...state,
                lemmataCoOccurrenceLoading: false,
            };

        case GET_CORRELATION_REQUEST:
            return {
                ...state,
                lemmataCorrelationLoading: true,
                ...!action.params?.insightSocketId
                    ? { actionName: action.type }
                    : {},
                insightNarrator: InsightNarratorService.updateOptionsOnRequest(state, action, 'correlation'),
            };

        case GET_CORRELATION_SUCCESS:
            return {
                ...StoreService.getStoreForChart(
                    { action, state, initialState: getInitialState(), type: 'correlation' },
                ),
            };

        case GET_CORRELATION_ERROR:
            return {
                ...state,
                lemmataCorrelationLoading: false,
            };

        case PAGINATION_FILTERS_CHANGED:
            const { name, page, limit , extraNameForFilter } = action.payload;

            const newFilters = { ...state[name].filters };

            page && (newFilters['page'] = page);
            (limit || limit === 0) && (newFilters['limit'] = limit);

            return {
                ...state,
                [name]: {
                    ...state[name],
                    filters: {
                        ...newFilters,
                    },
                },
                ...changeFilterExtraGraph(extraNameForFilter, state, newFilters.limit),
            };

        case CORRELATION_LIMIT_CHANGED:
            return {
                ...state,
                lemmataCorrelation: {
                    ...state.lemmataCorrelation,
                    correlationLimit: action.payload,
                },
            };

        case CHANGE_DIRTY_FILTERS: {
            return {
                ...state,
                dirtyFilters: { ...action.payload },
                isDirtyForm: true,
                needRunForm: true,
            };
        }

        case CHANGE_LANG_SET_DATA: {
            const { synonyms, stopWords, filters, language } = action.payload;

            return {
                ...state,
                queryInfoLoading: false,
                isSavingQuery: false,
                queryInfo: {
                    ...state.queryInfo,
                    language,
                },
                dirtyFilters: { ...filters },
                selectedDictionaries: {
                    synonyms,
                    stopWords,
                },
                isDirtyForm: true,
                needRunForm: true,
            };
        }

        case SET_SELECTED_DICTIONARIES:
            return {
                ...state,
                selectedDictionaries: action.payload,
                isDirtyForm: true,
                needRunForm: true,
            };

        case GET_CLASSIFIER_LIST_REQUEST: {
            return {
                ...state,
                classifierList: {
                    ...state.classifierList,
                    loading: true,
                },
            };
        }

        case GET_CLASSIFIER_LIST_SUCCESS: {
            return {
                ...state,
                classifierList: {
                    loading: false,
                    data: [
                        ...action.response,
                    ],
                },
            };
        }

        case GET_CLASSIFIER_LIST_ERROR: {
            return {
                ...state,
                classifierList: {
                    ...state.classifierList,
                    loading: false,
                },
            };
        }

        case EXPORT_REQUEST: {
            return {
                ...state,
                exportSending: true,
            };
        }

        case EXPORT_SUCCESS: {
            return {
                ...state,
                exportSending: false,
            };
        }

        case EXPORT_ERROR: {
            return {
                ...state,
                exportSending: false,
            };
        }

        case CLEANUP_QUERY_GRAPHS:
            return {
                ...StoreService.getInitialChartsData({ state, initialState: getInitialState() }),
            };

        case CHANGE_ACTION_NAME: {
            return {
                ...state,
                actionName: 'INSIGHT_NARRATOR',
            };
        }

        case STOP_CHARTS_LOADING: {
            return {
                ...state,
                textsLoading: false,
                lemmataLoading: false,
                lemmataWordCloudLoading: false,
                lemmataCoOccurrenceLoading: false,
                lemmataCorrelationLoading: false,
                socketQueryId: null,
                actionName: null,
            };
        }

        case CLEANUP_QUERY_EXECUTE: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function cleanup() {
    return {
        type: CLEANUP_QUERY_EXECUTE,
    };
}

export function cleanupQueryGraphs() {
    return {
        type: CLEANUP_QUERY_GRAPHS,
    };
}

export function getAllQueriesList() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/`,
            method: 'POST',
            contentType: 'application/json',
            unique: true,
            types: [ GET_ALL_QUERY_REQUEST, GET_ALL_QUERY_SUCCESS, GET_ALL_QUERY_ERROR ],
            redirect,
        },
    };
}

export function getQueryById(id) {
    return dispatch => {
        dispatch(setQueryIdAction(id));
        const request = dispatch(getQueryByIdAction(id));

        return request.promise;
    };
}

export function getQueryDictionaries() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/dictionary/list`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_QUERY_DICTIONARIES_REQUEST, GET_QUERY_DICTIONARIES_SUCCESS, GET_QUERY_DICTIONARIES_ERROR ],
            logger: true,
            unique: true,
            body: {
                limit: 9999,
            },
        },
    };
}

function setQueryIdAction(payload) {
    return {
        type: SET_QUERY_ID,
        payload,
    };
}

function getQueryByIdAction(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_QUERY_BY_ID_REQUEST, GET_QUERY_BY_ID_SUCCESS, GET_QUERY_BY_ID_ERROR ],
            unique: true,
            redirect,
        },
    };
}

export function changeDirtyFilters(payload) {
    return {
        type: CHANGE_DIRTY_FILTERS,
        payload,
    };
}

export function changeLangSetData(payload) {
    return {
        type: CHANGE_LANG_SET_DATA,
        payload,
    };
}

export function setSelectedDictionaries(payload) {
    return {
        type: SET_SELECTED_DICTIONARIES,
        payload,
    };
}

export function saveQueryById(body, id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ SAVE_QUERY_BY_ID_REQUEST, SAVE_QUERY_BY_ID_SUCCESS, SAVE_QUERY_BY_ID_ERROR ],
            redirect,
            body,
        },
    };
}

export function changeQueryContext(context) {
    return (dispatch, getState) => {
        const { id, title, tag, source, language, columnIds, useInVisual, user } = getState().VocMine.query.queryInfo;
        const requestBody = {
            title: title,
            tag,
            context,
            sourceId: source.id,
            language,
            columnIds,
            user: user.ssoId,
            useInVisual,
        };

        dispatch(changeQueryContextRequest({ id, body: requestBody }));
    };
}

export function changeQueryContextRequest({ id, body }) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }/edit`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ CHANGE_QUERY_CONTEXT_REQUEST, CHANGE_QUERY_CONTEXT_SUCCESS, CHANGE_QUERY_CONTEXT_ERROR ],
            logger: true,
            unique: true,
            loggerMessages: {
                success: 'Query context changed',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function createQuery(body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/create`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_NEW_QUERY_REQUEST, CREATE_NEW_QUERY_SUCCESS, CREATE_NEW_QUERY_ERROR ],
            redirect,
            body,
            logger: true,
        },
    };
}

export function getClassification(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/source/${ id }?with_placeholders=0`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CLASSIFICATION_REQUEST, GET_CLASSIFICATION_SUCCESS, GET_CLASSIFICATION_ERROR ],
            redirect,
            unique: true,
            logger: true,
        },
    };
}

export function getAllInsight(forAllInsight) {
    return (dispatch, getState) => {
        const { dirtyFilters, queryId, lemmataCorrelation, queryInfo, selectedDictionaries } = getState().VocMine.query;

        const page = 1;
        const limit = 10;
        const percentage = lemmataCorrelation.filters.limit / 5;
        const body = {
            ...selectedDictionaries,
            filters: dirtyFilters,
            language: queryInfo.language,
        };


        let requests = [];

        if(!forAllInsight) {
            requests = [
                dispatch(getTexts(queryId, {
                    page,
                    limit,
                    requestBody: { body },
                })),
                dispatch(getLemmata(queryId, {
                    page,
                    limit,
                    requestBody: { body: { ...body } },
                })),
                dispatch(getLemmataForCoOccurrence(queryId, {
                    requestBody: { body: { ...body, percentage } },
                })),
                dispatch(getLemmataForCorrelation(queryId, {
                    requestBody: { body: { ...body, percentage } },
                })),
            ];
        } else {
            const {
                context: contextStr,
                textsOptions,
                chartsOptions,
                language_out,
            } = forAllInsight;
            const context = contextStr ? { context: contextStr } : {};

            textsOptions
            && requests.push(dispatch(getTexts(queryId, {
                page,
                limit,
                withInsight: '&withInsight=true',
                requestBody: { body: {
                    ...body,
                    options: textsOptions,
                    ...context,
                    language_out,
                } },
            })));

            if (chartsOptions) {
                chartsOptions.forEach(option => {
                    option === 'lemmata'
                    && requests.push(
                        dispatch(getLemmata(queryId, {
                            page,
                            limit,
                            withInsight: '&withInsight=true',
                            requestBody: { body: {
                                ...body,
                                ...context,
                                language_out,
                            } },
                        })),
                    );

                    option === 'lemmataForCoOccurrence'
                    && requests.push(
                        dispatch(getLemmataForCoOccurrence(queryId, {
                            withInsight: '?withInsight=true',
                            requestBody: { body: {
                                ...body,
                                percentage,
                                ...context,
                                language_out,
                            } },
                        })),
                    );

                    option === 'lemmataForCorrelation'
                    && requests.push(
                        dispatch(getLemmataForCorrelation(queryId, {
                            withInsight: '?withInsight=true',
                            requestBody: { body: {
                                ...body,
                                percentage,
                                ...context,
                                language_out,
                            } },
                        })),
                    );
                });
            }

            // save new context, if it has been changed via prompt modal
            contextStr && dispatch(changeQueryContext(contextStr));
        }

        dispatch(changeActionName());
        return requests;
    };
}

export function changeActionName() {
    return {
        type: CHANGE_ACTION_NAME,
    };
}

export function stopChartsLoading() {
    return {
        type: STOP_CHARTS_LOADING,
    };
}

export function getLemmata(id, { requestBody, page, limit, withInsight= '' }, params) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }/lemmata?page=${ page }&limit=${ limit }${ withInsight }`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_LEMMATA_REQUEST, GET_LEMMATA_SUCCESS, GET_LEMMATA_ERROR ],
            unique: true,
            logger: true,
            typeMessage: 'warning',
            redirect,
            ...requestBody,
            params,
        },
    };
}

export function getTexts(id, { requestBody, page, limit, withInsight= '' }, params) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }/texts?page=${ page }&limit=${ limit }${ withInsight }`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_TEXTS_REQUEST, GET_TEXTS_SUCCESS, GET_TEXTS_ERROR ],
            unique: true,
            logger: true,
            typeMessage: 'warning',
            redirect,
            ...requestBody,
            params,
        },
    };
}

export function getTextsInfo(queryId, data_id, column_id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ queryId }/text`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_TEXTS_INFO_REQUEST, GET_TEXTS_INFO_SUCCESS, GET_TEXTS_INFO_ERROR ],
            unique: true,
            logger: true,
            loggerMessageIsPrior: true,
            loggerMessages: {
                error: 'Failed to get additional information',
            },
            body: {
                data_id,
                column_id,
            },
        },
    };
}

export function getLemmataForCloud(id, { requestBody, page }) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }/lemmata?page=${ page }&limit=${ 100 }`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_LEMMATA_CLOUD_REQUEST, GET_LEMMATA_CLOUD_SUCCESS, GET_LEMMATA_CLOUD_ERROR ],
            unique: true,
            logger: true,
            typeMessage: 'warning',
            redirect,
            ...requestBody,
        },
    };
}

export function getLemmataForCoOccurrence(id, { requestBody, withInsight = '' }, params) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }/cooccurrence${ withInsight }`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_COOCCURRENCE_REQUEST, GET_COOCCURRENCE_SUCCESS, GET_COOCCURRENCE_ERROR ],
            unique: true,
            logger: true,
            typeMessage: 'warning',
            ...requestBody,
            params,
        },
    };
}

export function getLemmataForCorrelation(id, { requestBody, withInsight = '' }, params) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/query/${ id }/correlation${ withInsight }`,
            method: 'POST',
            contentType: 'application/json',
            types: [ GET_CORRELATION_REQUEST, GET_CORRELATION_SUCCESS, GET_CORRELATION_ERROR ],
            unique: true,
            logger: true,
            typeMessage: 'warning',
            ...requestBody,
            params,
        },
    };
}

export function sockedWithError(actionName, queryId, error) {
    return {
        type: MINE_SOCKED_ERROR,
        payload: { actionName, queryId, error },
    };
}

export function setZeroShotExportData(exportData) {
    return {
        type: SET_ZERO_SHOT_EXPORT_DATA,
        payload: exportData,
    };
}

export function updateShares(payload) {
    return {
        type: QUERY_INFO_UPDATE_SHARES,
        payload,
    };
}

export function updatePaginationFilters(payload) {
    return {
        type: PAGINATION_FILTERS_CHANGED,
        payload,
    };
}

export function updateCorrelationLimit(payload) {
    return {
        type: CORRELATION_LIMIT_CHANGED,
        payload,
    };
}

export function getClassifierList() {
    return {
        [CALL_API]: {
            endpoint: `/public_api/classify/classifier/list`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_CLASSIFIER_LIST_REQUEST, GET_CLASSIFIER_LIST_SUCCESS, GET_CLASSIFIER_LIST_ERROR ],
            unique: true,
        },
    };
}

export function exportQuery({ exportType, ...rest }) {
    return dispatch => {
        switch (exportType) {
            case 'toText':
                return dispatch(exportText(rest));
            case 'toLemmas':
                return dispatch(exportLemmas(rest.queryId, rest.filters, rest.synonyms, rest.stopWords, rest.language));
            case 'forTraining':
                const body = {
                    [rest.isNew ? 'categoryName' : 'categoryId']: rest.category,
                    filters: rest.filters,
                    synonyms: rest.synonyms,
                    stopWords: rest.stopWords,
                    language: rest.language,
                };

                return dispatch(exportForTraining(rest.queryId, rest.classifierId, body));
            case 'toVerification':
                return dispatch(exportVerification(rest));
            case 'toZeroShot':
                const zeroShotBody = {
                    ...rest.zeroShotExportData,
                    filters: rest.filters,
                    synonyms: rest.synonyms,
                    stopWords: rest.stopWords,
                    language: rest.language,
                    queryId: rest.queryId,
                };

                return dispatch(exportZeroShot(zeroShotBody));

            default:
                return null;
        }
    };
}

export function exportForTraining(queryId, classifierId, body) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/export/${ queryId }/training/${ classifierId }`,
            method: 'POST',
            contentType: 'application/json',
            types: [ EXPORT_REQUEST, EXPORT_SUCCESS, EXPORT_ERROR ],
            logger: true,
            loggerMessages: {
                success: 'Export for training were successfully added to queue',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function exportVerification({ queryId, classifierId, filters, synonyms, stopWords, language }) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/export/${ queryId }/verification/${ classifierId }`,
            method: 'POST',
            contentType: 'application/json',
            types: [ EXPORT_REQUEST, EXPORT_SUCCESS, EXPORT_ERROR ],
            logger: true,
            loggerMessages: {
                success: 'Export to verification were successfully added to queue',
                error: 'Something went wrong',
            },
            body: { filters, synonyms, stopWords, language },
        },
    };
}

export function exportLemmas(queryId, filters, synonyms, stopWords, language) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/export/${ queryId }/lemmata`,
            method: 'POST',
            contentType: 'application/json',
            types: [ EXPORT_REQUEST, EXPORT_SUCCESS, EXPORT_ERROR ],
            logger: true,
            loggerMessages: {
                success: 'Export lemmas were successfully added to queue',
                error: 'Something went wrong',
            },
            body: { filters, synonyms, stopWords, language },
        },
    };
}

export function exportText({ queryId, ...body }) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/export/${ queryId }/texts`,
            method: 'POST',
            contentType: 'application/json',
            types: [ EXPORT_REQUEST, EXPORT_SUCCESS, EXPORT_ERROR ],
            logger: true,
            loggerMessages: {
                success: 'Export text were successfully added to queue',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function exportZeroShot({ queryId, ...body }) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/mine/export/${ queryId }/file-training`,
            method: 'POST',
            contentType: 'application/json',
            types: [ EXPORT_REQUEST, EXPORT_SUCCESS, EXPORT_ERROR ],
            logger: true,
            loggerMessages: {
                success: 'Export data labelling were successfully added to queue',
                error: 'Something went wrong',
            },
            body,
        },
    };
}
