export class GadgetSettingsService {
    static dateIntervals = [
        { value: "YEAR", id: 'YEAR', label: 'Year', type: 'Date' },
        { value: "QUARTER", id: 'QUARTER', label: 'Quarter', type: 'Date' },
        { value: "MONTH", id: 'MONTH', label: 'Month', type: 'Date' },
        { value: "WEEK_MON", id: 'WEEK_MON', label: 'Week (mon)', type: 'Date' },
        { value: "WEEK_SUN", id: 'WEEK_SUN', label: 'Week (sun)', type: 'Date' },
        { value: "DAY", id: 'DAY', label: 'Day', type: 'Date' },
    ];

    static optionsWithoutDuplicateIds(dataSettings: any, uploadAttrs: any, field: any, dateAttrs?: any) {
        const fieldsToExclude = [
            {
                id: dataSettings.facts,
                isEscluded: dataSettings.facts !== dataSettings[field],
            },
            {
                id: dataSettings.groupBy,
                isEscluded: dataSettings.groupBy !== dataSettings[field],
            },
            {
                id: dataSettings.secondGroupBy,
                isEscluded: dataSettings.secondGroupBy !== dataSettings[field],
            },
        ].filter(field => field.isEscluded);

        if ([ 'groupBy', 'secondGroupBy' ].includes(field)) {
            const selected = dataSettings[field === 'groupBy' ? 'secondGroupBy' : 'groupBy'];
            const isClearAttrs = GadgetSettingsService.dateIntervals.find(({ id }) => id === selected);
            const customColumn = dateAttrs.find(({ id }: any) => id === dataSettings.customDateColumn);

            const customDateIntervals = customColumn
                ? GadgetSettingsService.dateIntervals.map(interval => ({
                    ...interval,
                    label: interval.label + ' (' + customColumn.label + ')',
                    badge: {
                        ...GadgetSettingsService.getLabel(interval.type),
                    },
                }))
                : [
                    ...GadgetSettingsService.addBadgeForOptions(
                        GadgetSettingsService.dateIntervals,
                    ),
                ];

            uploadAttrs = isClearAttrs
                ? uploadAttrs
                : uploadAttrs.concat(customDateIntervals);
        }

        return uploadAttrs.filter((uploadAttr: any) => {
            return !(
                fieldsToExclude.find(excluedFileld =>
                    Array.isArray(excluedFileld.id)
                        ? excluedFileld.id.includes(uploadAttr.id)
                        : uploadAttr.id === excluedFileld.id,
                )
            );
        });
    }

    static getLabel = (type = '') => {
        let labelName;
        const greenLabels = [ 'META', 'INTEGER', 'STRING', 'UPLOAD', 'GROUP' ];
        const dictionary = [
            { id: 'SCALE', name: 'SCALABLE' },
            { id: 'CHOICE', name: 'CONFIGURABLE' },
            { id: 'FREETEXT', name: 'OPEN' },
            { id: 'INTEGER', name: 'META (Integer)' },
            { id: 'STRING', name: 'META (String)' },
            { id: /^SERVICE/, name: 'SYSTEM' },
            { id: 'NPS_SEGMENT', name: '' },
            { id: 'CLASSIFICATION', name: '' },
            { id: 'RECODER', name: 'RECODED' },
        ];

        type = type.toUpperCase();

        dictionary.forEach(label => {
            if (type.search(label.id) !== -1) {
                labelName = label.name;
            }
        });

        const title = labelName || type;

        return {
            color: greenLabels.includes(title)
                ? 'success'
                : title === 'RECODED'
                    ? 'secondary'
                    : 'info',
            title,
        };
    };

    static addBadgeForOptions(options = []) {
        return options.length
            ? options.map(option => {
                const badge = option.originType || option.type;

                return {
                    ...option,
                    badge: {
                        ...GadgetSettingsService.getLabel(badge),
                    },
                };
            })
            : [];
    }
}
