import React, { useState, useRef, useEffect, memo, DragEvent, Dispatch, SetStateAction } from 'react';
import ReactGridLayout, { Layout, ReactGridLayoutProps, DragOverEvent } from 'react-grid-layout';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { HelperService } from '/services';
import type { PaginatedLayout, GridPagePropsType } from '/visual/scenes/Dashboard/models';
import { DashboardGridService } from '/visual/scenes/Dashboard/services';
import { RESET_SCROLL_INTO_VIEW, updateCoordinates } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { scrollIntoViewSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';

import { PageControls } from './components';
import { useHover } from './сustomHooks';

import 'react-grid-layout/css/styles.css';
import styles from './GridPage.module.scss';

const DROP_EFFECT_ALLOWED = 'move';
const { maxSize: { maxW, maxH } } = DashboardGridService.getGadgetSize();
const droppedItemId = 'dropped-item';
const droppingItem = {
    w: maxW,
    h: maxH,
    i: droppedItemId,
};

const draggingThrottle = HelperService.throttle((setIsDragEvent: Dispatch<SetStateAction<boolean>>, value: boolean) =>
    setIsDragEvent(value),
100);

const GridPageComponent = ({
    children,
    gadgetClipboard,
    layout = [],
    pageHeight = DashboardGridService.getPageHeight(),
    page,
    pageIndex,
    pagesCount,
    dashboardId,
    isEditable,
    isTabletAndBelow,
    hoveredPageId,
    style,
    setIsDragEvent,
    onPositionChanged,
    setHoveredPageId,
    setHidePlaceholders,
    ...rest
}: GridPagePropsType) => {
    const dispatch = useDispatch();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const formatLayoutItem = (item: Layout): PaginatedLayout => {
        const foundItem = layout.find(val => val.i === item.i) || { page };

        return HelperService.pick(
            { ...item, page: foundItem.page },
            [ 'x', 'y', 'w', 'h', 'page', 'i', 'minH', 'minW' ],
        ) as PaginatedLayout;
    };

    const gridPageRef = useRef<HTMLDivElement | null>(null);
    const [ storedLayout, setStoredLayouts ] = useState<Array<PaginatedLayout>>(layout.map(formatLayoutItem));
    const [ isActionAllowed, setIsActionAllowed ] = useState(false);
    const { scrollToGadget, scrollToPage, startSmoothScrolling } = useSelector(scrollIntoViewSelector);

    useHover({ ref: wrapperRef, page, setHoveredPageId, isEditable });

    useEffect(() => {
        setStoredLayouts(layout.map(formatLayoutItem));
    }, [ gadgetClipboard, JSON.stringify(layout) ]);

    const isEnoughHeight = () => {
        const newHeight = parseInt(gridPageRef.current?.style.height as string);

        // prev layout height can be empty if first action dragover
        return newHeight <= pageHeight; // && newHeight <= (prevLayoutState.height || newHeight);
    };

    useEffect(() => {
        // Smooth scroll to the top of the page with animation
        // Skip this behaviour if the gadget needs to be scrolled into view
        // The page is scrolled into view inside GadgetsVirtualList.tsx (useEffect based on "scrollToPage")
        if (
            !scrollToGadget
            && startSmoothScrolling
            && HelperService.checkNotNullOrUndefined(scrollToPage)
            && gridPageRef?.current
            && scrollToPage === page
        ) {
            DashboardGridService.scrollElementIntoView(gridPageRef.current, 'fleshPage', 'asd');

            dispatch({ type: RESET_SCROLL_INTO_VIEW });
        }
    }, [ scrollToPage, startSmoothScrolling ]);

    const onLayoutChange = (newLayout: Array<Layout>) => {
        const formattedLayout = newLayout.map(formatLayoutItem);
        const gadgets = formattedLayout.filter(item => !item.i.includes('placeholder'));
        const isDragOverItem = Boolean(formattedLayout.find(item => item.i.includes(droppedItemId)));

        if (!isEnoughHeight() && !isDragOverItem) {
            // rollback to previous correct layout
            setStoredLayouts( [] );
        } else if(isActionAllowed) {
            setStoredLayouts(formattedLayout);
            setTimeout(() => {
                onPositionChanged(gadgets);
            }, 0);
            setIsActionAllowed(false);
        } else if(gadgets.length !== storedLayout.length && !isDragOverItem) {
            const isUpdateCoordinates = !gadgets.every(
                ({ i, x, y }) => storedLayout.find(
                    layout => layout.i === i && layout.x === x && layout.y === y,
                ));

            if(isUpdateCoordinates) {
                let pageId;
                const coordinates = gadgets.map(({ page, x, y, w, h, i: gadgetId }) => {
                    pageId = page;
                    return { x, y, w, h, gadgetId };
                });

                dispatch(updateCoordinates({ pageId, coordinates }));
                setStoredLayouts(gadgets);
            }
        }
    };

    const onDragging = () => {
        draggingThrottle(setIsDragEvent, true);
        draggingThrottle(setHidePlaceholders, true);
    };

    const onCoordinatesChanged = () => {
        setIsActionAllowed(true);

        // set 'isDragging' to 'false' (with 100ms delay to disable title's input opening by click)
        // check: https://sandsiv.atlassian.net/browse/VOC-12073
        draggingThrottle(setIsDragEvent, false);

        setTimeout(() => {
            setHidePlaceholders(false);
        }, 0);
    };

    const onMouseUp = () => setIsActionAllowed(false);

    const onDropOver: ReactGridLayoutProps['onDropDragOver'] = (event: DragOverEvent) => {
        event.preventDefault();
        const transferData: DataTransfer = (event as unknown as DragEvent).dataTransfer;

        transferData.dropEffect = DROP_EFFECT_ALLOWED;
        const isDroppingAllowed = transferData.effectAllowed === DROP_EFFECT_ALLOWED;

        return isDroppingAllowed ? { w: droppingItem.w, h: droppingItem.h } : false;
    };

    const onDrop: ReactGridLayoutProps['onDrop'] = (layout, item, event: Event) => {
        if (isEnoughHeight() && item) {
            const formattedLayout = layout.map(formatLayoutItem);
            const gadgets = formattedLayout
                .filter(formattedLayoutItem => !formattedLayoutItem.i.includes(droppedItemId));
            const id = (event as unknown as DragEvent).dataTransfer.getData('text');
            const element: PaginatedLayout = { ...formatLayoutItem(item), i: id, page };
            const layouts = [ ...gadgets, element ];

            onPositionChanged(layouts, 'drop');
        }

        // set 'isDragging' to 'false'
        draggingThrottle(setIsDragEvent, false);
        setIsActionAllowed(false);
        setHidePlaceholders(false);
    };

    const onResizeStart = () => setHidePlaceholders(true);

    return (
        <div
            style={{ height: pageHeight, ...style }}
            onMouseUp={ onMouseUp }
            className={ styles.gridPageWrapper }
            id={ `page_${page}` }
            ref={ wrapperRef }
        >
            <ReactGridLayout
                isBounded
                innerRef={ gridPageRef }
                layout={ storedLayout }
                style={{ zIndex: 2, minHeight: pageHeight }}
                droppingItem={ droppingItem }
                onDrag={ onDragging }
                onDragStop={ onCoordinatesChanged }
                onDropDragOver={ onDropOver }
                onDrop={ onDrop }
                onResizeStart={ onResizeStart }
                onResizeStop={ onCoordinatesChanged }
                onLayoutChange={ onLayoutChange }
                { ...rest }
            >
                { children }
            </ReactGridLayout>

            {
                !isMobile
                && <PageControls
                    pageId={ page }
                    pageIndex={ pageIndex }
                    pagesCount={ pagesCount }
                    dashboardId={ dashboardId }
                    show={ isEditable && !isTabletAndBelow }
                />
            }
        </div>
    );
};

GridPageComponent.displayName = 'GridPage';
export const GridPage = memo(GridPageComponent);
