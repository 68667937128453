import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageRequests } from '/services';
import { VocCleaupHOC, TooltipWrapper } from '/components';
import { ManageExportersListModal } from './components/ManageExportersListModal';
import { ExportersListTable } from './components/ExportersListTable';

import {
    getExportersList,
    checkScheduleByExporter,
    checkScheduleByExporterConfig,
    cleanup,
    updateFilters,
    deleteExporterItem,
    deleteNewExporterItem,
    manageExporterItem,
    getExporter,
} from './modules/exportersList.index';
import { getExporters } from '../../modules/export.index';

import {
    exportersSelector,
    tableSettingsSelector,
    filtersSelector,
    dataExportSelector,
} from './modules/exportersList.selectors';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { isLoadedPermissionSelector } from '/scenes/VocFeedback/scenes/Export/modules/export.selector';
import { availableUnitsId } from '/scenes/VocFeedback/modules/VocFeedback.selectors';

import './ExportersList.scss';

const mapStateToProps = state => ({
    tableData: exportersSelector(state),
    tableState: tableSettingsSelector(state),
    filters: filtersSelector(state),
    dataExport: dataExportSelector(state),
    availableUnitsId: availableUnitsId(state),
    isLoadedPermission: isLoadedPermissionSelector(state),
});

const mapDispatchToProps = {
    getExportersList,
    checkScheduleByExporter,
    getExporters,
    cleanup,
    getExporter,
    updateFilters,
    deleteExporterItem,
    deleteNewExporterItem,
    checkScheduleByExporterConfig,
    manageExporterItem,
    showConfirm,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class ExportersList extends Component {
    pageRequests = new PageRequests();

    state = {
        openModalManage: false,
        surveyId: null,
        exporterId: null,
        modalManageType: '',
    };

    getTableOptions() {
        return {
            requestTrigger: this.requestTrigger,
            haveSearch: true,
            updateFilters: this.props.updateFilters,
            withoutPagination: true,//todo temporarily until we delete old exports
            rowStyle: {
                height: '38px',
            },
        };
    }

    @autobind
    closeModal() {
        this.setState({
            openModalManage: false,
            exporterId: null,
            modalManageType: '',
        });
    }

    @autobind
    openModalManage(value, isNew) {
        !isNew && this.props.getExporter(parseInt(value.id));

        this.setState({
            openModalManage: !isNew,
            exporterId: value.id,
            modalManageType: 'edit',
        });
    }

    @autobind
    openModalCopy(value, isNew) {
        !isNew && this.props.getExporter(value.id);

        this.setState({
            openModalManage: !isNew,
            exporterId: value.id,
            modalManageType: 'copy',
        });
    }

    @autobind
    openModalDelete(exporterId, isNew) {
        const { checkScheduleByExporter, showConfirm, checkScheduleByExporterConfig } = this.props;
        const requestMethod = isNew ? checkScheduleByExporterConfig : checkScheduleByExporter;

        requestMethod(exporterId).promise
            .then(result => {
                const message = result.count === 0
                    ? 'You are going to delete this exporter, continue?'
                    : 'There is an active schedule for this exporter that will be removed. Are you sure you want to delete this exporter?';

                showConfirm({
                    header: 'Warning!',
                    content: message,
                    successCallback: () => this.deleteExporter(exporterId, isNew),
                });
            });
    }

    @autobind
    deleteExporter(exporterId, isNew = false) {
        const params = {
            ...this.props.filters,
            page: 1,
        };
        const requestMethod = isNew ? this.props.deleteNewExporterItem : this.props.deleteExporterItem;

        requestMethod(exporterId).promise
            .then(() => {
                this.requestTrigger(params);
                this.props.updateFilters(params);
            });
    }

    @autobind
    openModalAdd() {
        this.setState({
            openModalManage: true,
            modalManageType: 'new',
        });
    }

    componentDidMount() {
        this.requestTrigger();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isLoadedPermission !== this.props.isLoadedPermission) {
            this.requestTrigger();
        }
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        const model = {
            sort: filters.sort_field ? (filters.sort_order === 'desc' ? '' : '-') + filters.sort_field : '',
            limit: filters.limit,
            page: filters.page,
            search: filters.search ? filters.search : '',
            include_legacy_exporters: 1,
            units: `[${this.props.availableUnitsId?.toString()}]`,
        };

        this.pageRequests.cleanup();

        const request = this.props.getExportersList(model);

        this.pageRequests.addRequest(request);
    }

    @autobind
    manageExportersList(params) {
        return this.props.manageExporterItem(params).promise
            .then(() => {
                this.requestTrigger();
                this.closeModal();
            });
    }

    render() {
        const {
            tableData,
            tableState,
            filters,
            dataExport,
        } = this.props;

        const {
            openModalManage,
            modalManageType,
        } = this.state;

        return (
            <DocumentTitle title="Feedback :: Exporters List">
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Exporters List</span>
                        <div className={ 'panel__header-buttons' }>
                            <TooltipWrapper value={ `This type of exporter is deprecated and will be disabled soon (end of life - end of 2023).
                             Please reconfigure your exports via new exporter type.` } force>
                                <Button className={ 'panel__header-button' }
                                    outline
                                    color="white"
                                    onClick={ this.openModalAdd }>
                                    <FontAwesomeIcon icon='plus' className='color-green'/>
                                    <span> Add Legacy Exporter</span>
                                </Button>
                            </TooltipWrapper>
                            <Link to={ '/feedback/exporter/create' }>
                                <Button className={ 'panel__header-button' }
                                    outline
                                    color="white"
                                >
                                    <FontAwesomeIcon icon='plus' className='color-green'/>
                                    <span> Add Exporter</span>
                                </Button>
                            </Link>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ExportersListTable
                            filters={ filters }
                            options={ this.getTableOptions() }
                            data={ tableData }
                            tableState={ tableState }
                            openModalManage={ this.openModalManage }
                            openModalDelete={ this.openModalDelete }
                            openModalCopy={ this.openModalCopy }
                        />

                        <ManageExportersListModal
                            show={ openModalManage }
                            dataExportersModal={ dataExport }
                            manageExportersList={ this.manageExportersList }
                            close={ this.closeModal }
                            modalManageType={ modalManageType }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}

ExportersList.propTypes = {
    tableData: PropTypes.array,
};
