import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VocCleaupHOC } from '/components';
import { PageRequests } from '/services';
import { QuarantinesSettingsTable } from './components';
import { QuarantinesFlowModal } from '/feedback/components';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

import {
    getQuarantineListRequest,
    setQuarantineDataById,
    deleteQuarantineItem,
    createQuarantineItem,
    updateQuarantineItem,
    updateFilters,
    cleanup,
} from './modules/quarantines.modules';

import { getMetaData, getSurveyList, getSurveysWithDeleted } from '/feedback/modules/VocFeedback.modules';
import { surveyListLoading } from '/feedback/modules/VocFeedback.selectors';
import { getPermissionSelector } from '../../modules/globalSettings.selectors';

import {
    filtersSelector,
    formValuesSelector,
    tableSettingsSelector,
    selectOptionsSelector,
    initialValuesSelector,
    tableDataSelector,
    collectFlowOptions,
} from './modules/quarantines.selectors';

const mapStateToProps = state => ({
    formValue: formValuesSelector(state),
    selectOptions: selectOptionsSelector(state),
    initialValues: initialValuesSelector(state),
    tableState: tableSettingsSelector(state),
    tableData: tableDataSelector(state),
    filters: filtersSelector(state),
    flowOptions: collectFlowOptions(state),
    surveyListLoading: surveyListLoading(state),
    permission: getPermissionSelector(state),
});

const mapDispatchToProps = {
    getQuarantineListRequest,
    setQuarantineDataById,
    deleteQuarantineItem,
    createQuarantineItem,
    updateQuarantineItem,
    updateFilters,
    getSurveysWithDeleted,
    getSurveyList,
    showConfirm,
    cleanup,
    getMetaData,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class QuarantinesSettings extends Component {
    state = {
        openFlowModal: false,
        typeFlowModal: '',
    };
    pageRequests = new PageRequests();

    componentDidMount() {
        this.pageRequests.cleanup();
        const { getSurveyList, getMetaData } = this.props;

        const requests = [ getSurveyList(), getMetaData() ];

        requests.forEach(request => this.pageRequests.addRequest(request));
        this.requestTrigger();
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        this.pageRequests.cleanupById('quarantines');
        const request = this.props.getQuarantineListRequest({ ...filters });

        this.pageRequests.addRequest(request, 'quarantines');
    }

    @autobind
    openModalDelete({ id }) {
        this.props.showConfirm({
            header: 'Warning!',
            content: 'You are going to delete this quarantine, continue?',
            successCallback: () => this.deleteQuarantine(id),
        });
    }

    @autobind
    deleteQuarantine(id) {
        const model = {
            ...this.props.filters,
            page: 1,
        };

        this.props.deleteQuarantineItem(id).promise
            .then(() => {
                this.requestTrigger(model);
                this.props.updateFilters(model);
            });
    }

    @autobind
    submitQuarantineItem(id, data, isCopy) {
        const request = id && !isCopy
            ? this.props.updateQuarantineItem(id, data)
            : this.props.createQuarantineItem(data, isCopy);

        return request.promise.then(() => {
            this.closeModal();
            this.requestTrigger({ ...this.props.filters });
        });
    }

    @autobind
    openModal({ id }, type) {
        this.props.setQuarantineDataById(id);
        this.setState({
            openFlowModal: true,
            typeFlowModal: type,
        });
    }

    @autobind
    closeModal() {
        this.props.setQuarantineDataById(null);
        this.setState({
            openFlowModal: false,
            typeFlowModal: '',
        });
    }

    render() {
        const {
            tableState,
            tableData,
            filters,
            updateFilters,
            selectOptions,
            initialValues,
            formValue,
            flowOptions,
            surveyListLoading,
            permission,
        } = this.props;

        return (
            <DocumentTitle title="Feedback :: Quarantines Settings">
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Quarantines Setting</span>
                        <div className={ 'panel__header-buttons' }>
                            <Button className={ 'panel__header-button' }
                                outline
                                color="white"
                                onClick={ this.openModal }
                                disabled={ !permission.isPermitEdit }
                            >
                                <FontAwesomeIcon icon='plus' className='color-green'/>
                                <span> Add Settings</span>
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <QuarantinesSettingsTable
                            requestTrigger={ this.requestTrigger }
                            updateFilters={ updateFilters }
                            openModalDelete={ this.openModalDelete }
                            openModalEdit={ this.openModal }
                            filters={ filters }
                            data={ tableData }
                            tableState={ tableState }
                            selectOptions={ selectOptions }
                            formValue={ formValue }
                            flowOptions={ flowOptions }
                            setQuarantineDataById={ this.props.setQuarantineDataById }
                            permission={ permission }
                        />
                        <QuarantinesFlowModal
                            show={ this.state.openFlowModal }
                            close={ this.closeModal }
                            initialValues={ initialValues }
                            allSurveyLoading={ surveyListLoading }
                            formValue={ formValue }
                            selectOptions={ selectOptions }
                            flowOptions={ flowOptions }
                            submitQuarantineItem={ this.submitQuarantineItem }
                            typeFlowModal={ this.state.typeFlowModal }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}
