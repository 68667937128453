import { createSelector } from 'reselect';
import { HelperService } from '/services';

export const _getThemesSelector = state => state.VocFeedback.globalSettings.themes;

export const themeFiltersSelector = createSelector([ _getThemesSelector ], ({ filters }) => filters);
export const themeResultsSelector = createSelector([ _getThemesSelector ], ({ results }) => results);
export const getTagsSelector = createSelector([ _getThemesSelector ], ({ tags }) => tags);

export const themeTableDataSelector = createSelector([ _getThemesSelector ],
    ({ results, count, loading }) =>
        ({
            results: results.map(theme => ({
                name: { id: theme.id, name: theme.name, description: theme.title },
                creation: HelperService.formatDate(theme.creation, 'D/MM/YYYY'),
                last_interaction: HelperService.formatDate(theme.last_interaction, 'D/MM/YYYY'),
                actions: theme.id,
                tags: theme.digi_theme_tags.map(tag => tag.name),
                theme: {
                    ...theme,
                    units: theme.all_theme_units_name,
                },
            }),
            ),
            count,
            loading,
        }));

export const tagsSelector = createSelector(
    [ getTagsSelector, themeFiltersSelector ],
    ({ data, loading }, { extraFilters }) => {
        return {
            options: data.map(tag => ({
                label: tag.name,
                value: tag.name,
            })),
            selected: [ ...extraFilters.tags ],
            loading,
        };
    });
