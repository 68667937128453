import { sockets } from '/services';
import {
    updateNotificationItem,
    removeNotification,
} from './actions';

function socketCallback(notification, store) {
    switch(notification.action) {
        case 'delete': {
            store.dispatch(removeNotification(notification));
            break;
        }

        default: {
            store.dispatch(updateNotificationItem(notification, store));
        }
    }
}

export function initNotificationSocket() {
    return sockets.on('notification_widget', socketCallback);
}
