import { CALL_API, FEEDBACK } from 'store/middleware/api';
import { HelperService } from '/services';
import {
    getLayoutsList,
    getUnitThemeList,
    getSMSGatewaysList,
} from '/feedback/modules/VocFeedback.modules';
import { SettingsService } from '/feedback/services/SettingsService';

export const GET_ADVANCED_SETTINGS_REQUEST = 'GET_ADVANCED_SETTINGS_REQUEST';
export const GET_ADVANCED_SETTINGS_SUCCESS = 'GET_ADVANCED_SETTINGS_SUCCESS';
export const GET_ADVANCED_SETTINGS_ERROR = 'GET_ADVANCED_SETTINGS_ERROR';

export const PUT_GLOBAL_ADVANCED_SETTINGS_REQUEST = 'PUT_GLOBAL_ADVANCED_SETTINGS_REQUEST';
export const PUT_GLOBAL_ADVANCED_SETTINGS_SUCCESS = 'PUT_GLOBAL_ADVANCED_SETTINGS_SUCCESS';
export const PUT_GLOBAL_ADVANCED_SETTINGS_ERROR = 'PUT_GLOBAL_ADVANCED_SETTINGS_ERROR';

export const ADVANCED_SETTINGS_CLEANUP = 'ADVANCED_SETTINGS_CLEANUP';
export const ADVANCED_SETTINGS_START_LOADING = 'ADVANCED_SETTINGS_START_LOADING';
export const ADVANCED_SETTINGS_END_LOADING = 'ADVANCED_SETTINGS_END_LOADING';

export const ATTACH_THEME_ADVANCED_REQUEST = 'ATTACH_THEME_ADVANCED_REQUEST';
export const ATTACH_THEME_ADVANCED_SUCCESS = 'ATTACH_THEME_ADVANCED_SUCCESS';
export const ATTACH_THEME_ADVANCED_ERROR = 'ATTACH_THEME_ADVANCED_ERROR';

const GET_DEFAULT_THEME_ID_REQUEST = 'GET_DEFAULT_THEME_ID_REQUEST';
const GET_DEFAULT_THEME_ID_SUCCESS = 'GET_DEFAULT_THEME_ID_SUCCESS';
const GET_DEFAULT_THEME_ID_ERROR = 'GET_DEFAULT_THEME_ID_ERROR';

const modelKeys = {
    ...SettingsService.getKeysForFilter('advanced', true),
};

function getInitialState() {
    return {
        loading: false,
        sending: false,
        settings: {},
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case ADVANCED_SETTINGS_START_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case ADVANCED_SETTINGS_END_LOADING: {
            return {
                ...state,
                loading: false,
            };
        }

        case GET_ADVANCED_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...HelperService.pick(action.response, modelKeys[action.params]),
                },
            };

        case PUT_GLOBAL_ADVANCED_SETTINGS_REQUEST: {
            return {
                ...state,
                sending: true,
            };
        }

        case PUT_GLOBAL_ADVANCED_SETTINGS_SUCCESS: {
            return {
                ...state,
                sending: false,
                settings: {
                    ...state.settings,
                    ...HelperService.pick(action.response, modelKeys[action.params]),
                },
            };
        }

        case PUT_GLOBAL_ADVANCED_SETTINGS_ERROR: {
            return {
                ...state,
                sending: false,
            };
        }

        case ATTACH_THEME_ADVANCED_REQUEST: {
            return {
                ...state,
                sending: true,
            };
        }

        case ATTACH_THEME_ADVANCED_SUCCESS: {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    'digi_theme': action.response.new_default_theme,
                },
            };
        }

        case ATTACH_THEME_ADVANCED_ERROR: {
            return {
                ...state,
                sending: false,
            };
        }

        case GET_DEFAULT_THEME_ID_REQUEST: {
            return {
                ...state,
            };
        }

        case GET_DEFAULT_THEME_ID_SUCCESS: {
            const { results } = action.response;

            return {
                ...state,
                settings: {
                    ...state.settings,
                    'digi_theme': results.length ? results[0].id : null,
                },
            };
        }

        case ADVANCED_SETTINGS_CLEANUP:
            return getInitialState();

        default:
            return state;
    }
}

export function getSurveySettings(params) {
    return dispatch => {
        dispatch(startProcessing());
        const channelSettingRequest = dispatch(getSurveySettingsRequest(params));
        const requests = [ channelSettingRequest ];
        const promises = [ channelSettingRequest.promise ];

        let request;

        if (params.channel === 'WEB') {
            request = dispatch(getLayoutsList());
        } else if (params.channel === 'SMS') {
            request = dispatch(getSMSGatewaysList());
        } else if (params.channel === 'DIGI') {
            request = [
                dispatch(getUnitThemeList(params.unit)),
                dispatch(getIdDefaultTheme(params.unit)),
            ];
        }

        if (request && request.length) {
            request.forEach(el => {
                promises.push(el.promise);
                requests.push(el);
            });
        } else if (request) {
            promises.push(request.promise);
            requests.push(request);
        }

        Promise.all(promises)
            .then(() => dispatch(endProcessing()), ({ status }) => {
                if (status !== 0) {
                    return dispatch(endProcessing());
                }
            });

        return requests;
    };
}

export function getSurveySettingsRequest({ unit, channel }) {
    return {
        [CALL_API]: {
            endpoint: `unit/${ unit }/get-channel-settings/${ channel }/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_ADVANCED_SETTINGS_REQUEST, GET_ADVANCED_SETTINGS_SUCCESS, GET_ADVANCED_SETTINGS_ERROR ],
            apiType: FEEDBACK,
            params: channel,
        },
    };
}

export function setDefaultTheme(body) {
    return dispatch => {
        return dispatch(postDefaultTheme(body)).promise
            .catch(SettingsService.catchDIGIThemeErrors);
    };
}

export function postDefaultTheme(body) {
    return {
        [CALL_API]: {
            endpoint: '/digi-theme/reassign/',
            method: 'POST',
            contentType: 'application/json',
            types: [
                ATTACH_THEME_ADVANCED_REQUEST,
                ATTACH_THEME_ADVANCED_SUCCESS,
                ATTACH_THEME_ADVANCED_ERROR,
            ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function putGlobalAdvancedSettings(model) {
    return dispatch => {
        return dispatch(putAdvancedSettingsRequest(model)).promise
            .catch(SettingsService.catchSettingsErrors);
    };
}

export function putAdvancedSettingsRequest({ unit, body, channel }) {
    return {
        [CALL_API]: {
            endpoint: `/unit/${ unit }/set-settings/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [
                PUT_GLOBAL_ADVANCED_SETTINGS_REQUEST,
                PUT_GLOBAL_ADVANCED_SETTINGS_SUCCESS,
                PUT_GLOBAL_ADVANCED_SETTINGS_ERROR,
            ],
            apiType: FEEDBACK,
            logger: true,
            params: channel,
            loggerMessages: {
                success: 'Global Advanced settings were successfully updated',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function getIdDefaultTheme(id) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/?unit_id=${ id }`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_DEFAULT_THEME_ID_REQUEST,
                GET_DEFAULT_THEME_ID_SUCCESS,
                GET_DEFAULT_THEME_ID_ERROR,
            ],
            apiType: FEEDBACK,
            logger: true,
        },
    };
}

export function startProcessing() {
    return {
        type: ADVANCED_SETTINGS_START_LOADING,
    };
}

export function endProcessing() {
    return {
        type: ADVANCED_SETTINGS_END_LOADING,
    };
}

export function cleanup() {
    return {
        type: ADVANCED_SETTINGS_CLEANUP,
    };
}
