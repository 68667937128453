import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { VocTableV2, TooltipWrapper } from '/components';
import { ExportersListActionsFormatter } from '../ExportersListActionsFormatter';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ExportersListTable.scss';
import { Link } from 'react-router-dom';

export class ExportersListTable extends Component {
    settings = [
        {
            field: 'id',
            title: 'ID',
            style: {
                width: '60px',
            },
            sort: true,
        },
        {
            field: 'name',
            title: 'Name',
            formatter: this.nameFormatter,
            sort: true,
        },
        {
            field: 'id',
            formatter: this.ExportersListActionsFormatter,
            title: 'Actions',
            style: {
                width: '115px',
            },
        },
    ];

    @autobind
    openModal(obj) {
        this.props.openModalManage(obj);
    }

    @autobind
    nameFormatter(cell, obj) {
        const disabledDefaultExporter = obj.name === 'Default Exporter' ? 'disabled' : '';

        return (
            !obj.isNewExporter
                ? <>
                    <TooltipWrapper value='Reconfiguration required' force>
                        <span className={ `export-list_old-exporter-icon` }>
                            <FontAwesomeIcon icon={ 'wrench' }/>
                        </span>
                    </TooltipWrapper>
                    <span
                        className={ `export-list_old-exporter-name ${disabledDefaultExporter}` }
                        onClick={ () => obj.name !== 'Default Exporter' && this.openModal(obj) }
                    >
                        { cell }
                    </span>
                </>
                : <Link to={ `/feedback/exporter/${parseInt(obj.id)}` } className={ `${disabledDefaultExporter}` }>
                    <span className={ 'export-list_old-exporter-name' }>{ cell }</span>
                </Link>
        );
    }

    @autobind
    ExportersListActionsFormatter(cell, obj) {
        const {
            openModalManage,
            openModalDelete,
            openModalCopy,
        } = this.props;

        return <ExportersListActionsFormatter
            { ...obj }
            openModalManage={ openModalManage }
            openModalDelete={ openModalDelete }
            openModalCopy={ openModalCopy }
        />;
    }

    render() {
        const {
            tableState: {
                dataTotalSize: total,
                loading,
            },
            filters,
            options,
            data,
        } = this.props;

        return (
            <VocTableV2
                dataSettings={ this.settings }
                options={ options }
                filters={ filters }
                data={ data }
                total={ total }
                loading={ loading }
            />
        );
    }
}

ExportersListTable.propTypes = {
    options: PropTypes.object,
    data: PropTypes.array,
    tableState: PropTypes.shape({
        dataTotalSize: PropTypes.number,
        loading: PropTypes.bool,
    }),
    filters: PropTypes.object,
    openModal: PropTypes.func,
};
