import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { userRightsSelector } from '/modules/selectors';
import { insightDataSelector } from '/query/modules/query.selectors';
import { IInsightBlock, TInsightState, TInsightData } from './models';
import { InsightSideBlock, InsightPromptModal, InsightContent } from './components';

import styles from './style.module.scss';

const initialState = {
    'Text': {
        data: null,
        timestamp: null,
        loading: false,
    },
    'Bar': {
        data: null,
        timestamp: null,
        loading: false,
    },
    'CoOccurrence': {
        data: null,
        timestamp: null,
        loading: false,
    },
    'Correlation': {
        data: null,
        timestamp: null,
        loading: false,
    },
};

export const InsightBlock = ({ chartType, pageRequests }: IInsightBlock) => {
    const [ showInsightQCModal, setShowInsightQCModal ] = useState<boolean>(false);
    const [ state, setState ] = useState<TInsightState>(initialState);
    const { textsInsight, lemmataInsight, lemmataCoOccurrenceInsight, lemmataCorrelationInsight } = useSelector(insightDataSelector);
    const { hasMineInsightAccess } = useSelector(userRightsSelector);

    const toggleInsightQCModal = () => setShowInsightQCModal(hasMineInsightAccess ? !showInsightQCModal : false);

    const updateStateByChartType = (parts: { type: string, content: TInsightData }[]) => {
        return parts.reduce((acc, { type, content }) => ({
            ...acc,
            [type]: content,
        }), initialState);
    };

    useEffect(() => {
        switch (chartType) {
            case 'Text':
                setState(updateStateByChartType([{ type: chartType, content: textsInsight }]));
                break;
            case 'Bar':
                setState(updateStateByChartType([{ type: chartType, content: lemmataInsight }]));
                break;
            case 'CoOccurrence':
                setState(updateStateByChartType([{ type: chartType, content: lemmataCoOccurrenceInsight }]));
                break;
            case 'Correlation':
                setState(updateStateByChartType([{ type: chartType, content: lemmataCorrelationInsight }]));
                break;
            case 'insightNarrator':
                setState(updateStateByChartType([
                    { type: 'Text', content: textsInsight },
                    { type: 'Bar', content: lemmataInsight },
                    { type: 'CoOccurrence', content: lemmataCoOccurrenceInsight },
                    { type: 'Correlation', content: lemmataCorrelationInsight },
                ]));
                break;

            default:
                setState(initialState);
                break;
        }
    }, [ chartType, textsInsight, lemmataInsight, lemmataCoOccurrenceInsight, lemmataCorrelationInsight ]);

    return chartType === 'insightNarrator'
        ? <div className={ styles.insightBlockContainer }>
            <InsightContent
                chartType={ chartType }
                data={ state }
            />

            <div className={ styles.insightControls }>
                <span className={ styles.grayHint }>
                    Generative AI can produce errors and hallucinations.
                    Please review the insights carefully before relying on them.
                </span>
                <Button
                    color='primary'
                    disabled={ !hasMineInsightAccess }
                    onClick={ toggleInsightQCModal }
                    outline
                >
                    <FontAwesomeIcon icon='tasks' />
                    &nbsp;
                    <span>Prompt</span>
                </Button>
            </div>
            <InsightPromptModal
                show={ showInsightQCModal }
                pageRequests={ pageRequests }
                toggle={ toggleInsightQCModal }
            />
        </div>
        : <InsightSideBlock
            chartType={ chartType }
            insightData={ state }
        />;
};
