import React, { Fragment, useState, useRef } from 'react';
import './QuarantinesConditionFormatter.scss';
import { RenderQuarantineCondition } from '../QuarantineFlowConditionString/RenderQuarantineCondition';
import { QuarantinesFlowPopover } from '../QuarantinesFlowPopover';

export const QuarantinesConditionFormatter = ({
    flowOptions,
    formValue,
    setQuarantineDataById,
    id,
    conditions,
    logical_operation,
}) => {
    const [ showFlowPopover, setShowFlowPopover ] = useState(false);
    const ref = useRef(null);

    const openFlowPopover = () => {
        setQuarantineDataById(id);
        setShowFlowPopover(true);
    };

    const toggleFlowPopover = () => setShowFlowPopover(!showFlowPopover);

    const closeFlowPopover = () => setShowFlowPopover(false);

    const tableId = `table-action__container-${ id }`;

    return (
        <>
            <div
                ref={ ref }
                id={ tableId }
                className='table-action__quarantine-list'
                onMouseEnter={ openFlowPopover }
                onMouseLeave={ closeFlowPopover }
            >
                <span className={ `table-action__quarantine-link cell-data-wrapper` }>
                    {
                        conditions.map((condition, index) => <RenderQuarantineCondition
                            key={ index }
                            condition={ condition }
                            index={ index }
                            logicalOperation={ logical_operation }
                            conditionsLength={ conditions.length }
                            conditionType
                        />)
                    }
                </span>

            </div>
            <QuarantinesFlowPopover
                show={ showFlowPopover }
                toggle={ toggleFlowPopover }
                flowOptions={ flowOptions }
                formValue={ formValue }
                target={ ref ? tableId : null }
            />
        </>
    );
};
