import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import DocumentTitle from 'react-document-title';
import { VocCleaupHOC, showToast } from '/components';
import { autobind } from 'core-decorators';
import { PageRequests } from '/services';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { CustomersTable, EditCustomersModal, AddToBlackListModal } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomersTableToolbar } from './components/CustomersTableToolbar';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

import {
    getTableState,
    getTableData,
    getTableFilters,
    customerStatusesSelector,
    addToBlackListFormValuesSelector,
    getCustomersStatus,
    blacklistFormSubmitted,
    addToBlackListFormInitSelector,
    rightsCustomers,
} from './modules/customers.selectors';

import {
    updateFilters,
    getCustomersRequest,
    manageBlacklist,
    deleteCustomer,
    addCustomerAddress,
    updateCustomerContacts,
    cleanup,
    getIdForBlacklisted,
    customerDoesNotExists,
    addAllToBlacklist,
    createCustomer,
} from './modules/customers.modules';

import './Customers.scss';

const valueSelectorForCustomers = formValueSelector('customersModal');

const mapStateToProps = state => ({
    tableState: getTableState(state),
    filters: getTableFilters(state),
    tableData: getTableData(state),
    customerStatuses: customerStatusesSelector(state),
    customerFormState: valueSelectorForCustomers(state, 'state', 'emails', 'phones'),
    addToBlackListFormValues: addToBlackListFormValuesSelector(state),
    status: getCustomersStatus(state),
    blacklistFormSubmitted: blacklistFormSubmitted(state),
    addToBlackListFormInit: addToBlackListFormInitSelector(state),
    rightsCustomers: rightsCustomers(state),
});

const mapDispatchToProps = {
    getCustomersRequest,
    manageBlacklist,
    updateFilters,
    deleteCustomer,
    showConfirm,
    addCustomerAddress,
    updateCustomerContacts,
    cleanup,
    getIdForBlacklisted,
    showToast,
    addAllToBlacklist,
    customerDoesNotExists,
    createCustomer,
};

export const searchTypeOptions = [
    { label: 'Id', value: 'external_id' },
    { label: 'Address', value: 'addresses__address' },
];

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class Customers extends Component {
    state = {
        stateEditModal: {},
        stateBlackListModal: {},
        openEditModal: false,
        openAddToBlacklistModal: false,
        searchKey: searchTypeOptions[0],
    };
    pageRequests = new PageRequests();

    componentDidMount() {
        this.props.getCustomersRequest();
    }

    getTableOptions() {
        return {
            customTableHeader: this.createCustomToolBar(),
            updateFilters: this.props.updateFilters,
            rowStyle: {
                height: '38px',
            },
            goToPage: {
                closeOnChange: false,
                show: true,
            },
            requestTrigger: this.requestTrigger,
        };
    }

    @autobind
    createCustomToolBar() {
        return <CustomersTableToolbar
            search={ this.props.filters.search }
            onSearchType={ this.onSearchType }
            status={ this.props.status.value }
            statuses={ this.props.customerStatuses }
            changeStatus={ this.changeStatus }
            searchKey={ this.state.searchKey }
            onChangeSearchKey={ this.onChangeSearchKey }
            searchOptions={ searchTypeOptions }
        />;
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        const model = {
            sort: filters.sort_field ? (filters.sort_order === 'desc' ? '' : '-') + filters.sort_field : '',
            limit: filters.limit,
            page: filters.page,
            [this.state.searchKey.value]: filters.search ? filters.search : '',
        };

        const statusValue = filters.status.value === 25
            ? { partly_blacklisted: 1 }
            : { state: filters.status.value };

        const result = {
            ...model,
            ...statusValue,
        };

        this.pageRequests.cleanup();
        const request = this.props.getCustomersRequest(result);

        this.pageRequests.addRequest(request);
    }

    @autobind
    onChangeSearchKey(searchKey) {
        this.setState({ searchKey }, () => {
            if (this.props.filters.search) {
                this.requestTrigger({ ...this.props.filters });
            }
        });
    }

    @autobind
    onSearchType(search) {
        this.props.updateFilters({ search, page: 1 });
        this.requestTrigger({ ...this.props.filters, search, page: 1 });
    }

    @autobind
    changeStatus(status) {
        this.props.updateFilters({ status, page: 1 });
        this.requestTrigger({ ...this.props.filters, status, page: 1 });
    }

    @autobind
    openModalDelete(id) {
        this.props.showConfirm({
            header: 'Warning!',
            content: 'You are going to delete this customer, continue?',
            successCallback: () => this.props.deleteCustomer(id).promise
                .then(() => {
                    this.requestTrigger({ ...this.props.filters });
                }),
        });
    }

    @autobind
    openEditModal(value) {
        this.setState({
            openEditModal: true,
            stateEditModal: {
                ...value,
            },
        });
    }

    @autobind
    openAddToBlacklistModal() {
        const { rightsCustomers, addToBlackListFormInit } = this.props;

        rightsCustomers.isPermitEdit && this.setState({
            openAddToBlacklistModal: true,
            stateBlackListModal: {
                ...addToBlackListFormInit,
            },
        });
    }

    @autobind
    closeEditModal() {
        this.setState({
            openEditModal: false,
            stateEditModal: {},
        });
    }

    @autobind
    closeAddToBlackListModal() {
        this.setState({
            openAddToBlacklistModal: false,
            stateBlackListModal: {},
        });
    }

    @autobind
    addToBlackListSubmit({ id, email, phone }) {
        const {
            getIdForBlacklisted,
            customerDoesNotExists,
            showToast,
            addAllToBlacklist,
        } = this.props;

        const request = getIdForBlacklisted(id);

        request.promise
            .then(response => {
                const { id } = response.results[0];

                return addAllToBlacklist(id, email, phone, this.requestTrigger, this.closeAddToBlackListModal);
            })
            .catch(err => {
                err.status !== 404 && showToast({
                    text: 'Something went wrong',
                    type: 'error',
                });
                customerDoesNotExists();
            });

        return request;
    }

    render() {
        const {
            tableState,
            tableData,
            customerFormState,
            addCustomerAddress,
            updateCustomerContacts,
            addToBlackListFormValues,
            manageBlacklist,
            status,
            blacklistFormSubmitted,
            createCustomer,
            rightsCustomers,
        } = this.props;

        const { stateEditModal, openEditModal, stateBlackListModal } = this.state;

        return (
            <DocumentTitle title={ `Feedback :: Customers` }>
                <Card className={ 'customers-list page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Customers: { status.label }</span>
                        <div className={ 'panel__header-buttons' }>
                            <Button className={ 'panel__header-button' }
                                id={ 'addToBlackList' }
                                outline
                                color="white"
                                onClick={ this.openAddToBlacklistModal }
                                disabled={ !rightsCustomers.isPermitEdit }
                            >
                                <FontAwesomeIcon icon='file-signature' className='export-results__icon'/>
                                <span>Add to blacklist</span>
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <CustomersTable
                            options={ this.getTableOptions() }
                            tableState={ tableState }
                            openModalDelete={ this.openModalDelete }
                            data={ tableData }
                            openEditModal={ this.openEditModal }
                            isPermitEdit={ rightsCustomers.isPermitEdit }
                        />
                    </CardBody>
                    <EditCustomersModal
                        show={ openEditModal }
                        close={ this.closeEditModal }
                        customerFormState={ customerFormState }
                        addAddress={ addCustomerAddress }
                        updateContacts={ updateCustomerContacts }
                        manageBlacklist={ manageBlacklist }
                        requestTrigger={ this.requestTrigger }
                        { ...stateEditModal }
                    />
                    <AddToBlackListModal
                        show={ this.state.openAddToBlacklistModal }
                        closeModal={ this.closeAddToBlackListModal }
                        onSubmit={ this.addToBlackListSubmit }
                        initialValues={ stateBlackListModal }
                        formValues={ addToBlackListFormValues }
                        submitted={ blacklistFormSubmitted }
                        createCustomer={ createCustomer }
                    />
                </Card>
            </DocumentTitle>
        );
    }
}
