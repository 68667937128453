import React from 'react';
import Select from 'react-select';
import { Option } from '/components';

export const PermissionSelect = ({
    className,
    classNamePrefix,
    options,
    value,
    zIndex,
    onChange,
    dropdownIndicator,
}) => {
    const config = {};

    if (dropdownIndicator) {
        config['components'] = {
            IndicatorsContainer: () => null,
            DropdownIndicator: () => null,
            Option,
        };
    } else {
        config['components'] = {
            Option,
        };
    }

    return (
        <div className={ `${ className }__wrapper` }>
            <Select
                className={ className }
                classNamePrefix={ classNamePrefix }
                options={ options }
                value={ value }
                backspaceRemovesValue={ false }
                isSearchable={ false }
                onChange={ value => onChange(value) }
                menuPortalTarget={ document.body }
                styles={{ menuPortal: base => ({ ...base, zIndex, width: 135 }) }}
                menuShouldScrollIntoView={ false }
                menuPlacement={ "auto" }
                { ...config }
            />

        </div>
    );
};

PermissionSelect.defaultProps = {
    className: 'voc-select-rights',
    zIndex: 9999,
    dropdownIndicator: true,
    classNamePrefix: 'vochub-select-control',
    options: [],
    value: [],
};
