import React from 'react';
import { SortableItem } from '../SortableItem';
import { SortableContainer } from 'react-sortable-hoc';
import './SortableList.scss';

export const SortableList = SortableContainer(({ itemsList }) =>
    <div className={ 'virtual-source_reorder_body-container' }>
        {
            itemsList.map((item, index) =>
                <SortableItem
                    key={ `item-${item.key}` }
                    index={ index }
                    name={ item.name }
                    type={ item.type }
                    number={ index + 1 }
                />,
            )
        }
    </div>,
);

