import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { TooltipWrapper } from '/components';
import { DrillDownDashboardSettings, useGadgetData } from '/visual/scenes/Dashboard/components';
import { IDrillDashboardNavigationItem, IDrillDownSettingsItem } from '/visual/models';
import { DashboardService } from '/visual/scenes/Dashboard/services';
import { DrillDashboardConfiguration } from './DrillDashboardConfiguration';

import styles from './style.module.scss';

export const DrillDashboardNavigationItem = ({
    drillDashboard,
    onClickDashboard,
}: IDrillDashboardNavigationItem) => {
    const { t } = useTranslation();
    const [ settings, setSettings ] = useState<IDrillDownSettingsItem[]>([]);
    const { gadget, chart } = useGadgetData(drillDashboard.gadgetId);
    const isLoading = drillDashboard.isRoot
        ? false
        : chart.loading || !chart.chartData;

    const handleClickDashboard = () => {
        !drillDashboard.isActive && onClickDashboard(drillDashboard.id, drillDashboard.scrollToGadgetId);
    };

    const prepareSettings = () => {
        const settings = DashboardService.getDrillDownDashboardSettings(gadget, t);

        setSettings(settings);
    };

    useEffect(() => {
        prepareSettings();
    }, [ gadget?.dataSettings ]);

    return (
        <div className={ cx(styles.breadCrumbItem) }>
            <Button
                disabled={ !settings }
                className={ cx(styles.title, { [styles.activeTitle]: drillDashboard.isActive }) }
                color={ 'link' }
                onClick={ handleClickDashboard }
            >
                {
                    !drillDashboard.isRoot
                    && <div className={ cx(styles.drilledDown) }>
                        <FontAwesomeIcon icon={ 'level-up-alt' } />
                    </div>
                }
                <TooltipWrapper
                    value={ drillDashboard.title }
                    placement={ 'auto' }
                >
                    <span className={ cx(styles.titleStr) }>{ drillDashboard.title }</span>
                </TooltipWrapper>
            </Button>

            <div className={ cx(styles.configureInformation) }>
                {
                    drillDashboard.isActive && gadget
                    && <DrillDashboardConfiguration
                        loading={ isLoading }
                        gadget={ gadget }
                        chart={ chart }
                        settings={ settings }
                    />
                }

                <TooltipWrapper
                    className={ cx('configureInformationTooltip') }
                    value={ <DrillDownDashboardSettings settings={ settings } /> }
                    force={ !isLoading && settings?.length > 0 }
                    placement={ 'auto' }
                >
                    <div className={ cx(styles.actionButton, styles.settingsPopupIcon) }>
                        <FontAwesomeIcon
                            icon={ 'info-circle' }
                            size={ 'lg' }
                        />
                    </div>
                </TooltipWrapper>
            </div>
        </div>
    );
};
