import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentTitle from 'react-document-title';
import Select from 'react-select';
import { Card, Row, Col, Label, Button } from 'reactstrap';
import { Prompt } from "react-router";

import { VocSelectInvite, ThinSpinner, PermissionTableFilter } from '/components';
import { unitOptions } from '/feedback/modules/VocFeedback.selectors';
import { getAllUser, getAllGroups, getCurrentUser } from '/modules';
import { usersAndGroupsSelector } from '/modules/selectors';
import { getAllRights, postAllRights } from './modules/shareRights.modules';
import { rightsSelector, shareRightsLoadingSelector } from "./modules/shareRights.selectors";
import { useSortFilter } from "/services/CustomHooks";

import './ShareRights.scss';

export const feedbackRights = [
    {
        value: 5,
        label: "Viewer",
        icon: 'eye',
    },
    {
        value: 4,
        label: "Inviter",
        icon: 'calendar-check',
    },
    {
        value: 3,
        label: "Approver",
        icon: 'thumbs-up',
    },
    {
        value: 2,
        label: "Editor",
        icon: 'user-edit',
    },
    {
        value: 1,
        label: "Manager",
        icon: 'user-secret',
    },
];

export const ShareRights = () => {
    const dispatch = useDispatch();
    const [ unit, setUnit ] = useState('');
    const [ rightsList, setRightsList ] = useState([]);
    const [ rightsIsChanged, setRightsIsChanged ] = useState(false);
    const { data, onProcess, sortOptions, filterOptions } = useSortFilter(rightsList);
    const units = useSelector(unitOptions);
    const members = useSelector(usersAndGroupsSelector);
    const initialRightsList = useSelector(rightsSelector);
    const loadingShareRights = useSelector(shareRightsLoadingSelector);

    useEffect(() => {
        dispatch(getAllUser());
        dispatch(getAllGroups());
        dispatch(getCurrentUser());
    }, []);

    useEffect(() => {
        unit && dispatch(getAllRights(unit.value));
        setRightsList([]);
        setRightsIsChanged(false);
    }, [ unit ]);

    useEffect(() => {
        if(initialRightsList?.length && unit) {
            setRightsList(initialRightsList);
        }
    }, [ initialRightsList ]);

    const onChange = data => {
        const isDataArray = Array.isArray(data);

        if(isDataArray) {
            const uniqueValues = [ ...new Set(data.map(dataRight => `${dataRight.value}${dataRight.permission}`)) ];

            if(uniqueValues.length === data.length) {
                setRightsList(data);
                setRightsIsChanged(true);
            }
        } else {
            const isRightExist = !rightsList.some(
                right =>
                    right.value === data.value
                    && right.permission === data.permission,
            );

            if(isRightExist) {
                setRightsList([ data, ...rightsList ]);
                setRightsIsChanged(true);
            }
        }
    };

    const onApply = () => {
        const sharesModel = {
            unit: unit.value,
            shares: [],
        };

        initialRightsList.forEach(initialRight => {
            const owner = sharesModel.shares.find(sharesModelShare => sharesModelShare.owner_id === initialRight.value);

            if(!owner) {
                sharesModel.shares.push({
                    owner_id: initialRight.value,
                    owner_type: initialRight.ownerType,
                    permissions: [],
                });
            }
        });

        rightsList.forEach(right => {
            const ownerIndexId = sharesModel.shares.findIndex(sharesModelShare => sharesModelShare.owner_id === right.value);
            const permissionName = feedbackRights.find(permission => permission.value === right.permission)?.label;
            const permissionData = {
                id: right.permission,
                name: permissionName,
            };

            if(ownerIndexId >= 0) {
                sharesModel.shares[ownerIndexId].permissions.push(permissionData);
            } else {
                sharesModel.shares.push({
                    owner_id: right.value,
                    owner_type: right.ownerType,
                    permissions: [
                        permissionData,
                    ],
                });
            }
        });

        dispatch(postAllRights(sharesModel));
        setRightsIsChanged(false);
    };

    const onCancel = () => {
        setRightsList(initialRightsList);
        setRightsIsChanged(false);
    };

    return (
        <DocumentTitle title={ 'Feedback :: Share Rights' }>
            <Card className={ 'page__wrapper page__wrapper-share-rights' }>
                <Prompt when={ rightsIsChanged }
                    message='Seems you might have some unsaved changes. Are you sure you want to continue?'
                />

                {
                    loadingShareRights
                    && <div className={ 'page__wrapper-share-rights__loader-wrapper' }>
                        <ThinSpinner/>
                    </div>
                }

                <Row>
                    <Col md={ 6 }>
                        <Label for={ 'unit' }>
                            Unit
                        </Label>
                        <Select
                            className={ '' }
                            name={ 'unit' }
                            value={ unit }
                            options={ units }
                            placeholder={ 'Select Unit' }
                            onChange={ setUnit }
                            styles={{
                                placeholder: () => (
                                    {
                                        color: '#BCBCBC',
                                    }
                                ),
                            }}
                        />
                    </Col>
                </Row>

                <br/>

                <PermissionTableFilter
                    onSortFilter={ onProcess }
                    filterData={{
                        options: feedbackRights,
                        selected: [],
                        loading: false,
                    }}
                    disabled={ !unit }
                    sortData={ sortOptions }
                    filterActive={ Boolean(filterOptions?.length) }
                />
                <VocSelectInvite
                    placeholder={ 'Invite someone' }
                    inviteListPlaceholder={ 'Invitation list is empty' }
                    globalClass={ 'classifier-multi-select__row invite-multi-select__row' }
                    label="Invite people"
                    value={ data }
                    options={ members.options }
                    onChange={ onChange }
                    closeMenuOnSelect={ false }
                    isHubRights={ false }
                    disabled={ !unit }
                    isClearable
                    portal
                    clearValueInSelect
                    customRights={ feedbackRights }
                />
                <Row className='survey-share-rights__controls'>
                    <Col md={{ size: 4, offset: 8 }}>
                        <Button
                            className={ 'survey-share-rights__controls-button' }
                            color={ 'white' }
                            outline
                            onClick={ onCancel }
                        >
                            <FontAwesomeIcon className={ 'question__controls-icon' } icon="times"/>
                            Cancel
                        </Button>
                        <Button
                            color={ 'primary' }
                            onClick={ onApply }
                            disabled={ !unit || !rightsIsChanged }
                        >
                            <FontAwesomeIcon className={ 'question__controls-icon' } icon={ 'check' }/>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Card>
        </DocumentTitle>
    );
};
