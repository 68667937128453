import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import { PageRequests } from '/services';
import { VocCleaupHOC, Preloader } from '/components';
import { SettingsForm } from "/feedback/components";
import {
    getSurveySettings,
    putGlobalAdvancedSettings,
    setDefaultTheme,
    cleanup,
} from './modules/advancedSettings.modules';
import { advancedFormSettingsSelector } from "./modules/advancedSettings.selectors";
import {
    channelSelector,
    unitSelector,
    getPermissionByActiveSelector,
} from '../../modules/globalSettings.selectors';

const mapStateToProps = state => ({
    advancedSettings: advancedFormSettingsSelector(state),
    unit: unitSelector(state),
    channel: channelSelector(state),
    permission: getPermissionByActiveSelector(state),
});

const mapDispatchToProps = {
    getSurveySettings,
    putGlobalAdvancedSettings,
    setDefaultTheme,
    cleanup,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class AdvancedSettings extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        const { unit = {}, channel } = this.props;

        if (unit.value && channel) {
            this.getSurveySettings();
        }
    }

    componentDidUpdate(prevProps) {
        const { unit, channel } = this.props;

        if (unit?.value !== prevProps.unit?.value || channel !== prevProps.channel) {
            this.getSurveySettings();
        }
    }

    getSurveySettings() {
        const { unit, channel, getSurveySettings } = this.props;

        this.pageRequests.cleanup();
        const requests = getSurveySettings({ unit: unit.value, channel });

        requests.forEach(request => this.pageRequests.addRequest(request));
    }

    @autobind
    putAdvancedSettings(body) {
        const {
            unit: {
                value: unit,
            },
            channel,
            advancedSettings: {
                initialValues,
            },
            setDefaultTheme,
            putGlobalAdvancedSettings,
        } = this.props;

        const model = {
            unit,
            channel,
            body,
        };

        if (channel === 'DIGI') {
            const { digi_theme: defaultTheme } = initialValues;
            const { digi_theme: newDefaultTheme } = model.body;

            delete model.body.digi_theme;

            return defaultTheme.value !== newDefaultTheme
                ? setDefaultTheme({
                    unit,
                    new_default_theme: newDefaultTheme,
                }).then(() => putGlobalAdvancedSettings(model))
                : putGlobalAdvancedSettings(model);
        } else {
            return putGlobalAdvancedSettings(model);
        }
    }

    render() {
        const {
            advancedSettings: {
                loading,
                sending,
                fieldsMap,
                initialValues,
            },
            permission,
        } = this.props;

        return (
            <DocumentTitle title="Feedback :: Advanced Settings">
                <>
                    {
                        loading
                            ? <Preloader/>
                            : <SettingsForm
                                fieldsMap={ fieldsMap }
                                initialValues={ initialValues }
                                putSettings={ this.putAdvancedSettings }
                                sendingSettings={ sending }
                                form={ 'GlobalAdvancedSettingsForm' }
                                permission={ permission }
                            />
                    }
                </>
            </DocumentTitle>
        );
    }
}
