import React, { useState, useCallback } from 'react';
import { VocButtonDropdown } from '/components';
import { QueryExecuteService } from '/services';
import { QueryFilterCollapse, QueryInfoSection, FiltersModal } from './components';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './QueryFilterSection.scss';

export const QueryFilterSection = ({
    useQuery,
    collapseStates,
    filters,
    queryInfo,
    textCases,
    classification,
    changeCollapseState,
    changeDirtyFilters,
    changeLang,
    setUseQuery,
    getClassification,
    showConfirm,
    isDataLabelling,
}) => {
    const changeStatusModal = value => filters.reduce((acc, el) => (el.id === value ? el.id : acc), '');
    const isExistFilters = () => !Object.keys(QueryExecuteService.convertFiltersToServer(filters)).length;

    const [ isOpenModal, setIsOpenModal ] = useState(changeStatusModal());
    const [ indexFilter, setIndexFilter ] = useState(false);

    const openModal = useCallback((value, index) => {
        Number.isInteger(index) && setIndexFilter(index);
        setIsOpenModal(changeStatusModal(value));
    }, []);

    const deleteFilter = useCallback((name, index) => {
        let newFilters = QueryExecuteService.convertFiltersToServer(filters);
        const currentFilter = newFilters[name];

        if (currentFilter.filters || currentFilter.values) {
            const key = currentFilter.filters ? 'filters' : 'values';

            newFilters = {
                ...QueryExecuteService.removeFilterCondition(newFilters, key, index, name),
            };
        } else {
            delete currentFilter[index];
            newFilters[name] = {
                ...currentFilter,
            };
            !Object.keys(newFilters[name]).length && delete newFilters[name];
        }
        changeDirtyFilters(newFilters);
    }, [ filters ]);

    const deleteFilterFull = useCallback(name => {
        const newFilters = QueryExecuteService.convertFiltersToServer(filters);

        delete newFilters[name];
        changeDirtyFilters(newFilters);
    },
    [ filters ],
    );

    const closeModal = useCallback(() => {
        setIndexFilter(false);
        setIsOpenModal('');
    }, [] );

    const changeCondition = useCallback((value, name) => {
        const newFilters = QueryExecuteService.convertFiltersToServer(filters);

        newFilters[name].condition = value;
        changeDirtyFilters(newFilters);
    }, [ filters ] );

    const manageModal = useCallback((param, name) => {
        const newFilters = QueryExecuteService.convertFiltersToServer(filters);

        newFilters[name] = name === 'date'
            ? {
                ...QueryExecuteService.convertDate(param),
                column: param.column,
            }
            : { ...param };

        changeDirtyFilters(newFilters);
        changeCollapseState(name, true);
        closeModal();
    }, [ filters ]);

    const checkHasFilters = () => {
        const keyFilters = Object.keys(QueryExecuteService.convertFiltersToServer(filters));
        const except = [ 'textToInclude', 'textToExclude' ];
        const disable = keyFilters.some(el => except.includes(el));

        return disable || !keyFilters.length;
    };

    return (
        <div className='queryFilter-container'>
            <QueryInfoSection
                useQuery={ useQuery }
                queryInfo={ queryInfo }
                textCases={ textCases }
                changeLang={ changeLang }
                setUseQuery={ setUseQuery }
                showConfirm={ showConfirm }
                hasFilters={ checkHasFilters() }
                isDataLabelling={ isDataLabelling }
            />

            <div className='filter-button-group'>
                <VocButtonDropdown
                    options={ filters }
                    placeholder={ 'Add filters' }
                    color="secondary"
                    icon={ 'plus' }
                    handleClick={ openModal }
                    className='mine-button-dropdown'
                />
                <Button className={ 'button-reset__filters' } disabled={ isExistFilters() } color="link"
                    onClick={ () => changeDirtyFilters() }>
                    <FontAwesomeIcon icon="eraser"/>
                    Delete all filters
                </Button>
            </div>

            <QueryFilterCollapse
                changeCollapseState={ changeCollapseState }
                collapseStates={ collapseStates }
                options={ filters }
                queryAttributes={ queryInfo.attributes }
                source={ queryInfo.source }
                handlerChange={ openModal }
                handlerDelete={ deleteFilter }
                handlerDeleteFilter={ deleteFilterFull }
                changeCondition={ changeCondition }
            />

            <FiltersModal
                show={ !!isOpenModal }
                name={ isOpenModal }
                filters={ filters }
                indexFilter={ indexFilter }
                close={ closeModal }
                onSubmit={ manageModal }
                attributes={ queryInfo.attributes }
                source={ queryInfo.source }
                getClassification={ getClassification }
                classification={ classification }
            />
        </div>
    );
};
