import React from 'react';

import { Popover, ListGroup, ListGroupItem, PopoverBody } from 'reactstrap';

export const ExportResultsRatePopover = ({
    show,
    target,
    toggle,
    listData,
}) => (
    <Popover
        id='configurable-popover'
        isOpen={ show }
        className={ 'question-value-popover' }
        placement="bottom"
        target={ target }
        toggle={ toggle }
        trigger="legacy"
    >
        <PopoverBody>
            <ListGroup>
                {
                    listData?.map((listElement, index) => (
                        <ListGroupItem key={ index }>
                            { listElement.setting } <strong>{ listElement.value }</strong>
                        </ListGroupItem>
                    ))
                }
            </ListGroup>
        </PopoverBody>
    </Popover>
);
