import { SortableHandle } from "react-sortable-hoc";
import MultiValueGenericProps, { components } from "react-select";
import { Badge } from "reactstrap";
import React from "react";

interface IProps {
    MultiValueGenericProps: MultiValueGenericProps,
    data: {
        label: string
        badge: {
            title: string
            color: string
        }
    }
}

export const SortableMultiValueLabel = SortableHandle(
    (props: IProps ) => {
        const { data: { badge, label } } = props;

        return <components.MultiValueLabel { ...props }>
            <div className={ 'multi-value-label_title' }>
                { label }
            </div>
            {
                Boolean(badge)
                && <div className={ 'multi-value-label_badge' }>
                    <Badge color={ badge.color }>
                        { badge.title }
                    </Badge>
                </div>
            }
        </components.MultiValueLabel>;
    },
);
