import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, InputGroup, InputGroupAddon, Popover, PopoverBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import './VocTableGoToPage.scss';

@withTranslation()
export class VocTableGoToPage extends PureComponent {
    focusRef = React.createRef();
    state = {
        inputValue: '',
        validInput: true,
        popoverOpen: false,
    };

    @autobind
    handleKeyPress({ key }) {
        const { validInput } = this.state;

        if (key === "Enter" && validInput) {
            this.actionHandler();
        }
    }

    @autobind
    changeInputHandler(e) {
        const inputValue = +e.target.value;
        const validInput = inputValue > 0;

        this.setState({
            inputValue: validInput ? inputValue : '',
            validInput,
        });
    }

    @autobind
    actionHandler() {
        const { limit, page, total, onPageChange, closeOnChange } = this.props;
        const { inputValue } = this.state;

        const pagesCount = Math.ceil(total / limit);
        const value = inputValue ? inputValue : page;
        const currentPage = value <= pagesCount ? value : pagesCount;

        const validInput = currentPage !== page && currentPage >= 1;

        validInput && onPageChange(currentPage);

        this.setState({
            inputValue: '',
            validInput,
            popoverOpen: !closeOnChange,
        });
    }

    @autobind
    togglePopover() {
        this.setState({
            inputValue: '',
            validInput: true,
            popoverOpen: !this.state.popoverOpen,
        });
    }

    @autobind
    inpFocus(input) {
        this.focusRef = input;
        this.focusRef && this.focusRef.focus();
    }

    @autobind
    getPopoverBody() {
        const { inputValue, validInput } = this.state;
        const { t } = this.props;
        const inputClassName = validInput ? '' : 'is-invalid';

        return (
            <PopoverBody className={ 'popoverBody__pagination' }>
                <div className='pagination-form form-go-page noselect'>
                    <div className='form-group text-right'>
                        <InputGroup className='input-group'>
                            <span className='input-group-addon'>{ t('pagination.goToPage') }&nbsp;</span>

                            <Input id='pagination_set'
                                name='pagination_set'
                                autoComplete="off"
                                className={ inputClassName }
                                onKeyPress={ this.handleKeyPress }
                                value={ inputValue }
                                onChange={ e => this.changeInputHandler(e) }
                                maxLength='7'
                                innerRef={ this.inpFocus }
                            />
                            <InputGroupAddon addonType="append">
                                <Button className='form-control'
                                    color={ 'primary' }
                                    disabled={ !validInput }
                                    onClick={ this.actionHandler }
                                >
                                    <FontAwesomeIcon icon={ 'check' }/>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
            </PopoverBody>
        );
    }

    render() {
        return (
            <Fragment>
                <Button
                    id="showGoToPage"
                    type="button"
                    outline
                    color="white"
                    onClick={ this.togglePopover }>
                    <FontAwesomeIcon icon={ 'ellipsis-h' }/>
                </Button>
                <Popover
                    placement="right"
                    target="showGoToPage"
                    isOpen={ this.state.popoverOpen }
                    container={ 'paginationBox' }
                    trigger="legacy"
                    toggle={ this.togglePopover }>
                    { this.getPopoverBody() }
                </Popover>
            </Fragment>
        );
    }
}

VocTableGoToPage.propTypes = {
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    onPageChange: PropTypes.func,
    closeOnChange: PropTypes.bool,
};
