import React, { useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import SplitPane, { Pane } from 'split-pane-react';
import { isMobile } from 'react-device-detect';

import { useRadarChart } from '/visual/scenes/Dashboard/components';
import {
    ChartLabelsSortableList,
    ChartColorPicker,
    FooterInfo,
} from '/visual/scenes/Dashboard/components/Gadget/components';
import { sendChartColor, sendLabel, setContentSettings } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { useSplitPane } from '/services';
import { PopoverChartTooltip, SplitPaneSash } from '/components';

import { RadarChartD3 } from './components';
import {
    IColorPickerData, changeColorHandlerType,
    TApplyLabelModalData,
} from '../../models';

import commonStyles from '../BaseChart/style.module.scss';
import styles from './style.module.scss';

export const RadarChart = ({ chartData }: any) => {
    const dispatch = useDispatch();

    const {
        data,
        filterLabels,
        changeRadarColor,
        changeLabel,
    } = useRadarChart(chartData);

    const {
        sizes,
        onChange,
        onDragEnd,
    } = useSplitPane({
        initSizes: chartData.gadgetData.contentSettings?.labelsSize,
        sizeChanged: newSizes => dispatch(setContentSettings(chartData.gadgetData.id, {
            ...chartData.gadgetData.contentSettings,
            labelsSize: newSizes,
        })),
    });

    const tooltipRef = createRef<HTMLDivElement>();

    const [ highlightedLabel, setHighlightedLabel ] = useState<string | null>(null);
    const [ colorPickerData, setColorPickerData ] = useState<IColorPickerData | null>(null);

    const onApplyLabelModal = (labelData: TApplyLabelModalData) => {
        changeLabel(
            labelData.id,
            labelData.type,
            labelData.value,
            (labelRes, isChanged) => {
                dispatch(sendLabel(chartData.gadgetData.id, labelRes, isChanged));
            });
    };

    const changeColorHandler = ({ elementId = '', colorHex }: changeColorHandlerType) => {
        changeRadarColor(
            elementId,
            colorHex,
            (colorData, isChanged) => {
                dispatch(sendChartColor(chartData.gadgetData.id, colorData, isChanged));
            },
        );
    };

    const changeLabelOrderHandler = (order: string[]) => {
        dispatch(setContentSettings(chartData.gadgetData.id, {
            ...chartData.gadgetData.contentSettings,
            order,
        }));
    };

    return (
        <div className={ styles.radarChartWrapper } id={ 'radarChartWrapper' }>
            <SplitPane
                allowResize={ !isMobile }
                performanceMode
                resizerSize={ 1 }
                split='horizontal'
                sizes={ sizes }
                onChange={ onChange }
                onDragEnd={ onDragEnd }
                sashRender={ () => <SplitPaneSash split='horizontal'/> }
            >
                <Pane className={ commonStyles.graphSplitPanePadding } minSize='35%'>
                    {
                        data && <RadarChartD3
                            data={ data }
                            highlightedLabel={ highlightedLabel }
                            gadgetId={ chartData?.gadgetData.id }
                            gadgetFunction={ chartData?.gadgetData.contentSettings.function }
                            chartType={ chartData?.gadgetData.contentSettings.chartType }
                            toolTipRef={ tooltipRef }
                            setColorPickerData={ setColorPickerData }
                        />
                    }
                </Pane>
                <Pane minSize='20%' maxSize='50%' className={ commonStyles.labelsSplitPanePadding }>
                    <div className={ commonStyles.labelsContainer }>
                        <ChartLabelsSortableList
                            dragDisable={ isMobile }
                            labels={ filterLabels }
                            longLabel={ chartData.gadgetData.contentSettings.longLabel }
                            gadgetId={ chartData.gadgetData.id }
                            chartType={ chartData.gadgetData.contentSettings.chartType }
                            updateSelectedLabel={ setHighlightedLabel }
                            setColorPickerData={ setColorPickerData }
                            onApplyLabelModal={ onApplyLabelModal }
                            onSortEnd={ changeLabelOrderHandler }
                        />
                    </div>
                </Pane>
            </SplitPane>

            <FooterInfo records={ data?.count } lastHealthCheckTime={ chartData.gadgetData.lastHealthCheckTime } />

            <ChartColorPicker
                chartType={ chartData.gadgetData.contentSettings.chartType }
                gadgetId={ chartData.gadgetData.id }
                colorPickerData={ colorPickerData }
                setColorPickerData={ setColorPickerData }
                changeColorCallback={ changeColorHandler }
            />

            <PopoverChartTooltip ref={ tooltipRef } />
        </div>
    );
};
