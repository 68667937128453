import React, { PureComponent } from 'react';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';

import './Option.scss';

export class Option extends PureComponent {
    render() {
        // eslint-disable-next-line no-unused-vars
        const { innerProps, isFocused, data, ...otherProps } = this.props;
        // eslint-disable-next-line no-unused-vars
        const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
        const newProps = { innerProps: { ...otherInnerProps }, ...otherProps, data };
        const isCustomOption = Boolean(data && (data.icon || data.userName));

        const getOptionWithIcon = () => (
            <div className={ 'Select-value__option_container' } id={ data.id }>
                <FontAwesomeIcon icon={ data.icon }
                    className={ 'Select-value__option_container_icon' }
                />
                <div className={ 'Select-value__option_container_text' }>
                    { this.props.children }
                </div>
            </div>
        );

        const getOptionWithOwner = () => (
            <TooltipWrapper value={ `${ this.props.children } / ${ data.userName }` }>
                <div className='Select-value__option_container space-between'>
                    <div className='Select-value__option--title'>
                        { this.props.children }
                    </div>
                    <div className="Select-value__option--owner">
                        { `/ ${ data.userName }` }
                    </div>
                </div>
            </TooltipWrapper>
        );

        const getCustomOption = () => {
            if (data.icon) {
                return getOptionWithIcon();
            } else if (data.userName) {
                return getOptionWithOwner();
            }
        };

        return (
            <components.Option { ...newProps } className="react-select__option">
                {
                    isCustomOption
                        ? getCustomOption()
                        : this.props.children
                }
            </components.Option>
        );
    }
}
