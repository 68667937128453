import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import cx from 'classnames';
import DocumentTitle from 'react-document-title';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageRequests } from '/services';
import { showToast, VocCleaupHOC } from '/components';
import { CustomersTable } from './components';
import {
    getCustomers,
    updateFilters,
    deleteCustomers,
    sendReminder,
    invitationCustomers,
    cleanup,
    addToSelected,
    manageSelectAll,
    removeFromSelected,
    clearSelection,
    deleteQuestionnairesList,
} from './modules/customers.modules';
import {
    getCustomersList,
    getSurveyParam,
    getTableState,
    questionnaireStatesSelector,
    questionnaireSubStatesSelector,
    selectedListSelector,
} from './modules/customers.selectors';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { rightsSurveySelector } from '../../modules/survey.selectors';

const mapStateToProps = state => ({
    customersList: getCustomersList(state),
    tableState: getTableState(state),
    surveyParam: getSurveyParam(state),
    states: questionnaireStatesSelector(state),
    subStates: questionnaireSubStatesSelector(state),
    selected: selectedListSelector(state),
    rightsSurvey: rightsSurveySelector(state),
});

const mapDispatchToProps = {
    getCustomers,
    updateFilters,
    cleanup,
    showConfirm,
    deleteCustomers,
    sendReminder,
    invitationCustomers,
    addToSelected,
    manageSelectAll,
    removeFromSelected,
    clearSelection,
    showToast,
    deleteQuestionnairesList,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class SurveyCustomers extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        if (this.props.surveyParam.activeChannel) {
            const request = this.getCustomersMethod();

            this.pageRequests.addRequest(request);
        }
    }

    componentDidUpdate(prevProps) {
        const { activeChannel } = this.props.surveyParam;

        if (activeChannel !== prevProps.surveyParam.activeChannel) {
            this.pageRequests.cleanup();
            const request = this.getCustomersMethod();

            this.pageRequests.addRequest(request);
        }
    }

    getCustomersMethod() {
        const { surveyParam, getCustomers, tableState, updateFilters } = this.props;
        const data = {
            ...tableState.filters,
            page: 1,
            survey__survey_group_id: surveyParam.id,
            survey__channel__name: surveyParam.activeChannel,
        };

        return getCustomers(data).promise.then(() => updateFilters(data));
    }

    @autobind
    requestTrigger(filters = this.props.tableState.filters) {
        const {
            sort_field, sort_order,
            survey__survey_group_id,
            survey__channel__name,
            limit, page, search = '', state, substate,
        } = filters;

        const model = {
            sort: sort_field ? (sort_order === 'desc' ? '' : '-') + sort_field : '',
            limit,
            page,
            search,
            survey__survey_group_id,
            survey__channel__name,
            state,
            substate,
        };

        if (survey__channel__name) {
            this.pageRequests.cleanup();
            const request = this.props.getCustomers(model);

            this.pageRequests.addRequest(request);
        }
    }

    @autobind
    onSearchType(search) {
        this.props.updateFilters({ search, page: 1 });
        this.requestTrigger({ ...this.props.tableState.filters, search, page: 1 });
    }

    @autobind
    changeState(state, type) {
        this.props.updateFilters({ [type]: state.value, page: 1 });
        this.requestTrigger({ ...this.props.tableState.filters, [type]: state.value, page: 1 });
        this.props.clearSelection();
    }

    @autobind
    clearSelectionHandler() {
        this.props.showConfirm({
            header: 'Warning!',
            content: 'You are going to remove current selection, continue?',
            successCallback: this.clearSelection,
        });
    }

    @autobind
    clearSelection() {
        this.props.clearSelection();
        this.props.showToast({ text: 'Current selection was cleared.', type: 'success' });
    }

    @autobind
    removeQuestionnaireListHandler() {
        this.props.rightsSurvey.isPermitEdit
        && this.props.showConfirm({
            header: 'Warning!',
            content: 'You are going to delete all selected questionnaires, continue?',
            successCallback: this.removeQuestionnaireList,
        });
    }

    @autobind
    removeQuestionnaireList() {
        this.props.deleteQuestionnairesList(this.props.selected).promise
            .then(() => {
                this.requestTrigger();
                this.props.clearSelection();
            });
    }

    render() {
        const {
            customersList, tableState,
            showConfirm, deleteCustomers, sendReminder,
            invitationCustomers, customersTableRef, clearSelection,
            selected, updateFilters, states, subStates, addToSelected, removeFromSelected, manageSelectAll,
            surveyParam: { activeChannel }, rightsSurvey,
        } = this.props;

        return (
            <DocumentTitle title="Feedback :: Survey Customers">
                <Card className={ 'page__wrapper' }>
                    <CardHeader className={ 'panel__header panel__header--buttons' }>
                        <span className={ 'panel__header-title' }>Statistics Results</span>
                        <div className={ 'panel__header-buttons' }>
                            {
                                selected.length
                                    ? <Fragment>
                                        <Button className={ 'panel__header-button' }
                                            outline
                                            color="white"
                                            onClick={ this.clearSelectionHandler }>
                                            <FontAwesomeIcon icon='times' className='export-results__icon'/>
                                            <span>Clear selection</span>
                                        </Button>
                                        <Button
                                            className={ cx(
                                                'panel__header-button',
                                                { 'disabled': !rightsSurvey.isPermitEdit },
                                            ) }
                                            outline
                                            color="white"
                                            disabled={ !rightsSurvey.isPermitEdit }
                                            onClick={ this.removeQuestionnaireListHandler }>
                                            <FontAwesomeIcon icon='trash' className='export-results__icon'/>
                                            <span>Delete</span>
                                        </Button>
                                    </Fragment> : null
                            }
                        </div>
                    </CardHeader>
                    <CardBody>
                        <CustomersTable
                            ref={ customersTableRef }
                            data={ customersList }
                            tableState={ tableState }
                            showConfirm={ showConfirm }
                            deleteCustomers={ deleteCustomers }
                            sendReminder={ sendReminder }
                            invitationCustomers={ invitationCustomers }
                            selected={ selected }
                            states={ states }
                            subStates={ subStates }
                            channel={ activeChannel }
                            addToSelected={ addToSelected }
                            removeFromSelected={ removeFromSelected }
                            manageSelectAll={ manageSelectAll }
                            changeState={ this.changeState }
                            requestTrigger={ this.requestTrigger }
                            updateFilters={ updateFilters }
                            onSearchType={ this.onSearchType }
                            clearSelection = { clearSelection }
                            rightsSurvey={ rightsSurvey }
                        />
                    </CardBody>
                </Card>
            </DocumentTitle>
        );
    }
}

SurveyCustomers.propTypes = {
    customersList: PropTypes.array,
    customersTableRef: PropTypes.object,
};
