import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Preloader, showToast, TooltipWrapper } from '/components';
import { ReorderModal, VirtualSourceForm } from './components/';
import { columnTypesSelector } from '/modules/selectors';
import { showConfirm, dirtyStatusConfirm } from '/scenes/Confirm/modules/confirm.index';
import { isVocCIIntegration } from '../../modules/VocStore.selectors';
import {
    virtualSourceNameSelector,
    virtualSourceColumnHeaderSelector,
    virtualSourceSidebarSelector,
    virtualSourceCellsSelector,
    virtualSourceButtonSelector,
    virtualSourceSelector,
    virtualRelationSelector,
    userInfoSelector,
    virtualSourcesColumnsSelector,
} from './modules/VirtualSource.selectors';
import {
    updateVirtualSourceName,
    addVirtualSourceColumn,
    addVirtualSourceRow,
    updateColumnName,
    updateColumnType,
    updateSelectedSource,
    updateCellData,
    removeVirtualSourceColumn,
    removeVirtualSourceRow,
    processVirtualSource,
    getVirtualSourceById,
    cleanupSource,
    autofillRow,
    autofillSecondRow,
    getVirtualRelations,
    updateVirtualSourceCISync,
    reorderVirtualSourceColumns,
} from './modules/VirtualSource.modules';

import './VirtualSource.scss';

const mapStateToProps = state => {
    return {
        virtualSourceName: virtualSourceNameSelector(state),
        virtualSourceColumnHeader: virtualSourceColumnHeaderSelector(state),
        virtualSourceSidebar: virtualSourceSidebarSelector(state),
        virtualSourceCells: virtualSourceCellsSelector(state),
        columnTypes: columnTypesSelector(state),
        isDisabledSubmitButton: virtualSourceButtonSelector(state),
        data: virtualSourceSelector(state),
        isSupportUser: userInfoSelector(state),
        isVocCIIntegration: isVocCIIntegration(state),
        virtualRelation: virtualRelationSelector(state),
        virtualSourcesColumns: virtualSourcesColumnsSelector(state),
    };
};
const mapDispatchToProps = {
    updateVirtualSourceName,
    addVirtualSourceColumn,
    addVirtualSourceRow,
    updateColumnName,
    updateColumnType,
    updateSelectedSource,
    updateCellData,
    removeVirtualSourceColumn,
    removeVirtualSourceRow,
    processVirtualSource,
    getVirtualSourceById,
    cleanupSource,
    showConfirm,
    dirtyStatusConfirm,
    autofillRow,
    autofillSecondRow,
    updateVirtualSourceCISync,
    getVirtualRelations,
    reorderVirtualSourceColumns,
    showToast,
};

const ConnectedComponent = ({
    virtualSourceName,
    virtualSourceColumnHeader,
    updateVirtualSourceName,
    addVirtualSourceColumn,
    addVirtualSourceRow,
    updateColumnName,
    updateColumnType,
    virtualSourceSidebar,
    updateSelectedSource,
    virtualSourceCells,
    updateCellData,
    removeVirtualSourceColumn,
    removeVirtualSourceRow,
    isDisabledSubmitButton,
    columnTypes,
    data,
    processVirtualSource,
    match,
    getVirtualSourceById,
    cleanupSource,
    showConfirm,
    dirtyStatusConfirm,
    autofillRow,
    autofillSecondRow,
    isSupportUser,
    updateVirtualSourceCISync,
    isVocCIIntegration,
    getVirtualRelations,
    virtualRelation,
    virtualSourcesColumns,
    reorderVirtualSourceColumns,
    showToast,
}) => {
    const { t } = useTranslation();
    const [ submitButtonStatus, setSubmitButtonStatus ] = useState(false);
    const [ isAutoFill, setAutoFill ] = useState(false);
    const [ isOpenReorderModal, setIsOpenReorderModal ] = useState(false);

    const history = useHistory();
    const { sourceId } = match.params;
    const title = `${ t(sourceId ? 'edit' : 'create') } ${t('field.virtualSource')}`;

    const rows = data?.source.sources;

    useEffect(() => {
        if (sourceId) {
            getVirtualRelations(sourceId);
            getVirtualSourceById(sourceId);
        }
        return () => cleanupSource();
    }, []);

    useEffect(() => {
        !rows.length && setAutoFill(false);
    }, [ rows ]);

    useEffect(() => {
        const dirty = data.touched;

        dirtyStatusConfirm({ dirty });
        updateVirtualSourceCISync({ ciSyncRequired: dirty });
    }, [ data.touched ]);

    const submitHandler = () => {
        const { source } = data;
        const ciColumnNames = [ 'questionnaire_created_time', 'questionnaire created time' ];
        const recordIdNames = [ 'record_id', 'record id' ];

        let isQuestionnaireCreationExist = false;
        let isVsHasRecordId = false;

        source.columns.forEach(column => {
            if(!isQuestionnaireCreationExist) {
                isQuestionnaireCreationExist = ciColumnNames.includes(column.name.toLowerCase()) && column.type === 'DATE';
            }
        });

        source.columns.forEach(column => {
            if(!isVsHasRecordId) {
                isVsHasRecordId = recordIdNames.includes(column.name.toLowerCase());
            }
        });

        if(isVsHasRecordId && data.source.ciSync) {
            showToast({ text: t('ciSyncAndRecordId'), type: 'error' });

            return;
        }

        showConfirm({
            checkDirty: true,
            dirty: !isQuestionnaireCreationExist && source.ciSync,
            header: t('warning'),
            content: t('confirmSaveVirtualSourceWithSynchronizeToVocCi'),
            successCallback: () => {
                setSubmitButtonStatus(true);
                processVirtualSource().promise
                    .then(goBack)
                    .catch(() => setSubmitButtonStatus(false));
            },
        });
    };

    const goBackWithConfirm = () => {
        showConfirm({
            checkDirty: true,
            dirty: data.touched,
            header: t('warning'),
            content: `${ t('warningText.unsavedChanges') }${ t('warningText.areYouWantContinue') }`,
            successCallback: () => goBack(),
        });
    };

    const goBack = () => history.push('/store/virtual-sources');

    const syncHandler = () => updateVirtualSourceCISync({ ciSync: !data.source.ciSync, ciSyncRequired: !data.source.ciSync });

    const autofillColumnsHandler = data => {
        autofillRow(data);
        setAutoFill(true);
    };

    const secondAutofillColumnsHandler = data => {
        // Timeout because the UPDATE_SELECTED_SOURCE action didn't process column.mapping in time. VOC-11864
        setTimeout(() => autofillSecondRow(data), 1);
    };

    const handleOpenModalState = () => setIsOpenReorderModal(state => !state);

    return (
        <DocumentTitle title={ `${ t('store') } :: ${ title }` }>
            {
                data.loading
                    ? <div className='invite-modal-preloader-wrapper'>
                        <Preloader />
                    </div>
                    : <>
                        <Row>
                            <Col md={ 12 }>
                                <div className={ 'virtual-source__go-back' }>
                                    <Button color='link' onClick={ goBackWithConfirm }>
                                        <FontAwesomeIcon icon='arrow-left' />
                                        &nbsp;
                                        <span>{ t('back') }</span>
                                    </Button>
                                </div>
                            </Col>
                            <Col md={ 9 }>
                                <h2 className={ 'virtual-source__header-title' }>
                                    { title }
                                </h2>
                            </Col>
                            <Col md={ 3 } className={ 'align-self-end' }>
                                <div className={ 'virtual-source_input-container' }>
                                    {
                                        (isSupportUser && isVocCIIntegration)
                                            ? <div className={ 'virtual-source__input-wrapper' }>
                                                <Input
                                                    id={ 'ci-sync' }
                                                    type='checkbox'
                                                    checked={ data.source.ciSync }
                                                    onChange={ syncHandler }
                                                />
                                                <Label for={ 'ci-sync' } className={ 'virtual-source_input-label' }>
                                                    { t('synchronizeToVocCi') }
                                                </Label>
                                            </div>
                                            : null
                                    }

                                    {
                                        (isVocCIIntegration && data.source.ciSyncRequired && data.source.ciSync)
                                            ? <TooltipWrapper value={ t('syncRequired') } force>
                                                <div className={ 'virtual-source__label-sync' }>
                                                    <FontAwesomeIcon icon='sync' />
                                                </div>
                                            </TooltipWrapper>
                                            : null
                                    }

                                    <TooltipWrapper value={ t('reorderItems') } force>
                                        <Button
                                            outline
                                            color='white'
                                            className={ 'virtual-source__label-reorder' }
                                            disabled={ virtualSourcesColumns.length < 2 }
                                            onClick={ handleOpenModalState }
                                        >
                                            <FontAwesomeIcon icon='sort' />
                                        </Button>
                                    </TooltipWrapper>
                                </div>
                            </Col>
                        </Row>
                        <VirtualSourceForm
                            addColumn={ addVirtualSourceColumn }
                            addSource={ addVirtualSourceRow }
                            handleSourceName={ updateVirtualSourceName }
                            virtualSourceName={ virtualSourceName }
                            virtualSourceSidebar={ virtualSourceSidebar }
                            updateSelectedSource={ updateSelectedSource }
                            removeVirtualSourceRow={ removeVirtualSourceRow }
                            columnTypes={ columnTypes }
                            virtualSourceColumnHeader={ virtualSourceColumnHeader }
                            updateColumnName={ updateColumnName }
                            updateColumnType={ updateColumnType }
                            virtualSourceCells={ virtualSourceCells }
                            updateCellData={ updateCellData }
                            removeVirtualSourceColumn={ removeVirtualSourceColumn }
                            autofillColumnsHandler={ autofillColumnsHandler }
                            secondAutofillColumnsHandler={ secondAutofillColumnsHandler }
                            isAutoFill={ isAutoFill }
                            setAutoFill={ setAutoFill }
                            virtualRelation={ virtualRelation }
                            showConfirm={ showConfirm }
                        />
                        <div className='virtual-source-action-buttons'>
                            <Button
                                outline
                                color='white'
                                onClick={ goBack }
                            >
                                <FontAwesomeIcon icon='times' />
                                &nbsp;
                                <span>{ t('cancel') }</span>
                            </Button>
                            <Button
                                disabled={ isDisabledSubmitButton || submitButtonStatus }
                                color='primary'
                                onClick={ submitHandler }
                            >
                                <FontAwesomeIcon icon='check' />
                                &nbsp;
                                <span>{ t('save') }</span>
                            </Button>
                        </div>
                        <ReorderModal
                            isOpen={ isOpenReorderModal }
                            handleOpenModalState={ handleOpenModalState }
                            columnsList={ virtualSourcesColumns }
                            reorderVirtualSourceColumns={ reorderVirtualSourceColumns }
                        />
                    </>
            }
        </DocumentTitle>
    );
};

export const VirtualSource = connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent);
