import { createSelector } from 'reselect';
import { HelperService } from '/services';
import { formValueSelector } from 'redux-form';
import { getQueryTagsSelector } from '/mine/modules/vocMine.selectors';

export const getLabelClassByType = state => {
    return ({
        active: 'success',
        deleted: 'danger',
        paused: 'warning',
        upload: 'success',
        system: 'info',
        virtual: 'info',
        survey: 'info',
        info: 'info',
        success: 'success',
        danger: 'danger',
        inactive: 'warning',
    })[state];
};

export const _getQueriesList = state => state.VocMine.queriesList;
export const filtersSelector = state => _getQueriesList(state).filters;
export const queriesSelector = state => _getQueriesList(state).queryList;
export const getQueryTitles = state => _getQueriesList(state).queryTitles;
export const getQuerySources = state => _getQueriesList(state).querySources;
export const sourceSelector = state => _getQueriesList(state).source;

const queryModalSelector = formValueSelector('AddQueryModalForm');

export const formValuesSelector = state => queryModalSelector(
    state,
    'source_field',
);

export const tableDataSelector = createSelector([ queriesSelector ], ({ data: queries }) => {
    return queries.map((
        { id, title, tag, created, updated, source, access, user, language, columnIds, currentUserPermission },
    ) => {
        return {
            title: {
                title,
                id,
            },
            tag,
            created,
            updated,
            sourceId: source ? source.title : '',
            shared: {
                id,
            },
            access,
            actions: {
                id,
                currentUserPermission,
                language,
                userId: user.ssoId,
                columnIds,
                source,
            },
            language,
        };
    });
});

export const tableSettingsSelector = createSelector([ queriesSelector ], ({ loading, count: total }) => {
    return {
        total,
        loading,
    };
});

export const getFiltersBySourceSelector = createSelector(sourceSelector, ({ filters }) => filters);
export const getSourcesSelector = createSelector(sourceSelector, ({ loading, data, count }) => ({
    loading,
    count,
    sourcesOptions: data.map(el => {
        const attributes = el.attributes.filter(attr => attr.type === 'TEXT' || attr.type === 'freetext');

        return {
            value: el.id,
            state: el.state,
            label: el.title,
            channel: el.channel !== el.type ? el.channel : false,
            typeColor: getLabelClassByType(el.type),
            channelStatusColor: getLabelClassByType(el.state ? el.state.toLowerCase() : 'success'),
            type: el.type,
            attributes: HelperService.mapObjToSelect(attributes, 'id', 'name'),
            languages: HelperService.mapObjToSelect(el.languages, 'id', 'name'),
        };
    }),
}));

export const textColumnSelector = createSelector([ formValuesSelector ], source => source ? source.attributes : []);

export const languagesSelector = createSelector([ formValuesSelector ], source => {
    const langOptions = source ? source.languages : [];

    return langOptions.filter((lang, inx) => langOptions.findIndex(({ value }) => value === lang.value) === inx);
});

export const queryTagsSelector = createSelector(
    [ getQueryTagsSelector, filtersSelector ],
    ({ data, loading }, { extraFilters }) => {
        return {
            options: HelperService.mapArrayToSelectModel(data.filter(tag => tag)),
            selected: [ ...extraFilters.tag ],
            loading,
        };
    });

export const queryTitlesSelector = createSelector([ getQueryTitles, filtersSelector ], ({ data, loading }, { extraFilters }) => {
    return {
        options: HelperService.mapArrayToSelectModel(data),
        selected: [ ...extraFilters.title ],
        loading,
    };
});

export const querySourcesSelector = createSelector([ getQuerySources, filtersSelector ], ({ data, loading }, { extraFilters }) => {
    const idSources = Object.keys(data);
    const options = idSources.length ? idSources.map(source => ({
        label: data[source].title,
        value: data[source].id,
    })) : [];

    return {
        options,
        selected: [ ...extraFilters.sourceId ],
        loading,
    };
});

export const queryDateSelector = createSelector([ filtersSelector ], ({ extraFilters }) => {
    const { created, updated } = extraFilters;

    return {
        created,
        updated,
    };
});
