import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Toast, ToastBody } from 'reactstrap';
import cx from 'classnames';

import './SortableItem.scss';

export const SortableItem = SortableElement(({ name, type, number }) =>
    <Toast
        className={ cx('virtual-source_reorder_body-item',
            { 'virtual-source_reorder_body-item--even': number % 2 !== 0 },
        ) }
    >
        <ToastBody className={ 'virtual-source_reorder_body-item--body' }>
            <div className={ 'virtual-source_reorder_body-item--name_container' }>
                <div className={ 'virtual-source_reorder_body-item--index' }>
                    { number }
                </div>
                <div className={ 'virtual-source_reorder_body-item--name' }>
                    { name }
                </div>
            </div>
            <div className={ 'virtual-source_reorder_body-item--type' }>
                { type }
            </div>
        </ToastBody>
    </Toast>,
);

