import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    InputGroup,
    InputGroupAddon,
    Input,
    InputGroupText,
    Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* eslint-disable */
export class ProjectListItemControl extends Component {
    render() {
        const {
            selectedProject,
            updateProject,
            addProject,
            newProject,
            changeProjectFieldHandler,
        } = this.props;

        const actionHandler = selectedProject ? updateProject : addProject;
        const projectInputValue = selectedProject ? selectedProject.name : newProject;
        const isDefaultProject = selectedProject && selectedProject.id === 1;

        if (isDefaultProject) {
            return null;
        }

        return (
            <InputGroup>
                <InputGroupAddon addonType={ 'prepend' }>
                    <InputGroupText>
                        { selectedProject ? 'Rename' : 'Add' } Customer Journey
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    type="text"
                    ref="projectName"
                    placeholder={ 'Customer Journey name...' }
                    onChange={ changeProjectFieldHandler }
                    value={ projectInputValue }/>
                <InputGroupAddon addonType={ 'append' }>
                    <Button onClick={ actionHandler } color={ 'white' } outline>
                        <FontAwesomeIcon icon={ 'check' }/>
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        );
    }
}

ProjectListItemControl.propTypes = {
    selectedProject: PropTypes.object,
    newProject: PropTypes.any,
    updateProject: PropTypes.func,
    changeProjectFieldHandler: PropTypes.func,
    addProject: PropTypes.func,
};
