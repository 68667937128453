import React, { useState, useEffect, useRef, memo } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';

import { IGadgetPopover } from './models';

import styles from './style.module.scss';

const GadgetPopoverComponent = ({
    containerRef,
    placement = 'auto',
    flip = false,
    hideArrow = true,
    popperClassName,
    popperInnerClassName,
    popoverInlineStyle = {},
    children,
    hovered = false,
    showPopover = false,
    delay = {
        show: 0, // duration (animation: 300ms delay + delay.show in ms for show)
        hide: 0, // duration (animation: 300ms delay + delay.hide in ms hiding)
    },
    onBeforePopoverHide,
}: IGadgetPopover) => {
    const [ isPopoverVisible, setIsPopoverVisible ] = useState<boolean>(false);
    const [ isDisableHidePopover, setIsDisableHidePopover ] = useState<boolean>(false);
    const [ fadeOut, setFadeOut ] = useState<boolean>(false);
    const showTimeoutRef = useRef<number | null>(null);
    const hideTimeoutRef = useRef<number | null>(null);

    const showWithDelay = () => {
        if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current);
        }

        setFadeOut(false);
        // fade in animation with duration (+animation)
        showTimeoutRef.current = setTimeout(() => {
            setIsPopoverVisible(true);
        }, delay?.show);
    };

    const hideWithDelay = () => {
        if (!isDisableHidePopover && isPopoverVisible) {
            onBeforePopoverHide?.();

            if (showTimeoutRef.current) {
                clearTimeout(showTimeoutRef.current);
            }

            setFadeOut(true);
            // fade out animation with duration
            hideTimeoutRef.current = setTimeout(() => {
                setIsPopoverVisible(false);
            }, delay?.hide);
        }
    };

    useEffect(() => {
        if (showPopover) {
            hovered
                ? showWithDelay()
                : hideWithDelay();
        }

        return () => {
            showTimeoutRef.current && clearTimeout(showTimeoutRef.current);
            hideTimeoutRef.current && clearTimeout(hideTimeoutRef.current);
        };
    }, [ hovered, showPopover, isDisableHidePopover ]);

    return showPopover && !isMobile
        ? <Popover
            target={ containerRef }
            isOpen={ isPopoverVisible }
            boundariesElement={ 'viewport' }
            placement={ placement }
            flip={ flip }
            hideArrow={ hideArrow }
            popperClassName={ cx(styles.popperClass, popperClassName, { [styles.fadeOut]: fadeOut }) }
            innerClassName={ cx(popperInnerClassName) }
            style={ popoverInlineStyle }
        >
            <PopoverBody>
                { children({ setIsDisableHidePopover }) }
            </PopoverBody>
        </Popover>
        : null;
};

GadgetPopoverComponent.displayName = 'GadgetPopover';

export const GadgetPopover = memo(GadgetPopoverComponent);
