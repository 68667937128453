import { EDigiQuestionType } from '/scenes/VocFeedback/constants';

export class QuestionsServices {
    static activeIconColor = '#28a745';

    static isQuestionUsedInCurrentFlow(checkQuestionId, currentQuestionId, flow) {
        let state = false;

        state = flow.next_question === checkQuestionId && currentQuestionId !== checkQuestionId;

        if(!state) {
            flow.conditions.forEach(condition => {
                if(!state) {
                    state = (condition?.value?.question_id || condition?.question_id?.value) === checkQuestionId;
                }
            });
        }

        return state;
    }

    static isQuestionUsedInFlows(checkQuestionId, currentQuestionId, flows) {
        let state = false;

        flows.forEach(flow => {
            if(!state) {
                state = this.isQuestionUsedInCurrentFlow(
                    checkQuestionId,
                    currentQuestionId,
                    flow,
                );
            }
        });

        return state;
    }

    static isMatrixQuestion(questionType) {
        return !questionType
            ? false
            : !!questionType.toLowerCase()?.includes('matrix');
    }

    static inheritDataFromGrantorQuestion(originalData, parentData) {
        let displayClass, languageValues, configurable = null;

        const inheritLanguageValues = () => parentData.language_values.map((languageData, index) => {
            const childLanguageItemData = originalData?.language_values?.[index] ?? {};

            return {
                ...childLanguageItemData,
                language: languageData.language,
                configurable: {
                    ...childLanguageItemData?.configurable ?? {},
                    info_text: languageData.configurable.info_text,
                    next_button_text: languageData.configurable.next_button_text,
                },
            };
        });

        const inheritConfigurableValues = () => {
            const inheritedConfigurableData = {};

            Object
                .entries(parentData.configurable)
                .forEach(([ key, value ]) => inheritedConfigurableData[key] = value);

            return {
                ...(originalData?.configurable ?? {}),
                ...inheritedConfigurableData,
            };
        };

        switch (parentData.display_class) {
            case EDigiQuestionType.MatrixQuestionConfigurableDIGI:
                displayClass = EDigiQuestionType.QuestionConfigurableDIGI;
                languageValues = inheritLanguageValues();
                configurable = inheritConfigurableValues();
                break;
            case EDigiQuestionType.MatrixQuestionScalableDIGI:
                displayClass = EDigiQuestionType.QuestionScalableDIGI;
                languageValues = inheritLanguageValues();
                configurable = inheritConfigurableValues();
                break;

            default:
                displayClass = originalData.display_class;
                break;
        }

        return {
            ...originalData,
            display_class: displayClass,
            language_values: languageValues,
            configurable,
        };
    }

    static prepareChildQuestionForSaving({ originalValues, formValues, parentDisplayClass }) {
        // merge child origin data with form data (user can change some data of child question, so we must check this)
        const result = {
            ...originalValues,
            ...formValues,
        };

        // configurable and answers data were been inherited from parent question and disabled
        // so just get these configurable and answer data from the origin question
        // &names - transform the answer.names for the client so that it can later be converted correctly in the Redux action for the server.
        if (parentDisplayClass === EDigiQuestionType.MatrixQuestionConfigurableDIGI) {
            result.configurable.render_type = originalValues.configurable.render_type;

            result.answers = originalValues.answers.map(answer => ({
                ...answer,
                is_open: answer.is_open ? '1' : '0',
                names: QuestionsServices.prepareLanguageDependentFieldsForClient(answer.names),
            }));

            result.configurable.last_is_open = originalValues.configurable.last_is_open;
        }

        if (parentDisplayClass === EDigiQuestionType.MatrixQuestionScalableDIGI) {
            result.configurable.render_type = originalValues.configurable.render_type;
            result.configurable.number = originalValues.configurable.number;
            result.configurable.start_from_0 = originalValues.configurable.start_from_0;
        }

        // origin data language_values has array type, but this should be formatted as the object type
        // check prepareLanguageDependentFields (question.selectors.js)
        const formattedOriginalLanguageValues = originalValues.language_values
            .reduce((accumulator, currentItem) => {
                accumulator[currentItem.language] = currentItem;

                return accumulator;
            }, {});

        // merge origin and form language data
        for (const lng in formValues.language_values) {
            result.language_values[lng] = {
                ...formattedOriginalLanguageValues[lng],
                ...formValues.language_values[lng],
                configurable: {
                    ...formattedOriginalLanguageValues[lng].configurable,
                },
            };
        }

        return result;
    }

    static prepareLanguageDependentFieldsForClient(array = [], channel) {
        const result = {};
        const languageValues = channel === 'IVR'
            ? array.map(item => ({
                ...item,
                text: QuestionsServices.prepareIvrField(item, 'text'),
                error_text: QuestionsServices.prepareIvrField(item, 'error_text'),
            }))
            : array;

        languageValues.forEach(item => (
            result[item.language] = item
        ));

        return result;
    }

    static prepareIvrField(item, field) {
        return item[field]
            ? { name: item[field] }
            : '';
    }

    static prepareLanguageDependentFieldsForServer(fields) {
        return fields
            ? Object.keys(fields).map(language => ({
                ...fields[language],
                language,
            }))
            : null;
    }
}
