import React from 'react';
import { Button, Col, Row, Container } from 'reactstrap';
import { FieldArray } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortableList } from '../';

export const FieldsTableRender = ({
    fields,
    addFields,
    onSortEnd,
    nameButton,
    nameFields,
    change,
    lengthRow,
    typeOptions,
    disabled,
}) => {
    const changeDataType = (idRow, { valueRequired }) => {
        !valueRequired && change(`${nameFields}[${idRow}].data_value`, null);
    };

    return <>
        <Row>
            <Col md={ 12 } className={ 'text-right add-exporter-condition__wrap' }>
                <Button outline color="primary" onClick={ () => addFields(nameFields) } disabled={ disabled }>
                    <FontAwesomeIcon icon={ 'plus' }/>
                    <span className='button-primary__text'> Add { nameButton }</span>
                </Button>
            </Col>
        </Row>

        {
            lengthRow
                ? <Container className={ 'exporter-conditions__header' }>
                    <Row>
                        <Col md={ 1 }/>
                        <Col md={ 10 }>
                            <Row>
                                {
                                    fields.map((name, inx) => <Col key={ inx } md={ 4 }> { name } </Col>)
                                }
                            </Row>
                        </Col>
                        <Col md={ 1 }/>
                    </Row>
                </Container>
                : <Row>
                    <Col md={ 12 }>
                        {
                            nameFields === 'custom_columns'
                                ? <p className="dynamic-form_box-empty">Exporter settings values are required for custom type</p>
                                : null
                        }
                    </Col>
                </Row>
        }

        <Row>
            <Col md={ 12 } className={ 'exporter-fields-table' }>
                <FieldArray
                    name={ nameFields }
                    component={ SortableList }
                    onSortEnd={ value => onSortEnd(value, nameFields) }
                    changeDataType={ changeDataType }
                    useDragHandle={ true }
                    typeOptions={ typeOptions }
                    lockAxis={ 'y' }
                    lockToContainerEdges
                />
            </Col>
        </Row>
    </>;
};
