import { createSelector } from "reselect";
import { formValueSelector } from 'redux-form';
import { HelperService, UsersRoleFeedbackService } from '/services';
import { isSupportUserSelector } from "/modules/selectors";
import { unitsListSelector } from '/feedback/modules/VocFeedback.selectors';

export const statesSelector = state => state.VocFeedback.common.states;

const CustomerAddToBlackListFormFields = formValueSelector('CustomerAddToBlackListForm');

const _getCustomers = state => state.VocFeedback.customers;

export const getTableState = createSelector(
    [ _getCustomers ], ({ loading, filters, count }) => ({ loading, filters, count }),
);

export const getTableFilters = createSelector(
    [ _getCustomers ], ({ filters }) => filters,
);

export const getCustomersStatus = createSelector(
    [ getTableFilters ], ({ status }) => status,
);

export const customerStatusesSelector = createSelector([ statesSelector ], ({ customer }) => {
    const customerStatuses = customer
        .filter(status => status.id !== 3)
        .map(status => ({
            label: HelperService.capittalize(status.name),
            value: status.id,
        }));

    customerStatuses.unshift({
        label: 'All',
        value: 0,
    });

    customerStatuses.push({
        label: 'Partly blacklisted',
        value: 25,
        partly_blacklisted: 1,
    });

    return [ ...customerStatuses ];
});

const addInitialProtocol = phones => {
    return [
        ...phones.map(phone => {
            const protocols = [
                ...phone.protocols,
            ];

            if (phone.protocols.length === 1) {
                const newProtocol = {
                    address: phone.address,
                    customer: phone.customer,
                    state: 1,
                    blacklisted: false,
                    id: null,
                    protocolId: 1,
                };

                phone.protocols[0].name === 'SMS'
                    ? protocols.push({
                        name: 'IVR',
                        protocol: 'VOICE',
                        protocolId: 3,
                        ...newProtocol,
                    })
                    : protocols.unshift({
                        name: 'SMS',
                        protocol: 'SMS',
                        ...newProtocol,
                    });
            }

            return {
                ...phone,
                protocols,
            };
        }),
    ];
};

export const getTableData = createSelector(
    [ _getCustomers ], ({ results }) => {
        const customersData = results.map(result => {
            const { id, addresses, external_id, creation, unsubscribed, state } = result;
            const phones = addresses
                .filter(item => item.protocol.name === 'VOICE'
                    || item.protocol.name === 'SMS'
                    || (item.protocol.name === 'DIGI' && item.protocol_source === 'PHONE'))
                .reduce((acc, val) => {
                    const { address, customer, id, state } = val;

                    const result = {
                        address,
                        customer,
                    };

                    const protocol = {
                        name: val.protocol.name === 'VOICE' ? 'IVR' : val.protocol.name,
                        protocol: val.protocol.name,
                        protocolId: val.protocol.id,
                        state,
                        id,
                        blacklisted: state === 25,
                    };

                    const fitIndex = acc.findIndex(item => item.address === val.address);

                    if (fitIndex >= 0) {
                        acc[fitIndex].protocols = [ ...acc[fitIndex].protocols, protocol ];
                        return acc;
                    }

                    result.protocols = [ protocol ];
                    return [ ...acc, result ];
                }, []);

            const emails = addresses
                .filter(item =>
                    item.protocol.name === 'EMAIL'
                    || (item.protocol.name === 'DIGI' && item.protocol_source === "EMAIL"),
                )
                .map(address => ({
                    ...address,
                    protocols: [{ name: 'WEB', blacklisted: address.state === 25 }],
                }));

            const shouldEmailsCollapse = emails.length > 3;
            const shouldPhonesCollapse = phones.length > 3;
            const visibleEmails = shouldEmailsCollapse ? emails.filter((item, index) => index <= 2) : emails;
            const visiblePhones = shouldPhonesCollapse ? phones.filter((item, index) => index <= 2) : phones;
            const hiddenEmails = shouldEmailsCollapse ? emails.filter((item, index) => index > 2) : [];
            const hiddenPhones = shouldPhonesCollapse ? phones.filter((item, index) => index > 2) : [];
            const hiddenEmailsLength = shouldEmailsCollapse ? emails.length - 3 : [];
            const hiddenPhonesLength = shouldPhonesCollapse ? phones.length - 3 : [];
            const survey_group_name = unsubscribed.length ? unsubscribed.map(unsubscribe => unsubscribe.survey_group_name) : [];

            return {
                id,
                emails,
                phones,
                external_id,
                creation,
                state,
                survey_group_name,
                shouldPhonesCollapse,
                shouldEmailsCollapse,
                hiddenEmails,
                hiddenPhones,
                visibleEmails,
                visiblePhones,
                hiddenEmailsLength,
                hiddenPhonesLength,
                rowClass: state === 16 ? 'row-blacklisted' : '',
                blacklisted: state === 16,
                editModal: {
                    initialValues: {
                        creation: creation && HelperService.formatDate(creation, HelperService.defaultDateTimeMask),
                        emails,
                        phones: addInitialProtocol(phones),
                        state,
                        id,
                    },
                },
            };
        });

        return customersData;
    });

export const addToBlackListFormInitSelector = createSelector([ _getCustomers ], ({ blacklistForm }) => blacklistForm);
export const blacklistFormSubmitted = createSelector([ _getCustomers ], ({ submitted }) => submitted);

export const addToBlackListFormValuesSelector = state => CustomerAddToBlackListFormFields(
    state,
    'customerId',
    'email',
    'phone',
);

// -------- RIGHTS
export const rightsCustomers = createSelector(
    [ unitsListSelector, isSupportUserSelector ],
    (units, isSupportUser) => {
        const permissions = [];

        units.forEach(unit => unit.id !== null && permissions.push(...unit.permissionsName));

        return permissions.length
            ? UsersRoleFeedbackService.getRightsForCustomers(permissions, isSupportUser)
            : {};
    });
