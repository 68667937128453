import React, { useMemo, useState } from 'react';
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { useTableChart } from '/visual/scenes/Dashboard/components';
import { FooterInfo } from '/visual/scenes/Dashboard/components/Gadget/components';
import {
    getGadgetData,
    setContentSettings,
    setDataSettings,
    updateFilter,
} from '/visual/scenes/Dashboard/modules/Dashboard.modules';

import { Pagination, Table } from './components';
import { showToast } from '/components';
import { TableService } from './services';
import { useDidMountEffect } from '/services';
import { _getIsEditable } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';

import styles from './style.module.scss';

const MIN_COLUMN_SIZE = 100;

const RenderTable = ({ tableChartData, gadgetSize, gadget }) => {
    const { t } = useTranslation();
    const {
        chartData: {
            headers,
            body,
            filters,
            disableServerSort,
            disableServerPagination,
            disableReorder,
            count,
            records,
        },
    } = tableChartData;

    const { dataSettings, contentSettings, lastHealthCheckTime } = gadget;
    const dispatch = useDispatch();
    const isVirtual = dataSettings.dataType === 'virtual';
    const isEditable = useSelector(_getIsEditable);

    const [{ pageIndex, pageSize }, setPagination ]
        = React.useState({
            pageIndex: 0,
            pageSize: 10,
        });

    const [ sorting, setSorting ] = useState(filters.sort
        ? [{
            id: filters.sort,
            desc: filters.order !== 1,
        }]
        : []);

    const data = useMemo(() => body, [ body ]);

    const columns = useMemo(() => headers?.map((headerData: any) => {
        return ({
            accessorKey: headerData.id.replace(/\s|[\[\]:()]/g, ''),
            id: headerData.id.replace(/\s|[\[\]:()]/g, ''),
            realId: headerData.id,
            header: headerData.data,
            width: headerData.width || ((gadgetSize.width - 3) / headers.length),
        });
    }), []);

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [ pageIndex, pageSize ],
    );

    const initialColumnsWidth = useMemo(() => columns.reduce(
        (columnsData: any, columnData: any) => {
            columnsData[columnData.accessorKey] = columnData.width;
            return columnsData;
        }, {},
    ), []);

    const table = useReactTable({
        data,
        columns,
        defaultColumn: {
            size: MIN_COLUMN_SIZE,
            minSize: MIN_COLUMN_SIZE,
        },
        pageCount: Math.ceil(count / (disableServerSort ? pagination.pageSize : filters.limit)),
        initialState: {
            pagination: {
                pageIndex: Math.ceil(Number(filters.offset) / filters.limit),
                pageSize: filters.limit,
            },
            columnOrder: columns.map(column => column.id),
            sorting,
            columnSizing: initialColumnsWidth,
        },
        state: {
            sorting,
            ...disableServerSort
                ? {
                    pagination,
                }
                : {},
        },
        onSortingChange: setSorting,
        columnResizeMode: 'onChange',
        onPaginationChange: setPagination,
        manualPagination: !disableServerSort,
        getPaginationRowModel: getPaginationRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const onCopyTable = () => {
        TableService.copyTable(headers, body);

        dispatch(showToast({ text: t('messages.dataSuccessfullyCopied'), type: 'success' }));
    };

    const getDataByFilter = (filterData = filters) => {
        !disableServerPagination
        && dispatch(getGadgetData(gadget.id, {
            ...filters,
            ...filterData,
        }));
    };

    const onChangeColumnOrder = (e: any) => {
        const finalColumns = e.map(column => {
            const finalColumn = columns.find(finalColumn => column === finalColumn.id);

            return finalColumn.realId;
        });

        !disableReorder && dispatch(setDataSettings(
            gadget.id, {
                ...gadget.dataSettings,
                facts: finalColumns,
            },
        ));
        table.setColumnOrder(e);
    };

    const saveSettings = (data: any) => {
        dispatch(setContentSettings(
            gadget.id,
            {
                ...gadget.contentSettings,
                ...data,
            },
        ));
    };

    const onChangeColumnResizing = (columnsData: any) => {
        contentSettings.saveTableLayout
        && TableService.columnResizingDebounce({ saveSettings, columnsData });
    };

    const onChangeColumnSort = (sortedColumn: any) => {
        const sortData = sortedColumn[0]
            ? {
                ...sortedColumn[0],
                id: columns.find(column => column.accessorKey === sortedColumn[0].id).realId,
            }
            : null;

        const order = sortData
            ? sortData.desc ? -1 : 1
            : null;

        const sort = sortedColumn[0]?.id || null;
        const updatedFilters = { ...filters, order, sort };

        contentSettings.saveTableLayout
        && saveSettings({
            tableSortColumn: sort,
            tableSortDirection: order,
        });

        !disableServerSort
        && dispatch(getGadgetData(gadget.id, updatedFilters));

        //save filters for client side sort
        if(contentSettings.saveTableLayout && disableServerSort) {
            dispatch(updateFilter(gadget.id, updatedFilters));
        }
    };

    useDidMountEffect(() => {
        const sizes = table.getState().columnSizing;

        const newSizes = columns.reduce((columnObj, column) => {
            return {
                ...columnObj,
                [column.accessorKey]: sizes[column.accessorKey] || MIN_COLUMN_SIZE,
            };
        }, {});

        const columnWidthObj = Object.keys(newSizes).reduce((columnObj, columnId) => ({
            ...columnObj,
            [columns.find(column => column.accessorKey === columnId).realId]: newSizes[columnId],
        }), {});

        onChangeColumnResizing(columnWidthObj);
    }, [ table.getState().columnSizing ]);

    useDidMountEffect(() => {
        onChangeColumnSort(sorting);
    }, [ sorting ]);

    const paginationHandler = (funcType: string, data?: number) => {
        switch (funcType) {
            case 'gotoPage': {
                disableServerPagination
                    ? table.setPageIndex(data)
                    : getDataByFilter({ offset: data * filters.limit });
                break;
            }

            case 'previousPage': {
                disableServerPagination
                    ? table.previousPage()
                    : getDataByFilter({ offset: Number(filters.offset) - filters.limit });
                break;
            }

            case 'nextPage': {
                disableServerPagination
                    ? table.nextPage()
                    : getDataByFilter({ offset: Number(filters.offset) + filters.limit });
                break;
            }

            case 'setPageSize': {
                if(!disableServerPagination) {
                    const pageCount = Math.ceil(count / data);
                    const currentPage = Number(filters.offset) / filters.limit;
                    const usePage = currentPage > pageCount || pageCount < 2 ? pageCount : currentPage;

                    const currentOffset = pageCount < 2 ? 0 : usePage * data;
                    const useOffset = currentOffset > count ? 0 : currentOffset;

                    getDataByFilter({ limit: data, offset: isVirtual ? 0 : useOffset });
                } else {
                    table.setPageSize(Number(data));
                }

                contentSettings.saveTableLayout
                && saveSettings({ rowsPerPage: data });

                break;
            }
        }
    };

    return (
        <div className={ styles.tableChart }>
            <DndProvider backend={ HTML5Backend } context={ window }>
                <Table
                    getCenterTotalSize={ table.getCenterTotalSize }
                    getHeaderGroups={ table.getHeaderGroups }
                    getRowModel={ table.getRowModel }
                    wordwrapMode={ contentSettings.wordwrapMode }
                    table={ table }
                    isEditable={ isEditable }
                    disableReorder={ disableReorder }
                    onChangeColumnOrder={ onChangeColumnOrder }
                />
            </DndProvider>

            <button className={ styles.copyButton } onClick={ onCopyTable }>
                <FontAwesomeIcon icon={ 'copy' } />
            </button>

            <Pagination
                canPreviousPage={ table.getCanPreviousPage() }
                pageIndex={ table.getState().pagination.pageIndex }
                canNextPage={ table.getCanNextPage() }
                pageCount={ table.getPageCount() }
                pageSize={ table.getState().pagination.pageSize }
                filters={ filters }
                count={ count }
                isVirtual={ isVirtual }
                gotoPage={ (data: any) => paginationHandler('gotoPage', data) }
                previousPage={ () => paginationHandler('previousPage') }
                nextPage={ () => paginationHandler('nextPage') }
                setPageSize={ (data: any) => paginationHandler('setPageSize', data) }
            />

            <FooterInfo records={ records } lastHealthCheckTime={ lastHealthCheckTime } />
        </div>
    );
};

export const TableChart = ({ chartData, gadgetSize }: any) => {
    const tableChartData = useTableChart(chartData);

    return tableChartData?.chartData
        ? <RenderTable
            tableChartData={ tableChartData }
            gadget={ chartData.gadgetData }
            gadgetSize={ gadgetSize }
        />
        : null;
};
