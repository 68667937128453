import React from "react";
import { Button, ListGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

import { VocModal } from "/components";

export const SourceDetailsModal = ({
    show,
    toggle,
    sourceDetailsData,
}) => {
    const { t } = useTranslation();

    const getModalBody = () => (
        <ListGroup>
            { sourceDetailsData?.tableData }
        </ListGroup>
    );

    const getModalHeader = () => <>
        { `${ t('sourceId') }:` }
        &nbsp;
        <strong>{ sourceDetailsData?.uuidMongo }</strong>
    </>;

    const getModalFooter = () =>
        <>
            <Button
                onClick={ () => toggle() }
                outline
                color={ 'white' }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                <span>
                    { t('cancel') }
                </span>
            </Button>
        </>;

    return (
        <VocModal
            isOpen={ show }
            toggle={ () => toggle() }
            header={ getModalHeader() }
            footer={ getModalFooter() }
            size="md"
            modalClassName="modal-scrollable"
        >
            { getModalBody() }
        </VocModal>
    );
};
