import React, { memo, DragEvent, useEffect, useState } from 'react';
import { Fade, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import { Preloader, StickyBox } from '/components';
import { Title } from '/visual/scenes/Dashboard/components/Gadget/components';
import { HelperService } from '/services';
import { IDroppableList, ReducedGadgetType } from '/visual/scenes/Dashboard/models';
import { MIN_DROPZONE_WIDTH, DASHBOARDS_CONTROLS_HEIGHT } from '/visual/scenes/Dashboard/components/GadgetsGrid/constants';
import { HEADER_HEIGHT } from '/constants';
import { StickyBoxPosition } from '/components/StickyBox/models';

import styles from './DroppableList.module.scss';

const onScrollThrottle = HelperService.throttle((callback: () => void) => callback?.(), 300);

const DroppableListComponent = ({
    loading,
    pageContainerRef,
    isExpanded,
    show,
    width,
    rightOffset,
    gadgets,
    draggable,
    onEdit,
    onMoreInfo,
    onDelete,
    onToggleExpanded,
    setHidePlaceholders,
}: IDroppableList) => {
    const controls = [
        {
            icon: 'cog',
            onClick: onEdit,
        },
        {
            icon: 'info-circle',
            onClick: onMoreInfo,
        },
        {
            icon: 'trash',
            onClick: onDelete,
        },
    ];
    const [ topOffset, setTopOffset ] = useState(0);
    const [ isStuck, setIsStuck ] = useState(false);
    const topStuckOffset = rightOffset.freeOffset >= (MIN_DROPZONE_WIDTH(isExpanded))
        ? HEADER_HEIGHT
        : HEADER_HEIGHT + DASHBOARDS_CONTROLS_HEIGHT;

    const onStuckChange = (value: boolean) => setIsStuck(value);

    const onScroll = () => {
        if (!isStuck && pageContainerRef?.current) {
            setTopOffset(pageContainerRef.current.getBoundingClientRect().top || 0);
        }
    };

    useEffect(() => {
        onScroll();
        document.addEventListener(
            'scroll',
            () => onScrollThrottle(onScroll),
        );

        return () => {
            document.removeEventListener(
                'scroll',
                () => onScrollThrottle(onScroll),
            );
        };
    }, [ isStuck ]);

    const onDragStart = (event: DragEvent, gadget: ReducedGadgetType) => {
        event.dataTransfer.effectAllowed = 'move';
        // this is a hack for firefox, requires some kind of initialization
        // which we can do by adding this attribute
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        event.dataTransfer.setData('text/plain', gadget.id);
    };

    const ToggleButton = () => (
        <Button
            className={ styles.toggleBtn }
            color={ 'primary' }
            onClick={ onToggleExpanded }
        >
            <FontAwesomeIcon icon={ isExpanded ? 'angle-double-right' : 'angle-double-left' } />
        </Button>
    );

    return (
        <StickyBox
            position={ StickyBoxPosition.TOP }
            offset={{ size: topStuckOffset }}
            onChange={ onStuckChange }
        >
            <Fade in={ show }>
                <div
                    className={ cx(styles.droppableListWrapper, { [styles.inactive]: !show }) }
                    style={{
                        transform: `translate(100%) rotateY(${ show ? 0 : 90 }deg)`,
                        maxHeight: `calc(100vh - ${isStuck ? topStuckOffset : topOffset}px)`,
                        width,
                    }}
                >
                    <ToggleButton />
                    {
                        loading
                            ? <Preloader color={ '#ECF0F6' }/>
                            : <div className={ styles.droppableList }>
                                {
                                    gadgets?.map(((gadget: ReducedGadgetType) => (
                                        <div
                                            className={ cx('droppable-element', styles.droppableItem) }
                                            key={ gadget.id }
                                            unselectable='on'
                                            draggable={ draggable }
                                            onDragStart={ (event: DragEvent) => onDragStart(event, gadget) }
                                            onDragEnd={ () => setHidePlaceholders(false) }
                                        >
                                            <Title
                                                title={ gadget.title }
                                                id={ gadget.id }
                                                canEdit={ isExpanded && show }
                                                fontSize={ 14 }
                                                tooltipProps={{
                                                    tooltipPlacement: isExpanded ? 'top' : 'auto' ,
                                                    withWrapperObserver: true,
                                                }}
                                            />

                                            <div className={ cx(
                                                styles.controls,
                                                { [styles.visibleControls]: isExpanded && show },
                                            ) }>
                                                {
                                                    controls.map(control => (
                                                        <button
                                                            className={ cx(styles.controlsButton, {
                                                                [styles.activeListItem]: control.icon === 'info-circle'
                                                                && !!gadget.contentSettings?.gadgetDescription,
                                                            }) }
                                                            onClick={ () => control.onClick(gadget, 'save_zone') }
                                                            key={ control.icon }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={ control.icon as IconProp }
                                                                color={ '#979797' }
                                                            />
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )))
                                }
                            </div>
                    }

                    <ToggleButton />
                </div>
            </Fade>
        </StickyBox>
    );
};

export const DroppableList = memo(DroppableListComponent);
