import { useEffect, useRef, useState } from 'react';
import { HelperService } from '/services';

export const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    const [ dependenciesState, setDependenciesState ] = useState(deps);


    useEffect(() => {
        setDependenciesState(deps);

        if (didMount.current) {
            !HelperService.deepEqual(dependenciesState, deps)
            && func();
        } else {
            didMount.current = true;
        }
    }, deps);
};
