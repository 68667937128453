import {
    DashboardPageType, getGridConfigType, getPagesType, IPages,
    pageEmptySpaceType, PaginatedLayout, ICalculatePageWidthAndRightFreeSpace,
} from '/visual/scenes/Dashboard/models';
import { HelperService } from '/services';
import { PlaceholdersService } from '../';
import {
    DASHBOARDS_CONTROLS_HEIGHT,
    LEFT_FLUID_OFFSET,
    MIN_DROPZONE_WIDTH,
    PAGE_CONTROLS,
} from '/visual/scenes/Dashboard/components/GadgetsGrid/constants';
import { HEADER_HEIGHT } from '/constants';

export class DashboardGridService {
    static getGadgetSize() {
        return {
            // not maximum dimension for stretching
            maxSize: {
                maxW: 6,
                maxH: 12,
            },
            minSize: {
                minW: 3,
                minH: 8,
            },
        };
    }

    static gadgetMinDimensions = DashboardGridService.getGadgetSize().minSize;

    static PAGE_HEIGHT = DashboardGridService.getPageHeightByRows(
        DashboardGridService.gadgetMinDimensions.minH * 3,
    );

    static ZERO_COORDINATES = {
        h: 0,
        w: 0,
        x: 0,
        y: 0,
    }

    static pages: Array<DashboardPageType> = [];

    static getGridConfig(): getGridConfigType {
        return {
            rowHeight: 30,
            cols: 12,
            pageHeight: this.getPageHeight(),
        };
    }

    static getPageHeightByRows(rows: number) {
        const { rowHeight } = this.getGridConfig();
        const gutter = 10;

        return rowHeight * rows + (rows * gutter) + gutter;
    }

    static getPageHeight() {
        return this.PAGE_HEIGHT;
    }

    static getPageLayout() {
        const { cols } = this.getGridConfig();
        const rowsInPage = 24;

        return {
            x: cols,
            y: rowsInPage,
            cols,
        };
    }

    static generatePages(
        { gadgets, allPages }: getPagesType,
        gadgetClipboardSize: null | [number, number],
    ): Array<DashboardPageType> {
        if (!allPages?.length) {
            this.pages = [];
            return [];
        }

        const cleanPagesList = allPages.map((page: IPages) => ({
            gadgets: [],
            layout: [],
            page: page.id,
            dashboardId: page.dashboardId,
        }));

        const cleanList = gadgets.reduce((acc, item) => {
            return acc.map(el => {
                if(el.page === item.coordinates.page) {
                    const gadgets = [
                        ...el.gadgets,
                        item,
                    ];

                    return {
                        ...el,
                        gadgets: gadgets.map(gadget => ({
                            ...gadget,
                            ...gadget.type === 'static_area'
                                ? { minW: 1, minH: 2 }
                                : DashboardGridService.getGadgetSize().minSize,
                        })),
                        layout: [
                            ...el.layout,
                            {
                                ...item.coordinates,
                                i: item.id, // item.id === gadgetId
                                ...item.type !== 'static_area'
                                    ? DashboardGridService.getGadgetSize().minSize
                                    : { minW: 1, minH: 2 },
                            },
                        ],

                    };
                } else {
                    return el;
                }
            });
        }, cleanPagesList);

        const { x, y, cols } = DashboardGridService.getPageLayout();
        const pages = cleanList.map((page: any) => {
            const params = {
                layout: page.layout.length
                    ? page.layout
                    // create abstract gadget's layout, if page has no gadget for which placeholders will create
                    : this.getEmptyPageLayout(page.page),
            };

            return {
                ...page,
                placeholders: PlaceholdersService.getPageEmptyPlaceholders(params, { x, y, cols }, gadgetClipboardSize),
            };
        });

        this.pages = pages;

        return pages;
    }

    static getEmptyPageLayout(pageId: string) {
        return [{ ...this.ZERO_COORDINATES, i: '', page: pageId, static: true }];
    }

    static findAvailablePlaceholders(
        placeholders: pageEmptySpaceType,
        coordinates: Omit<PaginatedLayout, 'i' | 'page'>,
        isCreateNew: boolean,
    ) {
        const availableSpaces = Object.values(placeholders)
            .map(placeholder => {
                return isCreateNew
                    ? placeholder
                    : placeholder
                        .filter(layout => {
                            return layout.maxW >= coordinates.w
                            && layout.maxY >= coordinates.y
                            && (layout.maxY - layout.minY) >= coordinates.h - 1;
                        });
            });

        return availableSpaces.filter(spaces => spaces.length);
    }

    static getAffectedItems(
        placeholders: pageEmptySpaceType = {},
        layout: Array<PaginatedLayout> = [],
        movedItem: PaginatedLayout,
    ): Array<PaginatedLayout> {
        const items = layout.map(item => {
            const emptyPlaceholder = item.x <= movedItem.x && movedItem.y < item.y
                ? Object.entries(placeholders).filter(([ key ]: any) =>
                    this.findAvailablePlaceholders(
                        placeholders,
                        {
                            ...HelperService.pick(item, [ 'h', 'w' ]) as PaginatedLayout,
                            y: Number(key),
                        },
                        false,
                    ))[0]
                : [];

            const [ , value = [] ] = emptyPlaceholder;
            const placeholder = value[0];

            return {
                ...item,
                placeholder,
            };
        });

        const filtered = items.filter(item => {
            return item.placeholder && item.w <= item.placeholder.maxW;
        });

        return filtered.map(item => ({
            ...item,
            y: item.placeholder.minY,
        }));
    }

    static calculatePageWidthAndRightFreeSpace({
        dimensions,
        isEditable,
        isExpanded,
        isFluidContainer,
        handleSetSizes,
    }: ICalculatePageWidthAndRightFreeSpace) {
        if (!dimensions) return;

        if (!isEditable) {
            return handleSetSizes({
                pageWidth: dimensions.width,
                dropZoneWidth: 0,
                freeRightSpace: 0,
                diff: 0,
            });
        }

        const minDropZoneWidth = MIN_DROPZONE_WIDTH(isExpanded);

        const pageWidth = dimensions?.width;
        const screenWidth = document.documentElement.clientWidth;
        const freeRightSpace = ((screenWidth - (isFluidContainer ? LEFT_FLUID_OFFSET : 0) - pageWidth) / 2) - PAGE_CONTROLS;
        let newPageWidth, newDropZoneWidth, diff;

        if (freeRightSpace < minDropZoneWidth) {
            diff = minDropZoneWidth - freeRightSpace;

            newPageWidth = pageWidth - diff;
            newDropZoneWidth = freeRightSpace + diff;
        } else {
            diff = 0;
            newPageWidth = pageWidth;
            newDropZoneWidth = isExpanded ? freeRightSpace : minDropZoneWidth;
        }

        handleSetSizes({
            pageWidth: newPageWidth,
            dropZoneWidth: newDropZoneWidth,
            freeRightSpace,
            diff,
        });
    }

    static scrollElementIntoView(scrollToContainer: HTMLDivElement, flashAnimationClassName?: string) {
        const elementTopCoordinate = scrollToContainer ? scrollToContainer.getBoundingClientRect().top : 0;
        const ADDITION_TOP_OFFSET = 2; // as border width

        // smooth scrolling to the element's coordinates (under the header and dashboard's control panel)
        window.scrollTo({
            top: Math.abs(
                window.pageYOffset
                - HEADER_HEIGHT
                - DASHBOARDS_CONTROLS_HEIGHT
                - ADDITION_TOP_OFFSET
                + elementTopCoordinate,
            ),
            behavior: 'smooth',
        });

        // add animation for page wrapper
        if (flashAnimationClassName) {
            scrollToContainer.classList.add(flashAnimationClassName);

            // reset classList after animation (1s delay + twice for 500ms)
            setTimeout(() => {
                scrollToContainer.classList.remove(flashAnimationClassName);
            }, 2000);
        }
    }
}
