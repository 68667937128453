import React from 'react';
import cx from 'classnames';

import { IDeviceFrame } from './models';

import styles from './style.module.scss';

export const DeviceFrame = ({ device, children, className, disable }: IDeviceFrame) => {
    return (
        <div className={ disable ? className : cx(className, styles.device, styles[device] ) }>
            <div className={ styles.deviceFrame }>
                <div className={ cx(styles.deviceScreen, 'deviceFrameDeviceScreen') }>
                    <div className={ styles.chromeSearchBar }>
                        <div className={ styles.searchBar }>
                            sandsiv.com
                        </div>
                    </div>
                    {children}
                </div>
            </div>
            <div className={ styles.deviceStripe }></div>
            <div className={ styles.deviceHeader }></div>
            <div className={ styles.deviceSensors }></div>
            <div className={ styles.deviceBtns }></div>
            <div className={ styles.devicePower }></div>
            <div className={ styles.deviceHome }/>
        </div>
    );
};
