import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HelperService } from '/services/';

import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
} from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class TouchPointItemControl extends Component {
    render() {
        const { selectedTP, options, handleChangeTPInput, editTouchPoint } = this.props;
        const isUnassignedTouchpoint = selectedTP.id === 1;
        const projectValue = HelperService.getSelectedValue(options, String(selectedTP.project));

        if (isUnassignedTouchpoint) {
            return null;
        }

        return (
            <InputGroup className={ 'text-left move-to-project-dropdown' }>
                <InputGroupAddon addonType={ 'prepend' }>
                    <InputGroupText>
                        Move to Customer Journey
                    </InputGroupText>
                </InputGroupAddon>
                <Select
                    className={ 'move-to-project-select' }
                    value={ projectValue }
                    isClearable={ false }
                    backspaceRemovesValue={ false }
                    options={ options }
                    placeholder={ 'Move Touchpoint to Customer Journey' }
                    isSearchable={ false }
                    onChange={ handleChangeTPInput }
                />
                <InputGroupAddon addonType={ 'append' }>
                    <Button onClick={ editTouchPoint } color={ 'white' } outline>
                        <FontAwesomeIcon icon={ 'check' }/>
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        );
    }
}

TouchPointItemControl.propTypes = {
    selectedTP: PropTypes.object,
    options: PropTypes.array,
    handleChangeTPInput: PropTypes.func,
    editTouchPoint: PropTypes.func,
};
TouchPointItemControl.defaultProps = {};
