import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { VocModal } from '/components';
import { gadgetDescriptionSelector } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { setContentSettings } from '/visual/scenes/Dashboard/modules/Dashboard.modules';

import style from './style.module.scss';

interface IModalProps {
    show: boolean,
    toggle: () => void;
    modalState: any
}

export const GadgetDescriptionModal = ({
    show,
    toggle,
    modalState,
}: IModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ enableEdit, setEnableEdit ] = useState(false);
    const [ textAreaValue, setTextAreaValue ] = useState('');
    const contentSettings = useSelector(gadgetDescriptionSelector);

    const onEditDescription = () => {
        setEnableEdit(false);
        dispatch(setContentSettings(modalState.gadgetId, {
            ...contentSettings,
            gadgetDescription: textAreaValue,
        }));
    };

    const onChange = ({ target }) => setTextAreaValue(target.value);

    const onClosed = () => {
        setEnableEdit(false);
        setTextAreaValue('');
    };

    const onEditAction = () => {
        setEnableEdit(!enableEdit);
        setTextAreaValue(contentSettings.gadgetDescription);
    };

    useEffect(() => {
        contentSettings?.gadgetDescription && setTextAreaValue(contentSettings.gadgetDescription);
    }, [ contentSettings?.gadgetDescription ]);

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color={ 'white' }
                onClick={ toggle }
            >
                { t('close') }
            </Button>
            <Button
                color={ 'primary' }
                onClick={ onEditAction }
            >
                { t('edit') }
            </Button>
        </div>
    );

    const getEditModalFooter = () => (
        <div>
            <Button
                outline
                color={ 'white' }
                onClick={ onEditAction }
            >
                <FontAwesomeIcon icon={ 'times' } />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color={ 'primary' }
                onClick={ onEditDescription }
            >
                <FontAwesomeIcon icon={ 'check' } />
                &nbsp;
                { t('save') }
            </Button>
        </div>
    );

    return (
        <VocModal
            isOpen={ show }
            toggle={ toggle }
            modalClassName={ 'modal-scrollable' }
            header={ t('gadgetDescription') }
            footer={
                enableEdit
                    ? getEditModalFooter()
                    : getModalFooter()
            }
            size={ 'ms' }
            onClosed={ onClosed }
        >
            <p className={ style.title }>
                { t('field.description') }:
            </p>
            {
                !enableEdit
                && <p className={ style.modalText }>
                    {
                        textAreaValue || t('noDescriptionProvided')
                    }
                </p>
            }
            {
                enableEdit
                && <Input
                    id={ 'formMessage' }
                    type={ 'textarea' }
                    rows={ '4' }
                    className={ style.textarea }
                    value={ textAreaValue }
                    onChange={ onChange }
                />
            }
        </VocModal>
    );
};
