import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InputLoader = ({ label, isRequired, text, nesting, className, withLabelCol }) => {
    return (
        nesting
            ? <div className={ className } key={ 2 }>
                <div className={ 'form-group' }>
                    <div className={ 'row' }>
                        {
                            withLabelCol
                            && <div className={ 'select-control-label col-md-3' }>
                                <label className={ 'control-label' }>
                                    { label } { isRequired ? <span className='star'> *</span> : '' }
                                </label>
                            </div>
                        }
                        <div className={ `col-md-${withLabelCol ? 9 : 12}` }>
                            <p style={{ margin: '8px' }}>
                                <FontAwesomeIcon icon={ 'circle-notch' } spin />
                                <span> { text }</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            : <>
                <FontAwesomeIcon icon={ 'circle-notch' } spin />
                <span> { text }</span>
            </>
    );
};

InputLoader.defaultProps = {
    nesting: true,
    className: 'dropdown-wrapper',
    withLabelCol: true,
    isRequired: false,
    text: 'Loading...',
};
