import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import { rightsSurveySelector, themeSettingsSelector } from '../../modules/survey.selectors';
import {
    cleanup,
    getSurveySettings,
    putSurveySettings,
    attachThemeToSurvey,
    manegeSurveyTheme,
    updateSurveyTheme,
} from './modules/SurveySettings.modules';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';
import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';

import {
    getActiveChannel,
    getSurveyGroup,
    surveyFormSettingsSelector,
    getSurveySettingsLoading,
    initialFormValuesSelector,
} from './modules/SurveySettings.selectors';

import { VocCleaupHOC, Preloader } from '/components';
import { SettingsForm } from "/feedback/components";
import { PageRequests } from '/services';

const mapStateToProps = state => ({
    activeChannel: getActiveChannel(state),
    surveyGroup: getSurveyGroup(state),
    surveySettings: surveyFormSettingsSelector(state),
    loading: getSurveySettingsLoading(state),
    initial: initialFormValuesSelector(state),
    themeSettings: themeSettingsSelector(state),
    rightsSurvey: rightsSurveySelector(state),
    assets: assetsSelector(state),
});

const mapDispatchToProps = {
    getSurveySettings,
    putSurveySettings,
    attachThemeToSurvey,
    manegeSurveyTheme,
    updateSurveyTheme,
    getAssets,
    uploadAsset,
    cleanup,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class SurveySettings extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        if (this.hasCreatedChannel()) {
            this.getSurveySettings();
        }
    }

    componentDidUpdate(prevProps) {
        const { activeChannel: { channel } } = this.props;

        if (channel !== prevProps.activeChannel.channel) {
            this.getSurveySettings();
        }
    }

    getSurveySettings() {
        this.pageRequests.cleanup();

        const {
            activeChannel: { channel, id: idChannel, unit },
            surveyGroup,
            getSurveySettings,
        } = this.props;

        const requests = getSurveySettings({ surveyGroup, channel, unit, idChannel });

        requests.forEach(request => this.pageRequests.addRequest(request));
    }

    @autobind
    hasCreatedChannel() {
        const { activeChannel: { channel }, surveyGroup } = this.props;

        return channel && surveyGroup;
    }

    @autobind
    putSurveySettings(body) {
        const {
            activeChannel: { channel },
            surveyGroup: id,
            putSurveySettings,
        } = this.props;

        const model = {
            id,
            body,
            channel,
        };

        return channel === 'DIGI'
            ? this.putSurveySettingsForDigi(model)
            : putSurveySettings(model);
    }

    @autobind
    async putSurveySettingsForDigi(model) {
        const {
            activeChannel: { id: idChannel },
            surveySettings: { initialValues },
            attachThemeToSurvey,
            themeSettings: { survey_theme },
            manegeSurveyTheme,
        } = this.props;

        const { digi_theme: surveyTheme } = initialValues;
        const { digi_theme: newSurveyTheme } = model.body;

        delete model.body.digi_theme;

        const idTheme = newSurveyTheme ? newSurveyTheme : surveyTheme.channelValue;

        if (survey_theme) {
            return manegeSurveyTheme(survey_theme, model, idChannel, idTheme);
        } else {
            try {
                await attachThemeToSurvey({
                    survey: idChannel,
                    digi_theme: idTheme,
                }).promise;

                return manegeSurveyTheme(survey_theme, model, idChannel, idTheme);
            } catch (e) {
                console.error(e);
            }
        }
    }

    getSettingsBody() {
        const {
            surveySettings: {
                loading,
                sending,
            },
            initial: {
                fieldsMap,
                initialValues,
            },
            rightsSurvey,
            getAssets,
            uploadAsset,
            assets,
        } = this.props;

        return (
            loading
                ? <Preloader/>
                : <SettingsForm
                    putSettings={ this.putSurveySettings }
                    fieldsMap={ fieldsMap }
                    initialValues={ initialValues }
                    sendingSettings={ sending }
                    form={ 'SurveySettingsForm' }
                    permission={ rightsSurvey }
                    uploadAsset={ uploadAsset }
                    getAssets={ getAssets }
                    assets={ assets }
                />
        );
    }

    render() {
        return (
            <DocumentTitle title="Feedback :: Survey Setting">
                <>
                    {
                        this.hasCreatedChannel()
                            ? this.getSettingsBody()
                            : <Alert color={ 'info' } className={ 'text-center' }>
                                Please activate or select an activated channel first
                            </Alert>
                    }
                </>
            </DocumentTitle>
        );
    }
}
