import { CALL_API, FEEDBACK } from 'store/middleware/api';

const EXPORT_QUEUE_REQUEST = 'EXPORT_QUEUE_REQUEST';
const EXPORT_QUEUE_SUCCESS = 'EXPORT_QUEUE_SUCCESS';
const EXPORT_QUEUE_ERROR = 'EXPORT_QUEUE_ERROR';

const ADD_QUEUE_REQUEST = 'ADD_QUEUE_REQUEST';
const ADD_QUEUE_SUCCESS = 'ADD_QUEUE_SUCCESS';
const ADD_QUEUE_ERROR = 'ADD_QUEUE_ERROR';

const ADD_NEW_QUEUE_REQUEST = 'ADD_NEW_QUEUE_REQUEST';
const ADD_NEW_QUEUE_SUCCESS = 'ADD_NEW_QUEUE_SUCCESS';
const ADD_NEW_QUEUE_ERROR = 'ADD_NEW_QUEUE_ERROR';

const NEW_EXPORT_QUEUE_REQUEST = 'NEW_EXPORT_QUEUE_REQUEST';
const NEW_EXPORT_QUEUE_SUCCESS = 'NEW_EXPORT_QUEUE_SUCCESS';
const NEW_EXPORT_QUEUE_ERROR = 'NEW_EXPORT_QUEUE_ERROR';

const EXPORT_QUEUE_CLEANUP = 'EXPORT_QUEUE_CLEANUP';
const EXPORT_QUEUE_CLEANUP_PAGINATION = 'EXPORT_QUEUE_CLEANUP_PAGINATION';

const EXPORT_QUEUE_FILTERS_CHANGED = 'EXPORT_QUEUE_FILTERS_CHANGED';

const DOWNLOAD_EXPORT_FILE_REQUEST = 'DOWNLOAD_EXPORT_FILE_REQUEST';
const DOWNLOAD_EXPORT_FILE_SUCCESS = 'DOWNLOAD_EXPORT_FILE_SUCCESS';
const DOWNLOAD_EXPORT_FILE_ERROR = 'DOWNLOAD_EXPORT_FILE_ERROR';

function getInitialState() {
    return {
        loading: false,
        filters: {
            page: 1,
            limit: 10,
            sort_field: '',
            sort_order: '',
            search: '',
        },
        results: [],
        count: 0,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case EXPORT_QUEUE_REQUEST:
        case NEW_EXPORT_QUEUE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EXPORT_QUEUE_SUCCESS:
        case NEW_EXPORT_QUEUE_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.response,
            };

        case EXPORT_QUEUE_ERROR:
        case NEW_EXPORT_QUEUE_ERROR:
            return {
                ...state,
                loading: false,
            };

        case EXPORT_QUEUE_FILTERS_CHANGED:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };

        //todo remove after delete old exportersv
        case EXPORT_QUEUE_CLEANUP_PAGINATION:
            return {
                ...getInitialState(),
                filters: {
                    ...getInitialState().filters,
                    sort_field: state.filters.sort_field,
                    sort_order: state.filters.sort_order,
                    search: state.filters.search,
                },
            };

        case EXPORT_QUEUE_CLEANUP:
            return getInitialState();

        default:
            return state;
    }
}

export function getExportQueueList(body) {
    return {
        [CALL_API]: {
            endpoint: `/export/expand-basic/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ EXPORT_QUEUE_REQUEST, EXPORT_QUEUE_SUCCESS, EXPORT_QUEUE_ERROR ],
            body: {
                ...body,
            },
            apiType: FEEDBACK,
        },
    };
}

export function addExportQueue(body) {
    return {
        [CALL_API]: {
            endpoint: `/export/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ ADD_QUEUE_REQUEST, ADD_QUEUE_SUCCESS, ADD_QUEUE_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export initialized',
                error: 'Something went wrong',
            },
        },
    };
}

export function addNewExportQueue(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-info/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ ADD_NEW_QUEUE_REQUEST, ADD_NEW_QUEUE_SUCCESS, ADD_NEW_QUEUE_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export initialized',
                error: 'Something went wrong',
            },
        },
    };
}

export function updateFilters(payload) {
    return {
        type: EXPORT_QUEUE_FILTERS_CHANGED,
        payload,
    };
}

export function cleanup() {
    return {
        type: EXPORT_QUEUE_CLEANUP,
    };
}

export function cleanupPagination() {
    return {
        type: EXPORT_QUEUE_CLEANUP_PAGINATION,
    };
}

export function getNewExportersList(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-info/`,
            method: 'GET',
            types: [ NEW_EXPORT_QUEUE_REQUEST, NEW_EXPORT_QUEUE_SUCCESS, NEW_EXPORT_QUEUE_ERROR ],
            body,
            apiType: FEEDBACK,
        },
    };
}

export function downloadExportFile({ id, type, isNewExporter, fileName }) {
    const endpointType = isNewExporter ? '/export-info' : '/export';

    return {
        [CALL_API]: {
            endpoint: `${endpointType}/${id}/download-${type}/`,
            method: 'GET',
            responseType: 'blob',
            fileName: fileName,
            apiType: FEEDBACK,
            types: [
                DOWNLOAD_EXPORT_FILE_REQUEST,
                DOWNLOAD_EXPORT_FILE_SUCCESS,
                DOWNLOAD_EXPORT_FILE_ERROR,
            ],
        },
    };
}
