import React from 'react';
import cx from 'classnames';
import { Input } from 'reactstrap';

import { TooltipWrapper } from '/components';

import styles from './style.module.scss';

export const Topic = ({
    isMine,
    topic,
    onSelect,
    onSelectForMove,
}) => {
    const getTopicStyles = topic => {
        return {
            [styles.activeSaved]: topic.isSaved,
            [styles.activeMined]: topic.isMined,
            [styles.active]: topic.isSelected,
        };
    };

    const onClickTopic = () => onSelect(topic);

    return (
        <div key={ topic.id } className={ cx(styles.topic, getTopicStyles(topic)) }>
            {
                !isMine
                && <Input
                    className={ styles.topicCheckbox }
                    type={ 'checkbox' }
                    checked={ topic.isSelectedForMove }
                    onChange={ e => {
                        e.stopPropagation();
                        onSelectForMove(topic);
                    } }
                />
            }
            <div className={ styles.topicBackground } onClick={ onClickTopic }/>
            <TooltipWrapper value={ topic.label }>
                <span onClick={ onClickTopic }>{topic.label}</span>
            </TooltipWrapper>
        </div>
    );
};
