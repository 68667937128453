import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type TPopoverListForwardRef = {
    checkAndHidePopover: () => void,
    isPopoverOpen: boolean
};

export type TAction = {
    title: string,
    icon?: IconProp,
    disabled?: boolean,
    type?: string,
    hidden?: boolean,
    className?: string,
    onClick?: () => void,
};

export enum EExportFormats {
    PDF = 'pdf',
    PPTX = 'pptx',
}

export enum EGadgetType {
    SUPER_CHART = 'super_chart',
    STATIC_AREA = 'static_area',
}
