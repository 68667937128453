import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormText, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './VocFormTextField.scss';

export const VocFormTextField = ({
    rows,
    input,
    disabled = false,
    className,
    type,
    onWheel,
    placeholder,
    required,
    withError,
    meta: {
        touched,
        error,
        warning,
    },
    onChange,
    editorCallback,
    showEditor,
    children,
}) => {
    const { value } = input;

    const handleChange = ({ target: { value } }) => (
        onChange !== undefined
            ? onChange(value)
            : input.onChange(value)
    );

    return (
        <div className={ `${ className } app-form-control__container ${ withError ? 'with-error' : '' }` }>
            <Input
                className={ `app-form-control` }
                id={ input.name }
                { ...input }
                { ...{
                    rows,
                    type,
                    placeholder,
                    required,
                    onWheel,
                } }
                value={ value }
                onChange={ handleChange }
                disabled={ disabled }
            />
            { children }
            {
                Boolean(touched && error && withError)
                    ? <FormText className="app-form-control__error">{ error }</FormText>
                    : false
            }
            {
                Boolean(touched && warning)
                    ? <FormText className="app-form-control__warning">{ warning }</FormText>
                    : false
            }
            {
                showEditor
                && <Button
                    color="link"
                    className={ 'edit-button__input' }
                    onClick={ () => editorCallback(input.value) }
                >
                    <FontAwesomeIcon icon='pencil-alt'/>
                </Button>
            }
        </div>
    );
};

VocFormTextField.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    withError: PropTypes.bool,
    onChange: PropTypes.func,
    editorCallback: PropTypes.func,
    showEditor: PropTypes.bool,
};

VocFormTextField.defaultProps = {
    withError: true,
    showEditor: false,
    type: 'text',
    className: '',
    onChange: undefined,
};
