import { createSelector } from 'reselect';
import { UsersRoleService } from '/services';

const _getSurveysList = state => state.VocStore.surveysList;

export const userInfoSelector = state => state.UserInfo.userInfo.isSupportUser;
export const surveysSelector = state => _getSurveysList(state).surveys;
export const filtersSelector = state => _getSurveysList(state).filters;
export const loadingSelector = state => _getSurveysList(state).loading;
export const surveysCountSelector = state => _getSurveysList(state).surveys.count;

export const surveysListTableDataSelector = createSelector([ surveysSelector, userInfoSelector ],
    (surveys, isSupportUser) => {
        return surveys.data.map(({
            updated,
            id,
            title,
            totalRowsCount,
            state,
            channel,
            languages,
            attributes,
            currentUserPermission,
            maxDate,
        }) => {
            const hasAttrText = attributes.some(attribute => [ 'TEXT', 'freetext', 'open' ].includes(attribute.type));
            const rights = UsersRoleService.getRightsForSurvey(isSupportUser, currentUserPermission);

            return {
                updated,
                maxDate,
                actionsData: {
                    uuidMongo: id,
                    attrByDate: attributes.filter(attribute => attribute.type === 'DATE'),
                    showReLemmatization: hasAttrText && isSupportUser,
                    rights,
                },
                shared: {
                    id,
                    isPermitViewInfoShare: rights.isPermitViewInfoShare,
                },
                title: title,
                totalRowsCount: totalRowsCount,
                channel: {
                    state: state,
                    channel: channel,
                },
                languages: languages.map(language => language.name).join(', '),
            };
        });
    });
