import { getI18n } from 'react-i18next';

import { CALL_API } from 'store/middleware/api';

export const GET_VIRTUAL_SOURCES_REQUEST = 'GET_VIRTUAL_SOURCES_REQUEST';
export const GET_VIRTUAL_SOURCES_SUCCESS = 'GET_VIRTUAL_SOURCES_SUCCESS';
export const GET_VIRTUAL_SOURCES_ERROR = 'GET_VIRTUAL_SOURCES_ERROR';

export const GET_VIRTUAL_INFO_REQUEST = 'GET_VIRTUAL_INFO_REQUEST';
export const GET_VIRTUAL_INFO_SUCCESS = 'GET_VIRTUAL_INFO_SUCCESS';
export const GET_VIRTUAL_INFO_ERROR = 'GET_VIRTUAL_INFO_ERROR';

export const UPDATE_VIRTUAL_DESCRIPTION_REQUEST = 'UPDATE_VIRTUAL_DESCRIPTION_REQUEST';
export const UPDATE_VIRTUAL_DESCRIPTION_SUCCESS = 'UPDATE_VIRTUAL_DESCRIPTION_SUCCESS';
export const UPDATE_VIRTUAL_DESCRIPTION_ERROR = 'UPDATE_VIRTUAL_DESCRIPTION_ERROR';

export const GET_VIRTUAL_RELATIONS_REQUEST = 'GET_VIRTUAL_RELATIONS_REQUEST';
export const GET_VIRTUAL_RELATIONS_SUCCESS = 'GET_VIRTUAL_RELATIONS_SUCCESS';
export const GET_VIRTUAL_RELATIONS_ERROR = 'GET_VIRTUAL_RELATIONS_ERROR';

export const DELETE_VIRTUAL_SOURCE_REQUEST = 'DELETE_VIRTUAL_SOURCE_REQUEST';
export const DELETE_VIRTUAL_SOURCE_SUCCESS = 'DELETE_VIRTUAL_SOURCE_REQUEST';
export const DELETE_VIRTUAL_SOURCE_ERROR = 'DELETE_VIRTUAL_SOURCE_REQUEST';

export const COPY_VIRTUAL_SOURCE_REQUEST = 'COPY_VIRTUAL_SOURCE_REQUEST';
export const COPY_VIRTUAL_SOURCE_SUCCESS = 'COPY_VIRTUAL_SOURCE_SUCCESS';
export const COPY_VIRTUAL_SOURCE_ERROR = 'COPY_VIRTUAL_SOURCE_ERROR';

export const VIRTUAL_SOURCES_FILTERS_CHANGED = 'VIRTUAL_SOURCES_FILTERS_CHANGED';
export const CLEANUP_VIRTUAL_INFO = 'CLEANUP_VIRTUAL_INFO';
export const CLEANUP = '/store/virtual-source/cleanup';

export const getInitialState = () => ({
    loading: false,
    copyLoading: false,
    descriptionSending: false,
    filters: {
        search: '',
        limit: 10,
        page: 1,
    },
    list: {
        data: [],
        count: 0,
    },
    virtualToDelete: {
        gadgets: [],
        virtualSources: [],
        segmentators: [],
        queries: [],
    },
    virtualInfo: {
        loading: false,
        data: null,
        activeSource: null,
    },
});

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_VIRTUAL_SOURCES_REQUEST:
        case GET_VIRTUAL_RELATIONS_REQUEST:
        case DELETE_VIRTUAL_SOURCE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_VIRTUAL_SOURCES_SUCCESS: {
            return {
                ...state,
                list: {
                    ...action.response,
                },
                loading: false,
            };
        }
        case GET_VIRTUAL_SOURCES_ERROR:
        case GET_VIRTUAL_RELATIONS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case GET_VIRTUAL_RELATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                virtualToDelete: {
                    ...action.response,
                },
            };
        }
        case VIRTUAL_SOURCES_FILTERS_CHANGED: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }
        case COPY_VIRTUAL_SOURCE_REQUEST: {
            return {
                ...state,
                copyLoading: true,
            };
        }

        case COPY_VIRTUAL_SOURCE_ERROR:
        case COPY_VIRTUAL_SOURCE_SUCCESS: {
            return {
                ...state,
                copyLoading: false,
            };
        }

        case GET_VIRTUAL_INFO_REQUEST: {
            return {
                ...state,
                virtualInfo: {
                    loading: true,
                    data: null,
                    activeSource: state.list.data.find(({ id }) => id === action.params),
                },
            };
        }

        case GET_VIRTUAL_INFO_SUCCESS: {
            return {
                ...state,
                virtualInfo: {
                    ...state.virtualInfo,
                    loading: false,
                    data: { ...action.response },
                },
            };
        }

        case GET_VIRTUAL_INFO_ERROR: {
            return {
                ...state,
                virtualInfo: {
                    ...state.virtualInfo,
                    loading: false,
                    data: null,
                },
            };
        }

        case UPDATE_VIRTUAL_DESCRIPTION_REQUEST: {
            return {
                ...state,
                descriptionSending: true,
            };
        }

        case UPDATE_VIRTUAL_DESCRIPTION_ERROR: {
            return {
                ...state,
                descriptionSending: false,
            };
        }

        case CLEANUP_VIRTUAL_INFO: {
            return {
                ...state,
                virtualInfo: {
                    ...getInitialState().virtualInfo,
                },
            };
        }

        case UPDATE_VIRTUAL_DESCRIPTION_SUCCESS: {
            const { id, description } = action.response;

            return {
                ...state,
                descriptionSending: false,
                list: {
                    ...state.list,
                    data: [
                        ...state.list.data.map(virtual => {
                            return id === virtual.id
                                ? {
                                    ...virtual,
                                    description,
                                }
                                : virtual;
                        }),
                    ],
                },
            };
        }

        case CLEANUP:
            return getInitialState();

        default:
            return state;
    }
}

export function getVirtualSources(body) {
    return {
        [CALL_API]: {
            endpoint: 'ENDPOINT_VOC_STORE_GET_VIRTUAL_SOURCES',
            method: 'GET',
            types: [
                GET_VIRTUAL_SOURCES_REQUEST,
                GET_VIRTUAL_SOURCES_SUCCESS,
                GET_VIRTUAL_SOURCES_ERROR,
            ],
            body,
        },
    };
}

export function getVirtualRelations(id) {
    return {
        [CALL_API]: {
            endpoint: ` /public_api/store/virtual-source/${ id }/relations`,
            method: 'GET',
            types: [
                GET_VIRTUAL_RELATIONS_REQUEST,
                GET_VIRTUAL_RELATIONS_SUCCESS,
                GET_VIRTUAL_RELATIONS_ERROR,
            ],
            logger: true,
            body: {},
        },
    };
}

export function deleteVirtualSource(id) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/virtual-source/${ id }`,
            types: [ DELETE_VIRTUAL_SOURCE_REQUEST, DELETE_VIRTUAL_SOURCE_SUCCESS, DELETE_VIRTUAL_SOURCE_ERROR ],
            method: 'DELETE',
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyDeleted', { entity: t('virtualSource') }),
                error: t('errorDuringVirtualSourceDeleteProcess'),
            },
            body: {},
        },
    };
}

export function copyVirtualSource(id, body) {
    const { t } = getI18n();

    return {
        [CALL_API]: {
            endpoint: `/public_api/store/virtual-source/${ id }/copy`,
            types: [ COPY_VIRTUAL_SOURCE_REQUEST, COPY_VIRTUAL_SOURCE_SUCCESS, COPY_VIRTUAL_SOURCE_ERROR ],
            method: 'POST',
            logger: true,
            loggerMessages: {
                success: t('messages.successfullyCopied', { entity: t('virtualSource') }),
                error: t('errorDuringVirtualSourceCopyProcess'),
            },
            body,
        },
    };
}

export function updateFilters(payload) {
    return {
        type: VIRTUAL_SOURCES_FILTERS_CHANGED,
        payload,
    };
}

export function getVirtualInfo(id) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/virtual-source/${ id }/relations`,
            types: [
                GET_VIRTUAL_INFO_REQUEST,
                GET_VIRTUAL_INFO_SUCCESS,
                GET_VIRTUAL_INFO_ERROR,
            ],
            method: 'GET',
            params: id,
            logger: true,
        },
    };
}

export function updateVirtualDescription(id, description) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/store/virtual-source/${ id }/description`,
            types: [
                UPDATE_VIRTUAL_DESCRIPTION_REQUEST,
                UPDATE_VIRTUAL_DESCRIPTION_SUCCESS,
                UPDATE_VIRTUAL_DESCRIPTION_ERROR,
            ],
            method: 'PUT',
            logger: true,
            body: { description },
        },
    };
}

export function cleanupVirtualInfo() {
    return {
        type: 'CLEANUP_VIRTUAL_INFO',
    };
}

export function cleanup() {
    return {
        type: CLEANUP,
    };
}
