import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './VocFormPasswordField.scss';
import { VocFormTextField } from '../VocFormTextField';

export const VocFormPasswordField = props => {
    const [ inputType, setInputType ] = useState('password');

    const toggleInputType = () => {
        const type = inputType === 'password' ? 'text' : 'password';

        setInputType(type);
    };

    return (
        <VocFormTextField
            { ...props }
            type={ inputType }
        >
            <div className='app-form-control_password-eye' onClick={ toggleInputType }>
                <FontAwesomeIcon icon={ inputType === 'password' ? 'eye' : 'eye-slash' }/>
            </div>
        </VocFormTextField>
    );
};
