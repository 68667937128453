import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { VocModal, CollapseSection, CopyTextButton } from "/components";
import { Button, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

import {
    socialUploadInfoSelector,
    importerInfoSelector,
    importerInfoQqlSelector,
    importerInfoLoadingSelector,
    syncLoadingSelector,
} from "../../modules/selectors";
import {
    followSocialSource,
    syncSocialSource,
    getImporterInfo,
    cleanupImporterInfoState,
} from "../../modules";
import { isSupportUserSelector } from "/modules/selectors";
import { SocialSourceService } from "../../../../services";

import './SocialSourceModal.scss';

export const SocialSourceModal = ({
    show,
    toggle,
    socialUploadId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isSupportUser = useSelector(isSupportUserSelector);
    const { prevIsFollowed, language, title, startFrom } = useSelector(socialUploadInfoSelector(socialUploadId));
    const { syncLoading, followLoading } = useSelector(syncLoadingSelector);
    const importerInfo = useSelector(importerInfoSelector(t));
    const importerQql = useSelector(importerInfoQqlSelector);
    const isImporterInfoLoading = useSelector(importerInfoLoadingSelector);

    const [ isFollowed, setIsFollowed ] = useState(true);

    const isDirty = prevIsFollowed !== isFollowed;

    useLayoutEffect(() => {
        setIsFollowed(prevIsFollowed);
    }, [ show ]);

    const onToggle = () => {
        dispatch(cleanupImporterInfoState());
        toggle();
    };

    const onSave = () => dispatch(followSocialSource(socialUploadId, SocialSourceService.formatStatusToServer(isFollowed))).promise.then(() => onToggle());

    const onSync = () => {
        dispatch(syncSocialSource(socialUploadId));
        !isDirty && onToggle();
    };


    const onCollapseOpen = () => !importerInfo.length && dispatch(getImporterInfo(socialUploadId));

    const onCollapseClose = () => {
        setTimeout(() => {
            const collapse = document.querySelector('.social-source-modal__collapse');
            const cardBody = collapse.childNodes[1].childNodes[0];

            cardBody.scrollTop = 0;
        }, 250);
    };

    const getModalBody = () => (
        <div className={ 'social-source-modal__wrapper' }>
            <div className={ 'social-source-modal__section' }>
                <div className={ 'social-source-modal__left-section-part' }>
                    <div className={ 'social-source-modal__input-wrapper' }>
                        <Input
                            id={ 'auto-sync' }
                            type='checkbox'
                            checked={ isFollowed }
                            onChange={ () => setIsFollowed(!isFollowed) }
                        />
                        <Label for={ 'auto-sync' }>
                            { t('syncWithSocialSourceAutomatically') }
                        </Label>
                    </div>

                    <Button
                        className={ 'social-source-modal__sync-btn' }
                        onClick={ onSync }
                        color={ 'primary' }
                        disabled={ syncLoading }
                    >
                        <span>
                            { t('syncWithSocialSourceNow') }
                        </span>
                    </Button>
                </div>

                <div className={ 'social-source-modal__right-section-part' }>
                    <div className={ 'social-source-modal__right-section-content' }>
                        <label>
                            { `${ t('field.language') }:` }
                        </label>
                        <span>{language}</span>
                    </div>
                    <div className={ 'social-source-modal__right-section-content' }>
                        <label>
                            { `${ t('startFrom') }:` }
                        </label>
                        <span className={ 'social-source-modal__right-section-content-date' }>{startFrom}</span>
                    </div>
                </div>
            </div>

            {
                isSupportUser
                && <CollapseSection
                    className={ 'social-source-modal__collapse' }
                    title={ t('details') }
                    onCollapseOpen={ onCollapseOpen }
                    onCollapseClose={ onCollapseClose }
                    isCollapseLoading={ isImporterInfoLoading }
                    loader={ true }
                >
                    <CopyTextButton
                        className='social-source-modal__copy-button'
                        textToCopyLabel={ t('query') }
                        textToCopy={ importerQql }
                    />

                    <ul className={ 'social-source-modal__info-list' }>
                        {importerInfo.map(({ title, value }, index) => (
                            <li className={ 'social-source-modal__info-item' } key={ index }>
                                <label>{title}</label>
                                <span>{value}</span>
                            </li>
                        ))}
                    </ul>
                </CollapseSection>
            }
        </div>
    );

    const getModalTitle = () => <>
        <span>{ `${ t('socialSource') }:` }</span>
        <span className={ 'social-source-modal__source-title' }>{title}</span>
    </>;

    const getModalFooter = () =>
        <>
            <Button
                onClick={ onToggle }
                outline
                color={ 'white' }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                <span>
                    { t('cancel') }
                </span>
            </Button>

            <Button
                disabled={ followLoading || !isDirty }
                onClick={ onSave }
                color={ 'primary' }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                <span>
                    { t('save') }
                </span>
            </Button>
        </>;

    return (
        <VocModal
            isOpen={ show }
            toggle={ onToggle }
            header={ getModalTitle() }
            footer={ getModalFooter() }
            size="lg"
        >
            { getModalBody() }
        </VocModal>
    );
};
