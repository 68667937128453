import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';

import { Popover, ListGroup, ListGroupItem, PopoverHeader, PopoverBody } from 'reactstrap';
import { Preloader } from '/components';
import './QuestionConfigurablePopover.scss';

export class QuestionConfigurablePopover extends Component {
    @autobind
    renderConfigurableValuesList() {
        const {
            configurable,
            answers,
        } = this.props.questionValue;

        return (
            <Fragment>
                {
                    configurable.map((el, index) => (
                        <ListGroupItem key={ index }>
                            { el.label } : <strong>{ el.value }</strong>
                        </ListGroupItem>
                    ))
                }

                { answers.length ? this.renderAnswersList() : '' }
            </Fragment>
        );
    }

    renderAnswersList() {
        return (
            <ListGroupItem>
                <p className={ 'popover-answers__label' }>answers</p>
                {
                    this.props.questionValue.answers.map((el, index) => (
                        <p key={ index } className={ 'popover-answers__item' }>
                            { el.is_open } <strong>{ el.name }</strong>
                        </p>
                    ),
                    )
                }
            </ListGroupItem>
        );
    }

    render() {
        const {
            show,
            toggle,
            target,
            questionValue: {
                loading,
            },
        } = this.props;

        return (
            <Popover
                id='configurable-popover'
                isOpen={ show }
                className={ 'question-value-popover' }
                placement="bottom"
                target={ target }
                toggle={ toggle }
                trigger="legacy"
            >
                <PopoverHeader>Configurable values</PopoverHeader>
                <PopoverBody>
                    <ListGroup>
                        {
                            loading ? <Preloader/> : this.renderConfigurableValuesList()
                        }
                    </ListGroup>
                </PopoverBody>
            </Popover>
        );
    }
}
