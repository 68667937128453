import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import SplitPane, { Pane } from 'split-pane-react';

import {
    QueryDetails, QueryFilterSection,
    QueryHeader, InsightBlock, QueryFooter,
} from './components';
import { SplitPaneSash, ThinSpinner, VocCleaupHOC } from '/components';
import { getSharedRights, saveMineRights } from '/modules';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { PageRequests, QueryExecuteService, HelperService } from '/services';
import {
    getAllQueriesList, getQueryById,
    getClassification, saveQueryById,
    getLemmata, getTexts, changeDirtyFilters,
    updatePaginationFilters, updateCorrelationLimit,
    getLemmataForCloud, updateShares, cleanup,
    createQuery, getClassifierList,
    exportQuery, changeLangSetData,
    getLemmataForCoOccurrence, getLemmataForCorrelation,
    getAllInsight, cleanupQueryGraphs,
    stopChartsLoading,
} from './modules/query.modules';
import { getTopics } from '/modules/topicSetModules/topicSet.modules';
import { autobind } from 'core-decorators';
import { getTags } from '/mine/modules/vocMine.modules';
import { sharesSelector, usersAndGroupsSelector } from '/modules/selectors';
import { getQueryDictionaries } from './modules/query.modules.js';
import {
    queriesSelector, queryInfoSelector,
    queryIdSelector, queryFiltersSelector,
    getClassificationSelector, textCountStatusSelector,
    getQueryText, getQueryLemmata, classifierListSelector,
    getQueryLemmataForCloud,
    getLoadingData, selectedDictionariesIdsSelector,
    dirtyFiltersSelector, getQueryLemmataLimitForCoOccurrence,
    getQueryLemmataLimitForCorrelation, getDirtyFormSelector,
} from './modules/query.selectors';

import './Query.scss';

const filterProperties = [ 'lemmaToInclude', 'lemmaToExclude', 'textToInclude', 'textToExclude' ];

const mapStateToProps = state => ({
    queries: queriesSelector(state),
    queryInfo: queryInfoSelector(state),
    queryId: queryIdSelector(state),
    filters: queryFiltersSelector(state),
    classification: getClassificationSelector(state),
    textCasesStatus: textCountStatusSelector(state),
    textsData: getQueryText(state),
    lemmataData: getQueryLemmata(state),
    lemmataForCloud: getQueryLemmataForCloud(state),
    lemmataLimitCoOccurence: getQueryLemmataLimitForCoOccurrence(state),
    lemmataLimitCorrelation: getQueryLemmataLimitForCorrelation(state),
    members: usersAndGroupsSelector(state),
    classifiers: classifierListSelector(state),
    shares: sharesSelector(state),
    isLoadingData: getLoadingData(state),
    selected: selectedDictionariesIdsSelector(state),
    dirtyFilters: dirtyFiltersSelector(state),
    isDirtyForm: getDirtyFormSelector(state),
});

const mapDispatchToProps = {
    getAllQueriesList, getQueryById,
    getClassification, saveQueryById,
    getLemmata, getTexts, changeDirtyFilters,
    updatePaginationFilters, updateCorrelationLimit,
    getLemmataForCloud, cleanup, showConfirm,
    saveMineRights, updateShares, createQuery,
    getClassifierList, exportQuery, getSharedRights,
    getQueryDictionaries, cleanupQueryGraphs,
    changeLangSetData, getLemmataForCoOccurrence,
    getLemmataForCorrelation, getTags,
    getTopics, getAllInsight, stopChartsLoading,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class Query extends Component {
    pageRequests = new PageRequests();
    state = {
        chartType: 'Cloud',
        sizes: [ 415, 665 ],
        useInVisual: false,
        ...this.getCollapseStates(),
    };

    componentDidMount() {
        this.getQueryInfo();
        window.addEventListener('resize', this.resizeWindowHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeWindowHandler);
    }

    @autobind
    resizeWindowHandler() {
        this.setState({
            sizes: [ 415, 665 ],
        });
    }

    @autobind
    getCollapseStates() {
        return this.props.filters.reduce((acc, { id }) => ({ ...acc, [id]: false }), {});
    }

    @autobind
    getQueryInfo(id) {
        const { queryId } = this.props.match.params;

        this.props.getAllQueriesList();
        this.props.getQueryDictionaries();
        this.props.getTags();

        this.props.getQueryById(id ? id : queryId)
            .then(() => this.requestTrigger({}, id));
    }

    @autobind
    async requestTrigger(body, id, model) { //body = params --- model = body { when change lang }
        const { stopChartsLoading } = this.props;

        // Abort requests
        await this.pageRequests.cleanupAsync();
        // Reset loaders, clean up sockets for charts that are in progress
        stopChartsLoading();

        const request = this.requestByChart(body, id, model);

        request.length
            ? request.forEach(request => this.pageRequests.addRequest(request))
            : this.pageRequests.addRequest(request);


        return request;
    }

    @autobind
    cleanup() {
        this.props.cleanup();
    }

    @autobind
    requestByChart({ chart = this.state.chartType, pagination }, id, body) {
        const {
            selected: { synonyms, stopWords },
            match: { params: { queryId } }, dirtyFilters, getLemmataForCoOccurrence, getLemmataForCorrelation, getTopics,
            getLemmataForCloud, lemmataForCloud: { filters: cloudPagination }, getAllInsight,
            getTexts, textsData: { filters: textsPagination }, queryInfo: { langSelected, sourceId, textColumns },
            getLemmata, lemmataData: { filters: lemmataPagination }, lemmataLimitCoOccurence, lemmataLimitCorrelation,
        } = this.props;

        const lemmataLimits = {
            'CoOccurrence': lemmataLimitCoOccurence,
            'Correlation': lemmataLimitCorrelation,
        };

        const getFilters = type => ({ // @todo винести
            synonyms,
            stopWords,
            filters: dirtyFilters,
            language: langSelected.value,
            ...(
                type === 'CoOccurrence' || type === 'Correlation'
                    ? { percentage: lemmataLimits[type] / 5 }
                    : {}
            ),
        });

        const requestBody = {
            body: body || getFilters(chart),
        };

        let request;

        switch (chart) {
            case 'Text' :
                request = getTexts(queryId, {
                    ...textsPagination,
                    ...pagination,
                    requestBody,
                });

                this.reRequestByPromise({
                    request, type: 'texts',
                    textsPagination, pagination,
                    queryId, requestBody,
                    action: getTexts,
                });

                return request;

            case 'Bar':
                request = getLemmata(queryId, {
                    ...lemmataPagination,
                    ...pagination,
                    requestBody,
                });

                this.reRequestByPromise({
                    request, type: 'lemmata',
                    textsPagination, pagination,
                    queryId, requestBody,
                    action: getLemmata,
                });

                return request;

            case 'Cloud':
                return getLemmataForCloud(queryId, {
                    ...cloudPagination,
                    ...pagination,
                    requestBody,
                });

            case 'CoOccurrence':
                return getLemmataForCoOccurrence(queryId, {
                    requestBody,
                });

            case 'Correlation':
                return getLemmataForCorrelation(queryId, {
                    requestBody,
                });

            case 'Data Labelling':
                return getTopics({
                    sourceId,
                    indexes: textColumns.map(columnData => columnData.index),
                    language: langSelected.value,
                    filters: dirtyFilters,
                    synonyms,
                    stopWords,
                    scopeType: 'mine',
                    queryId,
                });
            case 'insightNarrator': //@todo
                //percentage: same for charts 'CoOccurrence' && 'Correlation'
                return getAllInsight();

            default:
                return null;
        }
    }

    /*
    * this logic is for two graphs ( Text, Bar )
    * requested a page and sent new filters at the same time
    * find out the new last page and re-request
    * */
    @autobind
    reRequestByPromise({ request, type, textsPagination, pagination, queryId, requestBody, action }) {
        request.promise.then(response => {
            if(!response.id && !response[type]?.length) {
                const model = {
                    ...textsPagination,
                    ...pagination,
                    requestBody,
                };

                if(model.page !== 1) {
                    const lastPage = Math.ceil(response[`${type}Count`] / model.limit);

                    model.page = lastPage;
                    this.props.updatePaginationFilters({ page: lastPage, name: `${type}Data` });
                }

                this.pageRequests.addRequest(action(queryId, model));
            }
        });
    }

    @autobind
    getModelForRequest() {
        const {
            queryInfo: {
                langSelected,
                sourceId, textColumns,
            },
            filters,
        } = this.props;

        return {
            sourceId,
            language: langSelected.value,
            columnIds: textColumns.reduce((acc, el) => [ ...acc, el.id ], []),
            filters: QueryExecuteService.convertFiltersToServer(filters),
        };
    }

    @autobind
    saveFilters() {
        const {
            match: { params },
            saveQueryById,
            selected,
            queryInfo: { langSelected, context },
        } = this.props;
        const { useInVisual } = this.state;

        const { filters } = this.getModelForRequest();
        const { synonyms, stopWords } = selected;

        saveQueryById(
            {
                filters,
                useInVisual,
                synonyms,
                stopWords,
                language: langSelected.value,
                context,
            },
            params.queryId,
        );
    }

    @autobind
    changeLang(language) {
        const {
            match: { params },
            dirtyFilters,
            changeLangSetData,
        } = this.props;


        const payload = {
            language,
            filters: HelperService.filterObjectProperties(dirtyFilters, filterProperties),
            synonyms: [],
            stopWords: [],
        };

        changeLangSetData(payload);
        this.requestTrigger({}, params.queryId, payload);
    }

    @autobind
    saveNewQuery({ title, tag, useInVisual }) {
        const { synonyms, stopWords } = this.props.selected;

        const request = this.props.createQuery({
            ...this.getModelForRequest(),
            title,
            tag: tag ? tag : null,
            synonyms,
            stopWords,
            useInVisual,
        });

        request.promise.then(resp => {
            this.props.history.push(`/mine/query/${ resp.id }/execute`);
            this.getQueryInfo(resp.id);
        });

        return request.promise;
    }

    @autobind
    setUseQuery(value) {
        this.setState({ useInVisual: value });
    }

    @autobind
    onChangeChartType(value) {
        value === 'Data Labelling' && this.props.cleanupQueryGraphs();

        this.setState({
            chartType: value,
        });

        this.requestTrigger({ chart: value });
    }

    @autobind
    changeCollapseState(collapseName, isCollapseOpen = false) {
        this.setState(({
            [collapseName]: isCollapseOpen,
        }));
    }

    render() {
        const {
            history, queries, match: { params },
            getClassification, classification,
            textCasesStatus, changeDirtyFilters,
            queryInfo, filters, showConfirm,
            updatePaginationFilters, updateCorrelationLimit,
            textsData, lemmataData, lemmataForCloud,
            members, saveMineRights, updateShares,
            getClassifierList, classifiers, exportQuery,
            shares, getSharedRights, isLoadingData, isDirtyForm,
        } = this.props;
        const {
            chartType,
            sizes,
            useInVisual,
            ...collapseStates
        } = this.state;
        const isDataLabelling = chartType === 'Data Labelling';
        const showInsightBlock = [ 'Text', 'Bar', 'CoOccurrence', 'Correlation' ].includes(chartType);

        return (
            <DocumentTitle title={ 'Mine :: Execute query' }>
                {
                    !queryInfo.title && isLoadingData
                        ? <>
                            <ThinSpinner className={ 'thin-spinner__query' }/>
                            <div className={ 'row-wrapper__mine' }>
                                <SplitPane split='vertical' sizes={ sizes }>
                                    <Col md={ 5 } className={ 'Pane1' }/>
                                    <Col md={ 7 } className={ 'graph-box' }/>
                                </SplitPane>
                            </div>
                        </>
                        : <>
                            <div className={ 'row-wrapper__mine' }>
                                <SplitPane
                                    className={ 'querySplitPane' }
                                    split='vertical'
                                    sizes={ sizes }
                                    resizerSize={ 1 }
                                    onChange={ size => this.setState({ sizes: size }) }
                                    sashRender={ SplitPaneSash }
                                >
                                    <Pane className={ 'querySection' } minSize={ 415 }>
                                        {/* back navigation and change query */}
                                        <QueryHeader
                                            history={ history }
                                            queries={ queries }
                                            queryId={ params.queryId }
                                            disable={ queryInfo.isSavingQuery }
                                            getQueryInfo={ this.getQueryInfo }
                                            cleanup={ this.cleanup }
                                            showConfirm={ showConfirm }
                                            isDirtyForm={ isDirtyForm }
                                        />
                                        {
                                            queryInfo.title
                                                ? <QueryFilterSection
                                                    useQuery={ useInVisual }
                                                    collapseStates={ collapseStates }
                                                    filters={ filters }
                                                    queryInfo={ queryInfo }
                                                    textCases={ textCasesStatus }
                                                    classification={ classification }
                                                    changeCollapseState={ this.changeCollapseState }
                                                    changeDirtyFilters={ changeDirtyFilters }
                                                    changeLang={ this.changeLang }
                                                    setUseQuery={ this.setUseQuery }
                                                    getClassification={ getClassification }
                                                    showConfirm={ showConfirm }
                                                    isDataLabelling={ isDataLabelling }
                                                />
                                                : <ThinSpinner className={ 'thin-spinner__query-details' }/>
                                        }
                                        {/* Run and Save Query */}
                                        <QueryFooter
                                            loading={ isLoadingData }
                                            queryInfo={ queryInfo }
                                            useInVisual={ useInVisual }
                                            saveFilters={ this.saveFilters }
                                            saveNewQuery={ this.saveNewQuery }
                                            onRun={ this.requestTrigger }
                                        />

                                        {
                                            showInsightBlock
                                            && <InsightBlock chartType={ chartType } />
                                        }
                                    </Pane>

                                    <Pane minSize={ 710 } className={ 'graph-box' }>
                                        <QueryDetails
                                            changeCollapseState={ this.changeCollapseState }
                                            textsData={ textsData }
                                            lemmataData={ lemmataData }
                                            lemmataForCloud={ lemmataForCloud }
                                            filters={ filters }
                                            changeDirtyFilters={ changeDirtyFilters }
                                            updatePaginationFilters={ updatePaginationFilters }
                                            updateCorrelationLimit={ updateCorrelationLimit }
                                            requestByChart={ this.requestTrigger }
                                            chartType={ chartType }
                                            onChangeChartType={ this.onChangeChartType }
                                            language={ queryInfo.langSelected.value }
                                            members={ members }
                                            queryId={ params.queryId }
                                            queryList={ [ queryInfo ] }
                                            isSavingQuery={ queryInfo.isSavingQuery }
                                            saveMineRights={ saveMineRights }
                                            updateShares={ updateShares }
                                            getClassifierList={ getClassifierList }
                                            classifiers={ classifiers }
                                            exportQuery={ exportQuery }
                                            textCases={ textCasesStatus }
                                            shares={ shares }
                                            getSharedRights={ getSharedRights }
                                            isLoadingData={ isLoadingData }
                                            querySizes={ sizes }
                                            isDataLabelling={ isDataLabelling }
                                            pageRequests={ this.pageRequests }
                                        />
                                    </Pane>
                                </SplitPane>
                            </div>
                        </>
                }
            </DocumentTitle>
        );
    }
}
