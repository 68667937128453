import moment from 'moment';

function getToday() {
    return {
        start_time: moment(),
        end_time: moment(),
    };
}

function getYesterday() {
    return {
        start_time: moment().add(-1, 'days'),
        end_time: moment().add(-1, 'days'),
    };
}


function getThisWeek() {
    return {
        start_time: moment().startOf('isoWeek'),
        end_time: moment(),
    };
}

function getLastWeek() {
    return {
        start_time: moment().startOf('isoWeek').subtract(1, 'week'),
        end_time: moment().startOf('isoWeek').subtract(1, 'day'),
    };
}

function getThisMonth() {
    return {
        start_time: moment().startOf('month'),
        end_time: moment(),
    };
}

function getLastMonth() {
    return {
        start_time: moment().startOf('month').subtract(1, 'month'),
        end_time: moment().startOf('month').subtract(1, 'day'),
    };
}

function getThisYear() {
    return {
        start_time: moment().startOf('year'),
        end_time: moment(),
    };
}

function getLastYear() {
    return {
        start_time: moment().startOf('year').subtract(1, 'year'),
        end_time: moment().startOf('year').subtract(1, 'day'),
    };
}

function getAll() {
    return {
        start_time: null,
        end_time: null,
    };
}


export function DateRangesService (period) {
    const periodsRangeMap = {
        'today': getToday,
        'yesterday': getYesterday,
        'this_week': getThisWeek,
        'last_week': getLastWeek,
        'this_month': getThisMonth,
        'last_month': getLastMonth,
        'this_year': getThisYear,
        'last_year': getLastYear,
        'all': getAll,
    };

    return periodsRangeMap[period]();
}
