import { createSelector } from 'reselect';
import { HelperService } from '/services';
import { formValueSelector } from 'redux-form';
import { questionsListSelector } from './questionsList.selector';
import {
    expressionTypes,
    logicalOperation,
    metaDataToOptionSelector,
    activeSurveySelector,
    optionRandom100,
} from '/feedback/modules/VocFeedback.selectors';
import { languagesSelector } from "../../../modules/survey.selectors";

const formFieldsSelector = formValueSelector('QuestionFlowForm');
const languagesListSelector = state => state.VocFeedback.common.languages;

const getLanguagesList = createSelector([
    languagesSelector,
    languagesListSelector,
], ({ data }, { results }) => {
    return data.map(language => {
        const findFullLangData = results.find(fullLanguage => fullLanguage.iso_6391_code === language.language);

        return {
            label: findFullLangData.name,
            value: findFullLangData.id,
        };
    });
});

export const formValuesSelector = state => formFieldsSelector(
    state,
    'action_type',
    'comparison',
    'question_id',
    'value',
    'survey',
    'next_question',
    'id',
    'rule_priority',
    'logical_operation',
    'conditions',
    'enterpriseId',
    'digi_runner_command',
    'change_language_command',
    'question',
);

export const getInitialFlow = (question_id = null, isRootQuestion, activeChannel) => {
    return {
        action_type: activeChannel !== 'DIGI' || isRootQuestion
            ? actionTypes[3]
            : actionTypes[0],
        logical_operation: 'and',
        next_question: null,
        conditions: activeChannel === 'WEB'
            ? [
                {
                    condition_type: 'answer',
                    question_id: isRootQuestion ? question_id : null,
                    expression: null,
                    matching_value: null,
                },
            ]
            : [],
    };
};

const type = {
    SKIP: 1,
    GoTo: 2,
};

const actionTypes = [
    {
        label: 'Skip this question',
        text: 'Skip',
        value: 1,
    },
    {
        label: 'Terminate survey',
        text: 'Terminate',
        value: 3,
    },
    {
        label: 'End survey',
        text: 'End',
        value: 4,
    },
    {
        label: 'Go to',
        text: 'GoTo',
        value: 2,
    },
    {
        label: 'Command',
        text: 'digi_runner_command',
        value: 5,
    },
    {
        label: 'Change language',
        text: 'change_language_command',
        value: 6,
    },
    {
        label: 'Show this question',
        text: 'show_this_question',
        value: 7,
    },
];

const optionForCommand = [
    { value: 'close_survey_screen', label: 'close survey screen' },
    { value: 'close_survey_screen_and_terminate', label: 'close survey screen and terminate' },
    { value: 'open_survey_in_new_tab', label: 'open survey in new tab' },
    { value: 'hide_survey_to_button', label: 'hide to button' },
];

const additionalOptions = [
    {
        ...optionRandom100,
        question_type: 'additional:random_100',
    },
];

export const getEnterpriseId = state => state.UserInfo.userInfo.enterpriseId;
export const questionsSelector = state => state.VocFeedback.survey.questions;
export const activeChannelSelector = state => state.VocFeedback.survey.surveyCommon.activeChannel;

const idTreeSelector = createSelector([ questionsListSelector ], ({ list }) => {
    return HelperService.getIdFromTree(list);
});

const getExcludeIdQuestions = ({ root, children }, questionId, type) => {
    let idQuestions;

    if(type === 'condition') {
        idQuestions = root !== questionId ? [ ...children, root ] : [];
    } else {
        idQuestions = [ ...children ];
        root !== questionId && idQuestions.push(root);
    }

    return idQuestions;
};

const filterByExcludeId = (flatList, isDigi, excludeIdList) => {
    return flatList.filter(question => {
        const excludeForDigi = isDigi
            ? ( excludeIdList && !excludeIdList.includes(question.id) )
            : true;

        return question.nested_level && excludeForDigi;
    });
};

const convertQuestionToOptions = questions => {
    return questions.map(question => ({
        label: (question.title ? question.title : 'Empty title'),
        value: question.id,
        question_type: question.question_type.name,
        condition_type: 'answer',
        nested_level: question.nested_level,
    }));
};

const questionsToOptionSelector = createSelector(
    [ questionsSelector, idTreeSelector, activeChannelSelector ],
    ({ list: flatList, questionId }, idTree, activeChannel) => {
        let IdQuestions = {};
        let excludeIdForCondition;
        let excludeIdForNextQuestion;
        const isDigi = activeChannel === 'DIGI';

        if (questionId && isDigi) {
            IdQuestions = idTree.find(({ root, children }) => root === questionId || children.includes(questionId));

            /*
             * exclude Id Questions List
             * For Condition
             * */
            excludeIdForCondition = IdQuestions && getExcludeIdQuestions(IdQuestions, questionId, 'condition');

            /*
             * exclude Id Questions List
             * For Next Question
             * */
            excludeIdForNextQuestion = IdQuestions && getExcludeIdQuestions(IdQuestions, questionId, '');
        }

        const questionsForCondition = filterByExcludeId(flatList, isDigi, excludeIdForCondition);
        const questionsForNextQuestion = filterByExcludeId(flatList, isDigi, excludeIdForNextQuestion);

        return {
            questionsForCondition: convertQuestionToOptions(questionsForCondition),
            questionsForNextQuestion: convertQuestionToOptions(questionsForNextQuestion),
        };
    });

export const classifierListSelector = state => state.VocFeedback.common.classifier.results;
const classifierToOptionSelector = createSelector(
    [ classifierListSelector ],
    classifiers => {
        return classifiers.map(({ title, classifierId }) => ({
            label: title,
            value: classifierId,
            condition_type: 'classification',
        }));
    });

const getOptionsClassifierQuestions = createSelector(
    [ questionsSelector, classifierListSelector ],
    ({ list }, classifiers) => ({
        ...list.reduce((acc, { selects, id }) => ({
            ...acc,
            [id]: HelperService.mapObjToSelect(selects, null, null, val => val === 'dont_know' ? "Don't know" : val),
        }), {}),
        ...classifiers.reduce((acc, { categories, classifierId }) => ({
            ...acc,
            [classifierId]: HelperService.mapObjToSelect(categories, 'categoryId', 'title'),
        }), {}),
    }));

export const questionFlowSelector = createSelector(
    [
        classifierToOptionSelector,
        getOptionsClassifierQuestions,
        questionsToOptionSelector,
        metaDataToOptionSelector,
        activeChannelSelector,
        getLanguagesList,
    ],
    (
        classifierList,
        questionsSelects,
        {
            questionsForCondition,
            questionsForNextQuestion,
        },
        metaDataList,
        activeChannel,
        optionForChangeLanguage,
    ) => {
        const expression = [
            {
                label: 'Expression',
                options: [
                    ...expressionTypes,
                ],
            },
            {
                label: 'Classifier',
                options: [
                    ...classifierList,
                ],
            },
        ];

        const question = [
            {
                label: 'Answer',
                options: [
                    ...questionsForCondition,
                ],
            },
            {
                label: 'MetaData',
                options: [
                    ...metaDataList,
                ],
            },
        ];

        activeChannel !== 'WEB' && question.unshift(
            {
                label: 'Additional',
                options: additionalOptions,
            },
        );

        return {
            actionTypes,
            expression,
            question,
            questionForNext: [ ...questionsForNextQuestion ],
            optionForCommand,
            optionForChangeLanguage,
            logicalOperation,
            questionsSelects: {
                ...questionsSelects,
            },
        };
    });

const getMatchingValue = (arr, value) => arr.find(el => el.value === value);

const getActionType = (isRoot, action_type, activeChannel) => {
    let actionType = {};

    if (isRoot || activeChannel === 'DIGI') {
        actionType = actionTypes.find(action => action.value === action_type.id);
    } else if (action_type.id === type.SKIP) {
        actionType = actionTypes[0];
    }

    return actionType;
};

const mapQuestionFlows = (flows, enterpriseId, objOptionsForFlow, isRoot, activeChannel, optionForChangeLanguage) => {
    return flows.length ? flows.sort((a, b) => {
        if (a.rule_priority > b.rule_priority) return 1;
        return a.rule_priority < b.rule_priority ? -1 : 0;
    }).map(({
        logical_operation,
        digi_runner_command,
        id,
        survey,
        question,
        rule_priority,
        next_question,
        next_language,
        action_type,
        conditions,
        command,
    }) => {
        return {
            logical_operation,
            digi_runner_command,
            change_language_command: next_language,
            enterpriseId,
            id,
            survey,
            question,
            rule_priority,
            next_question,
            command,
            action_type: getActionType(isRoot, action_type, activeChannel),
            corrupted: isCorrupted(
                conditions,
                objOptionsForFlow,
                action_type,
                next_question,
                isRoot,
                activeChannel,
                next_language,
                optionForChangeLanguage,
            ),
            conditions: conditions.map(({ condition_type, value }) => {
                const matchingValue = objOptionsForFlow.questionsSelects[value.classifier_id]
                    || value.question_id && objOptionsForFlow.questionsSelects[value.question_id];
                const idQuestionField = value.question_id || value.metadata_name || optionRandom100.condition_type;

                const matchingValueForQuestion = matchingValue && matchingValue.length && value.matching_value !== null
                    ? getMatchingValue(matchingValue, value.matching_value)
                    : value.matching_value;
                const matchingValueForClassifier = value.classifier_id && getMatchingValue(matchingValue, value.category_id);

                const question_id = () => {
                    const data = [
                        ...objOptionsForFlow.questionsOption,
                        ...objOptionsForFlow.metadataOption,
                        ...objOptionsForFlow.additionalOptions,
                    ].find(option => option.value === idQuestionField);

                    if (data !== undefined) {
                        return data;
                    }

                    //check metadata is defined
                    const metaDataValue = value.metadata_name ? value.metadata_name : "";

                    //case for creation custom metadata
                    return {
                        condition_type: 'metadata',
                        label: metaDataValue,
                        value: metaDataValue,
                        question_type: 'metadata',
                    };
                };

                return {
                    condition_type,
                    expression: [ ...objOptionsForFlow.classifierList, ...expressionTypes ].find(item => item.value === (value.expression || value.classifier_id)),
                    matching_value: matchingValueForClassifier || matchingValueForQuestion,
                    question_id: question_id(),
                };
            }),
        };
    }) : [];
};

/*
 * this for flow when click on icon flow
 * */
const isCorrupted = (
    conditions,
    objOptionsForFlow,
    action_type,
    next_question,
    isRoot,
    activeChannel,
    next_language,
    optionForChangeLanguage,
) => {
    const checkPresenceOptions = conditions.some(({ value }) => value.question_id
        && !Boolean(objOptionsForFlow.questionsOption.find(question => question.value === value.question_id)));
    const checkNextQuestion = !Boolean(objOptionsForFlow.questionsOption.find(question => question.value === next_question));
    const checkMatchingValue = conditions.some(({ value }) => {
        const flowTargetQuestion = objOptionsForFlow && objOptionsForFlow.questionsSelects[value.question_id];
        const emptyNotEmptyCheck = ![ 'is_empty', 'is_not_empty' ].includes(value.expression);

        return emptyNotEmptyCheck
            && flowTargetQuestion
            && flowTargetQuestion.length
            && !flowTargetQuestion.find(el => el.value === value.matching_value);
    });

    const checkActionTypeForChild = activeChannel === "DIGI"
        ? !isRoot && action_type.id !== type.SKIP
        : false;

    const checkIfLanguageNotExist = next_language !== null && !optionForChangeLanguage.find(language => language.value === next_language);

    return checkPresenceOptions
        || (action_type.id === type.GoTo && checkNextQuestion)
        || checkMatchingValue
        || checkActionTypeForChild
        || checkIfLanguageNotExist;
};

export const getFlowList = createSelector(
    [
        activeChannelSelector,
        questionsSelector,
        questionsToOptionSelector,
        metaDataToOptionSelector,
        classifierToOptionSelector,
        getOptionsClassifierQuestions,
        getEnterpriseId,
        getLanguagesList,
    ],
    (
        activeChannel,
        { questionId, list },
        { questionsForCondition: questionsOption },
        metadataOption,
        classifierList,
        questionsSelects,
        enterpriseId,
        optionForChangeLanguage,
    ) => {
        const question = list.find(question => question.id === questionId);
        const isRoot = question && question.nested_level === 1;

        return question && mapQuestionFlows(
            question.question_flows,
            enterpriseId,
            {
                questionsOption,
                metadataOption,
                classifierList,
                questionsSelects,
                additionalOptions,
            },
            isRoot,
            activeChannel,
            optionForChangeLanguage,
        );
    });

export const getStatusFlow = createSelector(
    [ questionsSelector ],
    ({ flowLoading, flowSubmitted }) => ({
        flowLoading,
        flowSubmitted,
    }));

export const currentFlowQuestion = createSelector(
    [ questionsSelector ],
    ({ questionId, list }) => {
        const question = list.find(question => question.id === questionId);

        return question ? {
            label: (question.title ? question.title : 'Empty title'),
            value: question.id,
            question_type: question.question_type.name,
            condition_type: 'answer',
        } : null;
    });

export const getFlowItem = createSelector(
    [ getFlowList, questionsSelector, getEnterpriseId, currentFlowQuestion, activeChannelSelector ],
    (flows, { flowId, list, questionId }, enterpriseId, question_id, activeChannel) => {
        const isRootQuestion = list.some(question => question.id === questionId && question.nested_level === 1);

        return flowId
            ? flows.find(flow => flow.id === flowId)
            : {
                ...getInitialFlow(question_id, isRootQuestion, activeChannel),
                enterpriseId,
            };
    },
);

const getExpression = (question_id, activeSurvey, expression) => {
    const isRandom100 = question_id && question_id.question_type === 'additional:random_100';
    const isFullExpression = question_id
        && [ 'Open', 'metadata' ].includes(question_id.question_type)
        && [ 'DIGI', 'IVR' ].includes(activeSurvey.channel);

    const filters = [
        'contains',
        ...isRandom100 ? [ 'is empty', 'is not empty' ] : '',
    ];

    return isFullExpression
        ? expression
        : expression[0].options.filter(({ label }) => !filters.includes(label));
};

export const collectFlowOptions = createSelector(
    [ formValuesSelector, questionFlowSelector, activeSurveySelector ],
    (reduxFormValue, questionFlowOptions, activeSurvey) => {
        const collectionOptions = [];

        if (Object.keys(reduxFormValue).length) {
            reduxFormValue.conditions.map(condition => {
                const { question_id, expression } = condition ? condition : [];

                let answerOptions = expression ? questionFlowOptions.questionsSelects[expression.value] || [] : [];

                if (!answerOptions.length) {
                    answerOptions = question_id ? questionFlowOptions.questionsSelects[question_id.value] || [] : [];
                }

                collectionOptions.push({
                    ...questionFlowOptions,
                    matchingValue: answerOptions,
                    expression: getExpression(question_id, activeSurvey, questionFlowOptions.expression),
                    optionForCommand,
                });

                return condition;
            });
        }

        return collectionOptions.length ? collectionOptions : [ questionFlowOptions ];
    });
