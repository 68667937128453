import { createSelector } from 'reselect';
import { userInfoSelector } from '/modules/selectors';

export const MAX_TITLE_LENGTH = 50;
export const MAX_DELIMITER_LENGTH = 10;

export const UPLOAD_LIST_ROUTE = '/store/uploads-list';

export const languagesSelector = t => createSelector([ userInfoSelector ], user => {
    return [
        {
            value: '',
            label: t('severalLanguagesInFile'),
        },
        ...user.languages,
    ];
});

export const lengthHandler = (value, limit) => {
    const data = value.trimLeft();

    return data.length > limit ? data.slice(0, limit) : data;
};
