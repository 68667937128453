import React, { useEffect, useState } from 'react';
import cx from "classnames";
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

export const DateOptions = ({ onChange, data, type }) => {
    const { t } = useTranslation();
    const [ activeDate, setActiveDate ] = useState('');
    const dateOptions = [
        {
            title: t('dateOptions.wholeDay'),
            type: 'wholeDay',
            relativeData: {
                end: 0,
                period: "DAY",
                start: 0,
            },
        },
        {
            title: t('dateOptions.yesterday'),
            type: 'yesterday',
            relativeData: {
                end: -1,
                period: "DAY",
                start: -1,
            },
        },
        {
            title: t('dateOptions.thisWeek'),
            type: 'thisWeek',
            relativeData: {
                end: 0,
                period: "WEEK_MON",
                start: 0,
            },
        },
        {
            title: t('dateOptions.lastWeek'),
            type: 'lastWeek',
            relativeData: {
                end: -1,
                period: "WEEK_MON",
                start: -1,
            },
        },
        {
            title: t('dateOptions.thisMonth'),
            type: 'thisMonth',
            relativeData: {
                end: 0,
                period: "MONTH",
                start: 0,
            },
        },
        {
            title: t('dateOptions.lastMonth'),
            type: 'lastMonth',
            relativeData: {
                end: -1,
                period: "MONTH",
                start: -1,
            },
        },
        {
            title: t('dateOptions.thisQuarter'),
            type: 'thisQuarter',
            relativeData: {
                end: 0,
                period: "QUARTER",
                start: 0,
            },
        },
        {
            title: t('dateOptions.lastQuarter'),
            type: 'lastQuarter',
            relativeData: {
                end: -1,
                period: "QUARTER",
                start: -1,
            },
        },
        {
            title: t('dateOptions.thisYear'),
            type: 'thisYear',
            relativeData: {
                end: 0,
                period: "YEAR",
                start: 0,
            },
        },
        {
            title: t('dateOptions.lastYear'),
            type: 'lastYear',
            relativeData: {
                end: -1,
                period: "YEAR",
                start: -1,
            },
        },
        {
            title: t('dateOptions.last3Months'),
            type: 'last3Months',
            relativeData: {
                end: -1,
                period: "MONTH",
                start: -3,
            },
        },
        {
            title: t('dateOptions.last6Months'),
            type: 'last6Months',
            relativeData: {
                end: -1,
                period: "MONTH",
                start: -6,
            },
        },
        {
            title: t('dateOptions.last12Months'),
            type: 'last12Months',
            relativeData: {
                end: -1,
                period: "MONTH",
                start: -12,
            },
        },
        {
            title: t('dateOptions.all'),
            type: 'all',
            relativeData: {
                end: null,
                period: null,
                start: null,
            },
        },
    ];

    useEffect(() => {
        if(data && !data?.start || !data?.end || !data?.period) {
            setActiveDate('');
        }
        const findDateOption = dateOptions.find(dateOption => {
            const { relativeData: { end, start, period } } = dateOption;

            if(end === data?.end && start === data?.start && period === data?.period) {
                return dateOption;
            }
        });

        if(findDateOption) {
            setActiveDate(findDateOption.type);
        }
    }, [ data ]);

    useEffect(() => {
        const relativeCondition = !data?.start && !data?.end && !data?.period && type === 'relative';

        if(data && relativeCondition) {
            setActiveDate('wholeDay');
            onChange({
                title: t('dateOptions.wholeDay'),
                type: 'wholeDay',
                relativeData: {
                    end: 0,
                    period: "DAY",
                    start: 0,
                },
            });
        }
    }, []);

    return (
        <div className={ styles.dateOptions }>
            {
                dateOptions.map(date => (
                    <p
                        className={ cx({ [styles.active]: activeDate === date.type }) }
                        key={ date.type }
                        onClick={ () => {
                            onChange(date);
                            setActiveDate(date.type);
                        } }
                    >
                        { date.title }
                    </p>
                ))
            }
        </div>
    );
};
