import React, { forwardRef } from 'react';
import cx from 'classnames';

import { Portal } from '/components';

import './PopoverChartTooltip.scss';

interface IPopoverChartTooltip {
    bgColor?: string;
}

export const PopoverChartTooltip = forwardRef<HTMLDivElement, IPopoverChartTooltip>((props, ref) => (
    <Portal id={ 'chart_tooltip' }>
        <div
            style={{ backgroundColor: props.bgColor || '#000' }}
            className={ cx('popover_chart_tooltip') }
            ref={ ref }
        />
    </Portal>
));
