import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import barChart from './svg/barchart.svg';
import cloud from './svg/cloud.svg';
import flowChart from './svg/flowchart.svg';
import mindMap from './svg/mindmap.svg';
import stackedChart from './svg/stackedchart.svg';
import text from './svg/text.svg';
import defaultIcon from './svg/defaultIcon.svg';
import dictionaries from './svg/dictionaries.svg';
import dictionariesBlack from './svg/dictionariesBlack.svg';
import leftArrow from './svg/leftArrow.svg';
import gears from './svg/gears.svg';
import shareIcon from './svg/share.svg';
import editIcon from './svg/edit.svg';
import deleteIcon from './svg/delete.svg';
import dictionaryIcon from './svg/dictionaryIcon.svg';
import coOccurrenceGraphIcon from './svg/co-occurence.svg';
import correlationGraphIcon from './svg/correlation.svg';
import drillDownIcon from './svg/drillDown.svg';
import aiIcon from './svg/ai.svg';

import './Icons.scss';

const iconLib = name => {
    const allIcon = {
        defaultIcon,
        barChart,
        cloud,
        flowChart,
        mindMap,
        stackedChart,
        text,
        dictionaries,
        leftArrow,
        gears,
        shareIcon,
        editIcon,
        deleteIcon,
        dictionaryIcon,
        coOccurrenceGraphIcon,
        correlationGraphIcon,
        dictionariesBlack,
        aiIcon,
        drillDownIcon,
    };

    return allIcon[name] || allIcon.defaultIcon;
};

// For use with TooltipWrapper https://github.com/atomiks/tippyjs-react#component-children
export const Icons = forwardRef(({
    icon,
    className,
    name,
}, ref) => {
    return (
        <img
            ref={ ref }
            src={ iconLib(icon) }
            className={ className }
            alt={ name }
        />
    );
});

Icons.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
};

Icons.defaultProps = {
    className: 'icon__default',
    name: 'icon',
};
