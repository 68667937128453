import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayMove } from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';

import { VocModal } from '/components';
import { HelperService } from '/services';
import { SortableList } from './components';

import './ReorderModal.scss';

export const ReorderModal = ({
    isOpen,
    handleOpenModalState,
    columnsList,
    reorderVirtualSourceColumns,
}) => {
    const { t } = useTranslation();
    const [ items, setItems ] = useState([]);

    useEffect(() => {
        columnsList.length && setItems(columnsList);
    }, [ columnsList ]);

    const onSortEnd = ({ oldIndex, newIndex }) => setItems(items => (arrayMove(items, oldIndex, newIndex)));

    const onSubmit = () => {
        const data = items.map(item => HelperService.filterObjectProperties(item, [ 'key' ]));

        reorderVirtualSourceColumns(data);
        handleOpenModalState();
    };

    const onCancel = () => {
        setItems(columnsList);
        handleOpenModalState();
    };

    const renderBody = () =>
        <SortableList
            itemsList={ items }
            axis='y'
            lockAxis='y'
            onSortEnd={ onSortEnd }
        />;

    const renderFooter = () =>
        <>
            <Button
                outline
                color='white'
                onClick={ onCancel }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                <span>{ t('cancel') }</span>
            </Button>
            <Button
                color='primary'
                onClick={ onSubmit }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                <span>{ t('submit') }</span>
            </Button>
        </>;

    return (
        <VocModal
            header={ t('reorderVirtualSource') }
            modalClassName='virtual-source_reorder-modal'
            isOpen={ isOpen }
            footer={ renderFooter() }
        >
            { renderBody() }
        </VocModal>
    );
};
