import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'd3';
import cx from 'classnames';
import { RangeInput, EmptyData } from '/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CoOccurrenceGraph } from '../CoOccurrenceGraph';
import { SensitivityInfo } from "../SensitivityInfo";
import { coOccurrenceLoadingSelector, getQueryLemmataLimitForCoOccurrence } from '/query/modules/query.selectors';

export const QueryCoOccurrenceGraph = ({
    width,
    height,
    graphData,
    name,
    newChangeFilter,
    updatePaginationFilters,
    filterForInclude,
    filterForExclude,
    idInclude,
    idExclude,
    requestByChart,
    extraNameForFilter,
    setShowRefreshButton,
    showRefreshButton,
}) => {
    const [ statusButton, setStatusButton ] = useState(true);
    const [ selectedFilterType, setSelectedFilterType ] = useState(idInclude);
    const [ secondFilterType, setSecondFilterType ] = useState(idExclude);
    const limit = useSelector(getQueryLemmataLimitForCoOccurrence);
    const isLoading = useSelector(coOccurrenceLoadingSelector);
    const isNotEmpty = graphData.nodes.length;

    useEffect(() => {
        const circles = select('#graph').selectAll("circle");

        circles.attr("class", d => changeClass(d.name));
    }, [ filterForInclude.values, filterForExclude.values ]);

    const changeClass = word => {
        const { isInclude, isExclude } = checkEntry(word);
        let classes = '';

        if (isInclude) {
            classes = 'included';
        } else if (isExclude) {
            classes = 'excluded';
        }

        return classes;
    };

    const checkEntry = text => {
        return {
            isInclude: filterForInclude.values.includes(text),
            isExclude: filterForExclude.values.includes(text),
        };
    };

    const changeStatusButton = status => {
        setStatusButton(status);
        setSelectedFilterType(status ? idInclude : idExclude);
        setSecondFilterType(!status ? idInclude : idExclude);
    };

    const getControls = () => (
        <>
            <div className='btns-wrapper'>
                <button className={ cx('btn', { active: statusButton }) }
                    onClick={ () => changeStatusButton(true) }
                >
                    <FontAwesomeIcon className="icon" icon={ 'eye' }/>
                    <span>Include lemmata</span>
                </button>
                <button className={ cx('btn', { active: !statusButton }) }
                    onClick={ () => changeStatusButton(false) }
                >
                    <FontAwesomeIcon className="icon" icon={ 'eye-slash' }/>
                    <span>Exclude lemmata</span>
                </button>
            </div>
        </>
    );

    const changeLemmataLimit = limit => {
        setShowRefreshButton(true);
        updatePaginationFilters({ limit, name , extraNameForFilter });
    };

    const getInput = () => (
        <RangeInput
            label={ 'Sensitivity' }
            value={ limit }
            onChange={ changeLemmataLimit }
            refreshCallBack={ requestByChart }
            showRefreshButton={ showRefreshButton }
            trackPointsId={ 'coOccurrenceGraphSensitivity_' }
        />
    );

    const onWordClick = text => {
        newChangeFilter({
            text,
            selectedFilterType,
            secondFilterType,
            allFilters: {
                [idInclude]: filterForInclude,
                [idExclude]: filterForExclude,
            },
        });
    };

    return (
        <div className={ 'word-cloud__section word-cloud__section--co-occurrence' }>
            {
                !isNotEmpty && !isLoading
                    ? <>
                        <EmptyData errorText={ graphData.error }/>
                        {
                            !graphData.error
                                ? <div className={ 'ranger-place' }>
                                    <SensitivityInfo limit={ limit }/>
                                    { getInput() }
                                </div>
                                : null
                        }
                    </>
                    : isNotEmpty
                        ? <>
                            <div className={ 'button-place' }>
                                { getControls() }
                            </div>

                            <div className={ 'cloud-place' }>
                                <CoOccurrenceGraph
                                    height={ height }
                                    width={ width }
                                    graphData={ graphData }
                                    onWordClick={ onWordClick }
                                    changeClass={ changeClass }
                                    tooltip='Occurrence'
                                />
                            </div>

                            <div className={ 'ranger-place' }>
                                <SensitivityInfo limit={ limit }/>
                                { getInput() }
                            </div>
                        </>
                        : null
            }
        </div>
    );
};

QueryCoOccurrenceGraph.defaultProps = {
    width: 900,
    height: 450,
    graphData: {
        nodes: [],
        links: [],
    },
};
