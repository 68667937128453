import React, { Component } from 'react';
import { combineReducers } from 'redux';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import { VocCleaupHOC, VocSocketHoc, PrivateRoute } from '/components';
import { QueriesList, Query, Dictionaries } from './scenes';
import { reducer as queriesList } from './scenes/QueriesList/modules/queriesList.modules';
import { reducer as query } from './scenes/Query/modules/query.modules';
import { reducer as dictionariesList } from './scenes/Dictionaries/modules/dictionary.modules.js';
import { getAllUser, getAllGroups, getCurrentUser } from '/modules';
import { userRightsSelector } from '/modules/selectors';
import { reducer as common, cleanup } from './modules/vocMine.modules';
import { PageRequests } from '/services';

import './VocMine.scss';

const mapDispatchToProps = {
    cleanup,
    getAllUser,
    getAllGroups,
    getCurrentUser,
};

@connect(state => ({
    userRights: userRightsSelector(state),
}), mapDispatchToProps)
@VocCleaupHOC
@VocSocketHoc('mine')
export class VocMine extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        const { getAllUser, getAllGroups, getCurrentUser } = this.props;

        this.pageRequests.cleanup();
        const requests = [ getAllUser(), getAllGroups(), getCurrentUser() ];

        requests.forEach(request => this.pageRequests.addRequest(request));
    }

    render() {
        const { url } = this.props.match;
        const { hasMineAccess } = this.props.userRights;

        return (
            <div>
                <Switch>
                    <PrivateRoute
                        exact
                        path={ `${ url }/query/list-queries` }
                        component={ QueriesList }
                        hasAccess={ hasMineAccess }
                    />
                    <PrivateRoute
                        exact
                        path={ `${ url }/query/:queryId/execute` }
                        component={ Query }
                        hasAccess={ hasMineAccess }
                    />
                    <PrivateRoute
                        exact
                        path={ `${ url }/dictionaries` }
                        component={ Dictionaries }
                        hasAccess={ hasMineAccess }
                    />

                    {/* Support deprecated links */}
                    <Redirect
                        from={ `${ url }/:queryId/execute` }
                        to={ `${ url }/query/:queryId/execute` }
                    />
                </Switch>
            </div>
        );
    }
}

export const mineReducers = combineReducers({
    queriesList,
    common,
    query,
    dictionariesList,
});
