import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autobind } from 'core-decorators';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';

import { TooltipWrapper } from '/components';

@withTranslation()
export class SurveysActionFormatter extends Component {
    @autobind
    onCreateApplianceClick() {
        const { uuidMongo, showApplianceModal, rights } = this.props;

        rights.isPermitRecord && showApplianceModal({ sourceId: uuidMongo, mode: 'create' });
    }

    @autobind
    downloadSurvey() {
        const { uuidMongo, attrByDate, initiateSurveyExport, rights } = this.props;

        rights.isPermitDownloadSource && initiateSurveyExport(uuidMongo, attrByDate, 'survey');
    }

    @autobind
    reLemmatization() {
        const { reLemmatization, showConfirm, uuidMongo, t } = this.props;

        showConfirm({
            header: t('warning'),
            content: t('confirmReLemmatizationProcess'),
            successCallback: () => reLemmatization(uuidMongo),
        });
    }

    render() {
        const {
            openInviteModal,
            uuidMongo,
            showReLemmatization,
            rights: {
                isPermitShare,
                isPermitRecord,
                isPermitDownloadSource,
            },
            t,
        } = this.props;

        return (
            <div className='table-actions'>
                <TooltipWrapper value={ t('share') } force>
                    <span
                        className={ cx('table-action__btn-share', { disabled: !isPermitShare }) }
                        onClick={ () => isPermitShare && openInviteModal(uuidMongo) }
                    >
                        <FontAwesomeIcon icon='share' />
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('recodeVariables') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitRecord }) }>
                        <Button
                            color='link'
                            onClick={ this.onCreateApplianceClick }
                        >
                            <FontAwesomeIcon icon='sitemap' />
                        </Button>
                    </span>
                </TooltipWrapper>

                <TooltipWrapper value={ t('downloadSource') } force>
                    <span className={ cx('table-action-btn-wrapper', { disabled: !isPermitDownloadSource }) }>
                        <Button
                            color='link'
                            onClick={ this.downloadSurvey }
                        >
                            <FontAwesomeIcon icon='cloud-download-alt' />
                        </Button>
                    </span>
                </TooltipWrapper>

                {
                    showReLemmatization
                    && <TooltipWrapper value={ t('reLemmatization') } force>
                        <span className={ 'table-action-btn-wrapper' }>
                            <Button
                                color='link'
                                onClick={ this.reLemmatization }
                            >
                                <FontAwesomeIcon icon='registered' />
                            </Button>
                        </span>
                    </TooltipWrapper>
                }
            </div>
        );
    }
}

SurveysActionFormatter.propTypes = {
    showApplianceModal: PropTypes.func,
    uuidMongo: PropTypes.string,
    reLemmatization: PropTypes.func,
    showReLemmatization: PropTypes.bool,
};
