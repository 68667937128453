import React, { Component } from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { combineReducers } from 'redux';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { WithTranslation, withTranslation } from 'react-i18next';

import { VocCleaupHOC, VocSocketHoc } from '/components';
import { DashboardList, Dashboard } from "./scenes";
import { PageRequests } from '/services';
import { reducer as common, cleanup } from "./modules/VocVisual.modules";
import { reducer as dashboard } from "./scenes/Dashboard/modules/Dashboard.modules";

import { getAllUser, getAllGroups } from "/modules";

const mapDispatchToProps = {
    cleanup,
    getAllUser,
    getAllGroups,
};

interface IVocVisual extends RouteComponentProps, WithTranslation {
    getAllUser: () => Promise<any>,
    getAllGroups: () => Promise<any>,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@connect(() => ({}), mapDispatchToProps)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@VocCleaupHOC
@VocSocketHoc('visualV2')
@withTranslation()
export class VocVisual extends Component<IVocVisual> {
    pageRequests = new PageRequests();

    componentDidMount() {
        const { getAllUser, getAllGroups } = this.props;

        this.pageRequests.cleanup();

        const requests = [ getAllUser(), getAllGroups() ];

        requests.forEach(request => this.pageRequests.addRequest(request));
    }

    render() {
        const { match, t } = this.props;
        const { url } = match;

        return (
            <DocumentTitle title={ t('documentTitle') }>
                <Switch>
                    <Route exact
                        path={ `${ url }/dashboard-list` }
                        component={ DashboardList }
                    />

                    <Route exact
                        path={ [
                               `${ url }/dashboard/:dashboardId`,
                               `${ url }/dashboard`,
                        ] }
                        component={ Dashboard }
                    />

                    <Redirect to={ `${ url }/dashboard` }/>
                </Switch>
            </DocumentTitle>
        );
    }
}

export const visualReducers = combineReducers({
    common,
    dashboard,
});
