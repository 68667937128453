import { createSelector } from 'reselect';
import { HelperService } from '/services';
import {
    activeLanguageSelector,
    isLoadingSurveySelector,
} from '../../../modules/survey.selectors';
import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { SettingsService } from '/feedback/services/SettingsService';

export const _getSurveySettings = state => state.VocFeedback.survey.languageSettings;
export const _getLanguages = state => state.VocFeedback.common.languages.results;

export const idLanguagesSelector = createSelector([ _getLanguages, activeLanguageSelector ], (languages, activeLang) => {
    return activeLang && languages.length
        ? languages.find(el => el.iso_6391_code === activeLang).id
        : null;
});

const modelKeys = [
    'digi_button_finish_text',
    'digi_button_go_to_previous_text',
    'digi_button_submit_text',
    'digi_popup_title',
    'digi_thanks_message',
    'digi_theme_css_custom_language',
    'digi_triggered_button_text',
    'digi_custom_header_language',
    'digi_custom_footer_language',
];

export function getSettings(obj) {
    return HelperService.pick({ ...obj }, modelKeys);
}

export const themeLanguageSettingsSelector = createSelector([ _getSurveySettings ], ({ digiLangSettings }) => {
    return digiLangSettings;
});

export const surveySettingsSelector = createSelector([ _getSurveySettings ], languageSettings => {
    //logic for DIGI
    const {
        global_theme_language,
        parent_unit_theme_language,
        survey_theme_language,
    } = languageSettings.digiLangSettings;

    const getParentUnitThemeLanguage = () => {
        if (global_theme_language !== null && !parent_unit_theme_language && !survey_theme_language) {
            return getSettings(global_theme_language);
        } else if (parent_unit_theme_language) {
            return getSettings(parent_unit_theme_language);
        } else {
            return HelperService.getDefaultSettings(modelKeys);
        }
    };

    const globalThemeLanguage = global_theme_language
        ? getSettings(global_theme_language)
        : HelperService.getDefaultSettings(modelKeys);

    const parentUnitThemeLanguage = getParentUnitThemeLanguage();

    const surveyThemeLanguage = survey_theme_language
        ? getSettings(survey_theme_language)
        : HelperService.getDefaultSettings(modelKeys);
    //end logic for DIGI

    return {
        ...languageSettings,
        globalSettings: {
            ...languageSettings.globalSettings,
            ...globalThemeLanguage,
            ...parentUnitThemeLanguage,
        },
        surveySettings: {
            ...languageSettings.surveySettings,
            ...surveyThemeLanguage,
        },
    };
});

export const loadingSelector = createSelector([ isLoadingSurveySelector, surveySettingsSelector ], (surveyLoading, settings) => {
    return {
        loading: settings.loading || surveyLoading,
        sending: settings.sending,
    };
});

export const fieldsMapSelector = createSelector(
    [ activeSurveySelector ],
    survey =>
        SettingsService.getFieldsBySurveyLanguageSettings(survey.channel),
);

export const initialFormValuesSelector = createSelector(
    [ surveySettingsSelector, fieldsMapSelector, activeSurveySelector, activeLanguageSelector ],
    ({ globalSettings, surveySettings }, fields, { channel = '', id }, language) => {
        const settings = SettingsService.composeSurveySettings(globalSettings, surveySettings);

        if (channel === 'DIGI') {
            const urlSetting = { ...settings['DIGI_RUNNER_URL'] } || {};

            settings['DIGI_RUNNER_URL'] = {
                ...urlSetting,
                ...SettingsService.composeDigiUrlSettings(urlSetting.value, id, language),
            };
        }

        return {
            fieldsMap: fields.map(field => ({
                ...field,
                children: field.children.map(child => ({
                    ...child,
                    redefined: settings[child.key] && settings[child.key].redefined,
                })),
            })),
            initialValues: settings,
        };
    });

export const surveyFormSettingsSelector = createSelector([ loadingSelector, initialFormValuesSelector ],
    ({ loading, sending }, fields) => {
        return {
            loading,
            sending,
            ...fields,
        };
    });
