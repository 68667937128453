import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';
import cx from 'classnames';

import { AudioData } from './components';

import './VocFormDropzone.scss';

// audio/wav - accepted MIME-type for Chrome
// audio/x-wav - accepted MIME-type for
const ACCEPTABLE_DROPZONE_MIME_TYPES = [ 'audio/x-wav', 'audio/wav' ];

export const VocFormDropzone = ({ input, meta, disabled, audioFile, getAudioFile, cleanUpAudio, fieldType }) => {
    const file = input.value;

    const onFileDrop = (accepted, rejected) => {
        !rejected.length && cleanUpAudio(fieldType);

        input.onChange(rejected.length
            ? { errorText: 'Please upload correct file' }
            : accepted[0],
        );
    };

    const onRemoveFile = () => input.onChange({ removed: !!audioFile[fieldType] });

    return (
        <>
            <div className='voc-form-dropzone__input'>
                <FormGroup>
                    <InputGroup>
                        <Dropzone
                            accept={ ACCEPTABLE_DROPZONE_MIME_TYPES }
                            file={ file }
                            onDrop={ onFileDrop }
                            disabled={ disabled }
                        >
                            { ({ getRootProps, getInputProps }) => (
                                <div
                                    className={ cx('file-upload', { uploaded: !!file }) }
                                    { ...getRootProps() }
                                >
                                    <input { ...getInputProps() } />
                                    <FontAwesomeIcon icon='plus' />

                                    <span>
                                        { file.name ? 'Add new file' : 'Added file' }
                                    </span>
                                </div>
                            ) }
                        </Dropzone>
                    </InputGroup>
                </FormGroup>

                {
                    file.errorText
                    && <p>{ file.errorText }</p>
                }

            </div>

            {
                file.name
                && <AudioData
                    isServerFile={ !file.lastModified }
                    name={ file.name }
                    audioFile={ audioFile[fieldType] }
                    onRemoveFile={ onRemoveFile }
                    dirty={ meta.dirty }
                    getAudioFile={ () => getAudioFile(fieldType) }
                />
            }
        </>
    );
};

VocFormDropzone.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    withError: PropTypes.bool,
};

VocFormDropzone.defaultProps = {
    withError: true,
    type: 'text',
    className: '',
};
