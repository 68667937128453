import React from 'react';
import Slider from 'rc-slider';

import { SliderHandle } from "./SliderHandle";

import 'rc-slider/assets/index.css';

const baseTrackStyles = ({
    index,
    colors,
    allowOverlap,
}) => ({
    height: 4,
    cursor: allowOverlap ? 'ew-resize' : 'default',
    backgroundColor: colors ? colors[index] : '#13b6ff',
    transform: 'translateY(50%)',
});

const baseHandStyles = ({ index, colors }) => ({
    opacity: 1,
    height: 15,
    width: 15,
    border: 'none',
    backgroundColor: colors ? colors[index] : '#428bca',
});

export const RangeSlider = ({
    step = 1,
    min,
    max,
    values = [],
    disabled = false,
    colors,
    draggableTrack = false,
    allowOverlap = false,
    railStyles,
    trackStyles,
    handleStyles,
    keyboard = true,
    marks,
    onChange,
    tooltip = false,
}) => {
    const mergeStyles = (stylesList, baseStyles, options) =>
        stylesList && Array.isArray(stylesList)
            ? stylesList.map((s, index) => ({ ...baseStyles({ index, ...options }), ...s }))
            : values.map((s, index) => ({ ...baseStyles({ index, ...options }) }));

    const tStyles = mergeStyles(trackStyles, baseTrackStyles, { allowOverlap, colors: colors?.track });
    const hStyles = mergeStyles(handleStyles, baseHandStyles, { colors: colors?.hand });

    const getMarks = () => {
        if (marks && Array.isArray(marks) && marks.length !== 0) {
            const result = {};

            marks.forEach((m, index) => {
                if (values.includes(index)) {
                    result[index] = <span>{m}</span>;
                }
            });

            return result;
        }
    };

    const tooltipHandleRender = (originNode, handleProps) => <SliderHandle
        originNode={ originNode }
        visible={ handleProps.dragging }
        rangeValue={ handleProps.value }
    />;

    return (
        <Slider
            tool
            range={ values.length > 1 }
            step={ step }
            min={ min }
            max={ max }
            value={ values }
            disabled={ disabled }
            draggableTrack={ draggableTrack }
            allowCross={ allowOverlap }
            railStyle={{
                backgroundColor: colors?.rail || '#ccc',
                transform: 'translateY(50%)',
                ...railStyles,
            }}
            trackStyle={ tStyles }
            handleStyle={ hStyles }
            keyboard={ keyboard }
            marks={ getMarks() }
            onChange={ onChange }
            {
                ...tooltip ? { handleRender: tooltipHandleRender } : {}
            }
        />
    );
};
