import { createSelector } from 'reselect';
import { formValueSelector, isDirty } from 'redux-form';

import { questionTypesSelector, questionTypeOptionsSelector, activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { HelperService } from '/services';
import { QuestionsServices } from '/scenes/VocFeedback/services';
import { EDigiQuestionType } from '/scenes/VocFeedback/constants';

export const surveySelector = state => state.VocFeedback.survey;
export const questionSelector = state => state.VocFeedback.survey.question;
export const questionFormLoadingSelector = state => state.VocFeedback.survey.question?.loading || state.VocFeedback.survey.question?.ivrProcessing;

export const questionDataForInheritSelector = state => state.VocFeedback.survey.question.questionDataForInherit;

const questionDataSelector = state => state.VocFeedback.survey.question.data;

export const audioFilesSelector = state => state.VocFeedback.survey.question.textAudio;

export const questionInitialFormSelector = createSelector(
    surveySelector, questionDataSelector, questionDataForInheritSelector, activeSurveySelector,
    ( { questions }, questionData = {}, questionDataForInherit, survey = {} ) => {
        if (!questionDataForInherit && !questions.activeQuestionId) return {};

        let initialData = questions.activeQuestionId
            ? HelperService.deepClone(questionData)
            : {};

        if (questionDataForInherit) {
            initialData = QuestionsServices.inheritDataFromGrantorQuestion(initialData, questionDataForInherit);
        }

        if (initialData.language_values || questionDataForInherit) {
            initialData.language_values = QuestionsServices.prepareLanguageDependentFieldsForClient(initialData.language_values, survey.channel);
        }

        if (questionData.answers || questionDataForInherit?.answers) {
            if (questionData.display_class === EDigiQuestionType.QuestionUnsubscribeDIGI) {
                const questionAnswers = questionData.answers.reduce((sum, answer) => ({
                    ...sum,
                    [answer.value]: answer.names,
                }), {});

                const getAnswer = type => {
                    return {
                        checked: !!questionAnswers[type],
                        is_open: questionAnswers[type]?.is_open ? '1' : '0',
                        names: questionAnswers[type]
                            ? QuestionsServices.prepareLanguageDependentFieldsForClient(questionAnswers[type])
                            : {},
                        value: type,
                    };
                };

                initialData.answers = [
                    getAnswer('contact_unsubscribe'),
                    getAnswer('customer_unsubscribe'),
                ];
            } else {
                let answersList = questionDataForInherit
                    ? questionDataForInherit.answers
                    : questionData.answers;

                if (!Array.isArray(answersList)) answersList = [];

                initialData.answers = answersList.map(answer => ({
                    ...answer,
                    is_open: answer.is_open ? '1' : '0',
                    names: QuestionsServices.prepareLanguageDependentFieldsForClient(answer.names),
                }));
            }
        }

        return initialData;
    });

export const questionTypesOptionsSelector = createSelector(questionTypesSelector, activeSurveySelector,
    (questionTypes, survey) => {
        return questionTypes.filter(questions => questions.channel === survey.channel)
            .map(question => ({
                value: question.display_class,
                label: question.name,
                id: question.id,
            }));
    });

export const questionFormSelector = formValueSelector('QuestionForm');
export const isDirtySelector = isDirty('QuestionForm');

export const questionTypeSelector = state => questionFormSelector(state, 'display_class');
export const answersSelector = state => questionFormSelector(state, 'answers');
export const configurableFieldsSelector = state => questionFormSelector(state, 'configurable');

export const showAnswerSectionSelector = createSelector(questionTypeSelector, questionType => {
    return questionType === 'QuestionConfigurable'
        || questionType === 'QuestionConfigurableIVR'
        || questionType === 'QuestionConfigurableDIGI'
        || questionType === 'QuestionUnsubscribeDIGI'
        || questionType === 'MatrixQuestionConfigurableDIGI';
});

// filter all question type option by current type
export const questionConfigurableValuesSelector = createSelector(
    questionTypeOptionsSelector, questionTypesOptionsSelector, questionTypeSelector,
    (typeOptions, questionTypes, questionType) => {
        const questionTypeValue = HelperService.getSelectedValue(questionTypes, questionType);

        return questionTypeValue
            ? typeOptions.filter(option => option.question_type === questionTypeValue.id)
            : [];
    });

// filter question type option of the current question type for display in Configurable section
export const questionConfigurableValuesForSectionSelector = createSelector(
    questionConfigurableValuesSelector, questionDataForInheritSelector,
    (allConfigurableValuesForQuestionType, questionDataForInherit) => {
        const unusedField = [ 'info_text', 'next_button_text', 'select_placeholder' ];

        if (questionDataForInherit?.display_class === EDigiQuestionType.MatrixQuestionConfigurableDIGI) {
            unusedField.push('random_answers_order');
        }

        return allConfigurableValuesForQuestionType
            .filter(option => !unusedField.includes(option.name));
    });

export const ivrErrorsSelector = createSelector(
    questionSelector, questionDataSelector,
    (question, data = {}) => {
        return question.ivrErrors.map(error => {
            const {
                message,
                id,
                type,
            } = error;
            const languageValue = data.language_values && data.language_values.find(language => language.id === id);
            const language = languageValue && languageValue.language;

            return `Invalid ${ type } file for ${ language } language - ${ message }`;
        });
    });

export const answersErrorsSelector = createSelector(
    questionSelector, question => {
        return question.error ? question.error.answers : [];
    });

export const questionViewFormSelector = createSelector(
    questionDataSelector, surveySelector, questionDataForInheritSelector, questionConfigurableValuesSelector, questionTypeSelector,
    (questionData, { questions }, questionDataForInherit, configurableValues, questionType) => {
        const { activeQuestionId, inheritDataFromId } = questions;
        const isChildMatrix = (activeQuestionId || inheritDataFromId)
            && QuestionsServices.isMatrixQuestion(questionDataForInherit?.display_class);
        const isMatrixQuestion = ((activeQuestionId || inheritDataFromId) && QuestionsServices.isMatrixQuestion(questionData.display_class))
            || (!activeQuestionId && !inheritDataFromId && QuestionsServices.isMatrixQuestion(questionType));
        const isUnsubscribe = questionData.display_class === EDigiQuestionType.QuestionUnsubscribeDIGI
            || questionType === EDigiQuestionType.QuestionUnsubscribeDIGI;
        const isMatrixConfigurable = questionDataForInherit?.display_class === EDigiQuestionType.MatrixQuestionConfigurableDIGI;
        const isMatrixScalable = questionDataForInherit?.display_class === EDigiQuestionType.MatrixQuestionScalableDIGI;

        const isFieldExistInConfig = configName => configurableValues.some(cValue => cValue.name === configName);

        return {
            generalSection: {
                display_class: {
                    show: true,
                    disabled: Boolean(activeQuestionId || isChildMatrix),
                },
                external_identifier: {
                    show: !isMatrixQuestion,
                    disabled: false,
                },
            },
            textSection: {
                error_text: {
                    show: !isMatrixQuestion,
                    disabled: false,
                },
                info_text: {
                    show: isFieldExistInConfig('info_text'),
                    disabled: isChildMatrix,
                },
                next_button_text: {
                    show: isFieldExistInConfig('next_button_text'),
                    disabled: questions.list.find(question => question.id === questionData.id)?.nested_level > 1 || isChildMatrix,
                },
                select_placeholder: {
                    show: isFieldExistInConfig('select_placeholder') && !isChildMatrix,
                    disabled: false,
                },
            },
            answersSection: {
                checked: {
                    show: isUnsubscribe,
                    disabled: false,
                },
                add_button: {
                    show: !isUnsubscribe && !isChildMatrix,
                    disabled: isUnsubscribe || isChildMatrix,
                },
                remove_button: {
                    show: !isUnsubscribe && !isChildMatrix,
                    disabled: isUnsubscribe || isChildMatrix,
                },
                move_button: {
                    show: !isUnsubscribe && !isChildMatrix,
                    disabled: isUnsubscribe || isChildMatrix,
                },
                value: {
                    show: true,
                    disabled: isUnsubscribe || isChildMatrix,
                },
                name: {
                    show: true,
                    disabled: isChildMatrix,
                },
            },
            configSection: {
                render_type: {
                    show: true,
                    disabled: isChildMatrix,
                },
                last_is_open: {
                    show: true,
                    disabled: isChildMatrix && isMatrixConfigurable,
                },
                number: {
                    show: true,
                    disabled: isChildMatrix && isMatrixScalable,
                },
                start_from_0: {
                    show: true,
                    disabled: isChildMatrix && isMatrixScalable,
                },
            },
        };
    },
);
