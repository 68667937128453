import React from 'react';
import noImage from './no_image.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './FileManagerItem.scss';

export const FileManagerItem = ({ url, name, itemType, onClick }) => {
    return <li className={ 'file-list__item' } onClick={ () => onClick({ itemType, name, url }) }>
        <figure>
            <p>
                {
                    itemType === 'folder'
                        ? <FontAwesomeIcon
                            className={ 'file-list__item-folder' }
                            icon={ 'folder' }
                        />
                        : <img src={ url }
                            onError={ event => event.target.src = noImage }
                            alt={ name }
                        />
                }
            </p>
            <figcaption>
                <span>{ name }</span>
            </figcaption>
        </figure>
    </li>;
};
