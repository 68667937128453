import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { VocSelect, TooltipWrapper } from '/components';
import { ViewSelector, Dimensions } from '../';
import { HelperService } from '/services';
import {
    _getPreviewData,
    _getPreviewDevices,
    _getPreviewLanguages,
    _getPreviewThemes,
} from '../../modules/PreviewMode.selectors';
import { changeEditData, savePreviewTheme } from '../../modules/PreviewMode.modules';
import { IControlPanel } from './models';

import styles from './style.module.scss';

export const ControlPanel = ({
    onClickBack,
    dimensions,
    setDimensions,
    viewType,
    setViewType,
    deviceType,
    setDeviceType,
}: IControlPanel) => {
    const dispatch = useDispatch();
    const {
        name,
        loading,
        saveLoading,
        selectedThemeId,
        editData,
        digiId,
    } = useSelector(_getPreviewData);

    const themesOptions = useSelector(_getPreviewThemes);
    const devicesOptions = useSelector(_getPreviewDevices);
    const languagesOptions = useSelector(_getPreviewLanguages);
    const isEnableSave = editData.selectedThemeId && (editData.selectedThemeId !== selectedThemeId);

    const selectedLanguage = editData.selectedLanguage
        || sessionStorage.getItem('feedbackActiveLanguage')
        || 'EN';

    const onChangeField = (key: string, value: string) => {
        dispatch(changeEditData({ [key]: value }));
    };

    const onSaveTheme = () => {
        dispatch(savePreviewTheme({
            digi_theme: editData.selectedThemeId,
            survey: digiId,
        }));
    };

    return (
        <div className={ cx(styles.controlPanelWrapper, { [styles.controlPanelWrapperLoading]: loading || saveLoading }) }>
            <div className={ cx(styles.part, styles.partLeft) }>
                <Button
                    color='link'
                    className={ cx('button_link-back_grey', styles.backButton) }
                    onClick={ onClickBack }
                >
                    <FontAwesomeIcon icon='arrow-left'/>
                    <span> Back</span>
                </Button>

                <div className={ styles.previewTitleWrapper }>
                    <TooltipWrapper value={ name || '-' }>
                        <span className={ styles.previewTitle }>
                            { name || '-' }
                        </span>
                    </TooltipWrapper>
                </div>

                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/*@ts-ignore*/}
                <VocSelect
                    closeMenuOnSelect={ false }
                    className={ styles.themeSelector }
                    placeholder={ 'Choose theme' }
                    options={ themesOptions }
                    value={ HelperService.getSelectedValue(themesOptions, editData.selectedThemeId || selectedThemeId) }
                    portal
                    onChange={ ({ value }) => onChangeField('selectedThemeId', value) }
                />

                <Button
                    disabled={ !isEnableSave }
                    className={ styles.saveButton }
                    outline
                    onClick={ onSaveTheme }
                >
                    <FontAwesomeIcon icon='save'/>
                    &nbsp;
                    <span>Apply theme</span>
                </Button>
            </div>

            <div className={ styles.part }>
                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/*@ts-ignore*/}
                <VocSelect
                    closeMenuOnSelect={ false }
                    className={ styles.langSelector }
                    options={ languagesOptions }
                    onChange={ ({ value }) => onChangeField('selectedLanguage', value) }
                    value={ HelperService.getSelectedValue(languagesOptions, selectedLanguage) || languagesOptions[0] }
                    portal
                />

                {
                    viewType === 'mobile'
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ? <VocSelect
                            className={ styles.deviceSelector }
                            options={ devicesOptions }
                            onChange={ setDeviceType }
                            value={ HelperService.getSelectedValue(devicesOptions, deviceType.value) }
                            portal
                        />
                        : <Dimensions dimensions={ dimensions } setDimensions={ setDimensions } />
                }

                <ViewSelector viewType={ viewType } setViewType={ setViewType } />
            </div>
        </div>
    );
};
