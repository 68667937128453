import { getI18n } from 'react-i18next';

export const isVocCIIntegration = state => state.UserInfo.userInfo.vocCIIntegration;

export const exportDateFilterModalSelect = state => state.VocStore.rootSlice.exportFilterModal;
export const truncateUploadModalSelect = state => state.VocStore.rootSlice.truncateUploadModal;

export const mineQueryBySourcesSelector = state => {
    const { loadMineQuery, queries } = state.VocStore.rootSlice;

    return {
        loadMineQuery,
        queriesOptions: queries.map(({ title, id }) => ({ value: id, label: title })),
    };
};

export const exportModalDelimiterSelect = () => {
    const { t } = getI18n();

    return [
        { label: t('delimiter.commaDelimiter'), value: ',' },
        { label: t('delimiter.semicolonDelimiter'), value: ';' },
        { label: t('delimiter.dotDelimiter'), value: '.' },
        { label: t('delimiter.spaceDelimiter'), value: ' ' },
        { label: t('delimiter.tabDelimiter'), value: '\t' },
    ];
};
