import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button, ListGroup, ListGroupItem, Row, Col } from 'reactstrap';
import { autobind } from 'core-decorators';
import { FieldArray } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { EDigiQuestionType } from '/scenes/VocFeedback/constants';
import { AnswersList } from '../AnswersList';

import './AnswersSection.scss';

function renderAnswers({
    viewConfig,
    fields,
    axis,
    lockAxis,
    showConfirm,
    onSortStart,
    onSortEnd,
    useDragHandle,
    language,
    channel,
    htmlEditorCallback,
    showEditor,
    isUnsubscribe,
    isOneUnchecked,
    answers,
}) {
    return (
        <AnswersList
            items={ fields }
            isUnsubscribe={ isUnsubscribe }
            isOneUnchecked={ isOneUnchecked }
            answers={ answers }
            distance={ viewConfig.move_button.disabled ? Infinity : 0 }
            {
                ...{
                    language,
                    axis,
                    lockAxis,
                    showConfirm,
                    onSortStart,
                    onSortEnd,
                    useDragHandle,
                    channel,
                    htmlEditorCallback,
                    showEditor,
                    viewConfig,
                }
            }
        />
    );
}

export class AnswersSection extends PureComponent {
    @autobind
    onSortStart() {
        document.body.style.cursor = 'move';
    }

    @autobind
    onSortEnd({ oldIndex, newIndex }) {
        const { move } = this.props;

        document.body.style.cursor = 'auto';

        move('answers', oldIndex, newIndex);
    }

    @autobind
    addAnswer() {
        const { viewConfig, push } = this.props;

        if (viewConfig.add_button.show && !viewConfig.add_button.disabled) {
            push('answers', { value: '', is_open: '0', names: {} });
        }
    }

    renderErrors() {
        return (
            <ListGroup className='audio__section-error'>
                {
                    this.props.errors.map((error, index) => <ListGroupItem key={ index }>{ error }</ListGroupItem>)
                }
            </ListGroup>
        );
    }

    createUnsubscribeAnswers() {
        const { questionType, answers, push } = this.props;
        const isUnsubscribeProp = questionType === EDigiQuestionType.QuestionUnsubscribeDIGI;

        if (isUnsubscribeProp && !answers?.length) {
            push('answers', { is_open: '0', value: 'contact_unsubscribe', names: {}, checked: true });
            push('answers', { is_open: '0', value: 'customer_unsubscribe', names: {}, checked: true });
        }
    }

    componentDidUpdate() {
        this.createUnsubscribeAnswers();
    }

    componentDidMount() {
        this.createUnsubscribeAnswers();
    }

    componentWillUnmount() {
        const { removeAll } = this.props;

        removeAll('answers');
    }

    render() {
        const {
            language,
            showConfirm,
            loading,
            channel,
            htmlEditorCallback,
            showEditor,
            questionType,
            answers,
            errors,
            viewConfig,
        } = this.props;

        const isUnsubscribe = questionType === EDigiQuestionType.QuestionUnsubscribeDIGI;
        const isOneUnchecked = isUnsubscribe && answers?.some(answer => !answer.checked);

        return (
            <Card className='question__section configurable-answers-section'>
                <CardHeader>
                    <Row>
                        <Col lg={ 10 }>
                            <span>Answers</span>
                        </Col>
                        <Col lg={ 2 }>
                            <div className="language__header-value">
                                { language }
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className={ cx('question__section-body', { 'question__section--loading': loading }) }>
                    {
                        errors.length > 0 && this.renderErrors()
                    }
                    <FieldArray
                        viewConfig={ viewConfig }
                        name='answers'
                        axis='y'
                        showConfirm={ showConfirm }
                        lockAxis='y'
                        onSortStart={ this.onSortStart }
                        language={ language }
                        onSortEnd={ this.onSortEnd }
                        useDragHandle={ true }
                        channel={ channel }
                        htmlEditorCallback={ htmlEditorCallback }
                        showEditor={ showEditor }
                        isUnsubscribe={ isUnsubscribe }
                        isOneUnchecked={ isOneUnchecked }
                        component={ renderAnswers }
                        answers={ answers }
                    />

                    {
                        viewConfig.add_button.show
                        && <>
                            <hr/>
                            <Button
                                disabled={ viewConfig.add_button.disabled }
                                color='white'
                                outline
                                onClick={ this.addAnswer }
                            >
                                <FontAwesomeIcon icon='plus' className='question__section-icon' />
                                Add answer
                            </Button>
                        </>
                    }
                </CardBody>
            </Card>
        );
    }
}

AnswersSection.propTypes = {
    move: PropTypes.func,
    language: PropTypes.string,
    push: PropTypes.func,
    channel: PropTypes.string,
    htmlEditorCallback: PropTypes.func,
    showEditor: PropTypes.bool,
};

AnswersSection.defaultProps = {
    errors: [],
};
