import React, { Fragment, Component } from 'react';
import { SettingFieldContainer } from '../components/SettingFieldContainer';
import { autobind } from 'core-decorators';
import { FormText, Input } from 'reactstrap';
import './SettingTextField.scss';

export class SettingTextField extends Component {
    @autobind
    getValue() {
        return this.props.input.value.value;
    }

    @autobind
    onChange(event) {
        const {
            input: {
                onChange,
                value,
            },
        } = this.props;

        const model = {
            ...value,
            value: event.target.value,
        };

        onChange(model);
    }

    @autobind
    onFocus() {
        this.props.input.onFocus(this.props.input.value);
    }

    @autobind
    onBlur() {
        this.props.input.onBlur(this.props.input.value);
    }

    render() {
        const {
            input,
            type,
            withError,
            meta: {
                error,
                touched,
                dirty,
            },
            label,
            input: {
                value: {
                    redefined,
                },
            },
        } = this.props;

        return (
            <Fragment>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                <SettingFieldContainer
                    checked={ redefined }
                    field={ input }
                    className={ `${ dirty ? 'changed' : '' }` }
                >
                    <Input
                        id={ input.name }
                        type={ type ? type : 'text' }
                        className={ 'voc-setting-field__input' }
                        disabled={ !redefined }
                        placeholder={ label }
                        value={ this.getValue() }
                        onChange={ this.onChange }
                        onFocus={ this.onFocus }
                        onBlur={ this.onBlur }
                    />

                    {
                        Boolean(touched && error && withError)
                            ? <FormText className="app-form-control__error">{ error }</FormText>
                            : false
                    }

                </SettingFieldContainer>
            </Fragment>
        );
    }
}

SettingTextField.defaultProps = {
    withError: true,
    type: 'text',
    className: '',
};
