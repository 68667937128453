import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { CollapseBodyHandler } from '../RenderCollapseBody/CollapseBodyHandler';

export const HeaderHandler = ({
    handlerDelete,
    changeCondition,
    initialValues: {
        condition,
    },
    id,
    withoutCondition,
}) => {
    const onChange = ({ currentTarget }) => changeCondition(currentTarget.value, id);
    const onClick = event => event.stopPropagation();

    return (
        <div className='collapse-section-header__toolbar'>
            { withoutCondition
                ? null
                : <FormGroup className={ 'panel' }>
                    <Label
                        className='radio_label'
                        onClick={ onClick }
                        for={ `radioEnd${ id }` }
                    >
                        <Input
                            className='radio_input'
                            checked={ condition === "and" }
                            value="and"
                            id={ `radioEnd${ id }` }
                            type="radio"
                            onChange={ onChange }
                            onClick={ onClick }
                        />
                        And
                    </Label>
                    <Label
                        className='radio_label'
                        onClick={ onClick }
                        for={ `radioOr${ id }` }
                    >
                        <Input
                            className='radio_input'
                            checked={ condition === "or" }
                            value="or"
                            id={ `radioOr${ id }` }
                            type="radio"
                            onChange={ onChange }
                            onClick={ onClick }
                        />
                        Or
                    </Label>
                </FormGroup>
            }
            <div className={ 'panel' }>
                <CollapseBodyHandler
                    handlerDelete={ handlerDelete }
                    type={ id }
                    className={ 'button_handler' }
                />
            </div>
        </div>
    );
};
