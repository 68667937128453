export class LogoutService {
    static time = null;
    static logoutUrl = null;
    static active = null;
    static timeout = null;
    static eventsRegistry = [ 'scroll', 'load', 'mousemove', 'mouseenter', 'touchstart', 'click', 'keydown', 'keyup' ];
    static iframeListeners = [];
    static iframes = [];

    static initLogoutSpy(time = 1, url) {
        this.time = time * 1000;
        this.logoutUrl = url;

        this.initTimeout();
    }

    static initTimeout() {
        if (this.time <= 0) {
            return;
        }

        this.eventsRegistry.forEach(event =>
            window.addEventListener(event, this.resetTimer.bind(this), true),
        );

        this.timeout = setTimeout(this.timerCallback.bind(this), this.time);
    }

    static resetTimer() {
        this.initIframeListeners();
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.timerCallback.bind(this), this.time);
    }

    static initIframeListeners() {
        this.iframes = document.querySelectorAll('iframe');

        this.iframeListeners = this.iframeListeners.filter(iframe => {
            const temp = [];

            this.iframes.forEach(ifr => temp.push(ifr.outerHTML));
            return temp.indexOf(iframe) !== -1;
        });

        if (this.iframes.length) {
            this.iframes.forEach(iframe => {
                const key = iframe.outerHTML;
                const isListenerExists = this.iframeListeners.find(listener => listener === key);

                if (!isListenerExists) {
                    this.eventsRegistry.forEach(event =>
                        iframe.contentDocument.addEventListener(event, this.resetTimer.bind(this), true),
                    );
                    this.iframeListeners.push(key);
                }
            });
        }
    }

    static timerCallback(href = this.logoutUrl) {
        if(!href) {
            window.location.replace('/login');
        } else {
            window.location.href = href;
        }
    }
}
