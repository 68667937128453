import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { IViewSelector } from './models';
import { TooltipWrapper } from '/components';

import styles from './style.module.scss';

export const ViewSelector = ({ viewType, setViewType }: IViewSelector) => {
    return (
        <div className={ styles.deviceWrapper }>
            <span className={ styles.label }>Device</span>

            <div className={ styles.devices }>
                <TooltipWrapper value='Desktop (full screen)' force={ true }>
                    <Button
                        color='link'
                        className={ cx(styles.deviceButton, { [styles.deviceButtonActive]: viewType === 'full-screen' }) }
                        onClick={ () => setViewType('full-screen') }
                    >
                        <FontAwesomeIcon icon='desktop' />
                    </Button>
                </TooltipWrapper>

                <TooltipWrapper value='Pop-up/chatBot' force={ true }>
                    <Button
                        color='link'
                        className={ cx(styles.deviceButton, { [styles.deviceButtonActive]: viewType === 'desktop' }) }
                        onClick={ () => setViewType('desktop') }
                    >
                        <FontAwesomeIcon icon={ [ 'far', 'window-restore' ] }/>
                    </Button>
                </TooltipWrapper>

                <TooltipWrapper value='Mobile' force={ true }>
                    <Button
                        color='link'
                        className={ cx(styles.deviceButton, { [styles.deviceButtonActive]: viewType === 'mobile' }) }
                        onClick={ () => setViewType('mobile') }
                    >
                        <FontAwesomeIcon icon='mobile-alt' />
                    </Button>
                </TooltipWrapper>
            </div>
        </div>
    );
};
