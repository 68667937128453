import { EDigiQuestionType, EIvrQuestionType, EWebQuestionType } from '/scenes/VocFeedback/constants';

export const questionFormValidation = (values = {}, form) => {
    const errors = {
        configurable: {},
        answers: [{}],
    };

    const notRemoveAnswersFor = [
        EDigiQuestionType.QuestionConfigurableDIGI,
        EDigiQuestionType.MatrixQuestionConfigurableDIGI,
        EDigiQuestionType.QuestionUnsubscribeDIGI,
        EIvrQuestionType.QuestionConfigurableIVR,
        EWebQuestionType.QuestionConfigurable,
    ];

    const configurableValues = form.configurableValues
        .filter(option => option.required)
        .map(option => option.name);

    const isConfigurableValuesExists = form.configurableValues.some(value => value.field_type === 'StringLang');

    const { configurable = {} } = values;

    if (!values.title) {
        errors.title_error = 'The question title must not be empty!';
    }

    if (!values.display_class) {
        errors.display_class = 'The question type must not be empty!';
    }

    if (values.answers) {
        errors.answers = values.answers.map((answer, index, answers) => {
            let value = !answer.value ? 'Answer field may not be blank' : '',
                hasSameAnswers;

            if (!value) {
                hasSameAnswers = answers.some((existAnswer, inx) => existAnswer.value === answer.value && inx !== index);
                value = hasSameAnswers ? "Fields 'value' must have different values" : '';
            }

            return {
                value,
            };
        });
    }

    if (
        values.display_class
        && values.answers
        && !notRemoveAnswersFor.includes(values.display_class)
    ) {
        delete values.answers;
    }

    const isDIGI = values?.display_class?.includes('DIGI');

    if (!isConfigurableValuesExists && values.language_values && form.configurableValues.length && !isDIGI) {
        Object.keys(values.language_values).forEach(language =>
            delete values.language_values[language].configurable,
        );
    }

    configurableValues.forEach(value => {
        if (!configurable[value]) {
            errors.configurable[value] = 'This field may not be empty';
        }
    });

    return errors;
};
