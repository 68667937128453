import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { VocSelect } from '/components';
import PropTypes from 'prop-types';
import { HelperService } from '/services';
import { autobind } from 'core-decorators';

import './CustomersTableToolbar.scss';

export class CustomersTableToolbar extends Component {
    state = {
        search: this.props.search,
    };


    searchTypeDebouncer = HelperService.debounce((...args) => {
        this.props.onSearchType(...args);
    }, 300);

    @autobind
    onSearchType(event) {
        const search = event.target.value;

        this.setState({ search });
        this.searchTypeDebouncer(search);
    }

    @autobind
    changeStatus(status) {
        const result = {
            ...status,
            value: status.value,
        };

        this.props.changeStatus(result);
    }

    render() {
        const {
            status = 0,
            statuses,
            searchKey,
            onChangeSearchKey,
            searchOptions,
        } = this.props;
        const statusValue = HelperService.getSelectedValue(statuses, status);

        return (
            <Row className='customers_list__toolbar'>
                <Col md={ 3 }/>
                <Col md={ 2 }>
                    <VocSelect
                        name="statuslSelect"
                        isClearable={ false }
                        isHorizontal={ true }
                        options={ statuses }
                        value={ statusValue }
                        onChange={ this.changeStatus }
                        className={ 'customers_list-toolbar-select' }
                    />
                </Col>
                <Col md={ 4 }>
                    <div className="form-group form-group-sm react-bs-table-search-form">
                        <Input
                            placeholder={ 'Allowed search format: text or *text or text*' }
                            className={ 'customers_list__toolbar-search' }
                            type={ 'text' }
                            value={ this.state.search }
                            onChange={ this.onSearchType }/>
                    </div>
                </Col>
                <Col md={ 3 } className={ 'search-type-column' }>
                    <label htmlFor='searchTypeSelect'
                        className={ 'search-toolbar__label' }
                    >
                        <span>Search type:</span>
                    </label>
                    <VocSelect
                        name='searchTypeSelect'
                        isClearable={ false }
                        isHorizontal={ true }
                        value={ searchKey }
                        onChange={ onChangeSearchKey }
                        options={ searchOptions }
                        className={ 'customers_list-toolbar-select search-type-select' }
                    />
                </Col>
            </Row>
        );
    }
}

CustomersTableToolbar.propTypes = {
    status: PropTypes.number,
    statuses: PropTypes.array,
};
