import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

let changeNumberTimeOut;

export const Pagination = ({
    gotoPage,
    canPreviousPage,
    previousPage,
    pageIndex,
    nextPage,
    canNextPage,
    pageCount,
    setPageSize,
    filters,
    count,
    isVirtual,
    pageSize,
}: any) => {
    const { t } = useTranslation();
    const [ isOpenPageSizeDropDown, setIsOpenPageSizeDropDown ] = useState(false);
    const pageSizes = [ 10, 20, 50, 100, 500, 1000 ];
    const calculateNumberOfItems = Number(count) >= Number(filters.offset + filters.limit)
        ? Number(filters.offset + filters.limit)
        : count;

    const onChangePageNumberInput = (e: any) => {
        let pageNumber = Number(e.target.value) - 1;

        clearTimeout(changeNumberTimeOut);
        if(pageNumber < 0) {
            pageNumber = 0;
        }
        if(pageNumber > pageCount) {
            pageNumber = pageCount - 1;
        }

        changeNumberTimeOut = setTimeout(() => {
            gotoPage(pageNumber);
        }, 200);
    };

    return (
        <div className={ styles.pagination }>
            <div className={ styles.pageSelector }>
                {
                    !isVirtual
                    && <button onClick={ () => gotoPage(0) } disabled={ !canPreviousPage }>
                        <FontAwesomeIcon icon='angle-double-left' />
                    </button>
                }

                <button onClick={ previousPage } disabled={ !canPreviousPage }>
                    <FontAwesomeIcon icon='angle-left' />
                </button>

                <div className={ styles.pageEnter }>
                    <Input
                        onChange={ onChangePageNumberInput }
                        value={ pageIndex + 1 }
                        type='number'
                    />
                    <span>/ {pageCount}</span>
                </div>

                <button onClick={ nextPage } disabled={ !canNextPage }>
                    <FontAwesomeIcon icon='angle-right' />
                </button>

                {
                    !isVirtual
                    && <button onClick={ () => gotoPage(pageCount - 1) } disabled={ !canNextPage }>
                        <FontAwesomeIcon icon='angle-double-right' />
                    </button>
                }
            </div>


            <div className={ styles.rightPart }>
                <div className={ styles.pageSize }>
                    <Dropdown
                        isOpen={ isOpenPageSizeDropDown }
                        toggle={ () => setIsOpenPageSizeDropDown(!isOpenPageSizeDropDown) }
                        direction='up'
                    >
                        <DropdownToggle
                            outline
                            caret
                            color={ 'white' }
                        >
                            {pageSize}
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                pageSizes.map((limitItem, index) =>
                                    <DropdownItem
                                        onClick={ e => setPageSize(Number(e.target?.innerText)) }
                                        key={ index }
                                    >
                                        { limitItem }
                                    </DropdownItem>)
                            }
                        </DropdownMenu>
                    </Dropdown>
                    <span>
                        { t('pagination.perPage') }
                    </span>
                </div>

                <div className={ styles.pageInfo }>
                    <span>
                        {
                            t('pagination.totalRow', {
                                start: Number(filters.offset) + 1,
                                to: calculateNumberOfItems,
                                total: count,
                            })
                        }
                    </span>
                </div>
            </div>
        </div>
    );
};
