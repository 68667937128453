import { createSelector } from "reselect";
import { HelperService } from '/services';
import {
    layoutsSelector,
    phoneForSenderSelector,
    themeListSelector,
    SMSGatewaysOptionsSelector,
    activeSurveySelector,
    getSMSGatewaysChainWithBrokenItems,
} from '/feedback/modules/VocFeedback.selectors';
import { getSettings, modelKeys } from './SurveySettings.modules';
import { GatewaysSettingsService } from '/feedback/services';
import { themeSettingsSelector } from '../../../modules/survey.selectors';
import { SettingsService } from '/feedback/services/SettingsService';

export const getSurveyGroup = state => state.VocFeedback.survey.surveyCommon.id;
export const surveySettingsSelector = state => state.VocFeedback.survey.surveySettings;
export const getSurveySettingsLoading = state => state.VocFeedback.survey.surveySettings;

export const getSurveySettings = createSelector(
    [ surveySettingsSelector, themeSettingsSelector ],
    (surveySettings, themeSettings) => {
        //logic for DIGI
        const { global_theme, parent_unit_theme, survey_theme } = themeSettings;

        const globalTheme = global_theme ? getSettings(global_theme) : {};
        const parentUnitTheme = parent_unit_theme ? getSettings(parent_unit_theme) : {};
        const surveyTheme = survey_theme ? getSettings(survey_theme) : HelperService.getDefaultSettings(modelKeys['themeDIGI']);

        if (global_theme) {
            globalTheme['digi_theme'] = global_theme.id;
            surveyTheme['digi_theme'] = parent_unit_theme ? parent_unit_theme.id : null;
        }
        //end logic for DIGI

        return {
            ...surveySettings,
            globalSettings: {
                ...surveySettings.globalSettings,
                ...globalTheme,
                ...parentUnitTheme,
            },
            surveySettings: {
                ...surveySettings.surveySettings,
                ...surveyTheme,
            },
        };
    });

export const getActiveChannel = createSelector(activeSurveySelector, activeSurvey => activeSurvey || {});

export const fieldsMapSelector = createSelector([ getActiveChannel ], survey =>
    SettingsService.getFieldsByChannelSurveySettings(survey.channel),
);

export const initialFormValuesSelector = createSelector(
    [
        getActiveChannel,
        getSurveySettings,
        layoutsSelector,
        phoneForSenderSelector,
        fieldsMapSelector,
        themeListSelector,
        SMSGatewaysOptionsSelector,
    ],
    (activeChannel, {
        globalSettings,
        surveySettings,
    }, layouts, phoneForSender, fields, themeList, SMSGatewaysOptions) => {
        const settings = SettingsService.composeSurveySettings(globalSettings, {
            ...surveySettings,
        });

        const SMSGatewaysOptionsWithBrokenItems = getSMSGatewaysChainWithBrokenItems(surveySettings, settings, SMSGatewaysOptions);

        const layoutOptions = layouts.results.map(layout => ({
            value: layout.id,
            label: layout.name,
        }));

        const themeListOptions = themeList.map(template => ({
            value: template.id,
            label: template.name,
        }));

        const layoutFieldKey = 'SURVEY_LAYOUT_ID';
        const themeListKey = 'digi_theme';
        const SMSGatewaysKey = 'GATEWAYS_CHAIN';

        const getOptions = (key, options) => {
            if (key === layoutFieldKey) return layoutOptions;
            if (key === themeListKey) return themeListOptions;
            if (key === SMSGatewaysKey) return SMSGatewaysOptionsWithBrokenItems;

            return options;
        };

        return {
            fieldsMap: fields.map(field => ({
                ...field,
                children: field.children.map(child =>
                    ({
                        ...child,
                        options: getOptions(child.key, child.options),
                        redefined: settings[child.key] && settings[child.key].redefined,
                    })),
            })),
            initialValues: SMSGatewaysOptionsWithBrokenItems.length && settings['GATEWAYS_CHAIN']
                ? GatewaysSettingsService.addOptionsToInitial(settings, SMSGatewaysOptionsWithBrokenItems)
                : settings,
        };
    });

export const surveyFormSettingsSelector = createSelector([ getSurveySettingsLoading, initialFormValuesSelector ],
    ({ loading, sending }, fields) => ({
        loading,
        sending,
        ...fields,
    }));
