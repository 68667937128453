import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import DocumentTitle from 'react-document-title';
import { NavLink } from 'react-router-dom';
import { PageRequests } from '/services';
import { VocCleaupHOC, Preloader } from '/components';
import { SettingsForm } from "/feedback/components";
import { Col, Row } from 'reactstrap';
import { putGlobalLanguageSettings, getLanguageSettings, cleanup } from './modules/languagesSettings.modules';
import { advancedFormSettingsSelector } from "./modules/languagesSettings.selectors";
import { getEnableLanguages } from '../../modules/globalSettings.modules';
import {
    channelSelector,
    unitSelector,
    languagesSelector,
    activeLanguageSelector,
    getPermissionByActiveSelector,
} from '../../modules/globalSettings.selectors';
import { assetsSelector } from '/modules/uploadFileModules/uploadFile.selectors';
import { getAssets, uploadAsset } from '/modules/uploadFileModules/uploadFile.modules';

const mapStateToProps = state => ({
    advancedSettings: advancedFormSettingsSelector(state),
    globalLanguages: languagesSelector(state),
    activeLang: activeLanguageSelector(state),
    channel: channelSelector(state),
    unit: unitSelector(state),
    assets: assetsSelector(state),
    permission: getPermissionByActiveSelector(state),
});

const mapDispatchToProps = {
    putGlobalLanguageSettings,
    getEnableLanguages,
    getLanguageSettings,
    cleanup,
    getAssets,
    uploadAsset,
};

@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class LanguagesSettings extends Component {
    pageRequests = new PageRequests();

    componentDidMount() {
        const { unit = {}, channel, activeLang } = this.props;

        if (unit.value && channel) {
            this.getEnableLanguages();
            activeLang.language && this.getLanguageSettings();
        }
    }

    componentDidUpdate(prevProps) {
        const { unit, channel, activeLang } = this.props;

        if (unit.value !== prevProps?.unit?.value) {
            this.getEnableLanguages();
            activeLang?.language && this.getLanguageSettings();
        }

        if (channel !== prevProps?.channel || activeLang?.language !== prevProps?.activeLang?.language) {
            this.getLanguageSettings();
        }
    }

    getLanguageSettings() {
        const { unit, channel, getLanguageSettings, activeLang: { language } } = this.props;

        this.pageRequests.cleanupById('getLanguageSettings');
        const request = getLanguageSettings({ unit: unit.value, channel, language });

        this.pageRequests.addRequest(request, 'getLanguageSettings');
    }

    getEnableLanguages() {
        const { unit, channel, getEnableLanguages } = this.props;

        this.pageRequests.cleanupById('getEnableLanguages');
        const request = getEnableLanguages({ unit: unit.value, channel });

        this.pageRequests.addRequest(request, 'getEnableLanguages');
    }

    @autobind
    putLanguageSettings(body) {
        const { unit, putGlobalLanguageSettings, activeLang } = this.props;

        const model = {
            unit: unit.value,
            lang: activeLang.language,
            body,
        };

        return putGlobalLanguageSettings(model);
    }

    @autobind
    getSettings() {
        const {
            advancedSettings: {
                sending,
                loading,
                fieldsMap,
                initialValues,
            },
            activeLang,
            globalLanguages: {
                enableLanguage,
                enableLangLoading,
            },
            assets,
            getAssets,
            uploadAsset,
            permission,
        } = this.props;

        const isChecked = activeLang ? !activeLang.hasLanguageText : true;
        const hasActiveLanguages = !enableLanguage.length && !loading && !enableLangLoading && !activeLang.language;

        return (
            <Fragment>
                <Row>
                    {
                        hasActiveLanguages
                        && <Col md={ 12 } className={ 'enable-language__box' }>
                            <span className={ 'language-list__box' }> No active languages. Please select language first </span>
                        </Col>
                    }
                </Row>
                {
                    !isChecked
                    && <SettingsForm
                        fieldsMap={ fieldsMap }
                        initialValues={ initialValues }
                        putSettings={ this.putLanguageSettings }
                        sendingSettings={ sending }
                        form={ 'GlobalAdvancedSettingsForm' }
                        assets={ assets }
                        getAssets={ getAssets }
                        uploadAsset={ uploadAsset }
                        permission={ permission }
                    />
                }
            </Fragment>
        );
    }

    render() {
        const { advancedSettings, globalLanguages, channel } = this.props;

        return (
            <DocumentTitle title="Feedback :: Advanced Settings">
                <Fragment>
                    {
                        channel === 'DIGI'
                        && <span className='language-list__box--blue'>
                            { 'Please, visit ' }
                            <NavLink to={ "/feedback/settings/theme-list" }>Themes</NavLink>
                            { ' tab to customize your language settings' }
                        </span>
                    }
                    {
                        advancedSettings.loading || globalLanguages.enableLangLoading
                            ? <Preloader/>
                            : this.getSettings()
                    }
                </Fragment>
            </DocumentTitle>
        );
    }
}
