import { createSelector } from 'reselect';
import { HelperService, UsersRoleFeedbackService } from '/services';

export const userInfoSelector = state => state.UserInfo.userInfo.isSupportUser;
export const _getUnitsListSelector = state => state.VocFeedback.common.unitsList.results;
export const themeListSelector = state => state.VocFeedback.common.themeList.results;
export const unsubscribeSurveysSelector = state => state.VocFeedback.common.unsubscribeSurveys;
export const getEnterpriseId = state => state.UserInfo.userInfo.enterpriseId;

export const feedbackJwtSelector = state => state.UserInfo.userInfo.JWT;
export const channelListSelector = state => state.VocFeedback.common.channelList.results;

export const feedbackApiUrlSelector = state => state.UserInfo.userInfo.feedbackApiUrl;

export const commonLanguageSelector = state => {
    return state.VocFeedback.common.languages.results
        ? state.VocFeedback.common.languages.results
        : [];
};

export const getUnitId = state => state.VocFeedback.common.unitsList.results[0].id;

export const statesSelector = state => state.VocFeedback.common.states;
export const surveyStatesSelector = state => statesSelector(state).survey;

export const loaderSelector = state => (state.VocFeedback.common.states.loading
    || state.VocFeedback.common.languages.loading
    || state.VocFeedback.common.unitsList.loading
    || state.VocFeedback.common.questionTypes.loading
    || state.VocFeedback.common.questionTypeOptions.loading);

export const layoutsSelector = state => state.VocFeedback.common.layouts;
export const phoneForSenderSelector = state => state.VocFeedback.common.phoneForSender;
export const SMSGatewaysListSelector = state => state.VocFeedback.common.SMSGatewaysList.results;

export const surveyListLoading = state => {
    const { surveyList, surveysWithDeleted } = state.VocFeedback.common;

    return surveyList.loading || surveysWithDeleted.loading;
};

export const SMSGatewaysOptionsSelector = createSelector([ SMSGatewaysListSelector ], gateways => {
    return gateways.map(gateway => ({
        label: gateway.name,
        value: gateway.name,
        sms_senders: gateway.sms_senders.map(sender => ({
            label: sender.name,
            value: sender.phone,
        })),
    }));
});

export const questionTypesSelector = state => state.VocFeedback.common.questionTypes.results.map(question => ({
    ...question,
    channel: question.protocol === 'VOICE' ? 'IVR' : question.protocol === 'EMAIL' ? 'WEB' : question.protocol,
}));

export const questionTypeOptionsSelector = state =>
    state.VocFeedback.common.questionTypeOptions?.results.map(option => ({
        ...option,
        values: option.values ? option.values.map(value => ({
            label: value,
            value: String(value).toLowerCase(),
        })) : undefined,
    }));

export const possibleRangesSelector = () => [
    { label: 'Creation Date', value: 'creation' },
    { label: 'Response Date', value: 'end_time' },
];

export const dateRangesSelector = () => [
    {
        value: 'today',
        label: 'Today',
    },
    {
        value: 'yesterday',
        label: 'Yesterday',
    },
    {
        value: 'this_week',
        label: 'This week',
    },
    {
        value: 'last_week',
        label: 'Last week',
    },
    {
        value: 'this_month',
        label: 'This month',
    },
    {
        value: 'last_month',
        label: 'Last month',
    },
    {
        value: 'this_year',
        label: 'This year',
    },
    {
        value: 'last_year',
        label: 'Last year',
    },
    {
        value: 'all',
        label: 'All',
    },
];

export const commonSelector = state => state.VocFeedback.common;

export const activeSurveySelector = state => {
    const surveyGroup = state.VocFeedback.survey.surveyCommon || {};
    const { activeChannel } = surveyGroup;
    const activeSurvey = surveyGroup.surveys.find(survey => survey.channel === activeChannel);
    const unit = HelperService.deepFind(surveyGroup, 'unit.id');
    const firstSurvey = surveyGroup.surveys[0] || {};

    return activeSurvey
        ? {
            ...activeSurvey,
            unit,
            survey_group: surveyGroup.id,
        } : {
            ...firstSurvey,
            unit,
            survey_group: surveyGroup.id,
        };
};

//TimeSlots
export const timeSlotOptions = [
    { label: 'Periodic slots', value: 'regular' },
    { label: 'Custom events', value: 'specific' },
];

export const timeSlotDataSelector = () => ({
    options: timeSlotOptions,
    selected: timeSlotOptions.find(({ value }) => value === 'regular'),
});

export const channelSelector = state => state.VocFeedback.globalSettings.common.channel;
export const unitSelector = state => state.VocFeedback.globalSettings.common.unit;
export const channelUnitSelector = createSelector(
    [ unitSelector, channelSelector, activeSurveySelector ],
    (unitGlobal, activeChannelGlobal, { id = null, channel, unit }) => {
        return id
            ? {
                survey_id: id,
                unit,
                activeChannel: channel,
            }
            : {
                unit: unitGlobal ? unitGlobal.value : '',
                activeChannel: activeChannelGlobal,
                survey_id: null,
            };
    });

// Quarantine
export const metaDataSelector = state => state.VocFeedback.common.metaData.results;

export const metaDataToOptionSelector = createSelector([ metaDataSelector ], data => data.map(({ name }) => ({
    label: name,
    value: name,
    question_type: 'metadata',
    condition_type: 'metadata',
})));

export const mathExpression = [{
    label: 'equal',
    value: '==',
}, {
    label: 'not equal',
    value: '!=',
}, {
    label: '>=',
    value: '>=',
}, {
    label: '<=',
    value: '<=',
}, {
    label: '>',
    value: '>',
}, {
    label: '<',
    value: '<',
}];

export const expressionTypes = [
    {
        label: 'contains',
        value: 'contains',
    },
    ...mathExpression,
    {
        label: 'is empty',
        value: 'is_empty',
    }, {
        label: 'is not empty',
        value: 'is_not_empty',
    },
];

export const logicalOperation = [
    { label: 'And', value: 'and' },
    { label: 'Or', value: 'or' },
];

export const optionRandom100 = {
    value: 'random_100',
    label: 'Random 1 - 100',
    condition_type: 'random_100',
};

const optionAllSurveys = {
    condition_type: 'survey_group',
    label: 'All surveys',
    value: 'null',
};

export const optionsBlockedBy = {
    additional: [{ ...optionRandom100 }],
    survey: [{ ...optionAllSurveys }],
};

export const exception = [ 'is_empty', 'is_not_empty' ];

export const actionType = [
    { label: 'Block', value: 'BLOCK', text: 'Block' },
    { label: 'Delay', value: 'DELAY', text: 'Delay' },
];

export const surveyExpressionTypes = [
    {
        id: 1,
        label: 'Days since last completion',
        conditionLabel: 'since last completion',
        value: 'passed_days_since_completion',
    },
    {
        id: 2,
        label: 'Days since last invitation',
        conditionLabel: 'since last invitation',
        value: 'passed_days_since_invitation',
    },
    {
        id: 3,
        label: 'Days until next invitation',
        conditionLabel: 'until next invitation',
        value: 'will_be_invited_in_days',
    },
];

export const transformQuarantineConditionsToReduxForm = conditions => {
    const getBlockedFormData = value => {
        let data = {};

        if (value === null) {
            data = { ...optionAllSurveys };
        } else if (value) {
            data.condition_type = 'survey_group';
            data.label = value.name;
            data.value = value.id;
        }

        return data;
    };

    return conditions.map(({ value, condition_type }) => {
        if (condition_type === 'survey_group') {
            return {
                blocked_by: getBlockedFormData(value.blocking_survey_group),
                expression: getSurveyExpressionType(value.expression),
                matching_value: value.days,
            };
        }

        return {
            blocked_by: condition_type === 'random_100'
                ? { ...optionRandom100 }
                : {
                    label: value.metadata_name,
                    value: value.metadata_name,
                    condition_type: 'metadata',
                },
            expression: getMetaExpressionType(value.expression),
            matching_value: value.matching_value,
        };
    });
};

const getSurveyExpressionType = type => {
    return surveyExpressionTypes.find(item => item.value === type);
};

const getMetaExpressionType = type => {
    return expressionTypes.find(item => item.value === type);
};

// COMMON_SETTINGS
const getClassifierList = state => state.VocFeedback.common.classifier.results;

export const classifierOptionSelector = createSelector(
    [ getClassifierList ],
    classifierList =>
        classifierList.map(classifier => ({ value: classifier.classifierId, label: classifier.title })));

// END_COMMON_SETTINGS

export const getSMSGatewaysChainWithBrokenItems = (settings, globalSettings, SMSGatewaysOptions) => {
    const brokenSmsOptions = settings.GATEWAYS_CHAIN?.reduce((acc, item) => {
        const validOption = SMSGatewaysOptions.find(option => option.label === item.name);

        if (!validOption) {
            acc.push({
                label: item.name,
                value: item.name,
                isDisabled: true,
                badge: {
                    color: 'danger',
                    title: 'wrongly configured',
                },
                sms_senders: [
                    ...item.sms_senders.map(item => {
                        return {
                            label: item.name,
                            value: item.phone,
                        };
                    }),
                ],
            });
        }

        return acc;
    }, []) || [];
    const SMSGatewaysOptionsWithBrokenItems = [
        ...SMSGatewaysOptions,
        ...brokenSmsOptions,
    ];

    globalSettings.GATEWAYS_CHAIN?.smsSendersValue?.forEach(item => {
        const [ value, name ] = item.split('[::]');

        SMSGatewaysOptionsWithBrokenItems.forEach(option => {
            const sender = option.sms_senders?.find(sender => sender.value === value);

            if (option.value === name && !sender) {
                option.sms_senders.push({
                    label: value,
                    value,
                    isDisabled: true,
                    badge: {
                        color: 'danger',
                        title: 'wrongly configured',
                    },
                });
            }
        });
    });

    return SMSGatewaysOptionsWithBrokenItems;
};

// ------------------------------------------------------------------------------
const userPermissionSelector = state => state.VocFeedback.common.userPermission;
const getCurrentUserInfo = state => state.UserRights.currentUser.currentUserData;

export const unitsListSelector = createSelector(
    [ _getUnitsListSelector, userPermissionSelector, userInfoSelector ],
    (units, userPermission, isSupportUser) => {
        let results = [];

        if(isSupportUser) {
            results = [
                { id: null, name: 'All units' },
                ...units.map(unit => {
                    return {
                        ...unit,
                        permissionsId: 1,
                        permissionsName: "Manager",
                        rights: UsersRoleFeedbackService.getRightsForAll("Manager"),
                    };
                }),
            ];
        } else if(userPermission.loaded) {
            const filteredUnits = units.map(unit => {
                const permissionForUnit = userPermission.mainPermission.find(
                    permission => permission.unit === unit.id,
                );

                return permissionForUnit
                    ? {
                        ...permissionForUnit,
                        ...unit,
                        rights: UsersRoleFeedbackService.getRightsForAll(permissionForUnit.permissionsName),
                    }
                    : undefined;
            }).filter(unit => unit);

            results = [
                ...filteredUnits.length === units.length ? [{ id: null, name: 'All units' }] : [],
                ...filteredUnits,
            ];
        }

        return results;
    },
);

export const availableUnitsId = createSelector(
    [ unitsListSelector ], units => {
        return units.filter(unit => unit.id !== null).map(unit => unit.id);
    });

export const dataForPermissionSelector = createSelector(
    [ _getUnitsListSelector, getCurrentUserInfo, userPermissionSelector ],
    (units, { ssoId, groups }, userPermission) => {
        return {
            unitIds: units?.reduce((acc, el) => [ ...acc, el.id ], []),
            groupIds: groups?.reduce((acc, el) => [ ...acc, el.id ], []),
            userSsoId: ssoId,
            userPermission,
        };
    });

export const unitOptions = createSelector(
    [ _getUnitsListSelector ],
    units => units.map(unit => ({ value: unit.id, label: unit.name })),
);
