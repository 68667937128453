import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { VocSelect, InputLoader, SourcePaginateSelectOption } from '/components';
import { GadgetService, useDrillDown } from '/visual/scenes/Dashboard/components';
import { ButtonComponent } from '/visual/components';
import {
    IDrillDashboardConfiguration,
    drillDownOptionType,
    drillDownStateType,
} from '/visual/scenes/Dashboard/components/Gadget/models';
import { HelperService } from '/services';

import styles from './style.module.scss';

export const DrillDashboardConfiguration = ({
    loading,
    gadget,
    chart,
    settings = [],
}: IDrillDashboardConfiguration) => {
    const { t } = useTranslation();
    const [ state, setState ] = useState<drillDownStateType>({
        superGroupOptions: [],
        groupOptions: [],
        withSuperGroup: false,
        withoutStandardGroups: false,
        initSelectedSuperGroup: null,
        initSelectedGroup: null,
        selectedSuperGroup: null,
        selectedGroup: null,
        isDirty: false,
    });
    const { getDrillDownDashboard } = useDrillDown({ gadgetData: gadget });

    const getOpts = (opt: drillDownOptionType | null) => opt?.list || [];

    const getSelectedOption = (superGroupValue: string | null | undefined, options: drillDownOptionType[]) => {
        if (superGroupValue === null || superGroupValue === undefined) return null;

        const stringSuperGroupValue = superGroupValue.toString();

        return options.find((opt: drillDownOptionType) => {
            return stringSuperGroupValue === 'No value'
                ? opt.label.toString() === stringSuperGroupValue
                : opt.value.toString() === stringSuperGroupValue;
        }) || null;
    };

    const handleChangeSuperGroup = (e: drillDownOptionType) => {
        const {
            initSelectedSuperGroup,
            initSelectedGroup,
            selectedGroup,
            withoutStandardGroups,
        } = state;

        // check if field changed
        const dirty = initSelectedSuperGroup?.value !== e.value
            || initSelectedGroup?.value !== selectedGroup?.value;

        // get options for group by super group
        setState(prevState => ({
            ...prevState,
            groupOptions: !withoutStandardGroups
                ? getOpts(e)
                : [],
            // change super group value
            selectedSuperGroup: e,
            // clear group value
            selectedGroup: dirty && !withoutStandardGroups
                ? null
                : prevState.selectedGroup,
            isDirty: dirty,
        }));
    };

    const handleChangeGroup = (e: drillDownOptionType) => {
        const { initSelectedGroup, initSelectedSuperGroup, selectedSuperGroup } = state;

        // check if field changed
        const dirty = initSelectedGroup?.value !== e.value
            || initSelectedSuperGroup?.value !== selectedSuperGroup?.value;

        setState(prevState => ({
            ...prevState,
            // change group value
            selectedGroup: e,
            isDirty: dirty,
        }));
    };

    const handleConfirm = async () => {
        const {
            selectedGroup,
            selectedSuperGroup,
            withoutStandardGroups,
        } = state;

        const group = { id: selectedGroup ? selectedGroup.value : null };
        const superGroup = {
            id: selectedSuperGroup && !withoutStandardGroups
                ? selectedSuperGroup.value
                : null,
        };

        if (withoutStandardGroups && selectedSuperGroup) {
            group.id = selectedSuperGroup.value;
        }

        getDrillDownDashboard({ group, superGroup }, true);
    };

    useEffect(() => {
        if (gadget && !loading) {
            const { chartType } = gadget.contentSettings;
            const { function: func } = gadget.dataSettings;
            const formattedData = GadgetService.getFormattedDataByChartType({
                chartType,
                dataForFormatting: {
                    chartData: chart.chartData,
                    gadgetData: gadget,
                    forSelectorOnly: true,
                },
            });
            // get all selectors
            const selectors = GadgetService.getListPropertyByChartType({
                chartType,
                formattedData: formattedData,
            });

            // get super group from existed settings of dashboard
            const settingsSuperGroup = settings.find(({ field }) => field === 'selectedSuperGroup');
            // get group from existed settings of dashboard
            const settingsGroup = settings.find(({ field }) => field === 'selectedGroup');

            // check if super group selection need to show
            const withSuperGroup = !!settingsSuperGroup;
            // if math function that used in parent gadget not "count"
            // show only math function inside selector and disable it
            const withoutStandardGroups = !settingsGroup;

            const selectedSuperGroup = getSelectedOption(settingsSuperGroup?.value, selectors);

            let groupOptions: any[] = [];

            if (HelperService.checkNotNullOrUndefined(settingsGroup?.value)) {
                groupOptions = selectedSuperGroup ? getOpts(selectedSuperGroup) : selectors;
            }

            const selectedGroup = withoutStandardGroups
                ? getSelectedOption(func, [{ value: func, label: func.charAt(0).toUpperCase() + func.slice(1) }])
                : getSelectedOption(settingsGroup?.value, groupOptions);

            const initSelectedSuperGroup = selectedSuperGroup || null;
            const initSelectedGroup = selectedGroup || null;

            const sortedGroupOptions = HelperService.sortArrayWithObjects(
                groupOptions,
                'label',
                'asc',
                true,
            );

            // set init options for selectors
            setState(prevState => ({
                ...prevState,
                superGroupOptions: withSuperGroup ? selectors : [],
                groupOptions: !withSuperGroup ? selectors : sortedGroupOptions,
                withSuperGroup: withSuperGroup,
                withoutStandardGroups: withoutStandardGroups,
                // save the initial value of selectors
                initSelectedSuperGroup,
                initSelectedGroup,
                // set existed super group and group to the selects
                selectedSuperGroup: initSelectedSuperGroup,
                selectedGroup: initSelectedGroup,
                isDirty: false,
            }));
        }
    }, [ settings ]);

    const getLabel = () => {
        let superGroupLabel, groupLabel;
        const { groupByName, secondGroupByName, factName } = gadget.dataSettings;

        if (secondGroupByName && groupByName) {
            superGroupLabel = secondGroupByName;
            groupLabel = groupByName;
        } else if (!secondGroupByName && groupByName) {
            superGroupLabel = groupByName;
            groupLabel = factName;
        } else {
            groupLabel = factName;
        }

        return { groupLabel, superGroupLabel };
    };

    return loading
        ? <InputLoader
            className={ cx('global-settings__lang') }
            withLabelCol={ false }
        />
        : <div className={ cx(styles.configurationContainer) }>
            {
                state.withSuperGroup
                && state.selectedSuperGroup
                && <VocSelect
                    className={ cx(styles.configurationSelect) }
                    disabled={ !state.withSuperGroup || chart.needToUpdate }
                    name={ 'superGroup' }
                    placeholder={ 'Super Group' }
                    options={ state.superGroupOptions }
                    value={ state.selectedSuperGroup }
                    isSearchable
                    withTooltip
                    label={ getLabel().superGroupLabel }
                    components={{
                        Option: SourcePaginateSelectOption,
                    }}
                    onChange={ handleChangeSuperGroup }
                />
            }

            <VocSelect
                className={ cx(styles.configurationSelect) }
                disabled={
                    state.withSuperGroup
                    && !state.selectedSuperGroup
                    || chart.needToUpdate
                    || state.withoutStandardGroups
                }
                name={ 'group' }
                placeholder={ 'Group' }
                options={ state.groupOptions }
                value={ state.selectedGroup }
                isSearchable
                withTooltip
                label={ getLabel().groupLabel }
                components={{
                    Option: SourcePaginateSelectOption,
                }}
                onChange={ handleChangeGroup }
            />

            <div className={ cx(styles.configurationBtnWrapper) }>
                <ButtonComponent
                    buttonProps={{
                        outline: false,
                        color: 'primary',
                        disabled: (state.withSuperGroup && !state.selectedSuperGroup)
                            || !state.selectedGroup
                            || !state.isDirty
                            || chart.needToUpdate,
                        onClick: handleConfirm,
                    }}
                    tooltipProps={{
                        value: t('confirm'),
                        force: true,
                    }}
                    icon={ 'check' }
                />
            </div>
        </div>;
};
