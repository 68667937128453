import React, { Component } from 'react';

import { Label, FormGroup, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { HelperService } from '/services';

import './datepicker-vendor.scss';
import './FormDatePickerItem.scss';

import { autobind } from 'core-decorators';

import moment from 'moment';

export class FormDatePickerItem extends Component {
    state = {
        value: this.props.value,
    };

    pickerRef = React.createRef();

    @autobind
    clickOutside() {
        const { input } = this.pickerRef.current;
        const currentDate = new Date();

        this.pickerRef.current.cancelFocusInput();
        this.pickerRef.current.setOpen(false);

        if (!moment(input.value, HelperService.capturedAtMask, true).isValid()) {
            this.props.handleChange(currentDate);
            this.pickerRef.current.setSelected(currentDate);
        }
    }

    @autobind
    handleChange(value) {
        this.setState({
            value: value,
        });

        this.props.handleChange(value);
    }

    render() {
        const {
            controlId,
            isHorizontal,
            label,
        } = this.props;

        return (
            <div className="voc-datepicker form-datepicker__item">
                <FormGroup id={ controlId }>
                    <Row>
                        <Col md={ isHorizontal ? 3 : 12 }
                            className="datepicker-control-label">
                            <Label>{ label }</Label>
                        </Col>
                        <Col md={ isHorizontal ? 9 : 12 }>
                            <DatePicker
                                ref={ this.pickerRef }
                                className="form-control col-md-12"
                                selected={ this.state.value || new Date() }
                                dateFormat={ HelperService.datePickerUploadMask }
                                onClickOutside={ this.clickOutside }
                                onChange={ this.handleChange }
                                onBlur={ this.clickOutside }
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        );
    }
}

FormDatePickerItem.propTypes = {
    value: PropTypes.object,
    label: PropTypes.string.isRequired,
    controlId: PropTypes.string,
    handleChange: PropTypes.func,
    isHorizontal: PropTypes.bool,
};
