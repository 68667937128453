import { createSelector } from 'reselect';

import { HelperService, QueryExecuteService, UsersRoleService } from '/services';
import { _getAllUserInfo } from '/modules/selectors';
import { topicsLoading } from '/modules/topicSetModules/topicSet.selectors';
import { getQueryTagsSelector } from '/mine/modules/vocMine.selectors';

export const getLabelClassByType = state => {
    return ({
        active: 'success',
        deleted: 'danger',
        paused: 'warning',
        upload: 'success',
        virtual: 'info',
        survey: 'info',
        system: 'info',
        info: 'info',
        success: 'success',
        danger: 'danger',
        inactive: 'warning',
    })[state];
};

const filtersDictionary = [
    {
        name: 'Classification results',
        id: 'classification',
        labelForm: 'Filter by classification result',
        initialFilters: {
            condition: "or",
            filters: [],
        },
    },
    {
        name: 'Filter by date',
        id: 'date',
        labelForm: 'Filter by date',
        withoutCondition: true,
        initialFilters: {
            from: null,
            to: null,
            column: {},
        },
    },
    {
        name: 'Lemmata to include',
        id: 'lemmaToInclude',
        labelForm: 'Lemmas to include',
        initialFilters: {
            condition: "or",
            values: [],
        },
    },
    {
        name: 'Lemmata to exclude',
        id: 'lemmaToExclude',
        labelForm: 'Lemmas to exclude',
        initialFilters: {
            condition: "or",
            values: [],
        },
    },
    {
        name: 'Text include',
        id: 'textToInclude',
        labelForm: 'Text to include',
        initialFilters: {
            condition: "or",
            values: [],
        },
    },
    {
        name: 'Text to exclude',
        id: 'textToExclude',
        labelForm: 'Text to exclude',
        initialFilters: {
            condition: "or",
            values: [],
        },
    },
    {
        name: 'Filter by attribute',
        id: 'attributes',
        labelForm: 'Filter by attribute',
        initialFilters: {
            condition: "or",
            filters: [],
        },
    },
    {
        name: 'Advanced query',
        id: 'advanced',
        labelForm: 'Advanced query',
        initialFilters: {
            condition: "or",
            filters: [],
        },
    },
];

const defaultDateAttribute = {
    label: `Default date ("Questionnaire response" / "Captured at")`,
    originType: "DATE",
    parameters: null,
    skipOptions: false,
    value: null,
};

const withStrokeStyles = (arr, min = .1, max = 4) => {
    const maxValue = arr.reduce((acc, value) => acc > value.value
        ? acc : value.value
    , 0);

    const isInt = n => n % 1 === 0;

    return arr.map(({ value, ...rest }) => {
        const percent = (value * 100) / maxValue;
        const strokeWidth = percent * ((max - min) / 100) + min;
        const strokeOpacity = percent * ((1 - .1) / 100) + .1;

        return {
            ...rest,
            value: isInt(value) ? value : value.toFixed(3),
            strokeWidth,
            strokeOpacity,
        };
    });
};

const getGraphData = ({ nodesData, linksData }) => ({
    nodes: nodesData,
    links: withStrokeStyles(linksData),
});

const _getQuery = state => state.VocMine.query;
const _getAllQueries = state => _getQuery(state).queries;
const _getLemmataPercentage = state => state.VocMine.query.lemmataCoOccurrence.filters.limit;
const _getDirtyFilters = state => state.VocMine.query.dirtyFilters;

export const textFiltersQuerySelector = state => state.VocMine.query.textsData.filters;
export const lemmataFiltersQuerySelector = state => state.VocMine.query.lemmataData.filters;
export const lemmataColoudFiltersQuerySelector = state => state.VocMine.query.lemmataWordCloud.filters;
export const zeroShotExportDataSelector = state => _getQuery(state).zeroShotExportData;

export const dirtyFiltersSelector = state => _getQuery(state).dirtyFilters;
export const getDirtyFormSelector = state => _getQuery(state).isDirtyForm;
export const needRunFormSelector = state => _getQuery(state).needRunForm;
export const _getQueryDictionariesLoading = state => state.VocMine.query.dictionaries.loading;
export const selectedDictionariesIdsSelector = state => state.VocMine.query.selectedDictionaries;
export const textInfoSelector = ({ VocMine: { query: { textInfo } } }) => {
    return {
        ...textInfo,
        topicSet: textInfo.topicSet
            ? Object.keys(textInfo.topicSet).map(topicIndex => ({
                id: textInfo.topicSet[topicIndex],
                name: topicIndex,
                value: textInfo.topicSet[topicIndex],
            }))
            : [],
    };
};
export const textInfoLoadingSelector = state => state.VocMine.query.textInfoLoading;
const coOccurrenceSelector = state => state.VocMine.query.lemmataCoOccurrence;
const correlationSelector = state => state.VocMine.query.lemmataCorrelation;

export const _getQueryInfo = state => state.VocMine.query.queryInfo;

export const coOccurrenceLoadingSelector = state => state.VocMine.query.lemmataCoOccurrenceLoading;
export const correlationLoadingSelector = state => state.VocMine.query.lemmataCorrelationLoading;

export const coOccurenceDataSelector = createSelector(
    [ coOccurrenceSelector ],
    ({ cooccurrence, error }) => ({ ...getGraphData(cooccurrence), error }),
);

export const correlationDataSelector = createSelector(
    [ correlationSelector ],
    ({ correlation, error }) => ({ ...getGraphData(correlation), error }),
);

const getFormattedDictionaries = (dictionary = [], results) => results
    .filter(({ id }) => dictionary.includes(id))
    .map(({ id, title, language, user: { userName } }) => ({ value: id, label: title, language, userName }));

export const selectedDictionariesSelector = createSelector(
    [ _getQuery ],
    ({ selectedDictionaries: { synonyms, stopWords }, dictionaries: { results } }) => {
        return ({
            synonyms: getFormattedDictionaries(synonyms, results),
            stopWords: getFormattedDictionaries(stopWords, results),
        });
    },
);

const changeNullInFilters = (filter, id) => {
    return id === 'classification' || id === 'attributes'
        ? {
            ...filter,
            filters: filter.filters.map(el => {
                let filter = { ...el };

                if (el.values) {
                    filter = {
                        ...QueryExecuteService.convertNullToValue(el),
                    };
                }
                return {
                    ...filter,
                };
            }),
        }
        : filter;
};

export const dictionariesSelectOptionsSelector = createSelector(
    [ _getQuery, _getQueryInfo ],
    ({ dictionaries: { results } }, { language }) => {
        const queryLanguage = language;
        const options = results.reduce((acc, { id, title, language, user: { userName } }) =>
            language === queryLanguage
                ? [
                    ...acc,
                    {
                        value: id,
                        label: title,
                        language,
                        userName,
                    },
                ]
                : acc,
        []);

        return {
            synonyms: options,
            stopWords: options,
        };
    },
);

export const queryIdSelector = state => state.VocMine.query.queryId;
export const queryInfoSelector = createSelector(
    [ _getQuery, _getAllUserInfo ],
    (
        { queryId, queryInfo, isSavingQuery },
        { allUser, allGroups, currentUser },
    ) => {
        const currentUserId = currentUser.currentUserData.ssoId;
        const allUsers = queryInfo.shares ? [
            { ...queryInfo.user, ownerId: queryInfo.user.ssoId, permission: "owner" },
            ...HelperService.sortObject(queryInfo.shares, 'ownerType', [ 'group', 'user' ]),
        ] : [];

        const reComposedUsers = UsersRoleService.getUsersByRole(
            allUsers, currentUserId,
            { allUser, allGroups },
        );

        const attributes = queryInfo.title && [
            ...queryInfo.source.attributes.map(({
                id,
                name,
                options,
                originType,
                parameters,
                skipOptions,
            }) => {
                const isScaleType = originType === 'scale';

                return {
                    parameters: isScaleType ? parameters : null,
                    label: name,
                    value: id,
                    originType,
                    skipOptions,
                    attrOptions: isScaleType
                        ? null
                        : HelperService.mapObjToSelectWithNull(options, 'id', 'name', 'no value'),
                };
            },
            ),
            defaultDateAttribute,
        ];

        const isSystemChannel = queryInfo.title
            ? queryInfo.source.channel.toLowerCase()
            : false;

        return queryInfo.title
            ? {
                isSavingQuery,
                queryId,
                useInVisual: queryInfo.useInVisual,
                shared: {
                    queryId,
                    userRights: reComposedUsers.allUsers,
                },
                title: queryInfo.title,
                attributes,
                tag: queryInfo.tag,
                context: queryInfo.context,
                userPermission: queryInfo.currentUserPermission,
                sourceTitle: queryInfo.source ? queryInfo.source.title : '',
                sourceId: queryInfo.source ? queryInfo.source.id : '',
                typeColor: getLabelClassByType(queryInfo.source.type),
                type: isSystemChannel ? null : queryInfo.source.type.toUpperCase(),
                channelStatusColor: isSystemChannel
                    ? 'info'
                    : getLabelClassByType(queryInfo.source.state ? queryInfo.source.state.toLowerCase() : 'success'),
                source: {
                    ...queryInfo.source,
                    channel: queryInfo.source.channel.toUpperCase(),
                },
                lang: queryInfo.source ? [ ...HelperService.mapObjToSelectWithNull(queryInfo.source.languages, 'id', 'name', 'no value') ] : [],
                langSelected: { value: queryInfo.language, label: queryInfo.language },
                actions: {
                    id: queryId,
                    columnIds: queryInfo.columnIds,
                    language: queryInfo.language,
                    userId: queryInfo.user.ssoId,
                    source: {
                        id: queryInfo.source.id,
                    },
                },
                textColumns: queryInfo.source
                    ? [ ...queryInfo.source.attributes.filter(attr => queryInfo.columnIds.find(id => id === attr.id)) ]
                    : [],
            }
            : {
                isSavingQuery,
                langSelected: {
                    value: '',
                    label: '',
                },
            };
    });

export const queryFiltersSelector = createSelector([ _getQuery ], ({ queryInfo, dirtyFilters, filters }) => {
    return filtersDictionary.map(filter => {
        const initialValues = dirtyFilters[filter.id] ? changeNullInFilters(dirtyFilters[filter.id], filter.id) : null;

        return {
            ...filter,
            sourceId: queryInfo.source && queryInfo.source.id,
            initialValues,
            filter: filters[filter.id],
        };
    });
});

export const getClassificationSelector = createSelector(
    [ _getQuery ],
    ({ classificationLoading, classification }) => {
        return {
            classificationLoading,
            options: [
                ...classification.map(({ id, name, options }) => {
                    return {
                        value: id,
                        label: name,
                        matchingOptions: [
                            ...HelperService.mapObjToSelectWithNull(options, 'id', 'name', 'no value'),
                        ],
                    };
                }),
            ],
        };
    });

export const queriesSelector = createSelector([ _getAllQueries ], queries =>
    [ ...HelperService.mapObjToSelect(queries, 'id', 'title') ]);

export const textCountStatusSelector = createSelector([ _getQuery ],
    ({ lemmataCoOccurrence, lemmataCorrelation, lemmataData, lemmataWordCloud, textsLoading, textsData }) => {
        const count = Math.max(textsData.textsCount, lemmataData.textsCount, lemmataWordCloud.textsCount,
            lemmataCoOccurrence.textsCount, lemmataCorrelation.textsCount);
        const allTextsCount = Math.max(textsData.allTextsCount, lemmataData.allTextsCount, lemmataWordCloud.allTextsCount,
            lemmataCoOccurrence.allTextsCount, lemmataCorrelation.allTextsCount);
        const percent = (count / allTextsCount) * 100;
        const textsPercentage = Math.round(percent ? percent : 0);

        return ({
            textsLoading,
            count,
            allTextsCount,
            textsPercentage,
        });
    },
);

export const getLoadingData = createSelector([ _getQuery, _getQueryDictionariesLoading, topicsLoading ],
    ({
        textsLoading,
        lemmataLoading,
        lemmataWordCloudLoading,
        isSavingQuery,
        lemmataCoOccurrenceLoading,
        lemmataCorrelationLoading,
    }, dictionariesLoading, topicsLoading) => (
        textsLoading || lemmataLoading || lemmataWordCloudLoading || isSavingQuery || lemmataCoOccurrenceLoading
        || lemmataCorrelationLoading || dictionariesLoading || topicsLoading
    ),
);

export const getQueryText = createSelector([ _getQuery ], ({ textsData, textsLoading }) => ({
    ...textsData,
    textsLoading,
    total: textsData.textsCount,
    filters: {
        ...textsData.filters,
    },
}),
);

export const getQueryLemmata = createSelector([ _getQuery ], ({ lemmataData, lemmataLoading }) => ({
    ...lemmataData,
    lemmataLoading,
    total: lemmataData.lemmataCount,
    filters: {
        ...lemmataData.filters,
    },
}),
);

export const getQueryLemmataLimitForCoOccurrence = createSelector([ _getQuery ], ({ lemmataCoOccurrence }) => lemmataCoOccurrence.filters.limit);

export const getQueryLemmataLimitForCorrelation = createSelector([ _getQuery ], ({ lemmataCorrelation }) => lemmataCorrelation.filters.limit);

export const getQueryCorrelationLimit = createSelector([ _getQuery ], ({ lemmataCorrelation }) => lemmataCorrelation.correlationLimit);

export const getQueryLemmataForCloud = createSelector([ _getQuery ], ({ lemmataWordCloud, lemmataWordCloudLoading }) => {
    return {
        ...lemmataWordCloud,
        lemmataWordCloudLoading,
        lemmata: lemmataWordCloud.lemmata.map(({ _id, count }) => ({
            text: _id,
            count,
        })),
        filters: {
            ...lemmataWordCloud.filters,
        },
    };
});

export const classifierListSelector = createSelector(
    [ _getQuery ],
    ({ classifierList, exportSending }) => {
        return {
            exportSending,
            ...classifierList,
            data: [
                ...classifierList.data.map(classifier => {
                    return {
                        value: classifier.classifierId,
                        label: classifier.title,
                        category: [
                            ...classifier.categories.map(category => ({ value: category.categoryId, label: category.title })),
                        ],
                    };
                }),
            ],
        };
    });

export const requestBodyQuerySelector = createSelector(
    [ _getQueryInfo, _getDirtyFilters, _getQuery ],
    ({ language }, dirtyFilters, { selectedDictionaries }) => {
        return {
            filters: dirtyFilters,
            language,
            ...selectedDictionaries,
        };
    });

export const requestQueryPercentageSelector = createSelector([ _getLemmataPercentage ], limit => {
    return { percentage: limit / 5 };
});

export const socketDataSelector = createSelector(
    [ _getQuery ],
    ({ actionName, socketQueryId }) => {
        return {
            actionName,
            socketQueryId,
        };
    });

export const getQueryTagsOptionSelector = createSelector(
    [ getQueryTagsSelector ],
    ({ data }) => {
        return HelperService.mapArrayToSelectModel(
            data.filter(tag => HelperService.checkNotNullOrUndefined(tag)),
        );
    });

export const insightDataSelector = createSelector([ _getQuery ], query => {
    const {
        textsLoading, lemmataLoading, lemmataCoOccurrenceLoading, lemmataCorrelationLoading,
        lemmataCorrelation, textsData, lemmataCoOccurrence, lemmataData,
        insightNarrator: { texts, lemmata, coOccurrence, correlation },
    } = query;

    return {
        textsInsight: {
            loading: textsLoading,
            error: textsData.error,
            ...texts,
        },
        lemmataInsight: {
            loading: lemmataLoading,
            error: lemmataData.error,
            ...lemmata,
        },
        lemmataCoOccurrenceInsight: {
            loading: lemmataCoOccurrenceLoading,
            error: lemmataCoOccurrence.error,
            ...coOccurrence,
        },
        lemmataCorrelationInsight: {
            loading: lemmataCorrelationLoading,
            error: lemmataCorrelation.error,
            ...correlation,
        },
    };
});

export const insightNarratorPromptSelector = config => createSelector(
    [ _getQueryInfo, insightDataSelector ],
    ({ context }, { textsInsight, lemmataInsight, lemmataCoOccurrenceInsight, lemmataCorrelationInsight }) => {
        const result = Object
            .entries({
                texts: textsInsight,
                lemmata: lemmataInsight,
                lemmataForCoOccurrence: lemmataCoOccurrenceInsight,
                lemmataForCorrelation: lemmataCorrelationInsight,
            })
            .reduce((acc, [ chartType, chartData ]) => {
                if (chartData.loading) {
                    acc.inLoadingProcess = [
                        ...acc.inLoadingProcess,
                        chartType,
                        ...chartData.options?.length ? chartData.options : [],
                    ];

                    const disabledSubQuestions = config
                        .find(item => item.value === chartType)
                        ?.subQuestions
                        ?.map(subQuestion => subQuestion.value) || [];

                    acc.disabled = [
                        ...acc.disabled,
                        ...disabledSubQuestions,
                        chartType,
                    ];
                } else if (chartData.data && chartData.loaded) {
                    acc.isExisted = [
                        ...acc.isExisted,
                        chartType,
                        ...chartData.options?.length ? chartData.options : [],
                    ];
                }

                return acc;
            }, { inLoadingProcess: [], disabled: [], isExisted: [] });

        return {
            inLoadingProcess: result.inLoadingProcess,
            isExisted: result.isExisted,
            disabled: result.disabled,
            initialContext: context,
        };
    });

export const chartTypesSelector = chartType => createSelector(
    [ _getQuery ],
    ({
        textsLoading,
        lemmataLoading,
        lemmataCoOccurrenceLoading,
        lemmataCorrelationLoading,
    }) => [
        {
            label: 'Insight Narrator',
            insightNarratorLabel: null,
            value: 'insightNarrator',
            fontAwesomeIcon: 'book-open',
            disabled: (
                textsLoading
                || lemmataLoading
                || lemmataCoOccurrenceLoading
                || lemmataCorrelationLoading
            ) && chartType === 'insightNarrator',
        },
        {
            label: 'Cloud',
            insightNarratorLabel: null,
            value: 'Cloud',
            icon: 'cloud',
        },
        {
            label: 'Text',
            insightNarratorLabel: 'Text Analytics',
            value: 'Text',
            icon: 'text',
        },
        {
            label: 'Frequency',
            insightNarratorLabel: 'Frequency Analysis',
            value: 'Bar',
            icon: 'barChart',
        },
        {
            label: 'Co-occurrence',
            insightNarratorLabel: 'Co-Occurrence Analysis',
            value: 'CoOccurrence',
            icon: 'coOccurrenceGraphIcon',
        },
        {
            label: 'Correlation',
            insightNarratorLabel: 'Correlation Analysis',
            value: 'Correlation',
            icon: 'correlationGraphIcon',
        },
        {
            label: 'Data Labelling',
            insightNarratorLabel: null,
            value: 'Data Labelling',
            icon: 'aiIcon',
        },
    ],
);

export const promptLanguageOutSelector = () => {
    const languageList = [
        'Afrikaans',
        'Albanian',
        'Amharic',
        'Arabic',
        'Armenian',
        'Azerbaijani',
        'Basque',
        'Belarusian',
        'Bengali',
        'Bosnian',
        'Bulgarian',
        'Catalan',
        'Cebuano',
        'Chichewa',
        'Chinese (Simplified)',
        'Chinese (Traditional)',
        'Corsican',
        'Croatian',
        'Czech',
        'Danish',
        'Dutch',
        'English',
        'Esperanto',
        'Estonian',
        'Filipino',
        'Finnish',
        'French',
        'Galician',
        'Georgian',
        'German',
        'Greek',
        'Gujarati',
        'Haitian Creole',
        'Hausa',
        'Hawaiian',
        'Hebrew',
        'Hindi',
        'Hmong',
        'Hungarian',
        'Icelandic',
        'Igbo',
        'Indonesian',
        'Irish',
        'Italian',
        'Japanese',
        'Javanese',
        'Kannada',
        'Kazakh',
        'Khmer',
        'Kinyarwanda',
        'Korean',
        'Kurdish (Kurmanji)',
        'Kyrgyz',
        'Lao',
        'Latin',
        'Latvian',
        'Lithuanian',
        'Luxembourgish',
        'Macedonian',
        'Malagasy',
        'Malay',
        'Malayalam',
        'Maltese',
        'Maori',
        'Marathi',
        'Mongolian',
        'Myanmar (Burmese)',
        'Nepali',
        'Norwegian',
        'Odia (Oriya)',
        'Pashto',
        'Persian',
        'Polish',
        'Portuguese',
        'Punjabi',
        'Romanian',
        'Russian',
        'Samoan',
        'Scots Gaelic',
        'Serbian',
        'Sesotho',
        'Shona',
        'Sindhi',
        'Sinhala',
        'Slovak',
        'Slovenian',
        'Somali',
        'Spanish',
        'Sundanese',
        'Swahili',
        'Swedish',
        'Tajik',
        'Tamil',
        'Tatar',
        'Telugu',
        'Thai',
        'Turkish',
        'Turkmen',
        'Ukrainian',
        'Urdu',
        'Uyghur',
        'Uzbek',
        'Vietnamese',
        'Welsh',
        'Xhosa',
        'Yiddish',
        'Yoruba',
        'Zulu',
    ];
    const defaultLanguage = 'English';

    return {
        promptLanguageOutList: HelperService.mapObjToSelect(languageList),
        promptLanguageOutDefault: HelperService.mapObjToSelect([ defaultLanguage ])[0],
    };
};
