import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Switch, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { PageRequests, HelperService } from '/services';
import { TooltipWrapper, VocSelect } from '/components';
import { LanguagesList } from '/feedback/components';
import { Row, Col, Container, Button } from 'reactstrap';
import { RenameSurveyModal } from '../RenameSurveyModal';
import { ChannelsPanelQuestionInfo } from './ChannelsPanelQuestionInfo';
import { ChannelsPanelChannels } from './ChannelsPanelChannels';
import { ChannelsPanelStatus } from './ChannelsPanelStatus';
import './ChannelsPanel.scss';

const getInitialState = () => ({
    renameModal: false,
    changeModal: false,
});

export class ChannelsPanel extends Component {
    pageRequests = new PageRequests();
    state = getInitialState();

    componentDidMount() {
        this.requestTrigger();
    }

    componentWillUnmount() {
        this.pageRequests.cleanup();
    }

    @autobind
    requestTrigger() {
        const { getSurveysList, surveyGroupId } = this.props;

        this.pageRequests.cleanup();
        const request = getSurveysList(surveyGroupId).promise;

        this.pageRequests.addRequest(request);
    }

    @autobind
    createSurvey(survey) {
        this.props.createSurvey(survey).promise
            .then(this.requestTrigger);
    }

    @autobind
    switchSurvey(survey) {
        this.props.switchSurvey(survey);
    }

    @autobind
    closeModal() {
        this.setState(getInitialState());
    }

    @autobind
    renameSurvey(name) {
        const request = this.props.renameSurvey({ name }, this.props.surveyGroup.id).promise;

        this.pageRequests.addRequest(request);
        request.then(this.closeModal);
    }

    @autobind
    changeStatus({ value, label }) {
        const { surveyGroup, showConfirm, activeSurvey: { channel } } = this.props;

        const warningStatuses = [ 'Deleted', 'Stopped' ];
        const showConfirmState = surveyGroup.isUnsubscribeSurvey && warningStatuses.includes(label) && channel === 'DIGI';

        showConfirm({
            checkDirty: !showConfirmState,
            header: 'Warning!',
            content: 'Survey will be detached from all survey groups and units for which it used as "Unsubscribe survey"',
            successCallback: () => this.changeStatusConfirm({ value, label }),
        });
    }

    @autobind
    changeStatusConfirm({ value, label }) {
        const {
            activeSurvey: {
                channel,
                id,
                state,
            },
            surveyGroupId,
            rightsSurvey: {
                isPermitApprove,
            },
            surveyGroup,
            toggleIsUnsubscribeGroup,
        } = this.props;

        if (state !== +value && isPermitApprove) {
            surveyGroup.isUnsubscribeSurvey
            && (label === 'Deleted' || label === 'Stopped')
            && toggleIsUnsubscribeGroup(false);

            const request = this.props.changeStatus({
                state: value,
                survey_group: Number(surveyGroupId),
                channel,
            }, id).promise;

            this.pageRequests.addRequest(request);
        }
    }

    @autobind
    openModal(e) {
        this.setState({ [e.currentTarget.dataset.name]: true });
    }

    @autobind
    channelsHandler(param, action) {
        this.props.showConfirm({
            checkDirty: true,
            header: 'Warning!',
            content: 'You might have some unsaved changes. Are you sure you want to proceed?',
            successCallback: () => action(param),
        });
    }

    @autobind
    onTimeSlotTypeChange({ value }) {
        const { switchTimeSlotType } = this.props;

        this.channelsHandler(value, switchTimeSlotType);
    }

    @autobind
    onChangeLanguage(languageData) {
        this.props.showConfirm({
            checkDirty: true,
            header: 'Warning!',
            content: `Seems you might have some unsaved changes. Are you sure you want to continue?`,
            successCallback: () => this.props.switchLanguage(languageData),
        });
    }

    render() {
        const {
            toggleSendInvitation,
            dataSurveys: {
                unitsListCount,
                name,
                unit,
            },
            activeSurvey: {
                channel,
                state,
            },
            surveyLanguages,
            renameLoading,
            surveyGroup,
            changeStatusLoading,
            timeSlotData,
            timeSlotType,
            rightsSurvey,
            states,
        } = this.props;

        const statuses = states.map(({ id, name }) => ({
            value: String(id),
            label: HelperService.capittalize(name),
        }));

        let tooltipValue = name;
        const isManyUnitsList = unitsListCount > 1 && !surveyGroup.loading;

        if (isManyUnitsList) {
            tooltipValue = `Unit: ${ unit.name } \n ${ name }`;
        }

        const disabledInvite = !channel || !rightsSurvey.isPermitInvite;

        return (
            <div className={ 'question-channels-panel__wrap' }>
                { surveyGroup.loading
                    ? <div className={ `question-channels-loading` }>
                        <FontAwesomeIcon icon='circle-notch' size='lg' spin/>
                        <span> Loading...</span>
                    </div>
                    : <>
                        <Row>
                            <div className={ 'question-channels-panel' }>
                                <Col md={ 4 }>
                                    <div className="qustion-actions">
                                        <h4 className={ 'survey-title' }>
                                            <TooltipWrapper
                                                value={ tooltipValue }
                                                force={ isManyUnitsList }
                                                className={ isManyUnitsList ? 'tooltip_word-wrap' : '' }
                                            >
                                                <span>{ name }</span>
                                            </TooltipWrapper>
                                        </h4>
                                        <TooltipWrapper value={ 'Change name' } force={ true }>
                                            <Button color="link"
                                                data-name='renameModal'
                                                onClick={ e => rightsSurvey.isPermitEdit && this.openModal(e) }
                                                className={ cx(
                                                    'question-icon-control',
                                                    { 'disabled': !rightsSurvey.isPermitEdit },
                                                ) }
                                            >
                                                <FontAwesomeIcon icon={ 'pencil-alt' } data-name='renameModal'/>
                                            </Button>
                                        </TooltipWrapper>
                                        <div className="question-action__status">
                                            {
                                                state
                                                && <Switch>
                                                    <Route
                                                        path={ [
                                                            this.props.routes.question,
                                                            this.props.routes.quarantines,
                                                            this.props.routes.surveyCommonSettings,
                                                        ] }
                                                    />
                                                    <Route render={ () =>
                                                        <ChannelsPanelStatus
                                                            changeStatusLoading={ changeStatusLoading }
                                                            statuses={ statuses }
                                                            state={ state }
                                                            onChange={ this.changeStatus }
                                                            rightsSurvey={ rightsSurvey }
                                                        />
                                                    } />
                                                </Switch>
                                            }
                                        </div>
                                    </div>
                                </Col>

                                <Col md={ 5 }>
                                    <Switch>
                                        <Route
                                            path={ [
                                                this.props.routes.question,
                                            ] }
                                            exact
                                            component={ ChannelsPanelQuestionInfo }
                                        />
                                        <Route
                                            path={ [
                                                this.props.routes.quarantines,
                                                this.props.routes.surveyCommonSettings,
                                            ] }
                                        />
                                        <Route component={ ChannelsPanelChannels }/>
                                    </Switch>
                                </Col>
                                <Col md={ 3 } className={ 'channels-panel__right' }>

                                    <Switch>
                                        <Route path={ [
                                            this.props.routes.surveyCommonSettings,
                                            this.props.routes.surveySettings,
                                            this.props.routes.statisticsResults,
                                            this.props.routes.questionsStatistics,
                                            this.props.routes.quarantines,
                                            this.props.routes.customers,
                                        ] }/>
                                        <Route path={ [ this.props.routes.timeSlots ] }
                                            render={ () => (
                                                <Container>
                                                    <VocSelect
                                                        className={ 'selector-type-slots' }
                                                        placeholder="Choose time slots type"
                                                        options={ timeSlotData.options }
                                                        value={ timeSlotType }
                                                        onChange={ this.onTimeSlotTypeChange }
                                                        zIndex={ 90 }
                                                        portal
                                                    />
                                                </Container>
                                            ) }
                                        />
                                        <Route render={ () => (
                                            <div className={ 'channels-panel__languages-list' }>
                                                <LanguagesList data={ surveyLanguages }
                                                    changeHandler={ this.onChangeLanguage }
                                                />
                                            </div>
                                        ) }/>
                                    </Switch>

                                    <Switch>
                                        <Route path={ [ this.props.routes.timeSlots ] }/>
                                        <Route render={ () => (
                                            <Button color={ 'success' }
                                                disabled={ disabledInvite }
                                                className='survey-navigation__button'
                                                onClick={ e => !disabledInvite && toggleSendInvitation(e) }
                                            >
                                                <FontAwesomeIcon
                                                    icon={ 'paper-plane' }
                                                    className='survey-navigation__button-icon'
                                                />
                                                <span className='survey-navigation__button-text'>Send invite</span>
                                            </Button>
                                        ) }/>
                                    </Switch>

                                </Col>
                            </div>
                            <RenameSurveyModal
                                isOpen={ this.state.renameModal }
                                name={ name }
                                loading={ renameLoading }
                                toggle={ this.closeModal }
                                actionHandler={ this.renameSurvey }
                            />
                        </Row>
                    </>
                }
            </div>
        );
    }
}

ChannelsPanel.propTypes = {
    states: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    surveyGroup: PropTypes.object,
    getSurveysList: PropTypes.func,
    createSurvey: PropTypes.func,
    switchSurvey: PropTypes.func,
    renameSurvey: PropTypes.func,
    changeStatus: PropTypes.func,
    dataSurveys: PropTypes.shape({
        surveys: PropTypes.array,
        sequence: PropTypes.number,
        unit: PropTypes.object,
        name: PropTypes.string,
    }),
};
