import { sockets } from '/services';
import { invalidateCache } from '/modules';
import { updateUploadList } from './index';
import { uploadsSelector } from './selectors';

function socketCallback(updatedUpload, store) {
    store.dispatch(invalidateCache('vocStore.uploads'));

    if (location.pathname === '/store/uploads-list') {
        const uploadsList = uploadsSelector(store.getState());
        const updatedUploadIndex = uploadsList.data.findIndex(upload => upload.id === updatedUpload.id);

        if (updatedUploadIndex !== -1) {
            store.dispatch(updateUploadList(updatedUpload, updatedUploadIndex));
        }
    }
}

export function sockedUploadUpdateListener() {
    return sockets.on('store_upload_updated', socketCallback);
}

export function sockedUploadDeleteListener() {
    return sockets.on('store_upload_deleted', (deletedUpload, store) => {
        store.dispatch(invalidateCache('vocStore.uploads'));
    });
}

export function socketUploadUpdateListenerOff(socketCallback) {
    return sockets.off('store_upload_updated', socketCallback);
}
