import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Input } from 'reactstrap';
import './CustomersTableCheckbox.scss';

export class CustomersTableCheckbox extends Component {
    @autobind
    changeHandler(e) {
        e.target.checked ? this.props.addToSelected(this.props.item) : this.props.removeFromSelected(this.props.item);
    }

    render() {
        return (
            <div className='export-results__checkbox-container'>
                <Input
                    type={ 'checkbox' }
                    className='export-results__checkbox'
                    checked={ this.props.selected }
                    onChange={ this.changeHandler }
                />
            </div>
        );
    }
}
