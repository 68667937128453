import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { DynamicFormButton } from '../DynamicFormButton';
import { DynamicFormServices } from '../../services';
import { arrayMove } from 'react-sortable-hoc';
import { SortableList } from '../SortableList';

export class DynamicFormDataCreation extends Component {
    constructor(props) {
        super(props);

        const {
            settings: {
                titles,
                fields,
            },
            data,
        } = this.props;

        this.state = {
            titles,
            settings: [
                ...fields,
            ],
            rowsForms: [
                ...DynamicFormServices.parseRowsForms(fields, data),
            ],
        };
    }

    @autobind
    cloningRowsForms() {
        this.setState({
            rowsForms: [
                ...this.state.rowsForms,
                DynamicFormServices.deepCopyObj(this.props.settings.fields),
            ],
        });
    }

    @autobind
    addNewForm() {
        this.props.addNewForm();
        this.cloningRowsForms();
    }

    renderTitle() {
        return this.state.titles.map((title, inx) => <p className={ `dynamic-form_title dynamic-col-${inx}` }
            key={ inx }> { title } <span className='star'> *</span></p>);
    }

    @autobind
    removeRowForm(idRow) {
        this.setState({
            rowsForms: [
                ...this.state.rowsForms.filter((el, inx) => inx !== idRow),
            ],
        });

        this.props.removeForm(idRow, this.props.name);
    }

    @autobind
    handleChangeField(event, name, obj) {
        this.props.handlChange(event.target.value, obj, this.props.name);
    }

    @autobind
    handleChangeSelect(group) {
        const { row, item } = group.obj;
        const selectedValue = group.value;
        const rowElement = this.state.settings.filter(elem => elem.name === item);
        const { affect } = rowElement[0];

        if (affect) {
            const toggleChanges = affect.whenId === selectedValue;

            this.affectState(affect, row, toggleChanges);
        }

        this.props.handlChange(group.value, group.obj, this.props.name);
    }

    affectState(affect, idRow, toggleChanges) {
        const newRowsForms = this.state.rowsForms.map((row, inx) => {
            {
                if (inx === idRow) DynamicFormServices.changeObjRef(toggleChanges, row, affect);
                return row;
            }
        });

        this.setState({
            rowsForms: [
                ...newRowsForms,
            ],
        });
    }

    @autobind
    onSortEnd({ oldIndex, newIndex }) {
        this.setState({
            rowsForms: [
                ...arrayMove(this.state.rowsForms, oldIndex, newIndex),
            ],
        });

        this.props.onSortEnd({ oldIndex, newIndex }, this.props.name);
    }

    render() {
        const {
            data,
            settings: {
                btnName,
            },
        } = this.props;

        return (
            <Fragment>
                <div className="dynamic-form_box-title">{ this.renderTitle() }</div>
                <div className="dynamic-form_box-list">
                    {
                        <SortableList
                            items={ this.state.rowsForms }
                            handleChangeSelect={ this.handleChangeSelect }
                            handleChangeField={ this.handleChangeField }
                            removeRowForm={ this.removeRowForm }
                            data={ data }
                            onSortEnd={ this.onSortEnd }
                            useDragHandle={ true }
                        />
                    }
                </div>

                <DynamicFormButton
                    button={ btnName }
                    addNewForm={ this.addNewForm }
                />
            </Fragment>
        );
    }
}
