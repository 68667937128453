import { RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { _getDashboardData, gadgetChartData } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { getGadgetData, onSyncChart, updateGadgetData, InitialTableFilters } from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { GadgetService } from '/visual/scenes/Dashboard/components';

export const useGadgetData = (id: string, gadgetRef?: RefObject<HTMLElement> | null) => {
    const dispatch = useDispatch();

    const dashboard = useSelector(_getDashboardData);
    const storedGadgetChartData = useSelector((state: any) => gadgetChartData(state, id));
    const {
        gadgetData,
        chartData = null,
        loaded = false,
        loading = true,
        needToUpdate = false,
        updateType = null,
    } = storedGadgetChartData;
    const [ isRebuildCache, setRebuildCache ] = useState(false);

    const getChartData = (gadget, filters) => dispatch(getGadgetData(gadget.id, filters));

    const prepareData = () => {
        const {
            chartType,
            saveTableLayout,
            tableSortColumn,
            tableSortDirection,
            rowsPerPage,
        } = gadgetData.contentSettings;
        let filters = {};

        if(chartType === 'custom_columns_table') {
            filters = {
                ...InitialTableFilters,
                ...saveTableLayout
                && {
                    order: tableSortDirection,
                    sort: tableSortColumn,
                    limit: rowsPerPage || 10,
                },
            };
        }

        if(isRebuildCache) {
            filters = {
                ...filters,
                rebuildCache: 1,
            };

            setRebuildCache(false);
        }

        return filters;
    };

    const onSync = () => {
        setRebuildCache(true);
        dispatch(onSyncChart(gadgetData));
    };

    const updateGadget = () => {
        dispatch(updateGadgetData(gadgetData, prepareData()))
            .promise.then(() =>
                GadgetService.highlightGadget({ updateType, gadgetNode: gadgetRef?.current }),
            );
    };

    useEffect(() => {
        if (chartData || loaded || loading) return;

        // get chartData from server, if redux store doesn't have this
        getChartData(gadgetData, prepareData());
    }, [ chartData, loaded ]);

    useEffect(() => {
        if (needToUpdate) {
            updateGadget();
        }
    }, [ needToUpdate ]);

    return {
        gadget: gadgetData,
        chart: storedGadgetChartData,
        onSync,
        rights: dashboard.rights,
    };
};
