import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './VirtualSourceHorizontalScrollButtons.scss';

const INTERVAL_TIME = 100;
const SCROLL_DISTANCE = 130;

export const VirtualSourceHorizontalScrollButtons = ({ virtualColumnsNumber, gridContainerClassname }) => {
    const [ intervalId, setIntervalId ] = useState(null);
    const [ leftButtonDisabled, setLeftButtonDisabled ] = useState(true);
    const [ rightButtonDisabled, setRightButtonDisabled ] = useState(false);

    useEffect(() => {
        const rightBottomGrid = document.querySelector(gridContainerClassname);

        rightBottomGrid.addEventListener('scroll', disableButtons);

        return () => rightBottomGrid.removeEventListener('scroll', disableButtons);
    }, []);

    useEffect(() => {
        if ((leftButtonDisabled || rightButtonDisabled) && intervalId) clearIntervalId();
    }, [ leftButtonDisabled, rightButtonDisabled ]);

    useEffect(() => {
        const rightBottomGrid = document.querySelector(gridContainerClassname);

        disableButtons({ target: rightBottomGrid });
    }, [ virtualColumnsNumber ]);

    const disableButtons = ({ target: { scrollLeft, scrollWidth, clientWidth } }) => {
        if (!scrollLeft) {
            setLeftButtonDisabled(true);
            setRightButtonDisabled(false);
        } else {
            setLeftButtonDisabled(false);
            setRightButtonDisabled(scrollLeft === scrollWidth - clientWidth);
        }
    };

    const clearIntervalId = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };

    const onScroll = (isRightScroll = false) => {
        const rightBottomGrid = document.querySelector(gridContainerClassname);

        rightBottomGrid.scrollBy({
            left: isRightScroll ? SCROLL_DISTANCE : -SCROLL_DISTANCE,
            behavior: 'smooth',
        });
    };

    const onMouseDown = (isRightScroll = false) => () => {
        clearIntervalId();

        onScroll(isRightScroll);

        const interval = setInterval(() => onScroll(isRightScroll), INTERVAL_TIME);

        setIntervalId(interval);
    };

    const onMouseUp = () => clearIntervalId();

    const onMouseLeave = () => clearIntervalId();

    const scrollButtons = [
        {
            icon: 'arrow-circle-left',
            className: cx('virtual-source-scroll-buttons__button', {
                'virtual-source-scroll-buttons__button--disabled': leftButtonDisabled,
            }),
            onMouseDown: onMouseDown(),
        },
        {
            icon: 'arrow-circle-right',
            className: cx('virtual-source-scroll-buttons__button', {
                'virtual-source-scroll-buttons__button--disabled': rightButtonDisabled,
            }),
            onMouseDown: onMouseDown(true),
        },
    ];

    const renderScrollButtons = () =>
        scrollButtons.map(({ icon, onMouseDown, className }, index) =>
            <div key={ index } { ...{ onMouseDown, className, onMouseUp, onMouseLeave } }>
                <FontAwesomeIcon { ...{ icon } }/>
            </div>,
        );

    return (
        <div className='virtual-source-scroll-buttons__wrapper'>
            {renderScrollButtons()}
        </div>
    );
};
