import React from 'react';
import { Col } from 'reactstrap';
import { TooltipWrapper } from '/components';
import './AddressListItem.scss';

export const AddressListItem = ({ address, protocols = [] }) => {
    return (
        <Col xs={ 12 }>
            <div className={ 'address-list-item_part' }>
                {
                    protocols.map((protocol, index) =>
                        protocol.blacklisted && <TooltipWrapper value={ 'blacklisted' } key={ index } force={ true }>
                            <div className={ 'badge badge-danger' }>
                                { protocol.name }
                            </div>
                        </TooltipWrapper>,
                    )
                }
            </div>
            <div className={ 'address-list-item_part' }>
                <TooltipWrapper value={ address }>
                    <span className="customers-table__address">{ address }</span>
                </TooltipWrapper>
            </div>
        </Col>
    );
};
