import React from 'react';
import { useSelector } from "react-redux";
import { TimeDateGridContainer } from '/components';
import { PageRequests, dispatchWrapper } from '/services';
import {
    getTimeFrameRegular,
    getTimeFrameSpecial,
    reFetchTimeFrameSpecial,
    createTimeFrameRegular,
    updateTimeFrameRegular,
    manageTimeFrameSpecial,
    updateFiltersActions,
    cleanup,
} from './modules/timeSlots.modules';
import { getPermissionByActiveSelector } from '../../modules/globalSettings.selectors';
import { TimeSlotsDataSelector } from './modules/timeSlots.selectors';
import { channelListSelector, channelUnitSelector } from '/feedback/modules/VocFeedback.selectors';
import { showConfirm, dirtyStatusConfirm } from '/scenes/Confirm/modules/confirm.index';

const pageRequests = new PageRequests();

export const TimeSlots = () => {
    const permission = useSelector(getPermissionByActiveSelector);
    const timeSlotsData = useSelector(TimeSlotsDataSelector);
    const {
        survey_id,
        unit,
        activeChannel,
    } = useSelector(channelUnitSelector);
    const channelList = useSelector(channelListSelector);

    const [
        actionGetTimeFrameRegular, actionGetTimeFrameSpecial,
        actionCreateTimeFrameRegular, actionUpdateTimeFrameRegular,
        actionManageTimeFrameSpecial, actionUpdateFiltersActions,
        actionDirtyStatusConfirm, actionShowConfirm, actionCleanup,
        actionreFetchTimeFrameSpecial,
    ] = dispatchWrapper([
        getTimeFrameRegular, getTimeFrameSpecial,
        createTimeFrameRegular, updateTimeFrameRegular,
        manageTimeFrameSpecial, updateFiltersActions,
        dirtyStatusConfirm, showConfirm, cleanup,
        reFetchTimeFrameSpecial,
    ]);

    const requestTrigger = (filtersObj, channel = activeChannel) => {
        const request = timeSlotsData.timeSlotIsRegular
            ? actionGetTimeFrameRegular(unit, channel)
            : actionGetTimeFrameSpecial({ ...filtersObj, channel__name: channel, unit_id: unit });

        pageRequests.addRequest(request);
    };

    const manageRegularDataHandler = timeSlots => {
        return timeSlots[0].id
            ? actionUpdateTimeFrameRegular(timeSlots)
            : actionCreateTimeFrameRegular(timeSlots);
    };

    const handlingTimeFrameSpecial = ({ updateObj, deleteObj, createObj, filterModel }) => {
        const createTimeSlots = createObj.length
            ? createObj.map(slot => ({
                ...slot,
                survey: null,
            }))
            : createObj;

        actionManageTimeFrameSpecial(updateObj, deleteObj, createTimeSlots).then(() => {
            actionUpdateFiltersActions(filterModel);

            pageRequests.addRequest(
                actionreFetchTimeFrameSpecial({ ...filterModel, channel__name: activeChannel, unit_id: unit }),
            );
        });
    };

    return (
        <TimeDateGridContainer
            timeSlotsData={ timeSlotsData }
            unit={ unit }
            activeChannel={ activeChannel }
            survey_id={ survey_id }
            channelList={ channelList }
            requestTrigger={ requestTrigger }
            manageRegularDataHandler={ manageRegularDataHandler }
            manageTimeFrameSpecial={ handlingTimeFrameSpecial }
            updateFiltersActions={ actionUpdateFiltersActions }
            dirtyStatusConfirm={ actionDirtyStatusConfirm }
            cleanup={ actionCleanup }
            showConfirm={ actionShowConfirm }
            pageRequests={ pageRequests }
            pageTitle={ "Feedback :: Global Time slots" }
            rights={ permission }
        />
    );
};
