import { CALL_API, FEEDBACK } from 'store/middleware/api';

export const GET_SURVEY_REQUEST = 'GET_SURVEY_REQUEST';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_ERROR = 'GET_SURVEY_ERROR';

export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const SWITCH_SURVEY = 'SWITCH_SURVEY';

export const CREATE_SURVEY_REQUEST = 'CREATE_SURVEY_REQUEST';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_ERROR = 'CREATE_SURVEY_ERROR';

export const RENAME_SURVEY_REQUEST = 'RENAME_SURVEY_REQUEST';
export const RENAME_SURVEY_SUCCESS = 'RENAME_SURVEY_SUCCESS';
export const RENAME_SURVEY_ERROR = 'RENAME_SURVEY_ERROR';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_ERROR = 'CHANGE_STATUS_ERROR';

export const GET_SURVEY_GROUP_LANGUAGES_REQUEST = 'GET_SURVEY_GROUP_LANGUAGES_REQUEST';
export const GET_SURVEY_GROUP_LANGUAGES_SUCCESS = 'GET_SURVEY_GROUP_LANGUAGES_SUCCESS';
export const GET_SURVEY_GROUP_LANGUAGES_ERROR = 'GET_SURVEY_GROUP_LANGUAGES_ERROR';

export const CHECK_QUESTION_RESPONSE_REQUEST = 'CHECK_QUESTION_RESPONSE_REQUEST';
export const CHECK_QUESTION_RESPONSE_REQUEST_SUCCESS = 'CHECK_QUESTION_RESPONSE_REQUEST_SUCCESS';
export const CHECK_QUESTION_RESPONSE_REQUEST_ERROR = 'CHECK_QUESTION_RESPONSE_REQUEST_ERROR';

export const GET_THEMES_BY_DIGI_SURVEY_REQUEST = 'GET_THEMES_BY_DIGI_SURVEY_REQUEST';
export const GET_THEMES_BY_DIGI_SURVEY_SUCCESS = 'GET_THEMES_BY_DIGI_SURVEY_SUCCESS';
export const GET_THEMES_BY_DIGI_SURVEY_ERROR = 'GET_THEMES_BY_DIGI_SURVEY_ERROR';

const GET_IS_UNSUBSCRIBE_GROUP_REQUEST = 'GET_IS_UNSUBSCRIBE_GROUP_REQUEST';
const GET_IS_UNSUBSCRIBE_GROUP_SUCCESS = 'GET_IS_UNSUBSCRIBE_GROUP_SUCCESS';
const GET_IS_UNSUBSCRIBE_GROUP_ERROR = 'GET_IS_UNSUBSCRIBE_GROUP_ERROR';

const TOGGLE_IS_UNSUBSCRIBE_GROUP = 'TOGGLE_IS_UNSUBSCRIBE_GROUP';

export const SURVEY_SWITCH_TIME_SLOT_TYPE = 'SURVEY_SWITCH_TIME_SLOT_TYPE';

export const CLEANUP = '/feedback/survey/{surveyId}/questions/cleanup';

function getInitialState() {
    return {
        activeLanguage: sessionStorage.getItem('feedbackActiveLanguage') || '',
        activeChannel: sessionStorage.getItem('channel'),
        nameCreateSurvey: '',
        timeSlotType: 'regular',
        modals: {
            renameLoading: false,
            changeStatusLoading: false,
        },
        id: null,
        loading: true,
        surveyGroupLanguages: {
            loading: false,
            data: [],
        },
        themeSettingsForDigi: {
            global_theme: null,
            parent_unit_theme: null,
            survey_theme: null,
        },
        name: '',
        surveys: [],
        responsesCount: 0,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case GET_SURVEY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_SURVEY_SUCCESS:
            return {
                ...state,
                ...action.response,
                loading: false,
            };

        case GET_IS_UNSUBSCRIBE_GROUP_SUCCESS:
            return {
                ...state,
                ...action.response,
                isUnsubscribeSurvey: action.response.is_unsubscribe,
            };

        case TOGGLE_IS_UNSUBSCRIBE_GROUP:
            return {
                ...state,
                isUnsubscribeSurvey: action.isUnsubscribe,
            };

        case RENAME_SURVEY_REQUEST:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    renameLoading: true,
                },
            };

        case RENAME_SURVEY_SUCCESS:
            return {
                ...state,
                name: action.response.name,
                modals: {
                    ...state.modals,
                    renameLoading: false,
                },
            };

        case RENAME_SURVEY_ERROR:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    renameLoading: false,
                },
            };

        case CHANGE_STATUS_REQUEST:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    changeStatusLoading: true,
                },
            };

        case CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                surveys: updateSurveyList(state.surveys, action.response),
                modals: {
                    ...state.modals,
                    changeStatusLoading: false,
                },
            };

        case CHANGE_STATUS_ERROR:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    changeStatusLoading: false,
                },
            };

        case CHECK_QUESTION_RESPONSE_REQUEST_SUCCESS:
            return {
                ...state,
                responsesCount: action.response.count,
            };

        case GET_SURVEY_GROUP_LANGUAGES_REQUEST: {
            return {
                ...state,
                activeLanguage: sessionStorage.getItem('feedbackActiveLanguage') || '',
                surveyGroupLanguages: {
                    data: [],
                    loading: true,
                },
            };
        }

        case GET_SURVEY_GROUP_LANGUAGES_SUCCESS: {
            return {
                ...state,
                surveyGroupLanguages: {
                    data: action.response,
                    loading: false,
                },
            };
        }

        case GET_SURVEY_GROUP_LANGUAGES_ERROR: {
            return {
                ...state,
                surveyGroupLanguages: {
                    ...state.surveyGroupLanguages,
                    loading: false,
                },
            };
        }

        case GET_THEMES_BY_DIGI_SURVEY_SUCCESS: {
            return {
                ...state,
                themeSettingsForDigi: {
                    ...action.response,
                },
            };
        }

        case CREATE_SURVEY_REQUEST:
            return {
                ...state,
                nameCreateSurvey: action.body.channel,
            };

        case CREATE_SURVEY_SUCCESS:
            sessionStorage.setItem('channel', action.response.channel);

            return {
                ...state,
                nameCreateSurvey: '',
                activeChannel: action.response.channel,
                surveys: [
                    ...state.surveys,
                    {
                        ...action.response,
                    },
                ],

            };

        case CLEANUP: {
            return getInitialState();
        }

        case SURVEY_SWITCH_TIME_SLOT_TYPE: {
            return {
                ...state,
                timeSlotType: action.data,
            };
        }

        case SWITCH_LANGUAGE: {
            const {
                language,
            } = action.data;

            sessionStorage.setItem('feedbackActiveLanguage', language);

            return {
                ...state,
                activeLanguage: language,
            };
        }

        case SWITCH_SURVEY:
            sessionStorage.setItem('channel', action.payload.channel);

            return {
                ...state,
                activeChannel: action.payload.channel,
                surveys: updateSurveyList(state.surveys, action.payload),
            };

        default:
            return state;
    }
}

export function getSurveysList(id) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${id}/expand-basic/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_SURVEY_REQUEST, GET_SURVEY_SUCCESS, GET_SURVEY_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function getSurveyGroupLanguages(id) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${id}/has-language-settings/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_SURVEY_GROUP_LANGUAGES_REQUEST,
                GET_SURVEY_GROUP_LANGUAGES_SUCCESS,
                GET_SURVEY_GROUP_LANGUAGES_ERROR,
            ],
            apiType: FEEDBACK,
        },
    };
}

export function createSurvey(body) {
    return {
        [CALL_API]: {
            endpoint: `/survey/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_SURVEY_REQUEST, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_ERROR ],
            apiType: FEEDBACK,
            body,
            logger: true,
            loggerMessages: {
                success: 'Channel was successfully activated',
                error: 'Something went wrong',
            },
        },
    };
}

export function switchSurvey(payload) {
    return {
        type: SWITCH_SURVEY,
        payload,
    };
}

export function switchLanguage(data) {
    return {
        type: SWITCH_LANGUAGE,
        data,
    };
}

export function renameSurvey(body, id) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${id}/`,
            method: 'PATCH',
            contentType: 'application/json',
            types: [ RENAME_SURVEY_REQUEST, RENAME_SURVEY_SUCCESS, RENAME_SURVEY_ERROR ],
            apiType: FEEDBACK,
            body: {
                name: body.name,
            },
            logger: true,
            loggerMessages: {
                success: 'Survey was successfully renamed',
                error: 'Something went wrong',
            },
        },
    };
}

export function changeStatus(body, id) {
    return {
        [CALL_API]: {
            endpoint: `/survey/${id}/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ CHANGE_STATUS_REQUEST, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_ERROR ],
            apiType: FEEDBACK,
            body,
            logger: true,
            loggerMessages: {
                success: 'Survey status changed',
                error: 'Something went wrong',
            },
        },
    };
}

export function checkQuestionResponseById(question__id) {
    return {
        [CALL_API]: {
            endpoint: `/questionnaire-response/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ CHECK_QUESTION_RESPONSE_REQUEST, CHECK_QUESTION_RESPONSE_REQUEST_SUCCESS, CHECK_QUESTION_RESPONSE_REQUEST_ERROR ],
            body: {
                limit: 1,
                question__question_type__is_interactive: 1,
                question__id,
            },
            apiType: FEEDBACK,
        },
    };
}

export function getThemesBySurvey(id) {
    return {
        [CALL_API]: {
            endpoint: `/digi-theme/${ id }/extended/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_THEMES_BY_DIGI_SURVEY_REQUEST,
                GET_THEMES_BY_DIGI_SURVEY_SUCCESS,
                GET_THEMES_BY_DIGI_SURVEY_ERROR,
            ],
            apiType: FEEDBACK,
        },
    };
}

function updateSurveyList(surveys, payload) {
    return surveys.map(survey => survey.id === payload.id ? payload : survey);
}

export function switchTimeSlotType(data) {
    return {
        type: SURVEY_SWITCH_TIME_SLOT_TYPE,
        data,
    };
}

export function toggleIsUnsubscribeGroup(isUnsubscribe) {
    return {
        type: TOGGLE_IS_UNSUBSCRIBE_GROUP,
        isUnsubscribe,
    };
}

export function getIsUnsubscribeGroup(id) {
    return {
        [CALL_API]: {
            endpoint: `/survey-group/${id}/is-unsubscribe/`,
            method: 'GET',
            contentType: 'application/json',
            types: [
                GET_IS_UNSUBSCRIBE_GROUP_REQUEST,
                GET_IS_UNSUBSCRIBE_GROUP_SUCCESS,
                GET_IS_UNSUBSCRIBE_GROUP_ERROR,
            ],
            apiType: FEEDBACK,
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP,
    };
}
