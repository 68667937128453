export class VocAIServices {
    static getCategoriesCount(languages: any) {
        let count = 0;

        Object.keys(languages).forEach(langCode => {
            if(languages[langCode].length > count) {
                count = languages[langCode].length;
            }
        });

        return count;
    }
}
