import { combineReducers } from 'redux';
import { FeedbackReducer as VocFeedback } from '../scenes/VocFeedback';
import { mineReducers as VocMine } from '../scenes/VocMine';
import { aiReducers as VocAi } from '../scenes/VocAI';
import { reducer as VocAiTopicSet } from '../modules/topicSetModules/topicSet.modules';
import { ToastsReducer as Toasts } from '/components';
import { reducer as Form } from 'redux-form';
import { reducer as VocStore } from '../scenes/VocStore/VocStore';
import { reducer as UserInfo } from '../modules';
import { visualReducers as VocVisual } from '../scenes/VocVisual';
import { reducer as UserRights } from '../modules/rightsModules/rights.index';
import { reducer as UploadFiles } from '../modules/uploadFileModules/uploadFile.modules';
import { reducer as Segmentator } from '../modules/segmentatorModules/segmentator.modules';
import { HeaderReducer as Header } from '../scenes/Header';
import { ConfirmReducer as Confirm } from '../scenes/Confirm/modules/confirm.index';
import { reducer as SourceSelects } from '../scenes/VocStore/scenes/VirtualSource/components/SourceSelect/modules/source-select.modules';

export const makeRootReducer = () => {
    const appReducer = combineReducers({
        VocVisual,
        UserInfo,
        UploadFiles,
        Segmentator,
        UserRights,
        VocFeedback,
        VocStore,
        VocMine,
        VocAi,
        VocAiTopicSet,
        Header,
        Toasts,
        Confirm,
        SourceSelects,
        form: Form,
    });

    const rootReducer = (state, action) => {
        return appReducer(state, action);
    };

    return rootReducer;
};

export default makeRootReducer;
