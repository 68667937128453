import { useEffect, useState } from 'react';

import { HelperService } from '/services';
import { GadgetService } from '/visual/scenes/Dashboard/components';
import { IContentSettings } from '/visual/models';
import {
    ELabelType, filterRequiredWithScore,
    IChartData, IFormatDataPieChart,
    TLabelData,
} from '/visual/scenes/Dashboard/components/Gadget/models';

const onChangePieColorTimeout = HelperService.debounce(({ callback }: any) => {
    callback();
}, 200, false);

export const usePieChart = (initChartData: IChartData) => {
    const [ formatData, setFormatData ] = useState<IFormatDataPieChart>({
        data: null,
        filterLabels: [],
    });

    useEffect(() => {
        const formattedData = GadgetService.formatPieDataHandler({
            chartData: initChartData.chartData,
            gadgetData: initChartData.gadgetData,
        });

        setFormatData(formattedData);
    }, [ initChartData.chartData, initChartData.gadgetData ]);

    const changePieColor = (
        id: string,
        color: string,
        onChangeColorCallback: (data: any, isChanged: boolean) => void,
    ) => {
        const isChanged = GadgetService.getSavedColorIndex(id, initChartData.gadgetData.colors) > -1;
        const colorObject = {
            key: id,
            value: color,
        };

        onChangePieColorTimeout({
            callback: () => {
                onChangeColorCallback(colorObject, isChanged);
            },
        });
    };

    const changeLabel = (
        id: string,
        type: ELabelType,
        value: string | null,
        onChangeAxisLabelCallback: (labelRes: any, isChanged: any) => void,
    ) => {
        const formatLabelId = id.toString().toLowerCase();
        const axisObject = {
            key: formatLabelId,
            type,
            value,
        };

        const isChanged = initChartData.gadgetData.labels
            .findIndex((labelData: TLabelData) => labelData.key === formatLabelId) > -1;

        onChangeAxisLabelCallback(axisObject, isChanged);
    };

    const changePieSort = (direction: string, onChangePiesSortCallback: (data: IContentSettings) => void) => {
        const initData = JSON.parse(JSON.stringify(initChartData.gadgetData));
        const isAscSort = direction === 'asc';
        const { filterLabels } = formatData;

        initData.contentSettings.order = filterLabels
            .sort((a: filterRequiredWithScore, b: filterRequiredWithScore) => isAscSort
                ? b.score - a.score
                : a.score - b.score,
            )
            .map((item: filterRequiredWithScore) => item.id);

        onChangePiesSortCallback(initData.contentSettings);
    };

    return {
        gadgetData: initChartData.gadgetData,
        chartData: initChartData.chartData,
        ...formatData,
        changePieColor,
        changeLabel,
        changePieSort,
    };
};
