import React from 'react';
import propTypes from 'prop-types';
import { Field } from 'redux-form';
import { Row, Col } from 'reactstrap';
import { VocFormCheckbox, VocFormTextBlock } from '/components';

export const subscribeEmailList = ({ fields, valueCustomerBlacklist, onChange }) => {
    return (
        <div className='customers-subscribe__list'>
            {
                fields.map((field, index) =>
                    <Row key={ index } className={ 'customers-subscribe__item' }>
                        <Col md={ 8 }>
                            <Field
                                name={ `${field}.address` }
                                className={ '' }
                                component={ VocFormTextBlock }
                            />
                        </Col>
                        <Col md={ 4 }
                            className={ `customers-subscribe__field ${valueCustomerBlacklist === 16 ? 'disabled' : ''}` }>
                            <Field
                                name={ `${field}.state` }
                                label='WEB'
                                theme={ 'checkboxChannelTheme' }
                                component={ VocFormCheckbox }
                                className={ 'checkbox-channel' }
                                type={ 'checkbox' }
                                onChange={ onChange }
                                normalize={ value => value ? 25 : 1 }
                                format={ value => value === 25 }
                            />
                        </Col>
                    </Row>,
                )
            }
        </div>
    );
};

subscribeEmailList.propTypes = {
    fields: propTypes.object,
    onChange: propTypes.func.isRequired,
    valueCustomerBlacklist: propTypes.number,
};

subscribeEmailList.defaultProps = {
    valueCustomerBlacklist: 0,
};
