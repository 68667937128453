import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { _getDashboardData } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { DashboardService } from '/visual/scenes/Dashboard/services';
import { IDrillDashboardsNavigation, IDrillDownBreadCrumb } from '/visual/models';

import { DrillDashboardNavigationItem } from './DrillDashboardNavigationItem';
import styles from './style.module.scss';

export const DrillDashboardsNavigation = ({
    actualDashboardId,
    onSelectDashboard,
}: IDrillDashboardsNavigation) => {
    const dashboard = useSelector(_getDashboardData);
    const [ dashboardCrumbs, setDashboardCrumbs ] = useState<IDrillDownBreadCrumb[]>([]);

    useEffect(() => {
        if (dashboard?.id && dashboard.id === actualDashboardId) {
            const breadCrumbs = DashboardService.getDashboardStructure({
                data: dashboard,
                existedBreadCrumbs: [],
            }) || [];

            setDashboardCrumbs(breadCrumbs);
        } else {
            setDashboardCrumbs([]);
        }
    }, [ dashboard, actualDashboardId ]);

    return dashboardCrumbs.length > 1 && dashboard.id === actualDashboardId
        ? <div className={ cx(styles.breadCrumbContainer) }>
            {
                dashboardCrumbs.map((drillCrumb: IDrillDownBreadCrumb) => (
                    <DrillDashboardNavigationItem
                        key={ drillCrumb.id }
                        drillDashboard={ drillCrumb }
                        onClickDashboard={ onSelectDashboard }
                    />
                ))
            }
        </div>
        : null;
};
