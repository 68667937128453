import React from 'react';
import { VocSelect, InputLoader } from '/components';

export const ChannelsPanelStatus = ({ changeStatusLoading, statuses, state, onChange, rightsSurvey: { isPermitApprove } }) => (
    changeStatusLoading
        ? <InputLoader text={ 'Sending...' }/>
        : <VocSelect
            options={ statuses }
            value={ statuses.filter(el => +el.value === state) }
            className={ 'selector-status-survey' }
            formatOptionLabel={ ({ label }) => (
                <div className={ `custom-option-dot custom-option-dot_${ label.toLowerCase() }` }>
                    { label }
                </div>
            ) }
            onChange={ onChange }
            disabled={ !isPermitApprove }
            portal
        />
);
