import React from 'react';

import { InputLoader, VocSelect } from '/components';
import { HelperService } from '/services';

import './LanguagesList.scss';

export const LanguagesList = ({
    data: {
        loading,
        languages,
    },
    changeHandler,
    selectClass,
    disabled,
}) => {
    const activeLanguage = languages.find(language => language.active);

    const getLanguagesList = hasLanguageText => HelperService.sortArrayWithObjects(
        languages.filter(language => hasLanguageText
            ? language.hasLanguageText
            : !language.hasLanguageText,
        ), 'label', 'asc',
    );

    const languagesOptions = [
        ...getLanguagesList(true),
        ...getLanguagesList(false),
    ];

    const formatOptionLabel = ({ active, hasLanguageText, label }) => {
        let languageClass = '';

        if (active) {
            languageClass = 'custom-option-language__active';
        } else if (hasLanguageText) {
            languageClass = 'custom-option-language__text';
        }

        return (
            <div className={ 'custom-option-language' }>
                <div className={ `${ languageClass }` }/>
                { label }
            </div>
        );
    };

    return (
        loading
            ? <div className={ 'languages-list__loader' }><InputLoader/></div>
            : <VocSelect
                options={ languagesOptions }
                value={ activeLanguage }
                className={ selectClass }
                formatOptionLabel={ formatOptionLabel }
                isSearchable={ false }
                zIndex={ 90 }
                portal
                onChange={ changeHandler }
                disabled={ disabled }
            />
    );
};

LanguagesList.defaultProps = {
    selectClass: 'selector-status-survey',
};
