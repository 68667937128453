import React, { useRef, useEffect } from 'react';
import cx from 'classnames';
import { SketchPicker, ColorResult } from 'react-color';
import { isMobile } from 'react-device-detect';

import { Portal, ControlledTooltip } from '/components';
import { HelperService } from '/services';

import { IColorPickerData } from '../../models';
import styles from './style.module.scss';

interface IChartColorPicker {
    chartType: string,
    gadgetId: string,
    colorPickerData: IColorPickerData | null,
    setColorPickerData: (data: IColorPickerData | null) => void,
    changeColorCallback: ({ elementId, colorHex }: { elementId: string, colorHex: string }) => void,
}

export const ChartColorPicker = ({
    chartType,
    gadgetId,
    colorPickerData,
    setColorPickerData,
    changeColorCallback,
}: IChartColorPicker) => {
    const colorPickerCoordinatesRef = useRef<HTMLDivElement | null>(null);
    const chartTarget = `${chartType}_colorPicker_${gadgetId}`;

    const closePicker = () => {
        setColorPickerData({
            open: false,
            coords: { y: 0, x: 0 },
            target: null,
            color: null,
            elementId: null,
        });
    };

    const checkColorPickerCoords = () => {
        const colorPickerCoordinates = colorPickerCoordinatesRef.current;

        if (colorPickerCoordinates && colorPickerData !== null) {
            const { coords, open, target } = colorPickerData;

            if (open) {
                if (target === chartTarget && coords) {
                    colorPickerCoordinates.style.top = `${coords.y}px`;
                    colorPickerCoordinates.style.left = `${coords.x}px`;
                }
            } else {
                colorPickerCoordinates.style.top = '0px';
                colorPickerCoordinates.style.left = '0px';
            }
        }
    };

    const onChangeColorHandler = (colorData: ColorResult) => {
        const colorHex = colorData.hex;

        if (colorPickerData && HelperService.checkNotNullOrUndefined(colorPickerData.elementId)) {
            setColorPickerData({
                ...colorPickerData,
                color: colorHex,
            });

            changeColorCallback({
                elementId: colorPickerData.elementId as string,
                colorHex: colorHex,
            });
        }
    };

    useEffect(() => {
        checkColorPickerCoords();
    }, [ colorPickerData ]);

    return (
        <>
            <Portal id={ 'chart_color_picker' }>
                <div
                    id={ chartTarget }
                    ref={ colorPickerCoordinatesRef }
                    className={ cx(styles.colorPickerCoords) }
                />
            </Portal>
            {
                !isMobile && colorPickerData && colorPickerData.target && <ControlledTooltip
                    isOpen={ colorPickerData.open }
                    target={ colorPickerData.target }
                    tooltipContent={
                        <SketchPicker
                            presetColors={ [] }
                            color={ colorPickerData?.color || undefined }
                            disableAlpha
                            onChange={ onChangeColorHandler }
                        />
                    }
                    onClose={ closePicker }
                />
            }
        </>
    );
};
