import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';
import { Label, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { PermissionSelect, SelectedValues } from './components';
import { MenuList, DropdownIndicator, Option } from '/components';

import './VocSelectInvite.scss';

const hubRights = (addOwnerRights, t) => {
    return [
        {
            label: t('role.viewer'),
            value: 'view',
            icon: 'eye',
            id: 'user_rights_invite_view',
        },
        {
            label: t('role.editor'),
            value: 'edit',
            icon: 'user-edit',
            id: 'user_rights_invite_edit',
        },
        {
            label: t('role.full'),
            value: 'full',
            icon: 'user-secret',
            id: 'user_rights_invite_full',
        },
        ...addOwnerRights
            ? [{
                label: t('role.owner'),
                value: 'owner',
                icon: 'user-cog',
                id: 'user_rights_invite_owner',
            }]
            : [],
    ];
};

export const VocSelectInvite = ({
    name,
    value,
    clearValueInSelect,
    isClearable,
    options,
    placeholder,
    inviteListPlaceholder,
    className,
    globalClass,
    onChange,
    onFocus,
    onBlur,
    disabled,
    removeSelected,
    isMulti,
    closeMenuOnSelect,
    formatOptionLabel,
    isRequired,
    label,
    isHorizontal,
    defaultValue,
    portal,
    zIndex,
    customRights,
    isOwnerRights,
}) => {
    const { t } = useTranslation();
    const optionsRights = customRights || hubRights(isOwnerRights, t);
    const [ isFocus, setFocus ] = useState(false);
    const [ rights, setRights ] = useState(optionsRights[0]);
    const selectEl = useRef(null);
    const classList = new Set([ 'vochub-select-control', isMulti && 'multiselect' ]);
    const filterSelectedOptions = options.filter(option => !value.some(valueData => valueData.value === option.value));

    const config = {
        className: Array.from(classList).concat(className).filter(Boolean).join(' '),
        classNamePrefix: "vochub-select-control",
        name,
        value: clearValueInSelect ? '' : value,
        isClearable,
        components: { MenuList, DropdownIndicator, Option },
        options: filterSelectedOptions,
        placeholder,
        onBlur,
        onFocus,
        isDisabled: disabled,
        removeSelected,
        isMulti,
        defaultValue,
        closeMenuOnSelect,
    };

    if (!isClearable) {
        config['backspaceRemovesValue'] = false;
    }

    if (isMulti) {
        config['hideSelectedOptions'] = true;
    }

    if (formatOptionLabel) {
        config['formatOptionLabel'] = formatOptionLabel;
    }

    if (portal) {
        config['menuPortalTarget'] = document.body;
        config['styles'] = { menuPortal: base => ({ ...base, zIndex }) };
        config['menuShouldScrollIntoView'] = false;
        config['menuPlacement'] = "auto";
    }

    const onChangeDataModel = dataKey => ({
        ...dataKey,
        permission: dataKey.permission ? dataKey.permission : rights.value,
    });

    const handleOnChange = select => {
        if (options.length === select.length) {
            selectEl.current.onMenuClose();
        }

        const selected = Array.isArray(select)
            ? select.map(option => onChangeDataModel(option))
            : onChangeDataModel(select);

        onChange(selected);
    };

    const handleValue = value => {
        setFocus(!!value.length);
    };

    return (
        <Row className={ globalClass }>
            {
                label
                && <Col md={ isHorizontal ? 3 : 12 } className="select-control-label">
                    <Label for={ name }>
                        { label }
                        { isRequired ? <span className='star'> *</span> : '' }
                    </Label>
                </Col>
            }
            <Col md={ 6 }>
                {
                    <div
                        className={ `voc-select-control-wrap voc-select-control-wrap_mine isMultiOutside` }
                    >
                        <Select
                            ref={ selectEl }
                            onInputChange={ handleValue }
                            onChange={ handleOnChange }
                            { ...config }
                        />

                        <PermissionSelect
                            onChange={ value => setRights(value) }
                            options={ optionsRights }
                            value={ rights }
                        />
                        {
                            !isFocus
                            && <div className="voc-select-control-wrap__placeholder">{ placeholder }</div>
                        }
                    </div>
                }
            </Col>

            <Col md={ 6 } className='selctTable'>
                <SelectedValues
                    value={ value }
                    optionsRights={
                        [
                            ...optionsRights.map(optionRight => ({
                                ...optionRight,
                                id: `users_rights_invite_${optionRight.value}`,
                            })),
                            {
                                label: t('remove'),
                                value: 'remove',
                                icon: 'trash-alt',
                                id: 'users_rights_invite_remove',
                            },
                        ]
                    }
                    onChange={ handleOnChange }
                />
                {
                    !value.length
                    && <div className={ cx("invite-list__placeholder", "voc-select-control-wrap__placeholder") }>
                        { inviteListPlaceholder }
                    </div>
                }
            </Col>
        </Row>
    );
};

VocSelectInvite.propTypes = {
    isRequired: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    })),
    isClearable: PropTypes.bool,
    isHorizontal: PropTypes.bool,
    removeSelected: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    portal: PropTypes.bool,
    clearValueInSelect: PropTypes.bool,
    customRights: PropTypes.array,
};

VocSelectInvite.defaultProps = {
    isMulti: false,
    placeholder: 'Select...',
    zIndex: 9999,
    value: [],
    isHubRights: true,
    clearValueInSelect: false,
};
