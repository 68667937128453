import { Http } from '../../store/middleware/HttpClient';
import { FEEDBACK } from '../../store/middleware/api';

export class JourneyService {
    static getProjectsExpanded() {
        const url = 'project/expand-basic/?limit=9999';
        const request = {
            method: 'GET',
            endpoint: url,
            body: {
                limit: 9999,
            },
            apiType: FEEDBACK,
            contentType:'application/json',
        };

        return Http.get(request);
    }

    static getSurveysByTouchPointId(id) {
        const url = `survey-group/expand-full/`;
        const request = {
            method: 'GET',
            endpoint: url,
            body: {
                limit: 9999,
                sort: '-sequence',
                touch_point_id: id,
            },
            apiType: FEEDBACK,
            contentType:'application/json',
        };

        return Http.get(request);
    }
}
