import type { BaseSyntheticEvent } from 'react';
import {
    axisBottom, axisLeft, axisRight,
    curveLinear, drag, event, format,
    line, mouse, scaleBand, scaleLinear,
    select, Selection,
} from 'd3';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';
import { autobind } from 'core-decorators';
import { arrayMove } from 'react-sortable-hoc';
import { TFunction } from 'i18next';

import { AxisService } from '/visual/scenes/Dashboard/components';
import type {
    barData, chartEnum, domainTickData,
    IBaseChartService, IChartServiceInit, IColorPickerData,
    IDrawContainer, IDrawXAxis, IDrawYAxis,
    IDrillDownSelection, IPreConfig, npsDataItemType,
    npsDataType, onItemEnterType, tickData,
    TPrivateSetHighlightedLine,
} from '/visual/scenes/Dashboard/components/Gadget/models';
import { ELabelType } from '/visual/scenes/Dashboard/components/Gadget/models/global.model';
import { charts as chartConstant } from '/visual/scenes/Dashboard/components/Gadget/contstants';
import { HelperService } from '/services';

import styles from './style.module.scss';

export class BaseChartService {
    /* public constants */
    containerClass = 'container';
    yAxisClass = 'yAxis';
    yRightAxisClass = 'yRightAxis';
    elementsContainerClass = 'elementsContainer';
    xAxisClass = 'xAxis';
    gridClass = 'grid';
    verticalGridClass = 'verticalGrid';
    horizontalGridClass = 'horizontalGrid';
    pseudoAxisClass = 'pseudoAxis';
    gridLineClass = 'gridLine';
    groupClass = 'barGroup';
    groupItemClass = 'bar';
    interactiveClass = 'interactive';
    npsLineGroupClass = 'npsLineGroup';
    npsLineClass = 'npsLine';
    npsDotClass = 'npsCircle'
    itemHoveredClass = 'itemHovered';
    transparentClass = 'transparent';
    scoreClass = 'score';
    tickClass = 'tick';
    animationDuration = 300;
    animationDelay = 300;
    scaleYBand = 1.05;
    scaleXBand = 1.1;

    /* class properties */
    chartConfig: any;
    chartType: string;
    svgContainer: any;
    chartContainer: any;
    chartWidth: number;
    chartHeight: number;
    toolTipRef: any;
    activeTickId: string | null;
    drillDownEnabled?: boolean = false;
    yScale: any;
    yRightScale: any;
    xScale: any;
    xBandScale: any;
    isInitialized: boolean;
    onItemEventEnabled: boolean;
    xAxisSize?: number | null;
    tickList?: tickData[];
    maxYValue: number;
    maxXValue: number;
    maxCountSlicePerGroup: number;
    draggedItemId: string | null;
    gadgetId: string;
    gadgetFunction: string;
    isNpsChart: boolean;
    npsData: npsDataType;
    npsLineFn: any;
    protected _dndConfig: {
        groups: barData[],
        allList: barData[],
        withDnD?: boolean,
        redraw?: (((d: barData[], allList: barData[]) => void)) | null,
    };
    protected _withPseudoAxis = false;
    protected readonly _percentage?: boolean;
    protected readonly _decimalDigits?: string;
    protected readonly _withVerticalGrid?: boolean;
    protected readonly _withHorizontalGrid?: boolean;
    protected readonly _showScore?: boolean;
    protected readonly _t: TFunction;
    protected readonly _setTickModal?: IBaseChartService['setTickModal']
    protected readonly _resetTickModal?: (() => void) | null;
    protected readonly _saveTickOrder?: ((order: string[]) => void) | null;
    protected readonly _setXAxisSize?: ((xAxisHeight: number) => void) | null;
    protected readonly _setInnerCircleData?: IBaseChartService['setInnerCircleData'];
    protected readonly _setColorPickerData: (data: IColorPickerData) => void;
    protected readonly _drillDownFromSelection?: (d: IDrillDownSelection) => void;

    constructor(
        {
            chartType,
            svgRef,
            toolTipRef,
            gadgetId,
            gadgetFunction = 'count',
            decimalDigits,
            drillDownEnabled = false,
            percentage = false,
            withVerticalGrid = false,
            withHorizontalGrid = false,
            t,
            showScore = false,
            setTickModal = null,
            resetTickModal = null,
            saveTickOrder = null,
            setXAxisSize = null,
            setColorPickerData,
            setInnerCircleData,
            drillDownFromSelection,
        }: IBaseChartService) {
        this.chartConfig = chartConstant[chartType as keyof chartEnum];
        this.chartType = chartType;
        this.svgContainer = null;
        this.toolTipRef = null;
        this.activeTickId = null;
        this.gadgetId = gadgetId;
        this.gadgetFunction = gadgetFunction;
        this.drillDownEnabled = drillDownEnabled;
        this.chartContainer = null;
        this.chartWidth = 0;
        this.chartHeight = 0;
        this.yScale = null;
        this.yRightScale = null;
        this.xScale = null;
        this.xBandScale = null;
        this.isInitialized = false;
        this.onItemEventEnabled = false;
        this.xAxisSize = null;
        this.tickList = [];
        this.maxYValue = 0;
        this.maxXValue = 0;
        this.maxCountSlicePerGroup = 0;
        this.draggedItemId = null;
        this.isNpsChart = false;
        this.npsData = {
            data: [],
            id: '',
            label: '',
            color: 'black',
            customId: '',
        };
        this.npsLineFn = null;
        this._dndConfig = { groups: [], allList: [], withDnD: false, redraw: null };
        this._percentage = percentage;
        this._decimalDigits = decimalDigits;
        this._withVerticalGrid = withVerticalGrid;
        this._withHorizontalGrid = withHorizontalGrid;
        this._t = t;
        this._showScore = showScore;
        this._withPseudoAxis = false;
        this._setTickModal = setTickModal;
        this._resetTickModal = resetTickModal;
        this._saveTickOrder = saveTickOrder;
        this._setXAxisSize = setXAxisSize;
        this._setInnerCircleData = setInnerCircleData;
        this._setColorPickerData = setColorPickerData;
        this._drillDownFromSelection = drillDownFromSelection;

        this.init({
            svgRef,
            toolTipRef,
            gadgetId,
        });
    }

    /* protected methods */
    protected _setInitialized(isInitialized: boolean) {
        this.isInitialized = !!this.svgContainer && !!this.chartContainer && isInitialized;
    }

    protected _percentToDecimal(percent: number | null) {
        return HelperService.checkNotNullOrUndefined(percent) ? percent as number / 100 : 0;
    }

    protected _decimalToPercent(decimal: number) {
        return 100 * decimal;
    }

    protected _changeOrder(currId: string, targetId: string) {
        const { groups, allList, redraw } = this._dndConfig;

        const findIndex = ({ list, prevId, nextId }: { list: any[], prevId: string, nextId: string }) => {
            const oldIndex = list.findIndex((item: { id: string }) => item.id === prevId);
            const newIndex = list.findIndex((item: { id: string }) => item.id === nextId);

            return { oldIndex, newIndex };
        };

        // swap nps dots as groups (dynamically)
        if (this.isNpsChart) {
            const list = this.npsData.data;
            const { oldIndex: oldNpsIndex, newIndex: newNpsIndex } = findIndex({
                list: list,
                prevId: currId,
                nextId: targetId,
            });

            this.npsData.data = arrayMove(list, oldNpsIndex, newNpsIndex);
        }

        const { oldIndex, newIndex } = findIndex({ list: groups, prevId: currId, nextId: targetId });
        const { oldIndex: oldIndexFromFullList, newIndex: newIndexFromFullList } = findIndex({
            list: allList,
            prevId: currId,
            nextId: targetId,
        });

        this._dndConfig.groups = arrayMove(groups, oldIndex, newIndex);
        this._dndConfig.allList = arrayMove(allList, oldIndexFromFullList, newIndexFromFullList);

        redraw?.(this._dndConfig.groups, this._dndConfig.allList);
    }

    @autobind
    protected _getHorizontalGridY(value: number) {
        return this.yScale(value);
    }

    @autobind
    protected _getVerticalGridX(barGroupId: string) {
        return this.xScale(barGroupId) + (this.xScale.bandwidth() / 2);
    }

    @autobind _getXAxisTicks() {
        return Math.floor(this.chartWidth / this.chartConfig.decimateSize);
    }

    @autobind
    protected _setActiveTickId({ id }: { id: string | null }) {
        this.activeTickId = id;
    }

    @autobind
    protected _getTickValues({ domainValues }: { domainValues: string[] }) {
        const tickSlice = [];
        const ticks = this._getXAxisTicks();
        let step = Math.ceil(domainValues.length / (ticks - 1));

        if (step === 0) step = 1;

        const formattingTick = (index: number) => ({ id: domainValues[index], index });

        // add first domain value
        tickSlice.push(formattingTick(0));
        domainValues.forEach((domain: string, index: number, list: string[]) => {
            // first and last of domain values always placed on x-axis
            if (index === 0 || index === list.length - 1) return;

            const isMultipleOfStep = index % step === 0;
            const isTickDraggingNow = HelperService.checkNotNullOrUndefined(this.activeTickId)
                && domainValues[index] === this.activeTickId;

            if (isMultipleOfStep || isTickDraggingNow) {
                tickSlice.push(formattingTick(index));
            }
        });

        // add last domain value
        if (domainValues.length > 1) {
            tickSlice.push(formattingTick(domainValues.length - 1));
        }

        return tickSlice;
    }

    protected _xScaleBand({
        domainValues,
        rangeFrom,
        rangeTo,
    }: {
        domainValues: Iterable<string>,
        rangeFrom: number,
        rangeTo: number,
    }) {
        return scaleBand()
            .domain(domainValues)
            .range([ rangeFrom, rangeTo ]);
    }

    @autobind
    protected _tickClick(d: tickData, i: number, nodes: any[]) {
        this._resetTickModal?.();

        setTimeout(() => {
            this._setTickModal?.({
                open: true,
                target: nodes[i],
                id: d.id !== null ? d.id : '',
                value: d.label !== null ? d.label : '',
                type: ELabelType.TICK,
            });
        }, 100);
    }

    @autobind
    protected _updateHorizontalLine(linesGroup: any) {
        return linesGroup
            .attr('x1', 0)
            .attr('x2', this.chartWidth)
            .attr('y1', this._getHorizontalGridY)
            .attr('y2', this._getHorizontalGridY)
            .style('visibility', (d: number) => {
                // hide grid line if lying on abscissa axis
                return (this.yScale(0) === this._getHorizontalGridY(d) && this.chartType !== 'nps_bubble_chart')
                    ? 'hidden'
                    : 'visible';
            });
    }

    @autobind
    protected _updateVerticalLine(linesGroup: any) {
        return linesGroup
            .attr('x1', this._getVerticalGridX)
            .attr('x2', this._getVerticalGridX)
            .attr('y1', 0)
            .attr('y2', this.chartHeight);
    }

    @autobind
    protected _onItemEnter(d: onItemEnterType) {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        const {
            showParentId = false,
            label,
        } = this.chartConfig?.tooltip || {};
        const { id: parentId, label: parentLabel } = d.parentData || {};

        this.toolTipRef.html('');

        if (showParentId && HelperService.checkNotNullOrUndefined(parentId) && parentId !== 'root') {
            this.toolTipRef
                .append('span')
                .text(parentLabel);
        }

        this.toolTipRef
            .append('span')
            .text(`${ label(this._t) }: ${HelperService.checkNotNullOrUndefined(d.label) ? d.label : 'No value'}`);

        if (this.gadgetFunction !== 'affection') {
            this.toolTipRef
                .append('span')
                .text(`${ this._t('field.percentage') }: ${d.percent}%`);
        }

        this.toolTipRef
            .append('span')
            .style('text-transform', 'capitalize')
            .text(`${this.gadgetFunction}: ${d.score}`);
    }

    @autobind
    protected _onItemOverMove() {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        const { target } = event;

        target.classList.add(this.itemHoveredClass);

        !isMobile && this.toolTipRef
            .style('visibility', 'visible')
            .style('top', `${event.clientY + 15}px`)
            .style('left', `${event.clientX + 15}px`);
    }

    @autobind
    protected _onNpsItemEnter(d: npsDataItemType) {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        this.toolTipRef.html('');
        this.toolTipRef
            .append('span')
            .text(d.label);
        this.toolTipRef
            .append('span')
            .text(`${ this._t('field.name') }: ${this.npsData.label}`);
        this.toolTipRef
            .append('span')
            .text(`${ this._t('field.value') }: ${d.score.toFixed(2)}`);
        this.toolTipRef
            .append('span')
            .text(`${ this._t('field.answers') }: ${d.count}`);
    }

    @autobind
    protected _onNpsItemOver(d: npsDataItemType) {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        this._onItemOverMove();
        const highlightedLineId = this.npsData.id;

        this.setHighlightedNpsLine(highlightedLineId, d.customId);
    }

    @autobind
    protected _onNpsItemOut() {
        if (HelperService.checkNotNullOrUndefined(this.draggedItemId)) return;

        const { target } = event;

        this.setHighlightedNpsLine(null);

        this.toolTipRef
            .style('visibility', 'hidden')
            .html('');

        target.classList.remove(this.itemHoveredClass);
    }

    @autobind
    protected _setHighlightedLine({
        highlightedLineId,
        dotCustomId,
        lineGroupClass,
        dotClass,
        lineClass,
    }: TPrivateSetHighlightedLine) {
        const isHoverOnNpsLine = HelperService.checkNotNullOrUndefined(highlightedLineId) && highlightedLineId === 'npsScore';
        const lineGroups = this.chartContainer.selectAll(`.${ isHoverOnNpsLine ? this.npsLineGroupClass : lineGroupClass }`);
        let currLineGroup;

        if (isHoverOnNpsLine) {
            currLineGroup = lineGroups;
        } else {
            currLineGroup = HelperService.checkNotNullOrUndefined(highlightedLineId)
                ? lineGroups
                    .filter((lineGroup: { id: string }) => lineGroup?.id === highlightedLineId)
                    // the hovered line and line's points should be at the top of the overlay
                    // even after cursor lose target
                    .raise()
                : null;
        }

        if (currLineGroup && (HelperService.checkNotNullOrUndefined(highlightedLineId) || highlightedLineId === '')) {
            const dots = currLineGroup
                .selectAll(`.${ isHoverOnNpsLine ? this.npsDotClass : dotClass }`)
                .filter((dot: { customId: string }) => dot.customId === dotCustomId);
            const linePath = currLineGroup.selectAll(`.${ isHoverOnNpsLine ? this.npsLineClass : lineClass }`);
            const chartConfig = isHoverOnNpsLine
                ? this.chartConfig.nps
                : this.chartConfig;

            dots
                .transition()
                .duration(this.animationDuration)
                .attr('r', chartConfig.overRadius)
                .attr('stroke-width', chartConfig.overStrokeWidth);
            linePath
                .transition()
                .duration(this.animationDuration)
                .attr('stroke-width', chartConfig.overStrokeWidth);
        } else {
            const dots = this.chartContainer.selectAll(`.${ dotClass }`);
            const linePath = this.chartContainer.selectAll(`.${ lineClass }`);
            const npsDots = this.chartContainer.selectAll(`.${ this.npsDotClass }`);
            const npsLinePath = this.chartContainer.selectAll(`.${ this.npsLineClass }`);

            dots
                .transition()
                .duration(this.animationDuration)
                .attr('r', this.chartConfig.dotRadius)
                .attr('stroke-width', this.chartConfig.lineStrokeWidth);
            linePath
                .transition()
                .duration(this.animationDuration)
                .attr('stroke-width', this.chartConfig.lineStrokeWidth);
            npsDots
                .transition()
                .duration(this.animationDuration)
                .attr('r', this.chartConfig.nps.dotRadius)
                .attr('stroke-width', this.chartConfig.nps.lineStrokeWidth);
            npsLinePath
                .transition()
                .duration(this.animationDuration)
                .attr('stroke-width', this.chartConfig.nps.lineStrokeWidth);
        }

        this.setHighlightedSlice(highlightedLineId);
    }

    @autobind
    protected _dragStart(d: { id: string }) {
        const barGroup = this.chartContainer.selectAll(`.${this.groupClass}`);

        // add dragged tick to DOM (for dragging method)
        this._setActiveTickId({ id: d.id });

        barGroup
            .transition()
            .duration(this.animationDuration)
            .delay(this.animationDelay)
            .attr('opacity', (group: barData) => group.id === d.id ? 1 : 0.4);

        // close change tick modal
        this._resetTickModal?.();
    }

    @autobind
    protected _dragging(d: { id: string }) {
        const { groups } = this._dndConfig;
        const barGroup = this.chartContainer.selectAll(`.${this.groupClass}`);

        if (barGroup.size() === 1) {
            return;
        }

        this.draggedItemId = d.id;

        const curBar = barGroup.filter((bar: { id: string }) => bar.id === d.id);
        const currTick = this.chartContainer
            .selectAll(`.${this.xAxisClass} .${this.tickClass}`)
            .filter((tick: { id: string }) => tick.id === d.id);
        const step = this.xScale.step();
        const transitionBorder = this.xScale.step() / 2;

        const [ newX ] = mouse(currTick.node()); // returned as [x, y]
        const curIdx = groups.findIndex((item: { id: string | null }) => item.id === curBar.datum().id);

        // Get the index of the nearest bar
        let nearestIdx: number;

        if (newX > 0) {
            nearestIdx = curIdx + (Math.floor( Math.abs(newX) / step) + 1);
        } else {
            nearestIdx = curIdx - (Math.floor( Math.abs(newX) / step) + 1);
        }

        if (nearestIdx < 0 || nearestIdx > barGroup.size() - 1) return;

        const nearestBar = barGroup.filter((bar: { id: string }) => {
            return bar.id === groups[nearestIdx].id;
        });

        /* If the current bar is moved close enough to the nearest bar,
           then update the order of the data array. For example, if we are dragging
           the first bar and moving to right, the order will be [a, b, c] to [b, a, c] */
        if (Math.abs(newX) > transitionBorder) {
            const curBarId = curBar.datum().id;
            const targetBarId = nearestBar.datum().id;

            this._changeOrder(curBarId, targetBarId);
        }
    }

    @autobind
    protected _dragEnd() {
        const barGroup = this.chartContainer.selectAll(`.${this.groupClass}`);
        const order = this._dndConfig.allList.map((d: { id: string | null }) => d.id !== null ? d.id : '');

        barGroup
            .transition()
            .duration(this.animationDuration)
            .delay(this.animationDelay)
            .attr('opacity', 1);

        if (HelperService.checkNotNullOrUndefined(this.draggedItemId) && this._saveTickOrder) {
            this.draggedItemId = null;
            this._saveTickOrder?.(order);
        }

        // reset active tick
        this._setActiveTickId({ id: null });
    }

    @autobind
    protected _getNpsLineX(d: npsDataItemType, index: number) {
        return this.xScale(this.npsData.data[index].id) + this.xScale.bandwidth() / 2;
    }

    @autobind
    protected _getNpsLineY(d: npsDataItemType) {
        return this.yRightScale(d.score);
    }

    @autobind
    protected _getNpsScoreY(d: npsDataItemType) {
        const npsYValue = this._getNpsLineY(d);

        return (npsYValue < 20) ? npsYValue + 20 : npsYValue - 10;
    }

    @autobind
    protected _getNpsScoreText(d: npsDataItemType) {
        return Math.round(d.score * 100) / 100;
    }

    @autobind
    protected _getNpsColor() {
        return this.npsData.color;
    }

    @autobind
    protected _getNpsDotId(d: npsDataItemType) {
        return d.customId;
    }

    @autobind
    protected _onNpsColorPickerOpen() {
        this._setColorPickerData({
            open: true,
            coords: { y: event.pageY, x: event.pageX },
            target: `${this.chartType}_colorPicker_${this.gadgetId}`,
            color: this.npsData.color,
            elementId: this.npsData.id,
        });

        event.preventDefault();
    }

    @autobind
    protected _addTickHoverEvent(selector: any) {
        return selector
            .style('cursor', 'pointer')
            .on('mouseenter', this.tickEnter)
            .on('mouseover', this.tickOver)
            .on('mousemove', this.tickOver)
            .on('mouseout', this.tickOut);
    }

    /* public methods */
    @autobind
    setHighlightedNpsLine(highlightedLabel: string | number | null, dotCustomId?: string) {
        this._setHighlightedLine({
            highlightedLineId: highlightedLabel,
            dotCustomId,
        });
    }

    @autobind
    getYAxisTickFormat(maxValue: number): (n: number | { valueOf(): number; }) => string {
        // If the precision is not specified, it defaults to 6 for all types except '' (none), which defaults to 12.
        let yFormat = '';

        if (this._percentage) {
            yFormat = '.0~%';
            if (maxValue <= 0.1) yFormat = '.1~%';
            if (maxValue <= 0.01) yFormat = '.2~%';
        } else {
            const beforeDecimal = Number(maxValue.toString().split('.')[0]);

            if (beforeDecimal > 12) return (n: number | { valueOf(): number; }): string => n.toString();
            if (maxValue <= 0.01) yFormat = '.2~f';
        }

        return format(yFormat);
    }

    getXAxisTickFormat(maxValue: number): (n: number | { valueOf(): number; }) => string {
        // If the precision is not specified, it defaults to 6 for all types except '' (none), which defaults to 12.
        let xFormat = '';

        /**
         * The general form of a specifier is [[fill]align][sign][symbol][0][width][,][.precision][~][type].
         * 'f' - fixed point notation.
         * The '~' option trims insignificant trailing zeros across all format types.
         * @example 0.009 become 0.01 if maxValue less 0.01
         */
        if (maxValue <= 0.01) xFormat = '.2~f';

        return format(xFormat);
    }

    @autobind
    tickEnter(d: tickData) {
        this.toolTipRef
            .html('')
            .append('span')
            .text(
                HelperService.checkNotNullOrUndefined(d?.label)
                    ? d.label
                    : 'No value',
            );
    }

    @autobind
    tickOver(syntheticEvent: BaseSyntheticEvent) {
        const eventObj = event || syntheticEvent;

        if (eventObj) {
            const { target } = eventObj;

            target.parentNode.classList.add(this.itemHoveredClass);

            this.toolTipRef
                .style('visibility', 'visible')
                .style('top', `${eventObj.clientY + 15}px`)
                .style('left', `${eventObj.clientX + 15}px`);
        }
    }

    @autobind
    tickOut(syntheticEvent: BaseSyntheticEvent) {
        const eventObj = event || syntheticEvent;

        if (eventObj) {
            const { target } = eventObj;

            target.parentNode.classList.remove(this.itemHoveredClass);
        }

        this.toolTipRef
            .style('visibility', 'hidden');
    }

    @autobind
    setHighlightedSlice(highlightedLabel: string | number | null) {
        if (this.isInitialized) {
            this.chartContainer
                .selectAll(`.${this.groupItemClass}`)
                .classed(this.transparentClass, (d: { id: string }) =>
                    highlightedLabel !== null && d.id !== highlightedLabel,
                );

            this.chartContainer
                .selectAll(`.${this.npsLineGroupClass}`)
                .classed(
                    this.transparentClass,
                    highlightedLabel !== null && highlightedLabel !== 'npsScore',
                );
        }
    }

    @autobind
    checkIfNeedRedrawing() {
        // if user dragging item, then disable xAxisSize changing.
        // It's prevent emitting useEffect in D3 component and redrawing chart with initial dataSet
        if (this._setXAxisSize && !HelperService.checkNotNullOrUndefined(this.draggedItemId)) {
            const xAxis = this.chartContainer.selectAll(`.${this.xAxisClass}`);

            if (xAxis && xAxis.node()) {
                // if chart has negative boundary of the domain and has no visible ticks
                // take the xBottomAxis height as zero pixels
                const isTickListEmpty = this._withPseudoAxis && xAxis
                    .selectAll(`.${this.tickClass} > text > tspan`)
                    .nodes()
                    .map((i: SVGTextElement) => i.innerHTML)
                    .filter((text: string) => HelperService.checkNotNullOrUndefined(text) && text !== '')
                    .length === 0;
                const xAxisHeight = isTickListEmpty ? null : xAxis.node().getBoundingClientRect().height;

                // prevent an "infinite loop" in case of manual resizing or scaling, where the size of the container
                // and the contents of the SVG change, leading to changes in the X-axis size which in turn causes
                // the height of the SVG contents to change
                const xAxisHeightRounded = Math.round(xAxisHeight);

                this._setXAxisSize?.(xAxisHeightRounded);
                // if new size not equal to old size, wait for rerender
                // only after that, chart element can be drawn
                this.onItemEventEnabled = this.xAxisSize === xAxisHeightRounded;
            }
        } else {
            this.onItemEventEnabled = true;
        }
    }

    drawContainer({
        svgWidth,
        svgHeight,
        chartWidth,
        chartHeight,
        transformValue,
        isNpsChart = false,
        npsData = null,
    }: IDrawContainer) {
        this.chartWidth = chartWidth;
        this.chartHeight = chartHeight;

        this.svgContainer
            .attr('width', svgWidth)
            .attr('height', svgHeight);
        this.chartContainer
            .style('transform', transformValue);

        if (isNpsChart && npsData) {
            this.isNpsChart = isNpsChart;
            this.npsData = npsData;
        }

        return this;
    }

    drawYAxis({
        domainMin,
        domainMax,
        rangeFrom,
        rangeTo,
        tickFormat,
    }: IDrawYAxis) {
        // tick count from config
        const {
            yLeftTicksCount,
            yTickOffset,
            yLeftTickSize,
            yLeftTickPadding,
        } = this.chartConfig;
        const tickMaxWidth = yTickOffset - yLeftTickSize - yLeftTickPadding;
        const yScale = scaleLinear()
            .domain([ domainMin, domainMax ])
            .range([ rangeFrom, rangeTo ])
            .nice();

        // tick values without formatting
        const originalTickValues = yScale.ticks(yLeftTicksCount);
        const yAxisLeft = axisLeft(yScale);

        // set count of ticks
        yAxisLeft.ticks(yLeftTicksCount);
        yAxisLeft.tickSize(yLeftTickSize);
        yAxisLeft.tickPadding(yLeftTickPadding);

        // format each tick
        if (tickFormat) {
            yAxisLeft.tickFormat(tickFormat);
        }

        this.yScale = yScale;

        this.chartContainer
            .selectAll(`.${this.yAxisClass}`)
            .call((selection: Selection<SVGSVGElement, any, any, any>) =>
                this.gadgetFunction !== 'affection' && yAxisLeft(selection));

        if (this._withHorizontalGrid) {
            // get horizontal grid container (<g /> tag) and search all vertical grid line
            const gridLines = this.chartContainer
                .select(`.${this.horizontalGridClass}`)
                .selectAll(`.${this.gridLineClass}`)
                .data(this.yScale.ticks(yLeftTicksCount));

            // build vertical grid line
            gridLines
                .enter()
                .append('line')
                .attr('class', cx(this.gridLineClass, styles.gridLine))
                .call(this._updateHorizontalLine);

            // remove unnecessary grid lines
            gridLines
                .exit()
                .remove();

            // update coordinates for each existed grid line
            gridLines.call(this._updateHorizontalLine);
        }

        const yTicksTextsSelector = this.chartContainer
            .selectAll(`.${this.yAxisClass} .${this.tickClass} > text`);

        const yTicksFormattedText = yTicksTextsSelector
            .nodes()
            .map((i: SVGTextElement) => i.innerHTML);

        yTicksTextsSelector
            .text((a: number | string, i: number) =>
                HelperService.trimByWidth(yTicksFormattedText[i], tickMaxWidth),
            );

        this.chartContainer
            .selectAll(`.${this.yAxisClass} .${this.tickClass}`)
            .data(yTicksFormattedText.map((d: string, index: number) => {
                const valueFromOriginalList = originalTickValues[index];

                return {
                    label: this._percentage
                        ? `${this._decimalToPercent(valueFromOriginalList)} %`
                        : valueFromOriginalList,
                };
            }))
            .call(this._addTickHoverEvent);

        return this;
    }

    drawXAxis({
        domainValues,
        rangeFrom,
        rangeTo,
        bandPadding,
        bandInnerPadding,
        bandOuterPadding,
        additionalSettings,
    }: IDrawXAxis) {
        const tickValues = this._getTickValues({ domainValues });
        const xScale = this._xScaleBand({
            domainValues,
            rangeFrom,
            rangeTo,
        });

        if (bandPadding) {
            xScale.padding(bandPadding);
        }

        if (bandInnerPadding) {
            xScale.paddingInner(bandInnerPadding);
        }

        if (bandOuterPadding) {
            xScale.paddingOuter(bandOuterPadding);
        }

        const xAxisBottom = axisBottom(xScale);

        xAxisBottom.ticks(tickValues.length);
        xAxisBottom.tickValues(tickValues.map((tick: domainTickData) => tick.id));

        const xAxis = this.chartContainer
            .selectAll(`.${this.xAxisClass}`)
            .classed(styles.transparentAxisLine, this._withPseudoAxis)
            .call(xAxisBottom);
        const pseudoAxisElement = this.chartContainer
            .selectAll(`.${this.pseudoAxisClass}`)
            .classed(styles.transparentAxisLine, !this._withPseudoAxis);

        if (additionalSettings) {
            additionalSettings.forEach(({ prop, value }) => {
                Array.isArray(value) ? xAxis[prop](...value) : xAxis[prop](value);
            });
        }

        if (this._withPseudoAxis) {
            const xPseudoScale = xAxisBottom.tickSizeOuter(0);

            xPseudoScale.tickValues([]);
            pseudoAxisElement
                .style('transform', `translateY(${this.yScale(0)}px)`)
                .call(xPseudoScale);
        }

        this.xScale = xScale;

        if (this._withVerticalGrid) {
            // get vertical grid container (<g /> tag) and search all vertical grid line
            const gridLines = this.chartContainer
                .select(`.${this.verticalGridClass}`)
                .selectAll(`.${this.gridLineClass}`)
                .data(tickValues.map((tick: domainTickData) => tick.id));

            // build vertical grid line
            gridLines
                .enter()
                .append('line')
                .attr('class', cx(this.gridLineClass, styles.gridLine))
                .call(this._updateVerticalLine);

            // remove unnecessary grid lines
            gridLines
                .exit()
                .remove();

            // update coordinates for each existed grid line
            gridLines.call(this._updateVerticalLine);
        }

        // draw ticks
        const tickFormattedValues = tickValues.map(
            (tick: domainTickData) => ({
                label: this.tickList ? this.tickList[tick.index].label : null,
                id: tick.id,
            }),
        );

        const xTicks = this.chartContainer
            .selectAll(`.${this.xAxisClass} .${this.tickClass}`)
            .data(tickFormattedValues)
            .order()
            .on('click', this._tickClick)
            .call(this._addTickHoverEvent)
            .on('.drag', null);

        if (this._dndConfig.withDnD) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const dragHandler = drag()
                .on('start', this._dragStart)
                .on('drag', this._dragging)
                .on('end', this._dragEnd);

            xTicks.call(dragHandler);
        }

        AxisService.rotateTicks({
            dataSet: tickFormattedValues,
            chartWidth: this.chartWidth,
            xAxisView: this.chartContainer.selectAll(`.${this.xAxisClass} .${this.tickClass} text`),
            withPseudoAxis: this._withPseudoAxis as boolean,
        });

        this.checkIfNeedRedrawing();

        return this;
    }

    drawRightAxis() {
        if (!this.isNpsChart) return this;

        // tick count from config
        const {
            yRightTicksCount,
            yRightTickSize,
            yRightTickPadding,
        } = this.chartConfig;

        const yRightScale = scaleLinear()
            .domain([ -100, 100 ])
            .range([ this.chartHeight, 0 ])
            .nice();

        const yRightAxis = axisRight(yRightScale);

        // set count of ticks
        yRightAxis.ticks(yRightTicksCount);
        yRightAxis.tickSize(yRightTickSize);
        yRightAxis.tickPadding(yRightTickPadding);

        const yRightScaleView = this.chartContainer
            .selectAll(`.${this.yRightAxisClass}`)
            .call(yRightAxis);

        yRightScaleView
            .style('transform', `translateX(${this.chartWidth}px)`);

        const yRightTickValues = this.chartContainer
            .selectAll(`.${this.yRightAxisClass} .${this.tickClass} > text`)
            .nodes()
            .map((i: SVGTextElement) => i.innerHTML);

        this.chartContainer
            .selectAll(`.${this.yRightAxisClass} .${this.tickClass}`)
            .data(yRightTickValues.map((i: string) => ({ label: i })))
            .call(this._addTickHoverEvent);

        this.yRightScale = yRightScale;

        return this;
    }

    drawNpsLine() {
        if (!this.isNpsChart) return this;

        const linesGroup = this.chartContainer
            .selectAll(`.${this.npsLineGroupClass}`);

        this.npsLineFn = line<any>()
            .x(this._getNpsLineX)
            .y(this._getNpsLineY)
            .curve(curveLinear);

        linesGroup.select(`.${this.npsLineClass}`)
            .attr('d', this.npsLineFn(this.npsData.data))
            .attr('stroke', this._getNpsColor)
            .attr('stroke-width', this.chartConfig.nps.lineStrokeWidth);

        linesGroup
            .selectAll(`.${this.npsDotClass}`)
            .data(this.npsData.data)
            .join(
                (enter: any) => {
                    enter
                        .append('circle')
                        .attr('cx', this._getNpsLineX)
                        .attr('cy', this._getNpsLineY)
                        .attr('r', this.chartConfig.nps.dotRadius)
                        .attr('fill', 'white')
                        .attr('stroke', this._getNpsColor)
                        .attr('stroke-width', this.chartConfig.nps.lineStrokeWidth)
                        .attr('id', this._getNpsDotId)
                        .attr('class', cx(this.npsDotClass, styles.npsDot))
                        // add event listeners for each nps dot
                        .on('contextmenu', this._onNpsColorPickerOpen)
                        .on('mouseenter', this._onNpsItemEnter)
                        .on('mouseover', this._onNpsItemOver)
                        .on('mousemove', this._onItemOverMove)
                        .on('mouseout', this._onNpsItemOut);
                },
                (update: any) => {
                    update.exit().remove();

                    update
                        .attr('cx', this._getNpsLineX)
                        .attr('cy', this._getNpsLineY)
                        .attr('fill', 'white')
                        .attr('stroke', this._getNpsColor)
                        .attr('id', this._getNpsDotId)
                        // add event listeners for each nps dot
                        .on('contextmenu', this._onNpsColorPickerOpen)
                        .on('mouseenter', this._onNpsItemEnter)
                        .on('mouseover', this._onNpsItemOver)
                        .on('mousemove', this._onItemOverMove)
                        .on('mouseout', this._onNpsItemOut);
                },
            );

        if (this._showScore) {
            linesGroup
                .selectAll(`.${this.scoreClass}`)
                .data(this.npsData.data)
                .join(
                    (enter: any) => {
                        enter
                            .append('text')
                            .attr('x', this._getNpsLineX)
                            .attr('y', this._getNpsScoreY)
                            .attr('class', cx(this.scoreClass, styles.scoreNpsNumber))
                            .text(this._getNpsScoreText);
                    },
                    (update: any) => {
                        update.exit().remove();

                        update
                            .attr('x', this._getNpsLineX)
                            .attr('y', this._getNpsScoreY)
                            .text(this._getNpsScoreText);
                    },
                );
        }

        return this;
    }

    // set dndData, redraw function, other configs
    preConfig({
        redraw = null,
        withDnD = false,
        dragAndDropDataSet = [],
        allList = [],
        withPseudoAxis = false,
        xAxisSize = null,
        tickList = [],
    }: IPreConfig) {
        this._dndConfig = {
            groups: dragAndDropDataSet || [],
            allList: allList || [],
            withDnD: withDnD,
            redraw: redraw,
        };
        this._withPseudoAxis = withPseudoAxis;
        this.xAxisSize = xAxisSize;
        this.tickList = tickList;

        return this;
    }

    init({ svgRef, toolTipRef, gadgetId }: IChartServiceInit) {
        const svgContainer = select(svgRef);
        const toolTipContainer = select(toolTipRef);
        const existedChart = svgContainer.select(`g#${this.containerClass}_${gadgetId}`);

        this.svgContainer = svgContainer;
        this.toolTipRef = toolTipContainer;

        if (existedChart && existedChart.node()) {
            this.remove();
        }

        // init g.container
        const container = svgContainer
            .append('g')
            .attr('class', this.containerClass)
            .attr('id', `${this.containerClass}_${gadgetId}`);

        // init vertical grid lines if needed
        if (this._withVerticalGrid) {
            container
                .append('g')
                .attr('class', cx(this.gridClass, this.verticalGridClass));
        }

        // init horizontal grid lines if needed
        if (this._withHorizontalGrid) {
            container
                .append('g')
                .attr('class', cx(this.gridClass, this.horizontalGridClass));
        }

        // init yAxes
        container
            .append('g')
            .attr('class', this.yAxisClass);

        // init xAxis
        container
            .append('g')
            .attr('class', this.xAxisClass);

        // init pseudo xAxis
        container
            .append('g')
            .attr('class', this.pseudoAxisClass);

        // init yRightAxis
        container
            .append('g')
            .attr('class', this.yRightAxisClass);

        // order layout
        if (this.chartType !== 'line_chart') {
            // init container for elements (bars, lines)
            container
                .append('g')
                .attr('class', this.elementsContainerClass);
        }

        // init nps line
        container
            .append('g')
            .attr('class', this.npsLineGroupClass)
            .attr('fill', 'none')
            .append('path')
            .attr('class', cx(this.npsLineClass, styles.npsLine))
            .attr('fill', 'none');

        if (this.chartType === 'line_chart') {
            // init container for elements (bars, lines)
            container
                .append('g')
                .attr('class', this.elementsContainerClass);
        }

        this.chartContainer = container;
        this._setInitialized(true);

        return this;
    }

    remove() {
        if (!this.svgContainer) return;

        const chartElements = this.svgContainer.select('*');

        if (chartElements) {
            chartElements.remove();
            this._setInitialized(false);
        }
    }
}
