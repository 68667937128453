import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import './VirtualSourceFormSidebarHead.scss';

@withTranslation()
export class VirtualSourceFormSidebarHead extends Component {
    shouldComponentUpdate(nextProps) {
        return this.props.sourceName !== nextProps.sourceName
            || this.props.isAutoFill !== nextProps.isAutoFill;
    }

    @autobind
    onSourceNameChanged(event) {
        const maxTitleLength = 50;
        const { value } = event.target;

        if (this.props.virtualSourceName !== value && value.trim().length < maxTitleLength) {
            this.props.handleSourceName(value);
        }
    }

    render() {
        const {
            sourceName,
            addSource,
            addColumn,
            isAutoFill,
            t,
        } = this.props;

        return (
            <div className="virtual-table__header">
                <div className="fixed-table__th fixed-table__th--first clearfix">
                    <Input
                        type='text'
                        value={ sourceName }
                        placeholder={ t('placeholders.virtualSourceName') }
                        onChange={ this.onSourceNameChanged }
                    />
                    <div className="virtual-sources-form__controls">
                        <Button
                            className='virtual-sources-form__button'
                            color='white'
                            outline
                            onClick={ () => addSource(isAutoFill) }
                        >
                            <FontAwesomeIcon
                                icon='arrow-circle-down'
                                className='color-green virtual-sources-form__button-icon'
                            />
                            <span className='virtual-sources-form__button-text'>
                                { t('addSource') }
                            </span>
                        </Button>
                        <Button
                            className='virtual-sources-form__button virtual-sources-form__button--last'
                            color='white'
                            outline
                            onClick={ addColumn }
                        >
                            <span className='virtual-sources-form__button-text'>
                                { t('addColumn') }
                            </span>
                            <FontAwesomeIcon
                                icon='arrow-circle-right'
                                className='color-green virtual-sources-form__button-icon'
                            />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
