import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SortableTree from 'react-sortable-tree';
import { autobind } from 'core-decorators';
import { arrayMove } from 'react-sortable-hoc';
import cx from 'classnames';

import { PageRequests, SurveysService } from '/services';
import { showToast } from '/components';
import { QuestionTreeItem, QuestionTreePopover, QuestionConfigurablePopover } from '../';
import { Question } from '../../../Question';
import { QuestionsServices } from '/scenes/VocFeedback/services';
import { deleteQuestionFlowFromStore } from '../../modules/questions.modules';

import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import './QuestionTreeList.scss';

const mapDispatchToProps = {
    showToast,
    deleteQuestionFlowFromStore,
};

@connect(() => ({}), mapDispatchToProps)
export class QuestionsTreeList extends Component {
    pageRequests = new PageRequests();
    state = {
        flowTarget: null,
        showFlowPopover: false,
        openAnotherFlowPopover: false,
        parentId: null,
        configurableTarget: null,
        showConfigurablePopover: false,
        openAnotherConfigurablePopover: false,
    };

    @autobind
    updateRemoteTreeData(data) {
        const { showConfirm, surveyGroup, deleteQuestionFlowFromStore } = this.props;
        const { type, title } = data.treeData[0];
        const skipFlows = data.treeData[0].question_flows.filter(flow => flow.action_type.name === 'SKIP');

        const isSkip = !surveyGroup.isUnsubscribeSurvey && type === 'Unsubscribe' && !!skipFlows.length;
        const isDetachUnsubscribe = surveyGroup.isUnsubscribeSurvey
            && this.props.treeData[0].type === 'Unsubscribe'
            && type !== 'Unsubscribe';

        showConfirm({
            checkDirty: !(isSkip || isDetachUnsubscribe),
            header: 'Warning!',
            content: isSkip
                ? `Unsubscribe question "${ title }" has attached one or more question flows with
                "Skip this question" action type. Main unsubscribe question in unsubscribe survey
                cannot be skipped - these flows will be deleted.`
                : 'Survey will be detached from all survey groups and units for which it used as "Unsubscribe survey"',
            successCallback: () => {
                this.updateRemoteTreeDataConfirm(data);
                isSkip && deleteQuestionFlowFromStore(skipFlows.map(flow => flow.id));
            },
        });
    }

    @autobind
    updateRemoteTreeDataConfirm(data) {
        const {
            surveyGroup,
            showToast,
            rootQuestion,
            activeQuestionId,
            inheritDataFromId,
            toggleIsUnsubscribeGroup,
            reorderHandler,
            updateRemoteTreeData,
            setQuestionLayoutHandler,
        } = this.props;
        const {
            node,
            nextParentNode,
            treeData,
        } = data;

        const [ isTreeDataValid, validationMessage ] = SurveysService.checkIsTreeDataValid({
            newTreeData: treeData,
            isStepByStepSurvey: rootQuestion.survey.is_step_by_step,
        });

        if (!isTreeDataValid) {
            return validationMessage
                ? showToast({ text: validationMessage.text, type: validationMessage.type })
                : false;
        }

        surveyGroup.isUnsubscribeSurvey
        && this.props.treeData[0].type === 'Unsubscribe'
        && data.treeData[0].type !== 'Unsubscribe'
        && toggleIsUnsubscribeGroup(false);

        reorderHandler(treeData);

        const rootPosition = treeData.map(item => item.id).indexOf(node.id);
        const siblingPosition = nextParentNode && nextParentNode.children.map(item => item.id).indexOf(node.id);
        const nextSiblingNode = nextParentNode ? nextParentNode.children[siblingPosition - 1] : treeData[rootPosition - 1];

        const nextParentId = nextParentNode && siblingPosition === 0 ? nextParentNode : nextSiblingNode;
        const isFirstChild = rootPosition === 0 || siblingPosition === 0;

        const parentId = nextParentId ? nextParentId.id : rootQuestion.id;

        // node that moved is already open
        if (activeQuestionId === node.id && inheritDataFromId !== nextParentNode?.id) {
            setQuestionLayoutHandler({
                checkForDirty: false,
                inheritDataFromId: nextParentNode && inheritDataFromId !== nextParentNode.id
                    ? nextParentNode.id
                    : null,
            });
        }

        const model = {
            parentId: parentId,
            questionId: node.id,
            isFirstChild: isFirstChild,
        };

        updateRemoteTreeData(model);
    }

    @autobind
    showQuestionFlow(target, node) {
        this.props.setQuestionId(node.id);

        this.setState({
            flowTarget: target,
            showFlowPopover: true,
            parentId: node.parentId,
            openAnotherFlowPopover: this.state.flowTarget !== null,
        });
    }

    @autobind
    closeQuestionFlowPopover() {
        if (this.state.openAnotherFlowPopover) {
            this.setState({ openAnotherFlowPopover: false });
        } else {
            this.setState({
                flowTarget: null,
                showFlowPopover: false,
                parentId: null,
            });
        }
    }

    @autobind
    openConfigurablePopover(target, id) {
        this.pageRequests.cleanupById('getQuestionRequest');
        const request = this.props.getQuestionRequest(id);

        this.pageRequests.addRequest(request, 'getQuestionRequest');

        this.setState({
            configurableTarget: target,
            showConfigurablePopover: true,
            openAnotherConfigurablePopover: this.state.configurableTarget !== null,
        });
    }

    @autobind
    closeConfigurablePopover() {
        if (this.state.openAnotherConfigurablePopover) {
            this.setState({
                openAnotherConfigurablePopover: false,
            });
        } else {
            this.setState({
                configurableTarget: null,
                showConfigurablePopover: false,
            });
        }
    }

    @autobind
    onCopyQuestionIconClick(row) {
        this.props.copyQuestionHandler(row.node);
    }

    @autobind
    closeQuestionForm() {
        const { enableAddButton, setQuestionLayoutHandler } = this.props;

        setQuestionLayoutHandler({
            activeQuestionId: null,
            showQuestionDetails: false,
            inheritDataFromId: null
        });

        enableAddButton();
    }

    @autobind
    onSortEnd({ oldIndex, newIndex }) {
        const { flows, setReorderFlow } = this.props;

        if (oldIndex === newIndex) return;

        const movedFlows = arrayMove(flows, oldIndex, newIndex).map(({ id }, index) => ({
            id,
            rule_priority: ++index,
        }));

        setReorderFlow(movedFlows);
    }

    @autobind
    onVisibilityToggle({ node }) {
        const params = {
            id: node.id,
            expanded: !node.expanded,
        };

        this.props.toggleExpanded(params);
    }

    getMaxDepth() {
        const { activeChannel } = this.props;
        let maxDepth = null;

        if (activeChannel === 'DIGI') {
            maxDepth = 2;
        } else if ([ 'IVR', 'SMS' ].includes(activeChannel)) {
            maxDepth = 1;
        }

        return maxDepth;
    }

    render() {
        const {
            actionDisabled,
            treeData,
            canDrag,
            removeFlowHandler,
            updateStateFlow,
            flowOptions,
            questionValue,
            flows = [],
            statusFlow,
            getPreloader,
            getQuestionsList,
            activeQuestionId,
            inheritDataFromId,
            questionListLoading,
            setQuestionId,
            showQuestionDetails,
            setIsNewQuestionCreated,
            isNewQuestionCreated,
            setQuestionLayoutHandler,
            rightsSurvey,
            copyQuestionHandler,
            questionFlowHandler,
            removeQuestionHandler,
        } = this.props;

        const {
            flowTarget,
            showFlowPopover,
            parentId,
            configurableTarget,
            showConfigurablePopover,
        } = this.state;

        const isMatrixQuestionExist = treeData.some(({ type }) => QuestionsServices.isMatrixQuestion(type));

        return (
            <>
                <div
                    className={ cx(
                        'questions__sortable-tree',
                        { 'questions__sortable-tree--width': showQuestionDetails },
                    ) }>
                    { getPreloader() }
                    <SortableTree
                        treeData={ treeData }
                        canDrag={ canDrag }
                        onChange={ () => null }
                        onMoveNode={ this.updateRemoteTreeData }
                        nodeContentRenderer={ QuestionTreeItem }
                        onVisibilityToggle={ this.onVisibilityToggle }
                        rowHeight={ 54 }
                        isVirtualized={ false }
                        generateNodeProps={ () => ({
                            actionDisabled,
                            activeQuestionId,
                            inheritDataFromId,
                            isPermitEdit: rightsSurvey.isPermitEdit,
                            isMatrixQuestionExist,
                            showQuestionDetails,
                            setQuestionLayoutHandler,
                            copyQuestionHandler,
                            showQuestionFlow: this.showQuestionFlow,
                            questionFlowHandler,
                            removeQuestionHandler,
                            openConfigurablePopover: this.openConfigurablePopover,
                        }) }
                        scaffoldBlockPxWidth={ 48 }
                        maxDepth={ this.getMaxDepth() }
                    />
                    {
                        showFlowPopover && flows.length
                            ? <QuestionTreePopover
                                target={ flowTarget }
                                show={ showFlowPopover }
                                flows={ flows }
                                statusFlow={ statusFlow }
                                parentId={ parentId }
                                removeFlowAction={ removeFlowHandler }
                                updateStateFlow={ updateStateFlow }
                                flowOptions={ flowOptions }
                                rightsSurvey={ rightsSurvey }
                                activeQuestionId={ activeQuestionId }
                                onSortEnd={ this.onSortEnd }
                                toggle={ this.closeQuestionFlowPopover }
                            /> : ''
                    }
                    {
                        showConfigurablePopover
                        && <QuestionConfigurablePopover
                            target={ configurableTarget }
                            show={ showConfigurablePopover }
                            questionValue={ questionValue }
                            toggle={ this.closeConfigurablePopover }
                        />
                    }
                </div>
                <Question
                    questionListLoading={ questionListLoading }
                    getQuestionsList={ getQuestionsList }
                    questionId={ activeQuestionId }
                    inheritDataFromId={ inheritDataFromId }
                    setQuestionId={ setQuestionId }
                    onCloseForm={ this.closeQuestionForm }
                    setIsNewQuestionCreated={ setIsNewQuestionCreated }
                    isNewQuestionCreated={ isNewQuestionCreated }
                />
            </>
        );
    }
}

QuestionsTreeList.propTypes = {
    canDrag: PropTypes.bool,
    removeQuestionHandler: PropTypes.func,
    copyQuestionHandler: PropTypes.func,
};
