import { sockets } from '/services';
import { updateMineQueries } from './index';

function socketCallback(data, store) {
    store.dispatch(updateMineQueries(data));
}

export function initMineQuerySocket() {
    return sockets.on('mine_query_updated', socketCallback);
}
