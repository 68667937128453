import { CALL_API, FEEDBACK } from 'store/middleware/api';

const COPY_EXPORTER_REQUEST = 'COPY_EXPORTER_REQUEST';
const COPY_EXPORTER_SUCCESS = 'COPY_EXPORTER_SUCCESS';
const COPY_EXPORTER_ERROR = 'COPY_EXPORTER_ERROR';

const UPDATE_EXPORTER_REQUEST = 'UPDATE_EXPORTER_REQUEST';
const UPDATE_EXPORTER_SUCCESS = 'UPDATE_EXPORTER_SUCCESS';
const UPDATE_EXPORTER_ERROR = 'UPDATE_EXPORTER_ERROR';

const EXPORTERS_LIST_REQUEST = 'EXPORTERS_LIST_REQUEST';
const EXPORTERS_LIST_SUCCESS = 'EXPORTERS_LIST_SUCCESS';
const EXPORTERS_LIST_ERROR = 'EXPORTERS_LIST_ERROR';

const GET_EXPORTER_REQUEST = 'GET_EXPORTER_REQUEST';
const GET_EXPORTER_SUCCESS = 'GET_EXPORTER_SUCCESS';
const GET_EXPORTER_ERROR = 'GET_EXPORTER_ERROR';

const DELETE_EXPORTER_REQUEST = 'DELETE_EXPORTER_REQUEST';
const DELETE_EXPORTER_SUCCESS = 'DELETE_EXPORTER_SUCCESS';
const DELETE_EXPORTER_ERROR = 'DELETE_EXPORTER_ERROR';

const DELETE_NEW_EXPORTER_REQUEST = 'DELETE_NEW_EXPORTER_REQUEST';
const DELETE_NEW_EXPORTER_SUCCESS = 'DELETE_NEW_EXPORTER_SUCCESS';
const DELETE_NEW_EXPORTER_ERROR = 'DELETE_NEW_EXPORTER_ERROR';

const EXPORTERS_LANGUAGE_REQUEST = 'EXPORTERS_LANGUAGE_REQUEST';
const EXPORTERS_LANGUAGE_SUCCESS = 'EXPORTERS_LANGUAGE_SUCCESS';
const EXPORTERS_LANGUAGE_ERROR = 'EXPORTERS_LANGUAGE_ERROR';

const CLEANUP_EXPORTERS_PAGE = 'CLEANUP_EXPORTERS_PAGE';

const EXPORTERS_FILTERS_CHANGED = 'EXPORTERS_FILTERS_CHANGED';

const GET_EXPORTER_SCHEDULE_REQUEST = 'GET_EXPORTER_SCHEDULE_REQUEST';
const GET_EXPORTER_SCHEDULE_SUCCESS = 'GET_EXPORTER_SCHEDULE_SUCCESS';
const GET_EXPORTER_SCHEDULE_ERROR = 'GET_EXPORTER_SCHEDULE_ERROR';

const GET_EXPORTER_CONFIG_SCHEDULE_REQUEST = 'GET_EXPORTER_CONFIG_SCHEDULE_REQUEST';
const GET_EXPORTER_CONFIG_SCHEDULE_SUCCESS = 'GET_EXPORTER_CONFIG_SCHEDULE_SUCCESS';
const GET_EXPORTER_CONFIG_SCHEDULE_ERROR = 'GET_EXPORTER_CONFIG_SCHEDULE_ERROR';

function getInitialState() {
    return {
        loading: false,
        loadingLanguage: false,
        exporter: {
            loading: false,
            result: {},
        },
        filters: {
            page: 1,
            limit: 99999,// todo temporarily until we delete old exports
            search: '',
        },
        results: [],
        langResults: [],
        count: 0,
    };
}

export function reducer(state = getInitialState(), action = {}) {
    switch (action.type) {
        case EXPORTERS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EXPORTERS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                count: action.response.count,
                results: [//todo remove after delete old exporters
                    ...action.response.results.map(exporter =>
                        exporter.export_type
                            ? { ...exporter, isNewExporter: true, id: exporter.id }
                            : exporter,
                    ),
                ],
            };

        case EXPORTERS_LIST_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_EXPORTER_REQUEST:
            return {
                ...state,
                exporter: {
                    loading: true,
                    result: {},
                },
            };

        case GET_EXPORTER_SUCCESS:
            return {
                ...state,
                exporter: {
                    loading: false,
                    result: {
                        ...action.response?.results[0],
                    },
                },
            };

        case GET_EXPORTER_ERROR:
            return {
                ...state,
                exporter: {
                    loading: false,
                    ...state.exporter,
                },
            };

        case EXPORTERS_LANGUAGE_REQUEST:
            return {
                ...state,
                langResults: [],
                loadingLanguage: true,
            };

        case EXPORTERS_LANGUAGE_SUCCESS:
            return {
                ...state,
                langResults: [
                    ...action.response.results,
                ],
                loadingLanguage: false,
            };

        case EXPORTERS_FILTERS_CHANGED:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };

        case CLEANUP_EXPORTERS_PAGE:
            return getInitialState();

        default:
            return state;
    }
}

export function getExportersList(body) {
    return {
        [CALL_API]: {
            endpoint: `/export-config/`,
            method: 'GET',
            types: [ EXPORTERS_LIST_REQUEST, EXPORTERS_LIST_SUCCESS, EXPORTERS_LIST_ERROR ],
            body,
            apiType: FEEDBACK,
        },
    };
}

export function getExporter(id__in) {
    return {
        [CALL_API]: {
            endpoint: `/exporter/expand-basic/`,
            method: 'GET',
            types: [ GET_EXPORTER_REQUEST, GET_EXPORTER_SUCCESS, GET_EXPORTER_ERROR ],
            body: {
                id__in,
            },
            apiType: FEEDBACK,
        },
    };
}

export function updateFilters(payload) {
    return {
        type: EXPORTERS_FILTERS_CHANGED,
        payload,
    };
}

export function manageExporterItem(body) {
    return dispatch => {
        return body.id
            ? dispatch(updateExporterItem(body))
            : dispatch(createExporterItem(body));
    };
}

function createExporterItem(body) {
    return {
        [CALL_API]: {
            endpoint: `/exporter/`,
            method: 'POST',
            types: [ COPY_EXPORTER_REQUEST, COPY_EXPORTER_SUCCESS, COPY_EXPORTER_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export was successfully created',
                error: 'Something went wrong',
            },
        },
    };
}

function updateExporterItem(body) {
    return {
        [CALL_API]: {
            endpoint: `/exporter/${body.id}/`,
            method: 'PUT',
            types: [ UPDATE_EXPORTER_REQUEST, UPDATE_EXPORTER_SUCCESS, UPDATE_EXPORTER_ERROR ],
            body,
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export was successfully updated',
                error: 'Something went wrong',
            },
        },
    };
}

export function getLanguage(id) {
    return {
        [CALL_API]: {
            endpoint: `/language/?unit_id=${id}&limit=9999`,
            method: 'GET',
            types: [ EXPORTERS_LANGUAGE_REQUEST, EXPORTERS_LANGUAGE_SUCCESS, EXPORTERS_LANGUAGE_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function deleteExporterItem(id) {
    return {
        [CALL_API]: {
            endpoint: `/exporter/${id}/`,
            method: 'DELETE',
            types: [ DELETE_EXPORTER_REQUEST, DELETE_EXPORTER_SUCCESS, DELETE_EXPORTER_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export was successfully deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function deleteNewExporterItem(id) {
    return {
        [CALL_API]: {
            endpoint: `/export-config/${id}/`,
            method: 'DELETE',
            types: [ DELETE_NEW_EXPORTER_REQUEST, DELETE_NEW_EXPORTER_SUCCESS, DELETE_NEW_EXPORTER_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Export was successfully deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function checkScheduleByExporter(id) {
    return {
        [CALL_API]: {
            endpoint: `/export-schedule/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_EXPORTER_SCHEDULE_REQUEST, GET_EXPORTER_SCHEDULE_SUCCESS, GET_EXPORTER_SCHEDULE_ERROR ],
            apiType: FEEDBACK,
            body: {
                exporter: id,
                limit: 1,
            },
        },
    };
}

export function checkScheduleByExporterConfig(id) {
    return {
        [CALL_API]: {
            endpoint: `/export-schedule/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_EXPORTER_CONFIG_SCHEDULE_REQUEST, GET_EXPORTER_CONFIG_SCHEDULE_SUCCESS, GET_EXPORTER_CONFIG_SCHEDULE_ERROR ],
            apiType: FEEDBACK,
            body: {
                export_config: id,
                limit: 1,
            },
        },
    };
}

export function cleanup() {
    return {
        type: CLEANUP_EXPORTERS_PAGE,
    };
}
