import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';

import { VocSelect } from '/components';
import { languagesSelector } from '/modules/topicSetModules/topicSet.selectors';

import styles from './style.module.scss';

export const TopBlock = ({ fieldsData, onChange, isViewPermission }) => {
    const languages = useSelector(languagesSelector);

    const formatOptionLabel = ({ label, value }) => { // ?
        const isActive = fieldsData?.language?.value === value;
        const isExist = () => {
            const isNotEmptyLang = fieldsData.languages[value];
            let isExist = false;

            if(isNotEmptyLang) {
                Object.keys(fieldsData.languages[value]).forEach(topic => {
                    if(!isExist) {
                        isExist = !!fieldsData.languages[value][topic] && fieldsData?.language?.value !== value;
                    }
                });
            }

            return isExist;
        };

        const labelStyles = {
            [styles.classifierFieldLanguageActive]: isActive,
            [styles.classifierFieldLanguageExist]: isExist(),
        };

        return (
            <div className={ styles.classifierFieldLanguageOption }>
                <div className={ cx(labelStyles) }/>
                { label }
            </div>
        );
    };

    return (
        <div className={ styles.navigationWrapper }>
            <Link to='/ai/topic-sets' className={ styles.backButton }>
                <FontAwesomeIcon icon='arrow-left'/>
                <span> Back</span>
            </Link>

            <div className={ styles.fieldsInNavigationWrapper }>
                <div className={ cx(styles.classifierField, styles.classifierFieldClassifier) }>
                    <Label>Topic set name</Label>
                    <Input
                        disabled={ isViewPermission }
                        placeholder='Enter topic set name'
                        type='text'
                        value={ fieldsData.topicSetName || '' }
                        onChange={ e => onChange('topicSetName', e.target.value) }
                    />
                </div>

                <div className={ cx(styles.classifierField, styles.classifierFieldLanguage) }>
                    <Label>Language</Label>
                    <VocSelect
                        portal
                        isHorizontal
                        placeholder='Language'
                        isClearable={ false }
                        isSearchable={ false }
                        options={ languages }
                        value={ fieldsData.language }
                        onChange={ languageData => onChange('language', languageData) }
                        formatOptionLabel={ formatOptionLabel }
                    />
                </div>
            </div>
        </div>
    );
};
