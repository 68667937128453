import React, { Component } from 'react';
import { ChannelControl } from '../../ChannelsControl';
import { ButtonGroup } from 'reactstrap';
import {
    createSurvey,
    switchSurvey,
} from '../../../modules/survey.modules';

import { surveysPanelSelector, rightsSurveySelector } from '../../../modules/survey.selectors';
import { activeSurveySelector } from '/feedback/modules/VocFeedback.selectors';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    dataSurveys: surveysPanelSelector(state),
    activeSurvey: activeSurveySelector(state),
    rightsSurvey: rightsSurveySelector(state),
});

const mapDispatchToProps = {
    createSurvey,
    switchSurvey,
};

@connect(mapStateToProps, mapDispatchToProps)
export class ChannelsPanelChannels extends Component {
    render() {
        const {
            dataSurveys,
            activeSurvey,
            createSurvey,
            switchSurvey,
            location: {
                pathname,
            },
            rightsSurvey,
        } = this.props;

        const isTimeSlots = pathname.includes('time-slots');

        const channels = dataSurveys.surveys.map((survey, inx) => (
            <ChannelControl
                key={ inx }
                survey={ survey }
                activeSurvey={ activeSurvey }
                createSurvey={ createSurvey }
                switchSurvey={ switchSurvey }
                name={ survey.channel }
                isTimeSlots={ isTimeSlots }
                rightsSurvey={ rightsSurvey }
            />
        ));

        return (
            <ButtonGroup>
                { channels }
            </ButtonGroup>
        );
    }
}
