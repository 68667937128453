import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './VocButtonDropdown.scss';

export const VocButtonDropdown = ({
    caret,
    placeholder,
    handleClick,
    direction,
    className,
    options,
    outline,
    color,
    icon,
    disabled,
}) => {
    const onClick = ({ target }) => handleClick(target.value);

    return (
        <UncontrolledButtonDropdown direction={ direction } className={ `${ className }__wrapper` }>
            <DropdownToggle color={ color }
                outline={ outline }
                className={ `${ className }__toggle` }
                caret={ caret }
                disabled={ !options.length || disabled }
            >
                {
                    icon ? <FontAwesomeIcon icon={ icon }/> : ''
                }
                <span> { placeholder }</span>
            </DropdownToggle>
            <DropdownMenu className={ `${ className }__menu` }>
                {
                    options.map((option, inx) => (
                        <DropdownItem
                            onClick={ onClick }
                            header={ option.header }
                            key={ inx }
                            className={ `${ className }__menu-item` }
                            value={ option.id }
                            disabled={ option.disabled }
                        >
                            { option.name }
                        </DropdownItem>
                    ),
                    )
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
};

VocButtonDropdown.propTypes = {
    caret: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    })),
    icon: PropTypes.string,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
};

VocButtonDropdown.defaultProps = {
    className: 'button-dropdown',
    placeholder: 'Add',
    direction: 'down',
    outline: false,
    caret: false,
    disabled: false,
};
