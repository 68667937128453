import React from 'react';
import { useSelector } from 'react-redux';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { MetaFilterWrapper } from '/visual/scenes/Dashboard/components';
import { activeSourceSelectorWithoutDate } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { MetaQueryService, QueryExecuteService } from '/services';
import { setTempSettingsType } from '/visual/models';

import styles from './style.module.scss';

export const MetadataFilter = ({
    setTempSettings,
    setIsValidQuery,
    metaQuery,
}: {
    setTempSettings: setTempSettingsType,
    setIsValidQuery: (state: boolean) => void,
    metaQuery: any,
}) => {
    const { t } = useTranslation();
    const source = useSelector(activeSourceSelectorWithoutDate);

    const onChangeMetaFilter = () => {
        const isQueryValid = QueryExecuteService.queryItemsValidation(metaQuery);
        const isEmptyQuery = QueryExecuteService.checkIsEmptyQuery(metaQuery);

        setIsValidQuery(isEmptyQuery || isQueryValid);

        if (isQueryValid) {
            setTempSettings({
                dataSettings: {
                    metadataQuery: MetaQueryService.clientToServerFormatter({ query: metaQuery, source }, true),
                },
            });
        }

        if(isEmptyQuery) {
            setTempSettings({
                dataSettings: {
                    metadataQuery: null,
                },
            });
        }
    };

    return (
        <div className={ styles.metaDataFilterWrapper }>
            {
                source?.attributes
                    ? <MetaFilterWrapper
                        source={ source }
                        metaQuery={ metaQuery }
                        onChange={ onChangeMetaFilter }
                    />
                    : <Label>
                        { t('metadataFilterGadgetInfo') }
                    </Label>
            }
        </div>
    );
};
