import React from 'react';
import './QuarantineFlowConditionString.scss';
import { RenderQuarantineCondition } from './RenderQuarantineCondition';

export const QuarantineFlowConditionString = ({ formValuesSelector }) => {
    const {
        action_type,
        conditions,
        logical_operation,
        survey_group,
        days,
        hours,
    } = formValuesSelector;

    return (
        <div className={ 'flow__list-accent' }>
            <p className='combined-condition__padding'>
                Customer's import to
                <span className='combined-condition__item'>
                    {
                        !survey_group
                            ? ''
                            : ` "${ survey_group.label }" `
                    }
                </span>
                survey
                {
                    action_type.label === 'Block'
                        ? ' is blocked if'
                        : ` is delayed for ${ days } day(s) and ${ hours } hour(s) if`
                }
            </p>
            <div>
                { renderConditions(conditions, logical_operation) }
            </div>
        </div>
    );
};

const renderConditions = (conditions, logical_operation) => {
    const conditionsToString = conditions.map(({ blocked_by, expression, matching_value }) => {
        return ({
            blocked_by: {
                label: !blocked_by ? '' : blocked_by.label,
            },
            expression: {
                label: !expression ? '' : expression.label,
                conditionLabel: !expression ? '' : expression.conditionLabel,
                id: !expression ? '' : expression.id,
            },
            matching_value: matching_value,
            condition_type: !blocked_by ? '' : blocked_by.condition_type,
        });
    });

    return conditionsToString.map((condition, index) =>
        <RenderQuarantineCondition
            key={ index }
            condition={ condition }
            index={ index }
            logicalOperation={ logical_operation }
            conditionsLength={ conditionsToString.length }
            conditionType
        />,
    );
};
