import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { reduxForm, Field } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup, Row, Col, Button, Alert, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    VocModal,
    FormUploadFileItem,
    VocFormSelectField,
    VocFormSwitchControl,
    VocFormTextField,
} from '/components';
import { HelperService } from '/services';
import { InputLoader } from '/scenes/VocFeedback/components/InputLoader/';
import { delimiterVariantsSelector } from '/modules/selectors';
import { getImportExampleFile } from '/scenes/VocFeedback/modules/VocFeedback.modules';
import { importFormValidation } from './importFormValidation';

import './AddImportModal.scss';

const getInitialFormValues = () => ({
    address: 'ADDRESS',
    delimiter: ',',
    languageColumn: 'LANGUAGE',
    customerId: 'CUSTOMER_ID',
    channelColumn: '',
    questionnaireHash: false,
    file: null,
});

const mapDispatchToProps = {
    getImportExampleFile,
};

@withTranslation()
@reduxForm({
    form: 'ImportForm',
    enableReinitialize: true,
    initialValues: getInitialFormValues(),
    validate: importFormValidation,
})
@connect(null, mapDispatchToProps)
export class AddImportModal extends Component {
    acceptedFileFormats = [
        'text/csv',
        'application/csv',
        '.txt',
        '.csv',
    ].join(', ');

    InBytes200MB = 209715200;

    delimiterVariants = delimiterVariantsSelector();

    @autobind
    cleanupModal() {
        this.props.reset();
    }

    @autobind
    onImportClicked(values) {
        const model = {
            file: values.file,
            params: HelperService.removeEmptyValues({
                survey: values.surveyId ? Number(values.channelId) : null,
                survey_column: !values.surveyId ? values.surveyColumn : null,
                channel_column: !values.surveyId ? values.channelColumn : null,
                address_column: values.address,
                customer_id_column: values.customerId,
                language_column: values.languageColumn,
                add_questionnaire_hash: values.questionnaireHash,
                delimiter: values.delimiter,
            }),
        };

        return this.props.actionHandler(model);
    }

    @autobind
    onAddImportClosed() {
        this.props.close();
    }

    @autobind
    onFileDrop(file) {
        this.props.change('file', file);
    }

    @autobind
    deleteCurrentFile() {
        this.props.change('file', null);
    }

    @autobind
    downloadImportExample(e) {
        e.preventDefault();
        this.props.getImportExampleFile();
    }

    getModalBody() {
        const {
            formValues: {
                channels,
                surveyId,
                file,
            },
            t,
            getImportExampleFile,
        } = this.props;

        return (
            <form onSubmit={ this.props.handleSubmit }>
                <Alert color='info'>
                    <strong>
                        <FontAwesomeIcon icon='info-circle'/> { t('importsPage.info') }
                    </strong>
                    <p className={ 'import__alert-text' }>
                        <Trans
                            key='importsPage.infoFilePromt'
                            i18nKey='importsPage.infoFilePromt'
                            components={{ 1: <a onClick={ getImportExampleFile } href={ '#' } /> }}
                        />
                    </p>
                </Alert>

                { this.props.surveys.loading
                    ? <InputLoader label='Survey'/>
                    : <Row className={ 'import__field-wrapper' }>
                        <Col md={ 3 } className={ 'select-control-label' }>
                            <Label>{ t('survey') }</Label>
                        </Col>
                        <Col md={ 9 }>
                            <Field
                                component={ VocFormSelectField }
                                name={ 'surveyId' }
                                options={ this.props.surveys.results }
                                portal={ true }
                                placeholder={ t('importsPage.withoutSurvey') }
                                isClearable={ true }
                            />
                        </Col>
                    </Row>
                }

                {
                    (surveyId && channels.length > 0)
                    && (
                        this.props.surveys.loading
                            ? <InputLoader label='Channel'/>
                            : <Row className={ 'import__field-wrapper' }>
                                <Col md={ 3 } className={ 'select-control-label' }>
                                    <Label>{ t('importsPage.channel') }</Label>
                                </Col>
                                <Col md={ 9 }>
                                    <Field
                                        component={ VocFormSelectField }
                                        name={ 'channelId' }
                                        portal={ true }
                                        placeholder={ t('chooseChannel') }
                                        normalize={ HelperService.normalizeSelectValue }
                                        format={ value => HelperService.getSelectedValue(channels, value) }
                                        options={ channels }
                                    />
                                </Col>
                            </Row>
                    )
                }

                <FormGroup>
                    <Row>
                        <Col md={ 3 } className='text-field-control-label'>
                            <strong className='control-label disable-select'>
                                { t('importsPage.uploadFile') }
                                <span className='star'> *</span>
                            </strong>
                        </Col>
                        <Col md={ 9 }>
                            <FormUploadFileItem
                                showErrorAlert={ this.props.showToast }
                                controlId='upload_file'
                                accept={ this.acceptedFileFormats }
                                file={ file }
                                fileSize={ this.InBytes200MB }
                                onDrop={ this.onFileDrop }
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <Alert color='secondary'>
                    <FontAwesomeIcon icon={ 'cog' }/> { t('importsPage.advancedImportSettings') }
                </Alert>
                <Row className={ 'import__field-wrapper' }>
                    <Col md={ 3 } className={ 'select-control-label' }>
                        <Label>{ t('importsPage.delimiter') }</Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            component={ VocFormSelectField }
                            name={ 'delimiter' }
                            portal={ true }
                            placeholder={ t('importsPage.delimiter') }
                            isClearable={ false }
                            normalize={ HelperService.normalizeSelectValue }
                            format={ value => HelperService.getSelectedValue(this.delimiterVariants, value) }
                            options={ this.delimiterVariants }
                        />
                    </Col>
                </Row>
                <Row className={ 'import__field-wrapper' }>
                    <Col md={ 3 } className={ 'select-control-label' }>
                        <Label>
                            { t('importsPage.customerIdColumn') }
                            <span className='star'> *</span>
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            component={ VocFormTextField }
                            name={ 'customerId' }
                            placeholder={ t('importsPage.customerIdColumn') }
                        />
                    </Col>
                </Row>
                <Row className={ 'import__field-wrapper' }>
                    <Col md={ 3 } className={ 'select-control-label' }>
                        <Label>
                            { t('importsPage.addressColumn') }
                            <span className='star'> *</span>
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            component={ VocFormTextField }
                            name={ 'address' }
                            placeholder={ t('importsPage.addressColumn') }
                        />
                    </Col>
                </Row>
                <Row className={ 'import__field-wrapper' }>
                    <Col md={ 3 } className={ 'select-control-label' }>
                        <Label>
                            { t('importsPage.languageColumn') }
                            <span className='star'> *</span>
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            component={ VocFormTextField }
                            name={ 'languageColumn' }
                            placeholder={ t('importsPage.languageColumn') }
                        />
                    </Col>
                </Row>
                <Row className={ 'import__field-wrapper' }>
                    <Col md={ 3 } className={ 'select-control-label' }>
                        <Label>
                            { t('importsPage.surveyColumn') }
                            { !surveyId && <span className='star'> *</span> }
                        </Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            component={ VocFormTextField }
                            name={ 'surveyColumn' }
                            disabled={ Number(surveyId) > 0 }
                            placeholder={ t('importsPage.surveyColumn') }
                        />
                    </Col>
                </Row>
                <Row className={ 'import__field-wrapper' }>
                    <Col md={ 3 } className={ 'select-control-label' }>
                        <Label>{ t('importsPage.channelColumn') }</Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            component={ VocFormTextField }
                            name={ 'channelColumn' }
                            disabled={ Number(surveyId) > 0 }
                            placeholder={ t('importsPage.channelColumn') }
                        />
                    </Col>
                </Row>
                <Row className={ 'import__field-wrapper' }>
                    <Col md={ 3 } className={ 'select-control-label' }>
                        <Label htmlFor='questionnaireHash'>{ t('importsPage.addQuestionnaireHash') }</Label>
                    </Col>
                    <Col md={ 9 }>
                        <Field
                            component={ VocFormSwitchControl }
                            name={ 'questionnaireHash' }
                        />
                    </Col>
                </Row>
            </form>
        );
    }

    getModalFooter() {
        const { handleSubmit, valid, submitting, formValues: { file }, t } = this.props;

        return (
            <Fragment>
                <Button onClick={ this.onAddImportClosed } color={ 'white' } outline>
                    <FontAwesomeIcon icon='times'/>
                    <span> { t('cancel') }</span>
                </Button>
                <Button onClick={ handleSubmit(values => this.onImportClicked(values)) }
                        disabled={ !(valid && !submitting && file) }
                        color='primary'>
                    <FontAwesomeIcon icon='check'/>
                    <span> { t('save') }</span>
                </Button>
            </Fragment>
        );
    }

    render() {
        const { t, close, show } = this.props;

        return (
            <VocModal
                isOpen={ show }
                size={ 'lg' }
                modalClassName={ 'modal-scrollable' }
                onClosed={ this.cleanupModal }
                toggle={ close }
                header={ t('importsPage.importQuestionnairesSurvey') }
                footer={ this.getModalFooter() }>
                { this.getModalBody() }
            </VocModal>
        );
    }
}

AddImportModal.propTypes = {
    surveys: PropTypes.object,
    show: PropTypes.bool,
    close: PropTypes.func,
    actionHandler: PropTypes.any,
    showToast: PropTypes.func,
};
