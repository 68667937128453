import React from 'react';
import { components } from 'react-select';
import cx from "classnames";
import { Badge } from 'reactstrap';

import { TooltipWrapper } from '/components';

import './SelectAsyncSelectValue.scss';

export const SelectAsyncSelectValue = props => {
    const { children, isMulti } = props;
    const valueItem = props.getValue();
    let channel, type, label, channelStatusColor, typeColor;

    if (valueItem.length) {
        ({ channel, type, channelStatusColor, label, typeColor } = valueItem[0]);

        if(channel?.toLowerCase?.() === 'system') {
            type = null;
            channelStatusColor = 'info';
        }
    }

    return (
        <div className={
            cx(
                'Select-value',
                'source-select-value',
                'select-async__value',
                { "Select-value_multi":  isMulti },
            )
        }
        >
            {
                props.hasValue
                    ? <>
                        <div className={ cx('Select-value__title', { 'Select-value__title_multi': isMulti }) }>
                            {
                                props.selectProps.isTooltip
                                    ? <TooltipWrapper value={ label }>
                                        <div>
                                            <components.ValueContainer { ...props }>
                                                { children }
                                            </components.ValueContainer>
                                        </div>
                                    </TooltipWrapper>
                                    : <div>
                                        <components.ValueContainer { ...props }>
                                            { children }
                                        </components.ValueContainer>
                                    </div>
                            }
                        </div>
                        {
                            (channel || type) && <div className="Select-value__labels">
                                {
                                    channel
                                    && <Badge color={ channelStatusColor }
                                        className={ 'Select-options__source-label' }>
                                        { channel }
                                    </Badge>
                                }

                                <Badge color={ typeColor }
                                    className={ 'Select-options__source-label' }>
                                    { type }
                                </Badge>
                            </div>
                        }

                    </>
                    : <div className={ cx('Select-value__title', { 'Select-value__title_multi': isMulti }) }>
                        <components.ValueContainer { ...props }>
                            { children }
                        </components.ValueContainer>
                    </div>
            }
        </div>
    );
};
