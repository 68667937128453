import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    CreateDashboardModal, DeleteDashboardModal, VisualControlPanel,
    SendToEmailModal, SchedulerModal, EditDashboardTitleModal,
} from '/visual/components';
import { dashboardsSelector, tableDataSelector } from '/visual/modules/VocVisual.selectors';
import {
    changeDashboardSettings, toggleDashboardFavoriteFromList,
    updateFilters, getDashboards, resetFilterExceptField,
} from '/visual/modules/VocVisual.modules';
import { EExportFormats, IDashboard } from '/visual/models';
import {
    toggleModal as toggleCreateModal,
    DashboardModalType, exportToFormat,
} from '/visual/scenes/Dashboard/modules/Dashboard.modules';
import { ImportOldDashboardModal, TableDashboards } from './components';

export const DashboardList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const tableData = useSelector(tableDataSelector(t));
    const { dashboards: { filters, count, loading } } = useSelector(dashboardsSelector);

    const [ activeModalType, setActiveModalType ] = useState<DashboardModalType>(DashboardModalType.empty);
    const [ dashboardData, setDashboardData ] = useState<any>(null);

    const closeModal = () => {
        setActiveModalType(DashboardModalType.empty);
        setDashboardData(null);
    };

    const onDeleteDashboard = (data: IDashboard) => {
        setDashboardData(data);
        setActiveModalType(DashboardModalType.deleteDashboard);
    };

    const onCreateDashboard = () => setActiveModalType(DashboardModalType.createDashboard);

    const onImportOldDashboard = () => setActiveModalType(DashboardModalType.importOldDashboard);

    const onCopyDashboard = (data: IDashboard) => {
        setDashboardData(data);
        setActiveModalType(DashboardModalType.copyDashboard);
    };

    const onSendToEmail = (data: IDashboard) => {
        setDashboardData(data);
        setActiveModalType(DashboardModalType.sendToEmail);
    };

    const onScheduler = (data: IDashboard) => {
        setDashboardData(data);
        setActiveModalType(DashboardModalType.scheduler);
    };

    const onMakeDefault = (data: IDashboard) => {
        const message = t(data.isDefault
            ? 'dashboardUnmarkedAsDefault'
            : 'dashboardMarkedAsDefault',
        );

        dispatch(changeDashboardSettings(data.id, {
            title: data.title,
            isDefault: !data.isDefault,
        }, message));
    };

    const onEditDashboardTitle = (data: IDashboard) => {
        setDashboardData(data);
        setActiveModalType(DashboardModalType.renameDashboard);
    };

    const requestTrigger = (baseFilter = filters) => dispatch(getDashboards(baseFilter));

    const updateFiltersHandler = (baseFilter = filters) => dispatch(updateFilters(baseFilter));

    const resetFiltersExceptFavourites = () => dispatch(resetFilterExceptField([ 'isFavourite' ]));

    const onMakeFavourite = (dashboard: IDashboard) => {
        dispatch(toggleDashboardFavoriteFromList(dashboard.id, dashboard.isFavourite));

        if(filters.isFavourite) {
            const filtersModel = {
                ...filters,
                page: 1,
            };

            requestTrigger(filtersModel);
            updateFiltersHandler(filtersModel);
        }
    };

    const onExport = (dashboardId: string, format: EExportFormats) => dispatch(exportToFormat(dashboardId, format));

    useEffect(() => {
        dispatch(getDashboards(filters));
        updateFiltersHandler(filters);

        return () => {
            resetFiltersExceptFavourites();
        };
    }, []);

    return (
        <>
            <VisualControlPanel onCreateDashboard={ onCreateDashboard } isDashBoardList />

            <TableDashboards
                tableData={ tableData }
                loading={ loading }
                filters={ filters }
                count={ count }
                onDeleteDashboard={ onDeleteDashboard }
                onCopyDashboard={ onCopyDashboard }
                onSendToEmail={ onSendToEmail }
                onScheduler={ onScheduler }
                onMakeDefault={ onMakeDefault }
                onEditDashboardTitle={ onEditDashboardTitle }
                onMakeFavourite={ onMakeFavourite }
                onExport={ onExport }
                onImportOldDashboard={ onImportOldDashboard }
                updateFilters={ updateFiltersHandler }
                requestTrigger={ requestTrigger }
            />

            <CreateDashboardModal
                show={ [
                    DashboardModalType.copyDashboard,
                    DashboardModalType.createDashboard,
                ].includes(activeModalType) }
                toggle={ () => {
                    closeModal();
                    dispatch(toggleCreateModal({ type: '', id: null }));
                } }
                modalState={{ type: activeModalType, dashboardId: dashboardData?.id }}
            />

            <DeleteDashboardModal
                show={ activeModalType === DashboardModalType.deleteDashboard }
                deleteDashboardId={ dashboardData?.id }
                toggle={ closeModal }
            />

            <SendToEmailModal
                show={ activeModalType === DashboardModalType.sendToEmail }
                dashboardId={ dashboardData?.id }
                toggle={ closeModal }
            />

            <SchedulerModal
                show={ activeModalType === DashboardModalType.scheduler }
                dashboardId={ dashboardData?.id }
                toggle={ closeModal }
            />

            <EditDashboardTitleModal
                show={ activeModalType === DashboardModalType.renameDashboard }
                dashboard={ dashboardData }
                toggle={ closeModal }
            />

            <ImportOldDashboardModal
                show={ activeModalType === DashboardModalType.importOldDashboard }
                toggle={ closeModal }
            />
        </>
    );
};
