import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { VocModal, FormFieldItem } from '/components';

import './VirtualSourceModalCopy.scss';

@withTranslation()
export class VirtualSourceModalCopy extends Component {
    @autobind
    getModalBody() {
        const { loading, modalCopyName, t } = this.props;

        return (
            <FormFieldItem
                type='text'
                disabled={ loading }
                isHorizontal={ true }
                value={ modalCopyName }
                label={ t('field.name') }
                isRequired={ true }
                placeholder={ t('placeholders.enterName') }
                handleChange={ this.valueChangedInput }
                withError={ true }
                isValid={ this.lengthValidation() }
                textError={ t('validation.nameMustBeNoMoreThanCharacters', { charCount: 50 }) }
            />
        );
    }

    @autobind
    valueChangedInput(event) {
        this.props.changeModalCopyName(event.target.value);
    }

    @autobind
    close() {
        this.props.copyModalHandler(false);
    }

    @autobind
    onSubmit() {
        const { copyModalHandler, modalCopyName, source } = this.props;

        copyModalHandler(true, modalCopyName, source);
    }

    @autobind
    lengthValidation() {
        const { modalCopyName } = this.props;

        return modalCopyName.length <= 50;
    }

    @autobind
    getFooter() {
        const { loading, modalCopyName, t } = this.props;

        return (
            <div>
                <Button
                    outline
                    color='white'
                    onClick={ this.close }
                >
                    { t('cancel') }
                </Button>
                <Button
                    disabled={ loading || !modalCopyName.trim() || !this.lengthValidation() }
                    color='primary'
                    onClick={ this.onSubmit }
                >
                    <FontAwesomeIcon icon='check' />
                    &nbsp;
                    <span>{ t('save') }</span>
                </Button>
            </div>
        );
    }

    render() {
        const { show, t } = this.props;

        return (
            <VocModal
                modalClassName='modal-scrollable modal-virtual-source-copy'
                header={ t('copySource') }
                isOpen={ show }
                size='md'
                toggle={ this.close }
                footer={ this.getFooter() }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}
