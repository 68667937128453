import React from 'react';
import { TooltipWrapper } from '/components';
import { components } from 'react-select';
import { Badge } from 'reactstrap';
import { optionLabel } from '../../modules/export.selector';

import './SelectComponentOption.scss';

//todo remove after delete old exporters
export const SelectComponentOption = props => {
    const { color, title } = optionLabel(props.data?.isNewExporter);

    return (
        <div className={ props.className } { ...props.innerProps }>
            <components.Option { ...props }>
                <div className="export_select-component-options">
                    <div>
                        <TooltipWrapper value={ props.children } placement={ 'top' }>
                            <div className='exporter-select-options__title'>
                                { props.children }
                            </div>
                        </TooltipWrapper>
                    </div>

                    <div className="exporter-select-options__labels-container">
                        <Badge color={ color }
                            className={ 'exporter-select-options__source-label' }>
                            { title }
                        </Badge>
                    </div>
                </div>
            </components.Option>
        </div>
    );
};
