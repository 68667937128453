import React, { useEffect, useState } from 'react';
import { Button, FormText, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocModal } from '/components';
import { HelperService } from '/services';

import styles from './style.module.scss';

export const TopicsCategoryModal = ({
    modalIsOpen,
    onClose,
    onSubmit,
    initialValue,
    categories,
}) => {
    const [ value, setValue ] = useState('');
    const [ isCategoryExist, setIsCategoryExist ] = useState(false);
    const isEdit = !!initialValue;

    useEffect(() => {
        // controlling the input with string state variable (non-null and non-undefined)
        setValue(HelperService.checkNotNullOrUndefined(initialValue) ? initialValue : '');
    }, [ initialValue ]);

    const onClosedHandler = () => {
        setValue('');
        setIsCategoryExist(false);
    };

    const handleSubmit = () => {
        isEdit
            ? onSubmit(initialValue, value.trim())
            : onSubmit(value.trim());

        onClose();
    };

    const onChangeHandler = ({ target }) => {
        const { value } = target;
        const isExist = categories.some(category => category.value === value);

        setIsCategoryExist(isExist && initialValue !== value);
        setValue(value);
    };

    const getModalBody = () => {
        return (
            <div>
                <Label>Category name</Label>
                <Input onChange={ onChangeHandler } value={ value }/>
                <FormText className={ styles.errorCategory }>
                    { isCategoryExist ? 'Category already exist' : '‎' }
                </FormText>
            </div>
        );
    };

    const getFooter = () => {
        return (
            <div>
                <Button outline onClick={ onClose } color='white'>
                    Cancel
                </Button>
                <Button
                    disabled={ !value || isCategoryExist }
                    onClick={ !isCategoryExist ? handleSubmit : null }
                    color='primary'
                >
                    <FontAwesomeIcon icon='check'/>
                    <span> Save</span>
                </Button>
            </div>
        );
    };

    return (
        <VocModal
            toggle={ onClose }
            onClosed={ onClosedHandler }
            header={ `${isEdit ? 'Edit' : 'Add'} category` }
            modalClassName='modal-scrollable'
            isOpen={ modalIsOpen }
            size='md'
            footer={ getFooter() }
        >
            { getModalBody() }
        </VocModal>
    );
};
