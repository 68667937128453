import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DocumentTitle from 'react-document-title';

import { DictionariesHeader, DictionaryTable } from "/dictionaries/components";
import { TableResetFilter } from "/components";
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import {
    changeDictionaryFilters,
    deleteDictionaryById,
    getDictionaries,
    resetDictionariesFilters,
    setSelectedDictionariesFilters,
} from "./modules/dictionary.modules";
import {
    dictionariesListSelector,
    dictionariesExtraFiltersSelector,
    dictionariesFiltersSelector,
    dictionariesWithExtraFiltersDropped,
} from "./modules/dictionary.selectors";

import './Dictionaries.scss';

const initialFormState = {
    title: '',
    language: 'en',
    lemmata: '',
};

export const Dictionaries = () => {
    const dispatch = useDispatch();
    const dictionariesFilters = useSelector(dictionariesFiltersSelector);
    const dictionariesList = useSelector(dictionariesListSelector);
    const extraFilters = useSelector(dictionariesExtraFiltersSelector);
    const withDroppedExtraFilters = useSelector(dictionariesWithExtraFiltersDropped);

    const [ collapse, setCollapse ] = useState(false);
    const [ isModalOpened, setIsModalOpened ] = useState(false);
    const [ dictionaryId, setDictionaryId ] = useState(null);
    const [ isAddButtonActiveHandler, setIsAddButtonActiveHandler ] = useState(false);
    const [ isChecked, setIsChecked ] = useState(false);
    const [ initialFormValues, setInitialFormValues ] = useState(initialFormState);
    const [ targetFilter, setTargetFilter ] = useState('');
    const [ filterField, setFilterField ] = useState('');

    const requestTrigger = (filters = dictionariesFilters) => {
        const {
            sort_field, sort_order,
            limit, page, search, extraFilters,
        } = filters;

        const model = {
            sort: sort_field ? { [sort_field]: sort_order } : null,
            limit: limit,
            page: page,
            search: search ? search : null,
            filters: Object.keys(extraFilters)
                .filter(filter => extraFilters[filter].length)
                .reduce((accFilter, el) => {
                    return {
                        ...accFilter,
                        [el]: extraFilters[el]
                            .reduce((accValue, el) => {
                                return [
                                    ...accValue,
                                    el.value,
                                ];
                            }, []),
                    };
                }, {}),
        };

        dispatch(getDictionaries(model));
    };

    const updateFilters = payload => {
        dispatch(changeDictionaryFilters(payload));
    };

    const openPopoverFilter = (currentTarget, sort_field) => {
        if (!targetFilter || targetFilter !== currentTarget) {
            setTargetFilter(currentTarget);
            setFilterField(sort_field);
        }
    };

    const togglePopover = () => {
        setTargetFilter(null);
        setFilterField('');
    };

    const onSearchType = search => {
        const model = { search, page: 1 };

        updateFilters(model);
        requestTrigger({ ...dictionariesFilters, ...model });
    };

    const closeModal = () => {
        dictionaryId && setDictionaryId('');
        setIsModalOpened(false);
        setInitialFormValues(initialFormState);
        setIsChecked(false);
        setIsAddButtonActiveHandler(false);
    };

    const openEditModal = dictionaryId => {
        setIsAddButtonActiveHandler(true);
        setIsChecked(true);
        setDictionaryId(dictionaryId);
        setInitialFormValues(dictionariesList.find(({ id }) => id === dictionaryId));
        !isModalOpened && setIsModalOpened(true);
    };

    const deleteDictionary = dictionaryId => {
        const params = {
            ...dictionariesFilters,
            page: 1,
        };

        dispatch(deleteDictionaryById(dictionaryId)).promise
            .then(() => {
                updateFilters(params);
                requestTrigger(params);
            });
    };

    const openDeleteModal = dictionaryId => {
        dispatch(showConfirm({
            header: 'Please confirm',
            content: `Are you sure you want to delete this dictionary?`,
            successCallback: () => deleteDictionary(dictionaryId),
        }));
    };

    const applyFilters = ({ name, value }) => {
        dispatch(setSelectedDictionariesFilters({ name, value }));

        requestTrigger({
            ...dictionariesFilters,
            extraFilters: {
                ...extraFilters,
                [name]: value,
            },
        });
    };

    const resetFilters = name => dispatch(resetDictionariesFilters(name));

    useEffect(() => {
        requestTrigger(withDroppedExtraFilters);
    }, [ extraFilters ]);

    useEffect(() => {
        setCollapse(false);
    }, [ dictionariesFilters, dictionariesList ]);

    return (
        <>
            <DocumentTitle title={ 'Mine :: Dictionaries' }/>
            <DictionariesHeader
                closeModal={ closeModal }
                setIsChecked={ setIsChecked }
                isChecked={ isChecked }
                isAddButtonActiveHandler={ isAddButtonActiveHandler }
                setIsAddButtonActiveHandler={ setIsAddButtonActiveHandler }
                dictionaryId={ dictionaryId }
                isModalOpened={ isModalOpened }
                setIsModalOpened={ setIsModalOpened }
                initialFormValues={ initialFormValues }
                setInitialFormValues={ setInitialFormValues }
                onSearchType={ onSearchType }
                requestTrigger={ requestTrigger }
            />
            <TableResetFilter
                className={ 'reset-filter_margin-top' }
                filters={ extraFilters }
                reset={ resetFilters }
            />
            <DictionaryTable
                data={ dictionariesList }
                collapse={ collapse }
                setCollapse={ setCollapse }
                filters={ dictionariesFilters }
                isOpenPopover={ !!targetFilter }
                requestTrigger={ requestTrigger }
                updateFilters={ updateFilters }
                openEditModal={ openEditModal }
                openDeleteModal={ openDeleteModal }
                openPopoverFilter={ openPopoverFilter }
                filterField={ filterField }
                targetFilter={ targetFilter }
                togglePopover={ togglePopover }
                applyFilters={ applyFilters }
                resetFilters={ resetFilters }
            />
        </>
    );
};
