import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import cx from 'classnames';
import { Popover } from 'reactstrap';

import { Preloader } from '/components';
import { SortableList } from './SortableList';

import './QuestionTreePopover.scss';

export class QuestionTreePopover extends Component {
    @autobind
    renderFlowItems() {
        const {
            flows,
            removeFlowAction,
            updateStateFlow,
            onSortEnd,
            flowOptions,
            statusFlow,
            parentId,
            rightsSurvey,
            activeQuestionId,
        } = this.props;
        const { question, optionForCommand, optionForChangeLanguage } = flowOptions[0];

        return (
            <>
                {
                    statusFlow.flowLoading
                    && <div className={ cx('flow-preloader-box', { 'flow-preloader__min': flows.length < 4 }) }>
                        <Preloader/>
                    </div>
                }
                <SortableList
                    flows={ flows }
                    removeRowForm={ removeFlowAction }
                    updateStateFlow={ updateStateFlow }
                    parentId={ parentId }
                    onSortEnd={ onSortEnd }
                    flowOptions={ question }
                    optionForCommand={ optionForCommand }
                    optionForChangeLanguage={ optionForChangeLanguage }
                    useDragHandle={ true }
                    rightsSurvey={ rightsSurvey }
                    activeQuestionId={ activeQuestionId }
                />
            </>
        );
    }

    render() {
        const { show, toggle, target } = this.props;

        return (
            <Popover
                id='flow-structure-popover'
                className='flow-popover-inner'
                isOpen={ show }
                hideArrow={ true }
                container={ '.questions__sortable-container' }
                placement="bottom"
                target={ target }
                toggle={ toggle }
                trigger="legacy"
            >
                { this.renderFlowItems() }
            </Popover>
        );
    }
}
