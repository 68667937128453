import { HelperService } from '/services';

const maxLenghtEmail = 254;

export const CaseAlertFormValidation = (values = []) => {
    const errors = {
        name: '',
        target_emails: [],
    };

    const { name, target_emails } = values;

    if(!name) {
        errors.name = 'Field must not be empty';
    }

    if(target_emails?.length) {
        target_emails.map((el, inx) => {
            if(!HelperService.validateEmail(el)) {
                errors.target_emails[inx] = el?.length
                    ? 'Email not valid'
                    : 'Field must not be empty';
            } else if(el.length > maxLenghtEmail) {
                errors.target_emails[inx] = 'Too long email';
            }

            return el;
        });
    }


    return errors;
};
