import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import cx from "classnames";
import { useTranslation } from 'react-i18next';

import { containerIsFluidSelector, hasVisualWriteAccessSelector } from "/modules/selectors";
import { updateUserSettings } from "/modules";
import { IProps } from "./models";

import styles from './style.module.scss';

export const VisualControlPanel = ({ isDashBoardList, onCreateDashboard }: IProps) => {
    const { t } = useTranslation();
    const isFluidContainer = useSelector(containerIsFluidSelector);
    const hasVisualWriteAccess = useSelector(hasVisualWriteAccessSelector);
    const { push } = useHistory();
    const dispatch = useDispatch();
    const rootPath = '/visualV2';

    return (
        <div className={ cx(styles.header, { [styles.withOffset]: isDashBoardList }) }>
            <div className={ styles.headerBread }>
                <h1 className={ styles.title }>
                    {
                        isDashBoardList
                            ? <Link to={ `${rootPath}/dashboard` }>
                                { t('visual') }
                            </Link>
                            : t('visual')
                    }
                </h1>
                {
                    isDashBoardList
                        ? <>
                            <FontAwesomeIcon
                                icon={ 'angle-right' }
                                className={ styles.headerBreadIcon }
                            />
                            <h2 className={ styles.subTitle }>
                                { t('dashboardListUpperCase') }
                            </h2>
                        </>
                        : null
                }
            </div>

            <div className={ styles.headerNavigation }>
                {
                    !isDashBoardList
                        ? <>
                            <Button
                                outline={ !isFluidContainer }
                                color="primary"
                                className={ cx(styles.arrowsButton) }
                                onClick={ () => dispatch(updateUserSettings({ "widenLayout": !isFluidContainer })) }
                            >
                                <FontAwesomeIcon icon={ 'arrows-alt-h' } />
                            </Button>

                            <Button
                                outline
                                color="primary"
                                className={ styles.dashboardTypeButton }
                                onClick={ () => push(`${ rootPath }/dashboard-list`) }
                            >
                                <FontAwesomeIcon icon={ 'list' } />
                                &nbsp;
                                <span>{ t('dashboardList') }</span>
                            </Button>
                        </>
                        : null
                }

                {
                    hasVisualWriteAccess
                    && <Button
                        color="success"
                        className={ styles.createDashboardButton }
                        onClick={ onCreateDashboard }
                    >
                        <span>{ t('createDashboard') }</span>
                    </Button>
                }
            </div>
        </div>
    );
};
