import { CALL_API, FEEDBACK } from 'store/middleware/api';

const GET_ASSETS_REQUEST = 'GET_ASSETS_REQUEST';
const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
const GET_ASSETS_ERROR = 'GET_ASSETS_ERROR';

const UPLOAD_ASSETS_REQUEST = 'UPLOAD_ASSETS_REQUEST';
const UPLOAD_ASSETS_SUCCESS = 'UPLOAD_ASSETS_SUCCESS';
const UPLOAD_ASSETS_ERROR = 'UPLOAD_ASSETS_ERROR';

function getInitialState() {
    return {
        assets: {
            loading: true,
            dirs: [],
            files: [],
        },
    };
}

export function reducer(state = (getInitialState()), action = {}) {
    switch (action.type) {
        case GET_ASSETS_REQUEST: {
            return {
                ...state,
                assets: {
                    ...state.assets,
                    loading: true,
                },
            };
        }

        case GET_ASSETS_SUCCESS: {
            return {
                ...state,
                assets: {
                    ...state.assets,
                    ...action.response,
                    loading: false,
                },
            };
        }

        case UPLOAD_ASSETS_SUCCESS: {
            return {
                ...state,
                assets: {
                    ...state.assets,
                    files: [
                        ...state.assets.files,
                        {
                            name: action.response.filename,
                        },
                    ],
                },
            };
        }

        default:
            return state;
    }
}

export function getAssets(folder = '') {
    return {
        [CALL_API]: {
            endpoint: `/assets/${ folder }`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_ASSETS_REQUEST, GET_ASSETS_SUCCESS, GET_ASSETS_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function uploadAsset(formData) {
    return {
        [CALL_API]: {
            endpoint: `/assets/`,
            method: 'POST',
            contentType: 'multipart/form-data',
            types: [ UPLOAD_ASSETS_REQUEST, UPLOAD_ASSETS_SUCCESS, UPLOAD_ASSETS_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'File was successfully uploaded',
                error: 'Something went wrong',
            },
            body: formData,
        },
    };
}
