import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { VocModal, Preloader, VocSelectInvite } from '/components';

export const ShareRightsModal = ({
    show,
    close,
    onSubmit,
    queryId,
    members = {},
    shares: {
        loaded,
        userRights,
    },
    isOwnerRights,
}) => {
    const { t } = useTranslation();
    const [ rights, setRights ] = useState({});
    const [ isValid, setIsValid ] = useState(true);

    useEffect(() => {
        if (show && loaded) {
            let usersRights;

            if(isOwnerRights) {
                usersRights = { users: userRights };
            } else {
                const owner = userRights.find(user => user.permission === 'owner');
                const users = userRights.filter(user => user.permission !== 'owner');

                usersRights = { owner, users };
            }

            setRights(usersRights);
        }
    }, [ loaded, show ]);

    const setSelected = values => {
        setRights({ owner: rights.owner, users: values });
        if(isOwnerRights && show) {
            const isOwner = values.filter(({ permission }) => permission === 'owner');
            const isOwnerNotGroup = isOwner.filter(({ ownerType }) => ownerType === 'group');

            setIsValid(isOwner.length === 1 && isOwnerNotGroup.length === 0);
        }
    };

    const getModalBody = () => {
        return loaded
            ? <>
                {
                    rights.owner
                        ? <Row>
                            <Col md={ 12 }>
                                <div className={ 'owner__label' }>
                                    <div className={ 'owner-label__icon' }>
                                        <FontAwesomeIcon icon="user-cog"/>
                                    </div>
                                    <div className={ 'owner-label__text' }>
                                        {`${ rights.owner.label }`}
                                        &nbsp;
                                        <span>{ t('field.owner') }</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        : null
                }

                <VocSelectInvite
                    placeholder={ t('inviteSomeone') }
                    inviteListPlaceholder={ t('invitationListIsEmpty') }
                    globalClass={ 'classifier-multi-select__row invite-multi-select__row' }
                    label={ t('invitePeople') }
                    value={ rights.users }
                    options={ members.options }
                    onChange={ setSelected }
                    closeMenuOnSelect={ false }
                    isOwnerRights={ isOwnerRights }
                    isClearable
                    isMulti
                    portal
                />
            </>
            : <Preloader/>;
    };

    const toggle = () => {
        close();
        cleanupModal();
    };

    const handlerOnSubmit = () => {
        if(!isValid) return;

        const shares = rights.users.map(({ value, ownerType, permission }) => ({
            ownerId: value,
            ownerType,
            permission,
        }));

        onSubmit({
            queryId,
            shares: shares,
        });
    };

    const getModalFooter = () => (
        <>
            {
                isValid
                    ? null
                    : <p className={ 'dander-text' }>
                        { t('shareRightsOwnerWarning') }
                    </p>
            }
            <Button onClick={ toggle } outline color={ 'white' }>
                <FontAwesomeIcon icon="times" />
                &nbsp;
                <span>{ t('cancel') }</span>
            </Button>
            <Button
                onClick={ handlerOnSubmit }
                disabled={ members.submitting || !isValid }
                color={ 'primary' }
            >
                <FontAwesomeIcon icon="check" />
                &nbsp;
                <span>{ t('save') }</span>
            </Button>
        </>
    );

    const cleanupModal = () => {
        setRights({});
        setIsValid(true);
    };

    return (
        <VocModal
            isOpen={ show }
            toggle={ toggle }
            modalClassName='modal-scrollable'
            header={ t('shareRights') }
            footer={ getModalFooter() }
            onClosed={ cleanupModal }
            size="lg"
        >
            {
                getModalBody()
            }
        </VocModal>
    );
};
