import React, { memo, MouseEventHandler, useEffect, useState } from 'react';
import cx from 'classnames';
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { useSelector } from 'react-redux';

import { IChartLabelItem } from '/visual/scenes/Dashboard/components/Gadget/models';
import { _getIsEditable, getDashboardPages } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { TooltipWrapper } from '/components';
import { HelperService } from '/services';

import styles from './style.module.scss';

export const ChartLabelItem = memo(({
    isDisabled = false,
    isActive,
    labelData,
    longLabel,
    gadgetId,
    onColorPickerOpen,
    onMouseEnter,
    onMouseLeave,
    onCLickLabel,
    isOverlay,
}: IChartLabelItem) => {
    const { id, customId, label, color } = labelData;
    const modifiedCustomId = customId + gadgetId;
    const [ filterButtonFixWidth, setFilterButtonFixWidth ] = useState(0);
    const pages = useSelector(getDashboardPages);
    const isEditable = useSelector(_getIsEditable);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        node,
        transition,
    } = useSortable({
        id: customId,
        disabled: isDisabled,
    });

    const handleMouseEnter = () => {
        onMouseEnter(id);
    };

    const handleOnContextMenu: MouseEventHandler<HTMLButtonElement> = event => {
        onColorPickerOpen({
            id,
            customId: modifiedCustomId,
            target: modifiedCustomId,
            label,
            color,
            coords: { y: event.pageY, x: event.pageX },
        });
        event.preventDefault();
    };

    const renderLabelText = () =>
        <span
            className='filterLabelText'
            style={{ color: HelperService.getContrastYIQ(color) }}
        >
            { label }
        </span>;

    useEffect(() => {
        // FIX - The library does not use the exact dimensions of the dragged object (offsetWidth),
        // so some labels (with size for ex. 84.06px) move the text to the next row when dragging

        setFilterButtonFixWidth(0);
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setFilterButtonFixWidth(node?.current?.getBoundingClientRect().width);
        }, isEditable ? 300 : 0);
    }, [ label, pages, isEditable ]);

    return (
        <div
            className={ styles.filterButtonWrapper }
            ref={ setNodeRef }
            { ...attributes }
            { ...listeners }
            style={
                {
                    transform: CSS.Translate.toString(transform),
                    transition,
                    opacity: isActive ? "0.3" : "1",
                    ...filterButtonFixWidth ? { minWidth: filterButtonFixWidth } : {},
                }
            }
        >
            <button
                id={ modifiedCustomId }
                key={ modifiedCustomId }
                style={{ background: color }}
                onMouseEnter={ handleMouseEnter }
                onMouseLeave={ onMouseLeave }
                onContextMenu={ handleOnContextMenu }
                onClick={ event => {
                    event.stopPropagation();
                    onCLickLabel(modifiedCustomId, label, id, { y: event.pageY, x: event.pageX });
                } }
                className={ cx('filterLabelButton', { trimLabel: !longLabel }) }
            >
                {
                    longLabel || isOverlay
                        ? renderLabelText()
                        : <TooltipWrapper value={ label }>
                            { renderLabelText() }
                        </TooltipWrapper>
                }
            </button>
        </div>

    );
});

