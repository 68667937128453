import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { autobind } from 'core-decorators';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { VirtualSourcesTable, VirtualSourceModalCopy } from './components';
import { PageRequests } from '/services';
import { VocCleaupHOC } from '/components';
import { SourceModalInfo } from '../../components';
import { getSharedRights, saveStoreRights } from '/modules';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';
import { showExportFilterModal } from '/scenes/VocStore/modules/VocStore.modules';
import { showApplianceModal } from '/modules/segmentatorModules/segmentator.modules.js';
import { isVocCIIntegration } from '/scenes/VocStore/modules/VocStore.selectors';
import {
    virtualSourcesTableDataSelector,
    virtualSourcesTableStateSelector,
    virtualSourcesFiltersSelector,
    virtualSourcesInfoSelector,
    virtualToDeleteSelector,
    virtualSourcesCopyLoadingSelector,
} from './modules/VirtualSources.selectors';

import {
    updateVirtualDescription,
    deleteVirtualSource,
    getVirtualRelations,
    cleanupVirtualInfo,
    getVirtualSources,
    copyVirtualSource,
    getVirtualInfo,
    updateFilters,
    cleanup,
} from './modules/VirtualSources.modules';

const mapStateToProps = state => {
    return {
        tableData: virtualSourcesTableDataSelector(state),
        tableState: virtualSourcesTableStateSelector(state),
        filters: virtualSourcesFiltersSelector(state),
        virtualToDelete: virtualToDeleteSelector(state),
        copyLoading: virtualSourcesCopyLoadingSelector(state),
        isVocCIIntegration: isVocCIIntegration(state),
        virtualSourcesInfo: virtualSourcesInfoSelector(state),
    };
};

const mapDispatchToProps = {
    updateVirtualDescription,
    showExportFilterModal,
    getVirtualRelations,
    deleteVirtualSource,
    showApplianceModal,
    cleanupVirtualInfo,
    copyVirtualSource,
    getVirtualSources,
    getSharedRights,
    saveStoreRights,
    getVirtualInfo,
    updateFilters,
    showConfirm,
    cleanup,
};

@withTranslation()
@connect(mapStateToProps, mapDispatchToProps)
@VocCleaupHOC
export class VirtualSources extends Component {
    pageRequests = new PageRequests();

    state = {
        isCopyModalOpened: false,
        isInfoModalOpened: false,
        source: {},
        modalCopyName: '',
    };

    componentDidMount() {
        this.requestTrigger();
    }

    getTableOptions() {
        return {
            requestTrigger: this.requestTrigger,
            haveSearch: true,
            updateFilters: this.props.updateFilters,
        };
    }

    @autobind
    changeModalCopyName(modalCopyName) {
        this.setState({
            modalCopyName,
        });
    }

    @autobind
    requestTrigger(filters = this.props.filters) {
        this.pageRequests.cleanup();
        const request = this.props.getVirtualSources(filters);

        this.pageRequests.addRequest(request.xhr);
    }

    @autobind
    copyModalHandler(isCopyConfirmed, name, source) {
        isCopyConfirmed
            ? this.props.copyVirtualSource(source.id, { name }).promise
                .then(() => {
                    this.requestTrigger();
                    this.closeModal();
                })
            : this.closeModal();
    }

    @autobind
    closeModal() {
        this.setState({
            isCopyModalOpened: false,
            isInfoModalOpened: false,
            source: {},
            modalCopyName: '',
        });
    }

    @autobind
    deleteVirtualSource(id) {
        const params = {
            ...this.props.filters,
            page: 1,
        };

        const request = this.props.deleteVirtualSource(id);

        request.promise.then(() => {
            this.requestTrigger(params);
            this.props.updateFilters(params);
        });
    }

    @autobind
    onCopyButtonClick(source) {
        this.setState({
            isCopyModalOpened: true,
            source,
        });
    }

    @autobind
    onInfoButtonClick(source) {
        this.props.getVirtualInfo(source.id);

        this.setState({
            isInfoModalOpened: true,
            source,
        });
    }

    render() {
        const {
            updateVirtualDescription,
            tableState,
            tableData,
            filters,
            showConfirm,
            showApplianceModal,
            showExportFilterModal,
            getVirtualRelations,
            cleanupVirtualInfo,
            virtualSourcesInfo,
            virtualToDelete,
            copyLoading,
            getSharedRights,
            t,
        } = this.props;

        return (
            <DocumentTitle title={ `${ t('store') } :: ${ t('field.virtualSource') }` }>
                <div>
                    <Card className={ 'page__wrapper' }>
                        <CardHeader className={ 'panel__header panel__header--buttons' }>
                            <span className={ 'panel__header-title' }>
                                <FontAwesomeIcon icon='list-ul' />
                                &nbsp;
                                { t('virtualSources') }
                            </span>
                            <div className={ 'panel__header-buttons' }>
                                <Link to={ '/store/virtual-source/create' }>
                                    <Button
                                        className={ 'panel__header-button' }
                                        outline
                                        color={ 'white' }
                                    >
                                        <FontAwesomeIcon icon='plus' className='color-green' />
                                        &nbsp;
                                        <span>{ t('create') }</span>
                                    </Button>
                                </Link>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <VirtualSourcesTable
                                filters={ filters }
                                options={ this.getTableOptions() }
                                tableState={ tableState }
                                data={ tableData }
                                onCopyButtonClick={ this.onCopyButtonClick }
                                onInfoButtonClick={ this.onInfoButtonClick }
                                showApplianceModal={ showApplianceModal }
                                showExportFilterModal={ showExportFilterModal }
                                showConfirm={ showConfirm }
                                getVirtualRelations={ getVirtualRelations }
                                virtualToDelete={ virtualToDelete }
                                deleteVirtualSource={ this.deleteVirtualSource }
                                isVocCIIntegration={ this.props.isVocCIIntegration }
                                getSharedRights={ getSharedRights }
                                requestTrigger={ this.requestTrigger }
                            />
                        </CardBody>
                    </Card>
                    <VirtualSourceModalCopy
                        show={ this.state.isCopyModalOpened }
                        modalCopyName={ this.state.modalCopyName }
                        changeModalCopyName={ this.changeModalCopyName }
                        copyModalHandler={ this.copyModalHandler }
                        loading={ copyLoading }
                        source={ this.state.source }
                    />
                    <SourceModalInfo
                        show={ this.state.isInfoModalOpened }
                        sourcesInfo={ virtualSourcesInfo }
                        updateDescription={ updateVirtualDescription }
                        cleanupInfo={ cleanupVirtualInfo }
                        close={ this.closeModal }
                        source={ this.state.source }
                        header={ t('virtualSourcesInfo') }
                    />
                </div>
            </DocumentTitle>
        );
    }
}
