import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

import { TooltipWrapper } from '/components';

export const GraphButton = ({ onClick, className, disabled }) => (
    <TooltipWrapper value="Download flow structure graph" force={ true }>
        <Button
            onClick={ onClick }
            className={ `util-button ${className}` }
            color={ 'white' }
            disabled={ disabled }
            outline
        >
            <FontAwesomeIcon icon="chart-line" className={ 'util-button__icon' }/>
            <span>Graph</span>
        </Button>
    </TooltipWrapper>
);
