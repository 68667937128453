import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import './NumberInput.scss';

import { autobind } from 'core-decorators';

export class NumberInput extends Component {
    @autobind
    calculateValue(condition) {
        const {
            value,
            step = 1,
            min = 1,
            max = 100,
        } = this.props;
        let newValue;

        if (condition === 'plus') {
            newValue = value + step > max ? max : value + step;
        } else {
            newValue = value - step < min ? min : value - step;
        }
        this.props.changeHandler(newValue, this.props.name);
    }

    @autobind
    changeHandler(e) {
        const {
            min = 1,
            max = 100,
        } = this.props;
        const inputValue = +e.target.value;
        let newValue;

        switch (true) {
            case(inputValue > max):
                newValue = max;
                break;
            case(inputValue < min):
                newValue = min;
                break;

            default:
                newValue = inputValue;
        }

        this.props.changeHandler(newValue, this.props.name);
    }

    render() {
        const {
            name,
            value,
            min = 1,
            max = 100,
            step = 1,
            required = false,
        } = this.props;

        return (
            <div className="input-group input-group-number">
                <span className="input-group-btn">
                    <Button onClick={ this.calculateValue }
                        color={ 'white' }
                        outline
                        className="voc-number-input__button voc-number-input__button--first">
                        <FontAwesomeIcon icon="minus"/>
                    </Button>
                </span>
                <input name={ name }
                    id={ name }
                    type="number"
                    min={ min }
                    max={ max }
                    step={ step }
                    value={ value }
                    className="voc-number-input form-control"
                    onChange={ this.changeHandler }
                    required={ required }/>
                <span className="input-group-btn">
                    <Button onClick={ () => this.calculateValue('plus') }
                        color={ 'white' }
                        outline
                        className="voc-number-input__button voc-number-input__button--last">
                        <FontAwesomeIcon icon="plus"/>
                    </Button>
                </span>
            </div>
        );
    }
}

NumberInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    required: PropTypes.bool,
    changeHandler: PropTypes.func,
};

NumberInput.defaultProps = {
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    step: 1,
};
