import { HelperService } from "/services";

export class TableService {
    static columnResizingDebounce = HelperService.debounce((...args: any) => {
        const { saveSettings, columnsData } = args[0];

        saveSettings({
            tableColumnsWidth: Object.keys(columnsData).map(columnKey => (
                {
                    width: columnsData[columnKey],
                    name: columnKey.toString(),
                }
            )),
        });
    }, 500);

    static copyTable(headers: any, body: any) {
        const formatHeader = headers.reduce((total: any, currentHeader: {data: string}) => {
            return `${total}\t"${currentHeader.data?.toString()?.trim()}"`;
        }, '');

        const formatBodyLine = (rowData: any) =>
            headers.reduce((bodyLine: any, currentHeader: any) => {
                return `${bodyLine}\t"${(rowData[currentHeader.id] || '')?.toString()?.trim()}"`;
            }, '');

        const formatBody = body.reduce((total: any, bodyRow: any) => {
            return `${total}${formatBodyLine(bodyRow)}\n`;
        }, '');

        return HelperService.copyToClipboard(`${formatHeader}\n${formatBody}`);
    }
}
