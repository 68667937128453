import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { Greeting } from './components/Greeting';
import { Quoting } from './components/Quoting';
import { userInfoSelector } from '/modules/selectors';
import { Row } from 'reactstrap';

import './HomePage.scss';

const mapStateToProps = state => ({
    userInfo: userInfoSelector(state),
});

@connect(mapStateToProps, {})
export class HomePage extends Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const {
            userInfo: { firstName, background_image },
        } = this.props;

        return (
            <DocumentTitle title="WeAreSandSiv+" >
                <div className='home-container page__wrapper'>
                    <div className='home__background'>
                        <img className='home__background-image'
                            src={ `${background_image}` } alt=""/>
                    </div>
                    <Row>
                        <Greeting firstName={ firstName }/>
                    </Row>
                    <Row>
                        <Quoting classList={ [ 'quote--bottom' ] }/>
                    </Row>
                </div>
            </DocumentTitle>
        );
    }
}
