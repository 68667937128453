import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/webpack-resolver";
import PropTypes from 'prop-types';

export class Editor extends Component {
    componentDidUpdate(prevProps) {
        if (Boolean(this.props.url) && prevProps.url !== this.props.url) {
            this.insertUrl(this.props.url);
        }
    }

    @autobind
    onChange(value) {
        this.props.onChange(value);
    }

    @autobind
    onFocus() {
        this.props.onFocus && this.props.onFocus(this.props.value);
    }

    @autobind
    onBlur() {
        this.props.onBlur && this.props.onBlur(this.props.value);
    }

    @autobind
    htmlValidation(error) {
        if (typeof this.props.validationCallback === 'function') {
            const validationErrors = error.filter(item => item.type === 'error');

            this.props.validationCallback(validationErrors);
            this.props.disableSubmit(validationErrors.length > 0);
        }
    }

    @autobind
    onLoad(editor) {
        if (typeof this.props.resizeCallback === 'function') {
            this.checkEditorHeight(editor);
            editor.on('change', (arg, activeEditor) => {
                this.checkEditorHeight(activeEditor);
            });
        }
    }

    @autobind
    insertUrl(url) {
        const { editor } = this.ace;
        const { row, column } = editor.getCursorPosition();

        editor.session.insert(editor.getCursorPosition(), url);
        this.props.clearUrl();
        //fix async callback for aceEditor
        setTimeout(() => {
            editor.moveCursorTo(row, (column + url.length));
            editor.focus();
        }, 0);
    }

    @autobind
    checkEditorHeight(editor) {
        const aceEditor = editor;
        const currentHeight = aceEditor.getSession()
            .getScreenLength() * aceEditor.renderer.lineHeight;
        const newHeight = currentHeight > parseInt(this.props.baseHeight)
            ? `${ currentHeight }px`
            : this.props.baseHeight;

        if(newHeight !== this.props.baseHeight) {
            this.props.resizeCallback(newHeight);
        }
    }

    render() {
        const { value, disabled, mode, height, focus, numberOfLine } = this.props;

        return (
            <AceEditor
                onLoad={ this.onLoad }
                mode={ mode }
                theme={ 'chrome' }
                onChange={ this.onChange }
                onBlur={ this.onBlur }
                onFocus={ this.onFocus }
                height={ height }
                focus={ focus }
                style={{
                    width: '100%',
                    border: '1px solid #ccc',
                    borderTop: 'none',
                    background: `${ disabled ? '#eee' : '' }`,
                    opacity: `${ disabled ? '0.5' : '1' }`,
                }}
                fontSize={ 14 }
                readOnly={ disabled }
                showGutter={ !disabled }
                wrapEnabled={ true }
                cursorStart={ 1 }
                setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                }}
                editorProps={{
                    $blockScrolling: Infinity,
                }}
                value={ value?.toString() }
                onValidate={ this.htmlValidation }
                minLines={ numberOfLine }
                ref={ instance => {
                    this.ace = instance;
                } }
            />
        );
    }
}

Editor.defaultProps = {
    disabled: false,
    mode: 'html',
    cursorStartPosition: 1,
    height: '300px',
    focus: false,
    numberOfLine: 21,
};

Editor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    disableSubmit: PropTypes.func,
    validationCallback: PropTypes.func,
    resizeCallback: PropTypes.func,
    mode: PropTypes.string,
    height: PropTypes.string,
    focus: PropTypes.bool,
    numberOfLine: PropTypes.number,
    baseHeight: PropTypes.string,
    url: PropTypes.string,
    clearUrl: PropTypes.func,
};
