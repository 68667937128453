import React, { Component, Fragment } from 'react';
import {
    VocModal,
    VocFormSelectField,
    VocFormCreatableSelectField,
    VocFormTextField,
    VocFormSelectAsyncPaginate,
} from '/components';
import { autobind } from 'core-decorators';
import { Button, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelperService } from '/services';
import { AddQueryFormValidation } from './AddQueryFormValidation';
import { reduxForm, Field } from 'redux-form';

import './AddQueryModal.scss';

@reduxForm({
    form: 'AddQueryModalForm',
    enableReinitialize: true,
    validate: AddQueryFormValidation,
})
export class AddQueryModal extends Component {
    @autobind
    onAddQueryClicked(values) {
        const model = {
            title: values.title_field,
            language: values.language_field,
            sourceId: values.source_field.value,
            columnIds: values.text_column_field.reduce((acc, el) => ([ ...acc, el.value ]), []),
            tag: values.tag_field ? values.tag_field.value : null,
            context: values.context ? values.context : null,
        };

        return this.props.createQuery(model);
    }

    @autobind
    changeSourceField(el) {
        this.props.change(`text_column_field`, el && el.attributes && el.attributes.length === 1 ? [ el.attributes[0] ] : null);
        this.props.change(`language_field`, el && el.languages && el.languages.length === 1 ? el.languages[0].value : null);
    }

    @autobind
    getModalFooter() {
        const { handleSubmit, valid, submitting } = this.props;

        return (
            <Fragment>
                <Button onClick={ this.close } outline color={ 'white' }>
                    <FontAwesomeIcon icon="times"/>
                    <span> Cancel</span>
                </Button>
                <Button
                    onClick={ handleSubmit(values => this.onAddQueryClicked(values)) }
                    disabled={ !(valid && !submitting) }
                    color={ 'primary' }>
                    <FontAwesomeIcon icon="check"/>
                    <span> Add query</span>

                </Button>
            </Fragment>
        );
    }

    @autobind
    getModalBody() {
        const { sourcesFilters, getSources, updateFilters, data: { sourcesOptions, count, loading }, textOptions, languageOptions, tags } = this.props;

        return (
            <Fragment>
                <div className="form-group">
                    <Label htmlFor={ 'title_field' }>
                        Query name
                    </Label>
                    <Field
                        name={ 'title_field' }
                        component={ VocFormTextField }
                        withError={ false }
                    />
                </div>
                <div className="form-group">
                    <Label htmlFor={ 'source_field' }>
                        Source
                    </Label>
                    <Field
                        name={ 'source_field' }
                        component={ VocFormSelectAsyncPaginate }
                        total={ count }
                        loading={ loading }
                        requestHandler={ getSources }
                        options={ sourcesOptions }
                        filters={ sourcesFilters }
                        setFilters={ updateFilters }
                        onChange={ el => this.changeSourceField(el) }
                        portal
                    />
                </div>
                <div className="form-group">
                    <Label htmlFor={ 'text_column_field' }>
                        Text column
                    </Label>
                    <Field
                        name={ 'text_column_field' }
                        component={ VocFormSelectField }
                        options={ textOptions }
                        format={ value => HelperService.getSelectedValue(textOptions, value) }
                        withError={ false }
                        isMulti
                        portal
                    />
                </div>
                <div className="form-group">
                    <Label htmlFor={ 'language_field' }>
                        Language
                    </Label>
                    <Field
                        name={ 'language_field' }
                        component={ VocFormSelectField }
                        options={ languageOptions }
                        normalize={ HelperService.normalizeSelectValue }
                        format={ value => HelperService.getSelectedValue(languageOptions, value) }
                        withError={ false }
                        portal
                    />
                </div>
                <div className="form-group">
                    <Label htmlFor={ 'tag_field' }>
                        Query tag
                    </Label>
                    <Field
                        name={ 'tag_field' }
                        component={ VocFormCreatableSelectField }
                        options={ tags.options }
                        portal
                        isClearable
                    />
                </div>

                <div className='form-group'>
                    <Label htmlFor='context'>
                        Query context
                    </Label>
                    <Field
                        name='context'
                        type='textarea'
                        className='form-group__textarea'
                        placeholder='Enter Query context'
                        rows={ 3 }
                        withError={ false }
                        component={ VocFormTextField }
                    />
                </div>
            </Fragment>
        );
    }

    @autobind
    close() {
        this.props.reset(this.props.form);
        this.props.close();
    }

    render() {
        return (
            <VocModal
                isOpen={ this.props.show }
                toggle={ this.close }
                modalClassName='modal-scrollable'
                header={ 'Add new query' }
                footer={ this.getModalFooter() }
                onClosed={ this.close }
                size="md"
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}
