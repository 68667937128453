import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipWrapper } from '/components';
import { HelperService } from "/services";
import { withTranslation } from 'react-i18next';

import './FormUploadFileItem.scss';

const uploadZoneStyle = {
    width: '100%',
    height: '122px',
    borderRadius: '3px',
    cursor: 'pointer',
    border: '1px #333333 dashed',
};

@withTranslation()
export class FormUploadFileItem extends Component {
    state = {
        classError: '',
    };

    checkFileSignatures({ file, isAccepted, fileExtension }) {
        let FileSignaturesType = '';
        const fileReader = new FileReader();

        fileReader.onloadend = e => {
            const arr = (new Uint8Array(e.target.result)).subarray(0, 8);
            let header = "";

            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }

            switch (header) {
                case "d0cf11e0a1b11ae1":
                    FileSignaturesType = 'xls';
                    break;
                case "504b030414000600":
                case "504b3414060":
                case "504b3414088":
                    FileSignaturesType = 'xlsx';
                    break;

                default:
                    FileSignaturesType = null;
                    break;
            }

            this.checkFileValid({ file, fileExtension, FileSignaturesType, isAccepted });
        };

        fileReader.readAsArrayBuffer(file);
    }

    checkFileValid({ file, fileExtension, FileSignaturesType, isAccepted }) {
        const { t } = this.props;
        const isExcelExtension = [ 'xls', 'xlsx' ].includes(fileExtension) && isAccepted;
        const isExtensionEquallySignatures = fileExtension === FileSignaturesType;
        const isEmojiInString = HelperService.isEmojiInString(file.name);

        const notAllowedMessage = t('validation.typeOfFileNotAllowed');
        const isEmojiMessage = t('validation.importFileNameContainEmoji');
        const incorrectMessage = t('validation.fileIsIncorrect');
        const alertMessage = FileSignaturesType
            ? t('validation.fileExtensionShouldBe', { fileExtension, signatureType: FileSignaturesType })
            : incorrectMessage;

        if(isEmojiInString) {
            this.errorFile('alert-error', isEmojiMessage);
        } else if (isExcelExtension) {
            isExtensionEquallySignatures
                ? this.uploadFile(file)
                : this.errorFile('alert-error', alertMessage);
        } else if (isAccepted) {
            !FileSignaturesType
                ? this.uploadFile(file)
                : this.errorFile('alert-error', incorrectMessage);
        } else {
            this.errorFile('alert-error', notAllowedMessage);
        }
    }

    @autobind
    onDrop(accepted, rejected) {
        const isAccepted = !rejected.length;
        const { fileSize, t } = this.props;
        const file = isAccepted ? accepted[0] : rejected[0];

        if(fileSize && file.size > fileSize) {
            this.errorFile('alert-error', t('validation.fileTooBig'));
        } else {
            let fileExtension = file.name.split('.');

            fileExtension = fileExtension[fileExtension.length - 1];

            this.checkFileSignatures({ file, isAccepted, fileExtension });
        }
    }

    uploadFile(file) {
        this.setState({
            classError: '',
        });

        this.props.onDrop(file);
    }

    errorFile(classError, textAlert) {
        this.setState({
            classError,
        });

        this.props.showErrorAlert({ text: textAlert, type: 'error' });
    }

    render() {
        const { controlId, file, accept, t } = this.props;
        const { classError } = this.state;

        return (
            <Dropzone
                id={ controlId }
                className={ file ? "alert alert-info" : `alert ${ classError || '' }` }
                acceptClassName="alert-success"
                style={ uploadZoneStyle }
                accept={ accept }
                onDrop={ this.onDrop }>
                { ({ getRootProps, getInputProps }) => {
                    return (
                        <div className={ `container_drop_zone ${ file ? 'alert-info' : this.state.classError } ` }>
                            <div className="file-preview" { ...getRootProps() }>
                                <TooltipWrapper value={ t('placeholders.clickToSelect') } force>
                                    <span>
                                        <FontAwesomeIcon icon={ 'save' } className="file-upload-icon" size={ '4x' }/>
                                    </span>
                                </TooltipWrapper>
                                <div id="choosen-files">
                                    <input { ...getInputProps() } />
                                    { file ? <div>{ file.name }</div> : t('placeholders.dragFileHere') }
                                </div>
                            </div>
                        </div>
                    );
                } }
            </Dropzone>
        );
    }
}

FormUploadFileItem.propTypes = {
    showErrorAlert: PropTypes.func,
    controlId: PropTypes.string,
    accept: PropTypes.string,
    onDrop: PropTypes.func.isRequired,
};
