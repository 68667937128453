import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from 'react-text-selection-popover';
import moment from 'moment';
import cx from 'classnames';
import { CollapseSection } from '/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTextsInfo } from '/query/modules/query.modules';
import { queryIdSelector, textInfoSelector, textInfoLoadingSelector } from '/query/modules/query.selectors';

import './QueryChartText.scss';

export const QueryChartTextItem = ({
    index,
    filterForInclude,
    filterForExclude,
    collapse,
    onCollapseChanged,
    onToInclude,
    onToExclude,
    data_id,
    column_id,
    text,
}) => {
    const dispatch = useDispatch();
    const textInfoLoading = useSelector(textInfoLoadingSelector);
    const { attributes, lemmas, captured_at, classification, topicSet } = useSelector(textInfoSelector);
    const queryId = useSelector(queryIdSelector);
    const popover = React.createRef();
    const [ isOpen, setIsOpen ] = useState(false);
    const [ textInfoError, setTextInfoError ] = useState('');
    const [ selectedText, setSelectedText ] = useState('');
    const [ isInclude, setIsInclude ] = useState(false);
    const [ isExclude, setIsExclude ] = useState(false);

    const selectText = () => {
        const selectedText = window.getSelection().toString().trim().toLowerCase();

        setIsInclude(filterForInclude.values.includes(selectedText));
        setIsExclude(filterForExclude.values.includes(selectedText));
        if (selectedText.length) {
            setSelectedText(selectedText);
            !isOpen && setIsOpen(true);
        }
    };

    const onInclude = () => {
        setIsOpen(false);
        onToInclude(selectedText, !isInclude);
    };
    const onExclude = () => {
        setIsOpen(false);
        onToExclude(selectedText, !isExclude);
    };

    const renderPopover = () => {
        return !isOpen
            ? null
            : (
                <div className={ "select-text-popover-body" }>
                    <button className={ cx("select-text-popover-body__btn", { active: isInclude, disable: isExclude }) }
                        disabled={ isExclude }
                        onClick={ onInclude }
                    >
                        <FontAwesomeIcon icon={ 'eye' }/>
                        <div className={ 'select-text-popover-body__btn-title' }>
                            { isInclude ? 'Remove include text' : 'Include text' }
                        </div>
                    </button>
                    <button className={ cx("select-text-popover-body__btn", { active: isExclude, disable: isInclude }) }
                        disabled={ isInclude }
                        onClick={ onExclude }
                    >
                        <FontAwesomeIcon icon={ 'eye-slash' }/>
                        <div className={ 'select-text-popover-body__btn-title' }>
                            { isExclude ? 'Remove exclude text' : 'Exclude text' }
                        </div>
                    </button>
                </div>
            );
    };

    const toString = itemValue => {
        if (itemValue === null) {
            return 'No value';
        }

        return typeof itemValue !== 'object' ? itemValue : itemValue.join(', ');
    };

    const renderAttributes = (attributes, title) => {
        return (
            <>
                {
                    attributes.length
                        ? <h5 className="query-details-text-more__title">{ title }</h5>
                        : ''
                }
                {
                    attributes.length
                        ? attributes.map(({ name, value }, index) => {
                            value = value === null ? 'No value' : toString(value);

                            if (name.includes('DATE')) {
                                const date = moment(value).format('YYYY-MM-DD');

                                value = date.includes('Invalid date') ? value : date;
                            }

                            return (
                                <div className="query-details-text-more__attributes" key={ index }>
                                    <p>
                                        <span className={ 'attribute-name' }>{ name }:</span>
                                        <span className={ 'attribute-value' }>{ value }</span>
                                    </p>
                                </div>
                            );
                        })
                        : null
                }
            </>
        );
    };

    const renderClassification = (classification, title) => {
        const classificationKey = Object.keys(classification);

        return (
            classificationKey.length
                ? <>
                    <h5 className="query-details-text-more__title">{ title }</h5>
                    {
                        classificationKey.map((key, index) => (
                            <div className="query-details-text-more__attributes" key={ index }>
                                <p>
                                    <span className={ 'attribute-name' }>{ key }:</span>
                                    <span className={ 'attribute-value' }>{ classification[key] }</span>
                                </p>
                            </div>
                        ))
                    }
                </>
                : null
        );
    };

    const lemmasJSX = lemmas => {
        return lemmas.length
            ? (
                <>
                    <h5 className="query-details-text-more__title">Lemmata</h5>
                    <div className="query-details-text-more__attributes">
                        <div className={ 'attribute-value' }>
                            { lemmas.join(', ') }
                        </div>
                    </div>
                </>
            ) : null;
    };

    const capturedAtJSX = capturedAt => {
        return capturedAt
            ? (
                <>
                    <h5 className="query-details-text-more__title">Captured at</h5>
                    <div className="query-details-text-more__attributes">
                        <div className={ 'attribute-value' }>
                            { capturedAt }
                        </div>
                    </div>
                </>
            )
            : null;
    };

    const onCollapseOpen = () => {
        const request = dispatch(getTextsInfo(queryId, data_id, column_id));

        request.promise
            .then(() => setTextInfoError(''))
            .catch(() => setTextInfoError('Failed to get additional information'));
    };

    const getTitle = () => textInfoLoading && collapse ? `Loading` : `Details`;

    return (
        <div className="query-details-text__item">
            <p className="query-details-text-p" ref={ popover }>{ text }</p>
            <CollapseSection className={ cx('query-details-text-more', { maxHeight: textInfoError }) }
                title={ getTitle() }
                collapse={ collapse }
                onCollapseChanged={ () => onCollapseChanged(index) }
                onCollapseOpen={ onCollapseOpen }
                isCollapseLoading={ textInfoLoading }
                loader={ true }
            >
                {
                    textInfoError
                        ? <span className={ 'query-details-text-more__error' }>{ textInfoError }</span>
                        : <>
                            { lemmasJSX(lemmas.split(' | ')) }
                            { renderAttributes(attributes, 'Metadata') }
                            { renderAttributes(topicSet, 'Topics') }
                            { capturedAtJSX(captured_at) }
                            { renderClassification(classification, 'Classification result') }
                        </>
                }
            </CollapseSection>

            <Popover selectionRef={ popover }
                isOpen={ isOpen }
                onTextSelect={ selectText }
                onTextUnselect={ () => setIsOpen(false) }
                className={ 'select-text-popover' }
            >
                { renderPopover() }
            </Popover>
        </div>
    );
};
