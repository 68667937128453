import React from 'react';
import { EmptyData } from '/components';
import { VocTableFooter } from '/components/VocTableV2/components';
import { QueryChartBarItem } from './QueryChartBarItem';
import './QueryChartBar.scss';

export const QueryChartBar = ({
    newChangeFilter,
    filterForInclude,
    filterForExclude,
    idInclude,
    idExclude,
    lemmataData,
    requestByChart,
    updatePaginationFilters,
    name,
}) => {
    const onWordClick = (lemma, selectedFilterType, secondFilterType) => {
        newChangeFilter({
            text: lemma,
            selectedFilterType,
            secondFilterType,
            allFilters: {
                [idInclude]: filterForInclude,
                [idExclude]: filterForExclude,
            },
        });
    };

    const handlePaginationFilters = page => {
        updatePaginationFilters({ page, name });
        requestByChart({ pagination: { page } });
    };

    const handleLimitChange = limit => {
        updatePaginationFilters({ limit, name, page: 1 });
        requestByChart({ pagination: { limit, page: 1 } });
    };

    const getLemmaListItem = () => {
        return lemmataData.lemmata.map(({ _id: lemma, count }) => {
            const isInclude = filterForInclude.values.includes(lemma);
            const isExclude = filterForExclude.values.includes(lemma);
            const lemmataAll = lemmataData.textsCount ? lemmataData.textsCount : 1;

            return (
                <QueryChartBarItem
                    key={ lemma }
                    lemma={ lemma }
                    count={ count }
                    countLemmataAll={ lemmataAll }
                    isInclude={ isInclude }
                    isExclude={ isExclude }
                    onWordClick={ onWordClick }
                />
            );
        });
    };

    const isLoading = !!lemmataData.lemmataLoading;
    const isNotEmpty = !!lemmataData.total;

    return (
        <>
            <div className="query-details-chart">
                {
                    !isNotEmpty && !isLoading
                        ? <div className={ 'word-cloud__section' }>
                            <EmptyData errorText={ lemmataData.error }/>
                        </div>
                        : isNotEmpty
                            ? <>
                                <div className="query-details-chart-header">
                                    <div className="query-details-chart-header__left">Key words</div>
                                    <div className="query-details-chart-header__right">Lemmata</div>
                                </div>
                                <div className="query-details-chart-list">
                                    { getLemmaListItem() }
                                </div>
                            </>
                            : null
                }
                {
                    lemmataData.total > 10
                        && <VocTableFooter
                            total={ lemmataData.total }
                            page={ lemmataData.filters.page }
                            onPageChange={ handlePaginationFilters }
                            onLimitChange={ handleLimitChange }
                            limit={ lemmataData.filters.limit }
                            goToPage={{
                                show: true,
                                closeOnChange: true,
                            }}
                        />
                }
            </div>
        </>
    );
};

QueryChartBar.defaultProps = {
    name: 'lemmataData',
};
