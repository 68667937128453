import React, { useEffect, useRef, ChangeEvent } from 'react';
import { Button, Input, Popover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ControlledTooltip, Portal } from '/components';
import { ILabelModal } from './models';

import styles from './style.module.scss';
import { ButtonComponent } from '/visual/components';

export const LabelModal = ({
    maxLength,
    labelModalData,
    gadgetId,
    chartType,
    labelType,
    isControlledPositionTooltip,
    placement,
    additionalButton,
    setLabelModalData,
    onApplyLabelModalData,
}: ILabelModal) => {
    const { t } = useTranslation();
    const labelModalCoordinatesRef = useRef<HTMLDivElement | null>(null);
    const chartTarget = `${ chartType }_${ labelType }_${ gadgetId }`;

    const closeLabelModal = () => setLabelModalData(null);

    const checkLabelModalCoords = () => {
        const labelModalCoordinates = labelModalCoordinatesRef.current;

        if (labelModalCoordinates && labelModalData !== null) {
            const { coords, open, target } = labelModalData;

            if (open) {
                if (target === chartTarget && coords) {
                    labelModalCoordinates.style.top = `${ coords.y }px`;
                    labelModalCoordinates.style.left = `${ coords.x }px`;
                }
            } else {
                labelModalCoordinates.style.top = '0px';
                labelModalCoordinates.style.left = '0px';
            }
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let newLabelModalData = null;

        if (labelModalData) {
            newLabelModalData = {
                ...labelModalData,
                value: event.target.value,
            };
        }

        setLabelModalData(newLabelModalData);
    };

    const handleApply = () => {
        onApplyLabelModalData(labelModalData?.value ?? '');
        closeLabelModal();
    };

    useEffect(() => {
        checkLabelModalCoords();
    }, [ labelModalData ]);

    const modalBody = () => {
        const inputValue = labelModalData?.value !== null && labelModalData?.value !== undefined
            ? labelModalData?.value
            : '';

        return (
            <div className={ cx(styles.axisForm) }>
                <Input
                    maxLength={ maxLength }
                    value={ inputValue }
                    onChange={ handleChange }
                />
                <Button
                    color='primary'
                    onClick={ handleApply }
                >
                    <FontAwesomeIcon icon='check' />
                </Button>
                <Button
                    outline
                    color='white'
                    onClick={ closeLabelModal }
                >
                    <FontAwesomeIcon icon='times' />
                </Button>
                {
                    additionalButton
                    && <ButtonComponent
                        buttonProps={{
                            disabled: additionalButton.disabled,
                            color: additionalButton.color,
                            outline: additionalButton.outline,
                            onClick: additionalButton.onCLick,
                        }}
                        icon={ additionalButton.icon }
                        tooltipProps={{
                            value: t('resetCircleCenterText'),
                            force: true,
                        }}
                    />
                }
            </div>
        );
    };

    return <>
        <Portal id='chart_label_modal'>
            <div
                id={ chartTarget }
                ref={ labelModalCoordinatesRef }
                className={ styles.labelModalCoords }
            />
        </Portal>

        {
            labelModalData?.target
            && <>
                {
                    isControlledPositionTooltip
                        ? <ControlledTooltip
                            isOpen={ labelModalData.open }
                            target={ labelModalData.target }
                            className={ styles.controlledTooltipWrapper }
                            tooltipContent={ modalBody() }
                            placement={ placement }
                            onClose={ closeLabelModal }
                        />
                        : <Popover
                            isOpen
                            target={ labelModalData.target }
                            trigger='legacy'
                            placement='auto'
                            boundariesElement='window'
                            toggle={ closeLabelModal }
                        >
                            <PopoverBody>
                                { modalBody() }
                            </PopoverBody>
                        </Popover>
                }
            </>
        }
    </>;
};
