import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

export const VocTableCheckboxHeader = ({ addToSelected, data }) => {
    function isSelected() {
        const hasTableData = data.length;
        const dataSelectedLength = data.filter(({ checkbox }) => checkbox.disabled || checkbox.selected).length;

        return hasTableData && dataSelectedLength === data.length;
    }

    function onChange({ target }) {
        const { checked } = target;
        const filteredData = checked ? filterDataToSelected(data) : filterDataToUnSelected(data);

        addToSelected({ checked, data: filteredData });
    }

    function filterDataToSelected(data) {
        return data.reduce((acc, { checkbox }) => checkbox.selected || checkbox.disabled ? acc : [ ...acc, checkbox.item ], []);
    }

    function filterDataToUnSelected(data) {
        return data.reduce((acc, { checkbox }) => checkbox.disabled ? [ ...acc ]
            : [ ...acc, checkbox.item.id ? checkbox.item.id : checkbox.item ], []);
    }

    return (
        <Input
            type={ 'checkbox' }
            className='export-results__checkbox form-check-input'
            checked={ isSelected() }
            onChange={ onChange }
        />
    );
};

VocTableCheckboxHeader.propTypes = {
    addToSelected: PropTypes.func,
    data: PropTypes.array,
};

VocTableCheckboxHeader.defaultProps = {};
