import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const WarningLabs = function() {
    return (
        <Row>
            <Col lg={{ size: 10, offset: 1 }}>
                <Alert color="warning">
                    <div className="text-center">
                        <FontAwesomeIcon icon="cog"/>
                        <strong>Labs usage warning</strong>
                    </div>
                    <p>
                        All functionality provided in Labs is demo, preview,
                        alpha - you can choose the word, but the idea is that it may (and sometimes will):
                    </p>
                    <ul>
                        <li>Crash at random points of time"</li>
                        <li>Perform slowly"</li>
                        <li>Behave weird</li>
                        <li>Do not work at all</li>
                    </ul>
                    <p>
                        However, it will give you insight of what cool things SandSIV is about to deliver
                    </p>
                    <p>
                        Hang tight!
                    </p>
                </Alert>
            </Col>
        </Row>
    );
};
