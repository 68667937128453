import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SettingFieldContainer } from '../components';
import { MarkupEditor } from '/feedback/components';

export * from './SettingHtmlField.scss';

const defaultHeight = '300px';

export class SettingHtmlField extends Component {
    state = {
        height: defaultHeight,
        expand: false,
    };

    @autobind
    setHeight(value) {
        if(value !== this.state.height) {
            try {
                this.setState(prevState => ({
                    ...prevState,
                    height: value,
                }));
            } catch (e) {
                console.error('-- setHeight --', e);
            }
        }
    }

    @autobind
    toggleExpand() {
        this.setState(prevState => ({
            ...prevState,
            expand: !prevState.expand,
        }));
    }

    @autobind
    getValue() {
        return this.props.input.value.value;
    }

    @autobind
    onChange(val) {
        const {
            input: {
                onChange,
                value,
            },
        } = this.props;

        const model = {
            ...value,
            value: val,
        };

        onChange(model);
    }

    @autobind
    onFocus() {
        this.props.input.onFocus(this.props.input.value);
    }

    @autobind
    onBlur() {
        this.props.input.onBlur(this.props.input.value);
    }

    render() {
        const {
            input,
            meta: {
                dirty,
            },
            label,
            input: {
                value: {
                    redefined,
                },
            },
            assets,
            getAssets,
            uploadAsset,
            additionalPlugin,
        } = this.props;

        const editorHeight = () => {
            return redefined && this.state.expand
                ? this.state.height
                : defaultHeight;
        };

        const containerHeight = () => redefined ? `${ parseInt(editorHeight()) + 37 }px` : 'unset';

        return (
            <>
                <label className={ 'settings-field__input-label' } htmlFor={ input.name }>{ label }</label>
                <SettingFieldContainer
                    checked={ redefined }
                    className={ `${ dirty ? 'changed' : '' }` }
                    field={ input }
                >
                    <div className={ 'setting-css__container' }
                        style={{
                            height: containerHeight(),
                        }}
                    >
                        <div className={ 'setting-html__container' }>
                            <MarkupEditor
                                additionalPlugin={ additionalPlugin }
                                height={ editorHeight() }
                                value={ this.getValue() }
                                onChange={ this.onChange }
                                onFocus={ this.onFocus }
                                onBlur={ this.onBlur }
                                disabled={ !redefined }
                                assets={ assets }
                                getAssets={ getAssets }
                                uploadAsset={ uploadAsset }
                                resizeCallback={ this.setHeight }
                                baseHeight={ '230px' }
                            />
                            <Button
                                onClick={ this.toggleExpand }
                                className={ 'setting-expand__button btn button__expand' }
                                color={ 'white' }
                            >
                                <FontAwesomeIcon
                                    icon={ this.state.expand ? 'compress-alt' : 'expand-arrows-alt' }
                                    className={ 'setting-css__button-icon' }
                                />
                            </Button>
                        </div>
                    </div>
                </SettingFieldContainer>
            </>
        );
    }
}
