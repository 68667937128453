import React from 'react';
import { CollapseSection } from '/components';
import { RenderCollapseBody, HeaderHandler } from './components';
import './QueryFilterCollapse.scss';

export const QueryFilterCollapse = ({
    changeCollapseState,
    collapseStates,
    options,
    handlerChange,
    handlerDelete,
    handlerDeleteFilter,
    queryAttributes,
    changeCondition,
    source,
}) => {
    const getHeaderHandler = (id, initialValues, withoutCondition) => {
        return (
            <HeaderHandler
                changeCondition={ changeCondition }
                initialValues={ initialValues }
                id={ id }
                handlerDelete={ handlerDeleteFilter }
                withoutCondition={ withoutCondition }
            />
        );
    };

    return (
        options.map(({ initialValues, id, withoutCondition, name }, inx) => (
            initialValues
                ? <CollapseSection
                    onCollapseClose={ () => changeCollapseState(id) }
                    collapse={ collapseStates[id] }
                    key={ inx }
                    className={ 'mine-filter-collapse' }
                    title={ `${ name }` }
                    handlerElement={ getHeaderHandler(id, initialValues, withoutCondition) }
                >
                    <RenderCollapseBody
                        initialValues={ initialValues }
                        queryAttributes={ queryAttributes }
                        handlerChange={ handlerChange }
                        handlerDelete={ handlerDelete }
                        source={ source }
                        name={ id }
                    />
                </CollapseSection>
                : null
        ),
        )
    );
};
