import React, { Fragment } from 'react';
import { CollapseSection } from '/components';

import './StatisticResultsInfoSection.scss';

export const StatisticResultsInfoSection =({ channel }) => (
    <CollapseSection
        title = { 'Info Section' }
    >
        <Fragment>
            <div className={ `statistic_result__info-block ${ channel === 'DIGI' ? 'visible' : 'hidden' }` }>
                <p>
                    <span className={ 'statistic_result__info-title' }>Visited</span> - the cumulative number of website or
                    web app
                    visitors where this DIGI survey is used (where DIGI RS Script is injected).
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Triggered</span> - the cumulative number of visitors
                    whom DIGI
                    pop-up window or survey activate button was shown. Actually, following formula
                    should be met
                </p>
                <p>
                    Triggered = "online" triggered + Started + Completed + Terminated + Partial +
                    Expired.
                    "online" triggered - means triggered right now, in this particular moment.
                    Triggered box shows the cumulative sum of questionnaires has ever been in status
                    "online" triggered.
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Opened</span> - the ratio of Triggered to Visited (in
                    %).
                </p>
                <p>Opened = Triggered / Visited * 100</p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Started</span> - the state when the Customer started
                    to answer the
                    questionnaire and at least one response were provided. This box shows the "online"
                    number of
                    the questionnaire in this state. The only questionnaires with "step by step" survey
                    option
                    turned on can be in this state.
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Completed</span> - the cumulative number of completely
                    fulfilled
                    questionnaires by Customers. When the Customer clicked on "submit" button and all
                    mandatory
                    questions from the questionnaire are answered - questionnaire state will be changed
                    to
                    "Completed".
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Terminated</span> - the cumulative number of
                    questionnaires where
                    pop-up windows were closed by Customers (DIGI popup was closed by clicking on a
                    "Close"
                    icon) and there wasn't any answered question.
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Partial</span> - the cumulative number of
                    questionnaires that are
                    not finished by Customers (with at least one answered question in it) and
                    automatically
                    moved to this state when Customer clicked on "Close icon" or moved by automatically
                    scheduled process (called "Housekeeper") to this state (after some specified
                    "lifetime"
                    period of the questionnaire has expired).
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Expired</span> - the cumulative number of
                    questionnaires that are
                    not actual anymore. Usually, the questionnaire is moved to this state by
                    "Housekeeper"
                    process only from "triggered" state when the expiration period is met (questionnaire
                    "lifetime" period is over).
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Blacklisted</span> - the cumulative number of
                    recipients who were unsubscribed from receiving the selected survey.
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Quarantined</span> - the cumulative number of
                    questionnaires that fell under the quarantine rules selected on the survey.
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Response Rate</span> - The ratio of Triggered to Completed (in %).
                </p>
                <p>Response rate = (Completed)/(Invited + Started + Completed + Terminated + Partial + Expired) * 100 (and sum rounded to the second decimal place)
                </p>
                <p>
                    <span className={ 'statistic_result__info-title' }>Response Rate with partial</span> -
                    The ratio of Completed, Terminated, Started, and Partial to Triggered (in %).
                </p>
                <p>
                    Response rate with partial = (Completed + Termininated + Started + Partial)/(Triggered) * 100 (and sum rounded to the second decimal place)
                </p>
            </div>
            <div className={ `statistic_result__info-block ${ channel !== 'DIGI' ? 'visible' : 'hidden' }` }>
                <p>
                    <span className={ 'statistic_result__info-title' }>Imported</span> -
                    the cumulative number of successfully imported Customers into the channel specific
                    Survey [WEB or SMS or IVR]. For all successfully imported Customers, questionnaires
                    will be created automatically (internal state of a questionnaire - "Created").
                    Customers will not be imported in case they are blacklisted or quarantined. When the
                    survey will be launched and Customer will deal with the questionnaire, it's status
                    going to be changed accordingly to the current state of survey passing by Customer.
                </p>
                <p>There is expected questionnaire flow:</p>
                <p>
                    Imported -> Created ->Invited -> Started -> Completed / Terminated / Partial /
                    Expired
                </p>
                <p>
                    Imported box shows a cumulative sum of all questionnaires has ever been in status
                    "Created".
                </p>
                <p>
                    Imported = Created + Invited + Started + Completed +Terminated + Partial + Expired
                </p>
                <p>
                    <span className={ 'statistic_result__info-title' }>Invited</span> - the current
                    "online" number of questionnaires in the state "Invited". After Survey has been
                    started (Survey switched to the "Active" state), Invitations to participate in
                    Survey has to be sent to Customers by their addresses. When the Invitation to
                    Customer successfully sent, the questionnaire state has to be changed to "Invited".
                </p>
                <p>
                    <span className={ 'statistic_result__info-title' }>Opened</span> - The ratio of
                    Customers opened invitation email to Imported (in %). This calculation uses "pixel
                    tracking" technology and can't be absolutely accurate (only for WEB).
                </p>
                <p>
                    Opened = all questionnaires where start_time is present + all
                    questionnaires where read_state is present and start_time is absent = ROUND(x,2)
                </p>
                <p>Opened >= (Started + Completed + Terminated + Partial + Expired ) / Imported * 100</p>
                <p>
                    <span className={ 'statistic_result__info-title' }>Started</span> - the "online" number of questionnaires
                    in the
                    ACTIVE state.
                </p>
                <p>When the questionnaire invite is sent to the Customer and the Customer:</p>
                <ul>
                    <li>WEB: clicks the link</li>
                    <li>SMS: answers with the response/trigger word</li>
                    <li>IVR: answers the call</li>
                </ul>
                <span>the questionnaire is then considered to be Started.</span>
                <p>
                    <span className={ 'statistic_result__info-title' }>Completed</span> - the "online" number of
                    questionnaires in the
                    state "Completed".
                </p>

                <p>
                    When the customer has completed the survey either by answering all the questions or
                    by
                    hitting the "Finish Survey" type of question within the question flow, the
                    questionnaire is
                    considered Completed.
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Terminated</span> - the cumulative number of
                    questionnaires in the
                    state "Terminated".
                </p>

                <p>If one or more of these situations are triggered:</p>

                <ul>
                    <li>the survey is using Terminator type of questions and during the question flow
                        one of
                        them is reached by the user
                    </li>
                    <li>the "Terminate" button (can be renamed) is enabled and the user clicked it</li>
                    <li>
                        the question flow is set to terminate the survey after some condition is met and
                        that
                        condition has been triggered
                    </li>
                    <li>the survey is then considered to be Terminated.</li>
                </ul>

                <p>
                    <span className={ 'statistic_result__info-title' }>Partial</span> - the cumulative number of
                    questionnaires in the
                    state "Partial (Aborted)". If there is at least one question answered and the
                    expiration
                    date hits then the questionnaire is considered "Partial"
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Expired</span> - the cumulative number of
                    questionnaires in the
                    state "Expired". If the questionnaire expiration date hits and the survey doesn't
                    have any
                    questions answered at that point in time, then it's considered "Expired".
                </p>
                <p>
                    <span className={ 'statistic_result__info-title' }>Response Rate</span>
                    <span> - The ratio of Completed</span>
                    <span className={ `${channel !== 'IVR'? 'visible': 'hidden'}` }> and Terminated</span>
                    <span> to Imported (in %).</span>
                </p>

                <p className={ `${channel === 'IVR'? 'visible': 'hidden'}` }>
                    Response rate = Completed / Imported * 100
                </p>
                <p className={ `${channel !== 'IVR'? 'visible': 'hidden'}` }>
                    Response rate = (Completed + Terminated) / Imported * 100
                </p>

                <p>
                    <span className={ 'statistic_result__info-title' }>Response Rate with partial</span>
                    <span> - The ratio of Completed</span>
                    <span className={ `${channel !== 'IVR'? 'visible': 'hidden'}` }>, Terminated</span>
                    <span>and Partial to Imported (in %).</span>
                </p>
                <p>
                    <span>Response rate with partial = (Completed + Partial</span>
                    <span className={ `${channel !== 'IVR'? 'visible': 'hidden'}` }> + Terminated</span>
                    <span>)/ Imported * 100</span>
                </p>
            </div>
        </Fragment>
    </CollapseSection>
);
