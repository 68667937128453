import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';

import { VocTableV2 } from '/components';
import { HelperService } from '/services';
import { ExportResultsTableToolbar } from '../ExportResultsTableToolbar/';
import { ExportResultsTableCheckbox } from '../ExportResultsTableCheckbox/';
import { ExportResultsRate, ExportResultsRatePopover } from "../";

export class ExportResultsTable extends Component {
    state = {
        rateModalTarget: null,
        rateModalData: null,
        rateModalShow: false,
    };

    settings = [
        {
            field: 'checkbox',
            title: '',
            formatter: this.checkboxFormatter,
            sort: false,
            style: {
                textAlign: 'center',
                width: '30px',
            },
        },
        {
            field: 'id',
            title: 'ID',
            sort: true,
            style: {
                width: '60px',
            },
        },
        {
            field: 'name',
            title: 'Survey',
            formatter: 'conditionTooltip',
            sort: true,
            style: {
                width: '107px',
            },
        },
        {
            field: 'started',
            title: 'Started',
            sort: false,
            style: {
                textAlign: 'center',
                width: '65px',
            },
        },
        {
            field: 'completed',
            title: 'Completed',
            sort: false,
            style: {
                textAlign: 'center',
                width: '90px',
            },
        },
        {
            field: 'terminated',
            title: 'Terminated',
            sort: false,
            style: {
                textAlign: 'center',
                width: '90px',
            },
        },
        {
            field: 'aborted',
            title: 'Partial',
            sort: false,
            style: {
                textAlign: 'center',
                width: '60px',
            },
        },
        {
            field: 'expired',
            title: 'Expired',
            sort: false,
            style: {
                textAlign: 'center',
                width: '69px',
            },
        },
        {
            field: 'blacklisted',
            title: 'Blacklisted',
            sort: false,
            style: {
                textAlign: 'center',
                width: '86px',
            },
        },
        {
            field: 'quarantined',
            title: 'Quarantined',
            sort: false,
            style: {
                textAlign: 'center',
                width: '94px',
            },
        },
        {
            field: 'response_rate',
            title: 'Response rate',
            formatter: this.responseRateFormatter,
            sort: false,
            style: {
                textAlign: 'center',
                width: '80px',
            },
        },
    ];

    @autobind
    getTableSettings() {
        return [
            ...this.settings.slice(0, 3),
            ...this.getDynamicSettings(),
            ...this.settings.slice(3),
        ];
    }

    @autobind
    getDynamicSettings() {
        if (this.props.filters.channel === 'DIGI') {
            return [
                {
                    field: 'visited',
                    title: 'Visited',
                    sort: false,
                    style: {
                        textAlign: 'center',
                        width: '64px',
                    },
                },
                {
                    field: 'triggered',
                    title: 'Triggered',
                    sort: false,
                    style: {
                        textAlign: 'center',
                        width: '77px',
                    },
                },
            ];
        } else {
            return [
                {
                    field: 'imported',
                    title: 'Imported',
                    sort: false,
                    style: {
                        textAlign: 'center',
                        width: '75px',
                    },
                },
                {
                    field: 'invited',
                    title: 'Invited',
                    sort: false,
                    style: {
                        textAlign: 'center',
                        width: '65px',
                    },
                },
            ];
        }
    }

    createCustomToolBar() {
        const {
            filters: {
                start_time,
                end_time,
                search,
                channel,
                range,
            },
            selectedList,
            removeFromSelected,
            changeShowDisabledExports,
            onSearchType,
            possibleRanges,
        } = this.props;

        const dates = {
            from: new Date(start_time),
            to: new Date(end_time),
        };

        const channels = HelperService.getPossibleChannels()
            .map(channel => ({ label: channel, value: channel }));

        return (
            <ExportResultsTableToolbar
                dateRangeHandler={ this.dateRangeHandler }
                changeChannel={ this.changeChannel }
                changeRange={ this.changeRange }
                onSearchType={ onSearchType }
                changeShowDisabledExports={ changeShowDisabledExports }
                selectedList={ selectedList }
                rangeTypes={ possibleRanges }
                removeFromSelected={ removeFromSelected }
                dates={ dates }
                search={ search }
                channel={ channel }
                range={ range }
                channels={ channels }
            />
        );
    }

    @autobind
    changeRange({ value }) {
        this.props.onRangeChanged(value);
    }

    @autobind
    dateRangeHandler(date) {
        this.props.onDatesChanged(date);
    }

    @autobind
    changeChannel({ value }) {
        this.props.changeChannel(value);
    }

    @autobind
    checkboxFormatter(cell) {
        return <ExportResultsTableCheckbox
            { ...cell }
            addToSelected={ this.props.addToSelected }
            removeFromSelected={ this.props.removeFromSelected }
        />;
    }

    @autobind
    responseRateFormatter(cell, data) {
        return <ExportResultsRate id={ data.id } responseRate={ cell } openModal={ this.responseRateModalOpen }/>;
    }

    @autobind
    responseRateModalOpen(rateModalTarget, rateModalData) {
        this.setState({
            rateModalTarget,
            rateModalData,
            rateModalShow: true,
            openNext: this.state.rateModalTarget !== null,
        });
    }

    @autobind
    responseRateModalToggle() {
        this.setState({
            openNext: !this.state.openNext,
            ...!this.state.openNext
                ? {
                    rateModalData: null,
                    rateModalTarget: null,
                    rateModalShow: false,
                }
                : {},
        });
    }

    getTableOptions() {
        return {
            customTableHeader: this.createCustomToolBar(),
            requestTrigger: this.props.requestTrigger,
            updateFilters: this.props.updateFilters,
        };
    }

    render() {
        const {
            loading,
            total,
        } = this.props.tableState;

        const {
            rateModalTarget,
            rateModalData,
            rateModalShow,
        } = this.state;

        return (
            <>
                <VocTableV2
                    dataSettings={ this.getTableSettings() }
                    data={ this.props.data }
                    options={ this.getTableOptions() }
                    filters={ this.props.filters }
                    total={ total }
                    loading={ loading }>
                </VocTableV2>
                {
                    rateModalTarget
                    && <ExportResultsRatePopover
                        target={ rateModalTarget }
                        listData={ rateModalData }
                        show={ rateModalShow }
                        toggle={ this.responseRateModalToggle }
                    />
                }
            </>
        );
    }
}

ExportResultsTable.propTypes = {
    requestTrigger: PropTypes.func,
    updateFilters: PropTypes.func,
    onDatesChanged: PropTypes.func,
    changeChannel: PropTypes.func,
    addToSelected: PropTypes.func,
    removeFromSelected: PropTypes.func,
    selectedList: PropTypes.array,
    data: PropTypes.array,
    tableState: PropTypes.shape({
        dataTotalSize: PropTypes.number,
        page: PropTypes.number,
        sizePerPage: PropTypes.number,
        loading: PropTypes.bool,
    }),
};
