import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

export const RangeDatePickerV2 = ({
    maxDate,
    startDate,
    endDate,
    separator,
    apply,
}) => {
    const [ startD, setStartD ] = useState(null);
    const [ endD, setEndD ] = useState(null);
    const [ isStartD, setIsStartD ] = useState(true);

    useEffect(() => {
        setStartD(startDate && endDate ? new Date(startDate) : null);
        setEndD(startDate && endDate ? new Date(endDate) : null);
        return () => {
            setStartD(null);
            setEndD(null);
            setIsStartD(true);
        };
    }, []);

    const handleChange = date => {
        const valueIsMoment = moment(date);
        const newDate = formatDate(valueIsMoment);

        if (isStartD) {
            setStartD(newDate);
            setEndD(null);
        } else if (startD < newDate) {
            setEndD(newDate);
            apply([ startD, newDate ]);
        } else {
            setEndD(startD);
            setStartD(newDate);
            apply([ newDate, startD ]);
        }

        setIsStartD(!isStartD);
    };

    const formatDate = value => {
        return separator ? value.format(`YYYY${ separator }MM${ separator }DD`) : value.format('YYYY-MM-DD') + ' 00:00:00';
    };

    return (
        <DatePicker
            selected={ startD ? moment(startD || new Date()).toDate() : null }
            startDate={ startD ? moment(startD || new Date()).toDate() : null }
            endDate={ endD ? moment(endD || new Date()).toDate() : null }
            maxDate={ moment(maxDate || new Date()).toDate() }
            onChange={ handleChange }
            selectsStart
            selectsEnd
            inline
        />
    );
};

RangeDatePickerV2.defaultProps = {
    maxDate: null,
    startDate: null,
    endDate: null,
};
