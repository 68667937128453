import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { VocFormSelectField, VocFormTextField, VocFormNumberField } from '/components';
import iconCross from '/images/icon-cross.svg';
import { exception } from '/feedback/modules/VocFeedback.selectors';

export const renderConditionFlow = ({ fields, flowOptions, changeCondition }) =>
    fields.map((field, index) => {
        const { expression, mathExpression, surveyExpression, blocked_by } = flowOptions[index];
        const fieldValue = fields.get(index);

        const getPropsByType = (() => {
            let options = {
                options: surveyExpression,
                placeholder: 'Fill value in days',
                component: VocFormNumberField,
            };

            if(!fieldValue.blocked_by) return options;

            if(fieldValue.blocked_by.condition_type === 'metadata' || fieldValue.blocked_by.__isNew__) {
                options = {
                    options: expression,
                    placeholder: 'Fill value',
                    component: VocFormTextField,
                };
            }

            if(fieldValue.blocked_by.condition_type === 'random_100') {
                options = {
                    options: mathExpression,
                    placeholder: 'Fill in a value from 1 to 100',
                    component: VocFormNumberField,
                };
            }

            return options;
        })();

        const isCriteriaEmpty = !fieldValue.blocked_by;

        const isEmpty = !fieldValue.expression || exception.includes(fieldValue.expression.value);

        return (
            <Row key={ index } className='condition-row__box'>
                <Col md={ 11 }>
                    <Row className='condition__row'>
                        <Col md={ 4 }>
                            <Field
                                name={ `${ field }.blocked_by` }
                                component={ VocFormSelectField }
                                options={ blocked_by }
                                placeholder={ 'Select' }
                                onChange={ el => changeCondition(index, el, 'blocked_by') }
                                required
                                portal
                                isCreatable
                            />
                        </Col>
                        <Col md={ 4 }>
                            <Field
                                name={ `${ field }.expression` }
                                component={ VocFormSelectField }
                                disabled={ isCriteriaEmpty }
                                options={ getPropsByType.options }
                                placeholder={ 'Select' }
                                onChange={ el => changeCondition(index, el, 'expression') }
                                required
                                portal
                            />
                        </Col>
                        <Col md={ 4 }>
                            <Field
                                type={ 'text' }
                                placeholder={ getPropsByType.placeholder }
                                name={ `${ field }.matching_value` }
                                defaultValue={ '' }
                                disabled={ isCriteriaEmpty || isEmpty }
                                component={ getPropsByType.component }
                                required={ !isEmpty }
                                withError={ !isEmpty }
                            />
                        </Col>
                    </Row>

                </Col>

                <Col md={ 1 }>
                    <span onClick={ () => fields.remove(index) } className='condition-flow__icon'>
                        <img src={ iconCross } alt=""/>
                    </span>
                </Col>
            </Row>
        );
    },
    );
