import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VocButtonDropdown, ButtonsPanelSelector, ShareRightsModal } from '/components';
import { QueryExecuteService } from '/services/QueryExecuteService';
import {
    selectedDictionariesSelector, needRunFormSelector,
    zeroShotExportDataSelector, chartTypesSelector,
} from '/query/modules/query.selectors';
import { ExportModal } from '../';

import './QueryDetailsHeader.scss';

const optionsDropdown = [
    {
        name: 'Export to Excel',
        header: true,
    },
    {
        id: 'toText',
        name: 'Export texts',
        title: 'Select items to export',
        disabled: false,
    },
    {
        id: 'toLemmas',
        name: 'Export lemmas',
        title: 'Export lemmas',
        disabled: false,
    },
    {
        id: 'toZeroShot',
        name: 'Export data labelling',
        title: 'Export data labelling',
        disabled: false,
    },
    {
        name: 'Export to Сlassify',
        header: true,
    },
    {
        id: 'forTraining',
        name: 'Export for training',
        title: 'Export for training',
    },
    // {
    //     id: 'toVerification',
    //     name: 'Export for verification',
    //     title: 'Export for verification',
    // },
];

export const QueryDetailsHeader = ({
    isLoadingData,
    language,
    onChangeChartType,
    chartType,
    members,
    queryId,
    queryList,
    saveMineRights,
    updateShares,
    getClassifierList,
    classifiers,
    submitExport,
    filters,
    textCases,
    shares,
    getSharedRights,
    isDataLabelling,
}) => {
    const initialSelectedItems = useSelector(selectedDictionariesSelector);
    const needRunForm = useSelector(needRunFormSelector);
    const zeroShotExportData = useSelector(zeroShotExportDataSelector);
    const chartTypeList = useSelector(chartTypesSelector(chartType));
    const filterOptionsDropdown = optionsDropdown.filter(option =>
        !zeroShotExportData.labels?.length ? option.id !== 'toZeroShot' : true);

    const synonyms = QueryExecuteService.convertObjToArray(initialSelectedItems.synonyms);
    const stopWords = QueryExecuteService.convertObjToArray(initialSelectedItems.stopWords);

    const [ displayInviteModal, setDisplayInviteModal ] = useState(false);
    const [ displayExportModal, setDisplayExportModal ] = useState(false);
    const [ exportType, setExportType ] = useState({});
    const userPermission = queryList[0].userPermission || 'view';

    const handleVisualisation = value => {
        onChangeChartType(value);
    };

    const toggleDisplayInviteModal = () => {
        !displayInviteModal && getSharedRights(queryId, 'query');
        setDisplayInviteModal(!displayInviteModal);
    };

    const toggleDisplayExportModal = exportType => {
        const openModalTypes = [ 'toLemmas', 'toZeroShot' ];

        if (!openModalTypes.includes(exportType)) {
            exportType && setExportType(filterOptionsDropdown.find(option => option.id === exportType));
            setDisplayExportModal(!displayExportModal);
        } else {
            submitExport({ exportType, filters, synonyms, stopWords, queryId, language, zeroShotExportData });
        }
    };

    const onSubmitInvite = body => {
        saveMineRights(body).promise
            .then(() => {
                toggleDisplayInviteModal();
                updateShares(body.shares);
            });
    };

    return (
        <header className={ 'query-details-header' }>
            <div className="query-details-header__button-container_right">
                {
                    ![ 'view', 'edit' ].includes(userPermission)
                    && <Button onClick={ toggleDisplayInviteModal }>
                        <FontAwesomeIcon icon={ 'share' }/> Share
                    </Button>
                }
                <VocButtonDropdown
                    disabled={ isLoadingData && chartType === 'insightNarrator' }
                    options={ filterOptionsDropdown.map(el => {
                        return { ...el, disabled: isDataLabelling ? false : textCases.count === 0 };
                    }) }
                    placeholder={ 'Export' }
                    icon={ 'file-export' }
                    handleClick={ toggleDisplayExportModal }
                />
            </div>
            <ButtonsPanelSelector
                options={ chartTypeList }
                onChange={ handleVisualisation }
                value={ chartType }
            />
            <ShareRightsModal
                show={ displayInviteModal }
                close={ toggleDisplayInviteModal }
                onSubmit={ onSubmitInvite }
                queryId={ queryId }
                members={ members }
                shares={ shares }
            />
            <ExportModal
                show={ displayExportModal }
                close={ toggleDisplayExportModal }
                onClosed={ () => setExportType({}) }
                onSubmit={ submitExport }
                exportType={ exportType }
                getClassifierList={ getClassifierList }
                classifiers={ classifiers }
                queryId={ queryId }
                filters={ filters }
                synonyms={ synonyms }
                stopWords={ stopWords }
                language={ language }
                needRunForm={ needRunForm }
                textCases={ textCases }
            />
        </header>
    );
};
