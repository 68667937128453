import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { TooltipWrapper } from "/components";
import { IDashboard } from "/visual/models";

import styles from "./style.module.scss";

interface props {
    dashboard: IDashboard;
}

export const DashboardsTableTitle = ({ dashboard }: props) => {
    const { t } = useTranslation();
    const rootPath = '/visualV2';

    return (
        <div className={ styles.titleWrap }>
            <TooltipWrapper value={ dashboard.title }>
                <Link className={ styles.textWrap } to={ `${rootPath}/dashboard/${dashboard.id}` }>
                    { dashboard.title }
                </Link>
            </TooltipWrapper>
            {
                dashboard.isDefault
                && <div className={ styles.defaultLabel }>
                    <span>{ t('default') }</span>
                </div>
            }
        </div>
    );
};
