import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import { TabPane, TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TinyEditor, Editor } from '/components';
import { HelperService } from '/services';

import './LayoutEditor.scss';

export class LayoutEditor extends Component {
    state = {
        activeTab: 1,
    };

    componentDidUpdate(nextProps) {
        if (nextProps.disabled !== this.props.disabled) {
            this.setState({
                activeTab: 1,
            });
        }
    }

    @autobind
    togglePane(tab) {
        this.setState({
            activeTab: tab,
        });

        this.props.onChange(HelperService.parseHtmlString(this.props.value, tab !== 1));
    }

    render() {
        const {
            activeTab,
        } = this.state;

        const {
            value,
            onChange,
            onBlur,
            onFocus,
            disableSubmit,
            disabledLayoutTab,
            validationCallback,
            assets,
            getAssets,
            uploadAsset,
        } = this.props;

        return (
            <Fragment>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={ `markup-editor__pane-header ${ activeTab === 1 ? 'active' : null }` }
                            onClick={ () => this.togglePane(1) }
                            disabled={ disabledLayoutTab }
                        >
                            <FontAwesomeIcon className={ 'markup-editor__pane-icon' } icon={ 'columns' }/>
                            <span>Preview</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={ `markup-editor__pane-header ${ activeTab === 2 ? 'active' : null }` }
                            onClick={ () => this.togglePane(2) }
                        >
                            <FontAwesomeIcon className={ 'markup-editor__pane-icon' } icon={ 'code' }/>
                            <span>Edit HTML</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={ activeTab }
                    className={ `markup-editor__tabs` }>

                    <TabPane tabId={ 1 } className={ 'layout-editor__tab-size' }>
                        {
                            activeTab === 1
                            && <TinyEditor
                                value={ value }
                                onChange={ onChange }
                                assets={ assets }
                                getAssets={ getAssets }
                                uploadAsset={ uploadAsset }
                                height={ '100%' }
                            />
                        }
                    </TabPane>
                    <TabPane tabId={ 2 } className={ 'layout-editor__tab-size' }>
                        {
                            activeTab === 2
                            && <Editor
                                onChange={ onChange }
                                onBlur={ onBlur }
                                onFocus={ onFocus }
                                value={ value }
                                disableSubmit={ disableSubmit }
                                validationCallback={ validationCallback }
                                height="100%"
                                focus={ true }
                            />
                        }
                    </TabPane>
                </TabContent>
            </Fragment>
        );
    }
}

LayoutEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    disableSubmit: PropTypes.func,
    disabledLayoutTab: PropTypes.bool,
    validationCallback: PropTypes.func,
};

