import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { VocModal, FormFieldItem } from '/components';

export class ThemeModalCopy extends Component {
    state = {
        name: '',
    };

    @autobind
    getModalBody() {
        return (
            <FormFieldItem
                type="text"
                isHorizontal={ true }
                value={ this.state.name }
                label="Name"
                isRequired={ true }
                placeholder="Enter name"
                handleChange={ this.valueChangedInput }
            />
        );
    }

    @autobind
    close() {
        this.props.close();
        this.setState({
            name: '',
        });
    }

    @autobind
    onSubmit() {
        const { name } = this.state;
        const { copyTheme, theme: { digi_theme_tags, ...restTheme } } = this.props;

        const tags = digi_theme_tags.map(tag => tag.name);

        copyTheme({ ...restTheme, name, tags });
        this.close();
    }

    @autobind
    valueChangedInput(event) {
        this.setState({
            name: event.target.value,
        });
    }

    @autobind
    getFooter() {
        return (
            <div>
                <Button onClick={ this.close } color={ 'white' } outline>
                    Cancel
                </Button>
                <Button
                    disabled={ !this.state.name }
                    onClick={ this.onSubmit }
                    color="primary"
                >
                    <FontAwesomeIcon icon={ 'check' }/>
                    <span> Save</span>
                </Button>
            </div>
        );
    }

    render() {
        return (
            <VocModal
                toggle={ this.close }
                className='modal-scrollable'
                header={ 'Copy Theme' }
                isOpen={ this.props.show }
                size={ 'md' }
                footer={ this.getFooter() }
                onClosed={ this.props.reset }
            >
                { this.getModalBody() }
            </VocModal>
        );
    }
}
