import { CALL_API, FEEDBACK } from 'store/middleware/api';

const GET_LAYOUT_LIST_REQUEST = 'GET_LAYOUT_LIST_REQUEST';
const GET_LAYOUT_LIST_SUCCESS = 'GET_LAYOUT_LIST_SUCCESS';
const GET_LAYOUT_LIST_ERROR = 'GET_LAYOUT_LIST_ERROR';

const GET_LAYOUT_REQUEST = 'GET_LAYOUT_REQUEST';
const GET_LAYOUT_SUCCESS = 'GET_LAYOUT_SUCCESS';
const GET_LAYOUT_ERROR = 'GET_LAYOUT_ERROR';

const DELETE_LAYOUT_REQUEST = 'DELETE_LAYOUT_REQUEST';
const DELETE_LAYOUT_SUCCESS = 'DELETE_LAYOUT_SUCCESS';
const DELETE_LAYOUT_ERROR = 'DELETE_LAYOUT_ERROR';

const CREATE_LAYOUT_REQUEST = 'CREATE_LAYOUT_REQUEST';
const CREATE_LAYOUT_SUCCESS = 'CREATE_LAYOUT_SUCCESS';
const CREATE_LAYOUT_ERROR = 'CREATE_LAYOUT_ERROR';

const UPDATE_LAYOUT_REQUEST = 'UPDATE_LAYOUT_REQUEST';
const UPDATE_LAYOUT_SUCCESS = 'UPDATE_LAYOUT_SUCCESS';
const UPDATE_LAYOUT_ERROR = 'UPDATE_LAYOUT_ERROR';

const COPY_LAYOUT_REQUEST = 'COPY_LAYOUT_REQUEST';
const COPY_LAYOUT_SUCCESS = 'COPY_LAYOUT_SUCCESS';
const COPY_LAYOUT_ERROR = 'COPY_LAYOUT_ERROR';

const LAYOUT_FILTERS_CHANGED = 'LAYOUT_FILTERS_CHANGED';
const CLEANUP_LAYOUTS_PAGE = 'CLEANUP_LAYOUTS_PAGE';

function getInitialState() {
    return {
        loading: false,
        results: [],
        filters: {
            page: 1,
            limit: 10,
            search: '',
        },
        count: 0,
    };
}

export function reducer (state = (getInitialState()), action = {}) {
    switch (action.type) {
        case GET_LAYOUT_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_LAYOUT_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...action.response,
            };
        }

        case GET_LAYOUT_LIST_ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case LAYOUT_FILTERS_CHANGED: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case CLEANUP_LAYOUTS_PAGE: {
            return getInitialState();
        }

        default:
            return state;
    }
}

export function getLayoutList(body) {
    return {
        [CALL_API]: {
            endpoint: `/template/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_LAYOUT_LIST_REQUEST, GET_LAYOUT_LIST_SUCCESS, GET_LAYOUT_LIST_ERROR ],
            apiType: FEEDBACK,
            body,
        },
    };
}

export function getLayoutById(id) {
    return {
        [CALL_API]: {
            endpoint: `/template/${id}/`,
            method: 'GET',
            contentType: 'application/json',
            types: [ GET_LAYOUT_REQUEST, GET_LAYOUT_SUCCESS, GET_LAYOUT_ERROR ],
            apiType: FEEDBACK,
        },
    };
}

export function deleteLayout(id) {
    return {
        [CALL_API]: {
            endpoint: `/template/${ id }/`,
            method: 'DELETE',
            contentType: 'application/json',
            types: [ DELETE_LAYOUT_REQUEST, DELETE_LAYOUT_SUCCESS, DELETE_LAYOUT_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Layout was successfully deleted',
                error: 'Something went wrong',
            },
        },
    };
}

export function processLayout(body) {
    return dispatch => {
        if (body.id) {
            return dispatch(updateLayout(body));
        } else {
            return dispatch(createLayout(body));
        }
    };
}

export function createLayout(body) {
    return {
        [CALL_API]: {
            endpoint: `/template/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ CREATE_LAYOUT_REQUEST, CREATE_LAYOUT_SUCCESS, CREATE_LAYOUT_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Layout was successfully created',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function updateLayout(body) {
    return {
        [CALL_API]: {
            endpoint: `/template/${body.id}/`,
            method: 'PUT',
            contentType: 'application/json',
            types: [ UPDATE_LAYOUT_REQUEST, UPDATE_LAYOUT_SUCCESS, UPDATE_LAYOUT_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Layout was successfully updated',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function copyLayout(body) {
    return {
        [CALL_API]: {
            endpoint: `/template/`,
            method: 'POST',
            contentType: 'application/json',
            types: [ COPY_LAYOUT_REQUEST, COPY_LAYOUT_SUCCESS, COPY_LAYOUT_ERROR ],
            apiType: FEEDBACK,
            logger: true,
            loggerMessages: {
                success: 'Layout was successfully copied',
                error: 'Something went wrong',
            },
            body,
        },
    };
}

export function updateFilters(payload) {
    return {
        type: LAYOUT_FILTERS_CHANGED,
        payload,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_LAYOUTS_PAGE,
    };
}

