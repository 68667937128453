import React, { Component } from 'react';
import { CollapseSection } from '/components/';
import PropTypes from 'prop-types';
import './StatisticItem.scss';

export class StatisticItem extends Component {
    renderAddOn() {
        return (
            <span className={ 'question__responses' }>Total responses: { this.props.responses }</span>
        );
    }

    render() {
        const {
            title,
            collapsible,
        } = this.props;

        return (
            <CollapseSection
                title={ title }
                headerAddOn={ this.renderAddOn() }
                collapseDisable={ !collapsible }
                className={ 'questions-statistic__item' }
            >
                { this.props.children }
            </CollapseSection>
        );
    }
}

StatisticItem.propTypes = {
    title: PropTypes.string,
    responses: PropTypes.number,
    collapsible: PropTypes.bool,
    children: PropTypes.array,
};

StatisticItem.defaultProps = {
    responses: 0,
    collapsible: false,
};
