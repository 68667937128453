import React, { Fragment } from "react";
import cx from "classnames";
import { Badge } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { OutsideAlerter, SwitchControl } from "/components";
import { IAdditionalSelectFiltersProps } from "./models";

import styles from './style.module.scss';

export const AdditionalSelectFilters = ({
    setSelectFilters,
    filters,
    id,
    toogleAdditionalFilters,
    radioFilters,
    surveyFilters,
    disableOutsideClose,
}: IAdditionalSelectFiltersProps) => {
    const { t } = useTranslation();

    const sourceTypeChanged = (event: any) => {
        const { name: type } = event.target;
        const state = [ 'upload', 'all' ].includes(type) ? [] : filters.state;

        setSelectFilters({ filters: { type, state }, id });
    };

    const toogleHiddenUploads = (show_hidden: boolean) => {
        setSelectFilters({ filters: { show_hidden: Number(show_hidden) }, id });
    };

    const onChangeFilterSurvey = (event: any) => {
        const { state } = filters;
        const { name, checked } = event.target;
        const newState = state?.filter(status => status !== name).concat(checked ? [ name ] : []);

        setSelectFilters({ filters: { state: newState }, id });
    };

    const handleClickOutside = ({ target }: any) => {
        if (target.className.includes('active') || disableOutsideClose) return;
        toogleAdditionalFilters({ openedAdditionalFilters: false, id });
    };

    const getControlId = (controlId: string) => `${ id }-${ controlId }`;

    const mapSourceRadioGroup = () => (
        radioFilters.map(({ name, id, label }, key) => (
            <Fragment key={ key }>
                <input className={ styles.additionalFiltersInput }
                    type="radio"
                    name={ name }
                    id={ getControlId(id) }
                    onChange={ sourceTypeChanged }
                    checked={ filters.type === name }
                />
                <label className={ styles.additionalFiltersLabel }
                    htmlFor={ getControlId(id) }
                >
                    { label }
                </label>
            </Fragment>
        ))
    );

    const mapSurveyFilters = () => surveyFilters.map(({ name, color, label, id }, key) => (
        <div className={ styles.additionalFiltersSurvey } key={ key }>
            <input className={ styles.additionalFiltersInput }
                type="checkbox"
                id={ getControlId(id) }
                name={ name }
                onChange={ onChangeFilterSurvey }
                checked={ filters.state?.includes(name) }
            />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            <Badge color={ color } className={ name.toLowerCase() }>
                <label
                    className={ styles.additionalFiltersSurveyLabel }
                    htmlFor={ getControlId(id) }
                >
                    { label }
                </label>
            </Badge>
        </div>
    ));

    return (
        <OutsideAlerter
            handleClickOutside={ handleClickOutside }
            classList={ [ styles.additionalFilters ] }
        >
            <>
                <form className={ styles.additionalFiltersForm }>
                    { mapSourceRadioGroup() }
                </form>

                <div className="row">
                    {
                        filters.type === 'survey'
                        && <div className="form-group">
                            <div className="col-lg-12 control-label">
                                <label className="ng-binding">
                                    { t('surveyStatus') }
                                </label>
                            </div>
                            <div className="col-lg-12 filter-content">
                                { mapSurveyFilters() }
                            </div>
                        </div>
                    }
                    {
                        filters.type === 'upload'
                        && <div className={ cx(styles.showHiddenWrapper, 'col-lg-4') }>
                            <label
                                className={ cx(styles.additionalFiltersLabel, styles.additionalFiltersLabelSwitch) }
                                htmlFor={ `${ id }-showHidden` }
                            >
                                { t('showHiddenUploads') }
                            </label>
                            <SwitchControl
                                name={ `${ id }-showHidden` }
                                value={ Boolean(filters.show_hidden) }
                                type={ 'small' }
                                changeHandler={ toogleHiddenUploads }
                            />
                        </div>
                    }
                </div>
            </>
        </OutsideAlerter>
    );
};
