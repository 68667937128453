import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { createDrillDownDashboard } from '/visual/modules/VocVisual.modules';
import { useVisualNavigation } from '/visual/customHooks';
import { VocModal } from '/components';
import { HelperService } from '/services';

import styles from './style.module.scss';

interface IModalProps {
    show: boolean,
    title?: string;
    gadgetId?: string;
    toggle: () => void;
    cleanupModal?: () => void;
}

export const DrillDownGadgetModal = ({
    show,
    title,
    gadgetId,
    toggle,
    cleanupModal,
}: IModalProps) => {
    const { t } = useTranslation();
    const { goToDashboard } = useVisualNavigation();
    const dispatch = useDispatch();

    const onDrillDownGadget = async () => {
        toggle();

        if (gadgetId && HelperService.checkNotNullOrUndefined(title)) {
            const newDashboard = await dispatch(createDrillDownDashboard({
                byGadgetId: gadgetId,
                dashboardTitle: title as string,
            })).promise;

            goToDashboard(newDashboard.id);
        }
    };

    const getModalFooter = () => (
        <div>
            <Button
                outline
                color='white'
                onClick={ toggle }
            >
                <FontAwesomeIcon icon='times' />
                &nbsp;
                { t('cancel') }
            </Button>
            <Button
                color='primary'
                onClick={ onDrillDownGadget }
            >
                <FontAwesomeIcon icon='check' />
                &nbsp;
                { t('confirm') }
            </Button>
        </div>
    );

    return (
        <VocModal
            size='ms'
            isOpen={ show }
            modalClassName='modal-scrollable'
            header={ t('dashboardDrillDown') }
            toggle={ toggle }
            footer={ getModalFooter() }
            onClosed={ cleanupModal }
        >
            <p className={ styles.modalText }>
                { t('doYouWantCreateDrillDownDashboard') }
            </p>
        </VocModal>
    );
};
