import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

import { TableRightsItem } from './TableRightsItem';
import { Preloader } from '/components';

import './TableRightsColumn.scss';

export const TableRightsColumn = ({
    id,
    getShares,
    shares: {
        loaded,
        usersByRole,
    },
    isPermitViewInfoShare,
}) => {
    const idElement = 'itemRight' + id;
    const offset = usersByRole.Owner && loaded ? '0px, 10px' : '20px, 10px';

    return (
        <>
            <span
                className={ cx(
                    !isPermitViewInfoShare
                        ? 'table-coll__icon-disabled'
                        : 'table-coll__icon-primary',
                ) }
                id={ idElement }
                onClick={ () => isPermitViewInfoShare && getShares(id) }
            >
                <FontAwesomeIcon icon='users' />
            </span>
            <UncontrolledPopover
                trigger="legacy"
                placement="right"
                target={ idElement }
                modifiers={{
                    offset: {
                        offset,
                    },
                }}
            >
                <PopoverBody className={ 'shared-popover__body' }>
                    <div className="shared-popover__wrapper">
                        {
                            usersByRole.Owner && loaded
                                ? Object.keys(usersByRole).map(user => (
                                    <TableRightsItem
                                        key={ user }
                                        usersGroup={ usersByRole[user] }
                                        title={ user }
                                    />
                                ))
                                : <Preloader />
                        }
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </>
    );
};

TableRightsColumn.propTypes = {
    usersByRole: PropTypes.object,
};

TableRightsColumn.defaultProps = {
    id: 0,
    isPermitViewInfoShare: true,
};
