import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { combineReducers } from 'redux';
import { Navigation } from '/components';
import { SendInvitation } from '/feedback/components';
import {
    Questions,
    SurveyCustomers,
    Quarantines,
    StatisticsResults,
    QuestionsStatistics,
    SurveyLanguages,
    SurveyCommonSettings,
    SurveySettings,
    SurveyTimeSlots,
} from './scenes';
import { reducer as questions } from './scenes/Questions/modules/questions.modules';
import { reducer as quarantines } from './scenes/Quarantines/modules/quarantines.modules';
import { reducer as question } from './scenes/Question/modules/question.modules';
import { reducer as surveyCustomers } from './scenes/SurveyCustomers/modules/customers.modules';
import { reducer as statisticsResults } from './scenes/StatisticsResults/modules/statisticsResults.modules';
import { reducer as questionsStatistics } from './scenes/QuestionsStatistics/modules/questionsStatistics.modules';
import { reducer as languageSettings } from './scenes/SurveyLanguages/modules/surveyLanguages.modules';
import { reducer as commonSettings } from './scenes/SurveyCommonSettings/modules/SurveyCommonSettings.modules';
import { reducer as surveySettings } from './scenes/SurveySettings/modules/SurveySettings.modules';
import { reducer as surveyTimeSlots } from './scenes/SurveyTimeSlots/modules/SurveyTimeSlots.modules';
import {
    reducer as surveyCommon,
    getSurveyGroupLanguages,
    switchLanguage,
    createSurvey,
    switchSurvey,
    getSurveysList,
    changeStatus,
    renameSurvey,
    getThemesBySurvey,
    switchTimeSlotType,
    toggleIsUnsubscribeGroup,
    cleanup,
} from './modules/survey.modules';
import { surveysLanguageSelector } from './scenes/Questions/modules/questions.selectors';
import {
    surveyPageLinksSelector,
    renameSurveyLoadingSelector,
    changeStatusLoadingSelector,
    surveysPanelSelector,
    surveyGroupSelector,
    activeLanguageSelector,
    activeTimeSlotTypeSelector,
    rightsSurveySelector,
} from './modules/survey.selectors';
import {
    activeSurveySelector,
    timeSlotDataSelector,
    surveyStatesSelector,
} from '/feedback/modules/VocFeedback.selectors';
import { showToast } from '/scenes/Toasts/modules/Toasts.modules';
import { sendInvite, getSurveysGroupLangSet } from '/feedback/modules/VocFeedback.modules';
import { ChannelsPanel } from './components/ChannelsPanel';
import { showConfirm } from '/scenes/Confirm/modules/confirm.index';

import './Survey.scss';

const mapStateToProps = (state, props) => ({
    pageLinks: surveyPageLinksSelector(props.match.params.surveyGroup),
    surveyGroup: surveyGroupSelector(state),
    surveyLanguages: surveysLanguageSelector(state),
    activeLang: activeLanguageSelector(state),
    states: surveyStatesSelector(state),
    renameLoading: renameSurveyLoadingSelector(state),
    changeStatusLoading: changeStatusLoadingSelector(state),
    dataSurveys: surveysPanelSelector(state),
    activeSurvey: activeSurveySelector(state),
    timeSlotData: timeSlotDataSelector(state),
    timeSlotType: activeTimeSlotTypeSelector(state),
    rightsSurvey: rightsSurveySelector(state),
});

const mapDispatchToProps = {
    sendInvite,
    getSurveyGroupLanguages,
    switchTimeSlotType,
    getThemesBySurvey,
    switchLanguage,
    getSurveysList,
    createSurvey,
    switchSurvey,
    renameSurvey,
    changeStatus,
    showToast,
    showConfirm,
    toggleIsUnsubscribeGroup,
    cleanup,
    getSurveysGroupLangSet,
};

@connect(mapStateToProps, mapDispatchToProps)
export class Survey extends Component {
    state = {
        showSendInvitation: false,
    };

    customersTableRef = React.createRef();
    routes = {};

    componentWillUnmount() {
        this.props.cleanup();
    }

    @autobind
    toggleSendInvitation() {
        this.setState({ showSendInvitation: !this.state.showSendInvitation });
    }

    changeRedirectPath(base, path) {
        return base.charAt(base.length - 1) === '/'
            ? base.slice(0, -1) + path
            : base + path;
    }

    componentDidMount() {
        const { surveyGroup } = this.props.match.params;

        this.props.getSurveyGroupLanguages(surveyGroup).promise
            .then(() => {
                this.checkActiveLang();
            });
    }

    componentDidUpdate(prevProps) {
        const {
            getThemesBySurvey,
            activeSurvey: {
                channel,
                id,
            },
        } = this.props;

        if (prevProps.activeSurvey.channel !== channel && channel === 'DIGI') {
            id && getThemesBySurvey(id);
        }
    }

    checkActiveLang() {
        const {
            activeLang,
            switchLanguage,
            surveyLanguages: { languages },
        } = this.props;

        if (!activeLang && languages.length) {
            const languagesWithText = languages.filter(lang => lang.hasLanguageText);
            const activeLanguage = languagesWithText.length ? languagesWithText[0].language : languages[0].language;

            switchLanguage({ language: activeLanguage });
        }
    }

    @autobind
    sendInvite(data) {
        if (this.props.location.pathname === this.routes.customers) {
            return this.props.sendInvite(data)
                .then(() =>
                    this.customersTableRef.current.props.requestTrigger(),
                );
        } else {
            return this.props.sendInvite(data);
        }
    }

    render() {
        const {
            match, pageLinks, getSurveyGroupLanguages,
            surveyLanguages, switchLanguage, getSurveysList,
            createSurvey, switchSurvey, renameSurvey,
            changeStatus, surveyGroup, states,
            renameLoading, changeStatusLoading, dataSurveys,
            activeSurvey, timeSlotData, switchTimeSlotType,
            timeSlotType, showConfirm, rightsSurvey, toggleIsUnsubscribeGroup,
        } = this.props;

        this.routes = {
            questions: `${ match.url }/questions`,
            quarantines: `/feedback/survey/:surveyGroup(\\d+)/quarantines`,
            customers: `${ match.url }/customers`,
            statisticsResults: `/feedback/survey/:surveyGroup(\\d+)/statistics`,
            questionsStatistics: `/feedback/survey/:surveyGroup(\\d+)/questions_statistics`,
            surveyCommonSettings: '/feedback/survey/:surveyGroup(\\d+)/settings/common',
            surveySettings: '/feedback/survey/:surveyGroup(\\d+)/settings/advanced',
            surveyLanguages: '/feedback/survey/:surveyGroup(\\d+)/settings/language',
            timeSlots: `/feedback/survey/:surveyGroup(\\d+)/settings/time-slots`,
        };

        return (
            <div className={ 'page__wrapper' }>
                <div className="survey-navigation-wrapper">
                    <Navigation
                        navigationInfo={ pageLinks }
                        className="survey-navigation"
                    />
                </div>
                <ChannelsPanel
                    toggleIsUnsubscribeGroup={ toggleIsUnsubscribeGroup }
                    surveyGroupId={ this.props.match.params.surveyGroup }
                    surveyGroup={ surveyGroup }
                    states={ states }
                    renameLoading={ renameLoading }
                    changeStatusLoading={ changeStatusLoading }
                    dataSurveys={ dataSurveys }
                    activeSurvey={ activeSurvey }
                    getSurveyGroupLanguages={ getSurveyGroupLanguages }
                    getSurveysList={ getSurveysList }
                    createSurvey={ createSurvey }
                    switchSurvey={ switchSurvey }
                    renameSurvey={ renameSurvey }
                    routes={ this.routes }
                    changeStatus={ changeStatus }
                    surveyLanguages={ surveyLanguages }
                    switchLanguage={ switchLanguage }
                    toggleSendInvitation={ this.toggleSendInvitation }
                    timeSlotData={ timeSlotData }
                    switchTimeSlotType={ switchTimeSlotType }
                    timeSlotType={ timeSlotType }
                    showConfirm={ showConfirm }
                    rightsSurvey={ rightsSurvey }
                />
                <Switch>
                    <Route
                        path={ this.routes.questions }
                        exact
                        component={ Questions }/>
                    <Route
                        path={ this.routes.quarantines }
                        exact
                        component={ Quarantines }/>
                    <Route
                        path={ this.routes.statisticsResults }
                        exact
                        component={ StatisticsResults }/>
                    <Route
                        path={ this.routes.questionsStatistics }
                        exact
                        component={ QuestionsStatistics }/>
                    <Route
                        path={ this.routes.timeSlots }
                        exact
                        component={ SurveyTimeSlots }>
                    </Route>
                    <Route
                        path={ this.routes.surveySettings }
                        exact
                        component={ SurveySettings }
                    />
                    <Route
                        path={ this.routes.surveyCommonSettings }
                        exact
                        component={ SurveyCommonSettings }
                    />
                    <Route
                        path={ this.routes.surveyLanguages }
                        exact
                        component={ SurveyLanguages }
                    />
                    <Route
                        path={ this.routes.customers }
                        exact
                        render={ () => <SurveyCustomers customersTableRef={ this.customersTableRef }/> }/>
                    <Redirect to={ this.changeRedirectPath(match.url, '/questions') }/>
                </Switch>
                <SendInvitation
                    surveyGroup={ surveyGroup }
                    languages={ surveyGroup.surveyGroupLanguages.data }
                    show={ this.state.showSendInvitation }
                    close={ this.toggleSendInvitation }
                    actionHandler={ this.sendInvite }
                    getSurveysGroupLangSet={ this.props.getSurveysGroupLangSet }
                />
            </div>
        );
    }
}

export const SurveyReducer = combineReducers({
    questions,
    quarantines,
    question,
    surveyCommon,
    surveyCustomers,
    statisticsResults,
    questionsStatistics,
    languageSettings,
    commonSettings,
    surveySettings,
    surveyTimeSlots,
});
