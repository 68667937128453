import React from 'react';
import { TooltipWrapper } from '/components';
import { components } from 'react-select';
import { Badge } from 'reactstrap';

import './ComponentOption.scss';

export const ComponentOption = props => {
    const { data: { badge, label } } = props;

    return (
        <div className={ 'component-option-label_container' }>
            <components.Option { ...props }>
                <div className={ 'component-option_label' }>
                    <TooltipWrapper value={ label }>
                        <div>
                            { label }
                        </div>
                    </TooltipWrapper>
                </div>
                {
                    Boolean(badge)
                    && <div className={ 'component_option_badge' }>
                        <Badge color={ badge.color }>
                            { badge.title }
                        </Badge>
                    </div>
                }
            </components.Option>
        </div>
    );
};
