import React, { Fragment, useEffect, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { VocFormTextGroupField } from '/components';
import { textFilterFormValidation } from './textFilterFormValidation.js';
import { ModalFooter } from '../ModalFooter';

const Form = ({
    close,
    filter,
    name,
    onSubmit,
    handleSubmit,
    dirty,
    valid,
    notInclude,
    submitting,
    initialValues,
}) => {
    const [ filterForm, setFilterForm ] = useState(filter);

    useEffect(() => {
        if (!filterForm.name || filterForm.name !== filter.name) {
            setFilterForm(filter);
        }

        return () => {
            setFilterForm({});
        };
    }, [ filter ]);

    return (
        <Fragment>
            <div className={ 'modal-body' }>
                <Row>
                    <Col md={ 12 }>
                        <Field
                            name='values'
                            component={ VocFormTextGroupField }
                            label='Text'
                            notInclude={ notInclude }
                        />
                    </Col>
                </Row>
            </div>
            <ModalFooter
                close={ close }
                onClick={ handleSubmit(values => onSubmit(values, name)) }
                disabled={ !(valid && dirty) || submitting }
                addBtn={ initialValues.nameButton }
            />
        </Fragment>
    );
};

export const TextFilterForm = reduxForm({
    enableReinitialize: true,
    form: 'TextFilterForm',
    validate: textFilterFormValidation,
})(Form);
