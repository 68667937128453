import React from 'react';
import PropTypes from 'prop-types';
import { FormText } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import {
    MenuList,
    DropdownIndicator,
    Option,
} from '../../SelectComponents/';

export const VocFormCreatableSelectField = ({
    input,
    className,
    type,
    placeholder,
    isClearable,
    openOnFocus,
    options,
    required,
    withError,
    disabled,
    portal,
    meta: {
        touched,
        error,
    },
}) => {
    const blurHandler = () => {
        input.onBlur();
    };

    const config = {
        components: { MenuList, DropdownIndicator, Option },
    };

    if (portal) {
        config['menuPortalTarget'] = document.body;
        config['styles'] = { menuPortal: base => ({ ...base, zIndex: 9999 }) };
        config['menuShouldScrollIntoView'] = false;
        config['menuPlacement'] = "auto";
    }

    return (
        <div className={ `${ className } app-form-control__container ${ withError ? 'with-error' : '' }` }>
            <CreatableSelect
                value={ input.value }
                id={ input.name }
                openOnFocus={ openOnFocus }
                options={ options }
                isDisabled={ disabled }
                className={ `vochub-select-control ${ className }` }
                classNamePrefix={ 'vochub-select-control' }
                placeholder={ placeholder }
                { ...{
                    type,
                    placeholder,
                    required,
                    ...config,
                } }
                error={ Boolean(touched && error) }
                { ...input }
                isClearable={ isClearable }
                onBlur={ blurHandler }
            />
            { Boolean(touched && error && withError)
                ? <FormText className="app-form-control__error">{ error }</FormText>
                : false
            }

        </div>
    );
};

VocFormCreatableSelectField.propTypes = {
    className: PropTypes.string,
    withError: PropTypes.bool,
    openOnFocus: PropTypes.bool,
    options: PropTypes.array,
};

VocFormCreatableSelectField.defaultProps = {
    withError: true,
    isClearable: false,
    openOnFocus: false,
    className: '',
};
