import { CALL_API } from 'store/middleware/api';
import { IFilters, IParamsApplianceModal } from '/ai/models';

const GET_TOPIC_SETS_VOC_AI_REQUEST = 'GET_TOPIC_SETS_VOC_AI_REQUEST';
const GET_TOPIC_SETS_VOC_AI_SUCCESS = 'GET_TOPIC_SETS_VOC_AI_SUCCESS';
const GET_TOPIC_SETS_VOC_AI_ERROR = 'GET_TOPIC_SETS_VOC_AI_ERROR';

const GET_SOURCES_VOC_AI_REQUEST = 'GET_SOURCES_VOC_AI_REQUEST';
const GET_SOURCES_VOC_AI_SUCCESS = 'GET_SOURCES_VOC_AI_SUCCESS';
const GET_SOURCES_VOC_AI_ERROR = 'GET_SOURCES_VOC_AI_ERROR';

const CREATE_TOPIC_SET_APPLIANCE_REQUEST = 'CREATE_TOPIC_SET_APPLIANCE_REQUEST';
const CREATE_TOPIC_SET_APPLIANCE_SUCCESS = 'CREATE_TOPIC_SET_APPLIANCE_SUCCESS';
const CREATE_TOPIC_SET_APPLIANCE_ERROR = 'CREATE_TOPIC_SET_APPLIANCE_ERROR';

const EDIT_TOPIC_SET_APPLIANCE_REQUEST = 'EDIT_TOPIC_SET_APPLIANCE_REQUEST';
const EDIT_TOPIC_SET_APPLIANCE_SUCCESS = 'EDIT_TOPIC_SET_APPLIANCE_SUCCESS';
const EDIT_TOPIC_SET_APPLIANCE_ERROR = 'EDIT_TOPIC_SET_APPLIANCE_ERROR';

const TOGGLE_TOPIC_SET_APPLIANCE_MODAL = 'TOGGLE_TOPIC_SET_APPLIANCE_MODAL';
const CLEANUP_TOPIC_SET_APPLIANCE_MODAL = 'CLEANUP_TOPIC_SET_APPLIANCE_MODAL';

const UPDATE_TOPIC_SETS_VOC_AI_FILTERS = 'UPDATE_TOPIC_SETS_VOC_AI_FILTERS';
const UPDATE_SOURCES_VOC_AI_FILTERS = 'UPDATE_SOURCES_VOC_AI_FILTERS';
const CLEANUP_VOC_AI = 'CLEANUP_VOC_AI';

const initialFilters = {
    limit: 10,
    page: 1,
    search: '',
};

export const getInitialState = () => ({
    topicSetApplianceModalOpen: false,
    topicSetApplianceModalLoading: false,
    topicSets: {
        data: [],
        totalCount: 0,
        loading: false,
        filters: initialFilters,
    },
    sources: {
        data: [],
        totalCount: 0,
        loading: false,
        filters: initialFilters,
    },
});

export const reducer = (state = getInitialState(), action: any = {}) => {
    switch (action.type) {
        case CLEANUP_TOPIC_SET_APPLIANCE_MODAL: {
            return {
                ...state,
                topicSets: getInitialState().topicSets,
                sources: getInitialState().sources,
            };
        }

        case EDIT_TOPIC_SET_APPLIANCE_REQUEST:
        case CREATE_TOPIC_SET_APPLIANCE_REQUEST: {
            return {
                ...state,
                topicSetApplianceModalLoading: true,
            };
        }

        case EDIT_TOPIC_SET_APPLIANCE_SUCCESS:
        case EDIT_TOPIC_SET_APPLIANCE_ERROR:
        case CREATE_TOPIC_SET_APPLIANCE_SUCCESS:
        case CREATE_TOPIC_SET_APPLIANCE_ERROR: {
            return {
                ...state,
                topicSetApplianceModalLoading: false,
            };
        }

        case UPDATE_TOPIC_SETS_VOC_AI_FILTERS: {
            return {
                ...state,
                topicSets: {
                    ...state.topicSets,
                    filters: {
                        ...state.topicSets.filters,
                        ...action.payload,
                    },
                },
            };
        }

        case UPDATE_SOURCES_VOC_AI_FILTERS: {
            return {
                ...state,
                sources: {
                    ...state.sources,
                    filters: {
                        ...state.sources.filters,
                        ...action.payload,
                    },
                },
            };
        }

        case TOGGLE_TOPIC_SET_APPLIANCE_MODAL: {
            return {
                ...state,
                topicSetApplianceModalOpen: !state.topicSetApplianceModalOpen,
            };
        }

        case GET_TOPIC_SETS_VOC_AI_REQUEST: {
            return {
                ...state,
                topicSets: {
                    ...state.topicSets,
                    loading: true,
                },
            };
        }

        case GET_TOPIC_SETS_VOC_AI_SUCCESS: {
            return {
                ...state,
                topicSets: {
                    ...state.topicSets,
                    loading: false,
                    data: [
                        ...state.topicSets.data,
                        ...action.response.data,
                    ],
                    totalCount: action.response.count,
                },
            };
        }

        case GET_TOPIC_SETS_VOC_AI_ERROR: {
            return {
                ...state,
                topicSets: {
                    ...state.topicSets,
                    loading: false,
                },
            };
        }

        case GET_SOURCES_VOC_AI_REQUEST: {
            return {
                ...state,
                sources: {
                    ...state.sources,
                    loading: true,
                },
            };
        }

        case GET_SOURCES_VOC_AI_SUCCESS: {
            return {
                ...state,
                sources: {
                    ...state.sources,
                    loading: false,
                    data: [
                        ...state.sources.data,
                        ...action.response.data,
                    ],
                    totalCount: action.response.count,
                },
            };
        }

        case GET_SOURCES_VOC_AI_ERROR: {
            return {
                ...state,
                sources: {
                    ...state.sources,
                    loading: false,
                },
            };
        }

        case CLEANUP_VOC_AI: {
            return getInitialState();
        }

        default:
            return state;
    }
};

export function getTopicSets(filters: IFilters) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set/`,
            method: 'GET',
            types: [ GET_TOPIC_SETS_VOC_AI_REQUEST, GET_TOPIC_SETS_VOC_AI_SUCCESS, GET_TOPIC_SETS_VOC_AI_ERROR ],
            logger: true,
            contentType: 'application/json',
            body: {
                ...filters,
                sort: JSON.stringify({ title: 'asc' }),
            },
        },
    };
}

export function getApplianceSources(filters: IFilters) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/source/editor-list`,
            method: 'GET',
            types: [ GET_SOURCES_VOC_AI_REQUEST, GET_SOURCES_VOC_AI_SUCCESS, GET_SOURCES_VOC_AI_ERROR ],
            logger: true,
            contentType: 'application/json',
            body: {
                ...filters,
                sort: JSON.stringify({ title: 'asc' }),
            },
        },
    };
}

export function createTopicSetAppliance(params: IParamsApplianceModal, topicSetId: string, sourceId: string) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set-appliance/${topicSetId}/${sourceId}`,
            method: 'POST',
            types: [ CREATE_TOPIC_SET_APPLIANCE_REQUEST, CREATE_TOPIC_SET_APPLIANCE_SUCCESS, CREATE_TOPIC_SET_APPLIANCE_ERROR ],
            logger: true,
            contentType: 'application/json',
            body: params,
        },
    };
}

export function editTopicSetAppliance(params: IParamsApplianceModal, applianceId: number) {
    return {
        [CALL_API]: {
            endpoint: `/public_api/voc-ai/topic-set-appliance/${applianceId}`,
            method: 'PUT',
            types: [ EDIT_TOPIC_SET_APPLIANCE_REQUEST, EDIT_TOPIC_SET_APPLIANCE_SUCCESS, EDIT_TOPIC_SET_APPLIANCE_ERROR ],
            logger: true,
            contentType: 'application/json',
            body: params,
        },
    };
}

export function toggleTopicSetApplianceModal() {
    return {
        type: TOGGLE_TOPIC_SET_APPLIANCE_MODAL,
    };
}

export function cleanupTopicSetApplianceModal() {
    return {
        type: CLEANUP_TOPIC_SET_APPLIANCE_MODAL,
    };
}

export function updateTopicSetsFilters(filters: IFilters) {
    return {
        type: UPDATE_TOPIC_SETS_VOC_AI_FILTERS,
        payload: filters,
    };
}

export function updateSourcesFilters(filters: IFilters) {
    return {
        type: UPDATE_SOURCES_VOC_AI_FILTERS,
        payload: filters,
    };
}

export function cleanup() {
    return {
        type: CLEANUP_VOC_AI,
    };
}
