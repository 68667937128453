import './TextService.scss';

export class TextService {
    static getTextLength({ fontSize, fontFamily, fontWeight = '', text, letterSpacing }) {
        const canvas = TextService.getTextLength.canvas || (TextService.getTextLength.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");

        canvas.style.letterSpacing = letterSpacing;
        context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
        const metrics = context.measureText(text);

        return Math.round(metrics.width);
    }

    static isGreater({ width, fontSize, fontFamily, letterSpacing, text, fontWeight }) {
        const w = TextService.getTextLength({
            fontSize,
            fontFamily,
            text,
            letterSpacing,
            fontWeight,
        });

        return w > width;
    }
}
