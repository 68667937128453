import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Card, Collapse, CardBody } from 'reactstrap';
import uuid from 'uuid/v1';
import { useTranslation } from 'react-i18next';

import { setHeaderRowType } from '/scenes/VocStore/scenes/UploadData/scenes/UploadNewFile/modules';
import { Preloader } from '/components';

import { ColumnButtonGroup } from '../ColumnButtonGroup';

import './styles.scss';

export const VerificationSection = ({
    loading,
    columns,
    FileParser,
    error,
}) => {
    const { t } = useTranslation();
    const [ showMetadataColumns, setShowMetadataColumns ] = useState(false);
    const { main, additional } = columns;
    const dispatch = useDispatch();

    const showColumns = columns => columns.length > 0;

    const areMainColumns = showColumns(main);
    const areAdditionalColumns = showColumns(additional);

    const onColumnTypeSelect = (columnId, type, unsetFlag) => {
        const headerRowType = {};
        const columns = main
            .concat(additional)
            .map(item => {
                if (item.id === columnId) item.type = unsetFlag ? 'META' : type;

                return item;
            });

        columns.filter(col => !col.disabled).forEach(column => {
            headerRowType[column.index] = column.type;
        });
        dispatch(setHeaderRowType(headerRowType));
    };

    const getColumnButtonGroup = column =>
        <ColumnButtonGroup
            key={ uuid() }
            columnTypes={ FileParser.columnTypes.list.filter(type => type !== 'META') }
            onColumnTypeSelect={ onColumnTypeSelect }
            column={ column }
        />;

    const toggleShowMetadataColumnsBtn = () => setShowMetadataColumns(!showMetadataColumns);

    if (loading && !error) return <Preloader />;

    if (!areMainColumns && !areAdditionalColumns) return null;

    return (
        <div>
            <h4>{ t('verificationSection') }</h4>
            <hr/>
            {
                areMainColumns
                    && (
                        <Card className={ 'metadata-columns-block' }>
                            <CardBody>
                                { main.map(column => getColumnButtonGroup(column)) }
                            </CardBody>
                        </Card>
                    )
            }
            {
                (areMainColumns && areAdditionalColumns)
                    && (
                        <Button
                            color={ 'info' }
                            className={ 'metadata-button' }
                            onClick={ toggleShowMetadataColumnsBtn }
                        >
                            { t(showMetadataColumns ? 'hideMetadataColumns' : 'showMetadataColumns') }
                        </Button>
                    )
            }

            {
                areAdditionalColumns
                    && (
                        <Collapse isOpen={ !areMainColumns ? true : showMetadataColumns }>
                            <Card className={ 'metadata-columns-block' }>
                                <CardBody>
                                    { additional.map(column => getColumnButtonGroup(column)) }
                                </CardBody>
                            </Card>
                        </Collapse>
                    )
            }
        </div>
    );
};

VerificationSection.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    columns: PropTypes.object.isRequired,
    FileParser: PropTypes.func.isRequired,
};
