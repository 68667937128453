import React from "react";
import { Input } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import { ConfigureStatesSelector } from "/visual/scenes/Dashboard/modules/Dashboard.selectors";

import styles from "./style.module.scss";

interface IConfigureStatesProps {
    configureStates: string[];
    onChange: (data: { state: string[] }) => void;
}

export const ConfigureStates = ({ configureStates, onChange }: IConfigureStatesProps) => {
    const { t } = useTranslation();
    const configureStatesCheckboxes = useSelector(() => ConfigureStatesSelector(t));

    const onChangeHandler = (checkboxValue: string) => {
        let dataSettingsState = [ ...configureStates ];

        const findValue = dataSettingsState.find(stateValue => stateValue === checkboxValue);

        if(findValue) {
            dataSettingsState = dataSettingsState.filter(stateValue => stateValue !== checkboxValue);
        } else {
            dataSettingsState = [ ...dataSettingsState, checkboxValue ];
        }

        onChange({ state: dataSettingsState });
    };

    return (
        <div className={ styles.checkboxesWrapper }>
            {
                configureStatesCheckboxes.map(checkbox => (
                    <label key={ checkbox.title }>
                        <Input
                            className='radio_input'
                            id={ `customDate${checkbox.type}` }
                            name={ 'customDate' }
                            type="checkbox"
                            checked={ Boolean(configureStates.includes(checkbox.type)) }
                            onChange={ () => onChangeHandler(checkbox.type) }
                        />
                        <span>{ checkbox.title }</span>
                    </label>
                ))
            }
        </div>
    );
};
