import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import cx from 'classnames';

import { OutsideAlerter } from '/components';

import styles from './style.module.scss';

export const ControlledTooltip = ({
    isOpen,
    target,
    className,
    tooltipContent,
    placement = 'top',
    onClose,
}) => (
    <UncontrolledTooltip
        className={ cx(className, styles.controlledTooltipWrapper) }
        target={ target }
        isOpen={ isOpen }
        boundariesElement='body'
        trigger='hover'
        placement={ placement }
    >
        <OutsideAlerter handleClickOutside={ onClose }>
            { tooltipContent }
        </OutsideAlerter>
    </UncontrolledTooltip>
);
