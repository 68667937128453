import React from 'react';
import PropTypes from 'prop-types';

export const MetaFilterItemRemove = function({ parent, onRemoveClick }) {
    let firstItemHasRemoveBtn = true;

    if(parent.hash === 'root') {
        const { id, operator, value } = parent.items[0];

        firstItemHasRemoveBtn = Boolean(id.length || operator.length || value);
    }

    return firstItemHasRemoveBtn
        ? <div onClick={ onRemoveClick } className="voc-meta-filter__item-remove-btn">X</div>
        : null;
};

MetaFilterItemRemove.propTypes = {
    onRemoveClick: PropTypes.func,
    parent: PropTypes.object,
};

