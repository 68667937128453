import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Tabs, AbsolutePeriod, RelativePeriod } from '/visual/scenes/Dashboard/components';
import { SwitchControl, VocSelect, ComponentOption, ComponentValue } from '/components';
import { sourceCustomDate } from '/visual/scenes/Dashboard/modules/Dashboard.selectors';
import { IGadgetData, ITab, setTempSettingsType } from '/visual/models';
import { DateService } from '/visual/scenes/Dashboard/services';
import { HelperService } from '/services';

import styles from './style.module.scss';

export const PeriodSettings = ({
    gadget,
    setTempSettings,
}: {
    gadget: IGadgetData,
    setTempSettings: setTempSettingsType,
}) => {
    const { t } = useTranslation();
    const { dataSettings } = gadget;
    const dateOptions = useSelector(sourceCustomDate);
    const [ questionnaireDateValue, setQuestionnaireDateValue ] = useState(dataSettings.customDateColumn);
    const questionnaireDateSelectValue = dateOptions?.find(option => option.value === questionnaireDateValue);

    const [ activeTab, setActiveTab ] = useState<string | number>('');
    const [ showDate, setShowDate ] = useState(Boolean(dataSettings?.customDateColumn) || false);

    const [ periodsData, setPeriodsData ] = useState<{ [key: string]: any }>({
        absolute: {
            from: null,
            to: null,
        },
        relative: {
            end: null,
            period: null,
            start: null,
        },
    });

    useEffect(() => {
        const { dateFilter } = dataSettings;

        setActiveTab(dateFilter?.end || dateFilter?.period || dateFilter?.start
            ? 'relative'
            : 'absolute',
        );

        if(dateFilter) {
            setPeriodsData({
                absolute: {
                    from: dateFilter.from,
                    to: dateFilter.to,
                },
                relative: {
                    end: dateFilter.end,
                    period: dateFilter.period,
                    start: dateFilter.start,
                },
            });
        }
    }, []);

    const onChangePeriod = ({ periodType, ...data }: any) => {
        setPeriodsData({
            ...periodsData,
            [periodType]: data,
        });

        setTempSettings({
            dataSettings: {
                dateFilter: data,
            },
        });
    };

    const tabs: ITab[] = [
        {
            title: t('absolutePeriod'),
            component: <AbsolutePeriod
                data={ periodsData.absolute }
                onChange={
                    data => onChangePeriod({
                        ...data,
                        periodType: 'absolute',
                    })
                }
            />,
            id: 'absolute',
        },
        {
            title: t('relativePeriod'),
            component: <RelativePeriod
                data={ periodsData.relative }
                onChange={
                    data => onChangePeriod({
                        ...data,
                        periodType: 'relative',
                    })
                }
            />,
            id: 'relative',
        },
    ];

    const onChangeCustomDate = (data: any) => {
        setQuestionnaireDateValue(data?.value || null);
        setTempSettings({
            dataSettings: {
                customDateColumn: data?.value || null,
            },
        });
    };

    const onChangeTab = (tabId: ITab['id']) => {
        const getRelativePeriod = () => periodsData.absolute.from && periodsData.absolute.to
            ? {
                ...DateService.calculateAbsoluteToRelative(periodsData.absolute.from, periodsData.absolute.to),
                period: 'DAY',
            }
            : periodsData.relative;

        const getAbsolutePeriod = () => {
            let relativeData;

            if(periodsData.relative.period !== null) {
                relativeData = DateService.calculateRelativeDate({
                    start: Math.abs(periodsData.relative.start),
                    end: Math.abs(periodsData.relative.end),
                    period: periodsData.relative.period,
                });
            }

            return periodsData.relative.period !== null && relativeData
                ? {
                    from: HelperService.formatDate(new Date(relativeData.from), 'DD-MM-YYYY'),
                    to: HelperService.formatDate(new Date(relativeData.to), 'DD-MM-YYYY'),
                }
                : periodsData.absolute;
        };

        const periodsDataMask = {
            ...periodsData,
            [tabId]: {
                ...tabId === 'relative'
                    ? getRelativePeriod()
                    : getAbsolutePeriod(),
            },
        };

        setActiveTab(tabId);
        setPeriodsData(periodsDataMask);

        setTempSettings({
            dataSettings: {
                dateFilter: periodsDataMask[tabId],
            },
        });
    };

    const onChangeShowDate = () => {
        setShowDate(!showDate);
        setTempSettings({
            dataSettings: {
                customDateColumn: showDate
                    ? null
                    : questionnaireDateValue,
            },
        });
    };

    const radioBlock = () => (
        <div className={ styles.periodSettingsWrapper }>
            <div className={ styles.selectWrapper }>
                <Label htmlFor='customDate'>
                    { t('field.questionnaireCustomDate') }
                </Label>
                <SwitchControl
                    name='customDate'
                    value={ showDate }
                    changeHandler={ onChangeShowDate }
                />
            </div>

            {
                showDate
                && <div className={ styles.radiosWrapper }>
                    {
                        <VocSelect
                            name={ 'selectData' }
                            className={ 'select-with-label' }
                            placeholder={ t('field.selectCustomDate') }
                            portal
                            isHorizontal
                            isClearable
                            options={ dateOptions }
                            value={ questionnaireDateSelectValue }
                            onChange={ onChangeCustomDate }
                            components={{
                                Option: ComponentOption,
                                ValueContainer: ComponentValue,
                            }}
                        />
                    }
                </div>
            }
            <Tabs
                tabs={ tabs }
                onChange={ onChangeTab }
                activeTab={ activeTab }
            />
        </div>
    );

    return <div>{ radioBlock() }</div>;
};
