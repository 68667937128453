import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { VocFormSelectField, VocFormTextField } from '/components';
import { HelperService } from '/services';
import { exporterDynamicFormMapData } from '../../modules/exporter.selectors';
import iconCross from '/images/icon-cross.svg';

export const RenderConditions = ({ field, fields, idRow }) => {
    return <>
        <Col md={ 10 }>
            <Row>
                <Col md={ 4 }>
                    <Field
                        placeholder={ 'Id' }
                        name={ `${ field }.question_external_identifier` }
                        component={ VocFormTextField }
                        required
                    />
                </Col>
                <Col md={ 4 }>
                    <Field
                        component={ VocFormSelectField }
                        name={ `${ field }.expression` }
                        options={ exporterDynamicFormMapData.export_conditions }
                        placeholder={ 'Choose conditions' }
                        normalize={ HelperService.normalizeSelectValue }
                        format={ value => HelperService.getSelectedValue(exporterDynamicFormMapData.export_conditions, value) }
                        portal
                        required
                    />
                </Col>
                <Col md={ 4 }>
                    <Field
                        placeholder={ 'Value' }
                        name={ `${ field }.matching_value` }
                        component={ VocFormTextField }
                        required
                    />
                </Col>
            </Row>
        </Col>

        <Col md={ 1 }>
            <span onClick={ () => fields.remove(idRow) } className="exporter-condition__remove">
                <img src={ iconCross } alt=""/>
            </span>
        </Col>
    </>;
};
