import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ExportResultsRate = ({ responseRate, id, openModal }) => {
    const rateTarget = `response_rate_${id}`;
    const listData = Object.keys(responseRate).reduce((rateList, rate) => ([
        ...rateList,
        {
            setting: responseRate[rate].label + ':',
            value: responseRate[rate].value,
        },
    ]), []);

    return (
        <Button color="link" onClick={ () => openModal(rateTarget, listData) }>
            <FontAwesomeIcon icon="list" id={ rateTarget }/>
        </Button>
    );
};
