import React, { Fragment } from 'react';
import { StatisticItem } from '../StatisticItem';
import { StatisticAnswerItem } from '../StatisticAnswerItem/';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import './StatisticList.scss';

export const StatisticList = ({ values }) => (
    <Fragment>
        { values.length
            ? values.map((value, index) => {
                const { answers = [] } = value;

                return (
                    <StatisticItem { ...value } key={ index }>
                        { answers.map((answer, index) => <StatisticAnswerItem { ...answer } key={ index }/>) }
                    </StatisticItem>
                );
            })
            : <Alert color={ 'warning' } className={ 'statistic__list-alert' }>There is no question statistics for this channel.</Alert>
        }
    </Fragment>
);

StatisticList.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        responses: PropTypes.number,
        collapsible: PropTypes.bool,
        answers: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            value: PropTypes.number,
            responseRatio: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        })),
    })),
};

StatisticList.defaultProps = {
    values: [],
};
