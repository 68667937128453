import { createSelector } from 'reselect';
import { UsersRoleFeedbackService } from '/services';
import { surveyStatesSelector, timeSlotOptions, unitsListSelector } from '/feedback/modules/VocFeedback.selectors';

const surveySelector = state => state.VocFeedback.survey;

export const unitsListCountSelector = state => state.VocFeedback.common.unitsList.count;
export const surveyGroupSelector = state => state.VocFeedback.survey.surveyCommon;
export const themeSettingsSelector = state => state.VocFeedback.survey.surveyCommon.themeSettingsForDigi;
export const isLoadingSurveySelector = state => state.VocFeedback.survey.surveyCommon.loading;
export const isSupportUserSelector = state => state.UserInfo.userInfo.isSupportUser;
export const channelsSelector = () => [ "WEB", "SMS", "IVR", "DIGI" ];

export const surveysList = createSelector(surveyGroupSelector, surveyStatesSelector, channelsSelector,
    ({ surveys, id, nameCreateSurvey }, states, channels) => {
        return channels.map(channel => {
            const isSurvey = surveys.find(value => value.channel === channel);

            return !isSurvey
                ? {
                    channel,
                    survey_group: id,
                    isCreating: nameCreateSurvey === channel,
                }
                : {
                    ...isSurvey,
                    state: _getState({ states, isSurvey }),
                    survey_group: id,
                };
        });
    });

function _getState({ states, isSurvey }) {
    const state = states.find(state => state.id === isSurvey.state);

    return state && state.id;
}

export const surveysPanelSelector = createSelector(surveySelector, surveysList, unitsListCountSelector,
    ({ surveyCommon: { unit, name, sequence } }, surveys, unitsListCount) => {
        return {
            unit,
            surveys,
            name,
            sequence,
            unitsListCount,
        };
    },
);

export const surveyPageLinksSelector = surveyGroup => {
    return [
        {
            title: 'Questions',
            link: `/feedback/survey/${ surveyGroup }/questions`,
            isReactLink: true,
        },
        {
            title: 'Survey Settings',
            link: `/feedback/survey/${ surveyGroup }/settings/advanced`,
            isReactLink: true,
        },
        {
            title: 'Common Settings',
            link: `/feedback/survey/${ surveyGroup }/settings/common`,
            isReactLink: true,
        },
        {
            title: 'Language Settings',
            link: `/feedback/survey/${ surveyGroup }/settings/language`,
            isReactLink: true,
        },
        {
            title: 'Time slots',
            link: `/feedback/survey/${ surveyGroup }/settings/time-slots`,
            isReactLink: true,
        },
        {
            title: 'Statistics',
            icon: 'chart-line',
            items: [
                {
                    title: 'Statistics Results',
                    link: `/feedback/survey/${ surveyGroup }/statistics`,
                    isReactLink: true,
                },
                {
                    title: 'Questions Statistics',
                    link: `/feedback/survey/${ surveyGroup }/questions_statistics`,
                    isReactLink: true,
                },
            ],
        },
        {
            title: 'Quarantines',
            link: `/feedback/survey/${ surveyGroup }/quarantines`,
            isReactLink: true,
        },
        {
            title: 'Customers',
            link: `/feedback/survey/${ surveyGroup }/customers`,
            isReactLink: true,
        },
    ];
};

export const renameSurveyLoadingSelector = state => surveyGroupSelector(state).modals.renameLoading;
export const changeStatusLoadingSelector = state => surveyGroupSelector(state).modals.changeStatusLoading;
export const languagesSelector = state => surveyGroupSelector(state).surveyGroupLanguages;
export const activeLanguageSelector = state => surveyGroupSelector(state).activeLanguage;
export const responsesCountSelector = state => surveyGroupSelector(state).responsesCount;

export const _getTypeTimeSlotSelector = state => surveyGroupSelector(state).timeSlotType;
export const activeTimeSlotTypeSelector = createSelector(
    [ _getTypeTimeSlotSelector ],
    typeTimeSlot =>
        timeSlotOptions.find(({ value }) => value === typeTimeSlot));

//
export const rightsSurveySelector = createSelector(
    [ surveyGroupSelector, unitsListSelector, isSupportUserSelector ],
    ({ unit = {} }, units, isSupportUser) => {
        const findUnit = units.find(({ id }) => id === unit.id);

        return findUnit?.id
            ? {
                ...UsersRoleFeedbackService.getRightsForSurvey(findUnit.permissionsName),
                isSupportUser,
            }
            : {};
    });
