import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { VocModal } from '/components';
import { HelperService } from '/services';
import { sendToEmail } from '/visual/modules/VocVisual.modules';
import { ChooseFormatFileRadio } from '/visual/components';
import { EExportFormats } from '/visual/models';
import { ISendToEmailModal } from './models';

import styles from './style.module.scss';

export const SendToEmailModal = ({ show, toggle, dashboardId }: ISendToEmailModal) => {
    const [ isToMe, setIsToMe ] = useState(false);
    const [ isEmailValid, setIsEmailValid ] = useState(false);
    const [ email, setEmail ] = useState<string>('');
    const [ fileFormat, setFileFormat ] = useState<EExportFormats>(EExportFormats.PDF);
    const [ subject, setSubject ] = useState<string>('');
    const [ message, setMessage ] = useState<string>('');
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleChangeInput = (setState: React.Dispatch<React.SetStateAction<string>>) => {
        return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setState(value);
        };
    };

    const handleSendEmail = () => {
        dispatch(sendToEmail(dashboardId, {
            sendToOwnEmail: isToMe,
            email: isToMe ? null : email,
            format: fileFormat,
            subject: subject || null,
            message: message || null,
        }));

        toggle();
    };

    const cleanModal = () => {
        setIsToMe(false);
        setIsEmailValid(false);
        setEmail('');
        setSubject('');
        setMessage('');
    };

    const getBody = () => (
        <>
            <label htmlFor='sendTo'>
                { t('field.to') }:
            </label>
            <InputGroup>
                <InputGroupAddon addonType='prepend'>
                    <label>
                        <InputGroupText className={ styles.inputGroup }>
                            { t('field.me') }
                            <Input
                                checked={ isToMe }
                                className={ styles.checkbox }
                                type='checkbox'
                                addon
                                onChange={ () => setIsToMe(!isToMe) }
                            />
                        </InputGroupText>
                    </label>
                </InputGroupAddon>

                <Input
                    value={ email || '' }
                    disabled={ isToMe }
                    id='sendTo'
                    placeholder={ t('field.email') }
                    onChange={ handleChangeInput(setEmail) }
                />
            </InputGroup>

            <ChooseFormatFileRadio
                labelStyles={ styles.label }
                fileFormat={ fileFormat }
                setFileFormat={ setFileFormat }
            />

            <label className={ styles.label } htmlFor='subject'>
                { t('field.subject') }:
            </label>
            <Input
                value={ subject || '' }
                id='subject'
                placeholder={ t('field.subject') }
                onChange={ handleChangeInput(setSubject) }
            />

            <label className={ styles.label } htmlFor='message'>
                { t('field.message') }:
            </label>
            <Input
                value={ message || '' }
                id='message'
                type='textarea'
                rows='5'
                className={ styles.textarea }
                onChange={ handleChangeInput(setMessage) }
            />
        </>
    );

    const getFooter = () => (
        <>
            <Button
                outline
                color='white'
                onClick={ toggle }
            >
                <span>{ t('close') }</span>
            </Button>

            <Button
                disabled={ !(isToMe || isEmailValid) }
                color='primary'
                onClick={ handleSendEmail }
            >
                <span>{ t('send') }</span>
            </Button>
        </>
    );

    useEffect(() => {
        setIsEmailValid(HelperService.validateEmail(email));
    }, [ email ]);

    return (
        <VocModal
            isOpen={ show }
            header={ t('sendDashboardToEmail') }
            size='ms'
            modalClassName='modal-scrollable'
            footer={ getFooter() }
            toggle={ toggle }
            onClosed={ cleanModal }
        >
            { getBody() }
        </VocModal>
    );
};
