import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SplitPane, { Pane } from 'split-pane-react';

import { EmptyData, SplitPaneSash, TopicsBlock, VisualBlock } from "/components";
import { useTopicSetFieldsData, useTopics } from '/services';
import { _getTopicsData } from "/modules/topicSetModules/topicSet.selectors";
import { cleanupTopicSet, cleanupTopicSetSocket } from "/modules/topicSetModules/topicSet.modules";
import { dirtyFiltersSelector, selectedDictionariesIdsSelector } from "/mine/scenes/Query/modules/query.selectors";
import { setZeroShotExportData } from "/mine/scenes/Query/modules/query.modules";

import styles from './style.module.scss';

export const ZeroShotBlock = ({ querySizes, queryData: { langSelected, queryId, sourceId, textColumns,
} }) => {
    const dispatch = useDispatch();
    const [ sizes, setSizes ] = useState([ 130, 495 ]);

    const { synonyms, stopWords } = useSelector(selectedDictionariesIdsSelector);
    const dirtyFilters = useSelector(dirtyFiltersSelector);
    const topicsSelector = useSelector(_getTopicsData);

    const {
        fieldsData,
        onChange,
        cleanUpFields,
    } = useTopicSetFieldsData({});

    const formattedFieldsData = {
        ...fieldsData,
        source: {
            id: sourceId,
        },
        column: textColumns,
        language: langSelected,
    };

    const queryAdditionalDataForGraphs = {
        scopeType: 'mine',
        queryId,
        filters: dirtyFilters,
        synonyms,
        stopWords,
    };

    const {
        topics,
        loading,
        isEnabledSave,
        isEnabledValidateColumns,
        topicsForGraphs,
        onSelectTopics,
        onAddTopics,
        onSaveTopics,
        cleanUpTopics,
    } = useTopics({
        language: langSelected.value,
        minedTopics: topicsSelector.data,
        loading: topicsSelector.loading,
        isColumnSelected: true,
        isViewPermission: true,
    });

    const selectedTopicsLabels = topics.filter(topic => topic.isMined || topic.isSelected).map(topic => topic.label);

    const cleanStates = () => {
        cleanUpTopics();
        cleanUpFields();
        dispatch(cleanupTopicSet());
        dispatch(cleanupTopicSetSocket());
    };

    useEffect(() => {
        return () => cleanStates();
    }, []);

    useEffect(() => {
        setSizes([ 130, 495 ]);
    }, [ querySizes ]);

    useEffect(() => {
        dispatch(setZeroShotExportData({
            labels: selectedTopicsLabels,
            threshold: fieldsData.threshold,
        }));
    }, [ selectedTopicsLabels.length, fieldsData.threshold ]);

    return (
        <div className={ styles.zeroShotMineWrapper }>
            {
                topicsSelector.error
                    ? <EmptyData errorText={ topicsSelector.error }/>
                    : <SplitPane
                        resizerSize={ 1 }
                        className={ styles.zeroShotSplitPane }
                        split='vertical'
                        sizes={ sizes }
                        onChange={ setSizes }
                        sashRender={ SplitPaneSash }
                    >
                        <Pane className={ styles.topicsPane } minSize={ 130 }>
                            <TopicsBlock
                                isMine
                                disableLoadingRender
                                onChange={ onChange }
                                onSaveTopics={ onSaveTopics }
                                isEnabledSave={ isEnabledSave }
                                topics={ topics }
                                topicsForGraphs={ topicsForGraphs }
                                fieldsData={ formattedFieldsData }
                                onSelectTopics={ onSelectTopics }
                                onAddTopics={ onAddTopics }
                                loading={ loading }
                                isEnabledValidateColumns={ isEnabledValidateColumns }
                                queryAdditionalDataForGraphs={ queryAdditionalDataForGraphs }
                            />
                        </Pane>
                        <Pane className={ styles.visualPane } minSize={ 495 }>
                            <VisualBlock topics={ topics }/>
                        </Pane>
                    </SplitPane>
            }
        </div>
    );
};
