import React from 'react';
import { TooltipWrapper } from '/components';
import './QuarantinesNameFormatter.scss';

export const QuarantinesNameFormatter = props => {
    return (
        <div
            id={ 'table-action__container' }
            className='table-action__quarantine-list'>
            <TooltipWrapper value={ props.quarantine.survey_group.name }>
                <span className={ `table-action__quarantine-link cell-data-wrapper` }>
                    { props.quarantine.survey_group.name }
                </span>
            </TooltipWrapper>
        </div>
    );
};
