import React, { useEffect, useState } from 'react';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import { VirtualSourceMappingCell } from '../VirtualSourceMappingCell';
import { VirtualSourceFormSidebarHead } from '../VirtualSourceFormSidebarHead';
import { VirtualSourceHorizontalScrollButtons } from '../VirtualSourceHorizontalScrollButtons';

import './VirtualSourceForm.scss';

const VIRTUAL_COLUMN_HEADER_COUNT = 3;
const BORDERS_HEIGHT = 2;
const MULTI_GRID_WIDTH = 1110;
const BOTTOM_LEFT_GRID_CLASSNAME = 'multi_grid__wrapper-left';
const BOTTOM_RIGHT_GRID_CLASSNAME = 'multi_grid__wrapper-right';

export const VirtualSourceForm = ({
    addColumn,
    addSource,
    handleSourceName,
    virtualSourceName,
    virtualSourceSidebar,
    updateSelectedSource,
    removeVirtualSourceRow,
    columnTypes,
    virtualSourceColumnHeader,
    updateColumnName,
    updateColumnType,
    virtualSourceCells,
    updateCellData,
    removeVirtualSourceColumn,
    autofillColumnsHandler,
    secondAutofillColumnsHandler,
    isAutoFill,
    setAutoFill,
    virtualRelation,
    showConfirm,
}) => {
    const [ showScrollButtons, setShowScrollButtons ] = useState(false);
    const [ showChangeNotificationModal, setShowChangeNotificationModal ] = useState(true);

    useEffect(() => {
        setShowScrollButtons(Boolean(virtualSourceSidebar.length) && virtualSourceColumnHeader.length > VIRTUAL_COLUMN_HEADER_COUNT);
    }, [ virtualSourceColumnHeader.length, virtualSourceSidebar.length ]);

    return (
        <div className={ 'virtual-source-form' }>
            {
                showScrollButtons
                && <VirtualSourceHorizontalScrollButtons
                    virtualColumnsNumber={ virtualSourceColumnHeader.length }
                    gridContainerClassname={ `.${BOTTOM_RIGHT_GRID_CLASSNAME}` }
                />
            }
            {
                !virtualSourceColumnHeader.length && !virtualSourceSidebar.length
                    ? <VirtualSourceFormSidebarHead
                        sourceName={ virtualSourceName }
                        handleSourceName={ handleSourceName }
                        addSource={ addSource }
                        addColumn={ addColumn }
                        isAutoFill={ isAutoFill }
                    />
                    : <AutoSizer>
                        {({ height }) => (
                            <MultiGrid
                                height={ height - BORDERS_HEIGHT }
                                width={ MULTI_GRID_WIDTH }
                                columnCount={ virtualSourceColumnHeader.length + 1 }
                                rowCount={ virtualSourceSidebar.length + 1 }
                                cellRenderer={ ({ columnIndex, rowIndex, key, style }) =>
                                    <div className={ 'multigrid-cell' } key={ key } style={{ ...style }}>
                                        <VirtualSourceMappingCell
                                            { ...{
                                                columnIndex, rowIndex,
                                                virtualSourceName, handleSourceName,
                                                addSource, addColumn, virtualSourceSidebar,
                                                updateSelectedSource, removeVirtualSourceRow,
                                                virtualSourceColumnHeader, columnTypes, updateColumnType,
                                                removeVirtualSourceColumn, updateColumnName, virtualSourceCells,
                                                updateCellData, autofillColumnsHandler, isAutoFill, setAutoFill, showConfirm,
                                                virtualRelation, showChangeNotificationModal, setShowChangeNotificationModal,
                                                secondAutofillColumnsHandler,
                                            } }
                                        />
                                    </div>
                                }
                                fixedColumnCount={ 1 }
                                fixedRowCount={ 1 }
                                enableFixedColumnScroll
                                enableFixedRowScroll
                                hideTopRightGridScrollbar
                                hideBottomLeftGridScrollbar
                                columnWidth={ e => e.index ? 250 : 335 }
                                rowHeight={ e => e.index ? 49 : 106 }
                                classNameBottomLeftGrid={ BOTTOM_LEFT_GRID_CLASSNAME }
                                classNameBottomRightGrid={ BOTTOM_RIGHT_GRID_CLASSNAME }
                            />
                        )}
                    </AutoSizer>
            }
        </div>
    );
};
