import React from 'react';

import { ThinSpinner } from '/components';

import styles from './style.module.css';

export const DashboardPagePreloader = ({ className }: { className?: string }) => (
    <div className={ styles.dashPreloaderContainer }>
        <ThinSpinner className={ className } />
    </div>
);
