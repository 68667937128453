import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';

export const TextGroupControl = ({ addText, value, notInclude, toLowerCase }) => {
    const [ inpValue, setInpValue ] = useState('');

    const onClick = () => {
        let trimValue = inpValue.trim();

        if (toLowerCase) {
            trimValue = trimValue.toLowerCase();
        }
        if (trimValue && !value.includes(trimValue) && !notInclude.includes(trimValue)) {
            addText(trimValue);
        }
        setInpValue('');
    };

    const onChange = val => {
        const { value } = val.target;

        setInpValue(value);
    };

    const onKeyPress = ({ charCode }) => {
        const keyEnter = 13;

        if (charCode === keyEnter) {
            onClick();
        }
    };

    return <div className={ 'text-group-control-group' }>
        <Input
            type="email"
            className='app-form-control'
            onChange={ onChange }
            onKeyPress={ onKeyPress }
            value={ inpValue }
        />
        <Button
            className={ 'text-group-control-group_button' }
            onClick={ onClick }
            color="secondary"
        >
            Add
        </Button>
    </div>;
};
